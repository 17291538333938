import { Callout, Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { fromNow } from 'reports/utils/formatters';

import { Flex } from 'reports/components/core/containers';
import Section from 'reports/components/core/containers/Section2';
import { FavoriteStar, PublishButton } from 'reports/components/core/controls';
import { LibraryPreview } from 'reports/components/core/layout';
import { PreviewDrawer } from 'reports/components/library/main/components';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import * as pd from 'reports/models/power_device';

import * as auth from 'reports/modules/auth';
import { canMakeResourcePublic } from 'reports/modules/auth/permissions';
import EfficiencyTable from 'reports/modules/power_device/components/EfficiencyTable';
import ModeledPerformanceChart from 'reports/modules/power_device/components/ModeledPerformanceChart';
import SpecSheet from 'reports/modules/power_device/components/SpecSheet';
import { DeviceCharViewControls } from 'reports/modules/power_device/components/CharacterizationViewControls';

const RightPanel = styled(Flex.ContainerV)`
    flex: 1;
    margin-left: 20px;
`;

type Props = {
    powerDevice: pd.PowerDevice;
    characterization: pd.PowerDeviceCharacterization;
    navigateToCharacterization: ({
        power_device_id,
        power_device_characterization_id,
    }: pd.PowerDeviceCharacterization) => void;
    navigateToPowerDevices: () => void;
};

const PowerDevicePreview = React.memo(
    ({ powerDevice, characterization, navigateToCharacterization, navigateToPowerDevices }: Props) => {
        const user = useSelector((state) => auth.selectors.getUser(state)!);
        const dispatch = useDispatch();
        const { power_device_id, favorite, last_update, public: isPublic } = powerDevice;
        const toggleFavorite = () => dispatch(pd.api.save({ power_device_id, favorite: !favorite }));
        const togglePublic = () => dispatch(pd.api.save({ power_device_id, public: !isPublic }));
        const [drawerOpen, setDrawerOpen] = React.useState<boolean>(true);

        const characterizationsOnSameTeam = () => {
            const { characterizations, team_id } = powerDevice;
            for (const characterization of characterizations) {
                if (characterization.team_id !== team_id) {
                    return false;
                }
            }
            return true;
        };

        const characterizationPublicWarning = !characterizationsOnSameTeam() && (
            <Callout style={{ marginBottom: '10px' }} intent={Intent.WARNING}>
                <b>Warning</b>: this inverter is used by multiple teams. Changing this inverter's public status will
                update the status for all characterizations of this inverter for all teams using this device.
            </Callout>
        );

        return (
            <PreviewDrawer
                isOpen={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onClosed={navigateToPowerDevices}
                title={
                    <LibraryPreview.Header
                        title={powerDevice.name}
                        subtitle={last_update && `Last modified ${fromNow(last_update)}`}
                        favorite={
                            <FavoriteStar
                                empty={!favorite}
                                onClick={() => {
                                    addPromiseToastsToggle(
                                        'favorite',
                                        toggleFavorite(),
                                        'power device',
                                        powerDevice.name,
                                        favorite,
                                    );
                                }}
                            />
                        }
                    />
                }
                publishButton={
                    <PublishButton
                        isPublic={powerDevice.public}
                        resourceName={powerDevice.name}
                        resourceType={'inverter'}
                        privatePrompt={
                            <div>
                                {characterizationPublicWarning}
                                <p>
                                    Making this inverter private will also revoke access to this resource for any
                                    HelioScope users that are not on the Folsom Labs team and are currently using this
                                    inverter in their designs.
                                </p>
                            </div>
                        }
                        publicPrompt={
                            <div>
                                {characterizationPublicWarning}
                                <p>
                                    Making this inverter public will give all HelioScope users access to this inverter.
                                    Everyone will be able to find and use this inverter in their designs, but not make
                                    any changes to the resource itself.
                                </p>
                            </div>
                        }
                        togglePublic={togglePublic}
                        disabled={!canMakeResourcePublic(user, powerDevice)}
                    />
                }
                widthInPercent={80}
                viewControls={
                    <DeviceCharViewControls
                        device={powerDevice}
                        navigateToCharacterization={navigateToCharacterization}
                        selectedChar={characterization}
                    />
                }
            >
                <div style={{ padding: 10 }}>
                    <Flex.Container>
                        <Section title="Spec Sheet">
                            <SpecSheet device={powerDevice} />
                        </Section>
                        <RightPanel>
                            <Section title="Modeled Performance">
                                <ModeledPerformanceChart characterization={characterization} />
                            </Section>
                            <Section title="Efficiency Table">
                                <EfficiencyTable characterization={characterization} />
                            </Section>
                        </RightPanel>
                    </Flex.Container>
                </div>
            </PreviewDrawer>
        );
    },
);

export { PowerDevicePreview };
