import * as React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import * as usr from 'reports/models/user';
import * as analytics from 'reports/analytics';

import parsePhoneNumber from 'libphonenumber-js';

import { TermsOfUseStatement, formikSetFieldErrors } from './common';

import * as styles from 'reports/styles/styled-components';
import { getClassicUrl } from 'reports/utils/url';
import {
    Card,
    Flex,
    CheckboxInput,
    Form,
    Field,
    PhoneInput,
    PasswordInput,
    TextInput,
    usePhoneSchema,
    Text,
    Button,
} from '@aurorasolar/ds';
import { Formik, FormikHelpers } from 'formik';
import { NewPasswordField } from './NewPasswordField';
import SignupHeader from './SignupHeader';
import SignupFooter from './SignupFooter';
import { Intent } from '@blueprintjs/core';
import Toaster from 'reports/modules/Toaster';
const styled = styles.styled;

interface SignupFormData {
    phone_number: string;
}

// This styling here is to override some strange behavior with the label margins on the PhoneInput field
// All form fields are actually <label> elements that get a 10px bottom margin applied as part of styling rules
// However, since the Country Code dropdown is also a <label> element, this rule gets applied to the dropdown too,
// resulting in an extra bottom margin for the dropdown that shifts it out of alignment with the rest of the input
// To fix this, we force off all bottom margins for labels
//
// The label > div > button rule is to remove the border caused by the HelioScopeTheme's tertiary button rule
// This should be temporary, and removed when we figure out the best way to implement button themeing that doesn't
// unexpectedly overwrite existing button behavior
const StyledForm = styled(Form)`
    label {
        margin-bottom: 0px;
    }

    label > div > button {
        border: 0px;
    }
`;

const SignupPage = () => {
    const { requiredMessage, phoneSchema } = usePhoneSchema();
    const dispatch = useDispatch();
    const signupUser = async (formData) => {
        return await dispatch(usr.api.create(formData));
    };

    const onSubmit = async (formData: SignupFormData, formikHelpers: FormikHelpers<SignupFormData>) => {
        const { setSubmitting } = formikHelpers;
        const pn = parsePhoneNumber(formData.phone_number);
        const values = {
            ...formData,
            phone: {
                number: pn?.number,
                digits: `${pn?.countryCallingCode}${pn?.nationalNumber}`,
                dial_code: pn?.countryCallingCode,
                iso2_code: pn?.country?.toLowerCase(),
                country_name: pn?.country,
            },
        };
        try {
            const newUser = await signupUser(values);
            analytics.track('New User Signup', {
                user_id: newUser.user_id,
                user_email: newUser.email,
                user_name: `${newUser.last_name}, ${newUser.first_name}`,
                team_id: newUser.team_id,
                team_name: newUser.team.name,
            });
            window.location.href = getClassicUrl(`not-activated?email=${encodeURIComponent(newUser.email)}`);
        } catch (ex) {
            if (ex.response?.status === 400) {
                formikSetFieldErrors(ex, formikHelpers);
                // Special handling for phone number
                if (ex.response?.body?.phone) {
                    formikHelpers.setFieldError('phone_number', 'Please enter a valid phone number');
                }
            } else {
                Toaster.show({
                    message: 'Unknown error.',
                    intent: Intent.DANGER,
                });
            }
            throw ex;
        } finally {
            setSubmitting(false);
        }
    };

    const validationSchema = React.useMemo(() => {
        return yup.object({
            phone_number: phoneSchema.required(requiredMessage),
            company: yup.string().max(80, 'Field cannot be longer than 80 characters.'),
        });
    }, [phoneSchema]);

    return (
        <Flex h="100%">
            <SignupHeader />
            {/* The classic signup page uses fixed position to layout the form, so we apply a 196px paddingtop */}
            <Flex alignItems="center" padding="196px 125px">
                <Text fontSize="56px" lineHeight="70px" marginBottom="56px">
                    Free 14-day trial sign up{' '}
                </Text>
                {/* width taken from original signup form */}
                <Card maxWidth="562px">
                    <Formik
                        initialValues={{
                            phone_number: '',
                            password: '',
                            confirm_password: '',
                            terms_of_use: false,
                        }}
                        onSubmit={(values, formikHelpers: FormikHelpers<SignupFormData>) => {
                            onSubmit(values, formikHelpers);
                        }}
                        validateOnChange={false}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => (
                            <StyledForm>
                                <Field
                                    component={TextInput}
                                    fontWeight="normal"
                                    label="Email"
                                    name="email"
                                    placeholder="Enter your email"
                                />
                                <Field
                                    component={PhoneInput}
                                    fontWeight="normal"
                                    label="Phone number"
                                    name="phone_number"
                                />
                                <Field
                                    component={TextInput}
                                    fontWeight="normal"
                                    label="First name"
                                    name="first_name"
                                    placeholder="First name"
                                />
                                <Field
                                    component={TextInput}
                                    fontWeight="normal"
                                    label="Last name"
                                    name="last_name"
                                    placeholder="Last name"
                                />
                                <Field
                                    component={TextInput}
                                    fontWeight="normal"
                                    label="Company"
                                    name="company"
                                    placeholder="Company"
                                />
                                <NewPasswordField name="password" />
                                <Field
                                    component={PasswordInput}
                                    fontWeight="normal"
                                    label="Confirm new password"
                                    name="confirm_password"
                                    placeholder="Re-enter new password"
                                />
                                <Field
                                    component={CheckboxInput}
                                    label={<TermsOfUseStatement />}
                                    name="terms_of_use"
                                    disabled={isSubmitting}
                                />
                                <Button
                                    size="md"
                                    type="submit"
                                    variant="primary"
                                    fullWidth={false}
                                    disabled={isSubmitting}
                                >
                                    Sign up
                                </Button>
                            </StyledForm>
                        )}
                    </Formik>
                </Card>
            </Flex>
            <SignupFooter />
        </Flex>
    );
};

export default SignupPage;
