import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { humanizeTimestamp } from 'reports/utils/formatters';

import * as mod from 'reports/models/module';
import * as auth from 'reports/modules/auth';

import { Current, Power, Voltage } from 'reports/components/core/numbers';
import { CommonColumns } from 'reports/components/library/helpers';
import { ColWidth, SortType } from 'reports/components/core/tables/DataTable';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';
import { FavoriteStar } from 'reports/components/core/controls';
import { addPromiseToastsToggle } from 'reports/components/toasts';

type Props = {
    navigateToModule: (module: mod.Module) => void;
};

const ModulesList = React.memo(({ navigateToModule }: Props) => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const dispatch = useDispatch();
    const toggleFavorite = ({ module_id, favorite }: mod.Module) =>
        dispatch(mod.api.save({ module_id, favorite: !favorite }));
    return (
        <ResourceTable<mod.Module>
            columns={[
                {
                    colWidth: ColWidth.SMALL,
                    headerText: 'Favorite',
                    renderCell: (module) => (
                        <FavoriteStar
                            empty={!module.favorite}
                            onClick={(e) => {
                                e.stopPropagation();
                                addPromiseToastsToggle(
                                    'favorite',
                                    toggleFavorite(module),
                                    'module',
                                    module.name,
                                    module.favorite,
                                );
                            }}
                        />
                    ),
                },
                CommonColumns.name(),
                {
                    sort: { name: 'manufacturer', type: SortType.ALPHA },
                    headerText: 'Manufacturer',
                    renderCell: (module) => <>{module.manufacturer}</>,
                },
                {
                    sort: { name: 'power' },
                    headerText: 'Power',
                    renderCell: (module) => <Power value={module.power} />,
                },
                {
                    sort: {
                        name: 'cell_technology_name',
                        type: SortType.ALPHA,
                    },
                    headerText: 'Technology',
                    renderCell: (module) => <>{mod.CellTechnologyTypes[module.cell_technology_name]}</>,
                },
                {
                    headerText: 'Vmp, Imp',
                    renderCell: (module) => (
                        <span>
                            <Voltage value={module.defaultCharacterization().v_mp} />
                            {', '}
                            <Current value={module.defaultCharacterization().i_mp} />
                        </span>
                    ),
                },
                {
                    sort: { name: 'last_update' },
                    headerText: 'Last Modified',
                    renderCell: (module) => <>{module.last_update ? humanizeTimestamp(module.last_update) : '-'}</>,
                },
                CommonColumns.team(),
                CommonColumns.publicCol(user),
            ]}
            onRowClick={(module) => navigateToModule(module)}
        />
    );
});

export { ModulesList };
