/**
 * Variation of SaveCancelButtons that automatically call onClose after onSave or onCancel have been called.
 * Ideal use case: components that have both a "view" mode and "edit" mode, such that saving/canceling in edit
 * mode takes you back to view mode.
 */
import * as React from 'react';

import SaveCancelButtons from 'reports/components/core/controls/SaveCancelButtons';

interface IOwnProps {
    onSave: () => Promise<any>;
    onCancel: () => any;
    onClose: () => any; // gets run after onSave or onCancel
    hasChanges: boolean;
    toastConfig?: { description?: string };
    style?: React.CSSProperties;
    disabled?: boolean;
}

export class EditModeSaveCancelButtons extends React.PureComponent<IOwnProps> {
    saveThenClose = async () => {
        const { onSave, onClose } = this.props;

        const res = await onSave();
        onClose();
        return res;
    };

    cancelThenClose = async () => {
        const { onCancel, onClose } = this.props;

        await onCancel();
        onClose();
    };

    render() {
        const { hasChanges, toastConfig, style, disabled } = this.props;
        return (
            <SaveCancelButtons
                onSave={this.saveThenClose}
                onCancel={this.cancelThenClose}
                hasChanges={hasChanges}
                toastConfig={toastConfig}
                style={style}
                disabled={disabled}
                allowEmptyCancel={true}
            />
        );
    }
}

export default EditModeSaveCancelButtons;
