import * as React from 'react';

import { Classes, Divider } from '@blueprintjs/core';

import { Section2 } from 'reports/components/core/containers';

import { FormConsumer } from 'reports/components/forms/types';
import PercentInput from 'reports/components/forms/inputs/experimental/PercentInput';
import FormPercentInput from 'reports/components/forms/inputs/experimental/FormPercentInput';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const soilingMonthPaths = [
    'jan_soiling',
    'feb_soiling',
    'mar_soiling',
    'apr_soiling',
    'may_soiling',
    'jun_soiling',
    'jul_soiling',
    'aug_soiling',
    'sep_soiling',
    'oct_soiling',
    'nov_soiling',
    'dec_soiling',
];

export const SOILING_MONTHS = [
    {
        path: 'jan_soiling',
        monthName: 'Jan',
    },
    {
        path: 'feb_soiling',
        monthName: 'Feb',
    },
    {
        path: 'mar_soiling',
        monthName: 'Mar',
    },
    {
        path: 'apr_soiling',
        monthName: 'Apr',
    },
    {
        path: 'may_soiling',
        monthName: 'May',
    },
    {
        path: 'jun_soiling',
        monthName: 'Jun',
    },
    {
        path: 'jul_soiling',
        monthName: 'Jul',
    },
    {
        path: 'aug_soiling',
        monthName: 'Aug',
    },
    {
        path: 'sep_soiling',
        monthName: 'Sep',
    },
    {
        path: 'oct_soiling',
        monthName: 'Oct',
    },
    {
        path: 'nov_soiling',
        monthName: 'Nov',
    },
    {
        path: 'dec_soiling',
        monthName: 'Dec',
    },
];

export const SoilingInputGroup = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 8px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    && .${Classes.INPUT_GROUP} .${Classes.INPUT} {
        width: 100%;
    }
`;

export const SoilingEditorContainer = styled.div`
    display: flex;
`;

export const PaddedDivider = styled(Divider)`
    margin: 0 12px;
`;

interface ISoilingEditorState {
    allValue?: number;
}

/**
 * Editor for soiling fields (one for each month) in condition sets. Has a special "All" field that sets the
 * others to the same value. This assumes it exists with a FormContext given by FormProvider, most likely within
 * a Form component.
 */
class ConditionSetSoilingEdit extends React.Component<{}, ISoilingEditorState> {
    constructor(props = {}) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Section2 title="Soiling" subtitle="Derates to irradiance due to module soiling or other factors">
                <SoilingEditorContainer>
                    <SoilingInputGroup>
                        <label>Set All</label>
                        <FormConsumer>
                            {({ updateValues, submitting }) => (
                                <PercentInput
                                    value={this.state.allValue || ''}
                                    onChange={(val) => this.onAllChange(val, updateValues)}
                                    disabled={submitting}
                                />
                            )}
                        </FormConsumer>
                    </SoilingInputGroup>
                    <PaddedDivider />
                    {SOILING_MONTHS.map((month) => (
                        <SoilingInputGroup key={month.path}>
                            <label>{month.monthName}</label>
                            <FormPercentInput path={month.path} />
                        </SoilingInputGroup>
                    ))}
                </SoilingEditorContainer>
            </Section2>
        );
    }

    onAllChange(val, updateValues) {
        this.setState((state) => ({ ...state, allValue: val }));

        const allSetSoiling = {};
        for (const monthPath of soilingMonthPaths) {
            allSetSoiling[monthPath] = val;
        }
        updateValues(allSetSoiling, true);
    }
}

export default ConditionSetSoilingEdit;
