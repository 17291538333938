import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Tag } from '@blueprintjs/core';

import { Team, api } from 'reports/models/team';
import { getUserStatusIntent, User } from 'reports/models/user';

import { Section2 } from 'reports/components/core/containers';
import { FormData as TrialFormData, TrialForm } from 'reports/modules/settings/admin/components/TrialForm';
import { ContractConversionButton } from 'reports/modules/settings/admin/components/ContractConversionButton';

import { addPromiseToasts } from 'reports/modules/Toaster';

import * as teamLimitsAndUsage from 'reports/models/team_usage';
import { getClassicUrl } from 'reports/utils/url';

interface Props {
    team: Team;
    user: User;
}

const TrialView = ({ team, user }: Props) => {
    const dispatch = useDispatch();
    const updateAllowance = (formData: TrialFormData) =>
        dispatch(
            api.updateTrialSettings({ trial_project_limit: formData.trial_project_limit }, { team_id: team.team_id }),
        );
    const getTeamProjectLimit = (team: Team) => dispatch(teamLimitsAndUsage.api.get({ team_id: team.team_id }));

    const onSubmit = async (formData: TrialFormData) => {
        await addPromiseToasts(updateAllowance(formData), {
            initial: 'Updating trial subscription',
            onSuccess: () => {
                getTeamProjectLimit(team);
                return 'Trial subscription updated';
            },
            onCatch: 'Error updating trial subscription',
        });
    };

    const statusTag = (
        <Tag intent={getUserStatusIntent(user.status)} minimal={true}>
            {user.isExpired() ? 'Expired' : 'Active'}
        </Tag>
    );
    const sectionTitle = <div>Trial subscription {statusTag}</div>;

    return (
        <Section2
            title={sectionTitle}
            contextEl={<ContractConversionButton isConversion={true} userEmail={user.email} />}
        >
            <TrialForm
                onSubmit={onSubmit}
                team={team}
                trialExtensionUrl={getClassicUrl('admin/extensions')}
                user={user}
            />
        </Section2>
    );
};

export default TrialView;
