import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'reports/store';

import * as auth from 'reports/modules/auth';

import { METER_IN_FEET } from 'reports/utils/constants';
import UnitsInput from 'reports/components/forms/inputs/experimental/UnitsInput';

const DISTANCE_UNIT_CONVERSIONS = {
    m: 1.0,
    ft: METER_IN_FEET,
};

interface IOwnProps {
    value: number;
    placeholder: string;
    precision: number;
    onChange: (val: any) => void;
    disabled: boolean;
    width?: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;

/**
 * An input for a numeric distance field that displays distance units on the right side of the input.
 * This input can take into account the user's preferred units (in user.preferences.units).
 * The API for this is not fully baked, hence it's in the /experimental dir.
 */
const _UserDistanceInput: React.FC<IProps> = ({
    user,
    value,
    placeholder = '',
    precision = 5,
    onChange,
    disabled,
    width,
}) => {
    const unit = user ? user.preferences.units.distance : 'm';
    return (
        <UnitsInput
            value={value}
            placeholder={placeholder}
            precision={precision}
            onChange={onChange}
            disabled={disabled}
            unitTagText={unit}
            conversion={{
                toBaseUnit: (val: number) => val / DISTANCE_UNIT_CONVERSIONS[unit],
                toDisplayUnit: (val: number) => val * DISTANCE_UNIT_CONVERSIONS[unit],
            }}
            width={width}
        />
    );
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state),
});

const UserDistanceInput = connect(mapStateToProps)(_UserDistanceInput);

export default UserDistanceInput;
