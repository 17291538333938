import * as React from 'react';

import { Classes, HTMLTable } from '@blueprintjs/core';

import { Card, CardSection, Flex } from 'reports/components/core/containers';
import { FormGroup } from 'reports/components/core/forms';

import FormTextInput from 'reports/components/forms/inputs/experimental/FormTextInput';
import FormCheckboxInput from 'reports/components/forms/inputs/experimental/FormCheckboxInput';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const SummaryCard = styled(Card)`
    max-width: 425px;
    margin: 0;
`;

class RequiredFieldError extends Error {
    errors?: object;

    constructor(errors, ...params) {
        super(...params);
        this.errors = errors;
        this.name = 'RequiredFieldError';
    }
}

const ViewContainer = styled(Flex.Container)`
    margin: 20px 0;

    > *:not(:last-child) {
        margin-right: 30px;
    }
`;

// The padding-bottom is to account for our chat widget on the bottom right
// of the screen.  We want users to be able to scroll enough to where
// the widget isn't covering important UI elements.
const ContentContainer = styled.div<{ details?: boolean }>`
    flex: 2;
    width: 100%;
    max-width: ${(props) => (props.details ? '700px' : '900px')};
    padding-bottom: 100px;
`;

const CardContainer = styled.div`
    flex: 1;
    margin-top: 20px;
`;

const SettingsPane = ({ content, card }: { content: JSX.Element; card?: React.ReactNode }) => (
    <ViewContainer>
        <ContentContainer>{content}</ContentContainer>
        {card && <CardContainer>{card}</CardContainer>}
    </ViewContainer>
);

const DetailsCard = ({
    title,
    style,
    children,
}: React.PropsWithChildren<{ title: string; style?: React.CSSProperties }>) => (
    <SummaryCard style={style}>
        <CardSection title={title}>{children}</CardSection>
    </SummaryCard>
);

const DetailLine = styled.div`
    white-space: nowrap;
`;

const DetailsTable = styled(HTMLTable)`
    th {
        white-space: nowrap;
    }

    // Blueprint override
    &&& th {
        box-shadow: none;
        padding: 10px 0;
    }
    &&& td {
        box-shadow: none;
        padding: 10px 5;
    }
`;

// TODO: Things used across both User and Team Settings that need to be standardized or cleaned up.

const FixedWidthTextInput = styled(FormTextInput)`
    width: 400px;
`;

const StyledCheckbox = styled(FormCheckboxInput)`
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: normal;
`;

const DescriptionFormGroup = ({ desc, title, children }: React.PropsWithChildren<{ desc: string; title?: string }>) => (
    <div style={{ marginTop: 5 }}>
        {title && <b>{title}</b>}
        <p style={{ marginTop: 5, width: 450 }}>{desc}</p>
        {children}
    </div>
);

// TODO: Things used exclusively in Team Settings that need to be cleaned up.

const SectionContainer = styled(Flex.Container)<{ disabled?: boolean }>`
    margin-bottom: 10px;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const SectionContainerColumn = styled(Flex.ContainerV)`
    margin-bottom: 10px;
`;

const CategoryTitle = styled.span`
    font-weight: bold;
    margin-top: 5px;
    width: 160px;
`;

const SubSectionContainer = styled(Flex.ContainerV)`
    flex: 1;
    ${FormGroup} {
        .${Classes.FORM_HELPER_TEXT} {
            margin-top: -10px;
        }
        margin-bottom: 7px;
    }
    label {
        font-weight: normal;
    }
`;

const HelperText = styled.div<{ bold?: boolean; large?: boolean; small?: boolean }>`
    color: #5c7080;
    font-size: 12px;
    font-size: ${({ small }) => (small ? '10px' : '')};
    font-size: ${({ large }) => (large ? '14px' : '')};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const ControlLabel = styled.div`
    max-width: 550px;
`;

const ControlPrimaryText = styled.div`
    font-weight: normal;
`;

const ControlContainer = styled.div`
    flex-grow: 1;
    text-align: right;
`;

const pluralizeText = (name: string, quantity: number, withQuantity = true): string => {
    if (withQuantity) {
        return quantity > 1 ? `${quantity} ${name}s` : `${quantity} ${name}`;
    }

    return quantity > 1 ? `${name}s` : `${name}`;
};

export {
    // Main view
    SettingsPane,
    // Form section layout
    SectionContainer,
    SectionContainerColumn,
    SubSectionContainer,
    CategoryTitle,
    // Form fields
    DescriptionFormGroup,
    FixedWidthTextInput,
    StyledCheckbox,
    ControlLabel,
    ControlPrimaryText,
    ControlContainer,
    RequiredFieldError,
    // Team/User account details
    SummaryCard,
    DetailsCard,
    DetailLine,
    DetailsTable,
    // Misc common
    HelperText,
    pluralizeText,
};
