import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as analytics from 'reports/analytics';

import { IconNames } from '@blueprintjs/icons';

import { CreateButtonDropdown } from 'reports/components/core/controls/CreateButtonDropdown';
import { LibraryMain } from 'reports/components/library/main/components';

import FilterSelect from 'reports/components/library/main/components/search/FilterSelect';

import { ProfilesList } from 'reports/modules/profile/components/ProfilesList';
import * as auth from 'reports/modules/auth';
import * as pro from 'reports/models/profile';
import { createUniqueDescription } from 'reports/utils/helpers';

import { IAppState } from 'reports/store';

interface Props {
    inlineControls?: boolean;
    navigateToProfile: (profileId: number) => void;
    navigateToProfilePreview: (profileId: number) => void;
    preview?: React.ReactNode;
    profileId?: number;
}

const PROFILE_TYPES: pro.ProfileType[] = ['project', 'scenario', 'electrical', 'mechanical'];
const FILTERS = [
    { name: 'type' as keyof pro.Profile, text: `Project`, value: 'project' },
    { name: 'type' as keyof pro.Profile, text: `Conditions`, value: 'scenario' },
    { name: 'type' as keyof pro.Profile, text: `Electrical`, value: 'electrical' },
    { name: 'type' as keyof pro.Profile, text: `Mechanical`, value: 'mechanical' },
];

const ProfileLibrary = React.memo(
    ({ inlineControls, navigateToProfile, navigateToProfilePreview, preview, profileId }: Props) => {
        const dispatch = useDispatch();
        const user = useSelector((state) => auth.selectors.getUser(state)!);

        const refreshItem = useSelector(
            (state) => (item: pro.Profile) =>
                pro.selectors.byObject(state as IAppState, item, { creator: true, team: true }) || item,
        );

        const getProfiles = (args: pro.ProfileGetOpts) => dispatch(pro.api.index(args));
        const createProfile = async (newProfile: pro.ProfileForm) => dispatch(await pro.api.create(newProfile));
        const getProfileDefaults = async () => dispatch(await pro.api.defaults());

        const createDefaultProfile = async (profileType: pro.ProfileType) => {
            const defaultProfileData = await getProfileDefaults();
            const baseProfileName = `New ${pro.ProfileTypes[profileType]} Profile`;
            const matchingProfiles = await getProfiles({ q: baseProfileName });

            const newProfile = await createProfile({
                type: profileType,
                name: createUniqueDescription(
                    baseProfileName,
                    matchingProfiles.map((p) => p.name),
                    true,
                ),
                description: '',
                data: defaultProfileData[profileType],
                public: false,
            });

            analytics.track('profile.new', { profile_type: profileType });
            navigateToProfile(newProfile.profile_id);
        };

        const canViewFinancials = user.hasFinancialsAccess();
        const items = canViewFinancials ? PROFILE_TYPES.concat('financial') : PROFILE_TYPES;
        const filters = canViewFinancials
            ? FILTERS.concat({
                  name: 'type',
                  text: `Financial`,
                  value: 'financial',
              })
            : FILTERS;

        const quickFilters = [
            {
                name: 'bookmarked',
                icon: IconNames.STAR,
                text: 'Favorited',
            },
            {
                name: 'team_id',
                icon: IconNames.PEOPLE,
                text: `Only Show My Team`,
                value: user.team.team_id,
            },
        ];

        return (
            <LibraryMain
                resourceIdName="profile_id"
                loadItems={getProfiles}
                refreshItem={refreshItem}
                inlineControls={inlineControls}
                views={[
                    {
                        view: <ProfilesList navigateToProfilePreview={navigateToProfilePreview} />,
                        icon: IconNames.LIST,
                        id: 'profile-list',
                    },
                ]}
                // @ts-ignore
                quickFilters={inlineControls ? quickFilters : [...quickFilters, ...filters]}
                id="profiles-views"
                preview={preview}
                contextBarControls={
                    <CreateButtonDropdown<pro.ProfileType>
                        createText="New Profile"
                        items={items}
                        itemRenderer={(profileType) => pro.ProfileTypes[profileType]}
                        onItemSelect={(profileType) => createDefaultProfile(profileType)}
                    />
                }
                extraFilterControls={inlineControls ? <ProfileTypeSelect items={items} /> : undefined}
                selectedItemId={profileId}
            />
        );
    },
);

const ProfileTypeSelect = ({ items }: { items: string[] }) => (
    <FilterSelect
        label="Filter by Profile Type"
        items={items}
        emptyValue={'Show All'}
        filterName={'type'}
        buttonProps={{ style: { minWidth: '220px' } }}
    />
);

export { ProfileLibrary };
