import * as React from 'react';

import { PlanCard } from './PlanCard';
import { PrimaryButton } from 'reports/components/core/controls';
import { Price } from 'reports/models/stripe/price';

import FormField from 'reports/components/forms/FormField';

interface Props {
    isSelected: boolean;
    isCurrentSelection?: boolean;
    cost: { amount: string; description?: string };
    description: React.ReactNode;
    features: { title: string; items: string[] };
    onSelect?: () => void;
    path: string;
    price: Price;
    subtitle: string;
    title: string;
    disabled: boolean;
    canSelect: boolean;
}

const FormBarePlanCard = ({
    isSelected,
    isCurrentSelection,
    onSelect,
    path,
    price,
    disabled,
    canSelect,
    ...props
}: Props) => {
    return (
        <FormField path={path}>
            {({ form, onChange }) => (
                <PlanCard
                    button={
                        canSelect && (
                            <PrimaryButton
                                active={isSelected}
                                disabled={form.submitting || isCurrentSelection || disabled}
                                onClick={() => onChange(price)}
                            >
                                {isCurrentSelection ? 'Current Plan ' : 'Select Plan'}
                            </PrimaryButton>
                        )
                    }
                    isSelected={!isCurrentSelection && isSelected && !disabled}
                    onClick={() => {
                        if (canSelect && !isCurrentSelection && !disabled) {
                            onSelect && onSelect();
                            onChange(price);
                        }
                    }}
                    interactive={canSelect && !isCurrentSelection && !disabled}
                    {...props}
                />
            )}
        </FormField>
    );
};

export { FormBarePlanCard };
