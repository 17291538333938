import * as React from 'react';

import { ButtonGroup, Popover, Position, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { PrimaryButton } from 'reports/components/core/controls';

import { addPromiseToasts } from 'reports/modules/Toaster';

export interface ISaveAsNewOpts {
    saveAsNew: () => Promise<any>;
    text: string;
}

interface IOwnProps {
    save: () => Promise<any>;
    // "Save As" button in the dropdown is optional. If you don't want one, leave `saveAsNewOptions` prop undefined.
    saveAsNewOptions?: ISaveAsNewOpts;
    saveEnabled: boolean;
    cancel: () => any;
    addToast?: boolean;
    toastDescr?: string;
}

const SaveDropdown: React.FC<IOwnProps> = (props) => {
    const { save, saveAsNewOptions, saveEnabled, cancel, addToast = true, toastDescr = 'changes' } = props;

    const onSave = async () => {
        if (addToast) {
            await addPromiseToasts(save(), {
                initial: `Saving ${toastDescr}`,
                onCatch: (err) => (typeof err === 'string' ? err : `Error saving ${toastDescr}`),
                onSuccess: `Successfully saved ${toastDescr}`,
            });
        } else {
            await save();
        }
    };

    const onSaveAsNew = async () => {
        if (!saveAsNewOptions) {
            return;
        }
        if (addToast) {
            await addPromiseToasts(saveAsNewOptions.saveAsNew(), {
                initial: `Saving copy of ${toastDescr}`,
                onCatch: (err) => (typeof err === 'string' ? err : `Error saving copy of ${toastDescr}`),
                onSuccess: `Successfully saved copy of ${toastDescr}`,
            });
        } else {
            await saveAsNewOptions.saveAsNew();
        }
    };

    const SavePopoverMenu = (
        <Menu>
            {saveAsNewOptions ? (
                <>
                    <MenuItem icon={IconNames.DUPLICATE} text={saveAsNewOptions.text} onClick={onSaveAsNew} />
                    <MenuDivider />
                </>
            ) : null}
            <MenuItem icon={IconNames.CROSS} text="Cancel" onClick={cancel} />
        </Menu>
    );

    return (
        <ButtonGroup>
            <PrimaryButton text="Save" icon={IconNames.FLOPPY_DISK} onClick={onSave} disabled={!saveEnabled} />
            <Popover
                content={SavePopoverMenu}
                modifiers={{ preventOverflow: { enabled: false } }}
                position={Position.BOTTOM_RIGHT}
            >
                <PrimaryButton icon={IconNames.CARET_DOWN} style={{ marginLeft: 0 }} />
            </Popover>
        </ButtonGroup>
    );
};

export default SaveDropdown;
