// This file is intended to be a proof of concept. If you need to make changes
// in here, please ensure that you are working with the MBU epic owner.
import { useCallback, useMemo } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as DS from '@aurorasolar/ds';

import { selectors as authSelectors } from 'reports/modules/auth';
import { IAppState } from 'reports/store';

const LIBRARIES_ROUTES = [
    '/libraries',
    '/profiles',
    '/modules',
    '/components',
    '/weather_sources',
    '/wires',
    '/reports',
    '/financial-models',
    '/utility-rates',
    '/incentives',
];

// Turn LIBRARIES_ROUTES above into type similar to SettingsPath.
type LibrariesPath = (typeof LIBRARIES_ROUTES)[number];

type SettingsPath =
    | '/settings/hs-admin/subscription'
    | '/settings/hs-admin/merge-teams'
    | '/settings/hs-admin/team-features'
    | '/settings/hs-admin/user-features'
    | '/settings/team'
    | '/settings/team/manage'
    | '/settings/team/billing'
    | '/settings/team/integrations'
    | '/settings/team/themes'
    | '/settings/user'
    | '/settings/user/changepassword'
    | '/settings/user/preferences';

type Path = LibrariesPath | SettingsPath;

const useNavigationItems = () => {
    const user = useSelector((state: IAppState) => authSelectors.getUser(state)!);
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const currentPathName = location.pathname;

    const routeIncludes = useCallback((pathName: Path) => currentPathName?.includes(pathName), [currentPathName]);

    const routeEndsWith = useCallback((pathName: Path) => currentPathName?.endsWith(pathName), [currentPathName]);

    const librariesAreActive = useCallback(
        () => LIBRARIES_ROUTES.some((subpath) => routeIncludes(subpath as Path)),
        [currentPathName],
    );

    const settingsDisabled = !user.role.can_view_settings;

    const contextualNavigationGroups = () => {
        if (routeIncludes('/projects') && !routeEndsWith('/projects')) {
            const projectId = params.projectId || null;

            return [
                {
                    items: [
                        {
                            action: () => navigate(generatePath('projects/:projectId', { projectId })),
                            active: !!projectId && location.pathname === `/projects/${projectId}`,
                            activeIcon: DS.IconDashboardFill,
                            children: 'Overview',
                            icon: DS.IconDashboard,
                        },
                        {
                            action: () => navigate('/designer'),
                            active: routeEndsWith('/designer'),
                            activeIcon: DS.IconDesignMode,
                            children: 'Designer',
                            icon: DS.IconDesignMode,
                        },
                        {
                            action: () => navigate('/conditions'),
                            active: routeEndsWith('/conditions'),
                            activeIcon: DS.IconShade,
                            children: 'Conditions',
                            icon: DS.IconShade,
                        },
                        {
                            action: () => navigate(generatePath('projects/:projectId/simulation', { projectId })),
                            active: routeEndsWith('/simulation'),
                            activeIcon: DS.IconPlugFill,
                            children: 'Simulations',
                            icon: DS.IconPlug,
                        },
                        {
                            action: () => navigate('/financials'),
                            active: routeEndsWith('/financials'),
                            activeIcon: DS.IconDollarCircleFill,
                            children: 'Financials',
                            icon: DS.IconDollarCircle,
                        },
                        {
                            action: () => navigate('/sharing'),
                            active: routeEndsWith('/sharing'),
                            activeIcon: DS.IconUserFill,
                            children: 'Sharing',
                            icon: DS.IconUser,
                        },
                        {
                            action: () => navigate('/reports'),
                            active: routeEndsWith('/reports'),
                            activeIcon: DS.IconPaperLinesFill,
                            children: 'Reports & proposals',
                            icon: DS.IconPaperLines,
                        },
                    ],
                },
            ];
        }

        if (librariesAreActive()) {
            return [
                {
                    title: 'Libraries',
                    items: [
                        {
                            action: () => navigate('/profiles'),
                            active: routeIncludes('/profiles'),
                            children: 'Profiles',
                        },
                        {
                            action: () => navigate('/modules'),
                            active: routeIncludes('/modules'),
                            children: 'Modules',
                        },
                        {
                            action: () => navigate('/components'),
                            active: routeIncludes('/components'),
                            children: 'Inverters & Optimizers',
                        },
                        {
                            action: () => navigate('/weather_sources'),
                            active: routeEndsWith('/weather_sources'),
                            children: 'Weather Sources',
                        },
                        {
                            action: () => navigate('/wires'),
                            active: routeEndsWith('/wires'),
                            children: 'Wires',
                        },
                    ],
                },
                {
                    title: 'Reports and proposals',
                    items: [
                        {
                            action: () =>
                                navigate('/reports', { state: { referrer: 'report_templates_library_sidebar' } }),
                            children: 'Report templates',
                        },
                    ],
                },
                {
                    title: 'Quoting',
                    items: [
                        {
                            action: () =>
                                navigate('/financial-models', {
                                    state: { referrer: 'financial_models_library_sidebar' },
                                }),
                            active: routeIncludes('/financial-models'),
                            children: 'Financial models',
                        },
                        {
                            action: () =>
                                navigate('/incentives', { state: { referrer: 'incentives_library_sidebar' } }),
                            active: routeIncludes('/incentives'),
                            children: 'Incentives',
                        },
                        {
                            action: () =>
                                navigate('/utility-rates', { state: { referrer: 'utility_rates_library_sidebar' } }),
                            active: routeIncludes('/utility-rates'),
                            children: 'Utility Rates',
                        },
                    ],
                },
            ];
        }

        if (routeIncludes('/settings')) {
            const settingsRoutes = [
                {
                    title: 'Account',
                    items: [
                        {
                            action: () => navigate('/settings/user'),
                            active: routeEndsWith('/settings/user') || routeEndsWith('/settings/user/changepassword'),
                            children: 'Account Overview',
                            disabled: settingsDisabled,
                        },
                        {
                            action: () => navigate('/settings/user/preferences'),
                            active: routeEndsWith('/settings/user/preferences'),
                            children: 'Preferences',
                            disabled: settingsDisabled,
                        },
                    ],
                },
                {
                    title: 'Team',
                    items: [
                        {
                            action: () => navigate('/settings/team'),
                            active: routeEndsWith('/settings/team'),
                            children: 'Team Overview',
                            disabled: settingsDisabled,
                        },
                        {
                            action: () => navigate('/settings/team/manage'),
                            active: routeEndsWith('/settings/team/manage'),
                            children: 'Manage Team',
                        },
                        {
                            action: () => navigate('/settings/team/integrations'),
                            active: routeEndsWith('/settings/team/integrations'),
                            children: 'Integrations',
                            disabled: settingsDisabled,
                        },

                        {
                            action: () => navigate('/settings/team/themes'),
                            active: routeEndsWith('/settings/team/themes'),
                            children: 'Themes',
                            disabled: settingsDisabled,
                        },
                        {
                            action: () => navigate('/settings/team/billing'),
                            active: routeEndsWith('/settings/team/billing'),
                            children: 'Billing & Usage',
                        },
                    ],
                },
            ];

            const adminRoutes = [
                {
                    title: 'HS Admin',
                    items: [
                        {
                            action: () => navigate('/settings/hs-admin/subscription'),
                            active: routeEndsWith('/settings/hs-admin/subscription'),
                            children: 'Subscription',
                        },
                        {
                            action: () => navigate('/settings/hs-admin/merge-teams'),
                            active: routeEndsWith('/settings/hs-admin/merge-teams'),
                            children: 'Merge Teams',
                        },

                        {
                            action: () => navigate('/settings/hs-admin/team-features'),
                            active: routeEndsWith('/settings/hs-admin/team-features'),
                            children: 'Team Features',
                        },
                        {
                            action: () => navigate('/settings/hs-admin/user-features'),
                            active: routeEndsWith('/settings/hs-admin/user-features'),
                            children: 'User Features',
                        },
                    ],
                },
            ];

            return user && user.is_admin ? [...settingsRoutes, ...adminRoutes] : settingsRoutes;
        }

        return [];
    };

    const pageNavigation = useMemo<DS.INavigationItemGroup[]>(
        () => [
            {
                items: [
                    {
                        action: () => navigate('/projects'),
                        active: routeIncludes('/projects'),
                        activeIcon: DS.IconProjectFill,
                        children: 'Projects',
                        icon: DS.IconProject,
                    },
                    {
                        action: () => navigate('/profiles'),
                        active: librariesAreActive(),
                        activeIcon: DS.IconDatabaseFill,
                        children: 'Libraries',
                        icon: DS.IconDatabase,
                    },
                    {
                        action: () => navigate('/settings/team'),
                        active: routeIncludes('/settings'),
                        activeIcon: DS.IconGearFill,
                        children: 'Settings',
                        icon: DS.IconGear,
                    },
                ],
            },
        ],
        [currentPathName, routeIncludes],
    );

    const contextualNavigation = useMemo(
        () => ({
            groups: contextualNavigationGroups(),
        }),
        [currentPathName],
    );

    return { contextualNavigation, pageNavigation };
};

export { useNavigationItems };
