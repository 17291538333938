import { noop } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { bindActions } from 'reports/utils/redux';
import { RouterStateLock } from 'reports/utils/router';

import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';
import * as horiz from 'reports/models/horizon_profile';
import * as usr from 'reports/models/user';

import { ContextBarControls } from 'reports/components/ContextBar';
import { EditModeSaveCancelButtons } from 'reports/components/core/controls';

import { Flex } from 'reports/components/core/containers';
import { Form, handleRequestException } from 'reports/components/forms';
import { FormConsumer } from 'reports/components/forms/types';

import { actions as projActions } from 'reports/modules/project';
import { selectors as authSelectors } from 'reports/modules/auth';

import ConditionSetWeatherEdit from './ConditionSetWeatherEdit';
import ConditionSetHorizonEdit from './ConditionSetHorizonEdit';
import ConditionSetComponentCharsEdit from './ConditionSetComponentCharsEdit';

import {
    ConditionSetSoilingEdit,
    ConditionSetCellTempEdit,
    ConditionSetAcLossesEdit,
    ConditionSetAdvancedEdit,
    ConditionSetMismatchEdit,
    ConditionSetTrackerEdit,
} from 'reports/modules/condition_sets/components/CondSetEditComponents';
import { makeCellTempParams } from 'reports/modules/condition_sets/components/common';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { surfaceCellTempErrors } from 'reports/modules/profile/components/ProfileEditor';

const FormActionButtons = ({ onClose = noop }) => (
    <FormConsumer>
        {({ submitForm, dirty, submitting, clearForm }) => (
            <EditModeSaveCancelButtons
                onSave={submitForm}
                hasChanges={dirty}
                onCancel={clearForm}
                onClose={onClose}
                disabled={submitting}
            />
        )}
    </FormConsumer>
);

interface IConditionSetEditProps {
    scenario: scen.Scenario;
    project: proj.Project;
    horizons: horiz.HorizonProfile[];
    user: usr.User;
    navigateTo: (name: string, params: any) => any;
    saveScenario: (scenario: scen.Scenario) => Promise<any>;
    setPrimaryScenario: (project: proj.Project, scenario: scen.Scenario) => Promise<any>;
}

class ConditionSetEdit extends React.Component<IConditionSetEditProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const { project, scenario, horizons, user } = this.props;

        return (
            <Form
                onSubmit={async (scenario) => {
                    return await addPromiseToasts(
                        this.props
                            .saveScenario(this.postProcess(scenario))
                            .then(() => this.props.setPrimaryScenario(project, scenario)),
                        {
                            initial: 'Saving condition set...',
                            onSuccess: 'Successfully saved condition set.',
                            onCatch: 'Error saving condition set.',
                        },
                    );
                }}
                baseValue={this.preProcess(scenario)}
                exceptionHandler={this.customExceptionHandler}
            >
                {({ dirty, submitForm, clearForm }) => (
                    <>
                        <RouterStateLock
                            title="Save condition set changes?"
                            prompt={`You have unsaved changes on ${scenario.description || 'this condition set'}.`}
                            cancellable={true}
                            showExitPrompt={dirty}
                            onSave={submitForm}
                            onDontSave={clearForm}
                        />
                        <ContextBarControls>
                            <FormActionButtons onClose={() => this.goToView()} />
                        </ContextBarControls>
                        <Flex.ContainerV>
                            <ConditionSetWeatherEdit scenario={scenario} />
                            <ConditionSetHorizonEdit horizons={horizons} />
                            <ConditionSetSoilingEdit />
                            <ConditionSetCellTempEdit toggleBehavior="condition_sets" />
                            <ConditionSetComponentCharsEdit scenario={scenario} />
                            <ConditionSetMismatchEdit />
                            <ConditionSetAdvancedEdit />
                            <ConditionSetAcLossesEdit />
                            {user.hasSingleAxisTrackersAccess() && <ConditionSetTrackerEdit />}
                        </Flex.ContainerV>
                    </>
                )}
            </Form>
        );
    }

    preProcess(scenario) {
        return {
            ...scenario,
            cell_temp_parameters: makeCellTempParams(scenario),
        };
    }

    postProcess(scenario) {
        return {
            ...scenario,
            cell_temp_parameters: Object.values(scenario.cell_temp_parameters),
        };
    }

    customExceptionHandler = (exc) => {
        const { fieldErrors, formErrors } = handleRequestException(exc);
        surfaceCellTempErrors(fieldErrors?.cell_temp_parameters);
        return { fieldErrors, formErrors };
    };

    goToView() {
        this.props.navigateTo('app.projects.project.conditions.condition', {
            scenarioId: this.props.scenario.scenario_id,
            projectId: this.props.project.project_id,
        });
    }
}

const mapStateToProps = (state, ownProps: { project: proj.Project }) => ({
    horizons: horiz.selectors.all(state, {
        filter: (obj) => obj.project_id === ownProps.project.project_id,
    }),
    user: authSelectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
    saveScenario: (scenario: scen.Scenario) => scen.api.save(scenario),
    setPrimaryScenario: (project, scenario) => projActions.setPrimaryScenario(project, scenario),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ConditionSetEdit);
