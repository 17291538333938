/* tslint:disable:variable-name */
/* tslint:disable:no-increment-decrement*/

import _ from 'lodash';

import {
    getModuleCategory,
    ModuleCategory,
    MODULE_CATEGORIES,
    MODULE_REGISTRY,
} from 'reports/modules/financials/model/modules';
import { Node, NodeType } from './node';
import { FinancialPipelineTree } from './pipeline';

export function parentChildIndex(index: number[]) {
    const parentIndex = index.slice();
    const childIndex = parentIndex.pop();
    return { parentIndex, childIndex };
}

export function incrementChildIndex(index: number[]) {
    const parentIndex = index.slice();
    parentIndex[0] += 1;
    return parentIndex;
}

export function paramKey(nidx: number[], pidx: number) {
    return `${nidx.toString()}!${pidx}`;
}

// Note: use pipeline/node:Node.getPipelineParameters() in favor of this helper.
export function pipelineModuleData(node: Node) {
    if (node.node_type !== NodeType.Basic) {
        throw new Error('invalid node type');
    }

    const metaParams = node.module.parameters.map((i) => {
        const setting = node.parameter_settings[i.path];
        if (setting) return _.merge({}, setting, i);
        return _.merge({ value: i.default }, i);
    });

    return {
        node,
        metaParams,
        description: node.module.description,
        required: node.module.required,
    };
}

export function pipelineDefaults() {
    const treeData = {
        node_type: NodeType.Root,
        children: [
            {
                node_type: NodeType.Basic,
                module_key: 'BasicInitializeTimeSeries',
            },
            {
                node_type: NodeType.Basic,
                module_key: 'BasicProductionSimulated',
            },
            { node_type: NodeType.Basic, module_key: 'BasicConsumptionHourly' },
            { node_type: NodeType.Basic, module_key: 'BasicDegradationSimple' },
            { node_type: NodeType.Basic, module_key: 'BasicDemandProfile' },
            {
                node_type: NodeType.Basic,
                module_key: 'BasicUtilityRatesSimple',
            },
            {
                node_type: NodeType.Basic,
                module_key: 'BasicRatesEscalationSimple',
            },
            {
                node_type: NodeType.Basic,
                module_key: 'BasicSystemCostNameplate',
            },
            {
                node_type: NodeType.Basic,
                module_key: 'BasicReplacementCostSimple',
            },
            {
                node_type: NodeType.Basic,
                module_key: 'BasicMaintenanceCostSimple',
            },
            { node_type: NodeType.Basic, module_key: 'BasicApplyIncentives' },
            {
                node_type: NodeType.Basic,
                module_key: 'BasicFinancingLoanSimple',
            },
            { node_type: NodeType.Basic, module_key: 'BasicFinancialMetrics' },
            {
                node_type: NodeType.Basic,
                module_key: 'BasicEnvironmentalMetrics',
            },
        ],
    };

    const tree = FinancialPipelineTree.fromRawData(treeData);

    const makeConfigurable = (node, filter?) => {
        for (const [key, setting] of Object.entries(node.parameter_settings) as [any, any]) {
            if (!filter || filter(key)) setting.configurable = true;
        }
    };

    tree.traverseNodes((node) => {
        if (node.node_type !== NodeType.Basic) return;

        if (node.module_key === 'BasicSystemCostNameplate') {
            makeConfigurable(node);
        } else if (node.module_key === 'BasicFinancingLoanSimple') {
            node.toggleable = true;
            makeConfigurable(node);
        } else if (node.module_key === 'BasicIncentivesCostSimple') {
            makeConfigurable(node, (key) => key === 'value_tiers');
        } else if (node.module_key === 'BasicFinancialMetrics') {
            makeConfigurable(node);
        }
    });

    return { root: tree.root };
}

export function pipelineExecutable(templateData, configData) {
    const basePipe = FinancialPipelineTree.fromRawData(templateData.root);
    const configPipe = FinancialPipelineTree.fromRawData(configData.root);
    const finalPipe = basePipe.mergeNodeData(configPipe);
    return finalPipe.executableList();
}

export function pipelineInsertables() {
    const categorizedInsertables = _.chain(MODULE_REGISTRY)
        .pickBy('insertable')
        .mapValues((mod, key) => ({
            key,
            object: mod!.module,
            category: mod!.categoryName,
        }))
        .groupBy('category')
        .value();

    return categorizedInsertables;
}

export function verifyAdvancedPipeline(pipeline: FinancialPipelineTree, allowDepreciationInFinancing = false) {
    const ordered_nodes = pipeline.flattened().filter((node) => node.node_type === NodeType.Basic);
    for (let i = 0; i < ordered_nodes.length - 1; i++) {
        if (
            allowDepreciationInFinancing &&
            getModuleCategory(ordered_nodes[i].module_key) === ModuleCategory.Financing &&
            ordered_nodes[i + 1].module_key === 'BasicTaxDepreciationSimple'
        ) {
            // this module used to be in "Incentives", so some templates may have it "out of order"
            continue;
        }
        const currentCat = getModuleCategory(ordered_nodes[i].module_key);
        const nextCat = getModuleCategory(ordered_nodes[i + 1].module_key);
        if (MODULE_CATEGORIES.indexOf(currentCat) > MODULE_CATEGORIES.indexOf(nextCat)) {
            return true;
        }
    }
    return false;
}

export function getLastIncentiveIndex(pipeline: FinancialPipelineTree) {
    let lastIdx: number[] | null = null;
    pipeline.traverseNodes((node, idx) => {
        if (
            node.module_key &&
            getModuleCategory(node.module_key) === ModuleCategory.Incentives &&
            node.module_key !== 'BasicTaxDepreciationSimple'
        ) {
            lastIdx = idx;
        }
    });
    return lastIdx;
}
