import { Intent } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';
import Callout from 'reports/components/core/controls/Callout';

import * as auth from 'reports/modules/auth';

import { IAppState } from 'reports/store';

type IProps = ReturnType<typeof mapStateToProps>;

const UpgradeToProReportControls: React.FC<IProps> = () => {
    return (
        <Callout intent={Intent.WARNING}>
            <span>
                Custom reports and financial analysis are early access features that are not included in your current
                plan. If you would like full access to these features, please{' '}
                <a href="mailto:support@helioscope.com" target="_blank">
                    contact us
                </a>
                .
            </span>
        </Callout>
    );
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

export default connect(mapStateToProps)(UpgradeToProReportControls);
