/* tslint:disable:variable-name function-name */
import moment from 'moment';
import { schema } from './schema';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

interface ISubscriptionExtensionForm {
    end_date: string;
    reason: string;
    subscription_id: string;
}

interface IUpdateSubscriptionExtensionForm {
    subscription_extension_id: number;
    end_date: string;
    reason: string;
}

class SubscriptionExtension extends BaseClass {
    subscription_extension_id: number;
    reason: string;
    end_date: moment.Moment;

    constructor(data) {
        super(SubscriptionExtension.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        end_date: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(SubscriptionExtension, 'subscription_extension');

const endpoint = ReduxEndpoint.fromSchema('/api/v2/subscription_extensions/', schemaObj);

const api = {
    create: endpoint.post<ISubscriptionExtensionForm>(),
    save: endpoint.put<IUpdateSubscriptionExtensionForm>('{subscription_extension_id}'),
    delete: endpoint.delete('{subscription_extension_id}'),
};

export { SubscriptionExtension, api, schemaObj, ISubscriptionExtensionForm, IUpdateSubscriptionExtensionForm };
