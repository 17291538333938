import _ from 'lodash';
import { rootFind } from 'helioscope/app/utilities/optimization';

export function metricROI(series: number[]): number {
    const cf0 = series[0];

    if (cf0 === 0) return 0;

    return _.sum(series) / Math.abs(cf0);
}

export function metricNPV(series: number[], discount: number): number {
    let totalDiscount = 1;
    let npv = 0;
    for (let i = 0; i < series.length; i += 1) {
        npv += series[i] / totalDiscount;
        totalDiscount *= 1 + discount;
    }

    return npv;
}

export function metricIRR(series: number[]): number {
    if (series[0] === 0) return 0;

    return rootFind((i) => metricNPV(series, i), 0.0, 99999.0, 0.0001, 2500);
}

// follows http://financejs.org/#PP
export function metricPP(series: number[]): number | null {
    let sum = series[0];
    let idx = 0;
    for (let i = 1; i < series.length; i += 1) {
        sum += series[i];
        if (sum >= 0) {
            idx += Math.abs(sum - series[i]) / series[i];
            return idx;
        }
        idx += 1;
    }

    return null;
}
