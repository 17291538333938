import { range } from 'lodash';

import moment from 'moment';

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 - Sunday, 6 - Saturday
export type Hour =
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23;
export type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const DAY_INTS = range(0, 7) as DayOfWeek[];
export const HOUR_INTS = range(0, 24) as Hour[];
export const MONTH_INTS = range(1, 13) as Month[];

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const SHORT_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const SHORT_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const NON_LEAP_YEAR = 2019;
export const HOURS_IN_YEAR = 24 * 365;

/**
 * Return an hour index in 1 .. 8760, adjusting out leap years
 * @param intervalStart
 */
export function hourIndex(intervalStart: moment.Moment, removeLeapYear = true) {
    const timeUTC = intervalStart.utc();

    // start of the year with the same offset as the passed in date
    const startDate = moment.utc([timeUTC.year()]).utcOffset(timeUTC.utcOffset(), true);
    const rawHours = timeUTC.diff(startDate, 'hours');
    const leapAdjust = removeLeapYear && timeUTC.isLeapYear() && rawHours > 24 * (31 + 28);

    return rawHours - (leapAdjust ? 24 : 0) + 1; // ensure the range is from 1..8760
}

/**
 * Return number of hours in the month (non-DST adjusted, assume non-leap year).
 * Use UTC for non-DST offset, because otherwise moment() will return browser local time.
 *
 * TODO: detect and adjust for DST
 */
export function hoursInMonth(month: Month, year: number = NON_LEAP_YEAR) {
    // Note: Moment array parsing is zero indexed
    const monthStart = moment.utc([year, month - 1]).valueOf(); // in ms
    const nextMonthStart = (month < 12 ? moment.utc([year, month]) : moment.utc([year + 1, 0])).valueOf(); // in ms

    return moment.duration(nextMonthStart - monthStart).asHours();
}

export function getCurrentMonth() {
    return (new Date().getMonth() + 1) as Month;
}

/**
 * Return month (1 indexed) given local Unix timestamp in seconds
 */
export function getMonthFromUnix(unixTS: number, utcOffset?: number) {
    const dateLocal = utcOffset ? moment.unix(unixTS).utcOffset(utcOffset) : moment.unix(unixTS).utc();
    return (dateLocal.month() + 1) as Month;
}

export function getHourOfDayFromUnix(unixTS: number, utcOffset: number) {
    const dateLocal = moment.unix(unixTS).utcOffset(utcOffset);
    return dateLocal.hour() as Hour;
}

/**
 * Highcharts can't plot an hour of the day unless it belongs to a specific day,
 * so this maps a zero-indexed hour (0...23) to a unix timestamp on 1/1/1970
 */
export function makeJan1970Hour(hour: number) {
    return moment.utc([1970, 0, 1, hour, 0, 0, 0]).unix() * 1000;
}

/**
 * Return array of months from start to stop months (inclusive of both start and stop months).
 */
export function monthsInRange(start: Month, stop: Month) {
    if (start > stop) {
        return range(start, 13).concat(range(1, stop + 1)) as Month[];
    }
    return range(start, stop + 1) as Month[];
}
