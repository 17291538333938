import { mapValues, reduce } from 'lodash';
import * as React from 'react';

import { Colors } from '@blueprintjs/core';

import { DEFAULT_PALETTE, HELIOSCOPE_ORANGE } from 'reports/styles/global';
import { IColor } from 'reports/modules/report/components/forms/ColorPicker';

export interface IStyleMap {
    [k: string]: React.CSSProperties;
}

type IStyleProp = { label: string; style: keyof IStyleMap };
export type IColorCustomKey = 'color_primary' | 'color_secondary';

export function isColor(color: IColor | IColorCustomKey): color is IColor {
    return (<IColor>color).rgb !== undefined;
}

export const mapColors = (palette: string[] = DEFAULT_PALETTE): IStyleMap =>
    reduce(
        palette,
        (map, color) => {
            map[`color_${color.toUpperCase()}`] = { color };
            return map;
        },
        {},
    );

export const DEFAULT_COLOUR_MAP: IStyleMap = mapColors(DEFAULT_PALETTE);

export const DEFAULT_FONT_FAMILIES: IStyleMap = {
    fontFamily_arial: { fontFamily: 'Arial' },
    fontFamily_garamond: { fontFamily: 'Garamond' },
    fontFamily_georgia: { fontFamily: 'Georgia' },
    fontFamily_helvetica: { fontFamily: 'Helvetica' },
    fontFamily_sourceSansPro: { fontFamily: 'Source Sans Pro' },
    fontFamily_timesNewRoman: { fontFamily: 'Times New Roman' },
    fontFamily_trebuchet: { fontFamily: 'Trebuchet' },
    fontFamily_verdana: { fontFamily: 'Verdana' },
};

export const DEFAULT_FONT_SIZES: IStyleMap = {
    fontSize_14: { fontSize: 14 },
    fontSize_16: { fontSize: 16 },
    fontSize_20: { fontSize: 20 },
};

// Default custom style map for Draft Editor
export const DEFAULT_STYLE_MAP: IStyleMap = {
    ...DEFAULT_COLOUR_MAP,
    ...DEFAULT_FONT_FAMILIES,
    ...DEFAULT_FONT_SIZES,
    color_primary: { color: Colors.COBALT1.toUpperCase() },
    color_secondary: { color: HELIOSCOPE_ORANGE },
};

// Available fonts mapped to style map
export const AVAILABLE_FONTS: IStyleProp[] = [
    {
        label: 'Arial',
        style: 'fontFamily_arial',
    },
    {
        label: 'Garamond',
        style: 'fontFamily_garamond',
    },
    {
        label: 'Georgia',
        style: 'fontFamily_georgia',
    },
    {
        label: 'Helvetica',
        style: 'fontFamily_helvetica',
    },
    {
        label: 'Source Sans Pro',
        style: 'fontFamily_sourceSansPro',
    },
    {
        label: 'Times New Roman',
        style: 'fontFamily_timesNewRoman',
    },
    {
        label: 'Trebuchet',
        style: 'fontFamily_trebuchet',
    },
    {
        label: 'Verdana',
        style: 'fontFamily_verdana',
    },
];

export type IRgb = { r: number; g: number; b: number };
export type IRgba = { r: number; g: number; b: number; a: number };

export const rgbToString = ({ r, g, b, a }: IRgba): string => `rgba(${r},${g},${b},${a})`;

function colorToRgb(color: string): IRgb | undefined {
    const hexRegex = /^#[a-fA-F0-9]{6}$/;
    const rgbaRegex = /^rgba\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*([-+]?[0-9]*[.]?[0-9]+)\s*\)$/;
    const rgbRegex = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;

    // Convert rgb(a) string to rgb object
    const rgbMatch = rgbaRegex.exec(color) || rgbRegex.exec(color);
    if (rgbMatch) {
        return {
            r: parseInt(rgbMatch[0], 10),
            g: parseInt(rgbMatch[1], 10),
            b: parseInt(rgbMatch[2], 10),
        };
    }

    if (!color.match(hexRegex)) {
        console.error(`Unsupported color format - ${color} must be in rgb, rgba, or 6-digit hex`);
        return;
    }

    // Convert hex to decimal
    return {
        r: parseInt(`${color[1]}${color[2]}`, 16),
        g: parseInt(`${color[3]}${color[4]}`, 16),
        b: parseInt(`${color[5]}${color[6]}`, 16),
    };
}

function getColorLuminance(color: string): number | undefined {
    const rgb = colorToRgb(color);

    if (!rgb) {
        return;
    }

    // Relative luminance as defined by w3c (https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef)
    const { r, g, b } = mapValues(rgb, (val) => {
        const channel = val / 255;
        return channel <= 0.03928 ? channel / 12.92 : Math.pow((channel + 0.055) / 1.055, 2.4);
    });
    return parseFloat((0.2126 * r + 0.7152 * g + 0.0722 * b).toFixed(3));
}

/*
 * Find the more readable text colour of black or white given a colour string
 * @param color - a color of rgb, rgba or 6-digit hex form (`rgba(255,255,255,1)`, `#ff9900`)
 * @return more contrasting of the white or black text color
 */
export function getReadableColor(color: string) {
    const luminance = getColorLuminance(color);
    return luminance && luminance > 0.179 ? Colors.WHITE : Colors.BLACK;
}
