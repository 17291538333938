import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fromNow } from 'reports/utils/formatters';

import { CopyButton, EditButton, FavoriteStar, PublishButton } from 'reports/components/core/controls';
import { LibraryPreview as Preview } from 'reports/components/core/layout';
import { PreviewDrawer } from 'reports/components/library/main/components';

import * as auth from 'reports/modules/auth';
import { canModifySharedTeamResource, canMakeResourcePublic } from 'reports/modules/auth/permissions';

import { FinancialTemplate as FinancialModel, api, saver } from 'reports/models/financial_template';
import { User } from 'reports/models/user';

import { actions } from 'reports/modules/financials';
import { FinancialPipelineTree } from 'reports/modules/financials/model/pipeline';
import { verifyAdvancedPipeline } from 'reports/modules/financials/model/pipeline/utils';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import EditorPreview from 'reports/modules/financials/components/financial_template/EditorPreview';

type Props = {
    model: FinancialModel;
    navigateToFinTemplate: (finTemplateId: number) => void;
    navigateToFinTemplates: () => void;
};

const FinModelPreview = React.memo(({ model, navigateToFinTemplate, navigateToFinTemplates }: Props) => {
    const dispatch = useDispatch();
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(true);
    const isAdvancedMode = verifyAdvancedPipeline(FinancialPipelineTree.fromRawData(model.data.root));

    const user = useSelector((state) => auth.selectors.getUser(state) as User);
    const saveAsNew = () => dispatch(actions.saveAsNew(model));
    const setBookmarked = (bookmarked) =>
        dispatch(
            api.patchTeamSettings({
                bookmarked,
                financial_template_id: model.financial_template_id,
            }),
        );
    const updateFinancialModel = (patch) => dispatch(saver.get(model).patch(patch, true));

    const bookmarked = model.bookmarked();
    const lastModified = model.last_modified || model.created;

    const onCopy = async () => {
        const newCopy = await saveAsNew();
        navigateToFinTemplate(newCopy.financial_template_id);
    };

    const togglePublic = () => updateFinancialModel({ public: !model.public });

    return (
        <PreviewDrawer
            title={
                <Preview.Header
                    title={model.description}
                    subtitle={lastModified ? `Last modified ${fromNow(lastModified)}` : undefined}
                    favorite={
                        <FavoriteStar
                            empty={!bookmarked}
                            onClick={() => {
                                addPromiseToastsToggle(
                                    'favorite',
                                    setBookmarked(!bookmarked),
                                    'financial model',
                                    model.description,
                                    bookmarked,
                                );
                            }}
                        />
                    }
                />
            }
            publishButton={
                <PublishButton
                    isPublic={model.public}
                    resourceName={model.description}
                    resourceType={'financial model'}
                    publicPrompt={
                        <p>
                            Making this financial model public will give all HelioScope users access to this model.
                            Everyone will be able to find and use this model, but not make any changes to the resource
                            itself.
                        </p>
                    }
                    privatePrompt={
                        <p>
                            Making this financial model private will also revoke access to this resource for any
                            HelioScope users that are not on the Folsom Labs team and are currently using this model.
                        </p>
                    }
                    togglePublic={togglePublic}
                    disabled={!canMakeResourcePublic(user, model)}
                />
            }
            editButton={
                canModifySharedTeamResource(user, model) ? (
                    <EditButton onClick={() => navigateToFinTemplate(model.financial_template_id)} />
                ) : (
                    <CopyButton text="Copy to Library" onClick={onCopy} />
                )
            }
            isOpen={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            onClosed={navigateToFinTemplates}
        >
            <div style={{ padding: 5 }}>
                <EditorPreview template={model} advanced={isAdvancedMode} />
            </div>
        </PreviewDrawer>
    );
});

export { FinModelPreview };
