import * as React from 'react';
import { Link } from 'react-router5';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { Classes, FormGroup, Radio, Spinner, Checkbox, Intent } from '@blueprintjs/core';
import * as auth from 'reports/modules/auth';

import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';
import * as weatherSrc from 'reports/models/weather_source';

import Colors from 'reports/styles/colors';
import { bindActions } from 'reports/utils/redux';
import { Flex } from 'reports/components/core/containers';
import Section2 from 'reports/components/core/containers/Section2';
import BasicTable from 'reports/components/core/tables/BasicTable';
import ScrollContainer from 'reports/components/core/tables/ScrollContainer';
import { FormField } from 'reports/components/forms';
import { Warning } from 'reports/components/helpers/errors';
import FixedItemsSelect from 'reports/components/forms/inputs/experimental/FixedItemsSelect';
import { WeatherSourceMap } from 'reports/modules/weather_source/views/WeatherSourceMap';
import WeatherSourceUploadLauncher from 'reports/modules/weather_source/views/WeatherSourceUploadLauncher';
import Callout, { BottomMargin } from 'reports/components/core/controls/Callout';

import * as styles from 'reports/styles/styled-components';
import { Button } from 'reports/components/core/controls';
import { useWatchPusherChannel } from 'reports/modules/hooks/useWatchPusherChannel';
const styled = styles.styled;

const WEATHER_SOURCE_API_LIMIT = 50;

const HEADER_HEIGHT = 32;
const ROW_HEIGHT = 42;
const ROWS_ABOVE_THE_FOLD = 10.5;
// Display the first 10.5 sources -- half-row alerts user that the element is scrollable
const TABLE_HEIGHT = HEADER_HEIGHT + ROW_HEIGHT * ROWS_ABOVE_THE_FOLD;

const WeatherSourceTableLoading = styled.div`
    height: ${TABLE_HEIGHT}px;
    display: flex;
    justify-content: center;
    border: 1px solid ${Colors.BORDER_TABLE_LIGHT};
    width: 100%;
`;

const ControlRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* Override default select button styles for ConditionSetsEdit */
    .${Classes.BUTTON} {
        margin-left: 16px;
    }

    /* Adjusts default Blueprint styles to vertically center properly within table cell */
    .${Classes.RADIO} {
        margin-bottom: 0;
    }
`;

const WeatherRightSideContainer = styled(Flex.ContainerV)`
    width: 100%;
    flex: 0 0 50%;
`;

const WeatherSourceUploadLauncherContainer = styled.div`
    margin-top: 6px;
    width: fit-content;
`;

const WeatherSourceTableContainer = styled(Flex.ContainerV)`
    width: 100%;
`;

const MeteonormCalloutButtonContainer = styled(Flex.ContainerV)`
    flex-shrink: 0;
    text-align: end;
`;

const MapContainer = styled.div`
    position: relative;
    height: ${TABLE_HEIGHT}px;
    width: 100%;
    margin-left: 12px;
    border: 1px solid ${Colors.BORDER_TABLE_LIGHT};
`;

const revAlphaSort = (datasets) => orderBy(datasets, ['name'], ['desc']);

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type IConditionSetWeatherEditProps = IStateProps & IDispatchProps;

const HighlightableRow = styled.tr<{ hovered: boolean }>`
    ${(props) => (props.hovered ? `background-color: ${Colors.TABLE_HIGHLIGHTED_ROW}` : '')}
`;

const PaddedWarning = styled(Warning)`
    padding: 6px 0;
`;

const PreferredSourceConfigBar = styled(Flex.Container)`
    justify-content: space-between;
`;

const DatasetSelect = ({ datasets, value, onChange, disabled }) => {
    const items = revAlphaSort(datasets).map((ds) => ({
        key: ds.weather_dataset_id,
        name: ds.name,
    }));
    const defaultValue = items.length ? items[0].key : undefined;

    return (
        <FixedItemsSelect
            items={items}
            value={items.map((i) => i.key).includes(value) ? value : defaultValue}
            onChange={onChange}
            disabled={disabled}
            width={80}
        ></FixedItemsSelect>
    );
};

const _SourceRow = ({
    source,
    sourceId,
    onSourceChange,
    onDatasetChange,
    datasetId,
    submitting,
    hovered,
    formatters,
}) => (
    <HighlightableRow hovered={hovered}>
        <td>
            <ControlRow>
                {/* Normally we'd group these in a RadioGroup, but Blueprint expects Radios to be children,
                which is not possible in a table */}
                <Radio
                    label={source.prettyName}
                    value={source.weather_source_id}
                    checked={sourceId === source.weather_source_id}
                    onChange={(e) => onSourceChange(parseInt((e.target as HTMLInputElement).value, 10))}
                />
                <DatasetSelect
                    datasets={source.weather_datasets}
                    onChange={onDatasetChange}
                    value={datasetId}
                    disabled={submitting || source.weather_datasets.length === 1}
                />
            </ControlRow>
        </td>
        <td style={{ verticalAlign: 'middle' }}>
            {formatters.distance(source.distance, {
                longDistance: true,
                precision: 1,
            })}
        </td>
    </HighlightableRow>
);
const mapStateToPropsSrcRow = (state) => ({
    formatters: auth.selectors.formatters(state),
});
const SourceRow = connect(mapStateToPropsSrcRow)(_SourceRow);

const WeatherSourceTable = styled(
    ({ datasetId, sources, sourceId, onDatasetChange, onSourceChange, submitting, hoveredSourceId }) => (
        <ScrollContainer height={TABLE_HEIGHT}>
            <BasicTable width="100%" sticky={true} tableTheme="control" hasScrollContainer={true}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Distance</th>
                    </tr>
                </thead>
                <tbody>
                    {sources!.map((source) => (
                        <SourceRow
                            key={source.weather_source_id}
                            source={source}
                            sourceId={sourceId}
                            onSourceChange={onSourceChange}
                            onDatasetChange={onDatasetChange}
                            datasetId={datasetId}
                            submitting={submitting}
                            hovered={source.weather_source_id === hoveredSourceId}
                        />
                    ))}
                </tbody>
            </BasicTable>
        </ScrollContainer>
    ),
)`
    width: 100%;
`;

const WeatherSourceMapContainer = ({ project, sources, sourceId, onSelectSource, onMouseOverSource, disabled }) => (
    <MapContainer>
        <WeatherSourceMap
            initMapConfig={{
                initialCenter: {
                    lat: project.location.latitude,
                    lng: project.location.longitude,
                },
                initialZoom: 7,
            }}
            projectLocation={{
                lat: project.location.latitude,
                lng: project.location.longitude,
            }}
            weatherSources={sources}
            selectedSourceId={sourceId}
            onSelectSource={onSelectSource}
            onMouseOverSource={onMouseOverSource}
            disabled={disabled}
            infoWindowTrigger={'hover'}
        />
    </MapContainer>
);

const ConditionSetWeatherEdit = ({
    project,
    loadMissingSources,
    loadWeatherSources,
}: IConditionSetWeatherEditProps) => {
    const [showOnlyPreferred, setShowOnlyPreferred] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [sources, setSources] = React.useState<weatherSrc.WeatherSource[]>([]);
    const [hoveredSourceId, setHoveredSourceId] = React.useState<string>();

    const getPreferredSourceType = React.useCallback(() => {
        return project.team.preferred_weather_source_type;
    }, [project]);

    const getSourceId = React.useCallback(
        (datasetId) => {
            if (sources && sources.length) {
                const sourceId = sources.find((source) =>
                    source.weather_datasets.find((ds) => ds.weather_dataset_id === datasetId),
                )?.weather_source_id;

                return sourceId;
            }
        },
        [sources],
    );

    const onSourceChange = React.useCallback(
        (sourceId, onDatasetChange) => {
            const source = sources.find((source) => source.weather_source_id === sourceId);

            // When the user selects a new source, we need to pick a reasonable default dataset for the source.
            // We use the same sort order as dropdown here, so we pick the first dropdown option.
            onDatasetChange(revAlphaSort(source?.weather_datasets)[0].weather_dataset_id);
        },
        [sources],
    );

    const hasAnyPreferredSources = React.useCallback(
        (sourcesToCheck = sources) => {
            const preferredSourceType = getPreferredSourceType();

            return preferredSourceType && sourcesToCheck.find((src) => src.source_type === preferredSourceType);
        },
        [sources],
    );

    const getPreferredSources = React.useCallback(() => {
        const preferredSourceType = getPreferredSourceType();

        return sources.filter((src) => preferredSourceType && src.source_type === preferredSourceType);
    }, [sources]);

    const addWeatherSources = React.useCallback(
        (newSources) => setSources((sources ?? []).concat(newSources)),
        [sources],
    );

    const loadSources = React.useCallback(async () => {
        const loadedSources = await loadWeatherSources();
        setSources(loadedSources);
        setShowOnlyPreferred(Boolean(hasAnyPreferredSources(loadedSources)));
        setLoading(false);
    }, []);

    const handlePusherEvent = React.useCallback(() => {
        if (!loading) {
            setLoading(true);
            loadSources();
        }
    }, [loading]);

    useWatchPusherChannel({
        channelPrefix: 'project_weather_sources',
        entityId: project.project_id,
        eventName: 'project.weather_sources_loaded',
        onReceiveEvent: handlePusherEvent,
    });

    React.useEffect(() => {
        loadSources();
    }, []);

    const processedSources: weatherSrc.WeatherSource[] = React.useMemo(
        () =>
            orderBy(
                (showOnlyPreferred ? getPreferredSources() : sources).map(
                    (source) =>
                        new weatherSrc.WeatherSource({
                            ...source,
                            distance: project.location.distance(source.location),
                            prettyName: source.toString(),
                        }),
                ),
                ['distance'],
            ),
        [sources, getPreferredSources, showOnlyPreferred, project],
    );

    const showLoadMeteonormCallout =
        !loading &&
        sources.findIndex((source) => source.source_type === 'meteonorm_v8') === -1 &&
        // API query limits results, we do not want to show if the limit is hit.
        sources.length !== WEATHER_SOURCE_API_LIMIT;

    return (
        <Section2 title="Weather" subtitle="Used to calculate the hourly performance of the array for a given year">
            {showLoadMeteonormCallout && (
                <Callout
                    bottomMargin={BottomMargin.STANDARD}
                    icon={null}
                    intent={Intent.PRIMARY}
                    title="Meteonorm 8 now available 🎉"
                >
                    <Flex.Container>
                        <Flex.ContainerV>
                            <p>
                                The Meteonorm 8 weather source is now available and ready to be integrated into your
                                project. Loading the Meteonorm 8 weather source will make it available for all
                                conditions within this project without altering the currently selected weather source.
                                The loading process may require a few minutes to complete.
                            </p>
                        </Flex.ContainerV>
                        <MeteonormCalloutButtonContainer>
                            <Button
                                loadingWhileAwaiting
                                intent={Intent.PRIMARY}
                                onClick={() => new Promise(() => loadMissingSources())}
                            >
                                Load Meteonorm 8
                            </Button>
                        </MeteonormCalloutButtonContainer>
                    </Flex.Container>
                </Callout>
            )}
            <FormGroup label="Weather Sources">
                {processedSources.length === 0 && !loading && (
                    <Warning
                        msg={
                            <>
                                No sources within search radius, please{' '}
                                <a href="mailto:support@helioscope.com" target="_blank">
                                    contact us
                                </a>{' '}
                                for information about custom or international weather.
                            </>
                        }
                    />
                )}
                <FormField path="weather_dataset_id">
                    {({ onChange: onDatasetChange, value: datasetId, form }) => {
                        const sourceId = getSourceId(datasetId);

                        return (
                            <>
                                <Flex.Container>
                                    <WeatherSourceTableContainer>
                                        {loading && (
                                            <WeatherSourceTableLoading>
                                                <Spinner />
                                            </WeatherSourceTableLoading>
                                        )}
                                        {!loading && (
                                            <>
                                                <WeatherSourceTable
                                                    sources={processedSources}
                                                    onSourceChange={(sourceId) =>
                                                        onSourceChange(sourceId, onDatasetChange)
                                                    }
                                                    sourceId={sourceId}
                                                    submitting={form.submitting}
                                                    datasetId={datasetId}
                                                    onDatasetChange={onDatasetChange}
                                                    hoveredSourceId={hoveredSourceId}
                                                />
                                                <PreferredSourceConfigBar>
                                                    {!getPreferredSourceType() && (
                                                        <PaddedWarning
                                                            msg={
                                                                <>
                                                                    No preferred source specified,{' '}
                                                                    <Link routeName="app.settings.team.overview">
                                                                        configure here
                                                                    </Link>
                                                                </>
                                                            }
                                                        />
                                                    )}
                                                    {getPreferredSourceType() && !hasAnyPreferredSources() && (
                                                        <PaddedWarning msg="No preferred sources available" />
                                                    )}
                                                    {
                                                        hasAnyPreferredSources() && <span /> // for justify-content
                                                    }
                                                    <Checkbox
                                                        checked={showOnlyPreferred}
                                                        label="Show preferred sources only"
                                                        onChange={(e) => {
                                                            setShowOnlyPreferred(
                                                                (e.currentTarget as HTMLInputElement).checked,
                                                            );
                                                        }}
                                                        disabled={form.submitting || !getPreferredSourceType()}
                                                    />
                                                </PreferredSourceConfigBar>
                                            </>
                                        )}
                                    </WeatherSourceTableContainer>
                                    <WeatherRightSideContainer>
                                        <WeatherSourceMapContainer
                                            project={project}
                                            sources={processedSources}
                                            sourceId={sourceId}
                                            disabled={form.submitting || loading}
                                            onSelectSource={(sourceId) => onSourceChange(sourceId, onDatasetChange)}
                                            onMouseOverSource={(sourceId) => setHoveredSourceId(sourceId)}
                                        />
                                        <WeatherSourceUploadLauncherContainer>
                                            <WeatherSourceUploadLauncher
                                                addWeatherSources={(sources) => addWeatherSources(sources)}
                                            />
                                        </WeatherSourceUploadLauncherContainer>
                                    </WeatherRightSideContainer>
                                </Flex.Container>
                            </>
                        );
                    }}
                </FormField>
            </FormGroup>
        </Section2>
    );
};

const mapStateToProps = (state, ownProps: { scenario: scen.Scenario }) => ({
    project: proj.selectors.byId(state, ownProps.scenario.project_id)!!,
});

const mapDispatchToProps = bindActions(({ scenario }) => ({
    loadWeatherSources: () =>
        weatherSrc.api.index({
            project_id: scenario.project_id,
            limit: WEATHER_SOURCE_API_LIMIT,
            load_datasets: true,
        }),
    loadMissingSources: () => scen.api.loadMissingWeatherSources({ scenario_id: scenario.scenario_id }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ConditionSetWeatherEdit);
