import * as React from 'react';

import { Currency } from 'reports/components/core/numbers';
import { ResultConfig } from 'reports/modules/financials/results';
import { ProjectIncentives } from 'reports/modules/financials/components/ProjectIncentives';
import { ResultType } from 'reports/modules/financials/model/pipeline/types';

const EmptyResult: React.SFC<{ type: ResultType }> = ({ type }) =>
    type === ResultType.Incentives ? null : <span>N/A</span>;

interface IOwnProps {
    result: ResultConfig;
}

const Result: React.SFC<IOwnProps> = ({ result }) => {
    if (result.value == null) {
        return <EmptyResult type={result.type} />;
    }

    switch (result.type) {
        case ResultType.Currency:
            return <Currency value={result.value} precision={result.precision} />;

        case ResultType.Incentives:
            return <ProjectIncentives incentives={result.value} />;
    }
};

export default Result;
