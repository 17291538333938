import _ from 'lodash';
import moment from 'moment';

import { IFinancialOutput } from '../state';
import { metricIRR, metricNPV, metricPP, metricROI } from './metrics';

export const TOKEN_FUNCTIONS = {
    // System details
    system_life_start: (output: IFinancialOutput) => {
        const { systemStartYear, systemStartMonth } = output;
        if (systemStartYear) {
            return moment({ year: systemStartYear, month: systemStartMonth });
        }
        return null;
    },
    system_lifetime_years: (output: IFinancialOutput) => output.systemLifeYears,

    /* SCALARS */
    // Energy
    // Note: energy (production, consumption) outputs are processed in kWh and token energy formatters expect Wh,
    // the energy outputs below are scaled by x1000 to represent the expected Wh
    avg_consumption_monthly: (output: IFinancialOutput) => (output.consumptionYearly.values[0] / 12.0) * 1000,
    total_consumption_yearly: (output: IFinancialOutput) => output.consumptionYearly.values[0] * 1000,
    total_consumption_yearly_with_solar: (output: IFinancialOutput) => {
        const surplus = output.surplusYearly.values[0];
        return (output.consumptionAfterYearly.values[0] - surplus) * 1000;
    },
    offset_energy: (output: IFinancialOutput) => {
        const after = TOKEN_FUNCTIONS.total_consumption_yearly_with_solar(output);
        const before = TOKEN_FUNCTIONS.total_consumption_yearly(output);
        return 1.0 - after / before;
    },
    annual_degradation: (output: IFinancialOutput) => output.annualDegradation,

    // Rates
    avg_utility_bill_monthly_presolar: (output: IFinancialOutput) => output.utilityBeforeYearly.values[0] / 12.0,
    avg_utility_bill_monthly_postsolar: (output: IFinancialOutput) => output.utilityAfterYearly.values[0] / 12.0,
    total_utility_bill_yearly_presolar: (output: IFinancialOutput) => output.utilityBeforeYearly.values[0],
    total_utility_bill_yearly_postsolar: (output: IFinancialOutput) => output.utilityAfterYearly.values[0],
    total_utility_bill_lifetime_presolar: (output: IFinancialOutput) => _.sum(output.utilityBeforeYearly.values),
    total_savings_yearly: (output: IFinancialOutput) =>
        output.utilityBeforeYearly.values[0] - output.utilityAfterYearly.values[0],
    total_savings_monthly: (output: IFinancialOutput) =>
        (output.utilityBeforeYearly.values[0] - output.utilityAfterYearly.values[0]) / 12.0,
    lifetime_savings: (output: IFinancialOutput) =>
        _.sum(output.utilityBeforeYearly.values) - _.sum(output.utilityAfterYearly.values),
    offset_bill: (output: IFinancialOutput) => {
        const after = TOKEN_FUNCTIONS.total_utility_bill_yearly_postsolar(output);
        const before = TOKEN_FUNCTIONS.total_utility_bill_yearly_presolar(output);
        return 1.0 - after / before;
    },
    utility_rate_escalation: (output: IFinancialOutput) => output.utilityRateEscalation,

    // Costs
    avg_full_cost_monthly: (output: IFinancialOutput) =>
        TOKEN_FUNCTIONS.financing_costs_monthly(output) + TOKEN_FUNCTIONS.avg_utility_bill_monthly_presolar(output),
    avg_cost_per_kwh: (output: IFinancialOutput) =>
        output.utilityBeforeYearly.values[0] / output.consumptionYearly.values[0],
    avg_cost_per_kwh_lifetime: (output: IFinancialOutput) =>
        _.sum(output.utilityBeforeYearly.values) / _.sum(output.consumptionYearly.values),
    system_total_cost: (output: IFinancialOutput) => -output.systemInitial,
    system_upfront_costs: (output: IFinancialOutput) => -(output.systemInitial + output.incentiveInitial),
    system_net_costs: (output: IFinancialOutput) =>
        -(output.systemInitial + output.incentiveInitial + output.incentiveYearly.values[0]),
    annual_maintenance_cost: (output: IFinancialOutput) => output.annualMaintenanceCost,

    // Finances
    financing_costs_monthly: (output: IFinancialOutput) => -output.financeYearly.values[0] / 12.0,
    ppa_rate: (output: IFinancialOutput) => output.ppaRate,
    ppa_term_years: (output: IFinancialOutput) => output.ppaTermYears,

    // Cashflow
    system_npv_cash: (output: IFinancialOutput) => metricNPV(output.outCashFlow, output.npvDiscount),
    system_npv_cost: (output: IFinancialOutput) => metricNPV(output.outCostFlow, output.npvDiscount),
    system_npv_energy: (output: IFinancialOutput) => metricNPV(output.outEnergyFlow, output.npvDiscount),
    irr: (output: IFinancialOutput) => metricIRR(output.outCashFlow),
    roi: (output: IFinancialOutput) => metricROI(output.outCashFlow),
    lcoe: (output: IFinancialOutput) =>
        metricNPV(output.outCostFlow, output.npvDiscount) / metricNPV(output.outEnergyFlow, output.npvDiscount),
    payback_period: (output: IFinancialOutput) => metricPP(output.outCashFlow),
    discount_rate: (output: IFinancialOutput) => output.npvDiscount,

    // Incentives
    incentives_total: (output: IFinancialOutput) => _.sum(output.outIncentiveYearly.values),

    // Environmental metrics
    co2_kg: (output: IFinancialOutput) => output.co2_kg,
    driven_vehicle: (output: IFinancialOutput) => output.driven_vehicle,
    driven_km: (output: IFinancialOutput) => output.driven_km,
    recycled_ton: (output: IFinancialOutput) => output.recycled_ton,
    recycled_truck: (output: IFinancialOutput) => output.recycled_truck,
    gasoline_liter: (output: IFinancialOutput) => output.gasoline_liter,
    gasoline_tanker: (output: IFinancialOutput) => output.gasoline_tanker,
    energy_annual_home: (output: IFinancialOutput) => output.energy_annual_home,
    electricity_annual_home: (output: IFinancialOutput) => output.electricity_annual_home,
    led_bulb: (output: IFinancialOutput) => output.led_bulb,
    oil_barrel: (output: IFinancialOutput) => output.oil_barrel,
    propane_can: (output: IFinancialOutput) => output.propane_can,
    coal_kg: (output: IFinancialOutput) => output.coal_kg,
    coal_railcar: (output: IFinancialOutput) => output.coal_railcar,
    seedling_ten_year: (output: IFinancialOutput) => output.seedling_ten_year,
    forest_hectare: (output: IFinancialOutput) => output.forest_hectare,

    /* ARRAYS */
    production_monthly_sum: (output: IFinancialOutput) => output.productionMonthSums.slice(), // in kWh
    consumption_monthly_sum: (output: IFinancialOutput) => output.consumptionMonthSums.slice(), // in kWh
    bill_monthly_sum: (output: IFinancialOutput) => output.utilityBeforeMonthSums.slice(),
    bill_monthly_sum_with_solar: (output: IFinancialOutput) => output.utilityAfterMonthSums.slice(),
    energy_value_yearly: (output: IFinancialOutput) => output.outEnergyFlow.slice(), // in kWh
    cash_flow_yearly: (output: IFinancialOutput) => output.outCashFlow.slice(),
    cumulative_cash_flow_yearly: (output: IFinancialOutput) => output.outCumulativeCashFlow.slice(),
};

export type TokenId = keyof typeof TOKEN_FUNCTIONS;
