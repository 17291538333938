/* tslint:disable:variable-name function-name */

import moment from 'moment';

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { Month } from 'reports/utils/time';

import { UsageSite } from './usage_site';
import { schema } from './schema';

class BuildingType extends BaseClass {
    building_type_id: number;
    name: string;
    type: string;
    floor_area: number;
    floors: number;
}

interface IHourlyDataPoint {
    date: moment.Moment;
    hour_index: number;
    consumption: number; // kW hours
}

class UsageProfile extends BaseClass {
    usage_profile_id: number;
    building_type: BuildingType;
    usage_site_id: number;
    usage_site: UsageSite;

    monthly: { [x in Month]: number }; // Month => kW Hours

    data?: {
        hourly: IHourlyDataPoint[];
    };

    constructor(args) {
        super(UsageProfile.deserializer(args));
    }

    static deserializer = BaseClass.getDeserializer({
        data: ({ hourly }) => ({
            hourly: hourly.map(({ date, ...rest }) => ({
                date: moment(date),
                ...rest,
            })),
        }),
    });
}

const buildingTypeSchemaObj = schema.addObject(BuildingType, 'building_type');

const schemaObj = schema.addObject(UsageProfile, 'usage_profile', {
    relationships: {
        building_type: { schema: buildingTypeSchemaObj },
    },
    defaultDeep: { building_type: true, usage_site: true },
});

const endpoint = ReduxEndpoint.fromSchema('/api/usage_profiles/', schemaObj);

const api = {
    index: endpoint.index<{ usage_site_id?: number }>(),
    get: endpoint.get<{ usage_profile_id: number }>('{usage_profile_id}'),
};

const selectors = {
    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

export { api, selectors, BuildingType, UsageProfile, schemaObj, buildingTypeSchemaObj };
