import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { bindActions } from 'reports/utils/redux';

import { Classes, Dialog } from '@blueprintjs/core';

import { PrimaryButton } from 'reports/components/core/controls';

import * as usr from 'reports/models/user';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ActionText = styled.div`
    font-size: 16px;
    margin-bottom: 40px;
    .email {
        color: #999999;
    }
`;

interface IOwnProps {
    isOpen: boolean;
    email: string;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IDispatchProps;

class ActivateAccountDialog extends React.PureComponent<IProps> {
    render() {
        const { isOpen, email, resendAuthToken, navWelcome } = this.props;
        return (
            <Dialog title="Activate your account to get started" isOpen={isOpen} onClose={navWelcome}>
                <div className={Classes.DIALOG_BODY}>
                    <ActionText>
                        We've sent an email to <span className="email">{email}</span> with a link to activate your
                        account. When you receive this email, click the link to get started.
                    </ActionText>
                    <p>
                        Can't find the email?
                        <PrimaryButton text="Resend it" onClick={() => resendAuthToken({ email })} />
                    </p>
                </div>
            </Dialog>
        );
    }
}

const mapDispatchToProps = bindActions(() => ({
    resendAuthToken: usr.api.resendAuthToken,
    navWelcome: () => routerActions.navigateTo('app.welcome'),
}));

export default connect(null, mapDispatchToProps)(ActivateAccountDialog);
