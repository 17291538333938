import { cloneDeep, get, maxBy, set } from 'lodash';
import * as React from 'react';

import { Checkbox, Intent } from '@blueprintjs/core';

import { DAY_INTS, Hour, Month, MONTH_INTS, SHORT_DAYS, SHORT_MONTHS } from 'reports/utils/time';

import { Flex } from 'reports/components/core/containers';
import { DropdownMenuItem, StaticSelect, IStaticSelect } from 'reports/components/core/forms';
import { Toaster } from 'reports/modules/Toaster';

import * as ur from 'reports/models/utility_rate';

import { DEFAULT_TIER } from 'reports/modules/financials/components/ParamTiers';

import { getOffPeakIntervals, getTOUfromSeason } from '../utils';
import { Subsection, SubsectionHeader, SettingRow } from './OverviewPanel';
import SeasonalTOUSettings from './SeasonalTOUSettings';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const RowSection = styled.div`
    margin: 12px 30px;
`;

const DEFAULT_TOU_RATE_SCHEDULE: ur.ISeasonalRateSchedule = {
    rate_tiers: [{ ...DEFAULT_TIER }],
    tou: {
        name: '',
        intervals: [],
    },
};

const MonthDropdown = (props: Pick<IStaticSelect<Month>, 'activeItem' | 'buttonText' | 'onItemSelect'>) => (
    <StaticSelect
        items={MONTH_INTS}
        itemRenderer={(month, { handleClick, modifiers }) => (
            <DropdownMenuItem key={month} title={SHORT_MONTHS[month - 1]} onClick={handleClick} {...modifiers} />
        )}
        {...props}
    />
);

interface IOwnProps {
    hasTOU: boolean;
    input: ur.ISeasonalInput;
    onUpdate: (subpath: string, value: any) => void;
}

class SeasonalSettings extends React.PureComponent<IOwnProps> {
    render() {
        const { hasTOU } = this.props;
        const { business_days: businessDays, seasons } = this.props.input;

        const maxTOUPerSeason = get(maxBy(seasons, 'rate_schedules.length'), 'rate_schedules.length');

        return (
            <Subsection>
                <SubsectionHeader title="Schedule Settings" />

                <SettingRow
                    label={`Seasons${hasTOU ? ' & TOU' : ''}`}
                    helperText={`Define seasonal${hasTOU ? ' and time of use' : ''} periods`}
                />
                <RowSection>
                    {seasons.map((season, sIdx) => {
                        const { months } = season;

                        const tou = getTOUfromSeason(season);

                        return (
                            <Flex.Container key={`season-${sIdx}`} style={{ marginBottom: 8 }}>
                                <div
                                    style={{
                                        flex: '0 0 100px',
                                        lineHeight: '30px',
                                    }}
                                >
                                    {season.name}
                                </div>
                                <Flex.Main>
                                    <Flex.Container>
                                        <MonthDropdown
                                            buttonText={SHORT_MONTHS[months.start - 1]}
                                            activeItem={months.start}
                                            onItemSelect={(month) => this.onMonthUpdate('start', sIdx, month)}
                                        />
                                        <Flex.Container
                                            style={{
                                                alignItems: 'center',
                                                padding: '0 8px',
                                            }}
                                        >
                                            to
                                        </Flex.Container>
                                        <MonthDropdown
                                            buttonText={SHORT_MONTHS[months.end - 1]}
                                            activeItem={months.end}
                                            onItemSelect={(month) => this.onMonthUpdate('end', sIdx, month)}
                                        />
                                    </Flex.Container>
                                    {this.props.hasTOU && (
                                        <SeasonalTOUSettings
                                            tou={tou}
                                            numCols={maxTOUPerSeason}
                                            onUpdate={(pIdx, period) => this.onTOUUpdate(sIdx, pIdx, period)}
                                            onAdd={() => this.addTOUPeriod(sIdx)}
                                            onDelete={(touIdx) => this.deleteTOUPeriod(sIdx, touIdx)}
                                            style={{ marginTop: 8 }}
                                        />
                                    )}
                                </Flex.Main>
                            </Flex.Container>
                        );
                    })}
                </RowSection>
                {this.props.hasTOU && (
                    <>
                        <SettingRow
                            label="Business Days"
                            helperText={
                                'Unchecked days assume seasonal off-peak rate schedules (commonly referred to \
                                as Weekend & Holidays).'
                            }
                        />
                        <RowSection>
                            {businessDays &&
                                DAY_INTS.map((day) => {
                                    const dayChecked = businessDays.includes(day);
                                    return (
                                        <Checkbox
                                            key={day}
                                            checked={dayChecked}
                                            label={SHORT_DAYS[day]}
                                            onChange={() =>
                                                this.onInputUpdate(
                                                    'business_days',
                                                    dayChecked
                                                        ? businessDays.filter((d) => d !== day)
                                                        : [...businessDays, day],
                                                )
                                            }
                                            inline={true}
                                        />
                                    );
                                })}
                        </RowSection>
                    </>
                )}
            </Subsection>
        );
    }

    addTOUPeriod = (seasonIdx: number) => {
        const input = cloneDeep(this.props.input);
        const { rate_schedules: rateSchedules } = input.seasons[seasonIdx];

        const prevSchedule = rateSchedules[rateSchedules.length - 2];

        // Populate next interval with previous if available
        let newInterval: ur.IInterval = [0, 1];
        if (prevSchedule && prevSchedule.tou) {
            const { intervals } = prevSchedule.tou;

            const lastIntervalEnd = intervals[intervals.length - 1][1];
            const intervalEnd = ((lastIntervalEnd + 1) % 24) as Hour;

            newInterval = [lastIntervalEnd, intervalEnd];
        }

        const newSchedule = {
            ...DEFAULT_TOU_RATE_SCHEDULE,
            tou: { name: '', intervals: [newInterval] },
        };

        // Insert as second last schedule, last schedule = default off peak
        rateSchedules.splice(rateSchedules.length - 1, 0, newSchedule);
        this.updateSeason(input, seasonIdx);
    };

    deleteTOUPeriod = (seasonIdx: number, touPeriodIdx: number) => {
        const input = cloneDeep(this.props.input);
        const { rate_schedules: rateSchedules } = input.seasons[seasonIdx];

        rateSchedules.splice(touPeriodIdx, 1);
        this.updateSeason(input, seasonIdx);
    };

    onInputUpdate = (subpath: string, val: any) => {
        const input = cloneDeep(this.props.input);
        set(input, subpath, val);
        this.props.onUpdate(`seasonal_input`, input);
    };

    onMonthUpdate = (pos: 'start' | 'end', seasonIdx: number, val: Month) => {
        const { seasons: currSeasons } = this.props.input;

        const seasons = cloneDeep(currSeasons);
        const { months } = seasons[seasonIdx];

        months[pos] = val;

        // Invalid ranges - season wraps around or contains the whole year
        if (months.start === ((months.end + 1) as Month) || (months.start === 1 && months.end === 12)) {
            Toaster.show({
                message:
                    'Invalid range of months for season - all seasons must contain at least 1 month and should \
                    not overlap.',
                intent: Intent.DANGER,
            });
            months[pos] = currSeasons[seasonIdx].months[pos];
            return;
        }

        // Shift current season's end
        if (pos === 'start' && val > months.end) {
            months.end = Math.min(val + (months.end - months.start), 12) as Month;
        }

        // Shift next season's interval
        if (seasonIdx < seasons.length - 1) {
            const { months: nextMonths } = seasons[seasonIdx + 1];

            if (pos === 'end') {
                const nextStart = val + 1;
                nextMonths.start = (val === 12 ? nextStart % 12 : nextStart) as Month;
            } else if (pos === 'start') {
                const nextEnd = val - 1;
                nextMonths.end = (val === 1 ? nextEnd + 12 : nextEnd) as Month;
            }
        }

        // Shift previous season's interval
        if (seasonIdx > 0) {
            const { months: prevMonths } = seasons[seasonIdx - 1];

            if (pos === 'start') {
                prevMonths.end = (val === 1 ? 12 : val - 1) as Month;
            } else if (pos === 'end') {
                // update either next season or first season start for > 2 seasons
                prevMonths.start = (val === 12 ? 1 : val + 1) as Month;
            }
        }

        this.onInputUpdate(`seasons`, seasons);
    };

    onTOUUpdate = (seasonIdx: number, touIdx: number, patch: Partial<ur.ITOUPeriod>) => {
        const input = cloneDeep(this.props.input);
        const rateSchedule = input.seasons[seasonIdx].rate_schedules[touIdx];

        rateSchedule.tou! = { ...rateSchedule.tou!, ...patch };
        this.updateSeason(input, seasonIdx);
    };

    // Update and set the off-peak period intervals for the specified season with given input
    recomputeSeasonOffPeak(input: ur.ISeasonalInput, seasonIdx: number) {
        const tou = getTOUfromSeason(input.seasons[seasonIdx]);
        input.seasons[seasonIdx].rate_schedules[tou.length - 1].tou!.intervals = getOffPeakIntervals(tou);
    }

    updateSeason(input: ur.ISeasonalInput, seasonIdx: number) {
        this.recomputeSeasonOffPeak(input, seasonIdx);
        this.props.onUpdate(`seasonal_input`, input);
    }
}

export default SeasonalSettings;
