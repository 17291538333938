import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';

import Highcharts from 'highcharts';
import { createSelector } from 'reselect';

import * as fmt from 'reports/utils/formatters';
import Translations from 'reports/localization/strings';
import * as proj from 'reports/models/project';
import { TOKEN_FUNCTIONS } from 'reports/modules/financials/model/tokens';
import * as finState from 'reports/modules/financials/state';
import { ILayoutChartProps, LayoutChart } from 'reports/modules/report/components/charts';
import { Theme, DEFAULT_THEME } from 'reports/modules/themes';
import { IAppState } from 'reports/types';

interface IOwnProps extends ILayoutChartProps {
    // we don't use this prop directly, however the availability of `primaryFinConfigTokens`
    // is not picked up by `mapStateToProps`, so we take this prop to pick up the change
    project?: proj.Project;
    overrideTokens?: finState.MaybeFinOutput;
    financialTokens?: finState.MaybeFinOutput;
    theme?: Theme;
    intl: IntlShape;
}

interface ISeries {
    name: string;
    data: number[];
    type: string;
    color: any;
}

function makeConfig(series: ISeries[], intl: IntlShape): Highcharts.Options {
    const { locale } = intl;
    return {
        series,

        chart: {
            type: 'area',
        },
        title: {
            text: '',
        },
        yAxis: {
            title: {
                text: fmt.currencySymbol(),
            },
            labels: {
                formatter(this: any) {
                    return fmt.stringifyNumber(this.value, {
                        locale,
                        // @ts-ignore: notation option exists, but is not in our version of typescript.
                        notation: 'compact',
                    });
                },
            },
            visible: true,
        },
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter(this: any) {
                return intl.formatMessage(Translations.financial.lifetime_production_formatter, {
                    x: this.x,
                    y: `<b>${fmt.currency(this.y, {
                        locale,
                        precision: 2,
                    })}</b>`,
                });
            },
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            series: {
                animation: false,
            },
        },
    };
}

const mapStateToProps = () => {
    const chartConfigSelector = createSelector(
        finState.selectors.primaryFinConfigTokens,
        (_state, props: IOwnProps) => props.financialTokens,
        (_state, props: IOwnProps) => props.overrideTokens,
        (_state, props: IOwnProps) => props.theme || DEFAULT_THEME,
        (_state, props: IOwnProps) => props.intl,
        (configTokens, financialTokens, overrideTokens, theme, intl) => {
            const finTokens = overrideTokens || financialTokens || configTokens;

            const dataSeries: ISeries[] = [
                {
                    data: finState.hasOutput(finTokens) ? TOKEN_FUNCTIONS.cumulative_cash_flow_yearly(finTokens) : [],
                    name: intl.formatMessage(Translations.financial.cumulative_cash_flow),
                    type: 'area',
                    color: theme.secondary_color,
                },
                {
                    data: finState.hasOutput(finTokens) ? TOKEN_FUNCTIONS.cash_flow_yearly(finTokens) : [],
                    name: intl.formatMessage(Translations.financial.cash_flow),
                    type: 'column',
                    color: theme.primary_color,
                },
            ];

            return makeConfig(dataSeries, intl);
        },
    );

    return (state: IAppState, ownProps: IOwnProps) => ({
        config: chartConfigSelector(state, ownProps),
    });
};

const ConnectedLifetimeChart = connect(mapStateToProps)(LayoutChart);

export default // the typecasting is gross
injectIntl(ConnectedLifetimeChart) as any as React.ComponentClass<Omit<IOwnProps, 'intl'>>;
