import * as React from 'react';
import { useSelector } from 'react-redux';

import { Intent } from '@blueprintjs/core';

import * as auth from 'reports/modules/auth';
import { Context } from 'reports/components/core/layout';

import { isNull } from 'lodash';

import { CheckoutFlowSignUpBtn } from 'reports/modules/auth/components/common';
import Callout from './core/controls/Callout';

const BANNER_HEIGHT = 64;

type TimeLeft = string | undefined | null;

const TrialLimitsBanner = () => {
    let timeLeft: TimeLeft;
    const user = useSelector((state) => auth.selectors.getUser(state)!);

    if (!user.isExpired()) {
        timeLeft = user.current_period_end?.fromNow().slice(3);
    } else {
        timeLeft = null;
    }

    const showBanner = user.status === 'trial' || user.status === 'expired_trial';

    const bannerIntent = !isNull(timeLeft) ? Intent.PRIMARY : Intent.WARNING;

    return (
        <>
            {showBanner && (
                <Context.NavBar style={{ height: BANNER_HEIGHT }}>
                    <Callout
                        icon="info-sign"
                        intent={bannerIntent}
                        rightElement={<CheckoutFlowSignUpBtn btnIntent={bannerIntent} />}
                        title={
                            !isNull(timeLeft)
                                ? 'Welcome to your HelioScope Trial!'
                                : 'Your HelioScope Trial has Expired!'
                        }
                    >
                        {!isNull(timeLeft) ? (
                            <>
                                You have <strong>{timeLeft}</strong> to try all HelioScope paid features. Upgrade
                                anytime for as low as $159/month.
                            </>
                        ) : (
                            <>
                                Your account has expired, to continue using HelioScope, register now for a full account.
                                Please{' '}
                                <a href="mailto:sales@helioscope.com" target="_blank">
                                    contact us
                                </a>{' '}
                                if you have any questions.
                            </>
                        )}
                    </Callout>
                </Context.NavBar>
            )}
        </>
    );
};

export { TrialLimitsBanner };
