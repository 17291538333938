import { HotkeysTarget2 } from '@blueprintjs/core';
import { clone } from 'lodash';
import * as React from 'react';

import { shallowCompare } from 'reports/utils/helpers';
import { setStatePromise } from 'reports/utils/async_helpers';

interface IOwnProps<Data> {
    baseData: Data;
    hasChangesFrom: (oldData: Data, newData: Data) => boolean;
    hotkeyGroup: string;
    children: (context: IUndoRedoContext<Data>) => JSX.Element;
}

interface IUndoRedoContext<Data> {
    data: Data;
    setData: (data: Data) => Promise<null>;
    undo: () => Promise<null>;
    redo: () => Promise<null>;
    hasChanges: () => boolean;
    canUndo: () => boolean;
    canRedo: () => boolean;
    clearChanges: () => Promise<null>;
}

interface IState<Data> {
    isDirty: boolean;
    data: Data;

    editHistory: Data[];
    editHistoryIndex: number;
}

export class UndoRedoManager<Data> extends React.PureComponent<IOwnProps<Data>> {
    state: IState<Data> = UndoRedoManager.initialState(this.props);

    private hotkeys = [
        {
            label: 'Undo',
            combo: 'mod + z',
            onKeyDown: () => this.undo(),
            group: this.props.hotkeyGroup,
            global: true,
        },
        {
            label: 'Redo',
            combo: 'mod + shift + z',
            onKeyDown: () => this.redo(),
            group: this.props.hotkeyGroup,
            global: true,
        },
    ];

    static initialState(props) {
        return {
            data: props.baseData,
            isDirty: false,

            editHistory: [props.baseData],
            editHistoryIndex: 0,
        };
    }

    hasChanges = () => this.state.isDirty;
    canUndo = () => this.state.editHistoryIndex > 0;
    canRedo = () => this.state.editHistoryIndex < this.state.editHistory.length - 1;

    clearChanges = (): Promise<null> => {
        return setStatePromise(this, UndoRedoManager.initialState(this.props));
    };

    /**
     * Updates the edit history with a new snapshot of data.
     * Does not add history points which are identical to the previous history point.
     * Ex: A line has width 10. We go into the "Border width" input and type "1" "0". Updates happen real time,
     *     but the finished action is a no-op.
     * @param data The data whose snapshot should be added to the history
     * @returns The updated history, where new checkpoints, if any, clobber any checkpoints after the current point.
     *    Ex: 1->2->[3]->4->5 + 3->6 = 1->2->3->[6], + 3->3 = 1->2->[3]->4->5
     */
    updatedHistory = (data: Data) => {
        const { editHistory, editHistoryIndex, data: currentData } = this.state;
        const { hasChangesFrom } = this.props;

        if (!hasChangesFrom(data, currentData)) {
            return { editHistory, editHistoryIndex };
        }

        const clobberedHistory = this.state.editHistory.slice(0, editHistoryIndex + 1);
        clobberedHistory.push(clone(data));

        return {
            editHistoryIndex: editHistoryIndex + 1,
            editHistory: clobberedHistory,
        };
    };

    setData = (data: Data): Promise<null> =>
        setStatePromise(this, {
            ...this.updatedHistory(data),
            data,
            isDirty: true,
        });

    shiftHistoryIndex = (shiftAmount: number) => {
        const { editHistory, editHistoryIndex } = this.state;
        const newHistoryIndex = Math.max(Math.min(editHistoryIndex + shiftAmount, editHistory.length - 1), 0);
        const data = editHistory[newHistoryIndex];

        return setStatePromise(this, {
            data,
            editHistoryIndex: newHistoryIndex,
            isDirty: true,
        });
    };

    undo = () => this.shiftHistoryIndex(-1);
    redo = () => this.shiftHistoryIndex(+1);

    componentDidUpdate(prevProps: Readonly<IOwnProps<Data>>) {
        const { baseData } = this.props;

        // If baseData's values have changed, we assume that the parent component has a good reason
        // for doing this and clear out the existing history
        if (!shallowCompare(prevProps.baseData, baseData)) {
            this.clearChanges().then();
        }
    }

    render() {
        const { data } = this.state;
        return (
            <HotkeysTarget2 hotkeys={this.hotkeys}>
                {this.props.children({
                    data,
                    setData: this.setData,
                    undo: this.undo,
                    redo: this.redo,
                    hasChanges: this.hasChanges,
                    canUndo: this.canUndo,
                    canRedo: this.canRedo,
                    clearChanges: this.clearChanges,
                })}
            </HotkeysTarget2>
        );
    }
}
export default UndoRedoManager;
