/* tslint:disable:variable-name */
import { cloneDeep } from 'lodash';
import moment from 'moment';

import { Project, schemaObj as projSchemaObj } from 'reports/models/project';
import * as user from 'reports/models/user';
import { IPageConfig, IRichTextContent, RichTextWidgetName } from 'reports/modules/report/widgets';
import { IAppState } from 'reports/store';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { schema } from '../schema';

import Document from './Document';
import Report, { schemaObj as repSchemaObj } from './Report';
import ReportVersion, { schemaObj as repVerSchemaObj } from './ReportVersion';

interface IRichTextWidgetConfig {
    name: RichTextWidgetName;
    content: IRichTextContent;
}

interface IReportConfigData {
    widgets: { [widgetId: number]: IRichTextWidgetConfig };
}

class ReportConfiguration extends BaseClass {
    report_configuration_id: number;

    report_id: number;
    report: Report;

    project_id: number;
    project: Project;

    report_version_id: number;
    report_version: ReportVersion;

    configuration_data: IReportConfigData;

    created?: moment.Moment;
    last_modified?: moment.Moment;
    creator_id?: number;
    creator?: user.User;
    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    constructor(data) {
        super(ReportConfiguration.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        last_modified: (x) => moment(x),
        created: (x) => moment(x),
    });

    makeDocument = () => {
        const baseDoc = this.report_version.document;
        const newPages: IPageConfig[] = cloneDeep(baseDoc.pages);
        for (const [widgetId, newWidgetConfig] of Object.entries(this.configuration_data.widgets)) {
            const { config: baseWidgetConfig, pageIdx } = baseDoc.findWidget(widgetId);
            newPages[pageIdx].widgets[widgetId] = {
                ...baseWidgetConfig,
                ...newWidgetConfig,
            };
        }
        return new Document({ ...baseDoc, pages: newPages });
    };

    static makeConfigData = (document: Document) => {
        const widgets = {};
        const configData: IReportConfigData = { widgets };
        for (const page of document.pages) {
            for (const [widgetId, widgetConfig] of Object.entries(page.widgets)) {
                const { configurable, name, content } = widgetConfig;
                if (configurable) {
                    widgets[widgetId] = { name, content };
                }
            }
        }
        return configData;
    };

    // Returns the subset of configuration data that still applies to new version (or null if nothing is configurable)
    removeStaleWidgets = (newVersion: ReportVersion) => {
        const configurableWidgets = ReportConfiguration.makeConfigData(newVersion.document).widgets;

        // Only keep the configuration for widgets that still exist and are still configurable in the latest version.
        const newWidgets = {};
        for (const [widgetId, widgetCfg] of Object.entries(this.configuration_data.widgets)) {
            if (configurableWidgets[widgetId]) {
                newWidgets[widgetId] = widgetCfg;
            }
        }
        return { widgets: newWidgets };
    };
}

const schemaObj = schema.addObject(ReportConfiguration, 'report_configuration', {});

const reportBackrefSelectors = schemaObj.addRelationship('report', repSchemaObj, { backref: 'report_configurations' });
schemaObj.addRelationship('report_version', repVerSchemaObj, {
    backref: 'report_configurations',
});
schemaObj.addRelationship('project', projSchemaObj, {
    backref: 'report_configurations',
});

const selectors = {
    byId: schemaObj.selectById,
    allConfigurations: reportBackrefSelectors.backrefSelector,
    configForProject: (state: IAppState, report: Report, projectId: number) => {
        const matching = selectors.allConfigurations(state, report).filter((c) => c.project_id === projectId);
        return matching.length > 0 ? matching[0] : null;
    },
};

const endpoint = ReduxEndpoint.fromSchema('/api/report_configurations/', schemaObj);

interface ICreateReportConfigForm {
    project_id: number;
    report_version_id: number;
    configuration_data: IReportConfigData;
}

interface IUpdateReportConfigForm {
    report_configuration_id: number;
    report_version_id?: number;
    configuration_data?: IReportConfigData;
}

const api = {
    create: endpoint.post<ICreateReportConfigForm>(),
    save: endpoint.put<IUpdateReportConfigForm>('{report_configuration_id}'),
    delete: endpoint.delete('{report_configuration_id}'),
    configsCount: endpoint.get<{ report_id: number }, { count: number }>(
        'configs_count',
        ReduxEndpoint.PassThroughConfig(),
    ),
};

export default ReportConfiguration;
export { ReportConfiguration, schemaObj, selectors, api };
