import { noop } from 'lodash';
import * as React from 'react';

import { FormField as InputFormField, IFormField } from 'reports/components/core/forms';

import FormField from '../FormField';

export type IFormInputProps = {
    path: string;
    defaultValue?: any;
    onUpdate?: (val: any) => void;
} & Omit<IFormField, 'value' | 'onChange'>;

/**
 * @deprecated
 * TODO: this is too generic (which means it is leaky), keeping it around
 * for backward  (because it's being used elsewhere) compatibility
 * but we should replace w/ simpler ie. a Primitive Input per form type
 */
const FormInput: React.FC<IFormInputProps> = ({
    path,
    defaultValue,
    inputProps,
    onUpdate = noop,
    disabled,
    ...formFieldProps
}) => (
    <FormField path={path} defaultValue={defaultValue} onUpdate={onUpdate}>
        {({ value, onChange }) => (
            <InputFormField
                value={value}
                onChange={onChange}
                inputProps={{ ...inputProps, disabled }}
                {...formFieldProps}
                disabled={disabled}
            />
        )}
    </FormField>
);

export default FormInput;
