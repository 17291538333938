import * as React from 'react';
import { actions as routerActions } from 'redux-router5';

import { RouteComponent } from 'reports/routing';

import * as mod from 'reports/models/module';

import { ModuleLibrary } from 'reports/modules/module/components/ModuleLibrary';
import { ModulePreview } from 'reports/modules/module/components/ModulePreview';
import { useDispatch } from 'react-redux';

const Library = () => {
    const dispatch = useDispatch();
    const navigateToModule = ({ module_id, defaultCharacterizationId }: mod.Module) =>
        navigateToCharacterization(module_id, defaultCharacterizationId());

    const navigateToCharacterization = (moduleId: number, characterizationId: number) =>
        dispatch(
            routerActions.navigateTo('app.modules.module.preview', {
                moduleId,
                characterizationId,
            }),
        );
    const navigateToModules = () => dispatch(routerActions.navigateTo('app.modules'));

    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.modules" exact={false}>
                    <ModuleLibrary
                        preview={
                            <RouteComponent name="app.modules.module.preview">
                                {({ module, characterization }) => (
                                    <ModulePreview
                                        module={module}
                                        characterization={characterization}
                                        navigateToCharacterization={navigateToCharacterization}
                                        navigateToModules={navigateToModules}
                                    />
                                )}
                            </RouteComponent>
                        }
                        navigateToModule={navigateToModule}
                    />
                </RouteComponent>
            </div>
        </div>
    );
};

export { Library };
