/* tslint:disable:variable-name */
import _ from 'lodash';

import { populateDebug } from 'reports/modules/financials/model/debug';
import { IExecutableModule } from 'reports/modules/financials/model/modules/types';

import { incrementChildIndex, parentChildIndex } from './utils';
import { ExecutableNode, FlattenedNode, Node, NodeType } from './node';
import { AuthErrorCode } from './types';

export function validValue(value: any) {
    return value !== null && typeof value !== 'undefined';
}

export class FinancialPipelineTree {
    root: Node;

    static fromFlattened(flat: FlattenedNode[]) {
        const cpy = flat.map((i) => i.copy());
        const newNodes: Node[] = [];

        for (let i = 0; i < cpy.length; i += 1) {
            const flatNode = cpy[i];
            const newNode = new Node({ ...cpy[i], children: null });
            if (flatNode != null) {
                newNode.children = flatNode.children != null ? flatNode.children.map((j) => new Node(cpy[j])) : null;
            }
            newNodes.push(newNode);
        }

        return new FinancialPipelineTree(newNodes[0]);
    }

    static fromRawData(data: object) {
        const root = new Node(data);
        return new FinancialPipelineTree(root);
    }

    toRawData() {
        const serialize = (node) => {
            const raw = node.serialize();

            raw.children = null;
            if (node.children) {
                raw.children = node.children.map((i) => serialize(i));
            }

            return raw;
        };

        return serialize(this.root);
    }

    constructor(treeRoot: Node) {
        if (!(treeRoot instanceof Node)) {
            throw new Error('Tree root must be of type Node');
        }
        this.root = treeRoot;
    }

    getNode(index: number[]) {
        let node = this.root;

        for (const i of index) {
            if (node.children != null) {
                node = node.children[i];
            }
        }

        return node;
    }

    findNode(moduleKey: string) {
        let firstIndex = undefined;
        this.traverseNodes((node, idx) => {
            if (firstIndex == null && node.module_key === moduleKey) {
                firstIndex = idx;
            }
        });
        return firstIndex;
    }

    insertNode(index: number[], node: Node) {
        const cpy = new FinancialPipelineTree(this.root);
        const { parentIndex, childIndex } = parentChildIndex(index);
        const parent = cpy.getNode(parentIndex);

        if (parent.children != null && childIndex) {
            parent.children.splice(childIndex, 0, node);
        }
        return cpy;
    }

    insertNodeAfter(index: number[], node: Node) {
        const incrIndex = incrementChildIndex(index);
        return this.insertNode(incrIndex, node);
    }

    deleteNode(index: number[]) {
        const cpy = new FinancialPipelineTree(this.root);
        const { parentIndex, childIndex } = parentChildIndex(index);
        const parent = cpy.getNode(parentIndex);
        if (parent.children && childIndex) {
            parent.children.splice(childIndex, 1);
        }
        return cpy;
    }

    moveNodeAfter(oldIndex: number[], newIndex: number[]) {
        const node = this.getNode(oldIndex);
        return this.deleteNode(oldIndex).insertNodeAfter(newIndex, node);
    }

    updateNode(index: number[], path: string, value: any) {
        const cpy = new FinancialPipelineTree(this.root);
        const node = cpy.getNode(index);
        _.set(node, path, value);
        return cpy;
    }

    executableList() {
        const boundModuleObj = (module: IExecutableModule) => {
            const obj = _.merge({}, module);

            for (const [key, entry] of Object.entries(obj)) {
                if (typeof entry === 'function') {
                    const bound = entry.bind(obj);
                    obj[key] = bound;
                }
            }

            return obj;
        };

        const flat = this.flattened();
        const execList: ExecutableNode[] = [];

        flat.forEach((i) => {
            if (!i.node_type) throw new Error('invalid pipeline node');

            if (i.node_type === NodeType.Basic) {
                if (i.toggleable && i.toggled_off) return;

                const values = {};

                for (const [key, entry] of Object.entries(i.parameter_settings) as [any, any]) {
                    if (!validValue(entry.value)) {
                        throw new Error('invalid pipeline parameter');
                    }
                    values[key] = entry.value;
                }

                if (!i.module) throw new Error('invalid pipeline module');

                const bound = boundModuleObj(i.module.module);
                const execute = (state) => bound.main(state, values);
                const authenticate = bound.authenticate && ((user) => bound.authenticate!(user));
                const debug =
                    bound.debugOutputs &&
                    ((state) =>
                        populateDebug(
                            state,
                            bound.debugOutputs!,
                            i.module.description,
                            _.isNil(i.user_label) ? undefined : i.user_label,
                        ));

                execList.push(_.merge({}, i, { execute, authenticate, debug }));
            }

            if (i.node_type === NodeType.Special) {
                if (i.special == null) {
                    throw new Error('Node type MODULE_SPECIAL must have special function defined');
                }
                execList.push(
                    _.merge({}, i, {
                        authenticate: (_) => AuthErrorCode.AUTHORIZED,
                        execute: i.special,
                    }),
                );
            }
        });

        return execList;
    }

    mergeNodeData(otherTree: FinancialPipelineTree) {
        const thisFlat = this.flattened();
        const otherFlat = otherTree.flattened();

        for (let i = 0; i < thisFlat.length; i += 1) {
            // eslint-disable-next-line
            const { parameter_settings, children, node_type, ...otherFields } = otherFlat[i];

            for (const key of Object.keys(otherFields)) {
                if (!validValue(otherFields[key])) delete otherFields[key];
            }

            _.assign(thisFlat[i], otherFields);
        }

        return FinancialPipelineTree.fromFlattened(thisFlat).mergeParameters(otherTree);
    }

    mergeParameters(otherTree: FinancialPipelineTree) {
        const thisFlat = this.flattened() as any;
        const otherFlat = otherTree.flattened() as any;

        for (let i = 0; i < thisFlat.length; i += 1) {
            const thisSettings = thisFlat[i].parameter_settings;

            if (thisSettings) {
                const otherSettings = otherFlat[i].parameter_settings;

                for (const [key, entry] of Object.entries(thisSettings)) {
                    if (otherSettings[key] && validValue(otherSettings[key].value)) {
                        _.assign(entry, otherSettings[key]);
                    }
                }
            }
        }
        return FinancialPipelineTree.fromFlattened(thisFlat);
    }

    traverseNodes(preFn, postFn?) {
        const index: number[] = [];

        const traverse = (node) => {
            if (preFn && preFn(node, index.slice())) return;

            if (node.children != null) {
                let idx = 0;
                for (const i of node.children) {
                    index.push(idx);
                    traverse(i);
                    index.pop();
                    idx += 1;
                }
            }

            if (postFn) postFn(node, index.slice());
        };

        traverse(this.root);
    }

    flattened() {
        const map = new WeakMap();
        let idx = 0;
        const flat: FlattenedNode[] = [];

        const preFn = (node) => {
            map.set(node, idx);
            idx += 1;
        };

        const postFn = (node) => {
            const cpy = new FlattenedNode({ children: null, ...node });
            flat[map.get(node)] = cpy;

            if (node.children != null) {
                cpy.children = node.children.map((i) => map.get(i));
            }
        };

        this.traverseNodes(preFn, postFn);
        return flat;
    }
}
