import * as React from 'react';

import { IAPIQueryOpts } from 'reports/models/types';

import { LibraryProvider } from '../context';
import { TableSortConfig } from 'reports/components/core/tables/DataTable';

interface Props<T> {
    loadItems: (opts: IAPIQueryOpts) => Promise<T[]>;
    refreshItem: (item: T) => T;
    resourceIdName: string;
    selectedItemId?: number | string;
}

interface State<T> {
    searchItems: Promise<T[]>;
    filters: { [key: string]: any };
    searchValue?: string;
    tableSort?: TableSortConfig<T>;
}

class SearchableLibrary<T> extends React.Component<Props<T>, State<T>> {
    state: State<T> = {
        searchItems: Promise.resolve([]),
        filters: {},
    };

    componentDidMount() {
        this.setState({ searchItems: this.loadItems({}), filters: {} });
    }

    async componentDidUpdate(prevProps: Props<T>) {
        const { selectedItemId } = this.props;
        if (selectedItemId !== prevProps.selectedItemId && selectedItemId != null) {
            // If we don't find the newly selected item in our cached list of loaded items, chances are the item was
            // just created (i.e. by inverter uploader). Reload the items, to get the new item to show up in the list.
            const items = await this.state.searchItems;
            if (!items.find(this.itemSelected)) {
                this.updateSearch();
            }
        }
    }

    render() {
        const providerValue = {
            items: this.state.searchItems.then((items) => items.map((item) => this.props.refreshItem(item))),
            filters: this.state.filters,
            setFilter: this.setFilter,
            setSearchValue: this.setSearchValue,
            setTableSort: this.setTableSort,
            itemSelected: this.itemSelected,
            toggleFilter: this.toggleFilter,
            clearFilter: this.clearFilter,
            updateSearch: this.updateSearch,
            searchValue: this.state.searchValue,
            tableSort: this.state.tableSort,
        };

        return <LibraryProvider value={providerValue}>{this.props.children}</LibraryProvider>;
    }

    loadItems = (opts = {}) => this.props.loadItems({ limit: 50, ...opts });

    toggleFilter = (name: string, val: any) => {
        if (this.state.filters[name] === val) {
            return this.clearFilter(name);
        }
        this.setFilter(name, val);
    };

    setFilter = (name: string, value: any) => {
        const filters = { ...this.state.filters, [name]: value };
        this.setState({ filters }, this.updateSearch);
    };

    clearFilter = (name: string) => {
        const { [name]: removed, ...remainingFilters } = this.state.filters;
        this.setState({ filters: remainingFilters }, this.updateSearch);
    };

    setSearchValue = (searchValue: string) => this.setState({ searchValue });
    setTableSort = (tableSort: TableSortConfig<T>) => this.setState({ tableSort }, this.updateSearch);

    updateSearch = () => {
        const { filters, searchValue, tableSort } = this.state;
        const sort = tableSort ? { sort: `${String(tableSort.property)} ${tableSort.order}` } : {};
        const searchItems = this.loadItems({
            q: searchValue,
            ...sort,
            ...filters,
        });
        this.setState({ searchItems });
    };

    itemSelected = (item: T) => {
        const { resourceIdName, selectedItemId } = this.props;
        return selectedItemId != null && item[resourceIdName].toString() === selectedItemId.toString();
    };
}

export default SearchableLibrary;
