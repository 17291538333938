import * as React from 'react';

import { TeamThemes as BetaTeamThemes } from 'reports/modules/settings/team/components/TeamThemes';
import { useSettingsContext } from 'frontend/settings/use-settings-data';

const TeamThemes = () => {
    const { team } = useSettingsContext();

    return <BetaTeamThemes team={team} />;
};

export { TeamThemes };
