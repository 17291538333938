/**
 * Report Proposals Generic Text Widget
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { chain, noop } from 'lodash';

import { Colors } from '@blueprintjs/core';
import {
    ContentBlock,
    convertToRaw,
    DraftHandleValue,
    Editor,
    EditorState,
    getDefaultKeyBinding,
    KeyBindingUtil,
    Modifier,
    RawDraftContentState,
} from 'draft-js';

import { IAppState } from 'reports/types';

import Translations from 'reports/localization/strings';

import { selectors as repSelectors } from 'reports/modules/report';
import { DEFAULT_STYLE_MAP, IStyleMap } from 'reports/modules/report/styles';

import { Suggestion, Suggestions } from 'reports/components/helpers/formHelpers';
import SidebarFormatForm from 'reports/modules/report/components/SidebarFormatForm';

import { ViewProps, EditProps } from './index';
import selectors from './selectors';
import GenericTextForm from './GenericTextForm';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const EditorContainer = styled.div.attrs({
    className: 'RichEditor-root',
})`
    height: 100%;
    width: 100%;
    padding: 4px;

    .public-DraftEditor-content {
        // Vertically center text
        div > span {
            vertical-align: middle;
        }

        // Align blocks
        .align-left > .public-DraftStyleDefault-block {
            text-align: left;
        }
        .align-center > .public-DraftStyleDefault-block {
            text-align: center;
        }
        .align-right > .public-DraftStyleDefault-block {
            text-align: right;
        }
        .align-justify > .public-DraftStyleDefault-block {
            text-align: justify;
        }
    }
`;

const TextVal = styled.span`
    font-style: italic;
    color: ${Colors.GRAY4};
`;

export interface IColorMap {
    [k: string]: { color: string };
}

function blockStyleFn(contentBlock: ContentBlock): string {
    const type = contentBlock.getType() as any;

    if (type === 'align-left' || type === 'align-center' || type === 'align-right' || type === 'align-justify') {
        return type;
    }

    return '';
}

type StateViewProps = ReturnType<ReturnType<typeof mapStateToViewProps>>;

const ViewTextComponent: React.SFC<ViewProps & StateViewProps> = (props) => {
    const { styleMap } = props;
    // createViewEditorState requires the intl prop, which is not available in mapStateToProps.
    const editorState = selectors.createViewEditorState(props);
    return (
        <EditorContainer>
            <Editor
                readOnly={true}
                editorState={editorState}
                blockStyleFn={blockStyleFn}
                customStyleMap={styleMap}
                onChange={noop}
            />
        </EditorContainer>
    );
};

interface IState {
    editorState: EditorState;
    styleMap: IStyleMap;

    autocompleteOpen: boolean;
    currentSuggestions: string[];
    currentTokenOffset: number;
    query: string;
    selectedSuggestion?: number;
}

type StateEditProps = ReturnType<ReturnType<typeof mapStateToEditProps>>;

class EditTextComponent extends React.PureComponent<EditProps & StateEditProps & IState> {
    editor = React.createRef<Editor>();

    suggestionRefs = {};
    minQueryLength: number = 1;
    regexpLookahead: RegExp = new RegExp(/^[^{]*(?=})/); // look ahead for '}'
    regexpLookbehind: RegExp = new RegExp(/{[^{}]+$/); // look behind for nearest open '{'

    state: IState = {
        editorState: this.props.editorState,
        styleMap: this.props.styleMap,

        // Autocomplete
        query: '',
        autocompleteOpen: false,
        currentSuggestions: this.props.completions,
        currentTokenOffset: 0, // start position of current token
        selectedSuggestion: undefined,
    };

    static DRAFT_DEFAULT_STYLES = {
        BOLD: 1,
        CODE: 1,
        ITALIC: 1,
        STRIKETHROUGH: 1,
        UNDERLINE: 1,
    };

    static TAB = '\t';

    componentDidMount() {
        // Autofocus once editor is mounted
        this.editor.current && this.editor.current.focus();
    }

    componentWillUnmount() {
        this.updateWidgetContent(this.state.editorState);
    }

    render() {
        return (
            <EditorContainer>
                <Editor
                    ref={this.editor}
                    editorState={this.state.editorState}
                    blockStyleFn={blockStyleFn}
                    customStyleMap={this.state.styleMap}
                    onChange={this.updateEditorState}
                    placeholder={this.props.intl.formatMessage(Translations.widgets.type_something_here)}
                    handleBeforeInput={this.handleBeforeInput}
                    handleKeyCommand={this.handleKeyCommand}
                    keyBindingFn={this.mapKeyToEditorCommand}
                    onEscape={this.resetAutocomplete}
                    onLeftArrow={this.mapKeyToEditorCommand}
                    onRightArrow={this.mapKeyToEditorCommand}
                    onUpArrow={this.updateSelectedSuggestion}
                    onDownArrow={this.updateSelectedSuggestion}
                    onTab={this.onTab}
                    handleDrop={() => {
                        // dragging selections throws an internal stack trace with react dnd
                        return 'handled';
                    }}
                />
                {this.renderSuggestions()}
                {this.renderForm()}
            </EditorContainer>
        );
    }

    renderForm = () => (
        <SidebarFormatForm>
            <GenericTextForm
                editorState={this.state.editorState}
                styleMap={this.state.styleMap}
                updateEditorState={this.updateEditorState}
                updateStyleMap={this.updateStyleMap}
                theme={this.props.context.theme}
            />
        </SidebarFormatForm>
    );

    renderSuggestions = () => {
        const {
            context,
            intl: { locale },
        } = this.props;
        return this.state.autocompleteOpen && this.state.currentSuggestions.length ? (
            <Suggestions
                style={{
                    // TODO: merge with Suggestions styles in `forms/helpers` once CustomText is removed
                    marginTop: 4,
                    maxHeight: 300,
                    overflowY: 'auto',
                }}
            >
                {this.state.currentSuggestions.map((suggestion, i) => (
                    <Suggestion
                        key={i}
                        ref={(el) => (this.suggestionRefs[i] = el)}
                        className={(this.state.selectedSuggestion === i ? 'selected' : '') + ' generic'}
                        onClick={() => this.completeToken(i)}
                    >
                        <span>{suggestion}</span>
                        <TextVal>{context.tokenMap![suggestion].format({ locale })}</TextVal>
                    </Suggestion>
                ))}
            </Suggestions>
        ) : null;
    };

    completeToken(idx: number | undefined = this.state.selectedSuggestion) {
        const { currentSuggestions, currentTokenOffset, editorState, query, selectedSuggestion } = this.state;

        if (idx !== undefined && currentSuggestions.length && currentSuggestions[idx]) {
            const content = editorState.getCurrentContent();
            const selection = editorState.getSelection();
            const contentBlock = content.getBlockForKey(selection.getAnchorKey());

            // Find end of current token, if it exists otherwise use end position of current selection
            const match = contentBlock.getText().slice(currentTokenOffset).match(this.regexpLookahead);
            const lookaheadBracketOffset = match === null ? match : match.index! + match[0].length + 1;
            const tokenEndOffset =
                lookaheadBracketOffset !== null
                    ? currentTokenOffset + lookaheadBracketOffset
                    : selection.getEndOffset();

            // Update selection range to current token offsets and replace with selected autosuggestion
            let updatedSelection = selection;
            if (query.length && selectedSuggestion != null) {
                updatedSelection = selection.merge({
                    anchorOffset: currentTokenOffset,
                    focusOffset: tokenEndOffset,
                }) as any;
            }
            const newContentState = Modifier.replaceText(
                content,
                updatedSelection,
                `${currentSuggestions[idx]}}`,
                contentBlock.getInlineStyleAt(currentTokenOffset), // apply any existing inline styles
            );

            this.setState({
                editorState: EditorState.push(editorState, newContentState, 'insert-characters'),
            });
            this.resetAutocomplete();
        }
    }

    handleBeforeInput = (char: string, editorState: EditorState): DraftHandleValue => {
        const selection = editorState.getSelection();
        switch (char) {
            case '{':
                this.setState({
                    autocompleteOpen: true,
                    currentTokenOffset: selection.getAnchorOffset(),
                });
                break;
            case '}':
                if (this.state.autocompleteOpen) {
                    this.resetAutocomplete();
                }
                break;
        }

        return 'not-handled';
    };

    handleKeyCommand = (command: string): DraftHandleValue => {
        switch (command) {
            case 'complete-token':
                this.completeToken();
                return 'handled';
            default:
                return 'not-handled';
        }
    };

    filterSuggestions(query: string) {
        if (query.length < this.minQueryLength) {
            return this.props.completions;
        }

        const queryArr = query.split(/[^\w]/);

        return this.props.completions.filter((suggestion) => {
            for (const i in queryArr) {
                const re = new RegExp(queryArr[i].trim(), 'gi');
                if (re.test(suggestion)) {
                    return true;
                }
            }
            return false;
        });
    }

    mapKeyToEditorCommand = (e: React.KeyboardEvent): string => {
        const { autocompleteOpen, currentSuggestions } = this.state;

        if (e.key !== 'Enter') {
            this.updateQuery(e);
        } else if (e.key === 'Enter' && autocompleteOpen && currentSuggestions.length) {
            // Update selected option and handle select on enter
            return 'complete-token';
        }

        // Return default Draft key binding
        return getDefaultKeyBinding(e)!;
    };

    onTab = (e: React.KeyboardEvent) => {
        e.preventDefault();
        const { autocompleteOpen, editorState } = this.state;

        // Complete current token
        if (autocompleteOpen) {
            this.completeToken();
        } else {
            const selection = editorState.getSelection();
            const content = editorState.getCurrentContent();
            const newContentState = Modifier.replaceText(content, selection, EditTextComponent.TAB);

            this.setState({
                editorState: EditorState.push(editorState, newContentState, 'insert-characters'),
            });
        }
    };

    pruneStyleMap = (rawTextContent: RawDraftContentState): IStyleMap =>
        chain(rawTextContent.blocks)
            .map((block) => block.inlineStyleRanges)
            .flatten()
            .transform((result, { style }) => {
                if (
                    result[style] === undefined &&
                    DEFAULT_STYLE_MAP[style] === undefined &&
                    EditTextComponent.DRAFT_DEFAULT_STYLES[style] === undefined
                ) {
                    result[style] = this.state.styleMap[style];
                }
            }, {})
            .value();

    resetAutocomplete = () => {
        this.setState({
            query: '',
            autocompleteOpen: false,
            currentSuggestions: this.props.completions,
            currentTokenOffset: 0,
            selectedSuggestion: undefined,
        });
    };

    updateEditorState = (editorState: EditorState) => this.setState({ editorState });
    updateStyleMap = (styleMap: IStyleMap) => this.setState({ styleMap });

    updateQuery(e: React.KeyboardEvent) {
        const { editorState } = this.state;

        const selection = editorState.getSelection();
        const currentContentBlock = editorState.getCurrentContent().getBlockForKey(selection.getAnchorKey());
        const blockText = currentContentBlock.getText();
        let endOffset = selection.getEndOffset();

        // Update offset if key is left or right arrow
        if (e.key === 'ArrowLeft') {
            endOffset -= 1;
        } else if (e.key === 'ArrowRight') {
            endOffset += 1;
        }

        // Include current non-control key in query
        let currentText = blockText.slice(0, endOffset);
        if (!KeyBindingUtil.isCtrlKeyCommand(e) && e.key.length === 1) {
            currentText += e.key;
        }

        const tokenOffset = currentText.search(this.regexpLookbehind) + 1; // exclude starting `{`
        if (tokenOffset > 0) {
            const query = currentText.slice(tokenOffset);

            this.suggestionRefs = {};
            this.setState({
                query,
                autocompleteOpen: true,
                currentSuggestions: this.filterSuggestions(query),
                currentTokenOffset: tokenOffset,
                selectedSuggestion: 0,
            });
        } else {
            this.resetAutocomplete();
        }
    }

    updateSelectedSuggestion = (e: React.KeyboardEvent) => {
        const { autocompleteOpen, currentSuggestions, selectedSuggestion } = this.state;

        if (!autocompleteOpen || !currentSuggestions.length) {
            return;
        }

        let newSelect;
        if (e.key === 'ArrowDown') {
            newSelect =
                selectedSuggestion === undefined ? 0 : Math.min(currentSuggestions.length - 1, selectedSuggestion + 1);
        } else if (e.key === 'ArrowUp' && selectedSuggestion !== undefined) {
            newSelect = Math.max(0, selectedSuggestion - 1);
        } else {
            // Up arrow and no selectedSuggestion
            this.resetAutocomplete();
            return;
        }

        // Scroll to suggestion
        const el = this.suggestionRefs[newSelect];
        if (el) {
            // Element.scrollIntoViewIfNeeded() currently supported by Chrome, Safari  - not FireFoox, Edge/IE
            // See https://caniuse.com/#search=scrollIntoViewIfNeeded
            if (typeof el.scrollIntoViewIfNeeded === 'function') {
                el.scrollIntoViewIfNeeded({ behavior: 'smooth' });
            } else {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        }

        this.setState({ selectedSuggestion: newSelect });
        e.preventDefault();
    };

    /**
     * Update WidgetContainer content with editor state - maintain updated content versions with tokens
     * as well as with mapped token values for edit/viewing.
     * Updating the WidgetContainer content will trigger report patch save.
     * @param editorState - current editor's state
     */
    updateWidgetContent(editorState: EditorState) {
        // Map to token values and save as separate Block Map
        const rawTextContent: RawDraftContentState = convertToRaw(editorState.getCurrentContent());

        // Clean up styleMap
        const styleMap = this.pruneStyleMap(rawTextContent);

        this.props.updateWidgetContent({ rawTextContent, styleMap });
    }
}

const mapStateToViewProps = () => {
    const { getStyleMap } = repSelectors;

    return (_state: IAppState, ownProps: ViewProps) => ({
        styleMap: getStyleMap(ownProps),
    });
};

const mapStateToEditProps = () => {
    const { getStyleMap } = repSelectors;
    const { createEditorState, getCompletions } = selectors;

    return (_state: IAppState, ownProps: EditProps) => ({
        completions: getCompletions(ownProps),
        editorState: createEditorState(ownProps),
        styleMap: getStyleMap(ownProps),
    });
};

const ViewTextComponentContainer = connect(mapStateToViewProps)(injectIntl(ViewTextComponent));
const EditTextComponentContainer = connect(mapStateToEditProps)(injectIntl(EditTextComponent));

export { ViewTextComponentContainer as ViewTextComponent, EditTextComponentContainer as EditTextComponent };
