import { omit } from 'lodash';
import * as React from 'react';

import { IInputGroupProps2, InputGroup } from '@blueprintjs/core';

interface IFormattedInputProps extends IInputGroupProps2 {
    value: any;

    // input formatting
    valueToDisplayText?: (val: any) => string;
    valueToEditText?: (val: any) => string;
    textToValue?: (val: string) => any;
    numericInputOnly?: boolean;

    // event handlers
    onChange?: (val: any) => void;
    onConfirm?: (val: any) => void;
    onEnter?: (val: any) => void;
    onEsc?: () => void;

    selectAllOnFocus?: boolean;
    style?: React.CSSProperties;
    disabled?: boolean;
}

interface IFormattedInputState {
    isEditing: boolean;
    editValue?: string;
    needsFocus: boolean;
}

export class FormattedInput extends React.Component<IFormattedInputProps, IFormattedInputState> {
    state = {
        isEditing: false,
        editValue: undefined,
        needsFocus: false,
    };

    getValue(text: string) {
        const { textToValue } = this.props;
        return textToValue ? textToValue(text) : text;
    }

    updateValue(text: string) {
        const { onConfirm, value } = this.props;
        const newValue = this.getValue(text);

        if (newValue !== value) {
            if (onConfirm) {
                onConfirm(newValue);
            }
        }
    }

    onChange = (evt) => {
        const { onChange } = this.props;
        const editValue = evt.target.value;

        this.setState({ editValue });
        onChange && onChange(this.getValue(editValue));
    };

    onKeyDown = (evt) => {
        const { onEnter, onEsc } = this.props;

        switch (evt.keyCode) {
            case 13:
                // on Enter
                evt.currentTarget.blur();
                onEnter && onEnter(this.getValue(evt.target.value));
                break;
            case 27:
                // on Escape
                this.setState({ isEditing: false });
                onEsc && onEsc();
                break;
        }
    };

    render() {
        const { value, valueToDisplayText, valueToEditText, selectAllOnFocus, numericInputOnly, ...otherProps } =
            this.props;
        const { isEditing, editValue, needsFocus } = this.state;

        // Remove custom event handlers
        const inputProps = omit(otherProps, ['onConfirm', 'onChange', 'onEnter', 'onEsc', 'textToValue']);
        const extra = {} as any;

        if (isEditing) {
            extra.inputRef = (ele) => {
                if (!ele) return;
                if (needsFocus) {
                    const { length } = ele.value;
                    ele.setSelectionRange(selectAllOnFocus ? 0 : length, length);
                    if (numericInputOnly) ele.type = 'number';
                    this.setState({ needsFocus: false });
                }
            };
        }

        return (
            <InputGroup
                onFocus={() => {
                    if (!isEditing) {
                        const editValue = valueToEditText ? valueToEditText(value) : value;
                        this.setState({
                            editValue,
                            needsFocus: true,
                            isEditing: true,
                        });
                    }
                }}
                onBlur={(evt) => {
                    if (!numericInputOnly || evt.currentTarget.value !== '') {
                        this.updateValue(evt.currentTarget.value);
                    }
                    this.setState({ isEditing: false });
                    if (numericInputOnly) evt.currentTarget.type = 'text';
                }}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                value={isEditing ? editValue : valueToDisplayText ? valueToDisplayText(value) : value}
                {...inputProps}
                {...extra}
            />
        );
    }
}

export default FormattedInput;
