/* Save and cancel controls - works with AsyncSaver
 *
 * Example usage,
 *    <SaveCancelButtons
 *        onSave={projectSaver.get(project).save}
 *        onCancel={projectSaver.get(project).clear}
 *        hasChanges={!projectSaver.get(project).isEmpty()}
 *        addToast={false}
 *    />
 */
import Logger from 'js-logger';
import * as React from 'react';
import styled from 'styled-components';

import { IconNames } from '@blueprintjs/icons';

import Flex from 'reports/components/core/containers/Flex';
import { addPromiseToasts } from 'reports/modules/Toaster';

import { PrimaryButton, Button as SecondaryButton } from './index';

const logger = Logger.get('forms');

const Container = styled(Flex.Container)`
    justify-content: flex-end;
`;

interface ISaveCancelProps {
    onSave: () => Promise<any>;
    onCancel: () => any;
    hasChanges: boolean;
    toastConfig?: { description?: string };
    style?: React.CSSProperties;
    disabled?: boolean;
    allowEmptyCancel?: boolean;
}

export class SaveCancelButtons extends React.PureComponent<ISaveCancelProps> {
    save = async () => {
        const { onSave, toastConfig } = this.props;
        if (toastConfig != null) {
            const { description = 'changes' } = toastConfig;

            await addPromiseToasts(onSave(), {
                initial: `Saving ${description}`,
                onCatch: (err) => (typeof err === 'string' ? err : `Error saving ${description}`),
                onSuccess: (res) => (res == null ? `No changes to save` : `Successfully saved ${description}`),
            });
        } else {
            try {
                await onSave();
            } catch (exc) {
                logger.warn(`Error saving changes: ${exc}`);
                return;
            }
        }
    };

    render() {
        const { hasChanges, onCancel, style, disabled = false, allowEmptyCancel = false } = this.props;
        return (
            <Container style={style}>
                <SecondaryButton
                    text="Cancel"
                    onClick={onCancel}
                    disabled={disabled || (!hasChanges && !allowEmptyCancel)}
                />
                <PrimaryButton
                    text="Save"
                    icon={IconNames.FLOPPY_DISK}
                    onClick={this.save}
                    disabled={disabled || !hasChanges}
                    style={{ marginLeft: 6 }}
                />
            </Container>
        );
    }
}

export default SaveCancelButtons;
