import { clamp, groupBy, map, round } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';

import { FormattedMessage, MessageDescriptor, IntlShape, injectIntl } from 'react-intl';

import { Alignment, Button, Classes, Colors, Icon, Switch } from '@blueprintjs/core';
import { IconNames, IconName } from '@blueprintjs/icons';

import { IAppState } from 'reports/types';

import Translations from 'reports/localization/strings';

import { Collapsible, Flex } from 'reports/components/core/containers';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms/index';

import * as auth from 'reports/modules/auth';
import * as styles from 'reports/styles/styled-components';

import { Document } from 'reports/models/report';
import { selectors as teamSelectors, Team } from 'reports/models/team';

import { helioscopeAdmin } from 'reports/modules/auth/permissions';
import { getWidgets, WIDGET_CATEGORIES } from 'reports/modules/report/widgets';
import { WidgetStyle } from 'reports/modules/report/widget_style';

import { PanelBody, PanelColumn, PanelColumnBody, PanelHeader } from './helpers';

const styled = styles.styled;

const CenteredLine = styled(Flex.Container).attrs({ className: 'center' })`
    padding: 5px 0;

    .${Classes.SWITCH} {
        margin-bottom: 0;
    }
`;

const WidgetPanel = styled.div.attrs({
    className: 'block',
})`
    justify-content: center;
    padding: 4px 0;
`;

const WidgetSearch = styled.div`
    input.${Classes.INPUT} {
        width: 100%;
        margin: 6px 0;
        border-radius: 4px;
        padding: 12px 4px;
    }
`;

const WidgetGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const WidgetButton = styled(Button)`
    &.${Classes.BUTTON} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 76px;
        min-width: 76px;
        margin: 3px;
        background: #fff;
        border: 1px solid ${Colors.LIGHT_GRAY4};
        text-align: center;
        cursor: default;

        &[class*='bs3-intent-']:hover {
            background: rgba(167, 182, 194, 0.3);
        }

        span.${Classes.BUTTON_TEXT} {
            width: inherit;
            word-wrap: break-word;
            padding: 6px 10px 0;
            color: ${Colors.GRAY1};
            font-size: 10px;
            font-weight: 400;
            line-height: 1.2;
        }

        .${Classes.ICON} {
            margin-right: 0;
        }
    }
`;

export interface IWidgetButton {
    name: string;
    description: string;
    displayName: MessageDescriptor;
    icon: IconName;
    iconText: string;
    adminOnly?: boolean;
}

export function getWidgetTypes(): IWidgetButton[] {
    return map(getWidgets(), ({ metadata }, name) => {
        let widgetData;

        if (metadata !== undefined) {
            widgetData = {
                name,
                category: metadata.category,
                description: metadata.description,
                displayName: metadata.displayName,
                icon: metadata.icon,
                iconText: name,
                adminOnly: metadata.adminOnly,
            };
        } else {
            widgetData = { name };
        }
        return widgetData;
    }).filter(({ category, icon }) => category !== 'ignore' && !!icon);
}

interface ICollapsibleSection {
    icon: IconName;
    title: string;
    defaultOpen?: boolean;
    children?: React.ReactNode;
}

const CollapsibleSection = ({ icon, title, defaultOpen = true, ...props }: ICollapsibleSection) => (
    <Collapsible
        renderHeader={(toggleCollapse, isOpen) => (
            <PanelHeader onClick={toggleCollapse}>
                <Flex.Container alignV={Flex.AlignV.CENTER}>
                    <Icon icon={icon} iconSize={12} style={{ marginRight: 8 }} />
                    {title}
                </Flex.Container>
                <div>
                    <Button
                        minimal
                        icon={isOpen ? IconNames.DOUBLE_CHEVRON_UP : IconNames.DOUBLE_CHEVRON_DOWN}
                        onClick={toggleCollapse}
                    />
                </div>
            </PanelHeader>
        )}
        defaultOpen={defaultOpen}
        style={{ maxHeight: 500, overflowY: 'auto' }}
    >
        {props.children}
    </Collapsible>
);

interface IFormatPanelProps {
    formRef: React.RefObject<HTMLDivElement>;

    team?: Team | null; // TODO: Check

    document: Document;
    editing: boolean;
    hasSelection: boolean;
    addWidget: (type: string) => void;
    // callback w/ newDoc after making any update EXCEPT adding widget.
    onUpdate: (newDoc: Document) => void;
}

interface IntlProps {
    intl: IntlShape;
}

interface IState {
    palette: string[];
    widgets: { [key: string]: IWidgetButton[] };
    filterBy?: string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IFormatPanelProps & IStateProps & IntlProps;

export class FormatPanel extends React.PureComponent<IProps, IState> {
    widgetTypes: IWidgetButton[] = this.getWidgetTypes();
    width: number = 300;

    state: IState = {
        palette: [],
        widgets: groupBy(this.widgetTypes, 'category'),
    };

    componentDidUpdate(prevProps) {
        const { theme } = this.props;

        if (!theme) return;

        if (
            (prevProps.theme && theme && prevProps.theme.logo_id !== theme.logo_id) ||
            ((prevProps.theme == null || theme == null) && prevProps.theme !== theme)
        ) {
            this.updateWidgetPanel();
        }
    }

    render() {
        const { intl, editing, hasSelection } = this.props;

        return (
            <PanelColumn
                style={
                    /* Hide format panel when it would be empty. Hide instead of returning null, since
                    SidebarFormatForm portal ref doesn't update properly after clicking a widget if the format panel
                    doesn't exist. */
                    !editing && !hasSelection ? { display: 'none' } : undefined
                }
            >
                <PanelColumnBody>
                    {this.renderWidgetConfigureSection()}
                    {editing && (
                        <>
                            <CollapsibleSection
                                icon={IconNames.WIDGET}
                                title={intl.formatMessage(Translations.report.editor_add_widgets)}
                            >
                                {this.renderWidgetAddSection()}
                            </CollapsibleSection>

                            <CollapsibleSection
                                icon={IconNames.SETTINGS}
                                title={intl.formatMessage(Translations.general.advanced)}
                            >
                                {this.renderAdvancedSection()}
                            </CollapsibleSection>
                        </>
                    )}
                </PanelColumnBody>
                <div style={{ height: '90px', borderTop: '1px solid #e2e2e2' }} />
            </PanelColumn>
        );
    }

    getWidgetTypes() {
        const { theme } = this.props;
        let allTypes = getWidgetTypes();

        if (!this.props.user || !this.props.user.is_admin) {
            allTypes = allTypes.filter((i) => !i.adminOnly);
        }

        // Exclude logo widget if no logo
        if (!theme || theme.logo_id == null) {
            return allTypes.filter(({ name }) => name !== 'team.logo');
        }
        return allTypes;
    }

    onChange = (filterBy: string) => this.setState({ filterBy });

    renderWidgetAddSection = () => (
        <PanelBody>
            <WidgetSearch>
                <input
                    className={Classes.INPUT}
                    type="search"
                    onChange={(e) => this.onChange(e.target.value)}
                    placeholder={this.props.intl.formatMessage(Translations.report.editor_filter_widgets)}
                />
            </WidgetSearch>
            <WidgetPanel>{map(this.state.widgets, this.renderGroup)}</WidgetPanel>
        </PanelBody>
    );

    setWidgetScale = (widgetScale: number) =>
        this.props.onUpdate(
            this.props.document.patch({
                widgetScale: clamp(widgetScale, 0.25, 2),
            }),
        );
    toggleWidgetStyle = () => this.props.onUpdate(this.props.document.toggleWidgetStyle());

    renderAdvancedSection() {
        const { document, user } = this.props;
        const { widgetScale, widgetStyle } = document;

        const scales = [0.4, 0.5, 0.6, 0.7, 0.8, 1, 1.25, 1.5];

        const scaleToString = (x) => `${round(x * 100)}%`;

        return (
            <PanelBody>
                {user && helioscopeAdmin(user) ? (
                    <CenteredLine>
                        <Flex.Main>
                            <FormattedMessage {...Translations.report.editor_widget_scale} />
                        </Flex.Main>
                        <StaticSelect<number>
                            items={scales}
                            itemRenderer={(scale, { handleClick, modifiers }) => (
                                <DropdownMenuItem
                                    key={scale}
                                    title={scaleToString(scale)}
                                    onClick={handleClick}
                                    {...modifiers}
                                />
                            )}
                            onItemSelect={(scale) => this.setWidgetScale(scale)}
                            buttonText={scaleToString(widgetScale)}
                        />
                    </CenteredLine>
                ) : null}

                <CenteredLine>
                    <Flex.Main>
                        <FormattedMessage {...Translations.report.editor_classic_widgets} />
                    </Flex.Main>
                    <Switch
                        checked={widgetStyle === WidgetStyle.classic}
                        onChange={this.toggleWidgetStyle}
                        alignIndicator={Alignment.RIGHT}
                        large={true}
                    />
                </CenteredLine>
            </PanelBody>
        );
    }

    renderGroup = (group: IWidgetButton[], category: string) => {
        const { filterBy } = this.state;
        const { intl } = this.props;
        const height = (this.width - 60) / 3 - 1;
        const width = height;

        let filtered = group;
        if (filterBy && filterBy.length > 1) {
            const regex = new RegExp(filterBy, 'i');
            filtered = group.filter(({ displayName }) => regex.test(intl.formatMessage(displayName)));
        }

        return filtered.length ? (
            <div key={category}>
                <div style={{ paddingTop: 8, fontSize: 14 }}>
                    <FormattedMessage {...WIDGET_CATEGORIES[category]} />
                </div>
                <WidgetGroup>
                    {filtered.map(({ name, icon, displayName }) => {
                        const iconName = icon ? icon : IconNames.WIDGET;
                        return (
                            <WidgetButton
                                key={name}
                                style={{ height, width }}
                                icon={<Icon icon={iconName} iconSize={20} />}
                                text={intl.formatMessage(displayName)}
                                onClick={() => this.props.addWidget(name)}
                                className={Classes.MINIMAL}
                            />
                        );
                    })}
                </WidgetGroup>
            </div>
        ) : null;
    };

    renderWidgetConfigureSection = () => (
        // stop sidebar form events from bubbling and rendering the widget's context menu
        // If no widget is selected, the ref DOM element will have no children. We need to
        // still render the ref so that the ref already exists by the time a widget is selected
        <div
            onContextMenu={(e) => e.stopPropagation()}
            style={!this.props.hasSelection ? { display: 'none' } : undefined}
        >
            <PanelHeader>
                <Flex.Container className="center">
                    <Icon icon={IconNames.STYLE} iconSize={12} style={{ marginRight: 8 }} />
                    <FormattedMessage {...Translations.widgets.configure} />
                </Flex.Container>
            </PanelHeader>

            <PanelBody ref={this.props.formRef} />
        </div>
    );

    updateWidgetPanel = () => this.setState({ widgets: groupBy(this.getWidgetTypes(), 'category') });
}

const mapStateToProps = (state: IAppState, { team }: IFormatPanelProps) => ({
    theme: team ? teamSelectors.themeSelector(state, team) : null,
    user: auth.selectors.getUser(state),
});

export default connect<IStateProps, IFormatPanelProps>(mapStateToProps)(injectIntl(FormatPanel));
