import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useRoute } from 'react-router5';
import { actions as routerActions } from 'redux-router5';
import { RouteComponent } from 'reports/routing';

import { ProfileLibrary } from 'reports/modules/profile/components/ProfileLibrary';
import { ProfilePreview } from 'reports/modules/profile/components/ProfilePreview';
import { ProfileEditor } from 'reports/modules/profile/components/ProfileEditor';

const Library = () => {
    const dispatch = useDispatch();
    const listRoute = 'app.profiles';
    const editRoute = 'app.profiles.profile.edit';
    const previewRoute = 'app.profiles.profile.preview';
    const { route } = useRoute();
    const navigateToProfile = (profileId: number) =>
        dispatch(
            routerActions.navigateTo(editRoute, {
                profileId,
            }),
        );

    const navigateToProfilePreview = (profileId: number) =>
        dispatch(
            routerActions.navigateTo(previewRoute, {
                profileId,
            }),
        );

    const navigateToProfiles = () => dispatch(routerActions.navigateTo(listRoute));

    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name={listRoute} exact={false} nameNot={editRoute}>
                    <ProfileLibrary
                        profileId={route.params.profileId}
                        preview={
                            <RouteComponent name={previewRoute}>
                                {({ profile }) => (
                                    <ProfilePreview
                                        navigateToProfile={navigateToProfile}
                                        navigateToProfiles={navigateToProfiles}
                                        profile={profile}
                                    />
                                )}
                            </RouteComponent>
                        }
                        navigateToProfile={navigateToProfile}
                        navigateToProfilePreview={navigateToProfilePreview}
                    />
                </RouteComponent>
                <RouteComponent name={editRoute}>
                    {({ profile }) => (
                        <ProfileEditor navigateToProfilePreview={navigateToProfilePreview} profile={profile} />
                    )}
                </RouteComponent>
            </div>
        </div>
    );
};

export { Library };
