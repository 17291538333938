import React from 'react';

import { useSelector } from 'react-redux';

import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import { Profile, selectors as profileSelectors } from 'reports/models/profile';

import { ProfileLibrary } from 'reports/modules/profile/components/ProfileLibrary';
import { ProfilePreview as BetaProfilePreview } from 'reports/modules/profile/components/ProfilePreview';

import { IAppState } from 'reports/store';

const ProfilePreview = () => {
    const navigate = useNavigate();

    const profileToSelect = useLoaderData() as Profile;
    const profile = useSelector((state) =>
        profileSelectors.byObject(state as IAppState, {
            profile_id: profileToSelect.profile_id,
        }),
    );

    const navigateToProfiles = () =>
        navigate({
            pathname: '/profiles',
        });

    const navigateToProfile = (profileId: number) =>
        navigate({
            pathname: generatePath('/profiles/:profileId/edit', { profileId: profileId.toString() }),
        });

    const navigateToProfilePreview = (profileId: number) =>
        navigate({
            pathname: generatePath('/profiles/:profileId/preview', { profileId: profileId.toString() }),
        });

    if (!profile) {
        throw new Response('Not Found', { status: 404 });
    }

    return (
        <ProfileLibrary
            inlineControls={true}
            navigateToProfile={navigateToProfile}
            navigateToProfilePreview={navigateToProfilePreview}
            preview={
                <BetaProfilePreview
                    navigateToProfiles={navigateToProfiles}
                    navigateToProfile={navigateToProfile}
                    profile={profile}
                />
            }
            profileId={profile.profile_id}
        />
    );
};

export { ProfilePreview };
