/* tslint:disable:variable-name */
import { startCase, lowerCase } from 'lodash';
import { GeoPoint } from 'helioscope/app/utilities/geometry';

import { ReduxEndpoint, BaseClass } from 'reports/utils/api';
import { schema } from './schema';

const properCase = (str) => startCase(lowerCase(str));

class AshraeWeather extends BaseClass {
    ashrae_weather_id: number;
    name: string;
    min_temp_extreme_mean: number;
    max_temp_extreme_mean: number;
    state?: string;
    country?: string;

    constructor(data) {
        super(AshraeWeather.deserializer(data));
    }

    get minTemp() {
        return this.min_temp_extreme_mean;
    }

    get maxTemp() {
        return this.max_temp_extreme_mean;
    }

    toString() {
        if (this.ashrae_weather_id !== undefined) {
            return properCase(this.name);
        }

        return `${properCase(this.name)}, ${this.state || this.country || ''} (ASHRAE)`;
    }

    static deserializer = BaseClass.getDeserializer({
        location: (x) => new GeoPoint(x),
    });
}

const schemaObj = schema.addObject(AshraeWeather, 'ashrae_weather');

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

const endpoint = ReduxEndpoint.fromSchema('/api/ashrae_weather/', schemaObj);

interface IQueryOpts {
    location?: GeoPoint;
    distance?: number;
    limit?: number;
}

const api = {
    index: endpoint.index<IQueryOpts>(),
    // get: endpoint.get<{ ashrae_weather_id: string | number }>('{ashrae_weather_id}'),
};

export { AshraeWeather, api, schemaObj, selectors };
