import * as DS from '@aurorasolar/ds';

const logoPath = require('frontend/media/helioscope-logo.png');
const brandmarkPath = require('frontend/media/helioscope-brandmark.png');

const HS_LOGO = DS.generateImageIcon(logoPath, 'Helioscope Logo', 'fill');
const HS_LOGO_SIZE = { h: '40px', w: '188px', mt: '6px', mb: '-2px', mx: '6px' };
const HS_BRANDMARK = DS.generateImageIcon(brandmarkPath, 'Helioscope Brandmark', 'fill');
const HS_BRANDMARK_SIZE = { h: '20px', w: '20px' };

export { HS_BRANDMARK, HS_BRANDMARK_SIZE, HS_LOGO, HS_LOGO_SIZE };
