/* tslint:disable:variable-name */

import { ReduxEndpoint, BaseClass, createAsyncSaver } from 'reports/utils/api';

import * as finTemp from './financial_template';
import { Project } from './project';
import { schema } from './schema';

class ProjectFinancialTemplate extends BaseClass {
    project_financial_template_id: number;

    financial_template_id: number;
    financial_template: finTemp.FinancialTemplate;

    name: string;
    financial_template_version_id: number | null;

    project_id: number;
    project: Project;

    template_data: any;
    configuration_data: any;
}

const schemaObj = schema.addObject(ProjectFinancialTemplate, 'project_financial_template', {
    relationships: {
        financial_template: { schema: finTemp.schemaObj },
    },
});

export interface IProjectFinancialTemplateForm {
    project_id: number;
    financial_template_id: number;

    name: string;

    financial_template_version_id?: number;
    template_data?: any;
    configuration_data?: any;
}

const endpoint = ReduxEndpoint.fromSchema('/api/project_financial_templates/', schemaObj);

interface IProjectFinancialTemplateAPIQueryOpts {
    financial_template_id?: number;
    project_id?: number;
    name?: string;
}

const api = {
    index: endpoint.index<IProjectFinancialTemplateAPIQueryOpts>(),
    get: endpoint.get<{ project_financial_template_id: number | string }>('{project_financial_template_id}'),
    create: endpoint.post<IProjectFinancialTemplateForm>(),
    save: endpoint.put('{project_financial_template_id}'),
    delete: endpoint.delete('{project_financial_template_id}'),
};

const selectors = {
    patchesById: (state, id) => schemaObj.selectPatchesById(state, id),
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

const saver = createAsyncSaver(schemaObj, api.save);

export { ProjectFinancialTemplate, api, saver, schemaObj, selectors };
