import { flatten, sumBy, map, compact, flatMap, groupBy, first } from 'lodash';

import { createSelector } from 'reselect';

import { Module } from 'reports/models/module';

import * as des from 'reports/models/design';

export interface IBOMLineItem {
    componentType: string;
    name: string;
    count: number;
}

interface IWireSummarizerCount {
    count: number;
    type: string;
    name: string;
    length: number;
}

interface IModuleCount {
    count: number;
    module: Module;
}

export const COMBINER_TYPES = {
    ACPanels: { name: 'AC Panels' },
    Combiners: { name: 'Combiners' },
};

export const WIRING_TYPES = {
    Strings: { name: 'Strings' },
    ACBranches: { name: 'AC Branches' },
    ACHomeRuns: { name: 'AC Home Runs' },
    HomeRuns: { name: 'Home Runs' },
};

export type CombinerTypes = keyof typeof COMBINER_TYPES;
export type WiringTypes = keyof typeof WIRING_TYPES;

function summarizeCombiner(type: CombinerTypes) {
    return (combiners: des.ICombinerMetadata[], { inputs } = first(combiners) as des.ICombinerMetadata) => ({
        type: COMBINER_TYPES[type].name,
        name: `${inputs} input ${COMBINER_TYPES[type].name}`,
        count: sumBy(combiners, 'count'),
    });
}

function summarizeWire(type: WiringTypes) {
    return (wires: des.IWireMetadata[], { wire_gauge } = first(wires) as des.IWireMetadata) => {
        // note: the field components API aggregates bom_length into length, so just use length here
        const length = sumBy(wires, 'length');
        if (!length) {
            return null;
        }

        return {
            length,
            type: WIRING_TYPES[type].name,
            name: wire_gauge,
            count: sumBy(wires, 'count'),
        };
    };
}

function parseWiringZoneData(wiringZonesData, componentKey, groupByKey, summarize) {
    // for each wiringZone, get the array for the category
    // flatten the array into a list
    let components = flatMap(wiringZonesData, componentKey);

    components = compact(components); // remove unused element
    // groupBy the unique identifier for the group
    const grouped = groupBy(components, groupByKey);
    const res = map(grouped, (subComponents) => summarize(subComponents));
    return res;
}

export function aggregateCombiners(design: des.Design): IBOMLineItem[] {
    let results: IWireSummarizerCount[] = [];
    const acPanel = parseWiringZoneData(
        design.field_component_metadata.wiring_zones,
        'ac_panel',
        'inputs',
        summarizeCombiner('ACPanels'),
    );
    const combiner = parseWiringZoneData(
        design.field_component_metadata.wiring_zones,
        'combiner',
        'inputs',
        summarizeCombiner('Combiners'),
    );
    results = results.concat(acPanel).concat(combiner);
    return results.map((r) => ({ name: r.name, componentType: r.type, count: r.count }));
}

export function aggregateWires(design: des.Design): IBOMLineItem[] {
    let results: IWireSummarizerCount[] = [];
    const strings: IWireSummarizerCount[] = parseWiringZoneData(
        design.field_component_metadata.wiring_zones,
        'string',
        'wire_gauge_id',
        summarizeWire('Strings'),
    );
    const acBranch: IWireSummarizerCount[] = parseWiringZoneData(
        design.field_component_metadata.wiring_zones,
        'ac_branch',
        'wire_gauge_id',
        summarizeWire('ACBranches'),
    );
    const acRun: IWireSummarizerCount[] = parseWiringZoneData(
        design.field_component_metadata.wiring_zones,
        'ac_run',
        'wire_gauge_id',
        summarizeWire('ACHomeRuns'),
    );
    const bus: IWireSummarizerCount[] = parseWiringZoneData(
        design.field_component_metadata.wiring_zones,
        'bus',
        'wire_gauge_id',
        summarizeWire('HomeRuns'),
    );
    results = compact(results.concat(strings).concat(acBranch).concat(bus).concat(acRun));

    return results.map((r) => ({ name: r.name, componentType: r.type, count: r.count }));
}

export function aggregateDevices(design: des.Design): IBOMLineItem[] {
    const DEVICE_NAMES = {
        inverter: 'Inverters',
        optimizer: 'Optimizers',
    };

    const results = ['inverter', 'optimizer'].map((type) => {
        const resultsForType: { [id: number]: IBOMLineItem } = {};

        Object.values(design.field_component_metadata.wiring_zones).forEach((wiringZone) => {
            if (type in wiringZone) {
                for (const deviceMetadata of wiringZone[type]) {
                    const deviceId = deviceMetadata.power_device_id;

                    if (resultsForType[deviceId] === undefined) {
                        resultsForType[deviceId] = {
                            name: deviceMetadata.name,
                            componentType: DEVICE_NAMES[type],
                            count: deviceMetadata.count,
                        };
                    } else {
                        resultsForType[deviceId].count += deviceMetadata.count;
                    }
                }
            }
        });

        return Object.values(resultsForType);
    });

    return compact(flatten(results));
}

export function aggregateModules(design: des.Design) {
    const results: { [id: number]: IModuleCount } = {};
    const moduleDefaults = { componentType: 'Modules' };
    for (const fieldSegment of design.field_segments) {
        if (fieldSegment.module_characterization == null) {
            continue;
        }
        if (results[fieldSegment.module_characterization.module_id] == null) {
            results[fieldSegment.module_characterization.module_id] = {
                module: fieldSegment.module_characterization.module,
                count: fieldSegment.data.modules,
            };
        } else {
            results[fieldSegment.module_characterization.module_id].count += fieldSegment.data.modules;
        }
    }

    return Object.values(results).map((r) => ({ ...moduleDefaults, name: r.module.name, count: r.count }));
}

export function aggregateComponentCounts(design: null | undefined | des.Design) {
    if (design == null || design.field_component_metadata == null) {
        return [] as IBOMLineItem[];
    }
    return aggregateModules(design)
        .concat(aggregateDevices(design))
        .concat(aggregateWires(design))
        .concat(aggregateCombiners(design)) as IBOMLineItem[];
}

export const selectors = {
    get designComponentCounts() {
        return createSelector(
            (state, { design }: { design: des.Design }) => {
                return des.selectors.byObject(state, design, {
                    field_segments: { module_characterization: ['module'] },
                    wiring_zones: '*',
                });
            },
            (design) => {
                return aggregateComponentCounts(design);
            },
        );
    },
};
