import { FormGroup, Radio, Slider } from '@blueprintjs/core';
import _ from 'lodash';
import Highcharts from 'highcharts';
import * as React from 'react';
import ReactHighcharts from 'react-highcharts';
import styled from 'styled-components';

import { Flex } from 'reports/components/core/containers';
import { RadioGroup } from 'reports/components/core/forms';
import { FormattedNumber, Integer, Irradiance, Percent, Temperature } from 'reports/components/core/numbers';

import HighchartsContainer from 'reports/components/helpers/HighchartsContainer';
import BasicTable from 'reports/components/core/tables/BasicTable';

import { HSLightTheme } from 'reports/styles/colors';
import * as fmt from 'reports/utils/formatters';

import { ModuleCharacterization } from 'reports/models/module';

const ControlsContainer = styled(Flex.Container)`
    justify-content: space-between;
    padding: 12px;
    background: ${HSLightTheme.backgroundMain};
    margin-bottom: 15px;
`;

const SliderFormGroup = styled(FormGroup)`
    width: 50%;
    /* Leave room for axis labels, which overflow sides of slider */
    padding-left: 5px;
    padding-right: 20px;
`;

interface IOwnProps {
    characterization: ModuleCharacterization;
}

// Ported from moduleIvCurves in app/libraries/directives.js
function makeConfig(characterization: ModuleCharacterization, conditions: IConditions): Highcharts.Options {
    const implementation = characterization.implementation();

    function makeAllSeries() {
        let seriesKeys;
        let fixedKey;

        if (conditions.dependence === 'irradiance') {
            seriesKeys = conditions.irradianceKeys;
            fixedKey = conditions.temperature;
        } else {
            seriesKeys = conditions.temperatureKeys;
            fixedKey = conditions.irradiance;
        }

        return _.map(seriesKeys, (seriesKey) => {
            const ivCurve: [number, number][] = [];
            if (conditions.dependence === 'irradiance') {
                implementation.setConditions(seriesKey, fixedKey);
            } else {
                implementation.setConditions(fixedKey, seriesKey);
            }
            const maxVoltage = implementation.vOc();

            for (let i = 0; i <= 80; i += 1) {
                const vDiode = (maxVoltage * i) / 75;
                const cur = implementation.current(vDiode);
                const vol = implementation.voltage(vDiode, cur);
                ivCurve.push([vol, cur * (conditions.graphType === 'power' ? vol : 1)]);
                if (cur <= 0) {
                    break;
                }
            }
            return {
                name: seriesKey,
                data: ivCurve,
            };
        });
    }

    return {
        chart: {
            type: 'line',
            spacingRight: 35,
        },
        title: {
            text: '',
        },
        xAxis: {
            min: 0,
            startOnTick: false,
            lineColor: '#444',
            title: {
                text: 'Voltage',
            },
        },
        yAxis: [
            {
                min: 0,
                title: {
                    text: 'Current',
                },
            },
        ],
        legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            backgroundColor: 'white',
            floating: true,
            enabled: true,
            labelFormatter(this: any) {
                if (conditions.dependence === 'irradiance') {
                    return fmt.irradiance(this.name);
                }
                return fmt.temperature(this.name);
            },
            useHTML: true,
            x: 25,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter(this: any) {
                const irradiance = conditions.dependence === 'irradiance' ? this.series.name : conditions.irradiance;
                const temperature = conditions.dependence === 'irradiance' ? conditions.temperature : this.series.name;
                return (
                    fmt.irradiance(irradiance) +
                    ', ' +
                    fmt.temperature(temperature) +
                    '<br>' +
                    fmt.voltage(this.x, { precision: 2 }) +
                    ', ' +
                    (conditions.graphType === 'power'
                        ? fmt.power(this.y, { precision: 2 })
                        : fmt.current(this.y, { precision: 2 }))
                );
            },
            useHTML: true,
        },
        plotOptions: {
            series: {
                animation: false,
                marker: {
                    enabled: false,
                },
            },
        },
        series: makeAllSeries(),
    };
}

interface IConditions {
    temperature: number;
    irradiance: number;
    irradianceKeys: number[];
    temperatureKeys: number[];
    graphType: 'current' | 'power';
    dependence: 'irradiance' | 'temperature';
}

const INITIAL_CONDITIONS: IConditions = {
    temperature: 25,
    irradiance: 1000,
    irradianceKeys: [1000, 800, 600, 400, 200, 100],
    temperatureKeys: [20, 25, 30, 35, 45, 55],
    graphType: 'current',
    dependence: 'irradiance',
};

// Ported from ModuleCharacterizationDetailCtrl in app/libraries/controllers.js
const PerfTable = ({ characterization, conditions }) => {
    let conditionTuples;
    if (conditions.dependence === 'irradiance') {
        conditionTuples = conditions.irradianceKeys.map((i) => ({
            irradiance: i,
            temperature: conditions.temperature,
        }));
    } else {
        conditionTuples = conditions.temperatureKeys.map((t) => ({
            irradiance: conditions.irradiance,
            temperature: t,
        }));
    }

    const impl = characterization.implementation();
    const conditionTable = conditionTuples.map(({ irradiance, temperature }) => {
        const c: any = { irradiance, temperature };
        impl.setConditions(irradiance, temperature);

        c.vOc = impl.vOc();
        c.iSc = impl.iSc();

        const powerTuple = impl.maxPower([0, c.vOc]);
        const tempDelta = 0.01;

        c.iMp = powerTuple.iMp;
        c.vMp = powerTuple.vMp;
        c.pMp = powerTuple.iMp * powerTuple.vMp;

        impl.setConditions(c.irradiance, c.temperature + tempDelta);
        const powerTupleDT = impl.maxPower([c.vMp - 1, c.vMp + 1]);

        c.dVdT = ((powerTupleDT.vMp - c.vMp) / tempDelta / c.vMp) * 100;
        c.dPdT = ((powerTupleDT.vMp * powerTupleDT.iMp - c.pMp) / tempDelta / c.pMp) * 100;
        c.dVocdT = ((impl.vOc() - c.vOc) / tempDelta / c.vOc) * 100;
        return c;
    });
    return (
        <BasicTable tableTheme="specs" width="100%">
            <thead>
                <tr>
                    <th>
                        {conditions.dependence === 'irradiance' ? (
                            <span>
                                Irradiance (W/m<sup>2</sup>)
                            </span>
                        ) : (
                            <span>Temperature (&deg;C)</span>
                        )}
                    </th>
                    <th>
                        I<sub>SC</sub>
                    </th>
                    <th>
                        V<sub>OC</sub>
                    </th>
                    <th>
                        I<sub>MP</sub>
                    </th>
                    <th>
                        V<sub>MP</sub>
                    </th>
                    <th>Power</th>
                    <th>
                        dP<sub>mp</sub>/dT
                    </th>
                    <th>
                        dV<sub>mp</sub>/dT
                    </th>
                    <th>
                        dV<sub>oc</sub>/dT
                    </th>
                </tr>
            </thead>
            <tbody>
                {conditionTable.map((c) => (
                    <tr key={`${c.irradiance},${c.temperature}`}>
                        <td>
                            <span>{conditions.dependence === 'irradiance' ? c.irradiance : c.temperature}</span>
                        </td>
                        <td>
                            <FormattedNumber value={c.iSc} precision={2} />
                        </td>
                        <td>
                            <FormattedNumber value={c.vOc} precision={1} />
                        </td>
                        <td>
                            <FormattedNumber value={c.iMp} precision={2} />
                        </td>
                        <td>
                            <FormattedNumber value={c.vMp} precision={1} />
                        </td>
                        <td>
                            <FormattedNumber value={c.pMp} precision={1} />
                        </td>
                        <td>
                            <Percent value={c.dPdT / 100} precision={2} />
                        </td>
                        <td>
                            <Percent value={c.dVdT / 100} precision={2} />
                        </td>
                        <td>
                            <Percent value={c.dVocdT / 100} precision={2} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </BasicTable>
    );
};

class ConditionsControls extends React.PureComponent<any> {
    state: { pendingTemp: number | null; pendingIrrad: number | null } = {
        pendingTemp: null,
        pendingIrrad: null,
    };

    patchConditions = (patch) => this.props.setConditions({ ...this.props.conditions, ...patch });
    // Changing conditions leads to a bunch of expensive computations being done, involving root finding.
    // When using slider, throttle so that conditions update at most 4 times a second. This is fast enough
    // to let user preview effect of slider on graph, before releasing slider.
    throttledPatch = _.throttle((patch) => this.patchConditions(patch), 250);

    render() {
        const { pendingIrrad, pendingTemp } = this.state;
        const { conditions } = this.props;

        return (
            <ControlsContainer>
                <RadioGroup
                    label="Chart Type"
                    selectedValue={conditions.graphType}
                    onChange={(val) => this.patchConditions({ graphType: val })}
                >
                    <Radio label="Current" value="current" />
                    <Radio label="Power" value="power" />
                </RadioGroup>

                <RadioGroup
                    label="Legend"
                    selectedValue={conditions.dependence}
                    onChange={(val) => this.patchConditions({ dependence: val })}
                >
                    <Radio label="Temperature" value="temperature" />
                    <Radio label="Irradiance" value="irradiance" />
                </RadioGroup>

                {conditions.dependence !== 'temperature' && (
                    <SliderFormGroup label="Temperature">
                        <Slider
                            initialValue={-30}
                            min={-30}
                            max={70}
                            value={pendingTemp || conditions.temperature}
                            onChange={(val) => {
                                this.setState({ pendingTemp: val });
                                this.throttledPatch({ temperature: val });
                            }}
                            onRelease={(val) => this.patchConditions({ temperature: val })}
                            labelRenderer={(val, opts) =>
                                opts?.isHandleTooltip ? (
                                    <Temperature value={val} precision={0} />
                                ) : (
                                    <Integer value={val} />
                                )
                            }
                            labelValues={[-30, 0, 30, 60]}
                        />
                    </SliderFormGroup>
                )}
                {conditions.dependence !== 'irradiance' && (
                    <SliderFormGroup label="Irradiance">
                        <Slider
                            min={1}
                            max={1500}
                            stepSize={10}
                            value={pendingIrrad || conditions.irradiance}
                            onChange={(val) => {
                                this.setState({ pendingIrrad: val });
                                this.throttledPatch({ irradiance: val });
                            }}
                            onRelease={(val) => this.patchConditions({ irradiance: val })}
                            labelRenderer={(val, opts) =>
                                opts?.isHandleTooltip ? (
                                    <Irradiance value={val} precision={0} />
                                ) : (
                                    <Integer value={val} />
                                )
                            }
                            labelValues={[1, 500, 1000, 1500]}
                        />
                    </SliderFormGroup>
                )}
            </ControlsContainer>
        );
    }
}

const ModeledPerformanceChart: React.FC<IOwnProps> = ({ characterization }) => {
    const [conditions, setConditions] = React.useState(INITIAL_CONDITIONS);

    return (
        <>
            <HighchartsContainer>
                <ReactHighcharts config={makeConfig(characterization, conditions)} isPureConfig={true} />
            </HighchartsContainer>
            <ConditionsControls conditions={conditions} setConditions={setConditions} />
            <PerfTable characterization={characterization} conditions={conditions} />
        </>
    );
};
export default ModeledPerformanceChart;
