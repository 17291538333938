import * as React from 'react';

import { uniqueId } from 'lodash';

let _dialogProvider: DomInjector | null = null;

function setDialogProvider(ref) {
    if (_dialogProvider != null) {
        throw Error('DialogProvider initialized multiple times');
    }
    _dialogProvider = ref;
}

function removeDialogProvider(ref) {
    if (_dialogProvider !== ref) {
        throw Error('Removing incorrect DialogProvider');
    }
    _dialogProvider = null;
}

interface IKeyedNode {
    key: string;
    node: React.ReactNode;
}

interface IState {
    nodes: IKeyedNode[];
}

class DomInjector extends React.PureComponent<{}, IState> {
    state: IState = { nodes: [] };

    componentDidMount() {
        setDialogProvider(this);
    }

    componentWillUnmount() {
        removeDialogProvider(this);
    }

    insertNode(node: React.ReactNode) {
        const key = uniqueId('dlg');

        this.setState({
            nodes: [...this.state.nodes, { key, node }],
        });

        return key;
    }

    removeNode(dialogKey: string) {
        this.setState({
            nodes: this.state.nodes.filter(({ key }) => key !== dialogKey),
        });
    }

    render() {
        return (
            <>
                {this.state.nodes.map(({ key, node }) => (
                    <span key={key}>{node}</span>
                ))}
            </>
        );
    }
}

export function insertReactNode(node: React.ReactNode) {
    if (_dialogProvider == null) {
        throw Error('DialogProvider not initialized');
    }

    const key = _dialogProvider.insertNode(node);
    return () => _dialogProvider != null && _dialogProvider.removeNode(key);
}

export default DomInjector;
