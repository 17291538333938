import * as React from 'react';

import { UserFeatures as BetaUserFeatures } from 'reports/modules/settings/admin/views/UserFeatures';

import { useSettingsContext } from 'frontend/settings/use-settings-data';

const UserFeatures = () => {
    const { user } = useSettingsContext();

    return <BetaUserFeatures user={user} />;
};

export { UserFeatures };
