import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reports/types';
import { head } from 'lodash';

import * as auth from 'reports/modules/auth';
import { actions as projActions } from 'reports/modules/project';
import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';
import * as des from 'reports/models/design';
import * as sim from 'reports/models/simulation';

import Section2 from 'reports/components/core/containers/Section2';
import ButtonGroupSelect from 'reports/components/forms/inputs/experimental/ButtonGroupSelect';

import ProjectAssetPage from './ProjectAssetPage';
import { SimulationTable } from 'reports/modules/project/components/SimulationTable';
import { User } from 'reports/models/user';

interface Props {
    project: proj.Project;
}

interface MetricSelectProps {
    metric: string;
    setMetric: (metric: string) => void;
}

const METRICS = {
    system_yield: 'Specific Yield',
    system_performance_ratio: 'Performance Ratio',
    annual_production: 'Grid Power',
};

const MetricSelect = ({ metric, setMetric }: MetricSelectProps) => (
    <ButtonGroupSelect
        items={Object.keys(METRICS)}
        selectedItem={metric}
        onSelect={setMetric}
        itemRenderer={(item) => ({ key: item, text: METRICS[item] })}
        flex={false}
    />
);

const Simulations = (props: Props) => {
    const [metric, setMetric] = React.useState('system_yield');
    const { project } = props;

    const dispatch = useDispatch();

    const loadDesigns = () => dispatch(des.api.index({ project_id: project.project_id }));
    const loadScenarios = () => dispatch(scen.api.index({ project_id: project.project_id }));
    const loadSimulations = () => dispatch(sim.api.index({ project_id: project.project_id }));
    const triggerSimulation = (design: des.Design, scenario: scen.Scenario) => {
        dispatch(
            sim.api.create({
                design_id: design.design_id,
                scenario_id: scenario.scenario_id,
            }),
        );
    };
    const deleteDesign = async (design: des.Design) => {
        const delDesign = new des.Design({ ...design });
        delDesign.to_delete = true;
        return await dispatch(des.api.save(delDesign));
    };
    const undoDeleteDesign = async (design: des.Design) => {
        const restoreDesign = new des.Design({ ...design });
        restoreDesign.to_delete = false;
        return await dispatch(des.api.save(restoreDesign));
    };
    const deleteSimulation = (simulation: sim.Simulation) =>
        dispatch(sim.api.delete({ simulation_id: simulation.simulation_id }));
    const setPrimaryDesign = (design: des.Design) => dispatch(projActions.setPrimaryDesign(project, design));
    const setPrimaryScenario = (scenario: scen.Scenario) => dispatch(projActions.setPrimaryScenario(project, scenario));

    const user = useSelector((state) => auth.selectors.getUser(state as IAppState)) as User;
    const scenarios = useSelector((state) =>
        scen.selectors.all(state as IAppState, {
            filter: (obj) => obj.project_id === project.project_id,
        }),
    );
    const designs = useSelector((state) => proj.selectors.designs(state as IAppState, project));
    const getSimulation = (design: des.Design, scenario: scen.Scenario) =>
        useSelector((state) =>
            head(
                sim.selectors.all(state as IAppState, {
                    filter: ({ design_id, scenario_id }) =>
                        design_id === design.design_id && scenario_id === scenario.scenario_id,
                }),
            ),
        );

    const tableProps = {
        ...props,
        loadDesigns,
        loadScenarios,
        loadSimulations,
        triggerSimulation,
        deleteDesign,
        undoDeleteDesign,
        deleteSimulation,
        setPrimaryDesign,
        setPrimaryScenario,
        user,
        scenarios,
        designs,
        getSimulation,
    };

    React.useEffect(() => {
        loadDesigns();
    }, []);

    React.useEffect(() => {
        loadScenarios();
    }, []);

    React.useEffect(() => {
        loadSimulations();
    }, []);

    return (
        <ProjectAssetPage>
            <Section2
                title="Simulations"
                subtitle={`For each combination of Design and Condition Set,
                           HelioScope can simulate the array's energy yield to generate a Report.`}
            >
                <MetricSelect metric={metric} setMetric={setMetric} />
                <hr />
                <SimulationTable metric={metric} {...tableProps} />
            </Section2>
        </ProjectAssetPage>
    );
};

export { Simulations };
