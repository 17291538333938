import createRouter from 'router5';
import browserPlugin from 'router5/plugins/browser';
import routes from './config/routes';

import {
    dependencyMiddlewareFactory,
    IRouterOptions,
    IBrowserPluginOptions,
    makeRouteComponent,
} from 'reports/utils/router';

import navPlugin from './navPlugin';
import { adminUserPlugin } from 'reports/modules/auth/components/AdminUserToast';

export function makeRouter(routerOptions: IRouterOptions, browserOptions: IBrowserPluginOptions) {
    const router = createRouter(routes, routerOptions);

    router.usePlugin(browserPlugin(browserOptions));
    router.usePlugin(navPlugin());
    router.usePlugin(adminUserPlugin());

    router.useMiddleware(dependencyMiddlewareFactory(routes));
    return router;
}

export const RouteComponent = makeRouteComponent(routes);
