import _ from 'lodash';
import Highcharts from 'highcharts';
import * as React from 'react';
import ReactHighcharts from 'react-highcharts';

import HighchartsContainer from 'reports/components/helpers/HighchartsContainer';
import { humanizeWatts, percentage, voltage } from 'reports/utils/formatters';
import { PowerDeviceCharacterization } from 'reports/models/power_device';

interface IOwnProps {
    characterization: PowerDeviceCharacterization;
}

function makeConfig(characterization: PowerDeviceCharacterization): Highcharts.Options {
    let seriesName = characterization.power_device.efficiency_factor_1;
    let seriesKey = 'factor_1';
    let xName = characterization.power_device.efficiency_factor_2;
    let xKey = 'factor_2';

    if (characterization.power_device.device_type_name === 'buck_optimizer') {
        seriesName = characterization.power_device.efficiency_factor_2;
        seriesKey = 'factor_2';
        xName = characterization.power_device.efficiency_factor_1;
        xKey = 'factor_1';
    }

    const seriesValues: number[] = _(characterization.efficiency_points).map(seriesKey).uniq().value();

    let xFilter;
    if (xName === 'Power') {
        // Need to use "function" so that highcharts library can provide "this.value".
        xFilter = function (this: any) {
            return humanizeWatts(this.value);
        };
    } else if (xName === 'Power Ratio' || xName === 'Voltage Buck Ratio') {
        xFilter = function (this: any) {
            return percentage(this.value);
        };
    } else {
        xFilter = function (this: any) {
            return this.value;
        };
    }

    function makeAllSeries() {
        return seriesValues.map((seriesValue: number) => {
            const currentSeries = characterization.efficiency_points.filter((pt) => pt[seriesKey] === seriesValue);
            let xyCoords: Highcharts.DataPoint[];
            if (currentSeries.length === 1) {
                xyCoords = singlePtSeries(currentSeries[0]);
            } else {
                xyCoords = currentSeries.map((pt) => ({
                    x: pt[xKey],
                    y: pt.efficiency,
                }));
                xyCoords.sort((coord1, coord2) => coord1.x! - coord2.x!);
            }
            return {
                name: seriesValue.toFixed(2),
                data: xyCoords,
            };
        });
    }

    // For efficiency curves w/ only a single point, make a line w/ 3 points to more accurately reflect device's
    // behavior, and to improve visibility of the efficiency point.
    function singlePtSeries(pt) {
        let xs: number[];
        if (xName === 'Power Ratio' || xName === 'Voltage Buck Ratio') {
            xs = [0, pt[xKey], 1];
        } else if (xName === 'Power') {
            xs = [0, pt[xKey], characterization.power_device.max_power];
        } else if (xName === 'Voltage') {
            xs = [characterization.power_device.min_voltage, pt[xKey], characterization.power_device.max_voltage];
        } else {
            console.error(
                'Unexpected single point efficiency for characterization ' +
                    `${characterization.power_device_characterization_id}.`,
            );
            xs = [pt[xKey]];
        }
        return xs.map((x) => ({ x, y: pt.efficiency }));
    }

    return {
        chart: {
            type: 'spline',
            spacingRight: 35,
        },
        title: {
            text: '',
        },
        xAxis: {
            startOnTick: false,
            lineColor: '#444',
            title: {
                text: xName,
            },
            labels: {
                formatter: xFilter,
            },
        },
        yAxis: [
            {
                title: {
                    text: 'Efficiency',
                },
                labels: {
                    formatter(this: any) {
                        return percentage(this.value);
                    },
                },
            },
        ],
        legend: {
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'top',
            backgroundColor: 'white',
            floating: true,
            enabled: seriesValues.length > 1,
            title: { text: seriesName },
            labelFormatter(this: any) {
                if (seriesName === 'Voltage') {
                    return voltage(this.name);
                }
                if (seriesName === 'Voltage Ratio') {
                    return percentage(this.name);
                }
                return this.name;
            },
            x: 25,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                animation: false,
                marker: {
                    enabled: false,
                },
            },
        },
        series: makeAllSeries(),
    };
}

const ModeledPerformanceChart: React.SFC<IOwnProps> = ({ characterization }) => {
    return (
        <HighchartsContainer>
            <ReactHighcharts config={makeConfig(characterization)} isPureConfig={true} />
        </HighchartsContainer>
    );
};
export default ModeledPerformanceChart;
