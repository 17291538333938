import * as React from 'react';

import { orderBy } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';

import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';

import BasicTable from 'reports/components/core/tables/BasicTable';
import ScrollContainer from 'reports/components/core/tables/ScrollContainer';

import { selectors as authSelectors } from 'reports/modules/auth';
import { IAppState } from 'reports/types';

import { api as subscriptionApi, Subscription } from 'reports/models/subscription';
import { api as teamApi, Team } from 'reports/models/team';
import { TeamInviteStatuses, User } from 'reports/models/user';

import { PrimaryIntent } from 'reports/components/core/controls';
import { promptModalBoolean } from 'reports/components/dialog';

import { Toaster, addPromiseToasts } from 'reports/modules/Toaster';

import { styled } from 'reports/styles/styled-components';
import { Intent } from '@blueprintjs/core';
import DropdownMenuButton from 'reports/modules/settings/team/components/DropdownMenuButton';
import { SelectTitle } from 'reports/modules/settings/team/components/common';

interface Props {
    disabled?: boolean;
    team: Team;
    subscription?: Subscription;
}

interface SeatItem {
    // Either assignSeat or removeSeat with some extra confirmation modal logic and cleanup.
    apiCall: (subscription: Subscription, user: User) => Promise<void>;
    user: User;
    key: string;
    text: string;
}

interface RoleItem {
    key: string;
    title: string;
    subtitle: string;
    user: User;
}

interface UserAction {
    value: string;
    action: () => void;
}

const TableHeader = styled.th`
    padding: 12px !important;
`;

const TableCell = styled.td`
    padding: 12px !important;
`;

const SelectCell = styled.td`
    padding: 8px !important;
`;

const SelectSubtitle = styled.div`
    font-size: 12px;
`;

const TABLE_ROW_HEIGHT = 42;
const TABLE_MAX_HEIGHT = 15 * TABLE_ROW_HEIGHT - TABLE_ROW_HEIGHT / 2;

const TeamMemberList = React.memo(({ disabled, subscription, team }: Props) => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => authSelectors.getUser(state as IAppState));
    const updateUser = (form) => dispatch(teamApi.updateUser(form));
    const reloadTeam = () => dispatch(teamApi.get({ team_id: team.team_id }));
    const refreshSubscription = ({ external_id }) => dispatch(subscriptionApi.refreshCache({ external_id }));

    const resendInvite = async (user: User, externalId: string) => {
        await addPromiseToasts(
            dispatch(subscriptionApi.resendInvite({ user: user.user_id }, { external_id: externalId })),
            {
                initial: '',
                onSuccess: async () => {
                    await refreshSubscription({ external_id: externalId });
                    await reloadTeam();
                    return `Invite has been resent to ${user.email}.`;
                },
                onCatch: (error) => error?.response?.body?.error || `Invite could not be resent to ${user.email}.`,
            },
        );
    };

    const rescindInvite = async (user: User, externalId: string) => {
        await addPromiseToasts(
            dispatch(subscriptionApi.rescindInvite({ user: user.user_id }, { external_id: externalId })),
            {
                initial: '',
                onSuccess: async () => {
                    await refreshSubscription({ external_id: externalId });
                    await reloadTeam();
                    return `Invite to ${user.email} has been rescinded.`;
                },
                onCatch: (error) => error?.response?.body?.error || `Invite to ${user.email} could not be rescinded.`,
            },
        );
    };

    const assignSeat = async (subscription: Subscription, user: User) => {
        await addPromiseToasts(
            dispatch(subscriptionApi.assignSeat({ user: user.user_id }, { external_id: subscription.external_id })),
            {
                initial: '',
                onSuccess: async () => {
                    await refreshSubscription({ external_id: subscription.external_id });
                    await reloadTeam();
                    return `${user.email} has been assigned a seat, and is now active.`;
                },
                onCatch: (error) =>
                    error?.response?.body?.error || `No seats available, you need to purchase more seats.`,
            },
        );
    };

    const removeSeat = async (subscription: Subscription, user: User) => {
        const shouldSave = await promptModalBoolean({
            title: 'Remove a team member from a seat',
            prompt: (
                <p>
                    Are you sure you want to remove this team member from a seat? Removing this user will free up a
                    license which can be assigned to a new team member.
                </p>
            ),
            cancellable: true,
            yesLabel: 'Remove',
            yesIntent: PrimaryIntent.DELETE,
        });

        if (!shouldSave) {
            return;
        }

        await addPromiseToasts(
            dispatch(subscriptionApi.removeSeat({ user: user.user_id }, { external_id: subscription.external_id })),
            {
                initial: '',
                onSuccess: async () => {
                    await refreshSubscription({ external_id: subscription.external_id });
                    await reloadTeam();
                    return `${user.email} has been removed from a seat, and is now inactive.`;
                },

                onCatch: (error) => error?.response?.body?.error || `${user.email} cannot be removed from seat.`,
            },
        );
    };

    const updateSeat = async (item: SeatItem, subscription?: Subscription | null) => {
        const { apiCall, user } = item;

        if (!subscription) {
            return;
        }

        return await apiCall(subscription, user);
    };

    const updateUserAdminStatus = async (user: User) => {
        await addPromiseToasts(
            updateUser({
                team_id: user.team_id,
                email: user.email,
                team_admin: !user.team_admin,
            }),
            {
                initial: '',
                onSuccess: `${user.email} updated.`,
                onCatch: () => {
                    return `${user.email} was not updated.`;
                },
            },
        );
        await reloadTeam();
    };

    const sortedUsers = orderBy(
        team.users,
        [
            (user) => subscription?.subscribers.includes(user),
            'team_admin',
            (user) => user.last_name.toLowerCase(),
            (user) => user.first_name.toLowerCase(),
        ],
        ['desc', 'desc', 'asc', 'asc'],
    );

    const isActiveSubscription = subscription?.is_active;

    return (
        <ScrollContainer height={TABLE_MAX_HEIGHT}>
            <BasicTable tableTheme="control" sticky={true} width="100%" hasScrollContainer={true}>
                <thead>
                    <tr>
                        <TableHeader>User</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Seats</TableHeader>
                        <TableHeader>Role</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {sortedUsers.map((user: User) => {
                        const isCurrentUser = user.user_id === currentUser!.user_id;
                        const isSeated = subscription?.subscribers.includes(user);
                        const isLastSeatedUser = subscription?.used_seats === 1 && isSeated;

                        const seatItems = [
                            { user, apiCall: assignSeat, key: `add_seat-${user.user_id}`, text: 'Seat assigned' },
                            {
                                user,
                                apiCall: removeSeat,
                                key: `remove_seat-${user.user_id}`,
                                text: 'No seat assigned',
                            },
                        ];

                        const roleItems = [
                            {
                                user,
                                key: `admin-${user.user_id}`,
                                title: 'Admin',
                                subtitle: 'Manage projects, teams, features and settings',
                            },
                            {
                                user,
                                key: `member-${user.user_id}`,
                                title: 'Team Member',
                                subtitle: 'Manage projects and resources',
                            },
                        ];

                        const userActions = (user) => {
                            const actions: UserAction[] = [];
                            const showInviteActions =
                                user.team_invite_status === TeamInviteStatuses.pending ||
                                user.team_invite_status === TeamInviteStatuses.expired;

                            if (showInviteActions) {
                                actions.push({
                                    value: 'Resend invite',
                                    action: () => {
                                        resendInvite(user, subscription!.external_id);
                                    },
                                });
                            }

                            actions.push({
                                value: 'Copy email',
                                action: () => {
                                    navigator.clipboard.writeText(user.email);
                                    Toaster.show({
                                        message: 'Email address copied to clipboard',
                                        intent: Intent.SUCCESS,
                                    });
                                },
                            });

                            if (showInviteActions) {
                                actions.push({
                                    value: 'Rescind invite',
                                    action: () => {
                                        rescindInvite(user, subscription!.external_id);
                                    },
                                });
                            }

                            return actions;
                        };

                        return (
                            <tr key={user.user_id}>
                                <SelectCell>
                                    <DropdownMenuButton
                                        title={user.fullName()}
                                        subtitle={user.email}
                                        tag={
                                            user.team_invite_status !== TeamInviteStatuses.not_invited &&
                                            user.team_invite_status !== TeamInviteStatuses.active &&
                                            user.teamInviteStatusText
                                        }
                                        menuItems={userActions(user)}
                                    />
                                </SelectCell>
                                <TableCell>{isSeated ? user.statusText : 'Inactive'}</TableCell>
                                <SelectCell>
                                    <BasicSelect
                                        disabled={
                                            disabled || !isActiveSubscription || isCurrentUser || isLastSeatedUser
                                        }
                                        dataSource={{
                                            items: seatItems,
                                        }}
                                        fill
                                        minimal
                                        onChange={async (item: SeatItem) => await updateSeat(item, subscription)}
                                        value={isSeated ? seatItems[0] : seatItems[1]}
                                        itemRenderer={({ key, text }: SeatItem) => ({
                                            key,
                                            text: <SelectTitle>{text}</SelectTitle>,
                                        })}
                                    />
                                </SelectCell>

                                <SelectCell>
                                    <BasicSelect
                                        fill
                                        minimal
                                        disabled={!isSeated || disabled || isCurrentUser}
                                        dataSource={{
                                            items: roleItems,
                                        }}
                                        onChange={async ({ user }: RoleItem) => await updateUserAdminStatus(user)}
                                        value={user.team_admin ? roleItems[0] : roleItems[1]}
                                        itemRenderer={({ key, subtitle, title }: RoleItem) => ({
                                            key,
                                            text: (
                                                <>
                                                    <SelectTitle>{title}</SelectTitle>
                                                    <SelectSubtitle>{subtitle}</SelectSubtitle>
                                                </>
                                            ),
                                        })}
                                    />
                                </SelectCell>
                            </tr>
                        );
                    })}
                </tbody>
            </BasicTable>
        </ScrollContainer>
    );
});

export default TeamMemberList;
