import { map } from 'lodash';

import { Dispatch } from 'redux';

import { createUniqueDescription } from 'reports/utils/helpers';

import * as proj from 'reports/models/project';
import * as ur from 'reports/models/utility_rate';

export const actions = {
    saveAsNew: (rate: ur.UtilityRate) => async (dispatch: Dispatch) => {
        const { apply_date, data } = rate;
        const potentialCopies = await dispatch(ur.api.index({ description: rate.description }));
        const description = createUniqueDescription(rate.description, map(potentialCopies, 'description'));
        return await dispatch(ur.api.create({ apply_date, description, data }));
    },
    safeDeleteUtilityRate: (rate: ur.UtilityRate) => {
        return async (dispatch: Dispatch) => {
            const rateProjects = await dispatch(
                proj.api.index({
                    utility_rate_id: rate.utility_rate_id,
                    limit: 1,
                }),
            );

            if (rateProjects.length) {
                throw new Error('Utility rate is in use by one or more projects');
            }

            return dispatch(ur.api.delete({ utility_rate_id: rate.utility_rate_id }));
        };
    },
};
