import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';
import { merge } from 'lodash';

import { Button, ButtonGroup, Checkbox, Classes, Dialog, NumericInput } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as usr from 'reports/models/user';
import * as ds from 'reports/models/design_snapshots';
import * as auth from 'reports/modules/auth';
import { bindActions } from 'reports/utils/redux';

import { DeepPartial } from 'reports/types';
import Colors from 'reports/styles/colors';
import Translations from 'reports/localization/strings';
import { Flex } from 'reports/components/core/containers';
import { PrimaryButton, Tooltip } from 'reports/components/core/controls';
import * as uploads from 'reports/modules/files/uploads';
import { FormSection, FormHeader } from 'reports/modules/report/components/helpers';
import SingleColorPicker from 'reports/modules/report/components/forms/SingleColorPicker';
import InteractiveDesign3dView from 'reports/modules/ogdesigner/components/InteractiveDesign3dView';
import { IWidgetConfig } from 'reports/modules/report/widgets';

import DesignHeatMapLegend from './DesignHeatMapLegend';
import { DesignHeatMapSeriesName, IHeatMapSettings, LegendPosition } from './common';
import { DesignHeatMapContext } from './DesignHeatMapContext';

import { DEFAULT_SETTINGS, DEFAULT_CAMERA_SETTINGS, IContent } from './DesignSnapshotWidget';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const MAX_INTERACTIVE_DESIGNER_SIZE = 500;

interface IDesignSnapshotFormProps {
    user: usr.User;
    widget: IWidgetConfig<IContent>;
    updateWidgetContent: (content: DeepPartial<IContent>) => void;
    onCancel: () => void;
    onSave: () => void;
    onEnableHeatMap: () => void;
    intl: IntlShape;
    showDialog: boolean;
    designId: number;
    upload: Function;
    create: Function;
}

interface IState {
    backupModuleColor?: string;
    backupHeatMapSettings?: IHeatMapSettings;
}

const DEFAULT_MODULE_COLOR = undefined;
const DEFAULT_HEAT_MAP_SETTINGS: IHeatMapSettings = {
    legendPosition: 'bottom_right',
    seriesName: 'tsrfSeries',
};

const DialogColumn = styled(Flex.ContainerV)`
    // consistent with .bp3-dialog-body margin
    margin-left: 20px;
    width: 150px;
`;

const DesignSnapshotDialog = styled(Dialog)`
    width: initial;
    height: 625px;
    // needed to make iframe placement work
    position: relative;
`;

const TooltipButtonGroup = styled(ButtonGroup)`
    &.bp3-button-group .bp3-popover2-target {
        display: flex;
        flex: 0 0 auto;
    }
`;

const FormLabel = styled.div`
    margin-top: 4px;
`;

class DesignSnapshotFormIntl extends React.PureComponent<IDesignSnapshotFormProps, IState> {
    state = {
        backupModuleColor: DEFAULT_MODULE_COLOR,
        backupHeatMapSettings: DEFAULT_HEAT_MAP_SETTINGS,
    };

    render() {
        const { intl, widget } = this.props;
        const widgetContent = widget.content;
        const settings = { ...DEFAULT_SETTINGS, ...widgetContent.settings };
        const cameraSettings = {
            ...DEFAULT_CAMERA_SETTINGS,
            ...widgetContent.cameraSettings,
        };
        const { heatMapSettings } = widgetContent;

        const { modules, field_segments, keepouts, overlays, premades, wiring, inverters, combiners, interconnect } =
            settings;

        const { azimuth, elevation, zoom } = cameraSettings;
        const { h, w } = this.props.widget.layout;
        const designerHeight = h > w ? MAX_INTERACTIVE_DESIGNER_SIZE : (MAX_INTERACTIVE_DESIGNER_SIZE / w) * h;
        const designerWidth = h > w ? (MAX_INTERACTIVE_DESIGNER_SIZE / h) * w : MAX_INTERACTIVE_DESIGNER_SIZE;
        const isHeatMapMode = !!heatMapSettings;

        return (
            <DesignSnapshotDialog
                title={intl.formatMessage(Translations.design.edit_design_render)}
                icon="page-layout"
                isOpen={this.props.showDialog}
                onClose={(e) => {
                    e && e.stopPropagation();
                    this.onClose();
                }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <Flex.Container>
                        <div
                            style={{
                                height: designerHeight,
                                width: designerWidth,
                                position: 'relative',
                                border: '1px solid',
                                borderColor: Colors.BORDER_DARK,
                            }}
                        >
                            <DesignHeatMapLegend
                                disabled={!heatMapSettings}
                                legendPosition={heatMapSettings?.legendPosition}
                                seriesName={heatMapSettings?.seriesName}
                                height={designerHeight}
                                width={designerWidth}
                                usePortal={true}
                            >
                                <DesignHeatMapContext.Consumer>
                                    {(heatMap) => (
                                        <InteractiveDesign3dView
                                            designId={this.props.designId}
                                            renderSettings={
                                                heatMap.ready && heatMapSettings
                                                    ? {
                                                          ...widgetContent,
                                                          moduleColor: heatMap.data![heatMapSettings.seriesName].colors,
                                                      }
                                                    : widgetContent
                                            }
                                            onChange={this.onInteractiveChange}
                                        ></InteractiveDesign3dView>
                                    )}
                                </DesignHeatMapContext.Consumer>
                            </DesignHeatMapLegend>
                        </div>
                        <DialogColumn>
                            <FormSection>
                                <FormHeader>
                                    {' '}
                                    <FormattedMessage {...Translations.general.settings} />{' '}
                                </FormHeader>
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.modules)}
                                    checked={modules}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            modules: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.field_segments)}
                                    checked={field_segments}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            field_segments: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.keepouts)}
                                    checked={keepouts}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            keepouts: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.overlays)}
                                    checked={overlays}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            overlays: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.trees)}
                                    checked={premades}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            premades: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                            </FormSection>
                            <FormSection>
                                <FormHeader>
                                    <FormattedMessage {...Translations.design.wiring} />
                                </FormHeader>
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.wiring)}
                                    checked={wiring}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            wiring: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.inverters)}
                                    checked={inverters}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            inverters: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.combiners)}
                                    checked={combiners}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            combiners: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                                <Checkbox
                                    label={intl.formatMessage(Translations.design.interconnect)}
                                    checked={interconnect}
                                    onChange={(evt) => {
                                        this.setDesignSettings({
                                            interconnect: evt.currentTarget.checked,
                                        });
                                    }}
                                />
                            </FormSection>
                        </DialogColumn>
                        <DialogColumn>
                            <FormSection>
                                <FormHeader>
                                    {' '}
                                    <FormattedMessage {...Translations.design.camera_angle} />{' '}
                                </FormHeader>
                                <FormLabel>
                                    <FormattedMessage {...Translations.design.azimuth} />
                                </FormLabel>
                                <NumericInput
                                    style={{ width: '50px' }}
                                    min={0}
                                    max={360}
                                    value={azimuth}
                                    onValueChange={(azimuth) => {
                                        this.setCameraSettings({ azimuth });
                                    }}
                                />
                                <FormLabel>
                                    <FormattedMessage {...Translations.general.elevation} />
                                </FormLabel>
                                <NumericInput
                                    style={{ width: '50px' }}
                                    min={0}
                                    max={90}
                                    value={elevation}
                                    onValueChange={(elevation) => {
                                        this.setCameraSettings({ elevation });
                                    }}
                                />
                                <FormLabel>
                                    <FormattedMessage {...Translations.general.zoom} />
                                </FormLabel>
                                <NumericInput
                                    style={{ width: '50px' }}
                                    stepSize={0.1}
                                    value={zoom}
                                    onValueChange={(zoom) => {
                                        this.setCameraSettings({ zoom });
                                    }}
                                />
                                <ButtonGroup vertical={true}>
                                    <Button
                                        onClick={() =>
                                            this.setCameraSettings({
                                                azimuth: 225,
                                                elevation: 45,
                                            })
                                        }
                                    >
                                        <FormattedMessage {...Translations.constants.southwest} />
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            this.setCameraSettings({
                                                azimuth: 135,
                                                elevation: 45,
                                            })
                                        }
                                    >
                                        <FormattedMessage {...Translations.constants.southeast} />
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            this.setCameraSettings({
                                                azimuth: 180,
                                                elevation: 90,
                                            })
                                        }
                                    >
                                        <FormattedMessage {...Translations.design.recenter} />
                                    </Button>
                                </ButtonGroup>
                            </FormSection>
                        </DialogColumn>
                        <DialogColumn>
                            <FormSection>
                                <FormHeader>
                                    <FormattedMessage {...Translations.design.module_color} />
                                </FormHeader>
                                <TooltipButtonGroup vertical={true}>
                                    <Button active={!isHeatMapMode} onClick={() => this.toggleToSingleColor()}>
                                        <FormattedMessage {...Translations.design.single_color} />
                                    </Button>
                                    <Button active={isHeatMapMode} onClick={() => this.toggleToHeatMap()}>
                                        <FormattedMessage {...Translations.design.heat_map} />
                                    </Button>
                                </TooltipButtonGroup>
                                {!isHeatMapMode && (
                                    <>
                                        <SingleColorPicker
                                            color={widgetContent.moduleColor}
                                            onColorChange={this.setModuleColor}
                                        />
                                        <Button
                                            style={{ marginTop: 5 }}
                                            onClick={() => this.setModuleColor(undefined)}
                                            icon={IconNames.RESET}
                                        >
                                            <FormattedMessage {...Translations.general.reset_to_default} />
                                        </Button>
                                    </>
                                )}
                                {isHeatMapMode && (
                                    <>
                                        <FormLabel>
                                            <FormattedMessage {...Translations.general.legend_position} />
                                        </FormLabel>
                                        <TooltipButtonGroup>
                                            <Tooltip content={<FormattedMessage {...Translations.general.top_left} />}>
                                                <Button
                                                    icon={IconNames.ARROW_TOP_LEFT}
                                                    active={heatMapSettings?.legendPosition === 'top_left'}
                                                    onClick={() => this.setLegendPosition('top_left')}
                                                />
                                            </Tooltip>
                                            <Tooltip content={<FormattedMessage {...Translations.general.top_right} />}>
                                                <Button
                                                    icon={IconNames.ARROW_TOP_RIGHT}
                                                    active={heatMapSettings?.legendPosition === 'top_right'}
                                                    onClick={() => this.setLegendPosition('top_right')}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                content={<FormattedMessage {...Translations.general.bottom_left} />}
                                            >
                                                <Button
                                                    icon={IconNames.ARROW_BOTTOM_LEFT}
                                                    active={heatMapSettings?.legendPosition === 'bottom_left'}
                                                    onClick={() => this.setLegendPosition('bottom_left')}
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                content={<FormattedMessage {...Translations.general.bottom_right} />}
                                            >
                                                <Button
                                                    icon={IconNames.ARROW_BOTTOM_RIGHT}
                                                    active={heatMapSettings?.legendPosition === 'bottom_right'}
                                                    onClick={() => this.setLegendPosition('bottom_right')}
                                                />
                                            </Tooltip>
                                        </TooltipButtonGroup>
                                        <FormLabel>
                                            <FormattedMessage {...Translations.general.data_source} />
                                        </FormLabel>
                                        {/* TODO: refactor this using ButtonGroupSelect */}
                                        <ButtonGroup vertical={true}>
                                            <Button
                                                active={heatMapSettings?.seriesName === 'tsrfSeries'}
                                                onClick={() => this.setHeatMapSeries('tsrfSeries')}
                                            >
                                                <FormattedMessage
                                                    {...Translations.simulation.total_solar_resource_fraction}
                                                />
                                            </Button>
                                            <Button
                                                active={heatMapSettings?.seriesName === 'solarAccessSeries'}
                                                onClick={() => this.setHeatMapSeries('solarAccessSeries')}
                                            >
                                                <FormattedMessage {...Translations.simulation.solar_access} />
                                            </Button>
                                        </ButtonGroup>
                                    </>
                                )}
                            </FormSection>
                        </DialogColumn>
                    </Flex.Container>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button text={intl.formatMessage(Translations.general.cancel)} onClick={() => this.onClose()} />
                        <PrimaryButton
                            text={intl.formatMessage(Translations.general.create_screenshot)}
                            onClick={() => this.onCreate()}
                        />
                    </div>
                </div>
            </DesignSnapshotDialog>
        );
    }

    onCreate = () => this.props.onSave();
    onClose = () => this.props.onCancel();

    toggleToHeatMap = () => {
        const { backupHeatMapSettings } = this.state;
        const { widget } = this.props;

        this.props.onEnableHeatMap();
        this.setState({ backupModuleColor: widget.content.moduleColor });
        this.props.updateWidgetContent({
            heatMapSettings: backupHeatMapSettings || DEFAULT_HEAT_MAP_SETTINGS,
            moduleColor: undefined,
        });
    };

    toggleToSingleColor = () => {
        const { backupModuleColor } = this.state;
        const { widget } = this.props;

        this.setState({
            backupHeatMapSettings: widget.content.heatMapSettings,
        });
        this.props.updateWidgetContent({
            moduleColor: backupModuleColor || DEFAULT_MODULE_COLOR,
            heatMapSettings: undefined,
        });
    };

    setModuleColor = (moduleColor) => this.props.updateWidgetContent({ moduleColor });
    setLegendPosition = (legendPosition: LegendPosition) =>
        this.props.updateWidgetContent({
            heatMapSettings: merge({}, this.props.widget.content.heatMapSettings, { legendPosition }),
        });
    setHeatMapSeries = (seriesName: DesignHeatMapSeriesName) =>
        this.props.updateWidgetContent({
            heatMapSettings: merge({}, this.props.widget.content.heatMapSettings, { seriesName }),
        });
    setDesignSettings = (settings) =>
        this.props.updateWidgetContent({
            settings: merge({}, this.props.widget.content.settings, settings),
        });
    setCameraSettings = (cameraSettings) =>
        this.props.updateWidgetContent({
            cameraSettings: merge({}, this.props.widget.content.cameraSettings, cameraSettings),
        });
    onInteractiveChange = (contentPartial) => this.props.updateWidgetContent(contentPartial);
}

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

const mapDispatchToProps = bindActions({
    upload: uploads.actions.uploadFile,
    create: (newDS) => ds.api.create(newDS),
});

export const DesignSnapshotForm = connect(mapStateToProps, mapDispatchToProps)(injectIntl(DesignSnapshotFormIntl));
