/*
 * Currencies (119 currencies)
 *
 * Source: https://gist.github.com/Fluidbyte/2973986
 */

export const DEFAULT_CURRENCY_USD: ICurrency = {
    code: 'USD',
    name: 'US Dollar',
    symbol_native: '$',
};

export interface ICurrency {
    code: string;
    name: string;
    symbol_native: string;
}

export const commonCurrencies: ICurrency[] = require('./currencies.json');

/*
 * Locales
 *
 * Source: Unicode Common Locale Data Repository
 * https://github.com/unicode-cldr/cldr-localenames-modern/blob/master/main/en-US-POSIX/languages.json
 */
export const DEFAULT_LOCALE: ILocale = {
    code: 'en-US',
    name: 'American English',
};

export interface ILocale {
    code: string;
    name: string;
}

export const locales: ILocale[] = require('./cldr_locales.json');
