import * as React from 'react';

import { Button, H6 } from '@blueprintjs/core';
import styled from 'styled-components';

import {
    IConfiguration,
    IFixedConfiguration,
    INameplateConfiguration,
    Incentive,
    IncentiveType,
    IncentiveTypes,
    IProductionConfiguration,
    ISystemPriceConfiguration,
} from 'reports/models/incentive';

import INC_PARAMETERS from 'reports/modules/incentive/parameters';
import { LineItem } from 'reports/modules/financials/components/LineItem';
import { complexEditor, Param } from 'reports/modules/financials/components/pipelineCommon';

const IncentiveName = styled(H6)`
    font-weight: normal;
`;

const IncentiveContainer = styled.div`
    margin-bottom: 10px;
`;

const IncentiveBody = styled.div`
    margin-left: 8px;
`;

interface IProps {
    incentives: Incentive[];
}

export const ProjectIncentives: React.SFC<IProps> = ({ incentives }) => {
    return (
        <div>
            {incentives.map((incentive) => {
                return (
                    <IncentiveContainer key={incentive.incentive_id}>
                        <IncentiveName>{`${incentive.name} (${
                            IncentiveTypes[incentive.incentive_type]
                        })`}</IncentiveName>
                        <IncentiveBody>
                            <IncentiveConfigFields
                                incentive_type={incentive.incentive_type}
                                configuration={incentive.configuration}
                            />
                        </IncentiveBody>
                    </IncentiveContainer>
                );
            })}
        </div>
    );
};

class ParamLine extends React.PureComponent<any> {
    state = {
        expanded: false,
    };

    render() {
        const { value, parameter } = this.props;
        const { expanded } = this.state;

        if (complexEditor(parameter)) {
            return (
                <LineItem
                    label={parameter.description}
                    line={
                        <Button text="View" active={expanded} onClick={() => this.setState({ expanded: !expanded })} />
                    }
                    after={
                        expanded ? (
                            <div className="expand-edit">
                                <Param parameter={parameter} value={value} disabled={true} />
                            </div>
                        ) : null
                    }
                />
            );
        }

        return (
            <LineItem
                label={parameter.description}
                line={<Param parameter={parameter} value={value} disabled={true} />}
            />
        );
    }
}

const FixedIncentiveFields = ({ configuration }: { configuration: IFixedConfiguration }) => (
    <>
        <ParamLine value={configuration.amount} parameter={INC_PARAMETERS.fixed.amount} />
        <ParamLine value={configuration.year} parameter={INC_PARAMETERS.fixed.year} />
    </>
);

const SystemPriceIncentiveFields = ({ configuration }: { configuration: ISystemPriceConfiguration }) => (
    <>
        <ParamLine value={configuration.amount} parameter={INC_PARAMETERS.system_price.amount} />
        <ParamLine value={configuration.payment_schedule} parameter={INC_PARAMETERS.system_price.payment_schedule} />
        <ParamLine value={configuration.reduced_basis} parameter={INC_PARAMETERS.system_price.reduced_basis} />
    </>
);

const NameplateIncentiveFields = ({ configuration }: { configuration: INameplateConfiguration }) => (
    <>
        <ParamLine value={configuration.amount} parameter={INC_PARAMETERS.nameplate.amount} />
        <ParamLine value={configuration.payment_schedule} parameter={INC_PARAMETERS.nameplate.payment_schedule} />
    </>
);

const ProductionIncentiveFields = ({ configuration }: { configuration: IProductionConfiguration }) => (
    <>
        <ParamLine value={configuration.amount} parameter={INC_PARAMETERS.production.amount} />
        <ParamLine value={configuration.payout_years} parameter={INC_PARAMETERS.production.payout_years} />
    </>
);

interface ConfigFieldsProps {
    configuration: IConfiguration;
    incentive_type: IncentiveType;
}
const IncentiveConfigFields: React.SFC<ConfigFieldsProps> = ({ configuration, incentive_type }) => {
    switch (incentive_type) {
        case 'fixed':
            return <FixedIncentiveFields configuration={configuration as IFixedConfiguration} />;
        case 'system_price':
            return <SystemPriceIncentiveFields configuration={configuration as ISystemPriceConfiguration} />;
        case 'nameplate':
            return <NameplateIncentiveFields configuration={configuration as INameplateConfiguration} />;
        case 'production':
            return <ProductionIncentiveFields configuration={configuration as IProductionConfiguration} />;
        default:
            const _exhaustiveCheck: never = incentive_type;
            return _exhaustiveCheck;
    }
};
