import { IRouteConfig, IParams } from './types';

export function indexRoutes<S>(
    routes: IRouteConfig<IParams, S>[],
    reducer = {},
    prefix = '',
): { [k: string]: IRouteConfig<IParams, S> } {
    for (const route of routes) {
        const key = prefix + route.name;

        reducer[key] = route;

        if (route.children !== undefined) {
            indexRoutes(route.children, reducer, key + '.');
        }
    }

    return reducer;
}

export function isParentOfRoute(parent: string, route: string) {
    return route.indexOf(parent + '/') === 0 || route.indexOf(parent + '.') === 0;
}
