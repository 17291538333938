import { Design } from 'reports/models/design';
import { Simulation } from 'reports/models/simulation';

export const perfRatio = (design: Design, simulation: Simulation) => {
    if (simulation?.metadata != null) {
        const perfRatio =
            simulation.metadata.grid_power /
            ((simulation.metadata.poa_irradiance * design.field_component_metadata.nameplate) / 1000);
        return perfRatio;
    }
    return null;
};
