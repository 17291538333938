/* tslint:disable:max-line-length */
// prettier is buggy and sometimes formats lines to 121 chars
import * as React from 'react';
import { connect } from 'react-redux';

import { INITIAL_TOU_DATE, FINAL_TOU_DATE, TOU_YEAR_END } from 'helioscope/app/constants';

import { Dialog, Classes, Intent } from '@blueprintjs/core';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';
import DialogActions from 'reports/components/dialog/components/DialogActions';
import { PrimaryButton, Button as SecondaryButton } from 'reports/components/core/controls';
import * as auth from 'reports/modules/auth';
import * as usr from 'reports/models/user';

interface IOwnProps {
    justLoggedIn: boolean;
    handleLoginHookFinished: () => void;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

type IFinalDialogProps = Pick<IProps, 'acceptTOU' | 'declineTOU' | 'handleLoginHookFinished' | 'user'>;
interface ISnoozeableEverywhereProps extends Pick<IProps, 'acceptTOU' | 'snoozeTOU' | 'handleLoginHookFinished'> {
    finalTouDate: Date;
}

const FinalDialog: React.FC<IFinalDialogProps> = (props) => {
    const { acceptTOU, declineTOU, handleLoginHookFinished, user } = props;
    const [isOpen, setIsOpen] = React.useState(true);
    const handleAccept = async () => {
        await acceptTOU();
        setIsOpen(false);
        handleLoginHookFinished();
    };
    const handleDecline = async () => {
        await declineTOU();
        window.location.reload();
    };
    return (
        <Dialog
            title="Terms of Use & Privacy Updates"
            isOpen={isOpen}
            isCloseButtonShown={false}
            canEscapeKeyClose={false}
        >
            <div className={Classes.DIALOG_BODY}>
                We’ve made some changes to our{' '}
                <a href="https://helioscope.aurorasolar.com/terms-of-use/">Terms of Use</a> and{' '}
                <a href="https://www.aurorasolar.com/privacy-policy">Privacy Policy</a> on June 1, 2022 to improve
                transparency and better align with our parent company, Aurora Solar. To continue, you must accept and
                agree to our updated terms and policies. If you decline, you will not be able to continue using our
                services. If you have any questions please <a href="mailto:support@helioscope.com">contact us</a>.
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <DialogActions>
                    <SecondaryButton
                        text={user.subscription!!.used_seats > 1 ? 'Decline' : 'Decline and cancel the subscription'}
                        className="hs-btn-left-align"
                        intent={Intent.DANGER}
                        onClick={handleDecline}
                        loadingWhileAwaiting
                    />
                    <PrimaryButton text="Accept" onClick={handleAccept} loadingWhileAwaiting />
                </DialogActions>
            </div>
        </Dialog>
    );
};

const SnoozeableEverywhere: React.FC<ISnoozeableEverywhereProps> = (props) => {
    const { acceptTOU, snoozeTOU, finalTouDate, handleLoginHookFinished } = props;
    const [isOpen, setIsOpen] = React.useState(true);
    const handleAccept = async () => {
        await acceptTOU();
        setIsOpen(false);
        handleLoginHookFinished();
    };
    const handleSnooze = async () => {
        await snoozeTOU();
        setIsOpen(false);
        handleLoginHookFinished();
    };
    return (
        <Dialog
            title="Terms of Use & Privacy Updates"
            isOpen={isOpen}
            isCloseButtonShown={false}
            canEscapeKeyClose={false}
        >
            <div className={Classes.DIALOG_BODY}>
                We’ve made some changes to our{' '}
                <a href="https://helioscope.aurorasolar.com/terms-of-use/">Terms of Use</a> and{' '}
                <a href="https://www.aurorasolar.com/privacy-policy">Privacy Policy</a> to improve transparency and
                better align with our parent company, Aurora Solar. By clicking “Accept”, you accept and agree to our
                updated terms and policies. We encourage you to read the updated Terms of Use and Privacy Policy. You
                have until <b>{finalTouDate.toDateString()}</b> to accept the updated terms and policies.
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <DialogActions>
                    <SecondaryButton
                        text="Snooze"
                        className="hs-btn-left-align"
                        onClick={handleSnooze}
                        loadingWhileAwaiting
                    />
                    <PrimaryButton text="Accept" onClick={handleAccept} loadingWhileAwaiting />
                </DialogActions>
            </div>
        </Dialog>
    );
};

const TOUBoundary: React.FC<IProps> = (props) => {
    const { children, user, acceptTOU, declineTOU, snoozeTOU, justLoggedIn, handleLoginHookFinished } = props;

    const finalTouDate =
        user.subscription?.plan_type === 'year' &&
        user.subscription.current_period_end?.isAfter(FINAL_TOU_DATE) &&
        user.subscription.current_period_end.isBefore(TOU_YEAR_END)
            ? user.subscription.current_period_end.clone().subtract(1, 'days').toDate()
            : FINAL_TOU_DATE;
    const snoozeableEverywhereDate = new Date(finalTouDate.getTime());
    snoozeableEverywhereDate.setDate(snoozeableEverywhereDate.getDate() - 30);
    const now = user.now().toDate();

    const showClickwrap =
        user.subscription && !user.tou_snooze_until?.isAfter(now) && now >= INITIAL_TOU_DATE && !user.tou_accepted;

    const finalDialogOpen = showClickwrap && now >= finalTouDate;
    const snoozeableEverywhereOpen = showClickwrap && !finalDialogOpen && now >= snoozeableEverywhereDate;
    const globalDialogOpen = finalDialogOpen || snoozeableEverywhereOpen;
    const snoozeableLoginOpen = showClickwrap && justLoggedIn && !globalDialogOpen;
    const anyDialogOpen = globalDialogOpen || snoozeableLoginOpen;
    if (!anyDialogOpen) {
        handleLoginHookFinished();
    }

    return (
        <>
            {finalDialogOpen && (
                <FinalDialog
                    acceptTOU={acceptTOU}
                    declineTOU={declineTOU}
                    handleLoginHookFinished={handleLoginHookFinished}
                    user={user}
                />
            )}
            {(snoozeableEverywhereOpen || snoozeableLoginOpen) && (
                <SnoozeableEverywhere
                    acceptTOU={acceptTOU}
                    snoozeTOU={snoozeTOU}
                    finalTouDate={finalTouDate}
                    handleLoginHookFinished={handleLoginHookFinished}
                />
            )}
            {children}
        </>
    );
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(() => ({
    acceptTOU: () => usr.api.save({ email: 'me' }, { action: 'accept_tou' }),
    declineTOU: () => usr.api.save({ email: 'me' }, { action: 'decline_tou' }),
    snoozeTOU: () => usr.api.save({ email: 'me' }, { action: 'snooze_tou' }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(TOUBoundary);
