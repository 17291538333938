/* tslint:disable:variable-name */

import moment from 'moment';
import { createSelector } from 'reselect';

import { IAppState } from 'reports/store';
import { BaseClass, ensureProperty } from 'reports/utils/api';

import * as user from '../user';
import { schema } from '../schema';

import Document from './Document';
import Report, { schemaObj as repSchemaObj } from './Report';
import type ReportConfiguration from './ReportConfiguration';

class ReportVersion extends BaseClass {
    report_version_id: number;
    report_id: number;
    report: Report;

    report_configurations: ReportConfiguration[];

    created: moment.Moment;
    creator_id: number;
    creator: user.User;

    document: Document;

    constructor(data) {
        super(ReportVersion.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        created: (x) => moment(x),
        document: ensureProperty((x) => new Document(x)),
    });
}

const schemaObj = schema.addObject(ReportVersion, 'report_version', {
    relationships: {
        creator: { schema: user.schemaObj },
    },
});

const reportBackrefSelectors = schemaObj.addRelationship('report', repSchemaObj, { backref: 'versions' });

const selectors = {
    byId: schemaObj.selectById,
    allVersions: reportBackrefSelectors.backrefSelector,
    latestVersion: (state: IAppState, report: Report) => schemaObj.selectById(state, report.latest_version_id),
    get latestDocument() {
        return createSelector(selectors.latestVersion, (version) => (version ? version.document : new Document({})));
    },
};

export default ReportVersion;
export { ReportVersion, schemaObj, selectors };
