import React from 'react';

import { BaseProject } from 'frontend/projects/base-project';
import { BaseProjects } from 'frontend/projects/base-projects';
import { Projects } from 'frontend/projects/projects';
import { Simulations } from 'frontend/projects/simulations';

import { AppStore } from 'reports/store';

import { loadProject } from 'frontend/routing/loaders';

const projectsRoutes = (store: AppStore) => ({
    element: <BaseProjects />,
    children: [
        {
            path: 'projects',
            element: <Projects />,
        },
        {
            path: 'projects/:projectId',
            element: <BaseProject />,
            loader: async ({ params }) => await loadProject(store, params.projectId),
            children: [
                {
                    index: true,
                    element: <div>Project Detail</div>,
                },
                {
                    path: 'simulation',
                    element: <Simulations />,
                },
            ],
        },
    ],
});

export { projectsRoutes };
