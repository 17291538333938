import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import Logger from 'js-logger';

import { Button, HTMLTable, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as proj from 'reports/models/project';
import * as inc from 'reports/models/incentive';

import { Section2 } from 'reports/components/core/containers';
import { LinkButton } from 'reports/components/core/controls';
import { Toaster } from 'reports/modules/Toaster';

import * as styles from 'reports/styles/styled-components';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';

const logger = Logger.get('incentives');
const styled = styles.styled;

const Table = styled(HTMLTable).attrs({
    striped: true,
    bordered: true,
    condensed: true,
})`
    width: 100%;
    height: 36px;

    & td.centered {
        text-align: center;
    }
`;

interface IIncentiveTableProps {
    incentives: inc.Incentive[];
    actionButtonProps?: {
        onReorder: (incentives: inc.Incentive[], oldIndex: number, newIndex: number) => void;
        onRemove: (incentive: inc.Incentive) => void;
        disabled: boolean;
    };
}

const isArrowButtonDisabled = (index: number, incentivesLength: number, isUp: boolean) => {
    if (index === 0 && isUp) {
        return true;
    }
    if (index === incentivesLength - 1 && !isUp) {
        return true;
    }
    return false;
};

// Exported for shared use with FinancialProfileIncentiveEditor
export const IncentiveTable: React.SFC<IIncentiveTableProps> = ({ incentives, actionButtonProps }) => (
    <Table>
        <colgroup>
            <col />
            {incentives.length > 0 && (
                <>
                    {!!actionButtonProps && <col style={{ width: '55px' }} />}
                    {!!actionButtonProps && <col style={{ width: '55px' }} />}
                    <col style={{ width: '55px' }} />
                    {!!actionButtonProps && <col style={{ width: '55px' }} />}
                </>
            )}
        </colgroup>
        <tbody>
            {incentives.length === 0 && (
                <tr>
                    <td>None selected</td>
                </tr>
            )}
            {incentives.map((incentive, index) => (
                <tr key={incentive.incentive_id}>
                    <td>{incentive.name}</td>
                    {!!actionButtonProps && (
                        <td className="centered">
                            <Button
                                minimal
                                small
                                icon={IconNames.ARROW_UP}
                                onClick={() => actionButtonProps.onReorder(incentives, index, index - 1)}
                                disabled={isArrowButtonDisabled(index, incentives.length, true)}
                            />
                        </td>
                    )}
                    {!!actionButtonProps && (
                        <td className="centered">
                            <Button
                                minimal
                                small
                                icon={IconNames.ARROW_DOWN}
                                onClick={() => actionButtonProps.onReorder(incentives, index, index + 1)}
                                disabled={isArrowButtonDisabled(index, incentives.length, false)}
                            />
                        </td>
                    )}
                    <td className="centered">
                        <LinkButton
                            minimal
                            small
                            style={{ marginLeft: '8px' }}
                            icon={IconNames.SHARE}
                            routeName="app.incentives.incentive"
                            routeParams={{
                                incentiveId: incentive.incentive_id,
                            }}
                        />
                    </td>
                    {!!actionButtonProps && (
                        <td className="centered">
                            <Button
                                minimal
                                small
                                icon={IconNames.TRASH}
                                onClick={() => actionButtonProps.onRemove(incentive)}
                                disabled={actionButtonProps.disabled}
                            />
                        </td>
                    )}
                </tr>
            ))}
        </tbody>
    </Table>
);

interface IOwnProps {
    project: proj.Project;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

type IProjectIncentiveEditor = IOwnProps & IDispatchProps;

interface IState {
    selectedIncentive?: inc.Incentive;
    updating: boolean;
}

class ProjectIncentiveEditor extends React.Component<IProjectIncentiveEditor, IState> {
    state: IState = {
        updating: false,
    };

    reorderIncentives = async (incentives: inc.Incentive[], oldIndex: number, newIndex: number) => {
        const incentivesCopy = [...incentives];
        [incentivesCopy[oldIndex], incentivesCopy[newIndex]] = [incentivesCopy[newIndex], incentivesCopy[oldIndex]];
        await this.updateProjectIncentives(incentivesCopy);
    };

    render() {
        const { project, getIncentives } = this.props;
        const { selectedIncentive, updating } = this.state;
        const projectIncentiveIds = project.incentives.map((incentive) => incentive.incentive_id);
        return (
            <Section2
                title="Incentives"
                contextEl={
                    <BasicSelect<inc.Incentive>
                        dataSource={{
                            async: true,
                            query: async (q) => {
                                return (await getIncentives({ name: q })).filter(
                                    (x) => !projectIncentiveIds.includes(x.incentive_id),
                                );
                            },
                        }}
                        itemRenderer={(incentive: inc.Incentive) => ({
                            key: incentive.incentive_id,
                            text: incentive.name,
                        })}
                        value={selectedIncentive || null}
                        onChange={(incentive) => {
                            const currentIncentives = this.props.project.incentives;
                            const updatedIncentives = [...currentIncentives, incentive];
                            this.updateProjectIncentives(updatedIncentives);
                            this.setState({ selectedIncentive: undefined });
                        }}
                        noneSelected="Add Incentive"
                    />
                }
            >
                <IncentiveTable
                    incentives={project.incentives}
                    actionButtonProps={{
                        onReorder: this.reorderIncentives,
                        onRemove: (incentive) => this.removeProjectIncentive(incentive),
                        disabled: updating,
                    }}
                />
            </Section2>
        );
    }

    async updateProjectIncentives(incentives: inc.Incentive[]) {
        this.setState({ updating: true });
        try {
            await this.props.setProjectIncentives(incentives);
        } catch (e) {
            Toaster.show({
                icon: 'warning-sign',
                message: <div>Error updating project incentives.</div>,
                timeout: 2500,
                intent: Intent.DANGER,
            });
            logger.warn(e);
            this.props.clearProjectPatches();
        } finally {
            this.setState({
                updating: false,
            });
        }
    }

    removeProjectIncentive(incentive: inc.Incentive) {
        const currentIncentives = this.props.project.incentives;
        const updatedIncentives = currentIncentives.filter((inc) => inc.incentive_id !== incentive.incentive_id);
        this.updateProjectIncentives(updatedIncentives);
    }
}

const mapDispatchToProps = bindActions((props: IOwnProps) => ({
    getIncentives: inc.api.index,
    setProjectIncentives: (incentives) =>
        proj.saver.get(props.project).patch(
            // Manually prune each incentive's relationships so there won't be a circular JSON issue. This is currently
            // necessary since the project.incentives relationship is not mapped.
            {
                incentives: incentives.map((i) => inc.schemaObj.pruneRelationships(i)),
            },
            true,
        ),
    clearProjectPatches: () => proj.saver.get(props.project).clear(),
}));

export default connect(null, mapDispatchToProps)(ProjectIncentiveEditor);
