import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';
import { IAppState } from 'reports/types';

import { Intent, HTMLTable } from '@blueprintjs/core';

import * as auth from 'reports/modules/auth';
import * as proj from 'reports/models/project';
import * as usr from 'reports/models/user';

import Section2 from 'reports/components/core/containers/Section2';
import { UserSelectDropdownContainer } from 'reports/components/CustomDropdowns';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';
import { DeleteButton } from 'reports/components/core/controls';
import ProjectShareButton from 'reports/modules/project/components/ProjectShareButton';

import { Flex } from 'reports/components/core/containers';
import Toaster from 'reports/modules/Toaster';

import ProjectAssetPage from './ProjectAssetPage';

const PROJECT_ADMIN_TEXT = 'Project Admin';
const USER_TEXT = 'User';

interface IOwnProps {
    project: proj.Project;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

const ProjectUserTable: React.FunctionComponent<Omit<IProps, 'addUser'>> = (props) => {
    const { project, user, deleteUser, modifyUser } = props;

    const modifiable = (projectUser: proj.ProjectUser) =>
        project.is_admin && projectUser.user_id !== user.user_id && projectUser.user_id !== project.creator_id;

    return (
        <HTMLTable striped small>
            <thead>
                <tr>
                    <th style={{ padding: 0, minWidth: 0 }}></th>
                    <th style={{ width: '100%' }}>User</th>
                    <th>Role</th>
                </tr>
            </thead>
            <tbody>
                {project.users.map((projectUser) => (
                    <tr key={projectUser.user_id}>
                        <td style={{ padding: '4px 0px' }}>
                            {modifiable(projectUser) && <DeleteButton onClick={() => deleteUser(projectUser)} />}
                        </td>
                        <td>
                            <a href={`mailto:${projectUser.email}`}>
                                {projectUser.first_name} {projectUser.last_name}
                            </a>
                        </td>
                        <td>
                            {modifiable(projectUser) ? (
                                <StaticSelect<boolean>
                                    items={[true, false]}
                                    itemRenderer={(isAdmin, { handleClick, modifiers }) => (
                                        <DropdownMenuItem
                                            key={isAdmin.toString()}
                                            title={isAdmin ? PROJECT_ADMIN_TEXT : USER_TEXT}
                                            onClick={handleClick}
                                            {...modifiers}
                                            active={isAdmin === projectUser.is_admin}
                                        />
                                    )}
                                    onItemSelect={(isAdmin) => modifyUser(projectUser, isAdmin)}
                                    buttonText={projectUser.is_admin ? PROJECT_ADMIN_TEXT : USER_TEXT}
                                />
                            ) : projectUser.is_admin ? (
                                PROJECT_ADMIN_TEXT
                            ) : (
                                USER_TEXT
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </HTMLTable>
    );
};

const ProjectSharing: React.FunctionComponent<IProps> = (props) => {
    const { project, user, addUser, deleteUser, modifyUser } = props;

    const tryAddUser = async (user: usr.User) => {
        if (project.users.find((projectUser) => projectUser.user_id === user.user_id) != null) {
            Toaster.show({
                intent: Intent.DANGER,
                message: `${user.email} is already on this project`,
            });
        } else {
            try {
                await addUser(user);
            } catch (err) {
                console.error(err.response);
                Toaster.show({
                    intent: Intent.DANGER,
                    message: `Error adding ${user.email} to project`,
                });
            }
        }
    };

    const projectUserIds = project.users.map((u) => u.user_id);

    const contextEl = project.is_admin ? (
        <Flex.Container>
            {!user.team.share_projects && (
                <UserSelectDropdownContainer
                    text="Add user..."
                    onItemSelect={tryAddUser}
                    includeOnlyTeamId={user.team_id}
                    excludeUserIds={projectUserIds}
                />
            )}
            <ProjectShareButton hash={project.share_hash} />
        </Flex.Container>
    ) : undefined;

    return (
        <ProjectAssetPage>
            <Section2
                title="Current Users"
                subtitle="Each user on a Project has full access to all Designs, Condition Sets, and Reports"
                contextEl={contextEl}
            >
                <ProjectUserTable project={project} user={user} deleteUser={deleteUser} modifyUser={modifyUser} />
            </Section2>
        </ProjectAssetPage>
    );
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(({ project }) => ({
    addUser: (user: usr.User) =>
        proj.api.addUser({
            user_id: user.user_id,
            project_id: project.project_id,
        }),
    deleteUser: (projectUser: proj.ProjectUser) =>
        proj.api.deleteUser({
            user_id: projectUser.user_id,
            project_id: projectUser.project_id,
        }),
    modifyUser: (projectUser: proj.ProjectUser, isAdmin: boolean) =>
        proj.api.modifyUser(
            { is_admin: isAdmin },
            {
                user_id: projectUser.user_id,
                project_id: projectUser.project_id,
            },
        ),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSharing);
