/**
 * Generic prompt for forms
 */
import * as React from 'react';
import Logger from 'js-logger';
import classNames from 'classnames';
import styled from 'styled-components';

import { Classes, Dialog, IconName, IDialogProps } from '@blueprintjs/core';

import { Deferred } from 'reports/utils/async_helpers';

import { PrimaryButton, PrimaryIntent, Button as SecondaryButton } from 'reports/components/core/controls';

import DialogActions from './DialogActions';
import { insertReactNode } from './DomInjector';

const StyledPrimaryButton = styled(PrimaryButton)`
    white-space: nowrap;
`;

const logger = Logger.get('dialog');

// By default this component is designed for Forms, but
// anything that accepts an `onUpdate` callback
// that provides these parameters can work with FormPrompt
interface UpdateProps<Rtn> {
    valid: boolean;
    submitForm: () => Promise<Rtn> | Rtn;
}

type Submittable<
    Rtn extends object,
    P extends {
        onUpdate: (formContext: UpdateProps<Rtn>) => any;
    } = any,
> = React.ComponentClass<P> | React.FunctionComponent<P>;

interface IDialogCallbacks<Rtn> {
    onCancel: () => void;
    onClosed: () => void;
    onSubmit: (r: Rtn) => void;
}

export type DialogPropOverrides = Omit<
    IDialogProps,
    'canOutsideClickClose' | 'canEscapeKeyClose' | 'isCloseButtonShown' | 'onClose' | 'onClosed' | 'isOpen' | 'onOpened'
>;

interface IFormPromptOpts<Rtn extends object> {
    title?: string;
    icon?: IconName;
    submitLabel?: string;
    submitIntent?: PrimaryIntent;
    cancellable?: boolean;

    Component: Submittable<Rtn>;
    componentProps?: any;

    dialogProps?: DialogPropOverrides;
}

type IFormDialogProps<Rtn extends Object> = IFormPromptOpts<Rtn> & IDialogCallbacks<Rtn>;
interface IDialogState<Rtn> {
    isOpen: boolean;
    isLoading: boolean;
    wiggleSubmit: boolean;

    canSubmit: boolean;
    submitForm?: () => Promise<Rtn> | Rtn;
}

class FormPrompt<Rtn extends object> extends React.PureComponent<IFormDialogProps<Rtn>, IDialogState<Rtn>> {
    state: IDialogState<Rtn> = {
        isOpen: true,
        isLoading: false,
        wiggleSubmit: false,
        canSubmit: true,
    };
    private wiggleTimeoutId: number;

    handleFormUpdate = (providerValue: UpdateProps<Rtn>) => {
        this.setState({
            canSubmit: providerValue.valid,
            submitForm: providerValue.submitForm,
        });
    };

    public componentWillUnmount() {
        window.clearTimeout(this.wiggleTimeoutId);
    }

    finalize = (callback) => async () => {
        try {
            await callback();
            this.setState({ isOpen: false });
        } catch (err) {
            // swallow expected errors (only to avoid debugging overlay)
            logger.debug('form submit failed');
        }
    };

    wiggleButton() {
        this.setState({ wiggleSubmit: true });

        this.wiggleTimeoutId = window.setTimeout(() => this.setState({ wiggleSubmit: false }), 125);
    }

    submit = async () => {
        if (this.state.submitForm == null) {
            logger.error('submitForm ref has not been initialized yet');
            return;
        }
        this.setState({ isLoading: true });
        try {
            const result = await this.state.submitForm();
            this.props.onSubmit(result);
        } catch (exc) {
            this.wiggleButton();
            throw exc;
        } finally {
            this.setState({ isLoading: false });
        }
    };

    render() {
        const {
            Component,
            componentProps = {},

            // actions
            cancellable = false,
            submitLabel = 'Ok',
            submitIntent,

            // dialog presentation
            title,
            icon,
            dialogProps = {},

            // state callbacks
            onCancel,
            onClosed,
        } = this.props;

        const { canSubmit, isOpen, isLoading, wiggleSubmit } = this.state;

        return (
            <Dialog
                title={title}
                icon={icon}
                canOutsideClickClose={false}
                canEscapeKeyClose={cancellable && !isLoading}
                isCloseButtonShown={cancellable && !isLoading}
                onClose={this.finalize(onCancel)}
                onClosed={onClosed}
                isOpen={isOpen}
                {...dialogProps}
            >
                <div className={Classes.DIALOG_BODY}>
                    <Component onUpdate={this.handleFormUpdate} onInit={this.handleFormUpdate} {...componentProps} />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <DialogActions>
                        {cancellable ? (
                            <SecondaryButton text={'Cancel'} disabled={isLoading} onClick={this.finalize(onCancel)} />
                        ) : null}
                        <StyledPrimaryButton
                            type="submit"
                            text={submitLabel}
                            intent={submitIntent}
                            onClick={this.finalize(this.submit)}
                            loading={isLoading}
                            disabled={!canSubmit}
                            className={classNames({
                                'wiggle-infinite': wiggleSubmit,
                            })}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

/**
 * show a user a modal dialog and return a promise that resolves to the type handled by the form
 *
 * @param {IFormPromptOpts} opts
 */
export async function promptModalForm<Rtn extends object>(opts: IFormPromptOpts<Rtn>) {
    const responseDeferred = new Deferred<Rtn>();
    const dialogClosed = new Deferred<Boolean>();

    const dialog = (
        <FormPrompt
            {...opts}
            onSubmit={(rtn: Rtn) => responseDeferred.resolve(rtn)}
            onCancel={() => responseDeferred.reject('cancelled')}
            onClosed={() => dialogClosed.resolve(true)}
        />
    );

    const removeFromDom = insertReactNode(dialog);

    dialogClosed.promise.finally(removeFromDom);

    return responseDeferred.promise;
}
