import * as React from 'react';

import { Callout, Classes, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Colors from 'reports/styles/colors';
import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const IntentContainer = styled.div.attrs<{ intent?: Intent }>(({ intent = Intent.NONE }) => ({
    className: Classes[`INTENT_${intent.toUpperCase()}`],
}))`
    &.${Classes.INTENT_PRIMARY} {
        color: ${Colors.INTENT_PRIMARY};
    }
    &.${Classes.INTENT_SUCCESS} {
        color: ${Colors.INTENT_SUCCESS};
    }
    &.${Classes.INTENT_DANGER} {
        color: ${Colors.INTENT_DANGER};
    }
    &.${Classes.INTENT_WARNING} {
        color: ${Colors.INTENT_WARNING};
    }
`;

export const Warning = ({
    msg,
    className,
    intent = Intent.WARNING,
}: {
    msg: React.ReactNode;
    className?: string;
    intent?: Intent;
}) => (
    <IntentContainer intent={intent} className={className}>
        <Icon icon={IconNames.WARNING_SIGN} style={{ marginRight: 8 }} intent={intent} />
        {msg}
    </IntentContainer>
);

export const FormErrorCallout = ({ errorMsg, style }: { errorMsg: string; style?: React.CSSProperties }) => (
    <Callout icon={null} intent={Intent.DANGER} style={{ marginBottom: '5px', ...style }}>
        <Warning intent={Intent.DANGER} msg={errorMsg} />
    </Callout>
);
