import { FormGroup } from '@blueprintjs/core';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ReferrerType } from 'reports/analytics/ReferrerTypes';
import { IntentContainer } from 'reports/components/helpers/errors';
import * as cfg from 'reports/config';
import { getUserStatusIntent, User, UserStatuses } from 'reports/models/user';
import { selectors as authSelectors } from 'reports/modules/auth';
import { DetailLine } from 'reports/modules/settings/common';
import { IAppState } from 'reports/store';

import { PlanInfoWrapper } from './PlanInfo';
import { PurchaseLink } from './PurchaseLink';
import { createTestClockDialog } from './StripeCustomerWithTestClockForm';

interface Props {
    periodEnd: moment.Moment;
    user: User;
    referrer: ReferrerType;
    manageBilling: boolean;
}

const TrialPlanInfo = ({ periodEnd, user, referrer, manageBilling }: Props) => {
    const authUser = useSelector((state: IAppState) => authSelectors.getUser(state)!);
    const config = useSelector((state: IAppState) => cfg.selectors.getConfig(state));

    const showPurchasePlan = manageBilling && user.team.can_purchase_subscription;
    const showCreateTestClock = manageBilling && config?.debug && !user.team.stripe_customer_id && authUser === user;

    const createTestClockLink = (
        <DetailLine>
            <a
                onClick={async () => {
                    await createTestClockDialog(user);
                }}
            >
                Create Stripe Customer with Test Clock
            </a>
        </DetailLine>
    );

    return (
        <PlanInfoWrapper>
            <FormGroup label="Account Status">
                <IntentContainer intent={getUserStatusIntent(user.status)}>{UserStatuses[user.status]}</IntentContainer>
            </FormGroup>
            <FormGroup label="Plan Summary">HelioScope Free Trial</FormGroup>
            <FormGroup label="Trial Ends">
                <DetailLine>{periodEnd.format('ll')}</DetailLine>
                {showPurchasePlan && <PurchaseLink referrer={referrer} />}
                {showCreateTestClock && createTestClockLink}
            </FormGroup>
        </PlanInfoWrapper>
    );
};

export { TrialPlanInfo };
