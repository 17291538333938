/* tslint:disable:variable-name */
import { get } from 'lodash';

import * as React from 'react';

import { Flex } from 'reports/components/core/containers';
import { DeleteButton } from 'reports/components/core/controls';
import { BehavedButton } from 'reports/components/helpers/common';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const Row = styled(Flex.Container)`
    padding: 2px;
`;

interface IResourceListProps {
    items: any[];
    labelField: string;
    onClick: (item: any) => void;
    onDelete?: (item: any) => void;
}

const ResourceList: React.FC<IResourceListProps> = (props) => {
    const { items, labelField, onClick, onDelete } = props;
    return (
        <>
            {items.map((item, idx) => (
                <Row key={idx}>
                    <BehavedButton
                        minimal
                        small
                        fill={true}
                        text={get(item, labelField, '')}
                        onClick={() => onClick(item)}
                        style={{ margin: '0 4px' }}
                    />
                    {onDelete && (
                        <DeleteButton minimal small onClick={() => onDelete(item)} style={{ margin: '0 4px' }} />
                    )}
                </Row>
            ))}
        </>
    );
};

export default ResourceList;
