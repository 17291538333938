import { get, isEmpty } from 'lodash';
import moment from 'moment';

import * as fmt from 'reports/utils/formatters';

import type { Design } from 'reports/models/design';
import type { Project } from 'reports/models/project';
import type { Simulation } from 'reports/models/simulation';

import { hasOutput, IncompleteFinStatus, FIN_STATUSES, IFinancialOutput } from 'reports/modules/financials/state';

import { IReportContext } from '../widgets';
import { createDeclarativeTokens } from './declarativeTokens';
import { createDynamicTokens } from './dynamicTokens';

type IFormatter = (val: any, options: fmt.INumberOptions) => string;

export type ITokenMap = { [key: string]: Token };

interface IToken {
    category: string;
    description: string;
    formatter: keyof typeof FORMATTERS | IFormatter;
    selector: string;
    hidden?: boolean; // hidden tokens still function, but are not suggested in autocomplete.
}

interface ITokenContext {
    project: Project;
    simulation: Simulation;
    design: Design;
    financialTokens: IFinancialOutput;
}
type TokenValue = string | number | number[] | moment.Moment | undefined | null;
type ICalculator = (context: ITokenContext) => TokenValue;
interface INoFinCalculator {
    omitFinancials: true;
    (context: Omit<ITokenContext, 'financialTokens'>): TokenValue;
}
type Path = string | ICalculator | INoFinCalculator;

export interface IGenericTokenConfig extends IToken {
    path: Path;
    subpath?: string;
}

type MaybeTokenValue = TokenValue | IncompleteFinStatus;
// Avoid referring to these outside of the tokens module
export const FORMATTED_SPECIAL_VALUES: {
    [key in keyof typeof FIN_STATUSES]: string;
} = {
    refreshing: '(refreshing...)',
    computing: '(computing...)',
    missingInputs: 'N/A',
    permissionsError: '(access error)',
    calculationError: '(error)',
};

// Default type formatters
export const FORMATTERS: { [key: string]: IFormatter } = {
    currency: (val, options) => `${fmt.currency(val, { displayOnly: true, ...options })}`,
    date: (val) => `${moment(val).format('MMMM DD, YYYY')}`,
    energy: (val, options) => fmt.humanizeEnergy(val, options),
    percent: (val, { precision = 2, ...opts }) => fmt.percentage(val, { precision, ...opts }),
    power: (val, options) => fmt.humanizeWatts(val, options),
    number: (val, { precision = 1, ...opts }) => fmt.stringifyNumber(val, { precision, ...opts }),
    integer: (val, options) => fmt.stringifyNumber(val, options),
    duration_years: (val, { precision = 1, ...opts }) =>
        fmt.duration(val, {
            precision,
            ...opts,
            unit: 'year',
            unitDisplay: 'long',
        }),
};

const omitFinancials = (calculator: ICalculator | INoFinCalculator): calculator is INoFinCalculator => {
    return (calculator as INoFinCalculator).omitFinancials;
};

export const getTokenVal = (context: IReportContext, path: Path): MaybeTokenValue => {
    if (typeof path === 'function') {
        const { project, design, simulation, financialTokens } = context;
        if (omitFinancials(path)) {
            return path({ project, design, simulation });
        }

        if (!hasOutput(financialTokens)) {
            return financialTokens.status;
        }
        return path({ project, design, simulation, financialTokens });
    }

    return get(context, path);
};

export const createToken = (tokenCfg: IToken, rawVal: MaybeTokenValue) => new Token({ ...tokenCfg, rawVal });

export class Token {
    category: string;
    description: string;
    formatter: IFormatter;
    selector: string;
    hidden?: boolean;
    _val: MaybeTokenValue;

    constructor(data: IToken & { rawVal: MaybeTokenValue }) {
        this.category = data.category;
        this.description = data.description;
        this.formatter = typeof data.formatter === 'function' ? data.formatter : FORMATTERS[data.formatter];
        this.selector = data.selector;
        this.hidden = data.hidden;
        this._val = data.rawVal;
    }

    format(options: fmt.INumberOptions = {}) {
        switch (this._val) {
            case FIN_STATUSES.refreshing:
                return FORMATTED_SPECIAL_VALUES.refreshing;
            case FIN_STATUSES.computing:
                return FORMATTED_SPECIAL_VALUES.computing;
            case FIN_STATUSES.permissionsError:
                return FORMATTED_SPECIAL_VALUES.permissionsError;
            case FIN_STATUSES.calculationError:
                return FORMATTED_SPECIAL_VALUES.calculationError;

            case FIN_STATUSES.missingInputs:
            case undefined:
            case null:
                return 'N/A';
        }

        // force output type (note that weirdly if _val is a number this is still fine)
        const str = this._val as string;
        return this.formatter != null ? this.formatter(str, options) : str;
    }

    get rawValue() {
        return this._val;
    }
}

/**
 * Enumerate selectors for tokens with multiple values.
 * Eg. 'module_manufacturer_1', 'module_manufacturer_2', etc.
 * @param instances - array of instances of the same token type
 * @param tokenConfig - token configuration
 * @param tokens - token map to populate
 * @returns populated token map
 */
export function createMultipleInstanceTokens(
    instances: object[],
    tokenConfig: IGenericTokenConfig,
    tokens: ITokenMap,
): ITokenMap {
    const totalUniq = Object.keys(instances).length;
    const { selector, subpath } = tokenConfig;

    if (isEmpty(instances)) {
        return tokens;
    }

    instances.forEach((instance, i) => {
        const subVal = subpath ? get(instance, subpath) : instance;
        const selectorKey = totalUniq === 1 ? selector : `${selector}_${i + 1}`;

        tokens[selectorKey] = createToken(tokenConfig, subVal);
    });

    return tokens;
}

/**
 * Populates token map with project values.
 * @param context - project context to populate tokens with
 * @returns populated token map
 */
export function createTokenMap(context: IReportContext): ITokenMap {
    const tokens: ITokenMap = {};

    createDeclarativeTokens(context, tokens);
    createDynamicTokens(context, tokens);

    return tokens;
}
