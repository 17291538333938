import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as auth from 'reports/modules/auth';

import * as teamLimitsAndUsage from 'reports/models/team_usage';
import { useWatchPusherChannel } from '../hooks/useWatchPusherChannel';

const TeamProjectUsageListener = () => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const dispatch = useDispatch();
    const fetchTeamLimitsAndUsage = (teamId) => dispatch(teamLimitsAndUsage.api.get({ team_id: teamId }));

    const loadTeamUsageAndLimits = React.useCallback(async () => {
        if (user) {
            await fetchTeamLimitsAndUsage(user.team_id);
        }
    }, []);

    useWatchPusherChannel({
        channelPrefix: 'team_limits_and_usage',
        entityId: user.team_id,
        eventName: 'team.limits_and_usage_updated',
        onReceiveEvent: loadTeamUsageAndLimits,
    });

    React.useEffect(() => {
        loadTeamUsageAndLimits();
    }, []);

    return <></>;
};

export default TeamProjectUsageListener;
