import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router';
import { Button, Card, Flex } from '@aurorasolar/ds';

const defaultErrorSvg = require('frontend/media/error-default.svg');
const forbiddenErrorSvg = require('frontend/media/error-403.svg');

const ErrorPage = ({ status }: { status: number | null }) => {
    const navigate = useNavigate();
    const is403Error = status === 403;
    // 403 errors were separated out with the "Admin override" button in mind, which only displays in Beta
    // if the error is a 403. This will be implemented in HEL-3639.
    return (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" minHeight="100%" rowGap={0}>
            {is403Error ? (
                <ErrorCard
                    flexStyle="flex-start"
                    imgPath={forbiddenErrorSvg}
                    navigate={navigate}
                    showButton={true}
                    title="Access denied"
                />
            ) : (
                <ErrorCard
                    flexStyle="flex-end"
                    imgPath={defaultErrorSvg}
                    navigate={navigate}
                    showButton={false}
                    title="We've drifted off course"
                />
            )}
        </Flex>
    );
};

const ErrorCard = ({
    flexStyle,
    imgPath,
    navigate,
    showButton,
    title,
}: {
    flexStyle: string;
    imgPath: string;
    navigate: NavigateFunction;
    showButton: boolean;
    title: string;
}) => {
    return (
        <>
            <Flex w={350} minH={216} flexDirection="row" bg="gray-200" alignItems={flexStyle} justifyContent="center">
                <img src={imgPath}></img>
            </Flex>
            <Card maxW={350} bg="gray-100" flexGrow={0} border="none" padding="none">
                <h2>{title}</h2>
                <div>Something went wrong and we're not able to find what you're looking for.</div>
                <div>
                    <a href="mailto:support@helioscope.com">Contact support</a> if the problem persists.
                </div>
                {showButton && (
                    <Button variant="primary" onClick={() => navigate('/')}>
                        Home
                    </Button>
                )}
            </Card>
        </>
    );
};

export { ErrorPage };
