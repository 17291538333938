import { IChatClient } from './common';

// A fallback chat client that does nothing so that we don't have null reference problems
// in case Zendesk doesn't load properly.
const NoopChat: IChatClient = {
    login() {},
    newMessage() {},
    updateVisitorStatus() {},
};

export default NoopChat;
