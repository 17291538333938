import { get, set } from 'lodash';
import moment from 'moment';

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Alignment,
    Classes,
    Intent,
    ISwitchProps,
    PopoverPosition,
    Switch as BPSwitch,
    Tab,
    Tabs,
} from '@blueprintjs/core';
import { DateInput } from '@blueprintjs/datetime';

import { DEFAULT_LOCALE } from 'reports/localization';

import { fromNow } from 'reports/utils/formatters';

import SaveDropdown from 'reports/components/library/SaveDropdown';
import { ContextBarControls } from 'reports/components/ContextBar';
import { Card, Flex } from 'reports/components/core/containers';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import { Warning } from 'reports/components/helpers/errors';
import Toaster from 'reports/modules/Toaster';

import { User } from 'reports/models/user';
import * as ur from 'reports/models/utility_rate';
import * as auth from 'reports/modules/auth';

import { getRateParam } from 'reports/modules/financials/utils';
import { validateSeasonalInput } from 'reports/modules/utility_rate/utils';
import { actions as rateActions } from 'reports/modules/utility_rate';

import OverviewPanel from './OverviewPanel';
import RatesPanel from './RatesPanel';

import * as styles from 'reports/styles/styled-components';
import { RouterStateLock } from 'reports/utils/router';

const styled = styles.styled;

enum TABS {
    DEMAND = 'demand',
    ENERGY = 'energy',
    OVERVIEW = 'overview',
}

const ContentBody = styled(Flex.Main)`
    margin: 10px 12px;

    .${Classes.TABS} {
        margin: 0 8px;
    }

    .${Classes.TAB_PANEL} {
        margin-top: 0;
        overflow: hidden;

        ${Card} {
            overflow-x: auto;
        }
    }
`;

export interface IRow {
    label: string;
    helperText?: string;
    labelStyle?: React.CSSProperties;
    leftEl?: JSX.Element;
    rightEl?: JSX.Element | string;
    showIf?: boolean;
    style?: React.CSSProperties;
}

export const Row = ({ label, rightEl, leftEl, helperText, style, showIf = true, labelStyle = { width: 150 } }: IRow) =>
    showIf ? (
        <Flex.Container className="center" style={{ minHeight: 30, margin: '6px 0', ...style }}>
            {leftEl}
            <Flex.ContainerV className="center" style={labelStyle}>
                <div>{label}</div>
                {helperText && <div style={{ fontSize: 12, fontWeight: 400 }}>{helperText}</div>}
            </Flex.ContainerV>
            {typeof rightEl === 'string' ? <div>{rightEl}</div> : rightEl}
        </Flex.Container>
    ) : null;

export const Switch = ({ style, ...props }: ISwitchProps & { style?: React.CSSProperties }) => (
    <BPSwitch style={{ marginBottom: 0, ...style }} large={true} {...props} />
);

interface Props {
    inlineControls?: boolean;
    navigateToUtilityRate: (utilityRateId: number) => void;
    navigateToUtilityRates: () => void;
    utilityRate: ur.UtilityRate;
}

const UtilityRateEditor = ({
    inlineControls = false,
    navigateToUtilityRate,
    navigateToUtilityRates,
    utilityRate,
}: Props) => {
    const [selectedTabId, setSelectedTabId] = useState<string>(TABS.OVERVIEW);
    const dispatch = useDispatch();
    const user = useSelector((state) => auth.selectors.getUser(state)) as User;
    const clearChanges = async () => dispatch(await ur.saver.get(utilityRate).clear());
    const hasChanges = () => dispatch(ur.saver.get(utilityRate).hasChanges());

    const saveUtilityRate = () => dispatch(ur.saver.get(utilityRate).save());
    const saveAsNewUtilityRate = async () => dispatch(await rateActions.saveAsNew(utilityRate));
    const updateUtilityRate = (patch) => dispatch(ur.saver.get(utilityRate).patch(patch));
    const safeDeleteUtilityRate = () => dispatch(rateActions.safeDeleteUtilityRate(utilityRate));
    const getRateProp = (path: string) => get(utilityRate!, path, null);

    const energyParam = getRateParam('energy_rates');
    const demandParam = getRateParam('demand_rates');

    const lastModified = utilityRate.last_modified || utilityRate.created;
    const lastModifiedBy = utilityRate.last_modified_by_user || utilityRate.creator;

    const demandRates = getRateProp('data.demand_rates');
    const seasonalInput = getRateProp('data.seasonal_input');

    const onDelete = async () => {
        try {
            await safeDeleteUtilityRate();
            Toaster.show({
                intent: Intent.SUCCESS,
                message: `Successfully deleted utility rate`,
                timeout: 2500,
            });
        } catch {
            Toaster.show({
                icon: 'warning-sign',
                message: <div>Can not delete rate: in use by one or more projects</div>,
                timeout: 2500,
            });
        }
    };

    const preSave = async () => {
        const { seasonal_input: seasonalInput, fixed_rates: fixedCharges } = utilityRate.data;

        if (seasonalInput) {
            const validation = validateSeasonalInput(utilityRate);

            if (validation.error) {
                return Promise.reject(validation.error);
            }

            // Update rate config if valid, currently only handles energy rates
            const config = utilityRate.toRateConfig();
            updateParameter('data.energy_rates', config);
        }

        if (fixedCharges) {
            fixedCharges.forEach((charge) => {
                if (!charge.name) {
                    throw 'All fixed charges must have names.';
                }
            });
        }
    };

    const save = async () => {
        await preSave();
        return saveUtilityRate();
    };
    const saveAndClose = async () => {
        await save();
        navigateToUtilityRates();
    };

    const saveAsNew = async () => {
        await preSave();
        const newRate = await saveAsNewUtilityRate();
        await clearChanges();
        navigateToUtilityRate(newRate.utility_rate_id);
    };

    const cancel = async () => {
        await clearChanges();
        navigateToUtilityRates();
    };

    const updateParameter = (path, val) => {
        if (user.team_id !== utilityRate.team_id) return;
        const patch = {};
        set(patch, path, val);
        updateUtilityRate(patch);
    };

    return (
        <>
            <RouterStateLock
                title="Save utility rate changes?"
                prompt={
                    `You have unsaved changes on ` +
                    `${utilityRate.description || 'utility rate'}` +
                    `, would you like to save these changes before leaving?`
                }
                cancellable={true}
                showExitPrompt={inlineControls ? hasChanges() : hasChanges}
                onSave={save}
                onDontSave={clearChanges}
            />

            <Flex.ContainerV style={{ flex: 1 }}>
                <div className="content-header">
                    {!auth.permissions.canModifySharedTeamResource(user, utilityRate) && (
                        <div className="sub-content-box-1">
                            <div style={{ textAlign: 'center' }}>
                                <Warning msg={'View Only. No permissions to edit.'} intent="warning" />
                            </div>
                        </div>
                    )}
                    {inlineControls && (
                        <Flex.Container style={{ paddingLeft: 15, paddingRight: 30, justifyContent: 'space-between' }}>
                            <EditableTitleSubHeader
                                value={utilityRate.description}
                                updateFn={(val) => updateParameter('description', val)}
                            />

                            <SaveDropdown
                                save={saveAndClose}
                                saveAsNewOptions={{
                                    saveAsNew,
                                    text: 'Save as New Utility Rate',
                                }}
                                saveEnabled={
                                    hasChanges() && auth.permissions.canModifySharedTeamResource(user, utilityRate)
                                }
                                cancel={cancel}
                                toastDescr={utilityRate.description}
                            />
                        </Flex.Container>
                    )}
                    {!inlineControls && (
                        <EditableTitleSubHeader
                            value={utilityRate.description}
                            updateFn={(val) => updateParameter('description', val)}
                        />
                    )}
                </div>

                <Flex.Container style={{ margin: '12px 20px' }}>
                    <Flex.Main>
                        <Row
                            label="Owner"
                            rightEl={utilityRate.creator && utilityRate.creator.fullName()}
                            showIf={!!utilityRate.creator}
                        />
                        <Row
                            label="Last Modified"
                            rightEl={
                                <div>
                                    <div>{lastModifiedBy && lastModifiedBy.fullName()}</div>
                                    <div>{fromNow(lastModified!)}</div>
                                </div>
                            }
                            showIf={!!lastModifiedBy}
                            style={{ alignItems: 'flex-start' }}
                        />
                        <Row
                            label="Date Effective"
                            rightEl={
                                <DateInput
                                    value={utilityRate.apply_date.toDate()}
                                    onChange={(dateObj) => updateParameter('apply_date', moment(dateObj))}
                                    formatDate={(dateObj) => dateObj.toLocaleDateString(DEFAULT_LOCALE.code)}
                                    parseDate={(dateStr) => new Date(dateStr)}
                                    placeholder={'M/D/YYYY'}
                                    popoverProps={{
                                        position: PopoverPosition.BOTTOM,
                                    }}
                                    minDate={new Date('1980-01-01T00:00:00')}
                                    maxDate={new Date('2120-01-01T00:00:00')}
                                />
                            }
                        />
                        {auth.permissions.canMakeResourcePublic(user, utilityRate) && (
                            <Row
                                label="Public"
                                rightEl={
                                    <Switch
                                        checked={utilityRate.public}
                                        onChange={() => updateParameter('public', !utilityRate.public)}
                                        alignIndicator={Alignment.RIGHT}
                                        style={{ marginLeft: -10 }}
                                    />
                                }
                            />
                        )}
                    </Flex.Main>
                </Flex.Container>

                <ContentBody>
                    <Tabs
                        id="utility-rate-editor"
                        selectedTabId={selectedTabId}
                        onChange={(tabId: string) => setSelectedTabId(tabId)}
                    >
                        <Tab
                            id={TABS.OVERVIEW}
                            title="Overview"
                            panel={
                                <OverviewPanel
                                    onUpdate={(subpath, value) => updateParameter(`data.${subpath}`, value)}
                                    onDelete={onDelete}
                                    user={user}
                                    utilityRate={utilityRate}
                                />
                            }
                        />
                        <Tab
                            id={TABS.ENERGY}
                            title="Energy Charges"
                            panel={
                                <RatesPanel
                                    isEnergy={true}
                                    param={energyParam}
                                    rates={getRateProp('data.energy_rates')}
                                    seasonalInput={seasonalInput}
                                    onUpdate={(subpath, value) => updateParameter(`data.${subpath}`, value)}
                                />
                            }
                        />
                        {demandRates && !seasonalInput ? (
                            <Tab
                                id={TABS.DEMAND}
                                title="Demand Charges"
                                panel={
                                    <RatesPanel
                                        isEnergy={false}
                                        param={demandParam}
                                        rates={demandRates}
                                        seasonalInput={seasonalInput}
                                        onUpdate={(subpath, value) => updateParameter(`data.${subpath}`, value)}
                                    />
                                }
                            />
                        ) : null}
                    </Tabs>
                </ContentBody>
                <div className="sub-content-footer" />
            </Flex.ContainerV>
            {!inlineControls && (
                <ContextBarControls>
                    <SaveDropdown
                        save={saveAndClose}
                        saveAsNewOptions={{
                            saveAsNew,
                            text: 'Save as New Utility Rate',
                        }}
                        saveEnabled={hasChanges() && auth.permissions.canModifySharedTeamResource(user, utilityRate)}
                        cancel={cancel}
                        toastDescr={utilityRate.description}
                    />
                </ContextBarControls>
            )}
        </>
    );
};

export { UtilityRateEditor };
