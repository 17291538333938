/* tslint:disable:no-increment-decrement */
import * as React from 'react';
import { ChromePicker } from 'react-color';

import { Classes, Colors, NonIdealState, Popover, Position } from '@blueprintjs/core';

import { DeepPartial } from 'reports/utils/types';
import { Theme } from 'reports/models/theme';

import { IColor } from 'reports/modules/report/components/forms/ColorPicker';
import { IThemeKey } from 'reports/modules/themes';
import { PALETTE_COUNT } from './ThemeEditor';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ColorPicker = styled.div`
    align-self: center;

    span.${Classes.POPOVER_WRAPPER}, span.${Classes.POPOVER_TARGET} {
        flex: 1;
        display: flex;
        height: 100%;
    }
`;

const PaletteContainer = styled.div`
    flex: 1;
    display: grid;
    min-height: 0;
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 4px), 1fr));
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 4px;
    margin-top: 2px;
`;

const PaletteDescriptor = styled.div`
    padding: 8px 4px 0;
    font-size: 12px;
`;

const PaletteSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const PopoverContent = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    > :not(:last-child) {
        padding-right: 20px;
    }
`;

const SwatchEmpty = styled(NonIdealState)`
    background: ${Colors.LIGHT_GRAY5};
    border-radius: 4px;

    .${Classes.ICON} {
        display: flex;
    }
`;

export const SwatchEl = styled.div.attrs<DeepPartial<ISwatchProps>>({
    className: 'hover-select-state',
})`
    margin: ${(props) => (props.small ? 0 : '2px')};
    flex: ${(props) => (props.small ? '1 1 calc(33% - 4px)' : 1)};
    border: 1px solid ${(props) => (props.color ? 'transparent' : Colors.LIGHT_GRAY3)};
    border-radius: 4px;

    ${SwatchEmpty} .${Classes.ICON} svg {
        height: ${(props) => (props.small ? 30 : 50)}px;
        width: ${(props) => (props.small ? 30 : 50)}px;
    }
`;

const SwatchSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

interface IState {
    popoverOpen: boolean;
    selectedThemeParam?: string;
}

interface ISwatchProps {
    onClick: (e: React.SyntheticEvent, themeKey: IThemeKey, idx?: number) => void;
    sKey: IThemeKey;
    color?: string;
    key?: string;
    idx?: number;
    small?: boolean | 'true' | 'false';
}

interface IThemeColorPicker {
    style?: React.CSSProperties;
    theme: Theme;
    updateTheme: (patch: DeepPartial<Theme>) => void;
}

const Swatch = ({ color, sKey, idx, onClick, small }: ISwatchProps) => {
    const strKey = `${sKey}${idx != null ? `_${idx}` : ''}` as any;
    return color && color !== 'none' ? (
        <SwatchEl
            key={strKey}
            onClick={(e) => onClick(e, sKey, idx)}
            style={{ backgroundColor: color }}
            small={small}
        />
    ) : (
        <SwatchEl key={strKey} onClick={(e) => onClick(e, sKey, idx)} small={small}>
            <SwatchEmpty icon="tint" />
        </SwatchEl>
    );
};

class ThemeColorPicker extends React.PureComponent<IThemeColorPicker, IState> {
    state: IState = {
        popoverOpen: false,
        selectedThemeParam: undefined,
    };

    paletteCount: number = PALETTE_COUNT;
    rowCount: number = 2;

    render() {
        const { style, theme } = this.props;
        const color = this.getCurrentColor() || 'none';
        const content = (
            <ChromePicker
                color={color}
                onChange={this.onColorChange}
                onChangeComplete={this.onColorChange}
                disableAlpha={true}
            />
        );

        return (
            <ColorPicker style={style}>
                <Popover
                    content={content}
                    isOpen={this.state.popoverOpen}
                    position={Position.RIGHT}
                    onClose={() => this.togglePopover(false)}
                >
                    <PopoverContent>
                        <PaletteSection>
                            <SwatchSection>
                                <PaletteDescriptor>Primary</PaletteDescriptor>
                                <Swatch sKey="primary_color" onClick={this.onSwatchClick} color={theme.primary_color} />
                            </SwatchSection>
                            <SwatchSection>
                                <PaletteDescriptor>Secondary</PaletteDescriptor>
                                <Swatch
                                    sKey="secondary_color"
                                    onClick={this.onSwatchClick}
                                    color={theme.secondary_color}
                                />
                            </SwatchSection>
                        </PaletteSection>
                        <PaletteSection style={{ flexDirection: 'column', flex: 1.3 }}>
                            <PaletteDescriptor>Palette Colors</PaletteDescriptor>
                            {this.renderPalette()}
                        </PaletteSection>
                    </PopoverContent>
                </Popover>
            </ColorPicker>
        );
    }

    renderPalette() {
        const swatches: JSX.Element[] = [];
        const { palette } = this.props.theme;

        for (let i = 0; i < PALETTE_COUNT; ++i) {
            const color = palette && palette.colors.length && palette.colors[i] ? palette.colors[i] : undefined;
            swatches.push(
                <Swatch
                    key={`palette_${i}`}
                    color={color}
                    idx={i}
                    onClick={this.onSwatchClick}
                    small={true}
                    sKey="palette"
                />,
            );
        }
        return <PaletteContainer>{swatches}</PaletteContainer>;
    }

    getCurrentColor = () => {
        const { selectedThemeParam } = this.state;
        const { theme } = this.props;

        if (selectedThemeParam) {
            if (theme.palette && theme.palette.colors && selectedThemeParam.startsWith('palette')) {
                const idx = parseInt(selectedThemeParam.split('_')[1], undefined);
                return theme.palette.colors[idx];
            }
            if (theme[selectedThemeParam]) {
                return theme[selectedThemeParam];
            }
        }
        return Colors.WHITE;
    };

    /**
     * Update current theme to reflect color picker changes
     */
    onColorChange = (color: IColor) => {
        const { theme } = this.props;
        const { selectedThemeParam } = this.state;

        if (!selectedThemeParam) {
            return;
        }

        let delta = {};
        if (selectedThemeParam.startsWith('palette_')) {
            const idx = parseInt(selectedThemeParam.split('_')[1], undefined);
            const colors = theme.palette ? theme.palette.colors.slice() : [];
            colors[idx] = color.hex;
            delta = { palette: { colors } };
        } else {
            delta = { [selectedThemeParam]: color.hex };
        }
        this.props.updateTheme(delta);
    };

    /**
     * Update selected theme parameter and open color picker dialog
     * @param {Object} e - click event
     * @param {String} themeKey - selected theme property
     * @param {String} idx - palette index if updating theme color palette
     */
    onSwatchClick = (e: React.SyntheticEvent, themeKey: IThemeKey, idx?: number) => {
        this.state.popoverOpen ? e.stopPropagation() : this.togglePopover(true);

        const selectedThemeParam = themeKey === 'palette' && idx != null ? `${themeKey}_${idx}` : (themeKey as string);
        this.setState({ selectedThemeParam });
    };

    togglePopover = (popoverOpen: boolean) => this.setState({ popoverOpen });
}

export default ThemeColorPicker;
