/* tslint:disable:variable-name */
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { schema } from '../schema';
import { IAPIQueryOpts } from '../types';

import * as prod from './product';

type RecurringInterval = 'month' | 'year'; // Stripe also supports 'day' and 'week', but HelioScope doesn't.

/**
 * Based off the stripe Price object: https://stripe.com/docs/api/prices/object
 */
class Price extends BaseClass {
    id: string;
    active: boolean;
    nickname: string;
    product: prod.Product;
    recurring: {
        aggregate_usage: string;
        interval: RecurringInterval;
        interval_count: number; // positive integer
        usage_type: 'metered' | 'licensed';
    };
    type: PriceType;
    unit_amount: number;
}

type PriceType = 'one_time' | 'recurring';

interface IPriceAPIQueryOpts extends IAPIQueryOpts {
    product_id?: string;
}

const schemaObj = schema.addObject(Price, 'price', {
    idName: 'id',
    relationships: { product: { schema: prod.schemaObj } },
});

export const endpoint = ReduxEndpoint.fromSchema('/api/prices/', schemaObj);

const api = {
    index: endpoint.index<IPriceAPIQueryOpts>(),
};

export { api, schemaObj, Price, RecurringInterval };
export default Price;
