import Translations from 'reports/localization/strings';

// Distance Conversion Constants
export const METER_IN_FEET = 3.28084;
export const METER_IN_MILES = 0.00062137273;
export const METER_IN_KILOMETERS = 0.001;

// Localized Month Strings
const m = Translations.constants;
const MONTHS = [
    m.january,
    m.february,
    m.march,
    m.april,
    m.may,
    m.june,
    m.july,
    m.august,
    m.september,
    m.october,
    m.november,
    m.december,
];
const MONTHS_ABBREV = [
    m.january_abbrev,
    m.february_abbrev,
    m.march_abbrev,
    m.april_abbrev,
    m.may_abbrev,
    m.june_abbrev,
    m.july_abbrev,
    m.august_abbrev,
    m.september_abbrev,
    m.october_abbrev,
    m.november_abbrev,
    m.december_abbrev,
];
const MONTHS_INITIAL = [
    m.january_initial,
    m.february_initial,
    m.march_initial,
    m.april_initial,
    m.may_initial,
    m.june_initial,
    m.july_initial,
    m.august_initial,
    m.september_initial,
    m.october_initial,
    m.november_initial,
    m.december_initial,
];

export { MONTHS, MONTHS_ABBREV, MONTHS_INITIAL };
