/* tslint:disable:variable-name */
import _ from 'lodash';

import * as React from 'react';

import { Button, Classes, HTMLTable } from '@blueprintjs/core';

import { IParamTableDefinition, IParamProps, ParamValueType } from 'reports/modules/financials/params';
import { INumericConfig, ParamNumeric } from './ParamNumeric';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const ParamHTMLTable = styled(HTMLTable).attrs({
    interactive: false,
})`
    &.${Classes.HTML_TABLE} {
        // add specificity to override blueprintjs
        th {
            padding: 6px 0;
            text-align: center;
        }

        td {
            padding: 4px;

            .period-name {
                display: table-cell;
                vertical-align: middle;
                height: 30px;
            }
        }
        &.no-headers th {
            padding: 0;
        }
    }
`;

export interface IColumn extends INumericConfig {
    description: string;
    path: string;
}

export interface ITableConfig<T = any> extends IParamTableDefinition<T> {
    type: ParamValueType.Table;
    columns: IColumn[];
    max_rows: number;
    sort_path: string | null;
    // If true, allows the last table entry to have a null value in the 'sort_path' column
    max_null?: boolean;
    index_label?: string;
    min_rows?: number;
}

export class ParamTable<T = any> extends React.Component<IParamProps<ITableConfig<T>>> {
    render() {
        const { parameter, value, disabled } = this.props;

        const headerElements = [] as any[];
        const rowElements = [] as any[];

        let idx = 0;

        if (parameter.index_label) {
            const element = this.renderHeader({ description: parameter.index_label }, idx);
            headerElements.push(element);
            idx += 1;
        }

        for (const i of parameter.columns) {
            const element = this.renderHeader(i, idx);
            headerElements.push(element);
            idx += 1;
        }

        idx = 0;
        for (const i of value || []) {
            const element = this.renderRow(i, idx);
            rowElements.push(element);
            idx += 1;
        }

        const noAdd = !!(parameter.max_rows && value.length >= parameter.max_rows);

        const editor = (
            <div>
                <ParamHTMLTable>
                    <thead>
                        <tr>
                            <th></th>
                            {headerElements}
                        </tr>
                    </thead>
                    <tbody>
                        {rowElements}
                        <tr>
                            <td>
                                <Button icon="add" disabled={disabled || noAdd} onClick={() => this.addRow()} />
                            </td>
                        </tr>
                    </tbody>
                </ParamHTMLTable>
            </div>
        );

        return editor;
    }

    addRow() {
        const { value, parameter } = this.props;

        const array = (value || []).slice();

        if (array.length) {
            const row = _.merge({}, _.last(array));
            array.push(row);
        } else {
            const row = {} as T;
            for (const i of parameter.columns) {
                row[i.path] = i.default;
            }

            array.push(row);
        }

        this.finalizeUpdate(array);
    }

    deleteRow(index) {
        const { value } = this.props;

        const array = value.slice();
        array.splice(index, 1);

        this.finalizeUpdate(array);
    }

    updateCell(rowidx, parameter, cellValue) {
        const { value } = this.props;

        const array = value.slice();
        const constrained = Math.max(Math.min(cellValue, parameter.max_value), parameter.min_value);
        array[rowidx] = _.assign({}, array[rowidx], {
            [parameter.path]: constrained,
        });

        this.finalizeUpdate(array);
    }

    finalizeUpdate(array) {
        const { parameter, updateFn } = this.props;

        const fix = (array) => {
            const { sort_path, max_null } = parameter;

            if (sort_path && max_null) {
                const fixed = array.map((i) => _.merge({}, i));

                if (fixed.length > 1 && fixed[0][sort_path] === null) {
                    const col = _.find(parameter.columns, (i) => i.path === sort_path);
                    fixed[0][sort_path] = col!.default;
                }

                for (let i = 0; i < fixed.length - 1; i += 1) {
                    if (fixed[i + 1][sort_path] === null) {
                        fixed[i + 1][sort_path] = fixed[i][sort_path];
                    }
                }

                if (fixed.length) {
                    fixed[fixed.length - 1][sort_path] = null;
                }

                return fixed;
            }

            return array;
        };

        if (parameter.sort_path) {
            const { sort_path } = parameter;
            const sorted = _.sortBy(array, (i) => (i[sort_path] === null ? Number.POSITIVE_INFINITY : i[sort_path]));

            if (updateFn) updateFn(fix(sorted));
        } else if (updateFn) {
            updateFn(fix(array));
        }
    }

    renderHeader(column, idx) {
        const element = (
            <th key={idx} style={{ padding: '4px', minWidth: '112px' }}>
                {column.description}
            </th>
        );
        return element;
    }

    renderRow(row, idx) {
        const { parameter, value, disabled } = this.props;

        const cellElements = [] as any[];

        let subidx = 0;

        if (parameter.index_label) {
            const index = <td key={subidx}>{idx + 1}</td>;

            cellElements.push(index);
            subidx += 1;
        }

        for (const i of parameter.columns) {
            const cell = this.renderCell(row, i, idx, subidx);
            cellElements.push(cell);
            subidx += 1;
        }

        const noDel = parameter.min_rows && value.length <= parameter.min_rows;

        const element = (
            <tr key={idx}>
                <td>
                    <Button disabled={noDel || disabled} icon="trash" onClick={() => this.deleteRow(idx)} />
                </td>
                {cellElements}
            </tr>
        );

        return element;
    }

    renderCell(row, parameter, rowidx, idx) {
        const { disabled } = this.props;
        const value = row[parameter.path];

        const effparam = _.assign({}, parameter);

        if (this.props.parameter.sort_path === parameter.path) {
            const prevRow = this.props.value[rowidx - 1];
            if (prevRow) effparam.min_value = prevRow[parameter.path];

            const nextRow = this.props.value[rowidx + 1];
            if (nextRow && nextRow[parameter.path] !== null) {
                effparam.max_value = nextRow[parameter.path];
            }
        }

        const element = (
            <td key={idx}>
                {value === null ? null : (
                    <ParamNumeric
                        value={value}
                        parameter={effparam}
                        disabled={disabled}
                        updateFn={(cellval) => {
                            this.updateCell(rowidx, effparam, cellval);
                        }}
                    />
                )}
            </td>
        );

        return element;
    }
}

export default ParamTable;
