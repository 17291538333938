import * as React from 'react';

import { LibraryPreview as Preview } from 'reports/components/core/layout';
import { IDrawer } from 'reports/components/core/layout/LibraryPreview';

const PreviewDrawer: React.FC<IDrawer> = ({ children, onClose, ...props }) => {
    return (
        <Preview.Drawer
            {...props}
            onClose={() => {
                onClose && onClose();
            }}
        >
            {children}
        </Preview.Drawer>
    );
};

export default PreviewDrawer;
