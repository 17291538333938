import * as React from 'react';

import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Flex from 'reports/components/core/containers/Flex';

import * as usr from 'reports/models/user';

import { DetailsCard } from 'reports/modules/settings/common';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const CardContent = styled(Flex.Container)`
    justify-content: space-evenly;

    > div {
        margin-right: 10px;
        flex: 1;
    }
`;

type Props = React.PropsWithChildren<{
    user: usr.User;
}>;

const UserDetails = ({ user }: { user: usr.User }) => (
    <div>
        <p>
            <Icon icon={IconNames.INBOX} /> {user.email}
        </p>
        <p>
            <Icon icon={IconNames.PEOPLE} /> {user.team.name}
        </p>
        {user.company && (
            <p>
                <Icon icon={IconNames.OFFICE} /> {user.company}
            </p>
        )}
        {user.current_period_end && (
            <p>
                <Icon icon={IconNames.CALENDAR} /> Expires {user.current_period_end.calendar()}
            </p>
        )}
    </div>
);

const UserDetailsCard = ({ user, children }: Props) => (
    <DetailsCard title={user.team_admin ? `${user.fullName()} (Admin)` : user.fullName()} style={{ maxWidth: '100%' }}>
        <CardContent>
            <UserDetails user={user} />
            {children}
        </CardContent>
    </DetailsCard>
);

export default UserDetailsCard;
