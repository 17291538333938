/* tslint:disable:max-line-length */
/**
 * Global styles
 */
import { Colors } from '@blueprintjs/core';

export const DEFAULT_FONT_FAMILY = 'Source Sans Pro';

export const DEFAULT_PALETTE = [
    Colors.BLACK,
    Colors.DARK_GRAY3,
    Colors.COBALT1,
    Colors.TURQUOISE1,
    Colors.GREEN1,
    Colors.RED1,
    Colors.ROSE1,
];

export const HELIOSCOPE_ORANGE = '#FF9900';

export const TRANSPARENT_BG =
    'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMUlEQVQ4T2NkYGAQYcAP3uCTZhw1gGGYhAGBZIA/nYDCgBDAm9BGDWAAJyRCgLaBCAAgXwixzAS0pgAAAABJRU5ErkJggg==") left top';
