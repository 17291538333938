import * as React from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { actions as routerActions } from 'redux-router5';

import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import Flex from 'reports/components/core/containers/Flex';
import Section from 'reports/components/helpers/Section';
import CardSection from 'reports/components/core/containers/CardSection';

import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';
import * as proj from 'reports/models/project';
import { actions } from 'reports/modules/financials/state';
import { actions as projActions, selectors as projSelectors } from 'reports/modules/project';

import FinancialInputsEditor from 'reports/modules/financials/components/FinancialConfigurationMini';

import FinancialConfigSelect, { isFinConfig, FinConfigs } from './FinancialConfigSelect';

interface IOwnProps {
    project: proj.Project;
}

interface IDispatchProps {
    createFinancialConfig: (template: finTemp.FinancialTemplate) => Promise<projFinTemp.ProjectFinancialTemplate>;
    navigateTo: (name: string, params: object) => Action;
    setFinancialConfig: (config: projFinTemp.ProjectFinancialTemplate) => any;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

const FinancialInputs: React.SFC<IProps> = ({ project, primaryFinConfig, ...props }) => {
    const selectOrCreateFinConfig = async (model: FinConfigs) => {
        const config = isFinConfig(model) ? model : await props.createFinancialConfig(model);
        props.setFinancialConfig(config);
    };

    return (
        <Flex.ContainerV>
            <CardSection
                title="Financial Inputs"
                contextEl={
                    <FinancialConfigSelect
                        project={project}
                        onItemSelect={selectOrCreateFinConfig}
                        activeConfig={primaryFinConfig}
                        style={{ maxWidth: 400 }}
                    />
                }
            >
                {primaryFinConfig ? (
                    <>
                        <FinancialInputsEditor config={primaryFinConfig} />
                        <Section.Footer>
                            <Button
                                text="View Detailed Configuration"
                                icon={IconNames.SHARE}
                                onClick={() =>
                                    props.navigateTo(
                                        'app.projects.project.financial-configurations.financial-configuration',
                                        {
                                            projectId: project.project_id,
                                            finConfigId: primaryFinConfig.project_financial_template_id,
                                        },
                                    )
                                }
                            />
                        </Section.Footer>
                    </>
                ) : null}
            </CardSection>
        </Flex.ContainerV>
    );
};

const mapStateToProps = (state: IAppState, { project }: IOwnProps) => ({
    primaryFinConfig: projSelectors.primaryProjectFinancialTemplate(state, {
        project,
    }),
});

const mapDispatchToProps = bindActions(({ project }) => ({
    createFinancialConfig: (template) => actions.createConfiguration(template, project),
    setFinancialConfig: (config) => projActions.setPrimaryProjectFinancialTemplate(project, config),
    navigateTo: routerActions.navigateTo,
}));

export default connect(mapStateToProps, mapDispatchToProps)(FinancialInputs);
