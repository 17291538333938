import { MessageDescriptor } from 'react-intl';
import Translations from 'reports/localization/strings';

interface IObjectFitEntries {
    [k: string]: {
        name: MessageDescriptor;
        helperTranslation: MessageDescriptor;
    };
}

export const OBJECT_FIT_OPTIONS: IObjectFitEntries = {
    fill: {
        name: Translations.image.image_fit_fill,
        helperTranslation: Translations.image.fill_helper,
    },
    cover: {
        name: Translations.image.image_fit_cover,
        helperTranslation: Translations.image.cover_helper,
    },
    contain: {
        name: Translations.image.image_fit_contain,
        helperTranslation: Translations.image.contain_helper,
    },
    none: {
        name: Translations.image.image_fit_none,
        helperTranslation: Translations.image.none_helper,
    },
};

export type ObjectFitType = keyof typeof OBJECT_FIT_OPTIONS;

interface IObjectPositionEntries {
    [k: string]: {
        name: MessageDescriptor;
    };
}

export const HORIZ_POSITION_OPTIONS: IObjectPositionEntries = {
    left: {
        name: Translations.general.position_left,
    },
    center: {
        name: Translations.general.position_center,
    },
    right: {
        name: Translations.general.position_right,
    },
};

export const VERT_POSITION_OPTIONS: IObjectPositionEntries = {
    top: {
        name: Translations.general.position_top,
    },
    center: {
        name: Translations.general.position_center,
    },
    bottom: {
        name: Translations.general.position_bottom,
    },
};

export type HorizPositionType = keyof typeof HORIZ_POSITION_OPTIONS;
export type VertPositionType = keyof typeof VERT_POSITION_OPTIONS;
