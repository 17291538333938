/* tslint:disable:no-increment-decrement */
import { ParamValueType } from 'reports/modules/financials/params';
import { OutputCategory } from 'reports/modules/financials/model/debug';
import { IPipelineModule } from 'reports/modules/financials/model/modules/types';

export const BasicTaxDeductInterestSimple: IPipelineModule = {
    description: 'Interest Deduction Savings',
    parameters: [
        {
            description: 'Federal Tax Rate',
            path: 'federal_rate',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.0,
        },
        {
            description: 'State Tax Rate',
            path: 'state_rate',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { federal_rate, state_rate } = params;
            const { interestYearly } = state;

            if (!interestYearly) return;

            const rate = federal_rate + (1 - federal_rate) * state_rate;

            const adj = interestYearly.map((i) => i * rate);
            state.taxYearly.addSeries(adj);
        },
        debugOutputs: [{ table: OutputCategory.Taxes }],
    },
};

export const BasicTaxDepreciationSimple: IPipelineModule = {
    description: 'Depreciation',
    parameters: [
        {
            description: 'Effective Tax Rate',
            path: 'tax_rate',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.0,
        },
        {
            description: 'Bonus Depreciation',
            path: 'depreciation_bonus',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.0,
        },
        {
            description: 'Depreciation Basis',
            path: 'depreciation_basis',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.0,
        },
        {
            description: 'Depreciation Schedule',
            path: 'schedule',
            type: ParamValueType.Table,
            max_rows: 99,
            index_label: 'Year',
            sort_path: null,
            default: [],
            columns: [
                {
                    description: 'Annual Depreciation',
                    path: 'depreciation',
                    type: ParamValueType.Percentage,
                    min_value: 0.0,
                    max_value: 1.0,
                    default: 0.0,
                },
            ],
        },
    ],
    module: {
        main: function main(state, params) {
            const { tax_rate, depreciation_bonus, depreciation_basis, schedule } = params;
            const { taxYearly, systemInitial } = state;

            const eff = schedule.length ? schedule : [{ depreciation: 0.0 }];

            const adj = taxYearly.map(() => 0.0);
            for (let i = 0; i < eff.length; ++i) {
                const deduction =
                    -systemInitial *
                    tax_rate *
                    (i === 0
                        ? eff[i].depreciation * depreciation_basis + depreciation_bonus
                        : eff[i].depreciation * depreciation_basis);
                adj.setRawSafe(i, deduction);
            }

            taxYearly.addSeries(adj);
        },
        debugOutputs: [{ table: OutputCategory.Taxes }],
    },
};
