import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import styled from 'styled-components';

import { Classes, Dialog, Divider, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Form, handleRequestException } from 'reports/components/forms';
import { FormTextInput, FormTextAreaInput, FormSwitchInput } from 'reports/components/forms/inputs/experimental';
import { Flex, Section2 as Section } from 'reports/components/core/containers';
import { Button as SecondaryButton, CreateButton } from 'reports/components/core/controls';

import * as pd from 'reports/models/power_device';
import * as pdChar from 'reports/models/power_device/PowerDeviceCharacterization';
import * as s3file from 'reports/models/s3file';

import * as auth from 'reports/modules/auth';
import { canMakeResourcePublic } from 'reports/modules/auth/permissions';
import AppToaster from 'reports/modules/Toaster';
import EfficiencyTable from 'reports/modules/power_device/components/EfficiencyTable';
import ModeledPerformanceChart from 'reports/modules/power_device/components/ModeledPerformanceChart';
import SpecSheet from 'reports/modules/power_device/components/SpecSheet';

const RightPanel = styled(Flex.ContainerV)`
    flex: 2;
    margin-left: 20px;
`;

const LeftPanel = styled(Flex.ContainerV)`
    flex: 1.4;
`;

type Props = {
    navigateToCharacterization: (powerDeviceCharacterization: pd.PowerDeviceCharacterization) => void;
    uploadedCharacterization: pd.PowerDeviceCharacterization;
    uploadedS3File: s3file.S3File;
};

const DIALOG_WIDTH = 720;
const FORM_LABEL_WIDTH = 80;

const PowerDeviceUploadDialog = React.memo(
    ({ navigateToCharacterization, uploadedCharacterization, uploadedS3File }: Props) => {
        const user = useSelector((state) => auth.selectors.getUser(state)!);
        const dispatch = useDispatch();
        const createCharacterization = ({ name, description, file_id, public: publicChar }) =>
            dispatch(pdChar.api.create({ name, description, file_id, public: publicChar }));

        const [isFileDialogOpen, setIsFileDialogOpen] = React.useState<boolean>(true);

        React.useEffect(() => setIsFileDialogOpen(true), [uploadedCharacterization]);

        const onSave = async ({ name, description, public: isPublic }) => {
            const toast = AppToaster.show({ message: `Uploading ${name}` });

            let characterization;
            try {
                characterization = await createCharacterization({
                    name,
                    description,
                    file_id: uploadedS3File.file_id,
                    public: isPublic,
                });
            } catch (err) {
                AppToaster.dismiss(toast);
                throw err;
            }

            AppToaster.show(
                {
                    message: `Successfully uploaded ${name}`,
                    intent: Intent.SUCCESS,
                },
                toast,
            );

            onClose();
            navigateToCharacterization(characterization);
        };

        const onClose = () => setIsFileDialogOpen(false);
        const uploadedPowerDevice = uploadedCharacterization.power_device;
        return (
            <Dialog
                title="Inverter Upload"
                isOpen={isFileDialogOpen}
                isCloseButtonShown={true}
                onClose={onClose}
                style={{ width: DIALOG_WIDTH, overflow: 'hidden' }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <div style={{ padding: 10 }}>
                        <Form
                            baseValue={{ name: uploadedPowerDevice.name }}
                            onSubmit={onSave}
                            exceptionHandler={handleRequestException}
                        >
                            {({ submitForm }) => (
                                <>
                                    <FormTextInput
                                        inline
                                        fill
                                        bold
                                        autoFocus
                                        path="name"
                                        label="Name"
                                        type="text"
                                        labelWidth={FORM_LABEL_WIDTH}
                                    />
                                    <FormTextAreaInput
                                        inline
                                        fill
                                        bold
                                        path="description"
                                        label="Description"
                                        placeholder="Inverter Description"
                                        labelWidth={FORM_LABEL_WIDTH}
                                    />
                                    {canMakeResourcePublic(user, uploadedPowerDevice) && (
                                        <FormSwitchInput
                                            inline
                                            fill
                                            bold
                                            path="public"
                                            label="Public"
                                            labelWidth={FORM_LABEL_WIDTH}
                                            helperText="Make inverter available to all users."
                                        />
                                    )}
                                    <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                                        <SecondaryButton text="Cancel" onClick={onClose} />
                                        <CreateButton text="Upload" icon={IconNames.UPLOAD} onClick={submitForm} />
                                    </div>
                                </>
                            )}
                        </Form>

                        <Divider style={{ margin: '10 0' }} />

                        <Flex.Container>
                            <LeftPanel>
                                <Section title="Spec Sheet">
                                    <SpecSheet device={uploadedPowerDevice} />
                                </Section>
                            </LeftPanel>
                            <RightPanel>
                                <Section title="Modeled Performance">
                                    <ModeledPerformanceChart characterization={uploadedCharacterization} />
                                </Section>
                                <Section title="Efficiency Table">
                                    <EfficiencyTable characterization={uploadedCharacterization} />
                                </Section>
                            </RightPanel>
                        </Flex.Container>
                    </div>
                </div>
            </Dialog>
        );
    },
);

export { PowerDeviceUploadDialog };
