import * as React from 'react';
import { chain, sumBy } from 'lodash';
import { connect } from 'react-redux';

import { bindActions } from 'reports/utils/redux';
import { hslToRgbHex } from 'reports/utils/color';

import * as sim from 'reports/models/simulation';
import { actions as projActions } from 'reports/modules/project';

import { IDesignHeatMapContext, IHeatMapSeries } from './common';

const DEFAULT_CONTEXT = { loading: true, ready: false };
export const DesignHeatMapContext = React.createContext<IDesignHeatMapContext>(DEFAULT_CONTEXT);

const THRESHOLD_VALUE = 0.7;
const DEFAULT_LUMINANCE = 0.5;
const GREEN_HUE_BOUNDARY = 120;
const MAX_HUE = 360;

const normalizedToColor = (normalizedValue) => {
    // normalize to threshold -- anything below the threshold will appear as red
    const thresholdedValue = (Math.max(normalizedValue, THRESHOLD_VALUE) - THRESHOLD_VALUE) / (1 - THRESHOLD_VALUE);
    const hue = (thresholdedValue * GREEN_HUE_BOUNDARY) / MAX_HUE;

    return hslToRgbHex(hue, 1, DEFAULT_LUMINANCE);
};

const computeModuleShading = (
    moduleResults: sim.IModuleLevelResults,
    rawValueCalc: (monthlyValues: { [month: number]: sim.ISimResultsSummary }) => number,
    normValueCalc: (monthlyValues: { [month: number]: sim.ISimResultsSummary }, rawValue: number) => number,
): Pick<IHeatMapSeries, 'colors'> => {
    const colors = chain(moduleResults)
        .toPairs()
        .map(([moduleId, monthlyValues]) => {
            const rawValue = rawValueCalc(monthlyValues);
            const normalizedValue = normValueCalc(monthlyValues, rawValue);
            const color = normalizedToColor(normalizedValue);

            return [moduleId, color];
        })
        .fromPairs()
        .value();

    return { colors };
};

const computeTsrfRanges = (optimalPoaIrr: number) => {
    return {
        rawRange: {
            min: THRESHOLD_VALUE * optimalPoaIrr,
            max: optimalPoaIrr,
        },
        normalizedRange: {
            min: THRESHOLD_VALUE,
            max: 1.0,
        },
    };
};

const computeSolarAccessRanges = () => {
    return {
        normalizedRange: {
            min: THRESHOLD_VALUE,
            max: 1.0,
        },
    };
};

const computeAnnualSum = (monthlyValues, accessor) =>
    sumBy(Object.values(monthlyValues), (monthData: sim.ISimResultsSummary) => accessor(monthData));

const computeShadedIrradiance = (monthlyValues) =>
    computeAnnualSum(monthlyValues, (monthData) => monthData.shaded_irradiance);
const computePoaIrradiance = (monthlyValues) =>
    computeAnnualSum(monthlyValues, (monthData) => monthData.poa_irradiance);

const _DesignHeatMapProvider = ({
    project,
    weatherDatasetId,
    moduleCharacterizationId,
    simulation,
    getModuleLevelResults,
    loadOptimalPoaData,
    disabled,
    children,
}) => {
    const [heatMap, setHeatMap] = React.useState<IDesignHeatMapContext>(DEFAULT_CONTEXT);

    const error = (msg) =>
        setHeatMap({
            error: msg,
            loading: false,
            ready: false,
            data: undefined,
        });

    const downloadResults = async () => {
        let results;
        let optimalPoaIrr;

        setHeatMap({
            ...heatMap,
            loading: true,
            error: undefined,
            ready: false,
        });

        try {
            results = await getModuleLevelResults(simulation);

            optimalPoaIrr = (await loadOptimalPoaData(project, weatherDatasetId, moduleCharacterizationId))
                .poa_irradiance;
        } catch (err) {
            error('Error loading heat map data.');

            return;
        }

        const tsrfSeries = {
            ...computeModuleShading(results, computeShadedIrradiance, (_, rawValue) => rawValue / optimalPoaIrr),
            ...computeTsrfRanges(optimalPoaIrr),
        };
        const solarAccessSeries = {
            ...computeModuleShading(
                results,
                computeShadedIrradiance,
                (monthlyValues, rawValue) => rawValue / computePoaIrradiance(monthlyValues),
            ),
            ...computeSolarAccessRanges(),
        };

        setHeatMap({
            data: { tsrfSeries, solarAccessSeries },
            ready: true,
            loading: false,
            error: undefined,
        });
    };

    React.useEffect(() => {
        if (!disabled && weatherDatasetId && moduleCharacterizationId) {
            if (simulation?.simulation_id && simulation?.status === 'completed') {
                downloadResults();
            } else {
                error('Simulation data not available. The simulation can be run from the project page.');
            }
        }
    }, [
        simulation?.simulation_id,
        simulation?.status,
        project.project_id,
        weatherDatasetId,
        moduleCharacterizationId,
        disabled,
    ]);

    return <DesignHeatMapContext.Provider value={heatMap}>{children}</DesignHeatMapContext.Provider>;
};

const mapDispatch = bindActions(() => ({
    getModuleLevelResults: (simulation) => projActions.loadModuleResults(simulation.simulation_id),
    loadOptimalPoaData: (project, weatherDatasetId, moduleCharacterizationId) =>
        projActions.loadOptimalPoaData(project, weatherDatasetId, moduleCharacterizationId),
}));

export const DesignHeatMapProvider = connect(null, mapDispatch)(_DesignHeatMapProvider);
