import * as React from 'react';
import { DragLayer, DragLayerMonitor } from 'react-dnd';

import { Colors } from '@blueprintjs/core';
import { IDragItem, Component, ILayout, IPoint, HandlePosition } from './types';
import { getResizedLayout, FullDiv } from './ResizeHandles';
import { IMatchResult } from './SnapIndex';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const DragContainer = styled.div`
    position: fixed;
    pointer-events: none;
    z-index: 999999;
    left: 0;
    top: 0;
    width: '100%';
    height: '100%';

    * {
        pointer-events: none !important;
    }
`;

/**
 * returns the drag offset in layout coordinates
 * @param monitor a react-dnd monitor
 */
export function getLayoutDeltaFromMonitor(monitor): IPoint {
    const item = monitor.getItem() as IDragItem;
    let delta: IPoint;

    const rawOffset = monitor.getDifferenceFromInitialOffset();
    if (rawOffset) {
        const { x, y } = rawOffset;
        const { scale } = item;
        delta = { x: x / scale, y: y / scale };
    } else {
        delta = { x: 0, y: 0 };
    }

    return delta;
}

const mapMonitorToProps = (monitor: DragLayerMonitor) => ({
    item: monitor.getItem() as IDragItem,
    isDragging: monitor.isDragging(),
    delta: getLayoutDeltaFromMonitor(monitor),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
});

interface IProps extends ReturnType<typeof mapMonitorToProps> {
    item: IDragItem;
}

const Line = styled.span`
    position: fixed;
    border-left: 2px dashed ${Colors.GOLD5};
    border-top: 2px dashed ${Colors.GOLD5};
    margin-left: -1px;
    margin-top: -1px;
`;

const EXTREMES = {
    maxX: Number.POSITIVE_INFINITY,
    maxY: Number.POSITIVE_INFINITY,

    minX: Number.NEGATIVE_INFINITY,
    minY: Number.NEGATIVE_INFINITY,
};

function getLines(newLayout: ILayout, matchResult: IMatchResult<any>, bounds = EXTREMES) {
    const { matchXLines, matchYLines } = matchResult;

    return [
        ...matchXLines.map((xLine, i) => {
            const yCoord = Math.max(bounds.minY, Math.min(xLine.layout.y, newLayout.y));
            const height =
                Math.min(bounds.maxY, Math.max(xLine.layout.y + xLine.layout.h, newLayout.y + newLayout.h)) - yCoord;
            return (
                <Line
                    key={'x' + i}
                    style={{
                        height,
                        transform: `translate(${xLine.x}px, ${yCoord}px) `,
                        transformOrigin: 'top left',
                    }}
                />
            );
        }),
        ...matchYLines.map((yLine, i) => {
            const xCoord = Math.max(bounds.minX, Math.min(yLine.layout.x, newLayout.x));
            const width =
                Math.min(bounds.maxX, Math.max(yLine.layout.x + yLine.layout.w, newLayout.x + newLayout.w)) - xCoord;

            return (
                <Line
                    key={'y' + i}
                    style={{
                        width,
                        transform: `translate(${xCoord}px, ${yLine.y}px) `,
                        transformOrigin: 'top left',
                    }}
                />
            );
        }),
    ];
}

class CustomDragLayer extends React.PureComponent<IProps> {
    state: { viewport?: ClientRect } = {};

    getViewportBounds() {
        const viewport = this.state.viewport;

        if (!viewport) return undefined;

        const viewXY = this.props.initialOffset;
        const layout = this.props.item.layout;
        const scale = this.props.item.scale;

        const minX = layout.x - (viewXY.x - viewport.left) / scale;
        const minY = layout.y - (viewXY.y - viewport.top) / scale;

        return {
            minX,
            minY,
            maxX: minX + viewport.width / scale,
            maxY: minY + viewport.height / scale,
        };
    }

    getOverlay(itemType = this.props.itemType): JSX.Element[] | null {
        const { item, delta } = this.props;
        const { layout } = item;

        const bounds = this.getViewportBounds();

        if (itemType === Component) {
            const matchResult = item.snapIndex.matchMove(delta, layout);
            const newLayout = matchResult.result;

            const style: React.CSSProperties = {
                position: 'fixed',
                width: newLayout.w,
                height: newLayout.h,
                transform: `translate(${newLayout.x}px, ${newLayout.y}px)`,
                transformOrigin: 'top left',
                opacity: 0.7,
            };

            return [
                <div key="preview" style={style} dangerouslySetInnerHTML={{ __html: item.previewHTML || '' }} />,
                ...getLines(newLayout, matchResult, bounds),
            ];
        }

        const { layout: newLayout, matchResult } = getResizedLayout(itemType as HandlePosition, item, delta);

        const style: React.CSSProperties = {
            position: 'fixed',
            width: newLayout.w,
            height: newLayout.h,
            transform: `translate(${newLayout.x}px, ${newLayout.y}px)`,
            transformOrigin: 'top left',
            border: `2px solid ${Colors.GOLD3}`,
            background: Colors.GOLD5,
            opacity: 0.4,
        };

        return [<div key="preview" style={style} />, ...getLines(newLayout, matchResult, bounds)];
    }

    updatePosition = (ref: HTMLDivElement) => {
        this.setState({
            viewport: ref ? ref.getBoundingClientRect() : null,
        });
    };

    render() {
        const { isDragging, currentOffset, item, initialOffset } = this.props;

        if (!isDragging || currentOffset == null) {
            return null;
        }

        const translation = {
            x: initialOffset.x - item.layout.x * item.scale,
            y: initialOffset.y - item.layout.y * item.scale,
        };

        const containerStyle = {
            transform: `translate(${translation.x}px, ${translation.y}px) scale(${item.scale})`,
            transformOrigin: 'top left',
        };

        return (
            <FullDiv ref={this.updatePosition as any}>
                <DragContainer style={containerStyle}>{this.getOverlay()}</DragContainer>
            </FullDiv>
        );
    }
}

export default DragLayer(mapMonitorToProps)(CustomDragLayer);
