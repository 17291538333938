import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as pd from 'reports/models/power_device';
import CharacterizationViewControls from 'reports/components/library/helpers/CharacterizationViewControls';

type Props = {
    device: pd.PowerDevice;
    selectedChar: pd.PowerDeviceCharacterization;
    navigateToCharacterization: ({
        power_device_id,
        power_device_characterization_id,
    }: pd.PowerDeviceCharacterization) => void;
};

const DeviceCharViewControls = ({ device, navigateToCharacterization, selectedChar }: Props) => {
    const characterizations = useSelector((state) => pd.selectors.allCharacterizations(state, device));
    const dispatch = useDispatch();
    const updatePowerDevice = (args) => dispatch(pd.api.save(args));
    return (
        <CharacterizationViewControls
            characterizations={characterizations}
            selectedChar={selectedChar}
            setSelectedChar={navigateToCharacterization}
            isDefaultChar={(char) => char.power_device_characterization_id === device.user_default_characterization_id}
            makeDefaultChar={({ power_device_id, power_device_characterization_id }) =>
                updatePowerDevice({
                    power_device_id,
                    user_default_characterization_id: power_device_characterization_id,
                })
            }
            charId={(char) => char.power_device_characterization_id}
        />
    );
};

export { DeviceCharViewControls };
