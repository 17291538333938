import * as React from 'react';

import { getChat } from 'reports/modules/chat/chat-wrapper';

import * as errorReports from 'reports/error-reports';

import ErrorPage from 'reports/components/ErrorPage';

interface IProps {
    errorState?: React.ReactNode;
}

interface IErrorBoundaryState {
    hasError: boolean;
    errorInfo?: any;
    errorId?: any;
}

class ErrorBoundary extends React.Component<IProps, IErrorBoundaryState> {
    state = {
        hasError: false,
        errorId: null,
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const errorId: any = errorReports.captureException(error, errorReports.LEVELS.error, 'app', errorInfo);
        this.setState({ errorId, errorInfo, hasError: true });
        getChat().updateVisitorStatus(`Last Error: ${errorReports.getEventLink(errorId)}`);
    }

    render() {
        const { errorState } = this.props;

        const message = this.state.errorId != null ? `Error ID: ${this.state.errorId}` : null;
        if (this.state.hasError) {
            return errorState ? errorState : <ErrorPage status={500} message={message} />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
