import _ from 'lodash';

import { fallback } from 'reports/utils/helpers';

import { ITier, makeAbsTiers } from 'reports/modules/financials/params';

/**
 * Distribute value by tier and aggregate tier sums
 *
 * Tier boundaries are defined by either absolute cap (abs_cap) or cap relative to previous tier (tier_cap).
 * Multiplies each tier_value by the portion of the 'val' that falls within that tier.
 * Sums up the tiers.
 */
export function marginalMultiplier(val: number, tiers: ITier[]) {
    let prevAbsCap = 0.0;
    // Distribute value by tiers
    const tierAmounts: number[] = [];
    for (const tier of makeAbsTiers(tiers)) {
        const tierAbsCap = fallback(tier.abs_cap, val);
        tierAmounts.push(Math.min(tierAbsCap, val) - prevAbsCap);
        if (tierAbsCap >= val) {
            break;
        }
        prevAbsCap = tierAbsCap;
    }
    // Perform dot-product of tierAmounts with tier_value's.
    return _.sum(tierAmounts.map((amt, i) => amt * tiers[i].tier_value));
}

/**
 * At each tier, multiply the remaining portion of 'val' (system cost) against the tier_value (percentage).
 * Cap each tier's scaled system cost by tier_cap.
 * Note that if there are multiple tiers, portions of the system cost contribute multiple times to the sum.
 *
 * Example: $10,000 system cost (val)
 * % of System Cost (tier_value)   Tier Limit (tier_cap)    Incentive Value for Tier ($)
 30 %                            $2,000                   $2,000 (smaller of: $2,000 or 30% of $10,000)
 5 %                             $500                     $400 (smaller of: $500 or 5% of $8,000)
 * After the first tier is applied, the remaining system cost is $10k - $2k = $8k, which is why the 2nd tier computes
 * 5% of 8k.
 */
export function systemCostMultiplier(val: number, tiers: ITier[]) {
    let remaining = val;
    let sum = 0.0;
    for (const tier of tiers) {
        const tierCap = fallback(tier.tier_cap, remaining);
        sum += Math.min(remaining * tier.tier_value, tierCap);
        remaining -= tierCap;
        if (remaining < 0) {
            break;
        }
    }
    return sum;
}

// Multiply entire value by maximum applicable tier rate. Rounds up to the next tier.
export function maximalMultiplier(val: number, tiers: ITier[]) {
    const maximumTier = makeAbsTiers(tiers).find((tier) => tier.abs_cap == null || tier.abs_cap > val);
    if (maximumTier == null) {
        return 0.0;
    }

    return maximumTier.tier_value * val;
}
