/* tslint:disable:variable-name */

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from './schema';

class HorizonProfile extends BaseClass {
    project_id: number;
    horizon_id: number;
    description: string;
}

const schemaObj = schema.addObject(HorizonProfile, 'horizon_profile', {
    idName: 'horizon_id',
});

const endpoint = ReduxEndpoint.fromSchema('/api/horizons/', schemaObj);

const api = {
    index: endpoint.index<{ project_id: number }>(),
};

const selectors = {
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

export { HorizonProfile, api, selectors };
