import * as React from 'react';
import { connect } from 'react-redux';

import { get } from 'lodash';
import Image from 'reports/components/helpers/Image';

import { Project } from 'reports/models/project';

import * as config from 'reports/config';

const DEFAULT_SIZE = { w: 480, h: 480 };

const STATIC_MAPS_URL = 'https://maps.google.com/maps/api/staticmap';

interface IOwnProps {
    project: Project;
    size?: { w: number; h: number };
}

type IStateProps = ReturnType<typeof mapStateToProps>;

const ProjectLocationMap: React.SFC<IOwnProps & IStateProps> = ({ project, appConfig, size = DEFAULT_SIZE }) => {
    const llStr = `${project.location.latitude},${project.location.longitude}`;
    const sizeStr = `${size.w}x${size.h}`;

    const url =
        `${STATIC_MAPS_URL}?center=${llStr}&markers=${llStr}&zoom=17&size=${sizeStr}&maptype=hybrid` +
        `&key=${get(appConfig, 'google_maps_api_key')}`;

    return <Image src={url} />;
};

const mapStateToProps = (state) => ({
    appConfig: config.selectors.getConfig(state),
});

export default connect(mapStateToProps)(ProjectLocationMap);
