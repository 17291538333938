import * as React from 'react';
import * as Q from 'q';
import _ from 'lodash';
import Logger from 'js-logger';
import { RendererOptions } from 'helioscope/app/apollo/RendererOptions';
import { toDegrees, toRadians } from 'helioscope/app/utilities/geometry';
import { PlaceholderContainer } from 'reports/components/helpers/Image';
import HelioScope from 'reports/modules/ogdesigner/components/HelioScope';
import { IFrameDriver } from 'reports/modules/ogdesigner/IFrameDriver';
import {
    DEFAULT_SETTINGS,
    DEFAULT_CAMERA_SETTINGS,
} from 'reports/modules/report/components/widgets/design_snapshot/DesignSnapshotWidget';
import { IInteractiveDesign3dViewProps, IDesignerCamera, IRenderCamera } from './InteractiveDesign3dView';

const logger = Logger.get('IframeDesignerView');

const DESIGNER_RENDERING_TIMEOUT = 30000;
export class IframeDesignerView extends React.Component<IInteractiveDesign3dViewProps> {
    driver: IFrameDriver;
    dispatcherDeferred = Q.defer<any>();
    designerAcquireDeferred = Q.defer<any>();
    populateSettingsTimeoutHandles: ReturnType<typeof setTimeout>[] = [];
    unsubscribe: () => {};
    renderer: any;

    dispatcherReady() {
        return this.dispatcherDeferred.promise;
    }

    designerReady() {
        return this.designerAcquireDeferred.promise;
    }

    async getSnapshot() {
        const dispatcher = await this.dispatcherReady();
        await this.designerReady();
        await this.populateDesignSettings();
        const imageObj = await dispatcher.renderer.capture();
        return {
            file: new File([imageObj.blob], `snapshot.${Date.now()}.png`, { type: 'image/png' }),
            width: imageObj.width,
            height: imageObj.height,
        };
    }

    async populateDesignSettings(shouldUpdateModuleColors: boolean = true) {
        const populateSettingsTimeoutHandle = setTimeout(
            () =>
                logger.error('Designer did not complete rendering before timeout', {
                    timeout: DESIGNER_RENDERING_TIMEOUT,
                    designId: this.props.designId,
                }),
            DESIGNER_RENDERING_TIMEOUT,
        );
        this.populateSettingsTimeoutHandles.push(populateSettingsTimeoutHandle);

        const dispatcher = await this.dispatcherReady();
        await dispatcher.onRendererReady();
        const { settings, cameraSettings, moduleColor } = this.props.renderSettings;
        // Upon exiting, renderer is reset to an instance of BufferRenderer
        // however, `renderer instanceof BufferRenderer` always returns false. Weird Javascript type checking.
        // hack to ensure doesn't error out
        if (this.renderer) {
            this.renderer.updateForScreenshot(dispatcher.design, { ...DEFAULT_SETTINGS, ...settings });
            this.renderer.activateInteractTool({ tool: 'DesignRender:Zoom' });

            const updatedCameraSettings = { ...DEFAULT_CAMERA_SETTINGS, ...cameraSettings };
            await this.renderer.setCameraState(this.getDesignerCameraSettings(updatedCameraSettings));

            if (shouldUpdateModuleColors) {
                this.renderer.setModuleColors(moduleColor);
            }

            await this.renderer.renderingCompleted();
            clearTimeout(populateSettingsTimeoutHandle);
            return true;
        }
    }

    async subscribeCameraChange() {
        const dispatcher = await this.dispatcherReady();
        this.unsubscribe = dispatcher.subscribe('cameraChange', (_dispatcher, camera) => {
            if (this.props.onChange) {
                this.props.onChange({
                    cameraSettings: this.getRenderCameraSettings(camera),
                    settings: this.props.renderSettings.settings,
                });
            }
        });
    }

    getDispatcherFromDriver(driver: IFrameDriver) {
        return driver.angular.$state.$current.locals.resolve.$$promises.dispatcher;
    }

    getRenderCameraSettings(camera: IDesignerCamera): IRenderCamera {
        return {
            center: camera.cameraCenter,
            elevation: toDegrees(camera.cameraTheta) + 90,
            azimuth: 180 - toDegrees(camera.cameraPhi),
            zoom: this.getRendererZoom() / camera.viewportScale,
        };
    }

    getDesignerCameraSettings(camera: IRenderCamera): IDesignerCamera {
        return {
            cameraCenter: camera.center,
            cameraTheta: toRadians(camera.elevation - 90),
            cameraPhi: toRadians(180 - camera.azimuth),
            viewportScale: this.getRendererZoom() / camera.zoom,
        };
    }

    getRendererZoom() {
        const bounds = this.renderer.getProjectBounds();
        const xScale = bounds.width / this.renderer.container.clientWidth;
        const yScale = bounds.height / this.renderer.container.clientHeight;
        return _.clamp(
            Math.max(xScale, yScale),
            RendererOptions.viewOptions.minZoom,
            RendererOptions.viewOptions.maxZoom,
        );
    }

    componentDidUpdate(prevProps) {
        if (this.driver) {
            const shouldUpdateModuleColors =
                prevProps.renderSettings.moduleColor !== this.props.renderSettings.moduleColor;
            this.populateDesignSettings(shouldUpdateModuleColors);
        }
    }

    componentWillUnmount() {
        if (this.unsubscribe) this.unsubscribe();
        this.populateSettingsTimeoutHandles.forEach((handle) => clearTimeout(handle));
    }

    render() {
        const { designId } = this.props;
        return (
            <HelioScope
                startUrl={`/designer/renders/${designId}`}
                onAcquire={async (driver) => {
                    this.driver = driver;
                    const dispatcher = await this.getDispatcherFromDriver(this.driver);
                    await dispatcher.onRendererReady();
                    this.renderer = dispatcher.renderer;
                    this.dispatcherDeferred.resolve(dispatcher);

                    this.subscribeCameraChange();

                    await this.populateDesignSettings();
                    this.designerAcquireDeferred.resolve();
                }}
            >
                <PlaceholderContainer title="Loading Designer" />
            </HelioScope>
        );
    }
}
