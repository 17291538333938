import { styled } from 'reports/styles/styled-components';
import { Text } from '@blueprintjs/core';
import * as React from 'react';

const ssoIcon = require('reports/static/sso-key-icon.svg');

const SSOEnabledWarningContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    background-color: #f8f8f8;
    border-color: #f8f8f8;
    border-style: solid;
    border-left-color: #dbdcdd;
    border-left-width: 2px;
    margin-bottom: 15px;
`;

const SSOEnabledWarningTitle = styled(Text)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 4px;
    color: #010916;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
`;

const SSOEnabledWarningText = styled(Text)`
    color: #343a45;
    font-size: 14px;
    line-height: 20px;
`;

const SSOEnabledWarning = (): React.JSX.Element => (
    <SSOEnabledWarningContainer>
        <SSOEnabledWarningTitle>
            <img src={ssoIcon} alt="SSO Icon" />
            <div>SSO is configured</div>
        </SSOEnabledWarningTitle>
        <SSOEnabledWarningText>
            All members need to be managed by your single sign-on (SSO) provider. Your SSO provider will have the most
            accurate seat assignment.
        </SSOEnabledWarningText>
    </SSOEnabledWarningContainer>
);

export default SSOEnabledWarning;
