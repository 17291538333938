import * as React from 'react';

import { ItemPredicate, ItemRenderer } from '@blueprintjs/select';

import { commonCurrencies, ICurrency } from 'reports/localization';
import { isSubstr } from 'reports/utils/strings';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';

import { SettingsSelect } from '../types';

const CurrencySelect = ({ disabled, onItemSelect, selectedItem }: SettingsSelect<ICurrency>) => {
    const filterCurrency: ItemPredicate<ICurrency> = (query: string, currency: ICurrency) =>
        isSubstr(`${currency.name} (${currency.code}) ${currency.symbol_native}`, query);

    const renderCurrencyItem: ItemRenderer<ICurrency> = (currency, { handleClick, modifiers }) => (
        <DropdownMenuItem
            key={`curr-symbol-${currency.code}`}
            title={`${currency.name} (${currency.code})`}
            label={currency.symbol_native}
            onClick={handleClick}
            selected={selectedItem.code === currency.code}
            {...modifiers}
        />
    );

    return (
        <StaticSelect<ICurrency>
            items={commonCurrencies}
            onItemSelect={onItemSelect}
            itemRenderer={renderCurrencyItem}
            itemPredicate={filterCurrency}
            buttonText={`${selectedItem.name} (${selectedItem.symbol_native})`}
            buttonProps={{ disabled, fill: true }}
            filterable={true}
            disabled={disabled}
        />
    );
};

export default CurrencySelect;
