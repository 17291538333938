import Logger from 'js-logger';
import rbush from 'rbush';
import { Vector } from './vector';

const logger = Logger.get('racking/tiling');


/**
 * find all the rectangles in an arbitrary shape that are defined in the y-axis by a specific bound
 * @param  paths  an array of x/y paths
 * @param  bounds an array of bounds with a top and bottom value.
 * @return an array of all valid rectangles within a shape
 */
export function findRectangles(paths, allBounds) {
    const rectangles = [];

    const tree = getPathSegmentTree(paths);

    const allScanPoints = generateScanPoints(paths, allBounds);

    for (const [scanBounds, scanPoints] of _.zip(allBounds, allScanPoints)) {
        const validSegments = scanPoints.map(y => getValidSegments(tree, y));

        try {
            rectangles.push(...parseRectangles(validSegments, scanBounds));
        } catch (exception) {
            logger.warn('Error creating rectangles from scanlines');
        }
    }

    return filterValidRectangles(paths, rectangles);
}


/**
 * determine all rectangles fully included in the scanlines
 *
 * each scanline has an array of line segments (start and end points) that are inside the shape all
 * at constant y
 *
 * returns an array of potential racking structures for each row
 */
function parseRectangles(scanLineSegments, { top, bottom }) {
    // store an index for each scanline indicating which internal line segment is being used to
    // determine valid space
    const segmentIndices = scanLineSegments.map(() => 0);
    const currentSegments = scanLineSegments.map(segments => segments[0]);

    // the index of the last scanline to restrict the end of a rectangle
    let lastEndIndex = 0;
    const rectangles = [];

    // loop until the scanline currently constraining valid internal rectangles has no more
    // line segments that are internal to the shape
    while (segmentIndices[lastEndIndex] < scanLineSegments[lastEndIndex].length) {
        const { startX, endX, endIndex } = findIntersectionArea(currentSegments);

        if (endX > startX) {
            rectangles.push({
                topLeft: new Vector(startX, top),
                bottomRight: new Vector(endX, bottom),
            });
        }

        // move constraining line to next area of valid geometry
        segmentIndices[endIndex] += 1;
        currentSegments[endIndex] = scanLineSegments[endIndex][segmentIndices[endIndex]];
        lastEndIndex = endIndex;
    }

    return rectangles;
}


/**
 * given an array of arrays of internal line segments, and an array of which line segment to use,
 * find the mutual overlap of all the line segments
 */
function findIntersectionArea(lineSegments) {
    let startX = Number.NEGATIVE_INFINITY;
    let startIndex;

    let endX = Number.POSITIVE_INFINITY;
    let endIndex;

    for (let i = 0; i < lineSegments.length; i += 1) {
        const segment = lineSegments[i];

        if (!segment) {
            // todo: dig into why the scanlines are return an empty line segment (ultimately driven
            // by the segments in perseRectangles), I'll bet it's because we're parsing a bound
            // outside the y-range of the valid area multipolygon
            return { endIndex: i };
        }

        const [segStart, segEnd] = segment;

        if (segStart > startX) {
            startX = segStart;
            startIndex = i;
        }

        if (segEnd < endX) {
            endX = segEnd;
            endIndex = i;
        }
    }

    return { startX, startIndex, endX, endIndex };
}


/**
 * points: all x/y points of the valid space for laying out modules
 * yRackVector: the projected distance in the y-axis the rack occupies
 * rowSpacing: the spacing between each subsequent rack
 *
 * returns an array of the necessary y-values to evaluate to determine
 * where modules can fit for each row of racking: the top and bottom y-value
 * for each row of racking, as well as all vertices between them
 */
function generateScanPoints(paths, allBounds) {
    const yValues = _(paths)
        .flatten()
        .map('y')
        .sortBy()
        .value();

    const rtn = [];

    for (const bounds of allBounds) {
        const { top, bottom } = bounds;
        const minIndex = _.sortedIndex(yValues, bottom);
        const maxIndex = _.sortedLastIndex(yValues, top);

        rtn.push([bottom, ...yValues.slice(minIndex, maxIndex), top]);
    }

    return rtn;
}

/**
 * given a path and a y-Value return all x-intersection with a horizontal line
 * Based On: http://alienryderflex.com/polygon_fill/
 *
 * Note: avoiding for..of loops roughly doubles total racking performance in complicated designs by
 * eliminating babel iterator code
 *
 * @return this will return an array of tuples, with each tuples containing the start and end of a
 * line segment within the paths
 */
export function getScanLine(tree, scanY) {
    const xIntersections = [];

    const results = tree.search({
        minX: 0, maxX: 0, minY: scanY, maxY: scanY,
    });

    for (let i = 0; i < results.length; i += 1) {
        const { point, lastPoint } = results[i];

        if ((point.y < scanY && lastPoint.y >= scanY) || (lastPoint.y < scanY && point.y >= scanY)) {
            xIntersections.push(
                point.x + (scanY - point.y) * (lastPoint.x - point.x) / (lastPoint.y - point.y)
            );
        } else if (point.y === scanY && lastPoint.y === scanY) {
            xIntersections.push(point.x, lastPoint.x);
        }
    }

    return _.sortBy(xIntersections);
}

export function getValidSegments(tree, scanY) {
    return _.chunk(getScanLine(tree, scanY), 2);
}


/**
 * run a vertical line test on all the valid rectangles to ensure that we were
 * not bitten by any concave shapes.  This runs a vertical line through each
 * shape, and checks if any intersections are within the bound of the finished
 * rectangle, if so, there was a concave effect making the polygon invalid.
 *
 *  A------------------------------B C--------------------------------D
 *  |                             / /                                 |
 *  |                            / /                                  |
 *  |                           / /                                   |
 *  |                          / /                                    |
 *  |                         / /                                     |
 *  |                        / /                                      |
 *  |                       / /                                       |
 *  |                      / /                                        |
 *  |                     / /                                         |
 *  E--------------------F G------------------------------------------H
 *
 * The scanline algorithm works by first find rectangles enclosed by
 * AB && EF;  then because F was the constraining endpoint, it tries to
 * find polygons enclosed by AB && GH; however because Bx > Gx with
 * additional analysis, the algoritm will return a rectangle that
 * intersects the empty space.  Adding a scanline through the middle of
 * this shape is the simplest way to eliminate this class of edge cases
 *
 */
function filterValidRectangles(paths, rectangles) {
    const rtn = [];

    const transposedTree = getPathSegmentTree(transpose(paths));

    for (const rectangle of rectangles) {
        const { topLeft, bottomRight } = rectangle;

        const verticalLineCheck = (topLeft.x + bottomRight.x) / 2;
        const scanline = getScanLine(transposedTree, verticalLineCheck);

        if (!_scanOverlaps(scanline, topLeft.y, bottomRight.y)) {
            rtn.push(rectangle);
        }
    }

    return rtn;
}


function _scanOverlaps(scanline, maxY, minY) {
    for (let i = 0; i < scanline.length; i++) {
        const intersection = scanline[i];
        if (intersection <= maxY && intersection >= minY) {
            return true;
        }
    }

    return false;
}


function transpose(arrOrVec) {
    if (Array.isArray(arrOrVec)) {
        return arrOrVec.map(pt => transpose(pt));
    }
    return new Vector(arrOrVec.y, arrOrVec.x);
}

/**
 * parse all the line segments into the shape into an rtree, which makes it easy to
 * search for line segments that may intersect a scanline, rather than having to
 * iterate through the entire shape.  Very low indexing cost, but large performance boost
 * in saving iterations through every path segment
 */
export function getPathSegmentTree(paths) {
    const tree = rbush();

    const items = [];

    for (const path of paths) {
        const length = path.length;
        let lastPoint = path[length - 1];

        for (let i = 0; i < path.length; i += 1) {
            const point = path[i];
            items.push(getTreeItem(point, lastPoint));

            lastPoint = point;
        }
    }

    tree.load(items);

    return tree;
}


function getTreeItem(point, lastPoint) {
    let maxY;
    let minY;

    if (point.y > lastPoint.y) {
        maxY = point.y;
        minY = lastPoint.y;
    } else {
        maxY = lastPoint.y;
        minY = point.y;
    }

    return { minX: -1, maxX: 1, maxY, minY, lastPoint, point };
}
