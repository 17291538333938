import * as React from 'react';

import { Unit } from 'reports/utils/units';
import { DistanceUnit, DurationUnit } from 'reports/utils/formatters';
import { FormattedNumber, INumber } from './FormattedNumber';

export const Currency = (props: INumber) => <FormattedNumber unit={Unit.Currency} {...props} />;

export const Date = (props: INumber) => <FormattedNumber unit={Unit.Date} {...props} />;

export const Energy = (props: INumber) => <FormattedNumber unit={Unit.Energy} {...props} />;

export const Percent = (props: INumber) => <FormattedNumber unit={Unit.Percent} {...props} />;

export const Power = (props: INumber) => <FormattedNumber unit={Unit.Power} {...props} />;
export const Voltage = (props: INumber) => <FormattedNumber unit={Unit.Voltage} {...props} />;
export const Current = (props: INumber) => <FormattedNumber unit={Unit.Current} {...props} />;
export const Resistance = (props: INumber) => <FormattedNumber unit={Unit.Resistance} {...props} />;
export const Irradiance = (props: INumber) => <FormattedNumber unit={Unit.Irradiance} {...props} />;

export const Angle = (props: INumber) => <FormattedNumber unit={Unit.Angle} {...props} />;
export const Temperature = (props: INumber) => <FormattedNumber unit={Unit.Temperature} {...props} />;

interface IDistanceProps extends Omit<INumber, 'value'> {
    meters: number; // input distance must be in meters
    unit?: DistanceUnit;
}
export const Distance = ({ meters, unit, ...otherProps }: IDistanceProps) => (
    <FormattedNumber value={meters} unit={Unit.Distance} subUnit={unit} {...otherProps} />
);

interface IDurationProps extends INumber {
    unit: DurationUnit;
}
export const Duration = ({ unit, ...otherProps }: IDurationProps) => (
    <FormattedNumber unit={Unit.Duration} subUnit={unit} {...otherProps} />
);

export const Integer = (props: INumber) => <FormattedNumber unit={Unit.Number} precision={0} {...props} />;
