import { Coords } from 'google-map-react';
import * as ws from 'reports/models/weather_source';
import { SatelliteSourceType } from './views/WeatherSourceMap';

function prospectorId(lat, lon) {
    // return a unique id for a prospector file based on latitude and
    // longitude
    const strLat = String(Math.round(-lon * 100));
    const strLatExtended = (strLat.length < 5 ? '0' : '') + strLat;
    const strLon = String(Math.round(lat * 100));
    return strLatExtended + strLon;
}

function prospectorRounder(num) {
    // round to increments of 0.1, ending in 0.05
    return Math.round((Math.trunc(num * 10.0) / 10.0 + (num > 0 ? 0.05 : -0.05)) * 100) / 100;
}

function prospectorAddress(lat, lng) {
    return {
        latitude: prospectorRounder(lat),
        longitude: prospectorRounder(lng),
    };
}

function psmAddress(lat, lng) {
    return {
        latitude:
            Math.round((lat - ws.WEATHER_CONSTS.PsmLatZero) / ws.WEATHER_CONSTS.PsmRes) * ws.WEATHER_CONSTS.PsmRes +
            ws.WEATHER_CONSTS.PsmLatZero,
        longitude:
            Math.round((lng - ws.WEATHER_CONSTS.PsmLonZero) / ws.WEATHER_CONSTS.PsmRes) * ws.WEATHER_CONSTS.PsmRes +
            ws.WEATHER_CONSTS.PsmLonZero,
    };
}

// we can calculate the coordinates of the nearest source without querying any backend resources
// since prospector and PSM data points are distributed along a predictable grid
export const getFakeSatelliteSource = (satelliteSourceToOutline: SatelliteSourceType, position: Coords) => {
    const { lat, lng } = position;

    if (satelliteSourceToOutline === SatelliteSourceType.Prospector) {
        const location = prospectorAddress(lat, lng);
        const fakeSource = new ws.WeatherSource({
            location,
            name: '10KM Grid (' + location.latitude.toFixed(2) + ',' + location.longitude.toFixed(2) + ')',
            external_id: prospectorId(location.latitude, location.longitude),
            source_type: 'prospector',
            source: 'NREL',
            url: 'http://maps.nrel.gov/prospector',
            weather_datasets: [
                { weather_dataset_id: -1, name: 'TMY' },
                { weather_dataset_id: -2, name: 'TGY' },
                { weather_dataset_id: -3, name: 'TDY' },
                { weather_dataset_id: -4, name: '2009' },
                { weather_dataset_id: -5, name: '2008' },
                { weather_dataset_id: -6, name: '2007' },
                { weather_dataset_id: -7, name: '2006' },
                { weather_dataset_id: -8, name: '2005' },
                { weather_dataset_id: -9, name: '2004' },
                { weather_dataset_id: -10, name: '2003' },
                { weather_dataset_id: -11, name: '2002' },
                { weather_dataset_id: -12, name: '2001' },
                { weather_dataset_id: -13, name: '2000' },
                { weather_dataset_id: -14, name: '1999' },
                { weather_dataset_id: -15, name: '1998' },
            ],
        });

        return fakeSource;
    }

    // PSM
    const location = psmAddress(lat, lng);
    const gy = Math.round((location.latitude - ws.WEATHER_CONSTS.PsmLatZero) / ws.WEATHER_CONSTS.PsmRes);
    const gx = Math.round((location.longitude - ws.WEATHER_CONSTS.PsmLonZero) / ws.WEATHER_CONSTS.PsmRes);
    const fakeSource = new ws.WeatherSource({
        location,
        name: '0.04\u00B0 Grid (' + location.latitude.toFixed(2) + ',' + location.longitude.toFixed(2) + ')',
        external_id: `${gy}_${gx}`,
        source_type: 'psm3',
        source: 'NREL',
        url: 'https://nsrdb.nrel.gov/data-sets/us-data',
        weather_datasets: [
            { weather_dataset_id: -1, name: 'TMY' },
            { weather_dataset_id: -2, name: 'TGY' },
            { weather_dataset_id: -3, name: 'TDY' },
        ],
    });

    return fakeSource;
};
