import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import * as pro from 'reports/models/profile';

import { FormBasicSelect } from 'reports/components/forms/inputs/experimental';
import { FavoriteItemRenderer, LABEL_WIDTH } from 'reports/modules/profile/components/common';

interface IFormProfileSelect {
    path: string;
    type: pro.ProfileType;
    isNullable?: boolean;
    label?: string;
    helperText?: string;
    labelWidth?: number;
    maxButtonWidth?: number;
    noneSelected?: React.ReactNode;
    fill?: boolean;
    matchSelectWidth?: boolean;
}
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IFormProfileSelect & IDispatchProps;

const FormProfileSelect: React.FC<IProps> = ({
    path,
    type,
    isNullable,
    label,
    helperText,
    labelWidth,
    maxButtonWidth,
    noneSelected,
    matchSelectWidth,
    fill,
    getProfiles,
}) => (
    <FormBasicSelect<pro.Profile>
        inline
        bold
        fill={fill}
        noneSelected={noneSelected || 'None'}
        label={label}
        helperText={helperText}
        labelWidth={labelWidth ? labelWidth : LABEL_WIDTH}
        path={path}
        isNullable={isNullable}
        dataSource={{
            async: true,
            query: async (q) => await getProfiles({ q, type, limit: 20 }),
            keyLookup: (profile) => profile.profile_id,
            itemLookup: (profileId, state) => pro.selectors.byId(state, profileId)!,
        }}
        itemRenderer={FavoriteItemRenderer}
        maxButtonWidth={maxButtonWidth}
        matchSelectWidth={matchSelectWidth}
    />
);

const mapDispatchToProps = bindActions(() => ({
    getProfiles: pro.api.index,
}));

export default connect(null, mapDispatchToProps)(FormProfileSelect);
