import * as React from 'react';

export interface IUpsellContent {
    title: string;
    contentText: string | React.ReactNode;
    imgUrl: string;
    helpCenterUrl: string;
    modalName: UpsellModalNameType;
}

export type ReferrerType =
    | 'edit_report_template_button'
    | 'report_templates_library_sidebar'
    | 'financial_models_library_sidebar'
    | 'utility_rates_library_sidebar'
    | 'incentives_library_sidebar'
    | 'project_financial_sidebar';

type UpsellModalNameType = 'reports' | 'financials';

const REPORTS_AND_PROPOSALS_TITLE = 'Unlock Customizable Sales Proposals';
const REPORTS_AND_PROPOSALS_CONTENT = (
    <p>
        Stand out from the competition with fully customizable Sales Proposals and Reports that help close more deals —{' '}
        <i>faster</i>. Easily drag and drop elements to customize your own Sales Proposals and Reports. Use Smart Tokens
        to seamlessly design, and bring in financial inputs and charts.
    </p>
);

const FINANCIALS_TITLE = 'Unlock Financial Analysis';
const FINANCIALS_CONTENT = (
    <p>
        Level up your financial analysis, and build custom financial models quickly and accurately. Using Financial
        Analysis you can visualize the viability of your project over time with cash flow analysis charts that
        incorporate utility rates, financial inputs, metrics, and more.
    </p>
);

export const UPSELL_DIALOG_CONTACT_SALES_TEMPLATE = `mailto:sales@helioscope.com?subject=HelioScope Subscription&body=Hello, I would like to speak to someone in Sales about a Custom plan.%0D%0A%0D%0A
About my business:%0D%0A%0D%0A
How many projects does your business average per month and per year?%0D%0A
(Enter your answer here)%0D%0A%0D%0A
What percentage of your monthly and annual projects do you anticipate will be less than 50KW in scale?%0D%0A
(Enter your answer here)%0D%0A%0D%0A
Is your business-focus primarily in the residential or commercial/industrial space?%0D%0A
(Enter your answer here)
`;

export const UPSELL_DIALOG_CONTENT: {
    [key: string]: IUpsellContent;
} = {
    FINANCIAL: {
        title: FINANCIALS_TITLE,
        contentText: FINANCIALS_CONTENT,
        imgUrl: require('reports/static/financials_paywall.png'),
        helpCenterUrl: 'https://help-center.helioscope.com/hc/en-us/categories/4416336540307-Financials-Proposals',
        modalName: 'financials',
    },
    REPORTS_AND_PROPOSALS: {
        title: REPORTS_AND_PROPOSALS_TITLE,
        contentText: REPORTS_AND_PROPOSALS_CONTENT,
        imgUrl: require('reports/static/reports_paywall.png'),
        helpCenterUrl: 'https://help-center.helioscope.com/hc/en-us/articles/8547323292051-Proposals-Overview',
        modalName: 'reports',
    },
};
