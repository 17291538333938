import { difference, flatten, pick } from 'lodash';

import * as React from 'react';
import { FormConsumer, IFormContext } from './types';

export interface IFormFieldsProps {
    paths: string[];

    children: (props: {
        values: any;
        onChange: (val: any) => void;
        errors: string[];

        form: IFormContext<any>;
    }) => React.ReactNode;
}

const FormFields: React.FC<IFormFieldsProps> = ({ paths, children }) => (
    <FormConsumer>
        {(formContext) => (
            <>
                {children({
                    values: pick(formContext.formData, paths),
                    onChange: (partialObject) => {
                        const invalidKeys = difference(Object.keys(partialObject), paths);
                        if (invalidKeys.length) {
                            throw Error(`Can't change keys not in paths prop: ${invalidKeys}`);
                        }
                        formContext.updateValues(partialObject);
                    },
                    errors: flatten(paths.map((path) => formContext.fieldErrors[path] || ([] as any))),
                    form: formContext,
                })}
            </>
        )}
    </FormConsumer>
);

export default FormFields;
