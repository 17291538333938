import { H3 } from '@blueprintjs/core';
import { styled } from 'reports/styles/styled-components';

const PurchaseHeader = styled(H3)`
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 6px;
    color: ${(props) => (props.disabled ? 'lightslategray' : '#333')};
`;

export { PurchaseHeader };
