import { Dispatch } from 'redux';

import * as wz from 'reports/models/wiring_zone';
import * as pd from 'reports/models/power_device';
import * as wr from 'reports/models/wire';
import * as fs from 'reports/models/field_segment';
import * as fc from 'reports/models/field_component';

const COMPONENT_CLASSES: { [key in fc.ComponentType]: any } = {
    combiner: fc.FieldCombiner,
    inverter: fc.FieldInverter,
    interconnect: fc.Interconnect,
    parallel_connection: fc.ParallelConnection,
    series_connection: fc.SeriesConnection,
    ac_panel: fc.AcPanel,
    ac_branch: fc.AcBranch,
    ac_run: fc.AcRun,
    module: fc.FieldModule,
    string: fc.FieldString,
    bus: fc.FieldBus,
    optimizer: fc.FieldOptimizer,
};

function getRelationIds(compData: any[] | any) {
    const idFields = ['wiring_zone_id', 'power_device_id', 'wire_gauge_id', 'field_segment_id'];

    const mergeObjs = (objs) => {
        const merged = {};
        for (const field of idFields) {
            const ids: any[] = [];
            for (const obj of objs) {
                if (obj[field]) {
                    ids.push(...obj[field]);
                }
            }
            merged[field] = [...new Set(ids)];
        }
        return merged;
    };

    if (Array.isArray(compData)) {
        return mergeObjs(compData.map((child) => getRelationIds(child)));
    }

    let childObj = {};
    if (compData.children) {
        childObj = getRelationIds(compData.children);
    } else if (compData.child) {
        childObj = getRelationIds(compData.child);
    }

    const obj = {};
    for (const field of idFields) {
        obj[field] = compData[field] != null ? [compData[field]] : [];
    }
    return mergeObjs([obj, childObj]);
}

function loadFieldCompDeps(compData, state, dispatch: Dispatch) {
    const relationIds = getRelationIds(compData);
    const promises: any[] = [];
    const modules = {
        wiring_zone_id: wz,
        power_device_id: pd,
        wire_gauge_id: wr,
        field_segment_id: fs,
    };
    for (const [idField, module] of Object.entries(modules)) {
        for (const id of relationIds[idField]) {
            if (!module.selectors.byId(state, id)) {
                promises.push(dispatch((module.api as any).get({ [idField]: id })));
            }
        }
    }
    return Promise.all(promises);
}

function addRelations(state, compData) {
    if (compData.wiring_zone_id != null) {
        compData.wiring_zone = wz.selectors.byId(state, compData.wiring_zone_id);
    }

    if (compData.power_device_id != null && ['optimizer', 'inverter'].includes(compData.component_type)) {
        compData[compData.component_type] = pd.selectors.byId(state, compData.power_device_id);
    }

    if (compData.wire_gauge_id != null) {
        compData.wire = wr.selectors.byId(state, compData.wire_gauge_id);
    }

    if (compData.field_segment_id != null) {
        compData.fieldSegment = fs.selectors.byId(state, compData.field_segment_id);
    }
}

function parseFieldComponent(state, compData: any[] | any) {
    if (Array.isArray(compData)) {
        return compData.map((child) => parseFieldComponent(state, child));
    }

    const compCopy = { ...compData };
    if (compCopy.children) {
        compCopy.children = parseFieldComponent(state, compCopy.children);
    } else if (compCopy.child) {
        compCopy.child = parseFieldComponent(state, compCopy.child);
    }

    addRelations(state, compCopy);

    return new COMPONENT_CLASSES[compData.component_type](compCopy);
}

export {
    loadFieldCompDeps,
    parseFieldComponent,
    getRelationIds, // Only exported for use in unit tests
};
