import { get } from 'lodash';
import * as React from 'react';

import { Alignment, Classes, Button, Colors, Popover, PopoverPosition } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { authorizedForModule, ModuleCategory, IPipelineModule } from 'reports/modules/financials/model/modules';
import { pipelineInsertables } from 'reports/modules/financials/model/pipeline';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const PopoverContainer = styled.div`
    padding: 6px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const Header = styled.div`
    color: ${Colors.GRAY1};
    font-weight: 600;
    background-color: #f8f8f8;
    padding: 6px;
`;

const ButtonText = styled.div`
    color: ${Colors.GRAY1};
    font-weight: 600;
`;

const Insert = ({ text, onClick, disabled }) => (
    <div>
        <Button
            fill
            minimal
            alignText={Alignment.LEFT}
            className={Classes.POPOVER_DISMISS}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </Button>
    </div>
);

interface IInsertPopoverProps {
    onSelect: (item: { key: string; object: IPipelineModule }) => void;
    text?: string;
    category?: ModuleCategory;
}

const InsertPopover: React.SFC<IInsertPopoverProps> = ({ category, onSelect, text }) => {
    const insertableSteps = pipelineInsertables();

    let inserts;
    if (category != null) {
        inserts = get(insertableSteps, category).map((mod, idx) => (
            <Insert
                key={idx}
                text={mod.object.description}
                onClick={() => onSelect(mod)}
                disabled={!authorizedForModule(mod.object)}
            />
        ));
    } else {
        inserts = Object.entries(insertableSteps).map(([category, modules], idx) => {
            const items = modules.map((mod, subidx) => (
                <Insert
                    key={subidx}
                    text={mod.object.description}
                    onClick={() => onSelect(mod)}
                    disabled={!authorizedForModule(mod.object)}
                />
            ));

            return (
                <div key={idx}>
                    <div style={{ padding: '2px' }}>
                        <b>{category}</b>
                    </div>
                    {items}
                </div>
            );
        });
    }

    return (
        <div style={{ marginLeft: '4px' }}>
            <Popover
                boundary="scrollParent"
                modifiers={{ preventOverflow: { enabled: true } }}
                position={PopoverPosition.TOP}
                content={
                    <PopoverContainer>
                        <Header>Insert Step</Header>
                        {inserts}
                    </PopoverContainer>
                }
            >
                <Button minimal small fill={true} icon={IconNames.PLUS}>
                    {text && <ButtonText>{text}</ButtonText>}
                </Button>
            </Popover>
        </div>
    );
};

export default InsertPopover;
