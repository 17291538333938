import { Spinner } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import styled from 'styled-components';

import * as analytics from 'reports/analytics';
import { Flex } from 'reports/components/core/containers';
import * as ws from 'reports/models/weather_source';
import * as auth from 'reports/modules/auth';
import { SatelliteSourceType, WeatherSourceMap } from 'reports/modules/weather_source/views/WeatherSourceMap';
import {
    FiltersPanel,
    GROUND_WEATHER_SOURCE_TYPES,
} from 'reports/modules/weather_source/views/WeatherLibraryFiltersPanel';
import Colors from 'reports/styles/colors';
import { ContextBarControls } from 'reports/components/ContextBar';
import WeatherSourceUploadLauncher from './WeatherSourceUploadLauncher';

const MapContainer = styled.div<{ inlineControls?: boolean }>`
    position: relative;
    width: 100%;
    height: ${(props) => (props.inlineControls ? '710' : '730')}px;
    border: 1px solid ${Colors.BORDER_TABLE_LIGHT};
`;

const PageContainer = styled(Flex.Container)`
    margin: 20px;
`;

const SpinnerContainer = styled(Flex.ContainerV)`
    height: 100%;
    justify-content: center;
`;

const UploadContainer = styled.div`
    width: fit-content;
    align-self: flex-end;
    margin: 0px 10px 10px 10px;
`;

const DefaultGroundFilters = Object.keys(GROUND_WEATHER_SOURCE_TYPES);

const WeatherLibrary = ({ inlineControls }: { inlineControls?: boolean }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => auth.selectors.getUser(state)!);

    const getSources = () =>
        dispatch(
            ws.api.index({
                limit: 10000,
                load_datasets: true,
            }),
        );

    const [sources, setSources] = React.useState<ws.WeatherSource[] | null>(null);
    const [satelliteFilter, setSatelliteFilter] = React.useState<SatelliteSourceType | undefined>(undefined);
    const [groundFilters, setGroundFilters] = React.useState<string[]>(DefaultGroundFilters);
    const [ownTeamOnly, setOwnTeamOnly] = React.useState(false);

    React.useEffect(() => {
        (async () => {
            setSources(await getSources());
        })();
    }, []);

    const toggleGroundFilter = (srcType) => {
        setSatelliteFilter(undefined);
        setGroundFilters(
            groundFilters.includes(srcType)
                ? groundFilters.filter((st) => st !== srcType)
                : [...groundFilters, srcType],
        );
    };

    const toggleSatellite = (srcType) => {
        const isSelected = srcType === satelliteFilter;
        setSatelliteFilter(isSelected ? undefined : srcType);
        setGroundFilters(isSelected ? DefaultGroundFilters : []);
    };

    const filteredSources = sources
        ?.filter((x) => groundFilters.includes(x.source_type!))
        .filter((x) => !ownTeamOnly || x.team_id === user.team_id);

    const addWeatherSources = (newSources) => {
        setSources((sources) => (sources ?? []).concat(newSources));
    };

    return (
        <>
            {!inlineControls && (
                <ContextBarControls>
                    <WeatherSourceUploadLauncher addWeatherSources={addWeatherSources} />
                </ContextBarControls>
            )}
            <PageContainer>
                <FiltersPanel
                    groundFilters={groundFilters}
                    satelliteFilter={satelliteFilter}
                    ownTeamOnly={ownTeamOnly}
                    toggleGroundFilter={toggleGroundFilter}
                    toggleSatelliteFilter={toggleSatellite}
                    toggleOwnTeamOnly={() => setOwnTeamOnly(!ownTeamOnly)}
                    user={user}
                    sources={sources}
                />
                <Flex.Container style={{ flexDirection: 'column', width: '100%' }}>
                    {inlineControls && (
                        <UploadContainer>
                            <WeatherSourceUploadLauncher addWeatherSources={addWeatherSources} />
                        </UploadContainer>
                    )}

                    <MapContainer inlineControls={inlineControls}>
                        <WeatherSourceMap
                            initMapConfig={{
                                initialCenter: { lat: 0, lng: 0 },
                                initialZoom: 1,
                            }}
                            weatherSources={filteredSources ?? []}
                            satelliteSourceToOutline={satelliteFilter}
                            enableClustering={true}
                            disabled={!sources}
                            infoWindowTrigger={'click'}
                            infoWindowDetailed
                            onSelectSource={(sourceId) => {
                                analytics.track('weather.inspect', {
                                    weather_source_id: sourceId,
                                });
                            }}
                        />
                        {!sources && (
                            <SpinnerContainer>
                                <Spinner />
                            </SpinnerContainer>
                        )}
                    </MapContainer>
                </Flex.Container>
            </PageContainer>
        </>
    );
};

export { WeatherLibrary };
