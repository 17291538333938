import * as React from 'react';

import { Intent, InputGroup } from '@blueprintjs/core';

import FormField from 'reports/components/forms/FormField';
import { FormGroup, IFormGroupProps } from 'reports/components/core/forms';

type IFormTextInputProps = IFormGroupProps & {
    dataTestId?: string;
    path: string;
    type?: string;
    placeholder?: string;
    className?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
};

const FormTextInput = ({
    dataTestId,
    path,
    label,
    inline,
    fill,
    bold,
    placeholder,
    helperText,
    type,
    className,
    autoFocus,
    labelWidth,
    disabled,
    onClick,
    onFocus,
    onBlur,
}: IFormTextInputProps) => {
    return (
        <FormField path={path}>
            {({ value, onChange, form, errors }) => {
                const invalid = errors.length > 0;
                return (
                    <>
                        <FormGroup
                            inline={inline}
                            fill={fill}
                            bold={bold}
                            label={label}
                            helperText={invalid ? errors[0] : helperText}
                            intent={invalid ? Intent.DANGER : undefined}
                            labelWidth={labelWidth}
                        >
                            <InputGroup
                                data-testid={dataTestId}
                                intent={invalid ? Intent.DANGER : Intent.NONE}
                                value={value}
                                placeholder={placeholder}
                                onChange={(evt) => {
                                    const editValue = evt.target.value;
                                    onChange(editValue); // Call formOnChange with the new value
                                }}
                                onClick={onClick}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                disabled={disabled || form.submitting}
                                type={type}
                                className={className}
                                autoFocus={autoFocus}
                            />
                        </FormGroup>
                    </>
                );
            }}
        </FormField>
    );
};

export default FormTextInput;
