import * as React from 'react';

import { Icon, Intent, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Flex from 'reports/components/core/containers/Flex';

import { PipelineResult } from 'reports/modules/financials/model/pipeline';

import { default as Content } from './FinancialStepContent';
import Result from '../Result';

const InfoTooltip: React.SFC<{ message?: string }> = ({ message }) => (
    <Tooltip
        content={
            <div style={{ maxWidth: '200px' }}>
                {message ? message : 'Result will be calculated dynamically for each project'}
            </div>
        }
        position={Position.BOTTOM_RIGHT}
    >
        <Icon icon={IconNames.INFO_SIGN} intent={Intent.PRIMARY} style={{ paddingRight: '4px' }} />
    </Tooltip>
);

interface IStepResultProps {
    result: PipelineResult;
    showInfo?: boolean;
}

const StepResult: React.SFC<IStepResultProps> = ({ result, showInfo }) => (
    <Content.ParameterContainer color={'#dfebf5'}>
        <Flex.Container className="center">
            <Content.Label>{result.description}</Content.Label>
            <Content.Data style={{ position: 'relative' }}>
                {showInfo && (
                    <div style={{ position: 'absolute', left: '-14px' }}>
                        <InfoTooltip message={result.info} />
                    </div>
                )}
                <Content.Result>
                    <Result result={result} />
                </Content.Result>
            </Content.Data>
            <Content.ParameterMeta />
        </Flex.Container>
    </Content.ParameterContainer>
);

export default StepResult;
