import * as React from 'react';

import { ConsumptionEnforcementPeriod } from 'reports/models/team';
import { FormBareBasicSelect } from 'reports/components/forms/inputs/experimental';
import { SubscriptionInput } from 'reports/modules/settings/admin/components/SubscriptionInput';

interface ConsumptionEnforcementPeriodOpt {
    name: string;
    key: ConsumptionEnforcementPeriod | null;
}

const CONSUMPTION_ENFORCEMENT_PERIOD_OPTIONS: ConsumptionEnforcementPeriodOpt[] = [
    {
        name: 'Sept 11, 2023',
        key: ConsumptionEnforcementPeriod.immediately,
    },
    {
        name: 'Oct 3, 2023',
        key: ConsumptionEnforcementPeriod.phase_iv_and_v,
    },
    {
        name: 'October 2024',
        key: ConsumptionEnforcementPeriod.one_year_grace_period,
    },
    {
        name: 'No Enforcement',
        key: ConsumptionEnforcementPeriod.no_enforcement,
    },
    {
        name: 'Not set (when enforcement period is null)',
        key: null,
    },
];

interface SelectProps {
    disabled: boolean;
}

export const ConsumptionEnforcementPeriodSelect = ({ disabled }: SelectProps) => (
    <SubscriptionInput disabled={disabled} title="Project Limit Enforcement Date">
        <FormBareBasicSelect<ConsumptionEnforcementPeriodOpt>
            inline
            path={'consumption_enforcement_period'}
            dataSource={{
                items: CONSUMPTION_ENFORCEMENT_PERIOD_OPTIONS,
                keyLookup: (item) => item.key,
            }}
            itemRenderer={({ key, name }) => ({
                key: String(key),
                text: name,
            })}
            disabled={disabled}
        />
    </SubscriptionInput>
);

export default ConsumptionEnforcementPeriodSelect;
