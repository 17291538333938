import * as React from 'react';
import { keyBy, chain } from 'lodash';
import { connect } from 'react-redux';

import { Spinner } from '@blueprintjs/core';

import * as scen from 'reports/models/scenario';
import * as mod from 'reports/models/module';
import * as pd from 'reports/models/power_device';

import { bindActions } from 'reports/utils/redux';
import FixedItemsSelect from 'reports/components/forms/inputs/experimental/FixedItemsSelect';
import BasicTable from 'reports/components/core/tables/BasicTable';

interface IOwnProps {
    scenario: scen.Scenario;
    scenMCs: scen.ScenarioModuleCharacterization[];
    scenPDCs: scen.ScenarioPowerDeviceCharacterization[];
    onScenMCsChange: (scenMCs: mod.ModuleCharacterization[]) => void;
    onScenPDCsChange: (scenPDCs: pd.PowerDeviceCharacterization[]) => void;
}

type IProps = IOwnProps & ReturnType<typeof mapDispatchToProps>;

interface IState {
    loading: boolean;

    projDeviceCharacterizations?: pd.PowerDeviceCharacterization[];
    projModuleCharacterizations?: mod.ModuleCharacterization[];
}

const INITIAL_STATE: IState = {
    loading: true,
    projDeviceCharacterizations: undefined,
    projModuleCharacterizations: undefined,
};

/**
 * Generate a new array of Scenario*Characterizations based on component defaults, previous characterizations,
 * and newly selected characterization. This works for both modules and power devices.
 */
const onSelectChar = (prevScenChars, selectedCharId, componentId, projComponents, componentIdField, charIdField) => {
    const prevComponentToChar = keyBy(prevScenChars, 'module_id');

    // Map over each project component and pick a characterization with the following precedence:
    // (1) just selected by the user (2) previous value (3) component default
    const newScenChars = projComponents.map((component) => ({
        scenario_id: component.scenario_id,
        [componentIdField]: component[componentIdField],
        [charIdField]:
            componentId === component[componentIdField]
                ? selectedCharId
                : (prevComponentToChar[component[componentIdField]] || component.defaultCharacterization())[
                      charIdField
                  ],
    }));

    // We generate an entirely new array of Scenario*Characterizations
    // so that Form dirty checking works without special handling for arrays
    return newScenChars;
};

class ModuleCharRows extends React.PureComponent<{
    projMCs;
    scenMCs;
    onScenMCsChange;
}> {
    render() {
        const { projMCs, scenMCs } = this.props;

        const scenMCsByModuleId = keyBy(scenMCs, 'module_id');

        const sortedModules = chain(projMCs).map('module').uniq().sortBy(['manufacturer', 'name']).value();

        const charsForModule = chain(projMCs).groupBy('module_id').value();

        const mcsById = keyBy(projMCs, 'module_characterization_id');

        return sortedModules.map((module) => {
            const selectedMCId = (scenMCsByModuleId[module.module_id] || module.defaultCharacterization())
                .module_characterization_id;

            return (
                <tr key={module.module_id}>
                    <td>Module</td>
                    <td>
                        {module.name} ({module.manufacturer})
                    </td>
                    <td>
                        <FixedItemsSelect
                            items={charsForModule[module.module_id].map((mc) => ({
                                key: mc.module_characterization_id,
                                name: mc.name,
                            }))}
                            value={selectedMCId}
                            onChange={(mcId) => this.onSelectMC(mcId, module.module_id, sortedModules)}
                            disabled={charsForModule[module.module_id].length <= 1}
                        />
                    </td>
                    <td>{module.team!.name}</td>
                    <td>{mcsById[selectedMCId].description}</td>
                </tr>
            );
        });
    }

    onSelectMC(mcId, moduleId, projModules) {
        const { scenMCs, onScenMCsChange } = this.props;

        const newScenMCs = onSelectChar(
            scenMCs,
            mcId,
            moduleId,
            projModules,
            'module_id',
            'module_characterization_id',
        );
        onScenMCsChange(newScenMCs);
    }
}

class DeviceCharRows extends React.PureComponent<{
    projPDCs;
    scenPDCs;
    onScenPDCsChange;
}> {
    render() {
        const { projPDCs, scenPDCs } = this.props;

        const scenPDCsById = keyBy(scenPDCs, 'power_device_id');

        const sortedDevices = chain(projPDCs)
            .map('power_device')
            .uniq()
            .sortBy(['device_type_name', 'manufacturer', 'name'])
            .value();

        const charsForDevice = chain(projPDCs).groupBy('power_device_id').value();

        const pdcsById = keyBy(projPDCs, 'power_device_characterization_id');

        return sortedDevices.map((device) => {
            const selectedPDCId = (scenPDCsById[device.power_device_id] || device.defaultCharacterization())
                .power_device_characterization_id;

            return (
                <tr key={device.power_device_id}>
                    <td>{pd.PowerDeviceTypes[device.device_type_name]}</td>
                    <td>
                        {device.name} ({device.manufacturer})
                    </td>
                    <td>
                        <FixedItemsSelect
                            items={charsForDevice[device.power_device_id].map((pdc) => ({
                                key: pdc.power_device_characterization_id,
                                name: pdc.name,
                            }))}
                            value={selectedPDCId}
                            onChange={(pdcId) => this.onSelectPDC(pdcId, device.power_device_id, sortedDevices)}
                            disabled={charsForDevice[device.power_device_id].length <= 1}
                        />
                    </td>
                    <td>{device.team!.name}</td>
                    <td>{pdcsById[selectedPDCId].description}</td>
                </tr>
            );
        });
    }

    onSelectPDC(selectedPdcId, deviceId, projDevices) {
        const { scenPDCs, onScenPDCsChange } = this.props;

        const newScenPDCs = onSelectChar(
            scenPDCs,
            selectedPdcId,
            deviceId,
            projDevices,
            'power_device_id',
            'power_device_characterization_id',
        );
        onScenPDCsChange(newScenPDCs);
    }
}

class _CharacterizationEditTable extends React.PureComponent<IProps, IState> {
    state = INITIAL_STATE;

    componentDidUpdate(prevProps) {
        const { scenario } = this.props;

        if (scenario.project_id !== prevProps.scenario.project_id) {
            this.loadProjectCharacterizations();
        }
    }

    componentDidMount() {
        this.loadProjectCharacterizations();
    }

    async loadProjectCharacterizations() {
        const { loadProjectDevices, loadProjectModules } = this.props;

        this.setState(INITIAL_STATE);
        const [projModuleCharacterizations, projDeviceCharacterizations] = await Promise.all([
            loadProjectModules(),
            loadProjectDevices(),
        ]);

        this.setState({
            projDeviceCharacterizations,
            projModuleCharacterizations,
            loading: false,
        });
    }

    render() {
        const { loading } = this.state;
        const { projModuleCharacterizations, projDeviceCharacterizations } = this.state;
        const { scenPDCs, scenMCs, onScenPDCsChange, onScenMCsChange } = this.props;

        return (
            <BasicTable>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Component</th>
                        <th>Characterization</th>
                        <th>Uploaded By</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={5}>
                                <Spinner />
                            </td>
                        </tr>
                    )}

                    <ModuleCharRows
                        scenMCs={scenMCs}
                        projMCs={projModuleCharacterizations}
                        onScenMCsChange={onScenMCsChange}
                    />
                    <DeviceCharRows
                        scenPDCs={scenPDCs}
                        projPDCs={projDeviceCharacterizations}
                        onScenPDCsChange={onScenPDCsChange}
                    />
                </tbody>
            </BasicTable>
        );
    }
}

const mapDispatchToProps = bindActions(({ scenario }) => ({
    loadProjectDevices: () => pd.charApi.index({ project_id: scenario.project_id }),
    loadProjectModules: () => mod.charApi.index({ project_id: scenario.project_id }),
}));

const CharacterizationEditTable = connect(null, mapDispatchToProps)(_CharacterizationEditTable);

export { CharacterizationEditTable };

export default CharacterizationEditTable;
