import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { withRoute } from 'react-router5';
import { IWithRouteProps } from 'reports/utils/router';
import { bindActions } from 'reports/utils/redux';
import { IAppState } from 'reports/store';

import _ from 'lodash';
import { Button, Classes, Intent, MenuDivider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Project } from 'reports/models/project';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as scen from 'reports/models/scenario';
import * as profile from 'reports/models/profile';
import * as auth from 'reports/modules/auth';

import { actions as projActions } from 'reports/modules/project';
import Flex from 'reports/components/core/containers/Flex';
import { BehavedButton } from 'reports/components/helpers/common';
import { promptModalForm } from 'reports/components/dialog';
import { CreateButton, PrimaryIntent } from 'reports/components/core/controls';
import { Form, IFormUpdateCallback } from 'reports/components/forms';
import { FormBasicSelect } from 'reports/components/forms/inputs/experimental';

import { Toaster } from 'reports/modules/Toaster';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const DropdownCreateButton = styled(CreateButton)`
    width: 100%;
    margin-left: 0px;
`;

const navigate = async (router, path, params) => {
    return new Promise((resolve) => {
        router.navigate(path, params, resolve);
    });
};

interface IFinConfOwnProps {
    project: Project;
    projFinConfig: projFinTemp.ProjectFinancialTemplate;
}

type FinConfProps = IFinConfOwnProps &
    ReturnType<typeof finConfMapStateToProps> &
    ReturnType<typeof finConfMapDispatchToProps> &
    IWithRouteProps;

class FinConfigPop extends React.Component<FinConfProps> {
    render() {
        const { projFinConfig, finConfigs, project, router } = this.props;

        const items = finConfigs.map((finConfig) => {
            return {
                isPrimary: project.primary_project_financial_template_id === finConfig.project_financial_template_id,
                key: finConfig.project_financial_template_id,
                name: finConfig.name,
                onSetPrimary: async () => this.props.setPrimaryConfig(project, finConfig),
                onDelete: async () => this.props.deleteFinancialConfig(finConfig),
                onSelect: async () =>
                    navigate(router, 'app.projects.project.financial-configurations.financial-configuration', {
                        projectId: finConfig.project_id,
                        finConfigId: finConfig.project_financial_template_id,
                    }),
            };
        });

        return (
            <SelectPop
                emptyMessage="No configuration"
                items={items}
                selectedId={projFinConfig.project_financial_template_id}
            />
        );
    }
}

export function generateNewConditionSetParams(
    project: Project,
    scenarios: scen.Scenario[],
    defaultProfileScenario: scen.Scenario | undefined,
) {
    const scenarioNames = scenarios.map((scenario) => scenario.description);

    // Generate a generic name to use for the new condition set.
    const regexp = /^Condition Set \d+$/;
    const condSetNames = scenarioNames.filter((name) => regexp.test(name));
    const condSetNums = condSetNames.map((name) => parseInt(name.split(' ')[2], 10));
    const numToUse = Math.max(...[0, ...condSetNums]) + 1;
    const defaultScenarioParams = defaultProfileScenario ? defaultProfileScenario : scen.DEFAULT_SCENARIO_PARAMS;

    // Create a new condition set. Server will attempt to find a weather dataset to use.
    return {
        ...defaultScenarioParams,
        project_id: project.project_id,
        description: `Condition Set ${numToUse}`,
        weather_dataset_id: undefined,
    };
}

interface INewScenarioButtonProps {
    project: Project;
    useDropdownStyles?: boolean;
}

type NewScenarioButtonProps = INewScenarioButtonProps &
    ReturnType<typeof conditionMapStateToProps> &
    ReturnType<typeof conditionMapDispatchToProps>;

const _NewScenarioButton: React.FC<NewScenarioButtonProps> = ({
    project,
    useDropdownStyles,
    scenarios,
    createScenario,
    setPrimaryScenario,
    navigateTo,
    getProjectProfileDefaults,
    user,
}) => {
    const createNewConditionSet = async () => {
        const profileId = project.profile_id || user.default_profile_id;
        const defaultProfileScenario = await getProjectProfileDefaults(profileId);
        const condSetData = generateNewConditionSetParams(project, scenarios, defaultProfileScenario['scenario']);
        try {
            const resp = await createScenario(condSetData);
            try {
                await setPrimaryScenario(project, resp);
            } catch (exc) {
                Toaster.show({
                    message: `Condition Set created, but unable to set as primary: ${exc}`,
                    intent: Intent.DANGER,
                });
            } finally {
                navigateTo('app.projects.project.conditions.condition.edit', {
                    scenarioId: resp.scenario_id,
                    projectId: resp.project_id,
                });
            }
        } catch (exc) {
            Toaster.show({
                message: `Create Condition Set failed: ${exc}`,
                intent: Intent.DANGER,
            });
        }
    };
    return useDropdownStyles ? (
        <DropdownCreateButton text="New Condition Set" icon={IconNames.ADD} onClick={createNewConditionSet} />
    ) : (
        <CreateButton text="New Condition Set" icon={IconNames.ADD} onClick={createNewConditionSet} />
    );
};

interface IConditionsOwnProps {
    project: Project;
    scenario: scen.Scenario;
}

type ConditionsProps = IConditionsOwnProps &
    ReturnType<typeof conditionMapStateToProps> &
    ReturnType<typeof conditionMapDispatchToProps> &
    IWithRouteProps;

class ConditionsPop extends React.Component<ConditionsProps> {
    render() {
        const { scenario, scenarios, project, router } = this.props;
        const items = scenarios.map((scenario) => {
            return {
                key: scenario.scenario_id,
                isPrimary: project.primary_scenario_id === scenario.scenario_id,
                name: scenario.description,
                onSetPrimary: async () => this.props.setPrimaryScenario(project, scenario),
                onDelete: async () => this.props.deleteScenario(scenario),
                onSelect: async () =>
                    navigate(router, 'app.projects.project.conditions.condition', {
                        projectId: scenario.project_id,
                        scenarioId: scenario.scenario_id,
                    }),
            };
        });

        return (
            <SelectPop
                emptyMessage="No configuration"
                items={items}
                selectedId={scenario.scenario_id}
                button={<NewScenarioButton project={project} useDropdownStyles={true} />}
            />
        );
    }
}

interface INewPrimaryForm {
    new_primary: number;
}

interface IDeletePrimaryProps {
    items: SelectPopItem[];
    onUpdate: IFormUpdateCallback;
}

class DeletePrimaryForm extends React.Component<IDeletePrimaryProps> {
    render() {
        const { items, onUpdate } = this.props;
        const selectItems = items.filter((item) => !item.isPrimary);

        return (
            <Form baseValue={{ new_primary: selectItems[0].key }} onSubmit={this.onSubmit} onUpdate={onUpdate}>
                <p>
                    Please select an existing condition set to replace your current primary condition set for this
                    project. This will be used as the project's default condition set.
                </p>
                <FormBasicSelect<SelectPopItem>
                    path="new_primary"
                    dataSource={{
                        items: selectItems,
                        keyLookup: ({ key }) => key,
                    }}
                    itemRenderer={({ key, name }) => ({ key, text: name })}
                />
            </Form>
        );
    }

    onSubmit = (formData: INewPrimaryForm) => {
        const { items } = this.props;
        return items.find((item) => item.key === formData.new_primary);
    };
}

async function showDeletePrimaryDialog(items: SelectPopItem[]) {
    return await promptModalForm<SelectPopItem>({
        title: 'Delete Primary Condition Set',
        Component: DeletePrimaryForm,
        componentProps: { items },
        cancellable: true,
        submitLabel: 'Save',
        submitIntent: PrimaryIntent.SAVE,
    });
}

type SelectPopItem = {
    isPrimary: boolean;
    key: number;
    name: string;
    onSelect: () => Promise<any>;
    onDelete: () => Promise<any>;
    onSetPrimary: () => Promise<any>;
};

type SelectPopProps = {
    items: SelectPopItem[];
    emptyMessage: string;
    selectedId: number;
    button?: React.ReactNode;
};

class SelectPop extends React.Component<SelectPopProps> {
    render() {
        if (!this.props.items.length) {
            return (
                <div style={{ padding: '12px' }}>
                    <i>{this.props.emptyMessage}</i>
                </div>
            );
        }

        const sortedItems = _.sortBy(this.props.items, (i) => i.name);

        const elements = sortedItems.map((itemProps, idx) => {
            return (
                <Flex.Container key={idx} style={{ padding: 2 }}>
                    <div style={{ margin: '0px 4px' }}>
                        {itemProps.isPrimary ? (
                            <Button icon="star" minimal small disabled />
                        ) : (
                            <Button icon="star-empty" minimal small onClick={() => itemProps.onSetPrimary()} />
                        )}
                    </div>
                    <Flex.Main style={{ margin: '0px 4px' }}>
                        <BehavedButton
                            minimal
                            small
                            fill
                            className={Classes.POPOVER_DISMISS}
                            text={itemProps.name}
                            onClick={() => itemProps.onSelect()}
                        />
                    </Flex.Main>
                    <div style={{ margin: '0px 4px' }}>
                        <Button
                            icon="trash"
                            minimal
                            small
                            onClick={async () => {
                                if (itemProps.isPrimary) {
                                    const newPrimaryItem = await showDeletePrimaryDialog(this.props.items);
                                    await newPrimaryItem.onSetPrimary();
                                    await newPrimaryItem.onSelect();
                                }
                                await itemProps.onDelete();
                            }}
                            disabled={this.props.items.length === 1}
                        />
                    </div>
                </Flex.Container>
            );
        });

        return (
            <div
                style={{
                    padding: '4px',
                    width: '220px',
                    maxHeight: '320px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {elements}
                {elements.length && this.props.button && (
                    <>
                        <MenuDivider />
                        <div style={{ width: '100%', padding: 4 }}>{this.props.button}</div>
                    </>
                )}
            </div>
        );
    }
}

const finConfMapStateToProps = (state: IAppState, props: IFinConfOwnProps) => ({
    project: props.project,
    finConfigs: projFinTemp.selectors.all(state, {
        filter: (obj) => obj.project_id === props.project.project_id,
    }),
});

const finConfMapDispatchToProps = bindActions({
    deleteFinancialConfig: (finConfig) =>
        projFinTemp.api.delete({
            project_financial_template_id: finConfig.project_financial_template_id,
        }),
    setPrimaryConfig: (project, config) => projActions.setPrimaryProjectFinancialTemplate(project, config),
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
});

const conditionMapStateToProps = (state: IAppState, props: IConditionsOwnProps) => ({
    project: props.project,
    scenarios: scen.selectors.all(state, {
        filter: (obj) => obj.project_id === props.project.project_id,
    }),
    user: auth.selectors.getUser(state)!,
});

const conditionMapDispatchToProps = bindActions({
    deleteScenario: (scenario) =>
        scen.api.delete({
            scenario_id: scenario.scenario_id,
        }),
    setPrimaryScenario: (project, scenario) => projActions.setPrimaryScenarioSync(project, scenario),
    createScenario: scen.api.create,
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
    getProjectProfileDefaults: (profileId) => profile.api.defaults({ profile_id: profileId }),
});

const FinConfigPopContainer = connect(finConfMapStateToProps, finConfMapDispatchToProps)(withRoute(FinConfigPop));
const NewScenarioButton = connect(conditionMapStateToProps, conditionMapDispatchToProps)(_NewScenarioButton);
const ConditionsPopContainer = connect(conditionMapStateToProps, conditionMapDispatchToProps)(withRoute(ConditionsPop));

export { FinConfigPopContainer, ConditionsPopContainer, NewScenarioButton };
