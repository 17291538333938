import { arrayItemWrap } from 'helioscope/app/utilities/helpers';

import { toRadians } from './math';
import { Vector, Matrix } from './vector';


export class Plane {
    constructor(normal, constant) {
        this.normal = normal;
        this.constant = constant;

        this.projectionMatrixCache = new WeakMap();
    }


    /**
     * get the transform matrix to project points onto this plane given a direction determined by
     * a ray
     *
     * from: http://stackoverflow.com/questions/2500499/howto-project-a-planar-polygon-on-a-plane-in-3d-space
     */
    getProjectionMatrix(ray) {
        let mat = this.projectionMatrixCache.get(ray);

        if (!mat) {
            const denom = this.normal.dot(ray);

            mat = new Matrix([ /* eslint-disable max-len */
                1 - this.normal.x * ray.x / denom, 0 - this.normal.y * ray.x / denom, 0 - this.normal.z * ray.x / denom, -this.constant * ray.x / denom,
                0 - this.normal.x * ray.y / denom, 1 - this.normal.y * ray.y / denom, 0 - this.normal.z * ray.y / denom, -this.constant * ray.y / denom,
                0 - this.normal.x * ray.z / denom, 0 - this.normal.y * ray.z / denom, 1 - this.normal.z * ray.z / denom, -this.constant * ray.z / denom,
                0, 0, 0, 1,
            ]); /* eslint-enable */

            this.projectionMatrixCache.set(ray, mat);
        }

        return mat;
    }

    projectPoint(point, ray, direction = null) {
        const projected = this.getProjectionMatrix(ray).transform(point);

        if (direction && direction.dot(projected.subtract(point)) < 0) {
            return null;
        }

        return projected;
    }

    pointFromXY(point) {
        const z = (this.normal.x * point.x + this.normal.y * point.y + this.constant) / (-this.normal.z);
        return new Vector(point.x, point.y, z);
    }

    pathFromXYs(path) {
        const rtn = new Array(path.length);

        for (let i = 0; i < path.length; i++) {
            rtn[i] = this.pointFromXY(path[i]);
        }

        return rtn;
    }

    /**
     * http://stackoverflow.com/questions/6408670/line-of-intersection-between-two-planes
     */
    intersect(otherPlane, parallel = 0) {
        const p3Normal = this.normal.cross(otherPlane.normal);
        const p3Det = p3Normal.lengthSq();

        if (p3Det <= parallel) {
            // planes are parallel
            return { normal: null, point: null };
        }

        const point = (
            (p3Normal.cross(otherPlane.normal).scaleSelf(this.constant))
                .addSelf(this.normal.cross(p3Normal).scaleSelf(otherPlane.constant))
                .scaleSelf(1 / p3Det)
        );
        const direction = p3Normal;

        return { point, direction };
    }

    intersectRay(point, dir) {
        const norm = dir.normalize();
        const dot = norm.dot(this.normal);

        if (dot === 0) {
            return null; // parallel
        }
        const delta = -(this.normal.dot(point) + this.constant) / dot;

        return point.add(norm.scale(delta));
    }

    intersectLine(p1, p2) {
        return this.intersectRay(p1, p2.subtract(p1));
    }

    approx(otherPlane, tolerance = 1e-6) {
        return (
            Math.abs(this.constant - otherPlane.constant) < tolerance
            && this.normal.approx(otherPlane.normal, tolerance)
        );
    }

    static fromOrientation(tilt, azimuth, coplanarPoint) {
        const phi = toRadians((90 - azimuth) % 360);
        const theta = toRadians(tilt);

        const normal = new Vector(
            Math.sin(theta) * Math.cos(phi),
            Math.sin(theta) * Math.sin(phi),
            Math.cos(theta),
        );

        return Plane.fromPointNormal(coplanarPoint, normal);
    }

    static fromPointNormal(point, normal) {
        const constant = -(point.dot(normal));
        return new Plane(normal, constant);
    }

    static fromTwoPoints(pt1, pt2) {
        const dir = pt2.subtract(pt1);
        const cross = dir.cross((dir.z === 0) ? new Vector(0, 0, 1) : new Vector(1, 0, 0));
        const normal = dir.cross(cross);
        if (normal.lengthSq()) return Plane.fromPointNormal(pt1, normal.normalize());
        return Plane.fromPointNormal(pt1, new Vector(0, 0, 1));
    }

    static CROSS_SQ_THRESH = 0.1; // threshold for allowing points to define a plane

    static fromPath(path) {
        if (!path.length) return null;
        if (path.length < 2) return Plane.fromPointNormal(path[0], new Vector(0, 0, 1));

        const pt1 = path[0];

        let bestCross = null;
        let bestLenSq = 0;

        for (let i = 1; i < path.length; ++i) {
            // use a moving window of the 2nd two points in case the first two points are too close
            const pt2 = path[i];
            const pt3 = arrayItemWrap(path, i + 1);

            const cross = crossFromPoints(pt1, pt2, pt3);
            const lenSq = cross.lengthSq();

            if (lenSq > Plane.CROSS_SQ_THRESH) {
                bestCross = cross;
                break;
            } else if (lenSq > bestLenSq) {
                bestLenSq = lenSq;
                bestCross = cross;
            }
        }

        if (bestCross) return Plane.fromPointNormal(pt1, bestCross.normalize());

        return Plane.fromTwoPoints(path[0], path[1]);
    }
}

function crossFromPoints(pt1, pt2, pt3) {
    return pt2.subtract(pt1).cross(pt3.subtract(pt1));
}

export const XY_PLANE = Plane.fromOrientation(0, 0, new Vector(0, 0, 0));
