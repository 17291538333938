/**
 * Save prompt dialog
 */
import * as React from 'react';
import { Classes, Dialog, IDialogProps, Intent } from '@blueprintjs/core';

import { Deferred } from 'reports/utils/async_helpers';

import { PrimaryButton, PrimaryIntent, Button as SecondaryButton } from 'reports/components/core/controls';

import { DialogPropOverrides } from './FormPrompt';
import DialogActions from './DialogActions';
import { insertReactNode } from './DomInjector';

interface IBoolPromptCallbacks {
    onCancel: () => void;
    onYes: () => void;
    onNo: () => void;
}

interface IBoolPromptOpts {
    title?: string;
    prompt: string | React.ReactNode;
    yesLabel?: string;
    yesIntent?: PrimaryIntent;
    noLabel?: string;
    cancellable?: boolean;
    cancelLabel?: string;
    dialogProps?: DialogPropOverrides;
    disabled?: boolean;
}

export const MODAL_CANCELED_EXCEPTION = 'Canceled';

type IBooleanPromptProps = IBoolPromptOpts & IBoolPromptCallbacks & Omit<IDialogProps, 'isOpen'>;

class BooleanPrompt extends React.PureComponent<IBooleanPromptProps, { isOpen: boolean }> {
    state = { isOpen: true };

    finalize = (callback) => () => {
        this.setState({ isOpen: false });
        callback();
    };

    render() {
        const {
            title,
            prompt,
            onYes,
            onNo,
            onCancel,
            onClosed,
            cancellable = false,
            yesLabel = 'Ok',
            yesIntent,
            noLabel,
            dialogProps,
            cancelLabel = 'Cancel',
            disabled,
        } = this.props;

        const { isOpen } = this.state;

        return (
            <Dialog
                style={{ width: 400 }}
                title={title}
                canOutsideClickClose={false}
                canEscapeKeyClose={cancellable}
                isCloseButtonShown={cancellable}
                onClose={this.finalize(onCancel)}
                onClosed={onClosed}
                isOpen={isOpen}
                {...dialogProps}
            >
                <div className={Classes.DIALOG_BODY}>{prompt}</div>
                <div className={Classes.DIALOG_FOOTER}>
                    <DialogActions>
                        {noLabel != null && onNo != null && (
                            <SecondaryButton
                                text={noLabel}
                                className="hs-btn-left-align"
                                intent={Intent.DANGER}
                                onClick={this.finalize(onNo)}
                            />
                        )}
                        {cancellable && <SecondaryButton text={cancelLabel} onClick={this.finalize(onCancel)} />}
                        <PrimaryButton
                            text={yesLabel}
                            onClick={this.finalize(onYes)}
                            intent={yesIntent}
                            disabled={disabled}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

export async function promptModalBoolean(opts: IBoolPromptOpts) {
    const responseDeferred = new Deferred<Boolean>();
    const dialogClosed = new Deferred<Boolean>();

    const dialog = (
        <BooleanPrompt
            {...opts}
            onYes={() => responseDeferred.resolve(true)}
            onNo={() => responseDeferred.resolve(false)}
            onCancel={() => responseDeferred.reject(MODAL_CANCELED_EXCEPTION)}
            onClosed={() => dialogClosed.resolve(true)}
        />
    );

    const removeFromDom = insertReactNode(dialog);

    dialogClosed.promise.finally(removeFromDom);

    return responseDeferred.promise;
}
