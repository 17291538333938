import { H6 } from '@blueprintjs/core';
import * as React from 'react';
import styled from 'styled-components';
import { Flex } from 'reports/components/core/containers';
import * as ws from 'reports/models/weather_source';

const ParamTitle = styled.span`
    font-weight: bold;
    width: 160px;
`;

const ParamValueContainer = styled(Flex.ContainerV)`
    flex: 1;
`;

const WeatherDatasetContainer = styled(Flex.Container)`
    flex-wrap: wrap;
    white-space: pre;
`;

interface IProps {
    source: ws.WeatherSource;
}

const WeatherSourceInfo: React.FC<IProps> = (props) => {
    const { source } = props;
    const sourceString = !source.source
        ? 'Unknown'
        : `${source.external_id ? `${source.external_id}, ` : ``}${source.source}${
              !source.url ? `, ${source.source_type}` : ``
          }`;

    return (
        <Flex.ContainerV style={{ maxWidth: '330px' }}>
            <H6 style={{ marginRight: '18px' }}>{source.toString()}</H6>
            <Flex.Container>
                <ParamTitle>Location</ParamTitle>
                <ParamValueContainer>{source.locationString}</ParamValueContainer>
            </Flex.Container>
            <Flex.Container style={{ marginTop: '8px' }}>
                <ParamTitle>Source</ParamTitle>
                <ParamValueContainer>
                    {source.url ? (
                        <a href={source.url} target="_blank">
                            {sourceString}
                        </a>
                    ) : (
                        <span>{sourceString}</span>
                    )}
                </ParamValueContainer>
            </Flex.Container>
            <Flex.Container style={{ marginTop: '8px' }}>
                <ParamTitle>Available Datasets</ParamTitle>
                <ParamValueContainer>
                    <WeatherDatasetContainer>
                        {source.weather_datasets?.map((ds, i) => {
                            const name = i > 0 ? `, ${ds.name}` : ds.name;
                            return ds.get_url ? (
                                <a href={ds.get_url} target="_blank" id="weather-download">
                                    {name}
                                </a>
                            ) : (
                                <span>{name}</span>
                            );
                        })}
                    </WeatherDatasetContainer>
                </ParamValueContainer>
            </Flex.Container>
        </Flex.ContainerV>
    );
};

export default WeatherSourceInfo;
