import * as React from 'react';

import { Checkbox } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';
import { currencySymbol } from 'reports/utils/formatters';

import ConsumptionEnergyChart from 'reports/modules/consumption/components/ConsumptionEnergyChart';
import MonthlySavingsChart from 'reports/modules/financials/components/MonthlySavingsChart';
import { FinancialMetricsWidgetTable } from 'reports/modules/financials/components/FinancialMetrics';
import LifetimeProduction from 'reports/modules/financials/components/LifetimeProduction';
import BillOffsetPieChart from 'reports/modules/financials/components/BillOffsetPieChart';

import { registerWidget, IWidgetRenderProps, IReportContext } from 'reports/modules/report/widgets';
import { ChartForm, ChartType } from 'reports/modules/report/components/charts';
import { createChartWidget } from 'reports/modules/report/components/charts/createChartWidget';

interface IConsumptionEnergyChartContent {
    consumption: boolean;
    production: boolean;
    chartType: ChartType;
}

interface IMonthlySavingsChartContent {
    year: number;
    withSolar?: boolean;
    withoutSolar?: boolean;
    chartType: ChartType;
}

type IConsumptionEnergyChartContext = Pick<IReportContext, 'theme' | 'project'>;
export const ConsumptionEnergyChartWidget = registerWidget(
    'consumption_energy_chart',
    createChartWidget<IConsumptionEnergyChartContent, IConsumptionEnergyChartContext>({
        Chart: ({ config, context, layout, mouseEvents }) => {
            return (
                <ConsumptionEnergyChart
                    {...config}
                    theme={context.theme}
                    project={context.project}
                    layout={layout}
                    mouseEvents={mouseEvents}
                />
            );
        },
        metadata: {
            category: 'financial',
            dimensions: { h: 300, w: 400 },
            displayName: Translations.widgets.consumption_energy_chart_header,
            icon: IconNames.TIMELINE_AREA_CHART,
        },
        Form: ({ config, onChange, intl }) => {
            const { production = true, consumption = true, chartType = 'area' } = config;

            return (
                <ChartForm chartType={chartType} onChange={(chartType) => onChange({ chartType })}>
                    <Checkbox
                        label={intl.formatMessage(Translations.simulation.production_kwh)}
                        checked={production}
                        onChange={(evt) => onChange({ production: evt.currentTarget.checked })}
                    />
                    <Checkbox
                        label={intl.formatMessage(Translations.simulation.consumption_kwh)}
                        checked={consumption}
                        onChange={(evt) => onChange({ consumption: evt.currentTarget.checked })}
                    />
                </ChartForm>
            );
        },
        dependencies: ['project'],
    }),
);

type IMonthlySavingsChartContext = Pick<IReportContext, 'theme' | 'project' | 'financialTokens'>;
export const MonthlySavingsChartWidget = registerWidget(
    'monthly_savings_chart',
    createChartWidget<IMonthlySavingsChartContent, IMonthlySavingsChartContext>({
        Chart: ({ config, context, layout, mouseEvents }) => (
            <MonthlySavingsChart
                year={config.year}
                chartType={config.chartType || 'column'}
                withSolar={config.withSolar !== false}
                withoutSolar={config.withoutSolar !== false}
                project={context.project}
                financialTokens={context.financialTokens}
                layout={layout}
                mouseEvents={mouseEvents}
                theme={context.theme}
            />
        ),
        metadata: {
            category: 'financial',
            dimensions: { h: 300, w: 400 },
            displayName: Translations.widgets.monthly_savings_chart_header,
            icon: IconNames.TIMELINE_BAR_CHART,
        },
        Form: ({ config, onChange, intl }) => {
            const { withSolar = true, withoutSolar = true, chartType = 'column' } = config;

            return (
                <ChartForm chartType={chartType} onChange={(chartType) => onChange({ chartType })}>
                    <Checkbox
                        label={`${intl.formatMessage(Translations.financial.with_solar)} (${currencySymbol()})`}
                        checked={withSolar}
                        onChange={(evt) =>
                            onChange({
                                withSolar: evt.currentTarget.checked,
                            })
                        }
                    />
                    <Checkbox
                        label={`${intl.formatMessage(Translations.financial.without_solar)} (${currencySymbol()})`}
                        checked={withoutSolar}
                        onChange={(evt) =>
                            onChange({
                                withoutSolar: evt.currentTarget.checked,
                            })
                        }
                    />
                </ChartForm>
            );
        },
        dependencies: ['project', 'financialTokens'],
    }),
);

type IFinancialMetricsProps = IWidgetRenderProps<object, Pick<IReportContext, 'financialTokens'>>;
export const FinancialMetricsWidget = registerWidget('financial_metrics', {
    Component: ({ context, className }: IFinancialMetricsProps) => (
        <FinancialMetricsWidgetTable className={className} {...context} />
    ),
    metadata: {
        category: 'financial',
        dimensions: { h: 450, w: 400 },
        displayName: Translations.widgets.financial_metrics_header,
        icon: IconNames.PANEL_TABLE,
    },
    dependencies: ['financialTokens'],
});

export const LifetimeProductionWidget = registerWidget('lifetime_production', {
    Component: ({ config, context }) => <LifetimeProduction {...context} layout={config.layout} />,
    EditingComponent: ({ config, context }) => (
        <LifetimeProduction {...context} layout={config.layout} mouseEvents={false} />
    ),
    metadata: {
        category: 'financial',
        dimensions: { h: 300, w: 500 },
        displayName: Translations.widgets.lifetime_production_header,
        icon: IconNames.TIMELINE_BAR_CHART,
    },
    dependencies: ['financialTokens', 'theme'],
});

export const BillOffsetPieChartWidget = registerWidget('utility_bill_pie_chart', {
    Component: ({ config, context }) => (
        <BillOffsetPieChart
            financialTokens={context.financialTokens}
            project={context.project}
            layout={config.layout}
            theme={context.theme}
        />
    ),
    EditingComponent: ({ config, context }) => (
        <BillOffsetPieChart
            financialTokens={context.financialTokens}
            project={context.project}
            layout={config.layout}
            mouseEvents={false}
            theme={context.theme}
        />
    ),
    metadata: {
        category: 'financial',
        dimensions: { h: 300, w: 500 },
        displayName: Translations.widgets.utility_bill_pie_chart_header,
        icon: IconNames.PIE_CHART,
    },
    dependencies: ['project', 'theme', 'financialTokens'],
});
