import { BBox } from 'geojson';
import GoogleMapReact from 'google-map-react';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useSelector } from 'react-redux';

import * as analytics from 'reports/analytics';
import * as config from 'reports/config';
import * as ws from 'reports/models/weather_source';
import { ProspectorBounds } from 'reports/static/map_bounds/prospector';
import { PsmBounds } from 'reports/static/map_bounds/psm';
import { IAppState } from 'reports/types';
import { GCoord, GLatLngLiteral } from 'reports/utils/maps/geocode';

import { ClusterGoogleMapReact } from './ClusterGoogleMapReact';
import WeatherSourceInfo from './WeatherSourceInfo';
import { WeatherSourceMarker } from './WeatherSourceMarker';

import * as styles from 'reports/styles/styled-components';
import { getFakeSatelliteSource } from '../utils';
const styled = styles.styled;

// Hack to not show the info window (tooltip) close button, since it's hoverable
const GoogleMapContainer = styled.div<{ showCloseButton: boolean; padCloseButton?: boolean }>`
    .gm-style-iw {
        /* !important is necessary to override element styles defined by google maps */
        padding: 12px !important;
        > button {
            ${(props) => (!props.showCloseButton ? 'display: none !important;' : '')}
            ${(props) =>
                props.padCloseButton
                    ? `
                margin-right: 8px !important;
                margin-top: 8px !important;
            `
                    : ''}
        }
        > .gm-style-iw-d {
            overflow: auto !important;
        }
    }
    .gm-style iframe + div {
        border: none !important;
    }
`;

const PROJECT_LOCATION_MARKER_ID = 'project_location';
const FLAG_ICON = require('reports/static/flag.svg');
const GROUND_SOURCE_INFO_WINDOW_OFFSET = -17;
const SATELLITE_SOURCE_INFO_WINDOW_OFFSET = -24;
export const toGLatLng = (loc) => ({ lat: loc.latitude, lng: loc.longitude });
export enum SatelliteSourceType {
    Prospector = 'prospector',
    Psm = 'psm',
}

interface MapConfig {
    initialCenter: GLatLngLiteral;
    initialZoom: number;
}

type Props = {
    disabled?: boolean;
    initMapConfig: MapConfig;
    projectLocation?: GCoord;
    weatherSources: ws.WeatherSource[];
    onSelectSource?: (sourceId: number) => void;
    onMouseOverSource?: (sourceId?: number) => void;
    selectedSourceId?: number;
    enableClustering?: boolean;
    satelliteSourceToOutline?: SatelliteSourceType;
    infoWindowTrigger: 'hover' | 'click';
    infoWindowDetailed?: boolean;
};

const WeatherSourceMap = React.memo(
    ({
        disabled,
        initMapConfig,
        projectLocation,
        weatherSources,
        onSelectSource,
        onMouseOverSource,
        selectedSourceId,
        enableClustering,
        satelliteSourceToOutline,
        infoWindowTrigger,
        infoWindowDetailed,
    }: Props) => {
        const [mapConfig, _] = React.useState<MapConfig>(initMapConfig);
        const [ready, setReady] = React.useState<boolean>(false);
        const [bounds, setBounds] = React.useState<BBox | undefined>(undefined);
        const [zoom, setZoom] = React.useState<number>(initMapConfig?.initialZoom || 1);
        const [infoWindowSourceId, setInfoWindowSourceId] = React.useState<number | undefined>(undefined);

        const googleMapsKey = useSelector(
            (state) => config.selectors.getConfig(state as IAppState)?.google_maps_api_key || '',
        );

        const googleMaps = React.useRef<any>();
        const mapRef = React.useRef<any>();
        const infoWindow = React.useRef<any>();
        const markers = React.useRef<{ [k: string]: any }>({});

        React.useEffect(() => {
            closeInfoWindow();
            clearFlagMarker();
        }, [satelliteSourceToOutline]);

        React.useEffect(() => {
            clearMarkers();
            renderMarkers();
        }, [weatherSources, satelliteSourceToOutline]);

        React.useEffect(() => {
            renderMarkers();
        }, [selectedSourceId]);

        const getMapOptions = React.useRef<(gMaps: any) => any>((gMaps) => {
            return {
                controlSize: 24,
                mapTypeId: gMaps.MapTypeId.ROADMAP,

                mapTypeControl: true,
                // remove terrain checkbox
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
                },
                disableDoubleClickZoom: false,
                fullscreenControl: false,
                rotateControl: false,
                streetViewControl: false,
                clickableIcons: false,
            };
        });

        const _onMapLoaded = (map, gMaps) => {
            googleMaps.current = gMaps;
            mapRef.current = map;
            infoWindow.current = new gMaps.InfoWindow({
                disableAutoPan: true,
                pixelOffset: new gMaps.Size(0, GROUND_SOURCE_INFO_WINDOW_OFFSET),
            });

            map.setTilt(0);
            renderMarkers();
            setReady(true);
        };

        const onHoverOverSource = (source, position) => {
            onMouseOverSource && onMouseOverSource(source.weather_source_id);

            if (infoWindowTrigger === 'hover') {
                openInfoWindow(source, position, GROUND_SOURCE_INFO_WINDOW_OFFSET);
            }
        };

        const onClickSource = (source, position) => {
            onSelectSource && onSelectSource(source.weather_source_id);

            if (infoWindowTrigger === 'click') {
                openInfoWindow(source, position, GROUND_SOURCE_INFO_WINDOW_OFFSET);
            }
        };

        const onMouseOutSource = () => {
            onMouseOverSource && onMouseOverSource();
            if (infoWindow.current || infoWindowTrigger !== 'hover') {
                return;
            }
            closeInfoWindow();
        };

        const isSourceSelected = (source: ws.WeatherSource) => {
            return source.weather_source_id === selectedSourceId;
        };

        const renderMarkers = () => {
            if (!mapRef.current || !googleMaps.current) {
                return;
            }

            renderProjectMarker();
            renderSatelliteBorders();
        };

        const renderProjectMarker = () => {
            if (!projectLocation) {
                return;
            }

            const marker = getOrCreateMarker(PROJECT_LOCATION_MARKER_ID);

            marker.setOptions({
                map: mapRef.current,
                position: projectLocation,
                zIndex: 0,
            });
        };

        const renderSatelliteBorders = () => {
            if (!satelliteSourceToOutline) return;
            const boundsSet =
                satelliteSourceToOutline === SatelliteSourceType.Prospector ? ProspectorBounds : PsmBounds;
            // #TODO Don't use hardcoded colors, pending product design
            const boundsColor = satelliteSourceToOutline === SatelliteSourceType.Prospector ? '#FF0000' : '#0000FF';

            boundsSet.forEach((bounds, i) => {
                const markerKey = `${satelliteSourceToOutline}_${i}`;
                if (!markers.current[markerKey]) {
                    markers.current[markerKey] = new googleMaps.current.Polygon();
                }
                markers.current[markerKey].setOptions({
                    paths: bounds,
                    strokeColor: boundsColor,
                    strokeOpacity: 0.75,
                    strokeWeight: 3,
                    fillColor: boundsColor,
                    fillOpacity: 0.1,
                    clickable: true,
                });
                markers.current[markerKey].addListener('click', (e) => {
                    if (!satelliteSourceToOutline) return;

                    const fakeSource = getFakeSatelliteSource(satelliteSourceToOutline, {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                    });
                    const position = toGLatLng(fakeSource.location);
                    openInfoWindow(fakeSource, position, SATELLITE_SOURCE_INFO_WINDOW_OFFSET);

                    const flag = new googleMaps.current.Marker();
                    flag.setOptions({
                        position,
                        icon: FLAG_ICON,
                        map: mapRef,
                        zIndex: 0,
                    });
                    markers.current['satellite_flag'] = flag;
                });
                markers.current[markerKey].setMap(mapRef.current);
            });
        };

        const getOrCreateMarker = (markerId: string) => {
            if (!markers.current[markerId]) {
                markers.current[markerId] = new googleMaps.current.Marker();
            }

            return markers.current[markerId];
        };

        const clearMarkers = () => {
            Object.values(markers.current).forEach((marker: any) => marker.setMap(null));
            markers.current = {};
        };

        const clearFlagMarker = () => {
            if (!markers.current['satellite_flag']) return;
            markers.current['satellite_flag'].setMap(null);
        };

        const openInfoWindow = (source: ws.WeatherSource, position, offset: number) => {
            clearFlagMarker();

            const detailed = infoWindowDetailed;

            // googleMaps.infowindow does not accept virtual DOM nodes (ie. React.ReactNode) as content, so we
            // have to convert our react node to a real DOM node
            const staticElement = !detailed ? source.name : renderToStaticMarkup(<WeatherSourceInfo source={source} />);

            infoWindow.current.setOptions({
                content: staticElement,
                // offsets prevent the info window from directly overlapping on top of the source's marker
                pixelOffset: new googleMaps.current.Size(0, offset),
            });

            if (detailed) {
                infoWindow.current.addListener('closeclick', () => {
                    clearFlagMarker();
                });
            }

            // since googleMaps.infowindow only accepts static content, we inject onclick js after the infowindow has loaded
            infoWindow.current.addListener('domready', () => {
                const downloadButton = document.getElementById('weather-download');
                if (!downloadButton) return;
                downloadButton.onclick = () => {
                    analytics.track('weather.download', {
                        weather_source_id: source.weather_source_id,
                    });
                };
            });

            const anchor = new googleMaps.current.MVCObject();
            anchor.setOptions({
                position,
            });

            infoWindow.current.open(mapRef.current, anchor);
            setInfoWindowSourceId(source.weather_source_id);
        };

        const closeInfoWindow = (sourceId?: number) => {
            if (sourceId && sourceId !== infoWindowSourceId) return;
            if (!infoWindow.current) return;
            infoWindow.current.close(mapRef.current);
        };

        const mapStyle: React.CSSProperties = {
            ...(disabled ? { pointerEvents: 'none' } : {}),
            transition: 'opacity 0.3s ease-in-out',
            opacity: ready ? (disabled ? 0.5 : 1) : 0,
        };

        const GoogleMapReactProps = {
            bootstrapURLKeys: { key: googleMapsKey },
            options: getMapOptions.current,
            defaultCenter: mapConfig?.initialCenter,
            defaultZoom: mapConfig?.initialZoom,
            center: mapConfig?.initialCenter,
            yesIWantToUseGoogleMapApiInternals: true,
            onGoogleApiLoaded: ({ map, maps }) => _onMapLoaded(map, maps),
            style: mapStyle as any,
            onChange: ({ zoom, bounds }) => {
                setZoom(zoom);
                setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
            },
        };

        return (
            <GoogleMapContainer
                showCloseButton={infoWindowTrigger === 'click' || !!satelliteSourceToOutline}
                padCloseButton={infoWindowDetailed}
            >
                {enableClustering ? (
                    <ClusterGoogleMapReact
                        {...GoogleMapReactProps}
                        weatherSources={weatherSources}
                        selectedSourceId={selectedSourceId}
                        zoom={zoom}
                        bounds={bounds}
                        onSelectSource={onClickSource}
                        onMouseOutSource={onMouseOutSource}
                        onMouseOverSource={onHoverOverSource}
                        satelliteRegion={satelliteSourceToOutline}
                        closeInfoWindow={closeInfoWindow}
                    />
                ) : (
                    <GoogleMapReact {...GoogleMapReactProps}>
                        {!satelliteSourceToOutline &&
                            weatherSources.map((src) => (
                                <WeatherSourceMarker
                                    key={src.weather_source_id}
                                    lat={toGLatLng(src.location)['lat']}
                                    lng={toGLatLng(src.location)['lng']}
                                    isSourceSelected={isSourceSelected(src)}
                                    onClick={() => onSelectSource}
                                    onMouseOver={() => onHoverOverSource(src, toGLatLng(src.location))}
                                    onMouseOut={onMouseOutSource}
                                    closeInfoWindow={closeInfoWindow}
                                    srcId={src.weather_source_id}
                                />
                            ))}
                    </GoogleMapReact>
                )}
            </GoogleMapContainer>
        );
    },
);

export { WeatherSourceMap };
