import * as React from 'react';

import { Colors } from '@blueprintjs/core';

import Flex from 'reports/components/core/containers/Flex';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const HeaderContainer = styled.div`
    padding: 8px;
    color: ${Colors.DARK_GRAY3};
`;

interface IHeader {
    title: string;
    subtitle?: string;
}

const Header: React.SFC<IHeader> = ({ title, subtitle }) => (
    <HeaderContainer>
        <div style={{ fontSize: '16px', fontWeight: 700 }}>{title}</div>
        <div style={{ fontSize: '12px', color: Colors.DARK_GRAY3 }}>{subtitle}</div>
    </HeaderContainer>
);

interface ICategoryContainerProps {
    minimal?: boolean;
}

const CategoryContainer = styled(Flex.ContainerV)<ICategoryContainerProps>`
    ${(props) => (!props.minimal ? 'background-color: #F2F2F2' : null)};
    ${(props) => (!props.minimal ? `border: 1px solid ${Colors.LIGHT_GRAY4}` : null)};
    padding: 6px;
    margin-bottom: 10px;

    ${HeaderContainer} {
        ${(props) => (props.minimal ? `border-bottom: 1px solid ${Colors.DARK_GRAY3}` : null)};
    }
`;

interface ITemplateCategoryProps {
    title: string;
    description?: string;
    minimal?: boolean;
}

const Category: React.SFC<ITemplateCategoryProps> = (props) => {
    const { description, title, minimal = false } = props;
    return (
        <CategoryContainer minimal={minimal}>
            <Header title={title} subtitle={description} />
            {props.children}
        </CategoryContainer>
    );
};

export default Category;
