interface Callback {
    (...args: any[]): void;
}

export class PubSub<K extends string = any> {
    channels: { [k in K]: Callback[] } = {} as any;

    getChannel(actionType) {
        let channel = this.channels[actionType];

        if (channel === undefined) {
            channel = this.channels[actionType] = [];
        }

        return channel;
    }

    /**
     * subscribe to a action and return an unsubscribe function
     * @param  {String}   action
     * @param  {Callback} callback  callback on events
     * @return {Function}           unsubsribe function
     */
    subscribe(actionType: K, callback) {
        const channel = this.getChannel(actionType);
        channel.push(callback);

        return () => {
            // unsubscriber
            const idx = channel.indexOf(callback);
            if (idx !== -1) {
                channel.splice(idx, 1);
            }
        };
    }

    subscribeOnce(actionType: K, callback) {
        const unsubscribe = this.subscribe(actionType, (...args) => {
            callback(...args);
            unsubscribe();
        });
    }

    publish(actionType: K, ...context) {
        for (const listener of this.getChannel(actionType)) {
            listener(...context);
        }
    }
}

export default PubSub;
