import * as React from 'react';
import { Button, FormGroup } from '@blueprintjs/core';

import * as fmt from 'reports/utils/formatters';
import * as scen from 'reports/models/scenario';

import { FormField, NestedFields } from 'reports/components/forms';
import Section2 from 'reports/components/core/containers/Section2';
import BasicTable from 'reports/components/core/tables/BasicTable';
import { ConditionalWrapper } from 'reports/components/helpers/common';

import { keyByRackType, SUPPORTED_RACK_TYPES } from 'reports/modules/condition_sets/components/common';
import {
    CellTempModelSelect,
    DiffuseRowFields,
    SandiaRowFields,
} from 'reports/modules/condition_sets/components/CondSetEditComponents';

import {
    DiffuseHeaderFields,
    SandiaHeaderFields,
} from 'reports/modules/condition_sets/components/CondSetDisplayComponents';

interface IToggleBehavior {
    toggleBehavior: 'condition_sets' | 'profiles';
}

class CellTempParamsFields extends React.Component<
    {
        cellTempModel: scen.CellTempModel;
        onChange;
        value;
    } & IToggleBehavior
> {
    stashed = {
        sandia: { ...keyByRackType(scen.DEFAULT_CELL_TEMP_PARAMS.sandia) },
        diffuse: { ...keyByRackType(scen.DEFAULT_CELL_TEMP_PARAMS.diffuse) },
    };

    componentDidUpdate(prevProps) {
        const { cellTempModel, value, onChange, toggleBehavior } = this.props;
        // Early out b/c profiles don't need stashing: both sandia and diffuse values are stored in form state.
        if (toggleBehavior === 'profiles') return;

        if (prevProps.cellTempModel !== cellTempModel) {
            onChange(this.stashed[cellTempModel]);
            this.stashed[prevProps.cellTempModel] = value;
        }
    }

    render() {
        const { cellTempModel } = this.props;
        return SUPPORTED_RACK_TYPES.map((rackType) => (
            <NestedFields key={rackType} path={rackType}>
                <tr>
                    <td>{fmt.rackingType(rackType)}</td>
                    {cellTempModel === 'sandia' ? <SandiaRowFields /> : <DiffuseRowFields />}
                </tr>
            </NestedFields>
        ));
    }
}

class CellTempParamsTable extends React.Component<{ cellTempModel: scen.CellTempModel } & IToggleBehavior> {
    render() {
        const { cellTempModel, toggleBehavior } = this.props;
        // It's unusual to use FormField and NestedFields on the same field, but FormField allows us to
        // swap out the polymorphic object (sandia or diffuse) via onChange when the cell_temp_model changes,
        // whereas NestedFields allows the input fields to map correctly within the nested object.
        // E.g. cell_temp_parameters.rack.a
        return (
            <ConditionalWrapper
                condition={toggleBehavior === 'profiles'}
                wrapper={(children) => <NestedFields path="all_temperature_parameters">{children}</NestedFields>}
            >
                <FormField path={toggleBehavior === 'condition_sets' ? 'cell_temp_parameters' : cellTempModel}>
                    {({ value, onChange, errors }) => (
                        <>
                            <BasicTable>
                                <thead>
                                    <tr>
                                        <th>Rack Type</th>
                                        {cellTempModel === 'sandia' ? (
                                            <SandiaHeaderFields errors={errors} />
                                        ) : (
                                            <DiffuseHeaderFields errors={errors} />
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <NestedFields
                                        path={
                                            toggleBehavior === 'condition_sets' ? 'cell_temp_parameters' : cellTempModel
                                        }
                                    >
                                        <CellTempParamsFields
                                            cellTempModel={cellTempModel}
                                            onChange={onChange}
                                            value={value}
                                            toggleBehavior={toggleBehavior}
                                        />
                                    </NestedFields>
                                </tbody>
                            </BasicTable>
                            <Button
                                style={{ marginTop: 10 }}
                                icon="undo"
                                text="Restore Defaults"
                                onClick={() =>
                                    onChange({
                                        ...keyByRackType(scen.DEFAULT_CELL_TEMP_PARAMS[cellTempModel]),
                                    })
                                }
                            />
                        </>
                    )}
                </FormField>
            </ConditionalWrapper>
        );
    }
}

class ConditionSetCellTempEdit extends React.Component<IToggleBehavior> {
    render() {
        const { toggleBehavior } = this.props;
        return (
            <Section2
                title="Cell Temperature"
                subtitle="Used to determine cell temperature based on environmental factors and module irradiance"
            >
                <CellTempModelSelect
                    path="cell_temp_model"
                    label="Cell Temperature Model"
                    helperText="The Sandia Temperature Model is quantitatively derived from module performance in the field, the diffusion model is equivalent to that used in PVSyst."
                />
                <FormField path="cell_temp_model">
                    {({ value }) => (
                        <FormGroup label="Temperature Model Parameters">
                            <CellTempParamsTable cellTempModel={value} toggleBehavior={toggleBehavior} />
                        </FormGroup>
                    )}
                </FormField>
            </Section2>
        );
    }
}

export default ConditionSetCellTempEdit;
