/* tslint:disable:variable-name */

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { schema } from '../schema';
import * as s3File from '../s3file';
import * as tm from '../team';
import { IAPIQueryOpts } from '../types';
import type { PowerDevice } from './PowerDevice';

class PowerDeviceCharacterization extends BaseClass {
    power_device_characterization_id: number;

    power_device_id: number;
    power_device: PowerDevice;

    team_id: number;
    team: tm.Team;
    public: boolean;

    name: string;
    description?: string;

    // uploaded inverters will have a file
    file_id?: number;
    file?: s3File.S3File;

    efficiency_points: EfficiencyCurvePoint[];
}

interface EfficiencyCurvePoint {
    factor_1: number;
    factor_2: number;
    efficiency: number;
}

interface IFileCharacterizationForm {
    file_id: number;
}

interface ICreateCharacterizationForm {
    name: string;
    description?: string;
    public: boolean;
    file_id: number;
}

const schemaObj = schema.addObject(PowerDeviceCharacterization, 'power_device_characterization', {
    relationships: {
        team: { schema: tm.schemaObj },
    },
    defaultDeep: { power_device: '*', team: true },
});

const endpoint = ReduxEndpoint.fromSchema('/api/power_device_characterizations/', schemaObj);

interface ICharacterizationQuery extends IAPIQueryOpts {
    power_device_id?: number;
    project_id?: number | string;
}

const api = {
    index: endpoint.index<ICharacterizationQuery>(),
    // we don't want to store the result in redux, so use PassThroughConfig
    tryParse: endpoint.post<IFileCharacterizationForm>('try_parse', ReduxEndpoint.PassThroughConfig()),
    create: endpoint.post<ICreateCharacterizationForm>(),
};

const selectors = {
    all: schemaObj.selectAll,
    byId: schemaObj.selectById,
};

export default PowerDeviceCharacterization;

export { PowerDeviceCharacterization, schemaObj, api, selectors };
