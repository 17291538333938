import * as React from 'react';

import { Card } from 'reports/components/core/containers';
import Section from 'reports/components/helpers/Section';

import * as ur from 'reports/models/utility_rate';

import { IRatesConfig, ParamRatesFull } from 'reports/modules/financials/components/ParamRatesFull';
import {
    ParamRatesSeasonalDemand,
    ParamRatesSeasonalEnergy,
} from 'reports/modules/financials/components/ParamRatesSeasonal';

interface IRatesPanel {
    isEnergy: boolean;
    param: IRatesConfig;
    rates: ur.RateConfig;
    seasonalInput: ur.ISeasonalInput | null;
    onUpdate: (subpath: string, val: any) => void;
}

const RatesPanel: React.SFC<IRatesPanel> = ({ isEnergy, param, rates, seasonalInput, onUpdate }) => {
    const SeasonalEditor = isEnergy ? ParamRatesSeasonalEnergy : ParamRatesSeasonalDemand;
    return (
        <Card>
            <Section.Header style={{ fontSize: 16 }}>{isEnergy ? 'Energy' : 'Demand'} Charges</Section.Header>
            <div style={{ fontSize: 12, marginBottom: 15 }}>
                {isEnergy
                    ? 'Define seasonal energy charges for your project below.'
                    : 'Define seasonal demand charges for your project below. Peak demand is currently calculated \
                    as the highest electricity usage during a single hour period.'}
            </div>
            {rates &&
                (seasonalInput ? (
                    <SeasonalEditor
                        input={seasonalInput}
                        rates={rates}
                        onUpdate={(input) => onUpdate('seasonal_input', input)}
                    />
                ) : (
                    <ParamRatesFull
                        key={`rates-${isEnergy ? 'energy' : 'demand'}`}
                        parameter={param}
                        value={rates}
                        updateFn={(rates) => onUpdate(isEnergy ? 'energy_rates' : 'demand_rates', rates)}
                    />
                ))}
        </Card>
    );
};

export default RatesPanel;
