import { user as currentUser } from 'reports/modules/auth/';
import * as us from 'reports/models/user';

import { IPipelineModule } from 'reports/modules/financials/model/modules/types';
import { AuthErrorCode } from 'reports/modules/financials/model/pipeline';

import {
    BasicInitializeTimeSeries,
    BasicProductionSimulated,
    BasicConsumptionHourly,
    BasicDegradationSimple,
    BasicDemandProfile,
    BasicEnvironmentalMetrics,
    BasicFinancialMetrics,
} from './../modules/basic';
import {
    BasicSystemCostSimple,
    BasicSystemCostNameplate,
    BasicSystemCostPerModule,
    BasicSystemCostPerInverter,
    BasicSystemCostPerOptimizer,
    BasicReplacementCostSimple,
    BasicMaintenanceCostSimple,
} from './../modules/basicCost';
import {
    BasicFinancingCashSimple,
    BasicFinancingLoanSimple,
    BasicFinancingPPASimple,
    SCFFinancingPPA,
} from './../modules/basicFinance';
import {
    BasicIncentivesRebateSimple,
    BasicIncentivesCostSimple,
    BasicIncentivesNameplateSimple,
    BasicIncentivesProductionSimple,
    BasicApplyIncentives,
} from './../modules/basicIncentive';
import { BasicUtilityRatesSimple, BasicRatesEscalationSimple } from './../modules/basicRate';
import { BasicTaxDeductInterestSimple, BasicTaxDepreciationSimple } from './../modules/basicTax';

export enum ModuleCategory {
    Energy = 'Energy Modeling',
    Rates = 'Rates',
    Costs = 'Costs',
    Incentives = 'Incentives',
    Financing = 'Financing',
    Environmental = 'Environmental',
}

interface IModuleRegistry {
    [key: string]: {
        categoryName: ModuleCategory;
        module: IPipelineModule;
        insertable: boolean;
    };
}

export const MODULE_REGISTRY: IModuleRegistry = {
    /* Energy Modeling Modules */
    BasicInitializeTimeSeries: {
        categoryName: ModuleCategory.Energy,
        module: BasicInitializeTimeSeries,
        insertable: false,
    },
    BasicProductionSimulated: {
        categoryName: ModuleCategory.Energy,
        module: BasicProductionSimulated,
        insertable: false,
    },
    BasicConsumptionHourly: {
        categoryName: ModuleCategory.Energy,
        module: BasicConsumptionHourly,
        insertable: false,
    },
    BasicDegradationSimple: {
        categoryName: ModuleCategory.Energy,
        module: BasicDegradationSimple,
        insertable: true,
    },
    BasicDemandProfile: {
        categoryName: ModuleCategory.Energy,
        module: BasicDemandProfile,
        insertable: false,
    },

    /* Rates Modules */
    BasicUtilityRatesSimple: {
        categoryName: ModuleCategory.Rates,
        module: BasicUtilityRatesSimple,
        insertable: false,
    },
    BasicRatesEscalationSimple: {
        categoryName: ModuleCategory.Rates,
        module: BasicRatesEscalationSimple,
        insertable: true,
    },

    /* Cost Modules */
    BasicSystemCostSimple: {
        categoryName: ModuleCategory.Costs,
        module: BasicSystemCostSimple,
        insertable: true,
    },
    BasicSystemCostNameplate: {
        categoryName: ModuleCategory.Costs,
        module: BasicSystemCostNameplate,
        insertable: true,
    },
    BasicSystemCostPerModule: {
        categoryName: ModuleCategory.Costs,
        module: BasicSystemCostPerModule,
        insertable: true,
    },
    BasicSystemCostPerInverter: {
        categoryName: ModuleCategory.Costs,
        module: BasicSystemCostPerInverter,
        insertable: true,
    },
    BasicSystemCostPerOptimizer: {
        categoryName: ModuleCategory.Costs,
        module: BasicSystemCostPerOptimizer,
        insertable: true,
    },
    BasicReplacementCostSimple: {
        categoryName: ModuleCategory.Costs,
        module: BasicReplacementCostSimple,
        insertable: true,
    },
    BasicMaintenanceCostSimple: {
        categoryName: ModuleCategory.Costs,
        module: BasicMaintenanceCostSimple,
        insertable: true,
    },

    /* Incentive Modules */
    BasicIncentivesRebateSimple: {
        categoryName: ModuleCategory.Incentives,
        module: BasicIncentivesRebateSimple,
        insertable: true,
    },
    BasicIncentivesCostSimple: {
        categoryName: ModuleCategory.Incentives,
        module: BasicIncentivesCostSimple,
        insertable: true,
    },
    BasicIncentivesNameplateSimple: {
        categoryName: ModuleCategory.Incentives,
        module: BasicIncentivesNameplateSimple,
        insertable: true,
    },
    BasicIncentivesProductionSimple: {
        categoryName: ModuleCategory.Incentives,
        module: BasicIncentivesProductionSimple,
        insertable: true,
    },
    BasicApplyIncentives: {
        categoryName: ModuleCategory.Incentives,
        module: BasicApplyIncentives,
        insertable: false,
    },
    BasicTaxDepreciationSimple: {
        categoryName: ModuleCategory.Incentives,
        module: BasicTaxDepreciationSimple,
        insertable: true,
    },

    /* Financing Modules */
    BasicFinancingCashSimple: {
        categoryName: ModuleCategory.Financing,
        module: BasicFinancingCashSimple,
        insertable: true,
    },
    BasicFinancingLoanSimple: {
        categoryName: ModuleCategory.Financing,
        module: BasicFinancingLoanSimple,
        insertable: true,
    },
    BasicFinancingPPASimple: {
        categoryName: ModuleCategory.Financing,
        module: BasicFinancingPPASimple,
        insertable: true,
    },
    BasicTaxDeductInterestSimple: {
        categoryName: ModuleCategory.Financing,
        module: BasicTaxDeductInterestSimple,
        insertable: true,
    },
    BasicFinancialMetrics: {
        categoryName: ModuleCategory.Financing,
        module: BasicFinancialMetrics,
        insertable: false,
    },
    SCFFinancingPPA: {
        categoryName: ModuleCategory.Financing,
        module: SCFFinancingPPA,
        insertable: true,
    },

    /* Environmental Modules */
    BasicEnvironmentalMetrics: {
        categoryName: ModuleCategory.Environmental,
        module: BasicEnvironmentalMetrics,
        insertable: true,
    },
};

export function getEntryFromRegistry(moduleKey) {
    if (MODULE_REGISTRY[moduleKey] == null) {
        throw new Error(`No corresponding financial module for: ${moduleKey}`);
    }
    return MODULE_REGISTRY[moduleKey];
}

export function getModuleFromRegistry(moduleKey) {
    return getEntryFromRegistry(moduleKey).module;
}

export function getModuleCategory(moduleKey) {
    return getEntryFromRegistry(moduleKey).categoryName;
}

export const MODULE_CATEGORIES = [
    ModuleCategory.Energy,
    ModuleCategory.Rates,
    ModuleCategory.Costs,
    ModuleCategory.Incentives,
    ModuleCategory.Financing,
    ModuleCategory.Environmental,
];

export { IPipelineModule } from './types';

export function authorizedForModule(mod: IPipelineModule) {
    return mod.module.authenticate ? mod.module.authenticate(currentUser) === AuthErrorCode.AUTHORIZED : true;
}

export function authenticateModule(mod: IPipelineModule, user?: us.User) {
    return mod.module.authenticate ? mod.module.authenticate(user ? user : currentUser) : AuthErrorCode.AUTHORIZED;
}
