import { useContext, createContext } from 'react';

import { ReferrerType } from 'reports/analytics/ReferrerTypes';

type Context = {
    dialog?: string | null;
    interval?: string | null;
    message?: string | null;
    product?: string | null;
    referrer?: ReferrerType;
};

const BillingContext = createContext<Context>({});

const useBillingContext = () => useContext(BillingContext);

export { Context, BillingContext, useBillingContext };
