import { head } from 'lodash';

import { createRouteNodeSelector } from 'redux-router5';
import { createSelector } from 'reselect';

import { IAppState } from 'reports/types';

import * as proj from 'reports/models/project';
import * as sim from 'reports/models/simulation';
import * as up from 'reports/models/usage_profile';
import * as us from 'reports/models/usage_site';

import { getFinConfigTokens } from 'reports/modules/financials/state';
import { createDeclarativeTokens } from 'reports/modules/report/tokens/declarativeTokens';
import { IReportContext } from 'reports/modules/report/widgets';

import { METRIC_SELECTORS } from 'reports/components/ContextBarMetric';

const routeSelector = createRouteNodeSelector('');

const selectors = {
    getState: (x: IAppState) => x,
    getProjectFromProps: (_state, { project }: { project?: proj.Project }) => project,
    getLastViewedProjectId: (state: IAppState) => state.project.lastViewedProjectId,
    getLastLoadedProjectId: (state: IAppState) => state.project.lastLoadedProjectId,

    primaryProjectId: (state: IAppState) => {
        const route = routeSelector(state).route;
        if (route != null) {
            return route.params.projectId;
        }
        return undefined;
    },

    get primaryDesign() {
        return createSelector(this.getState, this.getProjectFromProps, (state, project) => {
            if (project == null) {
                return undefined;
            }
            return proj.selectors.primaryDesign(state, project, {
                field_segments: {
                    module_characterization: '*',
                    wiring_zone: '*',
                    design: '*',
                },
                wiring_zones: '*',
            });
        });
    },

    get primaryProjectFinancialTemplate() {
        return createSelector(this.getState, this.getProjectFromProps, (state, project) => {
            if (project == null) {
                return undefined;
            }
            return proj.selectors.primaryProjectFinancialTemplate(state, project);
        });
    },

    get primaryProjectFromUrl() {
        return createSelector(this.getState, this.primaryProjectId, (state, projectId) =>
            proj.selectors.byObject(state, { project_id: projectId }),
        );
    },

    get primaryScenario() {
        return createSelector(this.getState, this.getProjectFromProps, (state, project) => {
            if (project == null) {
                return undefined;
            }
            if (project.primary_scenario != null) {
                return project.primary_scenario;
            }
            return proj.selectors.primaryScenario(state, project);
        });
    },

    get primarySimulation() {
        return createSelector(this.getState, this.primaryDesign, this.primaryScenario, (state, design, scenario) => {
            if (design == null || scenario == null) {
                return undefined;
            }

            return head(
                sim.selectors.all(state, {
                    filter: ({ design_id, scenario_id }) =>
                        design_id === design.design_id && scenario_id === scenario.scenario_id,
                }),
            );
        });
    },

    get getFinConfigTokensFromProject() {
        return createSelector(this.primaryProjectFinancialTemplate, (state) => state.financial, getFinConfigTokens);
    },

    get metricTokens() {
        return createSelector(
            this.getFinConfigTokensFromProject,
            this.primaryDesign,
            this.primarySimulation,
            (financialTokens, design, simulation) => {
                const context = { simulation, design, financialTokens };
                return createDeclarativeTokens(context as IReportContext, {}, METRIC_SELECTORS);
            },
        );
    },

    get getProjectNearbyUsageSites() {
        return createSelector(
            us.selectors.all, // this populates the usage_site relationship for usageProfile.usage_site_id
            (state: IAppState) => state.project.nearbyUsageSites.map((site) => site.usage_site_id),
            (usageSites, projectSiteIds) => usageSites.filter((site) => projectSiteIds.includes(site.usage_site_id)),
        );
    },

    get usageSites() {
        return createSelector(
            this.getProjectNearbyUsageSites,
            (_state: IAppState, { usageProfile }: { usageProfile?: up.UsageProfile }) => usageProfile,
            (nearbyUsageSites, usageProfile) => {
                return nearbyUsageSites.length === 0 && usageProfile && usageProfile.usage_site
                    ? [usageProfile.usage_site]
                    : nearbyUsageSites;
            },
        );
    },
};

export const getModuleResults = (state: IAppState, simulationId: number | null | undefined) => {
    if (simulationId == null) {
        return null;
    }
    return state.project.moduleLevelResults[simulationId];
};

export const getFieldComponents = (state: IAppState, designId: number | null | undefined) => {
    if (designId == null) {
        return null;
    }
    return state.project.fieldComponents[designId];
};

export const getOptimalPoaData = (state: IAppState, projectId: number | null | undefined) => {
    if (projectId == null) {
        return null;
    }
    return state.project.optimalPoaData[projectId];
};

export default selectors;
