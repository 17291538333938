import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Translations from 'reports/localization/strings';
import { IReportContext, IWidgetRenderProps, registerWidget } from 'reports/modules/report/widgets';
import * as fmt from 'reports/utils/formatters';
import { Energy, FormattedNumber, Percent, Power } from 'reports/components/core/numbers';
import { WidgetDetailsTable, TooltipContent } from 'reports/modules/report/components/helpers';
import { user } from 'reports/angular-bridge';

const fallbackFormatter = (Formatter, fallback = '-') => {
    return (value, precision) => {
        if (value == null) {
            return fallback;
        }
        return <Formatter value={value} precision={precision} />;
    };
};

const powerFormatter = fallbackFormatter(Power);
const energyFormatter = fallbackFormatter(Energy);
const numberFormatter = fallbackFormatter(FormattedNumber);
const percentFormatter = fallbackFormatter(Percent);
const chunkFormatter = (val) => (val != null ? fmt.chunk(val) : '-');

type IContext = Pick<IReportContext, 'design' | 'simulation'>;
const SystemMetricsTable: React.SFC<IWidgetRenderProps<object, IContext>> = ({ context, className }) => {
    const { design, simulation } = context;

    const DCNameplateTooltipContent = (
        <TooltipContent>
            <FormattedMessage {...Translations.design.dc_nameplate_tooltip} />
        </TooltipContent>
    );
    const ACNameplateTooltipContent = (
        <TooltipContent>
            <FormattedMessage {...Translations.design.ac_nameplate_tooltip} />
        </TooltipContent>
    );
    const annualProductionTooltipContent = (
        <TooltipContent>
            <FormattedMessage {...Translations.simulation.annual_production_tooltip} />
        </TooltipContent>
    );
    const performanceRatioTooltipContent = (
        <TooltipContent>
            <FormattedMessage {...Translations.simulation.perf_ratio_tooltip} />
        </TooltipContent>
    );
    const outputEnergyRatioTooltipContent = (
        <TooltipContent>
            <FormattedMessage {...Translations.simulation.output_ratio_tooltip} />
        </TooltipContent>
    );
    const weatherDatasetTooltipContent = (
        <TooltipContent>
            <FormattedMessage {...Translations.conditions.weather_dataset_tooltip} />
        </TooltipContent>
    );
    const p90EnergyRatioTooltipContent = (
        <TooltipContent>
            <FormattedMessage {...Translations.simulation.p90_tooltip} />
        </TooltipContent>
    );

    const loadRatio = design.field_component_metadata.nameplate / design.field_component_metadata.ac_nameplate;
    const gridPower = get(simulation, 'metadata.grid_power');
    const poaIrradiance = get(simulation, 'metadata.poa_irradiance');
    const weatherDataset = get(simulation, 'scenario.weather_dataset');
    const sketchupModel = get(simulation, 'scenario.sketchup_model');
    const simulationVersion = get(simulation, 'simulation_metadata.version');
    const performanceRatio =
        gridPower != null && poaIrradiance != null
            ? gridPower / ((poaIrradiance * design.field_component_metadata.nameplate) / 1000)
            : null;
    const outputEnergyRatio = gridPower != null ? gridPower / design.field_component_metadata.nameplate : null;
    const p90EnergyRatio =
        weatherDataset != null &&
        weatherDataset.weather_source.ghi_std != null &&
        weatherDataset.weather_source.ghi_mean != null &&
        outputEnergyRatio != null
            ? outputEnergyRatio *
              (1 - (weatherDataset.weather_source.ghi_std / weatherDataset.weather_source.ghi_mean) * 1.282)
            : null;
    return (
        <WidgetDetailsTable className={className}>
            <tbody>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.project.design} />
                    </th>
                    <td>{design.description}</td>
                </tr>
                <tr>
                    <th>
                        <Tooltip content={DCNameplateTooltipContent}>
                            <FormattedMessage {...Translations.design.module_dc_nameplate} />
                        </Tooltip>
                    </th>
                    <td>{powerFormatter(design.field_component_metadata.nameplate, 1)}</td>
                </tr>
                <tr>
                    <th>
                        <Tooltip content={ACNameplateTooltipContent}>
                            <FormattedMessage {...Translations.design.inv_ac_nameplate} />
                        </Tooltip>
                    </th>
                    <td>
                        {powerFormatter(design.field_component_metadata.ac_nameplate, 1)}
                        <br />
                        Load Ratio: {numberFormatter(loadRatio, 2)}
                    </td>
                </tr>
                <tr>
                    <th>
                        <Tooltip content={annualProductionTooltipContent}>
                            <FormattedMessage {...Translations.simulation.annual_production} />
                        </Tooltip>
                    </th>
                    <td>{energyFormatter(gridPower, 1)}</td>
                </tr>
                <tr>
                    <th>
                        <Tooltip content={performanceRatioTooltipContent}>
                            <FormattedMessage {...Translations.simulation.perf_ratio} />
                        </Tooltip>
                    </th>
                    <td>{percentFormatter(performanceRatio, 1)}</td>
                </tr>
                <tr>
                    <th>
                        <Tooltip content={outputEnergyRatioTooltipContent}>
                            <FormattedMessage {...Translations.simulation.kwh_kwp} />
                        </Tooltip>
                    </th>
                    <td>{numberFormatter(outputEnergyRatio, 1)}</td>
                </tr>
                {user.hasFeature('p90') && p90EnergyRatio && (
                    <tr>
                        <th>
                            <Tooltip content={p90EnergyRatioTooltipContent}>
                                <FormattedMessage {...Translations.simulation.p90} />
                            </Tooltip>
                        </th>
                        <td>{numberFormatter(p90EnergyRatio, 1)}</td>
                    </tr>
                )}
                {weatherDataset != null && (
                    <tr>
                        <th>
                            <Tooltip content={weatherDatasetTooltipContent}>
                                <FormattedMessage {...Translations.conditions.weather_dataset} />
                            </Tooltip>
                        </th>
                        <td>
                            {weatherDataset.name},&nbsp;
                            {weatherDataset.weather_source.name},&nbsp;
                            {weatherDataset.weather_source.source}&nbsp; ({weatherDataset.weather_source.source_type})
                        </td>
                    </tr>
                )}
                {sketchupModel != null && (
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.conditions.near_shade_profile} />
                        </th>
                        <td>{sketchupModel.name}</td>
                    </tr>
                )}
                {simulationVersion != null && (
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.simulation.simulator_version} />
                        </th>
                        <td>{chunkFormatter(simulationVersion)}</td>
                    </tr>
                )}
            </tbody>
        </WidgetDetailsTable>
    );
};

export const SystemMetricsTableWidget = registerWidget('system_metrics_table', {
    Component: SystemMetricsTable,
    metadata: {
        category: 'project',
        dimensions: { h: 500, w: 300 },
        displayName: Translations.widgets.system_metrics_table_header,
        icon: IconNames.TH,
    },
    dependencies: ['design', 'simulation'],
});

export default SystemMetricsTableWidget;
