import _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconNames } from '@blueprintjs/icons';
import Translations from 'reports/localization/strings';

import { TOKEN_FUNCTIONS } from 'reports/modules/financials/model/tokens';
import { hasOutput } from 'reports/modules/financials/state';
import { registerWidget, IWidgetRenderProps, IReportContext } from 'reports/modules/report/widgets';
import { WidgetDataTable } from 'reports/modules/report/components/helpers';

import { Currency, FormattedNumber } from 'reports/components/core/numbers';

const currency = (val) => (val === 0 || val == null ? '-' : <Currency value={val} precision={0} />);
const energy = (val) => (val == null ? '-' : <FormattedNumber value={val} precision={1} />);

type IProps = IWidgetRenderProps<object, Pick<IReportContext, 'financialTokens'>>;
const CashFlowTable: React.FC<IProps> = ({ context, className }) => {
    const { financialTokens } = context;

    const rows = [] as any[];

    if (hasOutput(financialTokens)) {
        const cashFlow = TOKEN_FUNCTIONS.cash_flow_yearly(financialTokens);
        const cumCashFlow = TOKEN_FUNCTIONS.cumulative_cash_flow_yearly(financialTokens);
        const energyValue = TOKEN_FUNCTIONS.energy_value_yearly(financialTokens);

        for (let i = 0; i < cashFlow.length; i += 1) {
            rows.push({
                year: i,
                energyValue: energyValue[i],
                cashFlow: cashFlow[i],
                cumCashFlow: cumCashFlow[i],
            });
        }
    }

    return (
        <WidgetDataTable className={className}>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.general.year} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.simulation.energy} /> (kWh)
                    </th>
                    <th>
                        <FormattedMessage {...Translations.financial.cash_flow} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.financial.cumulative_cash_flow} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {rows.map((i) => (
                    <tr key={i.year}>
                        <td>{i.year}</td>
                        <td>{energy(i.energyValue)}</td>
                        <td>{currency(i.cashFlow)}</td>
                        <td>{currency(i.cumCashFlow)}</td>
                    </tr>
                ))}
            </tbody>
            <thead>
                <tr className="summary">
                    <th></th>
                    <th>{energy(_.sumBy(rows, 'energyValue'))}</th>
                    <th>{currency(_.sumBy(rows, 'cashFlow'))}</th>
                    <th>{currency(_.sumBy(rows, 'cashFlow'))}</th>
                </tr>
            </thead>
        </WidgetDataTable>
    );
};

export const CashFlowTableWidget = registerWidget('cash_flow_table', {
    Component: CashFlowTable,
    metadata: {
        category: 'financial',
        dimensions: { h: 800, w: 300 },
        displayName: Translations.widgets.cash_flow_table_header,
        icon: IconNames.TH,
    },
    dependencies: ['financialTokens'],
});

export default CashFlowTableWidget;
