import React from 'react';

import { generatePath, useNavigate } from 'react-router-dom';

import { ProfileLibrary } from 'reports/modules/profile/components/ProfileLibrary';

const Profiles = () => {
    const navigate = useNavigate();

    const navigateToProfile = (profileId: number) =>
        navigate({
            pathname: generatePath('/profiles/:profileId/edit', { profileId: profileId.toString() }),
        });

    const navigateToProfilePreview = (profileId: number) =>
        navigate({
            pathname: generatePath('/profiles/:profileId/preview', { profileId: profileId.toString() }),
        });

    return (
        <ProfileLibrary
            inlineControls={true}
            navigateToProfile={navigateToProfile}
            navigateToProfilePreview={navigateToProfilePreview}
        />
    );
};

export { Profiles };
