import * as React from 'react';
import { goToLogin } from './common';
import { Text, Button } from '@aurorasolar/ds';

const ForgotPasswordMessage = () => (
    <>
        <Text>
            If the email you provided is associated with a HelioScope account, you will receive an email with
            instructions to reset your password.
        </Text>
        <Text>
            If you didn't receive an email from us, please check your spam folder and ensure that the email you provided
            is associated with an account.
        </Text>
        <Button variant="text" action={goToLogin}>
            Back to login
        </Button>
    </>
);

export { ForgotPasswordMessage };
