const COLOR_NAMES = {
    aliceblue: '#f0f8ff',
    antiquewhite: '#faebd7',
    aqua: '#00ffff',
    aquamarine: '#7fffd4',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    bisque: '#ffe4c4',
    black: '#000000',
    blanchedalmond: '#ffebcd',
    blue: '#0000ff',
    blueviolet: '#8a2be2',
    brown: '#a52a2a',
    burlywood: '#deb887',
    cadetblue: '#5f9ea0',
    chartreuse: '#7fff00',
    chocolate: '#d2691e',
    coral: '#ff7f50',
    cornflowerblue: '#6495ed',
    cornsilk: '#fff8dc',
    crimson: '#dc143c',
    cyan: '#00ffff',
    darkblue: '#00008b',
    darkcyan: '#008b8b',
    darkgoldenrod: '#b8860b',
    darkgray: '#a9a9a9',
    darkgreen: '#006400',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
    darkorange: '#ff8c00',
    darkorchid: '#9932cc',
    darkred: '#8b0000',
    darksalmon: '#e9967a',
    darkseagreen: '#8fbc8f',
    darkslateblue: '#483d8b',
    darkslategray: '#2f4f4f',
    darkturquoise: '#00ced1',
    darkviolet: '#9400d3',
    deeppink: '#ff1493',
    deepskyblue: '#00bfff',
    dimgray: '#696969',
    dodgerblue: '#1e90ff',
    firebrick: '#b22222',
    floralwhite: '#fffaf0',
    forestgreen: '#228b22',
    fuchsia: '#ff00ff',
    gainsboro: '#dcdcdc',
    ghostwhite: '#f8f8ff',
    gold: '#ffd700',
    goldenrod: '#daa520',
    gray: '#808080',
    green: '#008000',
    greenyellow: '#adff2f',
    honeydew: '#f0fff0',
    hotpink: '#ff69b4',
    indianred: '#cd5c5c',
    indigo: '#4b0082',
    ivory: '#fffff0',
    khaki: '#f0e68c',
    lavender: '#e6e6fa',
    lavenderblush: '#fff0f5',
    lawngreen: '#7cfc00',
    lemonchiffon: '#fffacd',
    lightblue: '#add8e6',
    lightcoral: '#f08080',
    lightcyan: '#e0ffff',
    lightgoldenrodyellow: '#fafad2',
    lightgrey: '#d3d3d3',
    lightgreen: '#90ee90',
    lightpink: '#ffb6c1',
    lightsalmon: '#ffa07a',
    lightseagreen: '#20b2aa',
    lightskyblue: '#87cefa',
    lightslategray: '#778899',
    lightsteelblue: '#b0c4de',
    lightyellow: '#ffffe0',
    lime: '#00ff00',
    limegreen: '#32cd32',
    linen: '#faf0e6',
    magenta: '#ff00ff',
    maroon: '#800000',
    mediumaquamarine: '#66cdaa',
    mediumblue: '#0000cd',
    mediumorchid: '#ba55d3',
    mediumpurple: '#9370d8',
    mediumseagreen: '#3cb371',
    mediumslateblue: '#7b68ee',
    mediumspringgreen: '#00fa9a',
    mediumturquoise: '#48d1cc',
    mediumvioletred: '#c71585',
    midnightblue: '#191970',
    mintcream: '#f5fffa',
    mistyrose: '#ffe4e1',
    moccasin: '#ffe4b5',
    navajowhite: '#ffdead',
    navy: '#000080',
    oldlace: '#fdf5e6',
    olive: '#808000',
    olivedrab: '#6b8e23',
    orange: '#ffa500',
    orangered: '#ff4500',
    orchid: '#da70d6',
    palegoldenrod: '#eee8aa',
    palegreen: '#98fb98',
    paleturquoise: '#afeeee',
    palevioletred: '#d87093',
    papayawhip: '#ffefd5',
    peachpuff: '#ffdab9',
    peru: '#cd853f',
    pink: '#ffc0cb',
    plum: '#dda0dd',
    powderblue: '#b0e0e6',
    purple: '#800080',
    red: '#ff0000',
    rosybrown: '#bc8f8f',
    royalblue: '#4169e1',
    saddlebrown: '#8b4513',
    salmon: '#fa8072',
    sandybrown: '#f4a460',
    seagreen: '#2e8b57',
    seashell: '#fff5ee',
    sienna: '#a0522d',
    silver: '#c0c0c0',
    skyblue: '#87ceeb',
    slateblue: '#6a5acd',
    slategray: '#708090',
    snow: '#fffafa',
    springgreen: '#00ff7f',
    steelblue: '#4682b4',
    tan: '#d2b48c',
    teal: '#008080',
    thistle: '#d8bfd8',
    tomato: '#ff6347',
    turquoise: '#40e0d0',
    violet: '#ee82ee',
    wheat: '#f5deb3',
    white: '#ffffff',
    whitesmoke: '#f5f5f5',
    yellow: '#ffff00',
    yellowgreen: '#9acd32',
};


function colorNameToHex(colorName) {
    return COLOR_NAMES[colorName.toLowerCase()] || '#000000';
}

function parseHex(hexColor) {
    let hexRed;
    let hexGreen;
    let hexBlue;

    if (hexColor.length === 4) {
        hexRed = hexColor.substring(1, 2);
        hexGreen = hexColor.substring(2, 3);
        hexBlue = hexColor.substring(3, 4);
        hexRed += hexRed;
        hexGreen += hexGreen;
        hexBlue += hexBlue;
    } else {
        hexRed = hexColor.substring(1, 3);
        hexGreen = hexColor.substring(3, 5);
        hexBlue = hexColor.substring(5, 7);
    }

    const red = parseInt(hexRed, 16);
    const green = parseInt(hexGreen, 16);
    const blue = parseInt(hexBlue, 16);

    return { red, green, blue };
}

// converts css colors to rgb (no alpha channel)
// convertColorToRGB('red')
// convertColorToRGB('rgba(1,0,0,1)')
// convertColorToRGB('#ff0000')
export function convertColorToRGB(color = '#000000') {
    if (color.substring(0, 4) === 'rgba') {
        const parenSplit = color.split(/\(|\)/);
        const commaSplit = parenSplit[1].split(',');
        return `rgba(${commaSplit[0]},${commaSplit[1]},${commaSplit[2]})`;
    }
    const hexColor = color[0] === '#' ? color : colorNameToHex(color);
    return hexColor;
}

export function convertColorAndOpacity(color = '#000000', alpha = 1.0) {
    if (color.substring(0, 4) === 'rgba') {
        return color;
    }
    const hexColor = color[0] === '#' ? color : colorNameToHex(color);
    const { red, green, blue } = parseHex(hexColor);

    return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function convertHexColorToComputedRGB(color = '#000000') {
    const hexColor = color[0] === '#' ? color : colorNameToHex(color);
    const { red, green, blue } = parseHex(hexColor);
    return `rgb(${red}, ${green}, ${blue})`;
}


// https://github.com/optimisme/javascript-temperatureMap/blob/master/temperatureMap.js#L57
export function hslToRGB(hue, sat, lum, alpha) {
    let red;
    let green;
    let blue;

    if (sat === 0) {
        red = green = blue = lum;
    } else {
        const q = lum < 0.5 ? lum * (1 + sat) : lum + sat - lum * sat; // eslint-disable-line id-length
        const p = 2 * lum - q; // eslint-disable-line id-length
        red = hue2RGB(p, q, hue + 0.33);
        green = hue2RGB(p, q, hue);
        blue = hue2RGB(p, q, hue - 0.33);
    }

    return `rgba(${(red * 255) | 0}, ${(green * 255) | 0}, ${(blue * 255) | 0}, ${alpha})`;
}

function hue2RGB(p, q, t_) { // eslint-disable-line id-length
    let t = t_; // eslint-disable-line id-length
    if (t < 0) {
        t += 1;
    } else if (t > 1) {
        t -= 1;
    }

    if (t >= 0.66) {
        return p;
    } else if (t >= 0.5) {
        return p + (q - p) * (0.66 - t) * 6;
    } else if (t >= 0.33) {
        return q;
    }
    return p + (q - p) * 6 * t;
}
