import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import { Switch } from '@blueprintjs/core';

import { Project } from 'reports/models/project';
import * as utility from 'reports/models/utility_rate';
import { IAppState } from 'reports/types';

import { isSubstr } from 'reports/utils/strings';
import * as auth from 'reports/modules/auth';
import { actions as projActions } from 'reports/modules/project';
import { FormGroupGrid, Header } from 'reports/modules/project/common';
import ParamNumeric from 'reports/modules/financials/components/ParamNumeric';
import { ParamValueType } from 'reports/modules/financials/params';
import { Section2 } from 'reports/components/core/containers';

import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';

const FLAT_RATE = new utility.UtilityRate({
    utility_rate_id: -1,
    description: 'Flat Rate',
});

interface IOwnProps {
    project: Project;
}
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IDispatchProps & IStateProps;

class ProjectRateEditor extends React.PureComponent<IProps> {
    render() {
        const { project, patchFinancialSettings, setUtilityRate, user } = this.props;
        const { utility_rate } = project;
        const { flat_rate_kwh, rate_mode, net_metering, annual_true_up, net_export_rate_kwh } =
            project.data.financial_settings;

        const setMode = (value) => {
            if (rate_mode !== value) {
                patchFinancialSettings({ rate_mode: value });
            }
        };

        const FlatRateInput = (
            <ParamNumeric
                parameter={{
                    type: ParamValueType.Currency,
                    precision: 4,
                    min_value: 0.0,
                    max_value: 9999.0,
                    default: 0.0,
                }}
                value={flat_rate_kwh}
                updateFn={(value) => {
                    if (flat_rate_kwh !== value) {
                        patchFinancialSettings({ flat_rate_kwh: value });
                    }
                }}
            />
        );

        const NetExportRateInput = (
            <ParamNumeric
                parameter={{
                    type: ParamValueType.Currency,
                    precision: 4,
                    min_value: 0.0,
                    max_value: 9999.0,
                    default: 0.0,
                }}
                value={net_export_rate_kwh}
                updateFn={(value) => {
                    if (net_export_rate_kwh !== value) {
                        patchFinancialSettings({ net_export_rate_kwh: value });
                    }
                }}
                disabled={!annual_true_up}
            />
        );

        const NetMeteringSwitch = (
            <Switch
                large
                checked={net_metering}
                onChange={() =>
                    patchFinancialSettings({
                        net_metering: !net_metering,
                        annual_true_up: false,
                    })
                }
                style={{ margin: '8px 2px' }}
            />
        );
        const AnnualTrueUpSwitch = (
            <Switch
                large
                checked={annual_true_up}
                onChange={() => patchFinancialSettings({ annual_true_up: !annual_true_up })}
                style={{ margin: '8px 2px' }}
                disabled={!net_metering}
            />
        );
        return (
            <Section2
                title="Utility Rate"
                contextEl={
                    <BasicSelect<utility.UtilityRate>
                        dataSource={{
                            async: true,
                            query: async (q) => {
                                const rates = await this.props.getRates({
                                    description: q,
                                    limit: 20,
                                    detail: false,
                                });

                                if (isSubstr(FLAT_RATE.description, q)) {
                                    rates.unshift(FLAT_RATE);
                                }

                                return rates;
                            },
                        }}
                        itemRenderer={(rate: utility.UtilityRate) => ({
                            key: rate.utility_rate_id,
                            text: rate.description,
                            label: rate.public ? '(Public)' : '',
                        })}
                        value={rate_mode === 'FLAT_KWH' ? FLAT_RATE : utility_rate || null}
                        onChange={(rate) => {
                            if (rate.utility_rate_id === -1) {
                                setMode('FLAT_KWH');
                            } else {
                                setMode('UTILITY_SIMPLE');
                                setUtilityRate(rate);
                            }
                        }}
                        noneSelected="No default rate"
                    />
                }
            >
                {rate_mode === 'FLAT_KWH' ? (
                    <FormGroupGrid>
                        <Header>Flat per kWh rate</Header>
                        {FlatRateInput}
                        <div>$ / kWh</div>

                        <Header>Apply Net Metering</Header>
                        {NetMeteringSwitch}
                        <div>Apply credits for surplus energy exported to the grid</div>

                        {user?.hasFeature('annual_true_up') && (
                            <>
                                <Header>Annual True-Up Period</Header>
                                {AnnualTrueUpSwitch}
                                <div>Reconcile net metering credits every 12 months</div>

                                <Header>Net export rate</Header>
                                {NetExportRateInput}
                                <div>Rate at which surplus energy is exported at end of true-up period ($ / kWh)</div>
                            </>
                        )}
                    </FormGroupGrid>
                ) : null}
            </Section2>
        );
    }
}

const mapStateToProps = (state: IAppState, _ownProps: IOwnProps) => ({
    user: auth.selectors.getUser(state),
});

const mapDispatchToProps = bindActions(({ project }) => ({
    setUtilityRate: (rate) => projActions.setUtilityRate(project, rate),
    patchFinancialSettings: (patch) => projActions.patchFinancialSettings(project, patch),
    getRates: utility.api.index,
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRateEditor);
