import { Intent } from '@blueprintjs/core';
import * as React from 'react';

import { IntentContainer } from 'reports/components/helpers/errors';

type Props = {
    perfectMatchExists: boolean;
    isPublicNonAdmin: boolean;
};

const ModuleUploadHelperText = ({ perfectMatchExists, isPublicNonAdmin }: Props) => {
    if (isPublicNonAdmin) {
        return <IntentContainer intent={Intent.DANGER}>Cannot upload to a public module.</IntentContainer>;
    }
    if (perfectMatchExists) {
        return <IntentContainer intent={Intent.SUCCESS}>Matches an existing module</IntentContainer>;
    }
    return (
        <IntentContainer intent={Intent.WARNING}>Select an existing module that your PAN file matches.</IntentContainer>
    );
};

export { ModuleUploadHelperText };
