import { isNaN } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import * as fmt from 'reports/utils/formatters';
import { Unit } from 'reports/utils/units';

type Formatter = (val: any, options: fmt.INumberOptions) => React.ReactNode;

const NUMBER_FORMATTERS: { [k in Unit]?: Formatter } = {
    [Unit.Currency]: (val, opts) => fmt.currency(val, { displayOnly: opts.precision == null, ...opts }),
    [Unit.Date]: (val) => `${moment(val).format('MMMM DD, YYYY')}`,
    [Unit.Energy]: (val, { precision = 2, ...opts }) => fmt.humanizeEnergy(val, { precision, ...opts }),
    [Unit.Number]: (val, { precision = 2, ...opts }) => fmt.stringifyNumber(val, { precision, ...opts }),
    [Unit.Percent]: (val, { precision = 1, ...opts }) => fmt.percentage(val, { precision, ...opts }),
    [Unit.Power]: (val, { precision = 2, ...opts }) => fmt.humanizeWatts(val, { precision, ...opts }),
    [Unit.Voltage]: (val, options) => fmt.voltage(val, options),
    [Unit.Current]: (val, options) => fmt.current(val, options),
    [Unit.Resistance]: (val, options) => fmt.resistance(val, options),
    [Unit.Irradiance]: (val, options) => fmt.irradiance(val, options),
    [Unit.Angle]: (val, options) => fmt.angle(val, options),
    [Unit.Temperature]: (val, { precision = 1, ...opts }) => fmt.temperature(val, { precision, ...opts }),
    // TODO: default to the user's preferred distance unit (via auth.selectors.formatters.distance)
    [Unit.Distance]: (meters, options) => fmt.distance(meters, options as fmt.IDistanceOptions),
    [Unit.Duration]: (val, options) => fmt.duration(val, options as fmt.IDurationOptions),
};

export interface INumber {
    value: number;
    precision?: fmt.Precision;
}

interface IFormattedNumber extends INumber {
    unit?: Unit;
    intl: IntlShape;
    subUnit?: string;
}

class _FormattedNumber extends React.PureComponent<IFormattedNumber> {
    render() {
        const {
            value,
            unit = Unit.Number,
            precision,
            intl: { locale },
            subUnit,
        } = this.props;

        if (isNaN(value)) {
            throw Error('Invalid value - must be a number');
        }

        const fmt = NUMBER_FORMATTERS[unit];
        if (fmt) {
            return (
                <span>
                    {fmt(value, {
                        precision,
                        locale,
                        ...(subUnit ? { unit: subUnit } : undefined),
                    })}
                </span>
            );
        }

        return value;
    }
}

export const FormattedNumber = injectIntl(_FormattedNumber);
export default FormattedNumber;
