import { Switch } from '@blueprintjs/core';
import * as React from 'react';
import styled from 'styled-components';

import FormField from 'reports/components/forms/FormField';

const StyledSwitch = styled(Switch)`
    margin-bottom: 0;
`;

interface ISwitchInputProps {
    path: string;
    switchLabel?: string;
    disabled?: boolean;
}

// TODO: migrate to using withForm higher-order component
/**
 * A simple toggle switch input meant to exist within a Form that provides a FormContext.
 * The API for this is not fully baked, hence it's in the /experimental dir.
 */
const FormBareSwitchInput: React.FC<ISwitchInputProps> = (props) => {
    const { path, switchLabel, disabled } = props;
    return (
        <FormField path={path}>
            {({ onChange, value, form }) => (
                <StyledSwitch
                    checked={value}
                    label={switchLabel}
                    onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
                    disabled={disabled || form.submitting}
                    large={true}
                />
            )}
        </FormField>
    );
};

export default FormBareSwitchInput;
