/* tslint:disable:variable-name */
import moment from 'moment';

import { ReduxEndpoint, BaseClass, createAsyncSaver, defaults, ensureProperty } from 'reports/utils/api';

import { schema } from './schema';

import * as team from './team';
import { IAPIQueryOpts } from './types';
import * as user from './user';

class FinancialTemplate extends BaseClass {
    financial_template_id: number;

    team_id: number;
    team?: team.Team;

    description: string;
    latest_version_id: number;
    data: any;
    public: boolean;

    team_settings: FinancialModelTeam;

    created?: moment.Moment;
    last_modified?: moment.Moment;

    creator_id?: number;
    creator?: user.User;
    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    soft_deleted?: boolean;

    constructor(data) {
        super(FinancialTemplate.deserializer(data));
    }

    bookmarked = () => this.team_settings.bookmarked;

    static deserializer = BaseClass.getDeserializer({
        created: (x) => moment(x),
        last_modified: (x) => moment(x),
        team_settings: ensureProperty<FinancialModelTeam>(
            (teamData, { financial_template_id }) => new FinancialModelTeam({ financial_template_id, ...teamData }),
        ),
    });

    toString() {
        return this.description;
    }
}

class FinancialModelTeam extends BaseClass {
    financial_template_id: number;
    team_id?: number;
    bookmarked: boolean;

    constructor(data) {
        super(FinancialModelTeam.deserializer(data));
    }

    static deserializer = defaults({
        bookmarked: false,
    });
}

const schemaObj = schema.addObject(FinancialTemplate, 'financial_template', {
    relationships: {
        creator: { schema: user.schemaObj },
        last_modified_by_user: { schema: user.schemaObj },
        team: { schema: team.schemaObj },
    },
});

export interface IFinancialTemplateForm {
    team_id?: number;

    description: string;
    data: any;
    public?: boolean;
}

interface IFinancialModelAPIQueryOpts extends IAPIQueryOpts {
    description?: string;
    public?: boolean;
    team_id?: number;
}

const endpoint = ReduxEndpoint.fromSchema('/api/financial_templates/', schemaObj, {
    deepSelect: {
        creator: true,
        last_modified_by_user: true,
        team: true,
    },
});

const api = {
    index: endpoint.index<IFinancialModelAPIQueryOpts>(),
    get: endpoint.get<{ financial_template_id: number | string }>('{financial_template_id}'),
    create: endpoint.post<IFinancialTemplateForm>(),
    save: endpoint.put('{financial_template_id}'),
    delete: endpoint.delete('{financial_template_id}'),

    patchTeamSettings: endpoint.patch<Partial<FinancialModelTeam>, Partial<FinancialModelTeam>>(
        '{financial_template_id}/team_settings',
        {
            onSuccess: (rawData) =>
                schemaObj.dataLoaded({
                    financial_template_id: rawData.financial_template_id,
                    team_settings: rawData,
                }),
        },
    ),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

const saver = createAsyncSaver(schemaObj, api.save, -1);

export { FinancialTemplate, FinancialModelTeam, api, saver, schemaObj, selectors };
