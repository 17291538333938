import { noop, isEqual } from 'lodash';

import Logger from 'js-logger';
import * as React from 'react';

import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTarget, DropTargetSpec, DropTargetConnector, DropTargetMonitor } from 'react-dnd';

import { IDragItem, ILayoutFileCallback, Component, ILayoutContext } from './types';
import SnapIndex from './SnapIndex';

const logger = Logger.get('layout');

export const { Provider: LayoutRegionProvider, Consumer: LayoutRegionConsumer } = React.createContext<ILayoutContext>({
    scale: 1,
    onChange: noop,
    snapIndex: new SnapIndex([]),
    regionKey: 1,
});

interface ILayoutRegionProps extends ILayoutContext, React.PropsWithChildren<{}> {
    className?: string;
    style?: React.CSSProperties;
    onFileDrop?: ILayoutFileCallback;
    scaledNodeRef?: any;
    onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
    regionKey: string | number;
    width: number;
    height: number;
}

interface IState {
    childWidth?: number;
    childHeight?: number;
}

type RegionDropCallback = (props: ILayoutRegionProps, monitor: DropTargetMonitor, component: LayoutRegion) => void;

function getDropPosition(monitor: DropTargetMonitor, component: LayoutRegion) {
    // if moving an object, use the top left of the root node, for a file, use where the mouse is
    const offset = monitor.getSourceClientOffset() || monitor.getClientOffset();
    const rect = component.node.getBoundingClientRect();

    return {
        x: (offset.x - rect.left) / component.props.scale,
        y: (offset.y - rect.top) / component.props.scale,
    };
}

const handleComponentDrop: RegionDropCallback = ({ onChange, scale = 1 }, monitor, component: LayoutRegion) => {
    if (monitor.getItemType() !== Component) return; // resize widgets handle their own dragEnd

    const item = monitor.getItem() as IDragItem;

    const dropPosition = getDropPosition(monitor, component);
    const move = {
        x: dropPosition.x - item.layout.x,
        y: dropPosition.y - item.layout.y,
    };

    const { result: newLayout } = item.snapIndex.matchMove(move, item.layout);

    onChange({
        ...item,
        scale, // the scale of the drop target page, not the item source scale
        layout: {
            ...item.layout,
            x: newLayout.x,
            y: item.regionKey === component.props.regionKey ? newLayout.y : dropPosition.y,
            // don't snap Y coords when dragging to a new page, since
            // this won't show up in the preview which assumes the pages
            // are infinite in all directions
        },
    });
};

const handleFileDrop: RegionDropCallback = ({ onFileDrop }, monitor, component) => {
    if (!onFileDrop) return; // wont happen as long as canDrop returns false correctly

    const item = monitor.getItem() as IDragItem;

    onFileDrop({
        files: item['files'],
        position: getDropPosition(monitor, component),
    });
};

const dropComponentSpec: DropTargetSpec<ILayoutRegionProps> = {
    drop: (props, monitor, component: LayoutRegion) => {
        if (monitor == null) {
            logger.warn('Bad Drop, no monitor');
            return;
        }
        const type = monitor.getItemType();

        switch (type) {
            case Component:
                handleComponentDrop(props, monitor, component);
                return;
            case NativeTypes.FILE:
                handleFileDrop(props, monitor, component);
                return;
            default:
                logger.warn(`Unknown drop type ${type}`);
                return;
        }
    },

    canDrop({ onFileDrop }, monitor) {
        if (!monitor) return false;
        if (monitor.getItemType() === NativeTypes.FILE && onFileDrop == null) {
            return false;
        }
        return true;
    },
};

const collect = (connect: DropTargetConnector, _monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    // isOver: monitor.isOver(),
    // canDrop: monitor.canDrop(),
});

class LayoutRegion extends React.PureComponent<ILayoutRegionProps & ReturnType<typeof collect>, IState> {
    node: HTMLElement; // TODO: createRef when: https://github.com/react-dnd/react-dnd/issues/998
    cachedSizeProps: any;

    state: IState = {};

    updateRef(node: HTMLElement) {
        const sizeProps = {
            scale: this.props.scale,
            width: this.props.width,
            height: this.props.height,
        };

        if (node && (node !== this.node || !isEqual(sizeProps, this.cachedSizeProps))) {
            this.node = node;
            this.cachedSizeProps = sizeProps;

            const style = window.getComputedStyle(node);

            const width = parseFloat(style['width'] || '');
            const height = parseFloat(style['height'] || '');

            const marginLeft = parseFloat(style['marginLeft'] || '');
            const marginRight = parseFloat(style['marginRight'] || '');
            const marginTop = parseFloat(style['marginTop'] || '');
            const marginBottom = parseFloat(style['marginBottom'] || '');

            const paddingLeft = parseFloat(style['paddingLeft'] || '');
            const paddingRight = parseFloat(style['paddingRight'] || '');
            const paddingTop = parseFloat(style['paddingTop'] || '');
            const paddingBottom = parseFloat(style['paddingBottom'] || '');

            const borderLeft = parseFloat(style['borderLeftWidth'] || '');
            const borderRight = parseFloat(style['borderRightWidth'] || '');
            const borderTop = parseFloat(style['borderTopWidth'] || '');
            const borderBottom = parseFloat(style['borderBottomWidth'] || '');

            this.setState({
                childWidth: width + marginLeft + marginRight + paddingLeft + paddingRight + borderLeft + borderRight,
                childHeight: height + marginTop + marginBottom + paddingTop + paddingBottom + borderTop + borderBottom,
            });
        }
    }

    render() {
        const { connectDropTarget, style, className, onChange, snapIndex, scale = 1, regionKey = 1 } = this.props;

        const wrapperStyle: React.CSSProperties = {
            display: 'inline-block',
            position: 'relative',
            // transition: 'all ease 1s',
        };

        const { childHeight, childWidth } = this.state;
        if (childHeight && childWidth) {
            wrapperStyle['width'] = childWidth * scale;
            wrapperStyle['height'] = childHeight * scale;
        }

        const transformStyle: React.CSSProperties = {
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            position: 'absolute',
            top: 0,
            left: 0,
        };

        // make the scaled component absolutely positioned, and wrap it in a dynamically
        // sized outer component, because css transforms do not CSS reflow
        return (
            <LayoutRegionProvider value={{ scale, onChange, snapIndex, regionKey }}>
                <div style={wrapperStyle} ref={this.props.scaledNodeRef} onClick={this.props.onClick}>
                    {connectDropTarget(
                        <div
                            style={{ ...transformStyle, ...style }}
                            className={className}
                            ref={this.updateRef.bind(this)}
                        >
                            {this.props.children}
                        </div>,
                    )}
                </div>
            </LayoutRegionProvider>
        );
    }
}

export default DropTarget([Component, NativeTypes.FILE], dropComponentSpec, collect)(LayoutRegion);
