import * as React from 'react';
import { PusherChannel } from 'reports/modules/project/listeners';
import { makeChannel } from 'helioscope/app/utilities/pusher';

export interface IWatchChannelProps {
    channelPrefix: string;
    entityId: string | number | null;
    eventName: string;
    onReceiveEvent: (ev: any | null) => void;
}

const unsubscribeFromChannel = (channelToUnsubscribe: PusherChannel | null) => {
    channelToUnsubscribe && channelToUnsubscribe.unsubscribe();
};

export const useWatchPusherChannel = ({ channelPrefix, entityId, eventName, onReceiveEvent }: IWatchChannelProps) => {
    React.useEffect(() => {
        let newChannel: PusherChannel;
        if (entityId) {
            newChannel = makeChannel(`${channelPrefix}@${entityId}`);
            newChannel.watch(eventName, async (ev) => {
                onReceiveEvent(ev);
            });
        }

        // unsubscribe from channel if/when entity id, channel prefix, or callback change, and on unmount
        return () => {
            unsubscribeFromChannel(newChannel);
        };
    }, [entityId, channelPrefix, eventName, onReceiveEvent]);
};
