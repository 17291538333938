/**
 * Financials tiered table editor
 */
import { assign, find, last, merge, range } from 'lodash';
import * as React from 'react';

import { IconNames } from '@blueprintjs/icons';
import { Checkbox } from '@blueprintjs/core';

import { Button, DeleteButton } from 'reports/components/core/controls';

import { IParamProps, ITier } from 'reports/modules/financials/params';
import { IColumn, ITableConfig, ParamHTMLTable } from './ParamTable';
import ParamNumeric from './ParamNumeric';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const CenteredCheckbox = styled(Checkbox)`
    margin-top: 5px;
    font-weight: normal;
`;

interface IParamTiers extends IParamProps<ITableConfig<ITier>> {
    minimal?: boolean;
    showAbsCap?: boolean;
    showHeaders?: boolean;
    showLastTierCapToggle?: boolean;
    updateFn?: (tiers: ITier[]) => any;
}

interface IState {
    isLastTierCapped: boolean;
}

export const DEFAULT_TIER = { tier_value: 0.0, tier_cap: null, abs_cap: null };

function adjustAbsCaps(tiers: ITier[], sortPath: string) {
    if (!sortPath) return;

    let absCap = 0;
    for (const tier of tiers) {
        if (tier && tier.tier_cap != null) {
            absCap += tier[sortPath];
            tier.abs_cap = absCap;
        }
    }
}

class ParamTiers extends React.PureComponent<IParamTiers, IState> {
    state: IState = { isLastTierCapped: false };

    static defaultProps = {
        showAbsCap: true,
        showHeaders: true,
    };

    columns = this.props.showAbsCap
        ? this.props.parameter.columns
        : this.props.parameter.columns.filter((col) => col.path !== 'abs_cap');

    headerElements = this.columns.map((column, idx) => (
        <th key={idx} style={{ width: 176 }}>
            {this.props.showHeaders && column.description}
        </th>
    ));

    componentDidMount() {
        const { value: tiers } = this.props;
        if (tiers.length === 0) return;
        const lastTier = tiers[tiers.length - 1];
        this.setState({ isLastTierCapped: lastTier.tier_cap != null });
    }

    render() {
        const { value: tiers, parameter, disabled, minimal, showHeaders } = this.props;
        const maxRowsReached = parameter.max_rows > 0 && tiers.length >= parameter.max_rows;

        const rowElements = tiers.map((tier, idx) => this.renderRow(tier, idx));

        return (
            <ParamHTMLTable className={!showHeaders ? 'no-headers' : undefined}>
                <thead>
                    <tr>
                        {this.headerElements}
                        <th id="delete" style={{ width: 50 }} />
                    </tr>
                </thead>
                <tbody>
                    {rowElements}
                    <tr>
                        {range(this.headerElements.length).map((_, idx) => (
                            <td key={idx} />
                        ))}
                        <td>
                            <Button
                                icon={IconNames.PLUS}
                                onClick={this.addTier}
                                disabled={maxRowsReached || disabled}
                                minimal={minimal}
                            />
                        </td>
                    </tr>
                </tbody>
            </ParamHTMLTable>
        );
    }

    toggleCappedLastTier = () => {
        const { value: tiers, updateFn, showAbsCap } = this.props;
        if (tiers.length === 0 || !updateFn) return;
        const newTiers = tiers.slice();
        const lastTier = newTiers[newTiers.length - 1];
        let isLastTierCapped;
        if (lastTier.tier_cap == null) {
            // Toggle from uncapped to capped.
            lastTier.tier_cap = 0;
            if (newTiers.length >= 2) {
                lastTier.abs_cap = newTiers[newTiers.length - 2].abs_cap;
            } else {
                lastTier.abs_cap = showAbsCap ? 0 : null;
            }
            isLastTierCapped = true;
        } else {
            // Toggle from capped to uncapped.
            lastTier.tier_cap = null;
            lastTier.abs_cap = null;
            isLastTierCapped = false;
        }
        updateFn(newTiers);
        this.setState({ isLastTierCapped });
    };

    renderCell(row: ITier, col: IColumn, rowIdx: number, colIdx: number) {
        const { disabled, value: tiers } = this.props;
        const { isLastTierCapped } = this.state;
        const value = row[col.path];

        const adjustedParam = assign({}, col);
        const isLastCap = rowIdx === tiers.length - 1 && col.path.includes('cap');
        return (
            <td key={colIdx}>
                <ParamNumeric
                    value={value}
                    parameter={adjustedParam}
                    disabled={disabled || (isLastCap && !isLastTierCapped)}
                    updateFn={(cellVal) => this.updateCell(rowIdx, adjustedParam, cellVal)}
                />
            </td>
        );
    }

    renderRow(row: ITier, rIdx: number) {
        const { parameter, value, showLastTierCapToggle, disabled, minimal } = this.props;
        const { isLastTierCapped } = this.state;
        const minRowsReached = parameter.min_rows !== undefined && value.length <= parameter.min_rows;

        const cellElements = this.columns.map((col, cIdx) => this.renderCell(row, col, rIdx, cIdx));
        const isLastRow = rIdx === value.length - 1;
        return (
            <tr key={rIdx}>
                {cellElements}
                <td>
                    <DeleteButton
                        onClick={() => this.deleteTier(rIdx)}
                        disabled={minRowsReached || disabled}
                        minimal={minimal}
                    />
                </td>
                {showLastTierCapToggle && isLastRow && (
                    <td>
                        <CenteredCheckbox
                            label="Capped"
                            onChange={this.toggleCappedLastTier}
                            checked={isLastTierCapped}
                            disabled={disabled || value.length === 0}
                        />
                    </td>
                )}
            </tr>
        );
    }

    addTier = () => {
        const tiers = (this.props.value || []).slice();

        if (tiers.length) {
            const newTier = merge({}, last(tiers));
            if (this.props.showAbsCap) newTier.abs_cap = null;
            tiers.push(newTier);
        } else {
            tiers.push({ ...DEFAULT_TIER });
        }
        this.finalizeUpdate(tiers);
    };

    deleteTier(index: number) {
        const tiers = this.props.value.slice();
        tiers.splice(index, 1);
        this.finalizeUpdate(tiers);
    }

    adjustTierCaps(tiers: ITier[]) {
        const { sort_path: sortPath, max_null: maxNull } = this.props.parameter;
        const { isLastTierCapped } = this.state;

        if (!sortPath || !maxNull) return tiers;

        const adjustedTiers = tiers.map((tier) => merge({}, tier));

        // Set initial tier cap
        if (adjustedTiers.length > 1 && adjustedTiers[0][sortPath] === null) {
            const col = find(this.columns, (col) => col.path === sortPath);
            adjustedTiers[0][sortPath] = adjustedTiers[0].abs_cap = col!.default;
        }

        // Copy tier cap from previous tier if null
        for (let i = 0; i < adjustedTiers.length - 1; i += 1) {
            if (adjustedTiers[i + 1][sortPath] === null) {
                adjustedTiers[i + 1][sortPath] = adjustedTiers[i][sortPath];
            }
        }

        // If this is IncentiveTierTable and we have the last tier uncapped,
        // set the last tier to null (uncapped).
        if (this.props.showLastTierCapToggle && !isLastTierCapped) {
            const lastIdx = adjustedTiers.length - 1;
            adjustedTiers[lastIdx].tier_cap = null;
            adjustedTiers[lastIdx].abs_cap = null;
        }

        // Remove caps on last tier (if this is not IncentiveTierTable)
        if (adjustedTiers.length && !this.props.showLastTierCapToggle) {
            adjustedTiers[adjustedTiers.length - 1][sortPath] = null;
            if (this.props.showAbsCap) {
                adjustedTiers[adjustedTiers.length - 1].abs_cap = null;
            }
        }

        this.props.showAbsCap && adjustAbsCaps(adjustedTiers, sortPath);
        return adjustedTiers;
    }

    updateCell(rowIdx: number, parameter: IColumn, cellValue: number) {
        const tiers = this.props.value.slice();
        const constrained = Math.max(Math.min(cellValue, parameter.max_value), parameter.min_value);

        tiers[rowIdx] = assign({}, tiers[rowIdx], {
            [parameter.path]: constrained,
        });

        // Adjust tier caps on abs cap change
        if (parameter.path === 'abs_cap') {
            const prevTier = tiers[rowIdx - 1];

            if (prevTier && prevTier.abs_cap != null) {
                const constrainedTierCap = Math.max(
                    Math.min(cellValue - prevTier.abs_cap, parameter.max_value),
                    parameter.min_value,
                );
                tiers[rowIdx].tier_cap = constrainedTierCap;
            } else {
                tiers[rowIdx].tier_cap = constrained;
            }
        }
        this.finalizeUpdate(tiers);
    }

    finalizeUpdate(tiers: ITier[]) {
        const { updateFn } = this.props;
        const { sort_path: sortPath } = this.props.parameter;

        if (sortPath) {
            if (updateFn) {
                updateFn(this.adjustTierCaps(tiers));
            }
        } else if (updateFn) {
            // Possibly unused for tiers
            updateFn(tiers);
        }
    }
}

export { adjustAbsCaps, ParamTiers };
