import * as React from 'react';

import { isEqual } from 'lodash';

import { Button, ButtonGroup, Intent } from '@blueprintjs/core';

import * as styles from 'reports/styles/styled-components';
import { Tooltip } from 'reports/components/core/controls';
const styled = styles.styled;

const SelectButton = styled(Button)`
    white-space: nowrap;
    // Override Blueprint styling
    &&&& {
        flex: 1;
    }
`;

export interface IItemProps {
    key: string | number;
    text: React.ReactNode;
}

interface IButtonGroupSelectProps<Item> {
    items: Item[];
    selectedItem: Item;
    itemRenderer: (item: Item) => IItemProps;
    onSelect: (item: Item) => void;
    toolTipRenderer?: (item: Item) => IItemProps;
    disabled?: boolean;
    fill?: boolean;
    flex?: boolean;
}

const TooltipWrapper = ({ item, toolTipRenderer, children }) => {
    if (toolTipRenderer) {
        const toolTipProps = toolTipRenderer(item);
        return (
            <Tooltip key={toolTipProps.key} content={toolTipProps.text} placement="bottom">
                {children}
            </Tooltip>
        );
    }
    return children;
};

/**
 * A select displayed as a horizontal button group. The user clicks on one of N buttons: one button for each item.
 * Once a button has been clicked, that button is highlighted (using primary intent) to indicate the corresponding
 * item is "selected". The previously selected button returns to normal styling.
 */
const ButtonGroupSelect = <Item,>(props: IButtonGroupSelectProps<Item>) => {
    const { items, selectedItem, onSelect, disabled, fill, itemRenderer, toolTipRenderer, flex = true } = props;
    return (
        <ButtonGroup fill={fill}>
            {items.map((item) => {
                const selected = isEqual(selectedItem, item);
                const renderedItem = itemRenderer(item);
                const StyledButton = flex ? SelectButton : Button;
                return (
                    <TooltipWrapper item={item} toolTipRenderer={toolTipRenderer} key={renderedItem.key}>
                        <StyledButton
                            text={renderedItem.text}
                            active={selected}
                            intent={selected ? Intent.PRIMARY : undefined}
                            onClick={selected ? undefined : () => onSelect(item)}
                            disabled={disabled}
                        />
                    </TooltipWrapper>
                );
            })}
        </ButtonGroup>
    );
};

export { IButtonGroupSelectProps, ButtonGroupSelect };
export default ButtonGroupSelect;
