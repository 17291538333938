import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { Card, Elevation, Spinner } from '@blueprintjs/core';

import { RouteComponent } from 'reports/routing';

import Welcome from 'reports/components/Welcome';
import { DialogOverlay } from 'reports/components/dialog';
import ErrorPage from 'reports/components/ErrorPage';
import { ProjectList } from 'reports/modules/project/components/ProjectList';
import ProjectOverview from 'reports/modules/project/components/ProjectOverview';
import SettingsView from 'reports/modules/settings/SettingsView';

import ProjectReportViewer from 'reports/modules/report/views/ProjectReportViewer';
import ReportConfigurationEditor from 'reports/modules/report/views/ReportConfigurationEditor';
import AuthTokenList from 'reports/modules/auth/components/AuthTokenList';

import FinancialConfigurationList from 'reports/modules/financials/components/FinancialConfigurationList';
import ReportLibrary from 'reports/modules/report/views/Library';
import ConditionSets from 'reports/modules/project/views/ConditionSets';
import ProjectSharing from 'reports/modules/project/views/ProjectSharing';
import { Simulations } from 'reports/modules/project/views/Simulations';

import { Library as FinancialTemplateLibrary } from 'reports/modules/financials/components/financial_template/views/Library';
import { Library as IncentiveLibrary } from 'reports/modules/incentive/views/Library';
import { Library as ProfileLibrary } from 'reports/modules/profile/views/Library';
import { Library as ModuleLibrary } from 'reports/modules/module/views/Library';
import { Library as PowerDeviceLibrary } from 'reports/modules/power_device/views/Library';
import { Library as UtilityRateLibrary } from 'reports/modules/utility_rate/views/Library';
import { WeatherLibrary as WeatherSourceLibrary } from 'reports/modules/weather_source/views/WeatherLibrary';
import { WiringLibrary } from 'reports/modules/wiring/WiringLibrary';

import RouteLinkedDesigner from 'reports/modules/ogdesigner/components/RouteLinkedDesigner';

import { BillingAdminCallout } from 'reports/modules/settings/BillingAdminCallout';

import DowntimeBanner from './DowntimeBanner';

import * as styles from 'reports/styles/styled-components';

const styled = styles.styled;

const ElevatedPane = styled(Card).attrs({
    elevation: Elevation.TWO,
})`
    margin: 20px;
`;

interface IMainPaneProps {
    className: string;
    navError: any;
    navBusy: any;
}

const SafeMainPane = () => {
    const dispatch = useDispatch();

    const navigateToProject = (projectId: string) =>
        dispatch(
            routerActions.navigateTo('app.projects.project.overview', {
                projectId,
            }),
        );

    return (
        <>
            <BillingAdminCallout />
            <RouteComponent name="app.welcome">
                <Welcome />
            </RouteComponent>
            <RouteComponent name="app.projects">
                <DowntimeBanner utcTime="2024-10-03 23:00:00" duration={180} />
                <ProjectList navigateToProject={navigateToProject} />
            </RouteComponent>
            <RouteComponent name="app.reports" exact={false}>
                {({ project }) => <ReportLibrary project={project} />}
            </RouteComponent>
            <RouteComponent name="app.profiles" exact={false}>
                <ProfileLibrary />
            </RouteComponent>
            <RouteComponent name="app.financial-templates" exact={false}>
                <FinancialTemplateLibrary />
            </RouteComponent>
            <RouteComponent name="app.utility-rates" exact={false}>
                <UtilityRateLibrary />
            </RouteComponent>
            <RouteComponent name="app.incentives" exact={false}>
                <IncentiveLibrary />
            </RouteComponent>
            <RouteComponent name="app.power-devices" exact={false}>
                <PowerDeviceLibrary />
            </RouteComponent>
            <RouteComponent name="app.modules" exact={false}>
                <ModuleLibrary />
            </RouteComponent>
            <RouteComponent name="app.weather_sources" exact={false}>
                <WeatherSourceLibrary />
            </RouteComponent>
            <RouteComponent name="app.wires" exact={false}>
                <WiringLibrary />
            </RouteComponent>
            <RouteComponent name="app.settings" exact={false}>
                <SettingsView />
            </RouteComponent>
            <RouteComponent name="app.projects.project.overview">
                {({ project }) => <ProjectOverview project={project} />}
            </RouteComponent>
            <RouteComponent name="app.projects.project.report" exact={false}>
                {({ report, project, data: { printableReady } }) => (
                    <>
                        <RouteComponent name="app.projects.project.report.view">
                            <ProjectReportViewer report={report} project={project} printableReady={printableReady} />
                        </RouteComponent>
                        <RouteComponent name="app.projects.project.report.configure">
                            <ReportConfigurationEditor
                                report={report}
                                project={project}
                                printableReady={printableReady}
                            />
                        </RouteComponent>
                    </>
                )}
            </RouteComponent>
            <RouteComponent name="app.projects.project.financial-configurations" exact={false}>
                {({ project }) => <FinancialConfigurationList project={project} />}
            </RouteComponent>
            <RouteComponent name="app.projects.project.conditions" exact={false}>
                {({ project }) => <ConditionSets project={project} />}
            </RouteComponent>
            <RouteComponent name="app.projects.project.designer">
                {({ design }) => <RouteLinkedDesigner design={design} />}
            </RouteComponent>
            <RouteComponent name="app.projects.project.sharing">
                {({ project }) => <ProjectSharing project={project} />}
            </RouteComponent>
            <RouteComponent name="app.projects.project.simulation">
                {({ project }) => <Simulations project={project} />}
            </RouteComponent>
            <RouteComponent name="app.auth-tokens">
                <ElevatedPane>
                    <AuthTokenList />
                </ElevatedPane>
            </RouteComponent>
        </>
    );
};

function renderError(navError) {
    return navError ? <ErrorPage status={navError.status} body={navError.body} /> : null;
}

function renderBusy(navBusy) {
    if (navBusy) {
        return (
            <DialogOverlay isOpen>
                <div style={{ margin: '18px' }}>
                    <Spinner />
                </div>
            </DialogOverlay>
        );
    }

    return null;
}

export const MainPane: React.FC<IMainPaneProps> = ({ className, navError, navBusy }) => (
    <div className={className}>
        {renderError(navError)}
        {renderBusy(navBusy)}
        <SafeMainPane />
    </div>
);

export default MainPane;
