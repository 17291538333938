import { get, isArray } from 'lodash';
import * as React from 'react';

import { Classes } from '@blueprintjs/core';

import { IntentContainer } from 'reports/components/helpers/errors';

import { FormConsumer } from './types';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ErrorMessage = styled.div`
    font-size: 12px;
    margin-top: 5px;
`;

type IErrorMessageProps = { path: string; style?: React.CSSProperties };

const FieldErrorMessage = ({ path, style }: IErrorMessageProps) => (
    <FormConsumer>
        {({ fieldErrors, formData }) => {
            const value = get(formData, path);
            let errors = get(fieldErrors, path) || [];

            if (!isArray(errors)) {
                // It would be simple enough to flatten all errors, but I
                // think we should until we we need that use case to build it
                console.error('Tried to render form errors for object property');
                errors = [];
            }

            const errorMsg = errors.length ? errors[0] : '';
            const invalid = errors.length > 0 || !value;

            const error =
                typeof errorMsg === 'string' ? (
                    <IntentContainer className={invalid ? Classes.INTENT_DANGER : undefined} style={style}>
                        <ErrorMessage>{errorMsg}</ErrorMessage>
                    </IntentContainer>
                ) : (
                    errorMsg
                );

            return errorMsg && invalid ? error : null;
        }}
    </FormConsumer>
);

export default FieldErrorMessage;
