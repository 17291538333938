import _ from 'lodash';

import { truthyZero } from 'helioscope/app/utilities/helpers';
import { fallback } from 'reports/utils/helpers';

import { ITier, makeAbsTiers, ParamConfigType, ParamValueType } from 'reports/modules/financials/params';
import { OutputCategory } from 'reports/modules/financials/model/debug';
import { IPipelineModule } from 'reports/modules/financials/model/modules/types';
import { IPipelineState, ResultType } from 'reports/modules/financials/model/pipeline/types';

import { Incentive } from 'reports/models/incentive';

import { TIME_INTERVALS } from '../core';
import { marginalMultiplier, systemCostMultiplier } from './multipliers';

function verifyIncentiveAllocation(params) {
    const { allocation_schedule } = params;
    const sum = _.sumBy(allocation_schedule as any[], (i) => i.portion);
    const epsilon = 0.001;
    if (Math.abs(1.0 - sum) > epsilon) {
        return false;
    }

    return true;
}

function applyIncentive(allocation, state, amount) {
    const { incentiveYearly } = state;

    const adj = incentiveYearly.map(() => 0.0);

    for (const i of allocation) {
        if (i.year === 0) {
            state.incentiveInitial += i.portion * amount;
            continue;
        }

        adj.setRawSafe(i.year - 1, i.portion * amount);
    }

    incentiveYearly.addSeries(adj);
}

function applyFixedIncentive(flatRebate, year, state) {
    const { systemInitial, taxCreditCostBasis } = state;

    const basis = truthyZero(taxCreditCostBasis) ? taxCreditCostBasis : -systemInitial;
    if (year === 0) {
        state.incentiveInitial += flatRebate;
    } else {
        const current = state.incentiveYearly.getRawSafe(year - 1);
        state.incentiveYearly.setRawSafe(year - 1, current + flatRebate);
    }
    state.taxCreditCostBasis = Math.max(0, basis - flatRebate);
}

function applySystemCostIncentive(valueTiers: ITier[], allocationSchedule, reducedBasis, state: IPipelineState) {
    const { systemInitial, taxCreditCostBasis } = state;

    const basis: number = fallback(taxCreditCostBasis, -systemInitial);
    const amount = systemCostMultiplier(reducedBasis ? basis : -systemInitial, valueTiers);
    applyIncentive(allocationSchedule, state, amount);

    state.taxCreditCostBasis = Math.max(0, basis - amount);
}

function applyNameplateIncentive(valueTiers: ITier[], allocationSchedule, state) {
    const { nameplate } = state;
    const amount = marginalMultiplier(nameplate, valueTiers);
    applyIncentive(allocationSchedule, state, amount);
}

export function applyProductionIncentive(valueTiers: ITier[], payoutYears, state) {
    const { incentiveYearly } = state;

    const prodYearly = state.production.reaggregateInterval(TIME_INTERVALS.YEAR);
    const remainingValueTiers = makeAbsTiers(valueTiers);
    const prodIncYearly = prodYearly.map((currYearProd, _j, _k, yearIndex) => {
        if (yearIndex >= payoutYears) {
            return 0.0;
        }

        const currYearInc = marginalMultiplier(currYearProd, remainingValueTiers);

        // Subtract this year's production from tiers, so that next year picks up where this year left off.
        for (const tier of remainingValueTiers) {
            if (tier.abs_cap != null) {
                tier.abs_cap = Math.max(tier.abs_cap - currYearProd, 0);
            }
        }

        return currYearInc;
    });

    incentiveYearly.addSeries(prodIncYearly);
}

export const BasicIncentivesRebateSimple: IPipelineModule = {
    description: 'Fixed Incentive',
    parameters: [
        {
            description: 'Incentive Amount',
            path: 'flat_rebate',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
        {
            description: 'Year of Payout',
            path: 'year',
            type: ParamValueType.Integer,
            min_value: 0,
            max_value: 999,
            default: 0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { flat_rebate, year } = params;
            applyFixedIncentive(flat_rebate, year, state);
        },
        debugOutputs: [{ table: OutputCategory.Incentives }],
    },
};

export const SystemPriceIncentiveConfig: ParamConfigType = {
    description: 'Incentive Amount',
    type: ParamValueType.IncentiveTierTable,
    min_rows: 1,
    max_rows: 99,
    sort_path: 'tier_cap',
    max_null: true,
    default: [],
    columns: [
        {
            description: '% of System Cost',
            path: 'tier_value',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.0,
        },
        {
            description: 'Tier Cap ($)',
            path: 'tier_cap',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
        {
            description: 'Absolute Cap ($)',
            path: 'abs_cap',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
    ],
};

export const NameplateIncentiveConfig: ParamConfigType = {
    description: 'Incentive Amount',
    type: ParamValueType.IncentiveTierTable,
    min_rows: 1,
    max_rows: 99,
    sort_path: 'tier_cap',
    max_null: true,
    default: [],
    columns: [
        {
            description: '$ / Wdc',
            path: 'tier_value',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.0,
        },
        {
            description: 'Tier Cap (W)',
            path: 'tier_cap',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
        {
            description: 'Absolute Cap (W)',
            path: 'abs_cap',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
    ],
};

export const ProductionIncentiveConfig: ParamConfigType = {
    description: 'Incentive Amount',
    type: ParamValueType.IncentiveTierTable,
    min_rows: 1,
    max_rows: 99,
    sort_path: 'tier_cap',
    max_null: true,
    default: [],
    columns: [
        {
            description: '$ / kWh',
            path: 'tier_value',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.0,
        },
        {
            description: 'Tier Cap (kWh)',
            path: 'tier_cap',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
        {
            description: 'Absolute Cap (kWh)',
            path: 'abs_cap',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
    ],
};

export const BasicIncentivesCostSimple: IPipelineModule = {
    description: 'System Cost (%) Incentive',
    parameters: [
        {
            ...SystemPriceIncentiveConfig,
            path: 'value_tiers',
            tier_value_path: 'value_ratio',
            tier_cap_path: 'value_cap',
        },
        {
            description: 'Payment Schedule',
            path: 'allocation_schedule',
            type: ParamValueType.Table,
            max_rows: 99,
            sort_path: 'year',
            default: [{ portion: 1.0, year: 1 }],
            columns: [
                {
                    description: 'Payout %',
                    path: 'portion',
                    type: ParamValueType.Percentage,
                    min_value: 0.0,
                    max_value: 1.0,
                    default: 0.0,
                },
                {
                    description: 'Year',
                    path: 'year',
                    type: ParamValueType.Integer,
                    min_value: 0,
                    max_value: 999,
                    default: 1,
                },
            ],
        },
        {
            description: 'Reduced Basis',
            path: 'reduced_basis',
            type: ParamValueType.Boolean,
            default: true,
        },
    ],
    module: {
        verify: verifyIncentiveAllocation,
        main: function main(state: IPipelineState, params) {
            const { value_tiers, allocation_schedule, reduced_basis } = params;
            const tiers = value_tiers.map((i) => ({
                tier_cap: i.value_cap,
                tier_value: i.value_ratio,
            }));
            applySystemCostIncentive(tiers, allocation_schedule, reduced_basis, state);
        },
        debugOutputs: [{ table: OutputCategory.Incentives }],
    },
};

export const BasicIncentivesNameplateSimple: IPipelineModule = {
    description: 'Price Per Watt Incentive',
    parameters: [
        {
            ...NameplateIncentiveConfig,
            path: 'value_tiers',
            tier_value_path: 'per_w',
            abs_cap_path: 'w_cap',
        },
        {
            description: 'Payment Schedule',
            path: 'allocation_schedule',
            type: ParamValueType.Table,
            max_rows: 99,
            sort_path: 'year',
            default: [{ portion: 1.0, year: 1 }],
            columns: [
                {
                    description: 'Payout %',
                    path: 'portion',
                    type: ParamValueType.Percentage,
                    min_value: 0.0,
                    max_value: 1.0,
                    default: 0.0,
                },
                {
                    description: 'Year',
                    path: 'year',
                    type: ParamValueType.Integer,
                    min_value: 0,
                    max_value: 999,
                    default: 1,
                },
            ],
        },
    ],
    module: {
        verify: verifyIncentiveAllocation,
        main: function main(state, params) {
            const { value_tiers, allocation_schedule } = params;
            const tiers = value_tiers.map((i) => ({
                abs_cap: i.w_cap,
                tier_value: i.per_w,
            }));
            applyNameplateIncentive(tiers, allocation_schedule, state);
        },
        debugOutputs: [{ table: OutputCategory.Incentives }],
    },
};

export const BasicIncentivesProductionSimple: IPipelineModule = {
    description: 'Production Based Incentive',
    parameters: [
        {
            ...ProductionIncentiveConfig,
            path: 'value_tiers',
            tier_value_path: 'per_kwh',
            tier_cap_path: 'kwh_cap',
        },
        {
            description: 'Incentive Duration (Years)',
            path: 'payout_years',
            type: ParamValueType.Integer,
            min_value: 1,
            max_value: 999,
            default: 1,
        },
    ],
    module: {
        main: function main(state, params) {
            const { value_tiers, payout_years } = params;
            const tiers = value_tiers.map((i) => ({
                tier_cap: i.kwh_cap,
                tier_value: i.per_kwh,
            }));
            applyProductionIncentive(tiers, payout_years, state);
        },
        debugOutputs: [{ table: OutputCategory.Incentives }],
    },
};

export const BasicApplyIncentives: IPipelineModule = {
    required: true,
    description: 'Apply Project Incentives',
    parameters: [],
    module: {
        main: function main(state: IPipelineState, _params) {
            const { projectIncentives } = state;
            for (const incentive of projectIncentives || []) {
                this.applyIncentive(incentive, state);
            }
        },
        applyIncentive: function applyIncentive(incentive: Incentive, state: IPipelineState) {
            const { configuration } = incentive;

            switch (configuration.type) {
                case 'fixed':
                    applyFixedIncentive(configuration.amount, configuration.year, state);
                    break;
                case 'system_price':
                    applySystemCostIncentive(
                        configuration.amount,
                        configuration.payment_schedule,
                        configuration.reduced_basis,
                        state,
                    );
                    break;
                case 'nameplate':
                    applyNameplateIncentive(
                        // TODO: run migration on existing nameplate incentives, to rename tier_cap to abs_cap
                        configuration.amount.map((i) => ({
                            abs_cap: i.tier_cap,
                            tier_value: i.tier_value,
                        })),
                        configuration.payment_schedule,
                        state,
                    );
                    break;
                case 'production':
                    applyProductionIncentive(configuration.amount, configuration.payout_years, state);
                    break;
            }
        },
        debugOutputs: [{ table: OutputCategory.Incentives }],
    },
    outputValues: [
        {
            type: ResultType.Incentives,
            path: 'projectIncentives',
        },
    ],
};
