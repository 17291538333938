import { Classes, Dialog, Colors, Spinner } from '@blueprintjs/core';

import * as React from 'react';
import { useRoute } from 'react-router5';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import Flex from 'reports/components/core/containers/Flex';
import { Form } from 'reports/components/forms';
import * as usr from 'reports/models/user';
import * as prc from 'reports/models/stripe/price';
import { useStripeData, StripeContainer } from 'reports/modules/settings/billing/components/StripeContainer';
import { PlanSelection } from 'reports/modules/settings/billing/components/PlanSelection';

import { canManageBilling } from '../permissions';
import SelfServeTrialExtensionCard from './SelfServeTrialExtensionCard';

import * as styles from 'reports/styles/styled-components';
export const styled = styles.styled;

const StyledDialog = styled(Dialog)`
    background-color: ${Colors.WHITE};
    width: 948px;
    padding: 32px;
    .${Classes.DIALOG_BODY} {
        line-height: unset;
    }

    a[role='button'],
    button {
        // Override Button's margin-left 8px
        margin-left: 0;
    }
`;

const StyledFlexContainer = styled(Flex.Container)`
    gap: 24px;
`;

const Title = styled.div`
    font-size: 36px;
    font-weight: 700;
    font-size: 36px;
    color: #607d8b;
`;

const Subtitle = styled.div`
    margin-bottom: 10px;
    font-size: 18px;
    color: ${Colors.DARK_GRAY5};
`;

const Column1 = styled.div`
    flex: 1;
`;

export const TitleContainer = styled(Flex.ContainerV)`
    margin-bottom: 0px;
`;

const TrialExpiredModalHeader = ({ user }) => (
    <TitleContainer>
        <Column1>
            <Title>Your trial has expired</Title>
        </Column1>
        <Column1>
            {user.role.can_manage_billing ? (
                <Subtitle>
                    Your trial with HelioScope has expired. Please choose a plan today to reactivate all your features
                    and functionality.
                </Subtitle>
            ) : (
                <Subtitle>
                    Your trial with HelioScope has expired, please contact your Admin to purchase a subscription.
                </Subtitle>
            )}
        </Column1>
    </TitleContainer>
);

const ProjectLimitsModalHeader = () => (
    <TitleContainer>
        <Column1>
            <Title>You have no more free projects remaining</Title>
        </Column1>
        <Column1>
            <Subtitle>
                To create new projects, please sign up for a full account. You may continue working on your existing
                projects.
            </Subtitle>
        </Column1>
    </TitleContainer>
);

const SubscriptionExpiredModalHeader = ({ user }) => (
    <TitleContainer>
        <Column1>
            <Title>Your subscription has expired</Title>
        </Column1>
        <Column1>
            {user.role.can_manage_billing ? (
                <Subtitle>Please choose a plan today to reactivate all your features and functionality.</Subtitle>
            ) : (
                <Subtitle>
                    Your subscription to HelioScope has expired, please contact your Admin to purchase a subscription.
                </Subtitle>
            )}
        </Column1>
    </TitleContainer>
);

interface Props {
    user: usr.User;
    isOpen?: boolean;
    showProjectLimitHeader?: boolean;
    onClose: () => void;
}

interface PlanSelectionFormData {
    interval: string;
    price: prc.Price;
}

const _ExpiredAccountDialog = ({ user, isOpen, onClose, showProjectLimitHeader }: Props) => {
    const isTrialExpired: boolean = user.status === 'expired_trial';

    const { basicPrices, proPrices, isLoading } = useStripeData();
    const { router } = useRoute();

    const isCurrentSelection = (_price: prc.Price) => false;
    const canUpgrade = (_price: prc.Price) => true;

    const onPriceSelection = (interval: string, price: prc.Price) => redirectToCheckoutFlow(interval, price);

    const redirectToCheckoutFlow = (interval: string, price: prc.Price) => {
        return router.navigate('app.settings.team.billing', {
            interval,
            price,
            dialog: 'initial',
            referrer: ReferrerTypes.expired_account_dialog,
        });
    };

    const canSelect = canManageBilling(user) && !user.latest_subscription?.selfServeDisabled;

    return (
        <StyledDialog isOpen={isOpen} onClose={onClose} canEscapeKeyClose={true} canOutsideClickClose={true}>
            <div style={{ boxShadow: '0 0 0 0', padding: '0px' }} className={Classes.DIALOG_HEADER}>
                {!showProjectLimitHeader ? (
                    isTrialExpired ? (
                        <TrialExpiredModalHeader user={user} />
                    ) : (
                        <SubscriptionExpiredModalHeader user={user} />
                    )
                ) : (
                    <ProjectLimitsModalHeader />
                )}
            </div>
            {isLoading ? (
                <Spinner />
            ) : (
                <div style={{ margin: '0px' }} className={Classes.DIALOG_BODY}>
                    <Form<PlanSelectionFormData, void>
                        baseValue={{
                            interval: 'month',
                        }}
                        onSubmit={() => {}}
                        keepStateOnSubmit={true}
                    >
                        {({ formData: { interval, price } }) => {
                            return (
                                <PlanSelection
                                    basicPrices={basicPrices}
                                    proPrices={proPrices}
                                    interval={interval}
                                    price={price}
                                    isCurrentSelection={isCurrentSelection}
                                    canUpgrade={canUpgrade}
                                    onPriceChange={(price: prc.Price) => onPriceSelection(interval, price)}
                                    referrer={ReferrerTypes.expired_account_dialog}
                                    canSelect={canSelect}
                                />
                            );
                        }}
                    </Form>
                </div>
            )}
            {!showProjectLimitHeader && (
                <div style={{ margin: '24px 0 0 0' }} className={Classes.DIALOG_FOOTER}>
                    <StyledFlexContainer>
                        <Column1>
                            <SelfServeTrialExtensionCard user={user} />
                        </Column1>
                    </StyledFlexContainer>
                </div>
            )}
        </StyledDialog>
    );
};

const ExpiredAccountDialog = (props: Props) => (
    <StripeContainer>
        <_ExpiredAccountDialog {...props} />
    </StripeContainer>
);

export default ExpiredAccountDialog;
