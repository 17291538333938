/* tslint:disable:variable-name */

import Logger from 'js-logger';

import { get, sumBy } from 'lodash';

import { ReduxEndpoint, BaseClass } from 'reports/utils/api';

import ComponentConfigurationBuilder from 'helioscope/app/designer/wiring_zone/ComponentConfigurationBuilder';
import InputConfiguration from 'helioscope/app/designer/stringing/InputConfiguration';

import { determineBounds } from 'helioscope/app/designer/stringing/string_sizing';

import { schema } from './schema';
import * as design from './design';
import * as powerDevice from './power_device';
import * as fieldSegment from './field_segment';
import * as wire from './wire';
import * as acConfig from './ac_config';

const logger = Logger.get('wiring_zone');

class WiringZone extends BaseClass {
    wiring_zone_id: number;
    description: string;

    design_id: number;
    design: design.Design;

    inverter_id: number;
    inverter: powerDevice.PowerDevice;

    power_optimizer_id: number;
    power_optimizer: powerDevice.PowerDevice;

    string_id: number;
    string: wire.Wire;

    bus_id: number;
    bus: wire.Wire;

    trunk_id: number;
    trunk: wire.Wire;

    ac_branch_id: number;
    ac_branch: wire.Wire;

    ac_run_id: number;
    ac_run: wire.Wire;

    inverter_ac_config_id: number;
    inverter_ac_config: acConfig.AcConfig;

    panel_transformer_config_id: number;
    panel_transformer_config: acConfig.AcConfig;

    ac_branch_center_feed: boolean;
    ac_branch_length: number;
    ac_panel_size: number;
    combiner_poles: number;
    inverter_count: null;
    max_dc_ac_ratio: number;

    string_size_max: number;
    string_size_min: number;

    module_config_parallel: number;
    module_config_series: number;

    stringing_strategy: 'along' | 'updown';
    use_transformers: boolean;

    field_segments: fieldSegment.FieldSegment[];

    /**
     * inverter string bounds, will eventually include ashrae sometimes
     */
    stringBounds() {
        return determineBounds(this);
    }

    moduleCount() {
        // TODO: data.modules (and data.frames and data.power) can be null
        // for empty design (/projects/201326/designs/435409), which is a separate
        // bug that should be fixed (they should be 0). This causes _.sumBy()
        // to return null, so convert to 0 in that case as a work-around
        const cnt = sumBy(this.field_segments, 'data.modules');
        if (!cnt && cnt !== 0) {
            logger.warn(`Module count is ${cnt} for this.field_segments=${this.field_segments}`);
        }
        return cnt || 0;
    }

    power() {
        return sumBy(this.field_segments, 'data.power') || 0;
    }

    /**
     * if the user has defined an inverter count, use that, otherwise try to target a specific
     * inverter load ratio based on the total module nameplate power and a target ILR
     */
    targetInverterCount(dcAcRatio = this.max_dc_ac_ratio || 1.25) {
        if (this.inverter_count) {
            return this.inverter_count;
        }

        if (this.inverter) {
            return Math.ceil(this.power() / this.inverter.max_power / dcAcRatio);
        }

        return 1;
    }

    componentConfiguration(subsystem = 'dc') {
        const config = new ComponentConfigurationBuilder(this).buildConfig();

        if (subsystem) {
            return config[subsystem];
        }

        return config;
    }

    get field_component_metadata() {
        // temp hack for tokens
        return get(this, `design.field_component_metadata.wiring_zones[${this.wiring_zone_id}]`);
    }

    inputConfiguration() {
        return new InputConfiguration(this);
    }
}

const schemaObj = schema.addObject(WiringZone, 'wiring_zone', {
    relationships: {
        inverter: { schema: powerDevice.schemaObj },
        power_optimizer: { schema: powerDevice.schemaObj },

        inverter_ac_config: { schema: acConfig.schemaObj },
        panel_transformer_config: { schema: acConfig.schemaObj },
    },
});
const endpoint = ReduxEndpoint.fromSchema('/api/wiring_zones/', schemaObj);

const { backrefSelector: fieldSegments } = fieldSegment.schemaObj.addRelationship('wiring_zone', schemaObj, {
    backref: 'field_segments',
});

const api = {
    index: endpoint.index<{ design_id?: number }>(),
    get: endpoint.get('{wiring_zone_id}'),
    create: endpoint.post(),
    save: endpoint.put('{wiring_zone_id}'),
    delete: endpoint.delete('{wiring_zone_id}'),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { fieldSegments, api, WiringZone, endpoint, schemaObj, selectors };
