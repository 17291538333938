import { Button as BpButton, ButtonProps } from '@blueprintjs/core';
import * as React from 'react';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const BUTTON_MARGIN_LEFT = '8px';

const StyledButton = styled(BpButton)`
    margin-left: ${BUTTON_MARGIN_LEFT};
`;

type IOwnProps = {
    // 'loadingWhileAwaiting' means show a loading spinner if onClick returned a pending promise. Continue showing a
    // spinner until the promise resolves.
    loadingWhileAwaiting?: boolean;
    style?: React.CSSProperties;
    title?: string;
};
export type IProps = IOwnProps & ButtonProps;

const Button: React.FC<IProps> = ({ onClick, loading, loadingWhileAwaiting, ...props }) => {
    const [awaiting, setAwaiting] = React.useState(false);
    return (
        <StyledButton
            {...props}
            loading={loading || (loadingWhileAwaiting && awaiting)}
            onClick={async (e) => {
                setAwaiting(true);
                try {
                    if (onClick) {
                        await onClick(e);
                    }
                } finally {
                    setAwaiting(false);
                }
            }}
        />
    );
};
export default Button;
