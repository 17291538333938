import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { makeChannel } from 'helioscope/app/utilities/pusher';

import { Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { PusherChannel } from 'reports/modules/project/listeners';
import { selectors as authSelectors } from 'reports/modules/auth';
import { actions as subActions } from 'reports/modules/subscription';
import { IAppState } from 'reports/store';

import { styled } from 'reports/styles/styled-components';

const StyledCallout = styled(Callout)`
    margin-bottom: 10px;
    font-size: 12px;
`;

const TestClockDisplay = () => {
    const user = useSelector((state: IAppState) => authSelectors.getUser(state)!);
    const subscription = user.latest_subscription;
    const testClockId = subscription?.stripe_data?.test_clock;
    const frozenTime = subscription?.test_clock_frozen_time;

    const dispatch = useDispatch();

    React.useEffect(() => {
        let testClockChannel: PusherChannel;
        if (testClockId != null) {
            testClockChannel = makeChannel(`test_clock@${testClockId}`);
            testClockChannel.watch('test_helpers.test_clock.ready', () => {
                // Reload subscription to get the latest frozen time (plus any other changes to the subscription)
                dispatch(subActions.loadFullSubscription(subscription!));
            });
        }
        return () => testClockChannel && testClockChannel.unsubscribe();
    }, [testClockId]);

    if (testClockId != null && frozenTime !== undefined) {
        const testClockURL = `https://dashboard.stripe.com/test/test-clocks/${testClockId}`;
        return (
            <StyledCallout icon={IconNames.TIME} intent={Intent.WARNING}>
                Subscription is using Stripe Test Clock{' '}
                <a href={testClockURL} target="_blank">
                    {testClockId}
                </a>
                . Frozen Time: {frozenTime.format('YYYY-MM-DD HH:mm:ss Z')}
            </StyledCallout>
        );
    }
    return null;
};

export default TestClockDisplay;
