import * as React from 'react';

import { Callout, Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';
import ErrorBoundary from 'reports/components/ErrorBoundary';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ErrorStateContainer = styled(Flex.Container).attrs({
    align: Flex.Align.CENTER,
    alignV: Flex.AlignV.CENTER,
})`
    width: 100%;
    height: 100%;

    padding: 10px;

    background-color: ${Colors.LIGHT_GRAY4};
    border: 1px solid ${Colors.LIGHT_GRAY1};
`;

const ErrorCallout = styled(Callout)`
    width: auto;
    max-width: 400px;
    font-size: 16px;
    background-color: inherit;
    &,
    & > .bp3-heading {
        color: ${Colors.GRAY1};
    }
`;

export interface IWidgetErrorDesc {
    title?: string;
    message?: React.ReactNode;
}
export const WidgetErrorState: React.FC<IWidgetErrorDesc> = ({ title, message }) => (
    <ErrorStateContainer>
        <ErrorCallout title={title} icon={IconNames.WARNING_SIGN}>
            {
                // Wrap string message in <div/> so that bp3-header styling knows to leave a margin between itself and
                // message
                typeof message === 'string' ? <div>{message}</div> : message
            }
        </ErrorCallout>
    </ErrorStateContainer>
);

const WidgetErrorBoundary: React.FC<{}> = ({ children }) => (
    <ErrorBoundary errorState={<WidgetErrorState title="Error loading widget" />}>{children}</ErrorBoundary>
);

export default WidgetErrorBoundary;
