import * as React from 'react';
import moment from 'moment';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const BannerDiv = styled.div`
    padding: 8px;
    background-color: #f2dede;
    border: 1px solid #d06452;
    color: #a94442;
`;

const DowntimeBanner: React.SFC<{ utcTime: string; duration: number }> = ({ utcTime, duration }) => {
    const start = moment.utc(utcTime, 'YYYY-MM-DD HH:mm:ss').local();
    const end = start.clone().add(moment.duration(duration, 'minutes'));
    const displayStart = start.clone().subtract(moment.duration(10, 'days'));
    if (moment().isBefore(end) && moment().isAfter(displayStart)) {
        const msg = `
            HelioScope will be unavailable on ${start.format('MMM Do')} from
            ${start.format('LT')} - ${end.format('LT')} in your local timezone due to scheduled maintenance.
            We apologize for any inconvenience.
        `;

        return <BannerDiv>{msg}</BannerDiv>;
    }

    return null;
};

export default DowntimeBanner;
