import LogRocket from 'logrocket';
import Logger from 'js-logger';
import { some, get } from 'lodash';

import { IAppConfig } from 'reports/config';
import { Deferred } from 'reports/utils/async_helpers';

import * as usr from 'reports/models/user';
import { addDefaultHeader, HEADERS } from 'reports/modules/request';

const logger = Logger.get('log_rocket');

// list of endpoints to automatically filter out (Because of large request sizes)
const FILTER_ENDPOINTS = ['/api/field_components/', '/pdf'];

// max size of requests to forward to logrocket
const MAX_REQUEST_FORWARDING_SIZE = 50 * 1000; // 50KB

function logRocketFilter(request) {
    // tag the original body size always
    const bodySize = get(request, 'body.length', 0);
    request.headers['logrocket-body-size'] = bodySize;

    if (request.url && some(FILTER_ENDPOINTS, (endpoint) => request.url.indexOf(endpoint) !== -1)) {
        logger.debug(`removing matched url body ${request.url}:${bodySize / 1000}kB`);
        request.headers['logrocket-truncated'] = true;
        request.body = null;
    } else if (bodySize > MAX_REQUEST_FORWARDING_SIZE) {
        logger.debug(`truncating unmatched url body ${request.url}:${bodySize / 1000}kB`);
        request.headers['logrocket-truncated'] = true;
        request.body = request.body.slice(0, MAX_REQUEST_FORWARDING_SIZE);
    }

    return request;
}

function getLogRocketURL() {
    const deferred = new Deferred<string>();

    LogRocket.getSessionURL((sessionURL) => deferred.resolve(sessionURL));

    return deferred.promise;
}

export async function initialize(helioscopeConfig: IAppConfig): Promise<string | undefined> {
    if (helioscopeConfig.log_rocket) {
        LogRocket.init('folsom-labs/helioscope', {
            release: helioscopeConfig.version,
            network: {
                responseSanitizer: logRocketFilter,
                requestSanitizer: logRocketFilter,
            },
            mergeIframes: true,
        });

        logger.info(`Initialized LogRocket. HelioScope version ${helioscopeConfig.version}`);

        // URL takes a while to be generated (up to 30s), so do that last.
        const url = await getLogRocketURL();
        addDefaultHeader(HEADERS.logRocketUrl, url);

        return url;
    }
}

export function identify(helioscopeConfig: IAppConfig, user: usr.User) {
    if (helioscopeConfig.log_rocket) {
        LogRocket.identify(user.user_id.toString(), {
            name: user.full_name,
            email: user.email,
        });
    }
}
