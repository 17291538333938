import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as analytics from 'reports/analytics';
import { IAppState } from 'reports/types';

import * as cfg from 'reports/config';

import { Form } from 'reports/components/forms';
import { DialogStep, DialogStepId, Intent, MultistepDialog } from '@blueprintjs/core';

import { Card } from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';

import { useStripeData, StripeContainer } from './StripeContainer';
import { useSubscriptionCustomer, useWatchSubscription } from 'reports/modules/settings/billing/Hooks';

import { handleFormException } from 'reports/modules/settings/billing';

import * as sub from 'reports/models/subscription';
import * as usr from 'reports/models/user';

import { Licenses } from './Licenses';
import { PlanPayment } from './PlanPayment';

import { Toaster } from 'reports/modules/Toaster';

import { styled } from 'reports/styles/styled-components';
import { useBillingContext } from '../Context';

const ToastSummary = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 105%;
`;

const StyledMultistepDialog = styled(MultistepDialog)`
    width: 1085px;
`;

interface Props {
    isOpen: boolean;
    closeDialog: () => void;
    subscription: sub.Subscription;
    user: usr.User;
}

interface FormData {
    card: Card | typeof CardElement;
    paymentMethod: string;
    email: string;
    quantity: number;
    purchaseOrder: string;
}

const _TeamSizeDialog = ({ closeDialog, isOpen, subscription, user }: Props) => {
    const dispatch = useDispatch();
    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));

    const billingContext = useBillingContext();

    const refreshSubscription = ({ external_id }) => dispatch(sub.api.refreshCache({ external_id }));
    const refreshUser = ({ email }) => dispatch(usr.api.get({ email }));

    const { customer: stripeCustomer } = useSubscriptionCustomer(subscription);

    const eventForDialogSteps = {
        licenses: {
            confirm: 'checkout.add_license_next',
        },
        confirm: {
            licenses: 'checkout.add_license_back',
        },
    };

    const onPaymentComplete = async (showToast = true) => {
        await refreshUser(user);
        closeDialog();

        await refreshSubscription({ external_id: user.latest_subscription_external_id });

        if (showToast) {
            Toaster.show({
                icon: 'tick',
                intent: Intent.SUCCESS,
                message: (
                    <ToastSummary>
                        <span>Successfully updated your HelioScope subscription</span>
                    </ToastSummary>
                ),
                timeout: 5000,
            });
        }
    };

    const [setWatchedSubID, watchedSubID, paymentSuccessNotified] = useWatchSubscription(
        null,
        'invoice.paid',
        onPaymentComplete,
    );

    const updateLicenses = async (
        params: sub.AddLicensesForm | sub.RemoveLicensesForm,
        formData: Omit<FormData, 'quantity'>,
    ) => {
        const isAddingLicenses = params.total_desired_seats > subscription.paid_seats;
        const { card, email, paymentMethod, purchaseOrder } = formData;

        if (isAddingLicenses) {
            const collectionMethod = paymentMethod === 'invoice' ? 'send_invoice' : 'charge_automatically';
            let addLicensesParams: sub.AddLicensesForm = {
                ...params,
                collection_method: collectionMethod,
            };

            if (paymentMethod === 'invoice') {
                const { billingDetails } = await validateBillingInfo(formData);
                const { address, name } = billingDetails.value;
                addLicensesParams = {
                    ...addLicensesParams,
                    payment_method_id: paymentMethod,
                    billing_info: {
                        email,
                        name,
                        address,
                        purchase_order: purchaseOrder,
                    },
                };
            }

            const { payment_intent_client_secret } = await dispatch(
                sub.api.addLicenses({ ...addLicensesParams }, { external_id: subscription.external_id }),
            );

            if (payment_intent_client_secret) {
                await validateBillingInfo({ card, email, paymentMethod });
                const amount = subscription.latest_invoice!.amount_due;
                await confirmCardPayment(payment_intent_client_secret, amount);
            }

            setWatchedSubID(subscription.external_id);
        } else {
            await dispatch(sub.api.removeLicenses(params, { external_id: subscription.external_id }));
            onPaymentComplete(true);
        }
    };

    const { confirmCardPayment, validateBillingInfo, setupNewCard } = useStripeData();

    const onSubmit = async (
        subscription: sub.Subscription,
        { card, email, paymentMethod, quantity, purchaseOrder }: FormData,
    ) => {
        analytics.track('checkout.confirm_and_pay', {
            current_subscription_values: {
                quantity: user.subscription?.paid_seats,
            },
            new_subscription_values: {
                quantity,
            },
            collection_method: paymentMethod === 'invoice' ? 'send_invoice' : 'charge_automatically',
        });
        // If we are adding a new card, we need to create a setup intent for that card.
        if (paymentMethod === 'credit_card') {
            await validateBillingInfo({ card, email, paymentMethod });

            const paymentMethodId = await setupNewCard();

            await updateLicenses(
                {
                    payment_method_id: paymentMethodId,
                    total_desired_seats: quantity,
                },
                { card, email, paymentMethod, purchaseOrder },
            );
            return subscription;
        }

        // If switching to invoice
        let params = {
            total_desired_seats: quantity,
            payment_method_id: '',
        };
        if (paymentMethod === 'invoice') {
            params = { ...params, payment_method_id: paymentMethod };
        }
        await updateLicenses(params, { card, email, paymentMethod, purchaseOrder });

        return subscription;
    };

    if (!config || !subscription) {
        return <></>;
    }

    return (
        <Form
            baseValue={{
                email: stripeCustomer?.email || user.email,
                quantity: subscription?.paid_seats || 1,
                paymentMethod: subscription?.is_pay_by_invoice ? 'invoice' : 'card',
                purchaseOrder: stripeCustomer?.purchaseOrder?.value,
            }}
            exceptionHandler={(exc) => handleFormException(exc)}
            onSubmit={async (formData: FormData) => await onSubmit(subscription, formData)}
            keepStateOnSubmit={true}
        >
            {({
                clearForm,
                formData: { billingDetails, card, paymentMethod, quantity },
                formErrors,
                submitting,
                submitForm,
                updateValue,
            }) => {
                const formSubmitting: boolean = !!(submitting || (watchedSubID && !paymentSuccessNotified));

                return (
                    <StyledMultistepDialog
                        canEscapeKeyClose={false}
                        canOutsideClickClose={false}
                        nextButtonProps={{
                            disabled: quantity < subscription.used_seats,
                        }}
                        finalButtonProps={{
                            text:
                                paymentMethod !== 'invoice' && quantity > subscription.paid_seats
                                    ? 'Confirm and Pay'
                                    : 'Confirm Plan Change',
                            onClick: () => submitForm({ quantity }),
                            disabled:
                                formSubmitting ||
                                quantity < subscription.used_seats ||
                                (paymentMethod === 'credit_card' && !card?.complete) ||
                                (paymentMethod === 'invoice' && !user.team.pay_by_invoice_enabled),
                            loading: formSubmitting,
                        }}
                        title="Change Team Size"
                        isOpen={isOpen}
                        closeButtonProps={{
                            disabled: formSubmitting,
                        }}
                        backButtonProps={{
                            disabled: formSubmitting,
                        }}
                        onOpening={() =>
                            analytics.track('checkout.open', {
                                referrer: `${billingContext.referrer}_change_team_size`,
                            })
                        }
                        onChange={(newDialogStepId: DialogStepId, prevDialogStepId: DialogStepId) => {
                            if (prevDialogStepId === 'confirm' && newDialogStepId !== 'confirm') {
                                updateValue('card', undefined);
                            }
                            const eventName = eventForDialogSteps[prevDialogStepId][newDialogStepId];
                            analytics.track(eventName, {
                                current_subscription_values: {
                                    quantity: user.subscription?.paid_seats,
                                },
                                new_subscription_values: {
                                    quantity,
                                },
                            });
                        }}
                        onClose={() => {
                            analytics.track('checkout.close', {});
                            clearForm();
                            closeDialog();
                        }}
                    >
                        <DialogStep
                            id="licenses"
                            nextButtonProps={{
                                disabled:
                                    quantity === subscription.paid_seats || quantity < subscription.subscribers.length,
                            }}
                            title="Change Team Size"
                            panel={
                                <Licenses
                                    interval={subscription.price.recurring.interval}
                                    price={subscription.price}
                                    quantity={quantity}
                                    subscription={subscription}
                                    user={user}
                                />
                            }
                        />
                        <DialogStep
                            id="confirm"
                            title="Confirm Plan Change"
                            panel={
                                <PlanPayment
                                    collectBillingInformation={false}
                                    disabled={quantity < subscription.paid_seats || formSubmitting}
                                    formErrors={formErrors}
                                    mapsAPIKey={config?.google_maps_api_key}
                                    paymentMethod={paymentMethod}
                                    address={billingDetails?.address}
                                    price={subscription.price}
                                    quantity={quantity}
                                    submitting={formSubmitting}
                                    subscription={subscription}
                                    user={user}
                                    interval={subscription.price.recurring.interval}
                                    promptTermsOfUse={false}
                                />
                            }
                        />
                    </StyledMultistepDialog>
                );
            }}
        </Form>
    );
};

const TeamSizeDialog = (props: Props) => {
    return (
        <StripeContainer>
            <_TeamSizeDialog {...props} />
        </StripeContainer>
    );
};

export { TeamSizeDialog };
