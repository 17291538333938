import * as React from 'react';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import { RouteComponent } from 'reports/routing';

import { AccountStatusBox } from 'reports/modules/settings/billing/components/AccountStatusBox';
import { SettingsPane } from 'reports/modules/settings/common';

import { TeamManagement, TeamOverview, TeamIntegrations, TeamThemes } from '../components';
import { useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

const TeamSettingsViews = React.memo(() => {
    const dispatch = useDispatch();
    const navigateToBilling = () => {
        dispatch(
            routerActions.navigateTo('app.settings.team.billing', {
                dialog: 'licenses',
                message: 'You need to purchase a license before you can invite new team members to a seat.',
                referrer: ReferrerTypes.manage_team_page,
            }),
        );
        return;
    };

    return (
        <>
            <RouteComponent name="app.settings.team.overview">
                {({ team, user }) => <SettingsPane content={<TeamOverview team={team} user={user} />} />}
            </RouteComponent>

            <RouteComponent name="app.settings.team.manage" exact={false}>
                {({ team, subscription, user }) => (
                    <SettingsPane
                        content={
                            <TeamManagement
                                navigateToBilling={navigateToBilling}
                                team={team}
                                subscription={subscription}
                                user={user}
                            />
                        }
                        card={<AccountStatusBox subscription={subscription} user={user} />}
                    />
                )}
            </RouteComponent>

            <RouteComponent name="app.settings.team.integrations">
                <SettingsPane content={<TeamIntegrations />} />
            </RouteComponent>

            <RouteComponent name="app.settings.team.themes">
                {({ team }) => <SettingsPane content={<TeamThemes team={team} />} />}
            </RouteComponent>
        </>
    );
});

export default TeamSettingsViews;
