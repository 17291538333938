/* tslint:disable:variable-name */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconNames } from '@blueprintjs/icons';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';
import { UpsellBanner } from 'reports/components/UpsellBanner';

import * as finTemp from 'reports/models/financial_template';

import * as auth from 'reports/modules/auth';
import { actions } from 'reports/modules/financials/';
import { FinancialTemplatesList } from 'reports/modules/financials/components/financial_template';
import { IAppState } from 'reports/types';

type Props = {
    finTemplateId?: number;
    hideUpsellBanner?: boolean;
    inlineControls?: boolean;
    navigateToFinTemplate: (finTemplateId: number) => void;
    navigateToFinTempPreview: (finTemplateId: number) => void;
    preview?: React.ReactNode;
};

const FinancialTemplateLibrary = React.memo(
    ({
        finTemplateId,
        hideUpsellBanner,
        inlineControls,
        navigateToFinTemplate,
        navigateToFinTempPreview,
        preview,
    }: Props) => {
        const dispatch = useDispatch();
        const refreshItem = useSelector(
            (state) => (item) =>
                finTemp.selectors.byObject(state as IAppState, item, {
                    creator: true,
                    team: true,
                }) || item,
        );
        const user = useSelector((state) => auth.selectors.getUser(state)!);
        const createFinancialModel = () => dispatch(actions.createModel());
        const loadItems = (args) => dispatch(finTemp.api.index(args));

        const createSimpleModel = async () => {
            const model = await createFinancialModel();
            navigateToFinTemplate(model.financial_template_id);
        };

        return (
            <>
                {!hideUpsellBanner && <UpsellBanner />}
                <LibraryMain
                    loadItems={loadItems}
                    refreshItem={refreshItem}
                    resourceIdName="financial_template_id"
                    views={[
                        {
                            view: <FinancialTemplatesList navigateToFinTempPreview={navigateToFinTempPreview} />,
                            icon: IconNames.LIST,
                            id: 'financial-template-list',
                        },
                    ]}
                    id="financial-models-views"
                    quickFilters={[
                        {
                            name: 'bookmarked',
                            icon: IconNames.STAR,
                            text: 'Favorited',
                        },
                        {
                            name: 'team_id',
                            icon: IconNames.PEOPLE,
                            text: `Only Show My Team`,
                            value: user.team.team_id,
                        },
                    ]}
                    contextBarControls={<CreateButton text="New Financial Model" onClick={() => createSimpleModel()} />}
                    preview={preview}
                    selectedItemId={finTemplateId}
                    inlineControls={inlineControls}
                />
            </>
        );
    },
);

export { FinancialTemplateLibrary };
