import * as React from 'react';
import * as analytics from 'reports/analytics';
import { IUpsellContent, ReferrerType } from 'reports/utils/UpsellDialogConstants';

import UpsellDialogContainer from './UpsellDialogContainer';

interface IUpsellDialogLauncherContext {
    openDialog: () => void;
}

interface IProps {
    children: (context: IUpsellDialogLauncherContext) => JSX.Element;
    content: IUpsellContent;
    referrer: ReferrerType;
    disabled: boolean;
}

export default function UpsellDialogLauncher(props: IProps) {
    const { children, content, disabled, referrer } = props;
    const { modalName } = content;

    const [showDialog, setShowDialog] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (showDialog) {
            analytics.track('paywall.open', { referrer, modal_name: modalName });
        }
    }, [showDialog]);

    return (
        <>
            {children({
                openDialog: () => (!disabled ? setShowDialog(true) : null),
            })}
            <UpsellDialogContainer content={content} isOpen={showDialog} onCancel={() => setShowDialog(false)} />
        </>
    );
}
