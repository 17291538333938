import * as React from 'react';

import { LayoutChart } from 'reports/modules/report/components/charts';
import { stringifyNumber } from 'reports/utils/formatters';
import { ChartConfig } from './UsageGraphContainer';
import * as styles from 'reports/styles/styled-components';

const styled = styles.styled;

const StyledSection = styled.div`
    padding: 10px 10px 10px 0px;
    background: #f5f8fa;
`;

export const MIN_YAXIS_TICK_INCREMENTS = 2;
export const MIN_YAXIS_TICKS = 4;
const MAX_MONTHLY_PROJECT_COUNT = 10;
// Set to match the height of the adjacent PlanInfo
const DEFAULT_CHART_HEIGHT = 370;

function makeConfig(consumptionConfig: ChartConfig): Highcharts.Options {
    const yAxisMax = Math.max(...consumptionConfig.yAxisData);
    const yAxisMin = Math.min(...consumptionConfig.yAxisData);

    return {
        chart: {
            type: 'column',
            backgroundColor: '#F5F8FA',
            style: {
                fontFamily: 'Helvetica',
            },
        },
        title: {
            text: 'Project Usage',
            align: 'left',
            x: 10,
            style: {
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#333333',
            },
        },
        subtitle: {
            text: `Your team used a total of ${stringifyNumber(
                consumptionConfig.numProjects,
            )} projects in the last 12 months`,
            align: 'left',
            x: 10,
            style: {
                fontSize: '12px',
                paddingBottom: '4px',
                color: '#333333',
            },
        },
        xAxis: {
            categories: consumptionConfig.xAxisCategories,
            crosshair: true,
            tickmarkPlacement: 'on',
            labels: {
                rotation: -60,
                y: 20,
                style: {
                    fontSize: '10px',
                    color: '#7B7B7B',
                },
            },
            title: {
                text: 'Month/Year',
                style: {
                    fontSize: '10px',
                    padding: '8px',
                    color: '#7B7B7B',
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: 'No. of projects',
                style: {
                    fontSize: '10px',
                    color: '#7B7B7B',
                },
            },
            labels: {
                style: {
                    fontSize: '8px',
                    color: '#7B7B7B',
                },
            },
            startOnTick: false,
            endOnTick: false,
            softMax: MIN_YAXIS_TICK_INCREMENTS,
            allowDecimals: false,
            tickInterval: yAxisMax > MAX_MONTHLY_PROJECT_COUNT ? null : MIN_YAXIS_TICK_INCREMENTS,
            // *** this property is only utilized if the tickInterval property is set to null. ***
            // calculates interval length based on min and max y-axis values and ensures at least 4 ticks are rendered on graph
            // also rounds up to nearest multiple of 10
            tickPixelInterval: Math.ceil((yAxisMax - yAxisMin) / MIN_YAXIS_TICKS / 10) * 10,
        },
        tooltip: {
            pointFormatter(this: any) {
                return `<b>${stringifyNumber(this.y)}</b>`;
            },
            headerFormat: '',
            shared: true,
            useHTML: true,
        },
        legend: { enabled: false },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                color: '#60C4F4',
            },
        },
        series: [
            {
                data: consumptionConfig.yAxisData,
            },
        ],
        credits: {
            enabled: false,
        },
    };
}

interface IProps {
    consumptionConfig: ChartConfig;
}

export const UsageGraph = ({ consumptionConfig }: IProps) => {
    return (
        <StyledSection>
            <LayoutChart config={makeConfig(consumptionConfig)} style={{ height: DEFAULT_CHART_HEIGHT }} />
        </StyledSection>
    );
};
