import * as React from 'react';

import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { FormGroup } from '@blueprintjs/core';

import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';
import {
    OBJECT_FIT_OPTIONS,
    ObjectFitType,
    HorizPositionType,
    VertPositionType,
    VERT_POSITION_OPTIONS,
    HORIZ_POSITION_OPTIONS,
} from 'reports/modules/report/components/widgets/custom_image/types';
import Translations from 'reports/localization/strings';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const FitGridContainer = styled.div`
    && {
        display: grid;
    }
    grid-template-columns: 1fr 2fr;
    grid-gap: 5px;
    padding-top: 10px;
    #label {
        margin-top: 5px;
    }
`;

const PositionGridContainer = styled.div`
    // Override display flex styles from PanelBody.
    && {
        display: grid;
    }
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    #label {
        grid-row: 1 / span 2;
        align-self: start;
        margin-top: 5px;
    }
`;

interface IOwnProps {
    selectedFit: ObjectFitType;
    selectedVertPosition: VertPositionType;
    selectedHorizPosition: HorizPositionType;
    onSelectFit: (fit: ObjectFitType) => void;
    onSelectVert: (vert: VertPositionType) => void;
    onSelectHoriz: (horiz: HorizPositionType) => void;
}

type IProps = IOwnProps & { intl: IntlShape };

class CustomImageFormIntl extends React.PureComponent<IProps> {
    render() {
        const {
            selectedFit,
            onSelectFit,
            selectedVertPosition,
            onSelectVert,
            selectedHorizPosition,
            onSelectHoriz,
            intl,
        } = this.props;
        const { formatMessage } = intl;

        return (
            <>
                <FitGridContainer>
                    <b id="label">
                        <FormattedMessage {...Translations.image.image_fit} />
                    </b>
                    <FormGroup helperText={<FormattedMessage {...OBJECT_FIT_OPTIONS[selectedFit].helperTranslation} />}>
                        <BasicSelect<ObjectFitType>
                            itemRenderer={(fit: ObjectFitType) => ({
                                key: fit,
                                text: formatMessage(OBJECT_FIT_OPTIONS[fit].name),
                            })}
                            dataSource={{
                                items: Object.keys(OBJECT_FIT_OPTIONS),
                            }}
                            value={selectedFit}
                            onChange={(fit) => onSelectFit(fit)}
                            fill={true}
                            matchSelectWidth={true}
                        />
                    </FormGroup>
                </FitGridContainer>
                {selectedFit !== 'fill' && (
                    <PositionGridContainer>
                        <b id="label">
                            <FormattedMessage {...Translations.general.position} />
                        </b>
                        <span>
                            <FormattedMessage {...Translations.general.position_vertical} />
                        </span>
                        <BasicSelect<VertPositionType>
                            itemRenderer={(pos: VertPositionType) => ({
                                key: pos,
                                text: formatMessage(VERT_POSITION_OPTIONS[pos].name),
                            })}
                            dataSource={{
                                items: Object.keys(VERT_POSITION_OPTIONS),
                            }}
                            value={selectedVertPosition}
                            onChange={(pos) => onSelectVert(pos)}
                            fill={true}
                            matchSelectWidth={true}
                        />
                        <span>
                            <FormattedMessage {...Translations.general.position_horizontal} />
                        </span>
                        <BasicSelect<HorizPositionType>
                            itemRenderer={(pos: HorizPositionType) => ({
                                key: pos,
                                text: formatMessage(HORIZ_POSITION_OPTIONS[pos].name),
                            })}
                            dataSource={{
                                items: Object.keys(HORIZ_POSITION_OPTIONS),
                            }}
                            value={selectedHorizPosition}
                            onChange={(pos) => onSelectHoriz(pos)}
                            fill={true}
                            matchSelectWidth={true}
                        />
                    </PositionGridContainer>
                )}
            </>
        );
    }
}

const CustomImageForm = injectIntl(CustomImageFormIntl);

export default CustomImageForm;
