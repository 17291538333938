import * as React from 'react';

import { useDispatch } from 'react-redux';

import { capitalize } from 'lodash';
import { styled } from 'reports/styles/styled-components';

import Section2 from 'reports/components/core/containers/Section2';
import {
    CategoryTitle,
    DetailLine,
    HelperText,
    SectionContainer,
    SubSectionContainer,
} from 'reports/modules/settings/common';
import { Icon, Spinner } from '@blueprintjs/core';

import { StripePortalButton } from './StripePortalButton';

import { api as CustomerAPI } from 'reports/models/stripe/stripe_customer';
import { api as TeamApi } from 'reports/models/team';

import { Subscription } from 'reports/models/subscription';
import { PusherChannel } from 'reports/modules/project/listeners';

import { useSubscriptionCustomer } from '../Hooks';
import { Link } from 'reports/components/core/controls';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import { IconNames } from '@blueprintjs/icons';
import { Address } from '@stripe/stripe-js';

interface Props {
    subscription?: Subscription;
    subChannel: PusherChannel | undefined;
    manageBilling: boolean;
}

const SubSection = styled(SubSectionContainer)`
    margin-top: 5px;
`;

type UnformattedAddressProps = {
    address: Address;
};

const UnformattedAddress = ({ address }: UnformattedAddressProps) => (
    <>
        <DetailLine>{address.line1}</DetailLine>
        <DetailLine>{address.line2}</DetailLine>
        <DetailLine>{address.city}</DetailLine>
        <DetailLine>
            {address.state} {address.postal_code}
        </DetailLine>
        <DetailLine>{address.country}</DetailLine>
    </>
);

const PaymentDetails = ({ subscription, subChannel, manageBilling }: Props) => {
    if (!manageBilling) {
        return null;
    }

    const dispatch = useDispatch();
    const getCustomer = ({ hs_subscription_external_id }) =>
        dispatch(CustomerAPI.getStripeCustomer({ hs_subscription_external_id }));
    const { customer: stripeCustomer, isLoading, setCustomer } = useSubscriptionCustomer(subscription);
    const reloadTeam = (teamId: number) => dispatch(TeamApi.get({ team_id: teamId }));

    React.useEffect(() => {
        if (subChannel) {
            subChannel.watch('invoice.paid', async () => {
                const customer = await getCustomer({ hs_subscription_external_id: subscription?.external_id });
                setCustomer(customer);
            });
            subChannel.watch('customer.updated', async () => {
                const customer = await getCustomer({ hs_subscription_external_id: subscription?.external_id });
                setCustomer(customer);
                await reloadTeam(subscription!.team.team_id);
            });
        }
        return () => {};
    }, [subChannel]);

    const card = stripeCustomer?.invoice_settings?.default_payment_method?.card;

    if (isLoading) {
        return <Spinner />;
    }

    if (stripeCustomer === null) {
        return (
            <Section2 title="Payment Details">
                <HelperText large>Stripe customer deleted. Please restart trial.</HelperText>
            </Section2>
        );
    }

    return !subscription || stripeCustomer === undefined ? (
        <Section2 title="Payment Details">
            <HelperText large>There is no payment information on your account right now</HelperText>
        </Section2>
    ) : (
        <Section2 title="Payment Details">
            <SectionContainer>
                <CategoryTitle>Payment Method</CategoryTitle>
                <SubSection>
                    {subscription.is_pay_by_invoice ? (
                        'Invoice'
                    ) : card ? (
                        <>
                            {capitalize(card.brand)} {card.last4} - Expires {card.exp_month}/{card.exp_year}
                        </>
                    ) : (
                        'Unknown'
                    )}
                </SubSection>
                {!subscription.is_pay_by_invoice && (
                    <StripePortalButton
                        fill={false}
                        name="paymentMethod"
                        subscription={subscription}
                        text="Edit payment method"
                        icon={<Icon icon={IconNames.DOCUMENT_OPEN} />}
                        asLink
                    />
                )}
            </SectionContainer>
            {stripeCustomer && (
                <>
                    <SectionContainer>
                        <CategoryTitle>Billing Information</CategoryTitle>
                        <SubSection>
                            <DetailLine>{stripeCustomer.name}</DetailLine>
                            <DetailLine>{stripeCustomer.email}</DetailLine>
                        </SubSection>
                    </SectionContainer>
                    <SectionContainer>
                        <CategoryTitle> </CategoryTitle>
                        <SubSection>
                            {subscription.team.formatted_billing_address.length === 0 ? (
                                <UnformattedAddress address={stripeCustomer.address} />
                            ) : (
                                subscription.team.formatted_billing_address.map((addressLine, index) => (
                                    <div key={`addrline-${index}`}>{addressLine}</div>
                                ))
                            )}
                        </SubSection>
                        <Link
                            routeName="app.settings.team.billing"
                            searchParams={{ referrer: ReferrerTypes.billing_page, dialog: 'billing' }}
                        >
                            Edit billing information
                        </Link>
                    </SectionContainer>
                    <SectionContainer>
                        <CategoryTitle>Purchase Order</CategoryTitle>
                        <SubSection>
                            <DetailLine>{stripeCustomer?.purchaseOrder?.value}</DetailLine>
                        </SubSection>
                        <Link
                            routeName="app.settings.team.billing"
                            searchParams={{ referrer: ReferrerTypes.billing_page, dialog: 'billing' }}
                        >
                            Edit purchase order
                        </Link>
                    </SectionContainer>
                    <SectionContainer>
                        <CategoryTitle>Tax IDs</CategoryTitle>
                        <SubSection>
                            {stripeCustomer.taxIDs.map((taxID, index) => (
                                <div key={`taxid-${index}`}>{taxID.value}</div>
                            ))}
                        </SubSection>

                        <StripePortalButton
                            fill={false}
                            name="billingInfo"
                            subscription={subscription}
                            text="Edit Tax IDs"
                            icon={<Icon icon={IconNames.DOCUMENT_OPEN} />}
                            asLink
                        />
                    </SectionContainer>
                </>
            )}
        </Section2>
    );
};

export { PaymentDetails };
