import { useDispatch } from 'react-redux';
import React from 'react';

import { Intent } from '@blueprintjs/core';

import { api as SSOProviderApi, SSOProvider } from 'reports/models/sso_provider';

import { Toaster } from 'reports/modules/Toaster';
import { IntegrationOption } from './ExternalIntegrationsList';

import SSOCredentialsDialog, { clientSecretPlaceholder } from './SSOCredentialsDialog';

import { User } from 'reports/models/user';
import SSOCredentialsConfirmDialog from 'reports/modules/settings/team/components/SSOCredentialsConfirmDialog';

interface SSOCredentialsContainerProps {
    handleClose: () => void;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    options: IntegrationOption;
    user: User;
}

const SSOCredentialsContainer = ({ handleClose, isOpen, setIsOpen, options, user }: SSOCredentialsContainerProps) => {
    const dispatch = useDispatch();
    const [ssoProvider, setSSOProvider] = React.useState<SSOProvider>(new SSOProvider({}));
    const [loadingSSOProvider, setLoadingSSOProvider] = React.useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);
    const [loadingDeleteSSOProvider, setLoadingDeleteSSOProvider] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoadingSSOProvider(true);
            try {
                const ssoProvider = await dispatch(SSOProviderApi.get({ teamId: user.team_id }));
                setSSOProvider(ssoProvider);
            } catch {
                setSSOProvider(new SSOProvider({}));
            }
            setLoadingSSOProvider(false);
        };

        fetchData();
    }, []);

    const onSubmit = async (formData: SSOProvider) => {
        if (formData.client_secret === clientSecretPlaceholder) {
            delete formData.client_secret;
        }

        const ssoProvider = await dispatch(
            formData.sso_provider_id ? SSOProviderApi.save(formData) : SSOProviderApi.create(formData),
        );
        setSSOProvider(ssoProvider as SSOProvider);
        Toaster.show({
            intent: Intent.SUCCESS,
            message: `Successfully saved SSO Provider`,
            timeout: 5000,
        });
    };

    const onConfirm = async () => {
        setLoadingDeleteSSOProvider(true);
        try {
            await dispatch(SSOProviderApi.delete({ sso_provider_id: ssoProvider.sso_provider_id }));
        } catch {
            Toaster.show({
                intent: Intent.DANGER,
                message: `Failed to remove Single Sign-On.`,
                timeout: 5000,
            });
            setLoadingDeleteSSOProvider(false);
            return;
        }
        setSSOProvider(new SSOProvider({}));
        setConfirmDialogOpen(false);
        handleClose();
        Toaster.show({
            intent: Intent.SUCCESS,
            message: `Single Sign-On removed.`,
            timeout: 5000,
        });
        setLoadingDeleteSSOProvider(false);
    };

    const onConfirmClose = () => {
        setConfirmDialogOpen(false);
        setIsOpen(true);
    };

    const onDeleteButtonCLicked = () => {
        setConfirmDialogOpen(true);
        setIsOpen(false);
    };

    return (
        <>
            <SSOCredentialsDialog
                handleClose={handleClose}
                isOpen={isOpen}
                onSubmit={onSubmit}
                options={options}
                ssoProvider={ssoProvider}
                loadingSSOProvider={loadingSSOProvider}
                onDeleteButtonClicked={onDeleteButtonCLicked}
            />
            <SSOCredentialsConfirmDialog
                isOpen={confirmDialogOpen}
                onConfirm={onConfirm}
                onClose={() => onConfirmClose()}
                loading={loadingDeleteSSOProvider}
            />
        </>
    );
};

export default SSOCredentialsContainer;
