import * as React from 'react';

import { HTMLTable } from '@blueprintjs/core';

import { Subsection, SubsectionHeader } from './OverviewPanel';
import * as ur from 'reports/models/utility_rate';
import { INumericConfig, ParamNumeric } from 'reports/modules/financials/components/ParamNumeric';
import { ParamValueType } from 'reports/modules/financials/params';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';
import { Switch } from './UtilityRateEditor';

interface IOwnProps {
    minimumBill: ur.IMinimumBill;
    onUpdate: (propPath: string, val: any) => void;
}

const amountParam: INumericConfig = {
    type: ParamValueType.Currency,
    min_value: 0.0,
    max_value: 9999.0,
    default: 0.0,
    precision: 4,
};

const minimumBillTypeNames = {
    flat: '$/month',
    day: '$/day',
};
type MinimumBillType = keyof typeof minimumBillTypeNames;
const minimumBillTypes = Object.keys(minimumBillTypeNames) as MinimumBillType[];

const MinimumBill: React.FunctionComponent<IOwnProps> = ({ minimumBill, onUpdate }) => {
    const { amount, type, active } = minimumBill;

    return (
        <Subsection>
            <SubsectionHeader title="Minimum Bill" />
            <div style={{ fontSize: 12 }}>
                <HTMLTable>
                    <tr>
                        <td style={{ verticalAlign: 'middle' }}>
                            <Switch
                                checked={!!active}
                                onChange={() =>
                                    onUpdate('minimum_bill', {
                                        ...minimumBill,
                                        active: !active,
                                    })
                                }
                            />
                        </td>
                        <td>
                            <ParamNumeric
                                parameter={amountParam}
                                value={amount}
                                updateFn={(val) =>
                                    onUpdate('minimum_bill', {
                                        ...minimumBill,
                                        amount: val,
                                    })
                                }
                                disabled={!active}
                            />
                        </td>
                        <td>
                            <BasicSelect<'flat' | 'day'>
                                dataSource={{
                                    items: minimumBillTypes,
                                }}
                                itemRenderer={(item: MinimumBillType) => ({
                                    key: item,
                                    text: minimumBillTypeNames[item],
                                })}
                                value={type}
                                onChange={(item) =>
                                    onUpdate('minimum_bill', {
                                        ...minimumBill,
                                        type: item,
                                    })
                                }
                                disabled={!active}
                                fill
                                matchSelectWidth
                            />
                        </td>
                    </tr>
                </HTMLTable>
            </div>
        </Subsection>
    );
};

export default MinimumBill;
