import * as React from 'react';
import { useDispatch } from 'react-redux';

import { ReferrerType } from 'reports/analytics/ReferrerTypes';

import { Subscription, api as subApi } from 'reports/models/subscription';
import { TeamLimitsAndUsage, api as teamLimitsAndUsageAPI } from 'reports/models/team_usage';
import { User } from 'reports/models/user';

import { ContractPlanInfo } from './ContractPlanInfo';
import { SelfServePlanInfo } from './SelfServePlanInfo';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const PlanInfoWrapper = styled.div`
    width: auto;
`;

interface Props {
    manageBilling: boolean;
    subscription: Subscription;
    user: User;
    referrer: ReferrerType;
}

const PlanInfo = ({ manageBilling, subscription, user, referrer }: Props) => {
    const dispatch = useDispatch();
    const [teamLimitsAndUsage, setTeamLimitsAndUsage] = React.useState<TeamLimitsAndUsage>();

    React.useEffect(() => {
        (async () => {
            const limitsAndUsage = await fetchTeamLimitsAndUsage({ teamId: user.team_id });
            setTeamLimitsAndUsage(limitsAndUsage);
        })();
        return () => {};
    }, [user?.team_id]);

    const cancelScheduledChanges = async ({ external_id }: { external_id: string }) =>
        await dispatch(subApi.cancelScheduledChanges({ external_id }));

    const fetchTeamLimitsAndUsage = async ({ teamId }) =>
        await dispatch(teamLimitsAndUsageAPI.get({ team_id: teamId }));

    return (
        <PlanInfoWrapper>
            {subscription.is_contract && (
                <ContractPlanInfo
                    currentUsageAndLimits={teamLimitsAndUsage}
                    referrer={referrer}
                    subscription={subscription}
                    user={user}
                />
            )}
            {subscription.is_self_serve && (
                <SelfServePlanInfo
                    cancelScheduledChanges={cancelScheduledChanges}
                    currentUsageAndLimits={teamLimitsAndUsage}
                    manageBilling={manageBilling}
                    referrer={referrer}
                    subscription={subscription}
                    user={user}
                />
            )}
        </PlanInfoWrapper>
    );
};

export { PlanInfo };
