/* tslint:disable:variable-name function-name */
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { GeoPoint } from 'helioscope/app/utilities/geometry';

import { schema } from './schema';
import { UsageProfile, schemaObj as upSchemaObj } from './usage_profile';

class UsageSite extends BaseClass {
    usage_site_id: number;
    usage_profiles: UsageProfile[];
    location: GeoPoint;
    name: string;

    constructor(data: any) {
        super(UsageSite.deserialize(data));
    }

    static deserialize = BaseClass.getDeserializer({
        location: (x) => new GeoPoint(x),
    });

    toString() {
        // if (this._distance) {
        //     // const distanceFilter = $filter('hsDistance');
        //     return `${this.name} (${distanceFilter(this._distance, 1, true)})`;
        // } else {
        return this.name;
        // }
    }
}

const schemaObj = schema.addObject(UsageSite, 'usage_site');

const { backrefSelector: usageProfiles } = upSchemaObj.addRelationship('usage_site', schemaObj, {
    backref: 'usage_profiles',
});

const endpoint = ReduxEndpoint.fromSchema('/api/usage_sites/', schemaObj, {
    deepSelect: { usage_profiles: '*' },
});

const api = {
    index: endpoint.index<{
        location: GeoPoint;
        distance?: number;
        limit?: number;
        detail?: boolean;
    }>(),
    get: endpoint.get<{ usage_site_id: number }>('{usage_site_id}'),
};

const selectors = {
    usageProfiles,
    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

export { schemaObj, UsageSite, api, selectors };
