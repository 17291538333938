/* tslint:disable:variable-name function-name */

import { BaseClass } from 'reports/utils/api';
import { schema } from 'reports/models/schema';
import * as mod from 'reports/models/module';
import type { Scenario } from './Scenario';

class ScenarioModuleCharacterization extends BaseClass {
    scenario_id: number;
    scenario: Scenario;

    // the module used in the design
    module_id: number;
    module: mod.Module;

    // the characterization used to model it
    module_characterization_id: number;
    module_characterization: mod.ModuleCharacterization;
}

const schemaObj = schema.addObject(ScenarioModuleCharacterization, 'scenario_module_characterization', {
    compositeKeyFn: (obj) => obj.scenario_id + ',' + obj.module_id,
    relationships: {
        module: { schema: mod.schemaObj },
        module_characterization: { schema: mod.charSchemaObj },
    },
    defaultDeep: { module: '*', module_characterization: '*' },
});

export default ScenarioModuleCharacterization;

export { ScenarioModuleCharacterization, schemaObj };
