import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLoaderData, useNavigate, useParams } from 'react-router';

import { FinancialTemplate, selectors as finTemplateSelectors } from 'reports/models/financial_template';
import { FinancialTemplateLibrary } from 'reports/modules/financials/components/financial_template/FinancialTemplateLibrary';
import { FinModelPreview } from 'reports/modules/financials/components/financial_template/views/FinModelPreview';
import { IAppState } from 'reports/types';

const FinancialModelPreview = () => {
    const params = useParams();
    const navigate = useNavigate();
    const finTemplateId = params.finTemplateId ? Number(params.finTemplateId) : undefined;
    const navigateToFinTemplates = () => {
        navigate({
            pathname: generatePath('/financial-models'),
        });
    };
    const navigateToFinTemplate = (finTemplateId) => {
        navigate({
            pathname: generatePath('/financial-models/:finTemplateId/edit', {
                finTemplateId: finTemplateId.toString(),
            }),
        });
    };
    const navigateToFinTempPreview = (finTemplateId) => {
        navigate({
            pathname: generatePath('/financial-models/:finTemplateId/preview', {
                finTemplateId: finTemplateId.toString(),
            }),
        });
    };

    const finTemplateToSelect = useLoaderData() as FinancialTemplate;
    const finTemplate = useSelector((state) =>
        finTemplateSelectors.byObject(state as IAppState, {
            financial_template_id: finTemplateToSelect.financial_template_id,
        }),
    );

    if (!finTemplate) {
        throw new Response('Not Found', { status: 404 });
    }
    return (
        <FinancialTemplateLibrary
            finTemplateId={finTemplateId}
            hideUpsellBanner={true}
            navigateToFinTemplate={navigateToFinTemplate}
            navigateToFinTempPreview={navigateToFinTempPreview}
            preview={
                <FinModelPreview
                    model={finTemplate}
                    navigateToFinTemplate={navigateToFinTemplate}
                    navigateToFinTemplates={navigateToFinTemplates}
                />
            }
        />
    );
};

export { FinancialModelPreview };
