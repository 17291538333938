import { chain } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { Menu, MenuItem, Popover, Position } from '@blueprintjs/core';

import { bindActions } from 'reports/utils/redux';

import { Project, selectors } from 'reports/models/project';
import { api as scenApi } from 'reports/models/scenario';
import { selectors as userSelectors } from 'reports/modules/auth';

import { actions } from 'reports/modules/project';

interface IScenarioMenuProps {
    project: Project;
    formatters: any;
    children: any;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

const IntScenarioMenu: React.SFC<IScenarioMenuProps & IStateProps & IDispatchProps> = ({
    project,
    scenarios,
    activeScenario,
    onSelect,
    formatters,
    children,
    loadScenarios,
}) => {
    React.useEffect(() => {
        loadScenarios(project);
    }, [project.project_id]);

    const designRows = chain(scenarios)
        .sortBy('description')
        .map((scenario) => (
            <MenuItem
                key={scenario.scenario_id}
                text={
                    scenario.description +
                    ` (${scenario.weather_dataset.name}, ${scenario.weather_dataset.weather_source.name})`
                }
                label={
                    project == null
                        ? ''
                        : formatters.distance(
                              project.location.distance(scenario.weather_dataset.weather_source.location),
                              { precision: 1, longDistance: true },
                          )
                }
                onClick={() => onSelect(scenario)}
                disabled={scenario === activeScenario}
            />
        ))
        .value();

    return (
        <Popover position={Position.BOTTOM} content={<Menu>{designRows}</Menu>}>
            {children}
        </Popover>
    );
};

export const ScenarioMenu = connect((state) => ({
    formatters: userSelectors.formatters(state),
}))(IntScenarioMenu);

const mapStateToProps = (state, { project }) => ({
    scenarios: selectors.scenarios(state, project),
    activeScenario: selectors.primaryScenario(state, project),
});

const mapDispatchToProps = bindActions(({ project }) => ({
    onSelect: (scenario) => actions.setPrimaryScenario(project, scenario),
    loadScenarios: (project) => scenApi.index({ project_id: project.project_id }),
}));

export const ProjectScenarioMenu = connect(mapStateToProps, mapDispatchToProps)(ScenarioMenu);

export default ProjectScenarioMenu;
