import * as React from 'react';
import _ from 'lodash';
import { HTMLTable, Colors as bpColors, Classes } from '@blueprintjs/core';
import { Property } from 'csstype';
import classNames from 'classnames';

import { ValueTypes } from 'reports/types';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const BASIC_TABLE_THEMES = {
    standard: 'standard',
    specs: 'specs',
    control: 'control',
    dense: 'dense',
} as const;

const BasicTableClasses = {
    alignLeft: 'bt-align-left',
    alignCenter: 'bt-align-center',
} as const;

type BasicTableTheme = ValueTypes<typeof BASIC_TABLE_THEMES>;

interface IBasicTableProps {
    children?: React.ReactNode;
    centered?: boolean;
    tableTheme?: BasicTableTheme;
    width?: Property.Width;
    sticky?: boolean;
    hasScrollContainer?: boolean;
    className?: string;
}

const StyledHTMLTable = styled(HTMLTable)`
    border: 1px solid ${bpColors.LIGHT_GRAY1};
    border-collapse: separate;
    background: white;

    &.${Classes.HTML_TABLE_BORDERED} {
        tr:not(:first-child) th:first-child {
            box-shadow: inset 0 1px 0 0 ${bpColors.LIGHT_GRAY1};
        }

        tr:not(:first-child) th:not(:first-child) {
            box-shadow: inset 1px 1px 0 0 ${bpColors.LIGHT_GRAY1};
        }
    }

    &.has-scroll-container {
        border-width: 0 0 1px 0; // bottom
    }

    && thead tr th {
        background-color: ${bpColors.LIGHT_GRAY4};
        color: ${bpColors.DARK_GRAY5};
    }

    &.centered {
        td,
        th {
            text-align: center;
            vertical-align: middle;
        }
    }

    td,
    th {
        &.${BasicTableClasses.alignLeft} {
            text-align: left;
        }

        &.${BasicTableClasses.alignCenter} {
            text-align: center;
        }
    }

    &&.sticky {
        thead tr th {
            // sticky isn't valid CSS on TR or THEAD
            position: sticky;
            top: 0;
            // radio buttons in WeatherSourceTable table are at z-index: 0
            z-index: 1;
            border-bottom: 1px solid ${bpColors.LIGHT_GRAY1};
        }

        // We want the header row's bottom border to appear to be sticky with it, so we use
        // "border-collapse: separate" above. However, this creates a double width border between
        // the header row and first body row, so we fix this by eliminating the faux-border from
        // the first body row's box shadow
        tbody tr:first-child {
            td:first-child {
                box-shadow: none;
            }

            td:not(:first-child) {
                box-shadow: inset 1px 0 0 0 ${bpColors.LIGHT_GRAY1};
            }
        }
    }

    &&.theme-dense {
        font-size: 12px;
    }

    &&.theme-specs {
        tr th {
            color: ${bpColors.DARK_GRAY5};
            background-color: ${bpColors.LIGHT_GRAY4};
            vertical-align: middle;
        }

        tr td {
            vertical-align: middle;
        }

        // Various style overrides to fix the borders for multiple header rows and header cells within the body
        && {
            thead {
                tr:first-child {
                    th:first-child {
                        box-shadow: none;
                    }

                    th:not(:first-child) {
                        box-shadow: inset 1px 0 0 0 ${bpColors.LIGHT_GRAY1};
                    }
                }

                tr:not(:first-child) {
                    th:first-child {
                        box-shadow: inset 1px 1px 0 0 ${bpColors.LIGHT_GRAY1};
                    }

                    th:not(:first-child) {
                        box-shadow: inset 1px 1px 0 0 ${bpColors.LIGHT_GRAY1};
                    }
                }
            }

            tbody {
                tr:first-child th {
                    box-shadow: none;
                }

                tr:not(:first-child) th {
                    box-shadow: inset 0 1px 0 0 ${bpColors.LIGHT_GRAY1};
                }

                tr:first-child td {
                    box-shadow: inset 1px 0 0 0 ${bpColors.LIGHT_GRAY1};
                }
            }

            thead + tbody {
                tr:first-child th {
                    box-shadow: inset 0 1px 0 0 ${bpColors.LIGHT_GRAY1};
                }

                tr:first-child td:not(:first-child) {
                    box-shadow: inset 1px 1px 0 0 ${bpColors.LIGHT_GRAY1};
                }

                tr:first-child td:first-child {
                    box-shadow: inset 0 1px 0 0 ${bpColors.LIGHT_GRAY1};
                }
            }
        }
    }

    .${Classes.CHECKBOX} {
        margin-bottom: 0;

        .${Classes.CONTROL_INDICATOR} {
            margin-right: 0;
        }
    }
    td > .${Classes.CHECKBOX}:only-child {
        text-align: center;
    }
`;

/**
 * A standard library table implementation. Uses Blueprint's HTMLTable and provides styling
 * and some minimal behavior for common table use cases. The intent here is not to solve for
 * every table behavior but to standardize the look and feel of tables within the app.
 * Much of HTMLTable's functionality is hidden, and this is by design. The intent is to
 * limit the number of variations of table styling to give the app uniformity. Additional
 * properties and themes should be added judiciously.
 *
 * @param theme The set of styles applied to the table.
 *          "standard" - (default) A bordered table with default styling applied
 *          "specs" - Ideal for lists of properties. The header cells are emphasized, providing
 *                    a constrast to the data cells. There is some custom styling to make borders
 *                    render correctly for <th> elements in <tbody> rows.
 *          "control" - A condensed table intended for inline form elements.
 *          "dense" - A condensed, striped table with hoverable rows and a smaller font for
 *                    detailed displays of data with high row and column counts.
 */
const BasicTable: React.FC<IBasicTableProps> = ({
    children,
    centered = false,
    tableTheme: theme = BASIC_TABLE_THEMES.standard,
    sticky = false,
    hasScrollContainer = false,
    width,
    className,
}) => (
    <StyledHTMLTable
        className={classNames(
            {
                centered,
                sticky,
                'has-scroll-container': hasScrollContainer,
                [`theme-${theme}`]: theme,
            },
            className,
        )}
        bordered={true}
        striped={theme === BASIC_TABLE_THEMES.dense}
        interactive={theme === BASIC_TABLE_THEMES.dense}
        condensed={theme !== BASIC_TABLE_THEMES.standard}
        style={{ width }}
    >
        {children}
    </StyledHTMLTable>
);

export default BasicTable;

export { BasicTableTheme, IBasicTableProps, BasicTableClasses };
