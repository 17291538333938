import { defaultTheme } from '@xstyled/styled-components';
import * as DS from '@aurorasolar/ds';

const tokens = {
    // Hardcoding the color here b/c Blueprint Dialogs are outside the root DOM node and unable to evaluate this color
    // token (e.g. New Project Dialog).
    uiFocus: '#FFDA93',
    uiButtonPrimary: {
        defaultBgColor: DS.xCSSToken('yellow-200'),
        defaultBorderColor: DS.xCSSToken('yellow-200'),
        defaultTextColor: DS.xCSSToken('black'),
        disabledBgColor: DS.xCSSToken('gray-200'),
        disabledBorderColor: DS.xCSSToken('gray-200'),
        disabledTextColor: DS.xCSSToken('gray-500'),
        hoverBgColor: DS.xCSSToken('yellow-200'),
        hoverBorderColor: DS.xCSSToken('yellow-200'),
        hoverTextColor: DS.xCSSToken('black'),
        activeBgColor: DS.xCSSToken('yellow-200'),
        activeBorderColor: DS.xCSSToken('yellow-200'),
        activeTextColor: DS.xCSSToken('black'),
    },
};

// An example of how to extend an existing theme:
// https://github.com/aurorasolar/design-system/blob/main/packages/design-system/src/themes/novo.ts
const theme = {
    ...defaultTheme,
    ...DS.BorealisTheme,
    name: 'HelioScope',
    colors: {
        ...DS.BorealisTheme.colors,
        ...tokens,
    },
};

// @ts-ignore/no-unused-vars
// This allows us to have autocomplete for component props.
export const HelioScopeTheme: DS.ITheme = theme;
