import * as React from 'react';

import { IconNames } from '@blueprintjs/icons';

import Button, { IProps as IButtonProps } from './Button';

type DeleteProps = Omit<IButtonProps, 'icon'>;

export const DeleteButton: React.FC<DeleteProps> = (props) => <Button icon={IconNames.TRASH} {...props} />;

export default DeleteButton;
