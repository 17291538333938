import * as React from 'react';
import Flex from 'reports/components/core/containers/Flex';
import { Card as BpCard, Colors, Divider as BpDivider, Elevation } from '@blueprintjs/core';

import { PlanFeatures } from './PlanFeatures';
import { PlanHeader } from './PlanHeader';

import { styled } from 'reports/styles/styled-components';

const Card = styled(BpCard)`
    display: flex;
    flex-direction: column;
    border-radius: 2px 2px;
    background-color: white;
    padding: 24px 0px 24px 0px;
    width: 272px;
    justify-content: space-between !important;
    margin: 4px;
    outline: ${(props) => (props.elevation ? `2px solid ${Colors.BLUE3}` : '')};
`;

const Divider = styled(BpDivider)`
    margin-left: 24px;
    width: 80%;
`;

const Footer = styled(Flex.Container)`
    justify-content: center;
`;

interface Props {
    button: React.ReactNode;
    cost: { amount: string; description?: string };
    description: React.ReactNode;
    features: { title: string; items: string[] };
    isSelected?: boolean;
    subtitle: string;
    title: string;
    onClick: any;
    interactive?: boolean;
}

const PlanCard = ({
    button,
    cost,
    description,
    features,
    isSelected = false,
    onClick,
    subtitle,
    title,
    interactive = true,
}: Props) => (
    <Card interactive={interactive} elevation={isSelected ? Elevation.ONE : Elevation.ZERO} onClick={onClick}>
        <div>
            <PlanHeader cost={cost} description={description} subtitle={subtitle} title={title} />
            <Divider />
            <PlanFeatures features={features} />
        </div>
        <Footer>{button}</Footer>
    </Card>
);

export { PlanCard };
