import Logger from 'js-logger';
import * as Sentry from '@sentry/browser';
import { isType } from 'typescript-fsa';

import { IAppConfig } from 'reports/config';
import * as log_rocket from 'reports/log_rocket';

import * as usr from 'reports/models/user';
import * as auth from 'reports/modules/auth';

export const LEVELS = {
    fatal: 'fatal',
    error: 'error',
    warning: 'warning',
    info: 'info',
    debug: 'debug',
};

const consoleHandler = Logger.createDefaultHandler();

function install(helioscopeConfig) {
    const urlPromise = log_rocket.initialize(helioscopeConfig);
    initSentry(helioscopeConfig, urlPromise);

    Logger.setHandler((messages, context) => {
        // Maintain default logger handling (output to console)
        consoleHandler(messages, context);

        if (context.level.value >= Logger.ERROR.value) {
            const [message, ...rest] = messages;
            captureMessage(message, context.level.name, context.name, {
                extra: rest,
            });
        }
    });
}

async function initSentry(helioscopeConfig, sessionUrlPromise) {
    if (helioscopeConfig.capture_exceptions) {
        Sentry.init({
            dsn: helioscopeConfig.PUBLIC_API_KEYS.SENTRY_REACT,
            environment: helioscopeConfig.sentry_env,
            release: helioscopeConfig.version,
            integrations: [
                new Sentry.Integrations.GlobalHandlers({
                    onerror: true,
                    onunhandledrejection: true,
                }),
            ],
        });
        const url = await sessionUrlPromise;
        if (url != null) {
            Sentry.configureScope((scope) => scope.setExtra('sessionURL', url));
        }
    }
}

export function captureMessage(message, level, logger, data = {}) {
    Sentry.withScope((scope) => {
        scope.setLevel(level);
        scope.setTag('logger', logger);
        Object.keys(data).forEach((key) => {
            scope.setExtra(key, data[key]);
        });
        return Sentry.captureMessage(message);
    });
}

export function captureException(error, level, logger, data = {}) {
    let eventId;
    Sentry.withScope((scope) => {
        scope.setLevel(level);
        scope.setTag('logger', logger);
        Object.keys(data).forEach((key) => {
            scope.setExtra(key, data[key]);
        });
        eventId = Sentry.captureException(error);
    });
    return eventId;
}

export async function identify(helioscopeConfig: IAppConfig, user: usr.User) {
    Sentry.setUser({
        email: user.email,
        id: user.user_id.toString(),
    });
    log_rocket.identify(helioscopeConfig, user);
}

export function getEventLink(eventId: string) {
    return `https://sentry.io/organizations/folsom-labs/issues/?project=1546143&query=${eventId}`;
}

export function middleware(config: IAppConfig) {
    install(config);

    return (_store) => (next) => (action) => {
        if (isType(action, auth.api.login.done)) {
            identify(config, action.payload.result);
        }

        return next(action);
    };
}
