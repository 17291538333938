import { noop, remove } from 'lodash';
import * as React from 'react';

import { IPrintContext, IPrintCallback, IPrintMeta } from './types';

import * as styles from 'reports/styles/styled-components';

const styled = styles.styled;

const PrintDiv = styled.div`
    position: relative;
    page-break-inside: avoid;
    display: block;
`;

interface IPrintProviderState {
    canPrint: boolean;
    htmlPages?: string[];
    cleanup?: () => Promise<void> | void;
    lastTitle?: string;
    metadata?: IPrintMeta;
}

export const PrintContext = React.createContext<IPrintContext>({
    registerPrintFn: noop,
    deregisterPrintFn: noop,
    canPrint: false,
    routerUrlPrefix: '',
});

interface IPrintProviderProps {
    globalPrintFn: string; // set a global function for puppeteer to trigger the printable state
    routerUrlPrefix: string; // if the app router is mounted with a fixed URL prefix,
    // we need to add it back when specifying which route to print
}

/**
 * Class to wrap around the root component of the application, enables nested
 * states to inject pages of html when the user triggers a print or for PDF
 * export
 *
 * TODO: define the metadata options that this returns that the PDF renderer can use
 */
export class PrintProvider extends React.PureComponent<IPrintProviderProps, IPrintProviderState> {
    printFnStack: IPrintCallback[] = [];
    printActive: boolean = false;

    state: IPrintProviderState = {
        canPrint: false,
    };

    componentDidMount() {
        window[this.props.globalPrintFn] = this.setPrintView;
        window['onbeforeprint'] = (_evt) => this.setPrintView();
        window['onafterprint'] = this.resetPrintView;
    }

    componentWillUnMount() {
        window[this.props.globalPrintFn] = null;
        window['onbeforeprint'] = null;
        window['onafterprint'] = null;
    }

    setPrintView = async (parameters?: any) => {
        // parameters are optional parameters that come from the server
        if (this.printActive || this.printFnStack.length === 0) return;
        this.printActive = true;

        const getPayload = this.printFnStack[this.printFnStack.length - 1];
        const { metadata, htmlPages, cleanup } = await getPayload(parameters);

        const title = metadata != null ? metadata.title : null;

        this.setState({
            metadata,
            htmlPages,
            cleanup,
            lastTitle: document.title,
        });

        if (title != null) {
            document.title = title;
        }

        return metadata;
    };

    resetPrintView = async () => {
        if (this.state.cleanup) {
            await this.state.cleanup();
        }
        document.title = this.state.lastTitle || '';
        this.setState({
            htmlPages: undefined,
            cleanup: undefined,
        });

        this.printActive = false;
    };

    registerPrintFn = (getPayload: IPrintCallback) => {
        this.printFnStack.push(getPayload);
        this.setState({
            canPrint: true,
        });
    };

    deregisterPrintFn = (getPayload: IPrintCallback) => {
        remove(this.printFnStack, (x) => x === getPayload);
        this.setState({
            canPrint: this.printFnStack.length > 0,
        });
    };

    render() {
        const { routerUrlPrefix } = this.props;
        const { canPrint, metadata, htmlPages } = this.state;
        const { width = null, height = null } = metadata || {};
        const pageStyle = width != null && height != null ? { width, height, overflow: 'hidden' } : {};

        return (
            <PrintContext.Provider
                value={{
                    canPrint,
                    routerUrlPrefix,
                    registerPrintFn: this.registerPrintFn,
                    deregisterPrintFn: this.deregisterPrintFn,
                }}
            >
                {htmlPages == null
                    ? this.props.children
                    : htmlPages.map((rawHtmlPage, idx) => (
                          <PrintDiv key={idx} style={pageStyle} dangerouslySetInnerHTML={{ __html: rawHtmlPage }} />
                      ))}
            </PrintContext.Provider>
        );
    }
}

export default PrintProvider;
