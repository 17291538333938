import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router5';
import { createRouteNodeSelector } from 'redux-router5';

import { Classes, Icon, Intent, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { IAppState } from 'reports/types';
import { Flex } from 'reports/components/core/containers';
import { AnchorButton } from 'reports/components/core/controls';
import { Ellipsis } from 'reports/components/helpers/common';
import { getClassicUrl } from 'reports/utils/url';

import ProjectLimitProgressBarContainer from './ProjectLimitProgressBarContainer';
import SideBarNavTabs, { SIDEBAR_ICON_SIZE } from './SideBarNavTabs';
import UserPopover from './UserPopover';

import { HSLightTheme } from 'reports/styles/colors';
import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const bigLogo = require('reports/static/helioscope-black.png');

const EDIT_VIEW_ROUTES = [
    'app.projects.project.designer',
    'app.reports.report.edit',
    'app.financial-templates.financial-template.edit',
    'app.utility-rates.utility-rate',
    'app.incentives.incentive',
    'app.power-devices.power-device',
];

const SidebarSpacer = styled.div<{ collapsed?: boolean }>`
    width: ${(props) => (props.collapsed ? 60 : 240)}px;
    height: 100%;
    contain: none;
    // for performance reasons this is just a step function as opposed to SidebarContainer which is an ease-in-out
    // that way the sidebar can animate without an expensive continuous reflow of the whole page which looks clunky
    ${(props) => (props.collapsed ? 'transition-delay: 0s' : 'transition-delay: 0.1s;')}
`;

const SidebarContainer = styled.div<{ collapsed?: boolean }>`
    // border-right: 1px solid ${HSLightTheme.surfaceBorder}; // Switch to use w/ new colour scheme in the future
    border-color: #d4d4d4;
    border-width: 1px;
    box-shadow: 1px 0 4px rgb(165 165 165 / 42%);
    width: ${(props) => (props.collapsed ? 60 : 240)}px;
    height: 100%;
    left: 0px;
    position: absolute;
    z-index: 19; /* user popover has z-index 20 */
    transition: width 0.1s ease-in-out;
`;

const LogoHeader = styled.div<{ collapsed?: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.collapsed ? 'column' : 'row')};
    align-items: left;
    padding: ${(props) => (props.collapsed ? '15px 0px 2px 13px' : '15px 5px 2px 13px')};
    clip-path: ${(props) => (props.collapsed ? 'inset(0 15px 0 0)' : 'inset(0)')};
    width: 100%;
`;

const CollapseButton = styled.div<{ leftJustifyText?: boolean }>`
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    height: 36px;
    align-items: center;
    box-sizing: content-box;
    color: ${HSLightTheme.sidebarTitleFont};
    border-radius: 5px;
    margin: 0 8px;
    padding-left: ${(props) => (props.leftJustifyText ? '13px' : '5px')};

    svg {
        margin: 0px 8px;
    }

    &:hover {
        background-color: ${HSLightTheme.sidebarBgHover};
    }
`;

const Logo = styled.img`
    &:hover {
        opacity: 0.8;
    }
    max-width: none;
    height: 44px;
`;

interface IOwnProps {
    className: string;
}

interface IState {
    manualCollapsed: boolean;
    hoverExpanded: boolean;
    editViewCollapseOverride: boolean;
}

const BetaButtonContainer = styled(Flex.Container).attrs({
    className: Classes.DARK,
    $align: Flex.Align.CENTER,
})`
    width: calc(100% - 20px);
    margin: 0 10px;
`;

const SidebarContent = styled(Flex.ContainerV)`
    height: 100%;
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${HSLightTheme.sidebarBg};
`;

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;

class SideBar extends React.PureComponent<IProps, IState> {
    state: IState = {
        manualCollapsed: false,
        hoverExpanded: false,
        editViewCollapseOverride: false,
    };
    hoverHandler?: ReturnType<typeof setTimeout>;

    underlyingCollapsed = () => this.state.manualCollapsed || this.state.editViewCollapseOverride;
    collapsed = () => this.underlyingCollapsed() && !this.state.hoverExpanded;

    componentDidUpdate(prevProps) {
        const { route } = this.props;
        if (route && route.name !== (prevProps.route && prevProps.route.name)) {
            if (this.hoverHandler) {
                clearTimeout(this.hoverHandler);
                delete this.hoverHandler;
            }
            this.setState((state) => ({
                ...state,
                hoverExpanded: false,
                editViewCollapseOverride: EDIT_VIEW_ROUTES.includes(route.name),
            }));
        }
    }

    render() {
        const { className } = this.props;
        const collapsed = this.collapsed();

        const collapseToggle = () =>
            this.setState(() => ({
                manualCollapsed: !collapsed && !this.state.hoverExpanded,
                hoverExpanded: false,
                editViewCollapseOverride: false,
            }));

        const handleMouseEnter = () => {
            if (collapsed && !this.hoverHandler) {
                this.hoverHandler = setTimeout(
                    () =>
                        this.setState((state) => ({
                            ...state,
                            hoverExpanded: this.underlyingCollapsed(),
                        })),
                    1000,
                );
            }
        };

        const handleMouseLeave = () => {
            if (this.hoverHandler) {
                clearTimeout(this.hoverHandler);
                delete this.hoverHandler;
            }
            this.setState((state) => ({
                ...state,
                hoverExpanded: false,
            }));
        };

        return (
            <SidebarSpacer collapsed={this.underlyingCollapsed()}>
                <SidebarContainer
                    className={className}
                    collapsed={collapsed}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className="grid-block vertical collapse">
                        <SidebarContent>
                            <LogoHeader collapsed={collapsed}>
                                <Link routeName={'app.projects'}>
                                    <Logo src={bigLogo} />
                                </Link>
                            </LogoHeader>
                            <CollapseButton onClick={collapseToggle} leftJustifyText={this.state.hoverExpanded}>
                                {!collapsed && this.state.hoverExpanded && <Ellipsis>Click to Expand</Ellipsis>}
                                <Icon
                                    icon={
                                        collapsed || this.state.hoverExpanded
                                            ? IconNames.DOUBLE_CHEVRON_RIGHT
                                            : IconNames.DOUBLE_CHEVRON_LEFT
                                    }
                                    color={HSLightTheme.sidebarActionIcon}
                                    iconSize={SIDEBAR_ICON_SIZE}
                                />
                                {!collapsed && !this.state.hoverExpanded && <Ellipsis>Click to Minimize</Ellipsis>}
                            </CollapseButton>

                            <SideBarNavTabs collapsed={collapsed} />
                            <ProjectLimitProgressBarContainer collapsed={collapsed} />
                            <BetaButtonContainer>
                                <Tooltip
                                    boundary="window"
                                    content={'Switch to Current HelioScope'}
                                    position={Position.RIGHT}
                                    disabled={!collapsed}
                                    inheritDarkTheme={false}
                                >
                                    <AnchorButton
                                        href={getClassicUrl('')}
                                        icon={IconNames.SWAP_HORIZONTAL}
                                        text={!collapsed && <Ellipsis>Switch to Current HelioScope</Ellipsis>}
                                        intent={Intent.WARNING}
                                        style={{ margin: '10px 0' }}
                                        fill
                                        minimal
                                    />
                                </Tooltip>
                            </BetaButtonContainer>
                        </SidebarContent>
                        <UserPopover collapsed={collapsed} />
                    </div>
                </SidebarContainer>
            </SidebarSpacer>
        );
    }
}

const mapStateToProps = (state: IAppState) => {
    const routeSelector = createRouteNodeSelector('');
    const route = routeSelector(state).route;
    return { route };
};

export default connect(mapStateToProps)(SideBar);
