import * as fc from 'reports/models/field_component.ts';

import { loadSingleLineDiagram } from 'helioscope/app/singleline/async';
import { summarizePower } from 'helioscope/app/designer/components/summary';

import { groupBy } from 'lodash';


// This needs to be a JS file because (as of 3/24/2021) our project doesn't respect
// async imports in TS files, possibly due to our TS preprocessor being out of date
export async function exportSLD(fieldComponents) {
    const SingleLineDiagram = await loadSingleLineDiagram();

    const interconnect = fc.ensureInterconnect(fieldComponents);
    if (!interconnect) {
        throw Error('No components from which to generate SLD!');
    }

    const compsByZoneId = groupBy(fieldComponents, x => x.wiring_zone_id);
    const summaries = Object.values(compsByZoneId).map(comps => summarizePower(comps));
    const diagram = new SingleLineDiagram(interconnect, summaries);
    return diagram.exportDXF();
}
