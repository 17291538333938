import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconNames } from '@blueprintjs/icons';

import * as auth from 'reports/modules/auth';
import * as mod from 'reports/models/module';
import { LibraryMain } from 'reports/components/library/main/components';
import FilterSelect from 'reports/components/library/main/components/search/FilterSelect';

import { ModulesList } from 'reports/modules/module/components/ModulesList';
import { ModuleUploadLauncher } from 'reports/modules/module/components/upload/ModuleUploadLauncher';
import { IAppState } from 'reports/store';

type Props = {
    preview?: React.ReactNode;
    selectedModuleId?: number;
    navigateToModule: (module: mod.Module) => void;
    inlineControls?: boolean;
};

const ModuleLibrary = React.memo(({ preview, selectedModuleId, navigateToModule, inlineControls }: Props) => {
    const team = useSelector((state) => auth.selectors.getUser(state)!.team);
    const refreshItem = useSelector(
        (state) => (item) =>
            mod.selectors.byObject(state as IAppState, item, [
                'team',
                'default_characterization',
                'user_default_characterization',
            ]) || item,
    );

    const dispatch = useDispatch();
    const loadItems = (args) => dispatch(mod.api.index(args));

    return (
        <LibraryMain
            resourceIdName="module_id"
            loadItems={loadItems}
            refreshItem={refreshItem}
            inlineControls={inlineControls}
            views={[
                {
                    view: <ModulesList navigateToModule={navigateToModule} />,
                    icon: IconNames.LIST,
                    id: 'module-list',
                },
            ]}
            id="modules-views"
            quickFilters={[
                {
                    name: 'favorite',
                    icon: IconNames.STAR,
                    text: 'Favorited',
                },
                {
                    name: 'team_id',
                    icon: IconNames.PEOPLE,
                    text: `Only Show My Team`,
                    value: team.team_id,
                },
            ]}
            extraFilterControls={<CellTechnologySelect />}
            preview={preview}
            contextBarControls={<ModuleUploadLauncher />}
            selectedItemId={selectedModuleId}
        />
    );
});

const CellTechnologySelect = () => (
    <FilterSelect
        label="Filter by Cell Technology"
        items={Object.values(mod.CellTechnologyTypes)}
        emptyValue={'Show All'}
        filterName={'cell_technology_name'}
        buttonProps={{ style: { minWidth: '220px' } }}
    />
);

export { ModuleLibrary };
