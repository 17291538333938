// Coordinates for the bounding boxes of regions covered by Prospector weather data
export const ProspectorBounds = [
    [
        { lat: 18.05, lng: -161.05 },
        { lat: 18.05, lng: -154.15 },
        { lat: 22.95, lng: -154.15 },
        { lat: 22.95, lng: -161.05 },
        { lat: 18.05, lng: -161.05 },
    ],
    [
        { lat: 34.85, lng: -121.55 },
        { lat: 34.75, lng: -121.45 },
        { lat: 34.55, lng: -121.35 },
        { lat: 34.45, lng: -121.25 },
        { lat: 34.35, lng: -121.15 },
        { lat: 34.15, lng: -121.05 },
        { lat: 34.05, lng: -120.95 },
        { lat: 33.95, lng: -120.85 },
        { lat: 33.75, lng: -120.75 },
        { lat: 33.65, lng: -120.65 },
        { lat: 33.45, lng: -120.55 },
        { lat: 33.35, lng: -120.45 },
        { lat: 33.25, lng: -120.35 },
        { lat: 33.15, lng: -120.25 },
        { lat: 33.05, lng: -120.05 },
        { lat: 33.05, lng: -119.95 },
        { lat: 33.05, lng: -119.85 },
        { lat: 33.05, lng: -119.75 },
        { lat: 32.95, lng: -119.45 },
        { lat: 32.95, lng: -119.35 },
        { lat: 32.95, lng: -119.25 },
        { lat: 32.85, lng: -118.95 },
        { lat: 32.85, lng: -118.85 },
        { lat: 32.75, lng: -118.55 },
        { lat: 32.75, lng: -118.45 },
        { lat: 32.75, lng: -118.35 },
        { lat: 32.65, lng: -118.05 },
        { lat: 32.65, lng: -117.95 },
        { lat: 32.65, lng: -117.85 },
        { lat: 32.55, lng: -117.55 },
        { lat: 32.55, lng: -117.45 },
        { lat: 32.55, lng: -117.35 },
        { lat: 32.55, lng: -117.25 },
        { lat: 32.55, lng: -117.15 },
        { lat: 32.55, lng: -117.05 },
        { lat: 32.55, lng: -116.95 },
        { lat: 32.55, lng: -116.85 },
        { lat: 32.55, lng: -116.75 },
        { lat: 32.65, lng: -116.45 },
        { lat: 32.65, lng: -116.35 },
        { lat: 32.65, lng: -116.25 },
        { lat: 32.65, lng: -116.15 },
        { lat: 32.65, lng: -116.05 },
        { lat: 32.65, lng: -115.95 },
        { lat: 32.65, lng: -115.85 },
        { lat: 32.65, lng: -115.75 },
        { lat: 32.65, lng: -115.65 },
        { lat: 32.65, lng: -115.55 },
        { lat: 32.75, lng: -115.25 },
        { lat: 32.75, lng: -115.15 },
        { lat: 32.55, lng: -114.85 },
        { lat: 32.45, lng: -114.65 },
        { lat: 32.35, lng: -114.35 },
        { lat: 32.25, lng: -114.05 },
        { lat: 32.15, lng: -113.75 },
        { lat: 32.05, lng: -113.45 },
        { lat: 31.95, lng: -113.15 },
        { lat: 31.95, lng: -113.05 },
        { lat: 31.85, lng: -112.75 },
        { lat: 31.75, lng: -112.45 },
        { lat: 31.65, lng: -112.15 },
        { lat: 31.55, lng: -111.85 },
        { lat: 31.45, lng: -111.55 },
        { lat: 31.35, lng: -111.25 },
        { lat: 31.35, lng: -111.15 },
        { lat: 31.35, lng: -111.05 },
        { lat: 31.35, lng: -110.95 },
        { lat: 31.35, lng: -110.85 },
        { lat: 31.35, lng: -110.75 },
        { lat: 31.35, lng: -110.65 },
        { lat: 31.35, lng: -110.55 },
        { lat: 31.35, lng: -110.45 },
        { lat: 31.35, lng: -110.35 },
        { lat: 31.35, lng: -110.25 },
        { lat: 31.35, lng: -110.15 },
        { lat: 31.35, lng: -110.05 },
        { lat: 31.35, lng: -109.95 },
        { lat: 31.35, lng: -109.85 },
        { lat: 31.35, lng: -109.75 },
        { lat: 31.35, lng: -109.65 },
        { lat: 31.35, lng: -109.55 },
        { lat: 31.35, lng: -109.45 },
        { lat: 31.35, lng: -109.35 },
        { lat: 31.35, lng: -109.25 },
        { lat: 31.35, lng: -109.15 },
        { lat: 31.35, lng: -109.05 },
        { lat: 31.35, lng: -108.95 },
        { lat: 31.35, lng: -108.85 },
        { lat: 31.35, lng: -108.75 },
        { lat: 31.35, lng: -108.65 },
        { lat: 31.35, lng: -108.55 },
        { lat: 31.35, lng: -108.45 },
        { lat: 31.35, lng: -108.35 },
        { lat: 31.35, lng: -108.25 },
        { lat: 31.35, lng: -108.15 },
        { lat: 31.75, lng: -107.75 },
        { lat: 31.75, lng: -107.65 },
        { lat: 31.75, lng: -107.55 },
        { lat: 31.75, lng: -107.45 },
        { lat: 31.75, lng: -107.35 },
        { lat: 31.75, lng: -107.25 },
        { lat: 31.75, lng: -107.15 },
        { lat: 31.75, lng: -107.05 },
        { lat: 31.75, lng: -106.95 },
        { lat: 31.75, lng: -106.85 },
        { lat: 31.75, lng: -106.75 },
        { lat: 31.75, lng: -106.65 },
        { lat: 31.65, lng: -106.35 },
        { lat: 31.55, lng: -106.25 },
        { lat: 31.45, lng: -106.15 },
        { lat: 31.35, lng: -106.05 },
        { lat: 31.25, lng: -105.85 },
        { lat: 31.15, lng: -105.75 },
        { lat: 31.05, lng: -105.65 },
        { lat: 30.95, lng: -105.55 },
        { lat: 30.85, lng: -105.35 },
        { lat: 30.75, lng: -105.25 },
        { lat: 30.65, lng: -105.15 },
        { lat: 30.55, lng: -105.05 },
        { lat: 30.45, lng: -104.95 },
        { lat: 30.25, lng: -104.85 },
        { lat: 29.95, lng: -104.75 },
        { lat: 29.75, lng: -104.65 },
        { lat: 29.65, lng: -104.55 },
        { lat: 29.55, lng: -104.45 },
        { lat: 29.45, lng: -104.35 },
        { lat: 29.35, lng: -104.15 },
        { lat: 29.25, lng: -103.95 },
        { lat: 29.15, lng: -103.85 },
        { lat: 29.05, lng: -103.65 },
        { lat: 29.05, lng: -103.55 },
        { lat: 28.95, lng: -103.25 },
        { lat: 28.95, lng: -103.15 },
        { lat: 29.05, lng: -103.05 },
        { lat: 29.15, lng: -102.95 },
        { lat: 29.25, lng: -102.85 },
        { lat: 29.35, lng: -102.75 },
        { lat: 29.75, lng: -102.35 },
        { lat: 29.75, lng: -102.25 },
        { lat: 29.75, lng: -102.15 },
        { lat: 29.75, lng: -102.05 },
        { lat: 29.75, lng: -101.95 },
        { lat: 29.75, lng: -101.85 },
        { lat: 29.75, lng: -101.75 },
        { lat: 29.65, lng: -101.45 },
        { lat: 29.35, lng: -101.15 },
        { lat: 29.15, lng: -100.95 },
        { lat: 28.85, lng: -100.65 },
        { lat: 28.65, lng: -100.55 },
        { lat: 28.55, lng: -100.45 },
        { lat: 28.35, lng: -100.35 },
        { lat: 28.15, lng: -100.25 },
        { lat: 28.05, lng: -100.15 },
        { lat: 27.95, lng: -100.05 },
        { lat: 27.85, lng: -99.95 },
        { lat: 27.75, lng: -99.85 },
        { lat: 27.65, lng: -99.75 },
        { lat: 27.45, lng: -99.65 },
        { lat: 27.25, lng: -99.55 },
        { lat: 27.05, lng: -99.45 },
        { lat: 26.85, lng: -99.35 },
        { lat: 26.65, lng: -99.25 },
        { lat: 26.45, lng: -99.15 },
        { lat: 26.35, lng: -99.05 },
        { lat: 26.25, lng: -98.95 },
        { lat: 26.15, lng: -98.65 },
        { lat: 26.05, lng: -98.35 },
        { lat: 25.95, lng: -98.05 },
        { lat: 25.95, lng: -97.95 },
        { lat: 25.95, lng: -97.85 },
        { lat: 25.85, lng: -97.55 },
        { lat: 25.85, lng: -97.45 },
        { lat: 25.85, lng: -97.35 },
        { lat: 25.85, lng: -97.25 },
        { lat: 25.85, lng: -97.15 },
        { lat: 25.85, lng: -97.05 },
        { lat: 25.95, lng: -96.75 },
        { lat: 26.05, lng: -96.45 },
        { lat: 26.05, lng: -96.35 },
        { lat: 26.15, lng: -96.15 },
        { lat: 26.35, lng: -96.05 },
        { lat: 26.65, lng: -95.95 },
        { lat: 26.95, lng: -95.85 },
        { lat: 27.05, lng: -95.75 },
        { lat: 27.15, lng: -95.65 },
        { lat: 27.25, lng: -95.55 },
        { lat: 27.35, lng: -95.45 },
        { lat: 27.45, lng: -95.35 },
        { lat: 27.55, lng: -95.25 },
        { lat: 27.65, lng: -95.05 },
        { lat: 27.75, lng: -94.95 },
        { lat: 27.85, lng: -94.85 },
        { lat: 27.95, lng: -94.65 },
        { lat: 28.05, lng: -94.45 },
        { lat: 28.15, lng: -94.25 },
        { lat: 28.25, lng: -94.15 },
        { lat: 28.35, lng: -93.85 },
        { lat: 28.35, lng: -93.75 },
        { lat: 28.45, lng: -93.45 },
        { lat: 28.45, lng: -93.35 },
        { lat: 28.45, lng: -93.25 },
        { lat: 28.45, lng: -93.15 },
        { lat: 28.45, lng: -93.05 },
        { lat: 28.45, lng: -92.95 },
        { lat: 28.45, lng: -92.85 },
        { lat: 28.55, lng: -92.55 },
        { lat: 28.55, lng: -92.45 },
        { lat: 28.55, lng: -92.35 },
        { lat: 28.55, lng: -92.25 },
        { lat: 28.55, lng: -92.15 },
        { lat: 28.55, lng: -92.05 },
        { lat: 28.45, lng: -91.75 },
        { lat: 28.45, lng: -91.65 },
        { lat: 28.45, lng: -91.55 },
        { lat: 28.35, lng: -91.25 },
        { lat: 28.35, lng: -91.15 },
        { lat: 28.35, lng: -91.05 },
        { lat: 28.35, lng: -90.95 },
        { lat: 28.35, lng: -90.85 },
        { lat: 28.35, lng: -90.75 },
        { lat: 28.35, lng: -90.65 },
        { lat: 28.35, lng: -90.55 },
        { lat: 28.35, lng: -90.45 },
        { lat: 28.45, lng: -90.15 },
        { lat: 28.45, lng: -90.05 },
        { lat: 28.45, lng: -89.95 },
        { lat: 28.45, lng: -89.85 },
        { lat: 28.55, lng: -89.55 },
        { lat: 28.55, lng: -89.45 },
        { lat: 28.55, lng: -89.35 },
        { lat: 28.65, lng: -89.05 },
        { lat: 28.65, lng: -88.95 },
        { lat: 28.65, lng: -88.85 },
        { lat: 28.75, lng: -88.55 },
        { lat: 28.75, lng: -88.45 },
        { lat: 28.85, lng: -88.35 },
        { lat: 29.05, lng: -88.25 },
        { lat: 29.15, lng: -88.15 },
        { lat: 29.45, lng: -87.75 },
        { lat: 29.45, lng: -87.65 },
        { lat: 29.35, lng: -87.35 },
        { lat: 29.35, lng: -87.25 },
        { lat: 29.35, lng: -87.15 },
        { lat: 29.35, lng: -87.05 },
        { lat: 29.35, lng: -86.95 },
        { lat: 29.35, lng: -86.85 },
        { lat: 29.25, lng: -86.55 },
        { lat: 29.25, lng: -86.45 },
        { lat: 29.25, lng: -86.35 },
        { lat: 29.25, lng: -86.25 },
        { lat: 29.25, lng: -86.15 },
        { lat: 29.25, lng: -86.05 },
        { lat: 29.15, lng: -85.75 },
        { lat: 29.15, lng: -85.65 },
        { lat: 29.15, lng: -85.55 },
        { lat: 29.15, lng: -85.45 },
        { lat: 29.15, lng: -85.35 },
        { lat: 29.05, lng: -85.05 },
        { lat: 29.05, lng: -84.95 },
        { lat: 29.05, lng: -84.85 },
        { lat: 29.05, lng: -84.75 },
        { lat: 29.05, lng: -84.65 },
        { lat: 29.05, lng: -84.55 },
        { lat: 28.95, lng: -84.25 },
        { lat: 28.95, lng: -84.15 },
        { lat: 28.95, lng: -84.05 },
        { lat: 28.95, lng: -83.95 },
        { lat: 28.65, lng: -83.55 },
        { lat: 28.45, lng: -83.45 },
        { lat: 28.15, lng: -83.35 },
        { lat: 27.85, lng: -83.25 },
        { lat: 27.55, lng: -83.15 },
        { lat: 27.35, lng: -83.05 },
        { lat: 27.05, lng: -82.95 },
        { lat: 26.75, lng: -82.85 },
        { lat: 26.45, lng: -82.75 },
        { lat: 26.05, lng: -82.75 },
        { lat: 25.85, lng: -82.85 },
        { lat: 25.75, lng: -82.95 },
        { lat: 25.65, lng: -83.05 },
        { lat: 25.55, lng: -83.15 },
        { lat: 25.45, lng: -83.25 },
        { lat: 25.35, lng: -83.35 },
        { lat: 25.25, lng: -83.45 },
        { lat: 25.15, lng: -83.55 },
        { lat: 24.95, lng: -83.65 },
        { lat: 24.85, lng: -83.75 },
        { lat: 24.75, lng: -83.85 },
        { lat: 24.65, lng: -83.65 },
        { lat: 24.55, lng: -83.55 },
        { lat: 24.45, lng: -83.35 },
        { lat: 24.45, lng: -83.25 },
        { lat: 24.45, lng: -83.15 },
        { lat: 24.45, lng: -83.05 },
        { lat: 24.45, lng: -82.95 },
        { lat: 24.45, lng: -82.85 },
        { lat: 24.45, lng: -82.75 },
        { lat: 24.35, lng: -82.45 },
        { lat: 24.35, lng: -82.35 },
        { lat: 24.35, lng: -82.25 },
        { lat: 24.35, lng: -82.15 },
        { lat: 24.35, lng: -82.05 },
        { lat: 24.35, lng: -81.95 },
        { lat: 24.35, lng: -81.85 },
        { lat: 24.35, lng: -81.75 },
        { lat: 24.35, lng: -81.65 },
        { lat: 24.35, lng: -81.55 },
        { lat: 24.35, lng: -81.45 },
        { lat: 24.35, lng: -81.35 },
        { lat: 24.25, lng: -81.05 },
        { lat: 24.25, lng: -80.95 },
        { lat: 24.25, lng: -80.85 },
        { lat: 24.25, lng: -80.75 },
        { lat: 24.25, lng: -80.65 },
        { lat: 24.25, lng: -80.55 },
        { lat: 24.25, lng: -80.45 },
        { lat: 24.25, lng: -80.35 },
        { lat: 24.45, lng: -80.25 },
        { lat: 24.75, lng: -80.15 },
        { lat: 24.95, lng: -80.05 },
        { lat: 25.05, lng: -80.05 },
        { lat: 25.35, lng: -79.95 },
        { lat: 25.55, lng: -79.85 },
        { lat: 25.65, lng: -79.85 },
        { lat: 25.95, lng: -79.75 },
        { lat: 26.25, lng: -79.65 },
        { lat: 26.45, lng: -79.55 },
        { lat: 26.55, lng: -79.55 },
        { lat: 26.85, lng: -79.45 },
        { lat: 27.05, lng: -79.35 },
        { lat: 27.35, lng: -79.25 },
        { lat: 27.45, lng: -79.15 },
        { lat: 27.55, lng: -79.15 },
        { lat: 27.75, lng: -79.25 },
        { lat: 27.95, lng: -79.35 },
        { lat: 28.25, lng: -79.45 },
        { lat: 28.45, lng: -79.55 },
        { lat: 28.65, lng: -79.65 },
        { lat: 28.95, lng: -79.75 },
        { lat: 29.15, lng: -79.85 },
        { lat: 29.35, lng: -79.95 },
        { lat: 29.65, lng: -80.05 },
        { lat: 29.85, lng: -80.15 },
        { lat: 30.15, lng: -80.15 },
        { lat: 30.45, lng: -80.05 },
        { lat: 30.65, lng: -79.95 },
        { lat: 30.95, lng: -79.85 },
        { lat: 31.15, lng: -79.75 },
        { lat: 31.45, lng: -79.65 },
        { lat: 31.65, lng: -79.55 },
        { lat: 31.75, lng: -79.45 },
        { lat: 31.85, lng: -79.35 },
        { lat: 31.95, lng: -79.25 },
        { lat: 32.05, lng: -79.15 },
        { lat: 32.15, lng: -79.05 },
        { lat: 32.25, lng: -78.95 },
        { lat: 32.35, lng: -78.85 },
        { lat: 32.45, lng: -78.75 },
        { lat: 32.55, lng: -78.55 },
        { lat: 32.65, lng: -78.45 },
        { lat: 32.75, lng: -78.35 },
        { lat: 32.85, lng: -78.25 },
        { lat: 32.95, lng: -78.15 },
        { lat: 33.05, lng: -78.05 },
        { lat: 33.15, lng: -77.95 },
        { lat: 33.25, lng: -77.85 },
        { lat: 33.35, lng: -77.75 },
        { lat: 33.45, lng: -77.45 },
        { lat: 33.55, lng: -77.15 },
        { lat: 33.65, lng: -76.85 },
        { lat: 33.75, lng: -76.55 },
        { lat: 33.85, lng: -76.25 },
        { lat: 33.95, lng: -75.95 },
        { lat: 34.05, lng: -75.65 },
        { lat: 34.15, lng: -75.55 },
        { lat: 34.25, lng: -75.45 },
        { lat: 34.45, lng: -75.35 },
        { lat: 34.55, lng: -75.25 },
        { lat: 34.75, lng: -75.15 },
        { lat: 34.95, lng: -75.05 },
        { lat: 35.05, lng: -74.95 },
        { lat: 35.25, lng: -74.85 },
        { lat: 35.45, lng: -74.75 },
        { lat: 35.75, lng: -74.85 },
        { lat: 35.85, lng: -74.85 },
        { lat: 35.95, lng: -74.85 },
        { lat: 36.05, lng: -74.85 },
        { lat: 36.35, lng: -74.95 },
        { lat: 36.45, lng: -74.95 },
        { lat: 36.55, lng: -74.95 },
        { lat: 36.65, lng: -74.95 },
        { lat: 36.95, lng: -75.05 },
        { lat: 37.05, lng: -75.05 },
        { lat: 37.15, lng: -75.05 },
        { lat: 37.45, lng: -75.15 },
        { lat: 37.75, lng: -75.05 },
        { lat: 37.85, lng: -74.95 },
        { lat: 37.95, lng: -74.85 },
        { lat: 38.05, lng: -74.75 },
        { lat: 38.15, lng: -74.55 },
        { lat: 38.25, lng: -74.45 },
        { lat: 38.35, lng: -74.35 },
        { lat: 38.45, lng: -74.25 },
        { lat: 38.55, lng: -74.15 },
        { lat: 38.65, lng: -74.05 },
        { lat: 38.75, lng: -73.95 },
        { lat: 38.85, lng: -73.85 },
        { lat: 38.95, lng: -73.75 },
        { lat: 39.05, lng: -73.65 },
        { lat: 39.15, lng: -73.55 },
        { lat: 39.25, lng: -73.45 },
        { lat: 39.35, lng: -73.35 },
        { lat: 39.45, lng: -73.25 },
        { lat: 39.55, lng: -73.15 },
        { lat: 39.65, lng: -73.05 },
        { lat: 39.75, lng: -72.95 },
        { lat: 39.85, lng: -72.85 },
        { lat: 39.95, lng: -72.75 },
        { lat: 40.05, lng: -72.65 },
        { lat: 40.15, lng: -72.55 },
        { lat: 40.25, lng: -72.45 },
        { lat: 40.35, lng: -72.25 },
        { lat: 40.45, lng: -72.05 },
        { lat: 40.55, lng: -71.75 },
        { lat: 40.65, lng: -71.45 },
        { lat: 40.75, lng: -71.15 },
        { lat: 40.75, lng: -71.05 },
        { lat: 40.85, lng: -70.75 },
        { lat: 40.95, lng: -70.45 },
        { lat: 41.05, lng: -70.15 },
        { lat: 41.15, lng: -69.85 },
        { lat: 41.35, lng: -69.75 },
        { lat: 41.45, lng: -69.65 },
        { lat: 41.65, lng: -69.55 },
        { lat: 41.75, lng: -69.45 },
        { lat: 41.85, lng: -69.35 },
        { lat: 41.95, lng: -69.35 },
        { lat: 42.05, lng: -69.35 },
        { lat: 42.15, lng: -69.35 },
        { lat: 42.25, lng: -69.35 },
        { lat: 42.35, lng: -69.35 },
        { lat: 42.45, lng: -69.35 },
        { lat: 42.55, lng: -69.35 },
        { lat: 42.65, lng: -69.35 },
        { lat: 42.75, lng: -69.35 },
        { lat: 43.15, lng: -69.05 },
        { lat: 43.25, lng: -68.85 },
        { lat: 43.35, lng: -68.65 },
        { lat: 43.45, lng: -68.35 },
        { lat: 43.55, lng: -68.15 },
        { lat: 43.65, lng: -67.95 },
        { lat: 43.75, lng: -67.65 },
        { lat: 43.85, lng: -67.55 },
        { lat: 44.05, lng: -67.45 },
        { lat: 44.15, lng: -67.35 },
        { lat: 44.25, lng: -67.25 },
        { lat: 44.35, lng: -67.15 },
        { lat: 44.45, lng: -67.05 },
        { lat: 44.55, lng: -66.95 },
        { lat: 44.65, lng: -66.95 },
        { lat: 44.75, lng: -66.95 },
        { lat: 44.85, lng: -66.95 },
        { lat: 45.05, lng: -67.05 },
        { lat: 45.15, lng: -67.15 },
        { lat: 45.35, lng: -67.25 },
        { lat: 45.45, lng: -67.35 },
        { lat: 45.55, lng: -67.45 },
        { lat: 45.95, lng: -67.75 },
        { lat: 46.05, lng: -67.75 },
        { lat: 46.15, lng: -67.75 },
        { lat: 46.25, lng: -67.75 },
        { lat: 46.35, lng: -67.75 },
        { lat: 46.45, lng: -67.75 },
        { lat: 46.55, lng: -67.75 },
        { lat: 46.65, lng: -67.75 },
        { lat: 46.75, lng: -67.75 },
        { lat: 46.85, lng: -67.75 },
        { lat: 46.95, lng: -67.75 },
        { lat: 47.05, lng: -67.75 },
        { lat: 47.15, lng: -67.85 },
        { lat: 47.25, lng: -67.95 },
        { lat: 47.35, lng: -68.15 },
        { lat: 47.35, lng: -68.25 },
        { lat: 47.35, lng: -68.35 },
        { lat: 47.35, lng: -68.45 },
        { lat: 47.35, lng: -68.55 },
        { lat: 47.45, lng: -68.95 },
        { lat: 47.45, lng: -69.05 },
        { lat: 47.45, lng: -69.15 },
        { lat: 47.45, lng: -69.25 },
        { lat: 47.45, lng: -69.35 },
        { lat: 47.35, lng: -69.45 },
        { lat: 47.25, lng: -69.55 },
        { lat: 47.15, lng: -69.65 },
        { lat: 47.05, lng: -69.75 },
        { lat: 46.95, lng: -69.85 },
        { lat: 46.85, lng: -69.95 },
        { lat: 46.65, lng: -70.05 },
        { lat: 46.45, lng: -70.15 },
        { lat: 46.35, lng: -70.25 },
        { lat: 46.05, lng: -70.35 },
        { lat: 45.85, lng: -70.45 },
        { lat: 45.65, lng: -70.65 },
        { lat: 45.35, lng: -71.05 },
        { lat: 45.35, lng: -71.15 },
        { lat: 45.35, lng: -71.25 },
        { lat: 45.35, lng: -71.35 },
        { lat: 45.25, lng: -71.45 },
        { lat: 45.15, lng: -71.55 },
        { lat: 45.05, lng: -71.85 },
        { lat: 45.05, lng: -71.95 },
        { lat: 45.05, lng: -72.05 },
        { lat: 45.05, lng: -72.15 },
        { lat: 45.05, lng: -72.25 },
        { lat: 45.05, lng: -72.35 },
        { lat: 45.05, lng: -72.45 },
        { lat: 45.05, lng: -72.55 },
        { lat: 45.05, lng: -72.65 },
        { lat: 45.05, lng: -72.75 },
        { lat: 45.05, lng: -72.85 },
        { lat: 45.05, lng: -72.95 },
        { lat: 45.05, lng: -73.05 },
        { lat: 45.05, lng: -73.15 },
        { lat: 45.05, lng: -73.25 },
        { lat: 45.05, lng: -73.35 },
        { lat: 45.05, lng: -73.45 },
        { lat: 45.05, lng: -73.55 },
        { lat: 45.05, lng: -73.65 },
        { lat: 45.05, lng: -73.75 },
        { lat: 45.05, lng: -73.85 },
        { lat: 45.05, lng: -73.95 },
        { lat: 45.05, lng: -74.05 },
        { lat: 45.05, lng: -74.15 },
        { lat: 45.05, lng: -74.25 },
        { lat: 45.05, lng: -74.35 },
        { lat: 45.05, lng: -74.45 },
        { lat: 45.05, lng: -74.55 },
        { lat: 45.05, lng: -74.65 },
        { lat: 45.05, lng: -74.75 },
        { lat: 45.05, lng: -74.85 },
        { lat: 45.05, lng: -74.95 },
        { lat: 45.05, lng: -75.05 },
        { lat: 44.95, lng: -75.25 },
        { lat: 44.85, lng: -75.45 },
        { lat: 44.75, lng: -75.55 },
        { lat: 44.65, lng: -75.75 },
        { lat: 44.55, lng: -75.85 },
        { lat: 44.45, lng: -76.05 },
        { lat: 44.35, lng: -76.15 },
        { lat: 44.25, lng: -76.25 },
        { lat: 44.15, lng: -76.45 },
        { lat: 43.95, lng: -76.65 },
        { lat: 43.65, lng: -77.05 },
        { lat: 43.65, lng: -77.15 },
        { lat: 43.65, lng: -77.25 },
        { lat: 43.65, lng: -77.35 },
        { lat: 43.65, lng: -77.45 },
        { lat: 43.65, lng: -77.55 },
        { lat: 43.65, lng: -77.65 },
        { lat: 43.65, lng: -77.75 },
        { lat: 43.65, lng: -77.85 },
        { lat: 43.65, lng: -77.95 },
        { lat: 43.65, lng: -78.05 },
        { lat: 43.65, lng: -78.15 },
        { lat: 43.65, lng: -78.25 },
        { lat: 43.65, lng: -78.35 },
        { lat: 43.65, lng: -78.45 },
        { lat: 43.65, lng: -78.55 },
        { lat: 43.65, lng: -78.65 },
        { lat: 43.65, lng: -78.75 },
        { lat: 43.55, lng: -79.05 },
        { lat: 43.45, lng: -79.25 },
        { lat: 43.25, lng: -79.15 },
        { lat: 42.75, lng: -79.45 },
        { lat: 42.65, lng: -79.75 },
        { lat: 42.65, lng: -79.85 },
        { lat: 42.55, lng: -80.15 },
        { lat: 42.45, lng: -80.45 },
        { lat: 42.45, lng: -80.55 },
        { lat: 42.35, lng: -80.85 },
        { lat: 42.25, lng: -81.15 },
        { lat: 42.15, lng: -81.45 },
        { lat: 42.05, lng: -81.65 },
        { lat: 41.95, lng: -81.85 },
        { lat: 41.85, lng: -82.15 },
        { lat: 42.45, lng: -82.65 },
        { lat: 42.55, lng: -82.55 },
        { lat: 42.65, lng: -82.45 },
        { lat: 42.85, lng: -82.35 },
        { lat: 43.05, lng: -82.25 },
        { lat: 43.35, lng: -82.15 },
        { lat: 43.55, lng: -82.05 },
        { lat: 43.65, lng: -82.05 },
        { lat: 43.75, lng: -82.05 },
        { lat: 44.05, lng: -82.15 },
        { lat: 44.15, lng: -82.15 },
        { lat: 44.45, lng: -82.25 },
        { lat: 44.55, lng: -82.25 },
        { lat: 44.65, lng: -82.25 },
        { lat: 44.95, lng: -82.35 },
        { lat: 45.05, lng: -82.35 },
        { lat: 45.25, lng: -82.45 },
        { lat: 45.35, lng: -82.55 },
        { lat: 45.45, lng: -82.65 },
        { lat: 45.55, lng: -82.75 },
        { lat: 45.65, lng: -82.85 },
        { lat: 45.75, lng: -82.95 },
        { lat: 45.85, lng: -83.15 },
        { lat: 45.95, lng: -83.25 },
        { lat: 46.05, lng: -83.35 },
        { lat: 46.15, lng: -83.45 },
        { lat: 46.25, lng: -83.55 },
        { lat: 46.35, lng: -83.65 },
        { lat: 46.45, lng: -83.75 },
        { lat: 46.55, lng: -83.95 },
        { lat: 46.65, lng: -84.25 },
        { lat: 46.75, lng: -84.45 },
        { lat: 46.85, lng: -84.75 },
        { lat: 46.95, lng: -84.95 },
        { lat: 47.05, lng: -85.15 },
        { lat: 47.15, lng: -85.45 },
        { lat: 47.25, lng: -85.65 },
        { lat: 47.35, lng: -85.95 },
        { lat: 47.45, lng: -86.15 },
        { lat: 47.55, lng: -86.35 },
        { lat: 47.65, lng: -86.65 },
        { lat: 47.75, lng: -86.85 },
        { lat: 47.85, lng: -87.15 },
        { lat: 47.95, lng: -87.35 },
        { lat: 48.05, lng: -87.65 },
        { lat: 48.15, lng: -87.85 },
        { lat: 48.25, lng: -88.05 },
        { lat: 48.35, lng: -88.35 },
        { lat: 48.35, lng: -88.45 },
        { lat: 48.25, lng: -88.75 },
        { lat: 48.15, lng: -89.05 },
        { lat: 48.15, lng: -89.15 },
        { lat: 48.15, lng: -89.25 },
        { lat: 48.15, lng: -89.35 },
        { lat: 48.15, lng: -89.45 },
        { lat: 48.15, lng: -89.55 },
        { lat: 48.15, lng: -89.65 },
        { lat: 48.15, lng: -89.75 },
        { lat: 48.15, lng: -89.85 },
        { lat: 48.15, lng: -89.95 },
        { lat: 48.15, lng: -90.05 },
        { lat: 48.25, lng: -90.35 },
        { lat: 48.25, lng: -90.45 },
        { lat: 48.25, lng: -90.55 },
        { lat: 48.25, lng: -90.65 },
        { lat: 48.25, lng: -90.75 },
        { lat: 48.25, lng: -90.85 },
        { lat: 48.25, lng: -90.95 },
        { lat: 48.25, lng: -91.05 },
        { lat: 48.25, lng: -91.15 },
        { lat: 48.25, lng: -91.25 },
        { lat: 48.25, lng: -91.35 },
        { lat: 48.25, lng: -91.45 },
        { lat: 48.25, lng: -91.55 },
        { lat: 48.25, lng: -91.65 },
        { lat: 48.35, lng: -91.95 },
        { lat: 48.45, lng: -92.25 },
        { lat: 48.45, lng: -92.35 },
        { lat: 48.55, lng: -92.65 },
        { lat: 48.65, lng: -92.95 },
        { lat: 48.65, lng: -93.05 },
        { lat: 48.65, lng: -93.15 },
        { lat: 48.65, lng: -93.25 },
        { lat: 48.65, lng: -93.35 },
        { lat: 48.65, lng: -93.45 },
        { lat: 48.65, lng: -93.55 },
        { lat: 48.75, lng: -93.85 },
        { lat: 48.75, lng: -93.95 },
        { lat: 48.75, lng: -94.05 },
        { lat: 48.75, lng: -94.15 },
        { lat: 48.75, lng: -94.25 },
        { lat: 49.05, lng: -94.65 },
        { lat: 49.35, lng: -94.75 },
        { lat: 49.35, lng: -94.85 },
        { lat: 49.35, lng: -94.95 },
        { lat: 49.35, lng: -95.05 },
        { lat: 49.35, lng: -95.15 },
        { lat: 48.95, lng: -95.55 },
        { lat: 48.95, lng: -95.65 },
        { lat: 48.95, lng: -95.75 },
        { lat: 48.95, lng: -95.85 },
        { lat: 48.95, lng: -95.95 },
        { lat: 48.95, lng: -96.05 },
        { lat: 48.95, lng: -96.15 },
        { lat: 48.95, lng: -96.25 },
        { lat: 48.95, lng: -96.35 },
        { lat: 48.95, lng: -96.45 },
        { lat: 48.95, lng: -96.55 },
        { lat: 48.95, lng: -96.65 },
        { lat: 48.95, lng: -96.75 },
        { lat: 48.95, lng: -96.85 },
        { lat: 48.95, lng: -96.95 },
        { lat: 48.95, lng: -97.05 },
        { lat: 48.95, lng: -97.15 },
        { lat: 48.95, lng: -97.25 },
        { lat: 48.95, lng: -97.35 },
        { lat: 48.95, lng: -97.45 },
        { lat: 48.95, lng: -97.55 },
        { lat: 48.95, lng: -97.65 },
        { lat: 48.95, lng: -97.75 },
        { lat: 48.95, lng: -97.85 },
        { lat: 48.95, lng: -97.95 },
        { lat: 48.95, lng: -98.05 },
        { lat: 48.95, lng: -98.15 },
        { lat: 48.95, lng: -98.25 },
        { lat: 48.95, lng: -98.35 },
        { lat: 48.95, lng: -98.45 },
        { lat: 48.95, lng: -98.55 },
        { lat: 48.95, lng: -98.65 },
        { lat: 48.95, lng: -98.75 },
        { lat: 48.95, lng: -98.85 },
        { lat: 48.95, lng: -98.95 },
        { lat: 48.95, lng: -99.05 },
        { lat: 48.95, lng: -99.15 },
        { lat: 48.95, lng: -99.25 },
        { lat: 48.95, lng: -99.35 },
        { lat: 48.95, lng: -99.45 },
        { lat: 48.95, lng: -99.55 },
        { lat: 48.95, lng: -99.65 },
        { lat: 48.95, lng: -99.75 },
        { lat: 48.95, lng: -99.85 },
        { lat: 48.95, lng: -99.95 },
        { lat: 48.95, lng: -100.05 },
        { lat: 48.95, lng: -100.15 },
        { lat: 48.95, lng: -100.25 },
        { lat: 48.95, lng: -100.35 },
        { lat: 48.95, lng: -100.45 },
        { lat: 48.95, lng: -100.55 },
        { lat: 48.95, lng: -100.65 },
        { lat: 48.95, lng: -100.75 },
        { lat: 48.95, lng: -100.85 },
        { lat: 48.95, lng: -100.95 },
        { lat: 48.95, lng: -101.05 },
        { lat: 48.95, lng: -101.15 },
        { lat: 48.95, lng: -101.25 },
        { lat: 48.95, lng: -101.35 },
        { lat: 48.95, lng: -101.45 },
        { lat: 48.95, lng: -101.55 },
        { lat: 48.95, lng: -101.65 },
        { lat: 48.95, lng: -101.75 },
        { lat: 48.95, lng: -101.85 },
        { lat: 48.95, lng: -101.95 },
        { lat: 48.95, lng: -102.05 },
        { lat: 48.95, lng: -102.15 },
        { lat: 48.95, lng: -102.25 },
        { lat: 48.95, lng: -102.35 },
        { lat: 48.95, lng: -102.45 },
        { lat: 48.95, lng: -102.55 },
        { lat: 48.95, lng: -102.65 },
        { lat: 48.95, lng: -102.75 },
        { lat: 48.95, lng: -102.85 },
        { lat: 48.95, lng: -102.95 },
        { lat: 48.95, lng: -103.05 },
        { lat: 48.95, lng: -103.15 },
        { lat: 48.95, lng: -103.25 },
        { lat: 48.95, lng: -103.35 },
        { lat: 48.95, lng: -103.45 },
        { lat: 48.95, lng: -103.55 },
        { lat: 48.95, lng: -103.65 },
        { lat: 48.95, lng: -103.75 },
        { lat: 48.95, lng: -103.85 },
        { lat: 48.95, lng: -103.95 },
        { lat: 48.95, lng: -104.05 },
        { lat: 48.95, lng: -104.15 },
        { lat: 48.95, lng: -104.25 },
        { lat: 48.95, lng: -104.35 },
        { lat: 48.95, lng: -104.45 },
        { lat: 48.95, lng: -104.55 },
        { lat: 48.95, lng: -104.65 },
        { lat: 48.95, lng: -104.75 },
        { lat: 48.95, lng: -104.85 },
        { lat: 48.95, lng: -104.95 },
        { lat: 48.95, lng: -105.05 },
        { lat: 48.95, lng: -105.15 },
        { lat: 48.95, lng: -105.25 },
        { lat: 48.95, lng: -105.35 },
        { lat: 48.95, lng: -105.45 },
        { lat: 48.95, lng: -105.55 },
        { lat: 48.95, lng: -105.65 },
        { lat: 48.95, lng: -105.75 },
        { lat: 48.95, lng: -105.85 },
        { lat: 48.95, lng: -105.95 },
        { lat: 48.95, lng: -106.05 },
        { lat: 48.95, lng: -106.15 },
        { lat: 48.95, lng: -106.25 },
        { lat: 48.95, lng: -106.35 },
        { lat: 48.95, lng: -106.45 },
        { lat: 48.95, lng: -106.55 },
        { lat: 48.95, lng: -106.65 },
        { lat: 48.95, lng: -106.75 },
        { lat: 48.95, lng: -106.85 },
        { lat: 48.95, lng: -106.95 },
        { lat: 48.95, lng: -107.05 },
        { lat: 48.95, lng: -107.15 },
        { lat: 48.95, lng: -107.25 },
        { lat: 48.95, lng: -107.35 },
        { lat: 48.95, lng: -107.45 },
        { lat: 48.95, lng: -107.55 },
        { lat: 48.95, lng: -107.65 },
        { lat: 48.95, lng: -107.75 },
        { lat: 48.95, lng: -107.85 },
        { lat: 48.95, lng: -107.95 },
        { lat: 48.95, lng: -108.05 },
        { lat: 48.95, lng: -108.15 },
        { lat: 48.95, lng: -108.25 },
        { lat: 48.95, lng: -108.35 },
        { lat: 48.95, lng: -108.45 },
        { lat: 48.95, lng: -108.55 },
        { lat: 48.95, lng: -108.65 },
        { lat: 48.95, lng: -108.75 },
        { lat: 48.95, lng: -108.85 },
        { lat: 48.95, lng: -108.95 },
        { lat: 48.95, lng: -109.05 },
        { lat: 48.95, lng: -109.15 },
        { lat: 48.95, lng: -109.25 },
        { lat: 48.95, lng: -109.35 },
        { lat: 48.95, lng: -109.45 },
        { lat: 48.95, lng: -109.55 },
        { lat: 48.95, lng: -109.65 },
        { lat: 48.95, lng: -109.75 },
        { lat: 48.95, lng: -109.85 },
        { lat: 48.95, lng: -109.95 },
        { lat: 48.95, lng: -110.05 },
        { lat: 48.95, lng: -110.15 },
        { lat: 48.95, lng: -110.25 },
        { lat: 48.95, lng: -110.35 },
        { lat: 48.95, lng: -110.45 },
        { lat: 48.95, lng: -110.55 },
        { lat: 48.95, lng: -110.65 },
        { lat: 48.95, lng: -110.75 },
        { lat: 48.95, lng: -110.85 },
        { lat: 48.95, lng: -110.95 },
        { lat: 48.95, lng: -111.05 },
        { lat: 48.95, lng: -111.15 },
        { lat: 48.95, lng: -111.25 },
        { lat: 48.95, lng: -111.35 },
        { lat: 48.95, lng: -111.45 },
        { lat: 48.95, lng: -111.55 },
        { lat: 48.95, lng: -111.65 },
        { lat: 48.95, lng: -111.75 },
        { lat: 48.95, lng: -111.85 },
        { lat: 48.95, lng: -111.95 },
        { lat: 48.95, lng: -112.05 },
        { lat: 48.95, lng: -112.15 },
        { lat: 48.95, lng: -112.25 },
        { lat: 48.95, lng: -112.35 },
        { lat: 48.95, lng: -112.45 },
        { lat: 48.95, lng: -112.55 },
        { lat: 48.95, lng: -112.65 },
        { lat: 48.95, lng: -112.75 },
        { lat: 48.95, lng: -112.85 },
        { lat: 48.95, lng: -112.95 },
        { lat: 48.95, lng: -113.05 },
        { lat: 48.95, lng: -113.15 },
        { lat: 48.95, lng: -113.25 },
        { lat: 48.95, lng: -113.35 },
        { lat: 48.95, lng: -113.45 },
        { lat: 48.95, lng: -113.55 },
        { lat: 48.95, lng: -113.65 },
        { lat: 48.95, lng: -113.75 },
        { lat: 48.95, lng: -113.85 },
        { lat: 48.95, lng: -113.95 },
        { lat: 48.95, lng: -114.05 },
        { lat: 48.95, lng: -114.15 },
        { lat: 48.95, lng: -114.25 },
        { lat: 48.95, lng: -114.35 },
        { lat: 48.95, lng: -114.45 },
        { lat: 48.95, lng: -114.55 },
        { lat: 48.95, lng: -114.65 },
        { lat: 48.95, lng: -114.75 },
        { lat: 48.95, lng: -114.85 },
        { lat: 48.95, lng: -114.95 },
        { lat: 48.95, lng: -115.05 },
        { lat: 48.95, lng: -115.15 },
        { lat: 48.95, lng: -115.25 },
        { lat: 48.95, lng: -115.35 },
        { lat: 48.95, lng: -115.45 },
        { lat: 48.95, lng: -115.55 },
        { lat: 48.95, lng: -115.65 },
        { lat: 48.95, lng: -115.75 },
        { lat: 48.95, lng: -115.85 },
        { lat: 48.95, lng: -115.95 },
        { lat: 48.95, lng: -116.05 },
        { lat: 48.95, lng: -116.15 },
        { lat: 48.95, lng: -116.25 },
        { lat: 48.95, lng: -116.35 },
        { lat: 48.95, lng: -116.45 },
        { lat: 48.95, lng: -116.55 },
        { lat: 48.95, lng: -116.65 },
        { lat: 48.95, lng: -116.75 },
        { lat: 48.95, lng: -116.85 },
        { lat: 48.95, lng: -116.95 },
        { lat: 48.95, lng: -117.05 },
        { lat: 48.95, lng: -117.15 },
        { lat: 48.95, lng: -117.25 },
        { lat: 48.95, lng: -117.35 },
        { lat: 48.95, lng: -117.45 },
        { lat: 48.95, lng: -117.55 },
        { lat: 48.95, lng: -117.65 },
        { lat: 48.95, lng: -117.75 },
        { lat: 48.95, lng: -117.85 },
        { lat: 48.95, lng: -117.95 },
        { lat: 48.95, lng: -118.05 },
        { lat: 48.95, lng: -118.15 },
        { lat: 48.95, lng: -118.25 },
        { lat: 48.95, lng: -118.35 },
        { lat: 48.95, lng: -118.45 },
        { lat: 48.95, lng: -118.55 },
        { lat: 48.95, lng: -118.65 },
        { lat: 48.95, lng: -118.75 },
        { lat: 48.95, lng: -118.85 },
        { lat: 48.95, lng: -118.95 },
        { lat: 48.95, lng: -119.05 },
        { lat: 48.95, lng: -119.15 },
        { lat: 48.95, lng: -119.25 },
        { lat: 48.95, lng: -119.35 },
        { lat: 48.95, lng: -119.45 },
        { lat: 48.95, lng: -119.55 },
        { lat: 48.95, lng: -119.65 },
        { lat: 48.95, lng: -119.75 },
        { lat: 48.95, lng: -119.85 },
        { lat: 48.95, lng: -119.95 },
        { lat: 48.95, lng: -120.05 },
        { lat: 48.95, lng: -120.15 },
        { lat: 48.95, lng: -120.25 },
        { lat: 48.95, lng: -120.35 },
        { lat: 48.95, lng: -120.45 },
        { lat: 48.95, lng: -120.55 },
        { lat: 48.95, lng: -120.65 },
        { lat: 48.95, lng: -120.75 },
        { lat: 48.95, lng: -120.85 },
        { lat: 48.95, lng: -120.95 },
        { lat: 48.95, lng: -121.05 },
        { lat: 48.95, lng: -121.15 },
        { lat: 48.95, lng: -121.25 },
        { lat: 48.95, lng: -121.35 },
        { lat: 48.95, lng: -121.45 },
        { lat: 48.95, lng: -121.55 },
        { lat: 48.95, lng: -121.65 },
        { lat: 48.95, lng: -121.75 },
        { lat: 48.95, lng: -121.85 },
        { lat: 48.95, lng: -121.95 },
        { lat: 48.95, lng: -122.05 },
        { lat: 48.95, lng: -122.15 },
        { lat: 48.95, lng: -122.25 },
        { lat: 48.95, lng: -122.35 },
        { lat: 48.95, lng: -122.45 },
        { lat: 48.95, lng: -122.55 },
        { lat: 48.95, lng: -122.65 },
        { lat: 48.95, lng: -122.75 },
        { lat: 48.95, lng: -122.85 },
        { lat: 48.95, lng: -122.95 },
        { lat: 48.95, lng: -123.05 },
        { lat: 48.95, lng: -123.15 },
        { lat: 48.85, lng: -123.25 },
        { lat: 48.75, lng: -123.25 },
        { lat: 48.65, lng: -123.25 },
        { lat: 48.25, lng: -123.65 },
        { lat: 48.25, lng: -123.75 },
        { lat: 48.25, lng: -123.85 },
        { lat: 48.35, lng: -124.15 },
        { lat: 48.45, lng: -124.45 },
        { lat: 48.55, lng: -124.75 },
        { lat: 48.55, lng: -124.85 },
        { lat: 48.55, lng: -124.95 },
        { lat: 48.45, lng: -124.95 },
        { lat: 48.35, lng: -124.95 },
        { lat: 48.25, lng: -124.95 },
        { lat: 48.15, lng: -124.95 },
        { lat: 48.05, lng: -124.95 },
        { lat: 47.95, lng: -124.95 },
        { lat: 47.85, lng: -124.95 },
        { lat: 47.75, lng: -124.95 },
        { lat: 47.65, lng: -124.95 },
        { lat: 47.55, lng: -124.95 },
        { lat: 47.45, lng: -124.95 },
        { lat: 47.35, lng: -124.95 },
        { lat: 47.25, lng: -124.95 },
        { lat: 47.15, lng: -124.95 },
        { lat: 47.05, lng: -124.95 },
        { lat: 46.95, lng: -124.95 },
        { lat: 46.85, lng: -124.95 },
        { lat: 46.75, lng: -124.95 },
        { lat: 46.65, lng: -124.95 },
        { lat: 46.55, lng: -124.95 },
        { lat: 46.45, lng: -124.95 },
        { lat: 46.35, lng: -124.95 },
        { lat: 46.25, lng: -124.95 },
        { lat: 46.15, lng: -124.95 },
        { lat: 46.05, lng: -124.95 },
        { lat: 45.95, lng: -124.95 },
        { lat: 45.85, lng: -124.95 },
        { lat: 45.75, lng: -124.95 },
        { lat: 45.65, lng: -124.95 },
        { lat: 45.55, lng: -124.95 },
        { lat: 45.45, lng: -124.95 },
        { lat: 45.35, lng: -124.95 },
        { lat: 45.25, lng: -124.95 },
        { lat: 45.15, lng: -124.95 },
        { lat: 45.05, lng: -124.95 },
        { lat: 44.95, lng: -124.95 },
        { lat: 44.85, lng: -124.95 },
        { lat: 44.75, lng: -124.95 },
        { lat: 44.65, lng: -124.95 },
        { lat: 44.55, lng: -124.95 },
        { lat: 44.45, lng: -124.95 },
        { lat: 44.35, lng: -124.95 },
        { lat: 44.25, lng: -124.95 },
        { lat: 44.15, lng: -124.95 },
        { lat: 44.05, lng: -124.95 },
        { lat: 43.95, lng: -124.95 },
        { lat: 43.85, lng: -124.95 },
        { lat: 43.75, lng: -124.95 },
        { lat: 43.65, lng: -124.95 },
        { lat: 43.55, lng: -124.95 },
        { lat: 43.45, lng: -124.95 },
        { lat: 43.35, lng: -124.95 },
        { lat: 43.25, lng: -124.95 },
        { lat: 43.15, lng: -124.95 },
        { lat: 43.05, lng: -124.95 },
        { lat: 42.95, lng: -124.95 },
        { lat: 42.85, lng: -124.95 },
        { lat: 42.75, lng: -124.95 },
        { lat: 42.65, lng: -124.95 },
        { lat: 42.55, lng: -124.95 },
        { lat: 42.45, lng: -124.95 },
        { lat: 42.35, lng: -124.95 },
        { lat: 42.25, lng: -124.95 },
        { lat: 42.15, lng: -124.95 },
        { lat: 42.05, lng: -124.95 },
        { lat: 41.95, lng: -124.95 },
        { lat: 41.85, lng: -124.95 },
        { lat: 41.75, lng: -124.95 },
        { lat: 41.65, lng: -124.95 },
        { lat: 41.55, lng: -124.95 },
        { lat: 41.45, lng: -124.95 },
        { lat: 41.35, lng: -124.95 },
        { lat: 41.25, lng: -124.95 },
        { lat: 41.15, lng: -124.95 },
        { lat: 41.05, lng: -124.95 },
        { lat: 40.95, lng: -124.95 },
        { lat: 40.85, lng: -124.95 },
        { lat: 40.75, lng: -124.95 },
        { lat: 40.65, lng: -124.95 },
        { lat: 40.55, lng: -124.95 },
        { lat: 40.45, lng: -124.95 },
        { lat: 40.35, lng: -124.95 },
        { lat: 40.25, lng: -124.95 },
        { lat: 40.15, lng: -124.95 },
        { lat: 40.05, lng: -124.95 },
        { lat: 39.95, lng: -124.95 },
        { lat: 39.85, lng: -124.95 },
        { lat: 39.55, lng: -124.85 },
        { lat: 39.45, lng: -124.85 },
        { lat: 39.35, lng: -124.85 },
        { lat: 39.25, lng: -124.75 },
        { lat: 39.15, lng: -124.65 },
        { lat: 38.95, lng: -124.55 },
        { lat: 38.85, lng: -124.45 },
        { lat: 38.75, lng: -124.35 },
        { lat: 38.55, lng: -124.25 },
        { lat: 38.45, lng: -124.15 },
        { lat: 38.25, lng: -124.05 },
        { lat: 38.15, lng: -123.95 },
        { lat: 38.05, lng: -123.85 },
        { lat: 37.85, lng: -123.75 },
        { lat: 37.75, lng: -123.65 },
        { lat: 37.65, lng: -123.55 },
        { lat: 37.45, lng: -123.45 },
        { lat: 37.35, lng: -123.35 },
        { lat: 37.15, lng: -123.25 },
        { lat: 37.05, lng: -123.15 },
        { lat: 36.95, lng: -123.05 },
        { lat: 36.75, lng: -122.95 },
        { lat: 36.65, lng: -122.85 },
        { lat: 36.55, lng: -122.75 },
        { lat: 36.35, lng: -122.65 },
        { lat: 36.25, lng: -122.55 },
        { lat: 36.15, lng: -122.45 },
        { lat: 35.95, lng: -122.35 },
        { lat: 35.85, lng: -122.25 },
        { lat: 35.65, lng: -122.15 },
        { lat: 35.55, lng: -122.05 },
        { lat: 35.45, lng: -121.95 },
        { lat: 35.25, lng: -121.85 },
        { lat: 35.15, lng: -121.75 },
        { lat: 35.05, lng: -121.65 },
        { lat: 34.85, lng: -121.55 },
    ],
];
