import { selectors as authSelectors } from 'reports/modules/auth';
import { api as teamAPI, Team } from 'reports/models/team';
import { api as userAPI, selectors as userSelectors, User } from 'reports/models/user';
import { api as incentiveAPI } from 'reports/models/incentive';
import { Module, ModuleCharacterization } from 'reports/models/module';
import { PowerDevice, PowerDeviceCharacterization } from 'reports/models/power_device';
import { api as pdCharAPI } from 'reports/models/power_device/PowerDeviceCharacterization';
import { api as modCharAPI } from 'reports/models/module/ModuleCharacterization';
import { api as utilityRateAPI } from 'reports/models/utility_rate';
import { api as profileAPI } from 'reports/models/profile';
import { api as projectAPI } from 'reports/models/project';
import { api as finTemplateAPI } from 'reports/models/financial_template';

import { Subscription } from 'reports/models/subscription';
import { actions as subscriptionActions } from 'reports/modules/subscription';

import { AppStore } from 'reports/store';
import { Params } from 'react-router';

const getUser = async (store: AppStore, searchParams: URLSearchParams): Promise<User> => {
    const state = store.getState();
    const email = searchParams.get('email');

    if (email) {
        const user: User | undefined = userSelectors.byEmail(state, email);
        return user ? user : await store.dispatch(userAPI.get({ email }));
    }
    return authSelectors.getUser(state)!;
};

const getTeam = async (store: AppStore, team_id: number): Promise<Team> => {
    return await store.dispatch(teamAPI.get({ team_id }));
};

const getSubscription = async (store: AppStore, subscription?: Subscription) =>
    subscription ? await store.dispatch(subscriptionActions.loadFullSubscription(subscription)) : undefined;

const loadIncentive = async (store: AppStore, incentiveId: string) =>
    isNaN(Number(incentiveId)) ? null : await store.dispatch(incentiveAPI.get({ incentive_id: Number(incentiveId) }));

const loadProfile = async (store: AppStore, profileId: string) =>
    isNaN(Number(profileId)) ? null : await store.dispatch(profileAPI.get({ profile_id: Number(profileId) }));

const loadUtilityRate = async (store: AppStore, utilityRateId: string) =>
    isNaN(Number(utilityRateId))
        ? null
        : await store.dispatch(utilityRateAPI.get({ utility_rate_id: Number(utilityRateId) }));

const loadFinTemplate = async (store: AppStore, finTemplateId: string) =>
    isNaN(Number(finTemplateId))
        ? null
        : await store.dispatch(finTemplateAPI.get({ financial_template_id: Number(finTemplateId) }));

const loadProject = async (store: AppStore, projectId: string) =>
    isNaN(Number(projectId)) ? null : await store.dispatch(projectAPI.get({ project_id: Number(projectId) }));

const loadPowerDeviceCharacterizations = async (store: AppStore, powerDeviceId: number) =>
    store.dispatch(pdCharAPI.index({ power_device_id: powerDeviceId }));

const loadPowerDevice = async (
    store: AppStore,
    params: Params,
): Promise<{
    characterization?: PowerDeviceCharacterization | null;
    powerDevice?: PowerDevice | null;
}> => {
    if (isNaN(Number(params.powerDeviceId)) || isNaN(Number(params.characterizationId))) {
        return { powerDevice: null, characterization: null };
    }
    const powerDeviceId = Number(params.powerDeviceId);
    const characterizationId = Number(params.characterizationId);
    const characterizations = await loadPowerDeviceCharacterizations(store, powerDeviceId);
    const selectedCharacterization = characterizations.find(
        (char) =>
            char.power_device_id === powerDeviceId && char.power_device_characterization_id === characterizationId,
    );
    return selectedCharacterization
        ? { characterization: selectedCharacterization, powerDevice: selectedCharacterization.power_device }
        : { characterization: null, powerDevice: null };
};

const loadModuleCharacterizations = async (store: AppStore, moduleId: number) =>
    store.dispatch(modCharAPI.index({ module_id: moduleId }));

const loadModule = async (
    store: AppStore,
    params: Params,
): Promise<{ characterization?: ModuleCharacterization | null; module?: Module | null }> => {
    if (isNaN(Number(params.moduleId)) || isNaN(Number(params.characterizationId))) {
        return { characterization: null, module: null };
    }
    const moduleId = Number(params.moduleId);
    const characterizationId = Number(params.characterizationId);
    const characterizations = await loadModuleCharacterizations(store, moduleId);
    const selectedCharacterization = characterizations.find(
        (char) => char.module_id === moduleId && char.module_characterization_id === characterizationId,
    );
    return selectedCharacterization
        ? { characterization: selectedCharacterization, module: selectedCharacterization.module }
        : { characterization: null, module: null };
};

const loadSettingsData = async (
    store: AppStore,
    searchParams: URLSearchParams,
): Promise<{ subscription?: Subscription; team: Team; user: User }> => {
    const user = await getUser(store, searchParams);
    const team = await getTeam(store, user.team_id);

    const subscription = await getSubscription(store, team.latest_subscription || user.latest_subscription);

    return { subscription, team, user };
};

export { loadFinTemplate,loadIncentive, loadModule, loadPowerDevice, loadProfile, loadProject, loadUtilityRate, loadSettingsData };
