import * as React from 'react';
import { DragSource, DragSourceSpec, DragSourceConnector, DragSourceMonitor } from 'react-dnd';

import { getEmptyImage } from 'react-dnd-html5-backend';

import { disableDropzone, enableDropzone } from 'reports/modules/files/components/dropzone_helpers';

import ResizeHandles, { FullDiv } from './ResizeHandles';
import { ILayout, Component, ILayoutContext } from './types';
import { LayoutRegionConsumer } from './LayoutRegion';

interface IProps {
    className?: string;
    style?: React.CSSProperties;
    layout: ILayout;
    itemMeta: any;

    draggable?: boolean;
    resizable?: boolean;

    children?: React.ReactNode;
}

type IFullProps = IProps & ILayoutContext;

const dragSpec: DragSourceSpec<IFullProps> = {
    beginDrag({ itemMeta, layout, scale, snapIndex, regionKey }, _monitor, component: any) {
        disableDropzone();
        return {
            layout,
            itemMeta,
            scale,
            snapIndex,
            regionKey,

            previewHTML: component.componentRef.current.innerHTML,
        };
    },

    canDrag({ draggable = false }) {
        return draggable;
    },

    endDrag() {
        enableDropzone();
    },
};

const collect = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
});

class InnerLayoutComponent extends React.PureComponent<IFullProps & ReturnType<typeof collect>> {
    componentRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        this.props.connectDragPreview(getEmptyImage(), {
            // IE fallback: specify that we'd rather screenshot the node
            // when it already knows it's being dragged so we can hide it with CSS.
            captureDraggingState: true,
        });
    }

    render() {
        const {
            children,
            layout,
            className,
            style,
            connectDragSource,
            itemMeta,
            resizable = false,
            onChange,
            scale,
            isDragging,
            snapIndex,
            regionKey,
        } = this.props;

        const locatedStyle: React.CSSProperties = Object.assign(
            {
                top: layout.y,
                left: layout.x,
                width: layout.w,
                height: layout.h,
                zIndex: layout.z,
                position: 'absolute',
                // transition: 'all 0.05s',
                opacity: isDragging ? 0 : 'inherit',
            },
            style,
        );

        return connectDragSource(
            <div style={locatedStyle} className={className}>
                {resizable ? (
                    <ResizeHandles
                        itemMeta={itemMeta}
                        layout={layout}
                        onChange={onChange}
                        scale={scale}
                        snapIndex={snapIndex}
                        regionKey={regionKey}
                    />
                ) : null}
                <FullDiv ref={this.componentRef as any}>{children}</FullDiv>
            </div>,
        );
    }
}

const DraggableLayoutComponent = DragSource(Component, dragSpec, collect)(InnerLayoutComponent);

export const LayoutComponent: React.SFC<IProps> = (props) => (
    <LayoutRegionConsumer>
        {(regionContext) => <DraggableLayoutComponent {...props} {...regionContext} />}
    </LayoutRegionConsumer>
);

export default LayoutComponent;
