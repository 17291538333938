import { find } from 'lodash';

import { getModuleFromRegistry } from './model/modules';
import { IRatesConfig } from './components/ParamRatesFull';

export function getRateParam(path: 'energy_rates' | 'demand_rates') {
    const rateParam = find(
        getModuleFromRegistry('BasicUtilityRatesSimple').parameters,
        (i) => i.path === path,
    ) as IRatesConfig;
    if (rateParam === undefined) {
        throw new Error('No rate parameter defined in utility rate module');
    }
    return rateParam;
}
