import { merge, set } from 'lodash';
import * as React from 'react';

import { Classes } from '@blueprintjs/core';

import { SHORT_MONTHS as MONTHS } from 'reports/utils/time';
import { Flex } from 'reports/components/core/containers';

import * as ur from 'reports/models/utility_rate';

import { SubsectionHeader } from 'reports/modules/utility_rate/components/OverviewPanel';

import { getRateTableConfig } from './ParamRatesList';
import { ParamTiers } from 'reports/modules/financials/components/ParamTiers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ColTitle = styled.div`
    flex: 1;
    font-weight: 600;
    text-align: center;
`;

const RateSchedule = styled(Flex.Container)`
    .${Classes.HTML_TABLE} tbody tr:first-child td {
        box-shadow: none;
    }

    .${Classes.EDITABLE_TEXT} .${Classes.EDITABLE_TEXT_CONTENT} {
        line-height: 30px !important; // override inline Bluperintjs style
    }
`;

export enum Seasons {
    Summer = 'Summer',
    Winter = 'Winter',
}

// Seasonal tiered input
export const DEFAULT_TIERED_INPUT: ur.ISeasonalInput = {
    business_days: null,
    seasons: [
        {
            name: Seasons.Summer,
            months: { start: 4, end: 9 },
            rate_schedules: [
                {
                    rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                    net_meter_flat: 0.0,
                },
            ],
        },
        {
            name: Seasons.Winter,
            months: { start: 10, end: 3 },
            rate_schedules: [
                {
                    rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                    net_meter_flat: 0.0,
                },
            ],
        },
    ],
};

// Seasonal TOU input
export const DEFAULT_TOU_INPUT: ur.ISeasonalInput = {
    business_days: [1, 2, 3, 4, 5],
    seasons: [
        {
            name: Seasons.Summer,
            months: { start: 4, end: 9 },
            rate_schedules: [
                {
                    rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                    tou: { name: 'Peak', intervals: [[12, 19]] },
                    net_meter_flat: 0.0,
                },
                {
                    rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                    tou: {
                        name: 'Partial-Peak',
                        intervals: [
                            [8, 12],
                            [19, 22],
                        ],
                    },
                    net_meter_flat: 0.0,
                },
                {
                    rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                    tou: {
                        name: 'Off-Peak',
                        intervals: [
                            [0, 8],
                            [22, 0],
                        ],
                    },
                    net_meter_flat: 0.0,
                },
            ],
        },
        {
            name: Seasons.Winter,
            months: { start: 10, end: 3 },
            rate_schedules: [
                {
                    rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                    tou: { name: 'Peak', intervals: [[8, 22]] },
                    net_meter_flat: 0.0,
                },
                {
                    rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                    tou: {
                        name: 'Off-Peak',
                        intervals: [
                            [0, 8],
                            [22, 0],
                        ],
                    },
                    net_meter_flat: 0.0,
                },
            ],
        },
    ],
};

interface IOwnProps {
    input: ur.ISeasonalInput;
    rates: ur.RateConfig;
    onUpdate: (value: any) => void;
    demand?: boolean; // is demand rate
}

export class ParamRatesSeasonalEnergy extends React.PureComponent<IOwnProps> {
    rateParamConfig = getRateTableConfig(true);

    render() {
        const { seasons } = this.props.input;

        return (
            <Flex.ContainerV>
                <SubsectionHeader title="Rate Schedules" />

                {seasons.map(({ name, rate_schedules: rateSchedules, months }, sIdx) => (
                    <Flex.Container key={sIdx} style={{ margin: 10 }}>
                        <div style={{ width: 150, padding: '11px 0' }}>
                            <div style={{ fontSize: 15 }}>{name}</div>
                            <div>
                                ({MONTHS[months.start - 1]} - {MONTHS[months.end - 1]})
                            </div>
                        </div>

                        <div>
                            <Flex.Container
                                style={{
                                    marginBottom: 10,
                                    paddingBottom: 6,
                                    borderBottom: '1px solid',
                                }}
                            >
                                {rateSchedules[0].tou && <div id="name" style={{ width: 150 }} />}
                                <ColTitle>Rate ($/kWh)</ColTitle>
                                <ColTitle>Tier Cap (kWh)</ColTitle>
                                <ColTitle>Absolute Cap (kWh)</ColTitle>
                                <div id="delete" style={{ width: 46 }} />
                            </Flex.Container>
                            {rateSchedules.map((rateSchedule, rIdx) => {
                                const rateSchedulePath = `seasons[${sIdx}]rate_schedules[${rIdx}]`;
                                return (
                                    <RateSchedule key={rIdx}>
                                        {rateSchedule.tou && (
                                            <div
                                                style={{
                                                    width: 150,
                                                    padding: 4,
                                                    lineHeight: '30px',
                                                }}
                                            >
                                                {rateSchedule.tou.name}
                                            </div>
                                        )}
                                        <div>
                                            <ParamTiers
                                                parameter={this.rateParamConfig}
                                                value={rateSchedule.rate_tiers}
                                                updateFn={(val) =>
                                                    this.updateInput(`${rateSchedulePath}.rate_tiers`, val)
                                                }
                                                showHeaders={false}
                                                minimal={true}
                                            />
                                        </div>
                                    </RateSchedule>
                                );
                            })}
                        </div>
                    </Flex.Container>
                ))}
            </Flex.ContainerV>
        );
    }

    updateInput(subpath: string, val) {
        const seasonalInput = merge({}, this.props.input);
        set(seasonalInput, subpath, val);
        this.props.onUpdate(seasonalInput);
    }
}

export class ParamRatesSeasonalDemand extends React.PureComponent<IOwnProps> {
    render() {
        return null;
    }
}
