import { defer, map, omit } from 'lodash';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { actionCreatorFactory, isType, Action } from 'typescript-fsa';

import * as conf from 'reports/config';

import * as finTemp from 'reports/models/financial_template';
import * as inc from 'reports/models/incentive';
import * as proj from 'reports/models/project';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as sim from 'reports/models/simulation';

import * as auth from 'reports/modules/auth';
import { ModelRun } from 'reports/modules/financials/model/run';
import { actions as activityAction } from 'reports/modules/activity';
import { selectors as projSelector } from 'reports/modules/project';
import { IEnvironmentalMetrics, IOutputPipelineState } from 'reports/modules/financials/model/pipeline/types';

import { IAppState, ValueTypes } from 'reports/types';
import { createUniqueDescription } from 'reports/utils/helpers';

const actionCreator = actionCreatorFactory('FINANCIAL');

export const activityId = 'FINANCIAL_CONFIG_RUN';

export const actions = {
    createConfiguration: (template: finTemp.FinancialTemplate, project: proj.Project) => async (dispatch: Dispatch) => {
        const matchingConfigs = await dispatch(
            projFinTemp.api.index({
                project_id: project.project_id,
                name: template.description,
            }),
        );

        const data = {
            project_id: project.project_id,
            name: createUniqueDescription(template.description, map(matchingConfigs, 'name')),
            financial_template_id: template.financial_template_id,
        };

        return dispatch(projFinTemp.api.create(data));
    },
    duplicateConfiguration: (config: projFinTemp.ProjectFinancialTemplate) => (dispatch: Dispatch) => {
        const { name, ...data } = config;

        // config will not have relationships populated, can assume form
        const formData = {
            name: `Copy of ${name || 'Unnamed Configuration'}`,
            ...omit(data, 'project_financial_template_id'),
        } as projFinTemp.IProjectFinancialTemplateForm;

        return dispatch(projFinTemp.api.create(formData));
    },
    clearOutput: (config: projFinTemp.ProjectFinancialTemplate) => (dispatch: Dispatch) => {
        const driver = ModelRun.getInstance();

        defer(() => {
            driver.clearCache(config);

            const configId = config.project_financial_template_id;
            dispatch(actions.clearFinancialOutput(configId));
        });
    },
    queueRun:
        (config: projFinTemp.ProjectFinancialTemplate, project: proj.Project, sim: sim.Simulation) =>
        (dispatch: Dispatch, getState) => {
            const driver = ModelRun.getInstance();

            defer(async () => {
                const verify = await driver.verifyCache(config, project, sim);
                if (verify) return;
                const configId = config.project_financial_template_id;
                const user = auth.selectors.getUser(getState());
                const canViewFinancials = user?.hasFinancialsAccess();
                if (!canViewFinancials) {
                    dispatch(
                        actions.setFinancialOutput({
                            configId,
                            status: FIN_STATUSES.permissionsError,
                        }),
                    );
                    return;
                }

                dispatch(actions.setActivity());
                dispatch(
                    actions.setFinancialOutput({
                        configId,
                        status: FIN_STATUSES.computing,
                    }),
                );
                const appConfig = conf.selectors.getConfig(getState());

                try {
                    const output: IFinancialOutput | undefined = await driver.queueRun(appConfig, config, project, sim);
                    if (!output) {
                        dispatch(
                            actions.setFinancialOutput({
                                configId,
                                status: FIN_STATUSES.calculationError,
                            }),
                        );
                        return;
                    }

                    // Output full deserialized Incentive's, since that's what financial config view expects.
                    output.projectIncentives = project.incentives;
                    dispatch(actions.setFinancialOutput({ configId, ...output }));
                } finally {
                    if (!driver.isBusy()) {
                        dispatch(actions.clearActivity());
                    }
                }
            });
        },
    setActivity: () => (dispatch: Dispatch) => {
        dispatch(
            activityAction.setActivityItem({
                activityId,
                text: 'Computing financials...',
            }),
        );
    },
    clearActivity: () => (dispatch: Dispatch) => {
        dispatch(activityAction.clearActivityItem(activityId));
    },
    setRefreshing: (config: projFinTemp.ProjectFinancialTemplate) => (dispatch: Dispatch) => {
        defer(async () => {
            const configId = config.project_financial_template_id;
            dispatch(
                actions.setFinancialOutput({
                    configId,
                    status: FIN_STATUSES.refreshing,
                }),
            );
        });
    },
    clearFinancialOutput: actionCreator<number>('CLEAR_FINANCIAL_OUTPUT'),
    setFinancialOutput: actionCreator<MaybeFinOutput & { configId: number }>('SET_FINANCIAL_OUTPUT'),
};

export const getFinConfigTokens = (
    config: projFinTemp.ProjectFinancialTemplate | null,
    financial: IFinancialState,
): MaybeFinOutput => {
    if (!config) return { status: FIN_STATUSES.missingInputs };
    return (
        financial[config.project_financial_template_id] || {
            status: FIN_STATUSES.missingInputs,
        }
    );
};

export const selectors = {
    get primaryFinConfigTokens() {
        return createSelector(
            projSelector.primaryProjectFinancialTemplate,
            (state: IAppState) => state.financial,
            getFinConfigTokens,
        );
    },
    get finConfigOutput() {
        return createSelector(
            (_state: IAppState, props: { config: projFinTemp.ProjectFinancialTemplate }) => props.config,
            (state) => state.financial,
            getFinConfigTokens,
        );
    },
    get finDebugOutput() {
        return createSelector(this.finConfigOutput, (finOutput) =>
            hasOutput(finOutput) ? finOutput.debugOutput : null,
        );
    },
};

export interface IFinancialOutput extends IOutputPipelineState, IEnvironmentalMetrics {
    projectIncentives: inc.Incentive[];
}

// error and success are terminal statuses, indicating that the financial simulation has completed.
export const FIN_STATUSES = {
    refreshing: Symbol('refreshing'), // waiting for pending model/config changes to be flushed to DB.
    computing: Symbol('computing'),
    missingInputs: Symbol('missingInputs'),
    permissionsError: Symbol('permissionsError'), // user doesn't have access to the financial model
    calculationError: Symbol('calculationError'), // something went wrong while running the financial model
};
export type IncompleteFinStatus = ValueTypes<typeof FIN_STATUSES>;

interface IncompleteFinStatusContainer {
    status: IncompleteFinStatus;
}
// Contains financial output if fin simulation succeeded. Otherwise, contains status of fin simulation.
export type MaybeFinOutput = IFinancialOutput | IncompleteFinStatusContainer;
export const hasOutput = (output: MaybeFinOutput): output is IFinancialOutput =>
    (output as IncompleteFinStatusContainer).status == null;

export interface IFinancialState {
    [k: number]: MaybeFinOutput;
}

const INITIAL_STATE: IFinancialState = {};

export const reducer = (state: IFinancialState = INITIAL_STATE, action: Action<any>): IFinancialState => {
    if (isType(action, actions.clearFinancialOutput)) {
        const copy = { ...state };
        delete copy[action.payload];
        return copy;
    }

    if (isType(action, actions.setFinancialOutput)) {
        const { configId, ...otherOutput } = action.payload;

        return {
            ...state,
            [configId!]: otherOutput,
        };
    }

    return state;
};
