import * as React from 'react';
import { RadioGroup, Radio } from '@blueprintjs/core';
import FormField from 'reports/components/forms/FormField';

type RadioItem = {
    key: string;
    label?: string;
    radioChildren: React.ReactElement;
    radioValue: string;
    disabled?: boolean;
};

interface Props {
    disabled?: boolean;
    inline?: boolean;
    items: RadioItem[];
    path: string;
    selectedValue: string;
    onSelect?: (value: string | object) => void;
}

const FormBareRadioGroup = ({ disabled, inline, items, onSelect, path, selectedValue }: Props) => {
    return (
        <FormField path={path}>
            {({ onChange, value, form }) => (
                <RadioGroup
                    disabled={disabled || form.submitting}
                    inline={inline}
                    onChange={(e) => {
                        const value = (e.target as HTMLInputElement).value;
                        onSelect && onSelect(value);
                        return onChange(value);
                    }}
                    selectedValue={selectedValue || value}
                >
                    {items.map(({ key, label, radioChildren, radioValue, disabled }) => (
                        <Radio
                            disabled={disabled}
                            key={key}
                            label={label}
                            style={{ fontWeight: 'normal' }}
                            value={radioValue}
                        >
                            {radioChildren}
                        </Radio>
                    ))}
                </RadioGroup>
            )}
        </FormField>
    );
};

export default FormBareRadioGroup;
