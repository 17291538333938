import 'reports/styles';

import Logger from 'js-logger';

import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

import { RouterProvider as Router5Provider } from 'react-router5';

import * as DS from '@aurorasolar/ds';
import { HelioScopeTheme } from 'frontend/layout/theme';
import { HelioScopeTheme as BetaHelioScopeTheme } from 'reports/styles/theme';
import { Router } from 'frontend/routing/router';

import { FocusStyleManager, HotkeysProvider } from '@blueprintjs/core';

import { DEFAULT_LOCALE } from 'reports/localization';
import { LocaleProvider } from 'reports/localization/lang';

import { AppContainer } from 'reports/app';
import { configureStore } from 'reports/store';
import { makeRouter } from 'reports/routing';

import { DomInjector } from 'reports/components/dialog';
import { PrintProvider } from 'reports/modules/pdf';

import * as cfg from 'reports/config';
import { StoreContainer } from './utils/store_container';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';

Logger.useDefaults({
    logLevel: Logger.INFO,
});

FocusStyleManager.onlyShowFocusOnTabs();

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}

async function boot() {
    // TODO, this should be re-fetched somewhere else after auth so we pull in needed admin data for HS admins
    // tracked in https://aurorasolar.atlassian.net/browse/HEL-1415
    const config = await cfg.fetchConfig();

    const user = config.user;
    const showNewUI: boolean = !!user && user.active_features.includes('enable_new_ui');
    const routerPrefix = config.react_router_prefix || '';

    const router5 = makeRouter({ useTrailingSlash: false }, { base: routerPrefix, useHash: false });

    const store = configureStore(router5, config);
    (window as any).store = store;
    StoreContainer.store = store;

    await store.dispatch(cfg.actions.initializeApp({ config }));

    if (!showNewUI) {
        router5.setDependency('store', store);
        router5.start();
    }

    // Default to en-US when no one is logged in
    const locale = user ? user.team.locale.code : DEFAULT_LOCALE.code;
    const element = document.getElementById('root')!;

    // Disable translation to prevent React removeChild error.
    // More info: https://github.com/facebook/react/issues/11538#issuecomment-350110297
    element.setAttribute('translate', 'no');

    const root = createRoot(element);
    root.render(
        <Provider store={store}>
            <LocaleProvider locale={locale} forceEnglish={true}>
                <HotkeysProvider>
                    <PrintProvider globalPrintFn="getPrintView" routerUrlPrefix={routerPrefix}>
                        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                            {!showNewUI && (
                                <Router5Provider router={router5}>
                                    <DS.StyleProvider colorMode="light" theme={BetaHelioScopeTheme}>
                                        <AppContainer />
                                    </DS.StyleProvider>
                                    <DomInjector />
                                </Router5Provider>
                            )}
                            {showNewUI && (
                                <DS.StyleProvider colorMode="light" theme={HelioScopeTheme}>
                                    <Router basename={config.debug ? '/app' : ''} store={store} />
                                    <DomInjector />
                                </DS.StyleProvider>
                            )}
                        </StyleSheetManager>
                    </PrintProvider>
                </HotkeysProvider>
            </LocaleProvider>
        </Provider>,
    );
}

boot();
