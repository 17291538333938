import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLoaderData, useNavigate } from 'react-router';
import * as pd from 'reports/models/power_device';

import { IAppState } from 'reports/types';
import { PowerDevices } from 'frontend/libraries/power-devices';
import { PowerDevicePreview } from 'reports/modules/power_device/components/PowerDevicePreview';

type PowerDeviceContext = {
    characterization: pd.PowerDeviceCharacterization;
    powerDevice: pd.PowerDevice;
};

const PowerDevice = () => {
    const navigate = useNavigate();
    const navigateToPowerDevices = () => navigate('/components');

    const navigateToCharacterization = ({
        power_device_id,
        power_device_characterization_id,
    }: pd.PowerDeviceCharacterization) => {
        const path = generatePath('/components/:powerDeviceId/characterizations/:characterizationId/preview', {
            powerDeviceId: power_device_id.toString(),
            characterizationId: power_device_characterization_id.toString(),
        });
        navigate(path);
    };

    const { characterization, powerDevice } = useLoaderData() as PowerDeviceContext;
    const selectedPowerDevice = useSelector((state) =>
        pd.selectors.byObject(state as IAppState, { power_device_id: powerDevice.power_device_id }),
    );

    if (!selectedPowerDevice || !characterization) {
        throw new Response('Not Found', { status: 404 });
    }

    return (
        <PowerDevices
            selectedPowerDeviceId={selectedPowerDevice.power_device_id}
            preview={
                <PowerDevicePreview
                    powerDevice={selectedPowerDevice}
                    characterization={characterization}
                    navigateToPowerDevices={navigateToPowerDevices}
                    navigateToCharacterization={navigateToCharacterization}
                />
            }
        />
    );
};

export { PowerDevice };
