import { assign, isEmpty } from 'lodash';

import * as React from 'react';

import { MaybeElement } from '@blueprintjs/core';

import { DeepPartial } from 'reports/types';

import { FinancialTemplate } from 'reports/models/financial_template';

import { Flex } from 'reports/components/core/containers';
import { Warning } from 'reports/components/helpers/errors';

import { ErrorMessages } from 'reports/modules/financials/components';
import { ModuleCategory, getModuleCategory } from 'reports/modules/financials/model/modules';
import { AuthErrorCode, FinancialPipelineTree, Node, NodeType } from 'reports/modules/financials/model/pipeline';

import { PipelineEditor } from '../pipelineCommon';
import InsertPopover from './InsertPopover';
import TemplateStep from './TemplateStep';
import TemplateCategory from './TemplateCategory';

type Props = {
    template: FinancialTemplate;
    update: (patch: DeepPartial<FinancialTemplate>) => void;
    advanced?: boolean;
};

const FinancialTemplateEditor = ({ template, update, advanced = false }: Props) => {
    const tree = FinancialPipelineTree.fromRawData(template.data.root);

    const updateTree = (tree) => {
        const root = tree.toRawData();
        const { data } = template;
        update({ data: assign({}, data, { root }) });
    };

    const renderInsert = (nindex: number[], category: ModuleCategory, text?: string) => (
        <InsertPopover
            category={advanced ? undefined : category}
            onSelect={(item) => {
                const insert = new Node({
                    node_type: NodeType.Basic,
                    module_key: item.key,
                });
                const updated = tree.insertNodeAfter(nindex, insert);
                updateTree(updated);
            }}
            text={text}
        />
    );

    const renderNode = (node: Node, nindex: number[], error?: AuthErrorCode) => {
        const category = getModuleCategory(node.module_key);
        return (
            <Flex.Container className="center" style={{ justifyContent: 'center' }} key={nindex.toString()}>
                <TemplateStep
                    key={nindex.toString()}
                    node={node}
                    onUpdate={(param, val) => {
                        const updated = tree.updateNode(nindex, param, val);
                        updateTree(updated);
                    }}
                    onDelete={() => {
                        const updated = tree.deleteNode(nindex);
                        updateTree(updated);
                    }}
                    error={error && ErrorMessages[error]}
                />
                {renderInsert(nindex, category)}
            </Flex.Container>
        );
    };

    const renderLeaf = (node: Node, nindex: number[]) => renderNode(node, nindex);

    const renderLeafError = (error: AuthErrorCode, node: Node, nindex: number[]) => renderNode(node, nindex, error);

    const renderCategory = (category: ModuleCategory, children: MaybeElement[], index: number[]) => {
        const categoryDescriptions = {
            [ModuleCategory.Energy]: 'Steps to establish system design details such as consumption and usage profile.',
            [ModuleCategory.Rates]: 'Import project utility rates and add rate escalation.',
            [ModuleCategory.Costs]: 'Define system and maintenance costs.',
            [ModuleCategory.Incentives]: 'Apply any applicable federal, state, and utility incentives.',
            [ModuleCategory.Financing]: 'Add financing options and apply relevant tax deductions.',
            [ModuleCategory.Environmental]: 'Adjust environmental offset coefficients.',
        };

        return (
            <TemplateCategory title={category} description={categoryDescriptions[category]}>
                {...children}
                {isEmpty(children) && (
                    <div style={{ marginLeft: 'auto' }}>{renderInsert(index, category, 'Insert Step')}</div>
                )}
            </TemplateCategory>
        );
    };

    const renderRoot = (_node: Node, children) => {
        return <div>{children}</div>;
    };

    const renderError = () => <Warning msg="Model has errors -- please try creating a new model" />;

    return (
        <PipelineEditor
            tree={tree}
            renderLeaf={renderLeaf}
            renderLeafError={renderLeafError}
            renderCategory={advanced ? null : renderCategory}
            renderRoot={renderRoot}
            renderError={renderError}
        />
    );
};

export { FinancialTemplateEditor };
