import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';

import { registerWidget, RICH_TEXT_WIDGET_NAME } from 'reports/modules/report/widgets';

import { EditTextComponent, ViewTextComponent } from './RichText';

registerWidget<'theme' | 'tokenMap'>(RICH_TEXT_WIDGET_NAME, {
    Component: ViewTextComponent,
    EditingComponent: EditTextComponent,
    metadata: {
        category: 'generic',
        dimensions: { h: 200, w: 300 },
        displayName: Translations.widgets.rich_text_header,
        icon: IconNames.FONT,
        ignoreStyle: true,
    },
    dependencies: ['theme', 'tokenMap'],
});
