import * as React from 'react';
import { connect } from 'react-redux';
import { withRoute } from 'react-router5';

import { Button, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as codes from 'builtin-status-codes';

import { AnchorButton } from 'reports/components/core/controls';
import { DialogOverlay } from 'reports/components/dialog';
import { selectors as authSelectors } from 'reports/modules/auth';
import { retryRouteWithAsUser, retryRouteWithOverride } from 'reports/routing/navPlugin';
import { IWithRouteProps } from 'reports/types';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ErrorHeader = styled.div`
    padding: 12px;

    font-size: 28px;
    font-weight: 400;
    color: #202020;
`;

const ErrorMessage = styled.div`
    font-size: 18px;
    font-weight: 200;
    color: #202020;
`;

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IOwnProps {
    status: any;
    body?: any;
    message?: string | null;
}

export const ErrorPage: React.SFC<IOwnProps & IStateProps & IWithRouteProps> = ({
    status,
    user,
    body,
    message,
    router,
}) => {
    let headerText = 'Unknown error';
    const ownerEmail = body && body.owner_email;

    if (codes[status]) {
        headerText = `${status} ${codes[status]}`;
    }

    const renderOverride = () => {
        if (user!.is_admin && status === 403) {
            return (
                <div style={{ margin: '16px' }}>
                    {ownerEmail ? (
                        <Button
                            text={
                                <span>
                                    Impersonate <b>{ownerEmail}</b>
                                </span>
                            }
                            onClick={() => retryRouteWithAsUser(ownerEmail)}
                            intent={Intent.DANGER}
                            icon={IconNames.PERSON}
                        />
                    ) : (
                        <Button
                            text="Admin Override"
                            onClick={retryRouteWithOverride}
                            intent={Intent.DANGER}
                            icon={IconNames.KEY}
                        />
                    )}
                </div>
            );
        }

        return null;
    };

    return (
        <DialogOverlay isOpen>
            <div className="sub-container">
                <div className="sub-content-container">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        <div style={{ textAlign: 'center' }}>
                            <ErrorHeader>
                                <Icon icon="warning-sign" iconSize={32} />
                            </ErrorHeader>
                            <ErrorHeader>Oh no! {headerText}</ErrorHeader>
                            {message != null && <ErrorMessage>{message}</ErrorMessage>}
                            <div style={{ margin: '16px 0px' }}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        padding: '0px 4px',
                                    }}
                                >
                                    <AnchorButton href={router.buildUrl('app', {})}>Home</AnchorButton>
                                </div>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        padding: '0px 4px',
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            window.location.reload();
                                        }}
                                        icon={IconNames.REFRESH}
                                    >
                                        Refresh
                                    </Button>
                                </div>
                                <div style={{ marginTop: '8px' }}>
                                    <a href="mailto:support@helioscope.com" className="link-normal">
                                        Contact Support
                                    </a>
                                </div>
                            </div>
                            {renderOverride()}
                        </div>
                    </div>
                </div>
            </div>
        </DialogOverlay>
    );
};

const mapStateToProps = (state) => ({
    user: authSelectors.getUser(state),
});

export default connect(mapStateToProps)(withRoute(ErrorPage));
