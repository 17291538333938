/* tslint:disable:variable-name */
import _ from 'lodash';

import { IPipelineModule, getModuleFromRegistry } from 'reports/modules/financials/model/modules';

import { AuthErrorCode, PipelineMetaParam } from './types';
import { validValue } from './pipeline';
import { User } from 'reports/models/user';

export enum NodeType {
    Root = 'ROOT',
    Basic = 'MODULE_BASIC',
    Special = 'MODULE_SPECIAL',
}

export class BaseNode {
    node_type: NodeType;
    module_key: string;
    module: IPipelineModule;
    children: any;
    parameter_settings: Object;
    toggleable: boolean;
    toggled_off: boolean;
    user_label: string | null;
    special?: (state: any) => void;

    constructor(obj) {
        if (obj.node_type !== NodeType.Root && obj.node_type !== NodeType.Special) {
            this.module = getModuleFromRegistry(obj.module_key);
        }

        this.node_type = obj.node_type;
        this.module_key = obj.module_key;
        this.parameter_settings = _.get(obj, 'parameter_settings', {});
        this.toggleable = _.get(obj, 'toggleable', false);
        this.toggled_off = _.get(obj, 'toggled_off', false);
        this.user_label = _.get(obj, 'user_label', null);
        this.special = _.get(obj, 'special', undefined);

        if (_.get(obj, 'parameter_settings', null) == null) {
            this.setDefaults();
        }
    }

    serialize() {
        if (this.node_type === NodeType.Root) {
            return {
                node_type: this.node_type,
            };
        }

        if (this.node_type === NodeType.Basic) {
            return {
                node_type: this.node_type,
                module_key: this.module_key,
                parameter_settings: this.parameter_settings,
                toggleable: this.toggleable,
                toggled_off: this.toggled_off,
                user_label: this.user_label,
            };
        }

        throw new Error('Financial node type not serializable');
    }

    setDefaults() {
        if (this.node_type === NodeType.Basic) {
            if (!this.module) return;

            const settings = {};

            for (const param of this.module.parameters) {
                if (validValue(param.default)) {
                    _.set(settings, param.path, { value: param.default });
                }
            }
            this.parameter_settings = settings;
        }
    }

    getPipelineParameters() {
        if (this.node_type !== NodeType.Basic) {
            throw new Error('Node type does not have parameters.');
        }

        return this.module.parameters.map((i) => {
            const setting = this.parameter_settings[i.path];
            if (setting) return _.merge({}, setting, i);
            return _.merge({ value: i.default }, i);
        }) as PipelineMetaParam[];
    }
}

export class Node extends BaseNode {
    children: Node[] | null;

    constructor(obj) {
        super(obj);
        this.children = null;

        if (obj.children != null) {
            this.children = [];
            for (const child of _.get(obj, 'children', [])) {
                this.children.push(new Node(child));
            }
        }
    }

    copy() {
        return _.merge({}, this) as Node;
    }
}

export class FlattenedNode extends BaseNode {
    children: number[] | null;

    constructor(obj) {
        super(obj);
        this.children = null;

        if (obj.children != null) {
            this.children = _.get(obj, 'children', []);
        }
    }

    copy() {
        return _.merge({}, this) as FlattenedNode;
    }
}

export interface ExecutableNode extends FlattenedNode {
    execute: (state: any) => void | Promise<void>;
    authenticate?: (user: User) => AuthErrorCode;
    debug?: (state: any) => void;
}
