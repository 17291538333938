import * as usr from 'reports/models/user';
import { IChatClient } from './common';

let hasBeenLoggedOut = false;

const ZendeskChat: IChatClient = {
    login(user?: usr.User) {
        if (!user) {
            hasBeenLoggedOut = true;
        }
        if (window['zE'] && user) {
            window['zE'](() => {
                window['zE']('webWidget', 'updateSettings', {
                    webWidget: {
                        authenticate: {
                            chat: {
                                jwtFn(callback) {
                                    fetch('/api/zendesk_jwt').then((resp) => {
                                        if (resp.status === 200) {
                                            resp.json().then((respObj) => {
                                                callback(respObj.token);
                                            });
                                        } else {
                                            callback();
                                        }
                                    });
                                },
                            },
                        },
                    },
                });

                // Every time we call chat:reauthenticate, it causes the chat client to flicker and reinitialize.
                // We only want to do this when we have a freshly logged in user
                if (hasBeenLoggedOut) {
                    window['zE']('webWidget', 'chat:reauthenticate');
                }
            });
        }
    },

    newMessage() {
        if (window['zE']) {
            window['zE'](() => {
                window['zE'].activate();
            });
        }
    },

    // Unimplemented in Zendesk
    updateVisitorStatus() {},
};

export default ZendeskChat;
