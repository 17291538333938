/**
 * Common UI components used across reports.
 */
import _ from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import {
    Alignment,
    Button,
    Classes,
    IButtonProps,
    IEditableTextProps,
    IPopoverProps,
    Popover,
    ProgressBar as bpProgressBar,
    Text,
} from '@blueprintjs/core';

import Flex from 'reports/components/core/containers/Flex';
import FormattedEditor from 'reports/components/helpers/FormattedEditor';
import { bindActions } from 'reports/utils/redux';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export type IButtonAllProps = IButtonProps & { style?: React.CSSProperties };

// TODO: clean these up with dropdown cleanup
export const StyledContainedButton = styled(Button)`
    .${Classes.BUTTON_TEXT} {
        width: calc(100% - 20px);
    }
`;

export const ContainedButton = ({ text, ...buttonProps }: IButtonAllProps) => (
    <StyledContainedButton
        text={<Text ellipsize>{text}</Text>}
        title={typeof text === 'string' ? text : undefined}
        {...buttonProps}
    />
);

// these components should stretch to fill without overflowing parent, clipping inner text in a sane way
export const BehavedButton: React.SFC<IButtonAllProps> = ({ text, fill, ...otherProps }) => {
    if (fill) {
        return (
            <Button
                fill
                alignText={Alignment.LEFT}
                text={
                    <div style={{ position: 'relative', top: '-10px' }}>
                        <div
                            style={{
                                position: 'absolute',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                            }}
                        >
                            {text}
                        </div>
                    </div>
                }
                {...otherProps}
            />
        );
    }
    return <ContainedButton text={text} {...otherProps} />;
};

export const BehavedPopover: React.SFC<IPopoverProps> = ({ ...otherProps }) => (
    <Popover
        boundary="scrollParent"
        modifiers={{ preventOverflow: { enabled: true } }}
        targetProps={{ style: { width: '100%' } }}
        {...otherProps}
    />
);

type IEditableTitleProps = {
    value: string;
    updateFn: (val: string) => any;
    innerRef?: React.Ref<any>;
} & IEditableTextProps;

export const EditableTitle: React.SFC<IEditableTitleProps> = ({ value, updateFn, innerRef, ...editorProps }) => (
    <Flex.Main style={{ padding: '4px 8px' }}>
        <FormattedEditor
            ref={innerRef}
            value={value}
            onConfirm={(val: string) => updateFn(val)}
            formatOnConfirm={(val) => val.trim()}
            formatOnEdit={(val) => val.trim()}
            formatter={(val) => val.trim()}
            selectAllOnFocus={true}
            {...editorProps}
        />
    </Flex.Main>
);

export class EditableTitleSubHeader extends React.Component<{ right?: any } & IEditableTitleProps> {
    titleRef: any;

    render() {
        const { right, updateFn, value, ...editorProps } = this.props;

        return (
            <div className="content-header-bottom">
                <Flex.Container className="inner-default" style={{ whiteSpace: 'nowrap' }}>
                    <Button
                        icon="edit"
                        minimal
                        onClick={() => this.titleRef.onEdit()}
                        disabled={editorProps.disabled}
                    />
                    <EditableTitle
                        innerRef={(ref) => (this.titleRef = ref)}
                        value={value}
                        updateFn={updateFn}
                        className="editable-title"
                        {...editorProps}
                    />
                    <Flex.Container style={{ float: 'right' }}>{right}</Flex.Container>
                </Flex.Container>
            </div>
        );
    }
}

// Absolutely placed progress bar without rounded corners
export const ProgressBarTop = styled(bpProgressBar)`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0;

    .${Classes.PROGRESS_METER} {
        border-radius: 0;
        left: 0;
    }
`;

interface IRedirectHelperProps {
    name: string;
    params: any;
    callback?: () => any;
}

interface IRedirectHelperDispatchProps {
    navigateTo: (name: string, params: object) => any;
}

const _redirectState = { counter: 0 };

class RedirectHelper extends React.Component<IRedirectHelperProps & IRedirectHelperDispatchProps> {
    componentDidMount() {
        if (_redirectState.counter) return;

        _redirectState.counter += 1;

        const { navigateTo, name, params, callback } = this.props;
        _.defer(() => {
            if (callback) callback();
            navigateTo(name, params);
        });
    }

    componentWillUnmount() {
        _redirectState.counter -= 1;
        if (_redirectState.counter < 0) throw new Error();
    }

    render() {
        return null;
    }
}

export const RedirectHelperContainer = connect(
    null,
    bindActions(() => ({
        navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
    })),
)(RedirectHelper);

const windowUnloadFlags = {};

export function flagWindowUnload(flag) {
    windowUnloadFlags[flag] = true;

    window.onbeforeunload = () => {
        return true;
    };
}

export function unflagWindowUnload(flag) {
    delete windowUnloadFlags[flag];

    if (!Object.keys(windowUnloadFlags).length) {
        window.onbeforeunload = null;
    }
}

export const Ellipsis = styled.div`
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

// Source: https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);
