import * as React from 'react';
import { useDispatch } from 'react-redux';

import { api as AdminAPI, AdminData } from 'reports/models/admin_data';

interface IUseAdminDataProps {
    isAdmin: boolean;
}

export const useAdminData = ({ isAdmin }: IUseAdminDataProps) => {
    const dispatch = useDispatch();
    const [adminData, setAdminData] = React.useState<AdminData | null>(null);

    React.useEffect(() => {
        const retrieveAdminData = async () => {
            const result = await dispatch(AdminAPI.get());
            setAdminData(result);
        };
        if (isAdmin) {
            retrieveAdminData();
        }
    }, [isAdmin]);

    return {
        adminDataLoaded: !!adminData,
        authUserIsHSSuperAdmin: adminData?.is_hs_super_admin || false,
        allExperiments: adminData?.all_experiments || [],
        teamExperiments: adminData?.team_experiments || [],
    };
};
