import { map } from 'lodash';

import { convertFromRaw, EditorState, RawDraftContentState, ContentState } from 'draft-js';
import { createSelector } from 'reselect';

import { ITokenMap } from 'reports/modules/report/tokens';
import { ViewProps, EditProps, updateBlockWithValues } from './index';

type TextProps = ViewProps | EditProps;

export const selectors = {
    getRawTextContent: ({ config }: TextProps) => config.content.rawTextContent,

    get getContentState() {
        return createSelector(this.getRawTextContent, (rawTextContent: RawDraftContentState) =>
            rawTextContent ? convertFromRaw(rawTextContent) : null,
        );
    },

    get createEditorState() {
        return createSelector(this.getContentState, (content: ContentState) =>
            content ? EditorState.createWithContent(content) : EditorState.createEmpty(),
        );
    },

    get getContentWithTokenValues() {
        return createSelector(
            this.getRawTextContent,
            (props) => props.context.tokenMap!,
            (props) => props.intl.locale,
            (rawTextContent: RawDraftContentState, tokenMap: ITokenMap, locale: string) => {
                if (!rawTextContent) return null;

                // Update blockMap with token values
                const blocksWithValues = rawTextContent.blocks.map((block) =>
                    updateBlockWithValues(block, tokenMap, locale),
                );
                return convertFromRaw({
                    ...rawTextContent,
                    blocks: blocksWithValues,
                });
            },
        );
    },

    get createViewEditorState() {
        return createSelector(this.getContentWithTokenValues, (content: ContentState) =>
            content ? EditorState.createWithContent(content) : EditorState.createEmpty(),
        );
    },

    get getCompletions() {
        return createSelector(
            (props: TextProps) => props.context.tokenMap,
            (tokenMap) => map(tokenMap, (_, key) => key),
        );
    },
};

export default selectors;
