import * as React from 'react';

import { Text, Button, ButtonGroup } from '@aurorasolar/ds';
import { goToLogin, goToSignup } from './common';
import { useRoute } from 'react-router5';

export const PasswordExpiredLinkMessage = () => {
    const { router } = useRoute();
    const goToForgotPassword = () => router?.navigate('app.forgot-password');
    return (
        <>
            <Text>
                The reset link expired. Click below to get a new link emailed to you — links expire after 30 minutes.
            </Text>
            <Button fullWidth size="md" type="submit" variant="primary" action={goToForgotPassword}>
                Send new reset link
            </Button>
            <ButtonGroup justifyContent="space-between">
                <Button size="md" variant="text" action={goToLogin}>
                    Back to login
                </Button>
                <Button size="md" variant="text" action={goToSignup}>
                    Sign up
                </Button>
            </ButtonGroup>
        </>
    );
};

export default PasswordExpiredLinkMessage;
