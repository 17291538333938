import { addPromiseToasts } from 'reports/modules/Toaster';

const PropertyMap = {
    archive: {
        successToggleOn: (objName) => `Successfully archived ${objName}.`,
        successToggleOff: (objName) => `Successfully removed ${objName} from your archives.`,
        errorToggleOn: (objName) => `Error archiving ${objName}.`,
        errorToggleOff: (objName) => `Error removing ${objName} from your archives.`,
    },
    favorite: {
        successToggleOn: (objName) => `Successfully added ${objName} to your favorites.`,
        successToggleOff: (objName) => `Successfully removed ${objName} from your favorites.`,
        errorToggleOn: (objName) => `Error adding ${objName} to your favorites.`,
        errorToggleOff: (objName) => `Error removing ${objName} from your favorites.`,
    },
    public: {
        successToggleOn: (objName) => `Successfully made ${objName} public.`,
        successToggleOff: (objName) => `Successfully made ${objName} private.`,
        errorToggleOn: (objName) => `Error making ${objName} public.`,
        errorToggleOff: (objName) => `Error making ${objName} private.`,
    },
};

function addPromiseToastsToggle<I>(
    property: keyof typeof PropertyMap,
    promise: Promise<I>,
    typeName: string,
    objName: string,
    priorVal: boolean,
    postUpdate?: (updated) => any,
) {
    const entry = PropertyMap[property];
    return addPromiseToasts(promise, {
        initial: `Saving ${typeName}...`,
        onSuccess: (updated) => {
            const msg = priorVal ? entry.successToggleOff(objName) : entry.successToggleOn(objName);

            if (postUpdate) {
                postUpdate(updated);
            }

            return msg;
        },
        onCatch: () => {
            return priorVal ? entry.errorToggleOff(objName) : entry.errorToggleOn(objName);
        },
    });
}

export default addPromiseToastsToggle;
