import { IAppConfig } from 'reports/config';
import { Loader } from '@googlemaps/js-api-loader';

export let _GOOGLE_MAPS_API_KEY;

export const loader = new Loader({
    apiKey: '',
});

export function initApp(config: IAppConfig) {
    _GOOGLE_MAPS_API_KEY = config.google_maps_api_key;
    loader.apiKey = _GOOGLE_MAPS_API_KEY;
}

export { default as Geocode } from './geocode';
export { default as Timezone } from './timezone';
export default {
    initApp,
};
