import React from 'react';

import { generatePath, useNavigate } from 'react-router-dom';

import { ProjectList } from 'reports/modules/project/components/ProjectList';

const Projects = () => {
    const navigate = useNavigate();
    const navigateToProject = (projectId: number) =>
        navigate({
            pathname: generatePath('/projects/:projectId', { projectId: projectId.toString() }),
        });

    return <ProjectList navigateToProject={navigateToProject} />;
};

export { Projects };
