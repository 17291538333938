import { Classes, Dialog } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SaveCancelButtons } from 'reports/components/core/controls';
import { FormGroup } from 'reports/components/core/forms';
import { Form } from 'reports/components/forms';
import { FormTextInput } from 'reports/components/forms/inputs/experimental';
import * as ws from 'reports/models/weather_source';
import { bindActions } from 'reports/utils/redux';
import { IWeatherSourceUploadItem } from './WeatherSourceUploadLauncher';
import { addPromiseToasts } from 'reports/modules/Toaster';

const DialogBody = styled.div.attrs({ className: Classes.DIALOG_BODY })``;

const StyledFormGroup = styled(FormGroup).attrs({
    inline: true,
    bold: true,
    fill: true,
})`
    .${Classes.FORM_CONTENT} {
        margin-top: 5px; /* align content with label top */
    }
`;

interface IOwnProps {
    onClose: () => void;
    editingItem: IWeatherSourceUploadItem;
    changeWeatherSrc: (item: IWeatherSourceUploadItem, weatherSrc: ws.WeatherSource) => void;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IDispatchProps;

const WeatherSourceUploadEditDialog: React.FC<IProps> = ({
    onClose,
    editingItem,
    saveWeatherSource,
    changeWeatherSrc,
}) => {
    const saveForm = async (form: ws.IWeatherSourceSaveForm) => {
        return await addPromiseToasts(
            (async () => {
                const result = await saveWeatherSource(form);
                changeWeatherSrc(editingItem, result);
                onClose();
                return result;
            })(),
            {
                initial: 'Saving weather source...',
                onSuccess: 'Successfully saved weather source.',
                onCatch: 'Error saving weather source.',
            },
        );
    };

    const weatherSrc = editingItem?.uploadResult;
    if (!weatherSrc) return null;

    const datasetName = weatherSrc.dataset_name ?? weatherSrc.weather_datasets[0]?.name;

    return (
        <Dialog title="Modify Weather Source" isOpen={!!weatherSrc} onClose={onClose} style={{ width: 900 }}>
            <DialogBody>
                <Form baseValue={{ ...weatherSrc, dataset_name: datasetName }} onSubmit={saveForm}>
                    {({ submitForm, dirty }) => (
                        <>
                            <FormTextInput fill inline bold path="name" label="Name" />
                            <FormTextInput fill inline bold path="source" label="Source" />
                            <FormTextInput fill inline bold path="description" label="Description" />
                            <StyledFormGroup label="Location">
                                {weatherSrc.locationString}, {weatherSrc.latLong}
                            </StyledFormGroup>
                            <StyledFormGroup label="Elevation">
                                {weatherSrc.elevation ? `${weatherSrc.elevation}ft` : `-`}
                            </StyledFormGroup>
                            <FormTextInput
                                fill
                                inline
                                bold
                                path="dataset_name"
                                label="Dataset Name"
                                helperText="This is the type of data the weather file represents (typically TMY)"
                            />
                            <SaveCancelButtons
                                onSave={submitForm}
                                hasChanges={dirty}
                                onCancel={onClose}
                                allowEmptyCancel
                            />
                        </>
                    )}
                </Form>
            </DialogBody>
        </Dialog>
    );
};

const mapDispatchToProps = bindActions(() => ({
    saveWeatherSource: ws.api.save,
}));

export default connect(null, mapDispatchToProps)(WeatherSourceUploadEditDialog);
