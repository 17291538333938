import * as React from 'react';

import { Classes, Icon, InputGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';
import { PrimaryButton, PrimaryIntent } from 'reports/components/core/controls';
import { CheckboxInput, Form, FormInput } from 'reports/components/forms';
import { FormErrorCallout } from 'reports/components/helpers/errors';

import { ILoginForm } from 'reports/models/user';
import { StyledLink } from './common';

export interface ILoginFormProps {
    onSubmit: (formData: ILoginForm) => any;
    errorMessage: string | null;
    loading: boolean;
}

export const LoginForm: React.FC<ILoginFormProps> = ({ onSubmit, errorMessage, loading }) => (
    <Form onSubmit={onSubmit}>
        <div className={Classes.DIALOG_BODY}>
            {errorMessage && <FormErrorCallout style={{ marginBottom: '10px' }} errorMsg={errorMessage} />}
            <FormInput
                path="email"
                placeholder="Email Address"
                defaultValue=""
                inputComponent={InputGroup}
                inputProps={{
                    leftIcon: <Icon icon={IconNames.PERSON} />,
                    type: 'email',
                    large: true,
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    spellCheck: 'false',
                }}
            />
            <FormInput
                path="password"
                placeholder="Password"
                defaultValue=""
                inputComponent={InputGroup}
                inputProps={{
                    leftIcon: <Icon icon={IconNames.KEY} />,
                    type: 'password',
                    large: true,
                }}
            />
            <Flex.Container style={{ justifyContent: 'space-between' }}>
                <CheckboxInput
                    path="remember_me"
                    defaultValue={false}
                    label="Keep me signed in"
                    style={{ marginBottom: '0px' }}
                />
                <PrimaryButton
                    large
                    type="submit"
                    text="Sign In"
                    loading={loading}
                    intent={PrimaryIntent.CREATE}
                    style={{ marginBottom: '0px' }}
                />
            </Flex.Container>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
            <Flex.Container style={{ justifyContent: 'space-between' }}>
                <div>
                    <StyledLink routeName="app.forgot-password">Forgot password?</StyledLink>
                </div>
                <div>
                    <StyledLink routeName="app.signup">Sign up</StyledLink>
                </div>
            </Flex.Container>
        </div>
    </Form>
);

export default LoginForm;
