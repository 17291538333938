import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'reports/store';
import { ProfilesParameter, Section2 } from 'reports/modules/profile/components/common';

import * as pro from 'reports/models/profile';
import { IncentiveTable } from 'reports/modules/financials/components/ProjectIncentiveEditor';
import * as inc from 'reports/models/incentive';
import { find } from 'lodash';

interface IOwnProps {
    profile: pro.FinancialProfile;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

class FinancialPreviewContents extends React.PureComponent<IProps> {
    render() {
        const { profile, allIncentives } = this.props;
        const { financial_template } = profile;
        const { incentive_ids } = profile.data;
        const incentives = incentive_ids
            .map((x) => find(allIncentives, { incentive_id: x }))
            .filter((x) => x) as inc.Incentive[];

        return (
            <>
                <Section2 title="Financial Template Selection">
                    <ProfilesParameter label="Financial Model">
                        {financial_template ? `${financial_template.toString()}` : 'None selected'}
                    </ProfilesParameter>
                </Section2>
                <Section2 title="Incentives" contextEl={<></>}>
                    <IncentiveTable incentives={incentives} />
                </Section2>
            </>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    allIncentives: inc.selectors.all(state),
});

export default connect(mapStateToProps)(FinancialPreviewContents);
