import * as React from 'react';
import * as Q from 'q';
import * as sa from 'superagent';

import { createRoot } from 'react-dom/client';

import {
    Position,
    Toaster as BPToaster,
    Classes,
    Intent,
    IToaster,
    IToastOptions,
    IToasterProps,
    Spinner,
} from '@blueprintjs/core';
import { isPromise, AsyncThunk } from 'reports/utils/async_helpers';

import { ProgressToast } from 'reports/modules/ProgressToast';
import { IAppState } from 'reports/store';

/*
We cannot initialize blueprint 3 Toasters using Toaster.create after upgrading
React to version 18 due to Toaster.create internally using a deprecated react-dom
method. Instead we need to set singleton toasters by asynchronously setting references
to toasters in a react hook useToasters(). This allows us to avoid having to call Toaster.create
and minimizes how much code refactoring we have to do across all components that use Toaster and UserToaster.

We are providing a mock interface of a Toaster below so that we don't have to guard against calls to Toaster.show.
This interface is pulled from blueprint 3's docs and is coerced into an IToaster to ensure the type checker doesn't
complain about Toaster or UserToaster being the wrong type.

Any new toasters need to be created in this file and set appropriately in useToasters.
*/
const toasterMethods = {
    clear: (): void => undefined,
    dismiss: (_key: string): void => undefined,
    getToasts: (): IToastOptions[] => [],
    show: (_props: IToasterProps, _key?: undefined | string) => '',
};

let Toaster: IToaster = { ...toasterMethods } as IToaster;
let UserToaster: IToaster = { ...toasterMethods } as IToaster;

const createToaster = (props?: IToasterProps, container = document.body) => {
    const containerElement = document.createElement('div');
    container.appendChild(containerElement);
    const root = createRoot(containerElement);
    return new Promise<BPToaster>((resolve, reject) => {
        root.render(
            <BPToaster
                {...props}
                usePortal={false}
                ref={(instance) => {
                    if (!instance) {
                        reject(new Error('[Blueprint] Unable to create toaster.'));
                    } else {
                        resolve(instance);
                    }
                }}
            />,
        );
    });
};

// Hook to initialize Toasters across the application. This should only be called in app.tsx.
const useToasters = () => {
    const [toastersInitialized, setToastersInitialized] = React.useState<boolean>(false);
    React.useEffect(() => {
        const fetchToaster = async () => {
            if (!toastersInitialized) {
                Toaster = await createToaster({ position: Position.TOP });
                UserToaster = await createToaster({ position: Position.BOTTOM });
                setToastersInitialized(true);
            }
        };
        fetchToaster();
        return () => {};
    }, []);
};

type PromiseCallback<I = any> = (ProgressCallback) => Promise<I>;
type ThunkCallback<I = any, S = IAppState> = (ProgressCallback) => AsyncThunk<I, S>;
type InjectableFunc<I, S> = PromiseCallback<I> | ThunkCallback<I, S>;

interface ProgressOptions {
    text: string;
    getPercent?: (...args) => number;
}

const progressEvtCallback = (evt: sa.ProgressEvent) => evt.percent || 0;

/**
 * wraps an async function to create a progress bar that updates based on the results
 *
 * const result = await addProgressBar(onProgress => functionThatReturnsPromiseOrAction(onProgresss));
 */
const addProgressBar = <I, S>(
    callable: InjectableFunc<I, S>,
    { text, getPercent = progressEvtCallback }: ProgressOptions,
): Promise<I> | AsyncThunk<I, S> => {
    const toaster = new ProgressToast(text, Toaster);
    const toastDefer = Q.defer();
    toastDefer.promise.then(() => toaster.success());
    toastDefer.promise.catch(() => toaster.failure());

    const result = callable((evt) => toaster.progress(getPercent(evt)));

    if (isPromise(result)) {
        toastDefer.resolve(result);
        return result;
    }

    // if it's an async thunk, need to return a wrapped thunk to get the result of the action
    return (dispatch, getState, extra) => {
        const res = result(dispatch, getState, extra);
        toastDefer.resolve(res);
        return res;
    };
};

interface GetString<I> {
    (x: I): string | Promise<string>;
}

interface PromiseToastMessages<I = any> {
    initial: string;
    onSuccess: string | GetString<I>;
    onCatch: string | GetString<any>;
}

const addPromiseToasts = (
    promise: Promise<any>,
    messages: PromiseToastMessages,
    showSpinner: boolean = false,
): Promise<any> => {
    const toast = messages.initial
        ? Toaster.show({
              message: messages.initial,
              timeout: 0,
              icon: showSpinner ? (
                  <span style={{ margin: 10, marginRight: 0 }}>
                      <Spinner className={Classes.SMALL} />
                  </span>
              ) : null,
          })
        : undefined;

    promise
        .then(async (x) => {
            const message = typeof messages.onSuccess === 'string' ? messages.onSuccess : await messages.onSuccess(x);
            Toaster.show({ message, intent: Intent.SUCCESS }, toast);
        })
        .catch((x) => {
            const message = typeof messages.onCatch === 'string' ? messages.onCatch : messages.onCatch(x);
            Toaster.show({ message, intent: Intent.DANGER }, toast);
        });

    return promise;
};

export { Toaster as default, addProgressBar, addPromiseToasts, Toaster, UserToaster, useToasters };
