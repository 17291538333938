import * as cfg from 'reports/config';
import { StoreContainer } from './store_container';

export const getClassicUrl = (path: string) => {
    const config = cfg.selectors.getConfig(StoreContainer.store!.getState())!;

    return config.primary_url_root ? `${config.primary_url_root}/${path}` : `/${path}`;
};

export const getBillingUrl = () => {
    return getClassicUrl('account/me/billing');
};
