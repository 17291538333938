/* tslint:disable:variable-name function-name */

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { schema } from './schema';

class ConsumptionParserOptions extends BaseClass {
    name?: string;
    consumption_parser_options_id?: number;

    datetime_format: string;
    is_power?: boolean; // kwh vs kwp
    is_kilo?: boolean; // kwh vs wh
    row_start: number;
    date_column: number;
    energy_column: number;
    interval_length: number; // minutes

    constructor(args) {
        super(ConsumptionParserOptions.deserializer(args));
    }

    static deserializer = BaseClass.getDeserializer({});
}

interface IParserOptionsForm {
    name: string;
    datetime_format: string;
    is_power?: boolean; // kwh vs kwp
    is_kilo?: boolean; // kwh vs wh
    row_start: number;
    date_column: number;
    energy_column: number;
    interval_length: number; // minutes
    bookmarked: boolean;
}

const schemaObj = schema.addObject(ConsumptionParserOptions, 'consumption_parser_options', {});

const endpoint = ReduxEndpoint.fromSchema('/api/consumption_parser_options/', schemaObj);

const api = {
    index: endpoint.index<{ team_id: number; bookmarked?: boolean }>(),
    get: endpoint.get<{ consumption_parser_options_id: number }>('{consumption_parser_options_id}'),
    create: endpoint.post(),
};

const selectors = {
    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

export { api, selectors, schemaObj, IParserOptionsForm, ConsumptionParserOptions };
