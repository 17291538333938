import { default as Geocode } from 'reports/utils/maps/geocode';
import { default as SCF } from 'reports/utils/scf';

import { User } from 'reports/models/user';

import { OutputCategory } from 'reports/modules/financials/model/debug';
import { IPipelineModule } from 'reports/modules/financials/model/modules/types';
import { AuthErrorCode, ResultType } from 'reports/modules/financials/model/pipeline/types';

import { ParamValueType } from 'reports/modules/financials/params';

import { TIME_INTERVALS } from '../core';

function getPaymentYearly(production, rate, periodYears, escalatorYearly, endPayment = 0) {
    const productionYearly = production.reaggregateInterval(TIME_INTERVALS.YEAR);
    const coeffsYearly = productionYearly.map((_i, _j, _k, idx) =>
        idx >= periodYears ? 0.0 : 1.0 + escalatorYearly * idx,
    );
    const paymentYearly = productionYearly
        .multiplySeries(coeffsYearly)
        .map((i, _j, _k, idx) => -i * rate - (idx === periodYears - 1 ? endPayment : 0.0));
    return paymentYearly;
}

export const BasicFinancingCashSimple = {
    description: 'Cash Financing',
    parameters: [],
    module: {
        main: () => {},
    },
};

export const BasicFinancingLoanSimple: IPipelineModule = {
    description: 'Loan Financing',
    parameters: [
        {
            description: 'Downpayment',
            path: 'downpayment',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 1000.0,
        },
        {
            description: 'Loan Term (Months)',
            path: 'period_months',
            type: ParamValueType.Integer,
            min_value: 0,
            max_value: 999,
            default: 120,
        },
        {
            description: 'Annual Percentage Rate',
            path: 'apr',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.06,
        },
        {
            description: 'Dealer Fee',
            path: 'dealer_fee_pct',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { downpayment, period_months, apr, dealer_fee_pct = 0 } = params;
            const { systemInitial } = state;

            const principleBase = Math.max(0, -systemInitial - downpayment);

            const dealerFee = -systemInitial / (1 - dealer_fee_pct) + systemInitial;
            const principle = principleBase + dealerFee;

            state.systemInitial -= dealer_fee_pct === 0 ? 0 : dealerFee;
            state.financeInitial += principleBase + dealerFee;

            const rate = apr / 12.0;
            const paymentPeriodic = (principle * rate) / (1.0 - (1.0 + rate) ** -period_months);
            const payment = rate === 0.0 ? principle / period_months : paymentPeriodic;

            const monthly = state.financeYearly.reaggregateInterval(TIME_INTERVALS.MONTH).map((_i, _j, _k, idx) => {
                if (idx >= period_months) return 0.0;
                return payment;
            });

            const paymentYearly = monthly.reaggregateInterval(TIME_INTERVALS.YEAR);
            state.financeYearly.subSeries(paymentYearly);

            let remaining = principle;
            const interestYearly = monthly
                .map((monthlyPayment) => {
                    const i = rate * remaining;
                    remaining -= monthlyPayment - i;
                    return i;
                })
                .reaggregateInterval(TIME_INTERVALS.YEAR);

            if (state.interestYearly) {
                state.interestYearly.addSeries(interestYearly);
            } else state.interestYearly = interestYearly;
        },
        debugOutputs: [
            { table: OutputCategory.Financials },
            { table: OutputCategory.Interest },
            { table: OutputCategory.System },
        ],
    },
};

export const BasicFinancingPPASimple: IPipelineModule = {
    description: 'PPA Financing',
    parameters: [
        {
            description: 'Downpayment',
            path: 'downpayment',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
        {
            description: 'End of Term Payment',
            path: 'end_payment',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
        {
            description: 'PPA Rate (per kWh)',
            path: 'rate_per_kwh',
            type: ParamValueType.Currency,
            min_value: 0,
            max_value: 999.0,
            default: 0.14,
            precision: 4,
        },
        {
            description: 'PPA Term (Years)',
            path: 'period_years',
            type: ParamValueType.Integer,
            min_value: 0,
            max_value: 99,
            default: 8,
        },
        {
            description: 'Escalation Rate',
            path: 'escalator_yearly',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { downpayment, end_payment, rate_per_kwh, period_years, escalator_yearly } = params;
            const { production, systemInitial } = state;

            state.financeInitial += Math.max(0.0, -systemInitial - downpayment);

            const paymentYearly = getPaymentYearly(
                production,
                rate_per_kwh,
                period_years,
                escalator_yearly,
                end_payment,
            );

            state.ppaRate = rate_per_kwh;

            state.financeYearly.addSeries(paymentYearly);

            state.ppaTermYears = period_years;
        },
        debugOutputs: [{ table: OutputCategory.Financials }],
    },
};

export const SCFFinancingPPA: IPipelineModule = {
    description: 'Sustainable Capital Finance PPA',
    parameters: [
        {
            description: 'PPA Term (Years)',
            path: 'period_years',
            type: ParamValueType.Integer,
            min_value: 0,
            max_value: 35,
            default: 8,
        },
        {
            description: 'Escalation Rate (%)',
            path: 'escalator_yearly',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 3.0,
            default: 0.0,
        },
    ],
    module: {
        main: async function main(state, params) {
            const {
                name,
                location,
                mountType,
                nameplate,
                production,
                systemInitial,
                hourlyData,
                incentiveYearly,
                appConfig,
            } = state;
            const { escalator_yearly, period_years } = params;

            const hostState = await Geocode.getStateFromLocation(
                location.lat,
                location.lon,
                appConfig.google_maps_api_key,
            );

            const rebateYearly = Object.assign(
                SCF.EmptyYearlyRebateParam,
                ...incentiveYearly.values.map((amt, i) => ({
                    [`rcYear${i + 1}`]: amt,
                })),
            );

            const quickQuoteParams = {
                solveFor: SCF.QuickQuoteType.ppa,
                projectName: name,
                state: hostState.short_name,

                // Expects project size/output in kW
                projectTotalSize: nameplate / 1000,
                annualOutput: hourlyData.reduce((sum, data) => sum + data.grid_power, 0) / 1000,

                buildCost: -systemInitial / nameplate,
                ppaTerm: period_years,
                ppaEscalator: escalator_yearly * 100,
                mountingType: mountType,
                localIncentivePBI: SCF.IncentiveStatus.inactive,
                pbi: 0,
                pbiYears: 0,
                localIncentiveRebate: SCF.IncentiveStatus.active,
                rebate: state.incentiveInitial,
                rebateYearCost: rebateYearly,
                localIncentiveSREC: SCF.IncentiveStatus.inactive,
                srecContractType: SCF.SRECContractType.uncontracted,
            };

            const quote = await SCF.getQuickQuote(quickQuoteParams);
            const paymentYearly = getPaymentYearly(production, quote.ppa, period_years, escalator_yearly);

            // Set overall project ppa rate (used with tokens)
            state.ppaRate = quote.ppa;
            // Set ppa rate for SCF specific output value
            state.ppaRateSCF = quote.ppa;

            state.financeInitial += Math.max(0.0, -systemInitial);
            state.financeYearly.addSeries(paymentYearly);

            state.ppaTermYears = period_years;
        },
        authenticate: (user: User) =>
            user.hasFeature('enable_scf') && user.team.scf_company_id
                ? AuthErrorCode.AUTHORIZED
                : AuthErrorCode.SCF_UNAUTHORIZED,
        debugOutputs: [{ table: OutputCategory.Financials }],
    },
    outputValues: [
        {
            type: ResultType.Currency,
            description: 'PPA Rate (per kWh)',
            path: 'ppaRateSCF',
            info:
                'The PPA rate will be calculated dynamically ' +
                'by SCF for each project based on the term, escalator and ' +
                'system information',
        },
    ],
};
