import { keyBy } from 'lodash';

import * as scen from 'reports/models/scenario';
import { RackType } from 'reports/models/field_segment';

// single_axis is currently unsupported, so it is excluded here
const SUPPORTED_RACK_TYPES = [RackType.carport, RackType.dual, RackType.flush, RackType.rack];

const keyByRackType = (params) => keyBy(params, 'rack_type');

const makeCellTempParams = ({ cell_temp_parameters: params, cell_temp_model: model }) => ({
    ...keyByRackType(scen.DEFAULT_CELL_TEMP_PARAMS[model]),
    ...keyByRackType(params),
});

export { SUPPORTED_RACK_TYPES, makeCellTempParams, keyByRackType };
