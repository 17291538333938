/* tslint:disable:variable-name */

import _ from 'lodash';

import { ReduxEndpoint, BaseClass, defaults } from 'reports/utils/api';

import { Vector } from 'helioscope/app/utilities/geometry';

import { schema } from './schema';

import * as design from './design';
import * as mc from './module/ModuleCharacterization';

type OrientationType = 'vertical' | 'horizontal';

enum RackType {
    rack = 'rack',
    flush = 'flush',
    dual = 'dual',
    carport = 'carport',
    single_axis = 'single_axis',
}

class FieldSegment extends BaseClass {
    field_segment_id: number;
    description: string;

    design_id: number;
    design: design.Design;

    wiring_zone_id: number;
    wiring_zone: any;
    wiring_priority: number;

    module_characterization_id: number;
    module_characterization: mc.ModuleCharacterization;

    shadow_caster: boolean;
    refrence_height: number;
    tilt: number;
    independent_tilt_enabled: boolean;
    independent_tilt_surface_tilt: number;
    independent_tilt_surface_azimuth: number;
    azimuth: number;
    orientation: OrientationType;
    alignment: 'left' | 'center' | 'right' | 'block';

    row_spacing: number;
    module_spacing: number;
    frame_spacing: number;
    dome_spacing: number;
    bank_depth: number;
    bank_width: number;
    rack_type: RackType;
    inner_setback: number;
    max_size: number;
    geometry: FSGeometry;
    data: IFSData;
    racking: any;

    constructor(data: any) {
        super(FieldSegment.deserializer(data));
    }

    static deserializer = FieldSegment.getDeserializer({
        geometry: (x) => new FSGeometry(x),
        data: defaults({ modules: 0, frames: 0, power: 0, area: 0 }),
    });

    moduleCount() {
        return _.get(this, 'data.modules', 0);
    }

    power() {
        return _.get(this, 'data.power', 0);
    }
}

const _newVec = (pt) => new Vector(pt);
const _newVecArray = (arr) => arr.map(_newVec);

class FSGeometry extends BaseClass {
    path: Vector[];
    path_3d: Vector[];
    base_3d: Vector[];
    layout_start: Vector;
    removed_module_locations: Vector[];

    constructor(data) {
        super(FSGeometry.deserializer(data));
    }

    static deserializer = FSGeometry.getDeserializer({
        path: _newVecArray,
        path_3d: _newVecArray,
        base_3d: _newVecArray,
        removed_module_locations: _newVecArray,
        layout_start: _newVec,
    });
}

interface IFSData {
    area: number;
    frames: number;
    modules: number;
    power: number;
}

const schemaObj = schema.addObject(FieldSegment, 'field_segment', {
    relationships: {
        module_characterization: { schema: mc.schemaObj },
    },
});
const endpoint = ReduxEndpoint.fromSchema('/api/field_segments/', schemaObj);

const api = {
    index: endpoint.index<{ design_id?: number }>(),
    get: endpoint.get('{field_segment_id}'),
    create: endpoint.post(),
    save: endpoint.put('{field_segment_id}'),
    delete: endpoint.delete('{field_segment_id}'),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { api, FieldSegment, endpoint, schemaObj, selectors, OrientationType, RackType };
