import * as React from 'react';
import { Icon, Intent } from '@blueprintjs/core';

import Colors from 'reports/styles/colors';

import FormField from 'reports/components/forms/FormField';
import { FormGroup, IFormGroupProps } from 'reports/components/core/forms';
import { FormattedInput } from 'reports/components/helpers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export interface IItem<T> {
    key: string;
    name: T;
}

interface IDict<T> {
    [key: string]: T;
}

export const fromDict = <T,>(items: IDict<T>): IItem<T>[] =>
    Object.keys(items).map((key) => ({ key, name: items[key] }));

// Select Common Components
export const ICON_SIZE = 14;
export const ICON_MARGIN = 5;

const SelectIcon = styled(Icon)`
    svg {
        margin-top: -15px;
        margin-right: ${ICON_MARGIN}px;
    }
`;

export const FavoriteSelectIcon = () => <SelectIcon icon="star" color={Colors.INTENT_FAVORITE} size={ICON_SIZE} />;

interface IInnerProps<T = any> {
    disabled: boolean;
    onChange: (item: T) => void;
    value: T;
}

interface IFormFieldProps {
    path: string;
    disabled?: boolean;
}

interface IRemoveButtonProps {
    isNullable?: boolean;
}

type IOuterProps = IFormGroupProps & IFormFieldProps & IRemoveButtonProps;

// A type rather than an interface (e.g. FunctionComponent) is necessary to propagate the generic type
// (e.g. the T in BasicSelect<T>)
// See https://codewithstyle.info/Typing-Higher-Order-Components-in-React/
export type SimpleFunctionComponent<P> = (props: P) => React.ReactElement;

/**
 * Adds a FormField wrapper and FormGroup to a simple input component
 * so that it can be used within a standard <Form>
 * @param InnerComponent an input component
 * @returns The input component wrapped in FormField and FormGroup
 */
export const withForm = <P extends object>(
    InnerComponent: SimpleFunctionComponent<P & IInnerProps>,
): SimpleFunctionComponent<Omit<P, keyof IInnerProps> & IOuterProps> => {
    const FormifiedComponent = (props: P & IOuterProps) => {
        const { path, label, inline, fill, bold, helperText, labelWidth, isNullable, disabled, ...otherProps } = props;

        return (
            <FormField path={path}>
                {({ value, onChange, form, errors }) => {
                    const invalid = errors.length > 0;

                    const innerProps: IInnerProps = {
                        value,
                        onChange,
                        disabled: form.submitting || !!disabled,
                    };

                    return (
                        <FormGroup
                            inline={inline}
                            fill={fill}
                            bold={bold}
                            label={label}
                            helperText={invalid ? errors[0] : helperText}
                            intent={invalid ? Intent.DANGER : undefined}
                            labelWidth={labelWidth}
                        >
                            <InnerComponent fill={fill} {...innerProps} {...(otherProps as P)} />
                            {isNullable && value != null && (
                                <a onClick={() => onChange(null)} style={{ marginLeft: 7 }}>
                                    Remove
                                </a>
                            )}
                        </FormGroup>
                    );
                }}
            </FormField>
        );
    };

    return FormifiedComponent;
};

export const withFormBare = <P extends object>(
    InnerComponent: SimpleFunctionComponent<P & IInnerProps>,
): SimpleFunctionComponent<Omit<P, keyof IInnerProps> & IOuterProps> => {
    const FormifiedComponent = (props: P & IOuterProps) => {
        const { path, fill, helperText, disabled, ...otherProps } = props;

        return (
            <FormField path={path}>
                {({ value, onChange, form, errors }) => {
                    const innerProps: IInnerProps = {
                        value,
                        onChange,
                        disabled: form.submitting || !!disabled,
                    };

                    return (
                        <BareInputErrorWrapper errors={errors}>
                            <InnerComponent fill={fill} {...innerProps} {...(otherProps as P)} />
                        </BareInputErrorWrapper>
                    );
                }}
            </FormField>
        );
    };

    return FormifiedComponent;
};

/**
 * Wrapper for FormBare* components which will insert error messages directly under the bare input
 */
export const BareInputErrorWrapper = ({ errors, children }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {children}
            {errors.length > 0 && <ErrorBlock>{errors[0]}</ErrorBlock>}
        </div>
    );
};
export const ErrorBlock = styled.span`
    color: ${Colors.INTENT_DANGER};
    font-size: 12px;
    margin-top: 5px;
    font-weight: normal;
`;

/**
 * For use by DecimalInput and UnitsInput
 */
export const StyledFormattedInput = FormattedInput;
