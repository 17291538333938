import * as Q from 'q';
import Logger from 'js-logger';

import { ImageFile } from 'react-dropzone';
import { actionCreatorFactory } from 'typescript-fsa';

import { createAsyncWorkflow } from 'reports/utils/async_helpers';
import { S3File, api } from 'reports/models/s3file';
import { superagent, request } from 'reports/modules/request';
import { addProgressBar } from 'reports/modules/Toaster';

const actionCreator = actionCreatorFactory('S3_FILES');

const logger = Logger.get('uploads');

export interface ProgressCallback {
    (evt: superagent.ProgressEvent): void;
}

interface IPutRequest {
    content_type: string;
    get_url: string;
    put_url: string;
    seed: string;
}

const noop: ProgressCallback = () => {};

/**
 * get permissions, and save a file to S3, but do not store it in the HS database
 * @param file
 * @param onProgress
 */
export async function uploadFileToS3(
    rawFile: ImageFile | File,
    onProgress: ProgressCallback = noop,
    metadata: any,
    options: any = null,
) {
    const filename = rawFile.name;
    const size = rawFile.size;

    const req = await api.request_upload.request({ filename, size }, options);
    return await putFileToS3(req, rawFile, onProgress, metadata, options);
}

export async function putFileToS3(
    req: IPutRequest,
    rawFile: ImageFile | File,
    onProgress: ProgressCallback = noop,
    metadata: any,
    options: any,
) {
    const filename = rawFile.name;
    const size = rawFile.size;

    const { put_url, get_url, seed, content_type } = req;

    const send = request.put(put_url).send(rawFile).on('progress', onProgress).set('Content-Type', content_type);

    if (options && options.gzip) {
        send.set('Content-Encoding', 'gzip');
    }

    const [response, dimensions] = await Promise.all([
        send,
        getDimensions(rawFile).catch(() => {
            logger.warn(`Could not find dimensions for ${rawFile.name}`);
            return null;
        }),
    ]);
    const meta = { ...dimensions, ...metadata };

    if (!response.ok) {
        throw new Error('count not upload to s3');
    }

    return { put_url, get_url, seed, content_type, filename, size, meta };
}

export type IUpload = {
    file: ImageFile | File;
    onProgress?: ProgressCallback;
    name?: string;
    meta?: any;
};

const uploadFile = createAsyncWorkflow<IUpload, S3File>(
    'UPLOAD_S3_FILE',
    actionCreator,
    async ({ file, meta, onProgress }, dispatch) => {
        const payload = await uploadFileToS3(file, onProgress, meta);
        const s3file = await dispatch(api.create(payload));
        return s3file;
    },
);

function isImageFile(unknownFile: ImageFile | File): unknownFile is ImageFile {
    return (<ImageFile>unknownFile).preview != null;
}

/**
 * parse image dimensions from a file
 * @param file
 */
export async function getDimensions(file: ImageFile | File) {
    const deferred = Q.defer<{ width: number; height: number }>();
    let objectUrl;

    if (isImageFile(file) && file.preview != null) {
        objectUrl = file.preview;
        delete file.preview;
    } else {
        objectUrl = window.URL.createObjectURL(file);
    }

    const img = new Image();
    img.src = objectUrl;
    img.onload = () => deferred.resolve({ width: img.width, height: img.height });
    img.onerror = () => deferred.reject();

    deferred.promise.finally(() => URL.revokeObjectURL(img.src));

    return deferred.promise;
}

export const actions = {
    uploadFile,
    uploadFileWithProgress: ({ file, meta }: { file: File | ImageFile; meta?: any }) => {
        return addProgressBar((onProgress) => uploadFile({ file, meta, onProgress }), { text: file.name });
    },
};
