import { get, mapKeys } from 'lodash';

import { actionTypes } from 'redux-router5';

import * as analytics from 'reports/analytics/analytics';
import { analyticsTracker, defaultHandler, setCurrentRoute } from 'reports/analytics/analyticsTracker';
import * as auth from 'reports/modules/auth';

enum Actions {
    TRANSITION_SUCCESS = actionTypes.TRANSITION_SUCCESS as any,
    LOGIN = auth.api.login.done as any,
}

function handleTransition(_getState, action) {
    setCurrentRoute(get(action, 'payload.route', {}));

    const previousRouteParams = mapKeys(
        get(action, 'payload.previousRoute.params', {}),
        (_, k) => 'previous_route_' + k,
    );
    const routeParams = mapKeys(get(action, 'payload.route.params', {}), (_, k) => 'route_' + k);

    return {
        eventType: analytics.EventType.TRACK,
        payload: {
            event: 'Pageview: ' + get(action, 'payload.route.name', null),
            properties: {
                app: 'reports',
                view: get(action, 'payload.route.name', null),
                previous_route_name: get(action, 'payload.previousRoute.name', null),
                previous_route_path: get(action, 'payload.previousRoute.path', null),
                ...previousRouteParams,
                route_name: get(action, 'payload.route.name', null),
                route_path: get(action, 'payload.route.path', null),
                ...routeParams,
            },
        },
    };
}

const options = {
    defaultHandler,
    subscriptions: {
        [Actions.TRANSITION_SUCCESS]: {
            subscribed: true,
            handler: handleTransition,
        },
    },
};

const middleware = analyticsTracker(options);
export default middleware;
