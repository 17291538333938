/**
 * File browser dialog
 */
import { isEmpty } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';

import { Checkbox, Dialog, InputGroup, Label } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';

import Image from 'reports/components/helpers/Image';
import UploadableImage, { IFileTag } from 'reports/modules/files/components/UploadableImage';
import Translations from 'reports/localization/strings';

import { api, S3File } from 'reports/models/s3file';

import {
    ButtonRndTransp,
    DialogBody,
    FileContainer,
    FileName,
    FileSearch,
    Filters,
    Gallery,
    ImageContainer,
} from './common';

interface IDispatchProps {
    deleteFile: (fileId: number) => Promise<null>;
    getTeamFiles: (extensions: string[]) => Promise<S3File[]>;
}

interface IFileDialogProps {
    fileType: IFileTag;
    isOpen: boolean;
    allowDelete?: boolean;
    onAddNew?: (file: S3File) => void;
    onClose?: (e?: React.SyntheticEvent) => void;
    onSelect?: (fileId: number) => void;
    onSelectFileDelete?: () => void;
    selectedFile?: number;
    filterByTags?: IFileTag[];
}

interface IState {
    filterByTags: IFileTag[];
    filteredFiles: S3File[];
    filters: { [k: string]: boolean };
    searchVal: string;
}

type ITagMap = { [k: string]: IFileTag };

type IProps = IFileDialogProps & IDispatchProps & { intl: IntlShape };

class FileDialogIntl extends React.PureComponent<IProps, IState> {
    static filterTagMap: ITagMap = {
        logo: 'logo',
        overlay: 'overlay',
        reportImage: 'report_image',
    };

    allFiles: S3File[] = [];

    state: IState = {
        filteredFiles: [],
        filterByTags: this.props.filterByTags || [],
        filters: {
            logo: false,
            overlay: false,
            reportImage: false,
        },
        searchVal: '',
    };

    componentDidMount() {
        const { filterByTags } = this.state;
        const filters = { ...this.state.filters };

        if (filterByTags.length) {
            for (const [key, val] of Object.entries(FileDialogIntl.filterTagMap)) {
                if (filterByTags.find((f) => f === val) !== undefined) {
                    filters[key] = true;
                }
            }
            this.setState({ filters });
        }
    }

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.filterByTags !== this.state.filterByTags) {
            this.filterByTag();
        }
        if (prevState.searchVal !== this.state.searchVal) {
            this.filterBySearch();
        }
    }

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <Dialog
                title={<FormattedMessage {...Translations.general.browse_team_files} />}
                isOpen={this.props.isOpen}
                style={{ width: 800, height: 500 }}
                onClose={this.closeDialog}
                onOpening={this.loadFiles}
            >
                <DialogBody>
                    <FileSearch>
                        <InputGroup
                            value={this.state.searchVal}
                            onChange={this.updateSearchVal}
                            placeholder={formatMessage(Translations.general.search_files)}
                            leftIcon={IconNames.SEARCH}
                            type="text"
                        />
                        <Filters>
                            <Label>
                                <FormattedMessage {...Translations.image.filter_by} />
                            </Label>
                            <Checkbox
                                label={formatMessage(Translations.image.logos)}
                                checked={this.state.filters['logo']}
                                onChange={() => this.updateSelectedFilters('logo')}
                                inline={true}
                            />
                            <Checkbox
                                label={formatMessage(Translations.design.overlays)}
                                checked={this.state.filters['overlay']}
                                onChange={() => this.updateSelectedFilters('overlay')}
                                inline={true}
                            />
                            <Checkbox
                                label={formatMessage(Translations.image.report_images)}
                                checked={this.state.filters['reportImage']}
                                onChange={() => this.updateSelectedFilters('reportImage')}
                                inline={true}
                            />
                        </Filters>
                    </FileSearch>
                    <Gallery>
                        {this.props.onAddNew ? (
                            <FileContainer>
                                <ImageContainer>
                                    <UploadableImage
                                        fileId={null}
                                        onUpdate={this.addNewFile}
                                        tag={this.props.fileType}
                                    />
                                </ImageContainer>
                            </FileContainer>
                        ) : null}
                        {this.renderImages()}
                    </Gallery>
                </DialogBody>
            </Dialog>
        );
    }

    renderImages = () =>
        this.state.filteredFiles.map((file, idx) => (
            <FileContainer key={idx}>
                <ImageContainer
                    onClick={(e) => this.selectFile(e, file)}
                    className={
                        this.props.selectedFile != null && this.props.selectedFile === file.file_id
                            ? 'selected disabled'
                            : ''
                    }
                >
                    <Image src={file.get_url} className="img-rounded" />
                    {this.props.allowDelete ? (
                        <ButtonRndTransp
                            icon={IconNames.TRASH}
                            onClick={(e) => this.deleteFile(e, file.file_id, idx)}
                        />
                    ) : null}
                </ImageContainer>
                <FileName title={file.filename}>{file.filename}</FileName>
            </FileContainer>
        ));

    addNewFile = (file: S3File) => {
        this.updateFiles([file, ...this.allFiles]);
        this.props.onAddNew && this.props.onAddNew(file);
        this.closeDialog();
    };

    closeDialog = (e?: React.SyntheticEvent<HTMLElement>) => {
        // Reset filters
        this.setState({
            filters: { logo: false, overlay: false, reportImage: false },
            searchVal: '',
        });

        this.props.onClose && this.props.onClose(e);
    };

    deleteFile = (e: React.SyntheticEvent, fileId: number, fileIdx: number) => {
        e.stopPropagation();

        if (fileId === this.props.selectedFile && this.props.onSelectFileDelete) {
            this.props.onSelectFileDelete();
        }

        const files = this.allFiles.slice();
        files.splice(fileIdx, 1);

        this.updateFiles(files);
        this.props.deleteFile(fileId);
    };

    /**
     * Filter files by current search query. Automatically update state if no file list is given.
     * @param {Array} [files] - use given file list and don't update state yet, otherwise use master list.
     * @returns Updated list of files filtered by search query
     */
    filterBySearch = (files?: S3File[]) => {
        const { filterByTags, searchVal } = this.state;

        let filteredFiles = files || this.allFiles;
        if (searchVal) {
            const regex = new RegExp(searchVal, 'i');
            filteredFiles = filteredFiles.filter((file) => regex.test(file.filename));
        }

        if (!files) {
            // Re-apply tag filters if necessary
            if (filterByTags.length) {
                filteredFiles = this.filterByTag(filteredFiles);
            }
            this.setState({ filteredFiles });
        }
        return filteredFiles;
    };

    /**
     * Filter files by active tag filters. Automatically update state if no file list is given.
     * @param {Array} [files] - use given file list and don't update state yet, otherwise use master list.
     * @returns Updated list of files filtered by current active tags
     */
    filterByTag = (files?: S3File[]) => {
        const { filterByTags } = this.state;

        let filteredFiles = files || this.allFiles;
        if (filterByTags.length) {
            filteredFiles = filteredFiles.filter((file) => {
                if (!file.meta || isEmpty(file.meta.tags)) {
                    return false;
                }

                const { tags } = file.meta;
                for (const filter of filterByTags) {
                    if (tags!.find((tag) => tag === filter) !== undefined) {
                        return true;
                    }
                }
                return false;
            });
        }

        if (!files) {
            // Re-apply search if necessary
            if (this.state.searchVal) {
                filteredFiles = this.filterBySearch(filteredFiles);
            }
            this.setState({ filteredFiles });
        }
        return filteredFiles;
    };

    loadFiles = async () => {
        const teamFiles = await this.props.getTeamFiles(['jpeg', 'jpg', 'png']);
        this.updateFiles(teamFiles);
    };

    selectFile(e: React.SyntheticEvent, file: S3File) {
        this.props.onSelect && this.props.onSelect(file.file_id);
        this.props.onClose && this.props.onClose(e);
    }

    updateFiles = (files: S3File[]) => {
        this.allFiles = files;

        const filteredFiles = this.filterByTag(this.filterBySearch(files));
        this.setState({ filteredFiles });
    };

    updateSearchVal = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({ searchVal: (e.target as HTMLInputElement).value });
    };

    updateSelectedFilters = (selectedFilter: keyof ITagMap) => {
        const filterByTags = this.state.filterByTags.slice();
        const filterTag = FileDialogIntl.filterTagMap[selectedFilter];
        const updatedToggle = !this.state.filters[selectedFilter];

        if (updatedToggle) {
            filterByTags.push(filterTag);
        } else {
            const filterIdx = filterByTags.findIndex((filter) => filter === filterTag);
            if (filterIdx > -1) {
                filterByTags.splice(filterIdx, 1);
            }
        }

        const filters = {
            ...this.state.filters,
            [selectedFilter]: updatedToggle,
        };
        this.setState({ filters, filterByTags });
    };
}

const FileDialog = injectIntl(FileDialogIntl);

const mapDispatchToProps = bindActions({
    deleteFile: (fileId) => api.delete({ file_id: fileId }),
    getTeamFiles: (extensions) => api.index({ extensions }),
});

export default connect(null, mapDispatchToProps)(FileDialog);
