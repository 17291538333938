/*
 * SearchSelect with async querying
 */
import { includes } from 'lodash';
import * as React from 'react';

import { MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ISelectProps } from '@blueprintjs/select';

import { SearchSelect } from 'reports/components/core/forms';

interface IQuerySelect<SelectType = any> extends Omit<ISelectProps<SelectType>, 'items' | 'itemRenderer'> {
    selectItems: SelectType[];
    text: string;
    getId: (obj: SelectType) => number;
    getText: (obj: SelectType) => any;
    queryItems: (params: any) => Promise<any>;
}

export const QuerySelect = ({ getId, getText, text, selectItems, queryItems, ...otherProps }: IQuerySelect) => {
    const [searching, setSearching] = React.useState<boolean>(false);
    const [itemQueryIds, setItemQueryIds] = React.useState<number[]>([]);
    const listItems = selectItems.filter((i) => includes(itemQueryIds, getId(i)));

    const queryItemsRef = React.useRef(queryItems);

    React.useEffect(() => {
        queryItemsRef.current = queryItems;
    }, [queryItems]);

    return (
        <SearchSelect
            buttonText={text}
            icon={IconNames.PANEL_STATS}
            items={listItems}
            searching={searching}
            itemRenderer={(item, { handleClick, modifiers }) => (
                <MenuItem
                    key={getId(item)}
                    text={getText(item)}
                    active={modifiers.active}
                    onClick={handleClick}
                    style={{
                        maxWidth: '500px',
                    }}
                />
            )}
            onTextChange={() => {
                setSearching(true);
                setItemQueryIds([]);
            }}
            applySearch={(value) => {
                const cleaned = value.trim();
                if (cleaned === '') {
                    setSearching(false);
                    setItemQueryIds([]);
                    return;
                }

                queryItemsRef.current({ limit: 50, q: cleaned }).then((res) => {
                    setSearching(false);
                    setItemQueryIds(res.map(getId));
                });
            }}
            inputProps={{
                style: {
                    minWidth: '320px',
                },
            }}
            {...otherProps}
        />
    );
};

export default QuerySelect;
