import * as React from 'react';

import { Intent, InputGroup, Button } from '@blueprintjs/core';

interface IPasswordInputProps {
    disabled?: boolean;
    value: string;
    onChange: (value: string) => void;
}

const PasswordInput = (props: IPasswordInputProps) => {
    const { value, disabled = false, onChange } = props;

    const [showPassword, setShowPassword] = React.useState(false);

    const lockButton = (
        <Button
            disabled={disabled}
            icon={showPassword ? 'eye-off' : 'eye-on'}
            intent={Intent.WARNING}
            minimal={true}
            onClick={() => setShowPassword(!showPassword)}
        />
    );

    return (
        <InputGroup
            autoFocus
            value={value}
            onChange={(evt) => {
                onChange(evt.target.value);
            }}
            disabled={disabled}
            type={showPassword ? 'text' : 'password'}
            rightElement={lockButton}
        />
    );
};

export default PasswordInput;
