/* tslint:disable:variable-name function-name */

import moment from 'moment';

import { GeoPoint, Vector } from 'helioscope/app/utilities/geometry';

import { BaseClass, defaults, ReduxEndpoint, createAsyncSaver, ensureProperty } from 'reports/utils/api';
import { humanizeWatts } from 'reports/utils/formatters';

import { schema } from './schema';
import * as ashrae from './ashrae_weather';
import * as consumption from './user_consumption';
import * as design from './design';
import { Incentive } from './incentive';
import * as projFinTemp from './project_financial_template';
import type { ReportConfiguration } from './report/ReportConfiguration';
import * as scenario from './scenario';
import * as team from './team';
import { IAPIQueryOpts } from './types';
import * as user from './user';
import * as usage from './usage_profile';
import * as utility from './utility_rate';
import { ILatLong } from './common';

interface IProjectOrientation {
    azimuth: number;
    poa_irradiance: number;
    tilt: number;
}

class ProjectUser extends BaseClass {
    project_id: number;
    user_id: number;
    is_admin: boolean;
    first_name?: string;
    last_name?: string;
    email?: string;
    company?: string;
}

class Project extends BaseClass {
    project_id: number;
    location: GeoPoint;
    name: string;
    address: string;
    description: string;

    customer_name: string;

    designs: design.Design[];
    scenarios: scenario.Scenario[];
    project_financial_templates: projFinTemp.ProjectFinancialTemplate[];
    report_configurations: ReportConfiguration[];
    incentives: Incentive[];

    data: ProjectData;

    geometry: {
        optimal_orientations: {
            [k in number]: IProjectOrientation; // key is weather_dataset_id
        };

        pcc_location: Vector;
    };

    creator_id: number;
    creator: user.User;
    is_admin: boolean; // whether or not the effective user is a project admin
    last_modified_by_user_id: number;
    last_modified_by_user: user.User;
    favorited: boolean;
    archived: boolean;
    users: ProjectUser[];
    share_hash: string;

    team_id: number;
    team: team.Team;

    created: Date;
    last_modified: Date;

    ac_config_id: number;
    ac_config: any;

    ashrae_weather_id: number;
    ashrae_weather: ashrae.AshraeWeather;

    time_zone_offset: number; // raw UTC offset (not DST adjusted)

    primary_design_id?: number;
    primary_design: design.Design;
    primary_scenario_id?: number;
    primary_scenario: scenario.Scenario;
    primary_project_financial_template_id?: number;
    primary_project_financial_template: projFinTemp.ProjectFinancialTemplate;

    profile_id?: number;
    profile: any;

    utility_rate_id?: number;
    utility_rate?: utility.UtilityRate;
    usage_profile_id?: number;
    usage_profile?: usage.UsageProfile;

    user_consumption_id?: number;
    user_consumption?: consumption.UserConsumption;
    user_consumptions: consumption.UserConsumption[];

    metadata: {
        designs: number;
        max_size: number;
        min_size: number;
        scenarios: number;
        simulations: number;
        users: number;
    };

    constructor(data) {
        super(Project.deserializer(data));
    }

    get sizeRange() {
        if (!this.metadata || this.metadata.min_size == null) {
            return undefined;
        }

        return (
            `${humanizeWatts(this.metadata.min_size)}` +
            (this.metadata.min_size !== this.metadata.max_size ? ` - ${humanizeWatts(this.metadata.max_size)}` : '')
        );
    }

    static deserializer = BaseClass.getDeserializer({
        created: (x) => moment(x),
        last_modified: (x) => moment(x),
        location: (x) => new GeoPoint(x),
        data: ensureProperty((x) => new ProjectData(x)),
        geometry: defaults({ optimal_orientations: {} }),
        incentives: (incs) => incs.map((i) => new Incentive(i)),
    });
}

class ProjectData extends BaseClass {
    financialConfig: any; // deprecated
    financial_settings: FinancialRateSettings;

    constructor(data) {
        super(ProjectData.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        financialConfig: defaults({}),
        financial_settings: ensureProperty((x) => new FinancialRateSettings(x)),
    });
}

class FinancialRateSettings extends BaseClass {
    flat_rate_kwh: number;
    rate_mode: IRateMode;
    net_metering: boolean;
    annual_true_up: boolean;
    net_export_rate_kwh: number;

    constructor(rawData) {
        // Default net_metering=true for new projects (empty project financial settings),
        // but default net_metering=false for old projects (has project financial settings, but no net_metering flag)
        const data = rawData == null ? { net_metering: true } : rawData;
        super(FinancialRateSettings.deserialize(data));
    }

    static deserialize = defaults({
        flat_rate_kwh: 0.14,
        rate_mode: 'FLAT_KWH',
        net_metering: false,
        annual_true_up: false,
        net_export_rate_kwh: 0.14,
    });
}

type IRateMode = 'FLAT_KWH' | 'UTILITY_SIMPLE';

const schemaObj = schema.addObject(Project, 'project', {
    relationships: {
        creator: { schema: user.schemaObj },
        last_modified_by_user: { schema: user.schemaObj },
    },
});

const { selector: ashraeWeatherSelector } = schemaObj.addRelationship('ashrae_weather', ashrae.schemaObj);
const { selector: teamSelector } = schemaObj.addRelationship('team', team.schemaObj);
const { selector: usageProfile } = schemaObj.addRelationship('usage_profile', usage.schemaObj);
const { selector: utilityRate } = schemaObj.addRelationship('utility_rate', utility.schemaObj);
const { selector: userConsumption } = schemaObj.addRelationship('user_consumption', consumption.schemaObj);

const { selector: primaryDesign } = schemaObj.addRelationship('primary_design', design.schemaObj);
const { selector: primaryScenario } = schemaObj.addRelationship('primary_scenario', scenario.schemaObj);
const { selector: primaryProjectFinancialTemplate } = schemaObj.addRelationship(
    'primary_project_financial_template',
    projFinTemp.schemaObj,
);

const { backrefSelector: projectFinancialTemplates, selector: projectFinancialTemplateProjectSelector } =
    projFinTemp.schemaObj.addRelationship('project', schemaObj, {
        backref: 'project_financial_templates',
    });

const { backrefSelector: designs, selector: designProjectSelector } = design.schemaObj.addRelationship(
    'project',
    schemaObj,
    {
        backref: 'designs',
    },
);

const { backrefSelector: scenarios, selector: scenarioProjectSelector } = scenario.schemaObj.addRelationship(
    'project',
    schemaObj,
    {
        backref: 'scenarios',
    },
);

const { backrefSelector: userConsumptions } = consumption.schemaObj.addRelationship('project', schemaObj, {
    backref: 'user_consumptions',
});

const { selectByObject } = schemaObj;

const fullSelect = (state: any, obj: any | Project) => selectByObject(state, obj, '*');

const endpoint = ReduxEndpoint.fromSchema('/api/projects/', schemaObj, {
    deepSelect: {
        creator: true,
        last_modified_by_user: true,
        designs: true,
        scenarios: true,
        project_financial_templates: true,
    },
});

export interface IProjectAPIQueryOpts extends IAPIQueryOpts {
    creator_id?: number;
    include_archived?: boolean;
    favorited?: boolean;
    location?: ILatLong;
    utility_rate_id?: number;
}

interface IProjectAPIGetOpts {
    project_id: number | string;
    metadata?: boolean;
}

interface IProjectUserAPIOpts {
    project_id: number | string;
    user_id: number | string;
}

interface IModifyUserOpts {
    is_admin: boolean | string;
}

export interface IProjectForm {
    address: string;
    location: ILatLong;
    name: string;
    ac_config_id?: number | string;
    description?: string;
    profile_id?: number;
}

export interface IProjectSaveForm extends IProjectForm {
    primary_scenario_id?: number;
    customer_name?: string;
    creator_id?: number;
}

const api = {
    index: endpoint.index<IProjectAPIQueryOpts>(),
    get: endpoint.get<IProjectAPIGetOpts>('{project_id}'),
    create: endpoint.post<IProjectForm>(),
    save: endpoint.put<IProjectSaveForm>('{project_id}'),
    addUser: endpoint.post<IProjectUserAPIOpts>('{project_id}/users/{user_id}'),
    modifyUser: endpoint.put<IModifyUserOpts, IProjectUserAPIOpts>('{project_id}/users/{user_id}'),
    deleteUser: endpoint.delete<IProjectUserAPIOpts>('{project_id}/users/{user_id}', {
        onSuccess: (rawData) => schemaObj.dataLoaded(rawData),
    }),
};

const saver = createAsyncSaver(schemaObj, api.save);

const selectors = {
    primaryDesign,
    primaryScenario,
    primaryProjectFinancialTemplate,

    utilityRate,
    usageProfile,
    userConsumption,
    ashraeWeatherSelector,
    teamSelector,

    designs,
    scenarios,
    projectFinancialTemplates,
    userConsumptions,

    fromDesign: designProjectSelector,
    fromScenario: scenarioProjectSelector,
    fromProjFinTemp: projectFinancialTemplateProjectSelector,

    byObject: fullSelect,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

const actions = {
    dataLoaded: schemaObj.dataLoaded,
};

export {
    schemaObj,
    Project,
    ProjectUser,
    FinancialRateSettings,
    api,
    selectors,
    actions,
    saver,
    IProjectOrientation,
    GeoPoint,
};
