import React from 'react';
import { generatePath, useNavigate } from 'react-router';

import * as pd from 'reports/models/power_device';
import { PowerDeviceLibrary } from 'reports/modules/power_device/components/PowerDeviceLibrary';

type Props = {
    preview?: React.ReactElement;
    selectedPowerDeviceId?: number;
};

const PowerDevices = ({ preview, selectedPowerDeviceId }: Props) => {
    const navigate = useNavigate();
    const navigateToCharacterization = ({
        power_device_id,
        power_device_characterization_id,
    }: pd.PowerDeviceCharacterization) => {
        const path = generatePath('/components/:powerDeviceId/characterizations/:characterizationId/preview', {
            powerDeviceId: power_device_id.toString(),
            characterizationId: power_device_characterization_id.toString(),
        });
        navigate(path);
    };
    const navigateToDefaultCharacterization = ({ power_device_id, defaultCharacterizationId }: pd.PowerDevice) => {
        const path = generatePath('/components/:powerDeviceId/characterizations/:characterizationId/preview', {
            powerDeviceId: power_device_id.toString(),
            characterizationId: defaultCharacterizationId().toString(),
        });
        navigate(path);
    };
    return (
        <PowerDeviceLibrary
            navigateToCharacterization={navigateToCharacterization}
            navigateToDefaultCharacterization={navigateToDefaultCharacterization}
            preview={preview}
            powerDeviceId={selectedPowerDeviceId}
            inlineControls={true}
        />
    );
};

export { PowerDevices };
