import { ControlGroup, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import styled from 'styled-components';

import { CreateButton, DeleteButton, PrimaryButton } from 'reports/components/core/controls';

import BasicTable from 'reports/components/core/tables/BasicTable';
import { FormErrorCallout } from 'reports/components/helpers/errors';
import { humanizeBytes } from 'reports/utils/formatters';
import { IUploadItem } from './common';

const FixedWidthTable = styled(BasicTable)`
    table-layout: fixed;
    && td {
        overflow-wrap: break-word;
    }
`;

const CenteredControlGroup = styled(ControlGroup)`
    justify-content: center;
`;

const DetailsContainer = styled.div`
    margin: 0 auto;
    text-align: justify;
    width: fit-content;
`;

const FileCell: React.FC<{ item: IUploadItem }> = ({ item: { file } }) => (
    <td>
        <strong>{file.name}</strong>
        <br />
        {humanizeBytes(file.size)}
    </td>
);

interface IDetailsField {
    name: string;
    path: string;
}
export const DetailsCell: React.FC<{
    obj: any;
    detailsFields: IDetailsField[];
}> = ({ obj, detailsFields }) => (
    <td>
        <DetailsContainer>
            {detailsFields.map((field) => {
                const { name, path } = field;
                const value = obj[path] ? obj[path] : '-';

                return (
                    <>
                        <strong>{name}:</strong> {value}
                        <br />
                    </>
                );
            })}
        </DetailsContainer>
    </td>
);

const LoadingCell: React.FC<{ colSpan: number }> = ({ colSpan }) => <td colSpan={colSpan}>Loading...</td>;

const ErrorCell: React.FC<{
    errorMsg: string;
    colSpan: number;
}> = ({ errorMsg, colSpan }) => (
    <td colSpan={colSpan}>
        <FormErrorCallout errorMsg={errorMsg} />
    </td>
);

interface ActionCellProps<T extends IUploadItem> {
    item: T;
    uploadItem: (item: T) => Promise<void>;
    previewItem?: (item: T) => void;
    removeItem: (item: T) => void;
    postUploadActions: (item: T) => React.ReactNode;
    isUploadDisabled?: (item: T) => boolean;
}
class ActionsCell<T extends IUploadItem> extends React.PureComponent<ActionCellProps<T>> {
    render() {
        const { item, uploadItem, previewItem, removeItem, postUploadActions, isUploadDisabled } = this.props;
        const { status } = item;

        // When there's an error, we want to just show the Delete button.
        if (status === 'error') {
            return (
                <td>
                    <DeleteButton onClick={() => removeItem(item)} />
                </td>
            );
        }

        // If loading or uploading a new file, show spinner.
        if (status === 'loading' || status === 'uploading') {
            return (
                <td>
                    <Spinner />
                </td>
            );
        }

        // If item is staged, show action buttons.
        if (status === 'staged') {
            return (
                <td>
                    <CenteredControlGroup>
                        <CreateButton
                            text="Upload"
                            icon={IconNames.UPLOAD}
                            onClick={() => uploadItem(item)}
                            disabled={isUploadDisabled ? isUploadDisabled(item) : false}
                        />
                        {previewItem && (
                            <PrimaryButton text="Preview" icon={IconNames.SEARCH} onClick={() => previewItem(item)} />
                        )}
                        <DeleteButton onClick={() => removeItem(item)} />
                    </CenteredControlGroup>
                </td>
            );
        }
        // Otherwise, the item has already been uploaded.
        return <td>{postUploadActions(item)}</td>;
    }
}

export interface IProps<T extends IUploadItem> {
    helperText: string;
    errorMsg: string;
    items: T[];
    removeItem: (item: T) => void;
    uploadItem: (item: T) => Promise<void>;
    previewItem?: (item: T) => void;
    isUploadDisabled?: (item: T) => boolean;
    customTableColumns: {
        title: string;
        cell: (item: T) => React.ReactNode;
    }[];
    postUploadActions: (item: T) => React.ReactNode;
}

// Abstracted for shared use with weather source upload
export class BatchUploadTable<T extends IUploadItem> extends React.PureComponent<IProps<T>> {
    render() {
        const {
            items,
            customTableColumns,
            uploadItem,
            previewItem,
            removeItem,
            postUploadActions,
            isUploadDisabled,
            helperText,
            errorMsg,
        } = this.props;
        return (
            <FixedWidthTable centered width="100%">
                <thead>
                    <tr>
                        <th>File</th>
                        {customTableColumns.map((cell) => {
                            return <th>{cell.title}</th>;
                        })}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {items.length === 0 ? (
                        <tr>
                            <td colSpan={2 + customTableColumns.length}>{helperText}</td>
                        </tr>
                    ) : (
                        items.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <FileCell item={item} />
                                    {item.status === 'error' ? (
                                        <ErrorCell errorMsg={errorMsg} colSpan={customTableColumns.length} />
                                    ) : item.status === 'loading' ? (
                                        <LoadingCell colSpan={customTableColumns.length} />
                                    ) : (
                                        <>
                                            {customTableColumns.map((cell) => {
                                                return cell.cell(item);
                                            })}
                                        </>
                                    )}
                                    <ActionsCell
                                        item={item}
                                        uploadItem={uploadItem}
                                        previewItem={previewItem}
                                        removeItem={removeItem}
                                        postUploadActions={postUploadActions}
                                        isUploadDisabled={isUploadDisabled}
                                    />
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </FixedWidthTable>
        );
    }
}

export default BatchUploadTable;
