import * as React from 'react';

import * as scen from 'reports/models/scenario';

import Section2 from 'reports/components/core/containers/Section2';
import { FormField } from 'reports/components/forms';

import CharacterizationEditTable from './CharacterizationEditTable';

const ConditionSetComponentCharsEdit: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2
        title="Component Characterizations"
        subtitle="Define precisely which module characterizations (e.g. PAN files) will be used during the simulation"
    >
        <FormField path="module_characterizations">
            {({ value: scenMCs, onChange: onScenMCsChange }) => (
                <FormField path="power_device_characterizations">
                    {({ value: scenPDCs, onChange: onScenPDCsChange }) => (
                        <CharacterizationEditTable
                            scenario={scenario}
                            scenMCs={scenMCs}
                            scenPDCs={scenPDCs}
                            onScenMCsChange={onScenMCsChange}
                            onScenPDCsChange={onScenPDCsChange}
                        />
                    )}
                </FormField>
            )}
        </FormField>
    </Section2>
);

export default ConditionSetComponentCharsEdit;
