import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import { MODULE_ORIENTATION_NAMES, RACKING_TYPE_NAMES } from 'reports/utils/formatters';

import * as pro from 'reports/models/profile';
import * as mod from 'reports/models/module';

import { FormField, NestedFields } from 'reports/components/forms';
import { FormBareNumericInput, FormBasicSelect, fromDict, IItem } from 'reports/components/forms/inputs/experimental';
import { StyledFormGroup } from 'reports/components/core/forms';
import { Card, Flex } from 'reports/components/core/containers';

import {
    LABEL_WIDTH,
    Section2,
    AlignmentIcons,
    ProfileUserDistanceInput,
    ProfileNumericInput,
    ProfileUnitsInput,
    ProfilesParameter,
} from 'reports/modules/profile/components/common';
import { FormDateTimeRangePicker } from 'reports/modules/profile/components/FormDateTimeRangePicker';
import FormBareButtonGroupSelect from 'reports/components/forms/inputs/experimental/FormBareButtonGroupSelect';
import { User } from 'reports/models/user';

const TiltSelectionEditor: React.FC = () => (
    <FormField path="tilt_strategy">
        {({ value }: { value: pro.TiltStrategyType }) => (
            <>
                <FormBasicSelect<IItem<string>>
                    inline
                    bold
                    label="Strategy"
                    labelWidth={LABEL_WIDTH}
                    path="tilt_strategy"
                    dataSource={{
                        items: fromDict(pro.TiltStrategyTypes),
                        keyLookup: ({ key }) => key,
                    }}
                    itemRenderer={({ key, name }) => ({ key, text: name })}
                />
                {value === 'fixed' && <ProfileUnitsInput label="Tilt" path="tilt" unitTagText="&deg;" />}
                {value === 'latitude' && <b>Tilt will be set to Project Latitude</b>}
                {value === 'simple_optimization' && (
                    <div style={{ marginTop: 5 }}>
                        <b>Tilt will be Optimized for Project Latitude</b>
                        <ul>
                            <li>Latitude is below 25&deg;: lat &times; 0.87</li>
                            <li>Latitude is between 25&deg; and 50&deg;: lat &times; 0.76 + 3.1&deg;</li>
                            <li>Latitude is above 50&deg;: 45&deg;</li>
                        </ul>
                    </div>
                )}
            </>
        )}
    </FormField>
);

const RowSpacingEditor: React.FC = () => (
    <FormField path="row_spacing_strategy">
        {({ value }: { value: pro.RowSpacingType }) => (
            <>
                <FormBasicSelect<IItem<string>>
                    inline
                    bold
                    label="Strategy"
                    labelWidth={LABEL_WIDTH}
                    path="row_spacing_strategy"
                    dataSource={{
                        items: fromDict(pro.RowSpacingTypes),
                        keyLookup: ({ key }) => key,
                    }}
                    itemRenderer={({ key, name }) => ({ key, text: name })}
                />
                {value === 'fixed' && <ProfileUserDistanceInput label="Spacing" path="row_spacing" />}
                {value === 'span_to_rise' && (
                    <ProfileNumericInput label="Span to rise" path="span_to_rise" min={0} max={50} />
                )}
                {value === 'gcr' && <ProfileNumericInput label="GCR" path="gcr" min={0} max={2} />}
                {value === 'shading' && (
                    <ProfilesParameter label="Time Range">
                        <FormDateTimeRangePicker />
                    </ProfilesParameter>
                )}
            </>
        )}
    </FormField>
);

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface MechanicalProfileEditor {
    user: User;
}

type IProps = IDispatchProps & MechanicalProfileEditor;

const MechanicalProfileEditor = ({ getModules, user }: IProps) => (
    <NestedFields path="data">
        <Card>
            <Section2 title="Module Selection">
                <FormBasicSelect<mod.Module>
                    inline
                    bold
                    label="Module"
                    labelWidth={LABEL_WIDTH}
                    path="module_id"
                    isNullable={true}
                    dataSource={{
                        async: true,
                        query: async (q) => getModules({ q }),
                        keyLookup: (module) => module.module_id,
                        itemLookup: (moduleId, state) => mod.selectors.byId(state, moduleId)!,
                    }}
                    itemRenderer={(module: mod.Module) => ({
                        key: module.module_id,
                        text: `${module.manufacturer}, ${module.name}`,
                    })}
                    noneSelected="No module selected"
                />
            </Section2>
        </Card>
        <Card>
            <Section2 title="Racking Selection" subtitle="The core racking type and layout">
                <FormBasicSelect<IItem<string>>
                    inline
                    bold
                    label="Racking"
                    labelWidth={LABEL_WIDTH}
                    path="rack_type"
                    dataSource={{
                        items: fromDict(
                            user?.hasSingleAxisTrackersAccess()
                                ? RACKING_TYPE_NAMES
                                : Object.keys(RACKING_TYPE_NAMES)
                                      .filter((key) => !key.includes('single_axis'))
                                      .reduce((cur, key) => {
                                          return Object.assign(cur, { [key]: RACKING_TYPE_NAMES[key] });
                                      }, {}),
                        ),
                        keyLookup: ({ key }) => key,
                    }}
                    itemRenderer={({ key, name }) => ({ key, text: name })}
                />
                <FormBasicSelect<IItem<string>>
                    inline
                    bold
                    label="Orientation"
                    labelWidth={LABEL_WIDTH}
                    path="orientation"
                    dataSource={{
                        items: fromDict(MODULE_ORIENTATION_NAMES),
                        keyLookup: ({ key }) => key,
                    }}
                    itemRenderer={({ key, name }) => ({ key, text: name })}
                />
                <StyledFormGroup inline label="Frame Size" labelWidth={LABEL_WIDTH}>
                    <Flex.Container>
                        <FormBareNumericInput
                            path="bank_depth"
                            placeholder=""
                            min={1}
                            max={50}
                            width={70}
                            integerOnly
                        />
                        <span style={{ margin: 5 }}>up</span>
                        <FormBareNumericInput
                            path="bank_width"
                            placeholder=""
                            min={0}
                            max={250}
                            width={70}
                            integerOnly
                        />
                        <span style={{ margin: 5 }}>wide</span>
                    </Flex.Container>
                </StyledFormGroup>
                <StyledFormGroup inline label="Alignment" labelWidth={LABEL_WIDTH}>
                    <FormBareButtonGroupSelect
                        path="alignment"
                        items={['left', 'center', 'right', 'block']}
                        itemRenderer={(alignment) => ({
                            key: alignment,
                            text: AlignmentIcons[alignment],
                        })}
                        toolTipRenderer={(alignment) => ({
                            key: alignment,
                            text: pro.AlignmentTypes[alignment],
                        })}
                    />
                </StyledFormGroup>
            </Section2>
        </Card>
        <Card>
            <Section2 title="Tilt Selection" subtitle="How your starting tilt will be selected">
                <TiltSelectionEditor />
            </Section2>
        </Card>
        <Card>
            <Section2 title="Row Spacing" subtitle="How your starting row spacing will be selected">
                <RowSpacingEditor />
            </Section2>
        </Card>
        <Card>
            <Section2 title="Other Layout Rules" subtitle="How your field segments will be laid out initially">
                <ProfileUserDistanceInput label="Setback" path="inner_setback" />
                <ProfileUserDistanceInput label="Module Spacing" path="module_spacing" />
                <ProfileUserDistanceInput label="Frame Spacing" path="frame_spacing" />
            </Section2>
        </Card>
    </NestedFields>
);

const mapDispatchToProps = bindActions(() => ({
    getModules: mod.api.index,
}));

export default connect(null, mapDispatchToProps)(MechanicalProfileEditor);
