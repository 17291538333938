import { _GOOGLE_MAPS_API_KEY } from './index';

// From Google Maps Timezone
// https://developers.google.com/maps/documentation/timezone/
export interface TimezoneResult {
    dstOffset: number; // seconds
    rawOffset: number; // seconds
    timeZoneId: string;
    timeZoneName: string;
}

async function getTimezoneFromLocation(lat, lon): Promise<TimezoneResult> {
    const resp = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/` +
            `json?location=${lat},${lon}&timestamp=1458000000&` +
            `key=${_GOOGLE_MAPS_API_KEY}`,
    );
    return resp.json();
}

export default {
    getTimezoneFromLocation,
};
