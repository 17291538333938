import Logger from 'js-logger';
import moment from 'moment';
import { clamp, isNaN, round } from 'lodash';

import { DEFAULT_LOCALE, ICurrency } from 'reports/localization';
import { user } from 'reports/modules/auth';
import { METER_IN_FEET, METER_IN_KILOMETERS, METER_IN_MILES } from 'reports/utils/constants';

const logger = Logger.get('formatters');

export interface INumberOptions {
    precision?: Precision;
    locale?: string;
    unit?: string;
    unitDisplay?: 'long' | 'narrow' | 'short';
}

// Unit names supported by toLocaleString:
// tslint:disable-next-line:max-line-length
// https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
const DISTANCE_UNITS = ['centimeter', 'foot', 'inch', 'meter', 'mile', 'millimeter', 'kilometer', 'yard'] as const;

const DURATION_UNITS = ['day', 'hour', 'millisecond', 'minute', 'month', 'second', 'week', 'year'] as const;

const LOCALIZABLE_UNITS = [
    ...DISTANCE_UNITS,
    ...DURATION_UNITS,
    'acre',
    'bit',
    'byte',
    'celsius',
    'degree',
    'fahrenheit',
    'fluid-ounce',
    'gallon',
    'gigabit',
    'gigabyte',
    'gram',
    'hectare',
    'kilobit',
    'kilobyte',
    'kilogram',
    'liter',
    'megabit',
    'megabyte',
    'milliliter',
    'ounce',
    'percent',
    'petabyte',
    'pound',
    'stone',
    'terabit',
    'terabyte',
];

export type Precision = number | 'full';

type IStringifyNumberOptions = INumberOptions & Intl.NumberFormatOptions;

export function stringifyNumber(num: number | null | undefined, options: IStringifyNumberOptions = {}): string {
    if (num === null || num === undefined) {
        return '';
    }

    const { precision, locale = DEFAULT_LOCALE.code, unit, unitDisplay, ...otherOpts } = options;
    const precisionOpts: any = {};
    if (precision === 'full') {
        if (Math.abs(num) <= 1e-5) {
            // If number is tiny, use scientific notation so that we can display as many fraction digits as possible.
            precisionOpts.notation = 'scientific';
        }
        // Always include at least one fraction digit, to convey precision. I.e. 13.0 vs 13
        precisionOpts.minimumFractionDigits = 1;
        precisionOpts.maximumFractionDigits = 20;
    } else {
        precisionOpts.minimumFractionDigits = precision;
        precisionOpts.maximumFractionDigits = precision;
    }

    const unitOpts = LOCALIZABLE_UNITS.includes(unit as any) ? { unit, unitDisplay, style: 'unit' } : null;
    let formattedNum = num.toLocaleString(locale, {
        ...unitOpts,
        ...otherOpts,
        ...precisionOpts,
    });
    // If toLocaleString isn't familiar with the unit, manually append it to the end.
    if (unit != null && unitOpts == null) {
        formattedNum = `${formattedNum} ${unit}`;
    }

    if (locale === 'es-ES') {
        // Check if formattedNum should have an additional period for the thousands place. This is necessary
        // because according to CLDR, the minimum # of grouping digits is 2 for the European Spanish locale, but
        // in practice it's usually 1.
        const match = formattedNum.match(/^(-?\d)(\d{3}(\D.*)?)$/);
        if (match) {
            const [thousandsDigit, rest] = match.slice(1, 3);
            formattedNum = thousandsDigit + '.' + rest;
        }
    }
    return formattedNum;
}

const POWER_UNITS = {
    units: ['W', 'kW', 'MW', 'GW', 'TW'],
    base: 1000,
};

const BYTE_UNITS = {
    units: ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    base: 1024,
};

function getScaledUnits(value: number, { units, base }: { units: string[]; base: number }) {
    const scaleFactor = clamp(Math.floor(Math.log(Math.abs(value)) / Math.log(base)), 0, units.length - 1);

    return {
        value: value / base ** scaleFactor,
        unit: units[scaleFactor],
    };
}

export function humanizeWatts(watts: number | null | undefined, options: INumberOptions = {}): string {
    if (watts === null || watts === undefined) {
        return '';
    }

    const { precision = 2, ...otherOpts } = options;

    const { value, unit } = getScaledUnits(watts, POWER_UNITS);

    return stringifyNumber(value, { precision, unit, ...otherOpts });
}

export function humanizeEnergy(energy: number | null | undefined, options: INumberOptions = {}): string {
    if (energy == null) {
        return '';
    }
    return `${humanizeWatts(energy, options)}h`;
}

export function humanizeBytes(fileSizeInBytes: number, options: INumberOptions = {}) {
    const { precision = 1, ...otherOpts } = options;

    // we show bytes in a minimum of kB
    const { value, unit } = getScaledUnits(fileSizeInBytes / 1024, BYTE_UNITS);

    const renderValue = Math.sign(value) * Math.max(Math.abs(value), 0.1);
    return stringifyNumber(renderValue, { precision, unit, ...otherOpts });
}

export function stringifyNumberSimple(num: number | null | undefined, precision?: number): string {
    if (num === null || num === undefined) {
        return '';
    }

    return precision !== undefined ? round(num, precision).toString() : num.toString();
}

// Get an appropriate number of significant figures for currency values that could be small
function _calculatePrecision(x) {
    if (x == null || x === 0 || isNaN(x)) {
        return 2;
    }
    return Math.max(2, -Math.floor(Math.log(Math.abs(x)) / Math.LN10 + 1e-8));
}

interface IIntlCurrencyOptions extends INumberOptions {
    displayOnly?: boolean;
    currency?: ICurrency; // optional override for the current user's team currency setting
    isCents?: boolean;

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
    // symbol - "CA$1". narrowSymbol is not supported in Safari, so don't allow that.
    currencyDisplay?: 'code' | 'name' | 'symbol';
}

/*
 * Format value into currency string
 *
 * val - should be a number value, but not sure that all financial token functions return valid numbers
 * options - display options, passing `displayOnly = true` will return the local default number of decimals,
 * otherwise precision will be used
 */
export function currency(val: any, options: IIntlCurrencyOptions = { precision: _calculatePrecision(val) }) {
    if (isNaN(val)) {
        return val;
    }

    const { currency: teamCurrency } = user.team;
    const { precision, displayOnly, currency = teamCurrency, isCents, ...opts } = options;

    const formatOptions: IStringifyNumberOptions = {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: currency.code,
        // Ignore explicit precision if displayOnly is true
        precision: displayOnly ? undefined : precision,

        ...opts,
    };

    try {
        return stringifyNumber(isCents ? val / 100 : val, formatOptions);
    } catch (e) {
        logger.warn(`Invalid currency data - currency: ${currency.code}, locale: ${options.locale})`);
        return '(error)';
    }
}

export function currencySymbol() {
    return user.team.currency.symbol_native;
}

/*
 * Format number value between 0 and 1 into a percentage string, e.g. val = 0.02 -> "2%"
 */
export function percentage(val: number, options: INumberOptions = {}) {
    const { precision = 1, ...otherOpts } = options;
    return stringifyNumber(val, { precision, style: 'percent', ...otherOpts });
}

const OHM = '\u03a9';
const SQUARED = '\u00b2';

export const voltage = (val: number, options?: INumberOptions) => stringifyNumber(val, { unit: 'V', ...options });
export const current = (val: number, options?: INumberOptions) => stringifyNumber(val, { unit: 'A', ...options });
export const power = (val: number, options?: INumberOptions) => stringifyNumber(val, { unit: 'W', ...options });
export const resistance = (val: number, options?: INumberOptions) => stringifyNumber(val, { unit: OHM, ...options });
export const irradiance = (val: number, options?: INumberOptions) =>
    stringifyNumber(val, { unit: `W/m${SQUARED}`, ...options });

export const angle = (val: number, options?: INumberOptions) =>
    // Use 'narrow' display so en-US shows degree symbol instead of i.e. '5 deg'.
    stringifyNumber(val, { unit: 'degree', ...options, unitDisplay: 'narrow' });
export const temperature = (val: number, options?: INumberOptions) =>
    // Use 'narrow' display to have consistent formatting with angle and so that all locales consistently leave
    // no space between the number and degrees celsius.
    stringifyNumber(val, {
        unit: 'celsius',
        ...options,
        unitDisplay: 'narrow',
    });

/**
 * remove all '$', ',', '%', and whitespace from a string
 */
export function numberStringToFloat(val: string) {
    return parseFloat(val.replace(/\$|,|%/g, ''));
}

export function numberStringToInt(val: string) {
    return parseInt(val.replace(/\$|,|%/g, ''), 10);
}

export function fromNow(val: Date | moment.Moment) {
    return moment(val).fromNow();
}

export function humanizeTimestamp(val: Date | moment.Moment) {
    return moment(val).calendar();
}

/**
 * Formats a timestamp's hour and minute.
 * @param val a UTC Unix timestamp
 * @returns a 24-hour string like "3:00" or "14:00"
 */
export function hourOfDay(val: number) {
    return moment.utc(val).format('H:mm');
}

export function humanizeTime(val: Date | moment.Moment) {
    return moment.utc(val).format('h:mm A');
}

/**
 * Formats a timestamp's month.
 * @param val a month from 0 to 11
 * @returns "Jan", "Feb", etc.
 */
export function month(val: number) {
    return moment.utc([1970, val, 1, 0, 0, 0, 0]).format('MMM');
}

/**
 * Inserts hyphens into a string at intervals of n.
 * Ex. chunk('Hello World!', 2) => "He-ll-o -Wo-rl-d!"
 */
export function chunk(val: string, chunkSize: number = 10) {
    if (val == null) {
        return '';
    }

    const ret: string[] = [];
    const len = val.length;
    for (let i = 0; i < len; i += chunkSize) {
        ret.push(val.substr(i, chunkSize));
    }
    return ret.join('-');
}

export type DistanceUnit = Exclude<
    (typeof DISTANCE_UNITS)[number],
    'centimeter' | 'inch' | 'millimeter' | 'scandinavian-mile' | 'yard'
>;

export interface IDistanceOptions extends INumberOptions {
    unit?: DistanceUnit;
    longDistance?: boolean;
}

const UNITS_PER_METER: { [k in DistanceUnit]: number } = {
    foot: METER_IN_FEET,
    meter: 1.0,
    mile: METER_IN_MILES,
    kilometer: METER_IN_KILOMETERS,
};

/**
 * Provided a distance in meters, converts to the desired output units and formats according to locale.
 */
export function distance(meters: number, options: IDistanceOptions) {
    const { precision = 1, longDistance = false, unit = 'meter', ...opts } = options;

    const longUnitMap: { [k in DistanceUnit]: DistanceUnit } = {
        foot: 'mile',
        meter: 'kilometer',
        mile: 'mile',
        kilometer: 'kilometer',
    };
    const newUnit = longDistance ? longUnitMap[unit] : unit;
    return stringifyNumber(meters * UNITS_PER_METER[newUnit], {
        precision,
        ...opts,
        unit: newUnit,
    });
}

export type DurationUnit = (typeof DURATION_UNITS)[number];

export interface IDurationOptions extends INumberOptions {
    unit: DurationUnit;
}

export const duration = (val: number, options: IDurationOptions) => stringifyNumber(val, options);

export const MODULE_ORIENTATION_NAMES = {
    horizontal: 'Landscape (Horizontal)',
    vertical: 'Portrait (Vertical)',
};

export const RACKING_TYPE_NAMES = {
    rack: 'Fixed Tilt',
    flush: 'Flush Mount',
    dual: 'East-West',
    carport: 'Carport',
    single_axis: 'Single-axis Trackers (N/S)',
};

export const STRINGING_STRATEGY_NAMES = {
    along: 'Along Racking',
    updown: 'Up and Down Racking',
};

export const TRANSPOSITION_MODEL_NAMES = {
    hay: 'Hay Model',
    perez: 'Perez Model',
};

export const CELL_TEMPERATURE_MODEL_NAMES = {
    sandia: 'Sandia Model',
    diffuse: 'Diffusion Model',
};

const SPECTRAL_ADJUSTMENT_MODEL_NAMES = {
    precipitable_water: 'First Solar Spectral Adjustment by Precipitable Water',
    relative_humidity: 'First Solar Spectral Adjustment by Relative Humidity',
    dew_point_temperature: 'First Solar Spectral Adjustment by Dew Point Temperature',
};

const MODULE_MODEL_NAMES = {
    pvsyst: 'PAN',
    full_diode: 'Full-Diode',
};

export function tiltAndAzimuthPrefix(rackType: string) {
    if (rackType === 'rack') {
        return 'Module:';
    }
    return '';
}

export function rackingType(val: string) {
    return RACKING_TYPE_NAMES[val];
}

export function moduleOrientation(val: string) {
    return MODULE_ORIENTATION_NAMES[val];
}

export function stringingStrategy(val: string) {
    return STRINGING_STRATEGY_NAMES[val];
}

export function transpositionModel(val: string) {
    return TRANSPOSITION_MODEL_NAMES[val];
}

export function cellTemperatureModel(val: string) {
    return CELL_TEMPERATURE_MODEL_NAMES[val];
}

export function spectralAdjustmentModel(val: string) {
    return SPECTRAL_ADJUSTMENT_MODEL_NAMES[val];
}

export function moduleModel(val: string) {
    return MODULE_MODEL_NAMES[val];
}
