import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AppStore } from 'reports/store';

import { Page } from 'frontend/layout/page-layout';

import { librariesRoutes } from 'frontend/routing/libraries-routes';
import { projectsRoutes } from 'frontend/routing/projects-routes';
import { settingsRoutes } from 'frontend/routing/settings-routes';
import { ErrorBoundary } from 'frontend/errors/error-boundary';
import { ErrorPage } from 'frontend/errors/error-page';

const Router = ({ basename = '/app', store }: { basename: string; store: AppStore }) => {
    const errorRoutes = {
        children: [
            {
                path: '403',
                element: <ErrorPage status={403} />,
            },
            {
                path: 'error',
                element: <ErrorPage status={null} />,
            },
        ],
    };

    // This welcome route is likely temporary. It is in place so that users don't see
    // a 404 when they navigate from classic -> foxfire.
    const welcomeRoute = {
        path: '/welcome',
        element: <div>Welcome</div>,
    };

    const router = createBrowserRouter(
        [
            {
                path: '/',
                element: <Page />,
                errorElement: <ErrorBoundary />,
                children: [
                    errorRoutes,
                    librariesRoutes(store),
                    projectsRoutes(store),
                    settingsRoutes(store),
                    welcomeRoute,
                ],
            },
        ],
        {
            basename,
        },
    );
    return <RouterProvider router={router} />;
};

export { Router };
