import { Coords } from 'google-map-react';
import * as React from 'react';
import styled from 'styled-components';

const SUN_HOVER_ICON = require('reports/static/sun_hover.svg');
const SUN_SELECTED_ICON = require('reports/static/sun_selected.svg');
const SUN_UNSELECTED_ICON = require('reports/static/sun_unselected.svg');

const _WeatherSourceMarker = styled.div<{ isSourceSelected: boolean }>`
    position: absolute;
    transform: translate(-50%, -50%);
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-image: ${(props) =>
        props.isSourceSelected ? `url(${SUN_SELECTED_ICON})` : `url(${SUN_UNSELECTED_ICON})`};
    z-index: ${(props) => (props.isSourceSelected ? 2 : 1)};
    &:hover {
        background-image: url(${SUN_HOVER_ICON});
    }
`;

const WeatherSourceMarker = React.memo(
    (
        props: Coords & {
            srcId: number;
            isSourceSelected: boolean;
            onMouseOut: () => void;
            onClick: () => void;
            onMouseOver: () => void;
            closeInfoWindow: (srcId) => void;
        },
    ) => {
        const { isSourceSelected, srcId, onClick, onMouseOut, onMouseOver } = props;
        // Close info window when expanded marker unmounts
        React.useEffect(() => {
            return () => {
                props.closeInfoWindow(srcId);
            };
        }, []);
        return (
            <_WeatherSourceMarker
                isSourceSelected={isSourceSelected}
                onClick={onClick}
                onMouseOut={onMouseOut}
                onMouseOver={onMouseOver}
            />
        );
    },
);

export { WeatherSourceMarker };
