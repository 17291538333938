import { clamp, isEqual, mapValues, maxBy, mergeWith, pickBy, round } from 'lodash';

import { BaseClass, defaults, patchMerge } from 'reports/utils/api';
import { isEqualWithoutFuncs, uniqueId } from 'reports/utils/helpers';
import { DeepPartial } from 'reports/utils/types';

import { IPageConfig, IWidget, IWidgetConfig } from 'reports/modules/report/widgets';
import { WidgetStyle } from 'reports/modules/report/widget_style';
import { ILayout, ILayoutItemMeta, SnapIndex } from 'reports/modules/report/components/layout';

export const PAPER_TYPES = {
    a3: { name: 'A3', widthIn: 11.69, heightIn: 16.54 },
    a4: { name: 'A4', widthIn: 8.27, heightIn: 11.69 },
    a5: { name: 'A5', widthIn: 5.83, heightIn: 8.27 },
    letter: { name: 'US Letter', widthIn: 8.5, heightIn: 11 },
    legal: { name: 'US Legal', widthIn: 8.5, heightIn: 14 },
    tabloid: { name: 'US Tabloid/Ledger', widthIn: 11, heightIn: 17 },
};

export type PaperType = keyof typeof PAPER_TYPES;

export const pixelsFromInches = (inches) => round(100 * inches);

export function pageDimensions(paperType: PaperType, isLandscape: boolean) {
    const { widthIn, heightIn } = PAPER_TYPES[paperType];
    if (isLandscape) {
        return { widthIn: heightIn, heightIn: widthIn };
    }
    return { widthIn, heightIn };
}

export function pixelSize(paperType: PaperType, isLandscape: boolean) {
    const { heightIn, widthIn } = pageDimensions(paperType, isLandscape);

    return {
        width: pixelsFromInches(widthIn),
        height: pixelsFromInches(heightIn),
    };
}

function emptyPage(): IPageConfig {
    return { widgets: {} };
}

export class WidgetNotFoundError extends Error {}

export class Document extends BaseClass {
    pages: IPageConfig[];

    isLandscape: boolean;
    paperType: PaperType;
    widgetScale: number;
    widgetStyle: WidgetStyle;

    constructor(data) {
        super(Document.deserialize(data));
    }

    static deserialize = defaults({
        isLandscape: false,
        paperType: 'letter',
        widgetScale: 1.0,
        widgetStyle: WidgetStyle.minimal,
        pages: [emptyPage()],
    });

    isEmpty() {
        return this.pages.length === 1 && isEqual(this.pages[0], emptyPage());
    }

    patch(patch: DeepPartial<Document>) {
        return new Document(patchMerge(this, [patch]));
    }

    patchPage(pageIdx: number, pagePatch: DeepPartial<IPageConfig>) {
        const newPages = this.pages.slice();
        newPages[pageIdx] = patchMerge(this.pages[pageIdx], [pagePatch]);
        return this.patch({ pages: newPages });
    }

    patchPageWidgets(pageIdx: number, widgetsPatch: DeepPartial<IPageConfig['widgets']>) {
        return this.patchPage(pageIdx, { widgets: widgetsPatch });
    }

    patchWidget(widgetId, patch: DeepPartial<IWidgetConfig>) {
        const { pageIdx } = this.findWidget(widgetId);
        return this.patchPageWidgets(pageIdx, { [widgetId]: patch });
    }

    findWidget(widgetId: string) {
        for (let i = 0; i < this.pages.length; i += 1) {
            const config = this.pages[i].widgets[widgetId];
            if (config != null) {
                return { config, pageIdx: i };
            }
        }

        throw new WidgetNotFoundError(`Widget ${widgetId} not found`);
    }

    toggleWidgetStyle = () => {
        const isClassic = this.widgetStyle === WidgetStyle.classic;
        return this.patch({
            widgetStyle: isClassic ? WidgetStyle.minimal : WidgetStyle.classic,
        });
    };

    setZIndex(widgetId: string, layer: 'front' | 'back') {
        const { pageIdx } = this.findWidget(widgetId);

        if (layer === 'front') {
            const maxZ = this.maxZIndex(pageIdx);
            return this.patchWidget(widgetId, { layout: { z: maxZ + 1 } });
        }
        if (layer === 'back') {
            const { widgets } = this.pages[pageIdx];
            const updatedWidgets = mapValues(widgets, (config, wId) => {
                const z = wId !== widgetId && config.layout.z != null ? config.layout.z + 1 : 1;
                return {
                    ...config,
                    layout: { ...config.layout, z },
                };
            });

            return this.patchPageWidgets(pageIdx, updatedWidgets);
        }
    }

    maxZIndex(page: number): number {
        if (page >= this.pages.length) {
            throw new Error(`Referenced page that doesn't exist on this document ${page}/${this.pages.length}`);
        }

        const { widgets } = this.pages[page];
        const highestWidget = maxBy(Object.values(widgets), (widget) => widget.layout.z);

        return highestWidget != null && highestWidget.layout.z != null ? highestWidget.layout.z : 0;
    }

    movePage(widgetId: string, direction: 'up' | 'down') {
        const { pageIdx } = this.findWidget(widgetId);

        const newIdx = pageIdx + (direction === 'up' ? -1 : 1); // lower indices are earlier in the doc
        if (newIdx < 0 || newIdx >= this.pages.length) {
            return this;
        }

        const updatedPages = [...this.pages];
        [updatedPages[newIdx], updatedPages[pageIdx]] = [this.pages[pageIdx], this.pages[newIdx]];

        return this.patch({ pages: updatedPages });
    }

    addWidget(
        widgetType: string,
        widget: IWidget,
        initialConfig: DeepPartial<IWidgetConfig> = {},
        pageIdx = this.pages.length - 1,
    ) {
        if (pageIdx > this.pages.length) {
            throw Error(`Can't add widget on page ${pageIdx}.`);
        }
        return this._ensurePage(pageIdx)._addWidget(widgetType, widget, initialConfig, pageIdx);
    }

    private _ensurePage(pageIdx: number): Document {
        if (pageIdx === this.pages.length) {
            return this.patch({ pages: this.pages.concat(emptyPage()) });
        }
        return this;
    }

    private _addWidget(widgetType: string, widget: IWidget, initialConfig: DeepPartial<IWidgetConfig>, pageIdx) {
        const { metadata } = widget;
        const { content, dimensions } = metadata;

        const layout = Object.assign({}, dimensions, initialConfig.layout);
        const { width, height } = this.scaledPageSize();

        const defaultLocation = {
            x: layout.x != null ? layout.x : (width - layout.w) / 2,
            y: layout.y != null ? layout.y : (height - layout.h) / 2,
            z: layout.z != null ? layout.z : this.maxZIndex(pageIdx),
        };

        const config: IWidgetConfig = {
            name: widgetType,
            layout: Object.assign({}, dimensions, defaultLocation, initialConfig.layout),
            content: Object.assign({}, content, initialConfig.content),
        };

        const widgetId = uniqueId('document', 4);

        return {
            widgetId,
            document: this.patchPageWidgets(pageIdx, { [widgetId]: config }),
        };
    }

    setWidgetPosition = (itemMeta: ILayoutItemMeta, layout: ILayout, pageIdx: number) => {
        if (pageIdx > this.pages.length) {
            throw new Error(`Can't move widget to that page. ${pageIdx} > ${this.pages.length}.`);
        }

        const { widgetId, config, layoutDelta } = itemMeta;

        if (layoutDelta) {
            mergeWith(layout, layoutDelta, (layoutVal, delta) => (layoutVal != null ? layoutVal + delta : delta));
        }

        const newConfig = { ...config, layout };

        return this.deleteWidget(widgetId)
            ._ensurePage(pageIdx)
            .patchPageWidgets(pageIdx, { [widgetId]: newConfig });
    };

    deletePage(pageIdx: number) {
        return this.patch({
            pages: this.pages.filter((_pg, idx) => idx !== pageIdx),
        });
    }

    deleteWidget(widgetId: string) {
        const { pageIdx } = this.findWidget(widgetId);
        const page = this.pages[pageIdx];
        // Cannot use patchPageWidgets here, since 'page.widgets' is an object and a patch attempting to
        // remove a widget by key just ends up modifying all other keys, without removing anything.
        const newPage = {
            ...page,
            widgets: pickBy(page.widgets, (_config, wId) => wId !== widgetId),
        };
        return this.patch({
            pages: this.pages.map((pg, idx) => (idx === pageIdx ? newPage : pg)),
        });
    }

    pageDimensions = () => pageDimensions(this.paperType, this.isLandscape);
    pageSize = () => pixelSize(this.paperType, this.isLandscape);

    scaledPageSize() {
        // return the page size scaled up to account for widget scaling
        // When decreasing widgetScale, rather than decreasing the widget size, increase the page size
        // effectively reducing the widgets relative size on the page
        // e.g. pageWidth = 850, widgetScale = 0.5 => effectivePageWidth = 1700, so page can fit twice as many widgets

        const { width, height } = this.pageSize();
        return {
            width: width / this.widgetScale,
            height: height / this.widgetScale,
        };
    }

    // Reverse the effect of widgetScale on page size, so page takes up same amount of space on screen, regardless
    // of whether widgetScale is 0.5 or 1.0.
    getRenderScale = (zoomScale) => clamp(zoomScale, 0.25, 2) * this.widgetScale;

    getSnapIndex(pageIdx: number, radius: number) {
        const { width, height } = this.scaledPageSize();

        return new SnapIndex(
            [
                {
                    x: 0,
                    y: 0,
                    w: width,
                    h: height, // snap at edges of document
                    z: 0,
                },
                ...(pageIdx === this.pages.length
                    ? []
                    : Object.values(this.pages[pageIdx].widgets).map((x) => x.layout)),
            ],
            radius,
        );
    }

    get configurable() {
        return this.pages.some((page) => Object.values(page.widgets).some((widget) => widget.configurable));
    }

    // Used in Report.tsx:updatedHistory
    hasChangesFrom = (document: Document) => !isEqualWithoutFuncs(this, document);
}

export default Document;
