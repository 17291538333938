/** Collapse component that self manages it's open state.
 *
 * Example usage,
 *     <Collapsible
 *        renderHeader={(toggleCollapse, isOpen) =>
 *            <Button onClick={toggleCollapse} icon={isOpen ? "caret-up" : "caret-down"}/>
 *        }
 *        defaultOpen={true}
 *    >
 *        <div>Collapsed content</div>
 *    </Collapsible>
 */
import * as React from 'react';

import { Collapse } from '@blueprintjs/core';

interface IOwnProps {
    renderHeader: (toggleCollapse: () => void, isOpen: boolean) => JSX.Element;
    defaultOpen?: boolean;
    style?: React.CSSProperties;
}

interface IState {
    isOpen: boolean;
}

class Collapsible extends React.PureComponent<IOwnProps, IState> {
    state: IState = {
        isOpen: this.props.defaultOpen || false,
    };

    render() {
        return (
            <>
                {this.props.renderHeader(this.toggleCollapse, this.state.isOpen)}
                <Collapse isOpen={this.state.isOpen}>
                    <div style={this.props.style}>{this.props.children}</div>
                </Collapse>
            </>
        );
    }

    toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen });
}

export default Collapsible;
