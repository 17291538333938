import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRoute } from 'react-router5';
import { Params } from 'router5';

import { Icon, Tab, Tabs, Divider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { UnstyledLink } from 'reports/components/core/controls';
import { permissions, selectors as authSelectors } from 'reports/modules/auth';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const TabHeader = styled.div`
    padding: 2px 4px;
`;

const TabHeaderText = styled.span`
    margin-left: 6px;
    font-size: 15px;
    font-weight: 600;
`;

const TabText = styled.div`
    div {
        text-transform: lowercase;
    }
    div::first-letter {
        text-transform: uppercase;
    }
`;

const mapRouteToTabId = function (routeName: string) {
    if (routeName.startsWith('app.settings.team.manage')) {
        return 'app.settings.team.manage';
    }
    return routeName;
};

// Tabs has this weird filter where it ignores any child components that aren't type == Tab.
const renderTab = (props: { tabName: string; routeName: string; disabled?: boolean; routeParams?: Params }) => {
    const { tabName, routeName, disabled, routeParams } = props;
    return (
        <Tab
            id={routeName}
            disabled={disabled}
            title={
                <UnstyledLink
                    routeName={routeName}
                    className={disabled ? 'disabled' : undefined}
                    routeParams={routeParams}
                >
                    <TabText>
                        <div>{tabName}</div>
                    </TabText>
                </UnstyledLink>
            }
        />
    );
};

const SettingsSideBar = React.memo(() => {
    const authUser = useSelector((state) => authSelectors.getUser(state)!);
    const { route } = useRoute();

    const settingsEnabled = permissions.canViewSettings(authUser);
    const canManageTeam = authUser.role.can_manage_team_resources;

    const canManageBilling = permissions.canManageBilling(authUser);

    return (
        <>
            <Tabs id="app.settings" animate={false} vertical={true} selectedTabId={mapRouteToTabId(route.name)}>
                <TabHeader>
                    <Icon icon={IconNames.USER} iconSize={18} />
                    <TabHeaderText>Account</TabHeaderText>
                </TabHeader>
                {/* TODO: test that clicking account settings from bottom left context menu redirects you to Manage Team*/}
                {renderTab({
                    routeName: 'app.settings.user',
                    tabName: 'Overview',
                    disabled: !settingsEnabled,
                    routeParams: route.params,
                })}
                {renderTab({
                    routeName: 'app.settings.user.preferences',
                    tabName: 'Preferences',
                    disabled: !settingsEnabled,
                    routeParams: route.params,
                })}
                <Divider />

                <TabHeader>
                    <Icon icon={IconNames.PEOPLE} iconSize={18} />
                    <TabHeaderText>Team</TabHeaderText>
                </TabHeader>
                {renderTab({
                    routeName: 'app.settings.team.overview',
                    tabName: 'Overview',
                    disabled: !settingsEnabled,
                    routeParams: route.params,
                })}
                {(canManageBilling || canManageTeam) &&
                    renderTab({
                        routeName: 'app.settings.team.manage',
                        tabName: 'Manage Team',
                        routeParams: route.params,
                    })}
                {renderTab({
                    routeName: 'app.settings.team.integrations',
                    tabName: 'Integrations',
                    disabled: !settingsEnabled,
                    routeParams: route.params,
                })}
                {renderTab({
                    routeName: 'app.settings.team.themes',
                    tabName: 'Themes',
                    disabled: !settingsEnabled,
                    routeParams: route.params,
                })}
                {renderTab({
                    routeName: 'app.settings.team.billing',
                    tabName: 'Billing & Usage',
                    // Never disable billing tab. Expired users need to access billing to re-purchase HelioScope.
                    routeParams: route.params,
                })}
                <Divider />
            </Tabs>
            {permissions.helioscopeAdmin(authUser) && (
                <Tabs id="app.settings.hs-admin" animate={false} vertical={true} selectedTabId={route.name}>
                    <TabHeader>
                        <Icon icon={IconNames.CROWN} iconSize={18} />
                        <TabHeaderText>HS Admin</TabHeaderText>
                    </TabHeader>
                    {renderTab({
                        routeName: 'app.settings.hs-admin.subscription',
                        tabName: 'Subscription',
                        // Reset subscription view state to it's original form.
                        // Otherwise, createContract may be retained and users will have to refresh page manually.
                        routeParams: { ...route.params, createContract: undefined },
                    })}
                    {renderTab({
                        routeName: 'app.settings.hs-admin.merge-teams',
                        tabName: 'Merge Teams',
                        routeParams: route.params,
                    })}
                    {renderTab({
                        routeName: 'app.settings.hs-admin.team-features',
                        tabName: 'Team Features',
                        routeParams: route.params,
                    })}
                    {renderTab({
                        routeName: 'app.settings.hs-admin.user-features',
                        tabName: 'User Features',
                        routeParams: route.params,
                    })}
                </Tabs>
            )}
        </>
    );
});

export default SettingsSideBar;
