import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Classes, Dialog } from '@blueprintjs/core';

import * as bml from 'reports/models/billing_v2_migration_log';
import * as sub from 'reports/models/subscription';
import * as tm from 'reports/models/team';

import { StripePortalButton } from 'reports/modules/settings/billing/components/StripePortalButton';

interface Props {
    subscription: sub.Subscription;
    team: tm.Team;
}

export const UpdateBillingInfoDialog = ({ subscription, team }: Props) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState<boolean>(true);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false);
                dispatch(bml.api.updateRemindedToUpdateBillingAt({ team_id: team.team_id }));
            }}
            title="Is your billing information correct?"
        >
            <div className={Classes.DIALOG_BODY}>
                Review and update your billing information to ensure we have the correct info on file to calculate taxes
                applied to invoices.
                <StripePortalButton
                    asLink={true}
                    openNewTab={true}
                    name="billingInfo"
                    subscription={subscription}
                    text="Update my billing information"
                />
            </div>
        </Dialog>
    );
};
