import * as React from 'react';

import { RouteComponent } from 'reports/routing';
import { PrintButton } from 'reports/modules/pdf';

import { ContextBarControls } from 'reports/components/ContextBar';
import ReportLibrary from 'reports/modules/report/components/ReportLibrary';
import ReportEditor from 'reports/modules/report/components/ReportEditor';
import ReportPreview from 'reports/modules/report/components/ReportPreview';
import ProjectFinancialsCalculator from 'reports/modules/project/components/ProjectFinancialsCalculator';

import * as proj from 'reports/models/project';

interface IOwnProps {
    project: proj.Project;
}

const Library: React.SFC<IOwnProps> = ({ project }) => (
    <>
        <ProjectFinancialsCalculator project={project} />
        <ContextBarControls>
            <PrintButton />
        </ContextBarControls>
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.reports" exact={false} nameNot="app.reports.report.edit">
                    <ReportLibrary
                        project={project}
                        preview={
                            <RouteComponent name="app.reports.report.preview">
                                {({ report, data: { printableReady } }) => (
                                    <ReportPreview report={report} project={project} printableReady={printableReady} />
                                )}
                            </RouteComponent>
                        }
                    />
                </RouteComponent>
                <RouteComponent name="app.reports.report.edit">
                    {({ report, data: { printableReady } }) => (
                        <ReportEditor report={report} project={project} printableReady={printableReady} />
                    )}
                </RouteComponent>
            </div>
        </div>
    </>
);

export default Library;
