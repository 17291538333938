import * as React from 'react';
import { ChromePicker } from 'react-color';
import { Colors } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface IProps {
    onColorChange: (color: string) => void;
    color?: string;
}

const DIAGONAL_LINE_BG =
    'linear-gradient(to top left,\
    white 0%,\
    white calc(50% - 2px),\
    red 50%,\
    white calc(50% + 2px),\
    white 100%)';

const ColorRect = styled.div<{ color?: string }>`
    width: 100%;
    height: 60px;
    background: ${({ color }) => color || DIAGONAL_LINE_BG};
    border: 1px solid ${Colors.LIGHT_GRAY1};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RectText = styled.span`
    background: white;
    font-weight: bold;
`;

/**
 * An RGB color picker with a single rectangular swatch, showing the selected color.
 * Shows a default state if no color selected.
 */
export const SingleColorPicker: React.FC<IProps> = ({ onColorChange, color }) => {
    const setColor = (color) => {
        onColorChange(color.hex);
    };

    return (
        <Popover2
            content={<ChromePicker color={color} disableAlpha={true} onChange={setColor} onChangeComplete={setColor} />}
        >
            <ColorRect color={color}>{!color && <RectText>Default</RectText>}</ColorRect>
        </Popover2>
    );
};

export default SingleColorPicker;
