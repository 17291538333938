import * as React from 'react';

import moment from 'moment';

import Flex from 'reports/components/core/containers/Flex';

import { FormNumericInput } from 'reports/components/forms/inputs/experimental';

import Colors from 'reports/styles/colors';
import { Callout, H5, H6, Icon, Intent } from '@blueprintjs/core';

import { LeftPanel, Panels, RightPanel } from './Panels';

import { Price } from 'reports/models/stripe/price';
import { Subscription } from 'reports/models/subscription';
import { User } from 'reports/models/user';

import { styled } from 'reports/styles/styled-components';

import { PurchaseHeader } from './PurchaseHeader';
import { PurchaseSummary } from './PurchaseSummary';

import { useBillingContext } from 'reports/modules/settings/billing/Context';

interface Props {
    price?: Price;
    interval: string;
    quantity: number;
    subscription?: Subscription;
    user: User;
}

const DEFAULT_PROJECT_LIMIT = 10;

const Callouts = styled(Flex.Container)`
    flex-direction: column;
    padding: 12px 12px;
    gap: 4px;
`;

const LicenseChangeQuantity = styled(H6)`
    margin-bottom: 4px;
`;

const Arrow = styled(Icon)`
    padding: 28px 8px 0px 8px;
    color: ${Colors.HELIO_BLUE_GREY_3};
`;

const LicenseChangeTitle = styled(H5)`
    font-size: 16px;
    font-weight: 400;
    color: ${Colors.HELIO_BLUE_GREY_3};
`;

const LicenseChangeItem = styled(Flex.Container)<{ small: boolean }>`
    flex-direction: column;
    width: ${({ small }) => (small ? '134px' : '')};
`;

const LicenseChange = styled(Flex.Container)`
    flex-direction: row;
    gap: 4px;
`;

const LicenseSummary = styled.div`
    padding-top: 8px;
`;

const Licenses = ({ interval, price, quantity, subscription, user }: Props) => {
    const currentPeriodEnd = subscription
        ? subscription.current_period_end.format('LL')
        : moment().add(1, price?.recurring.interval).format('LL');

    const billingContext = useBillingContext();

    const seatsToBeRemoved = subscription ? subscription.paid_seats - quantity : 0;

    const callouts = subscription
        ? [
              ...(quantity < subscription.subscribers.length
                  ? [
                        {
                            intent: Intent.DANGER,
                            message: (
                                <span>
                                    You will need to remove a user from the manage team page before you can remove a
                                    license.
                                </span>
                            ),
                        },
                    ]
                  : []),

              ...(quantity < subscription.paid_seats
                  ? [
                        {
                            intent: Intent.WARNING,
                            message: (
                                <span>
                                    Removing {seatsToBeRemoved} {seatsToBeRemoved === 1 ? 'license' : 'licenses'} will
                                    remove {seatsToBeRemoved} {seatsToBeRemoved === 1 ? 'seat' : 'seats'} and{' '}
                                    {seatsToBeRemoved * 10} projects from your subscription starting {currentPeriodEnd}.
                                </span>
                            ),
                        },
                    ]
                  : []),
              ...(billingContext.message
                  ? [
                        {
                            intent: Intent.WARNING,
                            message: <span>{billingContext.message}</span>,
                        },
                    ]
                  : []),
          ]
        : [];

    const projectsIncludedText = (interval: string, quantity: number): string => {
        const count = interval === 'year' ? DEFAULT_PROJECT_LIMIT * quantity * 12 : DEFAULT_PROJECT_LIMIT * quantity;
        return `Includes ${quantity} ${quantity === 1 ? 'seat' : 'seats'} and ${count} projects / ${interval}`;
    };

    return (
        <>
            {!!callouts.length && (
                <Callouts>
                    {callouts.map((callout, index) => (
                        <Callout key={index} intent={callout.intent}>
                            {callout.message}
                        </Callout>
                    ))}
                </Callouts>
            )}
            <Panels>
                <LeftPanel>
                    <PurchaseHeader>Number of Licenses</PurchaseHeader>
                    <FormNumericInput path="quantity" width={95} min={1} max={50} inline integerOnly updateOnKeyDown />

                    {subscription && (
                        <LicenseChange>
                            <LicenseChangeItem small>
                                <LicenseChangeTitle>Current</LicenseChangeTitle>
                                <LicenseChangeQuantity>
                                    {subscription.paid_seats} {subscription.paid_seats === 1 ? 'License' : 'Licenses'}{' '}
                                </LicenseChangeQuantity>
                                <div>{projectsIncludedText(interval, subscription.paid_seats)}</div>
                            </LicenseChangeItem>
                            {subscription.paid_seats !== quantity && (
                                <>
                                    <Arrow icon="chevron-right" />
                                    <LicenseChangeItem small>
                                        <LicenseChangeTitle>Updated</LicenseChangeTitle>
                                        <LicenseChangeQuantity>
                                            {quantity} {quantity === 1 ? 'License' : 'Licenses'}{' '}
                                        </LicenseChangeQuantity>
                                        <div>{projectsIncludedText(interval, quantity)}</div>
                                    </LicenseChangeItem>
                                </>
                            )}
                        </LicenseChange>
                    )}

                    {!subscription && (
                        <LicenseSummary>
                            {projectsIncludedText(interval, quantity)}. You can invite users from the Manage Team page
                            after subscribing.
                        </LicenseSummary>
                    )}
                </LeftPanel>
                <RightPanel>
                    <PurchaseSummary
                        abridged={true}
                        price={price}
                        quantity={quantity}
                        subscription={subscription}
                        user={user}
                    />
                </RightPanel>
            </Panels>
        </>
    );
};

export { Licenses };
