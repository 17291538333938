import { assign } from 'lodash';

import { transitionPath, MiddlewareFactory } from 'router5';
import { Store } from 'redux';

import { IRouteConfig, IParams } from './types';
import { indexRoutes } from './utils';

/**
 * Resolves dependencies and handles component injection
 */
export function dependencyMiddlewareFactory<AppState>(routes: IRouteConfig<IParams, AppState>[]): MiddlewareFactory {
    const indexedRoutes = indexRoutes(routes);

    return (_router, dependencies: { store: Store<AppState> }) => {
        const onEnterData = {};

        return async (toState, fromState, _done) => {
            const { store } = dependencies;

            if (store === undefined) {
                throw Error('Must have a store defined as a router dependency to use dependency middleware');
            }

            const { toActivate, toDeactivate } = transitionPath(toState, fromState);

            const toParams = toState.params;
            const fromParams = fromState != null ? fromState.params : {}; // don't have fromParams on startup

            for (const routeName of toDeactivate) {
                delete onEnterData[routeName];

                const routeConfig = indexedRoutes[routeName];
                if (routeConfig.onExit !== undefined) {
                    await routeConfig.onExit(store.dispatch, () => store.getState(), { toParams, fromParams });
                }
            }

            for (const routeName of toActivate) {
                const routeConfig = indexedRoutes[routeName];
                if (routeConfig.onEnter !== undefined) {
                    onEnterData[routeName] = await routeConfig.onEnter(store.dispatch, () => store.getState(), {
                        toParams,
                        fromParams,
                    });
                }
            }

            // the type for middleware only allows a boolean or Promise<boolean>
            // but you can add extra data according to http://router5.github.io/docs/async-data.html
            return {
                ...toState,
                data: assign({}, ...Object.values(onEnterData)),
            } as any;
        };
    };
}
