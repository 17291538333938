import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'reports/store';
import { bindActions } from 'reports/utils/redux';

import * as pro from 'reports/models/profile';

import { BasicSelect } from 'reports/components/forms/inputs/experimental/BasicSelect';
import { FavoriteItemRenderer } from './common';

interface IOwnProps {
    onChange: (profile: pro.Profile) => any;
    value?: number;
    profileType?: pro.ProfileType;
    maxButtonWidth?: number;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

type IProps = IOwnProps & IStateProps & IDispatchProps;

const _ProfileSelect: React.FC<IProps> = ({ onChange, profileType, selectedProfile, getProfiles, maxButtonWidth }) => (
    <BasicSelect<pro.Profile>
        value={selectedProfile || null}
        onChange={onChange}
        noneSelected="None"
        dataSource={{
            async: true,
            query: async (q) => await getProfiles({ q, type: profileType, limit: 20 }),
        }}
        itemRenderer={FavoriteItemRenderer}
        maxButtonWidth={maxButtonWidth}
    />
);

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => ({
    selectedProfile: pro.selectors.byId(state, ownProps.value),
});

const mapDispatchToProps = bindActions(() => ({
    getProfiles: pro.api.index,
}));

const ProfileSelect = connect(mapStateToProps, mapDispatchToProps)(_ProfileSelect);

export default ProfileSelect;
