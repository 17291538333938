/* tslint:disable:variable-name */
import _ from 'lodash';
import moment from 'moment';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconNames } from '@blueprintjs/icons';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';

import * as auth from 'reports/modules/auth';
import * as utility from 'reports/models/utility_rate';

import { UtilityRatesList } from 'reports/modules/utility_rate/components/UtilityRatesList';

import { getRateParam } from 'reports/modules/financials/utils';
import { IAppState } from 'reports/store';
import { UpsellBanner } from 'reports/components/UpsellBanner';

type Props = {
    inlineControls?: boolean;
    navigateToUtilityRate: (rateId: number) => void;
    hideUpsellBanner?: boolean;
};

const UtilityRateLibrary = ({ inlineControls, navigateToUtilityRate, hideUpsellBanner }: Props): React.JSX.Element => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const dispatch = useDispatch();

    const loadItems = (args: utility.UtilityRateQuery) => {
        return dispatch(utility.api.index(args));
    };
    const refreshItem = useSelector(
        (state) => (item) =>
            utility.selectors.byObject(state as IAppState, item, { creator: true, team: true }) || item,
    );

    const addUtilityRate = () => {
        const randid = Math.floor(performance.now() * 99999 + Math.random() * 99999);
        const energyParam = getRateParam('energy_rates');
        const demandParam = getRateParam('demand_rates');
        const createUtilityRate = (data) => dispatch(utility.api.create(data));

        createUtilityRate({
            apply_date: moment({ year: 2020, month: 0, date: 1 }),
            description: `new rate ${randid.toString(16)}`,
            data: {
                energy_rates: _.merge({}, energyParam!.default),
                demand_rates: _.merge({}, demandParam!.default),
            },
        }).then((res) => navigateToUtilityRate(res.utility_rate_id));
    };

    const setBookmarked = (rate: utility.UtilityRate, bookmarked: boolean) =>
        dispatch(
            utility.api.patchTeamSettings({
                bookmarked,
                utility_rate_id: rate.utility_rate_id,
            }),
        );

    if (!user) {
        return <></>;
    }

    return (
        <>
            {!hideUpsellBanner && <UpsellBanner />}
            <LibraryMain
                resourceIdName="utility_rate_id"
                loadItems={loadItems}
                refreshItem={refreshItem}
                inlineControls={inlineControls}
                views={[
                    {
                        view: (
                            <UtilityRatesList
                                openEditor={navigateToUtilityRate}
                                setBookmarked={setBookmarked}
                                user={user}
                            />
                        ),
                        icon: IconNames.LIST,
                        id: 'rate-list',
                    },
                ]}
                id="rates-views"
                quickFilters={[
                    {
                        name: 'bookmarked',
                        icon: IconNames.STAR,
                        text: 'Favorited',
                    },
                    {
                        name: 'team_id',
                        icon: IconNames.PEOPLE,
                        text: `Only Show My Team`,
                        value: user.team.team_id,
                    },
                ]}
                contextBarControls={<CreateButton text="New Utility Rate" onClick={() => addUtilityRate()} />}
            />
        </>
    );
};

export { UtilityRateLibrary };
