import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import { STRINGING_STRATEGY_NAMES } from 'reports/utils/formatters';

import * as pd from 'reports/models/power_device';
import * as wr from 'reports/models/wire';
import * as acc from 'reports/models/ac_config';

import { FormBasicSelect, FormCheckboxInput, fromDict, IItem } from 'reports/components/forms/inputs/experimental';
import { Card } from 'reports/components/core/containers';

import { FormField, NestedFields } from 'reports/components/forms';

import { selectors as userSelectors } from 'reports/modules/auth';
import { LABEL_WIDTH, Section2, SubsectionPanel, ProfileNumericInput } from 'reports/modules/profile/components/common';

type WireSelectorStateProps = ReturnType<typeof mapStateToWireSelectorProps>;
type WireSelectorOwnProps = {
    label: string;
    helperText?: string;
    path: string;
    required?: boolean;
};

const WireSelector: React.FC<WireSelectorStateProps & WireSelectorOwnProps> = ({
    label,
    helperText,
    path,
    required,
    wires,
    user,
}) => {
    const wiresFiltered = wires.filter((w) => {
        if (w.units !== user.preferences.wiring.wire_units) return false;
        if (w.material === 'Aluminum' && !user.preferences.wiring.show_aluminum) {
            return false;
        }
        return true;
    });

    return (
        <FormBasicSelect<wr.Wire>
            inline
            bold
            label={label}
            helperText={helperText}
            noneSelected="None"
            labelWidth={LABEL_WIDTH}
            path={path}
            isNullable={!required}
            dataSource={{
                items: wiresFiltered,
                keyLookup: (w) => w.wire_gauge_id,
            }}
            itemRenderer={(w: wr.Wire) => ({
                key: w.wire_gauge_id,
                text: w.toString(),
            })}
        />
    );
};

const mapStateToWireSelectorProps = (state) => ({
    wires: wr.selectors.all(state),
    user: userSelectors.getUser(state)!,
});

const WireSelectorContainer = connect(mapStateToWireSelectorProps, null)(WireSelector);

type PowerDeviceSelectorDispatchProps = ReturnType<typeof mapDispatchToPowerDeviceSelectorProps>;
type IPowerDeviceSelectorOwnProps = {
    selectedPowerDeviceId: number;
    deviceType: 'inverter' | 'optimizer';
    label: string;
    noneSelectedText: string;
    helperText?: string;
    path: string;
    required?: boolean;
};

type IPowerDeviceSelectorProps = IPowerDeviceSelectorOwnProps & PowerDeviceSelectorDispatchProps;

const PowerDeviceSelector: React.FC<IPowerDeviceSelectorProps> = ({
    deviceType,
    label,
    noneSelectedText,
    getPowerDevices,
    helperText,
    path,
    required,
}) => (
    <FormBasicSelect<pd.PowerDevice>
        inline
        bold
        label={label}
        helperText={helperText}
        noneSelected={noneSelectedText}
        labelWidth={LABEL_WIDTH}
        path={path}
        isNullable={!required}
        dataSource={{
            async: true,
            query: (q) => {
                return getPowerDevices({
                    q,
                    device_type: deviceType,
                    limit: 20,
                });
            },
            keyLookup: (p) => p.power_device_id,
            itemLookup: (pdId, state) => pd.selectors.byId(state, pdId)!,
        }}
        itemRenderer={(p: pd.PowerDevice) => ({
            key: p.power_device_id,
            text: p.toString(),
        })}
    />
);

const mapDispatchToPowerDeviceSelectorProps = bindActions(() => ({
    getPowerDevices: pd.api.index,
}));

const PowerDeviceSelectorContainer = connect(null, mapDispatchToPowerDeviceSelectorProps)(PowerDeviceSelector);

// Use React Context to pass the boolean for whether or not there's a micro-inverter
// selected to various levels of children components.
// Maybe this would be easier or cleaner in the Form onUpdate callback?
interface IOwnMicroInverterCheckProps {
    inverterId: number;
}

type IMicroInverterCheckStateProps = ReturnType<typeof mapMicroInverterCheckStateToProps>;
type IMicroInverterCheckProps = IOwnMicroInverterCheckProps & IMicroInverterCheckStateProps;

const MicroInverterContext = React.createContext(false);
const MicroInverterProvider: React.FC<IMicroInverterCheckProps> = ({ inverter, children }) => (
    <MicroInverterContext.Provider value={!!inverter?.microinverter}>{children}</MicroInverterContext.Provider>
);

const mapMicroInverterCheckStateToProps = (state, ownProps) => ({
    inverter: pd.selectors.byId(state, ownProps.inverterId),
});

const MicroInverterCheck = connect(mapMicroInverterCheckStateToProps, null)(MicroInverterProvider);
const MicroInverterCheckContainer = ({ children }) => (
    <FormField path="inverter_id">
        {({ value: inverterId }) => (
            <MicroInverterCheck inverterId={inverterId}>
                <MicroInverterContext.Consumer>
                    {(isMicroInverter) => <>{children(isMicroInverter)}</>}
                </MicroInverterContext.Consumer>
            </MicroInverterCheck>
        )}
    </FormField>
);

const InverterSelectionContent: React.FC<{ isMicroInverter: boolean }> = ({ isMicroInverter }) => (
    <Card>
        <Section2 title="Inverter Selection">
            <PowerDeviceSelectorContainer
                label="Inverter"
                path="inverter_id"
                deviceType="inverter"
                noneSelectedText="No inverter selected"
            />
            {!isMicroInverter && (
                <ProfileNumericInput
                    label="MAX DC/AC Ratio"
                    helperText="Inverter sizing for string & central inverters"
                    path="max_dc_ac_ratio"
                />
            )}
        </Section2>
    </Card>
);

const DCSubsystemContent: React.FC<{ isMicroInverter: boolean }> = ({ isMicroInverter }) => {
    if (!isMicroInverter) {
        return (
            <Card>
                <Section2 title="DC Subsystem">
                    <WireSelectorContainer
                        label="Combiner Home Runs"
                        path="bus_id"
                        helperText="Conductors used between combiners and the inverter"
                    />
                    <ProfileNumericInput
                        path="combiner_poles"
                        label="Combiner Poles"
                        helperText="Maximum number of strings to connect to each combiner"
                        min={1}
                        max={200}
                        integerOnly
                    />
                    <WireSelectorContainer
                        label="Strings"
                        path="string_id"
                        helperText="Conductors connecting modules to combiners"
                        required
                    />
                    <ProfileNumericInput
                        label="Min String Length"
                        helperText="Min number of modules in series on each string"
                        path="string_size_min"
                        min={1}
                        max={100}
                        integerOnly
                    />
                    <ProfileNumericInput
                        label="Max String Length"
                        helperText="Max number of modules in series on each string"
                        path="string_size_max"
                        min={1}
                        max={100}
                        integerOnly
                    />
                    <FormBasicSelect<IItem<string>>
                        inline
                        bold
                        label="Stringing strategy"
                        helperText="How to string modules within the racking"
                        labelWidth={LABEL_WIDTH}
                        path="stringing_strategy"
                        dataSource={{
                            items: fromDict(STRINGING_STRATEGY_NAMES),
                            keyLookup: ({ key }) => key,
                        }}
                        itemRenderer={({ key, name }) => ({ key, text: name })}
                    />
                    <PowerDeviceSelectorContainer
                        label="Optimizer"
                        path="power_optimizer_id"
                        deviceType="optimizer"
                        noneSelectedText="No optimizer selected"
                    />
                    <WireSelectorContainer
                        label="Recombiner Home Runs"
                        path="trunk_id"
                        helperText="Conductors used between recombiners and the inverter, only used if your design has both combiners and recombiners"
                    />
                </Section2>
            </Card>
        );
    }
    return null;
};

type IUseTransformerStateProps = ReturnType<typeof mapUseTransformersStateToProps>;
type IUseTransformersProps = {
    useTransformers: boolean;
} & IUseTransformerStateProps;
const UseTransformersSection: React.FC<IUseTransformersProps> = ({ useTransformers, acConfigs }) => {
    if (useTransformers) {
        return (
            <SubsectionPanel margin="0 10px 15px" padding="15px 15px 0 15px">
                <FormBasicSelect<acc.AcConfig>
                    inline
                    bold
                    label="Inverter AC Output"
                    noneSelected="None"
                    labelWidth={LABEL_WIDTH}
                    path="inverter_ac_config_id"
                    isNullable={true}
                    dataSource={{
                        items: acConfigs,
                        keyLookup: (a) => a.ac_config_id,
                    }}
                    itemRenderer={(a: acc.AcConfig) => ({
                        key: a.ac_config_id,
                        text: a.toString(),
                    })}
                />
                <FormBasicSelect<acc.AcConfig>
                    inline
                    bold
                    noneSelected="None"
                    label="Panel XFRM Output"
                    labelWidth={LABEL_WIDTH}
                    path="panel_transformer_config_id"
                    isNullable={true}
                    dataSource={{
                        items: acConfigs,
                        keyLookup: (a) => a.ac_config_id,
                    }}
                    itemRenderer={(a: acc.AcConfig) => ({
                        key: a.ac_config_id,
                        text: a.toString(),
                    })}
                />
            </SubsectionPanel>
        );
    }
    return null;
};

const mapUseTransformersStateToProps = (state) => ({
    acConfigs: acc.selectors.all(state),
});

const UseTransformersContainer = connect(mapUseTransformersStateToProps, null)(UseTransformersSection);

const ACSubsystemContent: React.FC<{ isMicroInverter: boolean }> = ({ isMicroInverter }) => (
    <Card>
        <Section2 title="AC Subsystem">
            {isMicroInverter && (
                <>
                    <FormBasicSelect<IItem<string>>
                        inline
                        bold
                        label="Stringing strategy"
                        noneSelected="None"
                        helperText="How to string modules within the racking"
                        labelWidth={LABEL_WIDTH}
                        path="stringing_strategy"
                        dataSource={{
                            items: fromDict(STRINGING_STRATEGY_NAMES),
                            keyLookup: ({ key }) => key,
                        }}
                        itemRenderer={({ key, name }) => ({ key, text: name })}
                    />
                    <ProfileNumericInput
                        label="Branch Length"
                        helperText="Number of modules on each AC Branch"
                        path="ac_branch_length"
                        min={0}
                        max={1000}
                        integerOnly
                    />
                    <FormCheckboxInput
                        path="ac_branch_center_feed"
                        label="Center-feed Branches"
                        helperText="Center-feeding AC branches can reduce AC line losses in certain microinverter systems"
                        labelWidth={LABEL_WIDTH}
                        inline
                        bold
                    />
                </>
            )}
            <WireSelectorContainer
                label="AC Home Runs"
                helperText="Conductors used between the inverters and the AC Panels or PCC, where relevant"
                path="ac_branch_id"
                required
            />
            <WireSelectorContainer
                label="PCC Home Runs"
                helperText="Conductors used between AC Panels and the PCC. Select 'None' for no AC Panels"
                path="ac_run_id"
            />
            <ProfileNumericInput
                label="AC Panel Size"
                helperText="Maximum number of branches to collect in an AC Panel"
                path="ac_panel_size"
                min={1}
                max={1000}
                integerOnly
            />
            <FormCheckboxInput
                path="use_transformers"
                label="Use Transformers"
                helperText="Configure transformers from the AC Panel to the PCC"
                labelWidth={LABEL_WIDTH}
                inline
                bold
            />
            <FormField path="use_transformers">
                {({ value: useTransformers }) => <UseTransformersContainer useTransformers={useTransformers} />}
            </FormField>
        </Section2>
    </Card>
);

const ElectricalProfileEditor = () => (
    <NestedFields path="data">
        <MicroInverterCheckContainer>
            {(isMicroInverter) => (
                <>
                    <InverterSelectionContent isMicroInverter={isMicroInverter} />
                    <DCSubsystemContent isMicroInverter={isMicroInverter} />
                    <ACSubsystemContent isMicroInverter={isMicroInverter} />
                </>
            )}
        </MicroInverterCheckContainer>
    </NestedFields>
);

export default ElectricalProfileEditor;
