import Logger from 'js-logger';

import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { Classes, Dialog } from '@blueprintjs/core';

import { helioscopeConfig } from 'reports/angular-bridge';
import * as errorReports from 'reports/error-reports';
import Colors from 'reports/styles/colors';
import { bindActions } from 'reports/utils/redux';

import * as auth from 'reports/modules/auth';
import LoginForm from 'reports/modules/auth/components/LoginForm';
import { ILoginForm } from 'reports/models/user';

import * as styles from 'reports/styles/styled-components';
import { withRoute } from 'react-router5';
import { IWithRouteProps } from 'reports/utils/router';
import { DialogTitle, DialogStyles } from './common';
const styled = styles.styled;

const logger = Logger.get('auth');

const LoginDialogBody = styled.div`
    .${Classes.BUTTON} {
        background-color: ${Colors.HELIO_ORANGE};
        background-image: linear-gradient(to bottom, ${Colors.HELIO_ORANGE} 0%, ${Colors.HELIO_ORANGE_DARK} 100%);
    }
`;

interface IOwnProps {
    onLoggedIn?: (email: string) => any;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IDispatchProps & IWithRouteProps;
type IState = {
    errorMessage: string | null;
    animating: boolean;
    loading: boolean;
};

export class LoginDialog extends React.Component<IProps, IState> {
    state = { errorMessage: null, animating: false, loading: false };
    animateTimeoutId: number;

    componentWillUnmount() {
        window.clearTimeout(this.animateTimeoutId);
    }

    render() {
        return (
            <Dialog
                title={<DialogTitle>Sign In</DialogTitle>}
                isOpen={true}
                isCloseButtonShown={false}
                style={DialogStyles}
                className={this.state.animating ? 'shake animated' : ''}
            >
                <LoginDialogBody>
                    <LoginForm
                        onSubmit={(formData) => this.onSubmit(formData)}
                        errorMessage={this.state.errorMessage}
                        loading={this.state.loading}
                    />
                </LoginDialogBody>
            </Dialog>
        );
    }

    navPostLogin() {
        const { navHome, navToRoute } = this.props;
        const { postAuthDestinationName, postAuthDestinationParams } = this.props.route?.params;
        if (postAuthDestinationName) {
            navToRoute(postAuthDestinationName, postAuthDestinationParams);
        } else {
            navHome();
        }
    }

    async onSubmit(formData: ILoginForm) {
        const { navNotActivated, onLoggedIn } = this.props;

        try {
            this.setState({ loading: true });
            const user = await this.props.login(formData);
            this.setState({ loading: false });

            if (onLoggedIn) {
                onLoggedIn(formData.email);
            } else {
                errorReports.identify(helioscopeConfig, user);
                this.navPostLogin();
            }
        } catch (e) {
            this.setState({ loading: false });
            if (e.status === 403) {
                navNotActivated(formData.email);
                return;
            }

            logger.warn('Could not validate email/password');
            this.setState({ errorMessage: 'Invalid login', animating: true });
            this.animateTimeoutId = window.setTimeout(() => this.setState({ animating: false }), 1000);
            throw e;
        }
    }
}

const mapDispatchToProps = bindActions({
    login: (formData) => auth.api.login(formData),
    navHome: () => routerActions.navigateTo('app.projects'),
    navToRoute: (routeName: string, routeParams: any) => routerActions.navigateTo(routeName, routeParams),
    navNotActivated: (email) => routerActions.navigateTo('app.not-activated', { email }),
});

export default connect(null, mapDispatchToProps)(withRoute(LoginDialog));
