export enum Unit {
    Currency = 'currency',
    Date = 'date',
    Energy = 'energy',
    Number = 'number',
    Percent = 'percent',
    Power = 'power',
    Voltage = 'voltage',
    Current = 'current',
    Resistance = 'resistance',
    Irradiance = 'irradiance',
    Angle = 'angle',
    Temperature = 'temperature',
    Distance = 'distance',
    Duration = 'duration',
}

export const BYTES_PER_MEGABYTE = 1000 * 1000;
