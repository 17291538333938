import * as React from 'react';

import { IconName, TabId } from '@blueprintjs/core';

import { LibraryList as List } from 'reports/components/core/layout';
import { ContextBarControls } from 'reports/components/ContextBar';

import { IAPIQueryOpts } from 'reports/models/types';

import { SearchControls } from './search';
import SearchableLibrary from './SearchableLibrary';
import { Filter } from '../types';
import { ViewTabs } from './ViewTabs';

interface LibraryMainProps<T> {
    id: TabId;
    loadItems: (opts: IAPIQueryOpts) => Promise<T[]>;
    refreshItem: (item: T) => T;
    resourceIdName: string;
    views: { view: JSX.Element; icon: IconName; id: TabId }[];
    inlineControls?: boolean;
    contextBarControls?: React.ReactNode;
    preview?: React.ReactNode;
    quickFilters?: Filter<T>[];
    extraFilterControls?: JSX.Element;
    searching?: boolean;
    selectedItemId?: number | string;
}

const LibraryMain = <T,>({
    id,
    loadItems,
    refreshItem,
    resourceIdName,
    views,
    inlineControls = false,
    contextBarControls,
    preview,
    quickFilters,
    extraFilterControls,
    searching,
    selectedItemId,
}: LibraryMainProps<T>) => {
    return (
        <SearchableLibrary
            resourceIdName={resourceIdName}
            loadItems={loadItems}
            refreshItem={refreshItem}
            selectedItemId={selectedItemId}
        >
            <List.Container>
                <SearchControls
                    filters={quickFilters}
                    extraControls={inlineControls ? contextBarControls : undefined}
                    extraFilterControls={extraFilterControls}
                />
                <List.Main>
                    <List.ContentPane>
                        <ViewTabs id={id} views={views} searching={searching} />
                    </List.ContentPane>
                </List.Main>
                {preview}
            </List.Container>
            {!inlineControls && <ContextBarControls>{contextBarControls}</ContextBarControls>}
        </SearchableLibrary>
    );
};

export default LibraryMain;
