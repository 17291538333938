import * as React from 'react';

import { Classes, Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { TRANSPARENT_BG } from 'reports/styles/global';

import { S3File } from 'reports/models/s3file';

import UploadableImage from 'reports/modules/files/components/UploadableImage';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const EditOverlay = styled.div`
    display: flex;
    opacity: 0;
    flex-direction: column;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    background: #fff8;
    justify-content: center;
    align-items: center;
    transition: opacity 0.1s ease-in-out;

    .${Classes.ICON} {
        color: rgba(0, 0, 0, 0.5);
    }
`;

const LogoRemoveIcon = styled(Icon).attrs({
    iconSize: 24,
})`
    display: none;
    position: absolute;
    right: -14px;
    top: -14px;
    padding: 4px;
    background: rgba(170, 170, 170, 0.4);
    border-radius: 100%;

    &:hover {
        cursor: pointer;
        color: ${Colors.RED1};
        background: rgba(255, 183, 165, 0.6);
    }
`;

export const LogoContainer = styled.div.attrs({
    className: 'hover-select-state',
})`
    display: flex;
    position: relative;
    max-height: 300px;
    max-width: 100%;
    border-radius: 2px;
    padding: 2px;
    background: #fff4;

    &:hover {
        ${EditOverlay} {
            opacity: 1;
        }
        ${LogoRemoveIcon} {
            display: block;
        }
    }
    .${Classes.NON_IDEAL_STATE} {
        height: 200px;
    }
`;

interface IThemeLogo {
    deleteLogo: () => void;
    handleClick: (e: React.SyntheticEvent) => void;
    updateLogo: (file: S3File) => void;
    fileId?: number;
}

const ThemeLogo: React.SFC<IThemeLogo> = ({ fileId, handleClick, updateLogo, deleteLogo }) => (
    <LogoContainer onClick={handleClick}>
        <div style={{ background: TRANSPARENT_BG, flex: 1 }}>
            <UploadableImage fileId={fileId} onUpdate={updateLogo} tag="logo" disableClick={handleClick != null}>
                {fileId ? (
                    <EditOverlay>
                        <Icon icon={IconNames.MEDIA} iconSize={30} />
                        <div style={{ fontSize: 12, fontWeight: 500 }}>Click or drop file to upload logo</div>
                    </EditOverlay>
                ) : null}
            </UploadableImage>
        </div>
        {fileId != null ? <LogoRemoveIcon icon={IconNames.CROSS} onClick={deleteLogo} /> : null}
    </LogoContainer>
);

export default ThemeLogo;
