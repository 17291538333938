import * as React from 'react';
import * as Q from 'q';
import Logger from 'js-logger';
import { debounce, isEqual } from 'lodash';

import { ResizeSensor } from '@blueprintjs/core';
import { connect } from 'react-redux';

import { IFrameDriver } from 'reports/modules/ogdesigner/IFrameDriver';
import { AngularScope, getIframeScope } from 'reports/angular-bridge';

import * as auth from 'reports/modules/auth';
import { IAppState } from 'reports/store';

let _helioscope: HTMLIFrameElement | null;
let _angularScope: AngularScope | null;

const logger = Logger.get('Helioscope');

const REST_URL = '/empty?forceAngular&hideNavbar'; // lightest page
const REST_STYLE = 'display:none;';
const DEFAULT_Z_INDEX = 5000;
const DEFAULT_POSITION = {
    left: 100000,
    top: 1000000,
    width: 1200,
    height: 1000,
    z: DEFAULT_Z_INDEX,
};

interface IPosition {
    top: number;
    left: number;
    width: number;
    height: number;
    z: number;
}

const getPositionStyle = (sz: IPosition) =>
    `top:${sz.top}px;left:${sz.left}px;width:${sz.width}px;height:${sz.height}px;` + `z-index:${sz.z};position:fixed;`;

const getWidthHeightStyle = (sz: IPosition) => `width:${sz.width}px;height:${sz.height}px;position:fixed;`;

async function getHelioscopeFrame(position: IPosition = DEFAULT_POSITION) {
    if (_helioscope == null) {
        const ready = Q.defer<AngularScope>();

        _helioscope = document.createElement('iframe');
        _helioscope.src = REST_URL;
        _helioscope.onload = () => {
            ready.resolve(getIframeScope(_helioscope!));
        };
        _helioscope.setAttribute('style', REST_STYLE + getPositionStyle(position));
        document.body.appendChild(_helioscope);
        _angularScope = await ready.promise;
    }

    return { iframe: _helioscope, scope: _angularScope! };
}

function hideHelioscope() {
    if (_helioscope != null && _angularScope != null) {
        const style = _helioscope.getAttribute('style') || REST_STYLE;
        _helioscope.setAttribute('style', style.replace('display:block', 'display:none'));
        _angularScope.$location.url(REST_URL);
        _angularScope.$rootScope.$apply();
    }
}

function removeHelioscopeIframe() {
    if (_helioscope != null) {
        _helioscope.remove();
        _helioscope = null;
        _angularScope = null;
    }
}

interface IRequest {
    url: string;
    position: IPosition;
    asUserOverride?: string | number;
    deferred: Q.Deferred<{ iframe: HTMLIFrameElement; driver: IFrameDriver }>;
}

const requests: IRequest[] = [];
let currentRequest: IRequest | undefined = undefined;

export function requestHelioscope(url, position = DEFAULT_POSITION, asUserOverride?: string | number) {
    const deferred = Q.defer<{
        iframe: HTMLIFrameElement;
        driver: IFrameDriver;
    }>();

    const request = { url, position, asUserOverride, deferred };
    requests.push(request);
    manageRequests();

    return {
        promise: deferred.promise,
        checkin: () => {
            manageRequests(request);
        },
    };
}

async function manageRequests(request?: IRequest) {
    if (request) {
        if (currentRequest !== request) {
            const idx = requests.indexOf(request);
            requests.splice(idx, 1);
        } else {
            const { driver } = await request.deferred.promise;
            driver.cleanup();
            currentRequest = undefined;
        }
    }

    if (currentRequest) return; // don't need to initialize the next rquest if the current is still defined

    currentRequest = requests.shift();
    if (currentRequest) {
        const { iframe, scope } = await getHelioscopeFrame();
        scope.$rootScope.hideChat();
        const driver = new IFrameDriver(scope, iframe);

        if (currentRequest.asUserOverride != null) {
            driver.angular.Authenticator.impersonateUser(currentRequest.asUserOverride);
        }

        const style = 'outline:none;border:none;display:block;' + getWidthHeightStyle(currentRequest.position);
        iframe.setAttribute('style', style);

        await driver.goto(currentRequest.url);
        currentRequest.deferred.resolve({ iframe, driver });
    } else {
        hideHelioscope();
    }
}

interface IOwnProps {
    startUrl: string;
    onAcquire?: (iframeDriver: IFrameDriver) => void;
    onRouteChange?: (newPath: string) => void;
    zIndex?: number;
}

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

class HelioScope extends React.Component<IProps> {
    ref = React.createRef<HTMLDivElement>();
    helioscopeIframe: HTMLIFrameElement;
    iframeDriver: IFrameDriver;

    checkinHelioscope: () => void;
    urlListener?: () => void;

    async componentDidMount() {
        if (_angularScope && _helioscope && !isEqual(_angularScope.$rootScope.user()?.role, this.props.authUser.role)) {
            // user role has changed, we need to reload angular context to pick up any user changes
            removeHelioscopeIframe();
        }

        const initialPosition = this.getComponentPosition();
        const { promise, checkin } = requestHelioscope(this.props.startUrl, initialPosition, this.props.asUserOverride);
        this.checkinHelioscope = checkin;
        const { iframe, driver } = await promise;
        this.helioscopeIframe = iframe;
        this.iframeDriver = driver;
        this.resizeIframe();
        this.helioscopeIframe.contentWindow?.focus();
        this.helioscopeIframe.contentWindow?.addEventListener('click', () => {
            this.helioscopeIframe.contentWindow?.focus();
        });
        if (this.props.onAcquire) {
            this.props.onAcquire(driver);
        }

        if (this.props.onRouteChange) {
            this.urlListener = driver.onUrlChange(this.props.onRouteChange);
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.asUserOverride !== oldProps.asUserOverride) {
            this.iframeDriver.angular.Authenticator.impersonateUser(this.props.asUserOverride);
            this.iframeDriver.angular.$state.$reload();
        }
    }

    componentWillUnmount() {
        this.checkinHelioscope();
    }

    getComponentPosition(zIndex = DEFAULT_Z_INDEX): IPosition {
        const ref = this.ref;

        if (!ref.current) return { top: 0, left: 0, height: 0, width: 0, z: 1 };

        const element = ref.current!; // should only be called after component is mounted
        const rect = element.getBoundingClientRect();
        const window = element.ownerDocument!.defaultView;

        const top = rect.top + window!.pageYOffset;
        const left = rect.left + window!.pageXOffset;

        return {
            top,
            left,
            height: rect.height,
            width: rect.width,
            z: zIndex,
        };
    }

    resizeIframe = () => {
        const style =
            'outline:none;border:none;display:block;' + getPositionStyle(this.getComponentPosition(this.props.zIndex));

        if (!this.helioscopeIframe) {
            logger.warn('Iframe not acquired yet.');
        } else {
            this.helioscopeIframe.setAttribute('style', style);
        }
    };

    resizeIFrameDebounced = debounce(this.resizeIframe, 100);

    render() {
        return (
            <ResizeSensor onResize={this.resizeIFrameDebounced}>
                <div
                    ref={this.ref}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {this.props.children}
                </div>
            </ResizeSensor>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    asUserOverride: auth.selectors.asUserOverride(state),
    authUser: auth.selectors.getUser(state)!,
});

const HelioScopeContainer = connect(mapStateToProps)(HelioScope);

export { HelioScopeContainer as Helioscope };
export default HelioScopeContainer;
