import { Classes, Intent, TextArea } from '@blueprintjs/core';
import * as React from 'react';
import styled from 'styled-components';

import FormField from 'reports/components/forms/FormField';
import { FormGroup, IFormGroupProps } from 'reports/components/core/forms';

type ResizeType = 'vertical' | 'horizontal' | 'both' | 'none';

const StyledTextArea = styled(TextArea)<{
    height?: number;
    resize?: ResizeType;
}>`
    resize: ${(props) => props.resize || 'vertical'};
    overflow: auto;
    min-height: 50px;
    max-height: 120px;

    &.${Classes.INPUT} {
        ${(props) => (props.height ? `height: ${props.height}px;` : '')}
    }
`;

type IFormTextAreaInputProps = IFormGroupProps & {
    path: string;
    type?: string;
    placeholder?: string;
    className?: string;
    autoFocus?: boolean;
    height?: number;
    resize?: ResizeType;
};

// TODO: migrate to using withForm higher-order component
const FormTextAreaInput: React.FC<IFormTextAreaInputProps> = (props) => {
    const {
        path,
        label,
        inline,
        fill,
        bold,
        placeholder,
        helperText,
        className,
        autoFocus,
        labelWidth,
        height,
        resize,
    } = props;

    return (
        <FormField path={path}>
            {({ value, onChange, form, errors }) => {
                const invalid = errors.length > 0;
                return (
                    <FormGroup
                        inline={inline}
                        fill={fill}
                        bold={bold}
                        label={label}
                        helperText={invalid ? errors[0] : helperText}
                        intent={invalid ? Intent.DANGER : undefined}
                        labelWidth={labelWidth}
                    >
                        <StyledTextArea
                            intent={invalid ? Intent.DANGER : Intent.NONE}
                            value={value}
                            placeholder={placeholder}
                            onChange={(evt) => {
                                const editValue = evt.target.value;
                                onChange(editValue);
                            }}
                            fill={true}
                            disabled={form.submitting}
                            className={className}
                            autoFocus={autoFocus}
                            height={height}
                            resize={resize}
                        />
                    </FormGroup>
                );
            }}
        </FormField>
    );
};

export default FormTextAreaInput;
