import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Icon, Intent, Menu, MenuItem } from '@blueprintjs/core';
import { ISelectProps } from '@blueprintjs/select';

import { bindActions } from 'reports/utils/redux';
import { IAppState } from 'reports/types';

import { IStaticSelect, QuerySelect, StaticSelect } from 'reports/components/core/forms';

import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';
import * as proj from 'reports/models/project';
import * as usr from 'reports/models/user';

import { selectors as projSelector } from 'reports/modules/project';

import { IMetric } from 'reports/components/ContextBarMetric';

interface IUserSelectOwnProps {
    text: string;
    onItemSelect: (item) => Promise<void>;
    includeOnlyTeamId?: number;
    excludeTeamId?: number;
    excludeUserIds?: number[];
}

export const UserSelectDropdownContainer = ({
    includeOnlyTeamId,
    excludeTeamId,
    excludeUserIds,
    ...restProps
}: IUserSelectOwnProps) => {
    const dispatch = useDispatch();
    const selectItems = useSelector((state: IAppState) => usr.selectors.all(state));

    const queryItems = async (data) => {
        let users;
        // Currently HS admins are able to query for users on other teams - this is a safeguard so that they don't add
        // users from other teams on accident. This can be removed if we think it's unnecessary.
        if (includeOnlyTeamId) {
            users = await dispatch(usr.api.index({ ...data, team_id: includeOnlyTeamId }));
        }

        // This is currently being used by our AddTeamMember component when HS admins are trying to add/merge users from
        // other teams specifically.
        if (excludeTeamId) {
            users = await dispatch(usr.api.index(data));
            users = users.filter((u) => u.team_id !== excludeTeamId);
        }
        if (excludeUserIds && excludeUserIds.length > 0) {
            users = users.filter((u) => !excludeUserIds.includes(u.user_id));
        }
        return users;
    };

    return (
        <QuerySelect
            selectItems={selectItems}
            getId={(obj) => obj.user_id}
            getText={(obj) => `${obj.first_name} ${obj.last_name} (${obj.email})`}
            queryItems={queryItems}
            resetOnClose={true}
            {...restProps}
        />
    );
};

export const ProjectSelectDropdownContainer = connect(
    (state: IAppState, _ownProps) => ({
        selectItems: proj.selectors.all(state),
        getId: (obj) => obj.project_id,
        getText: (obj) => `${obj.name} (${obj.address})`,
    }),
    bindActions(() => ({
        queryItems: (data) => proj.api.index(data),
    })),
)(QuerySelect);

type ICustomStaticSelectProps<T> = Omit<ISelectProps<T>, 'items' | 'itemRenderer'>;

class FinConfigAddDropdown extends React.Component<
    ICustomStaticSelectProps<finTemp.FinancialTemplate> & {
        financialTemplates: finTemp.FinancialTemplate[];
    }
> {
    render() {
        const { financialTemplates, ...otherProps } = this.props;

        return (
            <StaticSelect
                buttonText={'Add Configuration...'}
                buttonProps={{ intent: Intent.WARNING }}
                icon="add"
                items={financialTemplates}
                itemListRenderer={({ items, renderItem }) => {
                    return (
                        <>
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    padding: '8px 0px',
                                    opacity: 0.5,
                                }}
                            >
                                <div style={{ display: 'inline-block' }}>
                                    <Icon icon="duplicate" style={{ marginRight: '8px' }} />
                                    Select model to copy
                                </div>
                            </div>
                            <Menu>{items.map(renderItem)}</Menu>
                        </>
                    );
                }}
                itemRenderer={(item: finTemp.FinancialTemplate, { handleClick, modifiers }) => (
                    <MenuItem
                        key={item.financial_template_id}
                        text={item.description}
                        active={modifiers.active}
                        onClick={handleClick}
                        style={{
                            maxWidth: '500px',
                        }}
                    />
                )}
                {...otherProps}
            />
        );
    }
}

export const FinConfigAddDropdownContainer = connect(
    (state: IAppState) => ({
        financialTemplates: finTemp.selectors.all(state),
    }),
    bindActions(() => ({})),
)(FinConfigAddDropdown);

class FinConfigSelectDropdown extends React.Component<
    ICustomStaticSelectProps<projFinTemp.ProjectFinancialTemplate> & {
        project: proj.Project;
        activeConfig?: projFinTemp.ProjectFinancialTemplate;
    }
> {
    render() {
        const { project, activeConfig, ...otherProps } = this.props;

        return (
            <StaticSelect
                buttonText={activeConfig ? activeConfig.name : 'Select Configuration...'}
                icon="cog"
                items={project.project_financial_templates}
                itemRenderer={(item: projFinTemp.ProjectFinancialTemplate, { handleClick, modifiers }) => (
                    <MenuItem
                        key={item.project_financial_template_id}
                        text={item.name}
                        active={modifiers.active}
                        onClick={handleClick}
                        style={{
                            maxWidth: '500px',
                        }}
                    />
                )}
                {...otherProps}
            />
        );
    }
}

export const FinConfigSelectDropdownContainer = connect(
    (state: IAppState, ownProps: { project: proj.Project }) => ({
        activeConfig: projSelector.primaryProjectFinancialTemplate(state, ownProps),
    }),
    bindActions(() => ({})),
)(FinConfigSelectDropdown);

export const MetricSelectDropdown: React.SFC<IStaticSelect<IMetric>> = ({ items, disabled, ...otherProps }) => (
    <StaticSelect
        items={items}
        buttonProps={{
            disabled,
            minimal: true,
            style: {
                height: '100%',
                width: 170,
                display: 'flex',
                borderRadius: 0,
                marginRight: 0,
            },
        }}
        disabled={disabled}
        popoverProps={{
            minimal: true,
            boundary: 'scrollParent',
            modifiers: { preventOverflow: { enabled: true, padding: 50 } },
            targetProps: { style: { height: '100%', display: 'flex' } },
        }}
        {...otherProps}
    />
);
