import { find, head } from 'lodash';
import Logger from 'js-logger';

import { Dispatch } from 'redux';
import { actionCreatorFactory } from 'typescript-fsa';

import * as time from 'reports/utils/time';
import { createAsyncWorkflow } from 'reports/utils/async_helpers';
import { addPromiseToasts } from 'reports/modules/Toaster';

import * as proj from 'reports/models/project';
import * as uc from 'reports/models/user_consumption';
import * as up from 'reports/models/usage_profile';
import * as us from 'reports/models/usage_site';

import { ERR_INCOMPLETE_INPUT, GeneratedConsumption } from './generated';
import { IAppState } from 'reports/store';

export interface IConsumptionInputForm {
    userInputKwh: uc.IUserInput;
    usageProfile?: up.UsageProfile;
}

const logger = Logger.get('consumption');

export const DEFAULT_CONSUMP_KWH = {
    resi: 1500,
    commercial: 15000,
};

export function getDefaultEnergyFromProfile(profile?: up.UsageProfile | null) {
    try {
        return !profile || profile.building_type.name.includes('resi')
            ? DEFAULT_CONSUMP_KWH.resi
            : DEFAULT_CONSUMP_KWH.commercial;
    } catch (e) {
        logger.error('Error creating default energy');
        logger.error(e);
    }

    return DEFAULT_CONSUMP_KWH.resi;
}

const actionCreator = actionCreatorFactory('CONSUMPTON');
const MAX_SEARCH_METERS = 100000;

const loadProjectUsageProfile = createAsyncWorkflow<proj.Project, up.UsageProfile | null | undefined>(
    'LOAD_PROJECT_CONSUMPTION',
    actionCreator,
    async (project, dispatch, getState) => {
        let projUsageProfileId = project.usage_profile_id;

        if (projUsageProfileId == null) {
            const nearestSite = head(
                await dispatch(
                    us.api.index({
                        distance: MAX_SEARCH_METERS,
                        location: project.location,
                        limit: 1,
                    }),
                ),
            );

            if (nearestSite == null) {
                logger.warn(`no usage sites within ${MAX_SEARCH_METERS}`);
                return null;
            }

            const fullNearestSite = await dispatch(us.api.get(nearestSite));

            const buildingType = 'resi_base';
            const usageProfile = find(fullNearestSite.usage_profiles, (u) => u.building_type.name === buildingType);

            if (usageProfile == null) {
                logger.warn(`could not find usage profile for building type: ${buildingType}`);
                return null;
            }

            projUsageProfileId = usageProfile.usage_profile_id;

            dispatch(proj.saver.get(project).patch({ usage_profile_id: projUsageProfileId }));
        }

        let usageProfile = proj.selectors.usageProfile(getState(), project);
        if (usageProfile == null || usageProfile.data == null) {
            usageProfile = await dispatch(up.api.get({ usage_profile_id: projUsageProfileId }));
        }

        return usageProfile;
    },
);

export const consumptionCreateMessages = (name) => ({
    initial: `Uploading ${name}`,
    onCatch: `Error uploading ${name}`,
    onSuccess: `Successfully uploaded ${name}`,
});

const updateConsumption =
    (consumption: uc.UserConsumption) => async (dispatch: Dispatch, getState: () => IAppState) => {
        let usageProfile = uc.selectors.usageProfile(getState(), consumption, '*');

        if (
            consumption.usage_profile_id != null &&
            (usageProfile == null || usageProfile.data == null || usageProfile.building_type == null)
        ) {
            usageProfile = await dispatch(up.api.get({ usage_profile_id: consumption.usage_profile_id }));
        }
        const userInput = consumption.user_input || {};
        const userInputCount = Object.keys(userInput).length;

        if (usageProfile == null && userInputCount < 12 && userInputCount > 1) {
            throw Error(ERR_INCOMPLETE_INPUT);
        }

        const generatedConsumption = new GeneratedConsumption(
            consumption.project.time_zone_offset,
            userInput,
            usageProfile,
        );
        const data = generatedConsumption.local8760Energy();

        const userConsumption = await addPromiseToasts(
            dispatch(uc.api.save({ ...consumption, sample_data: { data } })),
            {
                initial: `Saving updates to manual consumption`,
                onCatch: (error) => `Error saving manual consumption: ${error}`,
                onSuccess: `Successfully saved manual consumption`,
            },
        );

        return userConsumption;
    };

const createNewManualConsumption = (project: proj.Project) => async (dispatch: Dispatch) => {
    const deprecated = project.data.financialConfig;
    const month = deprecated.userConsumptionMonth || time.getCurrentMonth();

    const usageProfile = await dispatch(loadProjectUsageProfile(project));
    const energy = (deprecated.userConsumptionKwh || getDefaultEnergyFromProfile(usageProfile)) * 1000; // watts

    if (usageProfile == null) {
        logger.warn('Could not find usage profile for ', project);
    }

    const userInput: uc.IUserInput = {
        [month]: { energy },
    };

    let upWithHourly = usageProfile;
    if (usageProfile != null && usageProfile.data == null) {
        upWithHourly = await dispatch(up.api.get({ usage_profile_id: usageProfile.usage_profile_id }));
    }

    const generatedConsumption = new GeneratedConsumption(project.time_zone_offset, userInput, upWithHourly);

    return await addPromiseToasts(
        dispatch(
            uc.api.create({
                name: 'Manual Consumption File',
                project_id: project.project_id,
                usage_profile_id: usageProfile ? usageProfile.usage_profile_id : undefined,
                user_input: userInput,
                sample_data: {
                    data: generatedConsumption.local8760Energy(),
                },
            }),
        ),
        consumptionCreateMessages('Manual Consumption File'),
    );
};

export const actions = {
    updateConsumption,
    loadProjectUsageProfile,
    createNewManualConsumption,
};
