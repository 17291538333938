/* tslint:disable:variable-name */
/* eslint camelcase: 0 */

import _ from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { Button, Intent, Switch, Tab, Tabs } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import { Flex } from 'reports/components/core/containers';
import CardSection from 'reports/components/core/containers/CardSection';
import { DeleteButton } from 'reports/components/core/controls';
import { Warning } from 'reports/components/helpers/errors';
import { UpsellBanner } from 'reports/components/UpsellBanner';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import Toaster from 'reports/modules/Toaster';

import { FieldComponent } from 'reports/models/field_component';
import { Project } from 'reports/models/project';
import { Simulation } from 'reports/models/simulation';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';
import * as utility from 'reports/models/utility_rate';

import { actions as projActions, selectors as projSelectors } from 'reports/modules/project';

import {
    AuthErrorCode,
    FinancialPipelineTree,
    Node,
    PipelineResult,
    paramKey,
    pipelineModuleData,
} from 'reports/modules/financials/model/pipeline';
import { ModelRun } from 'reports/modules/financials/model/run';
import * as finState from 'reports/modules/financials/state';

import FinancialMetrics from './FinancialMetrics';
import LifetimeProduction from './LifetimeProduction';
import { LineItem } from './LineItem';
import { complexEditor, PipelineNode, PipelineEditor } from './pipelineCommon';
import Result from './Result';
import WarningTooltip from './WarningTooltip';

import TimeSeriesOutputTable from './TimeSeriesOutputTable';

enum TABS {
    Config = 'config',
    Debug = 'debug',
}

interface IOwnProps {
    config: projFinTemp.ProjectFinancialTemplate;
    project: Project;
}

interface IDispatchProps {
    deleteFinancialConfig: (config: projFinTemp.ProjectFinancialTemplate) => Promise<any>;
    loadFieldComponents: (designId: number) => Promise<FieldComponent[]>;
    setPrimaryConfig: (config: projFinTemp.ProjectFinancialTemplate | null) => Promise<any>;
    setUtilityRate: (rate: utility.UtilityRate | null) => Promise<any>;
    updateFinancialConfig: (params: Partial<projFinTemp.ProjectFinancialTemplate>, saveNow?: boolean) => Promise<any>;

    queueRun: (config: projFinTemp.ProjectFinancialTemplate, project: Project, sim: Simulation) => any;
    navigateTo: (name: string, params: any) => any;
}

interface IState {
    expandParams: any;
    selectedTabId: TABS;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

function showMissingSimToast() {
    Toaster.show({
        icon: IconNames.WARNING_SIGN,
        message: <div>Missing simulation for financial calculations</div>,
        timeout: 2500,
    });
}

class FinancialConfigurationEditor extends React.PureComponent<IOwnProps & IStateProps & IDispatchProps, IState> {
    driver: ModelRun;
    dirty: boolean;

    state: IState = {
        expandParams: {},
        selectedTabId: TABS.Config,
    };

    constructor(props) {
        super(props);

        this.driver = ModelRun.getInstance();
        this.dirty = false;
    }

    async componentDidMount() {
        const { simulation } = this.props;

        if (!simulation) {
            showMissingSimToast();
            return;
        }

        await this.props.loadFieldComponents(simulation.design_id);
        _.defer(() => this.runModel());
    }

    componentDidUpdate(prevProps) {
        if (prevProps.config.project_financial_template_id !== this.props.config.project_financial_template_id) {
            this.dirty = true;
        }

        if (this.dirty) {
            this.dirty = false;
            _.defer(() => this.runModel());
        }
    }

    render() {
        const { config, template, output } = this.props;
        const isComputing = !finState.hasOutput(output) && output.status === finState.FIN_STATUSES.computing;

        const renderSync = () => {
            if (!template) {
                return (
                    <div className="sub-content-box-1">
                        <Warning msg="Base model deleted" />
                    </div>
                );
            }

            if (config.financial_template_version_id !== template.latest_version_id) {
                return (
                    <Flex.Container className="sub-content-box-1" alignV={Flex.AlignV.CENTER}>
                        <div style={{ marginRight: 8 }}>
                            <Warning msg="Newer model exists" />
                        </div>
                        <Button text="Update Configuration" onClick={() => this.updateConfigVersion()} />
                    </Flex.Container>
                );
            }

            return null;
        };

        const isprimary = this.isPrimary();
        const primaryButton = isprimary ? (
            <Button icon="star" disabled text="Already primary" />
        ) : (
            <Button icon="star-empty" text="Make primary" onClick={() => this.props.setPrimaryConfig(config)} />
        );

        return (
            <>
                <div className="content-header">
                    <EditableTitleSubHeader
                        value={config.name}
                        updateFn={(val) => this.updateName(val)}
                        right={
                            <div style={{ display: 'flex' }}>
                                <div style={{ margin: '0px 2px' }}>{primaryButton}</div>
                                {template ? (
                                    <div style={{ margin: '0px 2px' }}>
                                        <Button
                                            text="Go To Model"
                                            icon={IconNames.SHARE}
                                            onClick={() =>
                                                this.props.navigateTo(
                                                    'app.financial-templates.financial-template.preview',
                                                    {
                                                        finTemplateId: template.financial_template_id,
                                                    },
                                                )
                                            }
                                        />
                                    </div>
                                ) : null}
                                <div style={{ margin: '0px 2px' }}>
                                    <DeleteButton
                                        onClick={() => this.props.deleteFinancialConfig(config)}
                                        intent={Intent.DANGER}
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
                <UpsellBanner />
                <div className="sub-content-inner" style={{ padding: '10px 12px' }}>
                    {renderSync()}

                    <Tabs
                        id="proj-fin-config"
                        selectedTabId={this.state.selectedTabId}
                        onChange={(selectedTabId: TABS) => this.setState({ selectedTabId })}
                    >
                        <Tab
                            id={TABS.Config}
                            key={TABS.Config}
                            title={'Configuration'}
                            panel={
                                <>
                                    <div className="sub-content-box-1">
                                        <CardSection title={'Key Metrics' + (isComputing ? ' (Computing...)' : '')}>
                                            <Flex.Container
                                                style={{
                                                    opacity: isComputing ? 0.5 : 1.0,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: '1 1 50%',
                                                        padding: '4px',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <FinancialMetrics overrideTokens={output} />
                                                </div>
                                                <div
                                                    style={{
                                                        flex: '1 1 50%',
                                                        padding: '4px',
                                                        verticalAlign: 'top',
                                                    }}
                                                >
                                                    <LifetimeProduction overrideTokens={output} />
                                                </div>
                                            </Flex.Container>
                                        </CardSection>
                                    </div>
                                    <div className="sub-content-box-1">
                                        <CardSection title="Steps">{this.renderSteps()}</CardSection>
                                    </div>
                                </>
                            }
                        />
                        <Tab
                            id={TABS.Debug}
                            key={TABS.Debug}
                            title="Debug"
                            panel={<TimeSeriesOutputTable config={config} />}
                        />
                    </Tabs>
                </div>
                <div className="sub-content-footer" />
            </>
        );
    }

    renderSteps() {
        const { config, output } = this.props;
        const { expandParams } = this.state;

        let tree: FinancialPipelineTree | null = null;

        try {
            const baseTree = FinancialPipelineTree.fromRawData(config.template_data.root);
            const configTree = FinancialPipelineTree.fromRawData(config.configuration_data.root);
            tree = baseTree.mergeNodeData(configTree);
        } catch (e) {
            return <Warning msg="Configuration has errors -- please try recreating or updating" />;
        }

        const toggleExpand = (pkey) =>
            this.setState({
                expandParams: _.assign({}, expandParams, {
                    [pkey]: !expandParams[pkey],
                }),
            });

        const renderLeaf = (node: Node, nindex: number[]) => {
            const modDat = pipelineModuleData(node);
            const filtered = modDat.metaParams.filter((i) => !i.hidden);

            const parameters = filtered.map((i, idx) => {
                const pkey = paramKey(nindex, idx);

                const renderParam = () => (
                    <PipelineNode
                        metaParam={i}
                        tree={tree!}
                        nindex={nindex}
                        updateTree={(updated) => this.updateTree(updated)}
                    />
                );

                if (complexEditor(i)) {
                    const expanded = expandParams[pkey];

                    return (
                        <LineItem
                            key={idx}
                            label={i.description}
                            line={
                                <Button icon="edit" text="Edit" active={expanded} onClick={() => toggleExpand(pkey)} />
                            }
                            after={expanded ? <div className="expand-edit">{renderParam()}</div> : null}
                        />
                    );
                }

                return <LineItem key={idx} label={i.description} line={renderParam()} />;
            });

            const outputs =
                node.module.outputValues &&
                node.module.outputValues.map((result: PipelineResult, idx) => {
                    const ResultComponent = (
                        <Result
                            key={idx}
                            result={{
                                ...result,
                                value: _.get(output, result.path),
                            }}
                        />
                    );
                    if (result.description) {
                        return (
                            <LineItem
                                key={idx}
                                label={result.description}
                                line={<div style={{ paddingLeft: '4px' }}>{ResultComponent}</div>}
                            />
                        );
                    }
                    return ResultComponent;
                });

            const renderToggle = () => {
                if (!node.toggleable) return null;

                return (
                    <Switch
                        innerLabel="disable"
                        innerLabelChecked="enable"
                        style={{ marginRight: '8px', marginBottom: '0px' }}
                        checked={!node.toggled_off}
                        onChange={() => {
                            const updated = tree!.updateNode(nindex, 'toggled_off', !node.toggled_off);
                            this.updateTree(updated);
                        }}
                    />
                );
            };

            const hasStepContent = filtered.length || (outputs && outputs.length);
            const hideStepContent = node.toggleable && node.toggled_off;
            return (
                <div className="financial-step-box" key={nindex.toString()}>
                    <div className="step-header" style={{ display: 'flex' }}>
                        {renderToggle()}
                        <span style={!node.toggled_off ? {} : { color: '#a0a0a0' }}>
                            {node.user_label || modDat.description}
                        </span>
                    </div>
                    {hasStepContent && !hideStepContent && (
                        <div className="param-list">
                            {parameters}
                            {outputs}
                        </div>
                    )}
                </div>
            );
        };

        const renderLeafError = (error: AuthErrorCode, node: Node, nindex: number[]) => {
            const modDat = pipelineModuleData(node);

            return (
                <div className="financial-step-box" key={nindex.toString()}>
                    <div className="step-header" style={{ display: 'flex' }}>
                        <WarningTooltip error={error} />
                        <span style={!node.toggled_off ? {} : { color: '#a0a0a0' }}>
                            {node.user_label || modDat.description}
                        </span>
                    </div>
                </div>
            );
        };

        const renderRoot = (_node: Node, children: React.ReactNode) => {
            return <div>{children}</div>;
        };

        return (
            <div>
                <PipelineEditor
                    tree={tree!}
                    renderLeaf={renderLeaf}
                    renderLeafError={renderLeafError}
                    renderRoot={renderRoot}
                />
            </div>
        );
    }

    updateTree(tree) {
        const root = tree.toRawData();
        const { configuration_data } = this.props.config;
        this.props.updateFinancialConfig({
            configuration_data: _.assign({}, configuration_data, { root }),
        });
    }

    async updateName(val) {
        try {
            await this.props.updateFinancialConfig({ name: val }, true);
        } catch (e) {
            const failureResponse = JSON.parse(e.response.text);
            if (failureResponse['name']) {
                Toaster.show({
                    message: `Error changing name: ${failureResponse['name']}`,
                    intent: Intent.DANGER,
                });
            } else {
                throw e;
            }
        }
    }

    updateConfigVersion() {
        const { template } = this.props;
        if (!template) throw new Error();

        this.dirty = true;
        this.props.updateFinancialConfig({
            financial_template_version_id: template.latest_version_id,
            template_data: template.data,
            configuration_data: template.data,
        });
    }

    isPrimary() {
        const { config, project } = this.props;
        return project.primary_project_financial_template_id === config.project_financial_template_id;
    }

    runModel() {
        if (this.isPrimary()) return;

        const { config, project, simulation } = this.props;

        if (!project || !simulation) {
            showMissingSimToast();
            return;
        }

        this.props.queueRun(config, project, simulation);
    }
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
    const { finConfigOutput } = finState.selectors;
    return {
        template: finTemp.selectors.byId(state, ownProps.config.financial_template_id),
        simulation: projSelectors.primarySimulation(state, ownProps),
        output: finConfigOutput(state, ownProps),
    };
};

const mapDispatchToProps = bindActions(({ project, config }: IOwnProps) => ({
    updateFinancialConfig: (patch, saveNow?) => projFinTemp.saver.get(config).patch(patch, saveNow),
    deleteFinancialConfig: (finConfig) =>
        projFinTemp.api.delete({
            project_financial_template_id: finConfig.project_financial_template_id,
        }),
    loadFieldComponents: (designId) => projActions.loadFieldComponents(designId),
    setPrimaryConfig: (config) => projActions.setPrimaryProjectFinancialTemplate(project, config),
    setUtilityRate: (rate) => projActions.setUtilityRate(project, rate),
    queueRun: (config, project, sim) => finState.actions.queueRun(config, project, sim),
    navigateTo: (name, params) => routerActions.navigateTo(name, params),
}));

export default connect(mapStateToProps, mapDispatchToProps)(FinancialConfigurationEditor);
