import * as React from 'react';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';

import { Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import classNames from 'classnames';

import Translations from 'reports/localization/strings';

import { Energy, FormattedNumber, Integer, Percent, Temperature } from 'reports/components/core/numbers';
import { SimulationMetadata } from 'reports/models/simulation';

import { registerWidget, IWidgetRenderProps, IReportContext } from 'reports/modules/report/widgets';
import { WIDGET_BORDER_RADIUS } from 'reports/modules/report/widget_style';
import { WidgetDataTable, TooltipContent } from 'reports/modules/report/components/helpers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const almostEquals = (a, b, tol = 1e-6) => Math.abs(a - b) < tol;

interface IProductionSubTableProps {
    simResults: SimulationMetadata;
    intl: IntlShape;
}

interface ITooltipRowProps {
    tooltipContent: string | JSX.Element;
    className: string | undefined;
}

interface ILossRow {
    label: string;
    value: number;
    tooltip: string | React.ReactNode;
}

const CombinedTableContainer = styled.div`
    &.widget-header {
        table {
            border: none;

            tr > :first-child {
                border-left: none;
            }
            tr > :last-child {
                border-right: none;
            }
        }
        table:first-child {
            thead tr:first-child > * {
                box-shadow: none;
                border-top: none;
            }
        }
        table:last-child {
            border-radius: ${WIDGET_BORDER_RADIUS}px;

            tbody tr:last-child > * {
                border-bottom: none;
            }
            tbody tr:last-child > :first-child {
                border-bottom-left-radius: ${WIDGET_BORDER_RADIUS}px;
            }
            tbody tr:last-child > :last-child {
                border-bottom-right-radius: ${WIDGET_BORDER_RADIUS}px;
            }
        }
    }
`;

class TooltipRow extends React.PureComponent<ITooltipRowProps> {
    state = {
        showTooltip: false,
    };

    onMouseOver = () => this.setState({ showTooltip: true });
    onMouseLeave = () => this.setState({ showTooltip: false });

    render() {
        return (
            <tr className={this.props.className} onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
                {this.props.children}
                <td className="hidden-cell">
                    <Tooltip
                        content={this.props.tooltipContent}
                        isOpen={this.state.showTooltip}
                        position={Position.RIGHT}
                        targetTagName="div"
                        modifiers={{ flip: { behavior: 'flip' } }}
                    >
                        <></>
                    </Tooltip>
                </td>
            </tr>
        );
    }
}

const LossRows: React.SFC<{ header: React.ReactNode; rows: ILossRow[] }> = ({ header, rows }) => {
    const renderedRows: React.ReactNode[] = [];

    for (let i = 0; i < rows.length; i += 1) {
        const { label, value, tooltip } = rows[i];
        const tooltipContent = <TooltipContent>{tooltip}</TooltipContent>;
        const lastValue = i === 0 ? null : rows[i - 1].value;
        const delta = lastValue == null ? null : value / lastValue - 1;

        const rowContent = (
            <>
                {i === 0 && <th rowSpan={rows.length}>{header}</th>}
                <td>{label}</td>
                <td>{value != null ? <FormattedNumber value={value / 1000} precision={1} /> : '-'}</td>
                <td>{delta != null ? <Percent value={delta} /> : '-'}</td>
            </>
        );

        renderedRows.push(
            <TooltipRow
                key={i}
                className={classNames({
                    'header-row': i === 0,
                    'energy-summary': i === rows.length - 1,
                })}
                tooltipContent={tooltipContent}
            >
                {rowContent}
            </TooltipRow>,
        );
    }

    return <>{...renderedRows}</>;
};

const LossTreeTableIntl: React.SFC<IProductionSubTableProps> = ({ simResults, intl }) => {
    const irradianceRows: ILossRow[] = [];

    irradianceRows.push({
        label: intl.formatMessage(Translations.simulation.annual_global_horiz_irrad),
        value: simResults.global_horizontal_irradiance,
        tooltip: intl.formatMessage(Translations.simulation.annual_horiz_irrad_tooltip),
    });
    if (
        simResults.adjusted_ghi > 0 &&
        !almostEquals(simResults.adjusted_ghi, simResults.global_horizontal_irradiance)
    ) {
        irradianceRows.push({
            label: intl.formatMessage(Translations.simulation.adj_global_horiz_irrad),
            value: simResults.adjusted_ghi,
            tooltip: intl.formatMessage(Translations.simulation.adj_horiz_irrad_tooltip),
        });
    }
    irradianceRows.push({
        label: intl.formatMessage(Translations.simulation.poa_irradiance),
        value: simResults.poa_irradiance,
        tooltip: intl.formatMessage(Translations.simulation.poa_irradiance_tooltip),
    });
    irradianceRows.push({
        label: intl.formatMessage(Translations.simulation.shaded_irradiance),
        value: simResults.shaded_irradiance,
        tooltip: intl.formatMessage(Translations.simulation.shaded_irradiance_tooltip),
    });
    irradianceRows.push({
        label: intl.formatMessage(Translations.simulation.irradiance_after_reflection),
        value: simResults.effective_irradiance,
        tooltip: intl.formatMessage(Translations.simulation.irradiance_after_reflection_tooltip),
    });
    irradianceRows.push({
        label: intl.formatMessage(Translations.simulation.irradiance_after_soiling),
        value: simResults.soiled_irradiance,
        tooltip: intl.formatMessage(Translations.simulation.irradiance_after_soiling_tooltip),
    });
    irradianceRows.push({
        label: intl.formatMessage(Translations.simulation.total_collector_irradiance),
        value: simResults.total_irradiance,
        tooltip: intl.formatMessage(Translations.simulation.total_collector_irradiance),
    });

    const energyRows: ILossRow[] = [];
    const hasOptimizerInputPower = !!simResults.optimizer_input_power && simResults.optimizer_input_power !== 0;
    const hasClippingLosses = !!simResults.optimal_dc_power && simResults.optimal_dc_power > 0;
    const showEnergyToGrid = !!simResults.grid_power && simResults.grid_power !== null;

    energyRows.push({
        label: intl.formatMessage(Translations.design.nameplate),
        value: simResults.nameplate_power,
        tooltip: intl.formatMessage(Translations.design.nameplate_tooltip),
    });
    energyRows.push({
        label: intl.formatMessage(Translations.simulation.module_irradiance_derated_power),
        value: simResults.module_irradiance_derated_power,
        tooltip: intl.formatMessage(Translations.simulation.irradiance_derated_power_tooltip),
    });
    energyRows.push({
        label: intl.formatMessage(Translations.simulation.module_mpp_power),
        value: simResults.module_mpp_power,
        tooltip: intl.formatMessage(Translations.simulation.module_mpp_power_tooltip),
    });
    energyRows.push({
        label: intl.formatMessage(Translations.simulation.module_mismatch),
        value: simResults.module_power,
        tooltip: intl.formatMessage(Translations.simulation.module_mismatch_tooltip),
    });
    if (hasOptimizerInputPower) {
        energyRows.push({
            label: intl.formatMessage(Translations.simulation.optimizer_output_power),
            value: simResults.optimizer_output_power,
            tooltip: intl.formatMessage(Translations.simulation.optimizer_output_power_tooltip),
        });
    }
    if (hasClippingLosses) {
        energyRows.push({
            label: intl.formatMessage(Translations.simulation.optimal_dc_output),
            value: simResults.optimal_dc_power,
            tooltip: intl.formatMessage(Translations.simulation.optimal_dc_output_tooltip),
        });
    }
    if (hasClippingLosses) {
        energyRows.push({
            label: intl.formatMessage(Translations.simulation.constrained_dc_output),
            value: simResults.constrainedDCOutput(),
            tooltip: (
                <div>
                    <FormattedMessage {...Translations.simulation.constrained_dc_output_tooltip} />
                    {simResults.inverter_overvoltage_loss !== 0 && (
                        <span>
                            <br />
                            <FormattedMessage {...Translations.simulation.overvoltage} />:{' '}
                            <Energy value={simResults.inverter_overvoltage_loss} />
                        </span>
                    )}
                    {simResults.inverter_undervoltage_loss !== 0 && (
                        <span>
                            <br />
                            <FormattedMessage {...Translations.simulation.undervoltage} />:{' '}
                            <Energy value={simResults.inverter_undervoltage_loss} />
                        </span>
                    )}
                    {simResults.inverter_overpower_loss !== 0 && (
                        <span>
                            <br />
                            <FormattedMessage {...Translations.simulation.overpower} />:{' '}
                            <Energy value={simResults.inverter_overpower_loss} />
                        </span>
                    )}
                    {simResults.inverter_underpower_loss !== 0 && (
                        <span>
                            <br />
                            <FormattedMessage {...Translations.simulation.underpower} />:{' '}
                            <Energy value={simResults.inverter_underpower_loss} />
                        </span>
                    )}
                </div>
            ),
        });
        if (!hasClippingLosses) {
            energyRows.push({
                label: intl.formatMessage(Translations.simulation.actual_dc_power),
                value: simResults.actual_dc_power,
                tooltip: intl.formatMessage(Translations.simulation.actual_dc_power_tooltip),
            });
        }
        energyRows.push({
            label: intl.formatMessage(Translations.simulation.ac_power),
            value: simResults.ac_power,
            tooltip: intl.formatMessage(Translations.simulation.ac_power_tooltip),
        });
        if (showEnergyToGrid) {
            energyRows.push({
                label: intl.formatMessage(Translations.simulation.grid_power),
                value: simResults.grid_power,
                tooltip: intl.formatMessage(Translations.simulation.grid_power_tooltip),
            });
        }
    }

    const irradianceHeaderLabel = (
        <div>
            <FormattedMessage {...Translations.simulation.irradiance} />
            <br />
            <span>
                (kWh/m<sup>2</sup>)
            </span>
        </div>
    );
    const energyHeaderLabel = (
        <div>
            <FormattedMessage {...Translations.simulation.energy} />
            <br />
            <span>(kWh)</span>
        </div>
    );

    return (
        <WidgetDataTable className="align-right">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <FormattedMessage {...Translations.general.description} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.simulation.output} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.simulation.percent_delta} />
                    </th>
                </tr>
            </thead>
            <tbody>
                <LossRows rows={irradianceRows} header={irradianceHeaderLabel} />
                <LossRows rows={energyRows} header={energyHeaderLabel} />
            </tbody>
        </WidgetDataTable>
    );
};
const LossTreeTable = injectIntl(LossTreeTableIntl);

const TemperatureMetricsTableIntl: React.SFC<IProductionSubTableProps> = ({ simResults }) => {
    return (
        <WidgetDataTable className="align-right">
            <thead>
                <tr>
                    <th colSpan={4}>
                        <FormattedMessage {...Translations.simulation.temperature_metrics} />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={3}>
                        <FormattedMessage {...Translations.simulation.avg_ambient_temp} />
                    </td>
                    <td colSpan={1}>
                        {!!simResults.avg_ambient_temp ? <Temperature value={simResults.avg_ambient_temp} /> : '-'}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <FormattedMessage {...Translations.simulation.avg_cell_temp} />
                    </td>
                    <td colSpan={1}>
                        {!!simResults.avg_cell_temp ? <Temperature value={simResults.avg_cell_temp} /> : '-'}
                    </td>
                </tr>
            </tbody>
        </WidgetDataTable>
    );
};
const TemperatureMetricsTable = injectIntl(TemperatureMetricsTableIntl);

const SimulationMetricsTableIntl: React.SFC<IProductionSubTableProps> = ({ simResults }) => {
    return (
        <WidgetDataTable className="align-right">
            <thead>
                <tr>
                    <th colSpan={4}>
                        <FormattedMessage {...Translations.simulation.simulation_metrics} />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td colSpan={3}>
                        <FormattedMessage {...Translations.simulation.operating_hours} />
                    </td>
                    <td colSpan={1}>{!!simResults.total_slices ? <Integer value={simResults.total_slices} /> : '-'}</td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <FormattedMessage {...Translations.simulation.solved_hours} />
                    </td>
                    <td colSpan={1}>
                        {!!simResults.complete_slices ? <Integer value={simResults.complete_slices} /> : '-'}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <FormattedMessage {...Translations.simulation.pending_hours} />
                    </td>
                    <td colSpan={1}>
                        {!!simResults.pending_slices ? <Integer value={simResults.pending_slices} /> : '-'}
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <FormattedMessage {...Translations.simulation.error_hours} />
                    </td>
                    <td colSpan={1}>{!!simResults.error_slices ? <Integer value={simResults.error_slices} /> : '-'}</td>
                </tr>
            </tbody>
        </WidgetDataTable>
    );
};
const SimulationMetricsTable = injectIntl(SimulationMetricsTableIntl);

type IContext = Pick<IReportContext, 'simulation'>;
const AnnualProductionTable: React.FC<IWidgetRenderProps<object, IContext>> = ({ context, className }) => {
    const { simulation } = context;
    const metadata = simulation.metadata;

    return (
        <CombinedTableContainer className={className}>
            <LossTreeTable simResults={metadata} />
            <TemperatureMetricsTable simResults={metadata} />
            <SimulationMetricsTable simResults={metadata} />
        </CombinedTableContainer>
    );
};

export const AnnualProductionTableWidget = registerWidget('loss_tree_table', {
    Component: AnnualProductionTable,
    metadata: {
        category: 'project',
        dimensions: { h: 815, w: 600 },
        displayName: Translations.simulation.annual_production,
        icon: IconNames.TH,
    },
    dependencies: ['simulation'],
});

export default AnnualProductionTableWidget;
