import { Colors, Intent } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { IAppState } from 'reports/types';
import { UnstyledLink, LinkButton } from 'reports/components/core/controls';
import ReportsColors from 'reports/styles/colors';
import { Text } from '@aurorasolar/ds';
import { x } from '@xstyled/styled-components';
import * as cfg from 'reports/config';

import * as styles from 'reports/styles/styled-components';
import * as React from 'react';
import { getClassicUrl } from 'reports/utils/url';
import { FormikHelpers } from 'formik';

const styled = styles.styled;

const defaultTOUUrl = 'https://helioscope.aurorasolar.com/terms-of-use/';
const defaultPrivacyPolicyURL = 'https://www.aurorasolar.com/privacy-policy';

const getTOUURL = () => {
    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));
    return config ? config.tou_url : defaultTOUUrl;
};

const getPrivacyPolicyURL = () => {
    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));
    return config ? config.privacy_policy_url : defaultPrivacyPolicyURL;
};

const StyledLink = styled(UnstyledLink)`
    &:hover {
        text-decoration: underline;
    }
    color: #f80 !important;
`;

const DialogStyles = {
    backgroundColor: ReportsColors.BG_WHITE,
    maxWidth: '400px',
};

const DialogTitle = styled.div`
    padding: 8px 0px;
    color: ${ReportsColors.HELIO_ORANGE};
    font-size: 30px;
    font-weight: normal;
`;

interface IPropsSignUpBtn {
    btnIntent: 'primary' | 'warning';
}

const CheckoutFlowSignUpBtn = ({ btnIntent = Intent.PRIMARY }: IPropsSignUpBtn) => {
    return (
        <LinkButton
            large
            intent={btnIntent}
            routeName="app.settings.team.billing"
            searchParams={{ dialog: 'initial' }}
            style={{ color: Colors.WHITE }}
            text="Sign Up"
        />
    );
};

const SignupPageLink = ({ text, url }) => (
    <x.a textDecoration="underline" fontWeight="400" href={url}>
        {text}
    </x.a>
);

const TermsOfUseStatement = () => (
    <Text>
        I have read and agree with the <SignupPageLink text="Terms of Use" url={getTOUURL()} /> and{' '}
        <SignupPageLink text="Privacy Policy" url={getPrivacyPolicyURL()} />.
    </Text>
);

const goToLogin = () => (window.location.href = getClassicUrl('?login'));
const goToSignup = () => (window.location.href = getClassicUrl('signup'));
const goToBookDemo = () => (window.location.href = 'https://helioscope.aurorasolar.com/book-a-demo/');

// Set formik field errors from exceptions thrown in the backend that result in an error code
const formikSetFieldErrors = (ex, { setFieldError }: FormikHelpers<any>) => {
    if (ex.response && ex.response.body) {
        // Response is an object with an array of error strings under each form field
        const fieldErrors = ex.response.body;
        Object.keys(fieldErrors).forEach((f) => setFieldError(f, fieldErrors[f][0]));
        return;
    }
};

export {
    StyledLink,
    CheckoutFlowSignUpBtn,
    DialogStyles,
    DialogTitle,
    goToLogin,
    goToSignup,
    goToBookDemo,
    formikSetFieldErrors,
    TermsOfUseStatement,
};
