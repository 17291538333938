/*
 * Suggested library list component hierarchy:
 *    Container
 *        LibrarySearch.Container
 *        Main
 *            ViewModeButtons
 *                ViewModeButton
 *            ContentPane
 *        LibraryPreview.Drawer
 */
import * as React from 'react';

import { Button, ButtonGroup, IButtonProps } from '@blueprintjs/core';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

import { Flex } from 'reports/components/core/containers';

export const Container = styled(Flex.ContainerV)`
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: 0 12px;

    > :first-child {
        margin-top: 12px;
    }
`;

export const Main = styled(Flex.ContainerV)`
    flex: 1;
    margin: 10px 0;
    overflow: hidden;
`;

// TODO: This can probably be refactored out
export const ContentPane = styled(Flex.Main)`
    height: 100%;
`;

export const ViewModeButton: React.FC<Omit<IButtonProps, 'text'>> = (props) => <Button {...props} />;

// TODO: consider refactoring ViewModeButtons using ButtonGroupSelect
export const ViewModeButtons: React.FC<React.HTMLProps<HTMLElement>> = (props) => (
    <Flex.Container align={Flex.Align.RIGHT} style={{ padding: 5 }}>
        <ButtonGroup minimal={true}>{/* `ViewModeButton`s */ props.children}</ButtonGroup>
    </Flex.Container>
);

export default {
    Container,
    ContentPane,
    Main,

    ViewModeButton,
    ViewModeButtons,
};
