/* tslint:disable:variable-name */

import moment from 'moment';

import { ReduxEndpoint, BaseClass } from 'reports/utils/api';

import { schema } from './schema';
import * as user from './user';
import * as team from './team';
import { IAPIQueryOpts } from './types';

export interface IS3FileMetadata {
    tags?: string[];

    // for images, TODO: refactor to a dimensions thing
    width?: number;
    height?: number;
}

// I prefer the name File, here, but that's a reserved type
class S3File extends BaseClass {
    file_id: number;

    filename: string;
    name: string;
    extension: string;
    size: number;

    get_url: string;

    team_id: number;
    team: team.Team;

    created: moment.Moment;
    last_modified: moment.Moment;

    creator_id: number;
    creator: user.User;

    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    meta: IS3FileMetadata;

    // needed for uploads, but not persisted
    seed?: string;

    constructor(data: any) {
        super(S3File.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        last_modified: (x) => moment(x),
        created: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(S3File, 'file', {
    relationships: {
        creator: { schema: user.schemaObj },
        last_modified_by_user: { schema: user.schemaObj },
        team: { schema: team.schemaObj },
    },
});

interface IFileSearchOpts extends IAPIQueryOpts {
    extensions?: string[];
    extension?: string;
    filename?: string;
    name?: string;
}

const endpoint = ReduxEndpoint.fromSchema('/api/files/', schemaObj);

const api = {
    index: endpoint.index<IFileSearchOpts>(),
    get: endpoint.get<{ file_id: number }>('{file_id}'),
    create: endpoint.post(),
    save: endpoint.put<{ file_id: number }>('{file_id}'),
    delete: endpoint.delete<{ file_id: number }>('{file_id}'),
    download: endpoint.get<{ file_id: number }, File>(
        '{file_id}/download',
        ReduxEndpoint.PassThroughConfig('blob', false),
    ),

    request_upload: endpoint.post<
        { filename: string; size: number; meta?: any },
        { timeout?: number },
        { put_url: string; get_url: string; seed: string }
    >('request_upload', ReduxEndpoint.PassThroughConfig()),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { S3File, api, endpoint, schemaObj, selectors };
