import { Dispatch } from 'redux';

import * as auth from 'reports/modules/auth';

import * as rep from 'reports/models/report';
import { api, ITeamForm, Team } from 'reports/models/team';

const getFormDataWithAccessibleReports = (formData, userAccessibleReports) => {
    // FormData may have custom reports that user no longer has access to. Filter out these reports and keep the
    // public reports the user has selected for the team.
    const exportSettings = formData.project_export_settings;
    const reportIds = exportSettings && exportSettings.report_ids ? exportSettings.report_ids : [];
    let accessibleReportIds: number[] = [];
    if (userAccessibleReports.length > 0 && exportSettings && reportIds) {
        accessibleReportIds = userAccessibleReports
            .map((report) => report.report_id)
            .filter((reportId) => reportIds.includes(reportId));
    }
    return {
        ...formData.project_export_settings,
        report_ids: accessibleReportIds,
    };
};

const updateTeam = (tm: Team, teamForm: ITeamForm, email?: string) => async (dispatch: Dispatch) => {
    let team;

    if (JSON.stringify(teamForm.project_export_settings) !== JSON.stringify(tm.project_export_settings)) {
        // If the project export settings field is dirty, call the API to update it first so we only use
        // as_user if necessary
        const userReports = await dispatch(rep.api.index({ bookmarked: true, as_user: email }));
        const formDataWithAccessibleReportIds = getFormDataWithAccessibleReports(teamForm, userReports);

        team = await dispatch(
            api.updateProjectExportSettings(formDataWithAccessibleReportIds, {
                team_id: tm.team_id,
                as_user: email || undefined,
            }),
        );
    }

    team = await dispatch(api.save({ ...team, ...teamForm }));

    dispatch(auth.actions.updateCurrentUser());
    return team;
};

export const actions = {
    updateTeam,
};
