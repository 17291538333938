import * as React from 'react';

import { Classes, Colors, NonIdealState, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const NoDragImg = styled.img`
    user-drag: none;
    user-select: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const PlaceholderContainer = styled(NonIdealState)`
    background: ${Colors.LIGHT_GRAY5};
    border: 1px solid ${Colors.LIGHT_GRAY3};
    max-width: 100%;
    padding: 10px 0;

    > :not(:last-child) {
        margin-bottom: 10px;
    }

    .${Classes.ICON} {
        color: ${Colors.LIGHT_GRAY3};
    }

    /* Oddly, blueprint sets font-size to 60px, which screws up the icon's height. */
    .${Classes.NON_IDEAL_STATE_VISUAL} {
        font-size: initial;
    }
`;

const PlaceholderDescription = styled.div`
    color: ${Colors.GRAY1};
    font-size: 12px;
    line-height: 1.2;
`;

interface IImageProps {
    src?: string | null;
    className?: string;
    errorMsg?: string | JSX.Element;
    style?: React.CSSProperties;
    loading?: boolean;
    onError?: (event) => void;
}

export const Image: React.FC<IImageProps> = ({
    src,
    style,
    className,
    errorMsg = 'image not found',
    loading,
    onError,
}) => (
    <CenteredContainer>
        {loading ? (
            <PlaceholderContainer icon={<Spinner />} />
        ) : src != null ? (
            <NoDragImg src={src} className={className} style={style} onError={onError} />
        ) : (
            <PlaceholderContainer
                description={<PlaceholderDescription>{errorMsg}</PlaceholderDescription>}
                icon={IconNames.MEDIA}
            />
        )}
    </CenteredContainer>
);

export default Image;
