/**
 * Report Proposals Custom Text Widget
 */
import * as React from 'react';
import { connect } from 'react-redux';
import AutoComplete, { Completion } from 'react-abstract-autocomplete';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import { Colors } from '@blueprintjs/core';

import { IAppState } from 'reports/types';

import Translations from 'reports/localization/strings';

import { Suggestion, Suggestions } from 'reports/components/helpers/formHelpers';

import { DEFAULT_FONT_FAMILY } from 'reports/styles/global';

import SidebarFormatForm from 'reports/modules/report/components/SidebarFormatForm';
import { selectors as repSelectors } from 'reports/modules/report/index';

import { CustomTextProps, EditCustomTextProps } from './index';
import selectors from './selectors';
import CustomTextForm from './Form';

import * as styles from 'reports/styles/styled-components';

const styled = styles.styled;

const DEFAULT_STYLE: React.CSSProperties = {
    fontFamily: DEFAULT_FONT_FAMILY,
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    textDecoration: 'none',
};

const InputContainer = styled.div`
    height: 100%;
    width: 100%;

    > *,
    > div > span, // Autocomplete injects a span to contain input element
    textarea {
        position: relative;
        height: 100%;
        width: 100%;
    }

    span {
        display: block;
    }

    textarea {
        resize: none;
        text-align: center;
        background: none;

        // Default text styles
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        text-decoration: none;

        &,
        &:focus {
            border: none;
            outline: none;
        }

        &:hover {
            cursor: default;
        }
    }
`;

const TextVal = styled.span`
    font-style: italic;
    color: ${Colors.GRAY4};
`;

type IStateProps = ReturnType<ReturnType<typeof mapStateToProps>>;
type IViewProps = CustomTextProps & IStateProps;
type IEditProps = EditCustomTextProps & IStateProps;

const ViewTextComponent: React.SFC<IViewProps> = (props) => {
    const { compiledStyle, intl } = props;
    // compileText requires the intl prop, which is not available in mapStateToProps.
    const compiledText = selectors.compileText(props);
    const inputProps = {
        placeholder: intl.formatMessage(Translations.widgets.add_text),
        readOnly: true,
        style: { ...DEFAULT_STYLE, ...compiledStyle, overflow: 'hidden' },
    };

    return (
        <InputContainer>
            <AutoComplete inputComponent="textarea" inputProps={inputProps} value={compiledText} />
        </InputContainer>
    );
};

class EditTextComponent extends React.PureComponent<IEditProps, {}> {
    isDirty: boolean = false;

    componentWillUnmount() {
        if (this.isDirty) {
            this.props.updateWidgetContent(this.props.config.content);
            this.isDirty = false;
        }
    }

    render() {
        const inputProps = {
            style: this.props.compiledStyle,
            autoFocus: true,
            placeholder: this.props.intl.formatMessage(Translations.widgets.add_text),
            spellCheck: false,
        };

        return (
            <InputContainer>
                <AutoComplete
                    ref="autocomplete"
                    inputComponent="textarea"
                    inputProps={inputProps}
                    renderSuggestion={this.renderSuggestion}
                    renderSuggestions={(suggestions) => <Suggestions>{suggestions}</Suggestions>}
                    value={this.props.config.content.tokens || ''}
                    onUpdate={this.updateTokens.bind(this)}
                >
                    <Completion
                        ref="completion"
                        trigger="{"
                        completions={this.props.completions}
                        minLength={1}
                        getText={(value, props) => `${props.trigger}${value}}`}
                    />
                </AutoComplete>
                <SidebarFormatForm>
                    <CustomTextForm
                        theme={this.props.context.theme}
                        styleMap={this.props.styleMap}
                        updateStyle={this.updateStyle}
                        style={this.props.compiledStyle}
                    />
                </SidebarFormatForm>
            </InputContainer>
        );
    }

    renderSuggestion = ({ key, select, selected, value }) => {
        const {
            context,
            intl: { locale },
        } = this.props;
        const classes = classNames({ selected });

        return (
            <Suggestion key={key} className={classes} onClick={select}>
                <span>{value}</span>
                <TextVal>{context.tokenMap![value].format({ locale })}</TextVal>
            </Suggestion>
        );
    };

    updateTokens(tokens: string) {
        this.props.updateWidgetContent({
            ...this.props.config.content,
            tokens,
        });
        this.isDirty = true;
    }

    updateStyle = (style: React.CSSProperties) => {
        const { style: currStyle = {} } = this.props.config.content;
        this.props.updateWidgetContent({
            ...this.props.config.content,
            style: { ...currStyle, ...style },
        });
        this.isDirty = true;
    };
}

const mapStateToProps = () => {
    const { getCompletions } = selectors;
    const { compiledStyle, getStyleMap } = repSelectors;

    return (_state: IAppState, ownProps) => ({
        completions: getCompletions(ownProps),
        compiledStyle: compiledStyle(ownProps),
        styleMap: getStyleMap(ownProps),
    });
};

const ViewTextComponentContainer = connect(mapStateToProps)(injectIntl(ViewTextComponent));
const EditTextComponentContainer = connect(mapStateToProps)(injectIntl(EditTextComponent));

export { ViewTextComponentContainer as ViewComponent, EditTextComponentContainer as EditComponent };
