import { Classes } from '@blueprintjs/core';
import Dropzone, { DropzoneProps } from 'react-dropzone';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const DropzoneContainer = styled(Dropzone).attrs<DropzoneProps>((props) => ({
    disablePreview: true,
    multiple: !!props.multiple, // Force prop to be boolean - never undefined.
    acceptClassName: 'file-valid',
    activeClassName: 'drag-active',
    rejectClassName: 'file-invalid',
}))`
    height: 100%;
    width: 100%;

    > div:only-child {
        height: 100%;
        width: 100%;
    }
    &.drag-active,
    &.drag-active table,
    &.file-valid,
    &.file-valid table {
        background: #edf8ff !important;

        .${Classes.ICON} {
            color: #b8dbf5;
        }
    }
    &.file-invalid,
    &.file-invalid table {
        background: #ffe7e7 !important;

        .${Classes.ICON} {
            color: #ffc3c3;
        }
    }
`;

export { DropzoneContainer };
