import * as React from 'react';
import { identity } from 'lodash';
import { Intent } from '@blueprintjs/core';

import { FormGroup, IFormGroupProps } from 'reports/components/core/forms';
import FormField from 'reports/components/forms/FormField';
import FixedItemsSelect, { ISelectItem } from './FixedItemsSelect';

type IFormFixedItemsSelectProps = IFormGroupProps & {
    path: string;
    items: ISelectItem[];
    width?: number;
    nilItemName?: string;
    toRawValue?: (itemsValue: any) => any;
    toItemsValue?: (rawValue: any) => any;
};

/**
 * A simple select box that displays a fixed list of items, meant to exist within a Form that provides a FormContext.
 * The API for this is not fully baked, hence it's in the /experimental dir.
 * @deprecated Use FormBasicSelect
 */
const FormFixedItemsSelect: React.FC<IFormFixedItemsSelectProps> = ({
    path,
    helperText,
    label,
    items,
    inline,
    fill,
    bold,
    width,
    labelWidth,
    nilItemName,
    toRawValue = identity,
    toItemsValue = identity,
}) => (
    <FormField path={path}>
        {({ onChange, value, errors, form }) => (
            <FormGroup
                inline={inline}
                fill={fill}
                bold={bold}
                helperText={errors.length ? errors[0] : helperText}
                label={label}
                labelWidth={labelWidth}
                intent={errors.length ? Intent.DANGER : undefined}
            >
                <FixedItemsSelect
                    items={items}
                    value={value}
                    onChange={onChange}
                    width={width}
                    nilItemName={nilItemName}
                    disabled={form.submitting || !items.length}
                    toRawValue={toRawValue}
                    toItemsValue={toItemsValue}
                />
            </FormGroup>
        )}
    </FormField>
);

export default FormFixedItemsSelect;
