import { useLoaderData, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectors as projectSelectors, Project } from 'reports/models/project';

import { IAppState } from 'reports/store';

type ProjectContext = {
    project: Project;
};

const useProject = (): { project?: Project } => {
    const projectToSelect = useLoaderData() as ProjectContext;

    const project = useSelector((state) => projectSelectors.byObject(state as IAppState, projectToSelect));

    return { project: project ?? undefined };
};

// Intended to be used by BaseSettings's children only.
const useProjectContext = () => useOutletContext() as ProjectContext;

export { ProjectContext, useProject, useProjectContext };
