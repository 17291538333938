import * as Q from 'q';

import { isType } from 'typescript-fsa';

import * as us from 'reports/models/user';
import * as auth from 'reports/modules/auth';
import * as conf from 'reports/config';

import { IRootScopeService, ILocationService } from 'angular';
import { IStateService } from 'angular-ui-router';

export let user: us.User; // eslint-disable-line import/no-mutable-exports
export let helioscopeConfig: conf.IAppConfig; // eslint-disable-line import/no-mutable-exports
export let mapCredentials; // eslint-disable-line import/no-mutable-exports

export function setUser(newUser) {
    user = newUser;
}

export function setConfig(config) {
    helioscopeConfig = config;

    mapCredentials = {
        queryKey: helioscopeConfig.debug ? 'key' : 'client',
        queryVal: helioscopeConfig.debug
            ? helioscopeConfig.google_maps_api_key
            : helioscopeConfig.google_maps_client_id,
    };
}

export const middleware = (_store) => (next) => (action) => {
    const rtn = next(action);

    if (isType(action, auth.api.login.done)) {
        setUser(action.payload.result);
    }

    if (isType(action, conf.actions.initializeApp.done)) {
        setConfig(action.payload.result);
    }

    return rtn;
};

interface ICustomScopeService extends IRootScopeService {
    hideChat: () => void;
    user: () => us.User;
}

function getAngularScope($injector) {
    return {
        $rootScope: $injector.get('$rootScope') as ICustomScopeService,
        $state: $injector.get('$state') as IStateService,
        $location: $injector.get('$location') as ILocationService,
        Authenticator: $injector.get('Authenticator') as any, // see auth.js in Angular for methods
    };
}

export type AngularScope = ReturnType<typeof getAngularScope>;

const deferred = Q.defer<AngularScope>();

// needs to put on window global so that an iframe scope can be referenced by a parent application
window['angularScope'] = deferred.promise;

export function linkAngularScope($injector) {
    deferred.resolve(getAngularScope($injector));
}

export function getIframeScope(iframe: HTMLIFrameElement) {
    return iframe.contentWindow!['angularScope'];
}
