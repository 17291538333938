import { Colors, Dialog, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import styled from 'styled-components';
import { Flex } from 'reports/components/core/containers';

const GROUND_WEATHER_SOURCE_TYPE_LINKS = {
    'NREL TMY3': 'https://nsrdb.nrel.gov/data-sets/tmy',
    'NREL TMY2': 'https://nsrdb.nrel.gov/data-sets/tmy',
    'DOE EPW': 'http://apps1.eere.energy.gov/buildings/energyplus/weatherdata_about.cfm',
    SWERA: 'https://openei.org/wiki/Solar_and_Wind_Energy_Resource_Assessment_(SWERA)',
};

const SATELLITE_WEATHER_SOURCE_TYPE_LINKS = {
    Prospector: 'http://maps.nrel.gov/prospector',
    'PSM v3': 'https://nsrdb.nrel.gov/data-sets/us-data',
};

const SourceTypeAnchor = styled.a`
    background-color: transparent;
    padding: 0 4px;
    :hover &:hover {
        background-color: ${Colors.LIGHT_GRAY2};
    }
`;

const SourceTypeLinkContainer = styled(Flex.ContainerV)`
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

const SourceTypeLinks: React.FC<{
    sourceTypeLinksMap: { [key: string]: string };
}> = ({ sourceTypeLinksMap }) => (
    <SourceTypeLinkContainer>
        {Object.keys(sourceTypeLinksMap).map((srcType) => {
            return (
                <SourceTypeAnchor href={sourceTypeLinksMap[srcType]} target="_blank">
                    <Flex.Container style={{ margin: '4px 0', alignItems: 'center' }}>
                        <Icon icon={IconNames.FLASH} style={{ marginRight: '7px' }} />
                        {srcType}
                    </Flex.Container>
                </SourceTypeAnchor>
            );
        })}
    </SourceTypeLinkContainer>
);

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const WeatherSourcesLearnMoreDialog: React.FC<IProps> = (props) => {
    const { isOpen, onClose } = props;

    return (
        <Dialog
            title="Weather Source Types"
            isOpen={isOpen}
            onClose={onClose}
            style={{ maxWidth: 'min-content', minWidth: '300px' }}
        >
            <Flex.ContainerV style={{ padding: '16px 20px' }}>
                <p>Ground-based Sources</p>
                <SourceTypeLinks sourceTypeLinksMap={GROUND_WEATHER_SOURCE_TYPE_LINKS} />
                <p>Satellite-based Sources</p>
                <SourceTypeLinks sourceTypeLinksMap={SATELLITE_WEATHER_SOURCE_TYPE_LINKS} />
            </Flex.ContainerV>
        </Dialog>
    );
};

export default WeatherSourcesLearnMoreDialog;
