import Logger from 'js-logger';

const logger = Logger.get('components/helpers');


/**
 * split an array into a specified number of groups
 */
export function groupArray(arr, numGroups) {
    const length = arr.length;

    if (length === 0) {
        return [];
    }

    const maxGroupSize = Math.ceil(length / numGroups);
    return batchArray(arr, getAssymetricBatchSizes(length, maxGroupSize, numGroups));
}


/**
 * given an array and an array of batch sizes, segment the array according to
 * the batchSizes
 */
export function batchArray(arr, batchSizes, addRemainderToEnd = false) {
    const rtn = [];
    let cumulativeItems = 0;

    for (const batchSize of batchSizes) {
        rtn.push(arr.slice(cumulativeItems, cumulativeItems + batchSize));
        cumulativeItems += batchSize;
    }

    if (addRemainderToEnd) {
        rtn.push(arr.slice(cumulativeItems));
    } else if (cumulativeItems !== arr.length) {
        logger.warn(`Inconsistent target batch sizes: ${arr.length} items; ${cumulativeItems} total batched`);
    }

    return rtn;
}


/**
 * return an array of batch sizes that distributes all of itemCount into distinct batches
 * assuming a maximum batch size
 */
export function getAssymetricBatchSizes(itemCount, maxBatchSize, minBatchCount = Number.NEGATIVE_INFINITY) {
    const targetBatchCount = Math.max(Math.ceil(itemCount / maxBatchSize), minBatchCount);
    const baseItemsPerBatch = Math.floor(itemCount / targetBatchCount);
    const largeBatches = itemCount - baseItemsPerBatch * targetBatchCount;

    const actualBatchCount = Math.min(itemCount, targetBatchCount);

    const batches = new Array(actualBatchCount);

    for (let i = 0; i < actualBatchCount; i++) {
        batches[i] = baseItemsPerBatch + (i < largeBatches ? 1 : 0);
    }

    return batches;
}

/**
 * return an array of batch sizes that distributes all of itemCount into distinct batches
 * assuming a maximum and minimum batch size
 */
export function getBoundedBatchSizes(itemCount, minSize, maxSize) {
    const rawBatches = getAssymetricBatchSizes(itemCount, maxSize);
    const smallest = _.last(rawBatches); // the last is always the smallest from getAssymetricBatchSizes

    if (smallest >= minSize) {
        return rawBatches;
    }

    let rtn = rawBatches.filter(x => x >= minSize);

    // compute assymetric batch sizes will always try to distribute the array
    // as evenly as possible, so there will be two sizes, a large size, and a
    // small size, exactly one apart.

    let toAllocate = itemCount - _.sum(rtn);
    const newSmallBatches = Math.floor(toAllocate / minSize);
    if (newSmallBatches > 0) {
        // add small batches to the end of the array
        rtn = rtn.concat(_.times(newSmallBatches, _.constant(minSize)));
    }

    toAllocate -= newSmallBatches * minSize;

    const batchCount = rtn.length;
    const maxBatchIncrement = Math.ceil(toAllocate / batchCount);
    const reallocation = getAssymetricBatchSizes(toAllocate, maxBatchIncrement);

    // the reallocation will always be smaller than the batch;
    // by going backwards through the batch sizes we match the largest
    // reallocations with the smallest batches
    for (let i = 0; i < reallocation.length; i += 1) {
        rtn[batchCount - i - 1] = Math.min(maxSize, rtn[batchCount - i - 1] + reallocation[i]);
    }

    return rtn;
}

function shouldIncludeComponent(component, typesToIncludeOrFilterFunc) {
    if (!typesToIncludeOrFilterFunc) {
        return true;
    }
    if (typesToIncludeOrFilterFunc instanceof Function) {
        return typesToIncludeOrFilterFunc(component);
    }
    return typesToIncludeOrFilterFunc[component.component_type];
}

/**
 * convert component tree to a list of components, optionally filtering
 * components by typesToIncludeOrFilterFunc (a dictionary or filterFunc).
 * If typesToIncludeOrFilterFunc is not provided, include all components.
 * If typesToIncludeOrFilterFunc is provided and:
 * - is a function  : include only if typesToIncludeOrFilterFunc(component) returns true
 * - is a dictionary: include only if typesToInclude[component_type] is true
 */
export function flattenComponentTree(componentTree, typesToIncludeOrFilterFunc) {
    const rtn = [];
    if (!componentTree || componentTree.length === 0) {
        return rtn;
    }

    const toProcess = [componentTree];
    let idx = 0;
    while (idx < toProcess.length) {
        const tree = toProcess[idx++];
        for (let i = 0; i < tree.length; i++) {
            const component = tree[i];
            const toInclude = shouldIncludeComponent(component, typesToIncludeOrFilterFunc);
            if (toInclude) {
                rtn.push(component);
            }

            if (component.getChildren) {
                const children = component.getChildren();
                toProcess.push(children);
            }
        }
    }
    return rtn;
}

/**
 * object to keep manually repositioned components from moving as the wiring
 * tree is regenerated by the user.
 *
 * Stores component locations (for a specified list of types) from a given
 * wiring tree and then can apply the cache to a new component tree.  If a
 * given location/type tuple is cached, it can be applied to a new component
 * exactly once (this prevents several components from all being pushed to
 * the same location)
 *
 * Note: applying the cache is a one-time operation because it will remove
 * locations from the cache as they are applied.
 */
export class ComponentLocationCache {
    static defaultTypesToInclude = {
        ac_panel: true,
        inverter: true,
        combiner: true,
    };

    constructor(componentTree, typesToInclude = ComponentLocationCache.defaultTypesToInclude) {
        this.typesToInclude = typesToInclude;
        this._components = flattenComponentTree(componentTree, this.typesToInclude);
        this._cache = ComponentLocationCache.buildCache(this._components, typesToInclude);
    }

    /**
     * iterate through a new component tree, applying the location cache to any
     * matched components.  This mutates the existing cache, removing any
     * matched locations when they are applied to components.
     */
    apply(newComponentTree) {
        const flatComponents = flattenComponentTree(newComponentTree, this.typesToInclude);

        for (const component of flatComponents) {
            const nearestLoc = this.popLocation(component.location, component.component_type);

            if (nearestLoc) {
                component.setLocation(nearestLoc);
            }
        }
    }

    /**
     * check the location cache for the nearest location for  a given type of
     * component.  If the nearest location is within the cutoff return that
     * location and remove it from the cache
     */
    popLocation(location, type, cutoff = 5) {
        const locationCache = this._cache[type] || [];
        let best = { distance: cutoff };

        for (const entry of locationCache) {
            const { algoLocation, userLocation } = entry;
            const distance = location.distance(algoLocation);

            if (distance < best.distance) {
                best = { distance, location: userLocation, entry };
            }
        }

        if (best.entry !== undefined) {
            const idx = locationCache.indexOf(best.entry);
            locationCache.splice(idx, 1);
        }

        return best.location;
    }

    static componentLocationMap(components) {
        const locations = new Map();

        for (const component of components) {
            locations.set(component, component.location);
        }

        return locations;
    }

    /**
     * generate a cache for each component type including the user and
     * algorithmic location
     */
    static buildCache(components, typesToInclude) {
        const cache = {};
        for (const type of Object.keys(typesToInclude)) {
            cache[type] = [];
        }

        const userLocations = this.componentLocationMap(components);

        // reverse the tree to go from the bottom up, because second tier combiners
        // depend on the lower levels
        for (const component of components.reverse()) {
            const { component_type: type } = component;
            const userLocation = userLocations.get(component);

            // reset the component to where the algorithm would have put it
            component.setLocation();

            cache[type].push({ algoLocation: component.location, userLocation });
        }

        return cache;
    }
}
