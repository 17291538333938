/* tslint:disable:variable-name */
import * as lodash from 'lodash';
import * as React from 'react';

import * as fmt from 'reports/utils/formatters';

import { IParamTableDefinition, IParamProps, ParamValueType } from '../params';

interface IState {
    editCell?: any;
    dragStartRow?: any;
    dragStartCol?: any;
    dragRow?: any;
    dragCol?: any;
}

export interface IGridConfig extends IParamTableDefinition<number> {
    type: ParamValueType.Grid;
    columns: string[];
    cell_type: string;
    max_value: number;
    min_value: number;
    rows: string[];
    cell_height?: number;
    cell_width?: number;
    row_header_width?: number;
}

type IOwnProps = IParamProps<IGridConfig> & {
    cellStyler?: (x: number, y: number, value: any) => React.CSSProperties;
};

export class ParamGrid extends React.Component<IOwnProps, IState> {
    cellDupStartIdx: any;
    gridRef: HTMLElement | null;
    scrollRef: HTMLElement | null;
    rowHeaderWidth: number;
    scrollTimer: any;

    constructor(props) {
        super(props);

        this.state = { dragRow: null, dragCol: null };

        this.cellDupStartIdx = null;
    }

    componentWillUnmount() {
        this.setScrollTimer(null);
    }

    render() {
        const { parameter, value, cellStyler } = this.props;

        const { rows, columns } = parameter;
        const cellWidth = parameter.cell_width || 80;
        const rowHeight = parameter.cell_height || 24;
        const rowHeaderWidth = parameter.row_header_width || 80;

        this.rowHeaderWidth = rowHeaderWidth;

        const rowHeaders = rows.map((row, idx) => (
            <div
                key={idx}
                className="row-header"
                style={{
                    height: `${rowHeight}px`,
                    width: `${rowHeaderWidth}px`,
                }}
            >
                <div className="text">{row}</div>
            </div>
        ));

        const gridRows = rows.map((_, j) => {
            const cells = columns.map((_, i) => {
                const cellValue = value[j * columns.length + i];

                const style = {};
                if (cellStyler) {
                    lodash.assign(style, cellStyler(j, i, cellValue));
                }
                lodash.assign(style, {
                    width: `${cellWidth}px`,
                    height: `${rowHeight}px`,
                });

                return (
                    <div
                        key={i}
                        id={`grid_${j}_${i}`}
                        className="row-cell"
                        style={style}
                        onClick={(evt) => this.onCellClick(evt.currentTarget, j, i)}
                        onMouseDown={(evt) => this.onCellDown(evt.currentTarget, j, i)}
                    >
                        <div className="text" style={{ width: '100%' }}>
                            {cellValue}
                        </div>
                    </div>
                );
            });

            return (
                <div key={j} className="grid-row" style={{ height: `${rowHeight}px`, whiteSpace: 'nowrap' }}>
                    <div
                        style={{
                            width: `${rowHeaderWidth - 1}px`,
                            display: 'inline-block',
                        }}
                    ></div>
                    {cells}
                </div>
            );
        });

        const columnHeaders = columns.map((column, idx) => (
            <div key={idx} className="column-header" style={{ width: `${cellWidth}px`, height: `${rowHeight}px` }}>
                <div className="text">{column}</div>
            </div>
        ));

        let editElement = null as any;

        if (this.state.editCell) {
            const { row, col } = this.state.editCell;
            const idx = row * columns.length + col;

            editElement = (
                <div
                    style={{
                        position: 'absolute',
                        left: `${rowHeaderWidth + cellWidth * col}px`,
                        top: `${(row + 1) * rowHeight}px`,
                        width: `${cellWidth - 1}px`,
                        height: `${rowHeight - 1}px`,
                        pointerEvents: 'all',
                    }}
                >
                    <input
                        ref={(ele) => {
                            if (ele) ele.focus();
                        }}
                        onFocus={(evt) => evt.currentTarget.select()}
                        onBlur={(evt) => this.endEdit(idx, evt.currentTarget.value)}
                        onKeyDown={(evt) => {
                            if (evt.keyCode === 13) evt.currentTarget.blur();
                        }}
                        type="text"
                        className="edit-cell"
                        style={{ width: '100%', height: '100%' }}
                        defaultValue={`${value[idx]}`}
                    />
                </div>
            );
        } else if (this.state.dragRow !== null) {
            const { dragStartRow, dragStartCol, dragRow, dragCol } = this.state;

            if (dragRow !== dragStartRow || dragCol !== dragStartCol) {
                const cleft = Math.min(dragStartCol, dragCol);
                const cright = Math.max(dragStartCol, dragCol);
                const ctop = Math.min(dragStartRow, dragRow);
                const cbottom = Math.max(dragStartRow, dragRow);

                const left = rowHeaderWidth + cellWidth * cleft;
                const top = (ctop + 1) * rowHeight;
                const right = rowHeaderWidth + cellWidth * (cright + 1) - 1;
                const bottom = (cbottom + 2) * rowHeight - 1;

                editElement = (
                    <div
                        style={{
                            position: 'absolute',
                            left: `${left}px`,
                            top: `${top}px`,
                            width: `${right - left}px`,
                            height: `${bottom - top}px`,
                            background: '#c8d8fa',
                            opacity: 0.42,
                        }}
                    />
                );
            }
        }

        const overlay = (
            <div
                className="grid-overlay"
                style={{
                    position: 'absolute',
                    zIndex: 5,
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    pointerEvents: 'none',
                }}
            >
                {editElement}
            </div>
        );

        return (
            <div
                className="financials-grid"
                style={{
                    position: 'relative',
                    border: '1px solid #aaa',
                    display: 'inline-grid',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        zIndex: 9,
                    }}
                >
                    <div
                        className="row-header"
                        style={{
                            height: `${rowHeight}px`,
                            width: `${rowHeaderWidth}px`,
                        }}
                    ></div>
                    {rowHeaders}
                </div>
                <div
                    ref={(ele) => {
                        this.scrollRef = ele;
                    }}
                    style={{ overflow: 'auto' }}
                >
                    <div
                        ref={(ele) => {
                            this.gridRef = ele;
                        }}
                        style={{
                            position: 'relative',
                            width: `${columns.length * cellWidth + rowHeaderWidth - 1}px`,
                            height: `${(gridRows.length + 1) * rowHeight}px`,
                            overflowY: 'hidden',
                        }}
                    >
                        {overlay}
                        <div
                            className="grid-row"
                            style={{
                                height: `${rowHeight}px`,
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <div
                                style={{
                                    width: `${rowHeaderWidth - 1}px`,
                                    display: 'inline-block',
                                }}
                            ></div>
                            {columnHeaders}
                        </div>
                        {gridRows}
                    </div>
                </div>
            </div>
        );
    }

    onCellClick(target, row, col) {
        if (this.props.disabled) return;
        this.setState({ editCell: { target, row, col } });
    }

    onCellDown(_target, row, col) {
        if (this.props.disabled) return;
        this.setState({ dragStartRow: row, dragStartCol: col });

        const upFn = () => {
            this.setScrollTimer(null);

            const { dragStartRow, dragStartCol, dragRow, dragCol } = this.state;
            if (dragRow !== null) {
                this.copyCells(dragStartRow, dragStartCol, dragRow, dragCol);
            }

            removeFn();
            this.setState({
                dragStartRow: null,
                dragStartCol: null,
                dragRow: null,
                dragCol: null,
            });
        };

        const scrollFn = (evt) => {
            const scrollRect = this.scrollRef!.getBoundingClientRect();
            if (evt.clientX > scrollRect.right) {
                this.setScrollTimer(() => {
                    this.scrollRef!.scrollLeft += 25;
                });
                return true;
            }

            if (evt.clientX < scrollRect.left + this.rowHeaderWidth) {
                this.setScrollTimer(() => {
                    this.scrollRef!.scrollLeft -= 25;
                });
                return true;
            }

            this.setScrollTimer(null);
            return false;
        };

        const moveFn = (evt) => {
            if (scrollFn(evt)) return;

            const { target } = evt;

            let id = null as any;
            let gridCheck = target;
            while (gridCheck && this.gridRef !== gridCheck) {
                if (gridCheck.id.substring(0, 4) === 'grid') id = gridCheck.id;
                gridCheck = gridCheck.parentElement;
            }

            if (id) {
                id = (id as string).split('_');
                const crow = parseInt(id[1], 10);
                const ccol = parseInt(id[2], 10);

                this.setState({ dragRow: crow, dragCol: ccol });
            }
        };

        const removeFn = () => {
            document.removeEventListener('mouseup', upFn, true);
            document.removeEventListener('mousemove', moveFn, true);
        };

        document.addEventListener('mouseup', upFn, true);
        document.addEventListener('mousemove', moveFn, true);
    }

    endEdit(index, text) {
        this.setState({ editCell: null });

        const { value, parameter, updateFn } = this.props;
        if (!updateFn) return;

        let val = fmt.numberStringToFloat(text);
        if (parameter.cell_type === 'percentage') {
            val = fmt.numberStringToFloat(text) * 0.01;
        } else if (parameter.cell_type === 'integer') {
            val = fmt.numberStringToInt(text);
        }

        if (isNaN(val)) return;

        const newValue = value.slice();
        const constrained = Math.max(Math.min(val, parameter.max_value), parameter.min_value);
        newValue[index] = constrained;
        updateFn(newValue);
    }

    copyCells(startRow, startCol, endRow, endCol) {
        if (startRow === endRow && startCol === endCol) return;

        const { value, parameter, updateFn } = this.props;
        if (!updateFn) return;

        const stride = parameter.columns.length;
        const cellValue = value[startRow * stride + startCol];

        const cleft = Math.min(startCol, endCol);
        const cright = Math.max(startCol, endCol);
        const ctop = Math.min(startRow, endRow);
        const cbottom = Math.max(startRow, endRow);

        const newValue = value.slice();

        for (let i = ctop; i <= cbottom; i += 1) {
            for (let j = cleft; j <= cright; j += 1) {
                newValue[i * stride + j] = cellValue;
            }
        }

        updateFn(newValue);
    }

    setScrollTimer(fn) {
        if (this.scrollTimer) clearInterval(this.scrollTimer);
        this.scrollTimer = null;

        if (fn) this.scrollTimer = setInterval(fn, 50);
    }
}

export default ParamGrid;
