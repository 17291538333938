import SnapIndex from './SnapIndex';
import { IWidgetConfig } from 'reports/modules/report/widgets';
export const Component = 'COMPONENT';

export type HandlePosition =
    | 'TopLeft'
    | 'TopMiddle'
    | 'TopRight'
    | 'MiddleLeft'
    | 'MiddleRight'
    | 'BottomLeft'
    | 'BottomMiddle'
    | 'BottomRight';

export const HandlePositions: { [k in HandlePosition]: string } = {
    TopLeft: 'TOP_LEFT',
    TopMiddle: 'TOP_MIDDLE',
    TopRight: 'TOP_RIGHT',
    MiddleLeft: 'MIDDLE_LEFT',
    MiddleRight: 'MIDDLE_RIGHT',
    BottomLeft: 'BOTTOM_LEFT',
    BottomMiddle: 'BOTTOM_MIDDLE',
    BottomRight: 'BOTTOM_RIGHT',
};

export interface IPoint {
    x: number;
    y: number;
}

export interface ILayout {
    x: number;
    y: number;
    w: number;
    h: number;
    z?: number;
}

export interface ILayoutContext {
    onChange: ILayoutCallback;
    scale: number;
    snapIndex: SnapIndex;
    regionKey: string | number;
}

export interface ILayoutItemMeta {
    widgetId: string;
    config: IWidgetConfig;
    layoutDelta?: Partial<ILayout>;
}

export interface IDragItem {
    itemMeta: ILayoutItemMeta;
    layout: ILayout;
    scale: number;
    snapIndex: SnapIndex;
    previewHTML?: string;
    regionKey: string | number;
}

export interface ILayoutCallback {
    (result: IDragItem): void;
}

export interface ILayoutFileCallback {
    (args: { files: File[]; position: { x: number; y: number } }): void;
}
