import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import moment from 'moment';

import { Section2 } from 'reports/components/core/containers';
import { ContractForm } from 'reports/modules/settings/admin/components/ContractForm';
import { promptModalBoolean } from 'reports/components/dialog';
import { SectionTitleWithTag } from 'reports/modules/settings/admin/components/SectionTitleWithTag';
import { ContractConversionButton } from 'reports/modules/settings/admin/components/ContractConversionButton';
import { addPromiseToasts } from 'reports/modules/Toaster';

import * as sub from 'reports/models/subscription';
import { api as tmApi } from 'reports/models/team';
import { User } from 'reports/models/user';

interface Props {
    createContract: boolean;
    subscription?: sub.Subscription;
    user: User;
}

const ContractView = ({ createContract, subscription, user }: Props) => {
    // If the subscription is an expired contract and the HS admin has clicked the button to create a new plan, they
    // should be in the create new contract mode.
    const isExpiredContract = !!subscription && subscription.is_contract && subscription.status === 'canceled';
    const isCreateContract = !subscription || subscription.is_self_serve || (isExpiredContract && createContract);

    const dispatch = useDispatch();

    const createContractSubscription = (formData: sub.ContractSubscriptionForm) =>
        dispatch(sub.contractSubscriptionApi.create({ ...formData }, { email: user.email }));

    const updateContractSubscription = (formData: sub.ContractSubscriptionForm) =>
        dispatch(
            sub.contractSubscriptionApi.save(
                {
                    interval: formData.interval,
                    invoice_term_days: formData.invoice_term_days,
                    paid_seats: formData.paid_seats,
                    project_limit: formData.project_limit || undefined,
                    total_contract_value: formData.total_contract_value || undefined,
                    current_period_start: formData.current_period_start,
                    current_period_end: formData.current_period_end,
                    external_id: subscription?.external_id,
                    enable_self_serve: formData.enable_self_serve,
                },
                { email: user.email },
            ),
        );
    const refreshTeam = () => dispatch(tmApi.get({ team_id: user.team_id }));

    const clearRouteParams = () =>
        dispatch(routerActions.navigateTo('app.settings.hs-admin.subscription', { email: user.email }));

    const onSubmitCreate = async (formData: sub.ContractSubscriptionForm) => {
        if (subscription && !isExpiredContract) {
            const cancelSubscription = await promptModalBoolean({
                title: 'Cancel Existing Subscriptions?',
                prompt: `When you activate a Custom plan, the existing subscription will be automatically cancelled.
                Please note that this cancellation applies to the subscription with external ID:
                ${subscription.external_id}`,
                yesLabel: 'Confirm',
                cancellable: true,
            });
            if (!cancelSubscription) {
                return;
            }
        }
        await addPromiseToasts(createContractSubscription(formData), {
            initial: 'Creating custom plan subscription for team...',
            onSuccess: 'Successfully created custom plan subscription for team.',
            onCatch: (err) => {
                if (err.response && err.response.body && err.response.body.error) {
                    return `Error creating custom plan subscription for team: ${err.response.body.error}.`;
                }
                return 'Error creating custom plan subscription for team.';
            },
        });
        await refreshTeam();
        clearRouteParams();
    };

    const onSubmitUpdate = async (formData: sub.ContractSubscriptionForm) => {
        if (formData.current_period_end < moment.utc() && !isExpiredContract) {
            const expireSubscription = await promptModalBoolean({
                title: 'Expire custom plan subscription?',
                prompt: 'The custom plan subscription will expire immediately and the team will lose access to HelioScope.',
                yesLabel: 'Confirm',
                cancellable: true,
            });
            if (!expireSubscription) {
                return;
            }
        }
        await addPromiseToasts(updateContractSubscription(formData), {
            initial: 'Updating custom plan subscription for team...',
            onSuccess: 'Successfully updated custom plan subscription for team.',
            onCatch: 'Error updating custom plan subscription for team.',
        });
        await refreshTeam();
    };

    const onSubmit = async (formData: sub.ContractSubscriptionForm) => {
        if (isCreateContract) {
            await onSubmitCreate(formData);
        } else {
            await onSubmitUpdate(formData);
        }
    };

    return (
        <Section2
            title={<SectionTitleWithTag noTag={isCreateContract} subscription={subscription} title="Custom Plan" />}
            contextEl={
                isExpiredContract && !isCreateContract ? <ContractConversionButton userEmail={user.email} /> : null
            }
        >
            <ContractForm
                isCreateContract={isCreateContract}
                onSubmit={onSubmit}
                subscription={subscription}
                user={user}
            />
        </Section2>
    );
};

export default ContractView;
