import * as React from 'react';

// @ts-ignore
import { Text, Button, ButtonGroup } from '@aurorasolar/ds';
import { goToLogin, goToSignup } from './common';

export const AccountCreationExpiredLinkMessage = () => {
    return (
        <>
            <Text text="body14">
                Unfortunately account creation links expire after 72 hours. Contact your HelioScope administrator to get
                a new link.
            </Text>
            <Button disabled fullWidth size="md" bg="gray-200" color="gray-500" variant="tertiary">
                Reset link expired
            </Button>
            <ButtonGroup justifyContent="space-between">
                <Button size="md" variant="text" action={goToLogin}>
                    Back to login
                </Button>
                <Button size="md" variant="text" action={goToSignup}>
                    Sign up
                </Button>
            </ButtonGroup>
        </>
    );
};

export default AccountCreationExpiredLinkMessage;
