import * as React from 'react';

import { useDispatch } from 'react-redux';

import { styled } from 'reports/styles/styled-components';
import { Button, ButtonProps } from '@blueprintjs/core';

import { api as CustomerAPI } from 'reports/models/stripe/stripe_customer';
import { Subscription } from 'reports/models/subscription';

const StyledButton = styled(Button)`
    margin-top: 5px;
    text-align: right;
    max-width: 200px;
    max-height: 20px;
`;

const StyledLink = styled.a`
    margin-top: 5px;
`;

const stripePath = (portalName: string, subscription: Subscription) =>
    ({
        subscription: `subscriptions/${subscription.stripe_id}/update`,
        // Send users to the portal homepage to update the payment method attached to their Customer.
        paymentMethod: '',
        billingInfo: 'customer/update',
    }[portalName]);

type Props = {
    asLink: boolean;
    openNewTab?: boolean;
    name: 'billingInfo' | 'paymentMethod' | 'billingInfo' | 'subscription';
    subscription: Subscription;
};

const StripePortalButton = ({
    asLink = false,
    openNewTab = false,
    name,
    subscription,
    text,
    icon,
    ...props
}: ButtonProps & Props) => {
    const dispatch = useDispatch();
    const createStripePortalSession = ({ hs_subscription_external_id }: { hs_subscription_external_id: string }) =>
        dispatch(CustomerAPI.createStripePortalSession({ hs_subscription_external_id }));

    const onClick = async () => {
        const { stripe_portal_session }: any = await createStripePortalSession({
            hs_subscription_external_id: subscription.external_id,
        });

        const path = `${stripe_portal_session.url}/${stripePath(name, subscription)}`;
        if (openNewTab) {
            window.open(path, '_blank');
        } else {
            window.location.href = path;
        }
    };

    return asLink ? (
        <>
            {' '}
            <StyledLink onClick={onClick}>
                {text}
                {icon && <> {icon}</>}
            </StyledLink>
        </>
    ) : (
        <StyledButton onClick={onClick} text={text} {...props} />
    );
};

export { StripePortalButton };
