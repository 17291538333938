import { at, get } from 'lodash';
import { Action } from 'redux';

import { IAppState } from 'reports/types';
import * as analytics from 'reports/analytics/analytics';

export let currentRoute: object;
export function setCurrentRoute(route) {
    currentRoute = route;
}

interface IAnalytic {
    eventType: analytics.EventType;
    payload: analytics.ITrackFields | analytics.IIdentifyFields;
}

type IActionHandler = (getState: () => IAppState, action: Action) => IAnalytic;

interface ISubscription {
    subscribed: boolean;
    handler: IActionHandler;
}

interface IAnalyticsOptions {
    subscriptions: { [key: string]: ISubscription };
    defaultHandler: IActionHandler;
}

export function analyticsTracker(options: IAnalyticsOptions) {
    return (store) => (next) => (action) => handleAction(store.getState, options, next, action);
}

function handleAction(getState, options: IAnalyticsOptions, next, action) {
    const subscription = get(options, `subscriptions.${action.type}`, null);
    if (subscription != null && subscription.subscribed) {
        const handler = subscription.handler != null ? subscription.handler : defaultHandler;
        const analytic = handler(getState, action);
        logAction(analytic);
    }
    return next(action);
}

function getFields(eventType, payload) {
    return at(payload, analytics.schemas[eventType]) as any[];
}

function logAction(analytic: IAnalytic) {
    const { eventType, payload } = analytic;
    const fields = getFields(eventType, payload);
    const client = analytics.getSegmentClient();

    if (client != null) {
        client[eventType](...fields);
    }
}

export function defaultHandler(_getState, action): IAnalytic {
    return {
        eventType: analytics.EventType.TRACK,
        payload: {
            event: action.type,
        },
    };
}
