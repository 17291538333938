import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'reports/store';
import { ProfilesParameter, Section2 } from 'reports/modules/profile/components/common';

import * as pro from 'reports/models/profile';
import * as auth from 'reports/modules/auth';
import { UnstyledLink } from 'reports/components/core/controls';

interface IOwnProps {
    profile: pro.ProjectProfile;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

interface ProfileDisplayProps {
    label: string;
    helperText: string;
    profile?: pro.Profile;
}

const ProfileDisplay: React.FC<ProfileDisplayProps> = ({ label, helperText, profile }) => {
    return (
        <div style={{ marginBottom: '16px' }}>
            <ProfilesParameter label={label} helperText={helperText}>
                {profile ? (
                    <UnstyledLink
                        routeName="app.profiles.profile.preview"
                        routeParams={{ profileId: profile.profile_id }}
                    >
                        <a>{profile.toString()}</a>
                    </UnstyledLink>
                ) : (
                    'None selected'
                )}
            </ProfilesParameter>
        </div>
    );
};

class ProjectProfileContents extends React.PureComponent<IProps> {
    render() {
        const { profile, user } = this.props;
        const { electrical_profile, mechanical_profile, financial_profile, scenario_profile } = profile;
        const canViewFinancials = user.hasFinancialsAccess();
        return (
            <>
                <Section2 title="Profiles">
                    <ProfileDisplay
                        label="Mechanical Profile"
                        helperText="Mechanical profiles define the module layout and racking assumptions used when creating new Field Segments for a given project."
                        profile={mechanical_profile}
                    />
                    <ProfileDisplay
                        label="Electrical Profile"
                        helperText="Electrical profiles define the inverter, wiring, combiner boxes and stringing used when creating new Wiring Zones."
                        profile={electrical_profile}
                    />
                    <ProfileDisplay
                        label="Conditions Profile"
                        helperText="Condition profiles define the energy calculations to be used by default when creating a Condition Set to run energy simulations."
                        profile={scenario_profile}
                    />
                    {canViewFinancials && (
                        <ProfileDisplay
                            label="Financial Profile"
                            helperText="Financial profiles define the financial model and set of incentives to be used by default when calculating a project's financial metrics."
                            profile={financial_profile}
                        />
                    )}
                </Section2>
            </>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

export default connect(mapStateToProps)(ProjectProfileContents);
