import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { ReportConsumer } from '../context';

const SidebarFormatForm: React.FC<{}> = (props) => (
    <ReportConsumer>
        {({ sidebarFormatFormRef }) =>
            sidebarFormatFormRef && sidebarFormatFormRef.current
                ? ReactDOM.createPortal(props.children, sidebarFormatFormRef.current)
                : null
        }
    </ReportConsumer>
);

export default SidebarFormatForm;
