/*
 * Suggested component hierarchy:
 *    <Container>
 *        <Header title={...} controls={<Controls>...</Controls>}
 *        <Content>
 *          ...content
 */

import * as React from 'react';

import { Classes, Colors as bpColors, Icon } from '@blueprintjs/core';

import Flex from 'reports/components/core/containers/Flex';

import Colors from 'reports/styles/colors';
import * as styles from 'reports/styles/styled-components';
import { IconNames } from '@blueprintjs/icons';
const styled = styles.styled;

const HeaderContainer = styled(Flex.Container)`
    font-weight: 700;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    color: ${bpColors.DARK_GRAY3};

    justify-content: space-between;
`;

const Title = styled.div`
    flex: initial;
`;

const Container = styled(Flex.ContainerV)<{ minimal?: boolean }>`
    position: relative;
    margin: 6px 4px;
    border-radius: 2px;
    overflow: hidden;

    ${(props) => !props.minimal && 'box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05)'};
    ${(props) => !props.minimal && `border: 1px solid ${bpColors.LIGHT_GRAY1}`};

    flex: 1;
    flex-basis: 80%;

    & > div {
        padding: 6px;
    }

    ${HeaderContainer} {
        height: ${(props) => (props.minimal ? '30px' : '40px')};
        ${(props) => !props.minimal && 'background-color: #F8F8F8'};
        ${(props) => props.minimal && 'border-bottom: 1px solid #F2F2F2'};
    }
`;

const Controls = styled(Flex.Container).attrs({
    className: 'center',
})`
    justify-content: flex-end;
`;

const Content = styled.div`
    background-color: #ffffff;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
`;

const ErrorContainer = styled.div`
    background-color: ${Colors.BG_INTENT_WARNING};
    padding: 20px 30px;
`;

const ErrorHeader = styled(Flex.Container).attrs({
    alignV: Flex.AlignV.CENTER,
})`
    color: ${Colors.INTENT_WARNING};
    font-size: 16px;
    font-weight: 600;

    .${Classes.ICON} {
        padding-right: 8px;
        margin-top: -2px;
    }
`;

const ErrorContent = styled.div`
    padding: 6px 20px;
`;

interface IErrorProps {
    name: string;
    message: string | JSX.Element;
}

const Error: React.SFC<IErrorProps> = ({ name, message }) => (
    <ErrorContainer>
        <ErrorHeader>
            <Icon icon={IconNames.WARNING_SIGN} iconSize={16} />
            {name}
        </ErrorHeader>
        <ErrorContent>{message}</ErrorContent>
    </ErrorContainer>
);

interface IHeaderProps {
    title: string | React.ReactNode;
    controls?: React.ReactNode;
}

const Header: React.SFC<IHeaderProps> = ({ title, controls }) => (
    <HeaderContainer>
        <Title>{title}</Title>
        {controls}
    </HeaderContainer>
);

export default {
    Header,
    Container,
    Content,
    Controls,
    Error,
};
