import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLoaderData, useNavigate } from 'react-router';
import { FinancialTemplate, selectors as finTemplateSelectors } from 'reports/models/financial_template';
import { FinancialTemplateEditor } from 'reports/modules/financials/components/financial_template';
import { IAppState } from 'reports/types';

const FinancialModelEditor = () => {
    const navigate = useNavigate();
    const navigateToFinTemplatePreview = (finTemplateId) => {
        navigate({
            pathname: generatePath('/financial-models/:finTemplateId/preview', {
                finTemplateId: finTemplateId.toString(),
            }),
        });
    };

    const finTemplateToSelect = useLoaderData() as FinancialTemplate;
    const finTemplate = useSelector((state) =>
        finTemplateSelectors.byObject(state as IAppState, {
            financial_template_id: finTemplateToSelect.financial_template_id,
        }),
    );

    if (!finTemplate) {
        throw new Response('Not Found', { status: 404 });
    }

    return (
        <FinancialTemplateEditor
            inlineControls={true}
            navigateToFinTemplatePreview={navigateToFinTemplatePreview}
            template={finTemplate}
        />
    );
};

export { FinancialModelEditor };
