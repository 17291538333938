import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { truthyZero } from 'helioscope/app/utilities/helpers';
import * as fmt from 'reports/utils/formatters';

import FormattedInput from 'reports/components/helpers/FormattedInput';

import { IParameterDefinition, IParamProps, NumericParamType } from 'reports/modules/financials/params';

export interface INumericConfig extends IParameterDefinition<number> {
    type: NumericParamType;
    default: number;
    max_value: number;
    min_value: number;
    precision?: number;
}

class _ParamNumeric extends React.Component<IParamProps<INumericConfig> & { intl: IntlShape }> {
    render() {
        const {
            parameter,
            value,
            updateFn,
            disabled,
            intl: { locale },
        } = this.props;

        const defaultPrecision = 2;
        const precision = truthyZero(parameter.precision) ? parameter.precision : defaultPrecision;

        let editor = null as any;
        if (parameter.type === 'currency') {
            editor = (
                <FormattedInput
                    value={value}
                    onConfirm={(val: number) => this.verifyParameter(val, updateFn)}
                    textToValue={fmt.numberStringToFloat}
                    valueToDisplayText={(val) => fmt.currency(val, { precision, locale })}
                    valueToEditText={(val) => fmt.stringifyNumberSimple(val, precision)}
                    selectAllOnFocus={true}
                    disabled={disabled}
                    fill={true}
                />
            );
        } else if (parameter.type === 'percentage') {
            editor = (
                <FormattedInput
                    value={value}
                    onConfirm={(val: number) => this.verifyParameter(val, updateFn)}
                    textToValue={(val) => fmt.numberStringToFloat(val) * 0.01}
                    valueToDisplayText={(val) => fmt.stringifyNumberSimple(val * 100.0, precision) + '%'}
                    valueToEditText={(val) => fmt.stringifyNumberSimple(val * 100.0, precision)}
                    selectAllOnFocus={true}
                    disabled={disabled}
                    fill={true}
                />
            );
        } else if (parameter.type === 'integer') {
            editor = (
                <FormattedInput
                    value={value}
                    onConfirm={(val: number) => this.verifyParameter(val, updateFn)}
                    textToValue={fmt.numberStringToInt}
                    valueToDisplayText={(val) => val.toString()}
                    valueToEditText={(val) => val.toString()}
                    selectAllOnFocus={true}
                    disabled={disabled}
                    fill={true}
                />
            );
        } else if (parameter.type === 'float') {
            editor = (
                <FormattedInput
                    value={value}
                    onConfirm={(val: number) => this.verifyParameter(val, updateFn)}
                    textToValue={fmt.numberStringToFloat}
                    valueToDisplayText={(val) => fmt.stringifyNumberSimple(val, precision)}
                    valueToEditText={(val) => fmt.stringifyNumberSimple(val, precision)}
                    selectAllOnFocus={true}
                    disabled={disabled}
                    fill={true}
                />
            );
        }

        return editor;
    }

    verifyParameter(val, fn) {
        if (isNaN(val)) return;

        const { parameter } = this.props;
        const constrained = Math.max(Math.min(val, parameter.max_value), parameter.min_value);

        if (fn) fn(constrained);
    }
}

export const ParamNumeric = injectIntl(_ParamNumeric);
export default ParamNumeric;
