import * as React from 'react';

import UserDistanceInput from 'reports/components/forms/inputs/experimental/UserDistanceInput';
import { withForm } from 'reports/components/forms/inputs/experimental/common';
import { IFormGroupProps } from 'reports/components/core/forms';

type IOwnProps = IFormGroupProps & {
    placeholder?: string;
    precision?: number;

    value: number;
    onChange: (v: number) => any;
    disabled: boolean;
};

/**
 * A numeric input that shows units on the right side of the input.
 * An example of this input used in Forms is in MechanicalProfileEditor.tsx
 * The API for this is not fully baked, hence it's in the /experimental dir.
 */
const _FormUserDistanceInput = ({ placeholder, precision = undefined, value, onChange, disabled }) => (
    <UserDistanceInput
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        precision={precision}
    />
);

const FormUserDistanceInput = withForm<IOwnProps>(_FormUserDistanceInput);

export default FormUserDistanceInput;
