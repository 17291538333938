import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const FormGroupGrid = styled.div`
    display: grid;
    grid-template-columns: 4.6fr 7fr 4fr;
    grid-gap: 10px;
    padding: 8px;
    align-items: center;
`;

const Header = styled.div`
    font-weight: 700;
`;

export { FormGroupGrid, Header };
