import { find } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconNames } from '@blueprintjs/icons';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';

import * as auth from 'reports/modules/auth';
import * as inc from 'reports/models/incentive';
import { getModuleFromRegistry } from 'reports/modules/financials/model/modules';
import { IncentivesList } from 'reports/modules/incentive/components/IncentivesList';

import { IAppState } from 'reports/store';
import { UpsellBanner } from 'reports/components/UpsellBanner';

type Props = {
    inlineControls?: boolean;
    navigateToIncentive: (incentiveId: number) => void;
    hideUpsellBanner?: boolean;
};

const IncentiveLibrary = React.memo(({ inlineControls, navigateToIncentive, hideUpsellBanner }: Props) => {
    const user = useSelector((state) => auth.selectors.getUser(state));
    const dispatch = useDispatch();

    const loadItems = (args) => dispatch(inc.api.index(args));
    const refreshItem = useSelector(
        (state) => (item) => inc.selectors.byObject(state as IAppState, item, { creator: true, team: true }) || item,
    );
    const createIncentive = () => {
        const randid = Math.floor(performance.now() * 99999 + Math.random() * 99999);
        const incentiveRebateModule = getModuleFromRegistry('BasicIncentivesRebateSimple');

        dispatch(
            inc.api.create({
                name: `new incentive ${randid.toString(16)}`,
                incentive_type: 'fixed',
                configuration: {
                    amount: find(incentiveRebateModule.parameters, (i) => i.path === 'flat_rebate')!.default,
                    year: find(incentiveRebateModule.parameters, (i) => i.path === 'year')!.default,
                } as any,
                public: false,
            }),
        ).then((res) => navigateToIncentive(res.incentive_id));
    };

    if (!user) {
        return <></>;
    }

    return (
        <>
            {!hideUpsellBanner && <UpsellBanner />}
            <LibraryMain
                resourceIdName="incentive_id"
                loadItems={loadItems}
                refreshItem={refreshItem}
                inlineControls={inlineControls}
                views={[
                    {
                        view: <IncentivesList openEditor={navigateToIncentive} user={user} />,
                        icon: IconNames.LIST,
                        id: 'incentive-list',
                    },
                ]}
                id="incentives-views"
                quickFilters={[
                    {
                        name: 'team_id',
                        icon: IconNames.PEOPLE,
                        text: `Only Show My Team`,
                        value: user!.team.team_id,
                    },
                ]}
                contextBarControls={<CreateButton text="New Incentive" onClick={createIncentive} />}
            />
        </>
    );
});

export { IncentiveLibrary };
