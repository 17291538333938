import { IAppConfig } from 'reports/config';

import { ValueTypes } from 'reports/types';
import { MountingType } from 'reports/utils/scf';

import * as inc from 'reports/models/incentive';
import * as proj from 'reports/models/project';
import * as usr from 'reports/models/user';

import {
    AbsoluteTimeSeriesHour,
    AbsoluteTimeSeriesMonth,
    AbsoluteTimeSeriesYear,
    OutputSeries,
    TIME_INTERVALS,
} from 'reports/modules/financials/model/core';
import { DebugOutput } from 'reports/modules/financials/model/debug';

import { ParamConfigType } from 'reports/modules/financials/params';

interface IPipelineConfig {
    path: string;
}

interface IParamMetaProps {
    configurable: boolean;
    value: any;
}

export enum ResultType {
    Incentives = 'incentive',
    Currency = 'currency',
}

interface PipelineResultBase<T extends ResultType> {
    type: T;
    description?: string;
    path: string;
    hidden?: boolean;
    info?: string;
}

export type PipelineResultNumeric = PipelineResultBase<ResultType.Currency>;
export type PipelineResultIncentives = PipelineResultBase<ResultType.Incentives>;
export type PipelineResult = PipelineResultNumeric | PipelineResultIncentives;

export interface IEnvironmentalMetrics {
    co2_kg: number;
    driven_vehicle: number;
    driven_km: number;
    recycled_ton: number;
    recycled_truck: number;
    gasoline_liter: number;
    gasoline_tanker: number;
    energy_annual_home: number;
    electricity_annual_home: number;
    led_bulb: number;
    oil_barrel: number;
    propane_can: number;
    coal_kg: number;
    coal_railcar: number;
    seedling_ten_year: number;
    forest_hectare: number;
}

export interface IPipelineState extends IEnvironmentalMetrics, IOutputPipelineState {
    location: { lat: number; lon: number };
    name: string;
    timeZoneId: string;
    user: usr.User; // serialized User object (ex. timestamp fields will be strings, not Moment's)

    appConfig: IAppConfig;

    // Design metadata
    mountType: MountingType;
    hourlyData: { grid_power: number; usage: number }[]; // annual hourly production in Wh

    // System configuration
    fineGranularity: ValueTypes<typeof TIME_INTERVALS>;

    // Core energy timeseries (kWh)
    consumption: AbsoluteTimeSeriesHour;
    consumptionOneYear: AbsoluteTimeSeriesHour;
    production: AbsoluteTimeSeriesHour;
    productionOneYear: AbsoluteTimeSeriesHour;

    // Energy offset profile
    consumptionAfter: AbsoluteTimeSeriesHour; // consumption after solar
    offset: AbsoluteTimeSeriesHour; // energy offset by solar
    surplus: AbsoluteTimeSeriesHour; // surplus energy after consumption

    // Data imported from project
    projectRates: object;
    projectFinancialSettings: proj.FinancialRateSettings;
    // note: due to worker serialization, Incentive's timestamp fields are strings: not Moment's.
    projectIncentives: inc.Incentive[];
}

// Pipeline state that also gets put into IFinancialOutput in runcore: createOutput.
export interface IOutputPipelineState {
    debugOutput: DebugOutput[];

    // Design metadata
    nameplate: number;
    moduleCount: number;
    inverterCount: number;
    optimizerCount: number;
    netMeterFlag: boolean;

    // System configuration
    systemLifeYears: number;
    systemStartMonth: number;
    systemStartYear: number;
    utilityRateEscalation: number;
    ppaTermYears: number;
    annualDegradation: number;
    annualMaintenanceCost: number;
    discountRate: number;

    // Core financial timeseries and initial values
    financeInitial: number;
    financeYearly: AbsoluteTimeSeriesYear;
    incentiveInitial: number;
    incentiveYearly: AbsoluteTimeSeriesYear;
    interestYearly: AbsoluteTimeSeriesYear;
    systemInitial: number;
    systemYearly: AbsoluteTimeSeriesYear;
    taxInitial: number;
    taxYearly: AbsoluteTimeSeriesYear;
    taxCreditCostBasis?: number;

    // Core energy timeseries (kWh)
    utilityBeforeMonthly: AbsoluteTimeSeriesMonth;
    utilityAfterMonthly: AbsoluteTimeSeriesMonth;

    // Energy offset profile
    surplusYearly: AbsoluteTimeSeriesYear;

    // Output for financial integrations
    ppaRateSCF?: number;

    /* Output and intermediate series for reports financial tokens */
    outSystemMonthly: OutputSeries;
    outIncentiveMonthly: OutputSeries;
    outFinanceMonthly: OutputSeries;
    outTaxMonthly: OutputSeries;
    outSystemYearly: OutputSeries;
    outIncentiveYearly: OutputSeries;
    outFinanceYearly: OutputSeries;
    outTaxYearly: OutputSeries;

    consumptionYearly: AbsoluteTimeSeriesYear;
    consumptionAfterYearly: AbsoluteTimeSeriesYear;
    productionYearly: AbsoluteTimeSeriesYear;
    utilityBeforeYearly: AbsoluteTimeSeriesYear;
    utilityAfterYearly: AbsoluteTimeSeriesYear;

    consumptionMonthSums: number[];
    productionMonthSums: number[];
    utilityBeforeMonthSums: number[];
    utilityAfterMonthSums: number[];

    npvDiscount: number;
    ppaRate?: number;

    outCashFlow: number[];
    outCashFlowMonthly: number[];
    outCumulativeCashFlow: number[];
    outCumulativeCashFlowMonthly: number[];
    outCostFlow: number[];
    outEnergyFlow: number[];
}

export type PipelineParam = ParamConfigType & IPipelineConfig;
export type PipelineMetaParam = PipelineParam & IParamMetaProps;

export enum AuthErrorCode {
    AUTHORIZED = 'authorized',
    UNAUTHORIZED = 'unauthorized',
    SCF_UNAUTHORIZED = 'SCF unauthorized',
}
