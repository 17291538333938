import Logger from 'js-logger';
import React from 'react';

import { isFunction } from 'lodash';
import { useRoute } from 'react-router5';
import { useBlocker as useRouterBlocker } from 'react-router';
import { promptModalBoolean } from 'reports/components/dialog';
import { IRoute } from './types';

const logger = Logger.get('router');

type Props = React.PropsWithChildren<{
    lockRoute?: string;
    showExitPrompt: boolean | ((toState: IRoute, fromState: IRoute) => boolean);
    onSave?: () => Promise<any> | any;
    onDontSave?: () => Promise<any> | any;
    title?: string;
    prompt?: string;
    yesLabel?: string;
    noLabel?: string;
    cancellable?: boolean;
}>;

const lockedStates = new Set<string>();

type SaveProps = Pick<Props, 'title' | 'prompt' | 'yesLabel' | 'noLabel' | 'cancellable' | 'onSave' | 'onDontSave'>;

const promptSaveDialog = async ({
    title = 'Save changes...',
    prompt = 'You have unsaved changes, would you like to save before leaving?',
    yesLabel = 'Save',
    noLabel = "Don't Save",
    cancellable = true,
    onSave = () => true,
    onDontSave = () => true,
}: SaveProps) => {
    const shouldSave = await promptModalBoolean({
        title,
        prompt,
        yesLabel,
        noLabel,
        cancellable,
    });

    if (shouldSave === true) {
        await onSave();
        return true;
    }
    await onDontSave();
    return true;
};

const newBlocker = ({ showExitPrompt, ...props }: Props) => {
    const shouldBlock = React.useCallback(
        ({ currentLocation, nextLocation }) => {
            const promptExit = isFunction(showExitPrompt)
                ? showExitPrompt(nextLocation.pathname, currentLocation.pathname)
                : showExitPrompt;
            return promptExit && currentLocation.pathname !== nextLocation.pathname;
        },
        [showExitPrompt],
    );

    const blocker = useRouterBlocker(shouldBlock);

    React.useEffect(() => {
        (async () => {
            if (blocker.state === 'blocked' && showExitPrompt) {
                const canNavigate = await promptSaveDialog(props);
                if (canNavigate) {
                    blocker.proceed();
                    return;
                }
                blocker.reset();
            }

            if (blocker.state === 'blocked' && !showExitPrompt) {
                blocker.proceed();
                blocker.reset();
            }
        })();
        return () => {};
    }, [blocker, showExitPrompt]);
};

const oldBlocker = ({ lockRoute, showExitPrompt, ...props }: Props) => {
    const { route, router } = useRoute();
    let lockedRoute = lockRoute ?? route.name;

    const deactivateHandler = (exit) => (_router) => async (toState, fromState) => {
        const promptExit = isFunction(exit) ? exit(toState, fromState) : exit;

        if (!promptExit) {
            return true;
        }
        const canDeactivate = await promptSaveDialog(props);
        return canDeactivate;
    };

    React.useEffect(() => {
        if (lockRoute != null) {
            logger.info(`Locking route ${lockRoute}`);
            setStateHandler(lockRoute, showExitPrompt);
        } else {
            logger.info(`Locking current route ${route.name}`);
            setStateHandler(route.name, showExitPrompt);
        }

        return () => removeStateHandler();
    }, [lockRoute, route.name]);

    React.useEffect(() => {
        router.canDeactivate(route.name, deactivateHandler(showExitPrompt));
    }, [showExitPrompt]);

    const setStateHandler = (route, showExitPrompt) => {
        if (lockedStates.has(route)) {
            throw new Error(`Tried to lock ${route} but another component has already set the handler for it`);
        }
        lockedRoute = route;
        lockedStates.add(route);

        router.canDeactivate(route, deactivateHandler(showExitPrompt));
    };

    const removeStateHandler = (route = lockedRoute) => {
        if (lockedStates.delete(route)) {
            router.clearCanDeactivate(route);
        } else {
            logger.warn(`Tried to unlock state ${route}, but was not locked`);
        }
    };
};

const RouterStateLock = React.memo(({ children, ...props }: Props) => {
    // If we fail to load react-router's route blocker, fallback to router5's blocker.
    try {
        newBlocker(props);
    } catch (error) {
        oldBlocker(props);
    }

    return <>{children}</>;
});

export { RouterStateLock };
