import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as mod from 'reports/models/module';
import CharacterizationViewControls from 'reports/components/library/helpers/CharacterizationViewControls';

type Props = {
    module: mod.Module;
    selectedChar: mod.ModuleCharacterization;
    navigateToCharacterization: (moduleId: number, characterizationId: number) => void;
};

const ModuleCharViewControls = ({ module, navigateToCharacterization, selectedChar }: Props) => {
    const characterizations = useSelector((state) => mod.selectors.allCharacterizations(state, module));
    const dispatch = useDispatch();
    const updateModule = (args) => dispatch(mod.api.save(args));

    return (
        <CharacterizationViewControls
            characterizations={characterizations}
            selectedChar={selectedChar}
            setSelectedChar={(char: mod.ModuleCharacterization) =>
                navigateToCharacterization(module.module_id, char.module_characterization_id)
            }
            isDefaultChar={(char: mod.ModuleCharacterization) =>
                char.module_characterization_id === module.user_default_characterization_id
            }
            makeDefaultChar={({ module_id, module_characterization_id }) =>
                updateModule({
                    module_id,
                    user_default_characterization_id: module_characterization_id,
                })
            }
            charId={(char) => char.module_characterization_id}
        />
    );
};

export { ModuleCharViewControls };
