import { Button, Classes, Checkbox } from '@blueprintjs/core';
import { has, mapValues } from 'lodash';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterStateLock } from 'reports/utils/router';

import { Card, Flex } from 'reports/components/core/containers';
import { SaveCancelButtons } from 'reports/components/core/controls';
import { Form, FormInput, NestedFields } from 'reports/components/forms';

import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import Section from 'reports/components/helpers/Section';

import * as inc from 'reports/models/incentive';
import { User } from 'reports/models/user';

import * as auth from 'reports/modules/auth';
import INC_PARAMETERS from 'reports/modules/incentive/parameters';
import { addPromiseToasts, Toaster } from 'reports/modules/Toaster';

import IncentiveFields from './IncentiveFields';
import IncentiveTypeSelect from './IncentiveTypeSelect';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const StandaloneFieldsCard = styled(Card)`
    .${Classes.INLINE} {
        .${Classes.LABEL} {
            width: auto;
        }
    }
`;

type IncentiveEditorProps = {
    incentive: inc.Incentive;
    navigateToIncentives: () => void;
};

const DEFAULT_CONFIG_BY_INC_TYPE = mapValues(INC_PARAMETERS, (params) => {
    return mapValues(params, (paramConfig) => paramConfig.default);
});

const EditableHeader = ({
    name,
    updateName,
    onDelete,
    disabled,
}: {
    name: string;
    updateName: (val: string) => any;
    onDelete: () => any;
    disabled: boolean;
}) => (
    <div className="content-header">
        <EditableTitleSubHeader
            value={name}
            updateFn={updateName}
            right={
                <div style={{ display: 'flex' }}>
                    <div style={{ margin: '0px 2px' }}>
                        <Button icon="trash" onClick={onDelete} disabled={disabled} />
                    </div>
                </div>
            }
            disabled={disabled}
        />
    </div>
);

const IncentiveEditor = React.memo(({ incentive, navigateToIncentives }: IncentiveEditorProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => auth.selectors.getUser(state)!);

    const deleteIncentive = () => dispatch(inc.api.delete({ incentive_id: incentive.incentive_id }));
    const saveIncentive = (data: inc.IIncentiveForm) => dispatch(inc.api.save(data));

    const updateName = (name: string) =>
        dispatch(
            inc.api.save({
                ...incentive,
                name,
            }),
        );

    return (
        <IncentiveEditorForm
            deleteIncentive={deleteIncentive}
            updateName={updateName}
            navigateToIncentives={navigateToIncentives}
            saveIncentive={saveIncentive}
            user={user}
            incentive={incentive}
        />
    );
});

type IncentiveEditorFormProps = {
    deleteIncentive: () => Promise<null>;
    saveIncentive: (data) => Promise<inc.Incentive>;
    updateName: (name: string) => Promise<inc.Incentive>;
    navigateToIncentives: () => void;
    user: User;
    incentive: inc.Incentive;
};

const IncentiveEditorForm = React.memo(
    ({
        deleteIncentive,
        saveIncentive,
        updateName,
        navigateToIncentives,
        user,
        incentive,
    }: IncentiveEditorFormProps) => {
        const canModify = auth.permissions.canModifySharedTeamResource(user, incentive);
        const canPublish = auth.permissions.canMakeResourcePublic(user, incentive);

        const onDelete = async () => {
            try {
                await deleteIncentive();
                navigateToIncentives();
            } catch (e) {
                if (
                    has(e, 'response.body.incentive_id') &&
                    e.response.body.incentive_id.includes('Cannot delete: currently in use by projects')
                ) {
                    Toaster.show({
                        icon: 'warning-sign',
                        message: <div>Cannot delete incentive: in use by one or more projects</div>,
                        timeout: 2500,
                    });
                } else {
                    Toaster.show({
                        icon: 'warning-sign',
                        message: <div>Error deleting incentive</div>,
                        timeout: 2500,
                    });
                }
            }
        };

        const saveForm = async (form: inc.IIncentiveForm) =>
            await addPromiseToasts(saveIncentive(form), {
                initial: 'Saving incentive...',
                onSuccess: 'Successfully saved incentive.',
                onCatch: 'Error saving incentive.',
            });

        return (
            <>
                <EditableHeader
                    name={incentive.name}
                    updateName={updateName}
                    onDelete={onDelete}
                    disabled={!canModify}
                />
                <div className="sub-content-inner">
                    <Form baseValue={incentive} onSubmit={saveForm}>
                        {({ dirty, submitForm, formData, updateValue, clearForm }) => (
                            <>
                                <RouterStateLock
                                    title="Save incentive changes?"
                                    prompt={`You have unsaved changes on ${incentive.name || 'this incentive'}`}
                                    cancellable={true}
                                    showExitPrompt={dirty}
                                    onSave={submitForm}
                                    onDontSave={clearForm}
                                />

                                <Flex.ContainerV>
                                    <StandaloneFieldsCard>
                                        <FormInput
                                            label="Public"
                                            path="public"
                                            inputComponent={Checkbox}
                                            onUpdate={() => updateValue('public', !formData.public)}
                                            inputProps={{
                                                checked: formData.public,
                                            }}
                                            disabled={!(canModify && canPublish)}
                                        />
                                        <FormInput
                                            label="Incentive Type"
                                            path="incentive_type"
                                            inputComponent={IncentiveTypeSelect}
                                            onUpdate={(newIncType) => {
                                                // ensure there is always a compatible configuration
                                                if (incentive.incentive_type !== newIncType) {
                                                    updateValue(
                                                        'configuration',
                                                        DEFAULT_CONFIG_BY_INC_TYPE[newIncType],
                                                    );
                                                }
                                            }}
                                            inline={true}
                                            disabled={!canModify}
                                        />
                                    </StandaloneFieldsCard>
                                    <Card>
                                        <Section.Header>
                                            <Section.Text>Configuration</Section.Text>
                                        </Section.Header>
                                        <Section.Body>
                                            <NestedFields path="configuration">
                                                <IncentiveFields type={formData.incentive_type} disabled={!canModify} />
                                            </NestedFields>
                                            <SaveCancelButtons
                                                onSave={submitForm}
                                                hasChanges={dirty}
                                                onCancel={clearForm}
                                            />
                                        </Section.Body>
                                    </Card>
                                </Flex.ContainerV>
                            </>
                        )}
                    </Form>
                </div>
            </>
        );
    },
);

export { IncentiveEditor };
