/**
 * Consumption Editor dialog
 */
import { isEqual, range, round } from 'lodash';
import * as React from 'react';

import classNames from 'classnames';
import { Button, Classes, Colors, Dialog, FormGroup, IDialogProps, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { hoursInMonth, SHORT_MONTHS, Month } from 'reports/utils/time';
import { PrimaryButton, Button as SecondaryButton } from 'reports/components/core/controls';
import { FormattedInput } from 'reports/components/helpers/FormattedInput';
import { Toaster } from 'reports/modules/Toaster';
import Flex from 'reports/components/core/containers/Flex';

import * as proj from 'reports/models/project';
import * as up from 'reports/models/usage_profile';
import * as uc from 'reports/models/user_consumption';

import { IConsumptionInputForm } from 'reports/modules/consumption';
import { calculateMonthlyKwh, ERR_INCOMPLETE_INPUT } from 'reports/modules/consumption/generated';

import MonthlyChart from './MonthlyChart';
import UsageProfileSelect from './UsageProfileSelect';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// Align controls with monthly chart
const ChartControls = styled(Flex.ContainerV)`
    align-items: flex-end;
    margin-bottom: 10px;

    & > div {
        padding: 0 4px;
    }
`;

const DialogBody = styled.div.attrs({
    className: Classes.DIALOG_BODY,
})`
    display: flex;
    flex-direction: column;

    .subheader {
        font-size: 16px;
        font-weight: 600;
        padding: 10px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid ${Colors.GRAY3};
        color: ${Colors.GRAY1};
    }
`;

const InputsContainer = styled.div`
    display: flex;

    input, label {
        text-align: center;
    }
    .${Classes.FORM_GROUP} {
        margin: 0 2px;
    }

    // override Bluperint input action styling, no way around this!
    .${Classes.FORM_GROUP}
        .${Classes.INPUT}:not([readonly]) {
            padding-right: 24px !important;
        }
        .${Classes.INPUT_ACTION} .${Classes.BUTTON} {
            min-width: 18px !important;
        }
    }
`;

interface IConsumpEditorDialogProps extends IConsumptionInputForm, IDialogProps {
    location: proj.GeoPoint;
    monthlyEnergy: number[]; // kWh[]
    onCancel: () => void;
    onSave: (consumptionInput: IConsumptionInputForm) => void;
}

interface IState {
    editing: boolean;
    monthlyEnergy: number[];
    userInput: uc.IUserInput;
    usageProfile?: up.UsageProfile;
}

class ConsumptionEditorDialog extends React.PureComponent<IConsumpEditorDialogProps, IState> {
    dialogWidth = 1200;
    chartWidth = this.dialogWidth - 54; // y-axis width: 54px
    inputWidth = 86;

    state: IState = {
        editing: false,
        monthlyEnergy: this.props.monthlyEnergy,
        usageProfile: this.props.usageProfile,
        userInput: this.props.userInputKwh,
    };

    componentDidUpdate(_prevProps, prevState) {
        const { usageProfile } = this.state;
        if (usageProfile && prevState.usageProfile !== usageProfile) {
            const monthlyEnergy = calculateMonthlyKwh(this.state.userInput, usageProfile);
            this.setState({ monthlyEnergy });
        }
    }

    render() {
        const { isOpen, location, usageProfile: currUsageProfile } = this.props;
        const { editing, usageProfile } = this.state;

        const inputUnchanged = isEqual(this.props.userInputKwh, this.state.userInput);
        const usageProfileUnchanged =
            (!currUsageProfile && !usageProfile) ||
            (currUsageProfile && usageProfile && currUsageProfile.usage_profile_id === usageProfile.usage_profile_id);

        return (
            <Dialog
                title="Configure Manual Consumption"
                isOpen={isOpen}
                isCloseButtonShown={true}
                onClose={this.onClose}
                style={{ width: this.dialogWidth, overflow: 'hidden' }}
            >
                <DialogBody>
                    <div style={{ marginBottom: 10 }}>
                        Customize your consumption load profile below - empty months will be interpolated if a default
                        usage profile has been set.
                    </div>

                    <MonthlyChart
                        energy={this.state.monthlyEnergy}
                        showXLabels={false}
                        style={{
                            alignSelf: 'flex-end',
                            width: this.chartWidth,
                        }}
                    />
                    <ChartControls>
                        <Flex.Container style={{ alignItems: 'flex-end' }}>
                            <Button
                                text="Edit"
                                icon={IconNames.WRENCH}
                                intent={Intent.WARNING}
                                onClick={() => this.setEditing(true)}
                                disabled={editing}
                                style={{ marginRight: 4 }}
                            />
                            <InputsContainer>{this.renderInputs()}</InputsContainer>
                        </Flex.Container>
                        {editing && (
                            <div style={{ marginTop: 10 }}>
                                <Button
                                    text="Reset"
                                    onClick={() => this.reset(false)}
                                    disabled={inputUnchanged}
                                    style={{ marginRight: 10 }}
                                />
                                <Button
                                    text="Done"
                                    onClick={() => this.setEditing(false)}
                                    icon={IconNames.TICK}
                                    intent={Intent.SUCCESS}
                                />
                            </div>
                        )}
                    </ChartControls>

                    <div className="subheader">Customize Load Profile</div>
                    <UsageProfileSelect
                        usageProfile={usageProfile}
                        onUpdate={this.setUsageProfile}
                        location={location}
                    />
                </DialogBody>
                <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                    <SecondaryButton text="Cancel" onClick={this.onCancel} disabled={editing} />
                    <PrimaryButton
                        text="Save Changes"
                        icon={IconNames.FLOPPY_DISK}
                        onClick={this.onSave}
                        disabled={editing || (inputUnchanged && usageProfileUnchanged)}
                    />
                </div>
            </Dialog>
        );
    }

    renderInputs = () => {
        const { editing, userInput } = this.state;
        const readOnly = !editing ? { readOnly: true } : {};

        return range(12).map((monthIdx) => (
            <FormGroup key={monthIdx} label={SHORT_MONTHS[monthIdx]}>
                <FormattedInput
                    value={this.getDisplayValue(monthIdx)}
                    onConfirm={(energy) => this.onMonthUpdate(monthIdx, energy)}
                    style={{ width: this.inputWidth }}
                    selectAllOnFocus={true}
                    disabled={!editing && userInput[monthIdx + 1] === undefined}
                    rightElement={
                        editing && userInput[monthIdx + 1] !== undefined ? (
                            <Button
                                icon={IconNames.SMALL_CROSS}
                                onClick={() => this.onMonthUpdate(monthIdx, '')}
                                minimal={true}
                            />
                        ) : undefined
                    }
                    {...readOnly}
                />
            </FormGroup>
        ));
    };

    // Return user input if given, otherwise display interpolated monthly value
    getDisplayValue = (monthIdx: number) =>
        this.state.userInput[monthIdx + 1]
            ? round(this.state.userInput[monthIdx + 1].energy!, 2)
            : this.state.editing
            ? ''
            : round(this.state.monthlyEnergy[monthIdx], 2);

    onCancel = () => {
        this.reset();
        this.props.onCancel();
    };

    onClose = () => {
        this.onCancel();
        this.props.onClose && this.props.onClose(null as any);
    };

    onMonthUpdate = (monthIdx: number, energyStr: string) => {
        const month = monthIdx + 1;
        const { [month]: monthInput, ...otherMonths } = this.state.userInput;

        const energy = parseFloat(energyStr);
        const removeInput = monthInput !== undefined && energyStr === '';
        const invalidInput = energyStr.length > 0 && isNaN(energy); // allow '' as valid input

        const userInput = { ...otherMonths };
        if (!removeInput && !invalidInput) {
            userInput[month] = { energy };
        } else if (monthInput && invalidInput) {
            // ignore invalid input and reset to last value
            userInput[month] = monthInput;
        }

        let monthlyEnergy = [...this.state.monthlyEnergy];
        if (this.state.usageProfile) {
            monthlyEnergy = calculateMonthlyKwh(userInput, this.state.usageProfile);
        } else if (!invalidInput) {
            const inputMonths = Object.keys(userInput);

            // TODO: use generated.ts: calculateMonthlyKwh() once it supports optional usageProfile
            if (inputMonths.length === 1) {
                const singleMonth = parseInt(inputMonths[0], 10) as Month;
                const hourlyAvg = userInput[singleMonth].energy! / hoursInMonth(singleMonth);
                monthlyEnergy = range(1, 13).map((m: Month) => hourlyAvg * hoursInMonth(m));
            } else if (!removeInput) {
                monthlyEnergy[monthIdx] = energy;
            }
        }

        this.setState({ monthlyEnergy, userInput });
    };

    onSave = () => {
        const { usageProfile, userInput: userInputKwh } = this.state;
        const numInputs = Object.keys(userInputKwh).length;

        if ((!usageProfile && numInputs < 12 && numInputs > 1) || numInputs === 0) {
            Toaster.show({
                message: usageProfile ? 'Cannot generate consumption with incomplete input.' : ERR_INCOMPLETE_INPUT,
                intent: Intent.DANGER,
            });
            return;
        }

        this.props.onSave({ userInputKwh, usageProfile });
    };

    reset = (resetEditing: boolean = true) =>
        this.setState({
            editing: resetEditing ? false : true,
            monthlyEnergy: this.props.monthlyEnergy,
            userInput: this.props.userInputKwh,
        });

    setEditing = (editing: boolean) => this.setState({ editing });
    setUsageProfile = (usageProfile: up.UsageProfile) => this.setState({ usageProfile });
}

export default ConsumptionEditorDialog;
