import * as React from 'react';
import { connect } from 'react-redux';

import { bindActions } from 'reports/utils/redux';

import * as rep from 'reports/modules/report';

import * as proj from 'reports/models/project';

import { ProjectSelectDropdownContainer } from 'reports/components/CustomDropdowns';
import { InlineFormGroup } from 'reports/components/helpers/formHelpers';

interface IOwnProps {
    selectedProject: proj.Project;
}
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IDispatchProps;

const ProjectSelect: React.FC<IProps> = ({ selectedProject, setPreviewProject }) => (
    <InlineFormGroup label="Project">
        <ProjectSelectDropdownContainer
            text={selectedProject.name}
            onItemSelect={(project) => setPreviewProject(project.project_id)}
        />
    </InlineFormGroup>
);

const mapDispatchToProps = bindActions({
    setPreviewProject: rep.actions.setPreviewProject,
});

export default connect(null, mapDispatchToProps)(ProjectSelect);
