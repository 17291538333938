import { createSelector } from 'reselect';

import { actionCreatorFactory, isType, Action } from 'typescript-fsa';

import { IAppState } from 'reports/types';

const actionCreator = actionCreatorFactory('ACTIVITY');

export const actions = {
    setActivityItem: actionCreator<IActivityItem>('SET_ACTIVITY_ITEM'),
    clearActivityItem: actionCreator<string>('CLEAR_ACTIVITY_ITEM'),
};

export const selectors = {
    get allActivityItems() {
        return createSelector(
            (state: IAppState) => state,
            (state) => state.activity,
        );
    },
    get activityItem() {
        return createSelector(
            (state: IAppState) => state.activity,
            (_state: IAppState, props: { activityId: string }) => props.activityId,
            (activity, id) => activity[id],
        );
    },
};

export interface IActivityItem {
    activityId?: string;
    error?: boolean;
    text: string;
    [k: string]: any;
}

export interface IActivityState {
    [k: string]: IActivityItem;
}

const INITIAL_STATE: IActivityState = {};

export const reducer = (state: IActivityState = INITIAL_STATE, action: Action<any>): IActivityState => {
    if (isType(action, actions.clearActivityItem)) {
        const copy = { ...state };
        delete copy[action.payload];
        return copy;
    }

    if (isType(action, actions.setActivityItem)) {
        const { activityId, ...other } = action.payload;

        if (JSON.stringify(state[activityId as string]) === JSON.stringify(other)) {
            return state;
        }

        return {
            ...state,
            [activityId as string]: other,
        };
    }

    return state;
};
