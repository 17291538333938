import { find, map } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { Classes, HTMLSelect } from '@blueprintjs/core';

import { createUniqueDescription } from 'reports/utils/helpers';
import { bindActions } from 'reports/utils/redux';
import { Form, FormField, handleRequestException, IFormUpdateCallback } from 'reports/components/forms';
import { promptModalForm } from 'reports/components/dialog';

import * as proj from 'reports/models/project';
import * as des from 'reports/models/design';

import { InputWithError, InlineLabel } from 'reports/components/helpers/formHelpers';

interface IOwnProps {
    project: proj.Project;
    onUpdate: IFormUpdateCallback;
    onInit: IFormUpdateCallback;
}

const mapStateToProps = (state, { project }: IOwnProps) => ({
    designs: proj.selectors.designs(state, project),
});

const mapDispatchToProps = bindActions({ createDesign: des.api.create });

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

class NewDesignForm extends React.PureComponent<IProps> {
    render() {
        const { designs, onUpdate, onInit, project, createDesign } = this.props;

        const baseValue = {
            project_id: project.project_id,
            clone_design_id: undefined,
            description: this.newDescription(),
        };

        return (
            <Form
                onSubmit={createDesign}
                baseValue={baseValue}
                onUpdate={onUpdate}
                onInit={onInit}
                exceptionHandler={handleRequestException}
            >
                <FormField path="description">
                    {({ value, onChange, errors, form }) => (
                        <InlineLabel style={{ marginBottom: 0 }} disabled={form.submitting}>
                            Description
                            <InputWithError
                                value={value}
                                onChange={onChange}
                                placeholder="Brief design description"
                                className={errors.length || !value ? Classes.INTENT_DANGER : ''}
                                invalid={errors.length > 0 || !value}
                                errorMsg={errors.length ? errors[0] : ''}
                            />
                        </InlineLabel>
                    )}
                </FormField>
                <FormField path="clone_design_id" onUpdate={this.onUpdateClone}>
                    {({ value, onChange, form }) => (
                        <InlineLabel disabled={form.submitting || designs!.length === 0}>
                            Clone a design
                            <HTMLSelect
                                style={{ width: '300px' }}
                                value={value}
                                onChange={(evt) =>
                                    onChange(evt.target.value === '' ? undefined : parseInt(evt.target.value, 10))
                                }
                            >
                                <option value="">Start with an Empty Design</option>
                                {map(designs, (d, i) => (
                                    <option key={i} value={d.design_id}>
                                        {d.description}
                                    </option>
                                ))}
                            </HTMLSelect>
                        </InlineLabel>
                    )}
                </FormField>
            </Form>
        );
    }

    onUpdateClone = (newCloneId, form) => {
        if (!form.dirtyFields['description']) {
            const newDescr = typeof newCloneId === 'number' ? this.cloneDescription(newCloneId) : this.newDescription();
            form.updateValue('description', newDescr, false);
        }
    };

    newDescription = (): string => {
        const { designs } = this.props;
        return `Design ${designs!.length + 1}`;
    };

    cloneDescription = (designId): string => {
        const { designs } = this.props;
        const designToCopy = find(designs, (d) => d.design_id === designId);
        if (designToCopy == null) {
            throw new Error('Could not find a design in the state when it should have existed');
        }
        return createUniqueDescription(designToCopy.description, map(designs, 'description'));
    };
}

const NewDesignFormContainer = connect(mapStateToProps, mapDispatchToProps)(NewDesignForm);

export async function createDesignDialog(project: proj.Project) {
    return await promptModalForm<des.Design>({
        title: 'Add a New Design',
        icon: 'page-layout',
        Component: NewDesignFormContainer,
        componentProps: { project },
        submitLabel: 'Create Design',
        cancellable: true,
    });
}

export default NewDesignFormContainer;
