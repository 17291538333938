import { merge } from 'lodash';
import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { ReactComponent } from 'reports/utils/types';

import { IWidget, IWidgetMetadata, IWidgetRenderProps, IWidgetEditProps } from 'reports/modules/report/widgets';

import { ILayout } from 'reports/modules/report/components/layout';
import SidebarFormatForm from 'reports/modules/report/components/SidebarFormatForm';

interface IChartArgs<IChartConfig extends object = object, IContext extends object = object> {
    Chart: ReactComponent<{
        context: IContext;
        config: IChartConfig;
        layout: ILayout;
        mouseEvents?: boolean;
    }>;
    metadata: IWidgetMetadata<IChartConfig>;
    Form?: ReactComponent<{
        config: IChartConfig;
        onChange: (config: Partial<IChartConfig>) => any;
        intl: IntlShape;
    }>;
    dependencies: (keyof IContext)[];
}

export function createChartWidget<IConfig extends object = object, IContext extends object = object>({
    Chart,
    Form,
    metadata,
    dependencies,
}: IChartArgs<IConfig, IContext>): IWidget<IConfig, IContext> {
    type RenderProps = IWidgetRenderProps<IConfig, IContext>;
    type EditProps = IWidgetEditProps<IConfig, IContext>;

    const Component: React.SFC<RenderProps> = ({ context, config }) => (
        <Chart context={context} config={config.content} layout={config.layout} />
    );

    let EditingComponent: React.SFC<EditProps> | undefined;

    if (Form) {
        const FormIntl = injectIntl(Form);
        EditingComponent = ({ context, config, updateWidgetContent }: EditProps) => (
            <div>
                <Chart context={context} config={config.content} layout={config.layout} mouseEvents={false} />
                <SidebarFormatForm>
                    <FormIntl
                        config={config.content}
                        onChange={(patch) => updateWidgetContent(merge({}, config.content, patch))}
                    />
                </SidebarFormatForm>
            </div>
        );
    }

    return { Component, EditingComponent, metadata, dependencies };
}

export default createChartWidget;
