import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Intent, Tag } from '@blueprintjs/core';

import { IFormUpdateCallback, IExceptionHandler, Form, handleRequestException } from 'reports/components/forms';

import * as usr from 'reports/models/user';

import { Invoice } from 'reports/models/stripe/invoice';
import { Moment } from 'moment';
import * as sc from 'reports/models/stripe/stripe_customer';
import { Section2 } from 'reports/components/core/containers';
import { FormDatePicker, FormSwitchInput } from 'reports/components/forms/inputs/experimental';
import { HelperText } from '../../common';
import styled from 'styled-components';
import { FormErrorCallout } from 'reports/components/helpers/errors';
import { promptModalForm } from 'reports/components/dialog';

interface Props {
    invoice?: Invoice;
    onError: IExceptionHandler<StripeCustomerWithTestClockForm>;
    onUpdate?: IFormUpdateCallback;
    onInit?: IFormUpdateCallback;
    user: usr.User;
}
interface StripeCustomerWithTestClockForm {
    frozen_time: Moment;
}

const StyledHelperText = styled(HelperText)`
    margin-top: -10px;
`;

export const TestClockForm = ({ user, displaySwitch, testClockEnabled }) => (
    <>
        {displaySwitch && (
            <FormSwitchInput
                path="test_clock_enabled"
                labelWidth={370}
                label="Switch ON to create a test clock for this subscription"
                disabled={user.team.stripe_customer_id ? true : false}
                inline
            />
        )}
        {displaySwitch && user.team.stripe_customer_id && (
            <StyledHelperText>Test clocks can only be added to new Stripe customers</StyledHelperText>
        )}
        {testClockEnabled && (
            <FormDatePicker
                label="Test Clock Frozen Time"
                path="frozen_time"
                minDate={user!.now().toDate()}
                maxDate={user!.now().add(5, 'year').toDate()}
                timePrecision={'minute'}
                timePickerProps={{
                    useAmPm: true,
                }}
                helperText={'Time the test clock will be frozen at (in local time)'}
                inline
            />
        )}
    </>
);

const StripeCustomerWithTestClockForm = React.memo(({ onUpdate, onInit, user }: Props) => {
    const dispatch = useDispatch();

    const createStripeCustomer = async ({ frozen_time }: { frozen_time?: string }) =>
        dispatch(await sc.api.create({ frozen_time }));

    const refreshUser = ({ email }: { email: string }) => dispatch(usr.api.get({ email }));

    const onSubmit = async ({ frozen_time }: StripeCustomerWithTestClockForm) => {
        const frozenTimestamp = frozen_time?.toISOString();
        const customer = await createStripeCustomer({ frozen_time: frozenTimestamp });
        await refreshUser({ email: user.email });
        return customer;
    };
    return (
        <Form<StripeCustomerWithTestClockForm, sc.StripeCustomer>
            onSubmit={onSubmit}
            onInit={onInit}
            onUpdate={onUpdate}
            baseValue={{ frozen_time: user.now().add(1, 'minute') }}
            exceptionHandler={handleRequestException}
        >
            {({ formErrors }) => (
                <>
                    <Section2
                        title="Enable Test Clock"
                        contextEl={<Tag intent={Intent.DANGER}>Internal testing only</Tag>}
                    >
                        <TestClockForm user={user} testClockEnabled={true} displaySwitch={false}></TestClockForm>
                    </Section2>

                    {formErrors.length > 0 && (
                        <FormErrorCallout errorMsg={formErrors[0]} style={{ marginBottom: '15px' }} />
                    )}
                </>
            )}
        </Form>
    );
});

export async function createTestClockDialog(user: usr.User) {
    return await promptModalForm<sc.StripeCustomer>({
        title: 'Create a new Stripe Customer',
        Component: StripeCustomerWithTestClockForm,
        componentProps: { user },
        submitLabel: 'Create Customer',
        cancellable: true,
    });
}

export default StripeCustomerWithTestClockForm;
