import * as React from 'react';
import Flex from 'reports/components/core/containers/Flex';

import { H2 } from '@blueprintjs/core';

import { styled } from 'reports/styles/styled-components';

const Title = styled(H2)`
    font-weight: 400;
    color: #607d8b;
`;

const Subtitle = styled.div`
    margin-top: -11px;
    margin-bottom: 8px;
    font-size: 12px;
    width: 80%;
`;

const CostSubtitle = styled(Subtitle)`
    margin-top: -5px;
`;

const Description = styled.div`
    font-size: 14px;
`;

const Cost = styled.div`
    font-size: 28px;
`;

const Header = styled(Flex.Container)`
    gap: 8px;
    flex-direction: column;
    padding-bottom: 18px;
    padding: 0px 16px 12px 24px;
`;

interface Props {
    cost: { amount: string; description?: string };
    description: React.ReactNode;
    subtitle: string;
    title: string;
}

const PlanHeader = ({ cost, description, subtitle, title }: Props) => {
    return (
        <Header>
            <div>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </div>
            <div>
                <Cost>{cost.amount}</Cost>
                <CostSubtitle>{cost.description}</CostSubtitle>
            </div>
            <Description>{description}</Description>
        </Header>
    );
};

export { PlanHeader };
