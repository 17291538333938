import * as React from 'react';
import { useDispatch } from 'react-redux';

import { startCase } from 'lodash';

import { ExperimentSummary } from 'reports/models/admin_data';
import { Team } from 'reports/models/team';
import { User } from 'reports/models/user';

import { SaveCancelButtons } from 'reports/components/core/controls';
import { Form, NestedFields } from 'reports/components/forms';
import { FormBareBasicSelect } from 'reports/components/forms/inputs/experimental';

import { actions } from 'reports/modules/settings/team';
import { addPromiseToasts } from 'reports/modules/Toaster';
import {
    HelperText,
    ControlLabel,
    ControlPrimaryText,
    ControlContainer,
    SectionContainer,
} from 'reports/modules/settings/common';

// Copied from UserFeaturesForm.tsx
interface FeatureOpt {
    name: string;
    key: boolean | null;
}

// Copied from UserFeaturesForm.tsx
const FEATURE_OPTS: FeatureOpt[] = [
    {
        key: null,
        name: 'Auto',
    },
    {
        key: true,
        name: 'Enabled',
    },
    {
        key: false,
        name: 'Disabled',
    },
];

interface Props {
    team: Team;
    user: User;
    features: ExperimentSummary[];
}

const TeamFeaturesForm = ({ team, user, features }: Props) => {
    const dispatch = useDispatch();
    const updateTeam = (teamForm) => dispatch(actions.updateTeam(team, teamForm, user.email));
    const teamFeatures = features.reduce(
        (obj, feature) => ({
            ...obj,
            [feature.name]: team.features.hasOwnProperty(feature.name) ? team.features[feature.name] : null,
        }),
        {},
    );

    return (
        <Form
            baseValue={{
                ...team,
                features: teamFeatures,
            }}
            onSubmit={async (formData) => {
                await addPromiseToasts(updateTeam(formData), {
                    initial: 'Saving team settings...',
                    onSuccess: 'Successfully saved team settings.',
                    onCatch: 'Error saving team settings.',
                });
            }}
        >
            {({ dirty, submitForm, clearForm }) => (
                <>
                    <NestedFields path="features">
                        {features.map((feature) => (
                            <SectionContainer key={feature.name}>
                                <ControlLabel>
                                    <ControlPrimaryText>{startCase(feature.name)}</ControlPrimaryText>
                                    <HelperText>{feature.description}</HelperText>
                                </ControlLabel>
                                <ControlContainer>
                                    <FormBareBasicSelect<FeatureOpt>
                                        inline
                                        path={`${feature.name}`}
                                        dataSource={{
                                            items: FEATURE_OPTS,
                                            keyLookup: (item) => item.key,
                                        }}
                                        itemRenderer={({ key, name }) => ({
                                            key: String(key),
                                            text: name,
                                        })}
                                    />
                                </ControlContainer>
                            </SectionContainer>
                        ))}
                    </NestedFields>
                    <SaveCancelButtons
                        onSave={submitForm}
                        hasChanges={dirty}
                        onCancel={clearForm}
                        style={{ marginBottom: 10 }}
                    />
                </>
            )}
        </Form>
    );
};

export { TeamFeaturesForm };
