import React from 'react';
import { Flex, Button, Text } from '@aurorasolar/ds';
import { goToBookDemo } from './common';

const footerLogo = require('reports/static/helioscope-footer-logo.svg');

const listStyle = {
    width: 'auto',
    listStyleType: 'none',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
};

const listItemStyle = {
    margin: '0 0 4px',
};

const linkStyle = {
    color: 'white',
    fontWeight: 'inherit',
};

const SignupFooter = () => (
    <Flex gap="0px">
        <Flex backgroundColor="gray-900" alignItems="center" borderBottom="1px solid white">
            <Flex maxWidth="702px" flexDirection="row" justifyContent="space-between" padding="42px 10px">
                <Text fontSize="44px" color="white" alignSelf="center">
                    Book a demo today
                </Text>
                <Button size="md" variant="tertiary" bg="yellow-200" color="gray-700" action={goToBookDemo}>
                    {`Book a demo >`}
                </Button>
            </Flex>
        </Flex>
        <Flex
            backgroundColor="gray-900"
            justifyContent="center"
            alignItems="center"
            paddingTop="48px"
            borderBottom="1px solid grey"
        >
            <div style={{ maxWidth: '1440px', width: '100%', padding: '24px 122px' }}>
                <img src={footerLogo} alt="HelioScope Logo" />
            </div>
        </Flex>
        <Flex backgroundColor="gray-900" paddingTop="24px" alignItems="center">
            <Flex flexDirection="row" maxWidth="1440px" justifyContent="flex-end">
                <ul style={listStyle}>
                    <li style={listItemStyle}>
                        <a style={linkStyle} href="https://help-center.helioscope.com/" target="_blank">
                            Help Center
                        </a>
                    </li>
                    <li style={listItemStyle}>
                        <a style={linkStyle} href="mailto:sales@helioscope.com">
                            Contact Us
                        </a>
                    </li>
                    <li style={listItemStyle}>
                        <a style={linkStyle} href="https://helioscope.aurorasolar.com/terms-of-use-historic/">
                            Terms of Use (Historic)
                        </a>
                    </li>
                    <li style={listItemStyle}>
                        <a style={linkStyle} href="https://helioscope.aurorasolar.com/terms-of-use/">
                            Terms of Use (Current)
                        </a>
                    </li>
                    <li style={listItemStyle}>
                        <a style={linkStyle} href="https://helioscope.aurorasolar.com/privacy-policy-historic/">
                            Privacy Policy (Historic)
                        </a>
                    </li>
                    <li style={listItemStyle}>
                        <a style={linkStyle} href="https://www.aurorasolar.com/privacy-policy" target="_blank">
                            Privacy Policy (Current)
                        </a>
                    </li>
                </ul>
            </Flex>
        </Flex>
        <Flex backgroundColor="gray-900" paddingTop="24px" alignItems="center">
            <Flex padding="24px 0 48px" maxWidth="1440px">
                <Text color="white" alignSelf="center">
                    ©2024 HelioScope, an Aurora Inc. Company
                </Text>
            </Flex>
        </Flex>
    </Flex>
);

export default SignupFooter;
