import * as React from 'react';

import { IconNames } from '@blueprintjs/icons';

import { useDispatch } from 'react-redux';
import { RouteComponent } from 'reports/routing';

import { actions as routerActions } from 'redux-router5';

import { RedirectHelperContainer } from 'reports/components/helpers/common';

import Toaster from 'reports/modules/Toaster';

import { UtilityRateEditor } from 'reports/modules/utility_rate/components/UtilityRateEditor';
import { UtilityRateLibrary } from '../components/UtilityRateLibrary';

const Library = (): React.JSX.Element => {
    const dispatch = useDispatch();

    const navigateToUtilityRate = (rateId: number) =>
        dispatch(routerActions.navigateTo('app.utility-rates.utility-rate', { rateId }));

    const navigateToUtilityRates = () => dispatch(routerActions.navigateTo('app.utility-rates'));

    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.utility-rates">
                    <UtilityRateLibrary navigateToUtilityRate={navigateToUtilityRate} />
                </RouteComponent>
                <RouteComponent name="app.utility-rates.utility-rate">
                    {({ utilityRate }) => {
                        if (!utilityRate) {
                            return (
                                <RedirectHelperContainer
                                    name="app.utility-rates"
                                    params={{}}
                                    callback={() =>
                                        Toaster.show({
                                            icon: IconNames.WARNING_SIGN,
                                            message: <div>Utility rate does not exist</div>,
                                            timeout: 2500,
                                        })
                                    }
                                />
                            );
                        }

                        return (
                            <UtilityRateEditor
                                navigateToUtilityRate={navigateToUtilityRate}
                                navigateToUtilityRates={navigateToUtilityRates}
                                utilityRate={utilityRate}
                            />
                        );
                    }}
                </RouteComponent>
            </div>
        </div>
    );
};

export { Library };
