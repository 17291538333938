/* eslint camelcase: 0 */
import _ from 'lodash';
import moment from 'moment';

import { TIME_INTERVALS, mapAnnualTrueUp, TimeSeries } from '../core';

import { ParamValueType, isParamTier } from 'reports/modules/financials/params';
import { convertSeriesToOutput, OutputCategory } from 'reports/modules/financials/model/debug';
import { IPipelineModule } from './types';
import { marginalMultiplier, maximalMultiplier } from './multipliers';
import { IPipelineState } from '../pipeline';

function daysInMonth(startYear: number, startMonth: number, offset: number) {
    const year = (startYear + Math.floor((startMonth + offset) / 12)) % 100000;
    const month = (startMonth + offset) % 12;
    return moment({ year, month }).daysInMonth();
}

// TODO: run migration on existing rates, to rename tier_cap to abs_cap
function getParamTiers(tiers, absCapKey = 'tier_cap', valueKey?) {
    if (isParamTier(tiers)) return tiers;

    // Map deprecated tier structure to current ITier
    return tiers.map((tierData) => ({
        ...tierData,
        ...(valueKey ? { tier_value: tierData[valueKey] } : {}),
        abs_cap: tierData[absCapKey],
    }));
}

function simpleCharges(usage, rates, monthlyFn, chargeFn) {
    const { rate_schedules, tables } = rates;

    const daylookup = _.range(7).map(() => tables[0].table);

    for (const i of tables) {
        if (i.days) {
            for (const j of i.days) {
                daylookup[j] = i.table;
            }
        }
    }

    const zeroes = _.range(rate_schedules.length).map(() => 0.0);
    const datetime = usage.startMoment;

    const separated = usage.map((value, start, end, _idx) => {
        const tbl = datetime.day();
        const col = datetime.hour();
        const row = datetime.month();
        datetime.add(end - start, 'ms');

        const table = daylookup[tbl];
        const tieridx = table[row * 24 + col] - 1;

        const entry = zeroes.slice();
        entry[tieridx] = value;
        return entry;
    });

    const energySeparateHourly = _.range(rate_schedules.length).map((i) => separated.map((j) => j[i]));
    const energySeparateMonthly = energySeparateHourly.map(monthlyFn);
    const ratesSeparate = energySeparateMonthly.map((i: any, idx) => i.map((j) => chargeFn(j, rate_schedules[idx])));

    const cost = ratesSeparate[0].map(() => 0.0);

    for (const i of ratesSeparate) {
        cost.addSeries(i);
    }

    return cost;
}

function simpleDemandCharges(usage, rates) {
    const maxFn = (subseries) => {
        let max = 0.0;
        subseries.iterate((i) => {
            if (i > max) max = i;
        });
        return max;
    };

    const monthlyFn = (hourly) => hourly.changeInterval(TIME_INTERVALS.MONTH, maxFn, (i) => i);
    const chargeFn = (value, schedule) => {
        const tiers = getParamTiers(schedule.rate_tiers);
        return maximalMultiplier(value, tiers);
    };
    return simpleCharges(usage, rates, monthlyFn, chargeFn);
}

function simpleEnergyCharges(usage, surplus, rates) {
    const monthlyFn = (hourly) => hourly.reaggregateInterval(TIME_INTERVALS.MONTH);
    const netMeterFlat = rates.net_meter_options && rates.net_meter_options.net_meter_type === 'flat_rate';

    if (netMeterFlat) {
        const chargeFn = (value, schedule) => {
            if (value >= 0.0) {
                const tiers = getParamTiers(schedule.rate_tiers);
                return marginalMultiplier(value, tiers);
            }
            return value * (schedule.net_meter_flat || 0.0);
        };

        const netusage = usage.clone().subSeries(surplus);
        return simpleCharges(netusage, rates, monthlyFn, chargeFn);
    }

    const chargeFn = (value, schedule) => {
        const tiers = getParamTiers(schedule.rate_tiers);
        return marginalMultiplier(value, tiers);
    };
    return simpleCharges(usage, rates, monthlyFn, chargeFn);
}

function simpleFixedCharges(usage, charges, rates) {
    let cost = charges.map(() => 0.0);
    const monthlyUsage = usage.reaggregateInterval(TIME_INTERVALS.MONTH);
    const startMonth = usage.startMoment.month();
    const startYear = usage.startMoment.year();
    rates.forEach(({ type, amount }) => {
        if (type === 'flat') {
            cost = cost.map((x) => x + amount);
        } else if (type === 'kwh') {
            cost.addSeries(monthlyUsage.map((x) => x * amount));
        } else if (type === 'day') {
            cost = cost.map((x, _, __, i) => x + daysInMonth(startYear, startMonth, i) * amount);
        } else if (type === 'percent') {
            cost = cost.map((x) => x + x * amount);
            cost.addSeries(charges.map((x) => x * amount));
        }
    });
    return cost;
}

function simpleMinimumBill(charges, minimumBill) {
    const { type, amount } = minimumBill;
    const startMonth = charges.startMoment.month();
    const startYear = charges.startMoment.year();
    if (type === 'day') {
        return charges.map((_, __, ___, i) => daysInMonth(startYear, startMonth, i) * amount);
    }
    // type === 'flat'
    return charges.map((_) => amount);
}

function simpleNonbypassCharges(surplus, rates) {
    if (!rates.net_meter_options) {
        return surplus.map(() => 0.0).reaggregateInterval(TIME_INTERVALS.MONTH);
    }

    const multiplier = rates.net_meter_options.nonbypassable_per_kwh || 0.0;
    return surplus.map((i) => i * multiplier).reaggregateInterval(TIME_INTERVALS.MONTH);
}

function flatRateCharges(usage, surplus, rate, netMetering?) {
    if (netMetering) {
        const netUsage = usage.clone().subSeries(surplus);
        return netUsage.map((kwhUsed) => kwhUsed * rate);
    }
    return usage.map((kwhUsed) => kwhUsed * rate);
}

/**
 * Calculates the net export compensation (e.g. $) at the end of true-up period.
 * This is based on surplus energy use that is not consumed in subsequent months
 * @param usageAfter Monthly usage from grid after solar offset
 * @param surplusAfter Monthly surplus to grid after solar offset
 * @param netExportRate Net export rate (currency/kWh)
 * @returns Monthly time series containing the net export compensation for any
 *          surplus energy at the end of the true-up period.
 */
export function calcTrueUpNetExport(usageAfter, surplusAfter, netExportRate) {
    if (usageAfter.intervalType !== TIME_INTERVALS.MONTH) {
        throw new Error('usageAfter is expected to have a monthly interval');
    }
    if (surplusAfter.intervalType !== TIME_INTERVALS.MONTH) {
        throw new Error('surplusAfter is expected to have a monthly interval');
    }
    const zipped = TimeSeries.zipSeries({ usageAfter, surplusAfter });
    let accumEnergyCredit = 0;
    const netExportAdjustment = mapAnnualTrueUp(zipped, ({ usageAfter, surplusAfter }, isLastMonthOfTrueUp) => {
        // Usage and surplus can both be positive in a given month.
        // I believe this is due to summing over time slices (e.g. hours), each of which
        // has either a positve (or zero) usage and positive (or zero) surplus
        const netUsage = usageAfter - surplusAfter;
        if (netUsage <= 0) {
            accumEnergyCredit = accumEnergyCredit - netUsage;
        } else {
            const appliedCredit = Math.min(accumEnergyCredit, netUsage);
            accumEnergyCredit = accumEnergyCredit - appliedCredit;
        }

        if (isLastMonthOfTrueUp) {
            const netExportCompensation = -accumEnergyCredit * netExportRate;
            accumEnergyCredit = 0;
            return netExportCompensation;
        }

        return 0;
    });
    return netExportAdjustment;
}

/**
 * Zeroes out monthly credits and accumulates them to apply against subsequent months, with any
 * excess at the end of the true-up period being zeroed out
 * @param offsetableCharges Charges or credits (negative charges) computed without true-up assumptions,
 *                          namely that credits are applied in the month they appear (rather than subsequent months)
 * @returns Monthly time series of charge adjustments to account for credit rollover throughout the duration of the
 *          true-up period. Any excess credits accumulated at the end of the true-up period get reset to 0.
 */
export function calcTrueUpAdjustments(offsetableCharges) {
    let accumMoneyCredit = 0;
    return mapAnnualTrueUp(offsetableCharges, (monthCharge, isLastMonthOfTrueUp) => {
        let adjMonthCharge;

        // credits are negative charges
        if (monthCharge < 0) {
            accumMoneyCredit = accumMoneyCredit - monthCharge;
            adjMonthCharge = -monthCharge;
        } else {
            const appliedCredit = Math.min(accumMoneyCredit, monthCharge);
            adjMonthCharge = 0 - appliedCredit;
            accumMoneyCredit = accumMoneyCredit - appliedCredit;
        }

        if (isLastMonthOfTrueUp) {
            accumMoneyCredit = 0;
        }

        return adjMonthCharge;
    });
}

export const BasicUtilityRatesSimple: IPipelineModule = {
    required: true,
    description: 'Model Utility Rate Pricing',
    parameters: [
        {
            description: 'Customize Energy Charges',
            hidden: true,
            path: 'energy_rates',
            type: ParamValueType.RatesFull,
            rate_import_path: 'energy_rates',
            label_key: 'energy',
            net_meter_parameters: { flat_rate: true },
            default: {
                net_meter_options: {
                    net_meter_type: 'flat_rate',
                    nonbypassable_per_kwh: 0.025,
                    annual_true_up: null,
                },
                rate_schedules: [
                    {
                        rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                        net_meter_flat: 0.14,
                    },
                ],
                tables: [
                    {
                        days: null,
                        table: [
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1,
                        ],
                    },
                ],
            },
        },
        {
            description: 'Customize Demand Charges',
            hidden: true,
            rate_import_path: 'demand_rates',
            path: 'demand_rates',
            type: ParamValueType.RatesFull,
            label_key: 'demand',
            default: {
                rate_schedules: [
                    {
                        rate_tiers: [{ tier_value: 0.14, tier_cap: null, abs_cap: null }],
                    },
                ],
                tables: [
                    {
                        days: null,
                        table: [
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
                            1, 1, 1, 1, 1, 1, 1, 1, 1,
                        ],
                    },
                ],
            },
        },
        {
            type: ParamValueType.FixedCharges,
            default: [],
            description: 'Customize Fixed Charges',
            hidden: true,
            path: 'fixed_rates',
        },
        {
            type: ParamValueType.MinimumBill,
            default: { type: 'flat', active: false, amount: 0 },
            description: 'Customize Minimum Bill',
            hidden: true,
            path: 'minimum_bill',
        },
    ],
    module: {
        main: function main(state, params) {
            const { projectFinancialSettings } = state;

            if (projectFinancialSettings) {
                const { rate_mode, flat_rate_kwh, net_metering, annual_true_up, net_export_rate_kwh } =
                    projectFinancialSettings;

                if (rate_mode === 'FLAT_KWH' || !state.projectRates) {
                    this.flatRate(state, flat_rate_kwh, net_metering, annual_true_up, net_export_rate_kwh);
                    return;
                }
            }

            this.simpleRate(state, state.projectRates || params);
        },
        flatRate: function flatRate(state: IPipelineState, rate, netMetering, annualTrueUp, netExportRate) {
            const { consumption, consumptionAfter, surplus } = state;

            const usageBefore = consumption.reaggregateInterval(TIME_INTERVALS.MONTH);
            // net consumption
            const usageAfter = consumptionAfter.reaggregateInterval(TIME_INTERVALS.MONTH);

            const surplusBefore = consumption.map(() => 0.0).reaggregateInterval(TIME_INTERVALS.MONTH);
            // net production
            const surplusAfter = surplus.reaggregateInterval(TIME_INTERVALS.MONTH);

            const chargesBefore = flatRateCharges(usageBefore, surplusBefore, rate, netMetering);
            const chargesAfter = flatRateCharges(usageAfter, surplusAfter, rate, netMetering);

            if (annualTrueUp) {
                const trueUpAdjustments = calcTrueUpAdjustments(chargesAfter);

                const netExportAdjustment = calcTrueUpNetExport(usageAfter, surplusAfter, netExportRate);

                state.utilityAfterMonthly.addSeries(trueUpAdjustments);
                state.utilityAfterMonthly.addSeries(netExportAdjustment);
            }

            state.netMeterFlag = !!netMetering;
            state.utilityBeforeMonthly.addSeries(chargesBefore);
            state.utilityAfterMonthly.addSeries(chargesAfter);
        },
        simpleRate: function simpleRate(state, rates) {
            const { consumption, surplus, consumptionAfter } = state;
            const { demand_rates, energy_rates, fixed_rates, minimum_bill } = rates;

            const annualTrueUp = energy_rates?.net_meter_options?.annual_true_up;

            const zeroes = consumption.reaggregateInterval(TIME_INTERVALS.MONTH).map(() => 0.0);

            const usageBefore = consumption;
            const usageAfter = consumptionAfter;
            const surplusAfter = surplus;
            const surplusBefore = consumption.map(() => 0.0);

            if (energy_rates) {
                state.netMeterFlag = !!energy_rates.net_meter_options;
                state.ecBeforeMonthly = simpleEnergyCharges(usageBefore, surplusBefore, energy_rates);
                state.ecAfterMonthly = simpleEnergyCharges(usageAfter, surplusAfter, energy_rates);
                state.ncBeforeMonthly = simpleNonbypassCharges(surplusBefore, energy_rates);
                state.ncAfterMonthly = simpleNonbypassCharges(surplusAfter, energy_rates);
            } else {
                state.ecBeforeMonthly = zeroes.clone();
                state.ecAfterMonthly = zeroes.clone();
                state.ncBeforeMonthly = zeroes.clone();
                state.ncAfterMonthly = zeroes.clone();
            }

            if (demand_rates) {
                state.dcBeforeMonthly = simpleDemandCharges(usageBefore, demand_rates);
                state.dcAfterMonthly = simpleDemandCharges(usageAfter, demand_rates);
            } else {
                state.dcBeforeMonthly = zeroes.clone();
                state.dcAfterMonthly = zeroes.clone();
            }

            if (annualTrueUp) {
                const offsetableCharges = state.ecAfterMonthly.clone().addSeries(state.dcAfterMonthly);
                const trueUpAdjustments = calcTrueUpAdjustments(offsetableCharges);
                const netExportAdjustment = calcTrueUpNetExport(
                    usageAfter.reaggregateInterval(TIME_INTERVALS.MONTH),
                    surplusAfter.reaggregateInterval(TIME_INTERVALS.MONTH),
                    annualTrueUp.net_export_rate_kwh,
                );

                state.utilityAfterMonthly.addSeries(trueUpAdjustments).addSeries(netExportAdjustment);
            }

            state.utilityBeforeMonthly
                .addSeries(state.ecBeforeMonthly)
                .addSeries(state.dcBeforeMonthly)
                .addSeries(state.ncBeforeMonthly);
            state.utilityAfterMonthly
                .addSeries(state.ecAfterMonthly)
                .addSeries(state.dcAfterMonthly)
                .addSeries(state.ncAfterMonthly);

            if (fixed_rates) {
                state.fcBeforeMonthly = simpleFixedCharges(usageBefore, state.utilityBeforeMonthly, fixed_rates);
                state.fcAfterMonthly = simpleFixedCharges(usageAfter, state.utilityAfterMonthly, fixed_rates);
            } else {
                state.fcBeforeMonthly = zeroes.clone();
                state.fcAfterMonthly = zeroes.clone();
            }

            state.utilityBeforeMonthly.addSeries(state.fcBeforeMonthly);
            state.utilityAfterMonthly.addSeries(state.fcAfterMonthly);

            if (minimum_bill?.active) {
                state.minimumBillMonthly = simpleMinimumBill(state.utilityBeforeMonthly, minimum_bill);
                state.utilityBeforeMonthly.maxSeries(state.minimumBillMonthly);
                state.utilityAfterMonthly.maxSeries(state.minimumBillMonthly);
            }
        },
        debugOutputs: [
            { table: OutputCategory.Rates },
            {
                table: OutputCategory.Rates,
                descr: 'Model Utility Rate Pricing (After Solar)',
                getParamValue: (state) => convertSeriesToOutput(state.utilityAfterMonthly),
                getParamValueMonthly: (state) =>
                    convertSeriesToOutput(state.utilityAfterMonthly, 0.0, TIME_INTERVALS.MONTH),
            },
        ],
    },
};

export const BasicRatesEscalationSimple: IPipelineModule = {
    description: 'Utility Cost Escalator',
    parameters: [
        {
            description: 'Annual Escalation Rate',
            path: 'escalator_yearly',
            type: ParamValueType.Percentage,
            min_value: -1.0,
            max_value: 99.0,
            default: 0.02,
        },
    ],
    module: {
        main: function main(state, params) {
            const { escalator_yearly } = params;
            const { utilityBeforeMonthly, utilityAfterMonthly } = state;

            // this is stupid but apparently it's what people do
            const monthlyCoeffs = utilityBeforeMonthly
                .reaggregateInterval(TIME_INTERVALS.YEAR)
                .map((_i, _j, _k, idx) => 1.0 + escalator_yearly * idx)
                .resampleInterval(utilityBeforeMonthly.intervalType);

            utilityBeforeMonthly.multiplySeries(monthlyCoeffs);
            utilityAfterMonthly.multiplySeries(monthlyCoeffs);

            state.utilityRateEscalation = escalator_yearly;
        },
        debugOutputs: [
            { table: OutputCategory.Rates },
            {
                table: OutputCategory.Rates,
                descr: 'Utility Cost Escalator (After Solar)',
                getParamValue: (state) => convertSeriesToOutput(state.utilityAfterMonthly),
                getParamValueMonthly: (state) =>
                    convertSeriesToOutput(state.utilityAfterMonthly, 0.0, TIME_INTERVALS.MONTH),
            },
        ],
    },
};
