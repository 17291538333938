import * as React from 'react';

import classNames from 'classnames';
import { Classes } from '@blueprintjs/core';
import { IconNames, IconName } from '@blueprintjs/icons';

import Flex from 'reports/components/core/containers/Flex';
import { FormatButton, ButtonBar } from 'reports/modules/report/components/helpers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const PaddedDiv = styled(Flex.ContainerV)`
    padding: 20px;
`;

const Break = styled.hr`
    border-color: #ccc;
`;

export type ChartType = 'area' | 'column' | 'line' | 'bar';

const CHART_OPTIONS: { iconName: IconName; chartType: ChartType }[] = [
    { iconName: IconNames.TIMELINE_AREA_CHART, chartType: 'area' },
    { iconName: IconNames.TIMELINE_BAR_CHART, chartType: 'column' },
    { iconName: IconNames.HORIZONTAL_BAR_CHART, chartType: 'bar' },
    { iconName: IconNames.TIMELINE_LINE_CHART, chartType: 'line' },
];

interface IChartFormProps {
    chartType: ChartType;
    onChange: (chartType: ChartType) => any;
}

export const ChartForm: React.SFC<IChartFormProps> = (props) => {
    const { chartType, children, onChange } = props;

    return (
        <PaddedDiv>
            <ButtonBar>
                {CHART_OPTIONS.map(({ chartType: type, iconName }) => (
                    <FormatButton
                        key={type}
                        icon={iconName}
                        onClick={() => onChange(type)}
                        className={classNames(Classes.MINIMAL, {
                            [Classes.ACTIVE]: type === chartType,
                        })}
                    />
                ))}
            </ButtonBar>
            {children ? (
                <span>
                    {' '}
                    <Break /> {children}{' '}
                </span>
            ) : null}
        </PaddedDiv>
    );
};

export default ChartForm;
