import * as React from 'react';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import { User } from 'reports/models/user';
import { Subscription } from 'reports/models/subscription';
import { canManageBilling } from 'reports/modules/auth/permissions';
import { SummaryCard } from 'reports/modules/settings/common';

import { PlanInfo } from './PlanInfo';
import { TrialPlanInfo } from './TrialPlanInfo';
import { LegacySubscriptionInfo } from './LegacySubscriptionInfo';

interface Props {
    includeHSAdmin?: boolean;
    subscription?: Subscription;
    user: User;
}

const AccountStatusBox = ({ subscription, user }: Props) => {
    const { current_period_end } = user;

    const manageBilling = canManageBilling(user);
    if (!!subscription && subscription.status !== 'incomplete_expired') {
        // only render Billing Admin Callout component if current user is a billing admin
        // and the subscription payment has failed
        return (
            <SummaryCard>
                <PlanInfo
                    manageBilling={manageBilling}
                    subscription={subscription}
                    user={user}
                    referrer={ReferrerTypes.manage_team_page}
                />
            </SummaryCard>
        );
    }
    if (user.legacy_subscription_data) {
        return (
            <SummaryCard>
                <LegacySubscriptionInfo
                    user={user}
                    referrer={ReferrerTypes.manage_team_page}
                    manageBilling={manageBilling}
                />
            </SummaryCard>
        );
    }
    return (
        <SummaryCard>
            <TrialPlanInfo
                manageBilling={manageBilling}
                periodEnd={current_period_end}
                user={user}
                referrer={ReferrerTypes.manage_team_page}
            />
        </SummaryCard>
    );
};

export { AccountStatusBox };
