import moment from 'moment';
import { map, range } from 'lodash';

import * as time from 'reports/utils/time';

import * as uc from 'reports/models/user_consumption';
import * as up from 'reports/models/usage_profile';

import { getDefaultEnergyFromProfile } from './index';

export const ERR_INCOMPLETE_INPUT =
    'Cannot generate consumption with less than 12 months of data and no usage \
                                     profile. Try adding a usage profile.';

// TODO: allow optional usageProfile
export function calculateMonthlyKwh(userInputKwh: uc.IUserInput, usageProfile: up.UsageProfile): number[] {
    if (Object.keys(userInputKwh).length === 12) {
        return map(userInputKwh, (month) => month.energy!);
    }

    const defaultEnergy = getDefaultEnergyFromProfile(usageProfile);
    const defaultMonth = time.getCurrentMonth();

    const userInput = Object.keys(userInputKwh).length ? userInputKwh : { [defaultMonth]: { energy: defaultEnergy } };
    const [scaleFactors, scaleFactorAvg] = getMonthlyScaleFactors(userInput, usageProfile);

    return range(1, 13).map((month) => {
        const scaleFactor = scaleFactors[month] == null ? scaleFactorAvg : scaleFactors[month];
        return usageProfile.monthly[month] * scaleFactor;
    });
}

/**
 * Calculate set of scale factors given a set of user inputs and a usage profile. Use specific scale factor for given
 * monthly input values, otherwise use a series average.
 *
 * For example: if a single month of user input is given for January (month = 1),
 *     scaleFactors = { 1: userInput[1] / monthlyUsage[1] };
 *     scaleFactorAvg = userInput[1] / monthlyUsage[1];
 *
 * if two months of inputs are given,
 *     scaleFactors = { 1: userInput[1] / monthlyUsage[1], 4: userInput[4] / monthlyUsage[4] };
 *     scaleFactorAvg = (userInput[1] + userInput[4]) / (monthlyUsage[1] + monthlyUsage[4]);
 * etc. etc.
 *
 * The `scaleFactorAvg` is used to scale monthly consumption values for the remaining months of the year.
 */
export function getMonthlyScaleFactors(userInput: uc.IUserInput, usageProfile: up.UsageProfile) {
    const scaleFactors: { [k: number]: number } = {};

    let inputSeriesTotal = 0;
    let profileSeriesTotal = 0;

    map(userInput, (input, month) => {
        const energy = input.energy!;
        const usage = usageProfile.monthly![month];

        // Use month specific scale factors for given months
        scaleFactors[month] = energy / usage;

        // Get series avg value for remaining available months
        inputSeriesTotal += energy;
        profileSeriesTotal += usage;
    });

    // Use series average for remaining months
    const scaleFactorAvg = inputSeriesTotal / profileSeriesTotal;
    return [scaleFactors, scaleFactorAvg];
}

/*
 * Generate a year of hourly consumption data in a given local timezone (same as Green Button dates).
 * Consumption values will be scaled using a usage profile, if provided, otherwise an hourly mean per month
 * will be used.
 *
 * user input:
 *     energy: watt hours
 *     month: 1 indexed
 */
export class GeneratedConsumption {
    input: uc.IUserInput;
    inputMonths: string[]; // time.Month[]
    yearStart: number; // Unix timestamp of local year start
    utcOffset: number;
    profile?: up.UsageProfile;

    constructor(
        utcOffset: number,
        userInput: uc.IUserInput,
        usageProfile?: up.UsageProfile | null,
        yearStart?: moment.Moment,
    ) {
        const month = time.getCurrentMonth();
        const defaultEnergyWh = getDefaultEnergyFromProfile(usageProfile) * 1000;

        // Store all consumption in watt hours
        this.input = Object.keys(userInput).length ? userInput : { [month]: { energy: defaultEnergyWh } };
        this.inputMonths = Object.keys(this.input);

        this.profile = usageProfile || undefined;
        this.utcOffset = utcOffset;

        // By default, use January 1, 2019 local UTC format with offset, assume non-leap year and ignore DST
        this.yearStart = yearStart
            ? moment([yearStart.utc().year()]).utcOffset(utcOffset, true).unix()
            : moment([time.NON_LEAP_YEAR]).utcOffset(utcOffset, true).unix();
    }

    local8760Energy(): uc.IIntervalData[] {
        if (this.profile && this.profile.data) {
            return this.interpolateWithProfile();
        }
        return this.interpolateWithoutProfile();
    }

    interpolateWithProfile() {
        const [scaleFactors, scaleFactorAvg] = getMonthlyScaleFactors(this.input, this.profile!);

        return map(this.profile!.data!.hourly, (hour, idx) => {
            const intervalStart = this.yearStart + idx * 60 * 60;
            const month = time.getMonthFromUnix(intervalStart, this.utcOffset);

            const scaleFactor = scaleFactors[month] !== undefined ? scaleFactors[month] : scaleFactorAvg;
            return {
                energy: scaleFactor * hour.consumption,
                interval_start: intervalStart,
            };
        });
    }

    interpolateWithoutProfile() {
        if (this.inputMonths.length !== 12 && this.inputMonths.length !== 1) {
            // TODO: handle incomplete consumption input with no usage profile
            throw Error(ERR_INCOMPLETE_INPUT);
        }

        // Calculate hourly average if single month input
        const month = parseInt(this.inputMonths[0], 10) as time.Month;
        const hourlyAvg = this.inputMonths.length === 1 && this.input[month].energy! / time.hoursInMonth(month);

        return range(time.HOURS_IN_YEAR).map((hourIdx) => {
            const intervalStart = this.yearStart + hourIdx * 60 * 60;
            const intervalMonth = time.getMonthFromUnix(intervalStart, this.utcOffset);

            const energy =
                hourlyAvg !== false ? hourlyAvg : this.input[intervalMonth].energy! / time.hoursInMonth(intervalMonth);

            return {
                energy,
                interval_start: intervalStart,
            };
        });
    }
}
