import { every } from 'lodash';

import * as React from 'react';

import { connect } from 'react-redux';
import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import { Button, Classes, Dialog, DialogProps, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as profile from 'reports/models/profile';
import * as proj from 'reports/models/project';
import * as acc from 'reports/models/ac_config';
import * as analytics from 'reports/analytics';
import * as auth from 'reports/modules/auth';

import { SaveCancelButtons } from 'reports/components/core/controls';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { Form, handleRequestException } from 'reports/components/forms';
import { FormBasicSelect, FormTextAreaInput, FormTextInput } from 'reports/components/forms/inputs/experimental';
import FormProfileSelect from 'reports/modules/profile/components/FormProfileSelect';

import { mapValuesDeep } from 'reports/utils/helpers';

import classNames from 'classnames';
import { User } from '@sentry/browser';

const LABEL_WIDTH = 130;
const DIALOG_WIDTH = 530;
const INPUT_WIDTH = 350;
const TEXT_AREA_HEIGHT = 90;

interface IDialogState {
    invalidFields: any;
    name: string;
    profiles: profile.Profile[];
    description?: string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
interface IOwnProps {
    project: proj.Project;
}
type IProps = IDispatchProps & IStateProps & IOwnProps & DialogProps;

class _EditProjectDetailsDialog extends React.PureComponent<IProps, IDialogState> {
    isOwner() {
        return this.props.user?.user_id === this.props.project.creator_id;
    }

    render() {
        const dialogStyle = {
            width: DIALOG_WIDTH,
        };

        const { project, isOpen, getAcConfigs, onClose } = this.props;

        return (
            <Dialog
                title={`Edit Project: ${project.name}`}
                icon={IconNames.PROJECTS}
                isOpen={isOpen}
                onClose={onClose}
                style={dialogStyle}
            >
                <Form baseValue={project} onSubmit={this.saveForm} exceptionHandler={handleRequestException}>
                    {({ dirty, submitForm, submitting, fieldErrors }) => (
                        <>
                            <div className={Classes.DIALOG_BODY}>
                                <FormTextInput
                                    inline
                                    bold
                                    fill
                                    path="name"
                                    label="Name"
                                    placeholder="Project name"
                                    labelWidth={LABEL_WIDTH}
                                />
                                <FormTextInput
                                    fill
                                    inline
                                    bold
                                    path="address"
                                    label="Address"
                                    placeholder="Project address"
                                    labelWidth={LABEL_WIDTH}
                                    helperText="Note: Editing this field will not change the project location."
                                />
                                <FormTextAreaInput
                                    fill
                                    inline
                                    bold
                                    path="description"
                                    label="Description"
                                    labelWidth={LABEL_WIDTH}
                                    className={`${Classes.INPUT}`}
                                    height={TEXT_AREA_HEIGHT}
                                    resize={'none'}
                                />
                                <FormTextInput
                                    inline
                                    bold
                                    fill
                                    path="customer_name"
                                    label="Customer Name"
                                    placeholder="Customer name"
                                    labelWidth={LABEL_WIDTH}
                                />
                                {this.isOwner() && (
                                    <FormBasicSelect<proj.ProjectUser>
                                        inline
                                        bold
                                        fill
                                        label="Change Owner"
                                        labelWidth={LABEL_WIDTH}
                                        path="creator_id"
                                        isNullable={false}
                                        dataSource={{
                                            async: false,
                                            items: project.users,
                                            keyLookup: (user) => user.user_id,
                                        }}
                                        itemRenderer={ProjectUserItemRenderer}
                                        matchSelectWidth={true}
                                    />
                                )}
                                <FormBasicSelect<acc.AcConfig>
                                    inline
                                    bold
                                    fill
                                    label="AC Interconnect Configuration"
                                    labelWidth={LABEL_WIDTH}
                                    path="ac_config_id"
                                    isNullable={true}
                                    dataSource={{
                                        async: true,
                                        query: async () => await getAcConfigs(),
                                        keyLookup: (acConfig) => acConfig.ac_config_id,
                                        itemLookup: (acConfigId, state) => acc.selectors.byId(state, acConfigId)!,
                                    }}
                                    itemRenderer={AcConfigItemRenderer}
                                    matchSelectWidth={true}
                                    noneSelected="None specified"
                                />
                                <FormProfileSelect
                                    label="Profile"
                                    path="profile_id"
                                    type="project"
                                    labelWidth={LABEL_WIDTH}
                                    fill
                                    isNullable={true}
                                    noneSelected="None specified"
                                    maxButtonWidth={INPUT_WIDTH}
                                    matchSelectWidth={true}
                                />
                            </div>
                            <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                                <SaveCancelButtons
                                    onSave={async () => {
                                        await submitForm();
                                        onClose && onClose(null as any);
                                    }}
                                    onCancel={() => {
                                        onClose && onClose(null as any);
                                    }}
                                    hasChanges={dirty}
                                    // If there are field errors or the form is currently submitting, disable buttons
                                    disabled={
                                        submitting ||
                                        !every(mapValuesDeep(fieldErrors, (val) => val == null || val.length === 0))
                                    }
                                />
                            </div>
                        </>
                    )}
                </Form>
            </Dialog>
        );
    }

    saveForm = async (form: proj.IProjectSaveForm) => {
        const { project } = this.props;
        const prevProfileId = project.profile && project.profile.profile_id;
        const newProfileId = form.profile_id;

        const promise = this.props.saveProject(form);

        addPromiseToasts(promise, {
            initial: 'Saving project...',
            onSuccess: 'Successfully saved project.',
            onCatch: 'Error saving project.',
        });

        return await promise.then(() => {
            if (prevProfileId !== newProfileId) {
                analytics.track('profile.project_profile_change', {
                    project_id: project.project_id,
                    old_profile_id: prevProfileId,
                    new_profile_id: newProfileId,
                });
            }
        });
    };
}

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state),
});

const mapDispatchToProps = bindActions({
    saveProject: (project) => proj.api.save(project),
    getAcConfigs: acc.api.index,
});

const EditProjectDetailsDialog = connect<IStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
)(_EditProjectDetailsDialog);

const ProjectUserItemRenderer = (user: proj.ProjectUser) => {
    return {
        key: user.user_id,
        text: `${user.first_name} ${user.last_name}`,
    };
};

const AcConfigItemRenderer = (acConfig: acc.AcConfig) => {
    return {
        key: acConfig.ac_config_id,
        text: acConfig.toString(),
    };
};

class EditProjectDetailsLauncher extends React.PureComponent<{
    project: proj.Project;
    user: User;
}> {
    state = {
        dialogOpen: false,
    };

    render() {
        return (
            <div>
                <Button
                    text="Edit"
                    icon={IconNames.WRENCH}
                    intent={Intent.WARNING}
                    onClick={() => {
                        analytics.track('project.edit_project_details', {
                            project_id: this.props.project.project_id,
                            team_id: this.props.user.team_id,
                        });
                        this.toggleDialog(true);
                    }}
                />
                <EditProjectDetailsDialog
                    project={this.props.project}
                    isOpen={this.state.dialogOpen}
                    onClose={() => this.toggleDialog(false)}
                />
            </div>
        );
    }

    toggleDialog = (dialogOpen: boolean) => this.setState({ dialogOpen });
}

export default EditProjectDetailsLauncher;
