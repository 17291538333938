import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useRoute } from 'react-router5';
import { actions as routerActions } from 'redux-router5';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import { BillingContext } from 'reports/modules/settings/billing/Context';

import { RouteComponent } from 'reports/routing';

import { BillingOverview } from '../components/BillingOverview';

const BillingSettingsViews = React.memo(() => {
    const { route } = useRoute();
    const dispatch = useDispatch();

    const navigateToBillingOverview = () => dispatch(routerActions.navigateTo('app.settings.team.billing'));
    const contextValue = React.useMemo(
        () => ({
            dialog: route.params.dialog,
            interval: route.params.interval,
            message: route.params.message,
            product: route.params.product,
            referrer: route.params.referrer || ReferrerTypes.billing_page,
        }),
        [route.params],
    );
    return (
        <RouteComponent name="app.settings.team.billing">
            {({ subscription, user }) => (
                <BillingContext.Provider value={contextValue}>
                    <BillingOverview
                        navigateToBillingOverview={navigateToBillingOverview}
                        subscription={subscription}
                        user={user}
                    />
                </BillingContext.Provider>
            )}
        </RouteComponent>
    );
});

export default BillingSettingsViews;
