import * as React from 'react';
import { connect } from 'react-redux';

import { IAppState } from 'reports/types';

import { FavoriteStar } from 'reports/components/core/controls';
import { CommonColumns } from 'reports/components/library/helpers';
import { LibraryConsumer } from 'reports/components/library/main/context';
import { ColWidth, SortType } from 'reports/components/core/tables/DataTable';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';

import * as rep from 'reports/models/report';
import * as auth from 'reports/modules/auth';
import { addPromiseToastsToggle } from 'reports/components/toasts';
import { bindActions } from 'reports/utils/redux';

interface IOwnProps {
    openReport: (report: rep.Report) => void;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

const ReportsList: React.SFC<IProps> = (props) => {
    return (
        <LibraryConsumer>
            {({ updateSearch }) => (
                <ResourceTable<rep.Report>
                    columns={[
                        {
                            colWidth: ColWidth.SMALL,
                            headerText: 'Favorite',
                            renderCell: (report) => (
                                <FavoriteStar
                                    empty={!report.bookmarked()}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addPromiseToastsToggle(
                                            'favorite',
                                            props.setBookmarked(report, !report.bookmarked()),
                                            'report',
                                            report.name,
                                            report.bookmarked(),
                                        );
                                    }}
                                />
                            ),
                        },
                        {
                            sort: { name: 'name', type: SortType.ALPHA },
                            colWidth: ColWidth.LARGE,
                            headerText: 'Report Name',
                            renderCell: (report) => (
                                <div style={{ width: '100%', textAlign: 'left' }}>{report.name}</div>
                            ),
                        },
                        CommonColumns.lastModified(),
                        CommonColumns.created(),
                        CommonColumns.creator(),
                        CommonColumns.team(),
                        CommonColumns.publicCol(props.user),
                        CommonColumns.actionsCol(
                            props.user,
                            {
                                title: 'Delete Report Template',
                                prompt: `Deleting this report template will remove this template for all users on your team.
                                    Would you like to delete this report?`,
                                resName: 'Report Template',
                            },
                            props.deleteReport,
                            updateSearch,
                        ),
                    ]}
                    onRowClick={(report) => props.openReport(report)}
                />
            )}
        </LibraryConsumer>
    );
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions({
    setBookmarked: (report, bookmarked) => rep.api.patchTeamSettings({ bookmarked, report_id: report.report_id }),
    deleteReport: (report) => rep.api.delete({ report_id: report.report_id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);
