import * as React from 'react';

import * as cfg from 'reports/config';

import { DetailLine, pluralizeText } from 'reports/modules/settings/common';

import { Subscription } from 'reports/models/subscription';

interface Props {
    subscription: Subscription;
    projectLimits: cfg.IProjectLimits;
}

const ProjectLimits = ({ subscription, projectLimits }: Props) => {
    const { projects_per_license_per_month, annual_multiplier } = projectLimits;
    const { plan_type, paid_seats } = subscription;
    if (subscription.paid_seats) {
        const isAnnualSubscription = plan_type === 'year';

        let calculationString = `${projects_per_license_per_month} projects x `;
        let totalProjects = projects_per_license_per_month * paid_seats;

        if (isAnnualSubscription) {
            calculationString += `${annual_multiplier} months x `;
            totalProjects *= annual_multiplier;
        }
        calculationString += pluralizeText('license', subscription.paid_seats);

        return (
            <>
                <DetailLine>{calculationString}</DetailLine>
                <DetailLine>
                    {totalProjects} projects / {isAnnualSubscription ? 'year' : 'month'}
                </DetailLine>
            </>
        );
    }

    return null;
};

export { ProjectLimits };
