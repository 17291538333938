import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import { FormattedMessage } from 'react-intl';
import { Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Translations from 'reports/localization/strings';
import { percentage, stringifyNumber } from 'reports/utils/formatters';
import { MONTHS_ABBREV } from 'reports/utils/constants';

import { actions, actions as projActions } from 'reports/modules/project';
import * as projSelectors from 'reports/modules/project/selectors';
import { IReportContext, IWidgetRenderProps, registerWidget } from 'reports/modules/report/widgets';
import { WidgetDataTable } from 'reports/modules/report/components/helpers';
import {
    aggregateResultsByMonth,
    getModulesBySegment,
} from 'reports/modules/report/components/widgets/ShadingByFieldSegmentTable';
import { IModuleLevelResults, ISimResultsSummary } from 'reports/models/simulation';
import { FieldComponent } from 'reports/models/field_component';

const getSolarAccessPercentage = (poaIrradiance: number, shadedIrradiance: number, precision: number) => {
    const monthSolarAccess = poaIrradiance <= 0 ? 0 : shadedIrradiance / poaIrradiance;
    const monthSolarAccessPercentage = percentage(monthSolarAccess, {
        precision,
    });
    return monthSolarAccessPercentage;
};

const DEFAULT_MONTH_IRRADIANCE_DATA = {
    shaded_irradiance: 0,
    poa_irradiance: 0,
    grid_power: 0,
};
interface IFieldSegmentLevelResults {
    [fieldSegmentId: number]: {
        [month: number]: ISimResultsSummary; // month is 1 .. 12
    };
}

interface IFieldSegmentSolarAccess {
    [fieldSegmentId: number]: string[];
}

interface IMonthlySolarAccessResults {
    fsSolarAccess: IFieldSegmentSolarAccess;
    simMonthlySolarAccess: string[];
    simMonthlyGridPower: string[];
}

type IContext = Pick<IReportContext, 'simulation' | 'design'>;
type IRenderProps = IWidgetRenderProps<object, IContext>;
type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

type IProps = IRenderProps & IStateProps & IDispatchProps;
const _SolarAccessByMonthTable: React.FC<IProps> = ({
    context,
    className,
    loadFieldComponents,
    getModuleLevelResults,
    fieldComponents,
    moduleResults,
}) => {
    const { simulation, design } = context;
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [loadDataError, setLoadDataError] = React.useState<boolean>(false);
    const [solarAccessResults, setSolarAccessResults] = React.useState<IMonthlySolarAccessResults | null>(null);

    const getModuleLevelData = async () => {
        setIsLoading(true);
        setLoadDataError(false);
        try {
            await getModuleLevelResults(simulation);
        } catch (e) {
            setLoadDataError(true);
        }
        setIsLoading(false);
    };

    const getFieldComponents = async () => {
        setIsLoading(true);
        try {
            await loadFieldComponents(design.design_id);
        } catch (e) {
            setLoadDataError(true);
        }
        setIsLoading(false);
    };

    const calcFieldSegSolarAccess = (fieldComponents: FieldComponent[], moduleResults: IModuleLevelResults) => {
        const results: IFieldSegmentLevelResults = {};
        const fieldModulesBySegment = getModulesBySegment(fieldComponents);
        for (const [segmentId, segmentModules] of Object.entries(fieldModulesBySegment)) {
            const segmentModuleResults = segmentModules.map((mod) => moduleResults[mod.field_component_id]);
            results[segmentId] = aggregateResultsByMonth(segmentModuleResults);
        }

        const fsSolarAccess: IFieldSegmentSolarAccess = {};
        fieldSegments.map((fs) => {
            const monthlyData = results[fs.field_segment_id] || {};
            const monthlySolarAccess: string[] = [];
            for (let month = 1; month <= 12; month += 1) {
                const dataForMonth = monthlyData[month] || DEFAULT_MONTH_IRRADIANCE_DATA;
                const { shaded_irradiance, poa_irradiance } = dataForMonth;
                monthlySolarAccess.push(getSolarAccessPercentage(poa_irradiance, shaded_irradiance, 0));
            }
            fsSolarAccess[fs.field_segment_id] = monthlySolarAccess;
        });

        const simMonthlySolarAccess: string[] = [];
        const simMonthlyGridPower: string[] = [];
        for (let month = 1; month <= 12; month += 1) {
            const dataForMonth = simulation.metadata.monthly_data[month] || DEFAULT_MONTH_IRRADIANCE_DATA;

            const shadedIrr = dataForMonth.shaded_irradiance;
            const poaIrr = dataForMonth.poa_irradiance;
            const gridPower = dataForMonth.grid_power;
            simMonthlySolarAccess.push(getSolarAccessPercentage(poaIrr, shadedIrr, 1));
            simMonthlyGridPower.push(stringifyNumber(gridPower / 1000, { precision: 1 }));
        }
        setSolarAccessResults({
            fsSolarAccess,
            simMonthlySolarAccess,
            simMonthlyGridPower,
        });
    };

    React.useEffect(() => {
        if (simulation.simulation_id && simulation.status === 'completed') {
            if (moduleResults == null) {
                getModuleLevelData();
            }
            if (fieldComponents == null) {
                getFieldComponents();
            }
            if (moduleResults != null && fieldComponents != null) {
                calcFieldSegSolarAccess(fieldComponents, moduleResults);
            } else {
                setSolarAccessResults(null);
            }
        } else {
            throw new Error('Simulation data not available. The simulation can be run from the project page.');
        }
    }, [moduleResults, fieldComponents]);

    const fieldSegments = design.field_segments;
    const showTable = !isLoading && !loadDataError;
    return (
        <WidgetDataTable className={className} fontSizePixel={10}>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.general.description} />
                    </th>
                    {MONTHS_ABBREV.map((month, idx) => (
                        <th key={idx}>
                            <FormattedMessage {...month} />
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {loadDataError && (
                    <tr>
                        <td className="centered" colSpan={13}>
                            <div>
                                <Icon icon="warning-sign" style={{ marginRight: '8px' }} />
                                <span>
                                    <FormattedMessage {...Translations.errors.module_data_not_available} />
                                </span>
                            </div>
                        </td>
                    </tr>
                )}
                {isLoading && (
                    <tr>
                        <td className="centered" colSpan={13}>
                            <Spinner size={20} />
                        </td>
                    </tr>
                )}
                {solarAccessResults != null && showTable && (
                    <>
                        {fieldSegments.map((fs) => (
                            <tr key={`fs_row_${fs.field_segment_id}`}>
                                <td key={fs.field_segment_id}>{fs.description}</td>
                                {solarAccessResults.fsSolarAccess[fs.field_segment_id].map((solarAccess, monthIdx) => (
                                    <td key={`fs_month_solar_access_${(fs.field_segment_id, monthIdx)}_${solarAccess}`}>
                                        {solarAccess}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <td key="summary_solar_access">
                                <b>Solar Access, weighted by kWp</b>
                            </td>
                            {solarAccessResults.simMonthlySolarAccess.map((solarAccess, monthIdx) => (
                                <td key={`summary_month_solar_access_${monthIdx}`}>{solarAccess}</td>
                            ))}
                        </tr>
                        <tr>
                            <td key="summary_grid_power">
                                <b>AC Power (kWh)</b>
                            </td>
                            {solarAccessResults.simMonthlyGridPower.map((gridPower, monthIdx) => (
                                <td key={`summary_month_grid_power_${monthIdx}`}>{gridPower}</td>
                            ))}
                        </tr>
                    </>
                )}
            </tbody>
        </WidgetDataTable>
    );
};

const mapStateToProps = (state, ownProps: IRenderProps) => {
    const { design, simulation } = ownProps.context;
    return {
        fieldComponents: projSelectors.getFieldComponents(state, design.design_id),
        moduleResults: projSelectors.getModuleResults(state, simulation.simulation_id),
    };
};

const mapDispatchToProps = bindActions(() => ({
    getModuleLevelResults: (simulation) => projActions.loadModuleResults(simulation.simulation_id),
    loadFieldComponents: (designId) => actions.loadFieldComponents(designId),
}));

const SolarAccessByMonthTable = connect(mapStateToProps, mapDispatchToProps)(_SolarAccessByMonthTable);

const SolarAccessByMonthTableWidget = registerWidget('solar_access_by_month_table', {
    Component: SolarAccessByMonthTable,
    metadata: {
        category: 'project',
        dimensions: { h: 200, w: 840 },
        displayName: Translations.widgets.solar_access_by_month_table_header,
        icon: IconNames.TH,
    },
    dependencies: ['simulation', 'design'],
});

export default SolarAccessByMonthTableWidget;
