import moment from 'moment';
import * as React from 'react';
import { Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { fromNow, humanizeTimestamp } from 'reports/utils/formatters';

import { User } from 'reports/models/user';
import * as tm from 'reports/models/team';
import * as auth from 'reports/modules/auth';
import { canModifySharedTeamResource } from 'reports/modules/auth/permissions';
import { addPromiseToasts } from 'reports/modules/Toaster';

import { IconButton, PrimaryIntent } from 'reports/components/core/controls';
import { ColWidth, IColumn, SortType } from 'reports/components/core/tables/DataTable';
import { promptModalBoolean } from 'reports/components/dialog';
import { MODAL_CANCELED_EXCEPTION } from 'reports/components/dialog/components/BooleanPrompt';

export function name<T extends { name: string }>(): IColumn<T> {
    return {
        sort: { name: 'name', type: SortType.ALPHA },
        colWidth: ColWidth.LARGE,
        headerText: 'Name',
        renderCell: (resource) => <div style={{ width: '100%', textAlign: 'left' }}>{resource.name}</div>,
    };
}

export function lastModified<T extends { last_modified?: moment.Moment; created?: moment.Moment }>(): IColumn<T> {
    return {
        sort: { name: 'last_modified' },
        headerText: 'Last Modified',
        renderCell: (resource) => (
            <>
                {resource.last_modified
                    ? fromNow(resource.last_modified)
                    : resource.created
                    ? fromNow(resource.created)
                    : '-'}
            </>
        ),
    };
}

export function created<T extends { created?: moment.Moment }>(): IColumn<T> {
    return {
        sort: { name: 'created' },
        headerText: 'Date Created',
        renderCell: (resource) => <>{resource.created ? humanizeTimestamp(resource.created) : '-'}</>,
    };
}

export function creator<T extends { creator?: User }>(): IColumn<T> {
    return {
        sort: { name: 'creator', type: SortType.ALPHA },
        headerText: 'Creator',
        renderCell: (resource) => <>{resource.creator ? resource.creator.fullName() : '-'}</>,
    };
}

export function team<T extends { team?: tm.Team }>(): IColumn<T> {
    return {
        sort: { name: 'team', type: SortType.ALPHA },
        headerText: 'Team',
        renderCell: (resource) => <>{resource.team ? resource.team.name : '-'}</>,
    };
}

// Can't call this "public" since that's a reserved keyword.
export function publicCol<T extends { public: boolean }>(user: User): IColumn<T> {
    return {
        sort: { name: 'public' },
        colWidth: ColWidth.SMALL,
        headerText: 'Public',
        renderCell: (resource) => (
            <Icon
                icon={resource.public ? IconNames.UNLOCK : IconNames.LOCK}
                iconSize={Icon.SIZE_LARGE}
                style={{ color: Colors.GRAY2 }}
            />
        ),
        hidden: !auth.permissions.helioscopeAdmin(user),
    };
}

export function actionsCol<T extends { public: boolean; team_id: number }>(
    user: User,
    deleteProps: {
        title: string;
        prompt: string;
        resName: string;
        checkFn?: (res: T) => Promise<string | null>;
    },
    deleteFn: (resource: T) => any,
    updateSearch: () => any,
): IColumn<T> {
    return {
        colWidth: ColWidth.SMALL,
        headerText: 'Actions',
        renderCell: (resource) => {
            const [submitting, setSubmitting] = React.useState(false);
            return (
                <IconButton
                    icon={IconNames.TRASH}
                    intent={PrimaryIntent.DELETE}
                    onClick={async (e) => {
                        e.stopPropagation();
                        setSubmitting(true);
                        let deleteNotPossibleText: string | null = null;
                        if (deleteProps.checkFn) {
                            deleteNotPossibleText = await deleteProps.checkFn(resource);
                        }
                        let shouldDelete: Boolean = false;
                        try {
                            shouldDelete = await promptModalBoolean({
                                title: deleteProps.title,
                                prompt: `${deleteNotPossibleText || deleteProps.prompt}`,
                                cancellable: true,
                                yesLabel: 'Delete',
                                yesIntent: PrimaryIntent.DELETE,
                                dialogProps: { icon: IconNames.WARNING_SIGN },
                                disabled: !!deleteNotPossibleText,
                            });
                        } catch (error) {
                            if (error !== MODAL_CANCELED_EXCEPTION) {
                                throw error;
                            }
                        }
                        if (shouldDelete) {
                            await addPromiseToasts(deleteFn(resource), {
                                initial: `Deleting ${deleteProps.resName}...`,
                                onCatch: (err) => err.response.body['error'],
                                onSuccess: `Successfully deleted ${deleteProps.resName}.`,
                            });
                            updateSearch();
                        }
                        setSubmitting(false);
                    }}
                    // TODO: This is is hack to fix the `margin-left: 8px` that this shouldn't
                    // be inheriting from core/controls/Button. This should be fixed properly
                    // in a full refactor of *Button components.
                    style={{ marginLeft: 0 }}
                    disabled={submitting || !canModifySharedTeamResource(user, resource)}
                />
            );
        },
    };
}
