import { INumericConfig } from './components/ParamNumeric';
import { IRatesConfig } from './components/ParamRatesFull';
import { ITableConfig } from './components/ParamTable';
import { IBooleanConfig } from './components/ParamBoolean';
import { IFixedConfig, IMinimumBillConfig } from 'reports/models/utility_rate';
import { IIncentivesConfig } from 'reports/modules/financials/components/IncentiveTierTable';

export enum ParamValueType {
    Boolean = 'boolean',
    Currency = 'currency',
    Float = 'float',
    Grid = 'grid',
    Integer = 'integer',
    Percentage = 'percentage',
    RatesFull = 'rates_full',
    IncentiveTierTable = 'incentive_tier_table',
    FixedCharges = 'fixed_charges',
    MinimumBill = 'minimum_bill',
    Table = 'table',
}

export type NumericParamType =
    | ParamValueType.Currency
    | ParamValueType.Float
    | ParamValueType.Integer
    | ParamValueType.Percentage;

export type ParamConfigType =
    | IBooleanConfig
    | INumericConfig
    | IRatesConfig
    | ITableConfig
    | IFixedConfig
    | IMinimumBillConfig
    | IIncentivesConfig;

export interface IParameterDefinition<T = any> {
    // TS discriminant union so we can use type guarding
    // See http://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions.
    type: ParamValueType;
    default: T;
    description?: string;
    hidden?: boolean;
}

export interface IParamTableDefinition<T = any> extends IParameterDefinition<T[]> {
    columns: any[];
    default: T[];
}

export interface IParamProps<D extends IParameterDefinition> {
    parameter: D;
    value: D['default'];
    disabled?: boolean;
    updateFn?: (value: D['default']) => any;
}

export interface ITier {
    abs_cap?: number | null;
    tier_cap?: number | null;
    tier_value: number;
}

type DeprecatedTierType = { tier_cap: number | null; tier_value: number };

export function isParamTier(tiers: (ITier | DeprecatedTierType)[]): tiers is ITier[] {
    return (tiers[0] as ITier).abs_cap !== undefined;
}

/**
 * Returns a copy of input tiers, w/ tier limits consistently using absolute caps (abs_cap) instead of relative caps.
 */
export function makeAbsTiers(tiers: ITier[]) {
    let prevAbsCap = 0.0;
    const absTiers: ITier[] = [];
    for (const tier of tiers) {
        let tierAbsCap: number | null = null;
        if (tier.abs_cap != null) {
            tierAbsCap = tier.abs_cap;
        } else if (tier.tier_cap != null) {
            tierAbsCap = prevAbsCap + tier.tier_cap;
        }
        absTiers.push({
            tier_value: tier.tier_value,
            abs_cap: tierAbsCap,
            tier_cap: null,
        });
        if (tierAbsCap === null) {
            break;
        }
        prevAbsCap = tierAbsCap;
    }
    return absTiers;
}
