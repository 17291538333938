/**
 * Report Proposals Custom Image Widget
 */
import * as React from 'react';

import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';

import Image from 'reports/components/helpers/Image';
import UploadableImage from 'reports/modules/files/components/UploadableImage';

import { IWidgetEditProps, IWidgetRenderProps, IReportContext, registerWidget } from 'reports/modules/report/widgets';

import FileDialog from 'reports/modules/files/components/FileDialog';
import SidebarFormatForm from 'reports/modules/report/components/SidebarFormatForm';
import CustomImageForm from 'reports/modules/report/components/widgets/custom_image/Form';
import {
    HorizPositionType,
    ObjectFitType,
    VertPositionType,
} from 'reports/modules/report/components/widgets/custom_image/types';

interface IState {
    showFileDialog: boolean;
}

interface ICustomImageContent {
    file_id?: number;
    objectFit?: ObjectFitType;
    horizObjectPosition?: HorizPositionType;
    vertObjectPosition?: VertPositionType;
}

type IContext = Pick<IReportContext, 'fileLoader'>;
type RenderImageProps = IWidgetRenderProps<ICustomImageContent, IContext>;
type EditRenderProps = IWidgetEditProps<ICustomImageContent, IContext>;

const OBJECT_FIT_DEFAULT = 'contain';
const OBJECT_POSITION_DEFAULT = 'center';

const setImageStyleValues = (content: ICustomImageContent): React.CSSProperties => {
    // Set default values for object styles if undefined.
    const objectFit = content.objectFit ? content.objectFit : OBJECT_FIT_DEFAULT;
    const vertObjectPosition = content.vertObjectPosition ? content.vertObjectPosition : OBJECT_POSITION_DEFAULT;
    const horizObjectPosition = content.horizObjectPosition ? content.horizObjectPosition : OBJECT_POSITION_DEFAULT;

    return {
        objectFit: objectFit as any,
        objectPosition: horizObjectPosition + ' ' + vertObjectPosition,
    };
};

class ViewImageComponent extends React.PureComponent<RenderImageProps> {
    render() {
        const { config, context } = this.props;
        const { content } = config;

        const url = content.file_id != null ? context.fileLoader(content.file_id) : null;
        const style = setImageStyleValues(content);
        return <Image src={url} errorMsg={''} style={style} />;
    }
}

class EditImageComponent extends React.PureComponent<EditRenderProps, IState> {
    state: IState = {
        showFileDialog: false,
    };

    render() {
        const { config, updateWidgetContent } = this.props;
        const { content } = config;
        const { objectFit, vertObjectPosition, horizObjectPosition } = content;
        const style = setImageStyleValues(content);
        return (
            <>
                <div style={{ height: '100%', width: '100%' }} onClick={() => this.toggleFileDialog(true)}>
                    <UploadableImage
                        fileId={config.content.file_id}
                        onUpdate={({ file_id }) => updateWidgetContent({ file_id })}
                        tag="report_image"
                        disableClick={true}
                        style={style}
                    />
                    <FileDialog
                        fileType="report_image"
                        selectedFile={config.content.file_id}
                        filterByTags={['report_image']}
                        onAddNew={({ file_id }) => updateWidgetContent({ file_id })}
                        onSelect={(fileId) => updateWidgetContent({ file_id: fileId })}
                        onClose={(e) => {
                            e && e.stopPropagation();
                            this.toggleFileDialog(false);
                        }}
                        isOpen={this.state.showFileDialog}
                        allowDelete={false}
                    />
                </div>
                <SidebarFormatForm>
                    <CustomImageForm
                        selectedFit={objectFit || OBJECT_FIT_DEFAULT}
                        selectedVertPosition={vertObjectPosition || OBJECT_POSITION_DEFAULT}
                        selectedHorizPosition={horizObjectPosition || OBJECT_POSITION_DEFAULT}
                        onSelectFit={(fit) => updateWidgetContent({ objectFit: fit })}
                        onSelectVert={(vert) =>
                            updateWidgetContent({
                                horizObjectPosition: horizObjectPosition || OBJECT_POSITION_DEFAULT,
                                vertObjectPosition: vert,
                            })
                        }
                        onSelectHoriz={(horiz) =>
                            updateWidgetContent({
                                vertObjectPosition: vertObjectPosition || OBJECT_POSITION_DEFAULT,
                                horizObjectPosition: horiz,
                            })
                        }
                    />
                </SidebarFormatForm>
            </>
        );
    }

    toggleFileDialog = (showFileDialog: boolean) => this.setState({ showFileDialog });
}

const CustomImage = registerWidget('report_custom_image', {
    Component: ViewImageComponent,
    EditingComponent: EditImageComponent,
    metadata: {
        category: 'generic',
        dimensions: { h: 400, w: 300 },
        displayName: Translations.widgets.custom_image_header,
        icon: IconNames.MEDIA,
        ignoreStyle: true,
    },
    dependencies: ['fileLoader'],
});

export default CustomImage;
