import * as React from 'react';
import Flex from 'reports/components/core/containers/Flex';

import { H4 } from '@blueprintjs/core';

import { styled } from 'reports/styles/styled-components';

const Container = styled(Flex.Container)`
    flex-direction: column;
    padding: 12px 24px 36px 24px;
`;

const Title = styled(H4)`
    font-weight: 400;
    font-size: 16px;
    color: #607d8b;
`;

interface Props {
    features: { title: string; items: string[] };
}

const PlanFeatures = ({ features }: Props) => {
    return (
        <Container>
            <Title>{features.title}</Title>
            {features.items.map((item) => (
                <span key={item}>{item}</span>
            ))}
        </Container>
    );
};

export { PlanFeatures };
