import * as React from 'react';

import classNames from 'classnames';

import { capitalize } from 'lodash';

import type { Address } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';

import FormBareRadioGroup from 'reports/components/forms/inputs/experimental/FormBareRadioGroup';
import { FormErrorCallout } from 'reports/components/helpers/errors';

import { Subscription } from 'reports/models/subscription';
import { Price } from 'reports/models/stripe/price';
import { User } from 'reports/models/user';

import { Callout, Classes, Icon, Intent, Spinner } from '@blueprintjs/core';
import { styled } from 'reports/styles/styled-components';

import { LeftPanel, Panels, RightPanel } from './Panels';

import { CheckboxInput, FieldError, FormField } from 'reports/components/forms';

import { HelperText } from 'reports/modules/settings/common';
import { PurchaseHeader } from './PurchaseHeader';
import { PurchaseSummary } from './PurchaseSummary';
import { isDowngrade, isImmediateDowngrade, isSeatRemoval } from 'reports/modules/settings/billing';
import { useSubscriptionCustomer } from '../Hooks';
import { BillingDetailsFields } from './BillingDetailsFields';

interface Props {
    collectBillingInformation: boolean;
    debug?: boolean;
    disabled?: boolean;
    formErrors;
    mapsAPIKey: string;
    address?: Address;
    paymentMethod: string;
    price?: Price;
    quantity: number;
    submitting: boolean;
    subscription?: Subscription;
    user: User;
    setTaxIncluded?: (taxIncluded: boolean) => void;
    basicPrices?: Price[];
    proPrices?: Price[];
    interval: string;
    switchToAnnual?: () => void;
    promptTermsOfUse: boolean;
}

const DialogBody = styled.div.attrs({
    className: Classes.DIALOG_BODY,
})`
    min-height: 300px;
`;

const SectionContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 8px;
    }

    .${Classes.FORM_GROUP} {
        margin-bottom: 6px;
        justify-content: center;
    }

    .${Classes.INPUT} {
        height: 38px;
        border-radius: 4px;
    }
`;

const StyledCallout = styled(Callout)`
    margin-top: 16px;
    margin-bottom: 16px;
`;

const Lock = styled(Icon)`
    padding-right: 6px;
`;

const StyledCheckboxInput = styled(CheckboxInput)`
    label {
        font-weight: normal;
    }

    label a {
        color: #333;
        text-decoration: underline;
    }
`;

const SupportText = () => {
    const contactSubject = encodeURIComponent('Non-credit card payment request');
    const contactBody = encodeURIComponent(
        'Hello,\r\rI would like to pay via wire transfer, ACH or check. Please send me the appropriate routing information.\r\rThank you',
    );

    return (
        <HelperText>
            To pay by invoice,{' '}
            <a href={`mailto:support@helioscope.com?subject=${contactSubject}&body=${contactBody}`}>contact us</a>
        </HelperText>
    );
};

const PlanPayment = ({
    collectBillingInformation,
    disabled,
    formErrors,
    mapsAPIKey,
    address,
    paymentMethod,
    price,
    quantity,
    submitting,
    subscription,
    user,
    setTaxIncluded,
    basicPrices,
    proPrices,
    interval,
    switchToAnnual,
    promptTermsOfUse,
}: Props) => {
    const { customer, isLoading: isLoadingCustomer } = useSubscriptionCustomer(subscription);
    const card = customer?.invoice_settings?.default_payment_method?.card;

    const isDowngrading = isDowngrade(user, proPrices, basicPrices, price);
    const isImmediateDowngrading = isImmediateDowngrade(user, proPrices, basicPrices, price);
    const isRemovingSeats = isSeatRemoval(user, quantity);

    const willCreateInvoice = !(isDowngrading || isRemovingSeats);

    const enablePayByInvoice = user.team.pay_by_invoice_enabled && !user.flagged;

    const radioItems = (subscription?: Subscription): any => {
        const existingActiveSub = subscription && !subscription.is_closed;
        const purchasedWithCard = existingActiveSub && !subscription!.is_pay_by_invoice;
        const existingCardItem = {
            label: card ? `${capitalize(card.brand)} (${card.last4})` : 'Card - XXXX',
            value: 'card',
            disabled: existingActiveSub && subscription!.is_pay_by_invoice,
        };
        const newCardItem = existingActiveSub
            ? { label: 'Credit Card', value: 'credit_card', disabled: subscription!.is_pay_by_invoice }
            : { label: 'Credit Card', value: 'credit_card' };
        const invoiceItem = enablePayByInvoice
            ? {
                  radioChildren: (
                      <>
                          Pay by Invoice (annual plans only
                          {!subscription?.is_active && interval !== 'year' && (
                              <>
                                  , <a onClick={switchToAnnual}>switch to annual</a>
                              </>
                          )}
                          )
                      </>
                  ),
                  value: 'invoice',
                  disabled: interval === 'month',
              }
            : { label: 'Invoice', value: 'invoice' };
        return purchasedWithCard ? [existingCardItem, newCardItem, invoiceItem] : [newCardItem, invoiceItem];
    };

    return (
        <DialogBody>
            {(isDowngrading || isImmediateDowngrading) && (
                <Callout intent={Intent.WARNING}>
                    <span>
                        Switching from Pro means features, including LIDAR, financials, reports, and proposals, will be
                        unavailable.{' '}
                        {isImmediateDowngrading &&
                            'The cost of your updated subscription includes a pro-rated credit for the time remaining in your active subscription.'}{' '}
                        These changes will take effect{' '}
                        {isImmediateDowngrading
                            ? 'immediately after payment.'
                            : 'at the end of the current billing cycle. You may continue to use these features until renewal.'}
                    </span>
                </Callout>
            )}
            <Panels>
                <LeftPanel>
                    <PurchaseHeader disabled={isDowngrading}>Payment Method</PurchaseHeader>
                    <FormBareRadioGroup
                        disabled={disabled || isDowngrading}
                        path="paymentMethod"
                        selectedValue={paymentMethod}
                        items={radioItems(subscription).map(({ label, value, disabled, radioChildren }) => ({
                            label,
                            disabled,
                            key: value,
                            radioChildren: radioChildren || <></>,
                            radioValue: value,
                        }))}
                    />
                    {willCreateInvoice && enablePayByInvoice && paymentMethod === 'invoice' && (
                        <>
                            <StyledCallout intent={Intent.PRIMARY} icon={null}>
                                We will send an invoice and payment link to the <b>billing email address</b> entered
                                below. You will have 15 days to pay and you'll need to contact support to change your
                                payment method after confirming.
                            </StyledCallout>
                            {isLoadingCustomer ? (
                                <Spinner />
                            ) : (
                                <BillingDetailsFields
                                    user={user}
                                    customer={customer}
                                    mapsAPIKey={mapsAPIKey}
                                ></BillingDetailsFields>
                            )}
                        </>
                    )}
                    {willCreateInvoice && !enablePayByInvoice && paymentMethod === 'invoice' && <SupportText />}
                    {paymentMethod === 'credit_card' && (
                        <>
                            <SectionContainer>
                                <div className={classNames(Classes.FORM_CONTENT)}>
                                    <FormField path="card">
                                        {({ onChange, form: formContext }) => (
                                            <PaymentElement
                                                options={{ readOnly: submitting }}
                                                onChange={(event) => {
                                                    // We need to inject the `complete` value from Stripe's validation
                                                    // in order for us to be able to check for completion of this field
                                                    // when validating it as part of the Stripe payment process
                                                    const value = { ...event.value, complete: event.complete };
                                                    onChange(value);
                                                }}
                                                // HEL-1337 fix: Make focus refresh the card value so we can set form dirty state to allow resubmission
                                                onFocus={() => onChange(formContext.formData['card'])}
                                            />
                                        )}
                                    </FormField>
                                </div>
                                <FieldError path="card" />
                            </SectionContainer>

                            {collectBillingInformation && (
                                <BillingDetailsFields
                                    user={user}
                                    customer={customer}
                                    mapsAPIKey={mapsAPIKey}
                                ></BillingDetailsFields>
                            )}
                            <HelperText small>
                                <Lock icon="lock" /> All Transactions are securely encrypted by Stripe
                            </HelperText>
                        </>
                    )}
                    {promptTermsOfUse && (
                        <StyledCheckboxInput
                            path="touAccepted"
                            label={
                                <>
                                    I have read and agree with the{' '}
                                    <a href="https://helioscope.aurorasolar.com/terms-of-use/">Terms of Use</a>
                                </>
                            }
                            disabled={submitting}
                        />
                    )}
                    {formErrors.length > 0 && (
                        <FormErrorCallout errorMsg={formErrors[0]} style={{ marginTop: '15px' }} />
                    )}
                </LeftPanel>
                <RightPanel>
                    <PurchaseSummary
                        quantity={quantity}
                        price={price}
                        address={address}
                        subscription={subscription}
                        user={user}
                        setTaxIncluded={setTaxIncluded}
                        isDowngrade={isDowngrading}
                    />
                </RightPanel>
            </Panels>
        </DialogBody>
    );
};

export { PlanPayment };
