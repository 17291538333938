/* tslint:disable:variable-name */

import moment from 'moment';

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { fallback } from 'reports/utils/helpers';

import * as team from 'reports/models/team';

import { schema } from '../schema';
import { IAPIQueryOpts } from '../types';

import * as pdc from './PowerDeviceCharacterization';

export enum PowerDeviceTypes {
    inverter = 'Inverter',
    buck_optimizer = 'Buck Optimizer',
    module_buck_optimizer = 'Module Buck Optimizer',
    buck_boost_optimizer = 'Buck Boost Optimizer',
    boost_optimizer = 'Boost (Parallel) Optimizer',
}

type PowerDeviceType = keyof typeof PowerDeviceTypes;

class PowerDevice extends BaseClass {
    power_device_id: number;
    device_type_name: PowerDeviceType;

    name: string;
    manufacturer: string;
    description: string;

    min_power: number;
    max_power: number;
    min_voltage: number;
    max_voltage: number;
    min_mpp_voltage: number;
    max_mpp_voltage: number;

    efficiency_factor_1: string;
    efficiency_factor_2: string;

    characterizations: pdc.PowerDeviceCharacterization[];

    // metadata
    team_id: number;
    team?: team.Team;
    public: boolean;
    source?: string;
    last_update?: moment.Moment;

    // Inverter-only properties
    microinverter: boolean;
    ac_config_id: number;
    ac_config: any;

    default_characterization_id: number;
    default_characterization?: pdc.PowerDeviceCharacterization;
    user_default_characterization_id?: number;
    user_default_characterization?: pdc.PowerDeviceCharacterization;

    favorite: boolean;

    modules_per_device: number; // [Deprecated] param for how many modules (max) should be wired into a component
    // (in series for optimizers, in parallel for microinverters)
    modules_per_optimizer: number; // [Deprecated] field from before we supported multiple modules  per component
    // natively

    constructor(data: any) {
        super(PowerDevice.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        last_update: (x) => moment(x),
    });

    defaultCharacterizationId = (): number => {
        return fallback(this.user_default_characterization_id, this.default_characterization_id);
    };

    defaultCharacterization = (): pdc.PowerDeviceCharacterization => {
        return fallback(this.user_default_characterization, this.default_characterization);
    };

    toString() {
        return `${this.manufacturer}, ${this.name}`;
    }
}

const schemaObj = schema.addObject(PowerDevice, 'power_device', {
    relationships: {
        team: { schema: team.schemaObj },
        default_characterization: { schema: pdc.schemaObj },
        user_default_characterization: { schema: pdc.schemaObj },
    },
});

const { backrefSelector: allCharacterizations } = pdc.schemaObj.addRelationship('power_device', schemaObj, {
    backref: 'characterizations',
});

interface IPowerDeviceQuery extends IAPIQueryOpts {
    name?: string;
    manufacturer?: string;
    favorite?: boolean;
    device_type?: 'inverter' | 'optimizer';
}

interface IUpdatePowerDeviceForm {
    power_device_id: number;
    favorite?: boolean;
    public?: boolean;
    user_default_characterization_id?: number;
}

const endpoint = ReduxEndpoint.fromSchema('/api/power_devices/', schemaObj, {
    deepSelect: {
        team: true,
    },
});

const api = {
    index: endpoint.index<IPowerDeviceQuery>(),
    get: endpoint.get<{ power_device_id: number | string }>('{power_device_id}'),
    save: endpoint.put<IUpdatePowerDeviceForm>('{power_device_id}'),
};

const selectors = {
    allCharacterizations,

    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export default PowerDevice;

export { PowerDevice, schemaObj, api, selectors };
