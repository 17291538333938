import * as React from 'react';
import { fromPairs, identity, isNil } from 'lodash';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms/index';

export interface ISelectItem {
    key: string | number;
    name: string;
}

interface IFixedItemsSelectProps {
    items: ISelectItem[];
    value: any;
    disabled: boolean;
    nilItemName?: string;
    width?: number;
    onChange: (val: any) => void;
    toRawValue?: (itemsValue: any) => any;
    toItemsValue?: (rawValue: any) => any;
}

/**
 * A simple select box that displays a fixed list of items.
 * The API for this is not fully baked, hence it's in the /experimental dir.
 *
 * toRawValue and toItemsValue are to be used when a mapping needs to be made between the item key
 * and a non-key (i.e. non-string and non-number) type, like a boolean or an object
 *
 * @param items The items to select among
 * @param value The selected value (should correspond to key of one of the items)
 * @param disabled When true, the dropdown cannot be opened
 * @param nilItemName If value is undefined or null, this text gets displayed in the collapsed select
 * @param width Width in pixels
 * @param onChange Function called with the updated value when the user selects a new item
 * @param toRawValue Converts an item key to another representation (e.g. an object or a boolean)
 * @param toItemsValue Converts the representation (e.g. an object or a boolean) back into an item key
 * @deprecated Use BasicSelect
 */
const FixedItemsSelect: React.FC<IFixedItemsSelectProps> = ({
    items,
    value,
    disabled,
    nilItemName = '',
    // Normally the buttons representing selected state are sized to the selected string,
    // which makes them non-uniform in width
    width = 250,
    onChange,
    toRawValue = identity,
    toItemsValue = identity,
}) => {
    const itemDict = fromPairs(items.map((i) => [i.key, i.name]));
    const selectedKey = toItemsValue(value);
    // We could also check if selectedKey is in itemDict, but let's save this for when we have to
    // handle the bad or user-provided value case
    const selectedItemName = isNil(selectedKey) ? nilItemName : itemDict[selectedKey];

    return (
        <StaticSelect
            items={items}
            itemRenderer={(item, { handleClick, modifiers }) => (
                <DropdownMenuItem
                    key={item.key}
                    title={item.name}
                    onClick={handleClick}
                    selected={item.key === selectedKey}
                    {...modifiers}
                    multiline
                    active={item.key === selectedKey}
                />
            )}
            disabled={disabled}
            buttonText={selectedItemName}
            onItemSelect={(item) => onChange(toRawValue(item.key))}
            // overriding width: 100%, which was causing popover to be slammed to the right
            popoverProps={{
                targetProps: {},
            }}
            buttonProps={{
                style: { width },
            }}
        />
    );
};

export default FixedItemsSelect;
