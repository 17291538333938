import * as React from 'react';
import { PrimaryIntent } from 'reports/components/core/controls';
import { promptModalBoolean } from 'reports/components/dialog';
import { addPromiseToasts } from 'reports/modules/Toaster';

export const promptRemoveExtension = async ({
    subscription,
    subscriptionExtension,
    deleteSubscriptionExtension,
    updateSubscriptionExtension,
}) => {
    const shouldSave = await promptModalBoolean({
        title: 'Remove Subscription Extension?',
        prompt: (
            <>
                This subscription is currently extended until {subscriptionExtension.end_date.format('ll')}. This
                extension will no longer be in effect.
            </>
        ),
        cancellable: true,
        cancelLabel: 'Not now',
        yesLabel: 'Remove extension',
        yesIntent: PrimaryIntent.DELETE,
    });

    if (shouldSave) {
        const endDate = subscription.now().toDate();
        await addPromiseToasts(
            subscription.is_closed
                ? updateSubscriptionExtension({
                      subscription_extension_id: subscriptionExtension.subscription_extension_id,
                      reason: subscriptionExtension.reason,
                      end_date: endDate,
                  })
                : deleteSubscriptionExtension({
                      subscription_extension_id: subscriptionExtension.subscription_extension_id,
                  }),
            {
                initial: 'Removing extension...',
                onSuccess: 'Successfully removed extension.',
                onCatch: 'Error removing extension.',
            },
        );
    }
};
