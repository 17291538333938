import * as React from 'react';
import { Text, Button, ButtonGroup } from '@aurorasolar/ds';
import { goToLogin, goToSignup } from './common';

const PasswordForbiddenLinkMessage = () => (
    <>
        <Text>
            Unfortunately your account creation link expired. Contact your HelioScope administrator to get a new one.
        </Text>
        <Button fullWidth size="md" type="submit" variant="primary" disabled>
            Reset link expired
        </Button>
        <ButtonGroup justifyContent="space-between">
            <Button size="md" variant="text" action={goToLogin}>
                Back to login
            </Button>
            <Button size="md" variant="text" action={goToSignup}>
                Sign up
            </Button>
        </ButtonGroup>
    </>
);

export { PasswordForbiddenLinkMessage };
