const TO_RADIANS = Math.PI / 180;

export function toRadians(degrees) {
    return degrees * TO_RADIANS;
}

export function toDegrees(radians) {
    return radians / TO_RADIANS;
}

export function clampRadians(radians) {
    let clamped = radians;
    while (clamped < 0) {
        clamped += 2 * Math.PI;
    }
    while (clamped > 2 * Math.PI) {
        clamped -= 2 * Math.PI;
    }
    return clamped;
}

export function clampDegrees(degrees) {
    let clamped = degrees;
    while (clamped < 0) {
        clamped += 360.0;
    }
    while (clamped > 360.0) {
        clamped -= 360.0;
    }
    return clamped;
}

export function fastPow2(power) {
    if (power < 0) {
        return 1 / (1 << -power);
    }
    return 1 << power;
}

export function lerp(start, end, inter) {
    return start + (end - start) * inter;
}

export function invLerp(start, end, value) {
    return (value - start) / (end - start);
}

export function bilerp(v0, v1, v2, v3, i, j) {
    // 0---1
    // |   |
    // 3---2
    const y0 = lerp(v0, v1, i);
    const y1 = lerp(v3, v2, i);
    return lerp(y0, y1, j);
}

export function circularDistance(i, j, len) {
    const d = i - j;
    return Math.min(Math.abs(d), len - Math.abs(d));
}
