import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { CreateButton, PersistentTooltip } from 'reports/components/core/controls';
import CardSection from 'reports/components/core/containers/CardSection';

import { bindActions } from 'reports/utils/redux';

import * as des from 'reports/models/design';
import * as proj from 'reports/models/project';

import { actions } from 'reports/modules/project';

import DesignDropdown from './DesignDropdown';
import DesignRender from './DesignRender';
import { createDesignDialog } from './NewDesignForm';
import ProjectLocationMap from './ProjectLocationMap';

interface IOwnProps {
    project: proj.Project;
    design?: des.Design;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

const SystemDesign: React.FC<IOwnProps & IDispatchProps> = ({
    project,
    design,
    editDesign,
    onMenuClick,
    navigateToDesigner,
}) => (
    <>
        <CardSection
            title="System Design"
            contextEl={
                project.designs.length > 0 ? (
                    <ButtonGroup>
                        <DesignDropdown project={project} onClickOverride={onMenuClick}>
                            <Button
                                text={design != null ? design.description : 'No Design Selected'}
                                rightIcon={IconNames.CARET_DOWN}
                            />
                        </DesignDropdown>
                        <PersistentTooltip
                            content="Complete your design"
                            persistenceKey={`complete_design.${project.project_id}`}
                            disabled={design?.isComplete()}
                            placement="top-start"
                        >
                            <Button text="Edit" icon={IconNames.WRENCH} onClick={editDesign} intent={Intent.WARNING} />
                        </PersistentTooltip>
                    </ButtonGroup>
                ) : (
                    <CreateButton
                        text="Add New Design"
                        onClick={async () => {
                            try {
                                const design = await createDesignDialog(project);
                                navigateToDesigner(design);
                            } catch (exc) {
                                console.warn(exc);
                            }
                        }}
                    />
                )
            }
        >
            {design ? (
                <DesignRender design={design} />
            ) : (
                <ProjectLocationMap project={project} size={{ w: 800, h: 800 }} />
            )}
        </CardSection>
    </>
);

const mapDispatchToProps = bindActions(({ project }) => ({
    editDesign: () =>
        routerActions.navigateTo('app.projects.project.designer', {
            projectId: project.project_id,
            designId: project.primary_design_id,
            subpath: 'field_segments',
        }),
    onMenuClick: (design) => actions.setPrimaryDesign(project, design),
    navigateToDesigner: (design) =>
        routerActions.navigateTo('app.projects.project.designer', {
            projectId: design.project_id,
            designId: design.design_id,
            subpath: 'field_segments',
        }),
}));

export default connect(null, mapDispatchToProps)(SystemDesign);
