/* tslint:disable:variable-name */

import { Vector } from 'helioscope/app/utilities/geometry';
import { flatten } from 'helioscope/app/utilities/helpers';
import { DEFAULT_CHARACTERIZATION } from 'helioscope/app/designer/field_segment/racking/common';

import * as wz from 'reports/models/wiring_zone';
import { BaseClass } from 'reports/utils/api';
import * as fs from 'reports/models/field_segment';
import * as pd from 'reports/models/power_device';
import * as wr from 'reports/models/wire';

import {
    DEFAULT_AC_CONFIG,
    singleInput,
    seriesConnection,
    parallelConnection,
    wireLossTransformation,
    identityTransformation,
} from 'helioscope/app/designer/components/electrical';

interface IPower {
    voltage: number;
    current: number;
    power: number;
}

export type ComponentType =
    | 'combiner'
    | 'inverter'
    | 'interconnect'
    | 'parallel_connection'
    | 'series_connection'
    | 'ac_panel'
    | 'ac_branch'
    | 'ac_run'
    | 'module'
    | 'string'
    | 'bus'
    | 'optimizer';

export abstract class FieldComponent extends BaseClass {
    parent?: FieldComponent;
    component_type: ComponentType;

    constructor(data) {
        super(data);

        for (const child of this.getChildren()) {
            child.parent = this;
        }
    }

    abstract getChildren(): FieldComponent[];

    // --- Power fields needed by summarizePower ---
    power() {
        const input = this.inputPower(this);
        return { input, output: this.outputPower(input) };
    }

    outputPower(inputPower = this.inputPower(this)) {
        return this.outputTransformation(this, inputPower);
    }

    // Can be overridden by derived class
    inputPower: (FieldComponent) => IPower;
    outputTransformation: (FieldComponent, inputPower: IPower) => IPower = identityTransformation;
}

abstract class StandardFieldComponent extends FieldComponent {
    children: FieldComponent[];

    getChildren() {
        return this.children || [];
    }
}

abstract class SingleChildComponent extends FieldComponent {
    child: FieldComponent | null;

    getChildren() {
        return this.child ? [this.child] : [];
    }
}

export class FieldCombiner extends StandardFieldComponent {
    field_component_id: number;

    wiring_zone_id: number;
    wiring_zone?: wz.WiringZone;

    location: Vector;

    inputPower = parallelConnection;
}

export class FieldInverter extends FieldComponent {
    field_component_id: number;

    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    power_device_id: number;
    inverter: pd.PowerDevice;

    interter_count: number;
    location: Vector;
    children: (FieldComponent | FieldComponent[])[];

    /**
     * model FieldInverterChildren as an array, where every entry is considered
     * an individual mppt point, and can contain it's own arrayof items
     * connected in parallel
     */
    getChildren() {
        return this.children ? flatten(this.children) : [];
    }

    inputPower = parallelConnection;
    outputTransformation = (_comp, _inputPower) => {
        const { max_power: power, ac_config } = this.inverter;
        const { voltage, phase } = ac_config || DEFAULT_AC_CONFIG;

        return {
            phase,
            voltage,
            power,
            current: power / voltage,
        };
    };
}

export class FieldBus extends SingleChildComponent {
    field_component_id: number;

    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    wire_gauge_id: number;
    wire: wr.Wire;

    bom_length: number;
    length: number;
    tier: number;

    inputPower = singleInput;
    outputTransformation = wireLossTransformation;
}

export class FieldString extends StandardFieldComponent {
    field_component_id: number;

    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    wire_gauge_id: number;
    wire: wr.Wire;

    bom_length: number;
    length: number;

    inputPower = seriesConnection;
    outputTransformation = wireLossTransformation;

    // TODO: remove after replacing all callers with getModules()
    get modules() {
        return this.children;
    }

    getModules() {
        return this.children;
    }
}

export class FieldOptimizer extends StandardFieldComponent {
    field_component_id: number;

    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    power_device_id: number;
    optimizer: pd.PowerDevice;

    string_location: number;

    // pass through getter directly to the module
    get fieldSegment() {
        return this.children[0] && this.children[0]['fieldSegment'];
    }

    inputPower = seriesConnection;
}

export class SeriesConnection extends StandardFieldComponent {
    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    // pass through getter directly to the module
    get fieldSegment() {
        return this.children[0] && this.children[0]['fieldSegment'];
    }

    inputPower = seriesConnection;
}

export class ParallelConnection extends StandardFieldComponent {
    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    // pass through getter directly to the module
    get fieldSegment() {
        return this.children[0] && this.children[0]['fieldSegment'];
    }

    inputPower = parallelConnection;
}

export class AcRun extends SingleChildComponent {
    field_component_id: number;

    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    wire_gauge_id: number;
    wire: wr.Wire;

    bom_length: number;
    length: number;

    inputPower = singleInput;
    outputTransformation = wireLossTransformation;
}

export class AcPanel extends StandardFieldComponent {
    field_component_id: number;

    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    location: Vector;

    inputPower = parallelConnection;
    outputTransformation = (_component, inputPower) => {
        const { voltage: panelVoltage, phase: panelPhase } = this.wiring_zone.panel_transformer_config;
        const { power, voltage, phase } = inputPower;

        const useTransformers = this.wiring_zone.use_transformers && panelVoltage && panelPhase;

        return {
            power,
            phase: useTransformers ? panelPhase : phase,
            voltage: useTransformers ? panelVoltage : voltage,
            current: power / (useTransformers ? panelVoltage : voltage),
        };
    };
}

export class AcBranch extends StandardFieldComponent {
    field_component_id: number;

    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    wire_gauge_id: number;
    wire: wr.Wire;

    bom_length: number;
    length: number;

    // TODO: replace all callers with getModules()
    get modules() {
        return this.getModules();
    }

    getModules() {
        // children of a branch are an array of an inverters with a collection of modules
        const children = this.getChildren();
        const rtn = [];
        for (let i = 0; i < children.length; i += 1) {
            const component = children[i];
            const subChildren = component.getChildren();
            rtn.push.apply(rtn, subChildren);
        }
        return rtn;
    }

    inputPower = seriesConnection;
    outputTransformation = wireLossTransformation;
}

export class Interconnect extends StandardFieldComponent {
    inputPower = parallelConnection;
}

export class FieldModule extends StandardFieldComponent {
    field_component_id: number;

    field_segment_id: number;
    fieldSegment: fs.FieldSegment; // uses camel-case to be compatible w/ old SingleLineDiagram and summarizePower.

    wiring_zone_id: number;
    wiring_zone: wz.WiringZone;

    racking_structure_id: number;
    frame_index: number;
    x: number;
    y: number;
    height: number;
    rotation: number;
    manual_orientation: fs.OrientationType;

    inputPower = (_comp) => {
        const char = this.fieldSegment.module_characterization || DEFAULT_CHARACTERIZATION;
        // TODO: test i_mp, v_mp extraction
        const { i_mp: current, v_mp: voltage } = char;

        return {
            current,
            voltage,
            power: current * voltage,
        };
    };
}

export function ensureInterconnect(components: FieldComponent[]): Interconnect | null {
    const first = components[0];
    if (!first) {
        return null;
    }
    if (first instanceof Interconnect) {
        return first;
    }

    return new Interconnect({
        component_type: 'interconnect',
        children: components,
    });
}
