import React from 'react';

import { BaseLibraries } from 'frontend/libraries/base';
import { FinancialModels } from 'frontend/libraries/financial-models';
import { FinancialModelEditor } from 'frontend/libraries/financial-model-edit';
import { FinancialModelPreview } from 'frontend/libraries/financial-model-preview';
import { Incentives } from 'frontend/libraries/incentives';
import { Incentive } from 'frontend/libraries/incentive';
import { Profiles } from 'frontend/libraries/profiles';
import { ProfilePreview } from 'frontend/libraries/profile-preview';
import { ProfileEdit } from 'frontend/libraries/profile-edit';
import { Modules } from 'frontend/libraries/modules';
import { Module } from 'frontend/libraries/module';
import { PowerDevices } from 'frontend/libraries/power-devices';
import { PowerDevice } from 'frontend/libraries/power-device';
import { UtilityRates } from 'frontend/libraries/utility-rates';
import { UtilityRate } from 'frontend/libraries/utility-rate';
import { WeatherSources } from 'frontend/libraries/weather-sources';
import { Wires } from 'frontend/libraries/wires';

import { loadIncentive, loadProfile, loadModule, loadUtilityRate, loadPowerDevice, loadFinTemplate } from 'frontend/routing/loaders';

import { AppStore } from 'reports/store';

const librariesRoutes = (store: AppStore) => ({
    element: <BaseLibraries />,
    children: [
        {
            path: 'profiles',
            element: <Profiles />,
        },
        {
            path: 'profiles/:profileId/preview',
            element: <ProfilePreview />,
            loader: async ({ params }) => await loadProfile(store, params.profileId),
        },
        {
            path: 'profiles/:profileId/edit',
            element: <ProfileEdit />,
            loader: async ({ params }) => await loadProfile(store, params.profileId),
        },
        {
            path: 'components',
            element: <PowerDevices />,
        },
        {
            path: 'components/:powerDeviceId/characterizations/:characterizationId/preview',
            element: <PowerDevice />,
            loader: async ({ params }) => await loadPowerDevice(store, params),
        },
        {
            path: 'modules',
            element: <Modules />,
        },
        {
            path: 'modules/:moduleId/characterizations/:characterizationId/preview',
            element: <Module />,
            loader: async ({ params }) => await loadModule(store, params),
        },
        {
            path: 'weather_sources',
            element: <WeatherSources />,
        },
        {
            path: 'wires',
            element: <Wires />,
        },
        {
            path: 'financial-models',
            element: <FinancialModels />,
        },
        {
            path: 'financial-models/:finTemplateId/preview',
            element: <FinancialModelPreview />,
            loader: async ({ params }) => await loadFinTemplate(store, params.finTemplateId),
        },
        {
            path: 'financial-models/:finTemplateId/edit',
            element: <FinancialModelEditor />,
            loader: async ({ params }) => await loadFinTemplate(store, params.finTemplateId),
        },
        {
            path: 'utility-rates',
            element: <UtilityRates />,
        },
        {
            path: 'utility-rates/:utilityRateId',
            element: <UtilityRate />,
            loader: async ({ params }) => await loadUtilityRate(store, params.utilityRateId),
        },
        {
            path: 'incentives',
            element: <Incentives />,
        },
        {
            path: 'incentives/:incentiveId',
            element: <Incentive />,
            loader: async ({ params }) => await loadIncentive(store, params.incentiveId),
        },
    ],
});

export { librariesRoutes };
