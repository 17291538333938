import { capitalize } from 'lodash';
import { IFieldErrors } from './types';

export interface IFormAndFieldErrors<FormType> {
    fieldErrors: IFieldErrors<FormType>;
    formErrors: string[];
}

export interface IExceptionHandler<FormType> {
    (exc: Error): IFormAndFieldErrors<FormType>;
}

export function baseExceptionHandler(_exc) {
    return {
        fieldErrors: {},
        formErrors: ['Error submitting form'],
    };
}

export function handleRequestException(exc) {
    const response = exc.response;
    const fieldErrors = response.body ? response.body.errors || response.body : {};
    const formErrors =
        response.status === 400
            ? [] // 400 errors should have error messages in the body
            : response.status === 500
            ? ['Unknown Server Error']
            : response.status === 403
            ? ['User not authorized']
            : response.status === 401
            ? ['User not signed in']
            : response.statusText
            ? [capitalize(response.statusText)]
            : [];

    // If API threw a JSONRequestError w/ error="<Some error description>", that means the error wasn't about a specific
    // field. Thus, it's a top-level form error.
    if ('error' in fieldErrors) {
        formErrors.push(fieldErrors.error);
        delete fieldErrors.error;
    }
    return { fieldErrors, formErrors };
}
