import React from 'react';
import { Box, Button, Flex } from '@aurorasolar/ds';
import * as styles from 'reports/styles/styled-components';
import { goToLogin, goToSignup } from './common';
const styled = styles.styled;

const logo = require('reports/static/helioscope-logo.svg');

const Logo = styled.img`
    height: 50px;
`;

const SignupHeader = () => (
    // This is a fixed position div to retain the sticky header behavior
    <Flex
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray-400"
        position="fixed"
        backgroundColor="white"
        zIndex="199"
    >
        <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding="24px 10px"
            maxWidth="1472px"
        >
            <Box>
                <Logo src={logo} alt="HelioScope Logo" />
            </Box>
            <Box>
                <Flex flexDirection="row" justifyContent="flex-end" gap="22px">
                    {/* The classic signup page buttons have text color as #404040 but we don't have that in DS's list of colors */}
                    <Button size="md" variant="tertiary" bg="yellow-200" color="gray-700" action={goToSignup}>
                        Sign up
                    </Button>
                    <Button
                        size="md"
                        variant="text"
                        color="gray-700"
                        padding="12px 16px 12px 22px"
                        action={goToLogin}
                    >{`Login >`}</Button>
                </Flex>
            </Box>
        </Flex>
    </Flex>
);

export default SignupHeader;
