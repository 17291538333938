import React from 'react';
import { useSelector } from 'react-redux';
import { useBlocker } from 'react-router';
import * as analytics from 'reports/analytics';
import UpsellDialogContainer from 'reports/components/dialog/components/UpsellDialogContainer';
import { IUpsellContent, ReferrerType, UPSELL_DIALOG_CONTENT } from 'reports/utils/UpsellDialogConstants';
import * as auth from 'reports/modules/auth';
import { get } from 'lodash';

const UpsellDialog = () => {
    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    const [content, setContent] = React.useState<IUpsellContent>(UPSELL_DIALOG_CONTENT.FINANCIAL);
    const [referrer, setReferrer] = React.useState<ReferrerType>();

    const user = useSelector((state) => auth.selectors.getUser(state)!);

    const FINANCIALS_PATHS = ['/financial-models', '/incentives', '/utility-rates'];
    const PROPOSALS_PATHS = ['/reports'];

    const isFinancialsPath = (path) => FINANCIALS_PATHS.some((subpath) => path.includes(subpath));
    const isProposalsPath = (path) => PROPOSALS_PATHS.some((subpath) => path.includes(subpath));
    const canViewFin = user.hasFinancialsAccess();

    const blocker = useBlocker(({ nextLocation }) => {
        const inFinancials = isFinancialsPath(nextLocation.pathname);
        const inProposals = isProposalsPath(nextLocation.pathname);
        if (!inFinancials && !inProposals) {
            return false;
        }
        if (inFinancials) {
            setContent(UPSELL_DIALOG_CONTENT.FINANCIAL);
        } else {
            setContent(UPSELL_DIALOG_CONTENT.REPORTS_AND_PROPOSALS);
        }
        setReferrer(get(nextLocation, ['state', 'referrer']));
        return !canViewFin;
    });

    React.useEffect(() => {
        if (showDialog) {
            const modalName = content!.modalName;
            analytics.track('paywall.open', { referrer, modal_name: modalName });
        }
    }, [showDialog]);

    React.useEffect(() => {
        if (blocker.state === 'blocked') {
            setShowDialog(true);
            blocker.reset();
        }
    }, [blocker.state]);

    return <UpsellDialogContainer content={content} isOpen={showDialog} onCancel={() => setShowDialog(false)} />;
};

export { UpsellDialog };
