import * as React from 'react';

import { SortType } from 'reports/components/core/tables/DataTable';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';
import { CommonColumns } from 'reports/components/library/helpers';

import * as inc from 'reports/models/incentive';
import { User } from 'reports/models/user';

type Props = {
    openEditor: (incentiveId: number) => void;
    user: User;
};

const IncentivesList = ({ openEditor, user }: Props) => (
    <ResourceTable<inc.Incentive>
        columns={[
            CommonColumns.name(),
            {
                sort: { name: 'incentive_type', type: SortType.ALPHA },
                headerText: 'Incentive Type',
                renderCell: (incentive) => <>{inc.IncentiveTypes[incentive.incentive_type]}</>,
            },
            CommonColumns.lastModified(),
            CommonColumns.created(),
            CommonColumns.creator(),
            CommonColumns.team(),
            CommonColumns.publicCol(user),
        ]}
        onRowClick={(incentive) => openEditor(incentive.incentive_id)}
    />
);

export { IncentivesList };
