import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Alignment, Classes, ButtonGroup, Divider, Navbar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Context } from 'reports/components/core/layout';
import { AnchorButton } from 'reports/components/core/controls';

import ContextBreadCrumbs from 'reports/components/ContextBreadcrumbs';
import ContextBarActivity from 'reports/components/ContextBarActivity';
import { ContextBarMetricDropdown } from 'reports/components/ContextBarMetric';

import { RouteComponent } from 'reports/routing';

export const { Provider: ContextBarProvider, Consumer: ContextBarConsumer } = React.createContext<{
    contextBarRef?: React.RefObject<HTMLDivElement>;
}>({ contextBarRef: undefined });

interface IContextBarProps {
    innerRef: React.RefObject<HTMLDivElement>;
}

export const ContextBar: React.SFC<IContextBarProps> = ({ innerRef }) => (
    <Context.NavBar className={Classes.DARK}>
        <Navbar.Group>
            <div style={{ marginRight: '10px' }} />
            <ContextBreadCrumbs />
        </Navbar.Group>

        <Navbar.Group align={Alignment.RIGHT}>
            <AnchorButton href="http://help-center.helioscope.com/" icon={IconNames.HELP} text="Help" target="_blank" />
            <AnchorButton
                icon={IconNames.ENVELOPE}
                text="Give Feedback"
                href="mailto:support@helioscope.com?subject=Open Beta Feedback"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginRight: 10 }}
            />

            <Context.Controls ref={innerRef as any} />

            <RouteComponent name="app.projects.project" exact={false}>
                {({ project }) => (
                    <ButtonGroup>
                        <ContextBarMetricDropdown project={project} />
                        <Divider />
                        <div className="activity-container">
                            <ContextBarActivity project={project} />
                        </div>
                    </ButtonGroup>
                )}
            </RouteComponent>

            <RouteComponent name="app" nameNot="app.projects.project" exact={false}>
                <div className="activity-container">
                    <ContextBarActivity />
                </div>
            </RouteComponent>
        </Navbar.Group>
    </Context.NavBar>
);

export const ContextBarControls: React.SFC = (props) => (
    <ContextBarConsumer>
        {({ contextBarRef }) =>
            contextBarRef && contextBarRef.current ? ReactDOM.createPortal(props.children, contextBarRef.current) : null
        }
    </ContextBarConsumer>
);

export default ContextBar;
