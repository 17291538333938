import { Colors as bpColors } from '@blueprintjs/core';
import { HELIOSCOPE_ORANGE } from 'reports/styles/global';

import { convertColorAndOpacity } from 'helioscope/app/utilities/colors';

const palette = {
    // Accent Colours
    orange: '#ff9900',
    orangeLight: '#ffe0b2',
    orangeDark: '#c66a00',

    blue: '#19afd9',
    blueLight: '#afe3ef',
    blueDark: '#0084aa',

    teal: '#00acc1',
    tealLight: '#41b7bf',
    tealDark: '#00878f',

    yellow: '#fabf2c',
    yellowLight: '#fef075',
    yellowDark: '#e5b518',

    salmon: '#ef6d45',
    salmonLight: '#ff8a65',
    salmonDark: '#c75b39',

    // Base Colours
    white: '#ffffff',
    whiteAlmost: '#fafafb',
    whiteGrey: '#f5f5f6',

    blueGrey1: '#37474f', // darkest
    blueGrey2: '#455a64',
    blueGrey3: '#607d8b',
    blueGrey4: '#90a4ae',
    blueGrey5: '#b0bec5',
    blueGrey6: '#cfd8dc',
    blueGrey7: '#eceff1', // lightest
};

const primary = palette.orange;
// const primarylight = palette.orangeLight;
const primaryDark = palette.orangeDark;
const secondary = palette.blue;
// const secondaryLight = palette.blueLight;
const secondaryDark = palette.blueDark;

export const HSLightTheme = {
    // General
    backgroundMain: palette.whiteGrey, // "Background A" from palette above
    surface: palette.white, // cards, panels, tables
    surfaceBorder: '#ebebeb',

    // Sidebar
    sidebarBg: palette.whiteGrey, // "Sidebar A" from palette above
    sidebarBgHover: 'rgba(255, 255, 255, 0.8)',
    sidebarBorder: '#e6e6e6',
    sidebarFont: palette.blueGrey2, // Section and view titles
    sidebarFontActive: palette.white, // Selected title
    sidebarFontDisabled: palette.blueGrey4,
    sidebarViewIcon: palette.blueGrey3,
    sidebarViewIconHover: palette.blueGrey2,
    sidebarViewIconDisabled: palette.blueGrey5,
    sidebarActionIcon: palette.blueGrey5, // Collapse, min/max control icon
    sidebarTitleFont: palette.blueGrey3, // Default section title, i.e. of a collapsed section
    // Subsection, currently only Project Conf / Reports
    sidebarSubsectionTitleFont: palette.blueGrey2,
    sidebarSubsectionTitleBorder: palette.blueGrey2,
    sidebarSubsectionTitleIcon: palette.blueGrey2, // `Project Configuration/Reports/` icon

    // Project Configuration Section
    // header -> title, title icon, bottom border
    // active -> currently selected/active
    sidebarSections: {
        projectConfig: {
            header: primaryDark,
            activeBg: primary,
        },
        libraries: {
            header: secondaryDark,
            activeBg: secondary,
        },
    },

    emptyStateIcon: palette.blueGrey6,
};

const Colors = {
    HELIO_ORANGE: HELIOSCOPE_ORANGE,
    HELIO_ORANGE_MEDIUM: '#fb8d00',
    HELIO_ORANGE_DARK: '#dc6502',
    HELIO_ORANGE_DISABLED: convertColorAndOpacity(HELIOSCOPE_ORANGE, 0.5),
    HELIO_LIGHT_BLUE: '#60c4f4',
    HELIO_BLUE_GREY_3: palette.blueGrey3,

    BG_WHITE: '#ffffff',
    BG_DARK: '#303030',
    BG_INTENT_WARNING: '#ffe8d1',
    BG_LIGHT_GRAY: bpColors.LIGHT_GRAY5,

    INTENT_PRIMARY: bpColors.BLUE2,
    INTENT_SUCCESS: bpColors.GREEN2,
    INTENT_WARNING: bpColors.ORANGE2,
    INTENT_DANGER: bpColors.RED2,

    // HS primary intents
    INTENT_FAVORITE: palette.yellow,
    INTENT_FAVORITE_HOVER_BG: palette.yellowDark,
    INTENT_FAVORITE_HOVER_MINIMAL_BG: 'rgba(255, 187, 0, 0.15)',

    BORDER_DARK: bpColors.GRAY1,

    // Table styles
    BORDER_TABLE: bpColors.GRAY3,
    BORDER_TABLE_LIGHT: bpColors.LIGHT_GRAY1,
    TABLE_HIGHLIGHTED_ROW: bpColors.LIGHT_GRAY3,

    TEXT_DISABLED: 'rgba(92, 112, 128, 0.6)',

    ADMIN_TEXT: bpColors.RED1,
};

export default Colors;
