import { ButtonGroup, Menu, MenuItem, Popover, Position, Tag } from '@blueprintjs/core';
import { Button } from 'reports/components/core/controls';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import styled from 'styled-components';
import Colors from 'reports/styles/colors';
import { SelectTitle } from 'reports/modules/settings/team/components/common';

// Padding value is from the BP Button left/right padding
const StyledUserActionsSubtitle = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 0px 10px;
`;

const StyledTag = styled(Tag)`
    margin-left: 30px;
`;

const StyledMenuItem = styled(MenuItem)`
    &:focus {
        background-color: #2d72d2;
        color: ${Colors.BG_WHITE};
    }
`;

const DropdownMenuButton = ({ title, subtitle, tag, menuItems }) => {
    const PopoverMenu = () => {
        return (
            <Menu>
                {menuItems.map((item, index) => (
                    <StyledMenuItem key={`menuitem_${index}`} text={item.value} onClick={item.action} />
                ))}
            </Menu>
        );
    };

    return (
        <>
            <ButtonGroup vertical>
                <Popover
                    fill
                    content={PopoverMenu()}
                    modifiers={{ preventOverflow: { enabled: false } }}
                    position={Position.BOTTOM_RIGHT}
                >
                    <Button
                        fill
                        minimal
                        alignText="left"
                        style={{ marginLeft: 0, zIndex: 0 }}
                        text={
                            <>
                                <SelectTitle>{title}</SelectTitle>
                            </>
                        }
                        rightIcon={IconNames.CHEVRON_DOWN}
                    />
                </Popover>
            </ButtonGroup>
            <StyledUserActionsSubtitle>
                {subtitle} {tag && <StyledTag minimal>{tag}</StyledTag>}
            </StyledUserActionsSubtitle>
        </>
    );
};

export default DropdownMenuButton;
