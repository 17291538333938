import _ from 'lodash';
import { Mark, Node, Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import { history, redo, undo } from 'prosemirror-history';
import { EditorState, Plugin } from 'prosemirror-state';
import { keymap } from 'prosemirror-keymap';
import { baseKeymap, setBlockType, toggleMark } from 'prosemirror-commands';
import { addListNodes, liftListItem, splitListItem, wrapInList } from 'prosemirror-schema-list';

import * as theme from 'reports/models/theme';

import { ITokenMap, Token } from 'reports/modules/report/tokens';

export const DEFAULT_FONT_FAMILY = 'Arial';
export const DEFAULT_FONT_SIZE = '16px';
export const DEFAULT_FONT_COLOR = '#000000';

// custom commands (logic still derived from on prosemirror-commandsg)
function checkDefaults(mark) {
    const { value } = mark;
    if (value) return value.default !== mark.value;
    return true;
}

function markApplies(doc, ranges, type) {
    for (let i = 0; i < ranges.length; i += 1) {
        const { $from, $to } = ranges[i];
        let can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
        doc.nodesBetween($from.pos, $to.pos, (node) => {
            if (can) return false;
            can = node.inlineContent && node.type.allowsMarkType(type);
        });
        if (can) return true;
    }
    return false;
}

function updateMark(markType, attrs) {
    return (state, dispatch) => {
        const { empty, $cursor, ranges } = state.selection;
        if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) {
            return false;
        }
        if (dispatch) {
            const tr = state.tr;
            if ($cursor) {
                if (markType.isInSet(state.storedMarks || $cursor.marks())) {
                    tr.removeStoredMark(markType);
                }
                const mark = markType.create(attrs);
                if (checkDefaults(mark)) {
                    dispatch(tr.addStoredMark(mark));
                }
            } else {
                let has = false;
                for (let i = 0; !has && i < ranges.length; i += 1) {
                    const { $from, $to } = ranges[i];
                    has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
                }
                for (let i = 0; i < ranges.length; i += 1) {
                    const { $from, $to } = ranges[i];
                    if (has) tr.removeMark($from.pos, $to.pos, markType);
                    const mark = markType.create(attrs);
                    if (checkDefaults(mark)) {
                        tr.addMark($from.pos, $to.pos, mark);
                    }
                }
                dispatch(tr.scrollIntoView());
            }
        }
        return true;
    };
}

function detectAutocomplete(state) {
    const { $cursor } = state.selection;

    if (!$cursor || !$cursor.nodeBefore) return null;

    const textBefore = $cursor.nodeBefore.text || '';

    const beforeOpen = textBefore.lastIndexOf('{');
    const beforeClose = textBefore.lastIndexOf('}');

    if (beforeOpen >= 0 && (beforeClose < 0 || beforeClose < beforeOpen)) {
        if ($cursor.nodeAfter && Mark.sameSet($cursor.nodeBefore.marks, $cursor.nodeAfter.marks)) {
            const textAfter = $cursor.nodeAfter.text || '';

            const afterOpen = textAfter.indexOf('{');
            const afterClose = textAfter.indexOf('}');

            if (afterClose >= 0 && (afterOpen < 0 || afterOpen > afterClose)) {
                return {
                    $cursor,
                    textBefore: textBefore.substring(beforeOpen + 1),
                    textAfter: textAfter.substring(0, afterClose),
                };
            }
        }

        return {
            $cursor,
            textBefore: textBefore.substring(beforeOpen + 1),
            textAfter: null,
        };
    }

    return null;
}

// custom schema
function configureSchema(theme: theme.Theme) {
    const defaultStyle = `font-family: ${DEFAULT_FONT_FAMILY}; font-size: ${DEFAULT_FONT_SIZE}; color: ${DEFAULT_FONT_COLOR}`;

    // not specifying any parseDOM fields -- too messy and too uncommon a use case to worry about
    const nodes = {
        doc: {
            content: 'block+',
        },
        paragraph: {
            attrs: {
                align: { default: null },
            },
            content: 'inline*',
            group: 'block',
            toDOM(node) {
                const classStr = `${node.attrs.align || ''}`;
                return ['p', { class: classStr, style: defaultStyle }, 0];
            },
        },
        text: {
            group: 'inline',
        },
        hard_break: {
            inline: true,
            group: 'inline',
            selectable: false,
            toDOM() {
                return ['br'];
            },
        },
    };

    const marks = {
        italic: {
            toDOM() {
                return ['i', 0];
            },
        },
        bold: {
            toDOM() {
                return ['b', 0];
            },
        },
        underline: {
            toDOM() {
                return ['u', 0];
            },
        },
        fontFamily: {
            attrs: {
                value: { default: 'Arial' },
            },
            toDOM(mark) {
                const value = mark.attrs.value || DEFAULT_FONT_FAMILY;
                const styleStr = `font-family: ${value};`;
                return ['span', { style: styleStr }, 0];
            },
        },
        fontSize: {
            attrs: {
                value: { default: '16px' },
            },
            toDOM(mark) {
                const value = mark.attrs.value || DEFAULT_FONT_SIZE;
                const styleStr = `font-size: ${value};`;
                return ['span', { style: styleStr }, 0];
            },
        },
        fontColor: {
            attrs: {
                value: { default: '16px' },
            },
            toDOM(mark) {
                let value = mark.attrs.value || DEFAULT_FONT_COLOR;

                if (value === 'color_primary') {
                    value = theme.primary_color;
                } else if (value === 'color_secondary') {
                    value = theme.secondary_color;
                }

                const styleStr = `color: ${value};`;
                return ['span', { style: styleStr }, 0];
            },
        },
    };

    // Compilation error encountered here. Adding ts-ignore until we move to ts-loader
    // @ts-ignore: TS2322
    const baseSchema = new Schema({ nodes, marks });

    return new Schema({
        // @ts-ignore: TS2322
        marks,
        nodes: addListNodes(baseSchema.spec.nodes, 'paragraph block*', 'block'),
    });
}

export class RichTextController {
    tokenMap?: ITokenMap;
    schema: any = null;
    // @ts-ignore: TS2322
    view: EditorView = null;
    listenerSubs: ((event: any) => any)[] = [];
    autoComplete: any;

    tryInitEditor(divRef, document, theme, tokenMap, editable) {
        if (this.schema) return;

        this.tokenMap = tokenMap;

        this.schema = configureSchema(theme);

        const hsSetup = () => {
            const hsKeymap = {
                'Mod-z': undo,
                'Shift-Mod-z': redo,
                'Mod-b': toggleMark(this.schema.marks.bold),
                'Mod-i': toggleMark(this.schema.marks.italic),
                'Mod-u': toggleMark(this.schema.marks.underline),
                /* tslint:disable-next-line:object-literal-key-quotes */
                Enter: splitListItem(this.schema.nodes.list_item),
            };

            const appendTransaction = (_transactions, oldState, newState) => {
                // try to make empty paragraph marks carry over from previous paragraph's ending
                // kinda jank probably the right way to do this would be by implementing custom commands
                const { $cursor } = newState.selection;
                if (!$cursor) return null;
                if ($cursor.nodeAfter || $cursor.nodeBefore) return null;
                if (oldState.selection.$cursor && oldState.selection.$cursor.pos === $cursor.pos) {
                    return null;
                }

                const prev = newState.doc.resolve($cursor.pos - 1);
                const { nodeBefore } = prev;

                if (
                    nodeBefore &&
                    nodeBefore.type.isTextblock &&
                    nodeBefore.lastChild &&
                    nodeBefore.lastChild.marks &&
                    nodeBefore.lastChild.marks.length
                ) {
                    const tr = newState.tr.setStoredMarks(Mark.setFrom(nodeBefore.lastChild.marks));
                    return tr;
                }

                return null;
            };

            const keybindings = () => {
                return new Plugin({
                    appendTransaction,
                    props: {
                        handleKeyDown: (view, evt) => this.handleKeyDown(view, evt),
                    },
                    state: {
                        init: () => ({}),
                        // @ts-ignore: TS2322
                        apply: (_tr, _value, oldState, newState) => {
                            this.handleStateChanges(newState);
                            this.notifyStateChanges(newState, oldState);
                        },
                    },
                });
            };

            const plugins = [history(), keybindings(), keymap(hsKeymap), keymap(baseKeymap)];

            return plugins.concat(
                new Plugin({
                    props: {
                        editable: () => !!editable,
                        attributes: { class: 'reports-rich-text-container' },
                    },
                }),
            );
        };

        this.view = new EditorView(divRef, {
            state: EditorState.create({
                doc: this.initDocNode(document),
                plugins: hsSetup(),
            }),
        });

        this.view.focus();
        this.notifyStateChanges(this.view.state, this.view.state);
    }

    destroyEditor() {
        this.view.destroy();
    }

    subscribeListener(fn) {
        this.listenerSubs.push(fn);

        return () => _.remove(this.listenerSubs, (i) => i === fn);
    }

    serializeDocument() {
        return this.view.state.doc.toJSON();
    }

    toggleMark(mark) {
        const fn = toggleMark(this.schema.marks[mark]);
        fn(this.view.state, this.view.dispatch);
        _.defer(() => this.view.focus());
    }

    hasMark(state, mark) {
        const markType = this.schema.marks[mark];
        const { from, $from, to, empty } = state.selection;
        if (empty) return markType.isInSet(state.storedMarks || $from.marks());
        return state.doc.rangeHasMark(from, to, markType);
    }

    updateMark(mark, attrs) {
        const fn = updateMark(this.schema.marks[mark], attrs);
        fn(this.view.state, this.view.dispatch);
        _.defer(() => this.view.focus());
    }

    checkMarkAttr(state, mark, attr, defaultVal: any = null) {
        const { $cursor, from, to } = state.selection;

        const charmarks = [] as any[];

        if (state.storedMarks) {
            charmarks.push(state.storedMarks);
        } else if ($cursor) {
            charmarks.push($cursor.marks());
        } else {
            for (let i = from + 1; i <= to; i += 1) {
                const resolved = state.doc.resolve(i);
                if (resolved.parent.type.name === 'doc') continue;
                if (!resolved.parent.type.isTextblock && !resolved.parent.type.isText) {
                    continue;
                }
                if (resolved.parent.content.size === 0) continue;
                charmarks.push(resolved.marks());
            }
        }

        const filtered = charmarks.map((set) => {
            const found = _.find(set, (i) => i.type.name === mark);
            if (found && found.attrs[attr]) {
                return found.attrs[attr];
            }

            return defaultVal;
        });

        if (!filtered.length) return defaultVal;

        const same = filtered.filter((i) => i === filtered[0]);
        if (same.length === filtered.length) return filtered[0];
        return defaultVal;
    }

    setBlock(block, attrs) {
        const fn = setBlockType(this.schema.nodes[block], attrs);
        fn(this.view.state, this.view.dispatch);
        _.defer(() => this.view.focus());
    }

    wrapBlock(block) {
        const fn = wrapInList(this.schema.nodes[block]);
        fn(this.view.state, this.view.dispatch);
        _.defer(() => this.view.focus());
    }

    checkWrapBlock(state, block) {
        const fn = wrapInList(this.schema.nodes[block]);
        return fn(state);
    }

    liftBlock() {
        const fn = liftListItem(this.schema.nodes.list_item);
        fn(this.view.state, this.view.dispatch);
        _.defer(() => this.view.focus());
    }

    checkLiftBlock(state) {
        const fn = liftListItem(this.schema.nodes.list_item);
        return fn(state);
    }

    dispatchAutocomplete(value) {
        const detect = detectAutocomplete(this.view.state);
        if (!detect) return;

        const { $cursor, textBefore, textAfter } = detect;
        const { nodeBefore } = $cursor;

        const json = nodeBefore.toJSON();
        const trimBefore = nodeBefore.text.substring(0, nodeBefore.text.length - textBefore.length);
        json.text = trimBefore + value + '}';
        const replace = Node.fromJSON(this.schema, json);

        const { state, dispatch } = this.view;

        if (textAfter !== null) {
            dispatch(
                state.tr.replaceWith($cursor.pos - nodeBefore.text.length, $cursor.pos + textAfter.length + 1, replace),
            );
        } else {
            dispatch(state.tr.replaceWith($cursor.pos - nodeBefore.text.length, $cursor.pos, replace));
        }

        _.defer(() => this.view.focus());
    }

    handleKeyDown(_view, evt) {
        const handleAutoComplete = () => {
            const autoComplete = this.autoComplete;
            if (!autoComplete) return false;

            if (evt.key === 'ArrowDown') {
                this.notifySubscribers({ autoCompleteSelectDown: true });
            } else if (evt.key === 'ArrowUp') {
                this.notifySubscribers({ autoCompleteSelectUp: true });
            } else if (evt.key === 'Escape') {
                this.autoComplete = null;
                this.notifyAutocompleteChanges();
            } else if (evt.key === 'Enter' && this.autoComplete.selectIndex !== null) {
                this.notifySubscribers({ autoCompleteClose: true });

                this.autoComplete = null;
                this.notifyAutocompleteChanges();
            } else {
                return false;
            }

            return true;
        };

        if (handleAutoComplete()) return true;
        return false;
    }

    handleStateChanges(newState) {
        const checkAutocomplete = () => {
            const detect = detectAutocomplete(newState);
            if (!detect) return null;

            const { textBefore, textAfter, $cursor } = detect;

            const text = textBefore + (textAfter || '');
            const tokens = Object.values(this.tokenMap || {});
            // hidden tokens shouldn't show up in auto-complete
            const filtered: Token[] = _.filter(tokens, (token) => !token.hidden && _.includes(token.selector, text));
            if (!filtered.length) return null;

            const items = filtered.map((token) => ({
                text: token.selector,
                value: token.selector,
            }));

            try {
                const startPosition = this.view!.coordsAtPos($cursor.pos - textBefore.length - 1);
                const cursorPosition = this.view!.coordsAtPos($cursor.pos - 1);
                return { items, startPosition, cursorPosition };
            } catch (e) {
                return null;
            }
        };

        this.autoComplete = checkAutocomplete();
        this.notifyAutocompleteChanges();
    }

    notifySubscribers(msg) {
        for (const fn of this.listenerSubs) {
            fn(msg);
        }
    }

    notifyAutocompleteChanges() {
        this.notifySubscribers({ autoComplete: { data: this.autoComplete } });
    }

    notifyStateChanges(newState, oldState) {
        this.notifySubscribers({ newState, oldState });
    }

    initDocNode = (document?: any) => {
        try {
            if (document) return Node.fromJSON(this.schema, document);
        } catch (e) {}

        const initial = {
            type: 'doc',
            content: [{ type: 'paragraph', content: [] }],
        };

        return Node.fromJSON(this.schema, initial);
    };

    replaceDoc(newDoc) {
        this.view.updateState(
            EditorState.create({
                ...this.view.state,
                doc: this.initDocNode(newDoc),
            }),
        );
    }
}
