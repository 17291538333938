import React from 'react';

import { generatePath, useNavigate } from 'react-router-dom';

import { UtilityRateLibrary } from 'reports/modules/utility_rate/components/UtilityRateLibrary';

const UtilityRates = () => {
    const navigate = useNavigate();

    const navigateToUtilityRate = (rateId: number) =>
        navigate({
            pathname: generatePath('/utility-rates/:rateId', { rateId: rateId.toString() }),
        });
    return (
        <UtilityRateLibrary
            hideUpsellBanner={true}
            inlineControls={true}
            navigateToUtilityRate={navigateToUtilityRate}
        />
    );
};

export { UtilityRates };
