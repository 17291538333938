/* tslint:disable:variable-name */
import moment from 'moment';
import { chain, sumBy, range, mapValues } from 'lodash';

import { ReduxEndpoint, BaseClass } from 'reports/utils/api';
import { DeepPartial } from 'reports/utils/types';
import {
    getMonthFromUnix,
    getHourOfDayFromUnix,
    hourIndex,
    getCurrentMonth,
    makeJan1970Hour,
} from 'reports/utils/time';

import { schema } from './schema';

import * as proj from './project';
import * as s3File from './s3file';
import * as up from './usage_profile';
import * as user from './user';
import * as cpo from './consumption_parser_options';

export interface IIntervalData {
    energy: number; // watt hours
    interval_start: number; // seconds since Unix Epoch
    cost?: number; // cents
}

interface ISampleData {
    data: IIntervalData[];
    total_duration?: number; // seconds (from GreenButton)
    start_time?: number; // unix epoch seconds (from GreenButton)
}

export interface IUserInput {
    [k: number]: {
        // 1-indexed month
        energy?: number; // watt hours
        cost?: number; // dollars
    };
}

const SOURCE_TYPES = {
    user_input: 'User Input',
    green_button_xml: 'Green Button (XML)',
    pvsyst_csv: 'PVSyst (CSV)',
    custom_csv: 'CSV (two columns)',
};

type SourceType = keyof typeof SOURCE_TYPES;

export function consumptionMonthlyKWh(sampleData, tzOffset) {
    // return a 1..12 indexed object => energy
    return (
        chain(sampleData.data)
            // 1 indexed Month
            .groupBy((interval) => getMonthFromUnix(interval.interval_start, tzOffset))
            .mapValues((data) => sumBy(data, 'energy') / 1000)
            .value()
    );
}

class UserConsumption extends BaseClass {
    user_consumption_id: number;

    name: string;

    project_id: number;
    project: proj.Project;

    source_type: SourceType;
    sample_data: ISampleData;
    interval_size: number; // minutes

    consumption_parser_options_id: number;
    consumption_parser_options: cpo.ConsumptionParserOptions;

    // synthetically generated files should have a usage profile
    // as well as some selected user input
    usage_profile_id?: number;
    usage_profile?: up.UsageProfile;
    user_input?: IUserInput;

    // uploaded files will have a file
    file_id?: number;
    file?: s3File.S3File;

    created: moment.Moment;
    last_modified: moment.Moment;

    creator_id: number;
    creator: user.User;

    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    private _hourlySeries: number[];

    get source() {
        return SOURCE_TYPES[this.source_type];
    }

    localMonthlyKWh(tzOffset = this.project.time_zone_offset) {
        return consumptionMonthlyKWh(this.sample_data, tzOffset);
    }

    local8760Energy(tzOffset = this.project.time_zone_offset) {
        // return a 1..8760 array of energy
        if (this._hourlySeries == null) {
            const energyByHourIndex = chain(this.sample_data.data)
                .groupBy((interval) => hourIndex(moment.unix(interval.interval_start).utcOffset(tzOffset)))
                // average out duplicates in the same hour
                .mapValues((samples) => sumBy(samples, 'energy') * (60.0 / (this.interval_size * samples.length)))
                .value();

            this._hourlySeries = range(1, 8761).map((idx) => energyByHourIndex[idx] || 0);
        }

        return this._hourlySeries;
    }

    userInputKWh(): IUserInput {
        const userInputWh = this.user_input;
        if (userInputWh != null) {
            if ((userInputWh as any).month != null) {
                const deprecatedInput = userInputWh as any;
                // this is an old format (in kWh), there are only a few of them
                return {
                    [deprecatedInput.month]: { energy: deprecatedInput.energy },
                };
            }

            return mapValues(userInputWh, (input) => ({
                ...input,
                energy: input.energy! / 1000,
            }));
        }

        const currentMonth = getCurrentMonth();
        return {
            [currentMonth]: {
                energy: this.localMonthlyKWh()[currentMonth],
            },
        };
    }

    /**
     * Generates the average daily profile by hour for each month.
     * The timestamps are for 1/1/1970 (chosen arbitrarily) because Highcharts
     * expects a concrete date and not an hour and minute index.
     *
     * @param tzOffset The time zone offset of the intervals
     * @returns daily profiles for each month that each look like:
     *      [
     *          [<timestamp for 1/1/1970 0:00>, <energy in kWH>],
     *          [<timestamp for 1/1/1970 1:00>, <energy in kWH>],
     *          ...
     *      ]
     */
    dailyProfileKWh(tzOffset = this.project.time_zone_offset) {
        return (
            range(1, 13)
                .map((month) => {
                    return this.sample_data.data.filter((interval) => {
                        return getMonthFromUnix(interval.interval_start, tzOffset) === month;
                    });
                })
                // This should be agnostic to interval length since it's doing a simple summation
                .map((intervals) => {
                    return chain(intervals)
                        .groupBy((interval) => getHourOfDayFromUnix(interval.interval_start, tzOffset))
                        .mapKeys((_val, hour) => {
                            return makeJan1970Hour(parseInt(hour, 10));
                        })
                        .mapValues((intervals) => {
                            return sumBy(intervals, 'energy') / (1000.0 * intervals.length);
                        })
                        .entries()
                        .map(([k, v]) => [parseInt(k, 10), v])
                        .value();
                })
        );
    }

    toString() {
        return `${this.name} (${SOURCE_TYPES[this.source_type]})`;
    }

    static deserializer = BaseClass.getDeserializer({
        last_modified: (x) => moment(x),
        created: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(UserConsumption, 'user_consumption', {
    relationships: {
        creator: { schema: user.schemaObj },
        last_modified_by_user: { schema: user.schemaObj },
        file: { schema: s3File.schemaObj },
    },
    defaultDeep: ['project', 'usage_profile'],
});

const { selector: usageProfile } = schemaObj.addRelationship('usage_profile', up.schemaObj);

const endpoint = ReduxEndpoint.fromSchema('/api/user_consumptions/', schemaObj, { deepSelect: { usage_profile: '*' } });

interface IFileConsumptionForm {
    project_id: number;
    name: string;
    file_id: number;
    consumption_parser_options?: cpo.ConsumptionParserOptions;
    consumption_parser_options_id?: number;
}

interface IGenerateConsumptionForm {
    project_id: number;
    name: string;
    user_input: IUserInput;
    usage_profile_id?: number;
    sample_data: ISampleData;
}

export type UserConsumptionForm = IGenerateConsumptionForm | IFileConsumptionForm;

interface IUnsupportedFileForm {
    file_id: number;
    survey_question: string;
    survey_response: string;
}

const api = {
    index: endpoint.index<{ project_id?: number; user_id?: number }>(),
    get: endpoint.get<{ user_consumption_id: number }>('{user_consumption_id}'),
    create: endpoint.post<UserConsumptionForm>(),
    save: endpoint.put<DeepPartial<UserConsumption>>('{user_consumption_id}'),
    delete: endpoint.delete('{user_consumption_id}'),
    // we don't want to store the result in redux, so use PassThroughConfig
    tryParse: endpoint.post<UserConsumptionForm>('try_parse', ReduxEndpoint.PassThroughConfig()),

    logUnsupportedFile: endpoint.post<IUnsupportedFileForm>('unsupported_file', ReduxEndpoint.PassThroughConfig()),
};

const selectors = {
    usageProfile,

    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { UserConsumption, api, endpoint, schemaObj, selectors };
