import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { HotkeysProvider } from '@blueprintjs/core';

import { fromNow } from 'reports/utils/formatters';
import { bindActions } from 'reports/utils/redux';
import { IAppState } from 'reports/types';

import { TRANSLATED_LOCALES } from 'reports/localization/lang';

import { EditButton, FavoriteStar } from 'reports/components/core/controls';
import { LibraryPreview } from 'reports/components/core/layout';
import { InlineFormGroup } from 'reports/components/helpers/formHelpers';
import { PreviewDrawer } from 'reports/components/library/main/components';
import { ThemeSelect } from 'reports/modules/themes';

import * as rep from 'reports/models/report';
import * as proj from 'reports/models/project';
import * as tm from 'reports/models/team';
import * as thm from 'reports/models/theme';
import { actions as repActions } from 'reports/modules/report';

import { LocaleSelect } from 'reports/modules/settings/team/components';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import DocumentEditor from './DocumentEditor';
import ProjectSelect from './ProjectSelect';

interface IOwnProps {
    report: rep.Report;
    project: proj.Project;
    printableReady: Promise<any>;
}

interface IState {
    drawerOpen: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

class ReportPreview extends React.PureComponent<IProps, IState> {
    state: IState = {
        drawerOpen: false,
    };

    componentDidMount() {
        // Delay drawer open to allow element to be completely rendered before triggering drawer's transition animation
        setTimeout(() => this.setState({ drawerOpen: true }), 100);

        this.props.loadThemes();
    }

    renderEmptyHotkeys = () => <></>; // Disable Report Editor hotkeys for report preview

    render() {
        const { report, project, document, printableReady, team, themes } = this.props;

        const teamConfigControls = (
            <>
                <InlineFormGroup label="Report Locale">
                    <LocaleSelect
                        localeList={TRANSLATED_LOCALES}
                        selectedItem={report.locale}
                        onItemSelect={(locale) => this.props.setReportLocale(report, locale)}
                        noFill
                    />
                </InlineFormGroup>
                <InlineFormGroup label="Team Theme">
                    <ThemeSelect
                        themes={themes}
                        selectedTheme={this.props.theme}
                        onItemSelect={(theme) => this.props.setTeamTheme(team, theme)}
                        disabled={themes.length === 0}
                        noFill
                    />
                </InlineFormGroup>
            </>
        );

        const bookmarked = report.bookmarked();
        const lastModified = report.last_modified || report.created;

        return (
            <PreviewDrawer
                isOpen={this.state.drawerOpen}
                onClose={() => this.setState({ drawerOpen: false })}
                onClosed={() =>
                    this.props.navigateTo('app.reports', {
                        projectId: project.project_id,
                    })
                }
                title={
                    <LibraryPreview.Header
                        title={report.name}
                        subtitle={lastModified && `Last modified ${fromNow(lastModified)}`}
                        favorite={
                            <FavoriteStar
                                empty={!bookmarked}
                                onClick={() => {
                                    addPromiseToastsToggle(
                                        'favorite',
                                        this.props.setBookmarked(!bookmarked),
                                        'report',
                                        report.name,
                                        bookmarked,
                                    );
                                }}
                            />
                        }
                    />
                }
                editButton={<EditButton onClick={() => this.props.openEditor()} />}
                widthInPercent={70}
                teamConfigControls={teamConfigControls}
            >
                <HotkeysProvider renderDialog={this.renderEmptyHotkeys}>
                    <DocumentEditor
                        mode="view"
                        report={report}
                        document={document}
                        project={project}
                        printableReady={printableReady}
                        controls={
                            <>
                                <ProjectSelect selectedProject={project} />
                                <div style={{ marginRight: 'auto' }} />
                            </>
                        }
                    />
                </HotkeysProvider>
            </PreviewDrawer>
        );
    }
}

const mapStateToProps = (state: IAppState, { report, project }: IOwnProps) => {
    const team: tm.Team = proj.selectors.teamSelector(state, project)!;
    return {
        team,
        document: rep.versionSelectors.latestDocument(state, report),
        theme: tm.selectors.themeSelector(state, team),
        themes: thm.selectors.all(state),
    };
};

const mapDispatchToProps = bindActions(({ report }: IOwnProps) => ({
    navigateTo: routerActions.navigateTo,
    openEditor: () => repActions.setEditing(true),
    setBookmarked: (bookmarked) => rep.api.patchTeamSettings({ bookmarked, report_id: report.report_id }),
    loadThemes: thm.api.index,
    setReportLocale: (report, locale) => rep.api.save({ ...report, locale }),
    setTeamTheme: (team, theme) => tm.api.save({ ...team, theme_id: theme.theme_id }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ReportPreview);
