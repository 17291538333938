import { Classes, Navbar } from '@blueprintjs/core';

import Colors from 'reports/styles/colors';
import { Flex } from 'reports/components/core/containers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const Controls = styled(Flex.Container)`
    margin-right: 20px;
`;

const NavBar = styled(Navbar)`
    overflow: hidden;

    // Blueprint overrides
    box-shadow: none;
    padding: 0 0 4px 0;

    &.${Classes.DARK} {
        background-color: ${Colors.BG_DARK};
    }
    .${Classes.BUTTON_GROUP} {
        height: 100%;
    }
    .${Classes.DIVIDER} {
        margin: 0;
    }
`;

export default {
    Controls,
    NavBar,
};
