import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Icon } from '@blueprintjs/core';

import Translations from 'reports/localization/strings';

import { Design } from 'reports/models/design';
import * as fmt from 'reports/utils/formatters';
import { WidgetDataTable } from 'reports/modules/report/components/helpers';

const centeredStyle: React.CSSProperties = {
    verticalAlign: 'middle',
    textAlign: 'center',
};

function checkCombiners(wiringZones) {
    try {
        for (const id in wiringZones) {
            if (wiringZones[id]['combiner'].length) {
                return true;
            }
        }
    } catch (err) {
        return false;
    }
    return false;
}

const DesignWiringZoneTable: React.FC<{
    design: Design;
    className?: string;
}> = ({ design, className }) => {
    const wzBounds = {};
    const wzCombiners = {};

    if (design != null) {
        const hasCombiners = checkCombiners(design.field_component_metadata.wiring_zones);
        for (const wz of design.wiring_zones) {
            try {
                if (hasCombiners && wz.bus_id !== null) {
                    wzCombiners[wz.wiring_zone_id] = wz.combiner_poles;
                } else {
                    wzCombiners[wz.wiring_zone_id] = '-';
                }

                wzBounds[wz.wiring_zone_id] = wz.stringBounds();
            } catch (err) {
                wzBounds[wz.wiring_zone_id] = '-';
            }
        }
    }

    let errorMessage: JSX.Element | null = null;
    if (design == null) {
        errorMessage = <FormattedMessage {...Translations.errors.no_design_available} />;
    } else if (design.field_segments == null) {
        errorMessage = <FormattedMessage {...Translations.errors.design_data_unavailable} />;
    } else if (design.field_segments.length === 0) {
        errorMessage = <FormattedMessage {...Translations.errors.incomplete_design} />;
    }

    return (
        <div>
            <WidgetDataTable className={className}>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.general.description} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.design.combiner_poles} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.design.string_size} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.design.stringing_strategy} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {errorMessage != null && (
                        <tr>
                            <td style={centeredStyle} colSpan={4}>
                                <div>
                                    <Icon icon="warning-sign" style={{ marginRight: '8px' }} />
                                    {errorMessage}
                                </div>
                            </td>
                        </tr>
                    )}
                    {errorMessage == null &&
                        design.wiring_zones.map((wz, idx) => (
                            <tr key={idx}>
                                <td className="text-cell">{wz.description}</td>
                                <td className="text-cell">{wzCombiners[wz.wiring_zone_id]}</td>
                                <td className="text-cell">
                                    {wzBounds[wz.wiring_zone_id] === '-'
                                        ? wzBounds[wz.wiring_zone_id]
                                        : `${wzBounds[wz.wiring_zone_id].min} - ${wzBounds[wz.wiring_zone_id].max}`}
                                </td>
                                <td className="text-cell">{fmt.stringingStrategy(wz.stringing_strategy)}</td>
                            </tr>
                        ))}
                </tbody>
            </WidgetDataTable>
        </div>
    );
};

export default DesignWiringZoneTable;
