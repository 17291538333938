import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import Translations from 'reports/localization/strings';
import { Project } from 'reports/models/project';
import { TokenId } from 'reports/modules/financials/model/tokens';
import * as finState from 'reports/modules/financials/state';
import { DeclTokenSelector, finTokenValue } from 'reports/modules/report/tokens/declarativeTokens';
import { IAppState } from 'reports/types';
import { DetailsTable, WidgetDetailsTable } from 'reports/modules/report/components/helpers';

type IStateProps = ReturnType<ReturnType<typeof mapStateToProps>>;

class _FinancialMetrics extends React.Component<IStateProps & { intl: IntlShape }> {
    render() {
        const { financialTokens, intl } = this.props;

        const tokenValue = (id: TokenId & DeclTokenSelector) => finTokenValue(financialTokens, id, intl.locale);

        return (
            <tbody>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.financial.system_start} />
                    </th>
                    <td>{tokenValue('system_life_start')}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.financial.total_value_of_energy} />
                    </th>
                    <td>{tokenValue('lifetime_savings')}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.financial.system_npv_cash} />
                    </th>
                    <td>{tokenValue('system_npv_cash')}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.financial.internal_rate_of_return} />
                    </th>
                    <td>{tokenValue('irr')}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.financial.return_on_investment} />
                    </th>
                    <td>{tokenValue('roi')}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.financial.payback_period} />
                    </th>
                    <td>{tokenValue('payback_period')}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.financial.lcoe} />
                    </th>
                    <td>{tokenValue('lcoe')}</td>
                </tr>
            </tbody>
        );
    }
}

const FinancialMetrics = injectIntl(_FinancialMetrics);

interface ITableProps {
    overrideTokens?: finState.MaybeFinOutput;
    project?: Project;
}

const _FinancialMetricsTable: React.FC<ITableProps & IStateProps> = (props) => (
    <DetailsTable>
        <FinancialMetrics financialTokens={props.overrideTokens || props.financialTokens} />
    </DetailsTable>
);

const mapStateToProps = () => {
    return (state: IAppState, ownProps: { project?: Project }) => ({
        financialTokens: finState.selectors.primaryFinConfigTokens(state, ownProps),
    });
};

const FinancialMetricsTable = connect(mapStateToProps)(_FinancialMetricsTable);

interface IWidgetTableProps {
    className?: string;
    financialTokens: finState.MaybeFinOutput;
}

export const FinancialMetricsWidgetTable: React.FC<IWidgetTableProps> = ({ className, financialTokens }) => (
    <WidgetDetailsTable className={className}>
        <FinancialMetrics financialTokens={financialTokens} />
    </WidgetDetailsTable>
);

export default FinancialMetricsTable;
