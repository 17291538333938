import * as React from 'react';

import { Icon } from '@blueprintjs/core';

type Props = React.PropsWithChildren<{
    inline?: boolean;
    warningStyle: {
        marginTop?: string;
        height?: string;
    };
}>;

const TeamWarning = React.memo(({ children, inline = false, warningStyle }: Props) =>
    inline ? (
        <div style={{ marginTop: '16px', display: 'inline-block' }}>
            <div className="text-warning" style={warningStyle}>
                <span>
                    <Icon icon="warning-sign" style={{ marginRight: '8px' }} /> Warning:
                    {children}
                </span>
            </div>
        </div>
    ) : (
        <div className="text-warning" style={warningStyle}>
            <span>
                <Icon icon="warning-sign" style={{ marginRight: '8px' }} />
                {children}
            </span>
        </div>
    ),
);

export { TeamWarning };
