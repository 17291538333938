import * as React from 'react';

import { HorizonProfile } from 'reports/models/horizon_profile';

import Section2 from 'reports/components/core/containers/Section2';
import FormBasicSelect from 'reports/components/forms/inputs/experimental/FormBasicSelect';

type IConditionSetHorizonEdit = {
    horizons: HorizonProfile[];
};

const ConditionSetHorizonEdit: React.FC<IConditionSetHorizonEdit> = ({ horizons }) => {
    return (
        <Section2 title="Horizon Shading" subtitle="Include models of any near-shade or horizon obstructions">
            <FormBasicSelect<HorizonProfile>
                path="horizon_id"
                label="Horizon Profile"
                helperText="The horizon file (like those created by a Solmetric Suneye) defines the far shading of the array, and affects all modules equally."
                itemRenderer={(h) => ({
                    key: h.horizon_id,
                    text: h.description,
                })}
                dataSource={{
                    items: horizons,
                    keyLookup: (h) => h.horizon_id,
                }}
                noneSelected="Select Horizon Profile..."
                noResults="'No Horizon Profiles available'"
            />
        </Section2>
    );
};

export default ConditionSetHorizonEdit;
