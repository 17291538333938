import * as React from 'react';

import { Callout } from '@blueprintjs/core';

import { DetailLine } from 'reports/modules/settings/common';

import { ScheduledChanges, Subscription } from 'reports/models/subscription';

import { promptCancelScheduledChanges, seatRemovalText } from './promptCancelScheduledChanges';

import { styled } from 'reports/styles/styled-components';

const StyledCallout = styled(Callout)`
    width: 270px;
`;

interface Props {
    cancelScheduledChanges: any;
    manageBilling: boolean;
    subscription: Subscription;
}

const ScheduledChangeCallout = ({ cancelScheduledChanges, manageBilling, subscription }: Props) => {
    if (!(subscription.scheduled_changes && manageBilling)) {
        return null;
    }
    const seatRemovalScheduled = subscription.scheduled_changes === ScheduledChanges.remove_seats;
    const prevQuantitySeats = seatRemovalScheduled
        ? subscription.stripe_data['schedule']['phases'][0]['plans'][0]['quantity']
        : undefined;
    const scheduledQuantitySeats = seatRemovalScheduled
        ? subscription.stripe_data['schedule']['phases'][1]['plans'][0]['quantity']
        : undefined;
    const seatsToBeRemoved = seatRemovalScheduled ? prevQuantitySeats - scheduledQuantitySeats : undefined;

    return (
        <StyledCallout>
            {seatRemovalScheduled &&
                seatsToBeRemoved &&
                seatRemovalText(seatsToBeRemoved, 'scheduled to be removed at the end of this billing cycle.')}

            {subscription.scheduled_changes === ScheduledChanges.plan_downgrade &&
                'Your account is scheduled to be downgraded to Basic at the end of this billing cycle.'}

            {subscription.scheduled_changes === ScheduledChanges.cancel_at_period_end &&
                'Your subscription will not be renewed at the end of this billing cycle.'}
            <DetailLine>
                <a
                    onClick={async () => {
                        await promptCancelScheduledChanges(seatsToBeRemoved, cancelScheduledChanges, subscription);
                    }}
                >
                    Cancel this
                </a>
            </DetailLine>
        </StyledCallout>
    );
};

export { ScheduledChangeCallout };
