import { groupBy, mapKeys, reduce } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';

import { Classes, MenuItem } from '@blueprintjs/core';

import { IAppState } from 'reports/types';

import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';

import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';
import * as proj from 'reports/models/project';

import { bindActions } from 'reports/utils/redux';

interface IOwnProps {
    project: proj.Project;
    onItemSelect: (item: FinConfigs) => any;
    activeConfig?: projFinTemp.ProjectFinancialTemplate;
    style?: React.CSSProperties;
}

export type FinConfigs = projFinTemp.ProjectFinancialTemplate | finTemp.FinancialTemplate;

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    loading: boolean;
}

export function isFinConfig(config: FinConfigs): config is projFinTemp.ProjectFinancialTemplate {
    return (config as projFinTemp.ProjectFinancialTemplate).project_financial_template_id !== undefined;
}

class ProjectFinancialConfigSelect extends React.PureComponent<IProps, IState> {
    state: IState = {
        loading: true,
    };

    async componentDidMount() {
        await this.props.loadFinTemplates();
        this.setState({ loading: false });
    }

    render() {
        const { activeConfig, financialTemplates, project, style, onItemSelect } = this.props;
        const { loading } = this.state;

        const groupedConfigs = groupBy(project.project_financial_templates, 'financial_template_id');
        const templateMap = mapKeys(financialTemplates, (template) => template.financial_template_id);

        const items = reduce(
            financialTemplates,
            (items, finTempl) => {
                const group = groupedConfigs[finTempl.financial_template_id] || [];
                if (group.length) {
                    return items.concat(group);
                }
                if (finTempl.bookmarked()) {
                    return items.concat([finTempl]);
                }
                return items;
            },
            [] as FinConfigs[],
        );

        const renderConfigMenuItem = (config: projFinTemp.ProjectFinancialTemplate, { handleClick, modifiers }) => {
            const isConfig = isFinConfig(config);
            const templateId = config.financial_template_id;
            const templateName = templateMap[templateId].description;

            const key = isConfig ? `fin-config-${config.project_financial_template_id}` : `fin-template-${templateId}`;
            return (
                <DropdownMenuItem
                    key={key}
                    title={isConfig ? config.name : templateName}
                    subtitle={
                        isConfig ? (
                            `${templateName} Model`
                        ) : (
                            <span className={Classes.TEXT_DISABLED}>Create new configuration</span>
                        )
                    }
                    onClick={handleClick}
                    {...modifiers}
                />
            );
        };

        return (
            <StaticSelect<FinConfigs>
                buttonText={activeConfig ? activeConfig.name : 'Select Configuration'}
                activeItem={activeConfig || null}
                items={
                    /* Wait for fin models to be loaded before displaying any items. Otherwise, this would display a
                    half full list during loading, since fin configs were already loaded by the route. */
                    loading ? [] : items
                }
                itemRenderer={renderConfigMenuItem}
                onItemSelect={onItemSelect}
                noResults={
                    <MenuItem
                        disabled={true}
                        text={loading ? 'Loading financial configurations...' : 'No financial models to select from.'}
                    />
                }
                buttonProps={{ style }}
            />
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    financialTemplates: finTemp.selectors.all(state),
});

const mapDispatchToProps = bindActions({
    loadFinTemplates: finTemp.api.index,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFinancialConfigSelect);
