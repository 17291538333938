import Logger from 'js-logger';

const logger = Logger.get('wiring_zone');

export class ComponentConfigurationBuilder {
    constructor(wiringZone) {
        this.wiringZone = wiringZone;
    }

    buildConfig() {
        const dcVal = this.inverterSchedule();
        dcVal.combinerTiers = this.combinerTiers();
        return {
            dc: dcVal,
        };
    }


    /**
     * pre-process/clean the WiringZone assumptions to create a valid wiring configuration
     */
    inverterSchedule(wiringZone = this.wiringZone) {
        const stringBounds = wiringZone.stringBounds();
        const { createSchedule } = stringBounds;
        const moduleCount = wiringZone.moduleCount();
        const inverterCount = wiringZone.targetInverterCount();

        let { min, max } = stringBounds;

        if (moduleCount < min) {
            logger.warn(`${moduleCount} is shorter than the min string length of ${min};` +
                        'correcting to ensure stringing');
            min = moduleCount;
            max = Math.max(min, max);
        }

        return {
            inverterSchedule: createSchedule({ min, max }, moduleCount, inverterCount),
            stringBounds,
        };
    }

    combinerTiers(wiringZone = this.wiringZone) {
        const combinerTiers = [];
        if (wiringZone.bus_id !== null) {
            combinerTiers.push({ outputWireId: wiringZone.bus_id, inputCount: wiringZone.combiner_poles, tier: 'bus' });
        }

        if (wiringZone.trunk_id !== null) {
            // current behavior is that recombiners have infinite poles
            combinerTiers.push({ outputWireId: wiringZone.trunk_id, inputCount: 9999999, tier: 'trunk' });
        }

        return combinerTiers;
    }
}

export default ComponentConfigurationBuilder;
