import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors as authSelectors } from 'reports/modules/auth';
import { canManageBilling, helioscopeAdmin } from 'reports/modules/auth/permissions';

import { Subscription, api as SubscriptionV2API } from 'reports/models/subscription';
import { User } from 'reports/models/user';

import { useBillingContext } from 'reports/modules/settings/billing/Context';

import { SettingsPane } from 'reports/modules/settings/common';
import InvoiceList from 'reports/modules/settings/billing/components/InvoiceList';

import { PurchaseDialog } from './PurchaseDialog';
import { PaymentDetails } from './PaymentDetails';
import { PlanOverview } from './PlanOverview';
import { TeamSizeDialog } from './TeamSizeDialog';
import { BillingInfoDialog } from './BillingInfoDialog';
import { PusherChannel } from 'reports/modules/project/listeners';
import { makeChannel } from 'helioscope/app/utilities/pusher';
import { UpdatePlanDialog } from './UpdatePlanDialog';

import TestClockDisplay from './TestClockDisplay';
import { ProjectEnforcementDateCallout } from './ProjectEnforcementDateCallout';
import { UpdateBillingInfoDialog } from 'reports/modules/settings/billing/components/UpdateBillingInfoDialog';

interface Props {
    navigateToBillingOverview: () => void;
    subscription?: Subscription;
    user: User;
}

const BillingOverview = React.memo(({ navigateToBillingOverview, subscription, user }: Props) => {
    const dispatch = useDispatch();

    const authUser = useSelector((state) => authSelectors.getUser(state)!);
    const getSubscription = (data: { external_id: string }) => dispatch(SubscriptionV2API.get(data));
    const billingContext = useBillingContext();

    const [subChannel, setSubChannel] = React.useState<PusherChannel>();

    React.useEffect(() => {
        if (subscription) {
            const channel = makeChannel(`subscription@${subscription.external_id}`);
            setSubChannel(channel);
            channel.watch('customer.subscription.deleted', async (data: { external_id: string }) => {
                await getSubscription(data);
            });
        }
        return () => subChannel && subChannel.unsubscribe();
    }, [subscription?.external_id]);

    const manageBilling = canManageBilling(user);
    const isOnActiveV2CustomPlan = subscription?.is_contract && subscription.status === 'active';
    const migrationLog = subscription?.billing_v2_migration_log;
    const shouldRemindToUpdateBilling =
        user.team_admin &&
        !helioscopeAdmin(authUser) &&
        migrationLog?.migration_ended_at &&
        !migrationLog!.reminded_to_update_billing_at;
    const selfServeDisabled = subscription?.selfServeDisabled;
    const userHasV2SelfServe = manageBilling && !isOnActiveV2CustomPlan && !selfServeDisabled;
    // For checkout flow dialogs: we don't want to render the dialogs even when they are closed
    // because they will still attempt to fetch data from the backend.  i.e.: PurchaseDialog, despite being
    // closed, would try to fetch stripe prices.
    return (
        <SettingsPane
            content={
                <>
                    <TestClockDisplay />
                    <ProjectEnforcementDateCallout user={user} />
                    {shouldRemindToUpdateBilling && subscription?.is_self_serve && (
                        <UpdateBillingInfoDialog subscription={subscription} team={user.team} />
                    )}
                    {userHasV2SelfServe && (
                        <>
                            {billingContext.dialog === 'initial' && (
                                <PurchaseDialog
                                    closeDialog={() => navigateToBillingOverview()}
                                    isOpen={
                                        billingContext.dialog === 'initial' &&
                                        user.latest_subscription?.cleaned_status !== 'unpaid'
                                    }
                                    user={user}
                                />
                            )}
                            {billingContext.dialog === 'change' && !!subscription && (
                                <UpdatePlanDialog
                                    closeDialog={() => navigateToBillingOverview()}
                                    isOpen={billingContext.dialog === 'change'}
                                    user={user}
                                />
                            )}
                            {billingContext.dialog === 'licenses' && !!subscription && (
                                <TeamSizeDialog
                                    closeDialog={() => navigateToBillingOverview()}
                                    isOpen={billingContext.dialog === 'licenses'}
                                    subscription={subscription!}
                                    user={user}
                                />
                            )}
                            {billingContext.dialog === 'billing' && !!subscription && (
                                <BillingInfoDialog
                                    closeDialog={() => navigateToBillingOverview()}
                                    isOpen={billingContext.dialog === 'billing'}
                                    subscription={subscription!}
                                    user={user}
                                />
                            )}
                        </>
                    )}
                    <PlanOverview subscription={subscription} user={user} manageBilling={manageBilling} />
                    <PaymentDetails subscription={subscription} subChannel={subChannel} manageBilling={manageBilling} />
                    <InvoiceList
                        subscription={subscription}
                        subChannel={subChannel}
                        user={user}
                        manageBilling={manageBilling}
                    />
                </>
            }
        />
    );
});

export { BillingOverview };
