import * as React from 'react';

import { UserPreferences as BetaUserPreferences } from 'reports/modules/settings/user/views/UserPreferences';

import { useSettingsContext } from 'frontend/settings/use-settings-data';

const UserPreferences = () => {
    const { user } = useSettingsContext();

    return <BetaUserPreferences user={user} />;
};

export { UserPreferences };
