/* tslint:disable:variable-name */
/* eslint camelcase: 0 */

import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { Button, Classes, Dialog, Switch } from '@blueprintjs/core';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';
import { Warning } from 'reports/components/helpers/errors';
import Flex from 'reports/components/core/containers/Flex';

import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';

import { FinancialPipelineTree, Node, paramKey, pipelineModuleData } from 'reports/modules/financials/model/pipeline';
import * as finState from 'reports/modules/financials/state';

import { LineItem } from './LineItem';
import { complexEditor, PipelineNode, PipelineEditor } from './pipelineCommon';
import Result from './Result';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const FinancialStepBox = styled.div`
    display: grid;
    grid-template-columns: 38px 3fr 5fr;
    grid-gap: 4px;
    margin: 4px 2px;
    padding: 8px 2px;
    border-bottom: 1px solid #e0e0e0;
`;

const StepHeader = styled.div`
    display: flex;
    align-items: flex-start;
    font-weight: 700;
`;

const ParamList = styled.div`
    margin-bottom: 4px;

    .finance-parameter-line {
        min-height: 30px;
    }

    .finance-parameter-entry:nth-of-type(1) {
        margin-top: 0px;
    }
`;

// Clean up with other Financial Config editor styles - make them more consistent and cohesive.
const FinancialConfigurationContainer = styled.div`
    ${FinancialStepBox}:last-child {
        border-bottom: none;
    }

    &&&& .line-text {
        flex: 0 0 50%;
        width: auto;
    }

    .line-edit {
        flex: 0 0 50%;
        display: flex;
        max-width: 175px;

        .${Classes.INPUT_GROUP} {
            width: 100%;
        }

        .${Classes.SWITCH} {
            margin-bottom: 0px;
        }
    }
`;

const FinMiniEditorContainer = styled(Flex.Main)`
    padding: 8px;
    max-height: 600px;
    overflow: auto;
    border: 1px solid #f0f0f0;
`;

interface IOwnProps {
    config: projFinTemp.ProjectFinancialTemplate;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
    expandParams: any;
}

class FinancialConfigurationMiniEditor extends React.Component<IProps, IState> {
    state = { expandParams: {} };

    render() {
        const { config, output, template } = this.props;
        const { expandParams } = this.state;

        const emptyCell = <div>&nbsp;</div>;

        const renderSync = () => {
            if (!template) {
                return <Warning msg="Base model deleted" />;
            }

            if (config.financial_template_version_id !== template.latest_version_id) {
                return <Warning msg="Newer model exists" />;
            }

            return null;
        };

        let tree: FinancialPipelineTree | null = null;

        try {
            const baseTree = FinancialPipelineTree.fromRawData(config.template_data.root);
            const configTree = FinancialPipelineTree.fromRawData(config.configuration_data.root);
            tree = baseTree.mergeNodeData(configTree);
        } catch (e) {
            return <Warning msg="Configuration has errors -- please try recreating or updating" />;
        }

        const toggleExpand = (pkey) =>
            this.setState({
                expandParams: _.assign({}, expandParams, {
                    [pkey]: !expandParams[pkey],
                }),
            });

        const renderLeaf = (node: Node, nindex: number[]) => {
            const modDat = pipelineModuleData(node);
            const filtered = modDat.metaParams.filter((i) => !i.hidden && i.configurable);

            if (!filtered.length && !node.toggleable) return null;

            const parameters = filtered.map((i, idx) => {
                const pkey = paramKey(nindex, idx);

                const renderParam = () => (
                    <PipelineNode
                        metaParam={i}
                        tree={tree!}
                        nindex={nindex}
                        updateTree={(updated) => this.updateTree(updated)}
                    />
                );

                const renderDialog = () => (
                    <Dialog style={{ padding: '4px' }} isOpen>
                        {renderParam()}
                        <div style={{ padding: '4px' }}>
                            <Button onClick={() => toggleExpand(pkey)}>Close</Button>
                        </div>
                    </Dialog>
                );

                if (complexEditor(i)) {
                    const expanded = expandParams[pkey];

                    return (
                        <LineItem
                            key={idx}
                            label={i.description}
                            line={
                                <Button
                                    fill
                                    icon="edit"
                                    text="Edit"
                                    active={expanded}
                                    onClick={() => toggleExpand(pkey)}
                                />
                            }
                            after={expanded ? renderDialog() : null}
                        />
                    );
                }

                return <LineItem key={idx} label={i.description} line={renderParam()} />;
            });

            const outputs =
                node.module.outputValues &&
                node.module.outputValues.map((result, idx) => {
                    const resultComponent = (
                        <Result
                            key={idx}
                            result={{
                                ...result,
                                value: _.get(output, result.path),
                            }}
                        />
                    );
                    if (result.description) {
                        return (
                            <LineItem
                                key={idx}
                                label={result.description}
                                line={<div style={{ paddingLeft: '4px' }}>{resultComponent}</div>}
                            />
                        );
                    }
                    return resultComponent;
                });

            const renderToggle = () => {
                if (!node.toggleable) {
                    return emptyCell;
                }

                return (
                    <Switch
                        style={{ marginBottom: '0px' }}
                        checked={!node.toggled_off}
                        onChange={() => {
                            const updated = tree!.updateNode(nindex, 'toggled_off', !node.toggled_off);
                            this.updateTree(updated);
                        }}
                    />
                );
            };

            return (
                <FinancialStepBox key={nindex.toString()}>
                    {renderToggle()}
                    <StepHeader>
                        <span style={!node.toggled_off ? {} : { color: '#a0a0a0' }}>
                            {node.user_label || modDat.description}
                        </span>
                    </StepHeader>
                    {node.toggleable && node.toggled_off ? (
                        emptyCell
                    ) : (
                        <ParamList>
                            {parameters}
                            {outputs}
                        </ParamList>
                    )}
                </FinancialStepBox>
            );
        };

        const renderRoot = (_node: Node, children) => {
            return <div>{children}</div>;
        };

        return (
            <FinMiniEditorContainer>
                {renderSync()}
                <FinancialConfigurationContainer>
                    <PipelineEditor tree={tree} renderLeaf={renderLeaf} renderRoot={renderRoot} />
                </FinancialConfigurationContainer>
            </FinMiniEditorContainer>
        );
    }

    updateTree(tree) {
        const root = tree.toRawData();
        const { configuration_data } = this.props.config;
        this.props.updateFinancialConfig({
            configuration_data: _.assign({}, configuration_data, { root }),
        });
    }
}

const mapStateToProps = (state: IAppState, props: IOwnProps) => ({
    output: finState.selectors.finConfigOutput(state, props),
    template: finTemp.selectors.byId(state, props.config.financial_template_id),
});

const mapDispatchToProps = bindActions(({ config }) => ({
    updateFinancialConfig: (patch) => projFinTemp.saver.get(config).patch(patch),
}));

export default connect(mapStateToProps, mapDispatchToProps)(FinancialConfigurationMiniEditor);
