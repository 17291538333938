import * as React from 'react';

import { Card } from 'reports/components/core/containers';

import { EditableTitleSubHeader } from 'reports/components/helpers/common';

interface IOwnProps {
    title?: string;
    updateTitle?: (newTitle: string) => any;

    footer?: React.ReactChildren;
}

export const ProjectAssetPage: React.FC<IOwnProps> = ({ title, updateTitle, footer, children }) => (
    <div className="sub-container">
        <div className="sub-content-container">
            <div className="content-header">
                {title && updateTitle && <EditableTitleSubHeader value={title} updateFn={updateTitle} />}
            </div>
            <div className="sub-content-inner" style={{ padding: '8px' }}>
                <Card style={{ padding: 20 }}>{children}</Card>
            </div>
            <div className="sub-content-footer">{footer}</div>
        </div>
    </div>
);

export default ProjectAssetPage;
