import * as React from 'react';
import { useDispatch } from 'react-redux';

import { actions as routerActions } from 'redux-router5';
import { useRoute } from 'react-router5';

import { RouteComponent } from 'reports/routing';

import * as pd from 'reports/models/power_device';
import { PowerDevicePreview } from 'reports/modules/power_device/components/PowerDevicePreview';
import { PowerDeviceLibrary } from 'reports/modules/power_device/components/PowerDeviceLibrary';

const Library = () => {
    const dispatch = useDispatch();
    const { route } = useRoute();
    const navigateToCharacterization = ({
        power_device_id,
        power_device_characterization_id,
    }: pd.PowerDeviceCharacterization) =>
        dispatch(
            routerActions.navigateTo('app.power-devices.power-device.preview', {
                powerDeviceId: power_device_id,
                characterizationId: power_device_characterization_id,
            }),
        );
    const navigateToDefaultCharacterization = ({ power_device_id, defaultCharacterizationId }: pd.PowerDevice) => {
        dispatch(
            routerActions.navigateTo('app.power-devices.power-device.preview', {
                powerDeviceId: power_device_id,
                characterizationId: defaultCharacterizationId(),
            }),
        );
    };
    const navigateToPowerDevices = () => dispatch(routerActions.navigateTo('app.power-devices'));
    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.power-devices" exact={false}>
                    <PowerDeviceLibrary
                        navigateToCharacterization={navigateToCharacterization}
                        navigateToDefaultCharacterization={navigateToDefaultCharacterization}
                        preview={
                            <RouteComponent name="app.power-devices.power-device.preview">
                                {({ powerDevice, characterization }) => (
                                    <PowerDevicePreview
                                        characterization={characterization}
                                        navigateToCharacterization={navigateToCharacterization}
                                        navigateToPowerDevices={navigateToPowerDevices}
                                        powerDevice={powerDevice}
                                    />
                                )}
                            </RouteComponent>
                        }
                        powerDeviceId={route.params.powerDeviceId}
                    />
                </RouteComponent>
            </div>
        </div>
    );
};

export { Library };
