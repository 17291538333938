import * as React from 'react';

import { isString } from 'lodash';
import { Classes, Dialog, Intent, H4 } from '@blueprintjs/core';
import { AnchorButton, Button, LinkButton } from 'reports/components/core/controls';
import { IconNames } from '@blueprintjs/icons';
import { DialogBody } from 'reports/modules/files/components/common';
import { IUpsellContent } from 'reports/utils/UpsellDialogConstants';
import { HSLightTheme } from 'reports/styles/colors';
import * as analytics from 'reports/analytics';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import * as usr from 'reports/models/user';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface IUpsellDialogCallbacks {
    onCancel: () => void;
    onPrimaryButtonClick?: () => void;
    onSecondaryButtonClick?: () => void;
}

interface IUpsellDialogOptions {
    content: IUpsellContent;
    disabled?: boolean;
    isOpen: boolean;
    user: usr.User;
}

export type UpsellDialogProps = IUpsellDialogCallbacks & IUpsellDialogOptions;

const CloseButton = styled(Button)`
    margin-left: auto;
    position: absolute;
    right: 10px;
    top: 10px;
`;

const StyledUpsellDialog = styled(Dialog)`
    width: 600px;
    gap: 24px;
    padding: 24px;
    position: relative;
    background: ${HSLightTheme.surface};
`;

const UpsellDialogHeader = styled(H4)`
    margin-top: 0;
    text-align: center;
`;

const UpsellDialogFooter = styled.div``;

function UpsellDialog(props: UpsellDialogProps) {
    const { content, onCancel, disabled, isOpen, user } = props;

    const { contentText, imgUrl, helpCenterUrl, title, modalName } = content;

    const interval = user.subscription?.plan_type || 'month';

    return (
        <StyledUpsellDialog onClose={onCancel} onClosed={onCancel} isOpen={isOpen}>
            <CloseButton
                aria-label="DialogCloseButton"
                icon={IconNames.CROSS}
                minimal
                small
                onClick={() => onCancel()}
            />

            <DialogBody className={Classes.DIALOG_BODY} style={{ position: 'relative', margin: 0 }}>
                <UpsellDialogHeader>{title}</UpsellDialogHeader>
                <p>
                    <img alt="Upsell Image" src={imgUrl} style={{ height: '100%', width: '100%' }} />
                </p>

                {isString(contentText) ? <p>{contentText}</p> : contentText}
            </DialogBody>
            <UpsellDialogFooter>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <AnchorButton
                        text="Learn More"
                        intent={Intent.NONE}
                        disabled={disabled}
                        target="_blank"
                        href={helpCenterUrl}
                        onClick={() => {
                            analytics.track('paywall.learnMore', {
                                modal_name: modalName,
                            });
                        }}
                    />
                    <LinkButton
                        text="Upgrade to Pro"
                        intent={Intent.PRIMARY}
                        routeName="app.settings.team.billing"
                        routeParams={{
                            interval,
                            dialog: 'change',
                            product: 'helioscope_pro',
                            referrer: ReferrerTypes.upsell_dialog,
                        }}
                        tracking={{
                            name: 'paywall.primaryCta',
                            options: {
                                cta_action: 'contact_sales',
                                modal_name: modalName,
                            },
                        }}
                        onClick={() => onCancel()}
                    />
                </div>
            </UpsellDialogFooter>
        </StyledUpsellDialog>
    );
}

export default UpsellDialog;
