/**
 * Report Proposals Rich Text Widget
 */
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Button, MenuItem } from '@blueprintjs/core';

import Translations from 'reports/localization/strings';
import { Theme } from 'reports/models/theme';
import { IAppState } from 'reports/types';
import { IReportContext, IWidgetRenderProps, IWidgetEditProps, IRichTextContent } from 'reports/modules/report/widgets';

import { ColorPicker } from 'reports/modules/report/components/forms/ColorPicker';
import { FormHeader, FormSection } from 'reports/modules/report/components/helpers';
import SidebarFormatForm from 'reports/modules/report/components/SidebarFormatForm';
import { selectors as repSelectors } from 'reports/modules/report';

import { StaticSelect } from 'reports/components/core/forms';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

import { RichTextController, DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE, DEFAULT_FONT_COLOR } from './config';
import { isColor, rgbToString } from 'reports/modules/report/styles';

type IContext = Pick<IReportContext, 'theme' | 'tokenMap'>;
type ViewProps = IWidgetRenderProps<IRichTextContent, IContext> & {
    intl: IntlShape;
};
type EditProps = IWidgetEditProps<IRichTextContent, IContext>;

const AutoCompleteDiv = styled.div`
    border: 1px solid #a0a0a0;
    padding: 2px;
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 240px;
    z-index: 99;
`;

const AutoCompleteItem = styled.div`
    padding: 2px;
    width: 100%;

    &.selected {
        background-color: #d4d8ff;
    }
`;

const PlaceholderDiv = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 0px 2px;
    overflow: hidden;
    white-space: nowrap;
    font-style: italic;
    font-size: 14px;
    color: #a4a4a4;
`;

interface IAutoCompleteProps {
    data: {
        items: { text: string }[];
    };
    style: any;
    onClose: (item: any) => any;
}

class AutoCompleteList extends React.PureComponent<IAutoCompleteProps, { selectedIndex: number }> {
    state = { selectedIndex: -1 };
    itemRefs = {};

    render() {
        const { data, style } = this.props;
        const { selectedIndex } = this.state;

        return (
            <AutoCompleteDiv style={style}>
                {data.items.map((i, idx) => {
                    return (
                        <AutoCompleteItem
                            key={idx}
                            ref={(ref) => {
                                this.itemRefs[idx] = ref;
                            }}
                            className={idx === selectedIndex ? 'selected' : ''}
                            onMouseMove={() => this.selectIndex(idx)}
                            onClick={() => this.selectClose(idx)}
                        >
                            {i.text}
                        </AutoCompleteItem>
                    );
                })}
            </AutoCompleteDiv>
        );
    }

    selectClose(index: number | null = null) {
        const idx = index !== null ? index : this.state.selectedIndex;
        const item = this.props.data.items[idx];
        if (item) {
            this.props.onClose(item);
        }
    }

    selectUp() {
        const index = Math.max(0, this.state.selectedIndex - 1);
        this.selectIndex(index);
    }

    selectDown() {
        const index = Math.min(this.props.data.items.length - 1, this.state.selectedIndex + 1);
        this.selectIndex(index);
    }

    selectIndex(index) {
        this.setState({ selectedIndex: index });

        const itemref = this.itemRefs[index];
        if (itemref) {
            if (itemref.scrollIntoViewIfNeeded) {
                itemref.scrollIntoViewIfNeeded({ behavior: 'smooth' });
            } else {
                itemref.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
}

interface IBaseTextProps {
    editableController?: RichTextController;
    document?: any;
    context: IContext;
    zoomScale: number;
}

class BaseTextComponent extends React.Component<IBaseTextProps> {
    containerRef?: any;
    autocompleteRef: AutoCompleteList | null;
    readOnlyController?: RichTextController;
    unsub?: () => any;
    state: { autoComplete: any; placeholder?: boolean } = {
        autoComplete: null,
    };

    componentDidMount() {
        this.initController();

        if (this.props.editableController) {
            this.setState({
                placeholder: !this.props.editableController.view.state.doc.textContent.length,
            });
        }
    }

    componentWillUnmount() {
        this.destroyController();
    }

    render() {
        if (!this.props.editableController) {
            this.destroyController();
            this.initController();
        }

        const renderPlaceholder = () => {
            if (this.state.placeholder) {
                return (
                    <PlaceholderDiv>
                        <FormattedMessage {...Translations.widgets.enter_text_here} />
                    </PlaceholderDiv>
                );
            }

            return null;
        };

        const renderAutocomplete = () => {
            const { autoComplete } = this.state;
            if (!autoComplete) return null;

            const crect = this.containerRef!.getBoundingClientRect();
            const dy = (autoComplete.cursorPosition.bottom - crect.top) / this.props.zoomScale;

            return (
                <AutoCompleteList
                    ref={(ref) => {
                        this.autocompleteRef = ref;
                    }}
                    style={{
                        position: 'absolute',
                        top: `${dy}px`,
                        left: '0px',
                        right: '0px',
                    }}
                    data={autoComplete}
                    onClose={(item) => this.getController()!.dispatchAutocomplete(item.value)}
                />
            );
        };

        return (
            <div
                className="reports-rich-text-container-div"
                ref={(ref) => {
                    this.containerRef = ref;
                }}
            >
                {renderPlaceholder()}
                {renderAutocomplete()}
            </div>
        );
    }

    initController() {
        if (this.containerRef) {
            const { document, context } = this.props;
            const { theme, tokenMap } = context;

            const ctrl = () => {
                if (this.props.editableController) {
                    return this.props.editableController;
                }
                this.readOnlyController = new RichTextController();
                return this.readOnlyController;
            };

            const controller = ctrl();
            controller.tryInitEditor(this.containerRef, document, theme, tokenMap, !this.readOnlyController);
            this.unsub = controller.subscribeListener((state) => this.onEditorEvent(state));
        }
    }

    getController() {
        return this.readOnlyController || this.props.editableController;
    }

    destroyController() {
        if (this.unsub) this.unsub();
        if (this.readOnlyController) this.readOnlyController.destroyEditor();
    }

    onEditorEvent(event) {
        if (event.autoComplete) {
            this.setState({ autoComplete: event.autoComplete.data });
        }

        if (this.props.editableController && event.newState) {
            this.setState({
                placeholder: !event.newState.doc.textContent.length,
            });
        }

        if (event.autoCompleteSelectDown && this.autocompleteRef) {
            this.autocompleteRef.selectDown();
        }

        if (event.autoCompleteSelectUp && this.autocompleteRef) {
            this.autocompleteRef.selectUp();
        }

        if (event.autoCompleteClose && this.autocompleteRef) {
            this.autocompleteRef.selectClose();
        }
    }
}

const BaseTextComponentContainer = connect(() => {
    return (state: IAppState) => ({
        zoomScale: repSelectors.zoomScale(state),
    });
})(BaseTextComponent);

const TextOptButton = styled(Button).attrs({
    minimal: true,
})`
    margin-right: 2px;
`;

const FONT_FAMILIES = [
    { value: 'Arial' },
    { value: 'Garamond' },
    { value: 'Georgia' },
    { value: 'Helvetica' },
    { value: 'Source Sans Pro' },
    { value: 'Times New Roman' },
    { value: 'Trebuchet' },
    { value: 'Verdana' },
];

function generateFontSize() {
    const ranges = [
        [8, 12, 1],
        [14, 20, 2],
        [24, 48, 4],
        [54, 72, 6],
    ];

    const sizes = [] as any;

    for (const i of ranges) {
        for (let j = i[0]; j <= i[1]; j += i[2]) {
            sizes.push({
                value: `${j}px`,
                displayText: `${j}`,
            });
        }
    }

    return sizes;
}

const FONT_SIZES = generateFontSize();

interface IDetailProps {
    controller: RichTextController;
    theme: Theme;
}

class RichTextDetails extends React.PureComponent<IDetailProps, any> {
    unsub?: () => any;
    state: any = {};

    componentDidMount() {
        this.unsub = this.props.controller.subscribeListener((state) => this.onEditorEvent(state));
    }

    componentWillUnmount() {
        this.unsub!();
    }

    render() {
        const { controller } = this.props;

        const simpleItemRenderer = (item, { handleClick, modifiers }) => (
            <MenuItem
                key={item.value}
                text={item.displayText || item.value}
                active={modifiers.active}
                onClick={handleClick}
                style={{
                    maxWidth: '500px',
                }}
            />
        );

        const simpleButtonText = (value, items, defaultValue) => {
            let item = _.find(items, (i) => i.value === value);
            if (!item) item = _.find(items, (i) => i.value === defaultValue);
            return item.displayText || item.value;
        };

        return (
            <FormSection>
                <FormHeader>
                    <FormattedMessage {...Translations.widgets.format_rich_text} />
                </FormHeader>
                <div style={{ display: 'flex' }}>
                    <TextOptButton active={this.state.markBold} onClick={() => controller.toggleMark('bold')}>
                        <b>B</b>
                    </TextOptButton>
                    <TextOptButton active={this.state.markItalic} onClick={() => controller.toggleMark('italic')}>
                        <i>I</i>
                    </TextOptButton>
                    <TextOptButton active={this.state.markUnderline} onClick={() => controller.toggleMark('underline')}>
                        <u>U</u>
                    </TextOptButton>
                    <TextOptButton
                        icon="align-left"
                        onClick={() =>
                            controller.setBlock('paragraph', {
                                align: 'align-left',
                            })
                        }
                    />
                    <TextOptButton
                        icon="align-center"
                        onClick={() =>
                            controller.setBlock('paragraph', {
                                align: 'align-center',
                            })
                        }
                    />
                    <TextOptButton
                        icon="align-right"
                        onClick={() =>
                            controller.setBlock('paragraph', {
                                align: 'align-right',
                            })
                        }
                    />
                    <TextOptButton
                        icon="align-justify"
                        onClick={() =>
                            controller.setBlock('paragraph', {
                                align: 'align-justify',
                            })
                        }
                    />
                </div>
                <div style={{ display: 'flex' }}>
                    {this.state.allowOrderedList ? (
                        <TextOptButton icon="numbered-list" onClick={() => controller.wrapBlock('ordered_list')} />
                    ) : null}
                    {this.state.allowBulletList ? (
                        <TextOptButton icon="properties" onClick={() => controller.wrapBlock('bullet_list')} />
                    ) : null}
                    {this.state.allowLiftBlock ? (
                        <TextOptButton icon="menu-closed" onClick={() => controller.liftBlock()} />
                    ) : null}
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '192px', marginRight: '2px' }}>
                        <StaticSelect
                            fill
                            buttonText={simpleButtonText(this.state.currFontFamily, FONT_FAMILIES, DEFAULT_FONT_FAMILY)}
                            items={FONT_FAMILIES}
                            itemRenderer={simpleItemRenderer}
                            onItemSelect={(item) =>
                                controller.updateMark('fontFamily', {
                                    value: item.value,
                                })
                            }
                        />
                    </div>
                    <div style={{ width: '80px' }}>
                        <StaticSelect
                            fill
                            buttonText={simpleButtonText(this.state.currFontSize, FONT_SIZES, DEFAULT_FONT_SIZE)}
                            items={FONT_SIZES}
                            itemRenderer={simpleItemRenderer}
                            onItemSelect={(item) =>
                                controller.updateMark('fontSize', {
                                    value: item.value,
                                })
                            }
                        />
                    </div>
                </div>
                <div>
                    <ColorPicker
                        color={this.state.currFontColor}
                        theme={this.props.theme}
                        setColorProp={(item) => {
                            if (isColor(item)) {
                                controller.updateMark('fontColor', {
                                    value: rgbToString(item.rgb),
                                });
                            } else {
                                controller.updateMark('fontColor', {
                                    value: item,
                                });
                            }
                        }}
                        maxWidth={280}
                    />
                </div>
            </FormSection>
        );
    }

    onEditorEvent(event) {
        const { controller } = this.props;

        if (event.newState) {
            const state = event.newState;

            this.setState({
                markBold: controller.hasMark(state, 'bold'),
                markItalic: controller.hasMark(state, 'italic'),
                markUnderline: controller.hasMark(state, 'underline'),
                allowOrderedList: controller.checkWrapBlock(state, 'ordered_list'),
                allowBulletList: controller.checkWrapBlock(state, 'bullet_list'),
                allowLiftBlock: controller.checkLiftBlock(state),
                currFontFamily: controller.checkMarkAttr(state, 'fontFamily', 'value', DEFAULT_FONT_FAMILY),
                currFontSize: controller.checkMarkAttr(state, 'fontSize', 'value', DEFAULT_FONT_SIZE),
                currFontColor: controller.checkMarkAttr(state, 'fontColor', 'value', DEFAULT_FONT_COLOR),
            });
        }
    }
}

class EditTextComponent extends React.PureComponent<EditProps> {
    controller: RichTextController = new RichTextController();
    propsDocument?: any = null;

    componentWillUnmount() {
        const content = this.controller.serializeDocument();
        this.props.commitConfig({
            ...this.props.config,
            content: { prosemirror_doc: content },
        });
        this.controller.destroyEditor();
        this.propsDocument = null;
    }

    render() {
        const { prosemirror_doc } = this.props.config.content;
        if (!_.isEqual(prosemirror_doc, this.propsDocument)) {
            this.propsDocument = prosemirror_doc;
            if (this.controller.view) {
                this.controller.replaceDoc(prosemirror_doc);
            }
        }

        return (
            <>
                <BaseTextComponentContainer
                    editableController={this.controller}
                    document={prosemirror_doc}
                    context={this.props.context}
                />
                <SidebarFormatForm>
                    <RichTextDetails controller={this.controller} theme={this.props.context.theme} />
                </SidebarFormatForm>
            </>
        );
    }
}

class _ViewTextComponent extends React.PureComponent<ViewProps> {
    render() {
        const {
            intl: { locale },
        } = this.props;
        const { prosemirror_doc } = this.props.config.content;

        const tokenFill = (document) => {
            if (!document) return null;

            const filled = _.merge({}, document);
            const stk = filled.content.slice();
            const textnodes = [] as any[];

            while (stk.length) {
                const next = stk.pop();
                if (next.content) {
                    stk.push(...next.content);
                }

                if (next.type === 'text') {
                    textnodes.push(next);
                }
            }

            const tokenMap = this.props.context.tokenMap || {};

            for (const i of textnodes) {
                const { text } = i;

                const matches = text.match(/{[^{}]*}/g);
                if (!matches) continue;

                let remaining = text;
                let replace = '';
                while (matches.length) {
                    const match = matches.shift();
                    const idx = remaining.indexOf(match);
                    const fixed = remaining.substring(0, idx);

                    replace += fixed;
                    const token = match.substring(1, match.length - 1);

                    if (!tokenMap[token]) {
                        replace += 'ERROR';
                    } else {
                        replace += tokenMap[token].format({ locale });
                    }

                    remaining = remaining.substring(idx + match.length);
                }

                i.text = replace + remaining;
            }

            return filled;
        };

        return <BaseTextComponentContainer document={tokenFill(prosemirror_doc)} context={this.props.context} />;
    }
}

const ViewTextComponent = injectIntl(_ViewTextComponent);
export { ViewTextComponent, EditTextComponent };
