import { Classes } from '@blueprintjs/core';

import Button from './Button';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const HeaderButton = styled(Button).attrs({
    className: `${Classes.MINIMAL} ${Classes.LARGE}`,
})`
    border-bottom: solid 2px;
    margin: 0 4px;
`;

export default HeaderButton;
