import * as React from 'react';
import { range } from 'lodash';
import { Portal, Spinner, SpinnerSize } from '@blueprintjs/core';

import * as fmt from 'reports/utils/formatters';

import { IRange, LegendPosition } from './common';
import { DesignHeatMapContext } from './DesignHeatMapContext';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const LEGEND_SIZE_PCT = 1 / 3;
const LEGEND_NATIVE_RENDER_SIZE = 200;

const LEGEND_MARGIN = 10;

const getLegendMarginFromParentRect = (legendPosition: LegendPosition, boundingRect?: DOMRect) => {
    if (!boundingRect) {
        return `margin ${LEGEND_MARGIN}px;`;
    }

    const parentFixedTopMargin = boundingRect.top + LEGEND_MARGIN;
    const parentFixedBottomMargin = window.innerHeight - boundingRect.bottom + LEGEND_MARGIN;
    const parentFixedLeftMargin = boundingRect.left + LEGEND_MARGIN;
    const parentFixedRightMargin = window.innerWidth - boundingRect.right + LEGEND_MARGIN;

    switch (legendPosition) {
        case 'top_left':
            return `margin ${parentFixedTopMargin}px ${LEGEND_MARGIN}px ${LEGEND_MARGIN}px ${parentFixedLeftMargin}px;`;
        case 'top_right':
            return `margin ${parentFixedTopMargin}px ${parentFixedRightMargin}px ${LEGEND_MARGIN}px ${LEGEND_MARGIN}px;`;
        case 'bottom_left':
            return `margin ${LEGEND_MARGIN}px ${LEGEND_MARGIN}px ${parentFixedBottomMargin}px ${parentFixedLeftMargin}px;`;
        case 'bottom_right':
            return `margin ${LEGEND_MARGIN}px ${parentFixedRightMargin}px ${parentFixedBottomMargin}px ${LEGEND_MARGIN}px;`;
    }
};

const LegendContainer = styled.div<{
    scaleFactor: number;
    legendPosition: LegendPosition;
    usePortal?: boolean;
    parentBoundingRect?: DOMRect;
}>`
    display: flex;
    position: ${({ usePortal }) => (usePortal ? 'fixed' : 'absolute')};
    ${({ legendPosition }) => {
        switch (legendPosition) {
            case 'top_left':
                return 'top: 0; left: 0; transform-origin: top left;';
            case 'top_right':
                return 'top: 0; right: 0; transform-origin: top right;';
            case 'bottom_left':
                return 'bottom: 0; left: 0; transform-origin: bottom left;';
            case 'bottom_right':
                return 'bottom: 0; right: 0; transform-origin: bottom right;';
        }
    }}
    width: ${LEGEND_NATIVE_RENDER_SIZE}px;
    height: ${LEGEND_NATIVE_RENDER_SIZE}px;
    padding: 5px;
    margin: 10px;
    ${({ legendPosition, parentBoundingRect, usePortal }) =>
        usePortal ? getLegendMarginFromParentRect(legendPosition, parentBoundingRect) : `margin: ${LEGEND_MARGIN}px;`}
    background: rgba(0, 0, 0, 0.3);

    transform: scale(${(props) => props.scaleFactor});

    font-size: 16px;
    font-weight: bold;
    ${({ usePortal }) => (usePortal ? 'z-index: 5100;' : '')}
`;

const LegendOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // allow user to drag/rotate underlying render
    pointer-events: none;
    flex: 0 0 auto;
`;

const VerticalLegendLabel = styled.div`
    height: 100%;
    margin: 0 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: bold;
    flex: 0 0 auto;
`;

const GradientBar = styled.div`
    height: calc(100% - 1em);
    max-width: 25px;
    width: 25px;
    margin: 0.5em 0px;

    // 42.4% accounts for the luminosity decrease that happens during rendering
    background: linear-gradient(
        to bottom,
        hsl(120, 100%, 42.4%) 0%,
        hsl(60, 100%, 42.4%) 50%,
        hsl(0, 100%, 42.4%) 100%
    );
`;

const LeftLabel = styled.div`
    text-align: right;
`;

const CenteredSpinner = styled(Spinner)`
    width: 100%;
`;

const ErrorText = styled.p`
    color: white;
`;

const NUM_LABELS = 5;

const calcLabels = (labelRange: IRange) => {
    const delta = labelRange.max - labelRange.min;

    return range(NUM_LABELS).map((i) => labelRange.min + delta * ((NUM_LABELS - 1 - i) / (NUM_LABELS - 1)));
};

const PortalWrapper = ({ usePortal = false, containerRefCallback, children }) => {
    if (usePortal) {
        return (
            <div ref={containerRefCallback}>
                <Portal>{children}</Portal>
            </div>
        );
    }

    return <>{children}</>;
};

const DesignHeatMapLegend = ({
    children,
    disabled,
    height,
    width,
    legendPosition = 'bottom_right' as LegendPosition,
    seriesName = 'tsrfSeries',
    usePortal = false,
}) => {
    if (disabled) {
        // We need the consumer wrapper even though we don't use it
        // because otherwise the InteractiveDesign3dView gets reinstantiated
        // because of how React manages the virtual DOM
        return <DesignHeatMapContext.Consumer>{(_context) => children}</DesignHeatMapContext.Consumer>;
    }

    const [parentBoundingRect, setParentBoundingRect] = React.useState<DOMRect>();
    const containerRefCallback = React.useCallback((containerNode: HTMLDivElement) => {
        if (usePortal && containerNode && containerNode.parentElement) {
            const parentElement = containerNode.parentElement;
            setParentBoundingRect(parentElement.getBoundingClientRect());
        }
    }, []);

    const smallerDim = Math.min(height, width);
    const scaleFactor = LEGEND_SIZE_PCT / (LEGEND_NATIVE_RENDER_SIZE / smallerDim);

    return (
        <DesignHeatMapContext.Consumer>
            {(heatMap) => (
                <>
                    {children}
                    {heatMap.error && (
                        <LegendOverlay>
                            <LegendContainer scaleFactor={scaleFactor} legendPosition={legendPosition}>
                                <ErrorText>{heatMap.error}</ErrorText>
                            </LegendContainer>
                        </LegendOverlay>
                    )}
                    {heatMap.loading && (
                        <LegendOverlay>
                            <LegendContainer scaleFactor={scaleFactor} legendPosition={legendPosition}>
                                <CenteredSpinner size={SpinnerSize.LARGE} />
                            </LegendContainer>
                        </LegendOverlay>
                    )}
                    {heatMap.ready && (
                        <PortalWrapper usePortal={usePortal} containerRefCallback={containerRefCallback}>
                            <LegendOverlay>
                                <LegendContainer
                                    scaleFactor={scaleFactor}
                                    legendPosition={legendPosition}
                                    usePortal={usePortal}
                                    parentBoundingRect={parentBoundingRect}
                                >
                                    <VerticalLegendLabel>
                                        {calcLabels(heatMap.data![seriesName].normalizedRange).map((val, i) => (
                                            <LeftLabel key={i}>
                                                {fmt.percentage(val, {
                                                    precision: 0,
                                                })}
                                            </LeftLabel>
                                        ))}
                                    </VerticalLegendLabel>
                                    <GradientBar />
                                    {heatMap.data![seriesName].rawRange && (
                                        <VerticalLegendLabel>
                                            {calcLabels(heatMap.data![seriesName].rawRange).map((val, i) => (
                                                <div key={i}>
                                                    {fmt.stringifyNumber(val / 1000.0, { precision: 0 })}
                                                    {i === 0 && (
                                                        <>
                                                            {' '}
                                                            kW/m<sup>2</sup>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </VerticalLegendLabel>
                                    )}
                                </LegendContainer>
                            </LegendOverlay>
                        </PortalWrapper>
                    )}
                </>
            )}
        </DesignHeatMapContext.Consumer>
    );
};

export default DesignHeatMapLegend;
