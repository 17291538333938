import * as React from 'react';
import { Slider, Intent } from '@blueprintjs/core';
import FormField from 'reports/components/forms/FormField';
import { FormGroup, IFormGroupProps } from 'reports/components/core/forms';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// Custom FormGroup because the Slider labels tend to go past specified bounds.
const SliderFormGroup = styled(FormGroup)`
    margin-top: 10px;
    margin-right: 25px;
`;

type IFormSliderInputProps = IFormGroupProps & {
    path: string;
    min?: number;
    max?: number;
    stepSize?: number;
};

// TODO: migrate to using withForm higher-order component
const FormSliderInput: React.FC<IFormSliderInputProps> = (props) => {
    const { path, label, inline, labelWidth, fill, bold, ...inputProps } = props;

    return (
        <FormField path={path}>
            {({ onChange, value, errors, form }) => (
                <SliderFormGroup
                    inline={inline}
                    fill={fill}
                    bold={bold}
                    label={label}
                    labelWidth={labelWidth}
                    intent={errors.length ? Intent.DANGER : undefined}
                >
                    <Slider onChange={onChange} disabled={form.submitting} value={value} {...inputProps} />
                </SliderFormGroup>
            )}
        </FormField>
    );
};

export default FormSliderInput;
