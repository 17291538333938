import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SetPasswordFormData, api as authAPI } from 'reports/modules/auth';
import { ModalBody, ModalFooter, ModalHeader, Text, Modal, SunSpinnerLoader, Flex } from '@aurorasolar/ds';
import { FormikHelpers } from 'formik';
import SetPasswordForm from './SetPasswordForm';
import PasswordUpdatedMessage from './PasswordUpdatedMessage';
import PasswordExpiredLinkMessage from './PasswordExpiredLinkMessage';
import AccountCreationExpiredLinkMessage from './AccountCreationExpiredLinkMessage';
import { formikSetFieldErrors } from './common';
import { PasswordForbiddenLinkMessage } from 'reports/modules/auth/components/PasswordForbiddenLinkMessage';

const STATE_LOADING = 'STATE_LOADING';
const STATE_INITIAL = 'STATE_INITIAL';
const STATE_INITIAL_RESET = 'STATE_INITIAL_RESET';
const STATE_SUCCESS = 'STATE_SUCCESS';
const STATE_EXPIRED = 'STATE_EXPIRED';
const STATE_FORBIDDEN = 'STATE_FORBIDDEN';

const DialogTitle = ({ dialogState }) => {
    let titleText: string;
    switch (dialogState) {
        case STATE_SUCCESS:
            titleText = "You've created a new password!";
            break;
        case STATE_EXPIRED:
            titleText = "Let's try again.";
            break;
        case STATE_INITIAL_RESET:
            titleText = 'Reset your password';
            break;
        case STATE_FORBIDDEN:
            titleText = "Let's try again.";
            break;
        default:
            titleText = 'Create your password';
            break;
    }
    return (
        <ModalHeader>
            <Text as="h2" text="h2">
                {titleText}
            </Text>
        </ModalHeader>
    );
};

interface SetPasswordDialogProps {
    token: string;
    isReset: boolean;
}

interface SetPasswordDialogController {
    loading: boolean;
    dialogState: string;
    onSubmit: (formData: SetPasswordFormData, formikHelpers: FormikHelpers<SetPasswordFormData>) => void;
}

const useSetPasswordDialog = ({ token, isReset }: SetPasswordDialogProps): SetPasswordDialogController => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [dialogState, setDialogState] = useState(STATE_LOADING);

    const setPassword = async ({ token, new_password, confirm_password, terms_of_use }) => {
        return await dispatch(
            isReset
                ? authAPI.resetPassword(
                      {
                          new_password,
                          confirm_password,
                      },
                      { token },
                  )
                : authAPI.setPassword(
                      {
                          new_password,
                          confirm_password,
                          terms_of_use,
                      },
                      { token },
                  ),
        );
    };

    useEffect(() => {
        (async () => {
            try {
                await dispatch(authAPI.verifyPasswordChangeToken({ token }));
                setDialogState(isReset ? STATE_INITIAL_RESET : STATE_INITIAL);
            } catch (ex) {
                if (ex.response && ex.response.status === 422) {
                    setDialogState(STATE_EXPIRED);
                } else {
                    setDialogState(STATE_FORBIDDEN);
                }
            }
        })();
        return () => {};
    }, []);

    const onSubmit = async (formData: SetPasswordFormData, formikHelpers: FormikHelpers<SetPasswordFormData>) => {
        const { new_password, confirm_password, terms_of_use } = formData;
        const { setSubmitting } = formikHelpers;
        try {
            setLoading(true);
            await setPassword({
                token,
                new_password,
                confirm_password,
                terms_of_use,
            });
            setDialogState(STATE_SUCCESS);
        } catch (ex) {
            if (ex.response && ex.response.status === 404) {
                setDialogState(STATE_EXPIRED);
            } else {
                formikSetFieldErrors(ex, formikHelpers);
                setLoading(false);
            }
            throw ex;
        } finally {
            setSubmitting(false);
        }
    };

    return {
        loading,
        dialogState,
        onSubmit,
    };
};

const SetPasswordDialog = ({ token, isReset }: SetPasswordDialogProps) => {
    const { loading, dialogState, onSubmit } = useSetPasswordDialog({ token, isReset });
    return (
        <Modal bg="uiBg" open>
            <DialogTitle dialogState={dialogState} />
            <ModalBody padding="10px">
                {dialogState === STATE_LOADING && (
                    <Flex alignItems="center">
                        <SunSpinnerLoader />
                    </Flex>
                )}
                {(dialogState === STATE_INITIAL || dialogState === STATE_INITIAL_RESET) && (
                    <SetPasswordForm onSubmit={onSubmit} loading={loading} isReset={isReset} />
                )}
                {dialogState === STATE_SUCCESS && <PasswordUpdatedMessage />}
                {dialogState === STATE_EXPIRED &&
                    (isReset ? <PasswordExpiredLinkMessage /> : <AccountCreationExpiredLinkMessage />)}
                {dialogState === STATE_FORBIDDEN && <PasswordForbiddenLinkMessage />}
            </ModalBody>
            <ModalFooter />
        </Modal>
    );
};

export { SetPasswordDialog };
