import * as React from 'react';
import { ItemPredicate, ItemRenderer } from '@blueprintjs/select';

import { locales, ILocale } from 'reports/localization';
import { isSubstr } from 'reports/utils/strings';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';
import { SettingsSelect } from '../types';

interface Props extends SettingsSelect<ILocale> {
    localeList?: ILocale[];
    noFill?: boolean;
}

const LocaleSelect = ({ disabled, noFill, localeList, onItemSelect, selectedItem }: Props) => {
    const filterLocale: ItemPredicate<ILocale> = (query: string, locale: ILocale) =>
        isSubstr(`${locale.name} (${locale.code})`, query);

    const renderLocaleItem: ItemRenderer<ILocale> = (locale, { handleClick, modifiers }) => (
        <DropdownMenuItem
            key={`locale-${locale.code}`}
            title={`${locale.name}`}
            label={locale.code}
            onClick={handleClick}
            selected={locale.code === selectedItem.code}
            {...modifiers}
        />
    );

    return (
        <StaticSelect<ILocale>
            items={localeList || locales}
            onItemSelect={onItemSelect}
            itemRenderer={renderLocaleItem}
            itemPredicate={filterLocale}
            buttonText={`${selectedItem.name} (${selectedItem.code})`}
            buttonProps={{ disabled, fill: !noFill }}
            filterable={true}
            disabled={disabled}
        />
    );
};

export default LocaleSelect;
