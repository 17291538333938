import * as React from 'react';

import { Button, IButtonProps, Intent } from '@blueprintjs/core';

import { LibraryConsumer } from 'reports/components/library/main/context';

interface IFilterButton<T> extends IButtonProps {
    name: Extract<keyof T, string>;
    value: T[keyof T] | boolean;
}

export class FilterButton<T> extends React.PureComponent<IFilterButton<T>> {
    render() {
        const { name, value, ...buttonProps } = this.props;

        return (
            <LibraryConsumer>
                {({ filters, toggleFilter }) => (
                    <Button
                        active={filters[name] === value}
                        intent={filters[name] === value ? Intent.PRIMARY : undefined}
                        onClick={() => toggleFilter(name, value)}
                        {...buttonProps}
                    />
                )}
            </LibraryConsumer>
        );
    }
}
