import * as React from 'react';

import * as usr from 'reports/models/user';

import { NestedFields } from 'reports/components/forms';
import { Card } from 'reports/components/core/containers';

import {
    ConditionSetSoilingEdit,
    ConditionSetCellTempEdit,
    ConditionSetAcLossesEdit,
    ConditionSetAdvancedEdit,
    ConditionSetMismatchEdit,
    ConditionSetTrackerEdit,
} from 'reports/modules/condition_sets/components/CondSetEditComponents';

const ConditionsProfileEditor = ({ user }: { user: usr.User }) => (
    <NestedFields path="data">
        <Card>
            <ConditionSetSoilingEdit />
        </Card>
        <Card>
            <ConditionSetCellTempEdit toggleBehavior="profiles" />
        </Card>
        <Card>
            <ConditionSetMismatchEdit />
        </Card>
        <Card>
            <ConditionSetAcLossesEdit />
        </Card>
        <Card>
            <ConditionSetAdvancedEdit />
        </Card>
        {user.hasSingleAxisTrackersAccess() && (
            <Card>
                <ConditionSetTrackerEdit />
            </Card>
        )}
    </NestedFields>
);

export default ConditionsProfileEditor;
