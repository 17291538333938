import { has, map, range } from 'lodash';

import { Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { IAppState } from 'reports/types';
import { createUniqueDescription } from 'reports/utils/helpers';

import * as finModel from 'reports/models/financial_template';
import * as auth from 'reports/modules/auth';
import { selectors as projSelectors } from 'reports/modules/project';
import { pipelineDefaults } from 'reports/modules/financials/model/pipeline';

const { primaryDesign, primarySimulation } = projSelectors;

export const actions = {
    createModel: () => async (dispatch: Dispatch, getState: () => IAppState) => {
        const user = auth.selectors.getUser(getState())!;
        const description = `${user.full_name}'s New Model`;

        const potentialCopies = await dispatch(finModel.api.index({ description }));
        const data = {
            description: createUniqueDescription(description, map(potentialCopies, 'description')),
            data: pipelineDefaults(),
        };

        return dispatch(finModel.api.create(data));
    },
    saveAsNew: (model: finModel.FinancialTemplate) => {
        return async (dispatch: Dispatch) => {
            const potentialCopies = await dispatch(finModel.api.index({ description: model.description }));
            const description = createUniqueDescription(model.description, map(potentialCopies, 'description'));
            return dispatch(finModel.api.create({ description, data: model.data }));
        };
    },
};

export const selectors = {
    get monthlyProduction() {
        return createSelector(primarySimulation, (simulation) => {
            if (has(simulation, 'metadata.monthly_data')) {
                return range(12).map((idx) => (simulation!.metadata.monthly_data[idx + 1] || {}).grid_power / 1000);
            }

            if (has(simulation, 'metadata.grid_power')) {
                const metadata = simulation!.metadata;
                const totalEnergy = (metadata.grid_power * (metadata.total_slices / metadata.complete_slices)) / 1000;
                return range(12).map(() => totalEnergy / 12);
            }

            return range(12).map(() => 0);
        });
    },

    get designNameplate() {
        return createSelector(primaryDesign, (design) => (design ? design.nameplate : 0));
    },
};
