/**
 * Format form for report shapes
 */
import * as React from 'react';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';

import { Button, ButtonGroup, Classes, Colors, Tab, Tabs } from '@blueprintjs/core';

import Translations from 'reports/localization/strings';
import { DEFAULT_PALETTE } from 'reports/styles/global';
import { isColor, IColorCustomKey, rgbToString, IStyleMap } from 'reports/modules/report/styles';

import { NumericInput } from 'reports/components/helpers/formHelpers';

import { ILayout } from 'reports/modules/report/components/layout';
import { FormHeader, FormSection, FormSlider } from 'reports/modules/report/components/helpers';
import { ColorPicker, IColor } from 'reports/modules/report/components/forms/ColorPicker';
import { Theme } from 'reports/models/theme';

type IColorAttr = 'fill' | 'stroke';

const BorderWidthSlider = ({ onChange, onRelease, min, max, strokeWidth }) => {
    const inputUnitStyle: React.CSSProperties = {
        marginLeft: -1,
        lineHeight: 2,
        height: 30,
        padding: '0px 4px',
        background: Colors.LIGHT_GRAY3,
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        border: `1px solid ${Colors.LIGHT_GRAY2}`,
        borderLeft: 'none',
        color: Colors.DARK_GRAY5,
        cursor: 'default',
    };

    const Slider: any = FormSlider;
    const NumericInputAny: any = NumericInput; // to deal with onBlur

    return (
        <div style={{ display: 'flex', padding: '8px 0' }}>
            <Slider
                value={strokeWidth}
                onChange={onChange}
                onRelease={(_) => onRelease()}
                labelStepSize={9}
                min={min}
                max={max}
                showTrackFill={false}
            />
            <NumericInputAny
                value={strokeWidth}
                onValueChange={onChange}
                onBlur={onRelease}
                min={min}
                max={max}
                buttonPosition="none"
                className={Classes.MINIMAL}
                style={{ width: 34, marginLeft: 6, alignSelf: 'center' }}
            />
            <div style={inputUnitStyle}>px</div>
        </div>
    );
};

interface IState {
    currentColorAttr?: IColorAttr;
}

interface IProps {
    layout: ILayout;
    widgetStyle: React.SVGProps<any>;
    setCSSProp: (style: React.SVGProps<any>) => any;
    onLayoutChange: (layout: ILayout) => any;
    isLine: boolean;

    theme: Theme;
    styleMap: IStyleMap;

    intl: IntlShape;
}

class ShapesForm extends React.PureComponent<IProps, IState> {
    state: IState = {
        currentColorAttr: this.props.isLine ? 'stroke' : 'fill',
    } as any;

    render() {
        const { isLine, widgetStyle, setCSSProp, styleMap, theme, intl } = this.props;
        const { palette } = theme;
        const { currentColorAttr } = this.state;

        // Include transparent color option for non-linear shapes
        let paletteColors = palette && palette.colors.length ? palette.colors : DEFAULT_PALETTE;
        if (!isLine) {
            paletteColors = paletteColors.concat('transparent');
        }
        const updatedTheme = {
            ...theme,
            palette: { colors: paletteColors },
        };

        return (
            <div onClick={(e) => e.stopPropagation()} className={'block'}>
                <FormSection>
                    <FormHeader>
                        <FormattedMessage {...Translations.widgets.shape_color} />
                    </FormHeader>
                    <div>
                        {!isLine ? (
                            <Tabs
                                id="colorAttr"
                                onChange={(currentColorAttr: IColorAttr) => this.setState({ currentColorAttr })}
                                selectedTabId={currentColorAttr}
                                className={Classes.MINIMAL}
                            >
                                <Tab id="fill" title={intl.formatMessage(Translations.widgets.shape_fill)} />
                                <Tab id="stroke" title={intl.formatMessage(Translations.widgets.shape_border)} />
                            </Tabs>
                        ) : null}
                        <ColorPicker
                            color={widgetStyle[currentColorAttr!]}
                            setColorProp={(c: IColor | IColorCustomKey) =>
                                setCSSProp({
                                    [currentColorAttr!]: isColor(c) ? rgbToString(c.rgb) : c,
                                })
                            }
                            maxWidth={296} // 320 width - 12*2 padding
                            style={
                                isLine
                                    ? { paddingTop: 6 }
                                    : {
                                          marginTop: 1,
                                          padding: 6,
                                          background: 'rgba(235,241,245,0.7)',
                                          border: '1px solid #eee',
                                          borderRadius: 2,
                                      }
                            }
                            styleMap={styleMap}
                            theme={updatedTheme}
                        />
                    </div>
                </FormSection>
                <FormSection>
                    <FormHeader>
                        <FormattedMessage {...Translations.widgets.shape_border_width} />
                    </FormHeader>
                    <BorderWidthSlider
                        strokeWidth={widgetStyle.strokeWidth}
                        onChange={(strokeWidth) => setCSSProp({ strokeWidth })}
                        onRelease={() => setCSSProp({})}
                        min={1}
                        max={10}
                    />
                </FormSection>
                <FormSection>
                    <FormHeader>
                        <FormattedMessage {...Translations.widgets.shape_orientation} />
                    </FormHeader>
                    <ButtonGroup>
                        <Button
                            icon="image-rotate-right"
                            text={intl.formatMessage(Translations.widgets.shape_rotate90)}
                            onClick={() => this.rotate90CW()}
                        />
                    </ButtonGroup>
                </FormSection>
            </div>
        );
    }

    // Rotate 90 deg
    rotate90CW = () => {
        const { layout, onLayoutChange } = this.props;
        onLayoutChange({
            ...layout,
            h: layout.w,
            w: layout.h,
        });
    };
}

export default injectIntl(ShapesForm);
