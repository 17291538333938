import React from 'react';
import moment from 'moment';

import { DatePicker, TimePickerProps, TimePrecision } from '@blueprintjs/datetime';

import { withFormBare } from './common';

interface Props {
    maxDate?: Date;
    minDate?: Date;
    onChange: (date: moment.Moment | null) => void;
    timePrecision?: TimePrecision;
    timePickerProps?: TimePickerProps;
    value?: moment.Moment | null;
}

const DatePickerInput = (props: Props) => {
    const { onChange, value, ...inputProps } = props;
    return <DatePicker onChange={(e) => onChange(e ? moment.utc(e) : null)} value={value?.toDate()} {...inputProps} />;
};

const FormBareDatePicker = withFormBare(DatePickerInput);

export default FormBareDatePicker;
