import Logger from 'js-logger';

import request from 'reports/modules/request';

import { Action } from 'redux';
import { actionCreatorFactory, isType } from 'typescript-fsa';
import { createAsyncWorkflow } from 'reports/utils/async_helpers';

import * as pusher from 'helioscope/app/utilities/pusher';

import * as users from 'reports/models/user';
import * as auth from 'reports/modules/auth';

import { default as Maps } from 'reports/utils/maps';

import { IAppState } from 'reports/types';

const logger = Logger.get('config');

export interface IAppConfig {
    user?: users.User;
    pdf_mode: boolean;
    use_customer_support: boolean;
    access_token: string;
    capture_exceptions: boolean;
    debug: boolean;
    google_maps_client_id: string;
    google_maps_api_key: string;
    use_tracking: boolean;
    scf_public_key: string;
    pusher_key: string;
    stripe_public_key: string;
    product_metadata: IProductMetadata;
    PUBLIC_API_KEYS: { [s: string]: string };
    log_debug: boolean;
    statsd: boolean;
    log_rocket: string;
    version: string;
    sentry_env: string;
    tou_url: string;
    privacy_policy_url: string;

    // routing properties
    react_router_prefix?: string;
    primary_url_root: string; // point to the non-beta endpoint if different from url_root
    url_root: string;
}

export interface IProductMetadata {
    [productKey: string]: IProduct;
}

export interface IProduct {
    v2_stripe_id: string;
    name: string;
    limits: IProjectLimits;
}

export interface IProjectLimits {
    projects_per_license_per_month: number;
    annual_multiplier: number;
}

const actionCreator = actionCreatorFactory('CONFIG');

const configLoaded = actionCreator<IAppConfig>('CONFIG_LOADED');

export async function fetchConfig() {
    let config: IAppConfig = window['initialConfig'];
    if (config == null) {
        const resp = await request.get('/api/system/boot');
        config = resp.body;
    }

    return config;
}

const initializeApp = createAsyncWorkflow<any, IAppConfig>(
    'INITIALIZE_APP',
    actionCreator,
    async ({ config }: { config: IAppConfig }, dispatch, getState) => {
        let newLogLevel = config.debug ? Logger.DEBUG : Logger.INFO;

        if (config.user != null) {
            if (config.user.is_admin) {
                newLogLevel = Logger.DEBUG;
            }
        }

        Logger.setLevel(newLogLevel);

        dispatch(configLoaded(config));

        if (config.user != null) {
            dispatch(users.actions.dataLoaded(config.user));
            const fullUser = users.selectors.byObject(getState(), config.user);
            dispatch(auth.api.login.done({ result: fullUser, params: {} } as any));
        }

        pusher.initApp(config);
        Maps.initApp(config);

        return config;
    },
);

const selectors = {
    getConfig: (state: IAppState) => state.config.serverConfig,
};

const actions = {
    initializeApp,
};

export interface IConfigState {
    serverConfig: IAppConfig | null;
}

const initialState: IConfigState = { serverConfig: null };

function reducer(state: IConfigState = initialState, action: Action): IConfigState {
    if (isType(action, configLoaded)) {
        return {
            serverConfig: { ...action.payload },
        };
    }

    if (isType(action, initializeApp.failed)) {
        logger.warn('App initialization failed', action.payload.error);
        return initialState;
    }

    return state;
}

export { actions, reducer, selectors };
