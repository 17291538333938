import Section2 from './Section2';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

/**
 * Similar to Section2, but designed to be used within <Card>s or Card-like
 * container elements
 */
const CardSection = styled(Section2)`
    > .header {
        font-size: 16px;
    }
`;

export default CardSection;
