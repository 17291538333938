/* tslint:disable:variable-name function-name */
import moment from 'moment';
import { BaseClass, defaults, ensureProperty, ReduxEndpoint } from 'reports/utils/api';

import { schema } from './schema';
import * as user from './user';
import * as team from './team';
import * as pd from './power_device';
import * as mod from './module';
import * as wr from './wire';
import * as acc from './ac_config';
import * as scen from './scenario';
import * as ft from './financial_template';
import { IAPIQueryOpts } from './types';
import { RackType } from 'reports/models/field_segment';
import { FinancialTemplate } from 'reports/models/financial_template';

export interface ProfileForm {
    type: ProfileType;
    name: string;
    description: string;
    data: any;
    public: boolean;
}

export interface ProfileSaveForm extends ProfileForm {
    profile_id: string | number;
}

// Mechanical
export enum TiltStrategyTypes {
    fixed = 'Fixed Tilt Value',
    latitude = 'at Project Latitude',
    simple_optimization = 'Optimization for Project Latitude',
}

export type TiltStrategyType = keyof typeof TiltStrategyTypes;

export enum RowSpacingTypes {
    fixed = 'Fixed Row Spacing',
    span_to_rise = 'Fixed Span-to-Rise',
    gcr = 'Fixed Ground Coverage Ratio',
    shading = 'Shade Tolerance Window',
}

export type RowSpacingType = keyof typeof RowSpacingTypes;

export enum AlignmentTypes {
    left = 'Align Left',
    center = 'Align Center',
    right = 'Align Right',
    block = 'Align Block',
}

type AlignmentType = keyof typeof AlignmentTypes;

class Profile extends BaseClass {
    profile_id: number;
    name: string;
    description: string;
    data: any;
    type: ProfileType;

    public: boolean;
    team_id: number;
    team?: team.Team;
    team_settings: ProfileTeam;

    created: moment.Moment;
    creator: user.User;
    last_modified?: moment.Moment;
    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    // Mechanical profiles
    module?: mod.Module;

    // Electrical profiles
    inverter?: pd.PowerDevice;
    optimizer?: pd.PowerDevice;
    bus_wire?: wr.Wire;
    string_wire?: wr.Wire;
    trunk_wire?: wr.Wire;
    ac_branch_wire?: wr.Wire;
    ac_run_wire?: wr.Wire;
    inverter_ac_config?: acc.AcConfig;
    panel_transformer_ac_config?: acc.AcConfig;

    // Project profiles
    electrical_profile?: ElectricalProfile;
    mechanical_profile?: MechanicalProfile;
    financial_profile?: Profile;
    scenario_profile?: Profile;

    // Financial profiles
    financial_template?: FinancialTemplate;

    constructor(data, isCreatingFromSubclass?) {
        super(Profile.deserializer(data));
        if (!isCreatingFromSubclass) {
            switch (data.type) {
                case 'electrical':
                    return new ElectricalProfile(data);
                case 'mechanical':
                    return new MechanicalProfile(data);
                case 'scenario':
                    return new ConditionsProfile(data);
                case 'project':
                    return new ProjectProfile(data);
                case 'financial':
                    return new FinancialProfile(data);
            }
        }
    }

    bookmarked = () => this.team_settings.bookmarked;

    static deserializer = BaseClass.getDeserializer({
        created: (x) => moment(x),
        last_modified: (x) => moment(x),
        team_settings: ensureProperty<ProfileTeam>(
            (teamData, { profile_id }) => new ProfileTeam({ profile_id, ...teamData }),
        ),
    });

    toString() {
        return this.name;
    }
}

class ProfileTeam extends BaseClass {
    profile_id: number;
    team_id?: number;
    bookmarked: boolean;

    constructor(data) {
        super(ProfileTeam.deserializer(data));
    }

    static deserializer = defaults({
        bookmarked: false,
    });
}

class ConditionsProfile extends Profile {
    data: {
        jan_soiling: number;
        feb_soiling: number;
        mar_soiling: number;
        apr_soiling: number;
        may_soiling: number;
        jun_soiling: number;
        jul_soiling: number;
        aug_soiling: number;
        sep_soiling: number;
        oct_soiling: number;
        nov_soiling: number;
        dec_soiling: number;
        temperature_variance: number;
        irradiation_variance: number;
        min_module_binning: number;
        max_module_binning: number;
        cell_temp_model: scen.CellTempModel;
        all_temperature_parameters: {
            diffuse: scen.IDiffuseCellTempParameters[];
            sandia: scen.ISandiaCellTempParameters[];
        };
        transposition_model: string;
        use_project_location: boolean;
        use_spectral_adjustment: boolean;
        ac_conductor_derate: number;
        tracker_max_angle: number;
        tracker_backtrack: boolean;
    };

    get soiling() {
        return [
            this.data.jan_soiling,
            this.data.feb_soiling,
            this.data.mar_soiling,
            this.data.apr_soiling,
            this.data.may_soiling,
            this.data.jun_soiling,
            this.data.jul_soiling,
            this.data.aug_soiling,
            this.data.sep_soiling,
            this.data.oct_soiling,
            this.data.nov_soiling,
            this.data.dec_soiling,
        ];
    }

    constructor(data) {
        super(ElectricalProfile.deserializer(data), true);
    }
}

class ElectricalProfile extends Profile {
    data: {
        stringing_strategy: string;
        use_transformers: boolean;
        ac_branch_center_feed: boolean;
        max_dc_ac_ratio: number;
        combiner_poles: number;
        string_size_min: number;
        string_size_max: number;
        ac_branch_length: number;
        ac_panel_size: number;
        inverter_id: number;
        power_optimizer_id: number;
        bus_id: number;
        string_id: number;
        trunk_id: number;
        ac_branch_id: number;
        ac_run_id: number;
        inverter_ac_config_id: number;
        module_config_parallel: number;
        module_config_series: number;
        panel_transformer_config_id: number;
    };

    constructor(data) {
        super(ElectricalProfile.deserializer(data), true);
    }
}

class MechanicalProfile extends Profile {
    data: {
        orientation: string;
        inner_setback: number;
        row_spacing_start_time: moment.Moment;
        row_spacing_end_time: moment.Moment;
        span_to_rise: number;
        module_id: number;
        reference_height: number;
        bank_width: number;
        alignment: AlignmentType;
        tilt: number;
        tilt_strategy: TiltStrategyType;
        dome_spacing: number;
        frame_spacing: number;
        module_spacing: number;
        row_spacing_strategy: RowSpacingType;
        bank_depth: number;
        row_spacing: number;
        gcr: number;
        rack_type: RackType;
        azimuth: number;
        max_size: number;
        shadow_caster: boolean;
    };

    constructor(data) {
        super(data, true);
    }
}

class ProjectProfile extends Profile {
    data: {
        electrical_profile_id: number;
        financial_profile_id: number;
        mechanical_profile_id: number;
        scenario_profile_id: number;
    };

    constructor(data) {
        super(data, true);
    }
}

class FinancialProfile extends Profile {
    data: {
        financial_template_id: number;
        incentive_ids: number[];
    };

    constructor(data) {
        super(data, true);
    }
}

export enum ProfileTypes {
    electrical = 'Electrical',
    financial = 'Financial',
    mechanical = 'Mechanical',
    project = 'Project',
    scenario = 'Conditions',
}

export type ProfileType = keyof typeof ProfileTypes;

const schemaObj = schema.addObject(Profile, 'profile', {
    relationships: {
        creator: { schema: user.schemaObj },
        last_modified_by_user: { schema: user.schemaObj },
        team: { schema: team.schemaObj },
        module: { schema: mod.schemaObj },
        inverter: { schema: pd.schemaObj },
        optimizer: { schema: pd.schemaObj },
        bus_wire: { schema: wr.schemaObj },
        string_wire: { schema: wr.schemaObj },
        trunk_wire: { schema: wr.schemaObj },
        ac_branch_wire: { schema: wr.schemaObj },
        ac_run_wire: { schema: wr.schemaObj },
        inverter_ac_config: { schema: acc.schemaObj },
        panel_transformer_ac_config: { schema: acc.schemaObj },
        financial_template: { schema: ft.schemaObj },
    },
});
schemaObj.addRelationship('electrical_profile', schemaObj);
schemaObj.addRelationship('mechanical_profile', schemaObj);
schemaObj.addRelationship('financial_profile', schemaObj);
schemaObj.addRelationship('scenario_profile', schemaObj);

const endpoint = ReduxEndpoint.fromSchema('/api/profiles/', schemaObj, {
    deepSelect: { team: true },
});

export interface ProfileGetOpts extends IAPIQueryOpts {
    type?: ProfileType;
    email?: string;
}

const api = {
    index: endpoint.index<ProfileGetOpts>(),
    get: endpoint.get<{ profile_id: string | number }>('{profile_id}'),
    create: endpoint.post<ProfileForm>(),
    save: endpoint.put<ProfileSaveForm>('{profile_id}'),
    delete: endpoint.delete<{ profile_id: string | number }>('{profile_id}'),
    defaults: endpoint.get<{ profile_id: string | number }>('defaults', ReduxEndpoint.PassThroughConfig()),
    patchTeamSettings: endpoint.patch<Partial<ProfileTeam>, Partial<ProfileTeam>>('{profile_id}/team_settings', {
        onSuccess: (rawData) =>
            schemaObj.dataLoaded({
                profile_id: rawData.profile_id,
                team_settings: rawData,
            }),
    }),
    isProfileInUse: endpoint.get<{ profile_id: number }, { is_profile_in_use: boolean }>(
        'is_profile_in_use',
        ReduxEndpoint.PassThroughConfig(),
    ),
};

const selectors = {
    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

export {
    api,
    endpoint,
    Profile,
    ConditionsProfile,
    ElectricalProfile,
    MechanicalProfile,
    ProjectProfile,
    FinancialProfile,
    schemaObj,
    selectors,
};
