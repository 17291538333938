import * as React from 'react';

import { useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { IconNames } from '@blueprintjs/icons';

import { RouteComponent } from 'reports/routing';

import { RedirectHelperContainer } from 'reports/components/helpers/common';

import Toaster from 'reports/modules/Toaster';

import { IncentiveEditor } from 'reports/modules/incentive/components/IncentiveEditor';
import { IncentiveLibrary } from 'reports/modules/incentive/components/IncentiveLibrary';

const Library = () => {
    const dispatch = useDispatch();
    const navigateToIncentive = (incentiveId: number) =>
        dispatch(routerActions.navigateTo('app.incentives.incentive', { incentiveId }));

    const navigateToIncentives = () => dispatch(routerActions.navigateTo('app.incentives'));
    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name="app.incentives">
                    <IncentiveLibrary navigateToIncentive={navigateToIncentive} />
                </RouteComponent>
                <RouteComponent name="app.incentives.incentive">
                    {({ incentive }) => {
                        if (!incentive) {
                            return (
                                <RedirectHelperContainer
                                    name="app.incentives"
                                    params={{}}
                                    callback={() => {
                                        Toaster.show({
                                            icon: IconNames.WARNING_SIGN,
                                            message: <div>Incentive does not exist</div>,
                                            timeout: 2500,
                                        });
                                    }}
                                />
                            );
                        }
                        return <IncentiveEditor incentive={incentive} navigateToIncentives={navigateToIncentives} />;
                    }}
                </RouteComponent>
            </div>
        </div>
    );
};

export { Library };
