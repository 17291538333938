import _ from 'lodash';

import { _GOOGLE_MAPS_API_KEY } from './index';

// From Google Maps Geocoding
// https://developers.google.com/maps/documentation/javascript/geocoding
export interface GeocoderResult {
    formatted_address: string;
    geometry: {
        bounds: any;
        viewport: any;
        location: GLatLng;
        location_type: any;
    };

    address_components: AddressComponent[];
    place_id: string;
    types: string[];
    partial_match?: boolean;
    plus_code?: any;
    postcode_localities?: string[];
}

export type GLatLng = { lat: () => number; lng: () => number };
export type GLatLngLiteral = { lat: number; lng: number };
export type GCoord = GLatLng | GLatLngLiteral;
export type AddressComponent = {
    short_name: string;
    long_name: string;
    types: string[];
    postcode_localities?: string[];
};

function isGLatLng(coord: GCoord): coord is GLatLng {
    return typeof (coord as GLatLng).lat === 'function';
}

async function reverseGeocodeLocation(lat, lon, args = {}, key = _GOOGLE_MAPS_API_KEY): Promise<GeocoderResult> {
    const fmtArgs = Object.entries(args).map(([arg, val]) => `${arg}=${val}`);

    const resp = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/` +
            `json?latlng=${lat},${lon}` +
            (fmtArgs.length > 0 ? `&${fmtArgs.join('&')}` : '') +
            `&key=${key}`,
    );
    return resp.json();
}

async function getStateFromLocation(lat, lon, key = _GOOGLE_MAPS_API_KEY): Promise<AddressComponent> {
    const args = { result_type: 'administrative_area_level_1' };
    const addressResult = await reverseGeocodeLocation(lat, lon, args, key);
    const components = addressResult['results'][0]['address_components'];
    const stateComponents = _.filter(components, (component) =>
        _.includes(component.types, 'administrative_area_level_1'),
    );

    return stateComponents.length > 0 ? stateComponents[0] : null;
}

export default {
    isGLatLng,
    reverseGeocodeLocation,
    getStateFromLocation,
};
