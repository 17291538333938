import * as React from 'react';
import { useDispatch } from 'react-redux';

import { orderBy } from 'lodash';
import { Icon } from '@blueprintjs/core';

import * as teams from 'reports/models/team';
import Colors from 'reports/styles/colors';
import { Link } from 'reports/components/core/controls';
import Card from 'reports/components/core/containers/Card';

const AdminText = <span style={{ color: Colors.ADMIN_TEXT }}> (Admin)</span>;

const TeamUserList = ({ team }: { team: teams.Team }) => (
    <Card style={{ overflowY: 'scroll', maxHeight: '115px' }}>
        {orderBy(
            team.users,
            ['team_admin', (u) => u.last_name.toLowerCase(), (u) => u.first_name.toLowerCase()],
            ['desc', 'asc', 'asc'],
        ).map((u) => (
            <p key={u.user_id}>
                {u.fullName()} - {u.email} {u.team_admin ? AdminText : null}
            </p>
        ))}
    </Card>
);

interface Props {
    team: teams.Team;
}

const TeamDetails = ({ team }: Props) => {
    // Sometimes team is not fully loaded to use all users.
    // This ensures that the full team will be loaded.
    const dispatch = useDispatch();
    const [fullTeam, setTeam] = React.useState<teams.Team>(team);
    const retrieveTeam = (teamId: number) => dispatch(teams.api.get({ team_id: teamId }));

    React.useEffect(() => {
        const retrieveFullTeam = async () => {
            const result = await retrieveTeam(team.team_id);
            setTeam(result);
        };
        retrieveFullTeam();
    }, [team.team_id]);

    return (
        <div>
            <span>
                <Icon icon="people" />
                <span style={{ fontWeight: 'bold' }}>Current Team</span> - {team.name}
            </span>
            <TeamUserList team={fullTeam} />
            <Link routeName="app.settings.team.overview">View Team Settings</Link>
        </div>
    );
};

export { TeamDetails };
