import { chain, find, get } from 'lodash';
import Logger from 'js-logger';

import * as React from 'react';
import { connect } from 'react-redux';

import { Classes } from '@blueprintjs/core';

import { IAppState } from 'reports/types';

import { fallback } from 'reports/utils/helpers';
import { bindActions } from 'reports/utils/redux';

import { GeoPoint } from 'helioscope/app/utilities/geometry';

import * as up from 'reports/models/usage_profile';
import * as us from 'reports/models/usage_site';
import * as auth from 'reports/modules/auth';
import * as proj from 'reports/modules/project';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const UsageProfileForm = styled.form`
    display: flex;

    .label-container {
        flex: 1;

        .${Classes.SELECT} {
            width: 60%;
        }
    }
    label {
        margin-bottom: 8px;
    }
`;

const logger = Logger.get('consumption');

interface IOwnProps {
    location: GeoPoint;
    onUpdate: (usageProfile: up.UsageProfile) => void;
    usageProfile?: up.UsageProfile;
}

interface IDispatchProps {
    loadNearbySites: (distance?: number) => Promise<us.UsageSite[]>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IDispatchProps & IStateProps;

class UsageProfileSelect extends React.PureComponent<IProps> {
    componentDidMount() {
        this.props.loadNearbySites();
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;

        if (location !== prevProps.location && location.distance(prevProps.location) > 1000) {
            this.props.loadNearbySites();
        }
    }

    render() {
        const { usageProfile } = this.props;
        return (
            <div style={{ marginTop: 6 }}>
                <UsageProfileForm>
                    <div className="label-container">
                        <label className={Classes.LABEL}>
                            Usage Site
                            <div className={Classes.SELECT}>
                                <select
                                    className={Classes.FILL}
                                    value={usageProfile != null ? usageProfile.usage_site_id : undefined}
                                    onChange={(evt) => this.updateUsageSite(parseInt(evt.target.value, 10))}
                                >
                                    {this.usageSiteOptions()}
                                </select>
                            </div>
                        </label>
                    </div>
                    <div className="label-container">
                        <label className={Classes.LABEL}>
                            Building Type
                            <div className={Classes.SELECT}>
                                <select
                                    className={Classes.FILL}
                                    value={usageProfile != null ? usageProfile.usage_profile_id : undefined}
                                    onChange={(evt) => {
                                        const usageProfileId = parseInt(evt.target.value, 10);
                                        const usageProfile = this.getProfile(usageProfileId);
                                        if (usageProfile != null) {
                                            this.props.onUpdate(usageProfile);
                                        } else {
                                            logger.error('Select a usage profile that is not in the state');
                                        }
                                    }}
                                >
                                    <optgroup label="Residential">{this.getProfileOptions('resi')}</optgroup>
                                    <optgroup label="Commercial">{this.getProfileOptions('commercial')}</optgroup>
                                </select>
                            </div>
                        </label>
                    </div>
                </UsageProfileForm>
            </div>
        );
    }

    filterProfiles(usageProfiles: up.UsageProfile[], buildingType: string) {
        return find(usageProfiles, (up) => up.building_type.name === buildingType);
    }

    getProfile(profileId) {
        return find(this.props.siteProfiles, (up) => up.usage_profile_id === profileId);
    }

    getProfileOptions(buildingType) {
        return chain(this.props.siteProfiles)
            .filter((up) => up.building_type.type === buildingType)
            .map((usageProfile) => (
                <option key={usageProfile.usage_profile_id} value={usageProfile.usage_profile_id}>
                    {usageProfile.building_type.name}
                </option>
            ))
            .value();
    }

    updateUsageSite(usageSiteId: number, update = true) {
        const usageSite = find(this.props.nearbySites, (us) => us.usage_site_id === usageSiteId);
        const usageProfiles = get(usageSite, 'usage_profiles', []);

        this.setState({ usageProfiles });

        if (usageProfiles == null) {
            return;
        }

        const buildingType = get(this.props.usageProfile, 'building_type.name', 'resi_base');
        const targetProfile = fallback(
            this.filterProfiles(usageProfiles, buildingType),
            this.filterProfiles(usageProfiles, 'resi_base'),
            usageProfiles[0],
        );

        if (update && targetProfile != null) {
            this.props.onUpdate(targetProfile);
        }
    }

    usageSiteOptions() {
        const baseOptions = chain<us.UsageSite>(this.props.nearbySites)
            .map((usageSite) => ({
                usageSite,
                distance: usageSite.location.distance(this.props.location),
            }))
            .sortBy('distance')
            .map(({ usageSite, distance }) => (
                <option key={usageSite.usage_site_id} value={usageSite.usage_site_id}>
                    {usageSite.name} (
                    {this.props.formatters.distance(distance, {
                        longDistance: true,
                    })}
                    )
                </option>
            ))
            .value();

        return [
            <option key="none" disabled value={undefined}>
                None Selected
            </option>,
        ].concat(baseOptions);
    }
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => ({
    formatters: auth.selectors.formatters(state),
    nearbySites: proj.selectors.usageSites(state, ownProps),
    siteProfiles:
        ownProps.usageProfile != null
            ? us.selectors.usageProfiles(state, {
                  usage_site_id: ownProps.usageProfile.usage_site_id,
              })
            : null,
});

const mapToDispatch = bindActions(({ location }) => ({
    loadNearbySites: (distance) => proj.actions.loadUsageSites(location, distance),
}));

export default connect(mapStateToProps, mapToDispatch)(UsageProfileSelect);
