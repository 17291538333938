/* tslint:disable:variable-name */

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from './schema';

const CIRCUIT_TYPES = {
    wye: 'wye',
    delta: 'delta',
};
type AcCircuitType = keyof typeof CIRCUIT_TYPES;

class AcConfig extends BaseClass {
    ac_config_id: number;
    name: string;
    voltage: number;
    phase: number;
    ac_circuit: AcCircuitType;

    toString() {
        return this.name;
    }
}

const schemaObj = schema.addObject(AcConfig, 'ac_config');
const endpoint = ReduxEndpoint.fromSchema('/api/ac_configs/', schemaObj);
const api = {
    index: endpoint.index<void>(),
    get: endpoint.get<{ ac_config_id: number }>('{ac_config_id}'),
};
const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { AcConfig, schemaObj, endpoint, api, selectors };
