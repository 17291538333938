import * as React from 'react';
import { useDispatch } from 'react-redux';

import * as team from 'reports/models/team';
import * as usr from 'reports/models/user';
import { UsageGraph } from './UsageGraph';

export type ChartConfig = {
    xAxisCategories: string[];
    yAxisData: number[];
    numProjects: number;
};

export const convertToChartConfig = (data: team.TeamHistoricUsage): ChartConfig => {
    const xAxisCategories = data.periods.map((period) => period.period_start.format("MMM 'YY"));

    const yAxisData = data.periods.map((period) => period.project_count);

    const numProjects = yAxisData.reduce((total, count) => total + count, 0);

    return {
        xAxisCategories,
        yAxisData,
        numProjects,
    };
};

interface Props {
    user: usr.User;
}

const UsageGraphContainer = ({ user }: Props) => {
    const dispatch = useDispatch();
    const [historicUsage, sethistoricUsage] = React.useState<team.TeamHistoricUsage | null>();

    const getTeamHistoricProjectUsage = (user: usr.User) =>
        dispatch(team.api.getHistoricUsage({ team_id: user.team_id }));

    const loadTeamHistoricProjectUsage = async () => {
        const resp: team.TeamHistoricUsage = await getTeamHistoricProjectUsage(user);
        sethistoricUsage(resp);
    };

    React.useEffect(() => {
        (async () => {
            await loadTeamHistoricProjectUsage();
        })();
    }, []);

    const convertedConsumptionData = React.useMemo(
        () => historicUsage && convertToChartConfig(historicUsage),
        [historicUsage],
    );
    return convertedConsumptionData ? <UsageGraph consumptionConfig={convertedConsumptionData} /> : <></>;
};

export default UsageGraphContainer;
