import { sortBy } from 'lodash';

import { Dispatch } from 'redux';
import { actionTypes } from 'redux-router5';
import { actionCreatorFactory, isType, Action } from 'typescript-fsa';

import { GeoPoint } from 'helioscope/app/utilities/geometry';
import { wait } from 'helioscope/app/utilities/helpers';
import * as pusher from 'helioscope/app/utilities/pusher';

import * as analytics from 'reports/analytics';

import * as des from 'reports/models/design';
import * as ds from 'reports/models/design_snapshots';
import * as fc from 'reports/models/field_component';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';
import * as sim from 'reports/models/simulation';
import * as us from 'reports/models/usage_site';
import * as ur from 'reports/models/utility_rate';

import * as finState from 'reports/modules/financials/state';
import { selectors as authSelectors } from 'reports/modules/auth';
import { parseFieldComponent, loadFieldCompDeps } from 'reports/modules/project/field_component_loader';

import projSelectors from './selectors';

export const isProjectRoute = (name: string) =>
    name === 'app.projects.project' || name.startsWith('app.projects.project');

const setPrimaryDesign = (project: proj.Project, design: des.Design) => {
    return (dispatch: Dispatch) => {
        // only really loading the design in case design render access key expires
        dispatch(proj.saver.get(project).patch({ primary_design_id: design.design_id }));
        dispatch(des.api.get({ design_id: design.design_id }));
        dispatch(sim.api.index({ design_id: design.design_id }));
    };
};

const setPrimaryScenario = (project: proj.Project, scenario: scen.Scenario) => {
    return (dispatch: Dispatch) => {
        // probably don't need to load the scenario
        dispatch(proj.saver.get(project).patch({ primary_scenario_id: scenario.scenario_id }));
        dispatch(scen.api.get({ scenario_id: scenario.scenario_id }));
        dispatch(sim.api.index({ scenario_id: scenario.scenario_id }));
    };
};

const setPrimaryScenarioSync = (project: proj.Project, scenario: scen.Scenario) => {
    return async (dispatch: Dispatch) => {
        // probably don't need to load the scenario
        await dispatch(
            proj.api.save({
                ...project,
                primary_scenario_id: scenario.scenario_id,
            }),
        );
        await dispatch(scen.api.get({ scenario_id: scenario.scenario_id }));
        await dispatch(sim.api.index({ scenario_id: scenario.scenario_id }));
    };
};

const setUtilityRate = (project: proj.Project, rate: ur.UtilityRate) => {
    return (dispatch: Dispatch) => {
        dispatch(proj.saver.get(project).patch({ utility_rate_id: rate.utility_rate_id }));
        dispatch(ur.api.get({ utility_rate_id: rate.utility_rate_id }));
    };
};

const patchFinancialSettings = (project: proj.Project, settingsPatch: Partial<proj.FinancialRateSettings>) => {
    return (dispatch: Dispatch) => {
        // apply patch on top of the deserialized data, since the default value for net_metering depends on
        // whether financial_settings is null or not.
        const newSettings = {
            ...project.data.financial_settings,
            ...settingsPatch,
        };
        dispatch(proj.saver.get(project).patch({ data: { financial_settings: newSettings } }));
    };
};

const setPrimaryProjectFinancialTemplate = (
    project: proj.Project,
    config: projFinTemp.ProjectFinancialTemplate | null,
) => {
    if (!config) {
        return (dispatch: Dispatch) => {
            return dispatch(proj.saver.get(project).patch({ primary_project_financial_template_id: null }));
        };
    }

    return (dispatch: Dispatch) => {
        const ret = dispatch(
            proj.saver.get(project).patch({
                primary_project_financial_template_id: config.project_financial_template_id,
            }),
        );
        dispatch(
            projFinTemp.api.get({
                project_financial_template_id: config.project_financial_template_id,
            }),
        );
        return ret;
    };
};

const loadProjectState = (projId: number | string) => async (dispatch: Dispatch, getState: any) => {
    const projectId: number = parseInt(projId as string, 10);

    // First load important data synchronously
    await dispatch(loadSyncProjState(projectId));

    const project = proj.selectors.byId(getState(), projectId)!;

    // Load all other project data asynchronously
    const asyncLoading = dispatch(loadAsyncProjState(project));

    const finCalcsComplete = asyncLoading.then(async () => {
        dispatch(actions.projectLoaded({ projectId }));

        const primarySimulation = projSelectors.primarySimulation(getState(), { project });
        const primaryFinConfig = projSelectors.primaryProjectFinancialTemplate(getState(), {
            project,
        });

        // this is funky because there is a component that calls queueRun() on project sim and config changes
        // so all this does is wait for the output to appear (or time out)
        // whole system for triggering calcs may be revisited later -- several medium-sized pieces of hacky code
        if (primarySimulation && primaryFinConfig && primarySimulation.complete()) {
            for (let i = 0; i < 100; i += 1) {
                await wait(250);
                const tokens = finState.selectors.primaryFinConfigTokens(getState(), { project: project! });
                if (
                    finState.hasOutput(tokens) ||
                    tokens.status === finState.FIN_STATUSES.calculationError ||
                    tokens.status === finState.FIN_STATUSES.permissionsError
                ) {
                    break;
                }
            }
        }
    });

    return { project, printableReady: finCalcsComplete };
};

const loadSyncProjState = (projectId: number) => async (dispatch: Dispatch, getState: any) => {
    if (projectId === selectors.getLastLoadedProjectId(getState())) {
        return;
    }

    await dispatch(proj.api.get({ project_id: projectId }));
};

const loadAsyncProjState = (project: proj.Project) => async (dispatch: Dispatch, getState: any) => {
    if (project.project_id === selectors.getLastLoadedProjectId(getState())) {
        return;
    }

    // Load all other project data asynchronously
    const asyncPromises: any[] = [];
    const dispatchAndSave = (action) => {
        const promise = dispatch(action);
        asyncPromises.push(promise);
        return promise;
    };

    // Load primary simulation
    if (project.primary_design_id != null && project.primary_scenario_id != null) {
        dispatchAndSave(
            sim.api.index({
                design_id: project.primary_design_id,
                scenario_id: project.primary_scenario_id,
            }),
        );
    }

    // Load or set primary design (to largest nameplate)
    if (project.primary_design_id == null) {
        asyncPromises.push(
            (async () => {
                const designs = await dispatch(des.api.index({ project_id: project.project_id }));
                if (designs.length > 0) {
                    const primaryDesign = sortBy(designs, (d) => -(d.nameplate != null ? d.nameplate : 0))[0];
                    await dispatch(setPrimaryDesign(project, primaryDesign));
                }
            })(),
        );
    } else {
        await dispatch(des.api.get({ design_id: project.primary_design_id! }));
    }

    if (project.primary_design_id != null) {
        dispatchAndSave(ds.api.index({ design_id: project.primary_design_id }));
    }

    // Set primary location scenario
    if (project.primary_scenario_id == null) {
        asyncPromises.push(
            (async () => {
                const scenarios = await dispatch(scen.api.index({ project_id: project.project_id }));
                if (scenarios.length > 0) {
                    const location = project.location;
                    const primaryScenario = sortBy(scenarios, (scen) =>
                        location.distance(scen.weather_dataset.weather_source.location),
                    )[0];
                    await dispatch(setPrimaryScenario(project, primaryScenario));
                }
            })(),
        );
    } else {
        dispatchAndSave(scen.api.get({ scenario_id: project.primary_scenario_id! }));
    }

    // Get primary financial configuration
    const user = authSelectors.getUser(getState());
    const canViewFinancials = user?.hasFinancialsAccess();
    if (
        canViewFinancials &&
        project.primary_project_financial_template_id != null &&
        project.primary_project_financial_template == null
    ) {
        // TODO: load w/ project.get api
        dispatchAndSave(
            projFinTemp.api.get({
                project_financial_template_id: project.primary_project_financial_template_id!,
            }),
        );
    }

    await Promise.all(asyncPromises);
};

const deleteSimulationsLocally = (designId: number | string) => (dispatch, getState) => {
    const design = des.selectors.byId(getState(), designId)!;

    for (const simulation of design.simulations) {
        dispatch(sim.schemaObj.entityDeleted(simulation));
    }
};

const triggerPrimarySimulation = (projectId: number | string) => (dispatch, getState) => {
    const project = proj.selectors.byId(getState(), projectId)!;
    const { primary_scenario_id: scenarioId, primary_design_id: designId, team_id } = project;
    if (scenarioId == null || designId == null) {
        return;
    }

    const existingSimulation = projSelectors.primarySimulation(getState(), {
        project,
    });
    if (existingSimulation) {
        dispatch(sim.schemaObj.entityDeleted(existingSimulation));
    }

    analytics.track('simulation.triggered', {
        team_id,
        project_id: projectId,
        design_id: designId,
        nameplate: project.primary_design.nameplate,
    });

    return dispatch(sim.api.create({ scenario_id: scenarioId, design_id: designId }));
};

export const selectors = {
    ...projSelectors,
};

const actionCreator = actionCreatorFactory('PROJECT');
const fieldComponentsLoaded = actionCreator<{
    designId: number;
    results: fc.FieldComponent[];
}>('FIELD_COMPONENTS_LOADED');
const clearFieldComponents = actionCreator('CLEAR_FIELD_COMPONENTS');
const moduleResultsLoaded = actionCreator<{
    simulationId: number;
    results: sim.IModuleLevelResults;
}>('MODULE_RESULTS_LOADED');
const optimalPoaDataLoaded = actionCreator<{
    projectId: number;
    results: proj.IProjectOrientation;
}>('OPTIMAL_POA_DATA_LOADED');
const usageSitesLoaded = actionCreator<{ results: us.UsageSite[] }>('USAGE_SITES_LOADED');
const projectLoaded = actionCreator<{ projectId: number }>('PROJECT_LOADED');

const loadFieldComponents = (designId: number) => async (dispatch: Dispatch) => {
    const results = await des.api.field_components(designId);
    dispatch(fieldComponentsLoaded({ designId, results }));

    return results;
};

const loadAndParseFieldComponents = (designId: number) => {
    return async (dispatch: Dispatch, getState: any) => {
        const rawCompData = await des.api.field_components(designId);

        await loadFieldCompDeps(rawCompData, getState(), dispatch);

        const state = getState();
        const components = rawCompData.map((x) => parseFieldComponent(state, x));

        dispatch(fieldComponentsLoaded({ designId, results: components }));

        return components;
    };
};

const loadModuleResults = (simulationId: number) => async (dispatch: Dispatch) => {
    const results = await sim.api.module_level_results(simulationId);
    dispatch(moduleResultsLoaded({ simulationId, results }));

    return results;
};

function loadOptimalPoaData(project: proj.Project, weatherDatasetId: number, moduleCharacterizationId: number) {
    return async (dispatch: Dispatch) => {
        let results;
        const cachedPoaData =
            project.geometry.optimal_orientations && project.geometry.optimal_orientations[weatherDatasetId];

        if (cachedPoaData) {
            results = cachedPoaData;
        } else {
            const response = await dispatch(
                sim.api.find_optimal_tilt_azimuth({
                    project_id: project.project_id,
                    weather_dataset_id: weatherDatasetId,
                    module_characterization_id: moduleCharacterizationId,
                }),
            );
            results = await pusher.promiseFromChannel(response.channel);
        }

        dispatch(optimalPoaDataLoaded({ results, projectId: project.project_id }));

        return results;
    };
}

const DEFAULT_SEARCH_DISTANCE = 100000; // meters, a little more than 50 miles
const loadUsageSites =
    (location: GeoPoint, distance: number = DEFAULT_SEARCH_DISTANCE) =>
    async (dispatch: Dispatch) => {
        const results = await dispatch(us.api.index({ distance, location, detail: true }));
        dispatch(usageSitesLoaded({ results }));
        return results;
    };

export const actions = {
    setPrimaryDesign,
    setPrimaryScenario,
    setPrimaryScenarioSync,
    setPrimaryProjectFinancialTemplate,
    setUtilityRate,
    patchFinancialSettings,
    loadProjectState,
    deleteSimulationsLocally,
    triggerPrimarySimulation,
    clearFieldComponents,

    fieldComponentsLoaded,
    moduleResultsLoaded,
    optimalPoaDataLoaded,
    usageSitesLoaded,
    projectLoaded,
    loadModuleResults,
    loadFieldComponents,
    loadAndParseFieldComponents,
    loadOptimalPoaData,
    loadUsageSites,
};

export interface IProjectState {
    moduleLevelResults: {
        [simulationId: number]: sim.IModuleLevelResults;
    };
    fieldComponents: {
        [designId: number]: fc.FieldComponent[];
    };
    optimalPoaData: {
        [projectId: number]: proj.IProjectOrientation;
    };
    nearbyUsageSites: us.UsageSite[];
    lastLoadedProjectId?: number;
    lastViewedProjectId?: number;
}

const INITIAL_STATE: IProjectState = {
    moduleLevelResults: {},
    fieldComponents: {},
    optimalPoaData: {},
    nearbyUsageSites: [],
};

export const reducer = (state: IProjectState = INITIAL_STATE, action: Action<any>): IProjectState => {
    if (isType(action, actions.fieldComponentsLoaded)) {
        return {
            ...state,
            fieldComponents: {
                ...state.fieldComponents,
                [action.payload.designId]: action.payload.results,
            },
        };
    }

    if (isType(action, actions.clearFieldComponents)) {
        return {
            ...state,
            fieldComponents: {},
        };
    }

    if (isType(action, actions.moduleResultsLoaded)) {
        return {
            ...state,
            moduleLevelResults: {
                ...state.moduleLevelResults,
                [action.payload.simulationId]: action.payload.results,
            },
        };
    }

    if (isType(action, actions.optimalPoaDataLoaded)) {
        return {
            ...state,
            optimalPoaData: {
                ...state.optimalPoaData,
                [action.payload.projectId]: action.payload.results,
            },
        };
    }

    if (isType(action, actions.usageSitesLoaded)) {
        return {
            ...state,
            nearbyUsageSites: action.payload.results,
        };
    }

    if (isType(action, actions.projectLoaded)) {
        return {
            ...state,
            lastLoadedProjectId: action.payload.projectId,
        };
    }

    if (
        action.type === actionTypes.TRANSITION_SUCCESS &&
        action.payload.route.name.startsWith('app.projects.project')
    ) {
        const route = action.payload.route;
        return {
            ...state,
            lastViewedProjectId: isProjectRoute(route.name) ? route.params.projectId : undefined,
        };
    }

    return state;
};
