import * as React from 'react';

import { useSelector } from 'react-redux';

import { Intent } from '@blueprintjs/core';

import * as auth from 'reports/modules/auth';
import Callout from './core/controls/Callout';

const UpsellBanner = () => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);

    // TODO: update to work as expected, update dates in copy for legacy users
    const shouldTrialUserSeeUpsellBanner = user.role.will_lose_financials_on_trial_to_basic_conversion;
    return (
        <>
            {shouldTrialUserSeeUpsellBanner && (
                <Callout intent={Intent.WARNING}>
                    <span>This feature is available in HelioScope Pro and Custom subscriptions.</span>
                </Callout>
            )}
        </>
    );
};

export { UpsellBanner };
