import * as React from 'react';

import { Button, Colors, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { PipelineMetaParam } from 'reports/modules/financials/model/pipeline';
import { complexEditor, Param } from '../pipelineCommon';

import { default as Content, LEFT_RIGHT_PADDING } from './FinancialStepContent';

const ICON_SIZE = 14;

interface IStepParametersProps {
    parameter: PipelineMetaParam;
    onUpdate?: (param: string, value: any) => void;
    editable?: boolean;
}

interface IState {
    expandParams: boolean;
}

class StepParameter extends React.Component<IStepParametersProps, IState> {
    state = {
        expandParams: false,
    };

    toggleExpand = () =>
        this.setState({
            expandParams: !this.state.expandParams,
        });

    render() {
        const { parameter, onUpdate, editable = true } = this.props;
        const { expandParams } = this.state;

        const paramLocked = !editable && !parameter.configurable;

        const paramInput = (
            <Param
                parameter={parameter}
                value={parameter.value}
                updateFn={(value) => onUpdate && onUpdate(`parameter_settings.${parameter.path}.value`, value)}
                disabled={!editable}
            />
        );

        return (
            <Content.ParameterContainer>
                <Content.ParameterLine>
                    <Content.Label>{parameter.description}</Content.Label>
                    <Content.Data>
                        {complexEditor(parameter) ? (
                            <Button
                                fill
                                text={paramLocked ? 'View' : 'Edit'}
                                icon={paramLocked ? null : IconNames.EDIT}
                                active={expandParams}
                                onClick={this.toggleExpand}
                            />
                        ) : (
                            paramInput
                        )}
                    </Content.Data>
                    {editable && (
                        <Content.ParameterMeta>
                            <Content.EditableToggle
                                labelElement={<small>Editable</small>}
                                checked={!!parameter.configurable}
                                onChange={() =>
                                    onUpdate &&
                                    onUpdate(
                                        `parameter_settings.${parameter.path}.configurable`,
                                        !parameter.configurable,
                                    )
                                }
                            />
                        </Content.ParameterMeta>
                    )}
                    {!editable && (
                        <Content.ParameterMeta
                            style={{
                                minWidth: `${LEFT_RIGHT_PADDING * 2 + ICON_SIZE}px`,
                            }}
                        >
                            <Icon icon={paramLocked ? IconNames.LOCK : null} iconSize={14} color={Colors.GRAY1} />
                        </Content.ParameterMeta>
                    )}
                </Content.ParameterLine>
                {expandParams ? <Content.ExpandedInput>{paramInput}</Content.ExpandedInput> : null}
            </Content.ParameterContainer>
        );
    }
}

export default StepParameter;
