/**
 * Report Proposals Custom Text Format Form
 */
import { debounce } from 'lodash';
import * as React from 'react';

import classNames from 'classnames';
import { Classes, Colors, IconName } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { EditorState, Modifier, RichUtils } from 'draft-js';

import { Theme } from 'reports/models/theme';
import { AVAILABLE_FONTS, isColor, IColorCustomKey, IStyleMap, rgbToString } from 'reports/modules/report/styles';
import { NumericInput } from 'reports/components/helpers/formHelpers';

import { FormatButton, FormSection } from 'reports/modules/report/components/helpers';
import { ColorPicker, IColor } from 'reports/modules/report/components/forms/ColorPicker';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface IProps {
    editorState: EditorState;
    styleMap: IStyleMap;
    theme: Theme;
    updateEditorState: (editorState: EditorState) => any;
    updateStyleMap: (styleMap: IStyleMap) => any;
}

interface IState {
    selectedColor: string;
    selectedFontFamily: string;
    selectedFontSize: number;
}

interface IStyleButton {
    icon: IconName;
    style: string;
}

interface IStyleButtonConfig extends IStyleButton {
    label: string;
}

interface IStyleButtonProps extends IStyleButton {
    active: boolean;
    onToggle: (style: string) => void;
}

const ButtonBar = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FontDetails = styled.div`
    display: flex;
`;

const FontSelect = styled.div`
    &.${Classes.FILL} {
        &::after {
            line-height: inherit;
        }

        select {
            height: 100%;
        }
    }
`;

const FontSizeInput = styled(NumericInput).attrs({
    buttonPosition: 'none',
    className: Classes.MINIMAL,
})`
    align-items: center;
    margin-left: 6px;

    .${Classes.INPUT_GROUP} {
        width: 50px;
        border-radius: 1px 0 0 1px;
        border: 1px solid ${Colors.LIGHT_GRAY4};
    }

    input {
        padding: 0;
        text-align: center;
        height: 34px;

        &:focus {
            box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
        }
    }
`;

const StyleButton = ({ active, icon, style, onToggle }: IStyleButtonProps) => (
    <FormatButton
        icon={icon}
        active={active}
        onMouseDown={(e) => {
            e.preventDefault();
            onToggle(style);
        }}
    />
);

class GenericTextForm extends React.PureComponent<IProps, IState> {
    defaultStyles: IState = {
        selectedColor: `color_${Colors.BLACK}`,
        selectedFontFamily: AVAILABLE_FONTS[4].style as string, // Source Sans Pro
        selectedFontSize: 14,
    };

    blockStyles: IStyleButtonConfig[] = [
        {
            label: 'Align Left',
            icon: IconNames.ALIGN_LEFT,
            style: 'align-left',
        },
        {
            label: 'Align Center',
            icon: IconNames.ALIGN_CENTER,
            style: 'align-center',
        },
        {
            label: 'Align Right',
            icon: IconNames.ALIGN_RIGHT,
            style: 'align-right',
        },
        {
            label: 'Justify',
            icon: IconNames.ALIGN_JUSTIFY,
            style: 'align-justify',
        },
    ];

    inlineStyles: IStyleButtonConfig[] = [
        { label: 'Bold', icon: IconNames.BOLD, style: 'BOLD' },
        { label: 'Italic', icon: IconNames.ITALIC, style: 'ITALIC' },
        { label: 'Underline', icon: IconNames.UNDERLINE, style: 'UNDERLINE' },
        // { label: 'Monospace', icon="monospace" style: 'CODE' },
    ];

    fontSizeTimeoutId: number | undefined;

    state: IState = {
        ...this.defaultStyles,
    };

    componentDidUpdate(prevProps) {
        const { editorState, styleMap } = this.props;

        if (prevProps.editorState !== editorState) {
            const currentStyle = editorState.getCurrentInlineStyle();
            const updatedStyles = { ...this.defaultStyles };

            // Update selected styles for current selection
            currentStyle.forEach((style) => {
                if (style == null) return;
                if (style.startsWith('color_') || style === 'color_primary' || style === 'color_secondary') {
                    updatedStyles['selectedColor'] = style;
                }
                if (style.startsWith('fontFamily_')) {
                    updatedStyles['selectedFontFamily'] = style;
                }
                if (style.startsWith('fontSize_')) {
                    updatedStyles['selectedFontSize'] = styleMap[style].fontSize as any;
                }
            });

            this.setState(updatedStyles);
        }
    }

    render() {
        return (
            <FormSection onClick={(e) => e.stopPropagation()}>
                {this.renderButtonBar()}
                {this.renderFontDetails()}
                <ColorPicker
                    color={this.state.selectedColor}
                    theme={this.props.theme}
                    maxWidth={275}
                    setColorProp={this.setColorProp}
                    styleMap={this.props.styleMap}
                />
            </FormSection>
        );
    }

    keepSelectionInFocus(editorState: EditorState, selectionEditorState?: EditorState) {
        if (!editorState.getSelection().getHasFocus()) {
            const selectionES = selectionEditorState ? selectionEditorState : editorState;
            return EditorState.acceptSelection(
                editorState,
                selectionES.getSelection().merge({ hasFocus: true }) as any,
            );
        }
        return editorState;
    }

    renderButtonBar() {
        const { editorState } = this.props;
        const blockType = RichUtils.getCurrentBlockType(editorState);
        const currentStyle = editorState.getCurrentInlineStyle();

        return (
            <ButtonBar>
                {this.inlineStyles.map((inlineStyle) => (
                    <StyleButton
                        key={inlineStyle.label}
                        icon={inlineStyle.icon}
                        style={inlineStyle.style}
                        active={currentStyle.has(inlineStyle.style)}
                        onToggle={(style) => this.toggleInlineStyle(style)}
                    />
                ))}
                {this.blockStyles.map((blockStyle) => (
                    <StyleButton
                        key={blockStyle.label}
                        icon={blockStyle.icon}
                        style={blockStyle.style}
                        active={blockType === blockStyle.style}
                        onToggle={(style) => this.toggleBlockStyle(style)}
                    />
                ))}
            </ButtonBar>
        );
    }

    renderFontDetails() {
        const Input: any = FontSizeInput; // TODO: Remove Type System Jank
        return (
            <FontDetails>
                {this.renderFontFamilySelect()}
                <Input value={this.state.selectedFontSize} onValueChange={this.setFontSize} min={5} max={500} />
            </FontDetails>
        );
    }

    renderFontFamilySelect() {
        return (
            <FontSelect className={classNames(Classes.SELECT, Classes.FILL)}>
                <select value={this.state.selectedFontFamily} onChange={(e) => this.toggleInlineStyle(e.target.value)}>
                    {AVAILABLE_FONTS.map(({ label, style }) => (
                        <option key={style} value={style}>
                            {label}
                        </option>
                    ))}
                </select>
            </FontSelect>
        );
    }

    /**
     * Update colour of current selection and add new custom colours to widget style map
     * @param c - colour object returned by color picker or custom color key
     */
    setColorProp = (c: IColor | IColorCustomKey) => {
        const { styleMap } = this.props;

        let colorKey;
        let selectedColor;
        if (isColor(c)) {
            const hexKey = `color_${c.hex.toUpperCase()}`;
            const isCustomColor = c.rgb.a < 1 || !styleMap[hexKey];

            selectedColor = rgbToString(c.rgb);
            colorKey = isCustomColor ? `color_${selectedColor}` : hexKey;

            // Save custom colour to map
            if (isCustomColor && !styleMap[colorKey]) {
                this.props.updateStyleMap({
                    ...styleMap,
                    [colorKey]: { color: selectedColor },
                });
            }
        } else {
            // Set as primary/secondary color keys
            colorKey = c;
            selectedColor = c;
        }

        this.setState({ selectedColor: colorKey });

        // Update EditorState with color change and toggle color on current selection
        this.toggleStyleProp(colorKey, { color: selectedColor }, false);
    };

    setFontSize = debounce((fontSize: number) => {
        this.setState({ selectedFontSize: fontSize });
        this.toggleStyleProp(`fontSize_${fontSize}`, { fontSize });
    }, 300);

    styleExists(prefix: string, styleKey: string) {
        const currentStyle = this.props.editorState.getCurrentInlineStyle();
        return prefix.length && styleKey.startsWith(prefix) && currentStyle.has(styleKey);
    }

    toggleBlockStyle = (style: string) => {
        this.props.updateEditorState(RichUtils.toggleBlockType(this.props.editorState, style));
    };

    toggleInlineStyle = (inlineStyle: string) => {
        const nextEditorState = RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle);
        this.props.updateEditorState(this.keepSelectionInFocus(nextEditorState));
    };

    toggleStyleProp = (styleKey: string, styleVal: React.CSSProperties, updateMap: boolean = true) => {
        const { editorState, styleMap } = this.props;

        const currentStyle = editorState.getCurrentInlineStyle();
        const selection = editorState.getSelection();
        const prefix = styleKey.split('_')[0];

        // Save new custom styles
        if (updateMap && !styleMap[styleKey]) {
            this.props.updateStyleMap({ ...styleMap, [styleKey]: styleVal });
        }

        // Remove active styles of the same category
        const nextContentState = Object.keys(styleMap).reduce((contentState, style) => {
            const category = style.split('_')[0];
            if (category && category === prefix && currentStyle.has(style)) {
                return Modifier.removeInlineStyle(contentState, selection, style);
            }
            return contentState;
        }, editorState.getCurrentContent());

        let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

        // Unset style override for all styles of the same type
        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce((contentState: EditorState, style) => {
                if (style && this.styleExists(prefix, style) && style !== styleKey) {
                    return RichUtils.toggleInlineStyle(contentState, style);
                }
                return contentState;
            }, nextEditorState);
        }

        // Finally, actually toggle color
        if (!currentStyle.has(styleKey)) {
            nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, styleKey);
        }

        this.props.updateEditorState(this.keepSelectionInFocus(nextEditorState, editorState));
    };
}

export default GenericTextForm;
