import * as React from 'react';

import { ButtonProps, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Button from './Button';
import LinkButton, { ILinkButtonProps } from './LinkButton';

const EditButton = (buttonProps: ButtonProps) => (
    <Button icon={IconNames.EDIT} intent={Intent.SUCCESS} text="Edit" {...buttonProps} />
);

export const EditLinkButton = (props: ILinkButtonProps) => (
    <LinkButton icon={IconNames.EDIT} intent={Intent.SUCCESS} text="Edit" {...props} />
);

export default EditButton;
