import { IncentiveType } from 'reports/models/incentive';
import { ParamConfigType, ParamValueType } from 'reports/modules/financials/params';
import {
    NameplateIncentiveConfig,
    ProductionIncentiveConfig,
    SystemPriceIncentiveConfig,
} from 'reports/modules/financials/model/modules/basicIncentive';

type ParametersConfig = { [key: string]: ParamConfigType };

const FIXED_INCENTIVE_PARAMETERS: ParametersConfig = {
    amount: {
        description: 'Incentive Amount',
        type: ParamValueType.Currency,
        min_value: 0.0,
        max_value: 9999999999.0,
        default: 0.0,
    },
    year: {
        description: 'Year of Payout',
        type: ParamValueType.Integer,
        min_value: 0,
        max_value: 999,
        default: 0,
    },
};

const SYSTEM_PRICE_INCENTIVE_PARAMETERS: ParametersConfig = {
    amount: SystemPriceIncentiveConfig,
    payment_schedule: {
        description: 'Payment Schedule',
        type: ParamValueType.Table,
        max_rows: 99,
        sort_path: 'year',
        default: [{ portion: 1.0, year: 1 }],
        columns: [
            {
                description: 'Payout %',
                path: 'portion',
                type: ParamValueType.Percentage,
                min_value: 0.0,
                max_value: 1.0,
                default: 0.0,
            },
            {
                description: 'Year',
                path: 'year',
                type: ParamValueType.Integer,
                min_value: 0,
                max_value: 999,
                default: 1,
            },
        ],
    },
    reduced_basis: {
        description: 'Reduced Basis',
        type: ParamValueType.Boolean,
        default: true,
    },
};

const NAMEPLATE_INCENTIVE_PARAMETERS: ParametersConfig = {
    amount: NameplateIncentiveConfig,
    payment_schedule: {
        description: 'Payment Schedule',
        type: ParamValueType.Table,
        max_rows: 99,
        sort_path: 'year',
        default: [{ portion: 1.0, year: 1 }],
        columns: [
            {
                description: 'Payout %',
                path: 'portion',
                type: ParamValueType.Percentage,
                min_value: 0.0,
                max_value: 1.0,
                default: 0.0,
            },
            {
                description: 'Year',
                path: 'year',
                type: ParamValueType.Integer,
                min_value: 0,
                max_value: 999,
                default: 1,
            },
        ],
    },
};

const PRODUCTION_INCENTIVE_PARAMETERS: ParametersConfig = {
    amount: ProductionIncentiveConfig,
    payout_years: {
        description: 'Incentive Duration (Years)',
        type: ParamValueType.Integer,
        min_value: 1,
        max_value: 999,
        default: 1,
    },
};

const PARAMETERS_BY_INCENTIVE_TYPE: {
    [key in IncentiveType]: ParametersConfig;
} = {
    fixed: FIXED_INCENTIVE_PARAMETERS,
    system_price: SYSTEM_PRICE_INCENTIVE_PARAMETERS,
    nameplate: NAMEPLATE_INCENTIVE_PARAMETERS,
    production: PRODUCTION_INCENTIVE_PARAMETERS,
};

export default PARAMETERS_BY_INCENTIVE_TYPE;
