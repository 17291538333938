import * as React from 'react';
import { useDispatch } from 'react-redux';

import * as usr from 'reports/models/user';

import Section2 from 'reports/components/core/containers/Section2';

import { UserPreferencesForm } from 'reports/modules/settings/user/components/UserPreferencesForm';
import { addPromiseToasts } from 'reports/modules/Toaster';

interface Props {
    user: usr.User;
}

const UserPreferences = ({ user }: Props) => {
    const dispatch = useDispatch();
    const updateUser = (form: usr.IUserForm) =>
        dispatch(usr.api.save({ ...form, email: user.email }, { action: 'update_preferences' }));
    const onSubmit = async (formData: usr.IUserForm) => {
        await addPromiseToasts(updateUser(formData), {
            initial: 'Saving user settings...',
            onSuccess: 'Successfully saved user settings.',
            onCatch: 'Error saving user settings.',
        });
    };

    return (
        <Section2 title="Preferences">
            <UserPreferencesForm onSubmit={onSubmit} user={user} />
        </Section2>
    );
};

export { UserPreferences };
