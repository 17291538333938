import { useLoaderData, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectors as subscriptionSelectors, Subscription } from 'reports/models/subscription';
import { selectors as teamSelectors, Team } from 'reports/models/team';
import { selectors as userSelectors, User } from 'reports/models/user';

import { IAppState } from 'reports/store';

type SettingsContext = {
    subscription?: Subscription;
    team: Team;
    user: User;
};

// Intended to be used by the parent route's component only. I.E. BaseSettings.
const useSettingsData = (): { subscription?: Subscription; team?: Team; user?: User } => {
    const context = useLoaderData() as SettingsContext;

    const team = useSelector((state) => teamSelectors.byObject(state as IAppState, context.team));
    const user = useSelector((state) => userSelectors.byObject(state as IAppState, context.user));

    const sub = team?.latest_subscription || user?.latest_subscription;
    const subscription = useSelector((state) =>
        sub ? subscriptionSelectors.byObject(state as IAppState, sub) : undefined,
    );

    // We have some special ?? treatment in here so we can avoid having null values for subscription,
    // team and user. Having to define team?: Team | null all over the codebase would be a PITA.
    return { subscription: subscription ?? undefined, team: team ?? undefined, user: user ?? undefined };
};

// Intended to be used by BaseSettings's children only.
const useSettingsContext = () => useOutletContext() as SettingsContext;

export { SettingsContext, useSettingsContext, useSettingsData };
