import * as React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { Intent } from '@blueprintjs/core';

import { Button } from 'reports/components/core/controls';

import { SubscriptionInput } from 'reports/modules/settings/admin/components/SubscriptionInput';

import { api as TeamAPI } from 'reports/models/team';

const PayByInvoiceEnableControl = ({ user }) => {
    const dispatch = useDispatch();
    const updateTeamFraud = (data: { team_id: number }) => dispatch(TeamAPI.updateTeamFraud(data));
    const fraudEventCount = user.team.team_fraud_events.length;
    const mostRecentFraudTimestamp = fraudEventCount > 0 ? user.team.team_fraud_events[0].timestamp : null;
    const helperText =
        `This account has ${fraudEventCount} missed or failed payments, which could indicate fraud. `.concat(
            mostRecentFraudTimestamp
                ? `The most recent failure to pay was on ${moment(mostRecentFraudTimestamp).format('ll')}`
                : '',
        );
    return (
        <SubscriptionInput
            disabled={user.team.pay_by_invoice_enabled}
            helperText={helperText}
            title="Enable option to pay by invoice"
        >
            <Button
                text="Enable option to pay by invoice"
                disabled={user.team.pay_by_invoice_enabled}
                intent={Intent.NONE}
                onClick={async () => {
                    await updateTeamFraud({ team_id: user.team.team_id });
                }}
                loadingWhileAwaiting={true}
            />
        </SubscriptionInput>
    );
};

export default PayByInvoiceEnableControl;
