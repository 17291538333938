import * as React from 'react';

import { promptModalBoolean } from 'reports/components/dialog';
import { TeamWarning } from 'reports/modules/settings/team/components/TeamWarning';
import { addPromiseToasts } from 'reports/modules/Toaster';

export const promptAddUserToTeam = async ({ addTeamUser, team, user }) => {
    const shouldSave = await promptModalBoolean({
        title: `Add User`,
        prompt: (
            <>
                Are you sure you want to add user <b>{user.fullName()}</b> to <b>{team.name}</b> and remove prior access
                permissions?
                <TeamWarning inline={true} warningStyle={{ marginTop: '6px', height: '20px' }}>
                    <ul>
                        <li>
                            Only {user.fullName()} will be added to the {team.name}
                        </li>
                        <li>No user assets will be transferred</li>
                        <li>All current user-level access permissions to resources will be removed</li>
                    </ul>
                </TeamWarning>
            </>
        ),
        cancellable: true,
        yesLabel: 'Add User',
    });

    if (shouldSave) {
        await addPromiseToasts(addTeamUser(user), {
            initial: 'Adding user...',
            onSuccess: 'Successfully added user.',
            onCatch: 'Error adding user.',
        });
    }
};
