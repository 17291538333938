import * as React from 'react';

import { PowerDevice } from 'reports/models/power_device';
import BasicTable from 'reports/components/core/tables/BasicTable';
import { Power, Voltage } from 'reports/components/core/numbers';
import { fallback } from 'reports/utils/helpers';

interface IOwnProps {
    device: PowerDevice;
}

const SpecSheet: React.FC<IOwnProps> = ({ device }) => (
    <BasicTable tableTheme="specs" width="100%">
        <tbody>
            <tr>
                <th>Name</th>
                <td>{device.name}</td>
            </tr>
            <tr>
                <th>Manufacturer</th>
                <td>{device.manufacturer}</td>
            </tr>
            <tr>
                <th>Max Power</th>
                <td>
                    <Power value={device.max_power} />
                </td>
            </tr>
            <tr>
                <th>Min Power</th>
                <td>
                    <Power value={fallback(device.min_power, 0)} />
                </td>
            </tr>
            <tr>
                <th>Max Voltage</th>
                <td>
                    <Voltage value={fallback(device.max_voltage, device.max_mpp_voltage)} />
                </td>
            </tr>
            <tr>
                <th>Min Voltage</th>
                <td>
                    <Voltage value={fallback(device.min_voltage, device.min_mpp_voltage)} />
                </td>
            </tr>
            <tr>
                <th>Max MPPT Voltage</th>
                <td>
                    <Voltage value={fallback(device.max_mpp_voltage, device.max_voltage)} />
                </td>
            </tr>
            <tr>
                <th>Min MPPT Voltage</th>
                <td>
                    <Voltage value={fallback(device.min_mpp_voltage, device.min_voltage)} />
                </td>
            </tr>
            {device.ac_config && (
                <tr>
                    <th>AC Output</th>
                    <td>{device.ac_config.name}</td>
                </tr>
            )}
            <tr>
                <th>Source</th>
                <td>{fallback(device.source, '-')}</td>
            </tr>
            <tr>
                <th>Team</th>
                <td>{device.team ? device.team.name : '-'}</td>
            </tr>
        </tbody>
    </BasicTable>
);
export default SpecSheet;
