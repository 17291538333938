/**
 * Warning as of 08/2020: do not call this file's functions from a .ts file that's imported a lot.
 * Otherwise, Node has issues running out of memory (OOM) while building, due to some sort of circular dependency.
 * Historically, Node ran OOM when a getWidget call was moved from DocumentEditor.tsx to Document.ts.
 */

import Logger from 'js-logger';

import { IconName } from '@blueprintjs/core';

import { MessageDescriptor } from 'react-intl';

import { DeepPartial, ReactComponent } from 'reports/utils/types';

import Translations from 'reports/localization/strings';

import * as proj from 'reports/models/project';
import * as des from 'reports/models/design';
import * as sim from 'reports/models/simulation';
import * as scen from 'reports/models/scenario';
import * as theme from 'reports/models/theme';

import { MaybeFinOutput } from 'reports/modules/financials/state';

import { ITokenMap } from 'reports/modules/report/tokens';

import { ILayout } from 'reports/modules/report/components/layout/types';
import { widgetNotFound } from 'reports/modules/report/components/widgets/NotFound';

const logger = Logger.get('reports');

export interface IWidgetConfig<IContent = object> {
    // persisted configuration of a widget
    name: string; // name of the registered widget
    layout: ILayout; // where it is
    content: IContent; // what's in it
    configurable?: boolean; // whether or not the widget content can be overridden on a project-by-project basis
}

export const RICH_TEXT_WIDGET_NAME = 'rich_text';
export type RichTextWidgetName = typeof RICH_TEXT_WIDGET_NAME;
export interface IRichTextContent {
    prosemirror_doc: object;
}

export interface IPageConfig {
    widgets: { [k: string]: IWidgetConfig };
}

export interface IReportContext {
    project: proj.Project;
    simulation: sim.Simulation;
    design: des.Design;
    scenario: scen.Scenario;
    financialTokens: MaybeFinOutput;
    theme: theme.Theme;
    tokenMap?: ITokenMap;

    fileLoader: (fileId: number | string) => string;
}

export interface IWidgetRenderProps<IContent extends object = object, IContext extends object = object> {
    widgetId: string;
    config: IWidgetConfig<IContent>;
    context: IContext;
    className?: string;
}

export interface IWidgetEditProps<IContent extends object = object, IContext extends object = object>
    extends IWidgetRenderProps<IContent, IContext> {
    updateWidgetContent: (content: DeepPartial<IContent>) => void;
    updateWidgetLayout: (layout: ILayout) => void;
    commitConfig: (configToCommit?: IWidgetConfig<IContent>) => void;
    rollbackConfig: () => void;
}

export const WIDGET_CATEGORIES = {
    financial: Translations.widgets.category_financial,
    generic: Translations.widgets.category_generic,
    ignore: Translations.widgets.category_ignore,
    project: Translations.widgets.category_project,
};

export interface IWidgetMetadata<IContent> {
    category: keyof typeof WIDGET_CATEGORIES;
    displayName: MessageDescriptor;
    description?: string;
    icon?: string;
    ignoreStyle?: boolean;
    adminOnly?: boolean;

    // defaults for instantiated widgets
    content?: DeepPartial<IContent>;
    dimensions: { h: number; w: number };
}

export interface IWidget<IContent extends object = object, IContext extends object = object> {
    Component: ReactComponent<IWidgetRenderProps<IContent, IContext>>;
    EditingComponent?: ReactComponent<IWidgetEditProps<IContent, IContext>>;
    metadata: IWidgetMetadata<IContent>;
    dependencies: (keyof IContext)[];
}

export interface IWidgetControl {
    name: string;
    description?: string;
    displayName?: string;
    icon?: IconName;
    iconText?: string;
}

const WIDGETS: { [k: string]: IWidget<object, object> } = {};

export function registerWidget<DepKeys extends keyof IReportContext, IContent extends object = object>(
    name: string,
    widget: IWidget<IContent, Pick<IReportContext, DepKeys>>,
) {
    if (WIDGETS[name] != null) {
        logger.warn(`Overwriting widget ${name}`);
    }

    WIDGETS[name] = widget as IWidget<IContent, object>;

    return widget;
}

export function getWidgets() {
    return WIDGETS;
}

export function getWidget(name: string): IWidget {
    let widget = WIDGETS[name];

    if (widget == null) {
        logger.warn(`could not find widget: ${name}`);
        widget = WIDGETS[name] = widgetNotFound(name);
    }

    return widget;
}

export function isEditing<IConfig extends object = object>(
    props: IWidgetRenderProps<IConfig> | IWidgetEditProps<IConfig>,
): props is IWidgetEditProps<IConfig> {
    return (<IWidgetEditProps<IConfig>>props).updateWidgetContent != null;
}

function requireAll(r) {
    r.keys().forEach(r);
}

// force load all widgets
requireAll((require as any).context('./components/widgets', true, /^\.\/.*\.ts$/));
requireAll((require as any).context('./components/widgets', true, /^\.\/.*\.tsx$/));
