import * as React from 'react';

import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const DialogActions = styled.div.attrs<React.HTMLAttributes<HTMLDivElement>>((props) => ({
    className: classNames(props.className, Classes.DIALOG_FOOTER_ACTIONS),
}))`
    position: relative;
    padding-top: 10px;

    .hs-btn-left-align {
        position: absolute;
        left: 0;
        margin-left: 0;
    }
`;

export default DialogActions;
