/*
 * Suggested library preview component hierarchy:
 *    <Drawer title={<Header/>} {...otherDrawerProps}>
 *        <CustomPreviewComponentForItem>
 *            <ViewControls>
 *            ...preview content
 */
import * as React from 'react';

import { Classes, Colors, Drawer as BlueprintDrawer, H4, IDrawerProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';
import { Button } from 'reports/components/core/controls';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const DEFAULT_WIDTH_PERCENTAGE = 55; // 50% + 5% (extra for drawer dropshadow width)
const PADDING = 12;

const ContainerAbsolute = styled(Flex.Container)<{ widthInPercent: number }>`
    position: absolute;
    right: 0;
    left: ${(props) => Math.max(0, 100 - props.widthInPercent)}%;
    height: 100%;
    overflow: hidden;

    // Somehow this fixes drawer overlay slide-in animation in Chrome
    .${Classes.OVERLAY} {
        border: 1px solid transparent;
    }
`;

const FavoriteContainer = styled.div`
    padding: 0 8px;
    svg {
        margin-top: -3px;
    }
`;

const Subtitle = styled.div`
    font-size: 12px;
    font-style: italic;
`;

const DrawerHeader = styled.div.attrs({ className: Classes.DRAWER_HEADER })`
    padding: 8px ${PADDING}px;
    margin-bottom: 1px;

    // Override Blueprint Drawer Header specific icon margin
    .${Classes.BUTTON} > * {
        margin-right: 7px;
    }
    .${Classes.BUTTON} > :last-child,
    .${Classes.HEADING}:last-child {
        margin-right: 0;
    }
    .${Classes.HEADING} {
        flex: 0 1 auto;
    }
`;

const DrawerBody = styled.div.attrs({ className: Classes.DRAWER_BODY })`
    line-height: unset;
`;

const RowLabel = styled.div`
    font-size: 12px;
    color: ${Colors.DARK_GRAY5};
`;

const SpacedControls = styled(Flex.Container).attrs({
    align: Flex.Align.RIGHT,
})`
    flex: 1;
    flex-wrap: wrap;

    > div {
        margin-left: 10px;
        padding: 2px;
    }
`;

const ConfigControls: React.SFC<{ label: string; style?: any }> = ({ label, ...props }) => (
    <Flex.Container alignV={Flex.AlignV.CENTER} style={props.style}>
        <RowLabel>{label}</RowLabel>
        <SpacedControls>{props.children}</SpacedControls>
    </Flex.Container>
);

interface IContainedDrawer {
    isOpen: boolean;
    onClose: () => void;
    onClosed?: () => void;
    widthInPercent?: number; // Specify drawer width
}

export interface IDrawer {
    isOpen: boolean;
    onClose: () => void;
    onClosed?: () => void;
    title: React.ReactNode;
    footer?: React.ReactNode;
    widthInPercent?: number; // Specify drawer width

    // Preview Controls
    editButton?: JSX.Element;
    duplicateButton?: JSX.Element;
    publishButton?: JSX.Element;
    teamConfigControls?: React.ReactNode;
    viewControls?: React.ReactNode;
}

const ContainedDrawer: React.SFC<IContainedDrawer> = ({ isOpen, onClose, onClosed, widthInPercent, children }) => {
    const drawerProps: IDrawerProps = {
        isOpen,
        onClose,
        onClosed,
        canOutsideClickClose: false,
        hasBackdrop: false,
        enforceFocus: false,
        size: BlueprintDrawer.SIZE_LARGE, // Leave room for drawer dropshadow, instead of using 100% width
        usePortal: false,
    };

    return (
        <ContainerAbsolute widthInPercent={widthInPercent || DEFAULT_WIDTH_PERCENTAGE}>
            <BlueprintDrawer {...drawerProps}>{children}</BlueprintDrawer>
        </ContainerAbsolute>
    );
};

export const Drawer: React.SFC<IDrawer> = (props) => {
    const {
        title,
        isOpen,
        onClose,
        onClosed,
        publishButton,
        editButton,
        duplicateButton,
        teamConfigControls,
        viewControls,
        footer,
        widthInPercent,
    } = props;

    return (
        <ContainedDrawer isOpen={isOpen} onClose={onClose} onClosed={onClosed} widthInPercent={widthInPercent}>
            <DrawerHeader>
                <Flex.Main hideOverflow={true}>
                    <Flex.Container alignV={Flex.AlignV.CENTER}>
                        <Flex.Main hideOverflow={true}>{title}</Flex.Main>
                        <div style={{ marginLeft: 10 }}>
                            {publishButton}
                            {duplicateButton}
                            {editButton}
                        </div>
                        <Button icon={IconNames.CROSS} onClick={onClose} minimal={true} />
                    </Flex.Container>

                    {teamConfigControls && (
                        <ConfigControls label="Team Configuration" style={{ marginTop: 12, marginBottom: 8 }}>
                            {teamConfigControls}
                        </ConfigControls>
                    )}

                    {viewControls && (
                        <ConfigControls label="View Settings" style={{ marginTop: 8 }}>
                            {viewControls}
                        </ConfigControls>
                    )}
                </Flex.Main>
            </DrawerHeader>
            <DrawerBody>{props.children}</DrawerBody>
            {footer && <div className={Classes.DRAWER_FOOTER}>{footer}</div>}
        </ContainedDrawer>
    );
};

interface IHeader {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    favorite?: React.ReactNode;
}

export const Header: React.SFC<IHeader> = ({ title, subtitle, favorite }) => (
    <>
        <Flex.Container alignV={Flex.AlignV.CENTER}>
            <H4>{title}</H4>
            <FavoriteContainer>{favorite}</FavoriteContainer>
        </Flex.Container>
        {subtitle && <Subtitle style={{ fontStyle: 'italic' }}>{subtitle}</Subtitle>}
    </>
);

export default {
    Drawer,
    Header,
};
