import * as React from 'react';
import { connect } from 'react-redux';

import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Card } from 'reports/components/core/containers';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import CardSection from 'reports/components/core/containers/CardSection';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import { Project, saver as projectSaver } from 'reports/models/project';
import { UtilityRate } from 'reports/models/utility_rate';
import { actions as projActions, selectors } from 'reports/modules/project';
import * as auth from 'reports/modules/auth';

import ConsumptionEditor from 'reports/modules/consumption/components/ConsumptionEditor';
import ConsumptionEnergyChart from 'reports/modules/consumption/components/ConsumptionEnergyChart';
import FinancialMetricsTable from 'reports/modules/financials/components/FinancialMetrics';
import IncentiveEditor from 'reports/modules/financials/components/ProjectIncentiveEditor';
import UtilityRateEditor from 'reports/modules/financials/components/ProjectRateEditor';
import LifetimeProduction from 'reports/modules/financials/components/LifetimeProduction';

import FinancialInputs from './FinancialInputs';
import ProjectDetailTable from './ProjectDetailTable';
import ProjectExportTable from './ProjectExportTable';
import ScenarioDropdown from './ScenarioDropdown';
import ScenarioSummary from './ScenarioSummary';
import SystemDesign from './SystemDesign';
import EditProjectDetailsLauncher from './EditProjectDetailsLauncher';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface IOwnProps {
    project: Project;
}

interface IDispatchProps {
    setUtilityRate: (rate: UtilityRate) => any;
    updateProject: (data: Partial<Project>) => any;
    editDesign: () => any;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

const ProjectOverview: React.FC<IProps> = (props) => {
    const { primaryDesign, primaryScenario, primarySimulation, user } = props;
    const project = props.project!; // we know we have a selected project because of our router loader
    const canViewFin = user.hasFinancialsAccess();

    const inner = (
        <GridContainer>
            <Card>
                <CardSection
                    title="Project Details"
                    contextEl={project.is_admin && <EditProjectDetailsLauncher project={project} user={user} />}
                >
                    <ProjectDetailTable project={project} />
                </CardSection>
            </Card>
            <Card>
                <SystemDesign project={project} design={primaryDesign} />
            </Card>
            {canViewFin && (
                <Card>
                    <UtilityRateEditor project={project} />
                    <ConsumptionEditor project={project} />
                    <IncentiveEditor project={project} />
                </Card>
            )}
            <Card>
                <CardSection
                    title="Condition Set"
                    contextEl={
                        <ScenarioDropdown project={project}>
                            <Button
                                disabled={project.scenarios.length === 0}
                                rightIcon={IconNames.CARET_DOWN}
                                text={primaryScenario != null ? primaryScenario.description : 'No Scenario'}
                            />
                        </ScenarioDropdown>
                    }
                >
                    <div style={{ margin: '4px 0px' }}>
                        {primaryScenario != null ? <ScenarioSummary scenario={primaryScenario} /> : null}
                    </div>
                    {primaryDesign && primarySimulation && primarySimulation.complete() ? (
                        <ConsumptionEnergyChart project={project} />
                    ) : null}
                </CardSection>
            </Card>
            {canViewFin && (
                <Card>
                    <FinancialInputs project={project} />
                </Card>
            )}
            {canViewFin && (
                <Card>
                    <CardSection title="Financial Metrics">
                        {primaryDesign != null && primarySimulation != null ? (
                            <LifetimeProduction project={project} />
                        ) : null}
                        <FinancialMetricsTable project={project} />
                    </CardSection>
                </Card>
            )}
            <Card>
                <CardSection title="Project Files Exporter">
                    <ProjectExportTable project={project} />
                </CardSection>
            </Card>
        </GridContainer>
    );

    const outer = (
        <>
            <div className="content-header">
                <EditableTitleSubHeader
                    value={project.name}
                    updateFn={(value) => props.updateProject({ name: value })}
                />
            </div>
            <div className="sub-content-inner" style={{ padding: '8px' }}>
                {inner}
            </div>
            <div className="sub-content-footer" />
        </>
    );

    return (
        <div className="sub-container">
            <div className="sub-content-container">{outer}</div>
        </div>
    );
};

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => ({
    primaryDesign: selectors.primaryDesign(state, ownProps),
    primaryScenario: selectors.primaryScenario(state, ownProps),
    primarySimulation: selectors.primarySimulation(state, ownProps),
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(({ project }: IOwnProps) => ({
    setUtilityRate: (rate) => projActions.setUtilityRate(project, rate),
    updateProject: (data) => projectSaver.get(project).patch(data),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOverview);
