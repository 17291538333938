import { AnchorButton } from '@blueprintjs/core';

import { BUTTON_MARGIN_LEFT } from './Button';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export default styled(AnchorButton)`
    margin-left: ${BUTTON_MARGIN_LEFT};
    &&& {
        text-decoration: none;
    }
`;
