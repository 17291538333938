import { find, isMatch } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { Button, Classes, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';

import Image from 'reports/components/helpers/Image';

import * as ds from 'reports/models/design_snapshots';
import * as s3file from 'reports/models/s3file';

import * as uploads from 'reports/modules/files/uploads';
import {
    registerWidget,
    IWidgetRenderProps,
    IWidgetEditProps,
    IReportContext,
    isEditing,
    IWidgetConfig,
} from 'reports/modules/report/widgets';
import { IFullRenderSettings } from 'reports/modules/ogdesigner/components/InteractiveDesign3dView';
import { ILayout } from 'reports/modules/report/components/layout';

import { DesignHeatMapProvider, DesignHeatMapContext } from './DesignHeatMapContext';
import { DesignSnapshotForm } from './DesignSnapshotForm';
import { OffscreenDesignSnapshot } from './OffscreenDesignSnapshot';
import DesignHeatMapLegend from './DesignHeatMapLegend';
import { IHeatMapSettings } from './common';
import { bindActions } from 'reports/utils/redux';
import SidebarFormatForm from '../../SidebarFormatForm';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// Need because PanelBody in SidebarFormatForm lacks top padding
const TopMarginButton = styled(Button)`
    margin-top: 12px;
`;

export const DEFAULT_SETTINGS = {
    modules: true,
    field_segments: true,
    keepouts: true,
    overlays: true,
    premades: true,
    wiring: false,
    inverters: true,
    combiners: false,
    interconnect: false,
    azimuth: 0,
    elevation: 90,
};

export const DEFAULT_CAMERA_SETTINGS = {
    azimuth: 180,
    elevation: 90,
    zoom: 1.0,
};

export interface IContent extends IFullRenderSettings {
    // There is also a per-module color mapping allowed in IFullRenderSettings,
    // but we don't want to persist that in the widget content, since it can be
    // computed on demand
    moduleColor?: string;
    heatMapSettings?: IHeatMapSettings;
}

type IContext = Pick<IReportContext, 'project' | 'design' | 'scenario' | 'simulation'>;
type OwnProps = IWidgetRenderProps<IContent, IContext> & IWidgetEditProps<IContent, IContext>;
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type IProps = OwnProps & StateProps & DispatchProps;

class DesignSnapshot extends React.Component<IProps> {
    state = {
        loading: false,
        generating: false,
        uploading: false,
        showDialog: false,
        // We don't want to load the large module_level file if the snapshot doesn't have the
        // heat map enabled. However, the user can enable the heat map in the Form.
        heatMapDataNeededInForm: false,
        snapshotUrl: null as null | string,
        objectUrl: false,
        cachedLayout: {} as {} | ILayout,
        foundSnapshotId: null,
    };

    componentDidUpdate(prevProps: IProps) {
        if (
            !this.state.loading &&
            (!isMatch(this.state.cachedLayout, this.props.config.layout) ||
                prevProps?.context.design.design_id !== this.props.context.design.design_id)
        ) {
            this.renderSnapshot();
        }
    }

    componentWillUnmount() {
        this.clearUrl();
    }

    renderSnapshot() {
        this.setState({ loading: true });
        this.clearUrl();
        this.getSnapshot();
    }

    clearUrl() {
        if (this.state.objectUrl) {
            URL.revokeObjectURL(this.state.snapshotUrl as string);
        }

        this.setState({
            objectUrl: false,
            snapshotUrl: null,
        });
    }

    createSnapshot = async (rawFile) => {
        if (this.state.uploading) return;
        this.setState({ uploading: true });

        const { config } = this.props;
        const { heatMapSettings } = config.content;

        this.clearUrl();
        this.setState({
            objectUrl: true,
            snapshotUrl: URL.createObjectURL(rawFile),
            cachedLayout: config.layout,
        });

        const file = await this.props.upload({ file: rawFile });

        if (this.state.foundSnapshotId != null) {
            await this.props.updateSnapshot({
                design_snapshot_id: this.state.foundSnapshotId,
                design_id: this.props.context.design.design_id,
                simulation_id: heatMapSettings ? this.props.context.simulation?.simulation_id : undefined,
                file_id: file.file_id,
                width: Math.round(config.layout.w),
                height: Math.round(config.layout.h),
                settings: config.content,
            });
        } else {
            await this.props.createSnapshot({
                design_id: this.props.context.design.design_id,
                simulation_id: heatMapSettings ? this.props.context.simulation?.simulation_id : undefined,
                file_id: file.file_id,
                width: Math.round(config.layout.w),
                height: Math.round(config.layout.h),
                settings: config.content,
            });
        }

        this.setState({
            generating: false,
            loading: false,
            uploading: false,
        });
    };

    handleImageLoadError() {
        this.clearUrl();
        this.setState({ generating: true });
    }

    async getSnapshot() {
        const { design, simulation } = this.props.context;
        const { config } = this.props;

        const hasSimulationData = config.content.heatMapSettings !== undefined;

        const foundSnapshot = find(
            this.props.allSnapshots,
            (i) =>
                i.design_id === design.design_id &&
                (!hasSimulationData || i.simulation_id === simulation.simulation_id) &&
                i.width === Math.round(config.layout.w) &&
                i.height === Math.round(config.layout.h) &&
                isMatch(i.settings, config.content),
        );

        if (foundSnapshot) {
            await this.loadSnapshotImage(foundSnapshot, config);
        } else {
            this.setState({ generating: true, foundSnapshotId: null });
        }
    }

    async loadSnapshotImage(foundSnapshot: ds.DesignSnapshot, config: IWidgetConfig<IContent>) {
        try {
            // load the existing snapshot because the global list could be stale and files are not
            // cleaned up when snapshots are, snapshot load failures will trigger a new snapshot to be created
            const existingSnapshot = await this.props.getSnapshot(foundSnapshot.design_snapshot_id);
            const snapshotFileBlob = await this.props.downloadSnapshotBlob(existingSnapshot.file_id);
            const base64Url = URL.createObjectURL(snapshotFileBlob);

            this.setState({
                snapshotUrl: base64Url,
                cachedLayout: config.layout,
                loading: false,
                foundSnapshotId: foundSnapshot.design_snapshot_id,
            });
        } catch (error) {
            this.clearUrl();
            this.setState({ generating: true });
        }
    }

    render() {
        const { heatMapDataNeededInForm, showDialog, snapshotUrl, generating, loading } = this.state;
        const editMode = isEditing(this.props);
        const { context, config, updateWidgetContent } = this.props;
        const { project, design, simulation, scenario } = context;
        const url = snapshotUrl;

        const { w: offscreenWidth, h: offscreenHeight } = config.layout;
        const { heatMapSettings } = config.content;
        const { field_segments } = design;

        const isGeneratingSnapshot = generating && !this.state.snapshotUrl;
        const showSpinner = (isGeneratingSnapshot || loading) && !showDialog;

        return (
            <DesignHeatMapProvider
                disabled={!heatMapSettings && !heatMapDataNeededInForm}
                simulation={simulation}
                project={project}
                weatherDatasetId={scenario.weather_dataset_id}
                moduleCharacterizationId={field_segments.length ? field_segments[0].module_characterization_id : null}
            >
                {url && (
                    <div onClick={() => this.setState({ showDialog: editMode })}>
                        <DesignHeatMapLegend
                            disabled={!heatMapSettings}
                            legendPosition={heatMapSettings?.legendPosition}
                            seriesName={heatMapSettings?.seriesName}
                            width={offscreenWidth}
                            height={offscreenHeight}
                        >
                            <Image src={url} onError={this.handleImageLoadError.bind(this)} />
                        </DesignHeatMapLegend>
                    </div>
                )}

                {showSpinner && (
                    <div style={{ padding: '50px' }}>
                        <Spinner className={Classes.LARGE} intent={'success'} />
                    </div>
                )}

                {generating && !showDialog && (
                    <div>
                        <DesignHeatMapContext.Consumer>
                            {(heatMap) =>
                                (!heatMapSettings || heatMap.ready || heatMap.error) && (
                                    <OffscreenDesignSnapshot
                                        designId={design.design_id}
                                        renderSettings={
                                            heatMapSettings && !heatMap.error
                                                ? {
                                                      ...config.content,
                                                      moduleColor: heatMap.data![heatMapSettings!.seriesName].colors,
                                                  }
                                                : config.content
                                        }
                                        width={offscreenWidth}
                                        height={offscreenHeight}
                                        onCapture={this.createSnapshot}
                                    />
                                )
                            }
                        </DesignHeatMapContext.Consumer>
                    </div>
                )}

                {editMode && (
                    <>
                        <DesignSnapshotForm
                            widget={config}
                            showDialog={showDialog}
                            designId={design.design_id}
                            onEnableHeatMap={() => this.setState({ heatMapDataNeededInForm: true })}
                            updateWidgetContent={updateWidgetContent}
                            onCancel={() => {
                                this.props.rollbackConfig();
                                this.toggleDialog(false);
                            }}
                            onSave={() => {
                                this.props.commitConfig();
                                this.toggleDialog(false);
                                this.setState(
                                    {
                                        loading: false,
                                        generating: false,
                                        uploading: false,
                                        snapshotUrl: null,
                                    },
                                    () => this.renderSnapshot(),
                                );
                            }}
                        />
                        <SidebarFormatForm>
                            <TopMarginButton disabled={showDialog} onClick={() => this.toggleDialog(true)}>
                                <FormattedMessage {...Translations.design.configure_render} />
                            </TopMarginButton>
                        </SidebarFormatForm>
                    </>
                )}
            </DesignHeatMapProvider>
        );
    }

    toggleDialog = (showDialog: boolean) => {
        this.setState({ showDialog });
    };
}

const mapStateToProps = (state, ownProps) => ({
    allSnapshots: ds.selectors.all(state, {
        filter: (obj) => obj.design_id === ownProps.context.design.design_id,
    }),
});

const mapDispatchToProps = bindActions(() => ({
    upload: uploads.actions.uploadFile,
    createSnapshot: (obj) => ds.api.create(obj),
    updateSnapshot: (obj) => ds.api.save(obj),
    getSnapshot: (id) => ds.api.get({ design_snapshot_id: id }),
    downloadSnapshotBlob: (id) => s3file.api.download({ file_id: id }),
}));

const DesignSnapshotContainer = connect(mapStateToProps, mapDispatchToProps)(DesignSnapshot);

export const DesignSnapshotWidget = registerWidget('design_snapshot', {
    Component: DesignSnapshotContainer,
    EditingComponent: DesignSnapshotContainer,
    metadata: {
        category: 'project',
        dimensions: { h: 400, w: 400 },
        displayName: Translations.widgets.design_snapshot_header,
        icon: IconNames.CAMERA,
    },
    dependencies: ['project', 'design', 'simulation', 'scenario'],
});
