import * as React from 'react';

import { ProgressBar, Classes, Intent, IToaster, IToastProps, Colors } from '@blueprintjs/core';

import { IconNames } from '@blueprintjs/icons';
interface ProgressToastMessage {
    intent?: Intent;
    key?: string;
    percent?: number;
    text?: string;
}

export class ProgressToast {
    private key: string;
    text: string;
    toaster: IToaster | null;

    constructor(text: string, toaster: IToaster, initialProgress = 0) {
        this.text = text;
        this.showMessage({ percent: initialProgress });
        this.toaster = toaster;
    }

    progress(percent: number, text = this.text) {
        this.text = text;
        this.showMessage({ percent, text });
    }

    success(text: string = this.text) {
        this.text = text;
        this.showMessage({
            percent: 1,
            text: this.text,
            intent: Intent.SUCCESS,
        });
    }

    failure(text: string = this.text) {
        this.text = text;
        this.showMessage({
            percent: 1,
            text: this.text,
            intent: Intent.DANGER,
        });
    }

    private showMessage({
        percent = 0,
        text = this.text,
        intent = Intent.PRIMARY,
        key = this.key,
    }: ProgressToastMessage = {}) {
        const message: IToastProps = {
            icon: IconNames.CLOUD_UPLOAD,
            message: (
                <div style={{ marginTop: -8, color: Colors.GRAY3 }}>
                    {text}
                    <br />
                    <ProgressBar
                        className={percent >= 1 ? Classes.PROGRESS_NO_STRIPES : ''}
                        intent={intent}
                        value={percent}
                    />
                </div>
            ),
            timeout: percent < 1 ? 0 : 2000,
        };

        this.key = this.toaster ? this.toaster.show(message, key) : this.key;
    }
}
