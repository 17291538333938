import { flattenComponentTree } from './helpers';
import { assert } from 'helioscope/app/utilities/helpers';

const EMPTY_SUMMARY = {
    count: 0,
    current: 0,
    inputPower: 0,
    outputPower: 0,
    resistivity: null,
    length: 0,
    bom_length: 0,
    maxInputs: 0,
    wire: null,
};

function summarizeComponent(component, summary = { ...EMPTY_SUMMARY }) {
    const { input, output } = component.power();

    summary.count += 1;
    summary.inputPower += input.power;
    summary.outputPower += output.power;
    summary.current = output.current;

    return summary;
}

function summarizeWire(fieldWire, initialSummary) {
    const summary = summarizeComponent(fieldWire, initialSummary);

    const bomLength = fieldWire.bom_length ? fieldWire.bom_length : fieldWire.length;
    summary.bom_length += bomLength;
    summary.length += fieldWire.length;

    assert(!isNaN(summary.bom_length));

    if (summary.wire === null) {
        summary.wire = fieldWire.wire;
        summary.resistivity = fieldWire.wire.resistivity;
    } else if (summary.wire !== fieldWire.wire) {
        throw Error('Wire mismatch');
    }

    return summary;
}

function summarizeCombiner(fieldCombiner, initialSummary) {
    const summary = summarizeComponent(fieldCombiner, initialSummary);

    summary.maxInputs = Math.max(summary.maxInputs, fieldCombiner.children.length);

    return summary;
}

const ACCUMULATOR_FUNCTIONS = {
    string: summarizeWire,
    bus: summarizeWire,
    trunk: summarizeWire,
    ac_branch: summarizeWire,
    ac_run: summarizeWire,

    bus_combiner: summarizeCombiner,
    trunk_combiner: summarizeCombiner,
};

export function summarizePower(componentList) {
    const summary = {};

    for (const component of flattenComponentTree(componentList)) {
        const tier = getComponentTier(component);
        const accumulator = ACCUMULATOR_FUNCTIONS[tier];

        if (accumulator) {
            summary[tier] = accumulator(component, summary[tier]);
        }
    }

    return summary;
}

export function estimateWireLoss(tierSummary, wire) {
    const { inputPower, outputPower, resistivity } = tierSummary;
    const oldWireLoss = inputPower - outputPower;
    const newWireLoss = oldWireLoss * wire.resistivity / resistivity;

    // clip it to max power
    return _.clamp(newWireLoss, 0, inputPower);
}

function getComponentTier(component) {
    if (component.component_type === 'string') {
        return 'string';
    }

    if (component.tier) {
        return component.tier;
    }

    return null;
}
