import * as React from 'react';

import { Tag } from '@blueprintjs/core';

import * as fmt from 'reports/utils/formatters';

import { StyledFormattedInput } from 'reports/components/forms/inputs/experimental/common';

export interface IConversionProps {
    toBaseUnit: (val: number) => number;
    toDisplayUnit: (val: number) => number;
}

interface IOwnProps {
    value: number;
    placeholder: string;
    precision: number;
    onChange: (val: any) => void;
    disabled: boolean;
    unitTagText: string;
    conversion?: IConversionProps;
    width?: number;
}

/**
 * An input for a numeric field that displays units on the right side of the input
 * and performs conversion to the supplied units.
 * An example of this input used in Forms is in MechanicalProfileEditor.tsx
 * The API for this is not fully baked, hence it's in the /experimental dir.
 *
 * @param unitTagText the supplied units we want to display.
 * @param conversion.toBaseUnit converts the display value in the engineer's supplied units to the units
 * used in our database/form).
 * @param conversion.toDisplayUnit converts the form/db value to the desired display units value.
 */
const UnitsInput: React.FC<IOwnProps> = ({
    value,
    placeholder = '',
    precision = 5,
    onChange,
    disabled,
    unitTagText,
    conversion,
    width,
}) => {
    const unitConversionToString = (val) =>
        fmt.stringifyNumberSimple(conversion ? conversion.toDisplayUnit(val) : val, precision);
    return (
        <StyledFormattedInput
            placeholder={placeholder}
            value={value}
            onConfirm={(val) => onChange(conversion ? conversion.toBaseUnit(val) : val)}
            textToValue={(val) => fmt.numberStringToFloat(val)}
            valueToDisplayText={unitConversionToString}
            valueToEditText={unitConversionToString}
            selectAllOnFocus={true}
            disabled={disabled}
            fill={false}
            rightElement={<Tag>{unitTagText}</Tag>}
            numericInputOnly={true}
            width={width}
        />
    );
};

export default UnitsInput;
