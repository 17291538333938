import * as React from 'react';

import { Tooltip2, Classes as poClasses, ITooltip2Props } from '@blueprintjs/popover2';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface IProps<T> extends ITooltip2Props<T> {
    className?: string; // classes to apply to the popover
    underlined?: boolean;
    fill?: boolean;
}

const StyledTooltip = styled(Tooltip2)<{ fill?: boolean }>`
    ${(props) => (props.fill ? 'width: 100%;' : '')};
`;

export class Tooltip<T> extends React.PureComponent<IProps<T>> {
    render() {
        const { className, underlined, fill, ...rest } = this.props;
        // Make Tooltip2 stylable by styled-components, by passing className to popoverClassName.
        return (
            <StyledTooltip
                className={underlined ? poClasses.TOOLTIP2_INDICATOR : undefined}
                popoverClassName={className}
                fill={fill}
                {...rest}
            />
        );
    }
}
export default Tooltip;
