/* tslint:disable:variable-name */

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from './schema';

export const WIRING_UNITS = {
    awg: 'American Wire Gauge (AWG)',
    metric: 'Metric (mm2)',
};
export type WireUnit = keyof typeof WIRING_UNITS;

class Wire extends BaseClass {
    wire_gauge_id: number;

    name: string;
    material: 'Copper' | 'Aluminum';
    units: WireUnit;
    resistivity: number;
    ampacity?: number;
    visible: boolean;

    toString() {
        return `${this.name} (${this.material})`;
    }
}

const schemaObj = schema.addObject(Wire, 'wire', { idName: 'wire_gauge_id' });

const endpoint = ReduxEndpoint.fromSchema('/api/wires/', schemaObj);

const api = {
    index: endpoint.index(),
    get: endpoint.get<{ wire_gauge_id: number | string }>('{wire_gauge_id}'),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { Wire, schemaObj, api, selectors };
