/**
 * Report Proposals Team Theme Logo Widget
 */
import * as React from 'react';
import { connect } from 'react-redux';

import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

import { saver } from 'reports/models/theme';

import Image from 'reports/components/helpers/Image';
import { UnstyledLink } from 'reports/components/core/controls';
import { IWidgetEditProps, IWidgetRenderProps, IReportContext, registerWidget } from 'reports/modules/report/widgets';

import UploadableImage from 'reports/modules/files/components/UploadableImage';

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

type IContext = Pick<IReportContext, 'theme' | 'fileLoader'>;
type IEditProps = IWidgetEditProps<object, IContext>;
type ILogoEditProps = IEditProps & IStateProps & IDispatchProps;
type IStateProps = ReturnType<typeof mapStateToProps>;

const ViewLogoComponent: React.FC<IWidgetRenderProps & IStateProps> = ({ src }) =>
    src ? <Image src={src} /> : <span />;

const EditLogoComponent: React.FC<ILogoEditProps> = ({ context, saveTheme, src }) => {
    const isDefaultHSTheme = context.theme.theme_id === -1;
    return isDefaultHSTheme || src ? (
        <Image
            src={src}
            errorMsg={
                isDefaultHSTheme ? (
                    <span>
                        No theme to update logo - create a new theme
                        <UnstyledLink routeName="app.settings.team.themes">
                            &nbsp;<u>here</u>
                        </UnstyledLink>
                        !
                    </span>
                ) : undefined
            }
        />
    ) : (
        <UploadableImage
            fileId={null}
            onUpdate={({ file_id }) => saveTheme(context.theme, { logo_id: file_id })}
            tag="logo"
        />
    );
};

const mapStateToProps = (_state: IAppState, { context }: IEditProps) => ({
    src: context.theme?.logo_id ? context.fileLoader(context.theme.logo_id) : null,
});

const mapDispatchToProps = bindActions({
    saveTheme: (theme, patch) => saver.get(theme).patch(patch),
});

export const ViewLogoComponentContainer = connect(mapStateToProps)(ViewLogoComponent);
export const EditLogoComponentContainer = connect(mapStateToProps, mapDispatchToProps)(EditLogoComponent);

registerWidget('team.logo', {
    Component: ViewLogoComponentContainer,
    EditingComponent: EditLogoComponentContainer,
    metadata: {
        category: 'project',
        dimensions: { h: 200, w: 300 },
        displayName: Translations.widgets.logo_header,
        icon: IconNames.MEDIA,
        ignoreStyle: true,
    },
    dependencies: ['fileLoader', 'theme'],
});

export default ViewLogoComponentContainer;
