import React from 'react';
import { generatePath, useNavigate } from 'react-router';

import * as mod from 'reports/models/module';
import { ModuleLibrary } from 'reports/modules/module/components/ModuleLibrary';

type Props = {
    preview?: JSX.Element;
    selectedModuleId?: number;
};
const Modules = ({ preview, selectedModuleId }: Props) => {
    const navigate = useNavigate();
    const navigateToModule = ({ module_id, defaultCharacterizationId }: mod.Module) => {
        const path = generatePath('/modules/:moduleId/characterizations/:characterizationId/preview', {
            moduleId: module_id.toString(),
            characterizationId: defaultCharacterizationId().toString(),
        });
        navigate(path);
    };
    return (
        <ModuleLibrary
            navigateToModule={navigateToModule}
            preview={preview}
            selectedModuleId={selectedModuleId}
            inlineControls={true}
        />
    );
};

export { Modules };
