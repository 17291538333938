/* eslint-disable new-cap */

import Logger from 'js-logger';
import { cloneDeep } from 'lodash';
import { Vector } from './vector';

const logger = Logger.get('clipper');

// Clipper Needs to be preloaded in the global scope for tests
let ClipperLib = self.ClipperLib;

ClipperLib = patchError(ClipperLib);

/**
 * clipper errors are 'alerts' by default, let's turn this into a warning
 */
function patchError(UnpatchedLib) {
    let Patched = UnpatchedLib;

    if (UnpatchedLib) {
        Patched = cloneDeep(UnpatchedLib);
        Patched.Error = (message) => logger.warn(message);
    }

    return Patched;
}

export function getClipper() {
    return ClipperLib;
}

export async function loadClipper() {
    if (!ClipperLib) {
        ClipperLib = await import('helioscope/libs/clipper/clipper');

        return patchError(ClipperLib);
    }

    return ClipperLib;
}


// clipper uses ints internally, this gives us millimeter accuracy
export const CLIPPER_SCALAR = 1000;
export const CLIPPER_INV_SCALAR = 1 / CLIPPER_SCALAR;

// detail tolerance for rounded polygons when offseting
export const ARC_TOLERANCE = 50;

function scaleClipper(val) {
    return val * CLIPPER_SCALAR;
}

function makeClipper(pointOrPath) {
    if (Array.isArray(pointOrPath)) {
        return pointOrPath.map(makeClipper);
    }

    return {
        X: pointOrPath.x * CLIPPER_SCALAR,
        Y: pointOrPath.y * CLIPPER_SCALAR,
        // Z: pointOrPath.z * CLIPPER_SCALAR,
    };
}

function makeHelioScope(pointOrPath) {
    if (Array.isArray(pointOrPath)) {
        return pointOrPath.map(makeHelioScope);
    }

    return new Vector(
        pointOrPath.X / CLIPPER_SCALAR,
        pointOrPath.Y / CLIPPER_SCALAR,
    );
}

function ensureMultipath(path) {
    if (Array.isArray(path[0])) {
        return path;
    }

    return [path];
}


// /**
//  * ensure that a path is a polygon
//  *
//  * the point delta needs to be set in concert with the cleanDelta above for simplifyying polygon
//  * paths, this is set in meters
//  */
// function ensurePolygon(oldPath, { ptDelta = 0.05, force = false } = {}) {
//     // return oldPath;

//     if (oldPath.length > 2 && force !== true) {
//         return oldPath;
//     } else {
//         return oldPath.concat([oldPath[0]]);
//     }

//     const path = oldPath.slice();
//     const pt = path[path.length - 1];

//     if (path.length === 1) {
//         path.push(pt.add(new Vector(0, ptDelta)));
//         path.push(pt.add(new Vector(ptDelta, 0)));
//     } else {
//         // todo: hilariously untested
//         const span = pt.subtract(path[0]);  // find the vector between the two points
//         const midPoint = span.scale(0.5).add(path[0]);

//         path.push(span.normalize(ptDelta).rotate(90).add(midPoint));
//     }

//     return path;
// }


function _simplifyPaths(paths) {
    return ClipperLib.Clipper.SimplifyPolygons(paths, ClipperLib.PolyFillType.pftNonZero);
}

export function simplifyPaths(paths) {
    return makeHelioScope(_simplifyPaths(makeClipper(paths)));
}

export function cleanPath(path, cleanDelta = 0.1, scale = CLIPPER_SCALAR) {
    // 0.1 should be the appropriate delta in different cases
    // much better would be to scale adjust this factor so that we clean polygons
    // based on their total size, rather than on an absolute metric
    if (path === undefined) {
        return [];
    }

    const rtn = ClipperLib.Clipper.CleanPolygon(makeClipper(path), cleanDelta * scale);

    return rtn.length > 0 ? makeHelioScope(rtn) : path;
}


export function differencePaths(subjPaths, clipPaths) {
    const cpr = new ClipperLib.Clipper();
    const solutionPaths = [];
    const clipType = ClipperLib.ClipType.ctDifference;
    const fillType = ClipperLib.PolyFillType.pftNonZero;

    cpr.AddPaths(ensureMultipath(makeClipper(subjPaths)), ClipperLib.PolyType.ptSubject, true);
    cpr.AddPaths(makeClipper(clipPaths), ClipperLib.PolyType.ptClip, true);
    cpr.Execute(clipType, solutionPaths, fillType, fillType);

    return makeHelioScope(solutionPaths);
}

export function unionPaths(paths) {
    const cpr = new ClipperLib.Clipper();
    const solutionPaths = [];
    const clipType = ClipperLib.ClipType.ctUnion;
    const fillType = ClipperLib.PolyFillType.pftNonZero;

    cpr.AddPaths(makeClipper(paths), ClipperLib.PolyType.ptSubject, true);
    cpr.Execute(clipType, solutionPaths, fillType, fillType);

    return makeHelioScope(solutionPaths[0] || []);
}

export function unionPathsMulti(paths) {
    const cpr = new ClipperLib.Clipper();
    const solutionPaths = [];
    const clipType = ClipperLib.ClipType.ctUnion;
    const fillType = ClipperLib.PolyFillType.pftNonZero;

    cpr.AddPaths(makeClipper(paths), ClipperLib.PolyType.ptSubject, true);
    cpr.Execute(clipType, solutionPaths, fillType, fillType);

    return makeHelioScope(solutionPaths);
}


export function intersectPathsMultiOpen(subjSegs, clipPath) {
    const cpr = new ClipperLib.Clipper();
    const solutionTree = new ClipperLib.PolyTree();
    const clipType = ClipperLib.ClipType.ctIntersection;
    const fillType = ClipperLib.PolyFillType.pftNonZero;

    cpr.AddPaths([makeClipper(subjSegs)], ClipperLib.PolyType.ptSubject, false);
    cpr.AddPaths([makeClipper(clipPath)], ClipperLib.PolyType.ptClip, true);
    cpr.Execute(clipType, solutionTree, fillType, fillType);

    const solutionPaths = ClipperLib.Clipper.OpenPathsFromPolyTree(solutionTree);

    if (solutionPaths.length > 0) {
        return makeHelioScope(solutionPaths);
    }

    return [];
}

export function intersectPathsMulti(subjPath, clipPath) {
    const cpr = new ClipperLib.Clipper();
    const solutionPaths = [];
    const clipType = ClipperLib.ClipType.ctIntersection;
    const fillType = ClipperLib.PolyFillType.pftNonZero;

    cpr.AddPaths(ensureMultipath(makeClipper(subjPath)), ClipperLib.PolyType.ptSubject, true);
    cpr.AddPaths(ensureMultipath(makeClipper(clipPath)), ClipperLib.PolyType.ptClip, true);
    cpr.Execute(clipType, solutionPaths, fillType, fillType);

    return makeHelioScope(solutionPaths);
}

export function bufferPolygonSingle(xyPath, bufferDistance, { tolerance = ARC_TOLERANCE } = {}) {
    const offset = new ClipperLib.ClipperOffset();

    // in theory ClipperLib.JS.Lighten() could provide a similar functionality, but it seems much
    // less robust, particularly for 'straight line' keepouts where it simplifies the points away
    offset.ArcTolerance = tolerance;

    const solution = [];
    const path = makeClipper(xyPath);


    if (xyPath.length === 2) {
        offset.AddPath(path, ClipperLib.JoinType.jtRound, ClipperLib.EndType.etOpenPolygon);
    } else {
        const simplified = ClipperLib.Clipper.SimplifyPolygon(path, ClipperLib.PolyFillType.pftNonZero);
        offset.AddPaths(simplified, ClipperLib.JoinType.jtRound, ClipperLib.EndType.etClosedPolygon);
    }

    offset.Execute(solution, scaleClipper(bufferDistance));

    if (solution.length > 0) {
        return makeHelioScope(solution[0]);
    }
    return xyPath;
}


export function bufferPolygonMulti(xyPath, bufferDistance, { tolerance = ARC_TOLERANCE } = {}) {
    const offset = new ClipperLib.ClipperOffset();

    // in theory ClipperLib.JS.Lighten() could provide a similar functionality, but it seems much
    // less robust, particularly for 'straight line' keepouts where it simplifies the points away
    offset.ArcTolerance = tolerance;

    const solution = [];
    const path = makeClipper(xyPath);


    if (xyPath.length === 2) {
        offset.AddPath(path, ClipperLib.JoinType.jtRound, ClipperLib.EndType.etOpenPolygon);
    } else {
        const simplified = ClipperLib.Clipper.SimplifyPolygon(path, ClipperLib.PolyFillType.pftNonZero);
        offset.AddPaths(simplified, ClipperLib.JoinType.jtRound, ClipperLib.EndType.etClosedPolygon);
    }

    offset.Execute(solution, scaleClipper(bufferDistance));

    return makeHelioScope(solution);
}

export function pointInPolygon(pt, polygon) {
    // clipper PointIn Polygon returns 0 if false, 1 if true, -1 if on boundar
    return ClipperLib.Clipper.PointInPolygon(makeClipper(pt), makeClipper(polygon)) !== 0;
}


export function simplify(path) {
    return makeHelioScope(ClipperLib.Clipper.SimplifyPolygon(makeClipper(path), ClipperLib.PolyFillType.pftNonZero));
}
