/* tslint:disable:variable-name function-name */
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from './schema';
import { IProjectUsage } from './team';

class TeamLimitsAndUsage extends BaseClass {
    team_id: number;
    project_count: number;
    trial_project_limit: number;
    trial_limits: IProjectUsage | null;
    subscription_limits: IProjectUsage | null;
    admin_limits: number | null;

    constructor(data) {
        super(TeamLimitsAndUsage.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({});
}

const schemaObj = schema.addObject(TeamLimitsAndUsage, 'team_limits_and_usage', {
    idName: 'team_id',
});
export const endpoint = ReduxEndpoint.fromSchema('/api/teams/', schemaObj);

const api = {
    get: endpoint.get<{ team_id: number }>('{team_id}/limits_and_usage'),
};

const selectors = {
    byId: schemaObj.selectById,
};

export { TeamLimitsAndUsage, api, schemaObj, selectors };
