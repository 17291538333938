import * as React from 'react';
import { connect } from 'react-redux';
import * as Color from 'color.js';

import { InputGroup, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';
import { DeepPartial } from 'reports/utils/types';
import { RouterStateLock } from 'reports/utils/router';

import { PrimaryButton, Button as SecondaryButton } from 'reports/components/core/controls';
import { FormGroup } from 'reports/components/core/forms';
import { Flex } from 'reports/components/core/containers';

import * as s3file from 'reports/models/s3file';
import { api, IThemeForm, saver, Theme } from 'reports/models/theme';

import { Toaster } from 'reports/modules/Toaster';
import FileDialog from 'reports/modules/files/components/FileDialog';
import { DescriptionFormGroup } from 'reports/modules/settings/common';

import ThemeColorPicker, { SwatchEl } from './ThemeColorPicker';
import ThemeLogo, { LogoContainer } from './ThemeLogo';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const EditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;

    &.disabled {
        ${LogoContainer},
        ${SwatchEl} {
            pointer-events: none;
        }
    }
`;

export const PALETTE_COUNT: number = 6;

interface IDispatchProps {
    createTheme: (newTheme: IThemeForm) => Promise<Theme>;
    saveTheme: (theme: Theme, patch: DeepPartial<Theme>) => Promise<Theme>;
}

interface IState {
    showFileDialog: boolean;
    theme: Theme;
}

interface IThemeEditorProps {
    onSave: (theme: Theme, isNewTheme: boolean) => void;
    cancelNewTheme: () => void;
    theme: Theme;
    isNewTheme: boolean;
}

class ThemeEditor extends React.PureComponent<IThemeEditorProps & IDispatchProps, IState> {
    state: IState = {
        showFileDialog: false,
        theme: this.props.theme,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.resetEditor();
        }
    }

    render() {
        const { showFileDialog, theme } = this.state;
        return (
            <>
                <EditorContainer>
                    <RouterStateLock
                        title="Save Theme?"
                        prompt="You have unsaved changes to your current theme."
                        cancellable={true}
                        showExitPrompt={theme !== this.props.theme}
                        onSave={this.saveTheme}
                        onDontSave={() => {
                            this.props.isNewTheme ? this.resetToNonEmptyTeamTheme() : this.resetEditor();
                        }}
                    />

                    <FormGroup inline bold label="Name">
                        <InputGroup
                            placeholder="Untitled Theme"
                            value={theme.name}
                            onChange={(event) => this.updateTheme({ name: event.target.value })}
                            fill={true}
                        />
                    </FormGroup>

                    <FormGroup inline bold label="Colors">
                        <DescriptionFormGroup desc="Customize your color theme to be used across your team's projects.">
                            <ThemeColorPicker
                                theme={theme}
                                updateTheme={this.updateTheme}
                                style={{ height: 250, width: '90%' }}
                            />
                        </DescriptionFormGroup>
                    </FormGroup>

                    <FormGroup inline bold label="Logo">
                        <DescriptionFormGroup desc="This logo will be used across various project reports and available as a proposal widget.">
                            <div style={{ width: 400 }}>
                                <ThemeLogo
                                    fileId={theme.logo_id}
                                    updateLogo={({ file_id }) => this.updateLogo(file_id)}
                                    deleteLogo={() => this.updateTheme({ logo_id: undefined })}
                                    handleClick={() => this.toggleFileDialog(true)}
                                />
                            </div>
                        </DescriptionFormGroup>
                    </FormGroup>
                </EditorContainer>

                <Flex.Container align={Flex.Align.RIGHT}>
                    <SecondaryButton
                        text="Cancel"
                        onClick={() => {
                            this.props.isNewTheme ? this.resetToNonEmptyTeamTheme() : this.resetEditor();
                        }}
                    />
                    <PrimaryButton
                        icon={IconNames.FLOPPY_DISK}
                        text="Save"
                        onClick={() => this.saveTheme()}
                        disabled={theme === this.props.theme}
                    />
                </Flex.Container>

                {showFileDialog ? (
                    <FileDialog
                        fileType="logo"
                        selectedFile={theme.logo_id}
                        filterByTags={['logo']}
                        onAddNew={this.handleUpload}
                        onSelect={(fileId) => this.updateLogo(fileId)}
                        onSelectFileDelete={() => this.updateTheme({ logo_id: undefined })}
                        onClose={() => this.toggleFileDialog(false)}
                        isOpen={this.state.showFileDialog}
                        allowDelete={false}
                    />
                ) : null}
            </>
        );
    }

    async generateThemeColors(fileId: number) {
        const { get_url: url } = await s3file.api.get.request({
            file_id: fileId,
        });

        // Config details https://github.com/luukdv/color.js
        const colorMain = new Color(url, {
            amount: 2,
            format: 'hex',
            sample: 5,
            group: 60,
        });
        const colorPalette = new Color(url, {
            amount: PALETTE_COUNT,
            format: 'hex',
            sample: 5,
            group: 30,
        });
        const isSingleColor = (colorResult) => typeof colorResult === 'string' && !Array.isArray(colorResult);

        colorMain.mostUsed((colors1) => {
            const mainColors = isSingleColor(colors1) ? [colors1] : colors1;

            colorPalette.mostUsed((colors2) => {
                const colors = isSingleColor(colors2) ? [colors2] : colors2;

                this.setState((prevState) => ({
                    theme: {
                        ...prevState.theme,
                        palette: { colors },
                        primary_color: mainColors[0],
                        secondary_color: mainColors[1],
                    },
                }));
            });
        });
    }

    handleUpload = async (file: s3file.S3File) => {
        await this.updateLogo(file.file_id);
        this.toggleFileDialog(false);
    };

    resetToNonEmptyTeamTheme() {
        this.props.cancelNewTheme();
        this.setState({ theme: this.props.theme });
    }

    resetEditor() {
        this.setState({
            theme: this.props.theme,
        });
    }

    saveTheme = async () => {
        const { theme } = this.state;

        if (theme.hasOwnProperty('theme_id')) {
            // Save updated theme
            this.props.saveTheme(this.props.theme, theme);
            this.props.onSave(theme, false);
        } else {
            try {
                // Create new theme
                const newTheme = await this.props.createTheme(theme);
                this.props.onSave(newTheme, true);
            } catch (exc) {
                Toaster.show({
                    message: exc['name']
                        ? 'A theme name is required to create a new theme.'
                        : 'Error creating new theme, please try again.',
                    intent: Intent.DANGER,
                });
                return;
            }
        }
    };

    themeIsEmpty() {
        const { theme } = this.state;
        return (
            !theme ||
            (!theme.logo_id &&
                !theme.primary_color &&
                !theme.primary_color &&
                (!theme.palette || !theme.palette.colors.length))
        );
    }

    toggleFileDialog = (showFileDialog: boolean) => this.setState({ showFileDialog });

    updateLogo = async (fileId: number) => {
        // Autogenerate palette for an empty theme
        if (this.themeIsEmpty()) {
            this.generateThemeColors(fileId);
        }

        this.setState((prevState) => ({
            theme: {
                ...prevState.theme,
                logo_id: fileId,
            },
        }));
    };

    updateTheme = (delta: DeepPartial<Theme>) => {
        const theme = { ...this.state.theme, ...delta } as Theme;
        this.setState({ theme });
    };
}

const mapDispatchToProps = bindActions({
    createTheme: (newTheme) => api.create(newTheme),
    saveTheme: (theme, patch) => saver.get(theme).patch(patch),
});

export default connect(null, mapDispatchToProps)(ThemeEditor);
