import * as React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { IAppState } from 'reports/store';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';

import * as pd from 'reports/models/power_device';
import * as pdChar from 'reports/models/power_device/PowerDeviceCharacterization';
import * as s3file from 'reports/models/s3file';

import * as auth from 'reports/modules/auth';
import { Toaster } from 'reports/modules/Toaster';
import { actions as uploadActions } from 'reports/modules/files/uploads';
import { DropzoneContainer } from 'reports/modules/files/components/DropzoneContainer';
import { PowerDevicesList } from 'reports/modules/power_device/components/PowerDevicesList';
import { PowerDeviceUploadDialog } from 'reports/modules/power_device/components/PowerDeviceUploadDialog';

import { BYTES_PER_MEGABYTE } from 'reports/utils/units';

type Props = {
    preview?: React.ReactNode;
    powerDeviceId?: number;
    navigateToCharacterization: (powerDeviceCharacterization: pd.PowerDeviceCharacterization) => void;
    navigateToDefaultCharacterization: (powerDevice: pd.PowerDevice) => void;
    inlineControls?: boolean;
};

const PowerDeviceLibrary = React.memo(
    ({
        preview,
        powerDeviceId,
        navigateToCharacterization,
        navigateToDefaultCharacterization,
        inlineControls,
    }: Props) => {
        const dropzoneRef = React.createRef<Dropzone>();

        const [uploadedCharacterization, setUploadedCharacterization] = React.useState<
            pd.PowerDeviceCharacterization | undefined
        >();
        const [uploadedS3File, setUploadedS3File] = React.useState<s3file.S3File | undefined>();

        const user = useSelector((state) => auth.selectors.getUser(state as IAppState)!);
        const refreshItem = useSelector(
            (state) => (item) => pd.selectors.byObject(state as IAppState, item, { team: true }) || item,
        );

        const dispatch = useDispatch();
        const loadItems = (opts) => dispatch(pd.api.index(opts));
        const uploadFile = ({ file, meta }) => dispatch(uploadActions.uploadFileWithProgress({ file, meta }));
        const tryParse = ({ file_id }) => dispatch(pdChar.api.tryParse({ file_id }));

        const onDrop = async (acceptedFiles: File[], rejectedFiles: File[], event) => {
            event.stopPropagation();
            if (rejectedFiles.length > 0) {
                Toaster.show({
                    message: `Error uploading ${rejectedFiles[0].name}: invalid file type.`,
                    intent: Intent.DANGER,
                });
                return;
            }
            const file = acceptedFiles[0];
            if (!file?.name.toLowerCase().endsWith('.ond')) {
                Toaster.show({
                    message: `Error uploading ${file.name}: we expected a filename ending in .ond`,
                    intent: Intent.DANGER,
                });
                return;
            }
            const uploadedS3File = (await uploadFile({
                file,
                meta: { tags: ['power device'] },
            })) as s3file.S3File;
            try {
                const characterization = await tryParse({
                    file_id: uploadedS3File.file_id,
                });
                setUploadedS3File(uploadedS3File);
                setUploadedCharacterization(characterization);
            } catch (err) {
                Toaster.show({
                    message: `Error parsing ${file.name}`,
                    intent: Intent.DANGER,
                });
            }
        };

        return (
            <LibraryMain
                resourceIdName="power_device_id"
                loadItems={loadItems}
                refreshItem={refreshItem}
                inlineControls={inlineControls}
                views={[
                    {
                        view: (
                            <PowerDevicesList navigateToDefaultCharacterization={navigateToDefaultCharacterization} />
                        ),
                        icon: IconNames.LIST,
                        id: 'power-device-list',
                    },
                ]}
                id="power-devices-views"
                quickFilters={[
                    {
                        name: 'favorite',
                        icon: IconNames.STAR,
                        text: 'Favorited',
                    },
                    {
                        name: 'team_id',
                        icon: IconNames.PEOPLE,
                        text: `Only Show My Team`,
                        value: user.team.team_id,
                    },
                    {
                        name: 'device_type',
                        text: `Only Show Inverters`,
                        value: 'inverter',
                    },
                    {
                        name: 'device_type',
                        text: `Only Show Optimizers`,
                        value: 'optimizer',
                    },
                ]}
                preview={preview}
                contextBarControls={
                    <DropzoneContainer
                        ref={dropzoneRef}
                        onDrop={onDrop}
                        accept=".ond, .OND"
                        maxSize={10 * BYTES_PER_MEGABYTE}
                        disableClick={true}
                    >
                        <CreateButton
                            icon={IconNames.UPLOAD}
                            text="Upload Inverter"
                            onClick={() => dropzoneRef.current!.open()}
                            fill={true}
                        />
                        {uploadedCharacterization && (
                            <PowerDeviceUploadDialog
                                navigateToCharacterization={navigateToCharacterization}
                                uploadedCharacterization={uploadedCharacterization}
                                uploadedS3File={uploadedS3File!}
                            />
                        )}
                    </DropzoneContainer>
                }
                selectedItemId={powerDeviceId}
            />
        );
    },
);

export { PowerDeviceLibrary };
