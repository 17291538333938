import { mapValues } from 'lodash';

import { Colors } from '@blueprintjs/core';
import { createSelector } from 'reselect';

import { DEFAULT_PALETTE, HELIOSCOPE_ORANGE } from 'reports/styles/global';
import { IAppState } from 'reports/types';

import * as styles from 'reports/modules/report/styles';

import { Theme } from 'reports/models/theme';
import * as proj from 'reports/models/project';
import * as team from 'reports/models/team';

import ThemeSelect, { IThemeSelect } from './ThemeSelect';

import { IReportContext } from 'reports/modules/report/widgets';

type IThemeKey = keyof Theme;

// Default theme
const DEFAULT_THEME = new Theme({
    theme_id: -1,
    name: 'HelioScope Default',
    team_id: -1,
    primary_color: HELIOSCOPE_ORANGE,
    secondary_color: Colors.COBALT3,
    palette: { colors: DEFAULT_PALETTE },
});

/**
 * Map any styleMap properties to their actual style value.
 * @param styles - stored styles
 * @param styleMap - report style map
 * @returns Compiled style map
 */
export function parseColorMap(styles: React.CSSProperties, styleMap: styles.IStyleMap): React.CSSProperties {
    return mapValues(styles, (attrVal) => {
        const styleVal = attrVal != null && styleMap[attrVal];
        return (styleVal ? styleVal.color : attrVal) as any;
    });
}

const getTeamTheme = (state: IAppState, t?: team.Team | null) => (t ? team.selectors.themeSelector(state, t) : null);

const getThemeFromProject = createSelector(
    (state, { project }: IReportContext | { project: proj.Project }) => {
        const team = proj.selectors.teamSelector(state, project);
        return getTeamTheme(state, team);
    },
    (projectTheme) => (projectTheme ? projectTheme : (DEFAULT_THEME as Theme)),
);

const selectors = {
    getTeamTheme,
    getThemeFromProject,
};

export { Theme, ThemeSelect, IThemeKey, DEFAULT_THEME, selectors };
export type { IThemeSelect };
