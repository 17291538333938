import * as React from 'react';

import { RouteComponent } from 'reports/routing';

import { User } from 'reports/models/user';

import { UserOverviewForm } from '../components/UserOverviewForm';
import { UserPreferences } from './UserPreferences';
import { SettingsPane } from 'reports/modules/settings/common';

interface Props {
    user: User;
}

const UserSettingsViews = React.memo(({ user }: Props) => {
    return (
        <SettingsPane
            content={
                <>
                    <RouteComponent name="app.settings.user">
                        <UserOverviewForm user={user} />
                    </RouteComponent>
                    <RouteComponent name="app.settings.user.changepassword">
                        <UserOverviewForm user={user} changePassword />
                    </RouteComponent>
                    <RouteComponent name="app.settings.user.preferences">
                        <UserPreferences user={user} />
                    </RouteComponent>
                </>
            }
        />
    );
});

export default UserSettingsViews;
