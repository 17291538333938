import * as React from 'react';
import { connect } from 'react-redux';

import { createSelector } from 'reselect';

import { Project } from 'reports/models/project';
import * as finProjTemp from 'reports/models/project_financial_template';
import { actions as finActions } from 'reports/modules/financials/state';
import { actions as projActions, selectors } from 'reports/modules/project';
import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

interface IOwnProps {
    project: Project | undefined;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

class ProjectFinancialsCalculator extends React.Component<IOwnProps & IStateProps & IDispatchProps> {
    async componentDidMount() {
        this.calculate();
    }

    componentDidUpdate() {
        this.calculate();
    }

    calculate() {
        const { primaryProjectFinancialTemplate, primarySimulation, project, userConsumption, pftPatches } = this.props;

        if (
            project &&
            userConsumption &&
            primaryProjectFinancialTemplate &&
            primarySimulation &&
            primarySimulation.complete()
        ) {
            // configuration_data and template_data are the only FinancialConfiguration fields that affect the
            // financial simulation. If the patches only include other stuff, like "name", don't bother waiting for
            // the patches to flush; just immediately run the financial simulation.
            if (pftPatches && (pftPatches.configuration_data || pftPatches.template_data)) {
                this.props.setRefreshing(primaryProjectFinancialTemplate);
            } else {
                this.props.queueRun(primaryProjectFinancialTemplate, project, primarySimulation);
            }
        } else if (primaryProjectFinancialTemplate) {
            this.props.clearOutput(primaryProjectFinancialTemplate);
        }
    }

    render() {
        return null;
    }
}

const selectPFTPatches = createSelector(
    (state) => state,
    selectors.primaryProjectFinancialTemplate,
    (state, pft: finProjTemp.ProjectFinancialTemplate) => {
        if (!pft) return null;
        return finProjTemp.selectors.patchesById(state, pft.project_financial_template_id);
    },
);

const mapStateToProps = (state: IAppState, ownProps: { project: Project }) => ({
    primaryProjectFinancialTemplate: selectors.primaryProjectFinancialTemplate(state, ownProps),
    primaryDesign: selectors.primaryDesign(state, ownProps),
    primaryScenario: selectors.primaryScenario(state, ownProps),
    primarySimulation: selectors.primarySimulation(state, ownProps),
    userConsumption: ownProps.project && ownProps.project.user_consumption,
    pftPatches: selectPFTPatches(state, ownProps),
});

const mapDispatchToProps = bindActions(() => ({
    setRefreshing: (config) => finActions.setRefreshing(config),
    queueRun: (config, project, sim) => finActions.queueRun(config, project, sim),
    clearOutput: (config) => finActions.clearOutput(config),
    loadFieldComponents: (designId) => projActions.loadFieldComponents(designId),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFinancialsCalculator);
