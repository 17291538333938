import * as React from 'react';

import moment from 'moment';

import { useDispatch, useStore } from 'react-redux';

import { Intent } from '@blueprintjs/core';

import Callout from 'reports/components/core/controls/Callout';

import { Subscription } from 'reports/models/subscription';
import { api as teamAPI, Team } from 'reports/models/team';

import { canManageBilling } from 'reports/modules/auth/permissions';

import { selectors as authSelectors } from 'reports/modules/auth';
import { actions as subActions } from 'reports/modules/subscription';
import { useWatchSubscription } from './billing/Hooks';

const STRIPE_INVOICE_DIMENSIONS = 'width=485,height=730';

const InvoiceLink = ({ invoiceURL, text }: { invoiceURL: string; text: string }) => (
    <a
        onClick={() => {
            window.open(invoiceURL, 'popup', STRIPE_INVOICE_DIMENSIONS);
        }}
    >
        {text}
    </a>
);

const CalloutInvoiceLinks = ({ subscription }) => (
    <InvoiceLink
        invoiceURL={subscription.payment_failure_info.latest_invoice_url}
        text={'View Invoice or make a payment'}
    />
);

// userCpe differs from subscriptionCpe when the user's trial_period_end is after the subscription's end_date.
// In this case, we are granting a user w/ an expired subscription extended access via trial.
const ExpiredCallout = ({ userCpe, subscriptionCpe }: { userCpe: moment.Moment; subscriptionCpe: moment.Moment }) => (
    <Callout intent={Intent.DANGER}>
        Your subscription expired
        {subscriptionCpe && ` on ${subscriptionCpe.format('LL')}`}.{' '}
        {userCpe.isAfter(subscriptionCpe)
            ? `You are on a trial plan until ${userCpe.format('LL')}.`
            : 'Please purchase a new subscription to renew your access to HelioScope.'}
    </Callout>
);

const StatusCallout = ({
    subscription,
    userCpe,
    legacySubEnd,
}: {
    subscription?: Subscription;
    userCpe: moment.Moment;
    legacySubEnd?: moment.Moment;
}) => {
    if (!subscription) {
        return !!legacySubEnd ? <ExpiredCallout userCpe={userCpe} subscriptionCpe={legacySubEnd} /> : <></>;
    }

    const { cleaned_status, current_period_end, has_failed_payment, is_expired, is_pay_by_invoice } = subscription;

    if (is_pay_by_invoice && cleaned_status === 'unpaid') {
        return (
            <Callout intent={Intent.DANGER}>
                Your account has been suspended due to non-payment. If you don't take any action, you will lose access
                to HelioScope. <CalloutInvoiceLinks subscription={subscription} />.
            </Callout>
        );
    }

    if (is_expired && cleaned_status === 'canceled') {
        return <ExpiredCallout userCpe={userCpe} subscriptionCpe={current_period_end} />;
    }

    if (has_failed_payment && is_pay_by_invoice) {
        return (
            <Callout intent={Intent.DANGER}>
                Your annual payment is overdue. If you don't take any action, you will lose access to HelioScope.{' '}
                <CalloutInvoiceLinks subscription={subscription} />.
            </Callout>
        );
    }

    if (cleaned_status === 'initialized') {
        return (
            <Callout intent={Intent.WARNING}>
                A subscription has been created but has not yet been activated. Pay now to activate your subscription.
            </Callout>
        );
    }

    if (has_failed_payment && subscription.isV2) {
        const now = subscription.now();
        const interval = subscription.plan_type === 'year' ? 'annual' : 'monthly';
        const daysLeft = moment(subscription.current_period_start)
            .add(subscription.payment_failure_info.has_3DS_failure ? 15 : 14, 'days')
            .diff(now, 'days');

        return (
            <Callout intent={Intent.DANGER}>
                Your {interval} payment could not be processed. You have {daysLeft <= 0 ? 0 : daysLeft} days until your
                subscription is canceled. <CalloutInvoiceLinks subscription={subscription} />.
            </Callout>
        );
    }

    return <></>;
};

const BillingAdminCallout = React.memo(() => {
    const dispatch = useDispatch();
    const store = useStore();

    const [team, setTeam] = React.useState<Team>();
    const [subscription, setSubscription] = React.useState<Subscription>();
    const [hasLegacySub, setHasLegacySub] = React.useState<boolean>();

    const user = authSelectors.getUser(store.getState());
    const getTeam = (teamId: number) => dispatch(teamAPI.get({ team_id: teamId }));
    const getSubscription = (subscription: Subscription | null) =>
        subscription ? dispatch(subActions.loadFullSubscription(subscription)) : Promise.resolve(undefined);

    if (!user) {
        return <></>;
    }

    const [setWatchedSubID] = useWatchSubscription(null, 'invoice.paid', async () =>
        setSubscription(await getSubscription(subscription!)),
    );

    React.useEffect(() => {
        (async () => {
            const team = await getTeam(user.team_id);
            setTeam(team);
            setSubscription(await getSubscription(team.latest_subscription));
            setWatchedSubID(team.latest_subscription?.external_id || null);
            setHasLegacySub(!!user.legacy_subscription_data);
        })();
        return () => {};
    }, []);

    const showCallout = (!!subscription || hasLegacySub) && canManageBilling(user);

    if (!team || (!subscription && !hasLegacySub) || !showCallout) {
        return <></>;
    }

    return (
        <StatusCallout
            subscription={subscription}
            userCpe={user.current_period_end}
            legacySubEnd={user.latest_legacy_subscription_end_date}
        />
    );
});

export { BillingAdminCallout };
