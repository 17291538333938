import styled from 'styled-components';

export default styled.div`
    // https://stackoverflow.com/questions/43724029/resize-highchart-based-on-container-changing-not-window-resize
    // Long term, consider switching from react-highcharts to highcharts-react-official, which should have better
    // support for automatic resizing. https://www.npmjs.com/package/highcharts-react-official
    .highcharts-container,
    .highcharts-container svg {
        width: 100% !important; // override style attribute of container and width attribute of svg.
    }
`;
