import * as React from 'react';
import classNames from 'classnames';
import { get, noop, sum } from 'lodash';
import { Classes, Colors, Icon, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import PrimaryButton from 'reports/components/core/controls/PrimaryButton';

import BasicTable, { IBasicTableProps } from './BasicTable';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const StyledBasicTable = styled(BasicTable)`
    thead tr th.sortable {
        cursor: pointer;

        :hover {
            background-color: ${Colors.LIGHT_GRAY2};
        }

        :hover,
        &.active {
            color: ${Colors.BLUE3};

            .${Classes.ICON} {
                color: ${Colors.BLUE3};
            }
        }
    }

    &.row-selectable {
        tbody tr:not(.message) {
            cursor: pointer;

            &:hover {
                background-color: ${Colors.LIGHT_GRAY5};
            }

            &.row-selected {
                background-color: ${Colors.LIGHT_GRAY4};
                font-weight: bold;
            }
        }
    }
`;

const HeaderCellContent = styled.div`
    display: inline-flex;
    align-items: center;
`;

const SortIcon = styled(Icon)`
    color: ${Colors.GRAY1};
    padding-left: 6px;
`;

const Message = styled.div`
    font-size: 16px;
    font-weight: 600;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

enum SortType {
    ALPHA = 'alpha',
    NUMERIC = 'numeric',
    OTHER = 'other',
}

interface TableSortConfig<T> {
    property: keyof T;
    order: SortOrder;
}

const SORT_ORDER_DEFAULTS = {
    [SortType.ALPHA]: SortOrder.ASC,
    [SortType.NUMERIC]: SortOrder.DESC,
    [SortType.OTHER]: SortOrder.DESC,
};

const SORT_ICONS = {
    [SortOrder.ASC]: {
        [SortType.ALPHA]: IconNames.SORT_ALPHABETICAL,
        [SortType.NUMERIC]: IconNames.SORT_NUMERICAL,
        [SortType.OTHER]: IconNames.SORT_ASC,
    },
    [SortOrder.DESC]: {
        [SortType.ALPHA]: IconNames.SORT_ALPHABETICAL_DESC,
        [SortType.NUMERIC]: IconNames.SORT_NUMERICAL_DESC,
        [SortType.OTHER]: IconNames.SORT_DESC,
    },
};

export enum ColWidth {
    SMALL = 0.6,
    MEDIUM = 1,
    LARGE = 2,
}

interface ColumnSortConfig<T> {
    name: keyof T;
    type?: SortType;
}

export interface IColumn<T> {
    renderCell: (item: T) => JSX.Element;
    headerText: string;
    colWidth?: ColWidth;
    hidden?: boolean;
    sort?: ColumnSortConfig<T>;
}

interface RowSelectConfig<T> {
    itemSelected: (item: T) => boolean;
    onRowClick: (item: T) => void;
}

interface IOwnProps<T> {
    columns: IColumn<T>[];
    items: T[] | Promise<T[]>;
    onSortChange?: (tableSort: TableSortConfig<T>) => void;
    rowSelectConfig?: RowSelectConfig<T>;
    onRefresh?: () => void;
}

interface IState<T> {
    loadedItems: T[];
    loading: boolean;
    error: boolean;
    tableSortConfig?: TableSortConfig<T>;
}

type IDataTableProps<T> = Omit<IBasicTableProps, 'children'> & IOwnProps<T>;

const isColumnSortActive = function (tableSortConfig, columnSort) {
    return tableSortConfig && columnSort && tableSortConfig.property === columnSort.name;
};

const getColumnSortOrder = function (tableSortConfig, columnSort) {
    const sortActive = isColumnSortActive(tableSortConfig, columnSort);
    return sortActive && tableSortConfig ? tableSortConfig.order : undefined;
};

const getSortIcon = function (tableSortConfig, columnSort) {
    const { type: sortType = SortType.OTHER } = columnSort;

    const sortActive = isColumnSortActive(tableSortConfig, columnSort);
    const order = getColumnSortOrder(tableSortConfig, columnSort);
    return sortActive ? get(SORT_ICONS, `${order}.${sortType}`) : IconNames.DOUBLE_CARET_VERTICAL;
};

const getNextTableSortConfig = function (tableSortConfig, columnSort) {
    const { type: sortType = SortType.OTHER } = columnSort;

    const order = getColumnSortOrder(tableSortConfig, columnSort);

    const nextOrder = order
        ? order === SortOrder.DESC
            ? SortOrder.ASC
            : SortOrder.DESC
        : SORT_ORDER_DEFAULTS[sortType];

    return { order: nextOrder, property: columnSort.name };
};

const MessageBody = ({ shownCols, children }) => (
    <tr className="message">
        <td colSpan={shownCols.length}>
            <Message>{children}</Message>
        </td>
    </tr>
);

const BodyRow = ({ item, columns, rowSelectConfig }) => {
    const selected = rowSelectConfig?.itemSelected(item);
    const onRowClick = rowSelectConfig?.onRowClick || noop;

    return (
        <tr className={classNames({ 'row-selected': selected })} onClick={() => onRowClick(item)}>
            {columns.map((column, idx) => (
                <td key={idx}>{column.renderCell(item)}</td>
            ))}
        </tr>
    );
};

const HeaderCell = ({ tableSortConfig, column, onSortChange }) => {
    const columnSort = column.sort;
    const sortable = !!columnSort;

    return (
        <th
            className={classNames({
                sortable,
                active: isColumnSortActive(tableSortConfig, columnSort),
            })}
            onClick={() => sortable && onSortChange(getNextTableSortConfig(tableSortConfig, columnSort))}
        >
            <HeaderCellContent>
                <span>{column.headerText}</span>
                {sortable && <SortIcon icon={getSortIcon(tableSortConfig, columnSort)} iconSize={14} />}
            </HeaderCellContent>
        </th>
    );
};

/**
 * A more feature-rich table than BasicTable, implementing column configurations,
 * sorting, row selection, and loading/error states.
 *
 * @param columns column configurations
 * @param items the objects to render as rows in the table
 * @param onSortChange called with a new sort config when the sort is changed
 * @param rowSelectConfig determines row selection behavior
 * @param onRefresh called when there is an error and the user manually clicks "Reload"
 */
class DataTable<T> extends React.PureComponent<IDataTableProps<T>, IState<T>> {
    state: IState<T> = {
        loading: false,
        loadedItems: [],
        error: false,
    };

    componentDidMount() {
        this.handleItemsLoad();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.items !== this.props.items) {
            this.handleItemsLoad();
        }
    }

    render() {
        const { columns, centered, sticky, tableTheme, hasScrollContainer, width, rowSelectConfig, onRefresh } =
            this.props;
        const { error, loading, loadedItems, tableSortConfig } = this.state;

        const shownCols = columns.filter((col) => !col.hidden);

        return (
            <StyledBasicTable
                centered={centered}
                tableTheme={tableTheme}
                width={width}
                sticky={sticky}
                hasScrollContainer={hasScrollContainer}
                className={classNames({
                    'row-selectable': rowSelectConfig,
                })}
            >
                <colgroup>
                    {this.colWidthPcts(shownCols).map((widthPct, i) => (
                        <col style={{ width: `${widthPct}%` }} key={i} />
                    ))}
                </colgroup>
                <thead>
                    <tr>
                        {shownCols.map((column, idx) => (
                            <HeaderCell
                                key={idx}
                                column={column}
                                tableSortConfig={tableSortConfig}
                                onSortChange={(nextTableSortConfig) => {
                                    this.setState({
                                        tableSortConfig: nextTableSortConfig,
                                    });
                                    this.props.onSortChange && this.props.onSortChange(nextTableSortConfig);
                                }}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {!loading &&
                        loadedItems.map((item, idx) => (
                            <BodyRow item={item} columns={shownCols} key={idx} rowSelectConfig={rowSelectConfig} />
                        ))}
                    {loading && (
                        <MessageBody shownCols={shownCols}>
                            <Spinner />
                        </MessageBody>
                    )}
                    {!loading && !error && loadedItems.length === 0 && (
                        <MessageBody shownCols={shownCols}>There are no results to display.</MessageBody>
                    )}
                    {!loading && error && (
                        <MessageBody shownCols={shownCols}>
                            <div>
                                <p>There was a problem loading the results.</p>
                                {onRefresh && (
                                    <p>
                                        <PrimaryButton icon={IconNames.REFRESH} onClick={() => this.refresh()}>
                                            Reload
                                        </PrimaryButton>
                                    </p>
                                )}
                            </div>
                        </MessageBody>
                    )}
                </tbody>
            </StyledBasicTable>
        );
    }

    refresh() {
        if (this.props.onRefresh) {
            this.setState({ error: false });
            this.props.onRefresh();
        }
    }

    handleItemsLoad() {
        this.setState({ loading: true, error: false });
        const currentItemsPromise = this.props.items;

        Promise.resolve(this.props.items)
            .then((items) => {
                // If a new promise is set on items before the previous one has resolved, this ensures that we
                // correctly stay in the loading state until the most recent promise resolves. Without this,
                // React sometimes decides not to render the loading state
                if (currentItemsPromise === this.props.items) {
                    this.setState({
                        loading: false,
                        loadedItems: items,
                        error: false,
                    });
                }
            })
            .catch(() => {
                this.setState({ loading: false, error: true, loadedItems: [] });
            });
    }

    /**
     * Calculates column width percentages by assigning each column a weight (see ColWidth)
     * and dividing by the sum of the weights.
     */
    colWidthPcts = (columns: IColumn<T>[]) => {
        const sizes = columns.map((col) => col.colWidth || ColWidth.MEDIUM);
        const total = sum(sizes);

        return sizes.map((size) => (size / total) * 100);
    };
}

export default DataTable;

export { IDataTableProps, SortType, SortOrder, TableSortConfig };
