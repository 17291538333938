import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Placement } from '@blueprintjs/popover2';
import * as React from 'react';
import styled from 'styled-components';

import { Flex } from 'reports/components/core/containers';

import Button from './Button';
import Tooltip from './Tooltip';

const StyledTooltip = styled(Tooltip)`
    && .bp3-popover2-content {
        // Overwrite .bp3-tooltip2 padding, since that looks weird when we have the X button
        padding: 5px 0px 5px 12px;
    }
`;

const TooltipContainer = styled(Flex.Container).attrs({ className: 'center' })`
    > :not(:first-child) {
        margin-left: 7px;
    }
    > span {
        white-space: nowrap;
    }
`;

const useStateWithSessionStorage = (sessionStorageKey: string): [string, React.Dispatch<any>] => {
    const [value, setValue] = React.useState(sessionStorage.getItem(sessionStorageKey) || '');
    React.useEffect(() => sessionStorage.setItem(sessionStorageKey, value), [value]);

    return [value, setValue];
};

interface IOwnProps {
    content: string;
    persistenceKey: string;
    disabled?: boolean;
    placement?: Placement;
}

const PersistentTooltip: React.FC<IOwnProps> = ({ content, persistenceKey, disabled, placement, children }) => {
    const key = `HelioScope.PersistentTooltip.${persistenceKey}.dismissed`;
    const [dismissed, setDismissed] = useStateWithSessionStorage(key);

    return (
        <StyledTooltip
            content={
                <TooltipContainer>
                    <Icon icon={IconNames.WARNING_SIGN} />
                    <span>{content}</span>
                    <Button icon={IconNames.SMALL_CROSS} minimal onClick={() => setDismissed('true')} />
                </TooltipContainer>
            }
            disabled={disabled}
            isOpen={!dismissed}
            placement={placement}
        >
            {children}
        </StyledTooltip>
    );
};
export default PersistentTooltip;
