import * as React from 'react';
import { connect } from 'react-redux';

import UpsellDialog, { UpsellDialogProps } from './UpsellDialog';
import { IAppState } from 'reports/types';
import * as auth from 'reports/modules/auth';

type IStateProps = ReturnType<typeof mapStateToProps>;
type UpsellDialogContainerProps = UpsellDialogProps & IStateProps;

const UpsellDialogContainer = ({ user, ...otherProps }: UpsellDialogContainerProps) => {
    return <UpsellDialog user={user} {...otherProps} />;
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

export default connect(mapStateToProps, null)(UpsellDialogContainer);
