import * as React from 'react';

import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';

import * as theme from 'reports/models/theme';

export interface IThemeSelect {
    onItemSelect: (theme: theme.Theme) => void;
    themes?: theme.Theme[];
    selectedTheme?: theme.Theme | null;
    disabled?: boolean;
    noFill?: boolean;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

type IProps = IThemeSelect & IDispatchProps;

const ThemeSelect: React.FC<IProps> = (props) => {
    const { onItemSelect, getThemes, themes, selectedTheme, disabled, noFill } = props;
    const itemRenderer = (theme: theme.Theme) => ({
        key: theme.theme_id,
        text: theme.name,
        label: theme.public ? '(Public)' : '',
    });

    return (
        <BasicSelect<theme.Theme>
            dataSource={{
                items: themes || React.useCallback(getThemes, []),
                filterBy: 'name',
            }}
            itemRenderer={itemRenderer}
            value={selectedTheme || null}
            onChange={onItemSelect}
            noneSelected="No default theme"
            fill={!noFill}
            disabled={disabled}
        />
    );
};

const mapDispatchToProps = bindActions({
    getThemes: theme.api.index,
});

export default connect(null, mapDispatchToProps)(ThemeSelect);
