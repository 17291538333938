import * as React from 'react';
import { ProfilesParameter, Section2, SubsectionPanel } from 'reports/modules/profile/components/common';
import { stringingStrategy } from 'reports/utils/formatters';

import * as pro from 'reports/models/profile';

interface IProps {
    profile: pro.ElectricalProfile;
}

class ElectricalPreviewContents extends React.PureComponent<IProps> {
    render() {
        const { profile } = this.props;
        const {
            inverter,
            optimizer,
            bus_wire,
            string_wire,
            trunk_wire,
            ac_branch_wire,
            ac_run_wire,
            inverter_ac_config,
            panel_transformer_ac_config,
        } = profile;

        return (
            <>
                <Section2 title="Inverter Selection">
                    <ProfilesParameter label="Inverter">
                        {inverter ? `${inverter.toString()}` : 'No inverter selected'}
                    </ProfilesParameter>
                    {!inverter?.microinverter && (
                        <ProfilesParameter
                            label="MAX DC/AC Ratio"
                            helperText="Inverter sizing for string & central inverters"
                        >
                            {profile.data.max_dc_ac_ratio}
                        </ProfilesParameter>
                    )}
                </Section2>
                {!inverter?.microinverter && (
                    <Section2 title={'DC Subsystem'}>
                        <ProfilesParameter
                            label="Combiner Home Runs"
                            helperText="Conductors used between combiners and the inverter"
                        >
                            {bus_wire ? `${bus_wire.toString()}` : 'None'}
                        </ProfilesParameter>
                        <ProfilesParameter
                            label="Combiner Poles"
                            helperText="Maximum number of strings to connect to each combiner"
                        >
                            {profile.data.combiner_poles}
                        </ProfilesParameter>
                        <ProfilesParameter label="Strings" helperText="Conductors connecting modules to combiners">
                            {string_wire ? `${string_wire.toString()}` : 'None'}
                        </ProfilesParameter>
                        <ProfilesParameter
                            label="Min String Length"
                            helperText="Min number of modules in series on each string"
                        >
                            {profile.data.string_size_min ?? 'None'}
                        </ProfilesParameter>
                        <ProfilesParameter
                            label="Max String Length"
                            helperText="Max number of modules in series on each string"
                        >
                            {profile.data.string_size_max ?? 'None'}
                        </ProfilesParameter>
                        <ProfilesParameter
                            label="Stringing Strategy"
                            helperText="How to string modules within the racking"
                        >
                            {stringingStrategy(profile.data.stringing_strategy)}
                        </ProfilesParameter>
                        <ProfilesParameter label="Optimizers" helperText="Conductors connecting modules to combiners">
                            {optimizer ? `${optimizer.toString()}` : 'None'}
                        </ProfilesParameter>
                        <ProfilesParameter
                            label="Recombiner Home Runs"
                            helperText="Conductors used between recombiners and the inverter, only used if your design has both combiners and recombiners"
                        >
                            {trunk_wire ? `${trunk_wire.toString()}` : 'None'}
                        </ProfilesParameter>
                    </Section2>
                )}
                <Section2 title={'AC Subsystem'}>
                    {!!inverter?.microinverter && (
                        <>
                            <ProfilesParameter
                                label="Stringing Strategy"
                                helperText="How to string modules within the racking"
                            >
                                {stringingStrategy(profile.data.stringing_strategy)}
                            </ProfilesParameter>
                            <ProfilesParameter label="Branch Length" helperText="Number of modules on each AC Branch">
                                {profile.data.ac_branch_length}
                            </ProfilesParameter>
                            <ProfilesParameter
                                label="Center-feed Branches"
                                helperText="Center-feeding AC branches can reduce AC line losses in certain microinverter systems"
                            >
                                {profile.data.ac_branch_center_feed ? 'Yes' : 'No'}
                            </ProfilesParameter>
                        </>
                    )}
                    <ProfilesParameter
                        label="AC Home Runs"
                        helperText="Conductors used between the inverters and the AC Panels or PCC, where relevant"
                    >
                        {ac_branch_wire ? `${ac_branch_wire.toString()}` : 'None'}
                    </ProfilesParameter>
                    <ProfilesParameter
                        label="PCC Home Runs"
                        helperText="Conductors used between AC Panels and the PCC. Select 'None' for no AC Panels"
                    >
                        {ac_run_wire ? `${ac_run_wire.toString()}` : 'None'}
                    </ProfilesParameter>
                    <ProfilesParameter
                        label="AC Panel Size"
                        helperText="Maximum number of branches to collect in an AC Panel"
                    >
                        {profile.data.ac_panel_size}
                    </ProfilesParameter>
                    <ProfilesParameter
                        label="Use Transformers"
                        helperText="Configure transformers from the AC Panel to the PCC"
                    >
                        {profile.data.use_transformers ? 'Yes' : 'No'}
                    </ProfilesParameter>
                    {profile.data.use_transformers && (
                        <SubsectionPanel margin="10px 10px 0" padding="15px">
                            <ProfilesParameter label="Inverter AC Output">
                                {inverter_ac_config ? inverter_ac_config.toString() : 'None'}
                            </ProfilesParameter>
                            <ProfilesParameter label="Panel XFRM Output">
                                {panel_transformer_ac_config ? panel_transformer_ac_config.toString() : 'None'}
                            </ProfilesParameter>
                        </SubsectionPanel>
                    )}
                </Section2>
            </>
        );
    }
}

export default ElectricalPreviewContents;
