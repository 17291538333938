import { range } from 'lodash';
import * as React from 'react';

import { Button, Classes, Colors, Intent, HTMLTable } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Hour, HOUR_INTS } from 'reports/utils/time';

import { Flex } from 'reports/components/core/containers';
import { DeleteButton } from 'reports/components/core/controls';
import { DropdownMenuItem, StaticSelect, IStaticSelect } from 'reports/components/core/forms';

import { FormattedEditor } from 'reports/components/helpers';

import * as ur from 'reports/models/utility_rate';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const TimePickerRow = styled(Flex.Container)`
    margin-top: 6px;

    &:first-child {
        margin-top: 0;
    }
    &.empty {
        justify-content: flex-end;
    }
`;

const TOUTable = styled(HTMLTable)`
    // Override Blueprintjs HTML table first row styling
    &.${Classes.HTML_TABLE} tbody {
        tr:first-child th,
        td {
            box-shadow: none;
        }
        tr:nth-child(2) td {
            box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
        }
    }
    tbody tr th {
        padding: 4px 8px;

        .${Classes.EDITABLE_TEXT_CONTENT} {
            line-height: 30px;
        }
    }
`;

const formatHour = (hour: number) => (hour < 10 ? `0${hour}:00` : `${hour}:00`);

type HourDropdownProps = 'activeItem' | 'buttonProps' | 'buttonText' | 'disabled' | 'onItemSelect';

interface IHourDropdown extends Pick<IStaticSelect<Hour>, HourDropdownProps> {
    items?: Hour[];
}

const HourDropdown = ({ items, ...props }: IHourDropdown) => (
    <StaticSelect
        items={items || HOUR_INTS}
        itemRenderer={(hour, { handleClick, modifiers }) => (
            <DropdownMenuItem key={hour} title={formatHour(hour)} onClick={handleClick} {...modifiers} />
        )}
        {...props}
    />
);

interface ITimePicker {
    interval: ur.IInterval;
    onDelete: () => void;
    onUpdate: (intervalIdx: 0 | 1, hour: Hour) => void;
    disabled?: boolean;
    disabledDelete?: boolean;
}

const TOUIntervalTimePicker = ({ interval, onUpdate, onDelete, disabled, disabledDelete }: ITimePicker) => {
    const [start, end] = interval;
    return (
        <>
            <HourDropdown
                activeItem={start}
                buttonText={formatHour(start)}
                onItemSelect={(hour) => onUpdate(0, hour)}
                disabled={disabled}
                buttonProps={{ disabled }}
            />
            <Flex.Container style={{ alignItems: 'center', padding: '0 8px' }}>to</Flex.Container>
            <HourDropdown
                // Only display hours after interval start
                items={range(start + 1, 24).concat([0]) as Hour[]}
                activeItem={end}
                buttonText={formatHour(end)}
                onItemSelect={(hour) => onUpdate(1, hour)}
                disabled={disabled}
                buttonProps={{ disabled }}
            />
            {!disabled && (
                <DeleteButton onClick={onDelete} disabled={disabledDelete} minimal={true} style={{ marginLeft: 2 }} />
            )}
        </>
    );
};

interface IOwnProps {
    onAdd: () => void;
    onDelete: (touIdx: number) => void;
    onUpdate: (touIdx: number, val: Partial<ur.ITOUPeriod>) => void; // update current rate schedule
    tou: ur.ITOUPeriod[];
    numCols?: number;
    style?: React.CSSProperties;
}

class SeasonalTOUSettings extends React.PureComponent<IOwnProps> {
    render() {
        const { tou, numCols } = this.props;
        return (
            <TOUTable style={this.props.style}>
                <tbody>
                    <tr id="tou-headers">
                        {numCols && numCols > tou.length // render empty cells to right align season tables
                            ? range(numCols - tou.length).map((i) => (
                                  <th key={`empty${i}`} style={{ width: 237.78 }} rowSpan={2} scope="rowgroup" />
                              ))
                            : null}

                        {tou.map((period, pIdx) => {
                            const lastPeriod = pIdx === tou.length - 1;
                            return (
                                <React.Fragment key={`tou-header-fragment-${pIdx}`}>
                                    {lastPeriod && (
                                        <th
                                            key="tou-header-add"
                                            rowSpan={2}
                                            scope="rowgroup"
                                            style={{
                                                verticalAlign: 'middle',
                                                background: Colors.LIGHT_GRAY5,
                                            }}
                                        >
                                            <Button
                                                text="Add New"
                                                onClick={() => this.props.onAdd()}
                                                icon={IconNames.ADD}
                                                intent={Intent.PRIMARY}
                                                minimal={true}
                                                disabled={!this.canAddNewInterval()}
                                            />
                                        </th>
                                    )}

                                    <th key={`tou-header${pIdx}`}>
                                        <Flex.Container
                                            className="center"
                                            style={{
                                                height: 30,
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <FormattedEditor
                                                value={period.name}
                                                onConfirm={(val: string) =>
                                                    this.props.onUpdate(pIdx, {
                                                        name: val,
                                                    })
                                                }
                                                confirmOnEnterKey={true}
                                                formatter={(val) => val.trim()}
                                                formatOnConfirm={(val) => val.trim()}
                                                formatOnEdit={(val) => val.trim()}
                                                selectAllOnFocus={true}
                                                placeholder="Add period name"
                                            />
                                            {!lastPeriod && (
                                                <DeleteButton
                                                    onClick={() => this.props.onDelete(pIdx)}
                                                    minimal={true}
                                                />
                                            )}
                                        </Flex.Container>
                                    </th>
                                </React.Fragment>
                            );
                        })}
                    </tr>
                    <tr id="tou-intervals">{this.renderTimePickers()}</tr>
                </tbody>
            </TOUTable>
        );
    }

    renderTimePickers = () => {
        const { tou } = this.props;
        const canAddNew = this.canAddNewInterval();

        return tou.map((period, pIdx) => {
            const isOffPeak = pIdx === tou.length - 1;
            return (
                <td key={`tou${pIdx}`}>
                    {period.intervals.map((interval, iIdx) => (
                        <TimePickerRow key={`time-picker-row${iIdx}`}>
                            <TOUIntervalTimePicker
                                key={`tou${pIdx}-interval${iIdx}`}
                                interval={interval}
                                disabled={isOffPeak}
                                disabledDelete={period.intervals.length === 1}
                                onUpdate={(updateIdx, hour) => this.updateInterval(pIdx, iIdx, updateIdx, hour)}
                                onDelete={() => this.deleteInterval(pIdx, iIdx)}
                            />
                        </TimePickerRow>
                    ))}
                    {!isOffPeak && (
                        <TimePickerRow className="empty">
                            <Button
                                icon={IconNames.PLUS}
                                onClick={() => this.addInterval(pIdx)}
                                minimal={true}
                                disabled={!canAddNew}
                                title={!canAddNew ? 'All hours in the day have been accounted for already.' : undefined}
                            />
                        </TimePickerRow>
                    )}
                </td>
            );
        });
    };

    addInterval = (periodIdx: number) => {
        const { tou } = this.props;
        const intervals = [...tou[periodIdx].intervals];

        const start = intervals.length ? intervals[intervals.length - 1][1] : 0;
        const end = ((start + 1) % 24) as Hour;

        intervals.push([start, end]);
        this.props.onUpdate(periodIdx, { intervals });
    };

    canAddNewInterval = () => this.props.tou[this.props.tou.length - 1].intervals.length > 0;

    deleteInterval = (periodIdx: number, intervalIdx: number) => {
        const intervals = [...this.props.tou[periodIdx].intervals];
        intervals.splice(intervalIdx, 1);
        this.props.onUpdate(periodIdx, { intervals });
    };

    updateInterval = (periodIdx: number, intervalIdx: number, updateIdx: 0 | 1, hour: Hour) => {
        const intervals = [...this.props.tou[periodIdx].intervals];
        const interval = [...intervals[intervalIdx]] as ur.IInterval; // Typescript tuple type gets lost when copied

        interval[updateIdx] = hour;

        const [start, end] = interval;
        if (start > end && end !== 0) {
            interval[1] = ((start + 1) % 24) as Hour;
        }

        intervals[intervalIdx] = interval;
        this.props.onUpdate(periodIdx, { intervals });
    };
}

export default SeasonalTOUSettings;
