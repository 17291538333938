import { get, groupBy, mapValues, sum, values } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { Icon, Spinner, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { bindActions } from 'reports/utils/redux';

import Translations from 'reports/localization/strings';
import * as fs from 'reports/models/field_segment';
import * as fc from 'reports/models/field_component';
import * as proj from 'reports/models/project';
import * as sim from 'reports/models/simulation';
import { actions } from 'reports/modules/project';
import * as projSelectors from 'reports/modules/project/selectors';
import { registerWidget, IWidgetRenderProps, IReportContext } from 'reports/modules/report/widgets';
import * as fmt from 'reports/utils/formatters';
import { Angle, Energy, FormattedNumber, Integer, Percent, Power } from 'reports/components/core/numbers';
import { TooltipContent, WidgetDataTable } from 'reports/modules/report/components/helpers';

interface IFieldSegmentSummary {
    fieldSegment: fs.FieldSegment;
    minShadedIrradiance: number;
    results: sim.ISimResultsSummary;
    subarrays?: {
        description: string;
        azimuth: number;
        power: number;
        modules: number;
        minShadedIrradiance: number;
        results: sim.ISimResultsSummary;
    }[];
}

interface IState {
    loadingModuleResults: boolean;
    loadingFieldComponents: boolean;
    loadingOptimalPoaData: boolean;
    shadeData: {
        fieldSegmentSummary?: IFieldSegmentSummary[];
        summary?: {
            modules: number;
            power: number;
            minShadedIrradiance: number;
        };
    };
    moduleDataError: boolean;
}

interface IDispatchProps {
    loadModuleResults: (simulationId: number) => Promise<sim.IModuleLevelResults>;
    loadFieldComponents: (designId: number) => Promise<fc.FieldComponent[]>;
    loadOptimalPoaData: (
        project: proj.Project,
        weatherDatasetId: number,
        moduleCharacterizationId: number,
    ) => Promise<proj.IProjectOrientation>;
}

type IContext = Pick<IReportContext, 'project' | 'simulation' | 'scenario' | 'design'>;
type IStateProps = ReturnType<typeof mapStateToProps>;
type IRenderProps = IWidgetRenderProps<object, IContext>;
type IProps = IRenderProps & IStateProps & IDispatchProps & { intl: IntlShape };

class ShadingByFieldSegmentTable extends React.PureComponent<IProps> {
    state: IState = {
        loadingModuleResults: false,
        loadingFieldComponents: false,
        loadingOptimalPoaData: false,
        shadeData: {},
        moduleDataError: false,
    };

    async componentDidMount() {
        if (this.props.moduleResults == null) {
            this.loadModuleData();
        }
        if (this.props.fieldComponents == null) {
            this.loadComponentData();
        }
        if (this.props.optimalPoaData == null) {
            this.loadPoaData();
        }

        this.calculateSummary();
    }

    async componentDidUpdate(prevProps) {
        const { design, scenario, simulation } = this.props.context;
        const { fieldComponents, optimalPoaData } = this.props;

        const designUpdated = get(prevProps, 'context.design.design_id') !== get(design, 'design_id');
        const scenarioUpdated = get(prevProps, 'context.scenario.scenario_id') !== get(scenario, 'scenario_id');
        const prevSimulation = get(prevProps, 'context.simulation');

        if (!this.state.loadingModuleResults && simulation?.complete()) {
            if (
                prevSimulation == null ||
                !prevSimulation.complete() ||
                prevSimulation.simulation_id !== simulation.simulation_id
            ) {
                this.loadModuleData();
            }
        }

        if (!this.state.loadingFieldComponents && (fieldComponents == null || designUpdated)) {
            this.loadComponentData();
        }
        if (!this.state.loadingOptimalPoaData && (optimalPoaData == null || designUpdated || scenarioUpdated)) {
            this.loadPoaData();
        }

        const { fieldSegmentSummary, summary } = this.state.shadeData;
        const needToCalculate = fieldSegmentSummary == null || summary == null;
        const canCalculate = this.props.moduleResults != null && this.props.fieldComponents != null;

        if (canCalculate && needToCalculate) {
            this.calculateSummary();
        }
    }

    render() {
        const { fieldSegmentSummary, summary } = this.state.shadeData;
        const {
            optimalPoaData,
            intl: { locale },
        } = this.props;
        const { simulation } = this.props.context;

        const errorMessage = this.getErrorMessage();
        const showLoadingState = this.loading() && !errorMessage;
        const showTable = !this.loading() && !errorMessage;

        return (
            <WidgetDataTable className={this.props.className} style={{ fontSize: '90%' }}>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.general.description} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.design.tilt} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.design.azimuth} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.design.modules} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.design.nameplate} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.simulation.shaded_irradiance} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.simulation.ac_energy} />
                        </th>
                        <th>
                            <Tooltip
                                content={
                                    <TooltipContent>
                                        <FormattedMessage {...Translations.simulation.tilt_orientation_factor} />
                                    </TooltipContent>
                                }
                            >
                                <div>
                                    <FormattedMessage {...Translations.simulation.tof} />
                                </div>
                            </Tooltip>
                        </th>
                        <th>
                            <FormattedMessage {...Translations.simulation.solar_access} />
                        </th>
                        <th>
                            <Tooltip
                                content={
                                    <TooltipContent>
                                        <FormattedMessage
                                            {...Translations.simulation.minimum_solar_resource_fraction}
                                        />
                                    </TooltipContent>
                                }
                            >
                                <div>
                                    <FormattedMessage {...Translations.simulation.min_tsrf} />
                                </div>
                            </Tooltip>
                        </th>
                        <th>
                            <Tooltip
                                content={
                                    <TooltipContent>
                                        <FormattedMessage {...Translations.simulation.total_solar_resource_fraction} />
                                    </TooltipContent>
                                }
                            >
                                <div>
                                    <FormattedMessage {...Translations.simulation.avg_tsrf} />
                                </div>
                            </Tooltip>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {errorMessage && (
                        <tr>
                            <td className="centered" colSpan={11}>
                                <div>
                                    <Icon icon="warning-sign" style={{ marginRight: '8px' }} />
                                    {errorMessage}
                                </div>
                            </td>
                        </tr>
                    )}
                    {showLoadingState && (
                        <tr>
                            <td className="centered" colSpan={11}>
                                <Spinner size={20} />
                            </td>
                        </tr>
                    )}
                    {showTable && fieldSegmentSummary != null && optimalPoaData != null && (
                        <>
                            {fieldSegmentSummary
                                .sort((a, b) =>
                                    a.fieldSegment.description.localeCompare(b.fieldSegment.description, undefined, {
                                        numeric: true,
                                    }),
                                )
                                .map((row) => (
                                    <React.Fragment key={row.fieldSegment.field_segment_id}>
                                        <tr>
                                            <td>{row.fieldSegment.description}</td>
                                            <td>
                                                <div>
                                                    {fmt.tiltAndAzimuthPrefix(row.fieldSegment.rack_type)}{' '}
                                                    <Angle value={row.fieldSegment.tilt} />
                                                </div>
                                                {row.fieldSegment.independent_tilt_enabled && (
                                                    <div>
                                                        Surface:{' '}
                                                        <Angle value={row.fieldSegment.independent_tilt_surface_tilt} />
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <div>
                                                    {fmt.tiltAndAzimuthPrefix(row.fieldSegment.rack_type)}{' '}
                                                    <Angle value={row.fieldSegment.azimuth} />
                                                </div>
                                                {row.fieldSegment.independent_tilt_enabled && (
                                                    <div>
                                                        Surface:{' '}
                                                        <Angle
                                                            value={row.fieldSegment.independent_tilt_surface_azimuth}
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <Integer value={row.fieldSegment.moduleCount()} />
                                            </td>
                                            <td>
                                                <Power value={row.fieldSegment.power()} />p
                                            </td>

                                            <td>
                                                <FormattedNumber
                                                    value={row.results.shaded_irradiance / 1000}
                                                    precision={1}
                                                />{' '}
                                                kWh/m<sup>2</sup>
                                            </td>
                                            <td>
                                                <Energy value={row.results.power} />
                                                <sup>1</sup>
                                            </td>
                                            <td>
                                                <Percent
                                                    value={row.results.poa_irradiance / optimalPoaData.poa_irradiance}
                                                />
                                            </td>
                                            <td>
                                                <Percent
                                                    value={row.results.shaded_irradiance / row.results.poa_irradiance}
                                                />
                                            </td>
                                            <td>
                                                <Percent
                                                    value={row.minShadedIrradiance / optimalPoaData.poa_irradiance}
                                                />
                                            </td>
                                            <td>
                                                <Percent
                                                    value={
                                                        row.results.shaded_irradiance / optimalPoaData.poa_irradiance
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        {row.subarrays != null &&
                                            row.subarrays
                                                .sort((subarray) => subarray.azimuth)
                                                .map((subarray, index) => (
                                                    <tr className="subrow" key={index}>
                                                        <td>&raquo; subarray {index + 1}</td>
                                                        <td>
                                                            <Angle value={row.fieldSegment.tilt} />
                                                        </td>
                                                        <td>
                                                            <Angle value={subarray.azimuth} />
                                                        </td>
                                                        <td>
                                                            <Integer value={subarray.modules} />
                                                        </td>
                                                        <td>
                                                            <Power value={subarray.power} precision={1} />p
                                                        </td>

                                                        <td>
                                                            <FormattedNumber
                                                                value={subarray.results.shaded_irradiance / 1000}
                                                                precision={1}
                                                            />{' '}
                                                            kWh/m
                                                            <sup>2</sup>
                                                        </td>
                                                        <td>
                                                            <Energy value={subarray.results.power} />
                                                            <sup>1</sup>
                                                        </td>

                                                        <td>
                                                            <Percent
                                                                value={
                                                                    subarray.results.poa_irradiance /
                                                                    optimalPoaData.poa_irradiance
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <Percent
                                                                value={
                                                                    subarray.results.shaded_irradiance /
                                                                    subarray.results.poa_irradiance
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <Percent
                                                                value={
                                                                    subarray.minShadedIrradiance /
                                                                    optimalPoaData.poa_irradiance
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <Percent
                                                                value={
                                                                    subarray.results.shaded_irradiance /
                                                                    optimalPoaData.poa_irradiance
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                    </React.Fragment>
                                ))}
                            {summary != null && (
                                <tr className="summary">
                                    <td colSpan={3}>
                                        <FormattedMessage {...Translations.simulation.totals_weighted_by_kwp} />
                                    </td>
                                    <td>
                                        <Integer value={summary.modules} />
                                    </td>
                                    <td>
                                        <Power value={summary.power} />p
                                    </td>

                                    <td>
                                        <FormattedNumber
                                            value={simulation.metadata.shaded_irradiance / 1000}
                                            precision={1}
                                        />{' '}
                                        kWh/m<sup>2</sup>
                                    </td>
                                    <td>
                                        <Energy value={simulation.metadata.grid_power} />
                                    </td>

                                    <td>
                                        <Percent
                                            value={simulation.metadata.poa_irradiance / optimalPoaData.poa_irradiance}
                                        />
                                    </td>
                                    <td>
                                        <Percent
                                            value={
                                                simulation.metadata.shaded_irradiance /
                                                simulation.metadata.poa_irradiance
                                            }
                                        />
                                    </td>
                                    {summary.minShadedIrradiance != null && (
                                        <td>
                                            <Percent
                                                value={summary.minShadedIrradiance / optimalPoaData.poa_irradiance}
                                            />
                                        </td>
                                    )}
                                    <td>
                                        <Percent
                                            value={
                                                simulation.metadata.shaded_irradiance / optimalPoaData.poa_irradiance
                                            }
                                        />
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td className="footer" colSpan={11}>
                                    <sup>1</sup>
                                    <FormattedMessage {...Translations.widgets.fs_table_footer_1} />
                                    <br />
                                    <sup>2</sup>
                                    <FormattedMessage
                                        {...Translations.widgets.fs_table_footer_2}
                                        values={{
                                            poa_irradiance: fmt.stringifyNumber(optimalPoaData.poa_irradiance / 1000, {
                                                locale,
                                                precision: 1,
                                            }),
                                            tilt: fmt.stringifyNumber(optimalPoaData.tilt, { locale, precision: 1 }),
                                            azimuth: fmt.stringifyNumber(optimalPoaData.azimuth, {
                                                locale,
                                                precision: 1,
                                            }),
                                        }}
                                    />
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </WidgetDataTable>
        );
    }

    getErrorMessage() {
        if (this.state.moduleDataError) {
            return (
                <span>
                    <FormattedMessage {...Translations.errors.module_data_not_available} />
                </span>
            );
        }
    }

    loading() {
        return this.state.loadingModuleResults || this.state.loadingFieldComponents || this.state.loadingOptimalPoaData;
    }

    async loadModuleData() {
        const { simulation } = this.props.context;

        if (simulation == null || (simulation != null && !simulation.complete())) {
            return;
        }

        this.setState({
            loadingModuleResults: true,
            shadeData: {
                fieldSegmentSummary: null,
                summary: null,
            },
            moduleDataError: false,
        });

        try {
            await this.props.loadModuleResults(simulation.simulation_id);
        } catch (e) {
            this.setState({ moduleDataError: true });
        } finally {
            this.setState({ loadingModuleResults: false });
        }
    }

    async loadComponentData() {
        const { design } = this.props.context;

        if (design == null) return;

        this.setState({
            loadingFieldComponents: true,
            shadeData: {
                fieldSegmentSummary: null,
                summary: null,
            },
        });
        this.props
            .loadFieldComponents(design.design_id)
            .catch((_error) => {})
            .then(() => this.setState({ loadingFieldComponents: false }));
    }

    async loadPoaData() {
        const { design, project, scenario } = this.props.context;

        if (
            project == null ||
            get(scenario, 'weather_dataset_id') == null ||
            get(design, 'field_segments[0].module_characterization_id') == null
        ) {
            return;
        }

        this.setState({
            loadingOptimalPoaData: true,
            shadeData: {
                fieldSegmentSummary: null,
                summary: null,
            },
        });
        this.props
            .loadOptimalPoaData(
                project,
                scenario.weather_dataset_id,
                design.field_segments[0].module_characterization_id,
            )
            .then(() => this.setState({ loadingOptimalPoaData: false }))
            .catch((error) => {
                console.error(`Failed to load optimal POA data for project <${project.project_id}>`, error);
            });
    }

    calculateSummary() {
        const { context, fieldComponents, moduleResults } = this.props;
        const { design, simulation } = context;

        if (moduleResults == null || fieldComponents == null) {
            // If not all pieces of we need to calculate the derived data are available, exit and don't set state.
            return;
        }

        const fieldModulesBySegment = getModulesBySegment(fieldComponents);
        const modulePower = sum(
            values(moduleResults).map((monthlyResults) => {
                return sum(values(monthlyResults).map((results) => results.power));
            }),
        );

        // Module power doesn't include all sim effects
        const gridPowerScalar = simulation.metadata.grid_power / modulePower;

        const fieldSegmentSummary: IFieldSegmentSummary[] = [];
        let totalModules = 0;

        for (const [segmentId, segmentModules] of Object.entries(fieldModulesBySegment)) {
            const fieldSegment = design.fieldSegment(Number(segmentId));

            if (fieldSegment == null) continue;

            const segmentModuleResults = segmentModules.map((module) => moduleResults[module.field_component_id]);
            const minShadedIrradiance = Math.min(
                ...segmentModuleResults.map((monthlyResults) => {
                    return sum(values(monthlyResults).map((results) => results.shaded_irradiance));
                }),
            ); // Get minimum per module and then get minimum across modules in the segment
            const results = aggregateResults(segmentModuleResults);

            fieldSegmentSummary.push({
                fieldSegment,
                minShadedIrradiance,
                results: {
                    ...results,
                    power: results.power * gridPowerScalar,
                },
                subarrays:
                    fieldSegment != null && fieldSegment.rack_type === 'dual'
                        ? summarizeOrientations(segmentModules, moduleResults, fieldSegment, gridPowerScalar)
                        : [],
            });

            totalModules += fieldSegment != null ? fieldSegment.moduleCount() : 0;
        }

        this.setState({
            shadeData: {
                fieldSegmentSummary,
                summary: {
                    modules: totalModules,
                    power: sum(fieldSegmentSummary.map((fs) => fs.fieldSegment.power())),
                    minShadedIrradiance: Math.min(...fieldSegmentSummary.map((fs) => fs.minShadedIrradiance)),
                },
            },
        });
    }
}

function summarizeOrientations(fieldModules, moduleResults, fieldSegment, powerScalar) {
    const groupedModules = groupBy(values(fieldModules), (module) => module.rotation);
    const baseAzimuth = fieldSegment.azimuth - 90;
    const subarrayRows: any[] = [];
    let subarrayIndex = 0;

    for (const [orientation, modules] of Object.entries(groupedModules)) {
        subarrayIndex += 1;
        const groupModuleResults = modules.map((module) => moduleResults[module.field_component_id]);
        const subarrayResults = aggregateResults(groupModuleResults);

        subarrayRows.push({
            description: `subarray ${subarrayIndex}`,
            azimuth: (baseAzimuth + Number(orientation)) % 360,
            power: fieldSegment.module_characterization.power * groupModuleResults.length,
            modules: groupModuleResults.length,
            minShadedIrradiance: Math.min(
                ...groupModuleResults.map((monthlyResults) => {
                    return sum(values(monthlyResults).map((results) => results.shaded_irradiance));
                }),
            ),
            results: {
                ...subarrayResults,
                power: subarrayResults.power * powerScalar,
            },
        });
    }

    return subarrayRows;
}

function sumResults(results) {
    const aggregatedResults = results.reduce((prev, curr) => {
        return {
            power: prev.power + curr.power,
            mpp_power: prev.mpp_power + curr.mpp_power,
            poa_irradiance: prev.poa_irradiance + curr.poa_irradiance,
            nameplate_power: prev.nameplate_power + curr.nameplate_power,
            shaded_irradiance: prev.shaded_irradiance + curr.shaded_irradiance,
            total_irradiance: prev.total_irradiance + curr.total_irradiance,
        };
    });

    return aggregatedResults;
}

export function aggregateResultsByMonth(moduleResults: { [month: number]: sim.ISimResultsSummary }[]): {
    [month: number]: sim.ISimResultsSummary;
} {
    // Group module results by month
    const resultsByMonth = moduleResults.reduce((prevAgg, results) => {
        for (const month of Object.keys(results)) {
            if (prevAgg[month] == null) {
                prevAgg[month] = [];
            }
            prevAgg[month].push(results[month]);
        }
        return prevAgg;
    }, {});

    // Sum module results by month
    return mapValues(resultsByMonth, (results) => sumResults(results));
}

function aggregateResults(moduleResults: { [month: number]: sim.ISimResultsSummary }[]): sim.ISimResultsSummary {
    // Sum module results by month
    const aggResultsByMonth = aggregateResultsByMonth(moduleResults);

    // Irradiance should be averaged across modules for each month
    const normalizedResultsByMonth = mapValues(aggResultsByMonth, (aggResults) => {
        aggResults.poa_irradiance /= moduleResults.length;
        aggResults.shaded_irradiance /= moduleResults.length;
        return aggResults;
    });

    // Sum monthly normalized results to get annual results
    // Note: don't normalize irradiance when aggregating across time
    return sumResults(values(normalizedResultsByMonth));
}

/**
 * Mirrors flatten_components from field_components.py. If components were fully typed, we could
 * use getChildren() to simplify most of this.
 */
function flatten(fieldComponents) {
    return fieldComponents.reduce((result, component) => {
        if (Array.isArray(component)) {
            return result.concat(flatten(component));
        }

        let ret = result.concat(component);
        if (component.children) {
            ret = ret.concat(flatten(component.children));
        } else if (component.child) {
            ret = ret.concat(flatten([component.child]));
        }
        return ret;
    }, []);
}

export function getModulesBySegment(fieldComponents: fc.FieldComponent[]) {
    if (fieldComponents == null) return fieldComponents;
    const fieldModules = flatten(fieldComponents).filter((component) => component.component_type === 'module');

    return groupBy(fieldModules, (module) => module.field_segment_id);
}

const mapStateToProps = (state, ownProps: IRenderProps) => {
    const { design, project, simulation } = ownProps.context;

    return {
        moduleResults: projSelectors.getModuleResults(state, simulation.simulation_id),
        fieldComponents: projSelectors.getFieldComponents(state, design.design_id),
        optimalPoaData: projSelectors.getOptimalPoaData(state, project.project_id),
    };
};

const mapDispatchToProps = bindActions(() => ({
    loadModuleResults: (simulationId) => actions.loadModuleResults(simulationId),
    loadFieldComponents: (designId) => actions.loadFieldComponents(designId),
    loadOptimalPoaData: (project, weatherDatasetId, moduleCharacterizationId) =>
        actions.loadOptimalPoaData(project, weatherDatasetId, moduleCharacterizationId),
}));

const ShadingByFieldSegmentTableContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(ShadingByFieldSegmentTable));

export const ShadingByFieldSegmentTableWidget = registerWidget('field_segment_shading', {
    Component: ShadingByFieldSegmentTableContainer,
    metadata: {
        category: 'project',
        dimensions: { h: 350, w: 830 },
        displayName: Translations.widgets.shading_by_field_segment_header,
        icon: IconNames.TH,
    },
    dependencies: ['project', 'simulation', 'scenario', 'design'],
});

export default ShadingByFieldSegmentTableWidget;
