import { cloneDeep } from 'lodash';
import { IntlShape } from 'react-intl';

import { IconNames } from '@blueprintjs/icons';
import { RawDraftContentBlock, RawDraftContentState, RawDraftInlineStyleRange } from 'draft-js';

import Translations from 'reports/localization/strings';

import { rangesOverlap } from 'reports/utils/helpers';

import { ITokenMap } from 'reports/modules/report/tokens';
import { IWidgetEditProps, IWidgetRenderProps, IReportContext, registerWidget } from 'reports/modules/report/widgets';

import { EditTextComponent, ViewTextComponent } from './GenericText';
import { IStyleMap } from 'reports/modules/report/styles';

interface ITokenOffset {
    tokenLength: number;
    tokenStart: number;
    tokenValLength: number;
}

/**
 * Given a content block's inline style ranges, adjust each range accordingly with replaced token values.
 * For each range, adjust offsets/style length for every token that overlaps with the range.
 * @param offsets - list of block's token placement data (token length, start index, and length of token's mapped value)
 * @param ranges - list of applied inline styles for given block
 * @returns Adjusted style ranges for compiled text
 */
function mergeRanges(offsets: ITokenOffset[], ranges: RawDraftInlineStyleRange[]): RawDraftInlineStyleRange[] {
    const mergedRanges = cloneDeep(ranges);

    mergedRanges.forEach((range, i) => {
        const styleStart = range.offset;
        const styleEnd = styleStart + range.length - 1;

        // Updated style starts as you populate tokens with values
        let sStart = styleStart;
        let nStart = styleStart;
        offsets.forEach(({ tokenLength, tokenStart, tokenValLength }) => {
            const tokenEnd = tokenStart + tokenLength;
            const delta = tokenValLength - tokenLength;
            let updatedRange;

            // Shift style start if it follows token
            if (sStart > 0 && sStart >= tokenEnd) {
                sStart += delta;
            }

            // Update style range length and offset if it overlaps with or follows a token
            if (rangesOverlap(tokenStart, tokenEnd, styleStart, styleEnd)) {
                const length = range.length === tokenLength ? tokenValLength : range.length + delta;
                updatedRange = { ...range, length, offset: sStart };
            } else {
                // If style range doesn't overlap with but follows token, shift offset only
                if (styleStart >= tokenEnd) {
                    nStart += delta;
                    updatedRange = { ...range, offset: nStart };
                } else {
                    return;
                }
            }

            mergedRanges[i] = updatedRange;
        });
    });

    return mergedRanges;
}

/**
 * Replace tokens with their appropriate values and update inline styles for given block.
 * @param block - content block to update
 * @param tokenMap - map of report tokens to the compiled values
 * @param locale - used to format numerical tokens w/ commas and decimals in the appropriate places.
 * @returns Updated block with compiled text and converted inline styles
 */
export function updateBlockWithValues(block: RawDraftContentBlock, tokenMap: ITokenMap, locale: string) {
    const offsets: ITokenOffset[] = [];
    const regexpTokens: RegExp = new RegExp(/{([^{}]+)}/g); // All tokens contained within curly brackets, `{token}`

    let updatedBlock = { ...block };
    let text = block.text;
    let match;

    while ((match = regexpTokens.exec(block.text)) != null) {
        const matchStr = match[0]; // `{tokenKey}`
        const tokenKey = match[1]; // `tokenKey`

        const tokenVal = tokenMap[tokenKey] ? tokenMap[tokenKey].format({ locale }) : null;
        const token = {
            tokenLength: matchStr.length,
            tokenStart: match.index,
            tokenValLength: tokenVal ? tokenVal.length : 0,
        };
        offsets.push(token);

        // Replace match with tokenValue
        text = text.replace(matchStr, tokenVal!);
        updatedBlock = { ...block, text };
    }

    // Merge style ranges with updated offsets
    updatedBlock.inlineStyleRanges = mergeRanges(offsets, block.inlineStyleRanges);

    return updatedBlock;
}

export interface IGenericTextConfig {
    rawTextContent: RawDraftContentState;
    styleMap: IStyleMap;
}

type IContext = Pick<IReportContext, 'theme' | 'tokenMap'>;
export type ViewProps = IWidgetRenderProps<IGenericTextConfig, IContext> & {
    intl: IntlShape;
};
export type EditProps = IWidgetEditProps<IGenericTextConfig, IContext> & {
    intl: IntlShape;
};

registerWidget('generic_text', {
    Component: ViewTextComponent,
    EditingComponent: EditTextComponent,
    metadata: {
        category: 'ignore',
        dimensions: { h: 200, w: 300 },
        displayName: Translations.widgets.generic_text_header,
        icon: IconNames.FONT,
        ignoreStyle: true,
    },
    dependencies: ['theme', 'tokenMap'],
});
