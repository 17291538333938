/* tslint:disable:variable-name */

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from './schema';

export interface ExperimentSummary {
    name: string;
    description: string;
}

class AdminData extends BaseClass {
    team_experiments: ExperimentSummary[];
    all_experiments: ExperimentSummary[];
    is_hs_super_admin: boolean;
}

const schemaObj = schema.addObject(AdminData, 'admin_data');
const endpoint = ReduxEndpoint.fromSchema('/api/admin_data/', schemaObj);
const api = {
    get: endpoint.get<void>('', ReduxEndpoint.PassThroughConfig()),
};

export { AdminData, schemaObj, endpoint, api };
