import * as React from 'react';

import { Button, ButtonGroup, Colors, Intent } from '@blueprintjs/core';

import { ValueTypes } from 'reports/utils/types';
import { Card } from 'reports/components/core/containers';
import { DeleteButton } from 'reports/components/core/controls';
import { Section } from 'reports/components/helpers';

import { canAccessAdvFinancialFeatures } from 'reports/modules/auth/permissions';

import * as usr from 'reports/models/user';
import * as ur from 'reports/models/utility_rate';
import * as auth from 'reports/modules/auth';

import { promptModalBoolean } from 'reports/components/dialog';

import { getRateParam } from 'reports/modules/financials/utils';
import { aggregateSeasonalTOU } from 'reports/modules/utility_rate/utils';

import { DEFAULT_TIERED_INPUT, DEFAULT_TOU_INPUT } from 'reports/modules/financials/components/ParamRatesSeasonal';

import { IRow, Row, Switch } from './UtilityRateEditor';
import SeasonalSettings from './SeasonalSettings';
import FixedCharges from './FixedCharges';
import MinimumBill from './MinimumBill';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const Subsection = styled.div`
    margin: 10px 0;
`;

export const SubsectionHeader = ({ title }: { title: string }) => (
    <Section.Header style={{ borderBottom: `1px solid ${Colors.GRAY2}` }}>
        <Section.Text>{title}</Section.Text>
    </Section.Header>
);

export const SettingRow = (props: IRow) => (
    <Row labelStyle={{ width: 'auto', fontWeight: 400 }} style={{ justifyContent: 'space-between' }} {...props} />
);

interface IOwnProps {
    onDelete: () => void;
    onUpdate: (propPath: string, val: any) => void;
    user: usr.User;
    utilityRate: ur.UtilityRate;
}

type RateDataValueTypes = ValueTypes<ur.UtilityRateData>;

const OverviewPanel: React.SFC<IOwnProps> = ({ onDelete, onUpdate, user, utilityRate }) => {
    const {
        demand_rates: demandRates,
        seasonal_input: seasonalInput,
        fixed_rates: fixedCharges,
        minimum_bill: minimumBill,
    } = utilityRate.data;

    const defaultDemandRates = getRateParam('demand_rates').default;

    const hasSeasonalEditorAccess = canAccessAdvFinancialFeatures(user);
    const hasTOU = aggregateSeasonalTOU(seasonalInput).length > 0;

    const promptConversion = async () => {
        const editorName = seasonalInput ? 'Advanced' : 'Seasonal';
        const prompt = seasonalInput
            ? "You won't be able to switch back to the Seasonal Editor without losing your current seasonal input. \
               Switch to Advanced Editor?"
            : 'Current rate data will be reset when switching to the Seasonal Editor. Switch to Seasonal Editor?';

        const shouldConvert = await promptModalBoolean({
            prompt,
            title: `Switch to ${editorName} Editor?`,
            cancellable: true,
            noLabel: "Don't Switch",
            yesLabel: `Use ${editorName} Editor`,
        });

        if (shouldConvert) {
            if (seasonalInput) {
                // Switching to adv
                const energyConfig = utilityRate.toRateConfig();
                onUpdate('energy_rates', energyConfig);
            }

            // Reset demand to default null on switch until supported in seasonal
            onUpdate('demand_rates', null);
            toggleDataProp('seasonal_input', seasonalInput, DEFAULT_TOU_INPUT);
        }
    };

    const toggleDataProp = (
        subpath: keyof ur.UtilityRateData,
        val: RateDataValueTypes,
        defaultVal: RateDataValueTypes,
    ) => onUpdate(subpath, val ? null : defaultVal);

    const toggleTOU = () =>
        hasTOU ? onUpdate('seasonal_input', DEFAULT_TIERED_INPUT) : onUpdate('seasonal_input', DEFAULT_TOU_INPUT);

    return (
        <Card>
            <Section.Header style={{ fontSize: 16 }}>Utility Rate Overview</Section.Header>

            <Subsection style={{ marginTop: 0 }}>
                <SubsectionHeader title="Rate Structure" />

                {hasSeasonalEditorAccess && (
                    <SettingRow
                        label="Editor Type"
                        helperText="Use the Advanced Editor to create complex rate structures"
                        rightEl={
                            // TODO: refactor using ButtonGroupSelect
                            <ButtonGroup>
                                <Button
                                    text="Seasonal Editor"
                                    active={!!seasonalInput}
                                    intent={seasonalInput ? Intent.PRIMARY : undefined}
                                    onClick={!!seasonalInput ? undefined : promptConversion}
                                />
                                <Button
                                    text="Advanced Editor"
                                    active={!seasonalInput}
                                    intent={!seasonalInput ? Intent.PRIMARY : undefined}
                                    onClick={!seasonalInput ? undefined : promptConversion}
                                />
                            </ButtonGroup>
                        }
                    />
                )}
                <SettingRow
                    label="Demand Charges"
                    helperText="This rate has additional charges for peak usage"
                    rightEl={
                        <Switch
                            checked={!!demandRates}
                            onChange={() => toggleDataProp('demand_rates', demandRates, defaultDemandRates)}
                        />
                    }
                    showIf={!seasonalInput}
                />
                <SettingRow
                    label="Time of Use"
                    helperText="Energy costs vary by season and time of day (e.g. Peak, Off-Peak)"
                    rightEl={<Switch checked={hasTOU} onChange={toggleTOU} />}
                    showIf={hasSeasonalEditorAccess && seasonalInput != null}
                />
            </Subsection>

            <FixedCharges fixedCharges={fixedCharges || []} onUpdate={onUpdate} />
            <MinimumBill minimumBill={minimumBill || { amount: 0, active: false, type: 'flat' }} onUpdate={onUpdate} />

            {hasSeasonalEditorAccess && seasonalInput && (
                <SeasonalSettings input={seasonalInput} onUpdate={onUpdate} hasTOU={hasTOU} />
            )}

            <div>
                <DeleteButton
                    text="Delete Rate"
                    onClick={onDelete}
                    intent={Intent.DANGER}
                    disabled={!auth.permissions.canModifySharedTeamResource(user, utilityRate)}
                />
            </div>
        </Card>
    );
};

export default OverviewPanel;
