import * as React from 'react';
import { useOutletContext } from 'react-router-dom';

import { MergeTeams as BetaMergeTeams } from 'reports/modules/settings/admin/components/MergeTeams';
import { SettingsContext } from './use-settings-data';

const MergeTeams = () => {
    const { team } = useOutletContext() as SettingsContext;
    return <BetaMergeTeams team={team} />;
};

export { MergeTeams };
