import Logger from 'js-logger';
import * as Q from 'q';
import PubSub from 'reports/utils/PubSub';
import { AngularScope } from 'reports/angular-bridge';

const logger = Logger.get('iFrameDriver');

type Events = 'urlChange';

export class IFrameDriver {
    pubsub = new PubSub<Events>();

    angular: AngularScope;
    iframe: HTMLIFrameElement;
    ngListeners: { (): void }[] = [];

    constructor(angularScope: AngularScope, iframe: HTMLIFrameElement) {
        this.angular = angularScope;
        this.iframe = iframe;

        this.ngListeners.push(
            // angularScope.$rootScope.$on(
            //     '$locationChangeSuccess', (_evt, newUrl, oldUrl) => this.pubsub.publish('urlChange', newUrl, oldUrl),
            // ),
            angularScope.$rootScope.$on('$stateChangeSuccess', () =>
                this.pubsub.publish('urlChange', this.angular.$location.url()),
            ),
        );
    }

    cleanup() {
        for (const listener of this.ngListeners) {
            listener();
        }
    }

    onUrlChange(cb: (url: string) => void) {
        return this.pubsub.subscribe('urlChange', cb);
    }

    goto(requestedUrl: string) {
        const initialUrl = this.angular.$location.url();

        if (requestedUrl === initialUrl) {
            if (this.angular.$state.transition !== null) {
                // the angular promise if the states are mid-transition
                return this.angular.$state.transition;
            }
            return Q.when(true);
        }
        const deferred = Q.defer();

        const unsubscribe = this.pubsub.subscribe('urlChange', (newUrl) => {
            if (newUrl === requestedUrl) {
                deferred.resolve();
                unsubscribe();
            }
        });

        logger.info(`redirecting from ${initialUrl} to ${requestedUrl}`);

        // this can be syncronous if there are no async dependencies, so make sure to do it last
        this.angular.$location.url(requestedUrl);
        if (!this.angular.$rootScope.$$phase) {
            this.angular.$rootScope.$apply();
        }

        // focus iframe after route changes so designer keyboard shortcuts stay enabled
        // when field segment selection changes
        this.iframe.contentWindow?.focus();

        return deferred.promise;
    }
}

export default IFrameDriver;
