import * as React from 'react';

import { Button, ButtonGroup, Classes, Icon, InputGroup, Spinner } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import NewProjectButton from 'reports/modules/project/components/NewProjectButton';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ProjectSearchContainer = styled.div`
    display: flex;

    & > *:not(:last-child) {
        padding-right: 10px;
    }

    .${Classes.INPUT_GROUP} {
        flex: 1;
    }
    .${Classes.BUTTON_GROUP} {
        margin-left: 4px;
        height: 30px;
    }
`;

interface IProjectSearch {
    filterArchived: boolean;
    filterShared: boolean;
    searching: boolean;
    searchValue: string;
    handleInputChange: (e: React.FormEvent<HTMLElement>) => void;
    toggleFilter: (filter: string) => void;
}

const ProjectSearch: React.SFC<IProjectSearch> = ({
    filterArchived,
    filterShared,
    handleInputChange,
    searchValue,
    searching,
    toggleFilter,
}) => (
    <ProjectSearchContainer>
        <div style={{ display: 'flex' }}>
            <InputGroup
                value={searchValue}
                onChange={handleInputChange}
                placeholder="Search projects..."
                leftIcon={IconNames.SEARCH}
                rightElement={searchValue && searching ? <Spinner size={Icon.SIZE_STANDARD} /> : undefined}
                type="text"
                style={{ width: 250, paddingRight: 6 }}
            />
            <ButtonGroup>
                <Button
                    text="Shared"
                    icon={IconNames.PEOPLE}
                    active={!filterShared}
                    onClick={() => toggleFilter('filterShared')}
                />
                <Button
                    text="Archived"
                    icon={IconNames.BOX}
                    active={!filterArchived}
                    onClick={() => toggleFilter('filterArchived')}
                />
            </ButtonGroup>
        </div>
        <NewProjectButton />
    </ProjectSearchContainer>
);

export default ProjectSearch;
