import * as React from 'react';
import FormField from '../../FormField';
import { ButtonGroupSelect, IButtonGroupSelectProps } from './ButtonGroupSelect';

type IProps<Item> = Omit<IButtonGroupSelectProps<Item>, 'selectedItem' | 'onSelect' | 'disabled'> & {
    path: string;
};

/**
 * A select displayed as a horizontal button group. The user clicks on one of N buttons: one button for each item.
 * Once a button has been clicked, that button is highlighted (using primary intent) to indicate the corresponding
 * item is "selected". The previously selected button returns to normal styling.
 *
 * This is meant to be just the input itself with no labels or grouping elements
 * The API for this is not fully baked, hence it's in the /experimental dir.
 */
const FormBareButtonGroupSelect = <Item,>({ path, items, itemRenderer, toolTipRenderer, fill }: IProps<Item>) => (
    <FormField path={path}>
        {({ onChange, value, form }) => (
            <ButtonGroupSelect
                fill={fill}
                items={items}
                selectedItem={value}
                itemRenderer={itemRenderer}
                toolTipRenderer={toolTipRenderer}
                onSelect={onChange}
                disabled={form.submitting}
            />
        )}
    </FormField>
);

export default FormBareButtonGroupSelect;
