import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import { fromNow } from 'reports/utils/formatters';

import { Project } from 'reports/models/project';
import { DetailsTable, WidgetDetailsTable } from 'reports/modules/report/components/helpers';
import Translations from 'reports/localization/strings';

const ProjectDetails: React.SFC<{ project: Project }> = ({ project }) => (
    <tbody>
        <tr>
            <th>
                <FormattedMessage {...Translations.project.address} />
            </th>
            <td>{project.address}</td>
        </tr>
        {project.customer_name ? (
            <tr>
                <th>
                    <FormattedMessage {...Translations.project.customer_name} />
                </th>
                <td>{project.customer_name}</td>
            </tr>
        ) : null}
        <tr>
            <th>
                <FormattedMessage {...Translations.project.owner} />
            </th>
            <td>{project.creator.fullName()}</td>
        </tr>
        <tr>
            <th>
                <FormattedMessage {...Translations.project.last_modified} />
            </th>
            <td>
                {project.last_modified_by_user != null
                    ? project.last_modified_by_user.fullName()
                    : project.creator.fullName()}
                <br />
                {fromNow(project.last_modified)}
            </td>
        </tr>
        <tr>
            <th>
                <FormattedMessage {...Translations.general.location} />
            </th>
            <td>
                ({project.location.latitude}, {project.location.longitude}) (
                <FormattedMessage {...Translations.general.tz_offset} values={{ offset: project.time_zone_offset }} />)
            </td>
        </tr>
        {project.ac_config_id ? (
            <tr>
                <th>
                    <FormattedMessage {...Translations.design.ac_interconnect} />
                </th>
                <td>{project.ac_config.name}</td>
            </tr>
        ) : null}
        {project.profile_id ? (
            <tr>
                <th>
                    <FormattedMessage {...Translations.project.profile} />
                </th>
                <td>{project.profile.name}</td>
            </tr>
        ) : null}
    </tbody>
);

const ProjectDetailTable: React.SFC<{ project: Project }> = ({ project }) => (
    <DetailsTable>
        <ProjectDetails project={project} />
    </DetailsTable>
);

interface IProjectDetailWidgetTable {
    project: Project;
    className?: string;
}

export const ProjectDetailWidgetTable: React.SFC<IProjectDetailWidgetTable> = ({ project, className }) => (
    <WidgetDetailsTable className={className}>
        <ProjectDetails project={project} />
    </WidgetDetailsTable>
);

export default ProjectDetailTable;
