import * as React from 'react';
import Colors from 'reports/styles/colors';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const StyledSection = styled.div`
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    & > .header {
        font-size: 18px;
        font-weight: 400;
        border-bottom: 1px solid ${Colors.BORDER_DARK};
        min-height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & > .subtitle {
        font-weight: 400;
        font-size: 13px;
        padding: 8px 0;
    }

    & > .body {
        padding: 12px 0 0 0;
    }
`;

interface IProps {
    title: string | React.ReactNode;
    subtitle?: string;
    contextEl?: React.ReactNode;
    className?: string;
}

/**
 * An enclosed section with a header, optional subtitle, and optional context element,
 * such as a dropdown or toggle. This can be used within various container elements,
 * like <Card>.
 *
 * @param title The title text
 * @param subtitle The subtitle text
 * @param contextEl An optional element, such as a select box
 */
export const Section: React.FC<IProps> = ({ title, subtitle, children, contextEl, className }) => (
    <StyledSection className={className}>
        <div className="header">
            <span className="title">{title}</span>

            <div className="context-el">{contextEl}</div>
        </div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
        <div className="body">{children}</div>
    </StyledSection>
);

export default Section;
