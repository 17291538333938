enum ReferrerTypes {
    add_projects_dialog = 'add_projects_dialog',
    billing_page = 'billing_page',
    checkout_dialog = 'checkout_dialog',
    expired_account_dialog = 'expired_account_dialog',
    manage_team_page = 'manage_team_page',
    new_project_button = 'new_project_button',
    project_limit_progress_bar = 'project_limit_progress_bar',
    upsell_dialog = 'upsell_dialog',
}

type ReferrerType = undefined | keyof typeof ReferrerTypes;

export { ReferrerType, ReferrerTypes };
