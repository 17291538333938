import * as React from 'react';
import { connect } from 'react-redux';

import { PrimaryIntent } from 'reports/components/core/controls';
import LibraryEditor from 'reports/components/core/layout/LibraryEditor';
import { promptModalBoolean } from 'reports/components/dialog';
import SaveDropdown from 'reports/components/library/SaveDropdown';
import UndoRedoManager from 'reports/components/library/UndoRedoManager';
import { ContextBarControls } from 'reports/components/ContextBar';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import { UpsellBanner } from 'reports/components/UpsellBanner';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';
import * as repCfg from 'reports/models/report/ReportConfiguration';

import * as auth from 'reports/modules/auth';
import { canModifySharedTeamResource } from 'reports/modules/auth/permissions';
import { actions } from 'reports/modules/report';
import { addPromiseToasts } from 'reports/modules/Toaster';

import { IAppState } from 'reports/store';

import { RouterStateLock } from 'reports/utils/router';
import { bindActions } from 'reports/utils/redux';

import DocumentEditor from './DocumentEditor';
import ProjectSelect from './ProjectSelect';
import ReportPageSettingsControls from './ReportPageSettingsControls';
import ReportUndoRedoButtons from './ReportUndoRedoButtons';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const StyledDocumentEditor = styled(DocumentEditor)`
    // 58px is the height of EditableHeader. Subtract that to make sure no extra scrollbar is introduced.
    height: calc(100% - 58px);
`;

interface IOwnProps {
    report: rep.Report;
    project: proj.Project;
    printableReady: Promise<any>;
}

type IProps = IOwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface IUndoRedoData {
    report: rep.Report;
    document: rep.Document;
}

const EditableHeader: React.FC<{
    name: string;
    onUpdate: (val: string) => any;
}> = ({ name, onUpdate }) => (
    <div className="content-header">
        <EditableTitleSubHeader value={name} updateFn={onUpdate} />
    </div>
);

const ReportEditor: React.FC<IProps> = (props) => {
    const { report: baseReport, project, printableReady, document: baseDocument, user, saveReport } = props;

    return (
        <LibraryEditor.Container style={{ padding: 0 }}>
            <UpsellBanner />
            <UndoRedoManager<IUndoRedoData>
                baseData={{ report: baseReport, document: baseDocument }}
                hasChangesFrom={(oldData, newData) =>
                    newData.document.hasChangesFrom(oldData.document) || newData.report.hasChangesFrom(oldData.report)
                }
                hotkeyGroup="Report Editor"
            >
                {({ data: { document, report }, setData, undo, redo, hasChanges, canUndo, canRedo, clearChanges }) => {
                    const save = async () => {
                        const configsCount = (await props.reportConfigsCount()).count;
                        if (configsCount > 0) {
                            await promptModalBoolean({
                                title: 'Update report with existing configurations?',
                                prompt: (
                                    <p>
                                        This report template currently has {configsCount} project{' '}
                                        {configsCount === 1 ? 'configuration' : 'configurations'}
                                        . Updating this template may override an existing configuration if any
                                        configured widgets have been removed.
                                        <br />
                                        <br />
                                        Would you like to save this report anyway?
                                    </p>
                                ),
                                cancellable: true,
                                yesLabel: 'Save',
                                yesIntent: PrimaryIntent.SAVE,
                                dialogProps: {
                                    // needed so title text doesn't overflow
                                    style: { width: 500 },
                                },
                            });
                        }
                        const newRep: rep.Report = await saveReport({
                            ...report,
                            document,
                        });
                        await clearChanges();
                        return newRep;
                    };

                    const saveWithToast = () =>
                        addPromiseToasts(save(), {
                            initial: `Saving ${report.name}`,
                            onCatch: `Error saving ${report.name}`,
                            onSuccess: `Successfully saved ${report.name}`,
                        });
                    const setDocument = (newDoc: rep.Document) => setData({ report, document: newDoc });
                    const setReport = (newRep: rep.Report) => setData({ document, report: newRep });

                    return (
                        <>
                            <RouterStateLock
                                title="Save report changes?"
                                prompt={
                                    `You have unsaved changes on ${report.name || 'this proposal'}` +
                                    `, would you like to save these changes before leaving?`
                                }
                                cancellable={true}
                                showExitPrompt={hasChanges}
                                onSave={() => saveWithToast()}
                                onDontSave={clearChanges}
                            />
                            <ContextBarControls>
                                <ReportUndoRedoButtons
                                    undo={undo}
                                    canUndo={canUndo()}
                                    redo={redo}
                                    canRedo={canRedo()}
                                />
                                <SaveDropdown
                                    saveEnabled={hasChanges() && canModifySharedTeamResource(user, report)}
                                    save={() => {
                                        // Don't use saveWithToast() here. SaveDropdown does its own toast handling.
                                        return save().then(() => props.setEditing(false));
                                    }}
                                    cancel={async () => {
                                        await clearChanges();
                                        props.setEditing(false);
                                    }}
                                    saveAsNewOptions={{
                                        text: 'Save as New Report',
                                        saveAsNew: async () => {
                                            // There's no need to show a toast here. SaveDropdown does its own toast
                                            // handling.
                                            const newReport = await props.saveAsNew(document);
                                            await clearChanges();
                                            props.openReport(newReport);
                                        },
                                    }}
                                />
                            </ContextBarControls>
                            <EditableHeader
                                name={report.name}
                                onUpdate={(name) => setReport(new rep.Report({ ...report, name }))}
                            />
                            <StyledDocumentEditor
                                mode="edit"
                                report={report}
                                document={document}
                                setDocument={setDocument}
                                project={project}
                                printableReady={printableReady}
                                controls={
                                    <>
                                        <ProjectSelect selectedProject={project} />
                                        <div style={{ marginRight: 'auto' }} />
                                        <ReportPageSettingsControls document={document} onUpdate={setDocument} />
                                    </>
                                }
                            />
                        </>
                    );
                }}
            </UndoRedoManager>
        </LibraryEditor.Container>
    );
};

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => ({
    user: auth.selectors.getUser(state)!,
    document: rep.versionSelectors.latestDocument(state, ownProps.report),
});

const mapDispatchToProps = bindActions(({ report, project }: IOwnProps) => ({
    saveReport: (formData) => rep.api.save(formData),
    saveAsNew: (document) => actions.saveAsNew(report, document),
    setEditing: actions.setEditing,
    openReport: (newRep) => actions.open(newRep, false, project.project_id),
    reportConfigsCount: () => repCfg.api.configsCount({ report_id: report.report_id }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ReportEditor);
