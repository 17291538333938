import _ from 'lodash';

import * as React from 'react';

import { Icon, Intent, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { AuthErrorCode } from 'reports/modules/financials/model/pipeline';

import { ErrorMessages } from './index';

const WarningTooltip: React.SFC<{ error: AuthErrorCode }> = ({ error }) => (
    <Tooltip content={_.get(ErrorMessages, `${error}.name`, 'Unauthorized')} position={Position.TOP_LEFT}>
        <Icon icon={IconNames.WARNING_SIGN} intent={Intent.WARNING} style={{ paddingRight: '4px' }} />
    </Tooltip>
);

export default WarningTooltip;
