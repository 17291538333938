import { assign } from 'lodash';
import * as React from 'react';

import { MaybeElement } from '@blueprintjs/core';

import * as finTemp from 'reports/models/financial_template';

import { AuthErrorCode, FinancialPipelineTree, Node } from 'reports/modules/financials/model/pipeline';
import { PipelineEditor } from 'reports/modules/financials/components/pipelineCommon';

import { ModuleCategory } from 'reports/modules/financials/model/modules';
import TemplateCategory from './TemplateCategory';
import PreviewStep from './PreviewStep';

interface IProps {
    template: finTemp.FinancialTemplate;
    advanced?: boolean;
}

const FinancialTemplatePreview: React.SFC<IProps> = ({ template, advanced = false }) => {
    const previewTemplate = assign({}, template);

    const tree = FinancialPipelineTree.fromRawData(previewTemplate.data.root);

    const renderRoot = (_node, children) => {
        return <div>{children}</div>;
    };

    const renderCategory = (category: ModuleCategory, children: MaybeElement[], _index: number[]) => (
        <TemplateCategory title={category} minimal={true}>
            {...children}
        </TemplateCategory>
    );

    const renderLeaf = (node: Node, nindex: number[]) => <PreviewStep key={nindex.toString()} node={node} />;

    const renderLeafError = (error: AuthErrorCode, node: Node, nindex: number[]) => (
        <PreviewStep key={nindex.toString()} node={node} error={error} />
    );

    return (
        <PipelineEditor
            tree={tree}
            renderLeaf={renderLeaf}
            renderLeafError={renderLeafError}
            renderCategory={advanced ? null : renderCategory}
            renderRoot={renderRoot}
        />
    );
};

export default FinancialTemplatePreview;
