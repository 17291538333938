import * as React from 'react';

import { promptModalBoolean } from 'reports/components/dialog';
import { PrimaryIntent } from 'reports/components/core/controls';
import { addPromiseToasts } from 'reports/modules/Toaster';

export const promptRestartTrial = async (user, team, restartTrial, reloadTeam) => {
    const shouldRestart = await promptModalBoolean({
        title: `Restart Trial`,
        prompt: (
            <p>
                This will delete all subscriptions that <b>{user.fullName()}</b> belongs to and restart the free trial
                for any former subscribers of those deleted subscriptions. This feature is only available on test
                environments.
            </p>
        ),
        cancellable: true,
        yesLabel: 'Restart Trial',
        yesIntent: PrimaryIntent.DELETE,
    });

    if (shouldRestart) {
        await addPromiseToasts(restartTrial(user), {
            initial: 'Restarting trial',
            onSuccess: 'Successfully restarted trial.',
            onCatch: 'Error restarting trial.',
        });
        reloadTeam(team);
    }
};
