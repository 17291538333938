import * as React from 'react';

import { useRoute } from 'react-router5';

import { RouteComponent } from 'reports/routing';

import { MergeTeams } from 'reports/modules/settings/admin/components';
import { SettingsPane } from 'reports/modules/settings/common';
import { TeamFeatures } from 'reports/modules/settings/team/components';
import { UserFeatures } from 'reports/modules/settings/admin/views/UserFeatures';

import { SubscriptionView } from 'reports/modules/settings/admin/views/SubscriptionView';

import { Team } from 'reports/models/team';
import { User } from 'reports/models/user';

interface Props {
    team: Team;
    user: User;
}

const AdminSettingsViews = React.memo(({ team, user }: Props) => {
    const { route } = useRoute();
    return (
        <SettingsPane
            content={
                <>
                    <RouteComponent name="app.settings.hs-admin.subscription">
                        {({ subscription }) => (
                            <SubscriptionView
                                createContract={!!route.params.createContract}
                                subscription={subscription}
                                team={team}
                                user={user}
                            />
                        )}
                    </RouteComponent>
                    <RouteComponent name="app.settings.hs-admin.merge-teams">
                        <MergeTeams team={team} />
                    </RouteComponent>
                    <RouteComponent name="app.settings.hs-admin.team-features">
                        <TeamFeatures team={team} user={user} />
                    </RouteComponent>
                    <RouteComponent name="app.settings.hs-admin.user-features">
                        <UserFeatures user={user} />
                    </RouteComponent>
                </>
            }
        />
    );
});

export default AdminSettingsViews;
