import * as React from 'react';

import { Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { CreateButton } from 'reports/components/core/controls';

interface ICreateButtonDropdown<T> {
    createText: string;
    items: T[];
    itemRenderer: (item: T) => string;
    onItemSelect: (item: T) => void;
}

export const CreateButtonDropdown = <T,>(props: ICreateButtonDropdown<T>) => {
    const { createText, items, itemRenderer, onItemSelect } = props;
    const menu = (
        <Menu>
            {items.map((item, index) => (
                <MenuItem key={index} text={itemRenderer(item)} onClick={() => onItemSelect(item)} />
            ))}
        </Menu>
    );
    return (
        <Popover2 content={menu} position={Position.BOTTOM_RIGHT}>
            <CreateButton text={createText} rightIcon="caret-down" />
        </Popover2>
    );
};
