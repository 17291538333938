import * as React from 'react';

import { capitalize } from 'lodash';
import { LibraryConsumer } from 'reports/components/library/main/context';
import { InlineFormGroup } from 'reports/components/helpers/formHelpers';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';

export interface IProps {
    label: string;
    // Currently, only "string" items are supported. Items list is expected to not change after component is mounted.
    items: string[];

    emptyValue: string;
    filterName: string;
    buttonProps: object;
}

interface IState {
    itemsWithEmpty: string[];
}

export default class FilterSelect extends React.PureComponent<IProps, IState> {
    state: IState = {
        itemsWithEmpty: [this.props.emptyValue].concat(this.props.items),
    };

    render() {
        const { label, emptyValue, filterName, buttonProps } = this.props;
        return (
            <LibraryConsumer>
                {({ setFilter, clearFilter, filters }) => (
                    <InlineFormGroup label={label}>
                        <StaticSelect<string>
                            items={this.state.itemsWithEmpty}
                            itemRenderer={(item, { handleClick, modifiers }) => {
                                return (
                                    <DropdownMenuItem
                                        title={capitalize(item)}
                                        key={item}
                                        onClick={handleClick}
                                        {...modifiers}
                                    />
                                );
                            }}
                            activeItem={capitalize(filters[filterName]) || emptyValue}
                            buttonText={capitalize(filters[filterName]) || emptyValue}
                            buttonProps={buttonProps}
                            onItemSelect={(item) => {
                                if (item === emptyValue) {
                                    return clearFilter(filterName);
                                }
                                return setFilter(filterName, item);
                            }}
                        />
                    </InlineFormGroup>
                )}
            </LibraryConsumer>
        );
    }
}
