/* tslint:disable:variable-name */
import { first } from 'lodash';
import moment from 'moment';

import { isEqualWithoutFuncs } from 'reports/utils/helpers';
import { ReduxEndpoint, BaseClass, createAsyncSaver, defaults, ensureProperty } from 'reports/utils/api';
import { ILocale, DEFAULT_LOCALE } from 'reports/localization';

import * as team from 'reports/models/team';
import * as user from 'reports/models/user';

import type Document from './Document';
import type ReportVersion from './ReportVersion';
import type ReportConfiguration from './ReportConfiguration';
import { schema } from '../schema';
import { IAPIQueryOpts } from '../types';

export class Report extends BaseClass {
    report_id: number;
    name: string;
    description: string;
    locale: ILocale;
    slug: string;

    team_id: number;
    team?: team.Team;
    public: boolean;

    latest_version_id: number;

    versions?: ReportVersion[];
    report_configurations?: ReportConfiguration[];

    // TODO: Change backend serialization to return file_urls on ReportVersion instead of Report.
    // file_urls are tied to the latest ReportVersion. It doesn't make much sense for them to be returned on the Report
    // besides convenience.
    file_urls?: { [k: string]: string };
    team_settings: ReportTeam;

    created?: moment.Moment;
    last_modified?: moment.Moment;
    creator_id?: number;
    creator?: user.User;
    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    soft_deleted: boolean;
    basic_report: boolean;

    bookmarked = () => this.team_settings.bookmarked;

    constructor(data) {
        super(Report.deserializer(data));
    }

    // Used in Report.tsx:updatedHistory
    hasChangesFrom = (report: Report) => !isEqualWithoutFuncs(this, report);

    static deserializer = BaseClass.getDeserializer({
        created: (x) => moment(x),
        last_modified: (x) => moment(x),
        team_settings: ensureProperty((teamData, { report_id }) => new ReportTeam({ report_id, ...teamData })),
        locale: defaults(DEFAULT_LOCALE),
    });
}

class ReportTeam extends BaseClass {
    report_id: number;
    team_id?: number;
    bookmarked: boolean;

    constructor(data) {
        super(ReportTeam.deserializer(data));
    }

    static deserializer = defaults({
        bookmarked: false,
    });
}

const schemaObj = schema.addObject(Report, 'report', {
    relationships: {
        creator: { schema: user.schemaObj },
        last_modified_by_user: { schema: user.schemaObj },
        team: { schema: team.schemaObj },
    },
});

export interface IReportForm {
    name: string;
    description?: string;

    // TODO: move to version sepecific API
    document?: Document;
}

const endpoint = ReduxEndpoint.fromSchema('/api/reports/', schemaObj);

interface IReportAPIQueryOpts extends IAPIQueryOpts {
    name?: string;
    description?: string;
    slug?: string;
    public?: boolean;
    bookmarked?: boolean;
    basic_report?: boolean;
}

interface IReportAPIGetOpts {
    report_id: number | string;
    load_files?: boolean;
    project_id?: number | string;
}

const api = {
    index: endpoint.index<IReportAPIQueryOpts | { as_user?: string }>(),
    get: endpoint.get<IReportAPIGetOpts>('{report_id}'),
    create: endpoint.post<IReportForm>(),
    save: endpoint.put('{report_id}'),
    delete: endpoint.delete('{report_id}'),
    patchTeamSettings: endpoint.patch<Partial<ReportTeam>, Partial<ReportTeam>>('{report_id}/team_settings', {
        onSuccess: (rawData) =>
            schemaObj.dataLoaded({
                report_id: rawData.report_id,
                team_settings: rawData,
            }),
    }),
};

const selectors = {
    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
    bySlug: (state, slug, deep?) =>
        first(
            schemaObj.selectAll(state, {
                deep,
                filter: (x) => x.slug === slug,
            }),
        ),
};

const saver = createAsyncSaver(schemaObj, api.save);

export default Report;
export { selectors, api, saver, schemaObj };
