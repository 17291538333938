/* tslint:disable:variable-name function-name */
import moment from 'moment';

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { schema } from './schema';
import * as design from './design';
import * as simulation from './simulation';

class DesignSnapshot extends BaseClass {
    design_snapshot_id: number;

    design_id: number;
    design: design.Design;

    simulation_id: number;
    simulation: simulation.Simulation;

    file_id: number;

    settings: any;
    width: number;
    height: number;

    constructor(data) {
        super(DesignSnapshot.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        created: (x) => moment(x),
        last_modified: (x) => moment(x),
    });
}

export interface IDesignSnapshotForm {
    design_id: number;
    file_id: number;
    settings: any;
    width: number;
    height: number;
}

const schemaObj = schema.addObject(DesignSnapshot, 'design_snapshot', {
    relationships: {
        design: { schema: design.schemaObj },
        simulation: { schema: simulation.schemaObj },
    },
});

const endpoint = ReduxEndpoint.fromSchema('/api/design_snapshots/', schemaObj);

const api = {
    index: endpoint.index<{ design_id: number }>(),
    get: endpoint.get<{ design_snapshot_id: number | string }>('{design_snapshot_id}'),
    create: endpoint.post<IDesignSnapshotForm>(),
    save: endpoint.put<IDesignSnapshotForm>('{design_snapshot_id}'),
};

const selectors = {
    all: schemaObj.selectAll,
};

export { api, endpoint, DesignSnapshot, schemaObj, selectors };
