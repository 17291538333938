import * as React from 'react';
import { useDispatch } from 'react-redux';

import { RouterStateLock } from 'reports/utils/router';

import * as usr from 'reports/models/user';

import { SaveCancelButtons } from 'reports/components/core/controls';
import { Form, NestedFields } from 'reports/components/forms';
import { FormBasicSelect } from 'reports/components/forms/inputs/experimental';
import { addPromiseToasts } from 'reports/modules/Toaster';

// Types were copied to TeamFeatures.tsx
interface IFeatureOpt {
    name: string;
    key: boolean | undefined;
}

const FEATURE_OPTS: IFeatureOpt[] = [
    {
        key: undefined,
        name: 'Auto',
    },
    {
        key: true,
        name: 'Enabled',
    },
    {
        key: false,
        name: 'Disabled',
    },
];

interface Props {
    user: usr.User;
    features: any;
}

const UserFeaturesForm = ({ user, features }: Props) => {
    const dispatch = useDispatch();

    const updateUser = (form: usr.IUserForm) =>
        dispatch(usr.api.save({ ...form, email: user.email }, { action: 'update_preferences' }));

    const onSubmit = async (formData: usr.IUserForm) => {
        await addPromiseToasts(updateUser(formData), {
            initial: 'Saving user settings...',
            onSuccess: 'Successfully saved user settings.',
            onCatch: 'Error saving user settings.',
        });
    };

    return (
        <Form baseValue={user} onSubmit={onSubmit}>
            {({ dirty, submitForm, clearForm }) => (
                <>
                    <RouterStateLock
                        title="Save User Settings?"
                        prompt="You have unsaved changes on user settings."
                        cancellable={true}
                        showExitPrompt={dirty}
                        onSave={submitForm}
                        onDontSave={clearForm}
                    />
                    <NestedFields path="preferences">
                        <NestedFields path="features">
                            {features.map((exp) => (
                                <FormBasicSelect<IFeatureOpt>
                                    inline
                                    label={exp.description}
                                    path={exp.name}
                                    key={exp.name}
                                    dataSource={{
                                        items: FEATURE_OPTS,
                                        keyLookup: (item) => item.key,
                                    }}
                                    maxButtonWidth={100}
                                    labelWidth={425}
                                    itemRenderer={({ key, name }) => ({
                                        key: String(key),
                                        text: name,
                                    })}
                                />
                            ))}
                        </NestedFields>
                    </NestedFields>

                    <SaveCancelButtons
                        onSave={submitForm}
                        hasChanges={dirty}
                        onCancel={clearForm}
                        style={{ marginBottom: 10 }}
                    />
                </>
            )}
        </Form>
    );
};

export default UserFeaturesForm;
