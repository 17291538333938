import Logger from 'js-logger';
import { get } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';

import { Button, Checkbox } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';
import { DeleteButton } from 'reports/components/core/controls';
import FormattedEditor from 'reports/components/helpers/FormattedEditor';

import * as auth from 'reports/modules/auth';
import * as ur from 'reports/models/utility_rate';

import { IAppState } from 'reports/types';
import { IParamProps, ParamValueType } from 'reports/modules/financials/params';
import { getRateParam } from 'reports/modules/financials/utils';

import { IRatesConfig, isEnergyRate, getRateColor, RateElement, RateSection } from './ParamRatesFull';
import { INumericConfig, ParamNumeric } from './ParamNumeric';
import { IColumn, ITableConfig } from './ParamTable';
import { ParamTiers } from 'reports/modules/financials/components/ParamTiers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const logger = Logger.get('rates');

const FormInlineElement = styled(Flex.Container).attrs({ className: 'center' })`
    & > div {
        padding: 4px;
    }
`;

const DEFAULT_NM_FLAT_RATE = 0.0;

const DEFAULT_NM_OPTIONS: ur.NetMeterType = {
    net_meter_type: 'flat_rate',
    nonbypassable_per_kwh: 0.0,
    annual_true_up: null,
};

const DEFAULT_ATU_OPTIONS: ur.AnnualTrueUpType = {
    net_export_rate_kwh: 0.0,
};

const netMeterParamConfig: INumericConfig = {
    type: ParamValueType.Currency,
    min_value: 0.0,
    max_value: 999.0,
    default: 0.0,
    precision: 4,
};

function getDefaultRateSchedule(isEnergy: boolean): ur.RateSchedule {
    const paramConfig = getRateParam(isEnergy ? 'energy_rates' : 'demand_rates');
    const defaultTier = get(paramConfig, 'default.rate_schedules[0].rate_tiers[0]')!;

    return {
        rate_tiers: [defaultTier],
        ...(isEnergy ? { net_meter_flat: 0.0 } : {}),
    };
}

export function getDefaultRateColumns(isEnergy: boolean): IColumn[] {
    const units = isEnergy ? 'kWh' : 'kW';
    return [
        {
            description: `Rate ($/${units})`,
            path: 'tier_value',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 999.0,
            default: 0.0,
            precision: 4,
        },
        {
            description: `Tier Cap (${units})`,
            path: 'tier_cap',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
        {
            description: `Absolute Cap (${units})`,
            path: 'abs_cap',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
    ];
}

export function getRateTableConfig(isEnergy: boolean): ITableConfig {
    return {
        type: ParamValueType.Table,
        min_rows: 1,
        max_rows: 99,
        sort_path: 'tier_cap',
        max_null: true,
        default: [],
        columns: getDefaultRateColumns(isEnergy),
    };
}

interface IOwnProps extends IParamProps<IRatesConfig> {
    onUpdate: (propPath: string, value: any) => any;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;

class ParamRatesList extends React.PureComponent<IProps> {
    isEnergyRate: boolean = isEnergyRate(this.props.value);

    defaultRateSchedule: ur.RateSchedule = getDefaultRateSchedule(this.isEnergyRate);
    rateTableConfig: ITableConfig = getRateTableConfig(this.isEnergyRate);
    stashedAnnualTrueUp: ur.AnnualTrueUpType = DEFAULT_ATU_OPTIONS;

    render() {
        const { parameter, value, disabled, user } = this.props;

        const { net_meter_parameters: netMeterParams = {} } = parameter;
        const { rate_schedules: rateSchedules } = value;

        const netMeterOpts = this.isEnergyRate && (value as ur.IEnergyConfig).net_meter_options;
        const noAddRate = rateSchedules.length >= 99;
        const noDel = rateSchedules.length <= 1;
        const annualTrueUp = netMeterOpts && netMeterOpts.annual_true_up;

        return (
            <div>
                {this.isEnergyRate && netMeterParams.flat_rate && (
                    <>
                        <RateElement>
                            <Checkbox
                                checked={!!netMeterOpts}
                                onChange={() => this.toggleNetMetering(!netMeterOpts)}
                                label="Apply Net Metering"
                                disabled={disabled}
                                style={{ marginBottom: 0 }}
                            />
                            {netMeterOpts ? (
                                <FormInlineElement>
                                    <div>Nonbypassable Charges (per kWh)</div>
                                    <div>
                                        <ParamNumeric
                                            parameter={netMeterParamConfig}
                                            value={netMeterOpts.nonbypassable_per_kwh}
                                            updateFn={(val) =>
                                                this.props.onUpdate('net_meter_options.nonbypassable_per_kwh', val)
                                            }
                                            disabled={disabled}
                                        />
                                    </div>
                                </FormInlineElement>
                            ) : null}
                        </RateElement>
                        {user?.hasFeature('annual_true_up') && (
                            <>
                                <RateElement>
                                    <Checkbox
                                        checked={!!annualTrueUp}
                                        onChange={
                                            // This would be disabled if netMeterOpts was false, so we can safely cast
                                            () => this.toggleAnnualTrueUp(netMeterOpts as ur.NetMeterType)
                                        }
                                        label="Apply Annual True-Up"
                                        disabled={disabled || !netMeterOpts}
                                        style={{ marginBottom: 0 }}
                                    />
                                    {annualTrueUp && (
                                        <FormInlineElement>
                                            <div>Net Export Rate (per kWh)</div>
                                            <div>
                                                <ParamNumeric
                                                    parameter={netMeterParamConfig}
                                                    value={annualTrueUp.net_export_rate_kwh}
                                                    updateFn={(val) =>
                                                        this.props.onUpdate(
                                                            'net_meter_options.annual_true_up.net_export_rate_kwh',
                                                            val,
                                                        )
                                                    }
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </FormInlineElement>
                                    )}
                                </RateElement>
                            </>
                        )}
                    </>
                )}
                {(rateSchedules as ur.RateSchedule[]).map((rateSchedule, idx) => (
                    <RateElement key={idx}>
                        <Flex.Container className="center">
                            <DeleteButton onClick={() => this.deleteRate(idx)} disabled={noDel || disabled} />
                            <div
                                style={{
                                    margin: '0px 4px',
                                    padding: '2px 4px',
                                    fontWeight: 500,
                                    backgroundColor: getRateColor(idx),
                                }}
                            >
                                <span style={{ marginRight: '8px' }}>{idx + 1})</span>
                                <FormattedEditor
                                    value={get(rateSchedules[idx], 'name', 'Rate')}
                                    onConfirm={(val: string) => this.updateRateField(idx, 'name', val)}
                                    selectAllOnFocus={true}
                                    showIcon={true}
                                />
                            </div>
                        </Flex.Container>
                        <div style={{ padding: '2px 8px' }}>
                            <ParamTiers
                                parameter={this.rateTableConfig}
                                value={rateSchedule.rate_tiers}
                                updateFn={(val) => this.updateRateField(idx, 'rate_tiers', val)}
                                disabled={disabled}
                            />
                        </div>
                        {netMeterOpts && netMeterOpts.net_meter_type === 'flat_rate' && (
                            <FormInlineElement>
                                <div>Net Meter Rate (per kWh)</div>
                                <div>
                                    <ParamNumeric
                                        value={(rateSchedule as ur.IEnergyRateSchedule).net_meter_flat}
                                        parameter={netMeterParamConfig}
                                        updateFn={(val) => this.updateRateField(idx, 'net_meter_flat', val)}
                                        disabled={disabled}
                                    />
                                </div>
                            </FormInlineElement>
                        )}
                    </RateElement>
                ))}
                <RateSection>
                    <Button
                        text="Add Rate"
                        icon={IconNames.ADD}
                        disabled={noAddRate || disabled}
                        onClick={this.addRate}
                    />
                </RateSection>
            </div>
        );
    }

    addRate = () => {
        const { value } = this.props;
        const rateSchedules = [...value.rate_schedules];

        rateSchedules.push(this.defaultRateSchedule);
        this.props.onUpdate('rate_schedules', rateSchedules);
    };

    deleteRate = (idx: number) => {
        const rateSchedules = [...this.props.value.rate_schedules];

        if (rateSchedules.length === 1) {
            // Surface error when form validation is in.
            logger.warn('At least one rate schedule must be defined.');
            return;
        }

        rateSchedules.splice(idx, 1);

        this.removeRateFromTables(idx);
        this.props.onUpdate('rate_schedules', rateSchedules);
    };

    // Remove rate schedule from tables and decrease subsequent rate schedules ids by 1
    removeRateFromTables = (rateIdx: number) => {
        const rateId = rateIdx + 1;
        const updatedTables = [...this.props.value.tables];

        for (const t of updatedTables) {
            t.table = t.table.map((rId) => {
                if (rId === rateId) {
                    return 1;
                }
                if (rId > rateId) {
                    return rId - 1;
                }
                return rId;
            });
        }
        this.props.onUpdate('tables', updatedTables);
    };

    toggleNetMetering(toggle: boolean) {
        // Only available for energy rates
        const { onUpdate, parameter, value } = this.props;

        const { net_meter_parameters: netMeterParams } = parameter;
        if (!netMeterParams) throw new Error();

        let netMeterOpts;
        if (toggle) {
            if (netMeterParams.flat_rate) {
                netMeterOpts = DEFAULT_NM_OPTIONS;

                const rateSchedules = [...value.rate_schedules] as ur.IEnergyRateSchedule[];
                for (const rateSchedule of rateSchedules) {
                    rateSchedule.net_meter_flat = DEFAULT_NM_FLAT_RATE;
                }
                onUpdate('rate_schedules', rateSchedules);
            } else {
                // Does this ever happen?
                throw new Error();
            }
        } else {
            netMeterOpts = null;
        }

        onUpdate('net_meter_options', netMeterOpts);
    }

    toggleAnnualTrueUp(netMeterOpts: ur.NetMeterType) {
        const { onUpdate } = this.props;

        if (netMeterOpts.annual_true_up) {
            this.stashedAnnualTrueUp = netMeterOpts.annual_true_up;
        }

        const annualTrueUp = netMeterOpts.annual_true_up ? null : this.stashedAnnualTrueUp;

        onUpdate('net_meter_options', {
            ...netMeterOpts,
            annual_true_up: annualTrueUp,
        });
    }

    updateRateField = (rateIdx: number, field: string, value: any) => {
        this.props.onUpdate(`rate_schedules[${rateIdx}].${field}`, value);
    };
}

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state),
});

export default connect(mapStateToProps, null)(ParamRatesList);
