import * as React from 'react';

import ReactHighcharts from 'react-highcharts';
import Highcharts from 'highcharts';

import { ILayout } from 'reports/modules/report/components/layout';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const CHART_PADDING = 3; // charts are hard to drag because they take over click events, so create a buffer
export const DEFAULT_LAYOUT_CHART_HEIGHT = 250;

const LayoutChartContainer = styled.div`
    text-align: center;
    padding: ${CHART_PADDING}px;
    position: relative;
    width: 100%;
    height: ${DEFAULT_LAYOUT_CHART_HEIGHT}px; /* pick an arbitrary default */

    div[data-highcharts-chart] {
        position: absolute;
        width: 100%;
        height: 100%;
    }
`;

const { wrap, Pointer } = Highcharts as any;

wrap(Pointer.prototype, 'normalize', function (this: any, proceed, event, chartPosition) {
    /*
        Automatically scale the pointer events based on the widget scale
        https://github.com/highcharts/highcharts/issues/2405
        could inject scale from LayoutConsumer if we're worried about performance, but this seems sufficient
    */
    const e = proceed.call(this, event, chartPosition);

    const element = this.chart.container;
    if (element && element.offsetWidth && element.offsetHeight) {
        const { width, height } = element.getBoundingClientRect();

        const scaleX = width / element.offsetWidth;
        const scaleY = height / element.offsetHeight;

        if (scaleX !== 1) {
            e.chartX = Math.round(e.chartX / scaleX);
        }
        if (scaleY !== 1) {
            e.chartY = Math.round(e.chartY / scaleY);
        }
    }
    return e;
});

export interface ILayoutChartProps {
    layout?: ILayout;
    mouseEvents?: boolean;
    style?: React.CSSProperties;
}

interface IOwnProps extends ILayoutChartProps {
    config: Highcharts.Options;
}

export class LayoutChart extends React.PureComponent<IOwnProps> {
    chartRef = React.createRef<ReactHighcharts>();

    static defaultProps = {
        mouseEvents: true,
    };

    setSize() {
        if (this.chartRef.current == null) return;

        this.chartRef.current.getChart().setSize(null, null, false);
    }

    componentDidMount() {
        if (this.props.layout != null) {
            setTimeout(() => this.setSize(), 25); // Hack to ensure initial layout sizes correctly
        }
    }

    componentDidUpdate(lastProps) {
        const layout = this.props.layout;
        const oldLayout = lastProps.layout;

        if (layout != null && oldLayout !== layout) {
            if (
                oldLayout != null &&
                oldLayout.w === layout.w &&
                oldLayout.h === layout.h &&
                this.props.config === lastProps.config
            ) {
                return;
            }
            setTimeout(() => this.setSize()); // remove resize from any render loops
        } else if (this.props.config !== lastProps.config) {
            setTimeout(() => this.setSize()); // remove resize from any render loops
        }
    }

    render() {
        // default to allowing mouse events
        const { config, layout, style, mouseEvents } = this.props;

        const padding = CHART_PADDING * 2;
        const componentStyle = layout != null ? { width: layout.w - padding, height: layout.h - padding } : {};
        if (mouseEvents === false) {
            componentStyle['pointerEvents'] = 'none';
        }

        const fullStyle = style != null ? { ...style, ...componentStyle } : componentStyle;

        return (
            <LayoutChartContainer style={fullStyle}>
                <ReactHighcharts config={config} isPureConfig={true} ref={this.chartRef} />
            </LayoutChartContainer>
        );
    }
}

export default LayoutChart;
