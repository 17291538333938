import * as React from 'react';

import { IWidget, IWidgetConfig, IWidgetRenderProps } from 'reports/modules/report/widgets';

import { ILayout } from './layout';
import WidgetErrorBoundary from './WidgetErrorBoundary';

interface IWidgetContent {
    renderProps: IWidgetRenderProps;
    selected: boolean;
    widget: IWidget;
    setPendingConfig: (pendingConfig: IWidgetConfig) => void;
    commitConfig: (config?: IWidgetConfig) => void;
    rollbackConfig: () => void;
    className?: string;
}

const WidgetContent: React.FC<IWidgetContent> = (props) => {
    const { renderProps, selected, widget, setPendingConfig, commitConfig, rollbackConfig, className } = props;
    const { Component, EditingComponent } = widget;
    const { config } = renderProps;

    return (
        <WidgetErrorBoundary>
            {selected && EditingComponent ? (
                <EditingComponent
                    {...renderProps}
                    className={className}
                    updateWidgetContent={(content: object) =>
                        setPendingConfig({
                            ...config,
                            content: { ...config.content, ...content },
                        })
                    }
                    updateWidgetLayout={(layout: ILayout) =>
                        setPendingConfig({
                            ...config,
                            layout: { ...config.layout, ...layout },
                        })
                    }
                    commitConfig={commitConfig}
                    rollbackConfig={rollbackConfig}
                />
            ) : (
                <Component className={className} {...renderProps} />
            )}
        </WidgetErrorBoundary>
    );
};

export default WidgetContent;
