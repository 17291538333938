import * as React from 'react';
import moment from 'moment';

import { Callout, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as ext from 'reports/models/subscription_extension';

import { FormErrorCallout } from 'reports/components/helpers/errors';
import { FormBareSwitchInput, FormDatePicker, FormTextAreaInput } from 'reports/components/forms/inputs/experimental';

import { ControlContainer, ControlPrimaryText, HelperText, SectionContainer } from 'reports/modules/settings/common';

interface Props {
    disabled: boolean;
    formErrors: string[];
    minDate: Date;
    subscriptionExtension?: ext.SubscriptionExtension;
    subscriptionExtensionEnabled: boolean;
}

const SelfServeExtension = ({
    disabled,
    formErrors,
    minDate,
    subscriptionExtension,
    subscriptionExtensionEnabled,
}: Props) => {
    const maxDate = moment(minDate).add(1, 'year').toDate();
    return (
        <>
            <SectionContainer disabled={disabled}>
                <div>
                    <ControlPrimaryText>Extend Subscription</ControlPrimaryText>
                    <HelperText>
                        {subscriptionExtension
                            ? 'This will update the existing extension. To remove a current extension, toggle off.'
                            : 'This will extend the team subscription.'}
                    </HelperText>
                </div>
                <ControlContainer>
                    <FormBareSwitchInput path="subscription_extension_enabled" disabled={disabled} />
                </ControlContainer>
            </SectionContainer>
            {subscriptionExtensionEnabled && (
                <>
                    {subscriptionExtension && (
                        <Callout icon={IconNames.INFO_SIGN} intent={Intent.PRIMARY} style={{ marginBottom: 10 }}>
                            <div>
                                Current extension scheduled until{' '}
                                {subscriptionExtension.end_date.format('MMM DD, YYYY')}
                            </div>
                        </Callout>
                    )}
                    <FormDatePicker
                        inline
                        bold
                        label="Extension End Date"
                        path="end_date"
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                    <FormTextAreaInput fill inline bold path="reason" label="Reason" labelWidth={185} />
                    {formErrors.length > 0 && (
                        <FormErrorCallout errorMsg={formErrors[0]} style={{ marginBottom: '15px' }} />
                    )}
                </>
            )}
        </>
    );
};

export default SelfServeExtension;
