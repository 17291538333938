/* tslint:disable:variable-name function-name */
import moment from 'moment';

import { BaseClass, createAsyncSaver, ReduxEndpoint } from 'reports/utils/api';
import { DeepPartial } from 'reports/types';

import { schema } from './schema';
import { S3File } from './s3file';
import * as user from './user';

interface IThemeAPIQueryOpts {
    public?: boolean;
    team_id?: number;
    user_id?: number;
}

export interface IThemeForm {
    name: string;
    team_id?: number;
    logo_id?: number;
    palette?: { colors: string[] };
    primary_color?: string;
    secondary_color?: string;
    public?: boolean;
}

class Theme extends BaseClass {
    theme_id: number;

    team_id: number;
    logo?: S3File;
    logo_id?: number;
    logo_url?: string;

    name: string;
    primary_color?: string;
    secondary_color?: string;
    palette?: { colors: string[] };

    public?: boolean;

    created?: moment.Moment;
    last_modified?: moment.Moment;

    creator_id?: number;
    creator?: user.User;

    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    constructor(data: any) {
        super(Theme.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        last_modified: (x) => moment(x),
        created: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(Theme, 'theme');
const endpoint = ReduxEndpoint.fromSchema('/api/themes/', schemaObj);

const api = {
    index: endpoint.index<IThemeAPIQueryOpts>(),
    get: endpoint.get<{ theme_id: number }>('{theme_id}'),
    create: endpoint.post<IThemeForm>(),
    save: endpoint.put<DeepPartial<Theme>>('{theme_id}'),
    delete: endpoint.delete('{theme_id}'),
};

const selectors = {
    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

const saver = createAsyncSaver(schemaObj, api.save);

export { Theme, api, saver, schemaObj, selectors };
