import * as React from 'react';
import { connect } from 'react-redux';
import { Colors } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';
import {
    registerWidget,
    IWidgetRenderProps,
    IWidgetEditProps,
    isEditing,
    IReportContext,
} from 'reports/modules/report/widgets';

import { selectors } from 'reports/modules/report';

import SidebarFormatForm from 'reports/modules/report/components/SidebarFormatForm';
import ShapesForm from './ShapesForm';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ShapeContainer = styled.div.attrs({
    className: 'shape-container',
})`
    height: 100%;
    width: 100%;
`;

const DEFAULT_LINE_STYLE: React.SVGProps<SVGLineElement> = {
    stroke: '#000',
    strokeLinecap: 'butt',
    strokeLinejoin: 'round',
    strokeWidth: 1,
    // TODO: add dashed line types
    // strokeDasharray: '',
    // strokeMiterlimit: 4.0 // used when strokeLinejoin = miter
};

interface ILineContent {
    style: React.SVGProps<SVGLineElement>;
}

type IStateProps = ReturnType<ReturnType<typeof mapStateToProps>>;
type IContext = Pick<IReportContext, 'theme'>;
type ViewLineProps = IWidgetRenderProps<ILineContent, IContext> & IStateProps;
type EditLineProps = IWidgetEditProps<ILineContent, IContext> & IStateProps;

const Line: React.FC<ViewLineProps | EditLineProps> = (props) => {
    const { config } = props;
    const { w, h } = config.layout;
    const style = {
        ...DEFAULT_LINE_STYLE,
        ...props.style,
    } as React.SVGProps<SVGLineElement>;
    const coords = w >= h ? { x1: 0, x2: w, y1: h / 2, y2: h / 2 } : { x1: w / 2, x2: w / 2, y1: 0, y2: h };
    const widgetStyle = {
        ...DEFAULT_LINE_STYLE,
        ...props.config.content.style,
    };

    return (
        <ShapeContainer>
            <svg viewBox={`0 0 ${w} ${h}`}>
                <line strokeOpacity={1} {...style} {...coords} />
            </svg>
            {isEditing(props) ? (
                <SidebarFormatForm>
                    <ShapesForm
                        isLine={true}
                        layout={props.config.layout}
                        widgetStyle={widgetStyle}
                        setCSSProp={(style) =>
                            props.updateWidgetContent({
                                style: { ...widgetStyle, ...style },
                            })
                        }
                        onLayoutChange={(layout) => props.updateWidgetLayout(layout)}
                        styleMap={props.styleMap}
                        theme={props.context.theme}
                    />
                </SidebarFormatForm>
            ) : null}
        </ShapeContainer>
    );
};

const DEFAULT_RECT_STYLE: React.SVGProps<SVGRectElement> = {
    fill: Colors.BLUE5,
    stroke: Colors.BLUE1,
    strokeWidth: 2,
};

interface IRectContent {
    style: React.SVGProps<SVGRectElement>;
}
type ViewRectProps = IWidgetRenderProps<IRectContent, IContext> & IStateProps;
type EditRectProps = IWidgetEditProps<IRectContent, IContext> & IStateProps;

const Rect: React.FC<ViewRectProps | EditRectProps> = (props) => {
    const { w, h } = props.config.layout;

    const rectProps: any = {
        height: h,
        width: w,
        style: {
            ...DEFAULT_RECT_STYLE,
            ...props.style,
        },
    };
    const widgetStyle = {
        ...DEFAULT_RECT_STYLE,
        ...props.config.content.style,
    };

    return (
        <ShapeContainer>
            <svg viewBox={`0 0 ${w} ${h}`}>
                <rect {...rectProps} />
            </svg>
            {isEditing(props) ? (
                <SidebarFormatForm>
                    <ShapesForm
                        isLine={false}
                        layout={props.config.layout}
                        widgetStyle={widgetStyle}
                        setCSSProp={(style) =>
                            props.updateWidgetContent({
                                style: { ...widgetStyle, ...style },
                            })
                        }
                        onLayoutChange={(layout) => props.updateWidgetLayout(layout)}
                        styleMap={props.styleMap}
                        theme={props.context.theme}
                    />
                </SidebarFormatForm>
            ) : null}
        </ShapeContainer>
    );
};

const mapStateToProps = () => {
    const { compiledStyle, getStyleMap } = selectors;

    return (_state, ownProps: IWidgetEditProps<ILineContent, IContext>) => ({
        styleMap: getStyleMap(ownProps),
        style: compiledStyle(ownProps),
    });
};

const LineContainer = connect(mapStateToProps)(Line);
const RectContainer = connect(mapStateToProps)(Rect);

export const LineWidget = registerWidget('shapes.line', {
    Component: LineContainer as any,
    EditingComponent: LineContainer,
    metadata: {
        category: 'generic',
        dimensions: { h: 50, w: 500 },
        displayName: Translations.widgets.line_header,
        icon: IconNames.SLASH,
        ignoreStyle: true,
    },
    dependencies: ['theme'],
});

export const RectWidget = registerWidget('shapes.rect', {
    Component: RectContainer as any,
    EditingComponent: RectContainer,
    metadata: {
        category: 'generic',
        dimensions: { h: 100, w: 300 },
        displayName: Translations.widgets.rectangle_header,
        icon: IconNames.SQUARE,
        ignoreStyle: true,
    },
    dependencies: ['theme'],
});
