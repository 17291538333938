import * as React from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { BillingOverview as BetaBillingOverview } from 'reports/modules/settings/billing/components/BillingOverview';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import { BillingContext } from 'reports/modules/settings/billing/Context';

import { useSettingsContext } from 'frontend/settings/use-settings-data';

const BillingOverview = () => {
    const { subscription, user } = useSettingsContext();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const contextValue = React.useMemo(
        () => ({
            dialog: searchParams.get('dialog'),
            interval: searchParams.get('interval'),
            message: searchParams.get('message'),
            product: searchParams.get('product'),
            referrer: (searchParams.get('referrer') || ReferrerTypes.billing_page) as ReferrerTypes,
        }),
        [searchParams],
    );

    const navigateToBillingOverview = () => navigate({ pathname: '/settings/team/billing' });

    return (
        <BillingContext.Provider value={contextValue}>
            <BetaBillingOverview
                navigateToBillingOverview={navigateToBillingOverview}
                subscription={subscription}
                user={user}
            />
        </BillingContext.Provider>
    );
};

export { BillingOverview };
