import { EditableText, IEditableTextProps, Icon } from '@blueprintjs/core';

import * as React from 'react';

interface IFormatEditorState {
    isEditing: boolean;
    editValue?: string;
}

interface IFormatEditorProps extends IEditableTextProps {
    formatter?: (val: any) => string;
    value: any;

    onConfirm: (val: string | number) => any;
    formatOnEdit?: (val: any) => string;
    formatOnConfirm?: (val: any) => string | number;

    showIcon?: boolean;
}

export class FormattedEditor extends React.Component<IFormatEditorProps, IFormatEditorState> {
    state = {
        isEditing: false,
        editValue: undefined,
    };

    onCancel = () => {
        this.setState({
            isEditing: false,
            editValue: undefined,
        });
    };

    componentDidUpdate(prevProps: IFormatEditorProps) {
        const { isEditing } = this.props;

        if (isEditing !== prevProps.isEditing && isEditing === true) {
            this.onEdit();
        }
    }

    onChange = (editValue: string) => {
        this.setState({ editValue });
    };

    onEdit = () => {
        let editValue = this.props.value;

        if (this.props.formatOnEdit != null) {
            editValue = this.props.formatOnEdit(editValue);
        } else {
            editValue = String(editValue);
        }

        this.setState({ editValue });

        // ensure selectAllOnFocus works
        setTimeout(() => this.setState({ isEditing: true }));
    };

    onConfirm = (value: string) => {
        if (this.props.onConfirm != null) {
            let rtn: string | number;

            if (this.props.formatOnConfirm != null) {
                rtn = this.props.formatOnConfirm(value);
            } else {
                rtn = String(value);
            }

            this.props.onConfirm(rtn);
        }

        this.setState({
            isEditing: false,
            editValue: undefined,
        });
    };

    render() {
        const { value, showIcon, formatter = String, ...otherProps } = this.props;
        const { isEditing, editValue } = this.state;

        const editable = (
            <EditableText
                {...otherProps}
                onEdit={this.onEdit}
                onConfirm={this.onConfirm}
                onChange={this.onChange}
                onCancel={this.onCancel}
                value={isEditing ? editValue : formatter(value)}
                isEditing={isEditing}
            />
        );

        if (showIcon) {
            return (
                <span style={{ width: '100%' }}>
                    {editable}
                    <span style={{ marginLeft: '5px' }}>
                        <Icon icon="edit" onClick={() => this.onEdit()} />
                    </span>
                </span>
            );
        }

        return editable;
    }
}

export default FormattedEditor;
