import { Intent } from '@blueprintjs/core';
import * as React from 'react';
import { connect } from 'react-redux';

import { AnchorButton, PrimaryButton, PrimaryIntent } from 'reports/components/core/controls';
import { Form, handleRequestException } from 'reports/components/forms';
import { FormTextInput } from 'reports/components/forms/inputs/experimental';
import { FormErrorCallout } from 'reports/components/helpers/errors';
import * as usr from 'reports/models/user';
import { addPromiseToasts } from 'reports/modules/Toaster';

import { bindActions } from 'reports/utils/redux';

import * as styles from 'reports/styles/styled-components';
import { Flex } from 'reports/components/core/containers';

export const styled = styles.styled;

const MAX_TRIAL_EXTENSIONS = 1;

const TrialExtensionContainer = styled(Flex.Container)`
    min-height: 100px;
    gap: 24px;
`;

const TrialExtensionSection = styled.div`
    width: 100%;

    &&:not(:first-child) {
        padding-top: 8px;
    }
`;

const TrialExtensionButton = styled(PrimaryButton)`
    display: block;
    && {
        padding: 6px 10px;
        background: #f6f7f9;
        box-shadow: 0px -1px 1px 0px rgba(16, 22, 26, 0.1) inset;
        border-radius: 3px;
        border: 1px solid #ccc;
        background-image: none;
        width: max-content;
        line-height: 18px;
        font-size: 14px;
    }
`;

const TrialExtensionTitle = styled.div`
    font-weight: 400;
    font-size: 22px;
    color: #607d8b;
`;

const TrialExtensionText = styled.div`
    font-size: 14px;
    width: 80%;
`;

const StyledAnchorButton = styled(AnchorButton)`
    && {
        width: max-content;
        margin-top: 30px;
    }
`;

const contactSupportLink = 'mailto:support@helioscope.com';

const QuestionsBox = () => (
    <Flex.ContainerV style={{ alignItems: 'left' }}>
        <StyledAnchorButton intent={Intent.NONE} target="_blank" href={contactSupportLink}>
            Contact Support
        </StyledAnchorButton>
    </Flex.ContainerV>
);

const TrialExtensionTextCard = ({ text }) => <TrialExtensionText>{text}</TrialExtensionText>;

type IProps = ReturnType<typeof mapDispatchToProps> & { user: usr.User };

const SelfServeTrialExtensionCard = (props: IProps) => {
    const { user, extendTrial, trialExtensionCount } = props;
    const { email, latest_subscription, legacy_subscription_data } = user;
    const [allowExtensions, setAllowExtensions] = React.useState(true);
    const [loading, setLoading] = React.useState(true);

    const setCount = async () => {
        const { count } = await trialExtensionCount({ email: user.email });
        setAllowExtensions(count < MAX_TRIAL_EXTENSIONS);
        setLoading(false);
    };

    React.useEffect(() => {
        setCount();
    }, [user.user_id]);

    if (latest_subscription != null || legacy_subscription_data != null) {
        // User was recently subscribed; they are not on a trial. Only allow extending trials.
        return null;
    }

    const extendTrialWithToast = async ({ reason }) => {
        return await addPromiseToasts(extendTrial({ email }, { reason }), {
            initial: 'Requesting trial extension...',
            onSuccess: 'Trial extended successfully!',
            onCatch: 'There was an error extending your trial period.',
        });
    };

    if (loading) {
        return <TrialExtensionContainer />;
    }
    if (!allowExtensions) {
        return (
            <TrialExtensionContainer>
                <TrialExtensionSection>
                    <TrialExtensionTitle>Your extension has expired</TrialExtensionTitle>
                    <TrialExtensionTextCard text="You have already extended your free trial once. Please reach out to our support team if you need additional time" />
                </TrialExtensionSection>
                <TrialExtensionSection>
                    <QuestionsBox />
                </TrialExtensionSection>
            </TrialExtensionContainer>
        );
    }
    return (
        <TrialExtensionContainer>
            <TrialExtensionSection>
                <TrialExtensionTitle>Not quite ready yet?</TrialExtensionTitle>
                <TrialExtensionTextCard text="Tell us why you aren't ready to start a subscription yet, and we'll be happy to extend your trial by 3 days." />
            </TrialExtensionSection>
            <TrialExtensionSection>
                <Form
                    baseValue={{ reason: '' }}
                    onSubmit={extendTrialWithToast}
                    exceptionHandler={handleRequestException}
                >
                    {({ submitForm, valid, submitting, formErrors, formData }) => (
                        <>
                            {formErrors.length > 0 && <FormErrorCallout errorMsg={formErrors[0]} />}
                            <FormTextInput bold={false} path="reason" placeholder="Tell us more" />
                            {/* TODO: make a common SubmitButton component that does this */}
                            <TrialExtensionButton
                                text="Extend trial"
                                onClick={submitForm}
                                disabled={!valid || formData.reason?.length < 15}
                                loading={submitting}
                                intent={PrimaryIntent.SAVE}
                            />
                        </>
                    )}
                </Form>
            </TrialExtensionSection>
        </TrialExtensionContainer>
    );
};

const mapDispatchToProps = bindActions({
    extendTrial: usr.api.extendTrial,
    trialExtensionCount: usr.api.trialExtensionCount,
});

export default connect(null, mapDispatchToProps)(SelfServeTrialExtensionCard);
