import Colors from 'reports/styles/colors';
import { isNumber } from 'lodash';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ScrollContainer = styled.div<{ height: string | number; fillHeight?: boolean }>`
    height: ${({ height, fillHeight = true }) => (fillHeight ? (isNumber(height) ? `${height}px` : height) : 'auto')};
    max-height: ${({ height, fillHeight = true }) => (fillHeight ? 'auto' : isNumber(height) ? `${height}px` : height)};
    overflow-y: scroll;
    border: 1px solid ${Colors.BORDER_TABLE_LIGHT};
    width: 100%;
`;

export default ScrollContainer;
