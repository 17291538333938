import React from 'react';

import { useSelector } from 'react-redux';

import { useLoaderData, useNavigate } from 'react-router-dom';

import { Incentive as IncentiveModel, selectors as incentiveSelectors } from 'reports/models/incentive';

import { IncentiveEditor } from 'reports/modules/incentive/components/IncentiveEditor';
import { IAppState } from 'reports/store';

const Incentive = () => {
    const navigate = useNavigate();
    const navigateToIncentives = () => navigate({ pathname: '/incentives' });

    const incentiveToSelect = useLoaderData() as IncentiveModel;
    const incentive = useSelector((state) =>
        incentiveSelectors.byObject(state as IAppState, { incentive_id: incentiveToSelect.incentive_id }),
    );

    if (!incentive) {
        throw new Response('Not Found', { status: 404 });
    }

    return <IncentiveEditor incentive={incentive} navigateToIncentives={navigateToIncentives} />;
};

export { Incentive };
