import classNames from 'classnames';

import Flex from 'reports/components/core/containers/Flex';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// TODO: remove
export const Text = styled.div``;

export const SubContainer = styled.div`
    margin-top: 20px;
    position: relative;
`;

export const HeaderText = styled.div`
    font-size: 15px;
    font-weight: 700;
`;

export const HeaderSubtext = styled.div`
    font-size: 15px;
    font-weight: 400;
    padding-top: 4px;
`;

export const Header = styled(Flex.Container).attrs((props) => ({
    className: classNames('center', props.className),
}))`
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 34px;

    ${Text} {
        font-weight: 700;
    }
`;

export const SubHeader = styled(Flex.Container).attrs((props) => ({
    className: classNames('center', props.className),
}))`
    height: 46px; // make headers same height regardless of content
    justify-content: space-between;
    background: #f3f3f3;
    padding: 8px;
    font-size: 15px;
`;

export const Body = styled.div`
    padding: 12px;
`;

export const Footer = styled(Flex.Container)`
    justify-content: flex-end;
    margin-top: 10px;
`;

export default {
    Body,
    SubContainer,
    Footer,

    Header,
    SubHeader,
    Text,
    HeaderSubtext,
};
