import { Unit } from 'reports/utils/units';

import { TIME_INTERVALS } from 'reports/modules/financials/model/core';
import { IPipelineState } from 'reports/modules/financials/model/pipeline';

export enum OutputCategory {
    CashFlow = 'Cash Flow',
    Consumption = 'Consumption',
    CumulativeCashFlow = 'Cumulative Cash Flow',
    Financials = 'Financials',
    Incentives = 'Incentives',
    Interest = 'Interest',
    Production = 'Production',
    Rates = 'Rates',
    System = 'System Costs',
    Taxes = 'Taxes',
}

export const CATEGORY_LIST = [
    OutputCategory.Production,
    OutputCategory.Consumption,
    OutputCategory.Rates,
    OutputCategory.System,
    OutputCategory.Incentives,
    OutputCategory.Financials,
    OutputCategory.Taxes,
    OutputCategory.Interest,
    OutputCategory.CashFlow,
    OutputCategory.CumulativeCashFlow,
];

export interface ITimeSeriesOutput {
    title: string;
    userLabel?: string;
    values: number[];
    valuesMonthly: number[];
    unit?: Unit;
}

export type DebugOutput = {
    category: OutputCategory;
    output: ITimeSeriesOutput;
};

export function initDebugOutput() {
    return [] as DebugOutput[];
}

export interface IDebugParam {
    table: OutputCategory;
    descr?: string;
    getParamValue?: (state: IPipelineState) => number[];
    getParamValueMonthly?: (state: IPipelineState) => number[];
    unit?: Unit;
}

export function convertSeriesToOutput(series, initial = 0.0, interval = TIME_INTERVALS.YEAR): number[] {
    return series.reaggregateInterval(interval).toOutputSeries(initial).seriesRaw().slice();
}

export function populateDebug(state: IPipelineState, debugOutputs: IDebugParam[], descr: string, userLabel?: string) {
    const defaultSeries = {
        [OutputCategory.Consumption]: {
            series: state.consumption,
            initial: 0.0,
            unit: Unit.Energy,
        },
        [OutputCategory.Financials]: {
            series: state.financeYearly,
            initial: state.financeInitial,
            unit: Unit.Currency,
        },
        [OutputCategory.Incentives]: {
            series: state.incentiveYearly,
            initial: state.incentiveInitial,
            unit: Unit.Currency,
        },
        [OutputCategory.Interest]: {
            series: state.interestYearly,
            initial: 0.0,
            unit: Unit.Currency,
        },
        [OutputCategory.Production]: {
            series: state.production,
            initial: 0.0,
            unit: Unit.Energy,
        },
        [OutputCategory.Rates]: {
            series: state.utilityBeforeMonthly,
            initial: 0.0,
            unit: Unit.Currency,
        },
        [OutputCategory.System]: {
            series: state.systemYearly,
            initial: state.systemInitial,
            unit: Unit.Currency,
        },
        [OutputCategory.Taxes]: {
            series: state.taxYearly,
            initial: state.taxInitial,
            unit: Unit.Currency,
        },
    };

    for (const i of debugOutputs) {
        const title = i.descr || descr;
        // Check if the custom step name is the same as the provided one
        const label = userLabel === title || userLabel === descr ? undefined : userLabel;

        if (i.getParamValue && i.getParamValueMonthly) {
            const { unit = undefined } = defaultSeries[i.table] || {};
            const values = i.getParamValue(state).slice();
            const valuesMonthly = i.getParamValueMonthly(state).slice();
            state.debugOutput.push({
                category: i.table,
                output: {
                    title,
                    values,
                    valuesMonthly,
                    userLabel: label,
                    unit: i.unit || unit,
                },
            });
        } else if (defaultSeries[i.table]) {
            const { series, initial, unit } = defaultSeries[i.table];
            const values = convertSeriesToOutput(series, initial);
            const valuesMonthly = convertSeriesToOutput(series, initial, TIME_INTERVALS.MONTH);
            state.debugOutput.push({
                category: i.table,
                output: {
                    title,
                    unit,
                    values,
                    valuesMonthly,
                    userLabel: label,
                },
            });
        }
    }
}
