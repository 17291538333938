/**
 * Report Proposals Financial Summary Widget
 */
import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { Button, Menu, MenuItem, Popover, Position, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';

import { lookupTokenValue } from 'reports/modules/report/tokens/declarativeTokens';
import { IWidgetEditProps, IWidgetRenderProps, IReportContext, registerWidget } from 'reports/modules/report/widgets';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const BannerContainer = styled.div`
    display: flex;
    flex: 1;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        flex: 1;
        padding: 10px;

        &:not(:first-child) {
            border-left: 1px solid #eee;
        }
    }
`;

const Explainer = styled.div`
    text-transform: capitalize;
`;

const SummaryContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;

    font-size: 14;
    font-style: normal;
    font-weight: 400;
    text-decoration: 'none';
`;

const Title = styled.div`
    font-size: 18px;
`;

const Val = styled.div`
    font-size: 40px;
    font-weight: 400;
`;

const TopRight = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

// Available banner stats
const FINANCIAL_STATS = {
    lcoe: {
        name: Translations.financial.lcoe,
        description: Translations.financial.lcoe,
    },
    system_npv_cash: {
        name: Translations.financial.system_npv_cash,
        description: Translations.financial.system_npv_cash,
    },
    payback_period: {
        name: Translations.financial.payback_period,
        description: Translations.financial.payback_period,
    },
};

type Stat = keyof typeof FINANCIAL_STATS;

const DEFAULT_STATS: Stat[] = ['payback_period', 'lcoe'];

interface IFinancialSummaryConfig {
    stats: [Stat, Stat, Stat];
}

type IContext = Pick<IReportContext, 'tokenMap' | 'financialTokens'>;
type ViewProps = IWidgetRenderProps<IFinancialSummaryConfig, IContext> & {
    intl: IntlShape;
};
type EditProps = IWidgetEditProps<IFinancialSummaryConfig, IContext> & {
    intl: IntlShape;
};

interface IStat {
    description: string;
    val: number | string;
    popoverOptions?: any;
}

const Stat: React.FC<IStat> = ({ description, popoverOptions, val }) => (
    <div className="stat-container">
        <Val>{val}</Val>
        <Explainer>{description}</Explainer>

        {popoverOptions != null ? (
            <TopRight>
                <Popover
                    content={popoverOptions}
                    position={Position.BOTTOM_RIGHT}
                    modifiers={{ preventOverflow: { enabled: false } }}
                >
                    <Button icon="chevron-down" className={Classes.MINIMAL} />
                </Popover>
            </TopRight>
        ) : null}
    </div>
);

const ViewFinancialSummaryTableIntl: React.FC<ViewProps> = ({ config, context, intl }) => {
    const { stats = DEFAULT_STATS } = config.content;
    const { tokenMap } = context;

    return (
        <SummaryContainer>
            <Title>
                <FormattedMessage {...Translations.financial.financial_summary} />
            </Title>
            <BannerContainer>
                {stats.map((id, idx) => {
                    const value = lookupTokenValue(tokenMap, id, intl.locale);
                    return (
                        <Stat key={idx} val={value} description={intl.formatMessage(FINANCIAL_STATS[id].description)} />
                    );
                })}
            </BannerContainer>
        </SummaryContainer>
    );
};
const ViewFinancialSummaryTable = injectIntl(ViewFinancialSummaryTableIntl);

class EditFinancialSummaryTableIntl extends React.PureComponent<EditProps, {}> {
    constructor(props: EditProps) {
        super(props);
    }

    render() {
        return (
            <SummaryContainer>
                <Title>
                    <FormattedMessage {...Translations.financial.financial_summary} />
                </Title>
                <BannerContainer>{this.renderStats()}</BannerContainer>
            </SummaryContainer>
        );
    }

    renderMenu(i, currentStat) {
        const { intl } = this.props;

        return (
            <Menu>
                {Object.entries(FINANCIAL_STATS).map(([key, entry], idx) => {
                    return (
                        <MenuItem
                            key={idx}
                            icon={idx === currentStat ? IconNames.TICK : undefined}
                            onClick={(_) => this.updateStat(i, key)}
                            text={intl.formatMessage(entry.name)}
                        />
                    );
                })}
            </Menu>
        );
    }

    renderStats() {
        const { intl } = this.props;
        const { tokenMap } = this.props.context;

        return (this.props.config.content.stats || DEFAULT_STATS).map((id, idx) => {
            const value = lookupTokenValue(tokenMap, id, intl.locale);
            const description = intl.formatMessage(FINANCIAL_STATS[id].description);

            return <Stat key={idx} popoverOptions={this.renderMenu(idx, id)} val={value} description={description} />;
        });
    }

    setStats(stats) {
        this.props.updateWidgetContent({ stats });
    }

    updateStat(i, newStat) {
        const { stats = DEFAULT_STATS } = this.props.config.content;
        const updatedStats = stats.slice();

        if (updatedStats[i] !== newStat) {
            updatedStats[i] = newStat;
            this.setStats(updatedStats);
        }
    }
}
const EditFinancialSummaryTable = injectIntl(EditFinancialSummaryTableIntl);

const FinancialSummaryTable = registerWidget('financial_summary', {
    Component: ViewFinancialSummaryTable,
    EditingComponent: EditFinancialSummaryTable,
    metadata: {
        category: 'financial',
        dimensions: { h: 400, w: 800 },
        displayName: Translations.widgets.financial_summary_table_header,
        icon: IconNames.BANK_ACCOUNT,
    },
    // Added financialTokens even though it's not used so that the WidgetContainer
    // can render loading/error states for the financial calculations on which
    // this widget's tokens depend
    dependencies: ['tokenMap', 'financialTokens'],
});

export default FinancialSummaryTable;
