import * as React from 'react';

import { LibrarySearch as Search } from 'reports/components/core/layout';

import { LibraryConsumer } from 'reports/components/library/main/context';
import { Filter } from 'reports/components/library/main/types';

import { FilterButton } from './FilterButton';

interface ISearchControlsProps<T> {
    filters?: Filter<T>[];
    // Temporary home for filtering controls that are not quick filters. These will eventually move into the advanced
    // filters popover.
    extraFilterControls?: JSX.Element;
    extraControls?: React.ReactNode;
}

class SearchControls<T> extends React.PureComponent<ISearchControlsProps<T>> {
    render() {
        const { filters, extraControls, extraFilterControls } = this.props;
        return (
            <LibraryConsumer>
                {({ setSearchValue, updateSearch }) => (
                    <div>
                        <Search.Container>
                            <Search.SearchBarWithFilters
                                onChange={(val) => setSearchValue(val ? val : '')}
                                applySearch={(_) => updateSearch()}
                            />
                            <Search.QuickFilterButtons>
                                {filters &&
                                    filters.map((filter, idx) => (
                                        <FilterButton
                                            key={idx}
                                            name={filter.name}
                                            icon={filter.icon}
                                            text={filter.text}
                                            value={filter.value != null ? filter.value : true}
                                        />
                                    ))}
                            </Search.QuickFilterButtons>
                            {extraControls && <Search.ExtraControls>{extraControls}</Search.ExtraControls>}
                        </Search.Container>
                        <Search.ExtraFilters>{extraFilterControls}</Search.ExtraFilters>
                    </div>
                )}
            </LibraryConsumer>
        );
    }
}

export default SearchControls;
