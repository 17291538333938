import * as React from 'react';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { PrimaryIntent } from 'reports/components/core/controls';
import { promptModalBoolean } from 'reports/components/dialog';

export const seatRemovalText = (numSeatsToBeRemoved: number, removalText: string) => {
    const licensesText =
        numSeatsToBeRemoved > 1 ? `${numSeatsToBeRemoved} licenses are ` : `${numSeatsToBeRemoved} license is `;
    return licensesText + removalText;
};
const cancellationText = 'Your subscription will not be renewed at the end of this billing cycle. ';

export const promptCancelScheduledChanges = async (numSeatsToBeRemoved, cancelScheduledChanges, subscription) => {
    const shouldCancel = await promptModalBoolean({
        title: 'Confirm Cancellation',
        prompt: (
            <>
                {numSeatsToBeRemoved
                    ? seatRemovalText(
                          numSeatsToBeRemoved,
                          'scheduled to be removed from your account at the end of this billing cycle. ',
                      )
                    : cancellationText}
                Do you want to cancel this scheduled change to your account?
            </>
        ),
        cancellable: true,
        cancelLabel: 'No, nevermind',
        yesLabel: 'Yes, cancel',
        yesIntent: PrimaryIntent.DELETE,
    });

    if (shouldCancel) {
        await addPromiseToasts(cancelScheduledChanges(subscription), {
            initial: 'Cancelling scheduled changes...',
            onSuccess: 'Canceled scheduled changes to your account.',
            onCatch: (error) => `Error cancelling scheduled changes: ${error.response?.body?.error}.`,
        });
    }
};
