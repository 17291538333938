import * as React from 'react';

import { Classes } from '@blueprintjs/core';

import { ControlContainer, ControlPrimaryText, HelperText, SectionContainer } from 'reports/modules/settings/common';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

type Props = React.PropsWithChildren<{
    disabled?: boolean;
    helperText?: string;
    title: string;
}>;

const InputWrapper = styled.div`
    .${Classes.CONTROL_GROUP} {
        justify-content: flex-end;
    }
`;

const FlexControlContainer = styled(ControlContainer)`
    min-width: fit-content;
    margin-left: 10px;
`;

export const SubscriptionInput = ({ children, disabled, helperText, title }: Props) => (
    <SectionContainer disabled={disabled}>
        <div>
            <ControlPrimaryText>{title}</ControlPrimaryText>
            {helperText && <HelperText>{helperText}</HelperText>}
        </div>
        {children && (
            <FlexControlContainer>
                <InputWrapper>{React.cloneElement(children as React.ReactElement, { disabled })}</InputWrapper>
            </FlexControlContainer>
        )}
    </SectionContainer>
);
