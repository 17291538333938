import { get, range } from 'lodash';
import * as React from 'react';

import { ControlGroup, HTMLSelect } from '@blueprintjs/core';

import * as uc from 'reports/models/user_consumption';

import { getCurrentMonth, hoursInMonth, Month, MONTHS } from 'reports/utils/time';
import * as fmt from 'reports/utils/formatters';

import { getDefaultEnergyFromProfile, IConsumptionInputForm } from 'reports/modules/consumption';
import { FormattedInput } from 'reports/components/helpers/FormattedInput';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const SelectInputControlGroup = styled(ControlGroup)`
    .bp3-input-group {
        flex-shrink: 1;
    }
`;

interface IProps extends IConsumptionInputForm {
    onSave: (userInputKwh: uc.IUserInput) => void;
}

interface IState {
    monthlyEnergy: number[]; // in kWh
    selectedMonth: Month;
    userInputKwh: uc.IUserInput;
}

const MONTH_OPTIONS = MONTHS.map((mon, idx) => ({
    label: mon,
    value: idx + 1,
}));

class SingleMonthConsumptionForm extends React.PureComponent<IProps, IState> {
    state: IState = {
        selectedMonth: this.getSelectedMonth(this.props.userInputKwh),
        monthlyEnergy: this.calculateMonthly(this.props.userInputKwh),
        userInputKwh: this.props.userInputKwh,
    };

    getSelectedMonth(uc: uc.IUserInput): Month {
        const userMonths = Object.keys(uc);

        if (userMonths.length) {
            return parseInt(userMonths[0], 10) as any;
        }

        return getCurrentMonth();
    }

    calculateMonthly(input = this.state.userInputKwh, usageProfile = this.props.usageProfile) {
        const month = this.getSelectedMonth(input);
        const energy = input[month].energy || getDefaultEnergyFromProfile(usageProfile);
        const monthlyValues = range(1, 13).map(usageProfile == null ? hoursInMonth : (m) => usageProfile.monthly[m]);
        const monthAdj = energy / monthlyValues[month - 1];

        return monthlyValues.map((x) => x * monthAdj);
    }

    updateSeries = () => {
        const monthlyEnergy = this.calculateMonthly();
        this.setState({ monthlyEnergy });
    };

    save = () => {
        this.updateSeries();
        this.props.onSave(this.state.userInputKwh);
    };

    updateMonth = (energy: number) => {
        const { selectedMonth } = this.state;

        const userInputKwh = {
            [selectedMonth]: {
                ...this.state.userInputKwh[selectedMonth],
                energy,
            },
        };
        this.setState({ userInputKwh });
    };

    render() {
        const { selectedMonth, monthlyEnergy } = this.state;
        const energy = get(this.props.userInputKwh, `[${selectedMonth}].energy`, monthlyEnergy[selectedMonth - 1]);
        return (
            <SelectInputControlGroup>
                <HTMLSelect
                    onChange={(evt) =>
                        this.setState({
                            selectedMonth: parseInt(evt.currentTarget.value, 10) as Month,
                            userInputKwh: this.props.userInputKwh, // reset any previous changes
                        })
                    }
                    options={MONTH_OPTIONS}
                    value={selectedMonth}
                />
                <FormattedInput
                    value={energy}
                    onChange={this.updateMonth}
                    onConfirm={this.save}
                    textToValue={(val) => fmt.numberStringToFloat(val)}
                    valueToDisplayText={(val) => fmt.stringifyNumberSimple(val, 2) + ' KWh'}
                    valueToEditText={(val) => fmt.stringifyNumberSimple(val, 2)}
                    selectAllOnFocus={true}
                />
            </SelectInputControlGroup>
        );
    }
}

export default SingleMonthConsumptionForm;
