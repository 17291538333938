import * as React from 'react';
import { keyBy, chain } from 'lodash';
import { connect } from 'react-redux';

import { Spinner } from '@blueprintjs/core';

import * as scen from 'reports/models/scenario';
import * as mod from 'reports/models/module';
import * as pd from 'reports/models/power_device';

import { bindActions } from 'reports/utils/redux';
import BasicTable from 'reports/components/core/tables/BasicTable';

interface IOwnProps {
    scenario: scen.Scenario;
}

const mapDispatchToProps = bindActions(({ scenario }) => ({
    loadProjectDevices: () => pd.charApi.index({ project_id: scenario.project_id }),
    loadProjectModules: () => mod.charApi.index({ project_id: scenario.project_id }),
}));

type IProps = IOwnProps & ReturnType<typeof mapDispatchToProps>;

interface IState {
    loading: boolean;

    projDeviceCharacterizations?: pd.PowerDeviceCharacterization[];
    projModuleCharacterizations?: mod.ModuleCharacterization[];

    sortedPDCs: pd.PowerDeviceCharacterization[];
    sortedMCs: mod.ModuleCharacterization[];
}

const INITIAL_STATE: IState = {
    loading: true,
    sortedPDCs: [],
    sortedMCs: [],
    projDeviceCharacterizations: undefined,
    projModuleCharacterizations: undefined,
};

class _CharacterizationTable extends React.PureComponent<IProps, IState> {
    state = INITIAL_STATE;

    componentDidUpdate(prevProps, prevState) {
        const { scenario } = this.props;

        if (scenario.project_id !== prevProps.scenario.project_id) {
            this.loadProjectCharacterizations();
        }

        if (prevProps.scenario !== scenario || prevState.loading !== this.state.loading) {
            this.setState(this.generateCharacterizationList());
        }
    }

    componentDidMount() {
        this.loadProjectCharacterizations();
    }

    async loadProjectCharacterizations() {
        const { loadProjectDevices, loadProjectModules } = this.props;

        this.setState(INITIAL_STATE);
        const [projModuleCharacterizations, projDeviceCharacterizations] = await Promise.all([
            loadProjectModules(),
            loadProjectDevices(),
        ]);

        this.setState({
            projDeviceCharacterizations,
            projModuleCharacterizations,
            loading: false,
        });
    }

    generateCharacterizationList() {
        const { scenario } = this.props;
        const { projModuleCharacterizations, projDeviceCharacterizations } = this.state;

        const scenMCs = keyBy(
            scenario.module_characterizations.map((smc) => smc.module_characterization),
            'module_id',
        );

        const scenPDCs = keyBy(
            scenario.power_device_characterizations.map((spdc) => spdc.power_device_characterization),
            'power_device_id',
        );

        return {
            sortedMCs: chain(projModuleCharacterizations)
                .map('module')
                .uniq()
                .map((mod) => scenMCs[mod.module_id] || mod.defaultCharacterization())
                .sortBy(['module.manufacturer', 'module.name'])
                .value(),
            sortedPDCs: chain(projDeviceCharacterizations)
                .map('power_device')
                .uniq()
                .map((pd) => scenPDCs[pd.power_device_id] || pd.defaultCharacterization())
                .sortBy(['power_device.device_type_name', 'power_device.manufacturer', 'power_device.name'])
                .value(),
        };
    }

    render() {
        const { sortedPDCs, sortedMCs, loading } = this.state;

        return (
            <BasicTable>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Component</th>
                        <th>Characterization</th>
                        <th>Uploaded By</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {loading && (
                        <tr>
                            <td colSpan={5}>
                                <Spinner />
                            </td>
                        </tr>
                    )}
                    {sortedMCs.map((mc) => (
                        <tr key={mc.module_characterization_id}>
                            <td>Module</td>
                            <td>
                                {mc.module.name} ({mc.module.manufacturer})
                            </td>
                            <td>{mc.name}</td>
                            <td>{mc.module.team!.name}</td>
                            <td>{mc.description}</td>
                        </tr>
                    ))}
                    {sortedPDCs.map((pdc) => (
                        <tr key={pdc.power_device_characterization_id}>
                            <td>{pd.PowerDeviceTypes[pdc.power_device.device_type_name]}</td>
                            <td>
                                {pdc.power_device.name} ({pdc.power_device.manufacturer})
                            </td>
                            <td>{pdc.name}</td>
                            <td>{pdc.power_device.team!.name}</td>
                            <td>{pdc.description}</td>
                        </tr>
                    ))}
                </tbody>
            </BasicTable>
        );
    }
}

const CharacterizationTable = connect(null, mapDispatchToProps)(_CharacterizationTable);

export { CharacterizationTable };

export default CharacterizationTable;
