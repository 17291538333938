import * as React from 'react';
import { RouterStateLock } from 'reports/utils/router';

import * as usr from 'reports/models/user';
import { WIRING_UNITS } from 'reports/models/wire';

import { SaveCancelButtons } from 'reports/components/core/controls';
import { Flex } from 'reports/components/core/containers';
import { FormGroup } from 'reports/components/core/forms';

import { Form, NestedFields } from 'reports/components/forms';
import { fromDict, IItem, FormBasicSelect, FormSliderInput } from 'reports/components/forms/inputs/experimental';

import { DescriptionFormGroup, StyledCheckbox } from 'reports/modules/settings/common';
import FormProfileSelect from 'reports/modules/profile/components/FormProfileSelect';
import * as analytics from 'reports/analytics';

type Coordinate = { x: number; y: number };

const LABEL_WIDTH = 130;

const DIST_UNITS = { ft: 'Feet', m: 'Meters' };

const RESOLUTION_OPTIONS: Coordinate[] = [
    { x: 1200, y: 900 },
    { x: 1600, y: 1200 },
    { x: 2000, y: 1600 },
    { x: 2400, y: 2000 },
];

const resCoordToString = (coord: Coordinate) => `${coord.x} \u00d7 ${coord.y}`;

interface Props {
    onSubmit: (formData: usr.IUserForm) => void;
    user: usr.User;
}

const UserPreferencesForm = ({ onSubmit, user }: Props) => {
    return (
        <Form baseValue={user} onSubmit={onSubmit}>
            {({ dirty, submitForm, clearForm, formData }) => (
                <>
                    <RouterStateLock
                        title="Save User Settings?"
                        prompt="You have unsaved changes on user settings."
                        cancellable={true}
                        showExitPrompt={dirty}
                        onSave={submitForm}
                        onDontSave={clearForm}
                    />
                    <NestedFields path="preferences">
                        <FormGroup inline bold label="Units" labelWidth={LABEL_WIDTH}>
                            <NestedFields path="units">
                                <FormBasicSelect<IItem<string>>
                                    inline
                                    path="distance"
                                    label="Distance"
                                    dataSource={{
                                        items: fromDict(DIST_UNITS),
                                        keyLookup: ({ key }) => key,
                                    }}
                                    itemRenderer={({ key, name }) => ({
                                        key,
                                        text: name,
                                    })}
                                />
                            </NestedFields>

                            <NestedFields path="wiring">
                                <FormBasicSelect<IItem<string>>
                                    inline
                                    path="wire_units"
                                    label="Conductors/Wiring"
                                    dataSource={{
                                        items: fromDict(WIRING_UNITS),
                                        keyLookup: ({ key }) => key,
                                    }}
                                    itemRenderer={({ key, name }) => ({
                                        key,
                                        text: name,
                                    })}
                                />
                            </NestedFields>
                        </FormGroup>
                    </NestedFields>

                    <FormGroup inline bold label="Default Profile" labelWidth={LABEL_WIDTH}>
                        <DescriptionFormGroup desc="Every new project will start with these settings.">
                            <FormProfileSelect path="default_profile_id" type="project" />
                        </DescriptionFormGroup>
                    </FormGroup>

                    <NestedFields path="preferences">
                        <FormGroup inline bold label="Design Renders" labelWidth={LABEL_WIDTH}>
                            <NestedFields path="design_renders">
                                <DescriptionFormGroup desc="Preferences for what array detail is included when rendering.">
                                    <Flex.Container>
                                        <Flex.Main>
                                            <StyledCheckbox path="modules" checkboxLabel="Modules" />
                                            <StyledCheckbox path="inverters" checkboxLabel="Inverters" />
                                            <StyledCheckbox path="combiners" checkboxLabel="Combiners" />
                                            <StyledCheckbox path="keepouts" checkboxLabel="Keepouts" />
                                        </Flex.Main>
                                        <Flex.Main>
                                            <StyledCheckbox path="wiring" checkboxLabel="Wiring" />
                                            <StyledCheckbox path="interconnect" checkboxLabel="Interconnect" />
                                            <StyledCheckbox path="field_segments" checkboxLabel="Field Segments" />
                                        </Flex.Main>
                                    </Flex.Container>
                                </DescriptionFormGroup>
                                <FormBasicSelect<Coordinate>
                                    inline
                                    path="resolution"
                                    label="Resolution"
                                    dataSource={{
                                        items: RESOLUTION_OPTIONS,
                                        // Use identity function because the value here is its own key
                                        // E.g. { x: 800, y: 600 } is identified by its x and y values
                                        keyLookup: (res) => res,
                                    }}
                                    itemRenderer={(res) => {
                                        const coordStr = resCoordToString(res);
                                        return {
                                            key: coordStr,
                                            text: coordStr,
                                        };
                                    }}
                                />
                            </NestedFields>
                        </FormGroup>

                        <FormGroup inline bold label="Designer" labelWidth={LABEL_WIDTH}>
                            <NestedFields path="designer">
                                <DescriptionFormGroup
                                    title="Display Polygon Dimensions in Designer"
                                    desc="Flags will appear around every polygon with the precise dimensions, even when they are not being edited."
                                >
                                    <Flex.Container>
                                        <Flex.Main>
                                            <StyledCheckbox
                                                path="field_segment_labels"
                                                checkboxLabel="Field Segments"
                                            />
                                        </Flex.Main>
                                        <Flex.Main>
                                            <StyledCheckbox path="keepout_labels" checkboxLabel="Keepouts" />
                                        </Flex.Main>
                                    </Flex.Container>
                                </DescriptionFormGroup>
                                <FormSliderInput
                                    inline
                                    fill
                                    path="label_scale_factor"
                                    label="Label Size"
                                    min={0.1}
                                    max={4}
                                    stepSize={0.1}
                                />
                            </NestedFields>
                        </FormGroup>
                    </NestedFields>

                    <SaveCancelButtons
                        onSave={async () => {
                            if (formData.default_profile_id !== user.default_profile_id) {
                                analytics.track('profile.user_default_profile_change', {
                                    old_profile_id: user.default_profile_id,
                                    new_profile_id: formData.default_profile_id,
                                });
                            }
                            await submitForm();
                        }}
                        hasChanges={dirty}
                        onCancel={clearForm}
                        style={{ marginBottom: 10 }}
                    />
                </>
            )}
        </Form>
    );
};

export { UserPreferencesForm };
