import _ from 'lodash';

import * as React from 'react';

import { IInputGroupProps, InputGroup } from '@blueprintjs/core';

interface ISearchBoxProps extends IInputGroupProps {
    applySearch: (val: string) => any;
    autoFocus?: boolean;
    onTextChange?: (val?: string) => any;
    style?: React.CSSProperties;
    throttle?: number;
}

export default class SearchBox extends React.Component<ISearchBoxProps> {
    inputRef: HTMLInputElement | null; // RefObject is not supported for InputGroup, use old school ref
    throttledFn: (value: string) => any;

    componentDidMount() {
        const { autoFocus, throttle } = this.props;

        const effThrottle = throttle || 1000;

        this.throttledFn = _.debounce(
            (value) => {
                const { applySearch } = this.props;
                applySearch(value);
            },
            effThrottle,
            { trailing: true },
        );

        if (this.inputRef && autoFocus) {
            this.inputRef.focus();
        }
    }

    render() {
        const { applySearch, onTextChange, throttle, ...otherProps } = this.props;

        delete otherProps.autoFocus;

        return (
            <InputGroup
                inputRef={(ref) => (this.inputRef = ref)}
                leftIcon="search"
                placeholder="Search..."
                onChange={(evt) => {
                    const { value } = evt.currentTarget;
                    if (onTextChange) onTextChange(value);
                    this.throttledFn(value);
                }}
                {...otherProps}
            />
        );
    }
}
