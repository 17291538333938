import _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconNames } from '@blueprintjs/icons';
import Translations from 'reports/localization/strings';
import { FormattedNumber } from 'reports/components/core/numbers';
import { IWidgetRenderProps, registerWidget, IReportContext } from 'reports/modules/report/widgets';
import { WidgetDataTable } from 'reports/modules/report/components/helpers';
import { MONTHS } from 'reports/utils/constants';

const formatNumber = (val) => (val == null ? '-' : <FormattedNumber value={val} precision={1} />);

type IContext = Pick<IReportContext, 'simulation'>;
const MonthlyShadingTable: React.SFC<IWidgetRenderProps<object, IContext>> = ({ context, className }) => {
    const { simulation } = context;

    let rows = [] as any[];

    if (simulation && simulation.complete()) {
        const { monthly_data } = simulation.metadata;
        rows = _.keys(monthly_data)
            .sort(Number)
            .map((month) => ({ month, ...monthly_data[month] }));
    }

    return (
        <WidgetDataTable className={className}>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.general.month} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.simulation.ghi} /> (kWh/m<sup>2</sup>)
                    </th>
                    <th>
                        <FormattedMessage {...Translations.simulation.poa} /> (kWh/m<sup>2</sup>)
                    </th>
                    <th>
                        <FormattedMessage {...Translations.simulation.shaded} /> (kWh/m<sup>2</sup>)
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.nameplate} /> (kWh)
                    </th>
                    <th>
                        <FormattedMessage {...Translations.simulation.grid} /> (kWh)
                    </th>
                </tr>
            </thead>
            <tbody>
                {rows.map((i) => (
                    <tr key={i.month}>
                        <td>
                            <FormattedMessage {...MONTHS[i.month - 1]} />
                        </td>
                        <td>{formatNumber(i.global_horizontal_irradiance / 1000)}</td>
                        <td>{formatNumber(i.poa_irradiance / 1000)}</td>
                        <td>{formatNumber(i.shaded_irradiance / 1000)}</td>
                        <td>{formatNumber(i.nameplate_power / 1000)}</td>
                        <td>{formatNumber(i.grid_power / 1000)}</td>
                    </tr>
                ))}
            </tbody>
        </WidgetDataTable>
    );
};

export const MonthlyShadingTableWidget = registerWidget('monthly_shading_table', {
    Component: MonthlyShadingTable,
    metadata: {
        category: 'project',
        dimensions: { h: 450, w: 700 },
        displayName: Translations.widgets.monthly_shading_table_header,
        icon: IconNames.TH,
    },
    dependencies: ['simulation'],
});

export default MonthlyShadingTableWidget;
