import * as React from 'react';
import moment from 'moment';

import { Icon, Intent, Tag } from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime';

import { Flex } from 'reports/components/core/containers';

import * as styles from 'reports/styles/styled-components';
import FormBareDatePicker from 'reports/components/forms/inputs/experimental/FormBareDatePicker';
const styled = styles.styled;

const CalendarLabel = styled.div`
    text-align: left;
    width: 50%;
`;

const DateRangeContainer = styled.div`
    max-width: fit-content;
`;

const FORMAT = 'dddd, LL';

const MomentDate = ({ date }: { date: Date | null }) => {
    const m = date ? moment(date) : null;
    if (m && m.isValid()) {
        return <Tag intent={Intent.NONE}>{m.format(FORMAT)}</Tag>;
    }
    return <Tag minimal={true}>no date</Tag>;
};

const MomentDateRange = ({ range: [start, end] }: { range: DateRange }) => (
    <div style={{ paddingBottom: 15 }}>
        <MomentDate date={start} /> <Icon icon="arrow-right" /> <MomentDate date={end} />
    </div>
);

interface Props {
    range: DateRange;
    showDatePicker: boolean;
}

const FormDateRangePicker = ({ range, showDatePicker }: Props) => {
    const curDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 10);
    return (
        <DateRangeContainer>
            <Flex.Container>
                <CalendarLabel>Start Date</CalendarLabel>
                <CalendarLabel>End Date</CalendarLabel>
            </Flex.Container>
            {showDatePicker && (
                <Flex.Container>
                    <FormBareDatePicker path="current_period_start" maxDate={curDate} />
                    <FormBareDatePicker path="current_period_end" maxDate={maxDate} />
                </Flex.Container>
            )}
            <MomentDateRange range={range} />
        </DateRangeContainer>
    );
};

export default FormDateRangePicker;
