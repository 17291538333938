import * as React from 'react';

import { Checkbox } from '@blueprintjs/core';
import { withForm } from './common';

interface ICheckboxInputProps {
    disabled: boolean;
    checkboxLabel?: string;
    labelElement?: React.ReactNode;
    large?: boolean;
    value: boolean;
    onChange: (value: boolean) => void;
}

/**
 * A small wrapper around Blueprint's Checkbox to give it the right interface to use with
 * withForm()
 */
const CheckboxInput = (props: ICheckboxInputProps) => {
    const { large, value, checkboxLabel, labelElement, disabled, onChange } = props;

    return (
        <Checkbox
            large={large}
            checked={value}
            label={checkboxLabel}
            labelElement={labelElement}
            disabled={disabled}
            onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
        />
    );
};

/**
 * A simple checkbox input meant to exist within a Form that provides a FormContext.
 * The API for this is not fully baked, hence it's in the /experimental dir.
 */
const FormCheckboxInput = withForm(CheckboxInput);

export default FormCheckboxInput;
