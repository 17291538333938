import { debounce, toPairs } from 'lodash';
import { Dispatch, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import * as des from 'reports/models/design';
import * as scen from 'reports/models/scenario';
import * as sim from 'reports/models/simulation';
import { actions as projActions } from 'reports/modules/project';

import { makeChannel } from 'helioscope/app/utilities/pusher';

export interface PusherChannel {
    watch(eventName: string, callback: (data: object) => void);
    triggerLocal(eventName: string, data: object);
    unsubscribe(): void;
}

const channels: { [k in number]: PusherChannel } = {};

const updateDesign = (partialDesign: { design_id: number; render_url?: string; currently_rendering: boolean }) =>
    des.schemaObj.dataLoaded(partialDesign);

const refreshSimsInStore = (projectId: number, dispatch: Dispatch) => {
    // Fetch the latest simulation data. Our ReduxEndpoint library automatically updates the store with the fetched data.
    return dispatch(sim.api.index({ project_id: projectId }));
};
// Limit refreshes to twice a second, since many pusher progress events can be fired in quick succession, especially
// for small simulations.
const debouncedRefreshSims = debounce(refreshSimsInStore, 500);

const PROJECT_LISTENERS: {
    [k in string]: (data: any, projectId: number) => Action | ThunkAction<any, any, any, any>;
} = {
    'render.rendering': updateDesign,
    'render.complete': updateDesign,
    // 'render.saving': noop,
    'render.error': updateDesign,

    // TODO: handle deleted design is the primary design for the project
    deleteDesign: ({ design_id }) => des.schemaObj.entityDeleted({ design_id }),

    // simulations
    progress: (_simData, projectId: number) => (dispatch: Dispatch) => debouncedRefreshSims(projectId, dispatch),

    createdInitialScenario: (initialScenarioData) => (dispatch, getState) => {
        const initialScenario = initialScenarioData.scenario;
        dispatch(scen.schemaObj.dataLoaded(initialScenario));
        const scenario = scen.selectors.byObject(getState(), initialScenario)!;
        dispatch(projActions.setPrimaryScenario(scenario.project, scenario));
    },
};

export function installProjectListeners(projectIdMaybeStr: number | string, dispatch: Dispatch) {
    const projectId: number =
        typeof projectIdMaybeStr === 'string' ? parseInt(projectIdMaybeStr, 10) : projectIdMaybeStr;
    if (projectId in channels) {
        return;
    }

    const channel: PusherChannel = (channels[projectId] = makeChannel(`project@${projectId}`));

    for (const [evtName, callback] of toPairs(PROJECT_LISTENERS)) {
        channel.watch(evtName, (data) => dispatch(callback(data, projectId) as any)); // naive dispatch doesn't support thunks
    }
}

export function removeProjectListeners(projectId: number | string) {
    const channel = channels[projectId];
    delete channels[projectId];

    if (channel != null) {
        channel.unsubscribe();
    }
}
