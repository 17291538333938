import { Switch, Intent } from '@blueprintjs/core';
import * as React from 'react';
import styled from 'styled-components';

import FormField from 'reports/components/forms/FormField';
import { FormGroup, IFormGroupProps } from 'reports/components/core/forms';

const StyledSwitch = styled(Switch)`
    margin-bottom: 0;
`;

type ISwitchInputProps = IFormGroupProps & {
    path: string;
    switchLabel?: string;
    disabled?: boolean;
};

// TODO: migrate to using withForm higher-order component
/**
 * A simple toggle switch input meant to exist within a Form that provides a FormContext.
 * The API for this is not fully baked, hence it's in the /experimental dir.
 */
const FormSwitchInput: React.FC<ISwitchInputProps> = (props) => {
    const { path, switchLabel, label, labelWidth, inline, fill, bold, helperText, disabled } = props;
    return (
        <FormField path={path}>
            {({ onChange, value, errors, form }) => (
                <FormGroup
                    inline={inline}
                    fill={fill}
                    bold={bold}
                    helperText={errors.length ? errors[0] : helperText}
                    label={label}
                    labelWidth={labelWidth}
                    intent={errors.length ? Intent.DANGER : undefined}
                >
                    <StyledSwitch
                        checked={value}
                        label={switchLabel}
                        onChange={(e) => onChange((e.target as HTMLInputElement).checked)}
                        disabled={form.submitting || disabled}
                        large={true}
                    />
                </FormGroup>
            )}
        </FormField>
    );
};

export default FormSwitchInput;
