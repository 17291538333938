import React, { useCallback, useEffect, useState } from 'react';
import { Field, useField } from 'formik';

import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { PasswordInput } from '@aurorasolar/ds';

const loadOptions = async () => {
    const zxcvbnCommonPackage = await import(
        /* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common'
    );
    const zxcvbnEnPackage = await import(/* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en');

    return {
        translations: zxcvbnEnPackage.translations,
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        dictionary: {
            ...zxcvbnCommonPackage.dictionary,
            ...zxcvbnEnPackage.dictionary,
        },
    };
};

const scoreMap = ['weak', 'low', 'medium', 'strong', 'strong'] as const;
const scoreDescription = (score: number | null) => {
    switch (score) {
        case 4:
        case 3:
            return 'Password is strong';
        case 2:
        case 1:
            return 'Password is okay, add more characters to make it stronger';
        case 0:
        default:
            return 'Password is weak, add more characters';
    }
};

const PASSWORD_STRENGTH_ERROR = 'Please choose a stronger password';

export const NewPasswordField = ({ name }) => {
    const [score, setScore] = useState<null | number>(null);
    const validate = useCallback(() => {
        return !score || score < 3 ? PASSWORD_STRENGTH_ERROR : undefined;
    }, [score]);

    const [{ value: newPasswordValue }, meta, helpers] = useField({ name, validate });
    const { error } = meta;
    const { setError } = helpers;

    useEffect(() => {
        (async () => {
            const options = await loadOptions();
            zxcvbnOptions.setOptions(options);
        })();
    }, []);

    useEffect(() => {
        if (newPasswordValue) {
            const zxcvbvnValue = zxcvbn(newPasswordValue);
            setScore(zxcvbvnValue ? zxcvbvnValue.score : 0);
        }
    }, [newPasswordValue]);

    return (
        <Field
            component={PasswordInput}
            fontWeight="normal"
            label="New password"
            placeholder="Enter new password"
            onBlur={() => {
                if (error) {
                    setError(undefined);
                }
            }}
            onFocus={() => {
                if (error) {
                    setError(undefined);
                }
            }}
            {...{ name }}
            {...(newPasswordValue
                ? {
                      passwordStrength: score !== null ? scoreMap[score] : 'none',
                      description: scoreDescription(score),
                  }
                : {
                      description: 'Start typing to see password strength',
                  })}
        />
    );
};
