import { Colors, FormGroup, Spinner } from '@blueprintjs/core';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'reports/components/core/controls';

import { IAppState } from 'reports/types';
import { ReferrerType } from 'reports/analytics/ReferrerTypes';
import { IntentContainer } from 'reports/components/helpers/errors';
import * as cfg from 'reports/config';
import { DetailLine } from 'reports/modules/settings/common';

import { useUpcomingInvoice, useWatchSubscription } from '../Hooks';

import { CurrentPeriod } from './CurrentPeriod';
import { CurrentUsage } from './CurrentUsage';
import { LicenseAllocation } from './LicenseAllocation';
import { ProjectLimits } from './ProjectLimits';
import { ScheduledChangeCallout } from './ScheduledChangesCallout';
import { StripePortalButton } from './StripePortalButton';

import {
    getSubscriptionStatusIntent,
    Subscription,
    SubscriptionStatuses,
    SubscriptionVersion,
    api as subApi,
} from 'reports/models/subscription';

import * as team from 'reports/models/team';
import { User } from 'reports/models/user';

import { styled } from 'reports/styles/styled-components';

const AdminProjectCountText = styled.div`
    color: ${Colors.ORANGE3};
`;

interface Props {
    cancelScheduledChanges: any;
    currentUsageAndLimits?: team.ITeamLimitsAndUsage | null;
    manageBilling: boolean;
    subscription: Subscription;
    user: User;
    referrer: ReferrerType;
}

const SelfServePlanInfo = ({
    cancelScheduledChanges,
    currentUsageAndLimits,
    manageBilling,
    referrer,
    subscription,
    user,
}: Props) => {
    const dispatch = useDispatch();

    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));

    const [upcomingInvoice, isLoading] = useUpcomingInvoice(manageBilling, subscription);
    const loadSubscription = async (data: { external_id: string }) => await dispatch(subApi.get(data));

    useWatchSubscription(subscription.external_id, 'invoice.payment_succeeded', () =>
        loadSubscription({ external_id: subscription.external_id }),
    );

    const product = config?.product_metadata[subscription?.product_key];
    const showConsumptionUpsell =
        user?.team.should_upsell_consumption && manageBilling && subscription.isV2 && !subscription.is_awaiting_payment;
    const invoiceRevised =
        subscription.latest_invoice?.status === 'open' && subscription.latest_invoice?.metadata.revised_invoice_id;
    const showUpdateCTA =
        manageBilling &&
        subscription &&
        subscription.version === SubscriptionVersion.v2 &&
        subscription.status === 'active' &&
        !invoiceRevised;

    const showPayInvoice = showUpdateCTA && subscription.status === 'incomplete';
    const showUpgradePlan =
        manageBilling && !subscription.is_expired && subscription.plan_type === 'month' && subscription.isV2;

    const proV2StripeId = config?.product_metadata.pro.v2_stripe_id;

    const isExistingSubscriptionPro = product?.v2_stripe_id === proV2StripeId;

    const basicToProParams = {
        interval: subscription.plan_type,
        product: proV2StripeId,
    };

    const monthlyProToYearlyProParams = {
        interval: 'year',
        product: proV2StripeId,
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <FormGroup label="Account Status">
                <DetailLine>
                    <IntentContainer intent={getSubscriptionStatusIntent(subscription.status)}>
                        {SubscriptionStatuses[subscription.status]}
                    </IntentContainer>
                </DetailLine>
                <ScheduledChangeCallout
                    subscription={subscription}
                    cancelScheduledChanges={cancelScheduledChanges}
                    manageBilling={manageBilling}
                ></ScheduledChangeCallout>
            </FormGroup>
            <FormGroup label="Plan Summary">
                <DetailLine>
                    {product?.name} plan {subscription.plan_type === 'year' ? '(annual)' : '(monthly)'}
                </DetailLine>
                <DetailLine>
                    {showPayInvoice ||
                        (showUpgradePlan && subscription.status === 'incomplete' && (
                            <StripePortalButton
                                asLink={true}
                                name="subscription"
                                subscription={subscription}
                                text={'Pay Invoice'}
                            />
                        ))}
                </DetailLine>
                {product && <ProjectLimits subscription={subscription} projectLimits={product.limits} />}
                {currentUsageAndLimits?.admin_limits && (
                    <AdminProjectCountText>
                        {currentUsageAndLimits?.admin_limits} additional{' '}
                        {currentUsageAndLimits?.admin_limits === 1 ? 'project' : 'projects'} allocated
                    </AdminProjectCountText>
                )}
                {showUpdateCTA && subscription.is_active && !subscription.scheduled_changes && (
                    <Link
                        routeName="app.settings.team.billing"
                        searchParams={{
                            referrer,
                            dialog: 'change',
                            ...(isExistingSubscriptionPro ? monthlyProToYearlyProParams : basicToProParams),
                        }}
                    >
                        Update Plan
                    </Link>
                )}
            </FormGroup>
            <LicenseAllocation showUpdateCTA={showUpdateCTA} subscription={subscription} referrer={referrer} />
            <CurrentUsage
                currentUsageAndLimits={currentUsageAndLimits}
                isTeamAdmin={user.team_admin}
                planType={user.subscription?.plan_type}
                showUpsell={showConsumptionUpsell && !subscription.scheduled_changes}
                subscription={subscription}
            />
            <CurrentPeriod
                manageBilling={manageBilling}
                referrer={referrer}
                subscription={subscription}
                showUpdateCTA={showUpdateCTA}
                team={user.team}
                upcomingInvoice={upcomingInvoice}
            />
        </>
    );
};

export { SelfServePlanInfo };
