import * as React from 'react';
import { Intent } from '@blueprintjs/core';
import FormField from '../../FormField';
import PercentInput from 'reports/components/forms/inputs/experimental/PercentInput';
import { FormGroup } from 'reports/components/core/forms';

/**
 * A percentage input meant to exist within a Form that provides a FormContext.
 * The API for this is not fully baked, hence it's in the /experimental dir.
 * Note: raw value is expected to be 100 times the decimal representation of the percentage.
 * E.g. a raw value of 5 is rendered as 5%, not 500%. See PercentInput.
 */
const FormPercentInput = ({ path, label = '', placeholder = '', helperText = '', precision = 2 }) => (
    <FormField path={path}>
        {({ onChange, value, errors, form }) => (
            <FormGroup
                helperText={errors.length ? errors[0] : helperText}
                label={label}
                intent={errors.length ? Intent.DANGER : undefined}
            >
                <PercentInput
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    disabled={form.submitting}
                    precision={precision}
                />
            </FormGroup>
        )}
    </FormField>
);

export default FormPercentInput;
