import { get } from 'lodash';
import { createSelector } from 'reselect';

import { IAppState, DeepPartial } from 'reports/types';
import { IUserPreferences, api as userAPI } from 'reports/models/user';
import * as auth from './auth';

export const actions = {
    updatePreferences: (preferences: DeepPartial<IUserPreferences>) => (dispatch, getState: () => IAppState) => {
        const user = auth.selectors.getUser(getState());
        const body = { preferences, email: user!.email };
        dispatch(userAPI.save(body, { action: 'update_preferences' }));
    },
};

export const selectors = {
    get getDisplayMetric() {
        return createSelector(auth.selectors.getUser, (user) => {
            const defaultVal = {
                selected_metric: 'system_yield',
                display_metric_bar: false,
            };
            return get(user, 'preferences.display_metric', defaultVal);
        });
    },
};
