import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Button, Grid, GridItem, SunSpinnerLoader } from '@aurorasolar/ds';

import { SettingsContext, useSettingsData } from 'frontend/settings/use-settings-data';

import { setActionBar } from 'frontend/layout/action-bar';

const BaseSettings = () => {
    setActionBar(<Button>Simulate</Button>);

    const { subscription, team, user } = useSettingsData();

    return (
        <Box h="100%" applySystemPadding={true} overflowX="hidden">
            <Grid>
                <GridItem size="three-quarters">
                    {(!team || !user) && <SunSpinnerLoader />}
                    {team && user && <Outlet context={{ subscription, team, user } satisfies SettingsContext} />}
                </GridItem>
            </Grid>
        </Box>
    );
};

export { BaseSettings };
