import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import * as prc from 'reports/models/stripe/price';
import * as cfg from 'reports/config';
import { IAppState } from 'reports/types';

import { StripeCardSetupError } from '../';

import { RequiredFieldError } from 'reports/modules/settings/common';

import { AddressElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

const STRIPE_OPTIONS: StripeElementsOptions = {
    // Must default mode to setup, otherwise we'd have to pass an amount field here
    mode: 'setup',
    currency: 'usd',
    appearance: {
        variables: {
            colorText: '#333',
            fontFamily: 'Helvetica Neue, sans-serif',
            fontSizeBase: '14px',
            fontSizeSm: '14px',
        },
    },
    setup_future_usage: 'off_session',
    payment_method_types: ['card'],
    paymentMethodCreation: 'manual',
};

const validateBillingInfo = async (elements, formData) => {
    const { card, email, paymentMethod } = formData;
    const { error: submitError } = await elements.submit();

    if (submitError) {
        let errors = {};
        // Organization name and address are stored in billingDetails in the formData, but Stripe considers
        // errors in this field a general validation_error and we've historically kept these under the card error field
        // We want to separate it out so that when the user corrects the the billingDetails field
        // we can tell the form which formfield has been dirtied to allow resubmission
        if (submitError.code === 'incomplete_organization_name' || submitError.code === 'incomplete_address') {
            errors = { ...errors, billingDetails: [submitError.message] };
        } else if (submitError.type === 'validation_error') {
            errors = { ...errors, card: [submitError.message] };
        }
        throw new StripeCardSetupError(submitError.message, errors);
    }

    const addressElement = elements.getElement(AddressElement);

    const validateAddress = !!addressElement;

    const billingDetails = validateAddress && (await addressElement.getValue());

    if (paymentMethod === 'credit_card' && !card?.complete) {
        throw new RequiredFieldError({ card: ['Card info is required.'] });
    }

    const errors = validateAddress
        ? {
              ...(email === '' && { email: ['Email is required.'] }),
              ...((!billingDetails || !billingDetails.complete) && { billingDetails: ['Billing address is required'] }),
          }
        : {};

    if (!isEmpty(errors)) {
        throw new RequiredFieldError(errors);
    }

    return { billingDetails };
};

const confirmCardPayment = async (stripe, elements, paymentIntentClientSecret: string | null, amount, handleError?) => {
    if (!paymentIntentClientSecret) {
        throw new StripeCardSetupError('missing payment intent', {
            card: ['missing payment intent'],
        });
    }

    // We cannot just use the payment intent client secret because there is a difference between
    // paying with a new card setup versus using an existing payment method attached to the customer
    // We need to check for the existence of PaymentElement here because on new cards
    // we perform a setup step followed by a payment step, and in order to call confirmSetup (in setupNewCard)
    // or confirmPayment here, the elements options needs to have specific fields set
    // If calling confirmPayment with an existing card, we cannot pass elements in the call
    const paymentElement = elements.getElement('payment');
    if (paymentElement) {
        elements.update({ amount, mode: 'payment' });
    }
    const { error } = await stripe.confirmPayment({
        ...(paymentElement && { elements }),
        clientSecret: paymentIntentClientSecret,
        redirect: 'if_required',
    });

    if (error) {
        // This allows us to do things like remove incomplete subscriptions when we have card failures.
        if (error.code === 'card_declined' || error.code === 'payment_intent_authentication_failure') {
            handleError && (await handleError());
        }
        if (error.code === 'payment_intent_incompatible_payment_method') {
            error.message =
                'Transaction declined. Your card was rejected while verifying. Double-check the information you entered and try again or try adding another payment method.';
        }

        throw new StripeCardSetupError(
            error.message,
            {
                ...(error.type === 'validation_error' && { card: [error.message] }),
            },
            error.code,
        );
    }
};

const setupNewCard = async (stripe, elements) => {
    const { paymentMethod } = await stripe.createPaymentMethod({
        elements,
    });

    return paymentMethod.id;
};

const useStripeData = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [basicPrices, setBasicPrices] = React.useState<prc.Price[]>([]);
    const [proPrices, setProPrices] = React.useState<prc.Price[]>([]);

    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();
    const getPrices = ({ product_id }: { product_id: string | undefined }) => dispatch(prc.api.index({ product_id }));

    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));

    React.useEffect(() => {
        (async () => {
            const basicPrices = await getPrices({
                product_id: config?.product_metadata.basic.v2_stripe_id,
            });

            const proPrices = await getPrices({
                product_id: config?.product_metadata.pro.v2_stripe_id,
            });

            setBasicPrices(basicPrices);
            setProPrices(proPrices);
            setIsLoading(false);
        })();
        return () => {};
    }, []);

    return {
        basicPrices,
        proPrices,
        isLoading,
        confirmCardPayment: (paymentIntentClientSecret, amount, handleError?) =>
            confirmCardPayment(stripe, elements, paymentIntentClientSecret, amount, handleError),
        setupNewCard: () => setupNewCard(stripe, elements),
        validateBillingInfo: (formData) => validateBillingInfo(elements, formData),
    };
};

const StripeContainer = ({ children }: React.PropsWithChildren<{}>) => {
    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));
    const stripePublicKey = config?.stripe_public_key;

    if (!stripePublicKey) {
        throw new Error('Error loading Stripe');
    }

    const stripe = React.useMemo(() => loadStripe(stripePublicKey), [stripePublicKey]);

    return (
        <Elements options={STRIPE_OPTIONS} stripe={stripe}>
            {children}
        </Elements>
    );
};

export { useStripeData, StripeContainer };
