import { get, map } from 'lodash';

import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { actions as routerActions } from 'redux-router5';

import { Colors } from '@blueprintjs/core';

import { actionCreatorFactory, isType, Action } from 'typescript-fsa';

import { IAppState } from 'reports/types';
import { createUniqueDescription } from 'reports/utils/helpers';

import { Theme } from 'reports/models/theme';
import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';
import * as s3file from 'reports/models/s3file';

import { selectors as projSelector } from 'reports/modules/project';
import { selectors as finSelector } from 'reports/modules/financials/state';
import { selectors as themeSelector, parseColorMap } from 'reports/modules/themes';
import { createTokenMap } from 'reports/modules/report/tokens';
import { IReportContext, IWidgetRenderProps } from 'reports/modules/report/widgets';
import * as styles from 'reports/modules/report/styles';

const actionCreator = actionCreatorFactory('REPORTS');

/**
 * Generate a theme's style map to be used across the reports application.
 * Note: Style map structure defined (and originally used) by the generic text widget's Draft.js customStyleMap. Now
 * used to maintain theme styling as well.
 * @param theme - theme to use
 * @returns Style map includes theme's palette and primary and secondary colours, if available.
 */
function generateStyleMap(theme: Theme): styles.IStyleMap {
    const themeColors: string[] = [];

    if (theme.palette && theme.palette.colors.length) {
        themeColors.concat(Colors.BLACK, theme.palette.colors);
    }
    const themePalette = themeColors.length ? styles.mapColors(themeColors) : styles.DEFAULT_COLOUR_MAP;

    return {
        ...styles.DEFAULT_FONT_FAMILIES,
        ...styles.DEFAULT_FONT_SIZES,
        ...themePalette,
        ...(theme.primary_color ? { color_primary: { color: theme.primary_color } } : {}),
        ...(theme.secondary_color ? { color_secondary: { color: theme.secondary_color } } : {}),
    };
}

export const actions = {
    saveAsNew: (report: rep.Report, document: rep.Document) => async (dispatch: Dispatch) => {
        const potentialCopies = await dispatch(rep.api.index({ name: report.name }));
        const name = createUniqueDescription(report.name, map(potentialCopies, 'name'));
        return await dispatch(rep.api.create({ name, document }));
    },

    open: (report: rep.Report, editing: boolean, projectId: number | string) => async (dispatch) => {
        const routeName = editing ? 'app.reports.report.edit' : 'app.reports.report.preview';

        const params = {
            projectId,
            slug: report.slug,
            reportId: report.report_id, // TODO: could only add this field when it's necessary for uniqueness
        };

        dispatch(routerActions.navigateTo(routeName, params));
    },

    setEditing: (editing: boolean) => async (dispatch, getState: () => IAppState) => {
        const route = getState().router.route!;
        if (!route.name.startsWith('app.reports.report')) {
            throw Error('Attempt to change mode from outside report library view');
        }
        const newRouteName = editing ? 'app.reports.report.edit' : 'app.reports.report.preview';

        dispatch(routerActions.navigateTo(newRouteName, route.params));
    },

    setPreviewProject: (projectId: number | string) => async (dispatch, getState: () => IAppState) => {
        const route = getState().router.route!;
        if (!route.name.startsWith('app.reports.report')) {
            throw Error('Attempt to set preview project from outside report library view');
        }

        dispatch(
            routerActions.navigateTo(route.name, {
                ...route.params,
                projectId,
            }),
        );
    },

    getDefaultProjectId: () => async (dispatch, getState: () => IAppState) => {
        const lastLoadedId = projSelector.getLastLoadedProjectId(getState());
        if (lastLoadedId != null) {
            return lastLoadedId;
        }
        const lastViewedProjectId = projSelector.getLastViewedProjectId(getState());
        if (lastViewedProjectId != null) {
            return lastViewedProjectId;
        }
        const lastModifiedProjects = await dispatch(proj.api.index({ limit: 1 }));
        if (lastModifiedProjects.length) {
            return lastModifiedProjects[0].project_id;
        }
        return null;
    },

    setCurrentPage: actionCreator<number>('SET_CURRENT_PAGE'),
    setZoomScale: actionCreator<number>('SET_ZOOM_SCALE'),
};

interface IReportProps {
    report: rep.Report;
    project: proj.Project;
}

export const selectors = {
    zoomScale: (state: IAppState) => state.report.zoomScale,
    currentPage: (state: IAppState) => state.report.currentPage,

    get fileLoader() {
        return createSelector(
            (_state: IAppState, { report }: IReportProps) => report.file_urls,
            themeSelector.getThemeFromProject,
            (fileUrls, theme) => {
                const fileMap = fileUrls || {};
                if (theme && theme.logo_id != null) {
                    fileMap[theme.logo_id] = theme.logo_url!;
                }

                return (fileId) => get(fileMap, fileId, s3file.api.download.url({ file_id: fileId }));
            },
        );
    },

    get context() {
        const mapTokens = createSelector((context) => context, createTokenMap);

        return createSelector(
            (_state: IAppState, { project }: IReportProps) => project,
            projSelector.primaryDesign,
            projSelector.primaryScenario,
            projSelector.primarySimulation,
            finSelector.primaryFinConfigTokens,
            themeSelector.getThemeFromProject,
            this.fileLoader,
            (project, design, scenario, simulation, financialTokens, theme, fileLoader) => {
                const initialContext: any = {
                    project,
                    design,
                    scenario,
                    simulation,
                    financialTokens,
                    theme,
                    fileLoader,
                };

                initialContext.tokenMap = mapTokens(initialContext);
                return initialContext as IReportContext;
            },
        );
    },

    _getStyleMap: (content: any, theme: Theme) => {
        const styleMap = theme ? generateStyleMap(theme) : styles.DEFAULT_STYLE_MAP;
        return content && content.styleMap ? { ...styleMap, ...content.styleMap } : { ...styleMap };
    },

    get getStyleMap() {
        // TODO: remove, probably
        return createSelector(
            ({ config }: IWidgetRenderProps<{ styleMap?: any }>) => config.content,
            ({ context }: IWidgetRenderProps<any, { theme: Theme }>) => context.theme,
            (content, theme) => {
                const styleMap = theme ? generateStyleMap(theme) : styles.DEFAULT_STYLE_MAP;
                return content?.styleMap ? { ...styleMap, ...content.styleMap } : { ...styleMap };
            },
        );
    },

    get compiledStyle() {
        // TODO: remove, probably
        return createSelector(
            ({ config }: IWidgetRenderProps<{ style: any }>) => config,
            ({ context }: IWidgetRenderProps<any, { theme: Theme }>) => context.theme,
            (config, theme) => parseColorMap(config.content.style, this._getStyleMap(config, theme)),
        );
    },
};

export interface IReportState {
    currentPage: number;
    zoomScale: number;
}

const INITIAL_STATE: IReportState = {
    zoomScale: 0.8,
    currentPage: 0,
};

export const reducer = (state: IReportState = INITIAL_STATE, action: Action<any>): IReportState => {
    if (isType(action, actions.setZoomScale)) {
        return {
            ...state,
            zoomScale: action.payload,
        };
    }

    if (isType(action, actions.setCurrentPage)) {
        return {
            ...state,
            currentPage: action.payload,
        };
    }

    return state;
};
