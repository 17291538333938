import * as React from 'react';
import { useDispatch } from 'react-redux';

import * as DS from '@aurorasolar/ds';

import { api as wiresAPI, Wire, WIRING_UNITS } from 'reports/models/wire';

type Row = DS.ITableRow & {
    name: string;
    material: string;
    units: string;
    resistivity: number;
};

const order = (size: string): number => {
    if (size.includes('MCM')) {
        return 0;
    }

    if (size.includes('/0')) {
        return 1;
    }

    return 2;
};

const columns = [
    {
        accessor: 'name',
        Header: 'Size',
        sortType: (rowA: any, rowB: any, columnId: string): 1 | -1 => {
            const { units } = rowA.values;
            const a = rowA.values[columnId];
            const b = rowB.values[columnId];

            if (units === WIRING_UNITS.metric) {
                return parseInt(a, 10) > parseInt(b, 10) ? 1 : -1;
            }

            if (order(a) < order(b)) {
                return 1;
            }

            if (order(a) > order(b)) {
                return -1;
            }

            if (order(a) === order(b) && !a.includes('MCM') && !a.includes('/0')) {
                return parseInt(a, 10) > parseInt(b, 10) ? -1 : 1;
            }

            if (order(a) === order(b)) {
                return parseInt(a, 10) > parseInt(b, 10) ? 1 : -1;
            }

            return 1;
        },
    },
    {
        accessor: 'material',
        Header: 'Material',
    },
    {
        accessor: 'units',
        Header: 'Units',
        disableSortBy: true,
    },
    {
        accessor: 'resistivity',
        Header: 'Resistivity (Ω/m)',
        sortType: (rowA: any, rowB: any, columnId: string): 1 | -1 => {
            const a = rowA.values[columnId];
            const b = rowB.values[columnId];
            return a > b ? 1 : -1;
        },
    },
];

const useWiringTableData = () => {
    const [data, setData] = React.useState<Row[]>([]);
    const dispatch = useDispatch();
    React.useEffect(() => {
        const fetchWires = async () => {
            const wires: Wire[] = await dispatch(wiresAPI.index());
            const rows: Row[] = wires.map(({ name, material, units, resistivity }: Wire) => ({
                name,
                material,
                resistivity,
                units: units === 'awg' ? WIRING_UNITS.awg : WIRING_UNITS.metric,
            }));
            setData(rows);
        };
        fetchWires();
        return () => {};
    }, []);

    return { columns, data };
};

export { columns, useWiringTableData, Row };
