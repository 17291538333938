import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions, createRouteNodeSelector } from 'redux-router5';

import { Breadcrumb, Breadcrumbs as BPBreadcrumbs, Button, Popover } from '@blueprintjs/core';

import { RouteComponent } from 'reports/routing';
import { IAppState } from 'reports/types';

import { selectors as desSelectors } from 'reports/models/design';
import { selectors as projSelectors } from 'reports/models/project';
import { selectors as repSelectors } from 'reports/models/report';
import { selectors as usrSelectors } from 'reports/models/user';
import { selectors as profSelectors } from 'reports/models/profile';
import * as scen from 'reports/models/scenario';
import { selectors as invSelectors } from 'reports/models/stripe/invoice';
import { selectors as moduleSelectors } from 'reports/models/module';
import { selectors as deviceSelectors } from 'reports/models/power_device';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';
import * as inc from 'reports/models/incentive';
import * as utility from 'reports/models/utility_rate';

import DesignDropdown from 'reports/modules/project/components/DesignDropdown';
import { ConditionsPopContainer, FinConfigPopContainer } from 'reports/components/BreadcrumbPopovers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const _TextWrapper = styled.div`
    overflow: hidden;
    maxwidth: 160px;
    textoverflow: ellipsis;
    whitespace: nowrap;
`;

const Breadcrumbs = styled(BPBreadcrumbs)`
    // Now that beta is wrapped in a StyleProvider, xstyled margin-top: 0 is set for the breadcrumbs.
    // The xstyled styles are overriden here to maintain the existing beta look.
    li:first-child {
        margin-top: 8px;
    }
    li {
        padding-left: 0px;
    }
`;

const DropdownButton: React.SFC<{ text: string }> = ({ text }) => (
    <Button small minimal text={<_TextWrapper>{text}</_TextWrapper>} rightIcon="caret-down" />
);

type IDispatchProps = typeof mapDispatchToProps;
type IStateProps = ReturnType<typeof mapStateToProps>;

class ContextBreadcrumbs extends React.PureComponent<IStateProps & IDispatchProps> {
    render() {
        // TODO: is it better to do this with providers instead?

        return (
            <div>
                <RouteComponent name="app.projects">{this.renderBreadcrumbs(['home'])}</RouteComponent>
                <RouteComponent name="app.projects.project.overview">
                    {this.renderBreadcrumbs(['home', 'project'])}
                </RouteComponent>
                <RouteComponent name="app.projects.project.report" exact={false}>
                    {this.renderBreadcrumbs(['home', 'project', 'projectReport'])}
                </RouteComponent>
                <RouteComponent name="app.projects.project.designer">
                    {this.renderBreadcrumbs(['home', 'project', 'designer'])}
                </RouteComponent>
                <RouteComponent name="app.projects.project.conditions">
                    {this.renderBreadcrumbs(['home', 'project', 'conditions'])}
                </RouteComponent>
                <RouteComponent name="app.projects.project.conditions.condition" exact={false}>
                    {this.renderBreadcrumbs(['home', 'project', 'condition'])}
                </RouteComponent>
                <RouteComponent name="app.projects.project.financial-configurations">
                    {this.renderBreadcrumbs(['home', 'project', 'finconfigs'])}
                </RouteComponent>
                <RouteComponent name="app.projects.project.financial-configurations.financial-configuration">
                    {this.renderBreadcrumbs(['home', 'project', 'finconfig'])}
                </RouteComponent>
                <RouteComponent name="app.projects.project.sharing">
                    {this.renderBreadcrumbs(['home', 'project', 'sharing'])}
                </RouteComponent>
                <RouteComponent name="app.projects.project.simulation">
                    {this.renderBreadcrumbs(['home', 'project', 'simulation'])}
                </RouteComponent>
                <RouteComponent name="app.reports">{this.renderBreadcrumbs(['home', 'reports'])}</RouteComponent>
                <RouteComponent name="app.reports.report" exact={false}>
                    {this.renderBreadcrumbs(['home', 'reports', 'report'])}
                </RouteComponent>
                <RouteComponent name="app.profiles">{this.renderBreadcrumbs(['home', 'profiles'])}</RouteComponent>
                <RouteComponent name="app.profiles.profile" exact={false}>
                    {this.renderBreadcrumbs(['home', 'profiles', 'profile'])}
                </RouteComponent>
                <RouteComponent name="app.financial-templates">
                    {this.renderBreadcrumbs(['home', 'fintemplates'])}
                </RouteComponent>
                <RouteComponent name="app.financial-templates.financial-template" exact={false}>
                    {this.renderBreadcrumbs(['home', 'fintemplates', 'fintemplate'])}
                </RouteComponent>
                <RouteComponent name="app.utility-rates">
                    {this.renderBreadcrumbs(['home', 'utilrates'])}
                </RouteComponent>
                <RouteComponent name="app.utility-rates.utility-rate">
                    {this.renderBreadcrumbs(['home', 'utilrates', 'utilrate'])}
                </RouteComponent>
                <RouteComponent name="app.incentives">{this.renderBreadcrumbs(['home', 'incentives'])}</RouteComponent>
                <RouteComponent name="app.incentives.incentive">
                    {this.renderBreadcrumbs(['home', 'incentives', 'incentive'])}
                </RouteComponent>
                <RouteComponent name="app.power-devices">
                    {this.renderBreadcrumbs(['home', 'powerdevices'])}
                </RouteComponent>
                <RouteComponent name="app.power-devices.power-device" exact={false}>
                    {this.renderBreadcrumbs(['home', 'powerdevices', 'powerdevice'])}
                </RouteComponent>
                <RouteComponent name="app.modules">{this.renderBreadcrumbs(['home', 'modules'])}</RouteComponent>
                <RouteComponent name="app.weather_sources">
                    {this.renderBreadcrumbs(['home', 'weather_sources'])}
                </RouteComponent>
                <RouteComponent name="app.modules.module" exact={false}>
                    {this.renderBreadcrumbs(['home', 'modules', 'module'])}
                </RouteComponent>
                <RouteComponent name="app.wires" exact={false}>
                    {this.renderBreadcrumbs(['home', 'wires'])}
                </RouteComponent>
                <RouteComponent name="app.settings">{this.renderBreadcrumbs(['settings'])}</RouteComponent>
                <RouteComponent name="app.settings.user">
                    {this.renderBreadcrumbs(['settings', 'account'])}
                </RouteComponent>
                <RouteComponent name="app.settings.user.preferences">
                    {this.renderBreadcrumbs(['settings', 'account', 'preferences'])}
                </RouteComponent>
                <RouteComponent name="app.settings.user.features">
                    {this.renderBreadcrumbs(['settings', 'account', 'features'])}
                </RouteComponent>
                <RouteComponent name="app.settings.team.overview">
                    {this.renderBreadcrumbs(['settings', 'team'])}
                </RouteComponent>
                <RouteComponent name="app.settings.team.manage" exact={false}>
                    {this.renderBreadcrumbs(['settings', 'team', 'manageteam'])}
                </RouteComponent>
                <RouteComponent name="app.settings.team.integrations">
                    {this.renderBreadcrumbs(['settings', 'team', 'integrations'])}
                </RouteComponent>
                <RouteComponent name="app.settings.team.themes">
                    {this.renderBreadcrumbs(['settings', 'team', 'themes'])}
                </RouteComponent>
                <RouteComponent name="app.settings.team.billing">
                    {this.renderBreadcrumbs(['settings', 'team', 'billing'])}
                </RouteComponent>
                <RouteComponent name="app.settings.team.features">
                    {this.renderBreadcrumbs(['settings', 'team', 'features'])}
                </RouteComponent>
                <RouteComponent name="app.settings.hs-admin.subscription">
                    {this.renderBreadcrumbs(['settings', 'admin', 'subscription'])}
                </RouteComponent>
                <RouteComponent name="app.settings.hs-admin.merge-teams">
                    {this.renderBreadcrumbs(['settings', 'admin', 'mergeteams'])}
                </RouteComponent>
                <RouteComponent name="app.settings.hs-admin.team-features">
                    {this.renderBreadcrumbs(['settings', 'admin', 'teamfeatures'])}
                </RouteComponent>
                <RouteComponent name="app.settings.hs-admin.user-features">
                    {this.renderBreadcrumbs(['settings', 'admin', 'userfeatures'])}
                </RouteComponent>
            </div>
        );
    }

    renderBreadcrumbs(items) {
        const { navigateTo } = this.props;
        const navigateToSettings = (name: string) => {
            navigateTo(name, { email: this.props.user?.email });
        };
        const itemMap = {
            home: () => ({
                text: this.renderFixed('Home'),
                onClick: () => navigateTo('app.projects', {}),
            }),
            project: () => ({
                text: this.renderLimited(this.props.project ? this.props.project.name : ''),
                onClick: () =>
                    navigateTo('app.projects.project.overview', {
                        projectId: this.props.project!.project_id,
                    }),
            }),
            projectReport: () => ({
                text: this.renderLimited(this.props.report ? this.props.report.name : ''),
                onClick: () =>
                    navigateTo('app.projects.project.report.view', {
                        projectId: this.props.project!.project_id,
                        slug: this.props.report!.slug,
                        reportId: this.props.report!.report_id,
                    }),
            }),
            designer: () => ({
                text: this.props.design && (
                    <DesignDropdown project={this.props.project!}>
                        <DropdownButton text={this.props.design.description} />
                    </DesignDropdown>
                ),
            }),
            conditions: () => ({
                text: this.renderFixed('Conditions'),
                onClick: () =>
                    navigateTo('app.projects.project.conditions', {
                        projectId: this.props.project!.project_id,
                    }),
            }),
            condition: () => ({
                text: this.renderConditionsDropdown(),
                onClick: () =>
                    navigateTo('app.projects.project.conditions.condition', {
                        projectId: this.props.project!.project_id,
                        scenarioId: this.props.scenario!.scenario_id,
                    }),
            }),
            finconfigs: () => ({
                text: this.renderFixed('Financial Configurations'),
                onClick: () =>
                    navigateTo('app.projects.project.financial-configurations', {
                        projectId: this.props.project!.project_id,
                    }),
            }),
            finconfig: () => ({
                text: this.renderFinConfigDropdown(),
                onClick: () =>
                    navigateTo('app.projects.project.financial-configurations.financial-configuration', {
                        projectId: this.props.project!.project_id,
                        finConfigId: this.props.finConfig!.project_financial_template_id,
                    }),
            }),
            sharing: () => ({
                text: this.renderFixed('Sharing'),
                onClick: () =>
                    navigateTo('app.projects.project.sharing', {
                        projectId: this.props.project!.project_id,
                    }),
            }),
            simulation: () => ({
                text: this.renderFixed('Simulation'),
                onClick: () =>
                    navigateTo('app.projects.project.simulation', {
                        projectId: this.props.project!.project_id,
                    }),
            }),
            reports: () => ({
                text: this.renderFixed('Reports'),
                onClick: () =>
                    navigateTo('app.reports', this.props.project ? { projectId: this.props.project.project_id } : {}),
            }),
            report: () => ({
                text: this.renderLimited(this.props.report ? this.props.report.name : ''),
                onClick: () =>
                    navigateTo('app.reports.report.preview', {
                        projectId: this.props.project!.project_id,
                        slug: this.props.report!.slug,
                        reportId: this.props.report!.report_id,
                    }),
            }),
            profiles: () => ({
                text: this.renderFixed('Profiles'),
                onClick: () => navigateTo('app.profiles', {}),
            }),
            profile: () => ({
                text: this.renderLimited(this.props.profile ? this.props.profile.name : ''),
                onClick: () =>
                    navigateTo('app.profiles.profile.preview', {
                        profileId: this.props.profile!.profile_id,
                    }),
            }),
            fintemplates: () => ({
                text: this.renderFixed('Financial Models'),
                onClick: () => navigateTo('app.financial-templates', {}),
            }),
            fintemplate: () => ({
                text: this.renderLimited(this.props.finTemplate ? this.props.finTemplate.description : ''),
                onClick: () =>
                    navigateTo('app.financial-templates.financial-template.preview', {
                        finTemplateId: this.props.finTemplate!.financial_template_id,
                    }),
            }),
            utilrates: () => ({
                text: this.renderFixed('Utility Rates'),
                onClick: () => navigateTo('app.utility-rates', {}),
            }),
            utilrate: () => ({
                text: this.renderLimited(this.props.utilityRate ? this.props.utilityRate.description : ''),
                onClick: () =>
                    navigateTo('app.utility-rates.utility-rate', {
                        rateId: this.props.utilityRate!.utility_rate_id,
                    }),
            }),
            incentives: () => ({
                text: this.renderFixed('Incentives'),
                onClick: () => navigateTo('app.incentives', {}),
            }),
            incentive: () => ({
                text: this.renderLimited(this.props.incentive ? this.props.incentive.name : ''),
                onClick: () =>
                    navigateTo('app.incentives.incentive', {
                        incentiveId: this.props.incentive!.incentive_id,
                    }),
            }),
            powerdevices: () => ({
                text: this.renderFixed('Inverters & Optimizers'),
                onClick: () => navigateTo('app.power-devices', {}),
            }),
            powerdevice: () => ({
                text: this.renderLimited(this.props.powerDevice ? this.props.powerDevice.name : ''),
                onClick: () =>
                    navigateTo('app.power-devices.power-device', {
                        powerDeviceId: this.props.powerDevice!.power_device_id,
                    }),
            }),
            modules: () => ({
                text: this.renderFixed('Modules'),
                onClick: () => navigateTo('app.modules', {}),
            }),
            module: () => ({
                text: this.renderLimited(this.props.module ? this.props.module.name : ''),
                onClick: () =>
                    navigateTo('app.modules.module', {
                        moduleId: this.props.module!.module_id,
                    }),
            }),
            weather_sources: () => ({
                text: this.renderFixed('Weather Sources'),
                onClick: () => navigateTo('app.weather_sources', {}),
            }),
            wires: () => ({
                text: this.renderFixed('Wires'),
                onClick: () => navigateTo('app.wires', {}),
            }),
            settings: () => {
                const { user } = this.props;
                const text = user ? `Settings for ${user.first_name} ${user.last_name}` : 'Settings';
                return { text: this.renderFixed(text) };
            },
            account: () => ({
                text: this.renderFixed('Account'),
                onClick: () => navigateToSettings('app.settings.user'),
            }),
            preferences: () => ({
                text: this.renderFixed('Preferences'),
                onClick: () => navigateToSettings('app.settings.user.preferences'),
            }),
            features: () => ({
                text: this.renderFixed('Features'),
                onClick: () => navigateToSettings('app.settings.user.features'),
            }),
            team: () => ({
                text: this.renderFixed('Team'),
                onClick: () => navigateToSettings('app.settings.team.overview'),
            }),
            manageteam: () => ({
                text: this.renderFixed('Manage Team'),
                onClick: () => navigateToSettings('app.settings.team.manage'),
            }),
            integrations: () => ({
                text: this.renderFixed('Integrations'),
                onClick: () => navigateToSettings('app.settings.team.integrations'),
            }),
            themes: () => ({
                text: this.renderFixed('Themes'),
                onClick: () => navigateToSettings('app.settings.team.themes'),
            }),
            billing: () => ({
                text: this.renderFixed('Billing'),
                onClick: () => navigateToSettings('app.settings.team.billing'),
            }),
            admin: () => ({
                text: this.renderFixed('HS Admin'),
                onClick: () => navigateToSettings('app.settings.hs-admin.subscription'),
            }),
            subscription: () => ({
                text: this.renderFixed('Subscription'),
                onClick: () => navigateToSettings('app.settings.hs-admin.subscription'),
            }),
            mergeteams: () => ({
                text: this.renderFixed('Merge Teams'),
                onClick: () => navigateToSettings('app.settings.hs-admin.merge-teams'),
            }),
            teamfeatures: () => ({
                text: this.renderFixed('Team Features'),
                onClick: () => navigateToSettings('app.settings.hs-admin.team-features'),
            }),
            userfeatures: () => ({
                text: this.renderFixed('User Features'),
                onClick: () => navigateToSettings('app.settings.hs-admin.user-features'),
            }),
        };

        return (
            <Breadcrumbs
                items={items.map((i) => itemMap[i]())}
                currentBreadcrumbRenderer={({ onClick: _, ...props }) => <Breadcrumb current={true} {...props} />}
            />
        );
    }

    renderFinConfigDropdown() {
        const text = this.props.finConfig ? this.props.finConfig.name : '';

        return (
            <Popover
                boundary="scrollParent"
                modifiers={{ preventOverflow: { enabled: true } }}
                content={<FinConfigPopContainer project={this.props.project} projFinConfig={this.props.finConfig} />}
            >
                <DropdownButton text={text} />
            </Popover>
        );
    }

    renderConditionsDropdown() {
        const text = this.props.scenario ? this.props.scenario.description : '';

        return (
            <Popover
                boundary="scrollParent"
                modifiers={{ preventOverflow: { enabled: true } }}
                content={<ConditionsPopContainer project={this.props.project} scenario={this.props.scenario} />}
            >
                <DropdownButton text={text} />
            </Popover>
        );
    }

    renderFixed(text) {
        const inner = (
            <div
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {text}
            </div>
        );

        return <Button small minimal text={inner} />;
    }

    renderLimited(text, maxWidth = 160) {
        const inner = (
            <div
                style={{
                    overflow: 'hidden',
                    maxWidth: `${maxWidth}px`,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {text}
            </div>
        );

        return <Button small minimal text={inner} />;
    }
}

const mapStateToProps = (state: IAppState) => {
    const routeSelector = createRouteNodeSelector('');
    const route = routeSelector(state).route;
    const params = route ? route.params : {};

    return {
        route,
        project: projSelectors.byId(state, params.projectId),
        design: desSelectors.byId(state, params.designId),
        designs: projSelectors.designs(state, {
            project_id: parseInt(params.projectId, 10),
        }),
        profile: profSelectors.byId(state, params.profileId),
        finConfig: projFinTemp.selectors.byId(state, params.finConfigId),
        finTemplate: finTemp.selectors.byId(state, params.finTemplateId),
        utilityRate: utility.selectors.byId(state, params.rateId),
        scenario: scen.selectors.byId(state, params.scenarioId),
        report: params.reportId ? repSelectors.byId(state, params.reportId) : repSelectors.bySlug(state, params.slug),
        incentive: inc.selectors.byId(state, params.incentiveId),
        powerDevice: deviceSelectors.byId(state, params.powerDeviceId),
        module: moduleSelectors.byId(state, params.moduleId),
        invoice: invSelectors.byId(state, params.invoiceId),
        user: params.email ? usrSelectors.byEmail(state, params.email) : undefined,
    };
};

const mapDispatchToProps = {
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
};

export default connect<IStateProps, IDispatchProps>(mapStateToProps, mapDispatchToProps)(ContextBreadcrumbs);
