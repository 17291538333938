import { withForm } from './common';
import NumericInput from './NumericInput';

/**
 * A simple numeric input meant to exist within a Form that provides a FormContext.
 *
 * The API for this is not fully baked, hence it's in the /experimental dir.
 */
const FormNumericInput = withForm(NumericInput);

export default FormNumericInput;
