import * as React from 'react';

import { useDispatch } from 'react-redux';

import type { Address } from '@stripe/stripe-js';

import * as inv from 'reports/models/stripe/invoice';
import { Price } from 'reports/models/stripe/price';

import { Subscription } from 'reports/models/subscription';
import { Team } from 'reports/models/team';

import { makeChannel } from 'helioscope/app/utilities/pusher';
import { PusherChannel } from 'reports/modules/project/listeners';
import { api as CustomerAPI, StripeCustomer } from 'reports/models/stripe/stripe_customer';

interface InvoicePreviewData {
    isScheduledChange?: boolean;
    quantity: number;
    price?: Price;
    subscription?: Subscription;
    team: Team;
    address?: Address;
}

interface AddressValidation {
    tax_included: boolean;
}

const useDebounce = (value: any, timeoutMs: number = 1000) => {
    const [debouncedValue, setDebouncedValue] = React.useState<any | undefined>(value);

    React.useEffect(() => {
        const timeoutRef = setTimeout(() => setDebouncedValue(value), timeoutMs);
        return () => clearTimeout(timeoutRef);
    }, [value]);
    return debouncedValue;
};

const useAddressValidation = (subscription, address) => {
    const dispatch = useDispatch();
    const getAddressValidation = ({ subscription, address }) =>
        dispatch(CustomerAPI.getAddressValidation({ address }, { stripe_subscription_id: subscription.external_id }));
    const defaultValidation = { tax_included: false };
    const [addressValidation, setAddressValidation] = React.useState<AddressValidation>(defaultValidation);
    const [isValidatingAddress, setIsValidatingAddress] = React.useState(false);
    const debouncedAddress = useDebounce(address);

    const validateAddress = ({ address }) => {
        (async () => {
            setIsValidatingAddress(true);
            setAddressValidation(defaultValidation);
            const validation = await getAddressValidation({ subscription, address });
            setAddressValidation({ tax_included: validation.tax_included });
            setIsValidatingAddress(false);
        })();
    };

    // Set validating flag on instantaneous value change instead of debounced value
    React.useEffect(() => {
        setIsValidatingAddress(true);
    }, [address]);

    React.useEffect(() => {
        if (subscription && debouncedAddress) {
            validateAddress({ address: debouncedAddress });
        }
        return () => {};
    }, [subscription, debouncedAddress]);

    return { addressValidation, validateAddress, isValidatingAddress, setIsValidatingAddress };
};

const useInvoicePreview = ({ isScheduledChange, quantity, price, subscription, team, address }: InvoicePreviewData) => {
    const [invoice, setInvoice] = React.useState<inv.Invoice | null>();
    const dispatch = useDispatch();
    const getNewInvoicePreview = ({ quantity, stripe_customer_id, stripe_price_id, address }) =>
        dispatch(
            inv.invoicePreviewAPI.getNewInvoicePreview({
                quantity,
                stripe_customer_id,
                stripe_price_id,
                address,
            }),
        );
    const getChangeInvoicePreview = ({ quantity, address, stripe_price_id, subscription }) =>
        dispatch(
            inv.invoicePreviewAPI.getChangeInvoicePreview({
                quantity,
                address,
                subscription,
                stripe_price_id,
            }),
        );

    const debouncedAddress = useDebounce(address);

    React.useEffect(() => {
        (async () => {
            if (!price || !quantity) {
                return;
            }

            if (subscription && !subscription.is_closed && !isScheduledChange) {
                return setInvoice(
                    await getChangeInvoicePreview({
                        quantity,
                        address: debouncedAddress,
                        stripe_price_id: price.id,
                        subscription: subscription.external_id,
                    }),
                );
            }

            // Because there are cases when the stripe customer has not yet been created,
            // we want to wait on team.stripe_customer_id being available before fetching a new invoice preview.
            if (team.stripe_customer_id) {
                return setInvoice(
                    await getNewInvoicePreview({
                        quantity,
                        address: debouncedAddress,
                        stripe_customer_id: team.stripe_customer_id,
                        stripe_price_id: price.id,
                    }),
                );
            }
        })();
        return () => {};
    }, [price?.id, quantity, isScheduledChange, debouncedAddress, team.stripe_customer_id]);

    return {
        invoice,
    };
};

// Watch subscription Pusher channel named subscription@<sub_external_id> and handle eventName event
// TODO To register for different events you'd have to call this hook multiple times
// Not sure about the implications of registering multiple channels of the same identifier
const useWatchSubscription = (
    subID: string | null = null,
    eventName: string,
    onReceiveEvent: (ev: any | null) => void,
    enableTimeout: boolean = true,
) => {
    const [subChannel, setSubChannel] = React.useState<PusherChannel>();
    const [watchedSubID, setWatchedSubID] = React.useState(subID);

    // We cannot assume we'll only get one of this event,
    // so we track to see if we've already notified the user
    let notified = false;

    React.useEffect(() => {
        let backupTimeout: ReturnType<typeof setTimeout>;
        if (watchedSubID && !subChannel) {
            const channel = makeChannel(`subscription@${watchedSubID}`);
            setSubChannel(channel);

            channel.watch(eventName, async (ev) => {
                if (!notified) {
                    notified = true;
                    onReceiveEvent(ev);
                }
            });

            // Backup timeout to call event callback if pusher fails
            // track the ID of the timeout so we can properly refresh the value of notified
            // captured in this closure otherwise it will use a stale value
            if (enableTimeout) {
                backupTimeout = setTimeout(() => {
                    if (!notified) {
                        onReceiveEvent(null);
                    }
                }, 15000);
            }
        }
        return () => {
            if (backupTimeout) clearTimeout(backupTimeout);
            subChannel && subChannel.unsubscribe();
        };
    }, [watchedSubID, notified]);

    return [setWatchedSubID, watchedSubID, notified] as const;
};

// This hook automatically retrieves the upcoming invoice for a subscription if the subscription is available
// or if the invoice id changes due to quantity or plan updates, or cancellation
const useUpcomingInvoice = (manageBilling: boolean, subscription: Subscription) => {
    if (!manageBilling) {
        return [undefined, false] as const;
    }
    const dispatch = useDispatch();
    const getUpcomingInvoice = ({ subscription_external_id }) =>
        dispatch(inv.api.upcoming({ subscription_external_id }));
    const [upcomingInvoice, setUpcomingInvoice] = React.useState<inv.Invoice>();
    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        (async () => {
            if (subscription?.has_upcoming_invoice) {
                setLoading(true);
                try {
                    const invoice = await getUpcomingInvoice({ subscription_external_id: subscription.external_id });
                    setUpcomingInvoice(invoice);
                } catch (e) {
                    console.error('Failed to get upcoming invoice', e);
                } finally {
                    setLoading(false);
                }
            }
        })();
        return () => {};
    }, [subscription?.external_id, subscription?.latest_invoice?.id, subscription?.scheduled_changes]);

    return [upcomingInvoice, loading] as const;
};

// This hook grabs the customer associated with the subscription
// Also provides a way to manually set the customer
const useSubscriptionCustomer = (subscription) => {
    const [customer, setCustomer] = React.useState<StripeCustomer | null>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const dispatch = useDispatch();
    const getCustomer = ({ hs_subscription_external_id }) =>
        dispatch(CustomerAPI.getStripeCustomer({ hs_subscription_external_id }));

    React.useEffect(() => {
        (async () => {
            if (subscription?.external_id) {
                setIsLoading(true);
                try {
                    const customer = await getCustomer({ hs_subscription_external_id: subscription.external_id });
                    setCustomer(customer);
                } catch (e) {
                    console.error('Failed to get customer', e);
                } finally {
                    setIsLoading(false);
                }
            }
        })();
    }, [subscription?.external_id, subscription?.paid_seats, subscription?.plan_name]);

    return { customer, isLoading, setCustomer };
};

export {
    useInvoicePreview,
    useWatchSubscription,
    useUpcomingInvoice,
    useSubscriptionCustomer,
    useDebounce,
    useAddressValidation,
};
