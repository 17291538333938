import * as React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as THREE from 'three';

import * as modelUser from 'reports/models/user';
import * as auth from 'reports/modules/auth';
import { IframeDesignerView } from './IframeDesignerView';

export interface IRenderCamera {
    center: THREE.Vector3;
    azimuth: number;
    elevation: number;
    zoom: number;
}

export interface IDesignerCamera {
    cameraCenter: THREE.Vector3;
    cameraTheta: number;
    cameraPhi: number;
    viewportScale: number;
}

export interface IRenderSettings {
    modules: boolean;
    inverters: boolean;
    combiners: boolean;
    wiring: boolean;
    interconnect: boolean;
    field_segments: boolean;
    keepouts: boolean;
    overlays: boolean;
    premades: boolean;
}

export interface IFullRenderSettings {
    settings: Partial<IRenderSettings>;
    cameraSettings: IRenderCamera;
    // The number-to-string mappings are here to support the forthcoming
    // heat map use case
    moduleColor?: string | { [k: number]: string };
}

export interface IInteractiveDesign3dViewProps {
    user: modelUser.User;
    designId: number | string;
    renderSettings: IFullRenderSettings;
    onChange?: (settings: IFullRenderSettings) => any;
}
export class InteractiveDesign3dViewWrapper extends React.Component<IInteractiveDesign3dViewProps> {
    implRef: IframeDesignerView;

    async getSnapshot() {
        return await this.implRef.getSnapshot();
    }

    render() {
        return (
            <IframeDesignerView
                ref={(ref) => {
                    this.implRef = ref!;
                }}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    user: auth.selectors.getUser(state),
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(InteractiveDesign3dViewWrapper);
