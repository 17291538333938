import { get } from 'lodash';
import { request } from 'reports/modules/request';

import * as des from 'reports/models/design';
import * as fs from 'reports/models/field_segment';

enum QuickQuoteType {
    ppa = 'PPA',
    price = 'Price',
}

export enum MountingType {
    rooftop = 'Rooftop',
    parkingStructure = 'Parking Structure',
    ground = 'Ground Mount',
    mixed = 'Mixed',
}

enum SRECContractType {
    contracted = 'Contracted',
    uncontracted = 'Uncontracted',
}

enum IncentiveStatus {
    inactive = 0,
    active = 1,
}

interface QuickQuoteParams {
    solveFor: QuickQuoteType;
    projectName: string;
    state: string; // short name of US state
    projectTotalSize: number; // 100 - 99999
    annualOutput: number; // 500*projectTotalSize - 5000*projectTotalSize
    buildCost: number; // 0.25-10 $/Wdc
    ppaTerm: number; // 0 - 35
    ppaEscalator: number; // 0 - 3
    mountingType: MountingType;
    localIncentivePBI: 0 | 1; // 0 inactive, 1 active
    pbi: number;
    pbiYears: number;
    localIncentiveRebate: 0 | 1 | 2; // 0 inactive, 1 active $/kw, 2 active $/year
    rebate: number;
    rebateYearCost?: { [key: string]: number };
    localIncentiveSREC: 0 | 1; // 0 inactive, 1 active
    srecContractType: SRECContractType;
    srecYearCost?: { [key: string]: number };
}

interface QuickQuote {
    ppa?: number;
    price?: number;
}

async function getQuickQuoteURL() {
    const response = await request.get('/api/external_credentials/scf/sign/quickquote').send();
    return response.text;
}

async function getQuickQuote(body: QuickQuoteParams): Promise<QuickQuote> {
    const requestUrl = await getQuickQuoteURL();
    const response = await request.post(requestUrl).send(JSON.stringify(body)).set('Content-Type', `application/json`);

    if (response.status !== 200) {
        throw new Error(`SCF Quick Quote failed with status ${response.status}. ${response.body}`);
    }

    if (response.body.code !== '200') {
        throw new Error(
            `SCF Quick Quote failed with status ${response.status}, ${response.body.code}.` +
                `Error: ${response.body.message} ${response.body.description}`,
        );
    }

    return {
        ppa: get(response.body, 'result.ppa'),
        price: get(response.body, 'result.price'),
    };
}

function getMountType(design: des.Design) {
    if (design.field_segments.length === 0) {
        return MountingType.rooftop;
    }

    const RACK_TO_MOUNT_TYPE = {
        [fs.RackType.flush]: (_) => MountingType.rooftop,
        [fs.RackType.rack]: (fs) => (fs.bank_depth > 1 ? MountingType.ground : MountingType.rooftop),
        [fs.RackType.dual]: (_) => MountingType.rooftop,
        [fs.RackType.carport]: (_) => MountingType.parkingStructure,
        [fs.RackType.single_axis]: (_) => MountingType.ground,
    };

    const counts = {};
    design.field_segments.forEach((fs) => {
        const mountType = RACK_TO_MOUNT_TYPE[fs.rack_type](fs);
        counts[mountType] = (counts[mountType] || 0) + 1;
    });

    for (const mountType of Object.keys(counts)) {
        if (counts[mountType] === design.field_segments.length) {
            return mountType;
        }
    }

    return MountingType.mixed;
}

const EmptyYearlyRebateParam = Object.assign(
    {},
    ...[...Array(35).keys()].map((year) => ({ [`rcYear${year + 1}`]: 0 })),
);

export default {
    getQuickQuote,
    getMountType,
    IncentiveStatus,
    MountingType,
    SRECContractType,
    QuickQuoteType,

    EmptyYearlyRebateParam,
};
