import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import { find } from 'lodash';

import * as inc from 'reports/models/incentive';

import FormField from 'reports/components/forms/FormField';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';
import { Section2 } from 'reports/components/core/containers';
import { IncentiveTable } from 'reports/modules/financials/components/ProjectIncentiveEditor';

interface IOwnProps {
    path: string;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IStateProps & IDispatchProps;

/**
 * A table showing a list of incentives with buttons to add, remove, and reorder.
 * Meant to exist within a Form that provides a FormContext.
 */
const FinancialProfileIncentiveEditor: React.FC<IProps> = (props) => {
    const { path, allIncentives, getIncentives } = props;

    return (
        <>
            <FormField path={path}>
                {({ value = [], onChange }: { value?: number[]; onChange: (val?: number[]) => void }) => {
                    const selectedIncentives = value
                        .map((x) => find(allIncentives, { incentive_id: x }))
                        .filter((x) => x) as inc.Incentive[];
                    return (
                        <Section2
                            title="Incentives"
                            contextEl={
                                <BasicSelect<inc.Incentive>
                                    dataSource={{
                                        async: true,
                                        query: async (q) => {
                                            return (await getIncentives({ q })).filter(
                                                (x) => !value.includes(x.incentive_id),
                                            );
                                        },
                                    }}
                                    itemRenderer={(incentive: inc.Incentive) => ({
                                        key: incentive.incentive_id,
                                        text: incentive.name,
                                    })}
                                    value={null}
                                    onChange={(incentive) => onChange([...value, incentive.incentive_id])}
                                    noneSelected="Add Incentive"
                                    rightAligned
                                />
                            }
                        >
                            <IncentiveTable
                                incentives={selectedIncentives}
                                actionButtonProps={{
                                    onRemove: (inc) => onChange(value.filter((x) => x !== inc.incentive_id)),
                                    onReorder: (_, x, y) => {
                                        const newValue = [...value];
                                        newValue[x] = value[y];
                                        newValue[y] = value[x];
                                        onChange(newValue);
                                    },
                                    disabled: false,
                                }}
                            />
                        </Section2>
                    );
                }}
            </FormField>
        </>
    );
};

const mapStateToProps = (state) => ({
    allIncentives: inc.selectors.all(state),
});

const mapDispatchToProps = bindActions(() => ({
    getIncentives: inc.api.index,
}));

export default connect(mapStateToProps, mapDispatchToProps)(FinancialProfileIncentiveEditor);
