import * as React from 'react';

import { Alignment, Button, Classes, Colors, Switch } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { EditableTitle } from 'reports/components/helpers/common';

import { ErrorInfo } from 'reports/modules/financials/components';
import { Node } from 'reports/modules/financials/model/pipeline';

import FinancialStep from './FinancialStep';
import StepParameter from './StepParameter';
import StepResult from './StepResult';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const DeleteButton = styled(Button)`
    margin-left: 8px;

    &:hover {
        cursor: pointer;
        &.${Classes.BUTTON} {
            background: rgba(219, 55, 55, 0.15);
        }
        .${Classes.ICON} {
            color: ${Colors.RED2};
        }
    }
`;

const OptionalSwitch: React.SFC<{ checked: boolean; onChange: () => void }> = ({ checked, onChange }) => (
    <Switch
        alignIndicator={Alignment.RIGHT}
        labelElement={<small>Optional</small>}
        style={{ marginBottom: '0px' }}
        checked={checked}
        onChange={onChange}
    />
);

interface ITemplateStepProps {
    node: Node;
    onUpdate: (param: string, value: any) => void;
    onDelete: () => void;
    error?: ErrorInfo;
}

const TemplateStep: React.SFC<ITemplateStepProps> = ({ node, onDelete, onUpdate, error }) => {
    const parametersToShow = node.getPipelineParameters().filter((i) => !i.hidden);
    const resultsToShow = node.module.outputValues ? node.module.outputValues.filter((i) => !i.hidden) : [];

    const toggleOptional = () => onUpdate('toggleable', !node.toggleable);

    return (
        <FinancialStep.Container>
            <FinancialStep.Header
                title={
                    <EditableTitle
                        value={node.user_label ? node.user_label : node.module.description}
                        updateFn={(val) => onUpdate('user_label', val.trim() || null)}
                    />
                }
                controls={
                    !node.module.required && (
                        <FinancialStep.Controls>
                            <OptionalSwitch checked={!!node.toggleable} onChange={toggleOptional} />
                            <DeleteButton minimal icon={IconNames.TRASH} onClick={onDelete} />
                        </FinancialStep.Controls>
                    )
                }
            />
            {error ? (
                <FinancialStep.Content>
                    <FinancialStep.Error name={error.name} message={error.message} />
                </FinancialStep.Content>
            ) : (
                parametersToShow.length > 0 && (
                    <FinancialStep.Content>
                        {parametersToShow.map((parameter, idx) => (
                            <StepParameter key={idx} parameter={parameter} onUpdate={onUpdate} />
                        ))}
                        {resultsToShow.map((result, idx) => (
                            <StepResult key={idx} result={result} showInfo={true} />
                        ))}
                    </FinancialStep.Content>
                )
            )}
        </FinancialStep.Container>
    );
};

export default TemplateStep;
