import request from 'reports/modules/request';

import * as React from 'react';
import { connect } from 'react-redux';
import { createRouteNodeSelector } from 'redux-router5';

import { Button, Spinner, Classes, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { downloadBlob, downloadURL } from 'helioscope/app/utilities/io';
import * as pusher from 'helioscope/app/utilities/pusher';
import Toaster from 'reports/modules/Toaster';

import { PrintContext } from './PrintProvider';

interface IOwnProps {
    text?: string;
    async?: boolean;
    params?: any;
    onClick?: () => void;
}

type IProps = IOwnProps & ReturnType<typeof mapStateToProps>;

interface IState {
    loading: boolean;
}

const progressToasterConfig = (message: string) => ({
    message,
    icon: (
        <span style={{ margin: 8 }}>
            <Spinner className={Classes.SMALL} />
        </span>
    ),
    timeout: 0,
});

class PrintButton extends React.PureComponent<IProps, IState> {
    state: IState = {
        loading: false,
    };

    printCurrentView = async ({ routerUrlPrefix }: { routerUrlPrefix: string }) => {
        const { async = true } = this.props;
        const key = Toaster.show(progressToasterConfig('Initializing download...'));

        try {
            this.setState({ loading: true });
            const routePath = this.props.routeNode.route!.path;

            const req = request
                .post('/api/render/')
                .send({
                    url: `${routerUrlPrefix}${routePath}`,
                    params: this.props.params,
                })
                .withCredentials();

            if (!async) {
                const response = await req.responseType('blob');
                Toaster.dismiss(key);
                return downloadBlob(response.body, response.header.filename);
            }

            const response = await req.query({ async });

            const { channel } = response.body;
            const { download_url } = await pusher.promiseFromChannel(channel, (evt) => {
                Toaster.show(progressToasterConfig(evt.message), key);
            });

            downloadURL(download_url);
            Toaster.show(
                {
                    icon: 'download',
                    intent: Intent.SUCCESS,
                    action: { href: download_url, text: 'download' },
                    message: <a href={download_url}>Download complete</a>,
                },
                key,
            );
        } catch (err) {
            console.error('Report Download Failed', err);
            Toaster.show({ intent: Intent.DANGER, message: 'Download failed' }, key);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        return (
            <PrintContext.Consumer>
                {({ canPrint, routerUrlPrefix }) => (
                    <Button
                        icon={IconNames.PRINT}
                        disabled={!canPrint}
                        loading={this.state.loading}
                        onClick={() => {
                            this.printCurrentView({ routerUrlPrefix });
                            if (this.props.onClick != null) {
                                this.props.onClick();
                            }
                        }}
                        text={this.props.text}
                        style={{ marginLeft: '10px', order: 999 }}
                    />
                )}
            </PrintContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => {
    const routeSelector = createRouteNodeSelector('');
    return {
        routeNode: routeSelector(state),
    };
};

const PrintButtonContainer = connect(mapStateToProps)(PrintButton);

export { PrintButtonContainer as PrintButton };

export default PrintButtonContainer;
