import * as React from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from 'reports/localization';

const TRANSLATIONS = {
    en: './en.json',
    es: './es.json',
    de: './de.json',
    pt: './pt.json',
    tr: './tr.json',
    'es-419': './es-419.json',
    nl: './nl.json',
    fr: './fr.json',
};

const messageCache = new Map<string, any>();

const getTranslatedMessages = (locale: string) => {
    let messages = messageCache.get(locale);

    if (!messages) {
        messages = require(`${TRANSLATIONS[locale] || TRANSLATIONS[locale.split(/[\._\-@]/)[0]] || './en.json'}`);

        messageCache.set(locale, messages!);
    }

    return messages;
};

const _defaultRichTextElements = {
    // https://github.com/formatjs/formatjs/issues/1752
    sup: (chunks) => <sup>{chunks}</sup>,
};

interface ILocaleProvider {
    locale: string;
    forceEnglish?: boolean;
    children?: React.ReactNode;
}

const LocaleProvider: React.SFC<ILocaleProvider> = ({ locale, forceEnglish, children }) => {
    let validLocale = locale;
    try {
        Intl.NumberFormat.supportedLocalesOf(locale);
        Intl.DateTimeFormat.supportedLocalesOf(locale);
    } catch (err) {
        console.error(`Warning: locale ${locale} is invalid`);
        validLocale = 'en-US';
    }

    return (
        <IntlProvider
            messages={forceEnglish ? getTranslatedMessages(DEFAULT_LOCALE.code) : getTranslatedMessages(locale)}
            locale={validLocale}
            defaultLocale="en-US"
            defaultRichTextElements={_defaultRichTextElements}
        >
            {children}
        </IntlProvider>
    );
};

export default LocaleProvider;
