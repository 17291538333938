/* tslint:disable:variable-name */
import * as React from 'react';
import { CommonColumns } from 'reports/components/library/helpers';
import { FavoriteStar } from 'reports/components/core/controls';
import { ColWidth, SortType } from 'reports/components/core/tables/DataTable';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';

import { User } from 'reports/models/user';
import * as utility from 'reports/models/utility_rate';

import { DeepPartial } from 'reports/utils/types';
import { addPromiseToastsToggle } from 'reports/components/toasts';

type Props = {
    openEditor: (rateId: number) => void;
    setBookmarked: (rate: utility.UtilityRate, bookmarked: boolean) => Promise<DeepPartial<utility.UtilityRate>>;
    user: User;
};

const UtilityRatesList = ({ openEditor, setBookmarked, user }: Props) => (
    <ResourceTable<utility.UtilityRate>
        columns={[
            {
                colWidth: ColWidth.SMALL,
                headerText: 'Favorite',
                renderCell: (rate) => (
                    <FavoriteStar
                        empty={!rate.bookmarked()}
                        onClick={(e) => {
                            e.stopPropagation();
                            addPromiseToastsToggle(
                                'favorite',
                                setBookmarked(rate, !rate.bookmarked()),
                                'utility rate',
                                rate.description,
                                rate.bookmarked(),
                            );
                        }}
                    />
                ),
            },
            {
                sort: { name: 'description', type: SortType.ALPHA },
                colWidth: ColWidth.LARGE,
                headerText: 'Utility Rate Name',
                renderCell: (rate) => <div style={{ width: '100%', textAlign: 'left' }}>{rate.description}</div>,
            },
            CommonColumns.lastModified(),
            CommonColumns.created(),
            CommonColumns.creator(),
            CommonColumns.team(),
            CommonColumns.publicCol(user),
        ]}
        onRowClick={(rate) => openEditor(rate.utility_rate_id)}
    />
);

export { UtilityRatesList };
