import * as React from 'react';

import { useDispatch } from 'react-redux';

import { actions as routerActions } from 'redux-router5';

import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { RouteComponent } from 'reports/routing';

import { RedirectHelperContainer } from 'reports/components/helpers/common';
import Toaster from 'reports/modules/Toaster';

import { FinModelPreview as FinancialModelPreview } from 'reports/modules/financials/components/financial_template/views/FinModelPreview';

import {
    FinancialTemplateEditor,
    FinancialTemplateLibrary,
} from 'reports/modules/financials/components/financial_template';
import { useRoute } from 'react-router5';

const NonexistentModelRedirectToMain = () => (
    <RedirectHelperContainer
        name="app.financial-templates"
        params={{}}
        callback={() => {
            Toaster.show({
                icon: IconNames.WARNING_SIGN,
                intent: Intent.WARNING,
                message: <div>Financial model does not exist</div>,
                timeout: 2500,
            });
        }}
    />
);

const Library = () => {
    const dispatch = useDispatch();
    const { route } = useRoute();
    const editRoute = 'app.financial-templates.financial-template.edit';
    const previewRoute = 'app.financial-templates.financial-template.preview';
    const libraryRoute = 'app.financial-templates';
    const navigateToFinTemplates = () => dispatch(routerActions.navigateTo(libraryRoute));
    const navigateToFinTemplate = (finTemplateId: number) =>
        dispatch(
            routerActions.navigateTo(editRoute, {
                finTemplateId,
            }),
        );
    const navigateToFinTempPreview = (finTemplateId: number) =>
        dispatch(
            routerActions.navigateTo(previewRoute, {
                finTemplateId,
            }),
        );

    return (
        <div className="sub-container">
            <div className="sub-sidebar" style={{ width: '100%' }}>
                <RouteComponent name={libraryRoute} exact={false} nameNot={editRoute}>
                    <FinancialTemplateLibrary
                        finTemplateId={route.params.finTemplateId}
                        navigateToFinTemplate={navigateToFinTemplate}
                        navigateToFinTempPreview={navigateToFinTempPreview}
                        preview={
                            <RouteComponent name={previewRoute}>
                                {({ template: model }) =>
                                    model ? (
                                        <FinancialModelPreview
                                            model={model}
                                            navigateToFinTemplate={navigateToFinTemplate}
                                            navigateToFinTemplates={navigateToFinTemplates}
                                        />
                                    ) : (
                                        <NonexistentModelRedirectToMain />
                                    )
                                }
                            </RouteComponent>
                        }
                    />
                </RouteComponent>
                <RouteComponent name={editRoute}>
                    {({ template }) =>
                        template ? (
                            <FinancialTemplateEditor
                                navigateToFinTemplatePreview={navigateToFinTempPreview}
                                template={template}
                            />
                        ) : (
                            <NonexistentModelRedirectToMain />
                        )
                    }
                </RouteComponent>
            </div>
        </div>
    );
};

export { Library };
