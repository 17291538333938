import * as React from 'react';

import * as scen from 'reports/models/scenario';
import * as usr from 'reports/models/user';

import Section2 from 'reports/components/core/containers/Section2';

import CharacterizationTable from './CharacterizationTable';
import {
    AdvancedSection,
    CellTempTable,
    MismatchSection,
    ScenarioParameter,
    SoilingTable,
} from 'reports/modules/condition_sets/components/CondSetDisplayComponents';

const MeteoSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2
        title="Meteo and Solar Access"
        subtitle="Used to calculate the hourly performance of the array for a given year"
    >
        <ScenarioParameter
            label="Weather Dataset"
            helperText="Used to calculate the hourly performance of the array for a given year"
        >
            <a href={scenario.weather_dataset.get_url}>{`${scenario.weather_dataset}`}</a>
        </ScenarioParameter>

        <ScenarioParameter label="Horizon Profile">
            {scenario.horizon ? scenario.horizon.description : 'No Horizon File Selected'}
        </ScenarioParameter>
    </Section2>
);

const ScenarioCellTempTable: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <CellTempTable cellTempModel={scenario.cell_temp_model} cellTempParams={scenario.cell_temp_parameters} />
);

const LossSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2 title="Loss Calculations" subtitle="Sources of energy loss">
        <ScenarioParameter label="Soiling" helperText="Derates to irradiance due to module soiling or other factors">
            <SoilingTable soiling={scenario.soiling} />
        </ScenarioParameter>

        <ScenarioParameter
            label="Cell Temperature Model"
            helperText="Used to determine cell temperature based on environmental factors and module irradiance. The Sandia Temperature Model is quantitatively derived from module performance in the field,
            the diffusion model is equivalent to that used in PVSyst."
        >
            {scenario.cell_temp_model === 'sandia' ? 'Sandia' : 'Diffusion'} Model
        </ScenarioParameter>

        <ScenarioParameter label="Coefficients">
            <ScenarioCellTempTable scenario={scenario} />
        </ScenarioParameter>

        <ScenarioParameter
            label="AC System Derate"
            helperText={
                <span>
                    Constant derate factor to compensate for AC system losses (conductors, transformers, etc) that is
                    applied to the inverter's AC Output within the loss tree.{' '}
                    <strong>This will only be used if you do not define an AC subsystem on your design.</strong>
                </span>
            }
        >
            {scenario.ac_conductor_derate}%
        </ScenarioParameter>
    </Section2>
);

const ScenarioMismatchSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <MismatchSection
        irradiation_variance={scenario.irradiation_variance}
        temperature_variance={scenario.temperature_variance}
        min_module_binning={scenario.min_module_binning}
        max_module_binning={scenario.max_module_binning}
    />
);

const ComponentSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2
        title="Component Characterizations"
        subtitle="Define precisely which module characterizations (e.g. PAN/OND files) will be used during the simulation"
    >
        <ScenarioParameter label="Components">
            <CharacterizationTable scenario={scenario} />
        </ScenarioParameter>
    </Section2>
);

const ScenarioAdvancedSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <AdvancedSection
        transposition_model={scenario.transposition_model}
        use_project_location={scenario.use_project_location}
        use_spectral_adjustment={scenario.use_spectral_adjustment}
    />
);

const ScenarioTrackerSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2 title="Trackers" subtitle="Options for tracker systems">
        <ScenarioParameter label="Maximum Angle" helperText="Maximum tilt angle for tracker systems">
            {scenario.tracker_max_angle}&deg;
        </ScenarioParameter>
        <ScenarioParameter label="Backtracking" helperText="Enables backtracking for tracker systems">
            {scenario.tracker_backtrack ? 'On' : 'Off'}
        </ScenarioParameter>
    </Section2>
);

export const ParameterDisplay: React.FC<{
    scenario: scen.Scenario;
    user: usr.User;
}> = ({ scenario, user }) => (
    <>
        <MeteoSection scenario={scenario} />
        <LossSection scenario={scenario} />
        <ScenarioMismatchSection scenario={scenario} />
        <ComponentSection scenario={scenario} />
        <ScenarioAdvancedSection scenario={scenario} />
        {user.hasSingleAxisTrackersAccess() && <ScenarioTrackerSection scenario={scenario} />}
    </>
);

export default ParameterDisplay;
