import * as React from 'react';
import { useSearchParams } from 'react-router-dom';

import { SubscriptionView } from 'reports/modules/settings/admin/views/SubscriptionView';

import { useSettingsContext } from 'frontend/settings/use-settings-data';

const Subscription = () => {
    const [searchParams] = useSearchParams();
    const { subscription, team, user } = useSettingsContext();

    return (
        <SubscriptionView
            createContract={!!searchParams.get('createContract')}
            subscription={subscription}
            team={team}
            user={user}
        />
    );
};

export { Subscription };
