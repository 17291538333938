import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isNull } from 'lodash';

import * as auth from 'reports/modules/auth';

import * as DS from '@aurorasolar/ds';

const TrialLimitsBanner = () => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);

    const timeLeft = user.isExpired() ? null : user.current_period_end?.fromNow().slice(3);

    const showBanner = user.status === 'trial' || user.status === 'expired_trial';
    const isActive = !isNull(timeLeft);
    const bannerIntent = isActive ? 'info' : 'warning';

    const navigate = useNavigate();

    return (
        <>
            {showBanner && (
                <DS.Banner
                    actions={[
                        {
                            action: () => navigate({ pathname: '/settings/team/billing', search: `?dialog=initial` }),
                            children: 'Sign up',
                        },
                    ]}
                    bg={isActive ? DS.xCSSToken('blue-100') : DS.xCSSToken('yellow-100')}
                    color={DS.xCSSToken('black')}
                    icon={DS.IconInfoCircle}
                    iconProps={{ mt: '2px' }}
                    variant={bannerIntent}
                    w="100%"
                >
                    {isActive ? (
                        <>
                            <DS.Text text="h3">Welcome to your HelioScope Trial!</DS.Text>
                            <DS.Text text="body14">
                                You have <strong>{timeLeft}</strong> to try all HelioScope paid features. Upgrade
                                anytime for as low as $159/month.
                            </DS.Text>
                        </>
                    ) : (
                        <>
                            <DS.Text text="h3">Your HelioScope Trial has Expired!</DS.Text>
                            <DS.Text text="body14">
                                Your account has expired, to continue using HelioScope, register now for a full account.
                                Please{' '}
                                <a href="mailto:sales@helioscope.com" target="_blank">
                                    contact us
                                </a>{' '}
                                if you have any questions.
                            </DS.Text>
                        </>
                    )}
                </DS.Banner>
            )}
        </>
    );
};

export { TrialLimitsBanner };
