import { sum } from 'lodash';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { injectIntl, IntlShape } from 'react-intl';

import Translations from 'reports/localization/strings';
import * as fmt from 'reports/utils/formatters';
import * as proj from 'reports/models/project';

import { TOKEN_FUNCTIONS } from 'reports/modules/financials/model/tokens';
import * as finState from 'reports/modules/financials/state';
import { ILayoutChartProps, LayoutChart } from 'reports/modules/report/components/charts';
import { Theme, DEFAULT_THEME } from 'reports/modules/themes';

import { IAppState } from 'reports/types';

interface IOwnProps extends ILayoutChartProps {
    project: proj.Project;
    financialTokens: finState.MaybeFinOutput;
    year?: number;
    theme?: Theme;
}

interface IOwnPropsIntl extends IOwnProps {
    intl: IntlShape;
}

interface ISeries {
    name: string;
    data: number[];
    type: string;
    color: any;
    visible?: boolean;
}

function makeConfig(series: ISeries[], locale: string): Highcharts.Options {
    const formatCurrency = (val) => fmt.currency(val, { locale, precision: 2 });
    return {
        series,

        chart: {
            type: 'pie',
        },
        title: {
            text: '',
        },
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter(this: any) {
                const percent = fmt.percentage(this.percentage / 100, {
                    locale,
                });
                return `${this.key}: <b>${formatCurrency(this.y)}  (${percent})</b>`;
            },
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            pie: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    formatter(this: any) {
                        return `<b>${this.point.name}</b>: ${formatCurrency(this.y)}`;
                    },
                    // style: {
                    //     color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                    // }
                },
            },
        },
    };
}

const mapStateToProps = () => {
    const chartSelector = createSelector(
        (_state, props: IOwnPropsIntl) => props.theme || DEFAULT_THEME,
        (_state, props: IOwnPropsIntl) => props.financialTokens,
        (_state, props: IOwnPropsIntl) => props.intl,
        (theme, finTokens, intl) => {
            const beforeSum = finState.hasOutput(finTokens) ? sum(TOKEN_FUNCTIONS.bill_monthly_sum(finTokens)) : 0;
            const afterSum = finState.hasOutput(finTokens)
                ? sum(TOKEN_FUNCTIONS.bill_monthly_sum_with_solar(finTokens))
                : 0;

            const dataSeries: any = [
                {
                    name: intl.formatMessage(Translations.financial.utility_bill),
                    data: [
                        {
                            name: intl.formatMessage(Translations.financial.utility),
                            y: afterSum,
                            color: theme.secondary_color,
                        },
                        {
                            name: intl.formatMessage(Translations.financial.solar),
                            y: beforeSum - afterSum,
                            color: theme.primary_color,
                        },
                    ],
                },
            ];

            return makeConfig(dataSeries, intl.locale);
        },
    );

    return (state: IAppState, ownProps: IOwnPropsIntl) => {
        return {
            config: chartSelector(state, ownProps),
        };
    };
};

export const BillOffsetPieChart: React.ComponentClass<IOwnProps> = injectIntl(
    connect(mapStateToProps)(LayoutChart as any) as any,
) as any;

export default BillOffsetPieChart;
