import * as React from 'react';
import { startCase } from 'lodash';

import { IButtonProps, Intent, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Button from './Button';
import { promptModalBoolean } from 'reports/components/dialog';
import { addPromiseToastsToggle } from 'reports/components/toasts';

interface IPublishButtonProps extends Omit<IButtonProps, 'icon'> {
    isPublic: boolean;
    resourceName: string;
    resourceType: string;
    privatePrompt: string | React.ReactNode;
    publicPrompt: string | React.ReactNode;
    togglePublic: () => Promise<any>;
}

class PublishButton extends React.PureComponent<IPublishButtonProps> {
    render() {
        const { isPublic, resourceType, resourceName, publicPrompt, privatePrompt, disabled, togglePublic, ...props } =
            this.props;

        return (
            <Tooltip
                content={<span style={{ fontSize: 12 }}>{isPublic ? 'Make Private' : 'Make Public'}</span>}
                disabled={disabled}
                position={Position.BOTTOM}
            >
                <Button
                    icon={isPublic ? IconNames.UNLOCK : IconNames.LOCK}
                    intent={isPublic ? Intent.PRIMARY : undefined}
                    minimal={this.props.disabled}
                    onClick={async () => {
                        const shouldPublish = await promptModalBoolean({
                            title: `Make ${startCase(resourceType)} ${isPublic ? 'Private' : 'Public'}`,
                            prompt: isPublic ? privatePrompt : publicPrompt,
                            cancellable: true,
                            yesLabel: isPublic ? 'Make Private' : 'Make Public',
                            dialogProps: { icon: IconNames.WARNING_SIGN },
                        });

                        if (shouldPublish) {
                            addPromiseToastsToggle('public', togglePublic(), resourceType, resourceName, isPublic);
                        }
                    }}
                    disabled={disabled}
                    {...props}
                />
            </Tooltip>
        );
    }
}

export default PublishButton;
