import * as React from 'react';

type DropzoneAction = () => void;

const DROPZONE_INSTANCES = new Map<React.Component, { enable: DropzoneAction; disable: DropzoneAction }>();
export function disableDropzone() {
    for (const dz of DROPZONE_INSTANCES.values()) {
        dz.disable();
    }
}

export function enableDropzone() {
    for (const dz of DROPZONE_INSTANCES.values()) {
        dz.enable();
    }
}

export function registerDropzoneInstance(component: React.Component, enable: DropzoneAction, disable: DropzoneAction) {
    DROPZONE_INSTANCES.set(component, { enable, disable });
}

export function deregisterDropzoneInstance(component: React.Component) {
    DROPZONE_INSTANCES.delete(component);
}
