import { Intent, Tag } from '@blueprintjs/core';
import { styled } from 'reports/styles/styled-components';

const Discount = styled(Tag).attrs({
    round: true,
    minimal: true,
    intent: Intent.SUCCESS,
})`
    margin-left: 5px;
`;

export { Discount };
