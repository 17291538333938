import React from 'react';
import classNames from 'classnames';
import type { Address } from '@stripe/stripe-js';
import { AddressElement, useElements } from '@stripe/react-stripe-js';
import * as cfg from 'reports/config';
import * as usr from 'reports/models/user';
import * as sub from 'reports/models/subscription';
import { api as CustomerAPI } from 'reports/models/stripe/stripe_customer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Classes, Dialog, Intent, Spinner } from '@blueprintjs/core';
import { Form } from 'reports/components/forms';
import { useSubscriptionCustomer, useAddressValidation } from '../Hooks';
import { IAppState } from 'reports/store';
import { handleFormException } from '..';
import { StripeContainer } from './StripeContainer';
import { BillingDetailsFields } from './BillingDetailsFields';

interface Props {
    closeDialog: () => void;
    isOpen: boolean;
    subscription: sub.Subscription;
    user: usr.User;
}

interface BillingInfoFormData {
    email: string;
    billingDetails: {
        address: Address;
    };
    purchaseOrder: string;
}

const _BillingInfoDialog = ({ closeDialog, isOpen, subscription, user }: Props) => {
    const dispatch = useDispatch();
    const elements = useElements();
    const { customer: stripeCustomer, isLoading: isLoadingCustomer } = useSubscriptionCustomer(
        user.team.latest_subscription,
    );
    const updateCustomer = (
        subscriptionId: string,
        email: string,
        name: string,
        purchaseOrder: string,
        address: Address,
    ) =>
        dispatch(
            CustomerAPI.updateStripeCustomer(
                { email, name, address, purchase_order: purchaseOrder },
                { hs_subscription_external_id: subscriptionId },
            ),
        );

    const validateBillingInfo = async () => {
        if (!elements) {
            return null;
        }

        const addressElement = elements.getElement(AddressElement);
        if (!addressElement) {
            return null;
        }

        const { complete, value } = await addressElement.getValue();
        if (!complete) {
            return null;
        }
        return value;
    };

    const onSubmit = async (formData: BillingInfoFormData) => {
        const { email, purchaseOrder } = formData;
        const billingInfo = await validateBillingInfo();
        if (billingInfo) {
            const { name, address } = billingInfo;
            await updateCustomer(
                subscription.external_id,
                email,
                name,
                purchaseOrder ? purchaseOrder.trim() : '',
                address,
            );
            closeDialog();
        }

        return subscription;
    };

    return (
        <Form<BillingInfoFormData, sub.Subscription>
            baseValue={{
                email: stripeCustomer?.email || user.email,
                purchaseOrder: stripeCustomer?.purchaseOrder?.value,
            }}
            exceptionHandler={handleFormException}
            onSubmit={onSubmit}
        >
            {(formContext) => (
                <BillingInfoForm
                    {...{
                        formContext,
                        user,
                        isOpen,
                        closeDialog,
                        stripeCustomer,
                        isLoadingCustomer,
                    }}
                />
            )}
        </Form>
    );
};

const BillingInfoForm = ({ formContext, user, isOpen, closeDialog, stripeCustomer, isLoadingCustomer }) => {
    const { formData, submitForm, submitting, clearForm } = formContext;
    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));
    const onClose = () => {
        clearForm();
        closeDialog();
    };

    const address: Address | null = formData.billingDetails?.address || null;

    const { addressValidation, isValidatingAddress } = useAddressValidation(user.team?.latest_subscription, address);

    const canSubmit = !submitting && !isValidatingAddress && addressValidation?.tax_included;

    return (
        <Dialog
            isOpen={isOpen}
            canEscapeKeyClose={false}
            canOutsideClickClose={false}
            onClose={closeDialog}
            title="Edit account billing information"
        >
            <div className={Classes.DIALOG_BODY}>
                {isLoadingCustomer ? (
                    <Spinner />
                ) : (
                    <BillingDetailsFields
                        user={user}
                        customer={stripeCustomer}
                        mapsAPIKey={config?.google_maps_api_key}
                    />
                )}
            </div>
            <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                <Button onClick={onClose} disabled={submitting} intent={Intent.NONE} text="Cancel" />
                <Button
                    onClick={() => submitForm(formData)}
                    loading={submitting || isValidatingAddress}
                    disabled={!canSubmit}
                    intent={Intent.PRIMARY}
                    text="Save"
                />
            </div>
        </Dialog>
    );
};

const BillingInfoDialog = (props: Props) => (
    <StripeContainer>
        <_BillingInfoDialog {...props} />
    </StripeContainer>
);

export { BillingInfoDialog };
