import * as React from 'react';

import { Flex } from 'reports/components/core/containers';
import SettingsSideBar from 'reports/modules/settings/SettingsSideBar';
import { RouteComponent } from 'reports/routing';
import Colors from 'reports/styles/colors';

import { BillingSettingsViews } from './billing/views';
import { TeamSettingsViews } from './team/views';
import { UserSettingsViews } from './user/views';
import { AdminSettingsViews } from './admin/views';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const SettingsContainer = styled(Flex.Container)`
    height: 100%;
    background-color: ${Colors.BG_WHITE};
    overflow: auto;
`;

const SettingsTabContainer = styled.div`
    padding: 10px 12px;
    margin-top: 12px;
`;

const ViewContainer = styled.div`
    flex: 1;
    padding: 0px 20px 10px;
    background-color: ${Colors.BG_WHITE};
`;

const SettingsView = () => (
    <SettingsContainer>
        <RouteComponent name="app.settings" nameNot={/app.settings.team.billing.(invoice|upcoming)/} exact={false}>
            <SettingsTabContainer>
                <SettingsSideBar />
            </SettingsTabContainer>
        </RouteComponent>
        <ViewContainer>
            <RouteComponent name="app.settings.user" exact={false}>
                {({ user }) => <UserSettingsViews user={user} />}
            </RouteComponent>
            <RouteComponent name="app.settings.team" exact={false}>
                <TeamSettingsViews />
            </RouteComponent>
            <RouteComponent name="app.settings.team.billing" exact={false}>
                <BillingSettingsViews />
            </RouteComponent>
            <RouteComponent name="app.settings.hs-admin" exact={false}>
                {({ team, user }) => <AdminSettingsViews team={team} user={user} />}
            </RouteComponent>
        </ViewContainer>
    </SettingsContainer>
);

export default SettingsView;
