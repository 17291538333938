import React from 'react';

import { useSelector } from 'react-redux';

import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import { Profile, selectors as profileSelectors } from 'reports/models/profile';

import { ProfileEditor } from 'reports/modules/profile/components/ProfileEditor';

import { IAppState } from 'reports/store';

const ProfileEdit = () => {
    const navigate = useNavigate();

    const profileToSelect = useLoaderData() as Profile;
    const profile = useSelector((state) =>
        profileSelectors.byObject(state as IAppState, {
            profile_id: profileToSelect.profile_id,
        }),
    );
    const navigateToProfilePreview = (profileId: number) =>
        navigate({
            pathname: generatePath('/profiles/:profileId/preview', { profileId: profileId.toString() }),
        });

    if (!profile) {
        throw new Response('Not Found', { status: 404 });
    }

    return <ProfileEditor navigateToProfilePreview={navigateToProfilePreview} profile={profile} />;
};

export { ProfileEdit };
