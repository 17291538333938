import { sample } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';

import { Button, Classes } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { IAppState } from 'reports/types';
import * as config from 'reports/config';

import { GCoord, GLatLngLiteral, default as Geocode } from 'reports/utils/maps/geocode';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface IMapConfig {
    initialCenter: GLatLngLiteral;
    initialZoom: number;
}

interface IMapProps {
    map: any;
    setMap: (map: any) => void;
    disable?: boolean;
    mapConfig?: IMapConfig;
    markerLocation?: GCoord;
}

interface IState {
    mapChanged: boolean;
    mapConfig: IMapConfig;
    ready: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

const CROSSHAIR_URL = require('helioscope/images/cross-hairs.gif');
const DEFAULT_ZOOM = 18;

const SOLAR_LOCATIONS = [
    { lat: 44.014299, lng: 6.008059, zoom: 16 }, // 18MW in France
    { lat: 38.890515, lng: -6.286758, zoom: 15 }, // Trackers in Spain
    { lat: 38.89191, lng: -6.267187, zoom: 17 }, // Spain
    { lat: 39.627507, lng: -2.074502, zoom: 17 }, // Spain
    { lat: 40.866667, lng: -72.85, zoom: 16 }, // Long Island, NY
    { lat: 27.323089, lng: -81.8021, zoom: 16 }, // Single-axis trackers in Florida
    { lat: 35.783842, lng: -114.995534, zoom: 15 }, // Searchlight, Nevada
    { lat: 42.347717, lng: -70.955828, zoom: 19 }, // Deer Island, MA
    { lat: 42.945114, lng: -82.333739, zoom: 15 }, // Sarnia, Canada
    { lat: 45.587677, lng: -94.402589, zoom: 18 }, // St John's MN
    { lat: 41.766739, lng: -72.688889, zoom: 19 }, // Aetna Atrium Building, Hartford, CT
    { lat: 39.961835, lng: -75.080138, zoom: 18 }, // Carpenter & Patterson, NJ
    { lat: 40.836227, lng: -74.066365, zoom: 18 }, // LPS Industries, NJ
    { lat: 45.75037, lng: -122.875318, zoom: 19 }, // Otto Peterson Elementary School, OR
    { lat: 37.36473, lng: -121.922574, zoom: 18 }, // San Jose Airport
    { lat: 40.027676, lng: -105.212248, zoom: 18 }, // Special Transit
    { lat: 45.51426, lng: -122.554207, zoom: 18 }, // Cherrywood Retirement, OR
    { lat: 47.732671, lng: -122.623856, zoom: 18 }, // Poulsbo Middle School, OR
    { lat: 33.981499, lng: -118.106537, zoom: 17 }, // Pico Rivera Towne Center
    { lat: 38.406363, lng: -121.951247, zoom: 17 }, // Mariani Packing Company, CA
    { lat: 38.421688, lng: -122.408837, zoom: 18 }, // Far Niete Winery Floatovoltaics ,CA
    { lat: 39.747202, lng: -104.943381, zoom: 19 }, // Denver Museum of Nature & Science
    { lat: 39.838826, lng: -104.673477, zoom: 16 }, // Denver Airport
    { lat: 29.443828, lng: -98.48018, zoom: 19 }, // Pearl Brewery, TX
    { lat: 40.030011, lng: -105.25744, zoom: 19 }, // Movement Climbing & Fitness, CO
    { lat: 38.151605, lng: -121.411463, zoom: 18 }, // Sutter Home Winery, CA
    { lat: 39.72288, lng: -121.816246, zoom: 18 }, // Sierra Nevada Brewing, CA
    { lat: 37.642302, lng: -122.131016, zoom: 19 }, // Pan Ocean Aquarium, CA
    { lat: 37.846782, lng: -122.293354, zoom: 18 }, // Clif Bar & Company, CA
    { lat: 39.804069, lng: -76.950658, zoom: 16 }, // Snyder's of Hanover, PA
    { lat: 28.41852, lng: -81.425547, zoom: 17 }, // Darden Restaurants HQ
    { lat: 37.717911, lng: -121.88587, zoom: 17 }, // Santa Rita Jail
    { lat: 37.833774, lng: -121.947606, zoom: 19 }, // Athenian Schools, Danville
    { lat: 34.585975, lng: -113.178653, zoom: 18 }, // Copper Mine, Bagdad, AZ
    { lat: 32.366732, lng: -112.831639, zoom: 16 }, // Copper Mine, Ajo, AZ
    { lat: 37.750901, lng: -122.483574, zoom: 17 }, // Sunset Reservoir, San Francisco
    { lat: 37.367889, lng: -122.211847, zoom: 18 }, // Corte Madera School, Portola Valley
    { lat: 40.427549, lng: -74.708459, zoom: 17 }, // Johnson & Johnson, Skillman, NJ
    { lat: 38.403504, lng: -122.818397, zoom: 19 }, // Sebastopol
    { lat: 51.18918, lng: 4.363675, zoom: 18 }, // Belgium rooftop
];

const RecenterButton = styled(Button).attrs({
    className: Classes.INTENT_WARNING,
    small: true,
})`
    position: absolute;
    right: 0;
    margin: 6px;

    &.${Classes.SMALL} {
        min-height: 25px; // override Blueprint
    }
`;

function getLatLngLiteral(point: GCoord) {
    return Geocode.isGLatLng(point) ? { lat: point.lat(), lng: point.lng() } : point;
}

class ProjectMap extends React.PureComponent<IMapProps & IStateProps, IState> {
    googleMaps;
    markers = {};

    constructor(props) {
        super(props);

        const randomLocation = sample(SOLAR_LOCATIONS)!;
        const { zoom: initialZoom, ...initialCenter } = randomLocation;

        this.state = {
            mapConfig: {
                initialCenter,
                initialZoom,
                ...props.mapConfig,
            },
            mapChanged: false,
            ready: false,
        };
    }

    getMapOptions = (gMaps) => {
        return {
            controlSize: 24,
            mapTypeId: gMaps.MapTypeId.HYBRID,

            mapTypeControl: true, // must be set, lib default's to false
            disableDoubleClickZoom: true,
            fullscreenControl: false,
            rotateControl: false,
            streetViewControl: false,
            clickableIcons: false,
        };
    };

    componentDidUpdate(prevProps) {
        const { map, markerLocation } = this.props;

        if (map && prevProps.markerLocation !== markerLocation) {
            map.setZoom(DEFAULT_ZOOM); // reset zoom level on marker change
            this.renderProjectMarker(markerLocation);
            this.renderCrosshair(markerLocation);
        }
    }

    render() {
        const { markerLocation } = this.props;
        const { initialCenter, initialZoom } = this.state.mapConfig;

        const mapStyle: React.CSSProperties = {
            ...(this.props.disable ? { pointerEvents: 'none' } : {}),
            transition: 'opacity 0.3s ease-in-out',
            opacity: this.state.ready ? 1 : 0,
        };

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: this.props.googleMapsKey }}
                    options={this.getMapOptions}
                    defaultCenter={initialCenter}
                    defaultZoom={initialZoom}
                    center={markerLocation ? getLatLngLiteral(markerLocation) : initialCenter}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => this._onMapLoaded(map, maps, initialCenter)}
                    onDrag={this._onDrag}
                    onZoomAnimationEnd={this._onZoom}
                    style={mapStyle as any}
                />
                <RecenterButton
                    text="Recenter"
                    icon={IconNames.MAP_MARKER}
                    onClick={this.recenterOnMarker}
                    disabled={!markerLocation || !this.state.mapChanged}
                />
            </div>
        );
    }

    _onDrag = (map) => {
        this.setState({ mapChanged: true });
        this.renderCrosshair(map.center);
    };

    _onMapLoaded = (map, gMaps, position) => {
        this.googleMaps = gMaps;
        this.props.setMap(map);

        map.setTilt(0);
        this.renderCrosshair(position);
        this.setState({ ready: true });
    };

    _onZoom = () => {
        this.setState({ mapChanged: true });
        this.props.map && this.renderCrosshair(this.props.map.center);
    };

    recenterOnMarker = () => {
        const { map, markerLocation } = this.props;

        if (map && this.state.mapChanged && markerLocation) {
            map.panTo(markerLocation);
            map.setZoom(DEFAULT_ZOOM);

            this.renderCrosshair(markerLocation);
            this.setState({ mapChanged: false });
        }
    };

    renderCrosshair = (position?: GCoord) => this.renderMarker('crosshair', position, CROSSHAIR_URL);

    renderProjectMarker = (position?: GCoord) => this.renderMarker('project', position);

    renderMarker(markerId: string, position?: GCoord, icon?: any) {
        if (!this.props.map || !this.googleMaps) return;

        let marker = this.markers[markerId];
        if (position) {
            if (!marker) {
                marker = this.markers[markerId] = new this.googleMaps.Marker();
            }
            marker.setOptions({
                position,
                map: this.props.map,
                ...(icon ? { icon } : {}),
            });
        } else if (marker) {
            // Remove marker
            marker.setMap(null);
            marker = null;
        }
    }
}

const mapStateToProps = (state: IAppState) => ({
    googleMapsKey: config.selectors.getConfig(state)?.google_maps_api_key || '',
});

export default connect(mapStateToProps)(ProjectMap);
