import React from 'react';

import { useProjectContext } from 'frontend/projects/use-project';

import { Simulations as BetaSimulations } from 'reports/modules/project/views/Simulations';

const Simulations = () => {
    const { project } = useProjectContext();
    return <BetaSimulations project={project} />;
};

export { Simulations };
