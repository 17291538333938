import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Translations from 'reports/localization/strings';

import { IAppState } from 'reports/store';

import * as fmt from 'reports/utils/formatters';
import { IIntlProps } from 'reports/utils/intl';

import { Angle, Integer, Power } from 'reports/components/core/numbers';

import * as auth from 'reports/modules/auth';
import { WidgetDataTable } from 'reports/modules/report/components/helpers';
import { IReportContext, registerWidget, IWidgetRenderProps } from 'reports/modules/report/widgets';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IContext = Pick<IReportContext, 'design'>;
type IProps = IWidgetRenderProps<object, IContext> & IStateProps & IIntlProps;

const FieldSegmentsTableIntl: React.FC<IProps> = ({ context, className, intl, formatters }) => {
    const { design } = context;
    const { locale } = intl;
    const fieldSegments = design.field_segments;

    return (
        <WidgetDataTable className={className}>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.general.description} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.racking} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.orientation} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.tilt} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.azimuth} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.intrarow_spacing} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.frame_size} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.frames} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.modules} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.general.power} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {fieldSegments.map((i) => (
                    <tr key={i.field_segment_id}>
                        <td>{i.description}</td>
                        <td>{fmt.rackingType(i.rack_type)}</td>
                        <td>{fmt.moduleOrientation(i.orientation)}</td>
                        <td>
                            <div>
                                {fmt.tiltAndAzimuthPrefix(i.rack_type)} <Angle value={i.tilt} />
                            </div>
                            {i.independent_tilt_enabled && (
                                <div>
                                    Surface: <Angle value={i.independent_tilt_surface_tilt} />
                                </div>
                            )}
                        </td>
                        <td>
                            <div>
                                {fmt.tiltAndAzimuthPrefix(i.rack_type)} <Angle value={i.azimuth} />
                            </div>
                            {i.independent_tilt_enabled && (
                                <div>
                                    Surface: <Angle value={i.independent_tilt_surface_azimuth} />
                                </div>
                            )}
                        </td>
                        <td>{formatters.distance(i.row_spacing, { locale })}</td>
                        <td>
                            {i.bank_depth}x{i.bank_width}
                        </td>
                        <td>
                            <Integer value={i.data.frames} />
                        </td>
                        <td>
                            <Integer value={i.data.modules} />
                        </td>
                        <td>
                            <Power value={i.data.power} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </WidgetDataTable>
    );
};
const mapStateToProps = (state: IAppState) => ({
    formatters: auth.selectors.formatters(state),
});
const FieldSegmentsTable = connect(mapStateToProps)(injectIntl(FieldSegmentsTableIntl));

export const FieldSegmentsTableWidget = registerWidget('field_segments_table', {
    Component: FieldSegmentsTable,
    metadata: {
        category: 'project',
        dimensions: { h: 150, w: 800 },
        displayName: Translations.design.field_segments,
        icon: IconNames.TH,
    },
    dependencies: ['design'],
});

export default FieldSegmentsTableWidget;
