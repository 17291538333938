import _ from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { selectors as bomSelectors, IBOMLineItem } from 'reports/modules/project/bom';

import { IAppState } from 'reports/types';

import { Design } from 'reports/models/design';
import { WidgetDataTable } from 'reports/modules/report/components/helpers';
import Translations from 'reports/localization/strings';
import { Integer } from 'reports/components/core/numbers';

const DesignBOMTable: React.FC<{ componentCounts: IBOMLineItem[]; className?: string }> = ({
    componentCounts,
    className,
}) => {
    const sorted = _.sortBy(componentCounts, (i) => i.componentType);

    return (
        <div>
            <WidgetDataTable className={className}>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.design.component} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.design.component_type} />
                        </th>
                        <th>
                            <FormattedMessage {...Translations.general.quantity} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sorted.map(({ name, componentType, count }, idx) => (
                        <tr key={idx}>
                            <td className="text-cell">{name}</td>
                            <td className="text-cell">{componentType}</td>
                            <td>
                                <Integer value={count} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </WidgetDataTable>
        </div>
    );
};

const mapStateToProps = () => {
    const { designComponentCounts } = bomSelectors;

    return (state: IAppState, props: { design: Design }) => ({
        componentCounts: designComponentCounts(state, { design: props.design }),
    });
};

const DesignBOMTableContainer = connect(mapStateToProps)(DesignBOMTable);

export default DesignBOMTableContainer;
