import { Classes, Colors, Switch } from '@blueprintjs/core';

import Flex from 'reports/components/core/containers/Flex';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const LEFT_RIGHT_PADDING = 8;

const ParameterContainer = styled(Flex.ContainerV)`
    padding: 6px ${LEFT_RIGHT_PADDING}px;
`;

const ParameterLine = styled(Flex.Container).attrs({
    align: Flex.Align.CENTER,
    alignV: Flex.AlignV.CENTER,
})``;

const Label = styled.div`
    flex: 1;
    padding-right: 30px;
    text-align: left;
`;

const Data = styled(Flex.Container).attrs({ alignV: Flex.AlignV.CENTER })`
    justify-content: center;
    align-items: center;
    flex: 1;
    max-width: 250px;
    padding: 0px ${LEFT_RIGHT_PADDING}px;

    .${Classes.SWITCH} {
        margin-bottom: 0px;
    }
`;

const ParameterMeta = styled.div`
    flex: 1;
    min-width: 80px;
    padding: 0px ${LEFT_RIGHT_PADDING}px;
`;

const Result = styled(Flex.Container).attrs({ align: Flex.Align.CENTER })`
    flex: 1;
    padding: 4px;
    background-color: #dfebf5;
    border-radius: 3px;
    color: ${Colors.GRAY1};
    text-align: center;
`;

const EditableToggle = styled(Switch)`
    margin-bottom: 0px;
    font-weight: normal;
    color: ${Colors.DARK_GRAY5};
`;

const ExpandedInput = styled.div`
    margin: 4px;
    padding: 4px ${LEFT_RIGHT_PADDING}px;
    background-color: #f4f4f4;
    border-radius: 2px;
`;

export default {
    Data,
    EditableToggle,
    ExpandedInput,
    Label,
    ParameterContainer,
    ParameterLine,
    ParameterMeta,
    Result,
};
