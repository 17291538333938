/* tslint:disable:variable-name function-name */
import moment from 'moment';

import { BaseClass, createAsyncSaver, ReduxEndpoint } from 'reports/utils/api';

import { schema } from './schema';
import * as user from './user';
import * as team from 'reports/models/team';
import { IAPIQueryOpts } from './types';
import { ITier } from 'reports/modules/financials/params';

interface IIncentiveAPIQueryOpts extends IAPIQueryOpts {
    name?: string;
    public?: boolean;
    team_id?: number;
    project_id?: number;
}

export enum IncentiveTypes {
    fixed = 'Fixed Incentive',
    system_price = 'System Cost (%) Incentive',
    nameplate = 'Price Per Watt Incentive',
    production = 'Production Based Incentive',
}

export type IncentiveType = keyof typeof IncentiveTypes;

interface IPayoutPeriod {
    portion: number;
    year: number;
}

type IPaymentSchedule = IPayoutPeriod[];

export interface IConfigurationBase {
    type: IncentiveType;
}

export interface IFixedConfiguration extends IConfigurationBase {
    type: 'fixed';
    amount: number;
    year: number;
}

export interface IIncentiveTiers {
    amount: ITier[];
}

export interface ISystemPriceConfiguration extends IIncentiveTiers {
    type: 'system_price';
    payment_schedule: IPaymentSchedule;
    reduced_basis: boolean;
}

export interface INameplateConfiguration extends IIncentiveTiers {
    type: 'nameplate';
    payment_schedule: IPaymentSchedule;
}

export interface IProductionConfiguration extends IIncentiveTiers {
    type: 'production';
    payout_years: number;
}

export type IConfiguration =
    | IFixedConfiguration
    | ISystemPriceConfiguration
    | INameplateConfiguration
    | IProductionConfiguration;

export interface IIncentiveForm {
    name: string;
    incentive_type: IncentiveType;
    public: boolean;
    configuration: IConfiguration;
}

class Incentive extends BaseClass {
    incentive_id: number;

    name: string;
    incentive_type: IncentiveType;
    configuration: IConfiguration;

    team_id: number;
    team?: team.Team;
    public: boolean;

    created?: moment.Moment;
    last_modified?: moment.Moment;
    creator_id?: number;
    creator?: user.User;
    last_modified_by_user_id?: number;
    last_modified_by_user?: user.User;

    constructor(data: any) {
        super(Incentive.deserializer(data));

        // Add discriminating property to configuration so we can use type guarding on TS union type
        // See http://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions.
        this.configuration.type = this.incentive_type;
    }

    static deserializer = BaseClass.getDeserializer({
        last_modified: (x) => moment(x),
        created: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(Incentive, 'incentive', {
    relationships: {
        creator: { schema: user.schemaObj },
        team: { schema: team.schemaObj },
    },
});

const endpoint = ReduxEndpoint.fromSchema('/api/incentives/', schemaObj, {
    deepSelect: {
        creator: true,
        team: true,
    },
});

const api = {
    index: endpoint.index<IIncentiveAPIQueryOpts>(),
    get: endpoint.get<{ incentive_id: number }>('{incentive_id}'),
    create: endpoint.post<IIncentiveForm>(),
    save: endpoint.put<IIncentiveForm>('{incentive_id}'),
    delete: endpoint.delete('{incentive_id}'),
};

const selectors = {
    byObject: schemaObj.selectByObject,
    byId: schemaObj.selectById,
    all: schemaObj.selectAll,
};

const saver = createAsyncSaver(schemaObj, api.save);

export { Incentive, api, saver, schemaObj, selectors };
