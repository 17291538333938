/* Generic autocomplete component.
 *
 * Example usage,
 *    <Autocomplete<Project>
 *        value={this.state.input}
 *        onChange={projectName => this.setState({ name: projectName })}
 *        placeholder="Project address"
 *        items={this.loadProjects}
 *        onItemSelect={project => this.setState({ address: project.formatted_address })}
 *        getItemValue={project => addressFormatter(project.formatted_address)}
 *        // altenatively, pass in a property path string:
 *        // getItemValue="formatted_address"
 *        searching={this.asyncSearch != null}
 *        disable={this.state.disableInput}
 *    />
 */
import * as React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

import { Classes, IIntentProps, InputGroup, IProps } from '@blueprintjs/core';

import { Suggestion, Suggestions } from 'reports/components/helpers/formHelpers';

export interface IAutocompleteProps<T = any> extends IIntentProps, IProps {
    items: T[];
    onChange: (val: React.FormEvent<HTMLElement>) => void;
    onItemSelect: (item: T, idx: number) => void;
    value: string;

    defaultFilter?: boolean; // use built-in filtering
    disabled?: boolean;
    searching?: boolean;
    placeholder?: string;
    getItemValue?: ((item: T) => string) | string;
    minQueryLength?: number;
}

interface IState<T = any> {
    isOpen: boolean;
    items: T[];
    selectedIdx?: number;
}

class Autocomplete<T> extends React.PureComponent<IAutocompleteProps<T>, IState<T>> {
    state: IState = {
        isOpen: false,
        selectedIdx: undefined,
        items: this.props.items,
    };

    static ofType<T>() {
        return Autocomplete as new (props: IAutocompleteProps<T>) => Autocomplete<T>;
    }

    componentDidUpdate(prevProps) {
        const { items } = this.props;

        if (prevProps.items !== items) {
            this.setState({ items });
        }
    }

    render() {
        const { items } = this.state;
        return (
            <div className={classNames(this.props.className || Classes.FILL)} style={{ position: 'relative' }}>
                <InputGroup
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled || false}
                    // Event handlers
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.reset}
                    onKeyDown={this.handleKeyDown}
                />
                {this.state.isOpen ? (
                    <Suggestions>
                        {items.map((suggestion, i) => (
                            <Suggestion
                                key={i}
                                // prevent Input onBlur, onMouseDown called before IngputGroup onBlur event
                                onMouseDown={(e) => e.preventDefault()}
                                onMouseEnter={() => this.setState({ selectedIdx: i })}
                                onClick={() => {
                                    this.props.onItemSelect(items[i], i);
                                    this.reset();
                                }}
                                className={classNames({
                                    selected: this.state.selectedIdx === i,
                                })}
                            >
                                {this.getSuggestionStr(suggestion)}
                            </Suggestion>
                        ))}
                        {this.props.value && this.props.searching && items.length === 0 ? (
                            <Suggestion key="" className={Classes.DISABLED}>
                                No results.
                            </Suggestion>
                        ) : null}
                    </Suggestions>
                ) : null}
            </div>
        );
    }

    filterItems() {
        const queryArr = this.props.value.split(/[^\w]/);

        let items = this.props.items.slice();
        items = items.filter((suggestionObj) => {
            const suggestion = this.getSuggestionStr(suggestionObj);

            for (const word of queryArr) {
                const re = new RegExp(word.trim(), 'gi');
                if (re.test(suggestion)) {
                    return true;
                }
            }
            return false;
        });

        this.setState({ items });
    }

    getSuggestionStr = (suggestion: T) => {
        const { getItemValue } = this.props;

        return getItemValue
            ? typeof getItemValue === 'function'
                ? getItemValue(suggestion)
                : get(suggestion, getItemValue)
            : suggestion;
    };

    handleChange = (e: React.FormEvent<HTMLElement>) => {
        if (!this.state.isOpen) {
            this.setState({ isOpen: true });
        }

        this.props.onChange(e);

        if (this.props.defaultFilter) {
            this.filterItems();
        }
    };

    handleFocus = () => {
        const { value, minQueryLength } = this.props;

        if (!value || (minQueryLength && value.length < minQueryLength) || !this.props.searching) {
            return;
        }
        this.setState({ isOpen: true });
    };

    handleKeyDown = (e: React.KeyboardEvent) => {
        const { isOpen, selectedIdx, items } = this.state;

        if (!isOpen || !items.length) {
            return;
        }

        switch (e.key) {
            case 'Enter':
            case 'Tab':
                if (selectedIdx !== undefined) {
                    // Select current suggestion
                    this.props.onItemSelect(items[selectedIdx], selectedIdx);
                }
                this.reset();
                break;
            case 'ArrowDown':
                if (selectedIdx === undefined || selectedIdx < items.length - 1) {
                    const updatedSelectedIdx = selectedIdx === undefined ? 0 : selectedIdx + 1;
                    this.setState({ selectedIdx: updatedSelectedIdx });
                }
                break;
            case 'ArrowUp':
                if (selectedIdx !== undefined && selectedIdx > 0) {
                    this.setState({ selectedIdx: selectedIdx - 1 });
                }
                break;
            case 'Escape':
                this.reset();
                break;
        }
    };

    reset = () => this.setState({ isOpen: false, items: [], selectedIdx: undefined });
}

export default Autocomplete;
