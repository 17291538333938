import * as React from 'react';

import { Colors as bpColors, Icon, Position, ProgressBar } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import { Flex } from 'reports/components/core/containers';
import { Link, LinkButton, Tooltip } from 'reports/components/core/controls';
import hsColors, { HSLightTheme } from 'reports/styles/colors';
import { convertColorAndOpacity, convertHexColorToComputedRGB } from 'helioscope/app/utilities/colors';

import * as styles from 'reports/styles/styled-components';
import { RecurringInterval } from 'reports/models/stripe/price';
const styled = styles.styled;

export const PROGRESS_BAR_STATUS_COLORS = {
    trial: hsColors.BORDER_DARK,
    default: hsColors.HELIO_LIGHT_BLUE,
    warning: hsColors.HELIO_ORANGE,
    critical: hsColors.INTENT_DANGER,
};

export const PROGRESS_BAR_BACKGROUND_OPACITY = 0.25;

export const PROJECT_LIMIT_CRITICAL_PERCENT = 80;

const ADD_PROJECTS_DIALOG_ROUTE_PARAMS = {
    dialog: 'add_projects',
};

const ProgressBarContainer = styled(Flex.ContainerV)<{ condensed?: boolean }>`
    padding: ${(props) => (props.condensed ? '0px 5px' : '0px 18px')};
    gap: ${(props) => (props.condensed ? '8px' : '2px')};
`;

const ProgressBarText = styled(Flex.Container)`
    gap: 2px;
    color: ${bpColors.GRAY1};
`;

const StyledLink = styled(Link)`
    && {
        color: white;
        text-decoration: underline;
    }

    && :hover {
        color: white;
    }
`;

const StyledUsageLink = styled(Link)`
    justify-content: left;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 4px;
    color: ${HSLightTheme.sidebarTitleFont};

    &:hover {
        color: ${HSLightTheme.sidebarTitleFont};
        text-decoration: none;
        opacity: 0.8;
    }
`;

const StyledIcon = styled(Icon)`
    padding-bottom: 2px;
`;

const StyledLinkButton = styled(LinkButton)`
    padding: 0px;
    margin: 8px 0px 0px 0px;
`;

interface IProgressBarProps {
    color: string;
}

const StyledProgressBar = styled(ProgressBar)<IProgressBarProps>`
    background-color: ${(props) => convertColorAndOpacity(props.color, PROGRESS_BAR_BACKGROUND_OPACITY)};

    .bp3-progress-meter {
        background-color: ${(props) => convertHexColorToComputedRGB(props.color)};
    }
`;

const getUsagePercentage = (projectCount: number, projectLimit: number) =>
    Math.round((projectCount / projectLimit) * 100);

const getProgressBarColorProps = (projectCount: number, projectLimit: number, isUserOnTrial: boolean): string => {
    if (isUserOnTrial) {
        return PROGRESS_BAR_STATUS_COLORS.trial;
    }

    const usagePercentage = getUsagePercentage(projectCount, projectLimit);
    if (projectCount >= projectLimit) {
        return PROGRESS_BAR_STATUS_COLORS.critical;
    }

    if (usagePercentage >= PROJECT_LIMIT_CRITICAL_PERCENT) {
        return PROGRESS_BAR_STATUS_COLORS.warning;
    }

    return PROGRESS_BAR_STATUS_COLORS.default;
};

export interface IProjectLimitProgressBarProps {
    routeName?: string;
    routeParams?: { [key: string]: any };
    userOnTrial: boolean;
    projectCount: number;
    projectLimit: number;
    showUpsellAtCritical: boolean;
    isTeamAdmin: boolean;
    planType: RecurringInterval | undefined;
    condensed?: boolean;
}

const InfoTooltip: React.FC<{
    projectsRemaining: number;
}> = ({ projectsRemaining }) => (
    <Tooltip
        content={
            <div style={{ maxWidth: '200px' }}>
                You have {projectsRemaining} free {projectsRemaining === 1 ? 'project' : 'projects'} remaining on your
                trial account.{' '}
                <StyledLink
                    routeName="app.settings.team.billing"
                    searchParams={{ dialog: 'initial', referrer: ReferrerTypes.project_limit_progress_bar }}
                >
                    Upgrade now
                </StyledLink>{' '}
                for more projects.
            </div>
        }
        position={Position.TOP}
        usePortal={false}
        hoverCloseDelay={2000}
    >
        <Icon icon={IconNames.INFO_SIGN} data-testid="upgrade-now-tooltip-icon" />
    </Tooltip>
);

const ProjectLimitProgressBar = ({
    routeName,
    routeParams,
    userOnTrial,
    projectCount,
    projectLimit,
    showUpsellAtCritical,
    isTeamAdmin,
    planType,
    condensed,
}: IProjectLimitProgressBarProps) => {
    return (
        <ProgressBarContainer data-testid="project-limit-progress-bar" condensed={condensed}>
            {!userOnTrial && (
                <StyledUsageLink routeName="app.settings.team.billing">
                    Project Usage <StyledIcon size={14} icon={IconNames.SHARE} />
                </StyledUsageLink>
            )}
            <StyledProgressBar
                data-testid="usage-progress-bar"
                animate={false}
                stripes={false}
                value={projectCount / projectLimit}
                color={getProgressBarColorProps(projectCount, projectLimit, userOnTrial)}
            />
            <ProgressBarText>
                <div>
                    {projectCount} / {projectLimit} projects used
                </div>
                {userOnTrial && <InfoTooltip projectsRemaining={Math.max(0, projectLimit - projectCount)} />}
            </ProgressBarText>
            {showUpsellAtCritical &&
                !userOnTrial &&
                getUsagePercentage(projectCount, projectLimit) >= PROJECT_LIMIT_CRITICAL_PERCENT && (
                    <StyledLinkButton
                        data-testid="project-limit-add-projects-button"
                        routeName={routeName || 'app.settings.team.billing'}
                        routeParams={routeParams}
                        searchParams={ADD_PROJECTS_DIALOG_ROUTE_PARAMS}
                        text="Add Projects"
                        tracking={{
                            name: 'paywall.consumption_enforcement.open',
                            options: {
                                projectCount,
                                projectLimit,
                                referrer: 'add project',
                                model_type: {
                                    user_type: isTeamAdmin ? 'admin' : 'non-admin',
                                    subscription_type: planType ? (planType === 'year' ? 'annual' : 'monthly') : null,
                                },
                            },
                        }}
                    />
                )}
        </ProgressBarContainer>
    );
};

export default ProjectLimitProgressBar;
