import { get, has, noop } from 'lodash';

import * as React from 'react';
import { FormConsumer, IFormContext } from './types';

class EnsureDefault extends React.Component<{
    needsDefault: boolean;
    setDefault: () => any;
}> {
    componentDidMount() {
        const { needsDefault, setDefault } = this.props;
        if (needsDefault) {
            setDefault();
        }
    }

    render() {
        return this.props.children;
    }
}

export interface IFormFieldProps {
    path: string;

    /**
     * @deprecated Put all of the fields' default values in Form baseValue instead
     */
    defaultValue?: any;

    // optional callback for making changes after the user has made a change
    onUpdate?: (val: any, form?: IFormContext<any>) => void;

    children: (props: {
        value: any;
        onChange: (val: any) => void;
        dirty: boolean;
        errors: string[];

        form: IFormContext<any>;
    }) => React.ReactNode;
}

const FormField: React.FC<IFormFieldProps> = ({ path, defaultValue, onUpdate = noop, children }) => (
    <FormConsumer>
        {(formContext) => (
            <EnsureDefault
                needsDefault={defaultValue != null && !has(formContext.formData, path)}
                setDefault={() => formContext.updateValue(path, defaultValue, false)}
            >
                {children({
                    value: get(formContext.formData, path, defaultValue),
                    onChange: (val) => {
                        formContext.updateValue(path, val);
                        onUpdate(val, formContext);
                    },
                    // can only use this on naked props, not nested props
                    dirty: formContext.dirtyFields[path] as any,
                    errors: formContext.fieldErrors[path] || ([] as any),

                    form: formContext,
                })}
            </EnsureDefault>
        )}
    </FormConsumer>
);

export default FormField;
