import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { MenuItem, MenuDivider, Menu, Button, Icon, Classes, Colors } from '@blueprintjs/core';
import classNames from 'classnames';

import { IAppState } from 'reports/types';

import Flex from 'reports/components/core/containers/Flex';

import { IDisplayMetric } from 'reports/models/user';
import * as proj from 'reports/models/project';
import * as sim from 'reports/models/simulation';

import * as auth from 'reports/modules/auth';
import { actions as userActions, selectors as userSelectors } from 'reports/modules/user';
import { selectors as projSelector } from 'reports/modules/project';

import { FIN_STATUSES } from 'reports/modules/financials/state';
import { FORMATTED_SPECIAL_VALUES, ITokenMap } from 'reports/modules/report/tokens';

import { MetricSelectDropdown } from './CustomDropdowns';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const MetricDropdownWrapper = styled.div`
    background-color: #444444;
    border-radius: 0;

    .${Classes.POPOVER_WRAPPER} {
        height: 100%;
    }

    .${Classes.BUTTON_TEXT} {
        flex: 1;
        margin: 0 7px;
    }
`;

const MetricBarWrapper = styled(Flex.Container)`
    background-color: #444444;
    z-index: 10; // to maintain box-shadow, since z-index is 9 for '.bp3-intent-warning' class
    overflow-x: auto;
    overflow-y: hidden;
    border-top: solid 1px ${Colors.DARK_GRAY2};
    box-shadow: 0 1px 2px ${Colors.BLACK};
`;

const MetricBar = styled(Flex.Container)`
    flex: 1;
    justify-content: space-evenly;
    padding: 0 16px;
`;

const MetricBarItem = styled.div`
    width: 100px;
    text-align: center;
    padding: 8px 0;
    line-height: 1.15;
`;

const ErrorBar = styled(Flex.Container)`
    flex: 1;
    height: 45px;
    justify-content: center;
    align-items: center;
    font-style: italic;
    white-space: nowrap;
`;

const METRICS = {
    system_yield: {
        description: 'Specific Yield',
        isFinancial: false,
    },
    design_nameplate_dc: {
        description: 'Nameplate',
        isFinancial: false,
    },
    annual_production: {
        description: 'Grid Power',
        isFinancial: false,
    },
    offset_energy: {
        description: 'Offset Energy',
        isFinancial: true,
    },
    offset_bill: {
        description: 'Offset Bill',
        isFinancial: true,
    },
    payback_period: {
        description: 'Payback Period',
        isFinancial: true,
    },
};

export const METRIC_SELECTORS = Object.keys(METRICS) as (keyof typeof METRICS)[];

export interface IMetric {
    id: number;
    description: string;
    metric: string;
    metricVal: string;

    active: boolean;
    disabled: boolean;
    hasError: boolean;
}

const getMetricItems = (
    metricTokens: ITokenMap,
    locale: string,
    canViewFinancials: boolean,
    simulation?: sim.Simulation,
    selectedMetric: string | null = null,
): IMetric[] =>
    METRIC_SELECTORS.filter((metric) => canViewFinancials || !METRICS[metric].isFinancial).map((metric, idx) => {
        const token = metricTokens[metric];
        const rawVal = token.rawValue;
        let formattedVal = token.format({ locale, precision: 1 });

        let disabled = false;
        let hasError = false;

        switch (typeof rawVal) {
            case 'string':
                break;
            case 'number':
                if (isNaN(rawVal)) {
                    // Normally, formatters will display "NaN". For the metric bar, we'd rather display "N/A".
                    formattedVal = 'N/A';
                    hasError = true;
                }
                break;
            case 'symbol':
                switch (rawVal) {
                    case FIN_STATUSES.computing:
                    case FIN_STATUSES.refreshing:
                    case FIN_STATUSES.missingInputs:
                        disabled = true;
                        break;
                    case FIN_STATUSES.permissionsError:
                    case FIN_STATUSES.calculationError:
                        hasError = true;
                        break;
                }
                break;
            default:
                // i.e. rawVal === null
                disabled = true;
                break;
        }

        if ((metric === 'system_yield' || metric === 'annual_production') && simulation && !simulation.complete()) {
            // This is kind of hacky. It's only necessary since these tokens are setup to give live updates while
            // the simulation is in progress. For the metric bar, we would rather see 'computing' than live updates.
            formattedVal = FORMATTED_SPECIAL_VALUES.computing;
            disabled = true;
        }

        return {
            metric,
            disabled,
            hasError,
            metricVal: formattedVal,
            id: idx,
            active: selectedMetric === metric,
            description: METRICS[metric].description,
        };
    });

interface IOwnProps {
    project: proj.Project;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = typeof mapDispatchToProps;
type IProps = IOwnProps & IStateProps & IDispatchProps & { intl: IntlShape };

const _ContextBarMetricDropdown: React.SFC<IProps> = ({
    simulation,
    metricTokens,
    displayMetric,
    updateDisplayMetric,
    intl,
    user,
}) => {
    const canViewFinancials = user.hasFinancialsAccess();
    const menuItems = getMetricItems(
        metricTokens,
        intl.locale,
        canViewFinancials,
        simulation,
        displayMetric.selected_metric,
    );
    const { disabled, hasError, metricVal } =
        menuItems.find(({ metric }) => metric === displayMetric.selected_metric) || menuItems[0];

    return (
        <MetricDropdownWrapper>
            <MetricSelectDropdown
                disabled={disabled && !hasError}
                buttonText={<div style={{ textAlign: 'center', fontSize: 20 }}>{metricVal}</div>}
                items={menuItems}
                onItemSelect={(item) =>
                    updateDisplayMetric({
                        ...displayMetric,
                        selected_metric: item.metric,
                    })
                }
                itemRenderer={(item, { handleClick }) => (
                    <MenuItem
                        key={item.id}
                        style={{ margin: '0 4px', minWidth: 200 }}
                        active={item.active}
                        disabled={item.disabled || item.hasError}
                        onClick={handleClick}
                        text={<span>{item.description}</span>}
                        labelElement={<span style={{ fontStyle: 'italic' }}>{item.metricVal}</span>}
                    />
                )}
                itemListRenderer={({ items, itemsParentRef, renderItem }) => (
                    <Menu ulRef={itemsParentRef}>
                        {items.map(renderItem).filter((item) => item != null)}
                        <MenuDivider />
                        <MenuItem
                            key={items.length}
                            style={{
                                margin: '0 4px',
                                minWidth: 200,
                                textAlign: 'center',
                            }}
                            active={displayMetric.display_metric_bar}
                            text={displayMetric.display_metric_bar ? 'Hide All Metrics' : 'Show All Metrics'}
                            onClick={() =>
                                updateDisplayMetric({
                                    ...displayMetric,
                                    display_metric_bar: !displayMetric.display_metric_bar,
                                })
                            }
                        />
                    </Menu>
                )}
            />
        </MetricDropdownWrapper>
    );
};

const _ContextBarMetricBar: React.SFC<IProps> = (props) => {
    const { simulation, metricTokens, displayMetric, updateDisplayMetric, intl, user } = props;
    const canViewFinancials = user.hasFinancialsAccess();

    return displayMetric.display_metric_bar ? (
        <MetricBarWrapper className={classNames(Classes.DARK, 'center')}>
            {metricTokens['design_nameplate_dc'].format() === 'N/A' ? (
                <ErrorBar className={classNames(Classes.TEXT_DISABLED, 'center')}>
                    <Icon icon="warning-sign" iconSize={18} />
                    <div style={{ paddingLeft: 8 }}>Incomplete design - metrics unavailable.</div>
                </ErrorBar>
            ) : (
                <MetricBar>
                    {getMetricItems(metricTokens, intl.locale, canViewFinancials, simulation).map((metricItem) => {
                        const { id, disabled, hasError, metricVal, description } = metricItem;
                        return (
                            <MetricBarItem
                                key={id}
                                className={classNames({
                                    [Classes.TEXT_DISABLED]: disabled || hasError,
                                })}
                            >
                                <div style={{ fontSize: 16 }}>{metricVal}</div>
                                <div style={{ fontSize: 12 }}>{description}</div>
                            </MetricBarItem>
                        );
                    })}
                </MetricBar>
            )}
            <div style={{ padding: '0 10px' }}>
                <Button // not using margin since on resize the margin disappear due to sibling component's flex-grow
                    minimal
                    style={{ height: 30, width: 30 }}
                    icon={<Icon icon="cross" iconSize={20} />}
                    onClick={() =>
                        updateDisplayMetric({
                            ...displayMetric,
                            display_metric_bar: !displayMetric.display_metric_bar,
                        })
                    }
                />
            </div>
        </MetricBarWrapper>
    ) : null;
};

const mapStateToProps = (state: IAppState, ownProps: { project?: proj.Project }) => ({
    simulation: projSelector.primarySimulation(state, ownProps),
    metricTokens: projSelector.metricTokens(state, ownProps),
    displayMetric: userSelectors.getDisplayMetric(state),
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = {
    updateDisplayMetric: (displayMetric: IDisplayMetric) =>
        userActions.updatePreferences({
            display_metric: displayMetric,
        }),
};

const ContextBarMetricDropdown = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ContextBarMetricDropdown));
const ContextBarMetricBar = connect(mapStateToProps, mapDispatchToProps)(injectIntl(_ContextBarMetricBar));
export { ContextBarMetricDropdown, ContextBarMetricBar };
