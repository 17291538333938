// Coordinates for the bounding boxes of regions covered by PSM weather data
// Generated using the operations script found in operations/generate_map_psm.py
export const PsmBounds = [
    [
        { lat: 59.597, lng: -44.273 },
        { lat: 59.591, lng: -43.66 },
        { lat: 59.63, lng: -43.605 },
        { lat: 59.637, lng: -43.447 },
        { lat: 59.691, lng: -43.422 },
        { lat: 59.713, lng: -43.374 },
        { lat: 59.711, lng: -43.1 },
        { lat: 59.745, lng: -43.059 },
        { lat: 59.757, lng: -43.007 },
        { lat: 59.837, lng: -42.927 },
        { lat: 59.889, lng: -42.915 },
        { lat: 59.93, lng: -42.881 },
        { lat: 59.97, lng: -42.881 },
        { lat: 59.989, lng: -42.9 },
        { lat: 59.989, lng: -42.94 },
        { lat: 59.989, lng: -45.5 },
        { lat: 59.97, lng: -45.519 },
        { lat: 59.951, lng: -45.5 },
        { lat: 59.941, lng: -45.392 },
        { lat: 59.871, lng: -45.34 },
        { lat: 59.87, lng: -45.195 },
        { lat: 59.831, lng: -45.14 },
        { lat: 59.827, lng: -45.025 },
        { lat: 59.791, lng: -44.98 },
        { lat: 59.791, lng: -44.84 },
        { lat: 59.751, lng: -44.78 },
        { lat: 59.75, lng: -44.635 },
        { lat: 59.671, lng: -44.54 },
        { lat: 59.669, lng: -44.351 },
        { lat: 59.597, lng: -44.273 },
    ],
    [
        { lat: -15.003, lng: -75.953 },
        { lat: -15.009, lng: -38.82 },
        { lat: -14.99, lng: -38.801 },
        { lat: -14.489, lng: -38.801 },
        { lat: -14.43, lng: -38.761 },
        { lat: -14.315, lng: -38.757 },
        { lat: -14.27, lng: -38.721 },
        { lat: -13.61, lng: -38.721 },
        { lat: -13.55, lng: -38.681 },
        { lat: -13.4, lng: -38.67 },
        { lat: -13.363, lng: -38.607 },
        { lat: -13.26, lng: -38.508 },
        { lat: -13.243, lng: -38.447 },
        { lat: -13.213, lng: -38.416 },
        { lat: -13.209, lng: -38.26 },
        { lat: -13.171, lng: -38.211 },
        { lat: -13.163, lng: -38.127 },
        { lat: -13.043, lng: -38.007 },
        { lat: -12.982, lng: -37.99 },
        { lat: -12.803, lng: -37.807 },
        { lat: -12.665, lng: -37.752 },
        { lat: -12.603, lng: -37.687 },
        { lat: -12.541, lng: -37.669 },
        { lat: -12.443, lng: -37.567 },
        { lat: -12.381, lng: -37.549 },
        { lat: -12.323, lng: -37.487 },
        { lat: -11.951, lng: -37.315 },
        { lat: -11.923, lng: -37.287 },
        { lat: -11.829, lng: -37.274 },
        { lat: -11.763, lng: -37.207 },
        { lat: -11.631, lng: -37.155 },
        { lat: -11.563, lng: -37.087 },
        { lat: -11.502, lng: -37.07 },
        { lat: -11.403, lng: -36.967 },
        { lat: -11.345, lng: -36.952 },
        { lat: -11.283, lng: -36.887 },
        { lat: -11.151, lng: -36.835 },
        { lat: -11.083, lng: -36.767 },
        { lat: -11.021, lng: -36.749 },
        { lat: -10.94, lng: -36.668 },
        { lat: -10.923, lng: -36.607 },
        { lat: -10.861, lng: -36.549 },
        { lat: -10.843, lng: -36.487 },
        { lat: -10.776, lng: -36.421 },
        { lat: -10.73, lng: -36.325 },
        { lat: -10.723, lng: -36.207 },
        { lat: -10.683, lng: -36.167 },
        { lat: -10.622, lng: -36.15 },
        { lat: -10.563, lng: -36.087 },
        { lat: -10.458, lng: -36.067 },
        { lat: -10.416, lng: -36.021 },
        { lat: -10.403, lng: -35.967 },
        { lat: -10.243, lng: -35.807 },
        { lat: -10.185, lng: -35.792 },
        { lat: -10.083, lng: -35.687 },
        { lat: -10.025, lng: -35.672 },
        { lat: -9.963, lng: -35.607 },
        { lat: -9.912, lng: -35.584 },
        { lat: -9.883, lng: -35.487 },
        { lat: -9.794, lng: -35.466 },
        { lat: -9.763, lng: -35.407 },
        { lat: -9.683, lng: -35.327 },
        { lat: -9.631, lng: -35.315 },
        { lat: -9.549, lng: -35.262 },
        { lat: -9.523, lng: -35.207 },
        { lat: -9.443, lng: -35.127 },
        { lat: -9.389, lng: -35.114 },
        { lat: -9.323, lng: -35.047 },
        { lat: -9.271, lng: -35.035 },
        { lat: -9.07, lng: -34.921 },
        { lat: -9.005, lng: -34.92 },
        { lat: -8.95, lng: -34.881 },
        { lat: -8.879, lng: -34.879 },
        { lat: -8.763, lng: -34.807 },
        { lat: -8.679, lng: -34.799 },
        { lat: -8.63, lng: -34.761 },
        { lat: -8.559, lng: -34.759 },
        { lat: -8.51, lng: -34.721 },
        { lat: -8.399, lng: -34.719 },
        { lat: -8.283, lng: -34.647 },
        { lat: -8.199, lng: -34.639 },
        { lat: -8.15, lng: -34.601 },
        { lat: -6.91, lng: -34.601 },
        { lat: -6.859, lng: -34.639 },
        { lat: -6.737, lng: -34.647 },
        { lat: -6.659, lng: -34.719 },
        { lat: -6.51, lng: -34.721 },
        { lat: -6.45, lng: -34.761 },
        { lat: -6.23, lng: -34.761 },
        { lat: -6.019, lng: -34.879 },
        { lat: -5.87, lng: -34.881 },
        { lat: -5.826, lng: -34.917 },
        { lat: -5.75, lng: -34.921 },
        { lat: -5.699, lng: -34.959 },
        { lat: -5.59, lng: -34.961 },
        { lat: -5.539, lng: -34.999 },
        { lat: -5.43, lng: -35.001 },
        { lat: -5.381, lng: -35.039 },
        { lat: -5.31, lng: -35.041 },
        { lat: -5.269, lng: -35.075 },
        { lat: -5.217, lng: -35.087 },
        { lat: -5.149, lng: -35.155 },
        { lat: -5.057, lng: -35.167 },
        { lat: -5.017, lng: -35.207 },
        { lat: -5.0, lng: -35.268 },
        { lat: -4.937, lng: -35.327 },
        { lat: -4.929, lng: -35.449 },
        { lat: -4.891, lng: -35.5 },
        { lat: -4.89, lng: -35.645 },
        { lat: -4.851, lng: -35.7 },
        { lat: -4.851, lng: -36.22 },
        { lat: -4.891, lng: -36.279 },
        { lat: -4.891, lng: -36.521 },
        { lat: -4.731, lng: -36.7 },
        { lat: -4.723, lng: -36.965 },
        { lat: -4.631, lng: -37.034 },
        { lat: -4.577, lng: -37.047 },
        { lat: -4.497, lng: -37.127 },
        { lat: -4.485, lng: -37.179 },
        { lat: -4.451, lng: -37.22 },
        { lat: -4.449, lng: -37.331 },
        { lat: -4.417, lng: -37.367 },
        { lat: -4.402, lng: -37.425 },
        { lat: -4.318, lng: -37.51 },
        { lat: -4.257, lng: -37.527 },
        { lat: -4.217, lng: -37.567 },
        { lat: -4.202, lng: -37.625 },
        { lat: -4.097, lng: -37.727 },
        { lat: -4.085, lng: -37.779 },
        { lat: -4.043, lng: -37.83 },
        { lat: -3.918, lng: -37.95 },
        { lat: -3.857, lng: -37.967 },
        { lat: -3.697, lng: -38.127 },
        { lat: -3.668, lng: -38.184 },
        { lat: -3.577, lng: -38.207 },
        { lat: -3.497, lng: -38.287 },
        { lat: -3.484, lng: -38.461 },
        { lat: -3.377, lng: -38.567 },
        { lat: -3.365, lng: -38.619 },
        { lat: -3.324, lng: -38.669 },
        { lat: -3.177, lng: -38.807 },
        { lat: -3.169, lng: -38.889 },
        { lat: -3.137, lng: -38.927 },
        { lat: -3.124, lng: -38.981 },
        { lat: -3.017, lng: -39.087 },
        { lat: -2.924, lng: -39.341 },
        { lat: -2.857, lng: -39.407 },
        { lat: -2.764, lng: -39.621 },
        { lat: -2.697, lng: -39.687 },
        { lat: -2.685, lng: -39.739 },
        { lat: -2.651, lng: -39.78 },
        { lat: -2.65, lng: -39.925 },
        { lat: -2.611, lng: -39.98 },
        { lat: -2.611, lng: -40.6 },
        { lat: -2.571, lng: -40.66 },
        { lat: -2.607, lng: -40.704 },
        { lat: -2.611, lng: -40.78 },
        { lat: -2.651, lng: -40.839 },
        { lat: -2.651, lng: -41.1 },
        { lat: -2.691, lng: -41.159 },
        { lat: -2.694, lng: -41.41 },
        { lat: -2.671, lng: -41.462 },
        { lat: -2.617, lng: -41.487 },
        { lat: -2.605, lng: -41.539 },
        { lat: -2.531, lng: -41.66 },
        { lat: -2.53, lng: -41.805 },
        { lat: -2.491, lng: -41.86 },
        { lat: -2.489, lng: -42.329 },
        { lat: -2.44, lng: -42.428 },
        { lat: -2.377, lng: -42.487 },
        { lat: -2.369, lng: -42.569 },
        { lat: -2.337, lng: -42.607 },
        { lat: -2.324, lng: -42.661 },
        { lat: -2.251, lng: -42.74 },
        { lat: -2.25, lng: -42.885 },
        { lat: -2.211, lng: -42.94 },
        { lat: -2.207, lng: -43.016 },
        { lat: -2.171, lng: -43.06 },
        { lat: -2.172, lng: -43.337 },
        { lat: -2.152, lng: -43.381 },
        { lat: -2.097, lng: -43.407 },
        { lat: -2.051, lng: -43.46 },
        { lat: -2.051, lng: -43.86 },
        { lat: -2.097, lng: -43.913 },
        { lat: -2.155, lng: -43.928 },
        { lat: -2.202, lng: -43.975 },
        { lat: -2.206, lng: -44.116 },
        { lat: -2.171, lng: -44.154 },
        { lat: -2.07, lng: -44.161 },
        { lat: -2.029, lng: -44.195 },
        { lat: -1.977, lng: -44.207 },
        { lat: -1.933, lng: -44.274 },
        { lat: -1.79, lng: -44.281 },
        { lat: -1.741, lng: -44.319 },
        { lat: -1.67, lng: -44.321 },
        { lat: -1.595, lng: -44.392 },
        { lat: -1.537, lng: -44.407 },
        { lat: -1.533, lng: -44.534 },
        { lat: -1.495, lng: -44.593 },
        { lat: -1.39, lng: -44.601 },
        { lat: -1.346, lng: -44.637 },
        { lat: -1.27, lng: -44.641 },
        { lat: -1.215, lng: -44.68 },
        { lat: -1.057, lng: -44.687 },
        { lat: -1.051, lng: -45.18 },
        { lat: -1.09, lng: -45.235 },
        { lat: -1.092, lng: -45.417 },
        { lat: -1.063, lng: -45.468 },
        { lat: -0.937, lng: -45.527 },
        { lat: -0.931, lng: -45.721 },
        { lat: -0.891, lng: -45.78 },
        { lat: -0.878, lng: -45.933 },
        { lat: -0.849, lng: -45.955 },
        { lat: -0.75, lng: -45.961 },
        { lat: -0.731, lng: -45.98 },
        { lat: -0.729, lng: -46.329 },
        { lat: -0.617, lng: -46.447 },
        { lat: -0.598, lng: -46.573 },
        { lat: -0.531, lng: -46.62 },
        { lat: -0.529, lng: -46.729 },
        { lat: -0.491, lng: -46.78 },
        { lat: -0.487, lng: -46.856 },
        { lat: -0.451, lng: -46.9 },
        { lat: -0.449, lng: -47.049 },
        { lat: -0.411, lng: -47.1 },
        { lat: -0.407, lng: -47.176 },
        { lat: -0.371, lng: -47.22 },
        { lat: -0.371, lng: -47.86 },
        { lat: -0.449, lng: -47.991 },
        { lat: -0.446, lng: -48.116 },
        { lat: -0.423, lng: -48.148 },
        { lat: -0.379, lng: -48.164 },
        { lat: -0.11, lng: -48.161 },
        { lat: -0.057, lng: -48.207 },
        { lat: -0.05, lng: -48.365 },
        { lat: -0.011, lng: -48.42 },
        { lat: -0.011, lng: -48.721 },
        { lat: 0.029, lng: -48.78 },
        { lat: 0.029, lng: -48.921 },
        { lat: 0.069, lng: -48.98 },
        { lat: 0.075, lng: -49.119 },
        { lat: 0.114, lng: -49.156 },
        { lat: 0.223, lng: -49.167 },
        { lat: 0.263, lng: -49.207 },
        { lat: 0.277, lng: -49.444 },
        { lat: 0.332, lng: -49.482 },
        { lat: 0.423, lng: -49.487 },
        { lat: 0.463, lng: -49.527 },
        { lat: 0.471, lng: -49.611 },
        { lat: 0.509, lng: -49.66 },
        { lat: 0.524, lng: -49.776 },
        { lat: 0.578, lng: -49.803 },
        { lat: 0.704, lng: -49.811 },
        { lat: 0.837, lng: -49.727 },
        { lat: 0.921, lng: -49.719 },
        { lat: 0.97, lng: -49.681 },
        { lat: 1.65, lng: -49.681 },
        { lat: 1.701, lng: -49.719 },
        { lat: 1.823, lng: -49.727 },
        { lat: 1.903, lng: -49.807 },
        { lat: 2.014, lng: -50.066 },
        { lat: 2.064, lng: -50.084 },
        { lat: 2.17, lng: -50.081 },
        { lat: 2.219, lng: -50.119 },
        { lat: 2.29, lng: -50.121 },
        { lat: 2.331, lng: -50.155 },
        { lat: 2.383, lng: -50.167 },
        { lat: 2.463, lng: -50.247 },
        { lat: 2.466, lng: -50.448 },
        { lat: 2.497, lng: -50.508 },
        { lat: 2.61, lng: -50.521 },
        { lat: 2.695, lng: -50.597 },
        { lat: 2.77, lng: -50.601 },
        { lat: 2.825, lng: -50.64 },
        { lat: 2.93, lng: -50.641 },
        { lat: 3.015, lng: -50.717 },
        { lat: 3.13, lng: -50.721 },
        { lat: 3.255, lng: -50.797 },
        { lat: 3.33, lng: -50.801 },
        { lat: 3.385, lng: -50.84 },
        { lat: 3.49, lng: -50.841 },
        { lat: 3.545, lng: -50.88 },
        { lat: 4.103, lng: -50.887 },
        { lat: 4.134, lng: -50.946 },
        { lat: 4.223, lng: -50.967 },
        { lat: 4.285, lng: -51.032 },
        { lat: 4.343, lng: -51.047 },
        { lat: 4.503, lng: -51.207 },
        { lat: 4.518, lng: -51.265 },
        { lat: 4.589, lng: -51.34 },
        { lat: 4.586, lng: -51.448 },
        { lat: 4.609, lng: -51.502 },
        { lat: 4.771, lng: -51.595 },
        { lat: 4.823, lng: -51.607 },
        { lat: 4.863, lng: -51.647 },
        { lat: 4.88, lng: -51.828 },
        { lat: 5.063, lng: -52.007 },
        { lat: 5.089, lng: -52.062 },
        { lat: 5.143, lng: -52.087 },
        { lat: 5.153, lng: -52.176 },
        { lat: 5.223, lng: -52.247 },
        { lat: 5.243, lng: -52.312 },
        { lat: 5.463, lng: -52.527 },
        { lat: 5.489, lng: -52.582 },
        { lat: 5.543, lng: -52.607 },
        { lat: 5.663, lng: -52.727 },
        { lat: 5.67, lng: -52.885 },
        { lat: 5.709, lng: -52.94 },
        { lat: 5.713, lng: -53.055 },
        { lat: 5.749, lng: -53.1 },
        { lat: 5.751, lng: -53.251 },
        { lat: 5.823, lng: -53.327 },
        { lat: 5.831, lng: -53.409 },
        { lat: 5.863, lng: -53.447 },
        { lat: 5.875, lng: -53.499 },
        { lat: 5.943, lng: -53.567 },
        { lat: 5.955, lng: -53.619 },
        { lat: 5.983, lng: -53.647 },
        { lat: 5.991, lng: -53.809 },
        { lat: 6.069, lng: -53.94 },
        { lat: 6.07, lng: -54.085 },
        { lat: 6.109, lng: -54.14 },
        { lat: 6.111, lng: -54.289 },
        { lat: 6.149, lng: -54.34 },
        { lat: 6.15, lng: -54.525 },
        { lat: 6.189, lng: -54.58 },
        { lat: 6.186, lng: -55.084 },
        { lat: 6.193, lng: -55.136 },
        { lat: 6.229, lng: -55.18 },
        { lat: 6.193, lng: -55.224 },
        { lat: 6.186, lng: -55.276 },
        { lat: 6.189, lng: -55.9 },
        { lat: 6.151, lng: -55.949 },
        { lat: 6.149, lng: -56.06 },
        { lat: 6.113, lng: -56.104 },
        { lat: 6.106, lng: -56.159 },
        { lat: 6.113, lng: -56.216 },
        { lat: 6.149, lng: -56.26 },
        { lat: 6.15, lng: -56.485 },
        { lat: 6.189, lng: -56.54 },
        { lat: 6.197, lng: -56.924 },
        { lat: 6.229, lng: -56.954 },
        { lat: 6.303, lng: -56.967 },
        { lat: 6.503, lng: -57.167 },
        { lat: 6.511, lng: -57.251 },
        { lat: 6.543, lng: -57.287 },
        { lat: 6.561, lng: -57.349 },
        { lat: 6.609, lng: -57.394 },
        { lat: 6.663, lng: -57.407 },
        { lat: 6.756, lng: -57.549 },
        { lat: 6.983, lng: -57.767 },
        { lat: 6.995, lng: -57.819 },
        { lat: 7.029, lng: -57.86 },
        { lat: 7.033, lng: -58.015 },
        { lat: 7.088, lng: -58.101 },
        { lat: 7.143, lng: -58.127 },
        { lat: 7.169, lng: -58.222 },
        { lat: 7.222, lng: -58.244 },
        { lat: 7.33, lng: -58.241 },
        { lat: 7.389, lng: -58.281 },
        { lat: 7.583, lng: -58.287 },
        { lat: 7.682, lng: -58.39 },
        { lat: 7.743, lng: -58.407 },
        { lat: 7.823, lng: -58.487 },
        { lat: 7.849, lng: -58.542 },
        { lat: 7.903, lng: -58.567 },
        { lat: 7.983, lng: -58.647 },
        { lat: 8.023, lng: -58.687 },
        { lat: 8.043, lng: -58.752 },
        { lat: 8.263, lng: -58.967 },
        { lat: 8.278, lng: -59.025 },
        { lat: 8.343, lng: -59.087 },
        { lat: 8.361, lng: -59.149 },
        { lat: 8.423, lng: -59.207 },
        { lat: 8.518, lng: -59.425 },
        { lat: 8.583, lng: -59.487 },
        { lat: 8.596, lng: -59.661 },
        { lat: 8.743, lng: -59.807 },
        { lat: 8.755, lng: -59.859 },
        { lat: 8.829, lng: -59.98 },
        { lat: 8.826, lng: -60.555 },
        { lat: 8.844, lng: -60.598 },
        { lat: 8.887, lng: -60.615 },
        { lat: 8.954, lng: -60.593 },
        { lat: 9.01, lng: -60.521 },
        { lat: 9.41, lng: -60.521 },
        { lat: 9.491, lng: -60.595 },
        { lat: 9.583, lng: -60.607 },
        { lat: 9.663, lng: -60.687 },
        { lat: 9.689, lng: -60.742 },
        { lat: 9.743, lng: -60.767 },
        { lat: 9.797, lng: -60.91 },
        { lat: 9.855, lng: -60.934 },
        { lat: 9.899, lng: -60.909 },
        { lat: 9.917, lng: -60.847 },
        { lat: 9.957, lng: -60.807 },
        { lat: 10.079, lng: -60.799 },
        { lat: 10.13, lng: -60.761 },
        { lat: 10.514, lng: -60.764 },
        { lat: 10.566, lng: -60.757 },
        { lat: 10.61, lng: -60.721 },
        { lat: 10.915, lng: -60.713 },
        { lat: 10.951, lng: -60.659 },
        { lat: 10.951, lng: -60.58 },
        { lat: 10.989, lng: -60.531 },
        { lat: 10.997, lng: -60.447 },
        { lat: 11.025, lng: -60.419 },
        { lat: 11.037, lng: -60.367 },
        { lat: 11.09, lng: -60.321 },
        { lat: 11.543, lng: -60.327 },
        { lat: 11.549, lng: -60.74 },
        { lat: 11.511, lng: -60.789 },
        { lat: 11.503, lng: -60.873 },
        { lat: 11.438, lng: -60.935 },
        { lat: 11.423, lng: -60.993 },
        { lat: 11.37, lng: -61.039 },
        { lat: 11.102, lng: -61.036 },
        { lat: 11.057, lng: -61.052 },
        { lat: 11.035, lng: -61.081 },
        { lat: 11.029, lng: -61.34 },
        { lat: 10.99, lng: -61.395 },
        { lat: 10.989, lng: -61.62 },
        { lat: 10.953, lng: -61.664 },
        { lat: 10.946, lng: -61.715 },
        { lat: 10.949, lng: -62.1 },
        { lat: 10.913, lng: -62.144 },
        { lat: 10.906, lng: -62.196 },
        { lat: 10.911, lng: -62.289 },
        { lat: 10.949, lng: -62.34 },
        { lat: 10.949, lng: -63.02 },
        { lat: 10.913, lng: -63.064 },
        { lat: 10.906, lng: -63.118 },
        { lat: 10.915, lng: -63.559 },
        { lat: 10.981, lng: -63.604 },
        { lat: 11.21, lng: -63.601 },
        { lat: 11.259, lng: -63.639 },
        { lat: 11.343, lng: -63.647 },
        { lat: 11.389, lng: -63.7 },
        { lat: 11.389, lng: -64.06 },
        { lat: 11.318, lng: -64.135 },
        { lat: 11.27, lng: -64.235 },
        { lat: 11.269, lng: -64.54 },
        { lat: 11.21, lng: -64.599 },
        { lat: 10.77, lng: -64.599 },
        { lat: 10.718, lng: -64.529 },
        { lat: 10.655, lng: -64.506 },
        { lat: 10.615, lng: -64.513 },
        { lat: 10.589, lng: -64.544 },
        { lat: 10.583, lng: -64.593 },
        { lat: 10.529, lng: -64.618 },
        { lat: 10.475, lng: -64.701 },
        { lat: 10.469, lng: -64.78 },
        { lat: 10.431, lng: -64.829 },
        { lat: 10.423, lng: -64.913 },
        { lat: 10.349, lng: -64.926 },
        { lat: 10.317, lng: -64.955 },
        { lat: 10.309, lng: -65.12 },
        { lat: 10.349, lng: -65.18 },
        { lat: 10.351, lng: -65.331 },
        { lat: 10.463, lng: -65.527 },
        { lat: 10.471, lng: -65.609 },
        { lat: 10.503, lng: -65.647 },
        { lat: 10.518, lng: -65.705 },
        { lat: 10.622, lng: -65.831 },
        { lat: 10.783, lng: -65.847 },
        { lat: 10.791, lng: -65.931 },
        { lat: 10.829, lng: -65.98 },
        { lat: 10.826, lng: -66.365 },
        { lat: 10.833, lng: -66.416 },
        { lat: 10.869, lng: -66.46 },
        { lat: 10.829, lng: -66.52 },
        { lat: 10.829, lng: -67.02 },
        { lat: 10.79, lng: -67.075 },
        { lat: 10.789, lng: -67.3 },
        { lat: 10.749, lng: -67.36 },
        { lat: 10.749, lng: -67.66 },
        { lat: 10.713, lng: -67.704 },
        { lat: 10.706, lng: -67.757 },
        { lat: 10.716, lng: -67.981 },
        { lat: 10.789, lng: -68.041 },
        { lat: 11.09, lng: -68.041 },
        { lat: 11.131, lng: -68.075 },
        { lat: 11.183, lng: -68.087 },
        { lat: 11.249, lng: -68.154 },
        { lat: 11.383, lng: -68.207 },
        { lat: 11.396, lng: -68.261 },
        { lat: 11.463, lng: -68.327 },
        { lat: 11.478, lng: -68.385 },
        { lat: 11.543, lng: -68.447 },
        { lat: 11.558, lng: -68.545 },
        { lat: 11.629, lng: -68.62 },
        { lat: 11.63, lng: -68.805 },
        { lat: 11.709, lng: -68.94 },
        { lat: 11.71, lng: -69.045 },
        { lat: 11.749, lng: -69.1 },
        { lat: 11.746, lng: -69.444 },
        { lat: 11.772, lng: -69.504 },
        { lat: 11.939, lng: -69.599 },
        { lat: 12.09, lng: -69.601 },
        { lat: 12.135, lng: -69.637 },
        { lat: 12.25, lng: -69.641 },
        { lat: 12.305, lng: -69.68 },
        { lat: 12.65, lng: -69.681 },
        { lat: 12.829, lng: -69.86 },
        { lat: 12.823, lng: -70.233 },
        { lat: 12.625, lng: -70.24 },
        { lat: 12.57, lng: -70.279 },
        { lat: 12.375, lng: -70.283 },
        { lat: 12.318, lng: -70.335 },
        { lat: 12.303, lng: -70.393 },
        { lat: 12.245, lng: -70.408 },
        { lat: 12.17, lng: -70.479 },
        { lat: 11.91, lng: -70.479 },
        { lat: 11.85, lng: -70.519 },
        { lat: 11.69, lng: -70.519 },
        { lat: 11.626, lng: -70.479 },
        { lat: 11.556, lng: -70.496 },
        { lat: 11.511, lng: -70.551 },
        { lat: 11.503, lng: -70.673 },
        { lat: 11.431, lng: -70.751 },
        { lat: 11.429, lng: -70.98 },
        { lat: 11.391, lng: -71.029 },
        { lat: 11.389, lng: -71.1 },
        { lat: 11.235, lng: -71.341 },
        { lat: 11.221, lng: -71.441 },
        { lat: 11.32, lng: -71.593 },
        { lat: 11.4, lng: -71.611 },
        { lat: 11.469, lng: -71.531 },
        { lat: 11.471, lng: -71.42 },
        { lat: 11.509, lng: -71.371 },
        { lat: 11.511, lng: -71.26 },
        { lat: 11.545, lng: -71.219 },
        { lat: 11.557, lng: -71.167 },
        { lat: 11.597, lng: -71.127 },
        { lat: 11.702, lng: -71.107 },
        { lat: 11.744, lng: -71.061 },
        { lat: 11.757, lng: -71.007 },
        { lat: 11.837, lng: -70.927 },
        { lat: 12.33, lng: -70.921 },
        { lat: 12.549, lng: -71.06 },
        { lat: 12.551, lng: -71.131 },
        { lat: 12.589, lng: -71.18 },
        { lat: 12.59, lng: -71.245 },
        { lat: 12.629, lng: -71.3 },
        { lat: 12.633, lng: -71.375 },
        { lat: 12.669, lng: -71.42 },
        { lat: 12.669, lng: -71.9 },
        { lat: 12.598, lng: -71.975 },
        { lat: 12.583, lng: -72.033 },
        { lat: 12.516, lng: -72.099 },
        { lat: 12.423, lng: -72.353 },
        { lat: 12.259, lng: -72.361 },
        { lat: 12.082, lng: -72.467 },
        { lat: 11.993, lng: -72.625 },
        { lat: 11.989, lng: -72.74 },
        { lat: 11.913, lng: -72.865 },
        { lat: 11.903, lng: -72.953 },
        { lat: 11.838, lng: -73.015 },
        { lat: 11.823, lng: -73.073 },
        { lat: 11.718, lng: -73.175 },
        { lat: 11.703, lng: -73.233 },
        { lat: 11.636, lng: -73.299 },
        { lat: 11.623, lng: -73.353 },
        { lat: 11.473, lng: -73.504 },
        { lat: 11.471, lng: -73.649 },
        { lat: 11.549, lng: -73.78 },
        { lat: 11.549, lng: -74.34 },
        { lat: 11.463, lng: -74.433 },
        { lat: 11.333, lng: -74.438 },
        { lat: 11.274, lng: -74.484 },
        { lat: 11.273, lng: -74.535 },
        { lat: 11.309, lng: -74.58 },
        { lat: 11.309, lng: -74.96 },
        { lat: 11.343, lng: -75.033 },
        { lat: 11.081, lng: -75.291 },
        { lat: 11.023, lng: -75.433 },
        { lat: 10.926, lng: -75.502 },
        { lat: 10.783, lng: -75.713 },
        { lat: 10.699, lng: -75.721 },
        { lat: 10.65, lng: -75.759 },
        { lat: 10.534, lng: -75.763 },
        { lat: 10.503, lng: -75.793 },
        { lat: 10.442, lng: -75.81 },
        { lat: 10.33, lng: -75.919 },
        { lat: 9.929, lng: -75.907 },
        { lat: 9.704, lng: -75.916 },
        { lat: 9.654, lng: -75.934 },
        { lat: 9.626, lng: -75.992 },
        { lat: 9.629, lng: -76.14 },
        { lat: 9.558, lng: -76.215 },
        { lat: 9.543, lng: -76.273 },
        { lat: 9.463, lng: -76.353 },
        { lat: 9.411, lng: -76.365 },
        { lat: 9.168, lng: -76.499 },
        { lat: 9.115, lng: -76.581 },
        { lat: 9.103, lng: -76.633 },
        { lat: 8.961, lng: -76.771 },
        { lat: 8.943, lng: -76.833 },
        { lat: 8.882, lng: -76.867 },
        { lat: 8.869, lng: -76.94 },
        { lat: 8.833, lng: -76.985 },
        { lat: 8.818, lng: -77.11 },
        { lat: 8.869, lng: -77.18 },
        { lat: 8.882, lng: -77.293 },
        { lat: 8.943, lng: -77.327 },
        { lat: 9.023, lng: -77.407 },
        { lat: 9.036, lng: -77.461 },
        { lat: 9.081, lng: -77.509 },
        { lat: 9.223, lng: -77.567 },
        { lat: 9.303, lng: -77.647 },
        { lat: 9.321, lng: -77.709 },
        { lat: 9.423, lng: -77.807 },
        { lat: 9.431, lng: -77.891 },
        { lat: 9.469, lng: -77.94 },
        { lat: 9.471, lng: -78.011 },
        { lat: 9.509, lng: -78.06 },
        { lat: 9.513, lng: -78.135 },
        { lat: 9.629, lng: -78.34 },
        { lat: 9.635, lng: -78.719 },
        { lat: 9.662, lng: -78.751 },
        { lat: 9.749, lng: -78.78 },
        { lat: 9.749, lng: -79.161 },
        { lat: 9.789, lng: -79.22 },
        { lat: 9.793, lng: -79.296 },
        { lat: 9.829, lng: -79.34 },
        { lat: 9.823, lng: -79.753 },
        { lat: 9.743, lng: -79.873 },
        { lat: 9.689, lng: -79.886 },
        { lat: 9.64, lng: -79.932 },
        { lat: 9.583, lng: -80.073 },
        { lat: 9.553, lng: -80.105 },
        { lat: 9.543, lng: -80.193 },
        { lat: 9.396, lng: -80.339 },
        { lat: 9.389, lng: -80.42 },
        { lat: 9.35, lng: -80.475 },
        { lat: 9.349, lng: -80.58 },
        { lat: 9.31, lng: -80.635 },
        { lat: 9.309, lng: -80.7 },
        { lat: 9.195, lng: -80.901 },
        { lat: 9.183, lng: -80.953 },
        { lat: 9.075, lng: -81.061 },
        { lat: 9.063, lng: -81.113 },
        { lat: 9.031, lng: -81.151 },
        { lat: 9.004, lng: -81.293 },
        { lat: 9.046, lng: -81.394 },
        { lat: 9.089, lng: -81.434 },
        { lat: 9.143, lng: -81.447 },
        { lat: 9.383, lng: -81.687 },
        { lat: 9.399, lng: -81.848 },
        { lat: 9.43, lng: -81.875 },
        { lat: 9.503, lng: -81.887 },
        { lat: 9.543, lng: -81.927 },
        { lat: 9.562, lng: -82.013 },
        { lat: 9.623, lng: -82.047 },
        { lat: 9.633, lng: -82.216 },
        { lat: 9.823, lng: -82.407 },
        { lat: 9.846, lng: -82.554 },
        { lat: 9.889, lng: -82.594 },
        { lat: 9.943, lng: -82.607 },
        { lat: 9.956, lng: -82.661 },
        { lat: 10.002, lng: -82.71 },
        { lat: 10.063, lng: -82.727 },
        { lat: 10.129, lng: -82.794 },
        { lat: 10.183, lng: -82.807 },
        { lat: 10.196, lng: -82.861 },
        { lat: 10.238, lng: -82.911 },
        { lat: 10.398, lng: -83.067 },
        { lat: 10.463, lng: -83.087 },
        { lat: 10.562, lng: -83.19 },
        { lat: 10.623, lng: -83.207 },
        { lat: 10.695, lng: -83.277 },
        { lat: 10.77, lng: -83.281 },
        { lat: 10.895, lng: -83.357 },
        { lat: 10.97, lng: -83.361 },
        { lat: 11.019, lng: -83.399 },
        { lat: 11.103, lng: -83.407 },
        { lat: 11.143, lng: -83.447 },
        { lat: 11.164, lng: -83.536 },
        { lat: 11.233, lng: -83.57 },
        { lat: 11.338, lng: -83.55 },
        { lat: 11.41, lng: -83.441 },
        { lat: 11.85, lng: -83.441 },
        { lat: 11.901, lng: -83.479 },
        { lat: 11.991, lng: -83.484 },
        { lat: 12.045, lng: -83.477 },
        { lat: 12.077, lng: -83.447 },
        { lat: 12.139, lng: -83.429 },
        { lat: 12.19, lng: -83.365 },
        { lat: 12.174, lng: -83.262 },
        { lat: 12.117, lng: -83.236 },
        { lat: 11.97, lng: -83.239 },
        { lat: 11.951, lng: -83.22 },
        { lat: 11.951, lng: -82.86 },
        { lat: 11.97, lng: -82.841 },
        { lat: 12.119, lng: -82.839 },
        { lat: 12.17, lng: -82.801 },
        { lat: 12.383, lng: -82.807 },
        { lat: 12.386, lng: -83.204 },
        { lat: 12.412, lng: -83.264 },
        { lat: 12.466, lng: -83.284 },
        { lat: 12.69, lng: -83.281 },
        { lat: 12.734, lng: -83.317 },
        { lat: 12.787, lng: -83.324 },
        { lat: 13.47, lng: -83.321 },
        { lat: 13.53, lng: -83.281 },
        { lat: 13.606, lng: -83.277 },
        { lat: 13.65, lng: -83.241 },
        { lat: 13.771, lng: -83.234 },
        { lat: 13.917, lng: -83.087 },
        { lat: 13.975, lng: -83.072 },
        { lat: 14.037, lng: -83.007 },
        { lat: 14.109, lng: -82.995 },
        { lat: 14.141, lng: -82.968 },
        { lat: 14.154, lng: -82.922 },
        { lat: 14.151, lng: -82.54 },
        { lat: 14.17, lng: -82.521 },
        { lat: 14.623, lng: -82.527 },
        { lat: 14.629, lng: -82.96 },
        { lat: 14.674, lng: -83.011 },
        { lat: 14.729, lng: -83.006 },
        { lat: 14.81, lng: -82.921 },
        { lat: 15.183, lng: -82.927 },
        { lat: 15.223, lng: -82.967 },
        { lat: 15.246, lng: -83.074 },
        { lat: 15.295, lng: -83.117 },
        { lat: 15.383, lng: -83.127 },
        { lat: 15.463, lng: -83.207 },
        { lat: 15.555, lng: -83.419 },
        { lat: 15.583, lng: -83.447 },
        { lat: 15.591, lng: -83.529 },
        { lat: 15.623, lng: -83.567 },
        { lat: 15.638, lng: -83.625 },
        { lat: 15.823, lng: -83.807 },
        { lat: 15.841, lng: -83.869 },
        { lat: 16.029, lng: -84.06 },
        { lat: 16.029, lng: -84.24 },
        { lat: 16.069, lng: -84.3 },
        { lat: 16.07, lng: -84.445 },
        { lat: 16.109, lng: -84.5 },
        { lat: 16.113, lng: -84.576 },
        { lat: 16.149, lng: -84.62 },
        { lat: 16.153, lng: -84.695 },
        { lat: 16.189, lng: -84.74 },
        { lat: 16.189, lng: -85.22 },
        { lat: 16.153, lng: -85.264 },
        { lat: 16.122, lng: -85.361 },
        { lat: 16.149, lng: -85.42 },
        { lat: 16.153, lng: -85.495 },
        { lat: 16.189, lng: -85.54 },
        { lat: 16.196, lng: -85.621 },
        { lat: 16.245, lng: -85.652 },
        { lat: 16.69, lng: -85.641 },
        { lat: 16.709, lng: -85.66 },
        { lat: 16.709, lng: -86.06 },
        { lat: 16.638, lng: -86.135 },
        { lat: 16.626, lng: -86.194 },
        { lat: 16.623, lng: -86.633 },
        { lat: 16.595, lng: -86.661 },
        { lat: 16.543, lng: -86.793 },
        { lat: 16.503, lng: -86.833 },
        { lat: 16.379, lng: -86.841 },
        { lat: 16.316, lng: -86.899 },
        { lat: 16.303, lng: -87.193 },
        { lat: 16.095, lng: -87.197 },
        { lat: 16.049, lng: -87.218 },
        { lat: 16.031, lng: -87.257 },
        { lat: 16.035, lng: -87.299 },
        { lat: 16.109, lng: -87.42 },
        { lat: 16.109, lng: -88.02 },
        { lat: 16.073, lng: -88.065 },
        { lat: 16.069, lng: -88.14 },
        { lat: 16.042, lng: -88.199 },
        { lat: 16.099, lng: -88.285 },
        { lat: 16.169, lng: -88.281 },
        { lat: 16.237, lng: -88.207 },
        { lat: 16.295, lng: -88.192 },
        { lat: 16.357, lng: -88.127 },
        { lat: 16.481, lng: -88.119 },
        { lat: 16.61, lng: -88.041 },
        { lat: 16.715, lng: -88.04 },
        { lat: 16.77, lng: -88.001 },
        { lat: 16.993, lng: -88.004 },
        { lat: 17.043, lng: -87.988 },
        { lat: 17.074, lng: -87.926 },
        { lat: 17.071, lng: -87.66 },
        { lat: 17.13, lng: -87.601 },
        { lat: 17.27, lng: -87.601 },
        { lat: 17.33, lng: -87.561 },
        { lat: 17.45, lng: -87.561 },
        { lat: 17.509, lng: -87.601 },
        { lat: 17.73, lng: -87.601 },
        { lat: 17.757, lng: -87.67 },
        { lat: 17.802, lng: -87.695 },
        { lat: 17.886, lng: -87.677 },
        { lat: 17.93, lng: -87.641 },
        { lat: 18.091, lng: -87.634 },
        { lat: 18.171, lng: -87.582 },
        { lat: 18.193, lng: -87.534 },
        { lat: 18.197, lng: -87.167 },
        { lat: 18.25, lng: -87.121 },
        { lat: 18.395, lng: -87.113 },
        { lat: 18.45, lng: -87.041 },
        { lat: 18.903, lng: -87.047 },
        { lat: 18.91, lng: -87.285 },
        { lat: 18.962, lng: -87.332 },
        { lat: 19.045, lng: -87.317 },
        { lat: 19.09, lng: -87.281 },
        { lat: 19.165, lng: -87.277 },
        { lat: 19.21, lng: -87.241 },
        { lat: 19.321, lng: -87.239 },
        { lat: 19.37, lng: -87.201 },
        { lat: 19.77, lng: -87.201 },
        { lat: 19.829, lng: -87.241 },
        { lat: 20.005, lng: -87.237 },
        { lat: 20.071, lng: -87.161 },
        { lat: 20.077, lng: -86.767 },
        { lat: 20.197, lng: -86.647 },
        { lat: 20.255, lng: -86.632 },
        { lat: 20.317, lng: -86.567 },
        { lat: 20.369, lng: -86.555 },
        { lat: 20.41, lng: -86.521 },
        { lat: 20.77, lng: -86.521 },
        { lat: 20.808, lng: -86.564 },
        { lat: 20.846, lng: -86.576 },
        { lat: 20.961, lng: -86.559 },
        { lat: 21.01, lng: -86.521 },
        { lat: 21.155, lng: -86.52 },
        { lat: 21.21, lng: -86.481 },
        { lat: 21.37, lng: -86.481 },
        { lat: 21.411, lng: -86.515 },
        { lat: 21.463, lng: -86.527 },
        { lat: 21.505, lng: -86.593 },
        { lat: 21.623, lng: -86.607 },
        { lat: 21.641, lng: -86.709 },
        { lat: 21.689, lng: -86.754 },
        { lat: 21.743, lng: -86.767 },
        { lat: 21.823, lng: -86.847 },
        { lat: 21.829, lng: -87.3 },
        { lat: 21.793, lng: -87.344 },
        { lat: 21.789, lng: -87.42 },
        { lat: 21.751, lng: -87.471 },
        { lat: 21.746, lng: -87.6 },
        { lat: 21.753, lng: -87.656 },
        { lat: 21.789, lng: -87.7 },
        { lat: 21.79, lng: -87.885 },
        { lat: 21.829, lng: -87.94 },
        { lat: 21.829, lng: -88.3 },
        { lat: 21.793, lng: -88.344 },
        { lat: 21.789, lng: -88.42 },
        { lat: 21.749, lng: -88.479 },
        { lat: 21.749, lng: -88.78 },
        { lat: 21.678, lng: -88.855 },
        { lat: 21.663, lng: -88.913 },
        { lat: 21.635, lng: -88.941 },
        { lat: 21.629, lng: -89.06 },
        { lat: 21.59, lng: -89.115 },
        { lat: 21.589, lng: -89.26 },
        { lat: 21.549, lng: -89.32 },
        { lat: 21.549, lng: -89.54 },
        { lat: 21.509, lng: -89.6 },
        { lat: 21.509, lng: -89.9 },
        { lat: 21.471, lng: -89.949 },
        { lat: 21.469, lng: -90.06 },
        { lat: 21.393, lng: -90.185 },
        { lat: 21.383, lng: -90.273 },
        { lat: 21.355, lng: -90.301 },
        { lat: 21.303, lng: -90.433 },
        { lat: 21.236, lng: -90.499 },
        { lat: 21.223, lng: -90.553 },
        { lat: 21.171, lng: -90.565 },
        { lat: 21.143, lng: -90.593 },
        { lat: 21.059, lng: -90.601 },
        { lat: 21.01, lng: -90.639 },
        { lat: 20.901, lng: -90.641 },
        { lat: 20.85, lng: -90.679 },
        { lat: 20.219, lng: -90.681 },
        { lat: 20.17, lng: -90.719 },
        { lat: 20.082, lng: -90.73 },
        { lat: 19.983, lng: -90.873 },
        { lat: 19.931, lng: -90.885 },
        { lat: 19.89, lng: -90.919 },
        { lat: 19.569, lng: -90.926 },
        { lat: 19.32, lng: -91.172 },
        { lat: 19.263, lng: -91.313 },
        { lat: 19.195, lng: -91.381 },
        { lat: 19.189, lng: -91.46 },
        { lat: 19.115, lng: -91.581 },
        { lat: 19.103, lng: -91.633 },
        { lat: 18.999, lng: -91.712 },
        { lat: 18.989, lng: -91.78 },
        { lat: 18.951, lng: -91.831 },
        { lat: 18.949, lng: -91.9 },
        { lat: 18.911, lng: -91.951 },
        { lat: 18.909, lng: -92.34 },
        { lat: 18.869, lng: -92.4 },
        { lat: 18.869, lng: -92.7 },
        { lat: 18.831, lng: -92.751 },
        { lat: 18.823, lng: -92.873 },
        { lat: 18.718, lng: -92.975 },
        { lat: 18.703, lng: -93.033 },
        { lat: 18.631, lng: -93.151 },
        { lat: 18.629, lng: -93.78 },
        { lat: 18.591, lng: -93.829 },
        { lat: 18.583, lng: -93.913 },
        { lat: 18.473, lng: -94.105 },
        { lat: 18.469, lng: -94.18 },
        { lat: 18.431, lng: -94.229 },
        { lat: 18.414, lng: -94.349 },
        { lat: 18.43, lng: -94.387 },
        { lat: 18.562, lng: -94.51 },
        { lat: 18.703, lng: -94.567 },
        { lat: 18.711, lng: -94.651 },
        { lat: 18.749, lng: -94.7 },
        { lat: 18.757, lng: -94.805 },
        { lat: 18.823, lng: -94.847 },
        { lat: 18.838, lng: -94.905 },
        { lat: 18.903, lng: -94.967 },
        { lat: 18.91, lng: -95.405 },
        { lat: 18.983, lng: -95.487 },
        { lat: 18.991, lng: -95.569 },
        { lat: 19.023, lng: -95.607 },
        { lat: 19.038, lng: -95.665 },
        { lat: 19.085, lng: -95.712 },
        { lat: 19.143, lng: -95.727 },
        { lat: 19.174, lng: -95.757 },
        { lat: 19.263, lng: -95.767 },
        { lat: 19.286, lng: -95.858 },
        { lat: 19.371, lng: -95.915 },
        { lat: 19.423, lng: -95.927 },
        { lat: 19.44, lng: -95.988 },
        { lat: 19.561, lng: -96.109 },
        { lat: 19.69, lng: -96.121 },
        { lat: 19.819, lng: -96.199 },
        { lat: 19.97, lng: -96.201 },
        { lat: 20.011, lng: -96.235 },
        { lat: 20.063, lng: -96.247 },
        { lat: 20.125, lng: -96.312 },
        { lat: 20.183, lng: -96.327 },
        { lat: 20.322, lng: -96.47 },
        { lat: 20.383, lng: -96.487 },
        { lat: 20.849, lng: -96.954 },
        { lat: 20.903, lng: -96.967 },
        { lat: 20.941, lng: -96.999 },
        { lat: 21.023, lng: -97.007 },
        { lat: 21.225, lng: -97.12 },
        { lat: 21.783, lng: -97.127 },
        { lat: 21.863, lng: -97.207 },
        { lat: 21.883, lng: -97.272 },
        { lat: 22.038, lng: -97.427 },
        { lat: 22.263, lng: -97.527 },
        { lat: 22.299, lng: -97.559 },
        { lat: 22.45, lng: -97.561 },
        { lat: 22.494, lng: -97.597 },
        { lat: 22.545, lng: -97.601 },
        { lat: 22.61, lng: -97.561 },
        { lat: 22.721, lng: -97.559 },
        { lat: 22.77, lng: -97.521 },
        { lat: 22.97, lng: -97.521 },
        { lat: 23.014, lng: -97.557 },
        { lat: 23.065, lng: -97.564 },
        { lat: 23.272, lng: -97.564 },
        { lat: 23.326, lng: -97.557 },
        { lat: 23.37, lng: -97.521 },
        { lat: 23.873, lng: -97.524 },
        { lat: 23.926, lng: -97.517 },
        { lat: 23.97, lng: -97.481 },
        { lat: 24.195, lng: -97.48 },
        { lat: 24.25, lng: -97.441 },
        { lat: 24.395, lng: -97.44 },
        { lat: 24.45, lng: -97.401 },
        { lat: 24.526, lng: -97.397 },
        { lat: 24.57, lng: -97.361 },
        { lat: 24.675, lng: -97.36 },
        { lat: 24.73, lng: -97.321 },
        { lat: 24.806, lng: -97.317 },
        { lat: 24.85, lng: -97.281 },
        { lat: 24.921, lng: -97.279 },
        { lat: 25.037, lng: -97.207 },
        { lat: 25.095, lng: -97.192 },
        { lat: 25.157, lng: -97.127 },
        { lat: 25.369, lng: -97.035 },
        { lat: 25.397, lng: -97.007 },
        { lat: 25.481, lng: -96.999 },
        { lat: 25.53, lng: -96.961 },
        { lat: 25.715, lng: -96.96 },
        { lat: 25.77, lng: -96.921 },
        { lat: 26.01, lng: -96.921 },
        { lat: 26.054, lng: -96.957 },
        { lat: 26.105, lng: -96.964 },
        { lat: 26.33, lng: -96.961 },
        { lat: 26.385, lng: -97.0 },
        { lat: 26.53, lng: -97.001 },
        { lat: 26.585, lng: -97.04 },
        { lat: 26.69, lng: -97.041 },
        { lat: 26.735, lng: -97.077 },
        { lat: 26.81, lng: -97.081 },
        { lat: 26.855, lng: -97.117 },
        { lat: 26.93, lng: -97.121 },
        { lat: 26.974, lng: -97.157 },
        { lat: 27.027, lng: -97.164 },
        { lat: 27.125, lng: -97.157 },
        { lat: 27.397, lng: -97.007 },
        { lat: 27.451, lng: -96.994 },
        { lat: 27.557, lng: -96.887 },
        { lat: 27.615, lng: -96.872 },
        { lat: 27.717, lng: -96.767 },
        { lat: 27.771, lng: -96.754 },
        { lat: 27.823, lng: -96.709 },
        { lat: 27.865, lng: -96.659 },
        { lat: 27.877, lng: -96.607 },
        { lat: 27.982, lng: -96.505 },
        { lat: 28.037, lng: -96.367 },
        { lat: 28.104, lng: -96.301 },
        { lat: 28.117, lng: -96.207 },
        { lat: 28.175, lng: -96.192 },
        { lat: 28.222, lng: -96.145 },
        { lat: 28.357, lng: -95.847 },
        { lat: 28.387, lng: -95.816 },
        { lat: 28.397, lng: -95.727 },
        { lat: 28.425, lng: -95.699 },
        { lat: 28.677, lng: -95.167 },
        { lat: 28.78, lng: -95.068 },
        { lat: 28.797, lng: -95.007 },
        { lat: 28.898, lng: -94.933 },
        { lat: 28.985, lng: -94.779 },
        { lat: 28.997, lng: -94.727 },
        { lat: 29.064, lng: -94.661 },
        { lat: 29.077, lng: -94.607 },
        { lat: 29.117, lng: -94.567 },
        { lat: 29.178, lng: -94.55 },
        { lat: 29.225, lng: -94.499 },
        { lat: 29.237, lng: -94.447 },
        { lat: 29.307, lng: -94.335 },
        { lat: 29.317, lng: -94.247 },
        { lat: 29.389, lng: -94.131 },
        { lat: 29.391, lng: -94.06 },
        { lat: 29.427, lng: -94.016 },
        { lat: 29.431, lng: -93.94 },
        { lat: 29.47, lng: -93.885 },
        { lat: 29.471, lng: -93.7 },
        { lat: 29.551, lng: -93.561 },
        { lat: 29.55, lng: -93.275 },
        { lat: 29.511, lng: -93.22 },
        { lat: 29.505, lng: -93.141 },
        { lat: 29.391, lng: -92.94 },
        { lat: 29.39, lng: -92.795 },
        { lat: 29.351, lng: -92.74 },
        { lat: 29.354, lng: -92.195 },
        { lat: 29.345, lng: -92.141 },
        { lat: 29.271, lng: -92.02 },
        { lat: 29.269, lng: -91.591 },
        { lat: 29.209, lng: -91.525 },
        { lat: 29.09, lng: -91.519 },
        { lat: 29.037, lng: -91.473 },
        { lat: 29.029, lng: -91.351 },
        { lat: 28.991, lng: -91.3 },
        { lat: 28.981, lng: -91.152 },
        { lat: 28.949, lng: -91.125 },
        { lat: 28.85, lng: -91.119 },
        { lat: 28.831, lng: -91.1 },
        { lat: 28.831, lng: -90.26 },
        { lat: 28.87, lng: -90.205 },
        { lat: 28.871, lng: -90.06 },
        { lat: 28.909, lng: -90.011 },
        { lat: 28.911, lng: -89.94 },
        { lat: 29.022, lng: -89.825 },
        { lat: 29.034, lng: -89.756 },
        { lat: 29.022, lng: -89.695 },
        { lat: 28.935, lng: -89.608 },
        { lat: 28.77, lng: -89.599 },
        { lat: 28.717, lng: -89.553 },
        { lat: 28.711, lng: -89.22 },
        { lat: 28.787, lng: -89.136 },
        { lat: 28.791, lng: -88.98 },
        { lat: 28.862, lng: -88.905 },
        { lat: 28.877, lng: -88.847 },
        { lat: 29.111, lng: -88.841 },
        { lat: 29.17, lng: -88.801 },
        { lat: 29.399, lng: -88.799 },
        { lat: 29.517, lng: -88.687 },
        { lat: 29.575, lng: -88.672 },
        { lat: 29.637, lng: -88.607 },
        { lat: 29.923, lng: -88.603 },
        { lat: 29.972, lng: -88.581 },
        { lat: 29.993, lng: -88.533 },
        { lat: 29.991, lng: -88.3 },
        { lat: 30.027, lng: -88.256 },
        { lat: 30.034, lng: -88.202 },
        { lat: 30.031, lng: -87.46 },
        { lat: 30.067, lng: -87.415 },
        { lat: 30.071, lng: -87.3 },
        { lat: 30.11, lng: -87.245 },
        { lat: 30.111, lng: -86.98 },
        { lat: 30.151, lng: -86.92 },
        { lat: 30.151, lng: -86.62 },
        { lat: 30.178, lng: -86.559 },
        { lat: 30.151, lng: -86.5 },
        { lat: 30.15, lng: -86.395 },
        { lat: 30.111, lng: -86.34 },
        { lat: 30.105, lng: -86.261 },
        { lat: 29.957, lng: -85.993 },
        { lat: 29.944, lng: -85.899 },
        { lat: 29.877, lng: -85.833 },
        { lat: 29.851, lng: -85.778 },
        { lat: 29.797, lng: -85.753 },
        { lat: 29.736, lng: -85.624 },
        { lat: 29.678, lng: -85.596 },
        { lat: 29.53, lng: -85.599 },
        { lat: 29.477, lng: -85.553 },
        { lat: 29.47, lng: -85.395 },
        { lat: 29.431, lng: -85.34 },
        { lat: 29.427, lng: -85.264 },
        { lat: 29.391, lng: -85.22 },
        { lat: 29.391, lng: -84.78 },
        { lat: 29.505, lng: -84.579 },
        { lat: 29.517, lng: -84.527 },
        { lat: 29.579, lng: -84.469 },
        { lat: 29.597, lng: -84.407 },
        { lat: 29.665, lng: -84.339 },
        { lat: 29.677, lng: -84.207 },
        { lat: 29.796, lng: -84.129 },
        { lat: 29.787, lng: -83.985 },
        { lat: 29.702, lng: -83.853 },
        { lat: 29.637, lng: -83.833 },
        { lat: 29.571, lng: -83.766 },
        { lat: 29.517, lng: -83.753 },
        { lat: 29.505, lng: -83.701 },
        { lat: 29.448, lng: -83.616 },
        { lat: 29.317, lng: -83.593 },
        { lat: 29.277, lng: -83.553 },
        { lat: 29.26, lng: -83.492 },
        { lat: 29.179, lng: -83.406 },
        { lat: 29.129, lng: -83.365 },
        { lat: 29.077, lng: -83.353 },
        { lat: 29.043, lng: -83.292 },
        { lat: 28.957, lng: -83.273 },
        { lat: 28.917, lng: -83.233 },
        { lat: 28.91, lng: -83.035 },
        { lat: 28.851, lng: -82.966 },
        { lat: 28.797, lng: -82.953 },
        { lat: 28.761, lng: -82.921 },
        { lat: 28.69, lng: -82.919 },
        { lat: 28.629, lng: -82.892 },
        { lat: 28.57, lng: -82.919 },
        { lat: 28.495, lng: -82.923 },
        { lat: 28.37, lng: -82.999 },
        { lat: 28.149, lng: -82.999 },
        { lat: 28.09, lng: -83.039 },
        { lat: 27.61, lng: -83.039 },
        { lat: 27.535, lng: -82.968 },
        { lat: 27.477, lng: -82.953 },
        { lat: 27.445, lng: -82.923 },
        { lat: 27.277, lng: -82.913 },
        { lat: 27.249, lng: -82.885 },
        { lat: 27.197, lng: -82.873 },
        { lat: 27.099, lng: -82.771 },
        { lat: 27.037, lng: -82.753 },
        { lat: 26.975, lng: -82.688 },
        { lat: 26.877, lng: -82.673 },
        { lat: 26.819, lng: -82.611 },
        { lat: 26.757, lng: -82.593 },
        { lat: 26.689, lng: -82.525 },
        { lat: 26.637, lng: -82.513 },
        { lat: 26.599, lng: -82.481 },
        { lat: 26.517, lng: -82.473 },
        { lat: 26.401, lng: -82.401 },
        { lat: 26.277, lng: -82.393 },
        { lat: 26.231, lng: -82.34 },
        { lat: 26.234, lng: -82.116 },
        { lat: 26.212, lng: -82.059 },
        { lat: 26.156, lng: -82.036 },
        { lat: 26.01, lng: -82.039 },
        { lat: 25.961, lng: -82.001 },
        { lat: 25.877, lng: -81.993 },
        { lat: 25.765, lng: -81.923 },
        { lat: 25.677, lng: -81.913 },
        { lat: 25.669, lng: -81.751 },
        { lat: 25.597, lng: -81.633 },
        { lat: 25.582, lng: -81.575 },
        { lat: 25.495, lng: -81.488 },
        { lat: 25.437, lng: -81.473 },
        { lat: 25.319, lng: -81.401 },
        { lat: 25.17, lng: -81.399 },
        { lat: 25.126, lng: -81.363 },
        { lat: 25.074, lng: -81.356 },
        { lat: 24.968, lng: -81.379 },
        { lat: 24.946, lng: -81.434 },
        { lat: 24.949, lng: -81.58 },
        { lat: 24.911, lng: -81.629 },
        { lat: 24.909, lng: -81.7 },
        { lat: 24.79, lng: -81.835 },
        { lat: 24.783, lng: -82.313 },
        { lat: 24.625, lng: -82.32 },
        { lat: 24.57, lng: -82.359 },
        { lat: 24.397, lng: -82.353 },
        { lat: 24.391, lng: -82.04 },
        { lat: 24.351, lng: -81.98 },
        { lat: 24.351, lng: -81.46 },
        { lat: 24.387, lng: -81.416 },
        { lat: 24.391, lng: -81.34 },
        { lat: 24.43, lng: -81.285 },
        { lat: 24.431, lng: -81.14 },
        { lat: 24.471, lng: -81.08 },
        { lat: 24.471, lng: -80.94 },
        { lat: 24.507, lng: -80.895 },
        { lat: 24.511, lng: -80.82 },
        { lat: 24.587, lng: -80.696 },
        { lat: 24.591, lng: -80.62 },
        { lat: 24.625, lng: -80.579 },
        { lat: 24.637, lng: -80.527 },
        { lat: 24.694, lng: -80.498 },
        { lat: 24.757, lng: -80.367 },
        { lat: 24.957, lng: -80.167 },
        { lat: 25.011, lng: -80.154 },
        { lat: 25.077, lng: -80.087 },
        { lat: 25.129, lng: -80.075 },
        { lat: 25.25, lng: -80.001 },
        { lat: 25.431, lng: -80.001 },
        { lat: 25.49, lng: -79.961 },
        { lat: 25.605, lng: -79.957 },
        { lat: 25.65, lng: -79.921 },
        { lat: 25.91, lng: -79.921 },
        { lat: 25.97, lng: -79.881 },
        { lat: 26.231, lng: -79.881 },
        { lat: 26.29, lng: -79.841 },
        { lat: 27.09, lng: -79.841 },
        { lat: 27.139, lng: -79.879 },
        { lat: 27.223, lng: -79.887 },
        { lat: 27.335, lng: -79.957 },
        { lat: 27.423, lng: -79.967 },
        { lat: 27.535, lng: -80.037 },
        { lat: 27.623, lng: -80.047 },
        { lat: 27.735, lng: -80.117 },
        { lat: 27.823, lng: -80.127 },
        { lat: 28.011, lng: -80.235 },
        { lat: 28.09, lng: -80.241 },
        { lat: 28.219, lng: -80.319 },
        { lat: 28.65, lng: -80.321 },
        { lat: 28.699, lng: -80.359 },
        { lat: 28.783, lng: -80.367 },
        { lat: 28.845, lng: -80.432 },
        { lat: 28.903, lng: -80.447 },
        { lat: 29.002, lng: -80.55 },
        { lat: 29.063, lng: -80.567 },
        { lat: 29.129, lng: -80.634 },
        { lat: 29.183, lng: -80.647 },
        { lat: 29.245, lng: -80.712 },
        { lat: 29.303, lng: -80.727 },
        { lat: 29.331, lng: -80.755 },
        { lat: 29.383, lng: -80.767 },
        { lat: 29.819, lng: -80.999 },
        { lat: 29.89, lng: -81.001 },
        { lat: 29.935, lng: -81.037 },
        { lat: 30.01, lng: -81.041 },
        { lat: 30.054, lng: -81.077 },
        { lat: 30.13, lng: -81.081 },
        { lat: 30.185, lng: -81.12 },
        { lat: 30.33, lng: -81.121 },
        { lat: 30.465, lng: -81.2 },
        { lat: 30.65, lng: -81.201 },
        { lat: 30.694, lng: -81.237 },
        { lat: 30.75, lng: -81.244 },
        { lat: 30.806, lng: -81.237 },
        { lat: 30.85, lng: -81.201 },
        { lat: 30.926, lng: -81.197 },
        { lat: 31.037, lng: -81.087 },
        { lat: 31.161, lng: -81.079 },
        { lat: 31.197, lng: -81.047 },
        { lat: 31.258, lng: -81.03 },
        { lat: 31.317, lng: -80.967 },
        { lat: 31.395, lng: -80.96 },
        { lat: 31.45, lng: -80.921 },
        { lat: 31.535, lng: -80.912 },
        { lat: 31.597, lng: -80.847 },
        { lat: 31.656, lng: -80.816 },
        { lat: 31.677, lng: -80.727 },
        { lat: 31.735, lng: -80.712 },
        { lat: 31.797, lng: -80.647 },
        { lat: 31.891, lng: -80.622 },
        { lat: 31.917, lng: -80.567 },
        { lat: 31.997, lng: -80.487 },
        { lat: 32.058, lng: -80.453 },
        { lat: 32.077, lng: -80.327 },
        { lat: 32.157, lng: -80.247 },
        { lat: 32.229, lng: -80.235 },
        { lat: 32.26, lng: -80.21 },
        { lat: 32.277, lng: -80.127 },
        { lat: 32.389, lng: -79.931 },
        { lat: 32.397, lng: -79.807 },
        { lat: 32.462, lng: -79.745 },
        { lat: 32.477, lng: -79.687 },
        { lat: 32.538, lng: -79.653 },
        { lat: 32.597, lng: -79.527 },
        { lat: 32.702, lng: -79.425 },
        { lat: 32.717, lng: -79.367 },
        { lat: 32.78, lng: -79.33 },
        { lat: 32.797, lng: -79.167 },
        { lat: 32.851, lng: -79.154 },
        { lat: 32.894, lng: -79.114 },
        { lat: 32.917, lng: -79.047 },
        { lat: 32.957, lng: -79.007 },
        { lat: 33.009, lng: -78.995 },
        { lat: 33.05, lng: -78.961 },
        { lat: 33.161, lng: -78.959 },
        { lat: 33.197, lng: -78.927 },
        { lat: 33.258, lng: -78.91 },
        { lat: 33.542, lng: -78.625 },
        { lat: 33.637, lng: -78.407 },
        { lat: 33.667, lng: -78.375 },
        { lat: 33.682, lng: -78.267 },
        { lat: 33.667, lng: -78.185 },
        { lat: 33.631, lng: -78.14 },
        { lat: 33.637, lng: -77.767 },
        { lat: 33.726, lng: -77.757 },
        { lat: 33.797, lng: -77.687 },
        { lat: 33.886, lng: -77.677 },
        { lat: 34.099, lng: -77.554 },
        { lat: 34.145, lng: -77.499 },
        { lat: 34.197, lng: -77.367 },
        { lat: 34.265, lng: -77.299 },
        { lat: 34.277, lng: -77.207 },
        { lat: 34.384, lng: -77.101 },
        { lat: 34.43, lng: -77.005 },
        { lat: 34.431, lng: -76.9 },
        { lat: 34.471, lng: -76.833 },
        { lat: 34.462, lng: -76.775 },
        { lat: 34.391, lng: -76.7 },
        { lat: 34.391, lng: -76.34 },
        { lat: 34.437, lng: -76.287 },
        { lat: 34.499, lng: -76.269 },
        { lat: 34.622, lng: -76.151 },
        { lat: 34.717, lng: -76.007 },
        { lat: 34.818, lng: -75.973 },
        { lat: 34.837, lng: -75.847 },
        { lat: 34.9, lng: -75.788 },
        { lat: 34.917, lng: -75.727 },
        { lat: 34.949, lng: -75.691 },
        { lat: 34.951, lng: -75.62 },
        { lat: 34.989, lng: -75.569 },
        { lat: 34.991, lng: -75.46 },
        { lat: 35.029, lng: -75.411 },
        { lat: 35.037, lng: -75.327 },
        { lat: 35.089, lng: -75.315 },
        { lat: 35.13, lng: -75.281 },
        { lat: 35.315, lng: -75.28 },
        { lat: 35.37, lng: -75.241 },
        { lat: 35.81, lng: -75.241 },
        { lat: 35.854, lng: -75.277 },
        { lat: 35.93, lng: -75.281 },
        { lat: 36.131, lng: -75.395 },
        { lat: 36.183, lng: -75.407 },
        { lat: 36.245, lng: -75.472 },
        { lat: 36.463, lng: -75.567 },
        { lat: 36.495, lng: -75.597 },
        { lat: 36.61, lng: -75.601 },
        { lat: 36.659, lng: -75.639 },
        { lat: 36.77, lng: -75.641 },
        { lat: 36.815, lng: -75.677 },
        { lat: 36.93, lng: -75.704 },
        { lat: 37.037, lng: -75.607 },
        { lat: 37.166, lng: -75.546 },
        { lat: 37.197, lng: -75.487 },
        { lat: 37.286, lng: -75.477 },
        { lat: 37.357, lng: -75.407 },
        { lat: 37.446, lng: -75.397 },
        { lat: 37.517, lng: -75.327 },
        { lat: 37.579, lng: -75.309 },
        { lat: 37.662, lng: -75.225 },
        { lat: 37.671, lng: -75.14 },
        { lat: 37.717, lng: -75.087 },
        { lat: 37.771, lng: -75.074 },
        { lat: 37.837, lng: -75.007 },
        { lat: 38.129, lng: -74.875 },
        { lat: 38.157, lng: -74.847 },
        { lat: 38.632, lng: -74.844 },
        { lat: 38.683, lng: -74.828 },
        { lat: 38.757, lng: -74.647 },
        { lat: 38.917, lng: -74.487 },
        { lat: 38.971, lng: -74.474 },
        { lat: 39.021, lng: -74.431 },
        { lat: 39.102, lng: -74.345 },
        { lat: 39.157, lng: -74.207 },
        { lat: 39.237, lng: -74.127 },
        { lat: 39.292, lng: -74.101 },
        { lat: 39.357, lng: -74.007 },
        { lat: 39.415, lng: -73.992 },
        { lat: 39.477, lng: -73.927 },
        { lat: 39.529, lng: -73.915 },
        { lat: 39.57, lng: -73.881 },
        { lat: 39.645, lng: -73.877 },
        { lat: 39.69, lng: -73.841 },
        { lat: 39.835, lng: -73.84 },
        { lat: 39.89, lng: -73.801 },
        { lat: 40.005, lng: -73.797 },
        { lat: 40.05, lng: -73.761 },
        { lat: 40.274, lng: -73.764 },
        { lat: 40.331, lng: -73.742 },
        { lat: 40.391, lng: -73.64 },
        { lat: 40.391, lng: -73.26 },
        { lat: 40.431, lng: -73.2 },
        { lat: 40.431, lng: -72.94 },
        { lat: 40.467, lng: -72.895 },
        { lat: 40.471, lng: -72.82 },
        { lat: 40.51, lng: -72.765 },
        { lat: 40.511, lng: -72.7 },
        { lat: 40.549, lng: -72.649 },
        { lat: 40.551, lng: -72.58 },
        { lat: 40.589, lng: -72.529 },
        { lat: 40.591, lng: -72.42 },
        { lat: 40.627, lng: -72.376 },
        { lat: 40.631, lng: -72.3 },
        { lat: 40.67, lng: -72.245 },
        { lat: 40.671, lng: -72.14 },
        { lat: 40.747, lng: -72.015 },
        { lat: 40.751, lng: -71.94 },
        { lat: 40.789, lng: -71.891 },
        { lat: 40.791, lng: -71.82 },
        { lat: 40.829, lng: -71.771 },
        { lat: 40.837, lng: -71.687 },
        { lat: 40.895, lng: -71.672 },
        { lat: 40.947, lng: -71.615 },
        { lat: 40.957, lng: -71.367 },
        { lat: 41.126, lng: -71.346 },
        { lat: 41.157, lng: -71.287 },
        { lat: 41.218, lng: -71.253 },
        { lat: 41.231, lng: -71.18 },
        { lat: 41.27, lng: -71.125 },
        { lat: 41.251, lng: -71.058 },
        { lat: 41.157, lng: -71.033 },
        { lat: 41.111, lng: -70.98 },
        { lat: 41.111, lng: -70.54 },
        { lat: 41.125, lng: -70.486 },
        { lat: 41.077, lng: -70.433 },
        { lat: 41.069, lng: -70.349 },
        { lat: 41.031, lng: -70.3 },
        { lat: 41.031, lng: -69.86 },
        { lat: 41.065, lng: -69.819 },
        { lat: 41.077, lng: -69.767 },
        { lat: 41.47, lng: -69.761 },
        { lat: 41.53, lng: -69.721 },
        { lat: 42.05, lng: -69.721 },
        { lat: 42.171, lng: -69.795 },
        { lat: 42.223, lng: -69.807 },
        { lat: 42.235, lng: -69.859 },
        { lat: 42.309, lng: -69.98 },
        { lat: 42.307, lng: -70.363 },
        { lat: 42.324, lng: -70.398 },
        { lat: 42.362, lng: -70.415 },
        { lat: 42.595, lng: -70.4 },
        { lat: 42.65, lng: -70.361 },
        { lat: 42.85, lng: -70.361 },
        { lat: 42.916, lng: -70.415 },
        { lat: 43.005, lng: -70.397 },
        { lat: 43.037, lng: -70.367 },
        { lat: 43.134, lng: -70.338 },
        { lat: 43.157, lng: -70.247 },
        { lat: 43.237, lng: -70.127 },
        { lat: 43.32, lng: -70.11 },
        { lat: 43.397, lng: -70.007 },
        { lat: 43.472, lng: -69.994 },
        { lat: 43.505, lng: -69.96 },
        { lat: 43.511, lng: -69.62 },
        { lat: 43.627, lng: -69.496 },
        { lat: 43.637, lng: -69.327 },
        { lat: 43.749, lng: -69.211 },
        { lat: 43.751, lng: -69.02 },
        { lat: 43.831, lng: -68.921 },
        { lat: 43.831, lng: -68.66 },
        { lat: 43.865, lng: -68.619 },
        { lat: 43.877, lng: -68.567 },
        { lat: 43.938, lng: -68.533 },
        { lat: 44.027, lng: -68.376 },
        { lat: 44.031, lng: -68.1 },
        { lat: 44.092, lng: -68.061 },
        { lat: 44.145, lng: -67.979 },
        { lat: 44.157, lng: -67.847 },
        { lat: 44.185, lng: -67.819 },
        { lat: 44.237, lng: -67.687 },
        { lat: 44.305, lng: -67.639 },
        { lat: 44.311, lng: -67.5 },
        { lat: 44.349, lng: -67.451 },
        { lat: 44.357, lng: -67.367 },
        { lat: 44.411, lng: -67.342 },
        { lat: 44.432, lng: -67.298 },
        { lat: 44.431, lng: -66.66 },
        { lat: 44.471, lng: -66.589 },
        { lat: 44.452, lng: -66.539 },
        { lat: 44.401, lng: -66.517 },
        { lat: 44.05, lng: -66.519 },
        { lat: 44.031, lng: -66.5 },
        { lat: 44.023, lng: -66.435 },
        { lat: 43.993, lng: -66.406 },
        { lat: 43.89, lng: -66.399 },
        { lat: 43.831, lng: -66.359 },
        { lat: 43.65, lng: -66.359 },
        { lat: 43.609, lng: -66.325 },
        { lat: 43.557, lng: -66.313 },
        { lat: 43.511, lng: -66.26 },
        { lat: 43.502, lng: -66.055 },
        { lat: 43.455, lng: -66.008 },
        { lat: 43.397, lng: -65.993 },
        { lat: 43.317, lng: -65.913 },
        { lat: 43.292, lng: -65.859 },
        { lat: 43.231, lng: -65.82 },
        { lat: 43.231, lng: -65.42 },
        { lat: 43.267, lng: -65.375 },
        { lat: 43.271, lng: -65.3 },
        { lat: 43.305, lng: -65.259 },
        { lat: 43.317, lng: -65.207 },
        { lat: 43.463, lng: -65.085 },
        { lat: 43.471, lng: -64.94 },
        { lat: 43.509, lng: -64.891 },
        { lat: 43.511, lng: -64.82 },
        { lat: 43.582, lng: -64.745 },
        { lat: 43.637, lng: -64.607 },
        { lat: 43.731, lng: -64.582 },
        { lat: 43.757, lng: -64.527 },
        { lat: 43.862, lng: -64.425 },
        { lat: 43.877, lng: -64.367 },
        { lat: 43.931, lng: -64.342 },
        { lat: 43.957, lng: -64.287 },
        { lat: 44.059, lng: -64.189 },
        { lat: 44.077, lng: -64.047 },
        { lat: 44.166, lng: -64.037 },
        { lat: 44.264, lng: -63.963 },
        { lat: 44.27, lng: -63.875 },
        { lat: 44.231, lng: -63.82 },
        { lat: 44.231, lng: -63.42 },
        { lat: 44.265, lng: -63.379 },
        { lat: 44.277, lng: -63.327 },
        { lat: 44.366, lng: -63.306 },
        { lat: 44.429, lng: -63.209 },
        { lat: 44.431, lng: -63.1 },
        { lat: 44.471, lng: -63.04 },
        { lat: 44.471, lng: -62.86 },
        { lat: 44.511, lng: -62.801 },
        { lat: 44.511, lng: -62.62 },
        { lat: 44.585, lng: -62.559 },
        { lat: 44.591, lng: -62.34 },
        { lat: 44.625, lng: -62.299 },
        { lat: 44.637, lng: -62.247 },
        { lat: 44.707, lng: -62.175 },
        { lat: 44.711, lng: -62.1 },
        { lat: 44.751, lng: -62.041 },
        { lat: 44.751, lng: -61.9 },
        { lat: 44.827, lng: -61.775 },
        { lat: 44.831, lng: -61.66 },
        { lat: 44.87, lng: -61.605 },
        { lat: 44.871, lng: -61.46 },
        { lat: 44.932, lng: -61.421 },
        { lat: 44.952, lng: -61.376 },
        { lat: 44.951, lng: -61.18 },
        { lat: 44.991, lng: -61.121 },
        { lat: 44.991, lng: -60.94 },
        { lat: 45.065, lng: -60.819 },
        { lat: 45.077, lng: -60.767 },
        { lat: 45.279, lng: -60.764 },
        { lat: 45.341, lng: -60.728 },
        { lat: 45.351, lng: -60.5 },
        { lat: 45.387, lng: -60.456 },
        { lat: 45.391, lng: -60.38 },
        { lat: 45.43, lng: -60.325 },
        { lat: 45.431, lng: -60.18 },
        { lat: 45.505, lng: -60.059 },
        { lat: 45.517, lng: -60.007 },
        { lat: 45.584, lng: -59.941 },
        { lat: 45.597, lng: -59.887 },
        { lat: 45.661, lng: -59.848 },
        { lat: 45.671, lng: -59.78 },
        { lat: 45.71, lng: -59.725 },
        { lat: 45.717, lng: -59.607 },
        { lat: 45.772, lng: -59.581 },
        { lat: 45.797, lng: -59.527 },
        { lat: 45.995, lng: -59.52 },
        { lat: 46.05, lng: -59.481 },
        { lat: 46.223, lng: -59.487 },
        { lat: 46.244, lng: -59.576 },
        { lat: 46.343, lng: -59.607 },
        { lat: 46.355, lng: -59.659 },
        { lat: 46.383, lng: -59.687 },
        { lat: 46.391, lng: -59.809 },
        { lat: 46.429, lng: -59.86 },
        { lat: 46.431, lng: -59.971 },
        { lat: 46.463, lng: -60.007 },
        { lat: 46.478, lng: -60.065 },
        { lat: 46.522, lng: -60.11 },
        { lat: 46.589, lng: -60.124 },
        { lat: 47.05, lng: -60.121 },
        { lat: 47.229, lng: -60.22 },
        { lat: 47.223, lng: -60.833 },
        { lat: 47.169, lng: -60.846 },
        { lat: 47.119, lng: -60.889 },
        { lat: 47.038, lng: -60.975 },
        { lat: 47.023, lng: -61.033 },
        { lat: 46.903, lng: -61.153 },
        { lat: 46.848, lng: -61.179 },
        { lat: 46.823, lng: -61.233 },
        { lat: 46.771, lng: -61.245 },
        { lat: 46.73, lng: -61.279 },
        { lat: 46.66, lng: -61.29 },
        { lat: 46.583, lng: -61.393 },
        { lat: 46.531, lng: -61.405 },
        { lat: 46.48, lng: -61.447 },
        { lat: 46.436, lng: -61.499 },
        { lat: 46.423, lng: -61.553 },
        { lat: 46.358, lng: -61.615 },
        { lat: 46.343, lng: -61.673 },
        { lat: 46.281, lng: -61.691 },
        { lat: 46.255, lng: -61.743 },
        { lat: 46.27, lng: -61.784 },
        { lat: 46.303, lng: -61.803 },
        { lat: 46.399, lng: -61.799 },
        { lat: 46.45, lng: -61.761 },
        { lat: 46.61, lng: -61.761 },
        { lat: 46.669, lng: -61.82 },
        { lat: 46.669, lng: -62.9 },
        { lat: 46.631, lng: -62.951 },
        { lat: 46.626, lng: -63.043 },
        { lat: 46.646, lng: -63.098 },
        { lat: 46.709, lng: -63.14 },
        { lat: 46.71, lng: -63.285 },
        { lat: 46.749, lng: -63.34 },
        { lat: 46.755, lng: -63.579 },
        { lat: 46.906, lng: -63.753 },
        { lat: 47.17, lng: -63.761 },
        { lat: 47.269, lng: -63.82 },
        { lat: 47.269, lng: -64.18 },
        { lat: 47.235, lng: -64.221 },
        { lat: 47.223, lng: -64.273 },
        { lat: 47.158, lng: -64.335 },
        { lat: 47.143, lng: -64.393 },
        { lat: 47.063, lng: -64.473 },
        { lat: 47.002, lng: -64.507 },
        { lat: 46.999, lng: -64.568 },
        { lat: 47.062, lng: -64.604 },
        { lat: 47.25, lng: -64.601 },
        { lat: 47.319, lng: -64.659 },
        { lat: 47.37, lng: -64.664 },
        { lat: 47.502, lng: -64.545 },
        { lat: 47.517, lng: -64.447 },
        { lat: 47.582, lng: -64.385 },
        { lat: 47.597, lng: -64.327 },
        { lat: 47.649, lng: -64.315 },
        { lat: 47.69, lng: -64.281 },
        { lat: 48.152, lng: -64.274 },
        { lat: 48.184, lng: -64.243 },
        { lat: 48.197, lng: -64.167 },
        { lat: 48.264, lng: -64.101 },
        { lat: 48.277, lng: -64.047 },
        { lat: 48.317, lng: -64.007 },
        { lat: 48.401, lng: -63.999 },
        { lat: 48.45, lng: -63.961 },
        { lat: 48.97, lng: -63.961 },
        { lat: 49.011, lng: -63.995 },
        { lat: 49.063, lng: -64.007 },
        { lat: 49.143, lng: -64.087 },
        { lat: 49.158, lng: -64.145 },
        { lat: 49.223, lng: -64.207 },
        { lat: 49.235, lng: -64.259 },
        { lat: 49.309, lng: -64.38 },
        { lat: 49.311, lng: -64.451 },
        { lat: 49.343, lng: -64.487 },
        { lat: 49.351, lng: -64.571 },
        { lat: 49.389, lng: -64.62 },
        { lat: 49.391, lng: -64.729 },
        { lat: 49.429, lng: -64.78 },
        { lat: 49.429, lng: -65.12 },
        { lat: 49.469, lng: -65.18 },
        { lat: 49.469, lng: -65.82 },
        { lat: 49.433, lng: -65.864 },
        { lat: 49.426, lng: -65.915 },
        { lat: 49.429, lng: -66.34 },
        { lat: 49.39, lng: -66.395 },
        { lat: 49.389, lng: -66.54 },
        { lat: 49.353, lng: -66.585 },
        { lat: 49.349, lng: -66.7 },
        { lat: 49.31, lng: -66.755 },
        { lat: 49.309, lng: -66.9 },
        { lat: 49.271, lng: -66.951 },
        { lat: 49.279, lng: -67.008 },
        { lat: 49.333, lng: -67.042 },
        { lat: 49.395, lng: -67.04 },
        { lat: 49.477, lng: -66.967 },
        { lat: 49.598, lng: -66.951 },
        { lat: 49.632, lng: -66.897 },
        { lat: 49.637, lng: -66.807 },
        { lat: 49.689, lng: -66.795 },
        { lat: 49.776, lng: -66.736 },
        { lat: 49.829, lng: -66.651 },
        { lat: 49.837, lng: -66.567 },
        { lat: 49.949, lng: -66.449 },
        { lat: 49.951, lng: -66.26 },
        { lat: 49.987, lng: -66.216 },
        { lat: 49.994, lng: -66.165 },
        { lat: 49.991, lng: -65.9 },
        { lat: 50.071, lng: -65.761 },
        { lat: 50.073, lng: -64.789 },
        { lat: 50.052, lng: -64.739 },
        { lat: 50.002, lng: -64.717 },
        { lat: 49.69, lng: -64.719 },
        { lat: 49.597, lng: -64.633 },
        { lat: 49.59, lng: -64.515 },
        { lat: 49.551, lng: -64.46 },
        { lat: 49.547, lng: -64.384 },
        { lat: 49.511, lng: -64.34 },
        { lat: 49.51, lng: -64.235 },
        { lat: 49.471, lng: -64.18 },
        { lat: 49.465, lng: -64.101 },
        { lat: 49.437, lng: -64.073 },
        { lat: 49.379, lng: -63.931 },
        { lat: 49.258, lng: -63.81 },
        { lat: 49.197, lng: -63.793 },
        { lat: 49.185, lng: -63.741 },
        { lat: 49.151, lng: -63.7 },
        { lat: 49.149, lng: -63.591 },
        { lat: 49.111, lng: -63.54 },
        { lat: 49.107, lng: -63.464 },
        { lat: 49.031, lng: -63.34 },
        { lat: 49.03, lng: -63.235 },
        { lat: 48.991, lng: -63.18 },
        { lat: 48.99, lng: -63.035 },
        { lat: 48.951, lng: -62.98 },
        { lat: 48.95, lng: -62.715 },
        { lat: 48.911, lng: -62.66 },
        { lat: 48.907, lng: -62.545 },
        { lat: 48.871, lng: -62.5 },
        { lat: 48.871, lng: -61.58 },
        { lat: 48.93, lng: -61.481 },
        { lat: 49.37, lng: -61.481 },
        { lat: 49.543, lng: -61.647 },
        { lat: 49.551, lng: -61.769 },
        { lat: 49.589, lng: -61.82 },
        { lat: 49.591, lng: -61.971 },
        { lat: 49.663, lng: -62.047 },
        { lat: 49.673, lng: -62.136 },
        { lat: 49.703, lng: -62.167 },
        { lat: 49.72, lng: -62.228 },
        { lat: 49.783, lng: -62.287 },
        { lat: 49.793, lng: -62.375 },
        { lat: 49.869, lng: -62.5 },
        { lat: 49.87, lng: -62.605 },
        { lat: 49.909, lng: -62.66 },
        { lat: 49.913, lng: -62.736 },
        { lat: 49.949, lng: -62.78 },
        { lat: 49.953, lng: -62.895 },
        { lat: 50.0, lng: -62.933 },
        { lat: 50.054, lng: -62.92 },
        { lat: 50.074, lng: -62.875 },
        { lat: 50.074, lng: -62.518 },
        { lat: 50.067, lng: -62.464 },
        { lat: 50.031, lng: -62.42 },
        { lat: 50.031, lng: -62.079 },
        { lat: 49.979, lng: -62.011 },
        { lat: 49.911, lng: -61.98 },
        { lat: 49.911, lng: -61.38 },
        { lat: 49.947, lng: -61.335 },
        { lat: 49.951, lng: -61.22 },
        { lat: 49.991, lng: -61.161 },
        { lat: 49.991, lng: -60.62 },
        { lat: 50.027, lng: -60.576 },
        { lat: 50.034, lng: -60.522 },
        { lat: 50.031, lng: -60.319 },
        { lat: 49.991, lng: -60.26 },
        { lat: 49.991, lng: -59.94 },
        { lat: 50.027, lng: -59.896 },
        { lat: 50.031, lng: -59.82 },
        { lat: 50.105, lng: -59.699 },
        { lat: 50.117, lng: -59.647 },
        { lat: 50.171, lng: -59.634 },
        { lat: 50.214, lng: -59.594 },
        { lat: 50.267, lng: -59.496 },
        { lat: 50.271, lng: -59.38 },
        { lat: 50.35, lng: -59.285 },
        { lat: 50.357, lng: -59.127 },
        { lat: 50.486, lng: -59.066 },
        { lat: 50.545, lng: -58.979 },
        { lat: 50.557, lng: -58.807 },
        { lat: 50.597, lng: -58.767 },
        { lat: 50.686, lng: -58.757 },
        { lat: 50.82, lng: -58.673 },
        { lat: 50.865, lng: -58.619 },
        { lat: 50.957, lng: -58.407 },
        { lat: 51.023, lng: -58.365 },
        { lat: 51.031, lng: -58.22 },
        { lat: 51.071, lng: -58.16 },
        { lat: 51.071, lng: -57.98 },
        { lat: 51.111, lng: -57.921 },
        { lat: 51.111, lng: -57.78 },
        { lat: 51.184, lng: -57.701 },
        { lat: 51.191, lng: -57.62 },
        { lat: 51.23, lng: -57.565 },
        { lat: 51.233, lng: -57.346 },
        { lat: 51.212, lng: -57.299 },
        { lat: 51.166, lng: -57.278 },
        { lat: 51.005, lng: -57.288 },
        { lat: 50.951, lng: -57.349 },
        { lat: 50.949, lng: -57.42 },
        { lat: 50.91, lng: -57.475 },
        { lat: 50.903, lng: -57.593 },
        { lat: 50.731, lng: -57.605 },
        { lat: 50.663, lng: -57.673 },
        { lat: 50.365, lng: -57.808 },
        { lat: 50.303, lng: -57.873 },
        { lat: 50.245, lng: -57.888 },
        { lat: 50.183, lng: -57.953 },
        { lat: 50.129, lng: -57.966 },
        { lat: 50.063, lng: -58.033 },
        { lat: 50.002, lng: -58.05 },
        { lat: 49.903, lng: -58.153 },
        { lat: 49.777, lng: -58.172 },
        { lat: 49.715, lng: -58.261 },
        { lat: 49.703, lng: -58.313 },
        { lat: 49.583, lng: -58.433 },
        { lat: 49.423, lng: -58.436 },
        { lat: 49.372, lng: -58.456 },
        { lat: 49.347, lng: -58.508 },
        { lat: 49.349, lng: -58.58 },
        { lat: 49.303, lng: -58.633 },
        { lat: 49.245, lng: -58.648 },
        { lat: 49.183, lng: -58.713 },
        { lat: 49.085, lng: -58.728 },
        { lat: 48.998, lng: -58.815 },
        { lat: 48.989, lng: -58.98 },
        { lat: 48.955, lng: -59.021 },
        { lat: 48.943, lng: -59.073 },
        { lat: 48.876, lng: -59.139 },
        { lat: 48.743, lng: -59.433 },
        { lat: 48.509, lng: -59.439 },
        { lat: 48.45, lng: -59.479 },
        { lat: 48.29, lng: -59.479 },
        { lat: 48.217, lng: -59.454 },
        { lat: 48.123, lng: -59.528 },
        { lat: 48.09, lng: -59.599 },
        { lat: 47.69, lng: -59.599 },
        { lat: 47.601, lng: -59.521 },
        { lat: 47.437, lng: -59.513 },
        { lat: 47.351, lng: -59.42 },
        { lat: 47.351, lng: -58.86 },
        { lat: 47.39, lng: -58.805 },
        { lat: 47.391, lng: -58.46 },
        { lat: 47.431, lng: -58.4 },
        { lat: 47.434, lng: -57.959 },
        { lat: 47.427, lng: -57.904 },
        { lat: 47.391, lng: -57.86 },
        { lat: 47.394, lng: -57.396 },
        { lat: 47.387, lng: -57.344 },
        { lat: 47.351, lng: -57.3 },
        { lat: 47.35, lng: -57.035 },
        { lat: 47.311, lng: -56.98 },
        { lat: 47.312, lng: -56.662 },
        { lat: 47.288, lng: -56.616 },
        { lat: 47.236, lng: -56.596 },
        { lat: 46.61, lng: -56.599 },
        { lat: 46.591, lng: -56.58 },
        { lat: 46.589, lng: -56.469 },
        { lat: 46.551, lng: -56.42 },
        { lat: 46.551, lng: -55.98 },
        { lat: 46.597, lng: -55.927 },
        { lat: 46.652, lng: -55.901 },
        { lat: 46.674, lng: -55.848 },
        { lat: 46.671, lng: -55.18 },
        { lat: 46.709, lng: -55.131 },
        { lat: 46.711, lng: -55.06 },
        { lat: 46.877, lng: -54.887 },
        { lat: 46.935, lng: -54.872 },
        { lat: 46.997, lng: -54.807 },
        { lat: 47.049, lng: -54.795 },
        { lat: 47.1, lng: -54.753 },
        { lat: 47.145, lng: -54.699 },
        { lat: 47.154, lng: -54.641 },
        { lat: 47.153, lng: -54.469 },
        { lat: 47.115, lng: -54.407 },
        { lat: 46.637, lng: -54.393 },
        { lat: 46.625, lng: -54.341 },
        { lat: 46.591, lng: -54.3 },
        { lat: 46.585, lng: -53.881 },
        { lat: 46.554, lng: -53.847 },
        { lat: 46.477, lng: -53.833 },
        { lat: 46.431, lng: -53.78 },
        { lat: 46.431, lng: -52.9 },
        { lat: 46.477, lng: -52.847 },
        { lat: 46.532, lng: -52.821 },
        { lat: 46.597, lng: -52.727 },
        { lat: 46.681, lng: -52.719 },
        { lat: 46.73, lng: -52.681 },
        { lat: 46.801, lng: -52.679 },
        { lat: 46.85, lng: -52.641 },
        { lat: 47.006, lng: -52.637 },
        { lat: 47.117, lng: -52.527 },
        { lat: 47.175, lng: -52.512 },
        { lat: 47.25, lng: -52.441 },
        { lat: 47.47, lng: -52.441 },
        { lat: 47.53, lng: -52.401 },
        { lat: 47.69, lng: -52.401 },
        { lat: 47.735, lng: -52.437 },
        { lat: 47.81, lng: -52.441 },
        { lat: 47.851, lng: -52.475 },
        { lat: 47.903, lng: -52.487 },
        { lat: 48.029, lng: -52.634 },
        { lat: 48.303, lng: -52.647 },
        { lat: 48.343, lng: -52.687 },
        { lat: 48.36, lng: -52.748 },
        { lat: 48.419, lng: -52.799 },
        { lat: 48.556, lng: -52.804 },
        { lat: 48.606, lng: -52.797 },
        { lat: 48.65, lng: -52.761 },
        { lat: 48.783, lng: -52.767 },
        { lat: 48.909, lng: -52.9 },
        { lat: 48.907, lng: -53.243 },
        { lat: 48.941, lng: -53.29 },
        { lat: 49.002, lng: -53.285 },
        { lat: 49.05, lng: -53.241 },
        { lat: 49.423, lng: -53.247 },
        { lat: 49.503, lng: -53.327 },
        { lat: 49.511, lng: -53.411 },
        { lat: 49.589, lng: -53.54 },
        { lat: 49.591, lng: -53.609 },
        { lat: 49.623, lng: -53.647 },
        { lat: 49.644, lng: -53.736 },
        { lat: 49.743, lng: -53.767 },
        { lat: 49.779, lng: -53.799 },
        { lat: 49.863, lng: -53.807 },
        { lat: 49.949, lng: -53.9 },
        { lat: 49.949, lng: -54.34 },
        { lat: 49.913, lng: -54.384 },
        { lat: 49.909, lng: -54.46 },
        { lat: 49.87, lng: -54.515 },
        { lat: 49.869, lng: -54.74 },
        { lat: 49.79, lng: -54.875 },
        { lat: 49.789, lng: -55.06 },
        { lat: 49.75, lng: -55.115 },
        { lat: 49.755, lng: -55.199 },
        { lat: 49.791, lng: -55.235 },
        { lat: 49.93, lng: -55.241 },
        { lat: 49.985, lng: -55.28 },
        { lat: 50.183, lng: -55.287 },
        { lat: 50.189, lng: -55.56 },
        { lat: 50.229, lng: -55.62 },
        { lat: 50.231, lng: -55.809 },
        { lat: 50.319, lng: -55.912 },
        { lat: 50.37, lng: -55.944 },
        { lat: 50.452, lng: -55.919 },
        { lat: 50.509, lng: -55.851 },
        { lat: 50.511, lng: -55.34 },
        { lat: 50.597, lng: -55.247 },
        { lat: 50.97, lng: -55.241 },
        { lat: 51.027, lng: -55.314 },
        { lat: 51.151, lng: -55.321 },
        { lat: 51.37, lng: -55.201 },
        { lat: 51.645, lng: -55.197 },
        { lat: 51.77, lng: -55.081 },
        { lat: 52.183, lng: -55.087 },
        { lat: 52.187, lng: -55.372 },
        { lat: 52.208, lng: -55.421 },
        { lat: 52.251, lng: -55.442 },
        { lat: 52.61, lng: -55.441 },
        { lat: 52.657, lng: -55.508 },
        { lat: 52.73, lng: -55.521 },
        { lat: 52.79, lng: -55.561 },
        { lat: 53.45, lng: -55.561 },
        { lat: 53.491, lng: -55.595 },
        { lat: 53.543, lng: -55.607 },
        { lat: 53.555, lng: -55.659 },
        { lat: 53.609, lng: -55.742 },
        { lat: 53.691, lng: -55.795 },
        { lat: 53.743, lng: -55.807 },
        { lat: 53.783, lng: -55.847 },
        { lat: 53.789, lng: -56.041 },
        { lat: 53.829, lng: -56.1 },
        { lat: 53.838, lng: -56.185 },
        { lat: 53.891, lng: -56.235 },
        { lat: 53.97, lng: -56.241 },
        { lat: 53.989, lng: -56.26 },
        { lat: 53.989, lng: -56.74 },
        { lat: 53.95, lng: -56.795 },
        { lat: 53.962, lng: -56.853 },
        { lat: 54.023, lng: -56.887 },
        { lat: 54.06, lng: -56.95 },
        { lat: 54.143, lng: -56.967 },
        { lat: 54.161, lng: -57.029 },
        { lat: 54.295, lng: -57.157 },
        { lat: 54.783, lng: -57.167 },
        { lat: 54.823, lng: -57.207 },
        { lat: 54.835, lng: -57.259 },
        { lat: 54.869, lng: -57.3 },
        { lat: 54.876, lng: -57.541 },
        { lat: 54.937, lng: -57.628 },
        { lat: 55.023, lng: -57.647 },
        { lat: 55.038, lng: -57.705 },
        { lat: 55.103, lng: -57.767 },
        { lat: 55.109, lng: -58.041 },
        { lat: 55.149, lng: -58.1 },
        { lat: 55.113, lng: -58.144 },
        { lat: 55.109, lng: -58.22 },
        { lat: 55.07, lng: -58.275 },
        { lat: 55.069, lng: -58.42 },
        { lat: 55.031, lng: -58.471 },
        { lat: 55.03, lng: -58.565 },
        { lat: 55.088, lng: -58.661 },
        { lat: 55.143, lng: -58.687 },
        { lat: 55.205, lng: -58.752 },
        { lat: 55.343, lng: -58.767 },
        { lat: 55.357, lng: -58.885 },
        { lat: 55.429, lng: -58.94 },
        { lat: 55.429, lng: -59.42 },
        { lat: 55.415, lng: -59.474 },
        { lat: 55.509, lng: -59.58 },
        { lat: 55.506, lng: -59.882 },
        { lat: 55.521, lng: -59.949 },
        { lat: 55.579, lng: -59.999 },
        { lat: 55.703, lng: -60.007 },
        { lat: 55.716, lng: -60.082 },
        { lat: 55.75, lng: -60.115 },
        { lat: 55.983, lng: -60.127 },
        { lat: 55.991, lng: -60.369 },
        { lat: 56.048, lng: -60.461 },
        { lat: 56.143, lng: -60.527 },
        { lat: 56.157, lng: -60.725 },
        { lat: 56.212, lng: -60.762 },
        { lat: 56.303, lng: -60.767 },
        { lat: 56.316, lng: -60.821 },
        { lat: 56.371, lng: -60.886 },
        { lat: 56.85, lng: -60.881 },
        { lat: 56.869, lng: -60.9 },
        { lat: 56.866, lng: -61.046 },
        { lat: 56.889, lng: -61.102 },
        { lat: 56.94, lng: -61.124 },
        { lat: 57.01, lng: -61.121 },
        { lat: 57.069, lng: -61.161 },
        { lat: 57.303, lng: -61.167 },
        { lat: 57.349, lng: -61.22 },
        { lat: 57.347, lng: -61.333 },
        { lat: 57.385, lng: -61.393 },
        { lat: 57.61, lng: -61.401 },
        { lat: 57.665, lng: -61.44 },
        { lat: 57.85, lng: -61.441 },
        { lat: 57.901, lng: -61.479 },
        { lat: 58.023, lng: -61.487 },
        { lat: 58.031, lng: -61.649 },
        { lat: 58.103, lng: -61.727 },
        { lat: 58.113, lng: -61.855 },
        { lat: 58.189, lng: -61.94 },
        { lat: 58.197, lng: -62.084 },
        { lat: 58.303, lng: -62.167 },
        { lat: 58.332, lng: -62.224 },
        { lat: 58.383, lng: -62.247 },
        { lat: 58.406, lng: -62.338 },
        { lat: 58.464, lng: -62.364 },
        { lat: 58.703, lng: -62.367 },
        { lat: 58.706, lng: -62.566 },
        { lat: 58.726, lng: -62.618 },
        { lat: 58.78, lng: -62.644 },
        { lat: 58.903, lng: -62.647 },
        { lat: 58.934, lng: -62.706 },
        { lat: 59.023, lng: -62.727 },
        { lat: 59.049, lng: -62.782 },
        { lat: 59.103, lng: -62.807 },
        { lat: 59.134, lng: -62.906 },
        { lat: 59.263, lng: -62.927 },
        { lat: 59.271, lng: -63.049 },
        { lat: 59.322, lng: -63.133 },
        { lat: 59.423, lng: -63.167 },
        { lat: 59.463, lng: -63.207 },
        { lat: 59.482, lng: -63.293 },
        { lat: 59.543, lng: -63.327 },
        { lat: 59.564, lng: -63.496 },
        { lat: 59.619, lng: -63.523 },
        { lat: 59.743, lng: -63.527 },
        { lat: 59.766, lng: -63.658 },
        { lat: 59.823, lng: -63.687 },
        { lat: 59.885, lng: -63.752 },
        { lat: 59.943, lng: -63.767 },
        { lat: 59.989, lng: -63.82 },
        { lat: 59.989, lng: -65.66 },
        { lat: 59.943, lng: -65.713 },
        { lat: 59.821, lng: -65.721 },
        { lat: 59.77, lng: -65.759 },
        { lat: 59.534, lng: -65.763 },
        { lat: 59.475, lng: -65.821 },
        { lat: 59.463, lng: -65.913 },
        { lat: 59.379, lng: -65.921 },
        { lat: 59.33, lng: -65.959 },
        { lat: 59.255, lng: -65.963 },
        { lat: 59.201, lng: -66.011 },
        { lat: 59.183, lng: -66.073 },
        { lat: 59.076, lng: -66.179 },
        { lat: 59.069, lng: -66.26 },
        { lat: 59.031, lng: -66.311 },
        { lat: 59.023, lng: -66.673 },
        { lat: 58.937, lng: -66.692 },
        { lat: 58.863, lng: -66.793 },
        { lat: 58.805, lng: -66.808 },
        { lat: 58.718, lng: -66.895 },
        { lat: 58.709, lng: -67.14 },
        { lat: 58.638, lng: -67.215 },
        { lat: 58.549, lng: -67.407 },
        { lat: 58.568, lng: -67.461 },
        { lat: 58.663, lng: -67.527 },
        { lat: 58.675, lng: -67.659 },
        { lat: 58.728, lng: -67.741 },
        { lat: 58.789, lng: -67.78 },
        { lat: 58.786, lng: -68.042 },
        { lat: 58.8, lng: -68.09 },
        { lat: 58.894, lng: -68.157 },
        { lat: 58.983, lng: -68.167 },
        { lat: 59.029, lng: -68.22 },
        { lat: 59.03, lng: -68.285 },
        { lat: 59.069, lng: -68.34 },
        { lat: 59.071, lng: -68.411 },
        { lat: 59.109, lng: -68.46 },
        { lat: 59.106, lng: -68.842 },
        { lat: 59.122, lng: -68.893 },
        { lat: 59.375, lng: -69.037 },
        { lat: 59.503, lng: -69.047 },
        { lat: 59.511, lng: -69.131 },
        { lat: 59.549, lng: -69.18 },
        { lat: 59.548, lng: -69.259 },
        { lat: 59.582, lng: -69.311 },
        { lat: 59.85, lng: -69.321 },
        { lat: 59.894, lng: -69.357 },
        { lat: 59.97, lng: -69.361 },
        { lat: 59.989, lng: -69.38 },
        { lat: 59.989, lng: -77.78 },
        { lat: 59.917, lng: -77.835 },
        { lat: 59.903, lng: -77.953 },
        { lat: 59.85, lng: -77.999 },
        { lat: 59.742, lng: -78.009 },
        { lat: 59.703, lng: -78.073 },
        { lat: 59.651, lng: -78.085 },
        { lat: 59.623, lng: -78.113 },
        { lat: 59.494, lng: -78.134 },
        { lat: 59.435, lng: -78.221 },
        { lat: 59.423, lng: -78.273 },
        { lat: 59.278, lng: -78.415 },
        { lat: 59.263, lng: -78.473 },
        { lat: 59.209, lng: -78.486 },
        { lat: 59.16, lng: -78.532 },
        { lat: 59.149, lng: -78.74 },
        { lat: 59.088, lng: -78.779 },
        { lat: 59.067, lng: -78.829 },
        { lat: 59.063, lng: -79.113 },
        { lat: 58.865, lng: -79.12 },
        { lat: 58.81, lng: -79.159 },
        { lat: 58.65, lng: -79.159 },
        { lat: 58.591, lng: -79.06 },
        { lat: 58.594, lng: -78.991 },
        { lat: 58.574, lng: -78.942 },
        { lat: 58.485, lng: -78.883 },
        { lat: 58.397, lng: -78.873 },
        { lat: 58.389, lng: -78.789 },
        { lat: 58.351, lng: -78.74 },
        { lat: 58.349, lng: -78.509 },
        { lat: 58.237, lng: -78.313 },
        { lat: 58.222, lng: -78.255 },
        { lat: 58.111, lng: -78.14 },
        { lat: 58.107, lng: -78.065 },
        { lat: 58.031, lng: -77.94 },
        { lat: 58.024, lng: -77.719 },
        { lat: 57.957, lng: -77.673 },
        { lat: 57.945, lng: -77.621 },
        { lat: 57.871, lng: -77.5 },
        { lat: 57.864, lng: -77.419 },
        { lat: 57.757, lng: -77.313 },
        { lat: 57.732, lng: -77.259 },
        { lat: 57.677, lng: -77.233 },
        { lat: 57.481, lng: -77.041 },
        { lat: 57.21, lng: -77.039 },
        { lat: 57.169, lng: -77.005 },
        { lat: 57.117, lng: -76.993 },
        { lat: 57.039, lng: -76.921 },
        { lat: 56.89, lng: -76.919 },
        { lat: 56.826, lng: -76.865 },
        { lat: 56.65, lng: -76.879 },
        { lat: 56.595, lng: -76.84 },
        { lat: 56.455, lng: -76.837 },
        { lat: 56.4, lng: -76.87 },
        { lat: 56.383, lng: -76.953 },
        { lat: 56.299, lng: -76.961 },
        { lat: 56.25, lng: -76.999 },
        { lat: 56.175, lng: -77.003 },
        { lat: 56.121, lng: -77.051 },
        { lat: 56.103, lng: -77.113 },
        { lat: 56.001, lng: -77.211 },
        { lat: 55.983, lng: -77.273 },
        { lat: 55.918, lng: -77.335 },
        { lat: 55.903, lng: -77.393 },
        { lat: 55.84, lng: -77.43 },
        { lat: 55.823, lng: -77.513 },
        { lat: 55.756, lng: -77.579 },
        { lat: 55.663, lng: -77.793 },
        { lat: 55.609, lng: -77.806 },
        { lat: 55.563, lng: -77.848 },
        { lat: 55.543, lng: -77.913 },
        { lat: 55.48, lng: -77.972 },
        { lat: 55.463, lng: -78.033 },
        { lat: 55.433, lng: -78.064 },
        { lat: 55.429, lng: -78.14 },
        { lat: 55.353, lng: -78.265 },
        { lat: 55.343, lng: -78.353 },
        { lat: 55.233, lng: -78.545 },
        { lat: 55.229, lng: -78.66 },
        { lat: 55.193, lng: -78.704 },
        { lat: 55.189, lng: -78.78 },
        { lat: 55.15, lng: -78.835 },
        { lat: 55.149, lng: -79.26 },
        { lat: 55.109, lng: -79.319 },
        { lat: 55.109, lng: -79.54 },
        { lat: 55.073, lng: -79.584 },
        { lat: 55.066, lng: -79.635 },
        { lat: 55.069, lng: -79.82 },
        { lat: 54.995, lng: -79.941 },
        { lat: 54.983, lng: -79.993 },
        { lat: 54.85, lng: -79.999 },
        { lat: 54.806, lng: -79.963 },
        { lat: 54.754, lng: -79.956 },
        { lat: 54.45, lng: -79.959 },
        { lat: 54.431, lng: -79.94 },
        { lat: 54.427, lng: -79.864 },
        { lat: 54.382, lng: -79.81 },
        { lat: 54.295, lng: -79.728 },
        { lat: 54.207, lng: -79.705 },
        { lat: 54.166, lng: -79.72 },
        { lat: 54.147, lng: -79.753 },
        { lat: 54.143, lng: -80.113 },
        { lat: 53.73, lng: -80.119 },
        { lat: 53.711, lng: -80.1 },
        { lat: 53.698, lng: -79.987 },
        { lat: 53.621, lng: -79.961 },
        { lat: 53.551, lng: -80.031 },
        { lat: 53.549, lng: -80.26 },
        { lat: 53.53, lng: -80.279 },
        { lat: 53.13, lng: -80.279 },
        { lat: 53.077, lng: -80.233 },
        { lat: 53.043, lng: -80.172 },
        { lat: 52.961, lng: -80.121 },
        { lat: 52.877, lng: -80.113 },
        { lat: 52.873, lng: -79.833 },
        { lat: 52.826, lng: -79.786 },
        { lat: 52.774, lng: -79.797 },
        { lat: 52.73, lng: -79.839 },
        { lat: 52.33, lng: -79.839 },
        { lat: 52.271, lng: -79.812 },
        { lat: 52.21, lng: -79.839 },
        { lat: 51.73, lng: -79.839 },
        { lat: 51.657, lng: -79.814 },
        { lat: 51.602, lng: -79.845 },
        { lat: 51.521, lng: -79.931 },
        { lat: 51.506, lng: -80.0 },
        { lat: 51.511, lng: -80.129 },
        { lat: 51.568, lng: -80.221 },
        { lat: 51.663, lng: -80.247 },
        { lat: 51.725, lng: -80.312 },
        { lat: 51.863, lng: -80.367 },
        { lat: 52.023, lng: -80.527 },
        { lat: 52.08, lng: -80.668 },
        { lat: 52.223, lng: -80.807 },
        { lat: 52.23, lng: -80.925 },
        { lat: 52.269, lng: -80.98 },
        { lat: 52.273, lng: -81.056 },
        { lat: 52.309, lng: -81.1 },
        { lat: 52.313, lng: -81.176 },
        { lat: 52.419, lng: -81.279 },
        { lat: 52.518, lng: -81.271 },
        { lat: 52.554, lng: -81.208 },
        { lat: 52.549, lng: -81.111 },
        { lat: 52.511, lng: -81.06 },
        { lat: 52.517, lng: -80.487 },
        { lat: 53.01, lng: -80.481 },
        { lat: 53.085, lng: -80.552 },
        { lat: 53.143, lng: -80.567 },
        { lat: 53.223, lng: -80.647 },
        { lat: 53.241, lng: -80.709 },
        { lat: 53.343, lng: -80.807 },
        { lat: 53.355, lng: -80.859 },
        { lat: 53.389, lng: -80.9 },
        { lat: 53.393, lng: -80.975 },
        { lat: 53.429, lng: -81.02 },
        { lat: 53.429, lng: -81.7 },
        { lat: 53.393, lng: -81.744 },
        { lat: 53.386, lng: -81.799 },
        { lat: 53.395, lng: -81.879 },
        { lat: 53.422, lng: -81.911 },
        { lat: 53.469, lng: -81.924 },
        { lat: 54.01, lng: -81.921 },
        { lat: 54.055, lng: -81.957 },
        { lat: 54.13, lng: -81.961 },
        { lat: 54.171, lng: -81.995 },
        { lat: 54.223, lng: -82.007 },
        { lat: 54.35, lng: -82.121 },
        { lat: 54.406, lng: -82.117 },
        { lat: 54.61, lng: -82.001 },
        { lat: 55.05, lng: -82.001 },
        { lat: 55.109, lng: -82.041 },
        { lat: 55.29, lng: -82.041 },
        { lat: 55.343, lng: -82.087 },
        { lat: 55.349, lng: -82.6 },
        { lat: 55.429, lng: -82.74 },
        { lat: 55.429, lng: -83.28 },
        { lat: 55.469, lng: -83.34 },
        { lat: 55.47, lng: -83.565 },
        { lat: 55.509, lng: -83.62 },
        { lat: 55.509, lng: -84.46 },
        { lat: 55.469, lng: -84.52 },
        { lat: 55.468, lng: -84.856 },
        { lat: 55.489, lng: -84.902 },
        { lat: 55.543, lng: -84.927 },
        { lat: 55.551, lng: -85.011 },
        { lat: 55.589, lng: -85.06 },
        { lat: 55.591, lng: -85.131 },
        { lat: 55.629, lng: -85.18 },
        { lat: 55.636, lng: -85.261 },
        { lat: 55.685, lng: -85.312 },
        { lat: 55.743, lng: -85.327 },
        { lat: 55.795, lng: -85.459 },
        { lat: 55.823, lng: -85.487 },
        { lat: 55.831, lng: -85.611 },
        { lat: 55.909, lng: -85.74 },
        { lat: 55.91, lng: -86.005 },
        { lat: 55.949, lng: -86.06 },
        { lat: 55.953, lng: -86.136 },
        { lat: 55.989, lng: -86.18 },
        { lat: 55.993, lng: -86.255 },
        { lat: 56.029, lng: -86.3 },
        { lat: 56.03, lng: -86.445 },
        { lat: 56.069, lng: -86.5 },
        { lat: 56.073, lng: -86.615 },
        { lat: 56.109, lng: -86.66 },
        { lat: 56.109, lng: -86.841 },
        { lat: 56.149, lng: -86.9 },
        { lat: 56.149, lng: -87.12 },
        { lat: 56.189, lng: -87.18 },
        { lat: 56.193, lng: -87.256 },
        { lat: 56.223, lng: -87.287 },
        { lat: 56.241, lng: -87.349 },
        { lat: 56.343, lng: -87.447 },
        { lat: 56.363, lng: -87.512 },
        { lat: 56.409, lng: -87.554 },
        { lat: 56.463, lng: -87.567 },
        { lat: 56.503, lng: -87.607 },
        { lat: 56.521, lng: -87.669 },
        { lat: 56.663, lng: -87.807 },
        { lat: 56.671, lng: -87.929 },
        { lat: 56.709, lng: -87.98 },
        { lat: 56.713, lng: -88.096 },
        { lat: 56.743, lng: -88.127 },
        { lat: 56.76, lng: -88.188 },
        { lat: 56.823, lng: -88.247 },
        { lat: 56.835, lng: -88.299 },
        { lat: 56.869, lng: -88.34 },
        { lat: 56.878, lng: -88.425 },
        { lat: 56.943, lng: -88.487 },
        { lat: 56.958, lng: -88.545 },
        { lat: 57.023, lng: -88.607 },
        { lat: 57.035, lng: -88.659 },
        { lat: 57.069, lng: -88.7 },
        { lat: 57.069, lng: -89.04 },
        { lat: 57.109, lng: -89.1 },
        { lat: 57.111, lng: -89.289 },
        { lat: 57.149, lng: -89.34 },
        { lat: 57.149, lng: -89.56 },
        { lat: 57.189, lng: -89.62 },
        { lat: 57.19, lng: -89.765 },
        { lat: 57.269, lng: -89.9 },
        { lat: 57.27, lng: -89.965 },
        { lat: 57.309, lng: -90.02 },
        { lat: 57.313, lng: -90.095 },
        { lat: 57.389, lng: -90.22 },
        { lat: 57.391, lng: -90.331 },
        { lat: 57.429, lng: -90.38 },
        { lat: 57.429, lng: -90.561 },
        { lat: 57.469, lng: -90.62 },
        { lat: 57.469, lng: -91.26 },
        { lat: 57.43, lng: -91.315 },
        { lat: 57.429, lng: -91.46 },
        { lat: 57.39, lng: -91.515 },
        { lat: 57.389, lng: -91.66 },
        { lat: 57.35, lng: -91.715 },
        { lat: 57.349, lng: -91.86 },
        { lat: 57.313, lng: -91.905 },
        { lat: 57.309, lng: -92.02 },
        { lat: 57.27, lng: -92.075 },
        { lat: 57.275, lng: -92.159 },
        { lat: 57.307, lng: -92.194 },
        { lat: 57.41, lng: -92.201 },
        { lat: 57.465, lng: -92.24 },
        { lat: 57.623, lng: -92.247 },
        { lat: 57.651, lng: -92.275 },
        { lat: 57.703, lng: -92.287 },
        { lat: 57.805, lng: -92.392 },
        { lat: 57.863, lng: -92.407 },
        { lat: 57.965, lng: -92.512 },
        { lat: 58.023, lng: -92.527 },
        { lat: 58.059, lng: -92.559 },
        { lat: 58.13, lng: -92.561 },
        { lat: 58.175, lng: -92.597 },
        { lat: 58.25, lng: -92.601 },
        { lat: 58.291, lng: -92.635 },
        { lat: 58.343, lng: -92.647 },
        { lat: 58.441, lng: -92.749 },
        { lat: 58.503, lng: -92.767 },
        { lat: 58.569, lng: -92.834 },
        { lat: 58.623, lng: -92.847 },
        { lat: 58.689, lng: -92.914 },
        { lat: 58.89, lng: -92.921 },
        { lat: 58.943, lng: -92.967 },
        { lat: 58.949, lng: -93.32 },
        { lat: 58.989, lng: -93.38 },
        { lat: 58.989, lng: -94.2 },
        { lat: 59.029, lng: -94.26 },
        { lat: 59.031, lng: -94.331 },
        { lat: 59.063, lng: -94.367 },
        { lat: 59.092, lng: -94.464 },
        { lat: 59.148, lng: -94.484 },
        { lat: 59.53, lng: -94.481 },
        { lat: 59.575, lng: -94.517 },
        { lat: 59.65, lng: -94.521 },
        { lat: 59.694, lng: -94.557 },
        { lat: 59.747, lng: -94.564 },
        { lat: 59.846, lng: -94.557 },
        { lat: 59.93, lng: -94.481 },
        { lat: 59.97, lng: -94.481 },
        { lat: 59.989, lng: -94.5 },
        { lat: 59.983, lng: -144.793 },
        { lat: 59.61, lng: -144.799 },
        { lat: 59.591, lng: -144.78 },
        { lat: 59.591, lng: -144.46 },
        { lat: 59.629, lng: -144.411 },
        { lat: 59.637, lng: -144.327 },
        { lat: 59.791, lng: -144.04 },
        { lat: 59.791, lng: -143.62 },
        { lat: 59.83, lng: -143.565 },
        { lat: 59.831, lng: -143.26 },
        { lat: 59.867, lng: -143.216 },
        { lat: 59.874, lng: -143.163 },
        { lat: 59.874, lng: -142.479 },
        { lat: 59.867, lng: -142.424 },
        { lat: 59.831, lng: -142.38 },
        { lat: 59.83, lng: -142.155 },
        { lat: 59.791, lng: -142.1 },
        { lat: 59.787, lng: -141.985 },
        { lat: 59.751, lng: -141.94 },
        { lat: 59.753, lng: -141.745 },
        { lat: 59.732, lng: -141.699 },
        { lat: 59.671, lng: -141.66 },
        { lat: 59.67, lng: -141.555 },
        { lat: 59.631, lng: -141.5 },
        { lat: 59.63, lng: -141.355 },
        { lat: 59.591, lng: -141.3 },
        { lat: 59.591, lng: -141.199 },
        { lat: 59.551, lng: -141.14 },
        { lat: 59.549, lng: -141.029 },
        { lat: 59.511, lng: -140.98 },
        { lat: 59.513, lng: -140.105 },
        { lat: 59.47, lng: -140.045 },
        { lat: 59.317, lng: -140.033 },
        { lat: 59.309, lng: -139.949 },
        { lat: 59.271, lng: -139.9 },
        { lat: 59.267, lng: -139.825 },
        { lat: 59.197, lng: -139.713 },
        { lat: 59.189, lng: -139.629 },
        { lat: 59.151, lng: -139.58 },
        { lat: 59.15, lng: -139.435 },
        { lat: 59.111, lng: -139.38 },
        { lat: 59.107, lng: -139.304 },
        { lat: 59.071, lng: -139.26 },
        { lat: 59.069, lng: -139.149 },
        { lat: 59.031, lng: -139.1 },
        { lat: 59.03, lng: -139.035 },
        { lat: 58.991, lng: -138.98 },
        { lat: 58.987, lng: -138.904 },
        { lat: 58.911, lng: -138.78 },
        { lat: 58.91, lng: -138.635 },
        { lat: 58.871, lng: -138.58 },
        { lat: 58.867, lng: -138.504 },
        { lat: 58.831, lng: -138.46 },
        { lat: 58.827, lng: -138.385 },
        { lat: 58.797, lng: -138.353 },
        { lat: 58.739, lng: -138.211 },
        { lat: 58.658, lng: -138.13 },
        { lat: 58.597, lng: -138.113 },
        { lat: 58.557, lng: -138.073 },
        { lat: 58.502, lng: -137.935 },
        { lat: 58.397, lng: -137.833 },
        { lat: 58.387, lng: -137.744 },
        { lat: 58.277, lng: -137.553 },
        { lat: 58.267, lng: -137.465 },
        { lat: 58.191, lng: -137.34 },
        { lat: 58.189, lng: -137.149 },
        { lat: 58.117, lng: -137.033 },
        { lat: 58.098, lng: -136.947 },
        { lat: 58.037, lng: -136.913 },
        { lat: 58.02, lng: -136.79 },
        { lat: 57.958, lng: -136.756 },
        { lat: 57.717, lng: -136.753 },
        { lat: 57.702, lng: -136.695 },
        { lat: 57.637, lng: -136.633 },
        { lat: 57.624, lng: -136.539 },
        { lat: 57.557, lng: -136.473 },
        { lat: 57.538, lng: -136.387 },
        { lat: 57.477, lng: -136.353 },
        { lat: 57.237, lng: -136.113 },
        { lat: 57.2, lng: -136.05 },
        { lat: 57.153, lng: -136.036 },
        { lat: 56.797, lng: -136.033 },
        { lat: 56.794, lng: -135.637 },
        { lat: 56.778, lng: -135.587 },
        { lat: 56.725, lng: -135.557 },
        { lat: 56.637, lng: -135.553 },
        { lat: 56.597, lng: -135.513 },
        { lat: 56.571, lng: -135.418 },
        { lat: 56.477, lng: -135.393 },
        { lat: 56.456, lng: -135.344 },
        { lat: 56.397, lng: -135.313 },
        { lat: 56.368, lng: -135.256 },
        { lat: 56.317, lng: -135.233 },
        { lat: 56.255, lng: -135.168 },
        { lat: 56.197, lng: -135.153 },
        { lat: 56.037, lng: -134.993 },
        { lat: 56.025, lng: -134.901 },
        { lat: 55.951, lng: -134.82 },
        { lat: 55.954, lng: -134.635 },
        { lat: 55.931, lng: -134.578 },
        { lat: 55.837, lng: -134.553 },
        { lat: 55.795, lng: -134.52 },
        { lat: 55.637, lng: -134.513 },
        { lat: 55.634, lng: -134.077 },
        { lat: 55.612, lng: -134.019 },
        { lat: 55.554, lng: -133.996 },
        { lat: 55.25, lng: -133.999 },
        { lat: 55.206, lng: -133.894 },
        { lat: 55.037, lng: -133.833 },
        { lat: 55.031, lng: -133.679 },
        { lat: 54.991, lng: -133.62 },
        { lat: 54.994, lng: -133.472 },
        { lat: 54.966, lng: -133.414 },
        { lat: 54.85, lng: -133.399 },
        { lat: 54.809, lng: -133.365 },
        { lat: 54.757, lng: -133.353 },
        { lat: 54.663, lng: -133.21 },
        { lat: 54.575, lng: -133.123 },
        { lat: 54.527, lng: -133.105 },
        { lat: 54.469, lng: -133.144 },
        { lat: 54.469, lng: -133.26 },
        { lat: 54.45, lng: -133.279 },
        { lat: 54.19, lng: -133.279 },
        { lat: 54.13, lng: -133.319 },
        { lat: 53.91, lng: -133.319 },
        { lat: 53.85, lng: -133.359 },
        { lat: 53.69, lng: -133.359 },
        { lat: 53.649, lng: -133.325 },
        { lat: 53.597, lng: -133.313 },
        { lat: 53.52, lng: -133.21 },
        { lat: 53.45, lng: -133.199 },
        { lat: 53.409, lng: -133.165 },
        { lat: 53.357, lng: -133.153 },
        { lat: 53.277, lng: -133.073 },
        { lat: 53.243, lng: -132.972 },
        { lat: 53.117, lng: -132.953 },
        { lat: 53.077, lng: -132.913 },
        { lat: 53.065, lng: -132.861 },
        { lat: 53.003, lng: -132.772 },
        { lat: 52.877, lng: -132.753 },
        { lat: 52.831, lng: -132.7 },
        { lat: 52.834, lng: -132.631 },
        { lat: 52.814, lng: -132.582 },
        { lat: 52.729, lng: -132.525 },
        { lat: 52.677, lng: -132.513 },
        { lat: 52.651, lng: -132.458 },
        { lat: 52.597, lng: -132.433 },
        { lat: 52.545, lng: -132.301 },
        { lat: 52.477, lng: -132.233 },
        { lat: 52.462, lng: -132.175 },
        { lat: 52.357, lng: -132.073 },
        { lat: 52.262, lng: -131.855 },
        { lat: 52.175, lng: -131.768 },
        { lat: 52.117, lng: -131.753 },
        { lat: 52.1, lng: -131.692 },
        { lat: 51.997, lng: -131.593 },
        { lat: 51.982, lng: -131.535 },
        { lat: 51.917, lng: -131.473 },
        { lat: 51.898, lng: -131.347 },
        { lat: 51.797, lng: -131.313 },
        { lat: 51.757, lng: -131.273 },
        { lat: 51.751, lng: -130.86 },
        { lat: 51.797, lng: -130.807 },
        { lat: 51.849, lng: -130.795 },
        { lat: 51.89, lng: -130.761 },
        { lat: 51.93, lng: -130.761 },
        { lat: 52.29, lng: -130.761 },
        { lat: 52.339, lng: -130.799 },
        { lat: 52.423, lng: -130.807 },
        { lat: 52.438, lng: -130.865 },
        { lat: 52.503, lng: -130.927 },
        { lat: 52.526, lng: -131.018 },
        { lat: 52.623, lng: -131.047 },
        { lat: 52.627, lng: -131.171 },
        { lat: 52.654, lng: -131.226 },
        { lat: 52.705, lng: -131.244 },
        { lat: 52.903, lng: -131.247 },
        { lat: 52.943, lng: -131.287 },
        { lat: 52.962, lng: -131.373 },
        { lat: 53.011, lng: -131.402 },
        { lat: 53.263, lng: -131.407 },
        { lat: 53.343, lng: -131.487 },
        { lat: 53.368, lng: -131.541 },
        { lat: 53.423, lng: -131.567 },
        { lat: 53.518, lng: -131.677 },
        { lat: 53.575, lng: -131.672 },
        { lat: 53.717, lng: -131.527 },
        { lat: 53.769, lng: -131.515 },
        { lat: 53.81, lng: -131.481 },
        { lat: 54.074, lng: -131.484 },
        { lat: 54.126, lng: -131.477 },
        { lat: 54.17, lng: -131.441 },
        { lat: 54.343, lng: -131.447 },
        { lat: 54.349, lng: -131.86 },
        { lat: 54.273, lng: -131.984 },
        { lat: 54.269, lng: -132.035 },
        { lat: 54.309, lng: -132.1 },
        { lat: 54.306, lng: -132.364 },
        { lat: 54.313, lng: -132.416 },
        { lat: 54.349, lng: -132.46 },
        { lat: 54.347, lng: -132.692 },
        { lat: 54.364, lng: -132.736 },
        { lat: 54.401, lng: -132.758 },
        { lat: 54.443, lng: -132.748 },
        { lat: 54.466, lng: -132.716 },
        { lat: 54.471, lng: -132.5 },
        { lat: 54.507, lng: -132.456 },
        { lat: 54.514, lng: -132.405 },
        { lat: 54.511, lng: -132.199 },
        { lat: 54.471, lng: -132.14 },
        { lat: 54.471, lng: -131.82 },
        { lat: 54.49, lng: -131.801 },
        { lat: 54.566, lng: -131.797 },
        { lat: 54.61, lng: -131.761 },
        { lat: 54.686, lng: -131.761 },
        { lat: 54.724, lng: -131.716 },
        { lat: 54.707, lng: -131.625 },
        { lat: 54.671, lng: -131.58 },
        { lat: 54.661, lng: -131.192 },
        { lat: 54.596, lng: -131.156 },
        { lat: 54.29, lng: -131.159 },
        { lat: 54.271, lng: -131.14 },
        { lat: 54.265, lng: -131.041 },
        { lat: 54.235, lng: -131.007 },
        { lat: 53.97, lng: -130.999 },
        { lat: 53.928, lng: -130.936 },
        { lat: 53.88, lng: -130.916 },
        { lat: 53.65, lng: -130.919 },
        { lat: 53.631, lng: -130.9 },
        { lat: 53.632, lng: -130.784 },
        { lat: 53.603, lng: -130.732 },
        { lat: 53.555, lng: -130.716 },
        { lat: 53.357, lng: -130.713 },
        { lat: 53.317, lng: -130.673 },
        { lat: 53.299, lng: -130.611 },
        { lat: 53.197, lng: -130.513 },
        { lat: 53.187, lng: -130.424 },
        { lat: 53.117, lng: -130.353 },
        { lat: 53.1, lng: -130.292 },
        { lat: 52.957, lng: -130.153 },
        { lat: 52.938, lng: -129.947 },
        { lat: 52.877, lng: -129.913 },
        { lat: 52.84, lng: -129.85 },
        { lat: 52.757, lng: -129.833 },
        { lat: 52.754, lng: -129.556 },
        { lat: 52.738, lng: -129.507 },
        { lat: 52.68, lng: -129.476 },
        { lat: 52.517, lng: -129.473 },
        { lat: 52.317, lng: -129.273 },
        { lat: 52.305, lng: -129.221 },
        { lat: 52.271, lng: -129.18 },
        { lat: 52.274, lng: -129.036 },
        { lat: 52.258, lng: -128.987 },
        { lat: 52.157, lng: -128.953 },
        { lat: 52.071, lng: -128.9 },
        { lat: 52.064, lng: -128.619 },
        { lat: 52.015, lng: -128.568 },
        { lat: 51.957, lng: -128.553 },
        { lat: 51.855, lng: -128.448 },
        { lat: 51.69, lng: -128.439 },
        { lat: 51.595, lng: -128.36 },
        { lat: 51.397, lng: -128.353 },
        { lat: 51.277, lng: -128.273 },
        { lat: 51.223, lng: -128.13 },
        { lat: 51.16, lng: -128.107 },
        { lat: 51.12, lng: -128.132 },
        { lat: 51.103, lng: -128.193 },
        { lat: 51.073, lng: -128.225 },
        { lat: 51.069, lng: -128.3 },
        { lat: 51.031, lng: -128.351 },
        { lat: 51.029, lng: -128.46 },
        { lat: 50.991, lng: -128.509 },
        { lat: 50.983, lng: -128.593 },
        { lat: 50.84, lng: -128.607 },
        { lat: 50.816, lng: -128.641 },
        { lat: 50.829, lng: -128.7 },
        { lat: 50.81, lng: -128.719 },
        { lat: 50.791, lng: -128.7 },
        { lat: 50.804, lng: -128.641 },
        { lat: 50.775, lng: -128.604 },
        { lat: 50.53, lng: -128.599 },
        { lat: 50.455, lng: -128.528 },
        { lat: 50.397, lng: -128.513 },
        { lat: 50.38, lng: -128.452 },
        { lat: 50.277, lng: -128.353 },
        { lat: 50.267, lng: -128.265 },
        { lat: 50.219, lng: -128.207 },
        { lat: 50.006, lng: -128.083 },
        { lat: 49.917, lng: -128.073 },
        { lat: 49.871, lng: -128.02 },
        { lat: 49.871, lng: -127.68 },
        { lat: 49.831, lng: -127.62 },
        { lat: 49.825, lng: -127.541 },
        { lat: 49.797, lng: -127.513 },
        { lat: 49.779, lng: -127.451 },
        { lat: 49.677, lng: -127.353 },
        { lat: 49.656, lng: -127.224 },
        { lat: 49.569, lng: -127.165 },
        { lat: 49.517, lng: -127.153 },
        { lat: 49.477, lng: -127.113 },
        { lat: 49.465, lng: -127.061 },
        { lat: 49.391, lng: -126.94 },
        { lat: 49.394, lng: -126.834 },
        { lat: 49.376, lng: -126.784 },
        { lat: 49.321, lng: -126.757 },
        { lat: 49.197, lng: -126.753 },
        { lat: 49.185, lng: -126.541 },
        { lat: 49.131, lng: -126.458 },
        { lat: 49.077, lng: -126.433 },
        { lat: 49.064, lng: -126.299 },
        { lat: 48.957, lng: -126.193 },
        { lat: 48.936, lng: -126.104 },
        { lat: 48.877, lng: -126.073 },
        { lat: 48.865, lng: -125.981 },
        { lat: 48.837, lng: -125.953 },
        { lat: 48.822, lng: -125.895 },
        { lat: 48.751, lng: -125.82 },
        { lat: 48.749, lng: -125.749 },
        { lat: 48.711, lng: -125.7 },
        { lat: 48.707, lng: -125.424 },
        { lat: 48.651, lng: -125.366 },
        { lat: 48.57, lng: -125.359 },
        { lat: 48.517, lng: -125.313 },
        { lat: 48.51, lng: -125.195 },
        { lat: 48.471, lng: -125.14 },
        { lat: 48.469, lng: -125.069 },
        { lat: 48.383, lng: -124.934 },
        { lat: 48.312, lng: -124.916 },
        { lat: 47.93, lng: -124.919 },
        { lat: 47.875, lng: -124.88 },
        { lat: 47.77, lng: -124.879 },
        { lat: 47.677, lng: -124.793 },
        { lat: 47.656, lng: -124.727 },
        { lat: 47.611, lng: -124.686 },
        { lat: 47.557, lng: -124.673 },
        { lat: 47.523, lng: -124.612 },
        { lat: 47.439, lng: -124.561 },
        { lat: 47.33, lng: -124.559 },
        { lat: 47.279, lng: -124.521 },
        { lat: 47.157, lng: -124.513 },
        { lat: 47.045, lng: -124.403 },
        { lat: 46.85, lng: -124.399 },
        { lat: 46.805, lng: -124.363 },
        { lat: 46.73, lng: -124.359 },
        { lat: 46.686, lng: -124.323 },
        { lat: 46.61, lng: -124.319 },
        { lat: 46.551, lng: -124.279 },
        { lat: 46.09, lng: -124.279 },
        { lat: 46.048, lng: -124.216 },
        { lat: 45.993, lng: -124.196 },
        { lat: 45.226, lng: -124.196 },
        { lat: 45.174, lng: -124.203 },
        { lat: 45.13, lng: -124.239 },
        { lat: 44.945, lng: -124.24 },
        { lat: 44.89, lng: -124.279 },
        { lat: 44.586, lng: -124.276 },
        { lat: 44.534, lng: -124.283 },
        { lat: 44.49, lng: -124.319 },
        { lat: 44.185, lng: -124.32 },
        { lat: 44.13, lng: -124.359 },
        { lat: 43.985, lng: -124.36 },
        { lat: 43.93, lng: -124.399 },
        { lat: 43.825, lng: -124.4 },
        { lat: 43.77, lng: -124.439 },
        { lat: 43.695, lng: -124.443 },
        { lat: 43.463, lng: -124.593 },
        { lat: 43.295, lng: -124.603 },
        { lat: 43.263, lng: -124.633 },
        { lat: 43.125, lng: -124.688 },
        { lat: 43.05, lng: -124.759 },
        { lat: 42.61, lng: -124.759 },
        { lat: 42.475, lng: -124.64 },
        { lat: 42.13, lng: -124.639 },
        { lat: 42.081, lng: -124.601 },
        { lat: 42.01, lng: -124.599 },
        { lat: 41.961, lng: -124.561 },
        { lat: 41.877, lng: -124.553 },
        { lat: 41.83, lng: -124.485 },
        { lat: 41.73, lng: -124.479 },
        { lat: 41.675, lng: -124.44 },
        { lat: 41.557, lng: -124.433 },
        { lat: 41.528, lng: -124.376 },
        { lat: 41.426, lng: -124.319 },
        { lat: 41.374, lng: -124.323 },
        { lat: 41.33, lng: -124.359 },
        { lat: 41.059, lng: -124.361 },
        { lat: 41.023, lng: -124.393 },
        { lat: 40.961, lng: -124.411 },
        { lat: 40.863, lng: -124.513 },
        { lat: 40.811, lng: -124.525 },
        { lat: 40.77, lng: -124.559 },
        { lat: 40.695, lng: -124.563 },
        { lat: 40.65, lng: -124.599 },
        { lat: 40.21, lng: -124.599 },
        { lat: 40.159, lng: -124.561 },
        { lat: 40.037, lng: -124.553 },
        { lat: 40.024, lng: -124.499 },
        { lat: 39.957, lng: -124.433 },
        { lat: 39.937, lng: -124.368 },
        { lat: 39.606, lng: -124.043 },
        { lat: 39.37, lng: -124.039 },
        { lat: 39.326, lng: -124.003 },
        { lat: 39.275, lng: -123.996 },
        { lat: 39.05, lng: -123.999 },
        { lat: 38.915, lng: -123.92 },
        { lat: 38.717, lng: -123.913 },
        { lat: 38.557, lng: -123.753 },
        { lat: 38.545, lng: -123.701 },
        { lat: 38.503, lng: -123.651 },
        { lat: 38.317, lng: -123.473 },
        { lat: 38.305, lng: -123.421 },
        { lat: 38.246, lng: -123.334 },
        { lat: 38.117, lng: -123.273 },
        { lat: 38.098, lng: -123.227 },
        { lat: 38.066, lng: -123.204 },
        { lat: 37.797, lng: -123.193 },
        { lat: 37.787, lng: -123.025 },
        { lat: 37.717, lng: -122.953 },
        { lat: 37.705, lng: -122.901 },
        { lat: 37.671, lng: -122.86 },
        { lat: 37.667, lng: -122.784 },
        { lat: 37.619, lng: -122.731 },
        { lat: 37.553, lng: -122.716 },
        { lat: 37.33, lng: -122.719 },
        { lat: 37.255, lng: -122.648 },
        { lat: 37.197, lng: -122.633 },
        { lat: 37.165, lng: -122.603 },
        { lat: 36.997, lng: -122.593 },
        { lat: 36.797, lng: -122.393 },
        { lat: 36.785, lng: -122.341 },
        { lat: 36.751, lng: -122.3 },
        { lat: 36.745, lng: -122.201 },
        { lat: 36.713, lng: -122.166 },
        { lat: 36.33, lng: -122.159 },
        { lat: 36.281, lng: -122.121 },
        { lat: 36.21, lng: -122.119 },
        { lat: 36.161, lng: -122.081 },
        { lat: 36.077, lng: -122.073 },
        { lat: 36.037, lng: -122.033 },
        { lat: 35.985, lng: -121.901 },
        { lat: 35.941, lng: -121.849 },
        { lat: 35.891, lng: -121.806 },
        { lat: 35.837, lng: -121.793 },
        { lat: 35.763, lng: -121.692 },
        { lat: 35.637, lng: -121.633 },
        { lat: 35.624, lng: -121.579 },
        { lat: 35.584, lng: -121.536 },
        { lat: 35.517, lng: -121.513 },
        { lat: 35.437, lng: -121.433 },
        { lat: 35.419, lng: -121.371 },
        { lat: 35.237, lng: -121.193 },
        { lat: 35.211, lng: -121.098 },
        { lat: 35.158, lng: -121.076 },
        { lat: 34.997, lng: -121.073 },
        { lat: 34.923, lng: -120.892 },
        { lat: 34.874, lng: -120.876 },
        { lat: 34.73, lng: -120.879 },
        { lat: 34.67, lng: -120.839 },
        { lat: 34.37, lng: -120.839 },
        { lat: 34.351, lng: -120.82 },
        { lat: 34.337, lng: -120.728 },
        { lat: 34.205, lng: -120.643 },
        { lat: 33.837, lng: -120.633 },
        { lat: 33.825, lng: -120.461 },
        { lat: 33.778, lng: -120.41 },
        { lat: 33.711, lng: -120.38 },
        { lat: 33.711, lng: -119.82 },
        { lat: 33.751, lng: -119.761 },
        { lat: 33.751, lng: -119.54 },
        { lat: 33.79, lng: -119.485 },
        { lat: 33.791, lng: -119.34 },
        { lat: 33.854, lng: -119.298 },
        { lat: 33.871, lng: -119.25 },
        { lat: 33.831, lng: -119.18 },
        { lat: 33.829, lng: -119.031 },
        { lat: 33.791, lng: -118.98 },
        { lat: 33.782, lng: -118.695 },
        { lat: 33.735, lng: -118.666 },
        { lat: 33.686, lng: -118.68 },
        { lat: 33.667, lng: -118.713 },
        { lat: 33.669, lng: -118.78 },
        { lat: 33.65, lng: -118.799 },
        { lat: 32.81, lng: -118.799 },
        { lat: 32.769, lng: -118.765 },
        { lat: 32.717, lng: -118.753 },
        { lat: 32.591, lng: -118.62 },
        { lat: 32.591, lng: -118.26 },
        { lat: 32.625, lng: -118.219 },
        { lat: 32.637, lng: -118.167 },
        { lat: 32.759, lng: -118.159 },
        { lat: 32.81, lng: -118.121 },
        { lat: 33.069, lng: -118.134 },
        { lat: 33.13, lng: -118.081 },
        { lat: 33.277, lng: -118.083 },
        { lat: 33.324, lng: -118.039 },
        { lat: 33.313, lng: -117.984 },
        { lat: 33.277, lng: -117.953 },
        { lat: 33.26, lng: -117.892 },
        { lat: 33.197, lng: -117.833 },
        { lat: 33.18, lng: -117.772 },
        { lat: 32.942, lng: -117.533 },
        { lat: 32.831, lng: -117.479 },
        { lat: 32.557, lng: -117.473 },
        { lat: 32.529, lng: -117.445 },
        { lat: 32.477, lng: -117.433 },
        { lat: 32.462, lng: -117.375 },
        { lat: 32.409, lng: -117.325 },
        { lat: 32.25, lng: -117.319 },
        { lat: 32.129, lng: -117.245 },
        { lat: 32.077, lng: -117.233 },
        { lat: 32.062, lng: -117.175 },
        { lat: 32.011, lng: -117.126 },
        { lat: 31.915, lng: -117.08 },
        { lat: 31.797, lng: -117.073 },
        { lat: 31.778, lng: -116.987 },
        { lat: 31.689, lng: -116.925 },
        { lat: 31.53, lng: -116.919 },
        { lat: 31.479, lng: -116.881 },
        { lat: 31.357, lng: -116.873 },
        { lat: 31.015, lng: -116.528 },
        { lat: 30.757, lng: -116.513 },
        { lat: 30.744, lng: -116.459 },
        { lat: 30.677, lng: -116.393 },
        { lat: 30.662, lng: -116.335 },
        { lat: 30.571, lng: -116.246 },
        { lat: 30.197, lng: -116.233 },
        { lat: 30.185, lng: -116.101 },
        { lat: 30.089, lng: -116.005 },
        { lat: 29.77, lng: -115.999 },
        { lat: 29.72, lng: -115.93 },
        { lat: 29.65, lng: -115.919 },
        { lat: 29.609, lng: -115.885 },
        { lat: 29.557, lng: -115.873 },
        { lat: 29.491, lng: -115.778 },
        { lat: 29.437, lng: -115.753 },
        { lat: 29.304, lng: -115.459 },
        { lat: 29.237, lng: -115.393 },
        { lat: 29.23, lng: -115.315 },
        { lat: 29.191, lng: -115.26 },
        { lat: 29.18, lng: -115.172 },
        { lat: 28.997, lng: -114.993 },
        { lat: 28.971, lng: -114.938 },
        { lat: 28.917, lng: -114.913 },
        { lat: 28.904, lng: -114.859 },
        { lat: 28.864, lng: -114.816 },
        { lat: 28.797, lng: -114.793 },
        { lat: 28.717, lng: -114.713 },
        { lat: 28.704, lng: -114.659 },
        { lat: 28.661, lng: -114.609 },
        { lat: 28.611, lng: -114.566 },
        { lat: 28.557, lng: -114.553 },
        { lat: 28.477, lng: -114.473 },
        { lat: 28.464, lng: -114.398 },
        { lat: 28.431, lng: -114.366 },
        { lat: 28.29, lng: -114.336 },
        { lat: 28.143, lng: -114.473 },
        { lat: 28.088, lng: -114.499 },
        { lat: 28.03, lng: -114.595 },
        { lat: 28.029, lng: -114.801 },
        { lat: 28.069, lng: -114.86 },
        { lat: 28.077, lng: -114.925 },
        { lat: 28.109, lng: -114.954 },
        { lat: 28.45, lng: -114.961 },
        { lat: 28.491, lng: -114.995 },
        { lat: 28.543, lng: -115.007 },
        { lat: 28.549, lng: -115.36 },
        { lat: 28.583, lng: -115.433 },
        { lat: 28.53, lng: -115.479 },
        { lat: 28.374, lng: -115.483 },
        { lat: 28.29, lng: -115.559 },
        { lat: 27.877, lng: -115.553 },
        { lat: 27.87, lng: -115.475 },
        { lat: 27.831, lng: -115.42 },
        { lat: 27.834, lng: -115.35 },
        { lat: 27.812, lng: -115.299 },
        { lat: 27.717, lng: -115.273 },
        { lat: 27.675, lng: -115.24 },
        { lat: 27.557, lng: -115.233 },
        { lat: 27.517, lng: -115.193 },
        { lat: 27.46, lng: -115.052 },
        { lat: 27.317, lng: -114.913 },
        { lat: 27.265, lng: -114.781 },
        { lat: 27.223, lng: -114.73 },
        { lat: 27.159, lng: -114.681 },
        { lat: 27.037, lng: -114.673 },
        { lat: 26.957, lng: -114.593 },
        { lat: 26.949, lng: -114.509 },
        { lat: 26.911, lng: -114.46 },
        { lat: 26.905, lng: -114.321 },
        { lat: 26.797, lng: -114.233 },
        { lat: 26.785, lng: -114.181 },
        { lat: 26.751, lng: -114.14 },
        { lat: 26.744, lng: -114.019 },
        { lat: 26.695, lng: -113.968 },
        { lat: 26.637, lng: -113.953 },
        { lat: 26.511, lng: -113.82 },
        { lat: 26.514, lng: -113.514 },
        { lat: 26.507, lng: -113.464 },
        { lat: 26.471, lng: -113.42 },
        { lat: 26.465, lng: -113.301 },
        { lat: 26.415, lng: -113.248 },
        { lat: 26.357, lng: -113.233 },
        { lat: 26.302, lng: -113.095 },
        { lat: 26.197, lng: -112.993 },
        { lat: 26.182, lng: -112.935 },
        { lat: 26.117, lng: -112.873 },
        { lat: 26.105, lng: -112.781 },
        { lat: 26.031, lng: -112.7 },
        { lat: 26.034, lng: -112.63 },
        { lat: 26.012, lng: -112.579 },
        { lat: 25.929, lng: -112.525 },
        { lat: 25.877, lng: -112.513 },
        { lat: 25.806, lng: -112.414 },
        { lat: 25.631, lng: -112.319 },
        { lat: 25.505, lng: -112.32 },
        { lat: 25.45, lng: -112.359 },
        { lat: 25.305, lng: -112.36 },
        { lat: 25.25, lng: -112.399 },
        { lat: 25.185, lng: -112.4 },
        { lat: 25.13, lng: -112.439 },
        { lat: 25.051, lng: -112.445 },
        { lat: 24.97, lng: -112.519 },
        { lat: 24.85, lng: -112.519 },
        { lat: 24.795, lng: -112.48 },
        { lat: 24.597, lng: -112.473 },
        { lat: 24.495, lng: -112.368 },
        { lat: 24.357, lng: -112.313 },
        { lat: 24.317, lng: -112.273 },
        { lat: 24.309, lng: -112.191 },
        { lat: 24.277, lng: -112.153 },
        { lat: 24.264, lng: -112.099 },
        { lat: 24.197, lng: -112.033 },
        { lat: 24.185, lng: -111.981 },
        { lat: 24.111, lng: -111.86 },
        { lat: 24.104, lng: -111.579 },
        { lat: 24.037, lng: -111.513 },
        { lat: 23.945, lng: -111.301 },
        { lat: 23.877, lng: -111.233 },
        { lat: 23.859, lng: -111.171 },
        { lat: 23.811, lng: -111.126 },
        { lat: 23.757, lng: -111.113 },
        { lat: 23.737, lng: -111.048 },
        { lat: 23.517, lng: -110.833 },
        { lat: 23.505, lng: -110.781 },
        { lat: 23.431, lng: -110.66 },
        { lat: 23.419, lng: -110.571 },
        { lat: 23.331, lng: -110.486 },
        { lat: 23.237, lng: -110.473 },
        { lat: 23.129, lng: -110.365 },
        { lat: 23.05, lng: -110.359 },
        { lat: 22.995, lng: -110.32 },
        { lat: 22.89, lng: -110.319 },
        { lat: 22.835, lng: -110.28 },
        { lat: 22.77, lng: -110.279 },
        { lat: 22.677, lng: -110.193 },
        { lat: 22.671, lng: -109.7 },
        { lat: 22.705, lng: -109.659 },
        { lat: 22.717, lng: -109.607 },
        { lat: 22.862, lng: -109.465 },
        { lat: 22.917, lng: -109.327 },
        { lat: 22.997, lng: -109.247 },
        { lat: 23.119, lng: -109.239 },
        { lat: 23.17, lng: -109.201 },
        { lat: 23.65, lng: -109.201 },
        { lat: 23.691, lng: -109.235 },
        { lat: 23.743, lng: -109.247 },
        { lat: 23.783, lng: -109.287 },
        { lat: 23.795, lng: -109.339 },
        { lat: 23.829, lng: -109.38 },
        { lat: 23.836, lng: -109.442 },
        { lat: 23.862, lng: -109.471 },
        { lat: 23.943, lng: -109.487 },
        { lat: 24.065, lng: -109.56 },
        { lat: 24.21, lng: -109.561 },
        { lat: 24.27, lng: -109.601 },
        { lat: 24.41, lng: -109.601 },
        { lat: 24.485, lng: -109.672 },
        { lat: 24.543, lng: -109.687 },
        { lat: 24.553, lng: -110.016 },
        { lat: 24.605, lng: -110.072 },
        { lat: 24.663, lng: -110.087 },
        { lat: 24.725, lng: -110.152 },
        { lat: 24.783, lng: -110.167 },
        { lat: 24.787, lng: -110.255 },
        { lat: 24.817, lng: -110.308 },
        { lat: 24.867, lng: -110.324 },
        { lat: 25.09, lng: -110.321 },
        { lat: 25.139, lng: -110.359 },
        { lat: 25.223, lng: -110.367 },
        { lat: 25.275, lng: -110.499 },
        { lat: 25.303, lng: -110.527 },
        { lat: 25.314, lng: -110.585 },
        { lat: 25.351, lng: -110.614 },
        { lat: 25.863, lng: -110.607 },
        { lat: 25.87, lng: -110.805 },
        { lat: 25.922, lng: -110.87 },
        { lat: 26.263, lng: -110.887 },
        { lat: 26.27, lng: -111.085 },
        { lat: 26.322, lng: -111.15 },
        { lat: 26.425, lng: -111.2 },
        { lat: 26.53, lng: -111.201 },
        { lat: 26.585, lng: -111.24 },
        { lat: 26.73, lng: -111.241 },
        { lat: 26.777, lng: -111.348 },
        { lat: 26.903, lng: -111.367 },
        { lat: 27.023, lng: -111.487 },
        { lat: 27.038, lng: -111.545 },
        { lat: 27.103, lng: -111.607 },
        { lat: 27.107, lng: -111.692 },
        { lat: 27.128, lng: -111.741 },
        { lat: 27.18, lng: -111.764 },
        { lat: 27.303, lng: -111.767 },
        { lat: 27.311, lng: -111.851 },
        { lat: 27.343, lng: -111.887 },
        { lat: 27.36, lng: -111.948 },
        { lat: 27.409, lng: -111.994 },
        { lat: 27.463, lng: -112.007 },
        { lat: 27.565, lng: -112.112 },
        { lat: 27.743, lng: -112.127 },
        { lat: 27.753, lng: -112.215 },
        { lat: 27.823, lng: -112.287 },
        { lat: 27.842, lng: -112.373 },
        { lat: 27.903, lng: -112.407 },
        { lat: 27.928, lng: -112.461 },
        { lat: 27.983, lng: -112.487 },
        { lat: 28.045, lng: -112.552 },
        { lat: 28.17, lng: -112.561 },
        { lat: 28.221, lng: -112.599 },
        { lat: 28.315, lng: -112.604 },
        { lat: 28.371, lng: -112.594 },
        { lat: 28.464, lng: -112.521 },
        { lat: 28.471, lng: -112.38 },
        { lat: 28.55, lng: -112.285 },
        { lat: 28.545, lng: -112.141 },
        { lat: 28.342, lng: -111.933 },
        { lat: 28.277, lng: -111.913 },
        { lat: 28.237, lng: -111.873 },
        { lat: 28.227, lng: -111.784 },
        { lat: 28.141, lng: -111.649 },
        { lat: 28.091, lng: -111.606 },
        { lat: 28.037, lng: -111.593 },
        { lat: 27.939, lng: -111.491 },
        { lat: 27.877, lng: -111.473 },
        { lat: 27.797, lng: -111.393 },
        { lat: 27.785, lng: -111.341 },
        { lat: 27.751, lng: -111.3 },
        { lat: 27.743, lng: -111.195 },
        { lat: 27.677, lng: -111.153 },
        { lat: 27.665, lng: -111.101 },
        { lat: 27.631, lng: -111.06 },
        { lat: 27.634, lng: -110.914 },
        { lat: 27.612, lng: -110.859 },
        { lat: 27.556, lng: -110.836 },
        { lat: 27.41, lng: -110.839 },
        { lat: 27.351, lng: -110.799 },
        { lat: 27.117, lng: -110.793 },
        { lat: 27.109, lng: -110.711 },
        { lat: 27.077, lng: -110.673 },
        { lat: 27.064, lng: -110.619 },
        { lat: 26.951, lng: -110.5 },
        { lat: 26.949, lng: -110.429 },
        { lat: 26.911, lng: -110.38 },
        { lat: 26.9, lng: -110.252 },
        { lat: 26.775, lng: -110.128 },
        { lat: 26.717, lng: -110.113 },
        { lat: 26.679, lng: -110.081 },
        { lat: 26.597, lng: -110.073 },
        { lat: 26.517, lng: -109.993 },
        { lat: 26.505, lng: -109.941 },
        { lat: 26.471, lng: -109.9 },
        { lat: 26.47, lng: -109.635 },
        { lat: 26.408, lng: -109.558 },
        { lat: 26.368, lng: -109.547 },
        { lat: 26.295, lng: -109.563 },
        { lat: 26.21, lng: -109.639 },
        { lat: 25.57, lng: -109.639 },
        { lat: 25.521, lng: -109.601 },
        { lat: 25.437, lng: -109.593 },
        { lat: 25.427, lng: -109.425 },
        { lat: 25.277, lng: -109.273 },
        { lat: 25.265, lng: -109.221 },
        { lat: 25.237, lng: -109.193 },
        { lat: 25.227, lng: -109.105 },
        { lat: 25.151, lng: -108.98 },
        { lat: 25.149, lng: -108.829 },
        { lat: 25.117, lng: -108.793 },
        { lat: 25.059, lng: -108.651 },
        { lat: 25.011, lng: -108.606 },
        { lat: 24.957, lng: -108.593 },
        { lat: 24.936, lng: -108.544 },
        { lat: 24.877, lng: -108.513 },
        { lat: 24.843, lng: -108.452 },
        { lat: 24.717, lng: -108.393 },
        { lat: 24.538, lng: -108.21 },
        { lat: 24.437, lng: -108.193 },
        { lat: 24.357, lng: -108.113 },
        { lat: 24.344, lng: -108.059 },
        { lat: 24.277, lng: -107.993 },
        { lat: 24.262, lng: -107.935 },
        { lat: 24.197, lng: -107.873 },
        { lat: 24.142, lng: -107.735 },
        { lat: 24.077, lng: -107.673 },
        { lat: 24.051, lng: -107.618 },
        { lat: 23.997, lng: -107.593 },
        { lat: 23.982, lng: -107.535 },
        { lat: 23.917, lng: -107.473 },
        { lat: 23.902, lng: -107.415 },
        { lat: 23.837, lng: -107.353 },
        { lat: 23.825, lng: -107.301 },
        { lat: 23.784, lng: -107.251 },
        { lat: 23.618, lng: -107.09 },
        { lat: 23.557, lng: -107.073 },
        { lat: 23.491, lng: -107.006 },
        { lat: 23.437, lng: -106.993 },
        { lat: 23.237, lng: -106.793 },
        { lat: 23.224, lng: -106.739 },
        { lat: 23.179, lng: -106.691 },
        { lat: 23.09, lng: -106.679 },
        { lat: 23.049, lng: -106.645 },
        { lat: 22.997, lng: -106.633 },
        { lat: 22.977, lng: -106.568 },
        { lat: 22.597, lng: -106.193 },
        { lat: 22.582, lng: -106.135 },
        { lat: 22.502, lng: -106.053 },
        { lat: 22.437, lng: -106.033 },
        { lat: 22.375, lng: -105.968 },
        { lat: 22.317, lng: -105.953 },
        { lat: 22.119, lng: -105.841 },
        { lat: 21.757, lng: -105.833 },
        { lat: 21.691, lng: -105.766 },
        { lat: 21.397, lng: -105.633 },
        { lat: 21.342, lng: -105.495 },
        { lat: 21.291, lng: -105.465 },
        { lat: 21.246, lng: -105.477 },
        { lat: 21.103, lng: -105.633 },
        { lat: 21.049, lng: -105.658 },
        { lat: 21.023, lng: -105.713 },
        { lat: 20.869, lng: -105.719 },
        { lat: 20.81, lng: -105.759 },
        { lat: 20.709, lng: -105.766 },
        { lat: 20.61, lng: -105.879 },
        { lat: 20.17, lng: -105.879 },
        { lat: 20.129, lng: -105.845 },
        { lat: 20.077, lng: -105.833 },
        { lat: 20.006, lng: -105.763 },
        { lat: 19.93, lng: -105.759 },
        { lat: 19.881, lng: -105.721 },
        { lat: 19.797, lng: -105.713 },
        { lat: 19.735, lng: -105.648 },
        { lat: 19.677, lng: -105.633 },
        { lat: 19.578, lng: -105.53 },
        { lat: 19.517, lng: -105.513 },
        { lat: 19.397, lng: -105.393 },
        { lat: 19.384, lng: -105.339 },
        { lat: 19.338, lng: -105.29 },
        { lat: 19.237, lng: -105.273 },
        { lat: 19.175, lng: -105.208 },
        { lat: 19.117, lng: -105.193 },
        { lat: 19.105, lng: -105.141 },
        { lat: 19.071, lng: -105.1 },
        { lat: 19.065, lng: -105.021 },
        { lat: 19.037, lng: -104.993 },
        { lat: 19.022, lng: -104.935 },
        { lat: 18.951, lng: -104.86 },
        { lat: 18.95, lng: -104.755 },
        { lat: 18.911, lng: -104.7 },
        { lat: 18.905, lng: -104.621 },
        { lat: 18.851, lng: -104.538 },
        { lat: 18.797, lng: -104.513 },
        { lat: 18.789, lng: -104.429 },
        { lat: 18.711, lng: -104.3 },
        { lat: 18.702, lng: -104.215 },
        { lat: 18.637, lng: -104.153 },
        { lat: 18.619, lng: -104.091 },
        { lat: 18.5, lng: -103.967 },
        { lat: 18.449, lng: -103.925 },
        { lat: 18.397, lng: -103.913 },
        { lat: 18.317, lng: -103.833 },
        { lat: 18.286, lng: -103.774 },
        { lat: 18.157, lng: -103.713 },
        { lat: 18.117, lng: -103.673 },
        { lat: 18.105, lng: -103.621 },
        { lat: 18.071, lng: -103.58 },
        { lat: 18.067, lng: -103.505 },
        { lat: 18.031, lng: -103.46 },
        { lat: 18.031, lng: -103.359 },
        { lat: 17.991, lng: -103.3 },
        { lat: 17.989, lng: -103.189 },
        { lat: 17.877, lng: -102.993 },
        { lat: 17.869, lng: -102.909 },
        { lat: 17.831, lng: -102.86 },
        { lat: 17.83, lng: -102.715 },
        { lat: 17.791, lng: -102.66 },
        { lat: 17.79, lng: -102.555 },
        { lat: 17.751, lng: -102.5 },
        { lat: 17.749, lng: -102.429 },
        { lat: 17.711, lng: -102.38 },
        { lat: 17.707, lng: -102.025 },
        { lat: 17.655, lng: -101.968 },
        { lat: 17.597, lng: -101.953 },
        { lat: 17.437, lng: -101.793 },
        { lat: 17.414, lng: -101.686 },
        { lat: 17.371, lng: -101.646 },
        { lat: 17.317, lng: -101.633 },
        { lat: 17.184, lng: -101.339 },
        { lat: 17.077, lng: -101.233 },
        { lat: 17.025, lng: -101.101 },
        { lat: 16.997, lng: -101.073 },
        { lat: 16.989, lng: -100.989 },
        { lat: 16.951, lng: -100.94 },
        { lat: 16.949, lng: -100.869 },
        { lat: 16.911, lng: -100.82 },
        { lat: 16.909, lng: -100.749 },
        { lat: 16.871, lng: -100.7 },
        { lat: 16.869, lng: -100.629 },
        { lat: 16.797, lng: -100.513 },
        { lat: 16.789, lng: -100.429 },
        { lat: 16.751, lng: -100.38 },
        { lat: 16.747, lng: -100.264 },
        { lat: 16.717, lng: -100.233 },
        { lat: 16.7, lng: -100.172 },
        { lat: 16.637, lng: -100.113 },
        { lat: 16.62, lng: -100.052 },
        { lat: 16.511, lng: -99.94 },
        { lat: 16.51, lng: -99.835 },
        { lat: 16.471, lng: -99.78 },
        { lat: 16.47, lng: -99.555 },
        { lat: 16.431, lng: -99.5 },
        { lat: 16.43, lng: -99.355 },
        { lat: 16.391, lng: -99.3 },
        { lat: 16.387, lng: -99.185 },
        { lat: 16.311, lng: -99.06 },
        { lat: 16.307, lng: -98.945 },
        { lat: 16.117, lng: -98.753 },
        { lat: 16.105, lng: -98.701 },
        { lat: 16.071, lng: -98.66 },
        { lat: 16.069, lng: -98.549 },
        { lat: 16.031, lng: -98.5 },
        { lat: 16.025, lng: -98.381 },
        { lat: 15.957, lng: -98.313 },
        { lat: 15.947, lng: -98.224 },
        { lat: 15.877, lng: -98.153 },
        { lat: 15.862, lng: -98.095 },
        { lat: 15.751, lng: -97.98 },
        { lat: 15.754, lng: -97.636 },
        { lat: 15.747, lng: -97.584 },
        { lat: 15.711, lng: -97.54 },
        { lat: 15.71, lng: -97.355 },
        { lat: 15.637, lng: -97.273 },
        { lat: 15.545, lng: -97.061 },
        { lat: 15.517, lng: -97.033 },
        { lat: 15.509, lng: -96.911 },
        { lat: 15.471, lng: -96.86 },
        { lat: 15.47, lng: -96.715 },
        { lat: 15.431, lng: -96.66 },
        { lat: 15.431, lng: -96.34 },
        { lat: 15.431, lng: -96.3 },
        { lat: 15.471, lng: -96.24 },
        { lat: 15.471, lng: -96.02 },
        { lat: 15.625, lng: -95.739 },
        { lat: 15.631, lng: -95.66 },
        { lat: 15.707, lng: -95.535 },
        { lat: 15.711, lng: -95.42 },
        { lat: 15.749, lng: -95.369 },
        { lat: 15.757, lng: -95.247 },
        { lat: 15.785, lng: -95.219 },
        { lat: 15.797, lng: -95.167 },
        { lat: 15.851, lng: -95.142 },
        { lat: 15.877, lng: -95.087 },
        { lat: 15.94, lng: -95.028 },
        { lat: 15.957, lng: -94.967 },
        { lat: 15.987, lng: -94.935 },
        { lat: 15.989, lng: -94.629 },
        { lat: 15.917, lng: -94.513 },
        { lat: 15.907, lng: -94.425 },
        { lat: 15.831, lng: -94.3 },
        { lat: 15.83, lng: -94.235 },
        { lat: 15.791, lng: -94.18 },
        { lat: 15.785, lng: -94.101 },
        { lat: 15.757, lng: -94.073 },
        { lat: 15.74, lng: -94.012 },
        { lat: 15.677, lng: -93.953 },
        { lat: 15.662, lng: -93.895 },
        { lat: 15.597, lng: -93.833 },
        { lat: 15.58, lng: -93.772 },
        { lat: 15.477, lng: -93.673 },
        { lat: 15.457, lng: -93.608 },
        { lat: 15.317, lng: -93.473 },
        { lat: 15.305, lng: -93.421 },
        { lat: 15.263, lng: -93.37 },
        { lat: 15.211, lng: -93.326 },
        { lat: 15.157, lng: -93.313 },
        { lat: 15.142, lng: -93.255 },
        { lat: 15.037, lng: -93.153 },
        { lat: 15.016, lng: -93.087 },
        { lat: 14.971, lng: -93.046 },
        { lat: 14.917, lng: -93.033 },
        { lat: 14.317, lng: -92.433 },
        { lat: 14.304, lng: -92.379 },
        { lat: 14.237, lng: -92.313 },
        { lat: 14.222, lng: -92.255 },
        { lat: 14.077, lng: -92.113 },
        { lat: 14.062, lng: -92.055 },
        { lat: 13.997, lng: -91.993 },
        { lat: 13.984, lng: -91.939 },
        { lat: 13.917, lng: -91.873 },
        { lat: 13.9, lng: -91.812 },
        { lat: 13.837, lng: -91.753 },
        { lat: 13.829, lng: -91.669 },
        { lat: 13.791, lng: -91.62 },
        { lat: 13.789, lng: -91.549 },
        { lat: 13.751, lng: -91.5 },
        { lat: 13.749, lng: -91.429 },
        { lat: 13.711, lng: -91.38 },
        { lat: 13.709, lng: -90.671 },
        { lat: 13.597, lng: -90.473 },
        { lat: 13.587, lng: -90.385 },
        { lat: 13.477, lng: -90.193 },
        { lat: 13.464, lng: -90.139 },
        { lat: 13.412, lng: -90.059 },
        { lat: 13.357, lng: -90.033 },
        { lat: 13.311, lng: -89.98 },
        { lat: 13.314, lng: -89.675 },
        { lat: 13.307, lng: -89.624 },
        { lat: 13.271, lng: -89.58 },
        { lat: 13.269, lng: -89.431 },
        { lat: 13.111, lng: -89.18 },
        { lat: 13.107, lng: -89.105 },
        { lat: 13.031, lng: -88.98 },
        { lat: 13.029, lng: -88.831 },
        { lat: 12.991, lng: -88.78 },
        { lat: 12.987, lng: -88.704 },
        { lat: 12.951, lng: -88.66 },
        { lat: 12.943, lng: -87.915 },
        { lat: 12.88, lng: -87.876 },
        { lat: 12.677, lng: -87.873 },
        { lat: 12.664, lng: -87.819 },
        { lat: 12.597, lng: -87.753 },
        { lat: 12.577, lng: -87.688 },
        { lat: 12.237, lng: -87.353 },
        { lat: 12.222, lng: -87.295 },
        { lat: 12.117, lng: -87.193 },
        { lat: 12.065, lng: -87.061 },
        { lat: 12.023, lng: -87.011 },
        { lat: 11.969, lng: -86.965 },
        { lat: 11.837, lng: -86.913 },
        { lat: 11.739, lng: -86.811 },
        { lat: 11.677, lng: -86.793 },
        { lat: 11.615, lng: -86.728 },
        { lat: 11.557, lng: -86.713 },
        { lat: 11.517, lng: -86.673 },
        { lat: 11.499, lng: -86.611 },
        { lat: 11.397, lng: -86.513 },
        { lat: 11.379, lng: -86.451 },
        { lat: 11.157, lng: -86.233 },
        { lat: 11.123, lng: -86.172 },
        { lat: 11.072, lng: -86.156 },
        { lat: 10.69, lng: -86.159 },
        { lat: 10.671, lng: -86.14 },
        { lat: 10.664, lng: -86.059 },
        { lat: 10.616, lng: -86.028 },
        { lat: 10.572, lng: -86.039 },
        { lat: 10.53, lng: -86.079 },
        { lat: 10.13, lng: -86.079 },
        { lat: 10.081, lng: -86.041 },
        { lat: 10.01, lng: -86.039 },
        { lat: 9.961, lng: -86.001 },
        { lat: 9.877, lng: -85.993 },
        { lat: 9.778, lng: -85.89 },
        { lat: 9.717, lng: -85.873 },
        { lat: 9.705, lng: -85.821 },
        { lat: 9.671, lng: -85.78 },
        { lat: 9.667, lng: -85.705 },
        { lat: 9.631, lng: -85.66 },
        { lat: 9.622, lng: -85.535 },
        { lat: 9.462, lng: -85.373 },
        { lat: 9.397, lng: -85.353 },
        { lat: 9.351, lng: -85.3 },
        { lat: 9.351, lng: -84.9 },
        { lat: 9.378, lng: -84.839 },
        { lat: 9.311, lng: -84.74 },
        { lat: 9.31, lng: -84.555 },
        { lat: 9.271, lng: -84.5 },
        { lat: 9.264, lng: -84.419 },
        { lat: 9.157, lng: -84.313 },
        { lat: 9.147, lng: -84.224 },
        { lat: 9.117, lng: -84.193 },
        { lat: 9.1, lng: -84.132 },
        { lat: 9.037, lng: -84.073 },
        { lat: 9.022, lng: -84.015 },
        { lat: 8.938, lng: -83.925 },
        { lat: 8.896, lng: -83.908 },
        { lat: 8.629, lng: -83.919 },
        { lat: 8.57, lng: -83.959 },
        { lat: 8.397, lng: -83.953 },
        { lat: 8.231, lng: -83.78 },
        { lat: 8.231, lng: -83.64 },
        { lat: 8.191, lng: -83.58 },
        { lat: 8.182, lng: -83.375 },
        { lat: 8.117, lng: -83.313 },
        { lat: 8.099, lng: -83.251 },
        { lat: 8.018, lng: -83.17 },
        { lat: 7.957, lng: -83.153 },
        { lat: 7.921, lng: -83.121 },
        { lat: 7.837, lng: -83.113 },
        { lat: 7.831, lng: -82.66 },
        { lat: 7.9, lng: -82.633 },
        { lat: 7.925, lng: -82.588 },
        { lat: 7.911, lng: -82.541 },
        { lat: 7.871, lng: -82.5 },
        { lat: 7.871, lng: -82.14 },
        { lat: 7.941, lng: -82.088 },
        { lat: 7.942, lng: -82.015 },
        { lat: 7.892, lng: -81.956 },
        { lat: 7.85, lng: -81.945 },
        { lat: 7.8, lng: -81.972 },
        { lat: 7.783, lng: -82.033 },
        { lat: 7.731, lng: -82.045 },
        { lat: 7.69, lng: -82.079 },
        { lat: 7.29, lng: -82.079 },
        { lat: 7.245, lng: -82.043 },
        { lat: 7.17, lng: -82.039 },
        { lat: 7.126, lng: -82.003 },
        { lat: 7.05, lng: -81.999 },
        { lat: 7.031, lng: -81.98 },
        { lat: 7.031, lng: -81.62 },
        { lat: 7.107, lng: -81.535 },
        { lat: 7.117, lng: -81.407 },
        { lat: 7.235, lng: -81.393 },
        { lat: 7.274, lng: -81.326 },
        { lat: 7.274, lng: -81.192 },
        { lat: 7.251, lng: -81.138 },
        { lat: 7.196, lng: -81.116 },
        { lat: 7.05, lng: -81.119 },
        { lat: 6.991, lng: -81.06 },
        { lat: 6.991, lng: -80.62 },
        { lat: 7.031, lng: -80.56 },
        { lat: 7.031, lng: -80.26 },
        { lat: 7.157, lng: -80.127 },
        { lat: 7.223, lng: -80.085 },
        { lat: 7.237, lng: -79.847 },
        { lat: 7.29, lng: -79.801 },
        { lat: 7.77, lng: -79.801 },
        { lat: 7.845, lng: -79.872 },
        { lat: 7.903, lng: -79.887 },
        { lat: 8.007, lng: -79.999 },
        { lat: 8.073, lng: -80.013 },
        { lat: 8.142, lng: -79.945 },
        { lat: 8.197, lng: -79.807 },
        { lat: 8.342, lng: -79.665 },
        { lat: 8.357, lng: -79.567 },
        { lat: 8.475, lng: -79.56 },
        { lat: 8.53, lng: -79.521 },
        { lat: 8.605, lng: -79.517 },
        { lat: 8.669, lng: -79.449 },
        { lat: 8.663, lng: -79.355 },
        { lat: 8.603, lng: -79.317 },
        { lat: 7.997, lng: -79.313 },
        { lat: 7.991, lng: -78.98 },
        { lat: 8.027, lng: -78.936 },
        { lat: 8.034, lng: -78.881 },
        { lat: 8.034, lng: -78.713 },
        { lat: 8.011, lng: -78.658 },
        { lat: 7.958, lng: -78.636 },
        { lat: 7.797, lng: -78.633 },
        { lat: 7.769, lng: -78.605 },
        { lat: 7.637, lng: -78.553 },
        { lat: 7.571, lng: -78.486 },
        { lat: 7.477, lng: -78.473 },
        { lat: 7.415, lng: -78.408 },
        { lat: 7.277, lng: -78.353 },
        { lat: 7.211, lng: -78.258 },
        { lat: 7.157, lng: -78.233 },
        { lat: 6.957, lng: -78.033 },
        { lat: 6.944, lng: -77.979 },
        { lat: 6.899, lng: -77.931 },
        { lat: 6.77, lng: -77.919 },
        { lat: 6.721, lng: -77.881 },
        { lat: 6.637, lng: -77.873 },
        { lat: 6.624, lng: -77.819 },
        { lat: 6.584, lng: -77.776 },
        { lat: 6.517, lng: -77.753 },
        { lat: 6.435, lng: -77.68 },
        { lat: 5.97, lng: -77.679 },
        { lat: 5.869, lng: -77.612 },
        { lat: 5.803, lng: -77.648 },
        { lat: 5.783, lng: -77.713 },
        { lat: 5.731, lng: -77.725 },
        { lat: 5.69, lng: -77.759 },
        { lat: 5.33, lng: -77.759 },
        { lat: 5.277, lng: -77.713 },
        { lat: 5.251, lng: -77.618 },
        { lat: 5.157, lng: -77.593 },
        { lat: 5.119, lng: -77.561 },
        { lat: 4.628, lng: -77.556 },
        { lat: 4.569, lng: -77.578 },
        { lat: 4.543, lng: -77.633 },
        { lat: 4.489, lng: -77.658 },
        { lat: 4.463, lng: -77.713 },
        { lat: 4.41, lng: -77.759 },
        { lat: 4.01, lng: -77.759 },
        { lat: 3.991, lng: -77.74 },
        { lat: 3.983, lng: -77.675 },
        { lat: 3.955, lng: -77.647 },
        { lat: 3.81, lng: -77.639 },
        { lat: 3.707, lng: -77.55 },
        { lat: 3.625, lng: -77.56 },
        { lat: 3.56, lng: -77.612 },
        { lat: 3.543, lng: -77.673 },
        { lat: 3.425, lng: -77.68 },
        { lat: 3.383, lng: -77.713 },
        { lat: 3.292, lng: -77.736 },
        { lat: 3.267, lng: -77.788 },
        { lat: 3.269, lng: -77.86 },
        { lat: 3.223, lng: -77.913 },
        { lat: 3.031, lng: -77.925 },
        { lat: 2.983, lng: -77.993 },
        { lat: 2.926, lng: -78.022 },
        { lat: 2.906, lng: -78.073 },
        { lat: 2.915, lng: -78.119 },
        { lat: 2.983, lng: -78.167 },
        { lat: 2.983, lng: -78.193 },
        { lat: 2.93, lng: -78.239 },
        { lat: 2.874, lng: -78.226 },
        { lat: 2.837, lng: -78.25 },
        { lat: 2.823, lng: -78.393 },
        { lat: 2.72, lng: -78.47 },
        { lat: 2.703, lng: -78.633 },
        { lat: 2.64, lng: -78.692 },
        { lat: 2.623, lng: -78.753 },
        { lat: 2.583, lng: -78.793 },
        { lat: 2.531, lng: -78.805 },
        { lat: 2.41, lng: -78.879 },
        { lat: 2.108, lng: -78.876 },
        { lat: 2.06, lng: -78.89 },
        { lat: 2.027, lng: -78.948 },
        { lat: 2.029, lng: -79.06 },
        { lat: 1.995, lng: -79.101 },
        { lat: 1.983, lng: -79.153 },
        { lat: 1.863, lng: -79.233 },
        { lat: 1.465, lng: -79.24 },
        { lat: 1.326, lng: -79.326 },
        { lat: 1.27, lng: -79.435 },
        { lat: 1.269, lng: -79.66 },
        { lat: 1.19, lng: -79.755 },
        { lat: 1.189, lng: -79.9 },
        { lat: 1.033, lng: -80.144 },
        { lat: 1.023, lng: -80.273 },
        { lat: 0.971, lng: -80.285 },
        { lat: 0.943, lng: -80.313 },
        { lat: 0.49, lng: -80.319 },
        { lat: 0.409, lng: -80.245 },
        { lat: 0.334, lng: -80.243 },
        { lat: 0.201, lng: -80.327 },
        { lat: 0.041, lng: -80.491 },
        { lat: 0.023, lng: -80.553 },
        { lat: -0.017, lng: -80.593 },
        { lat: -0.078, lng: -80.61 },
        { lat: -0.137, lng: -80.673 },
        { lat: -0.295, lng: -80.68 },
        { lat: -0.35, lng: -80.719 },
        { lat: -0.638, lng: -80.73 },
        { lat: -0.729, lng: -80.829 },
        { lat: -0.737, lng: -81.033 },
        { lat: -0.83, lng: -81.119 },
        { lat: -1.31, lng: -81.119 },
        { lat: -1.405, lng: -81.04 },
        { lat: -1.83, lng: -81.039 },
        { lat: -1.891, lng: -81.012 },
        { lat: -1.964, lng: -81.059 },
        { lat: -1.971, lng: -81.18 },
        { lat: -1.99, lng: -81.199 },
        { lat: -2.403, lng: -81.193 },
        { lat: -2.465, lng: -81.128 },
        { lat: -2.523, lng: -81.113 },
        { lat: -2.618, lng: -80.855 },
        { lat: -2.843, lng: -80.633 },
        { lat: -2.856, lng: -80.579 },
        { lat: -2.898, lng: -80.529 },
        { lat: -2.965, lng: -80.48 },
        { lat: -3.095, lng: -80.48 },
        { lat: -3.272, lng: -80.579 },
        { lat: -3.293, lng: -80.628 },
        { lat: -3.297, lng: -80.713 },
        { lat: -3.479, lng: -80.891 },
        { lat: -3.497, lng: -80.953 },
        { lat: -3.577, lng: -81.033 },
        { lat: -3.642, lng: -81.053 },
        { lat: -3.817, lng: -81.233 },
        { lat: -3.869, lng: -81.245 },
        { lat: -3.92, lng: -81.287 },
        { lat: -4.0, lng: -81.372 },
        { lat: -4.017, lng: -81.433 },
        { lat: -4.057, lng: -81.473 },
        { lat: -4.215, lng: -81.48 },
        { lat: -4.27, lng: -81.519 },
        { lat: -4.415, lng: -81.52 },
        { lat: -4.47, lng: -81.559 },
        { lat: -4.91, lng: -81.559 },
        { lat: -5.079, lng: -81.401 },
        { lat: -5.443, lng: -81.393 },
        { lat: -5.483, lng: -81.353 },
        { lat: -5.501, lng: -81.291 },
        { lat: -5.546, lng: -81.265 },
        { lat: -5.592, lng: -81.276 },
        { lat: -5.67, lng: -81.359 },
        { lat: -5.891, lng: -81.359 },
        { lat: -5.95, lng: -81.399 },
        { lat: -6.15, lng: -81.399 },
        { lat: -6.191, lng: -81.365 },
        { lat: -6.243, lng: -81.353 },
        { lat: -6.363, lng: -81.233 },
        { lat: -6.418, lng: -81.095 },
        { lat: -6.523, lng: -80.993 },
        { lat: -6.535, lng: -80.941 },
        { lat: -6.563, lng: -80.913 },
        { lat: -6.856, lng: -80.299 },
        { lat: -6.94, lng: -80.207 },
        { lat: -6.995, lng: -80.163 },
        { lat: -7.083, lng: -80.153 },
        { lat: -7.163, lng: -80.073 },
        { lat: -7.194, lng: -80.014 },
        { lat: -7.243, lng: -79.993 },
        { lat: -7.311, lng: -79.925 },
        { lat: -7.403, lng: -79.913 },
        { lat: -7.509, lng: -79.806 },
        { lat: -7.67, lng: -79.799 },
        { lat: -7.789, lng: -79.686 },
        { lat: -7.87, lng: -79.679 },
        { lat: -7.991, lng: -79.605 },
        { lat: -8.043, lng: -79.593 },
        { lat: -8.458, lng: -79.173 },
        { lat: -8.643, lng: -79.153 },
        { lat: -8.683, lng: -79.113 },
        { lat: -8.7, lng: -79.052 },
        { lat: -8.785, lng: -78.968 },
        { lat: -8.99, lng: -78.959 },
        { lat: -9.075, lng: -78.883 },
        { lat: -9.19, lng: -78.879 },
        { lat: -9.235, lng: -78.843 },
        { lat: -9.31, lng: -78.839 },
        { lat: -9.351, lng: -78.805 },
        { lat: -9.403, lng: -78.793 },
        { lat: -9.465, lng: -78.728 },
        { lat: -9.523, lng: -78.713 },
        { lat: -9.549, lng: -78.658 },
        { lat: -9.634, lng: -78.603 },
        { lat: -9.79, lng: -78.599 },
        { lat: -10.035, lng: -78.443 },
        { lat: -10.15, lng: -78.439 },
        { lat: -10.201, lng: -78.401 },
        { lat: -10.323, lng: -78.393 },
        { lat: -10.351, lng: -78.365 },
        { lat: -10.403, lng: -78.353 },
        { lat: -10.465, lng: -78.288 },
        { lat: -10.523, lng: -78.273 },
        { lat: -10.589, lng: -78.206 },
        { lat: -10.683, lng: -78.193 },
        { lat: -10.745, lng: -78.128 },
        { lat: -10.803, lng: -78.113 },
        { lat: -10.902, lng: -78.01 },
        { lat: -10.963, lng: -77.993 },
        { lat: -11.022, lng: -77.93 },
        { lat: -11.083, lng: -77.913 },
        { lat: -11.115, lng: -77.883 },
        { lat: -11.483, lng: -77.873 },
        { lat: -11.523, lng: -77.833 },
        { lat: -11.578, lng: -77.695 },
        { lat: -11.643, lng: -77.633 },
        { lat: -11.658, lng: -77.575 },
        { lat: -11.745, lng: -77.488 },
        { lat: -11.803, lng: -77.473 },
        { lat: -11.885, lng: -77.4 },
        { lat: -12.03, lng: -77.399 },
        { lat: -12.09, lng: -77.359 },
        { lat: -12.283, lng: -77.353 },
        { lat: -12.303, lng: -77.288 },
        { lat: -12.349, lng: -77.246 },
        { lat: -12.403, lng: -77.233 },
        { lat: -12.443, lng: -77.193 },
        { lat: -12.461, lng: -77.131 },
        { lat: -12.582, lng: -77.01 },
        { lat: -12.723, lng: -76.993 },
        { lat: -12.821, lng: -76.891 },
        { lat: -12.963, lng: -76.833 },
        { lat: -13.069, lng: -76.726 },
        { lat: -13.19, lng: -76.719 },
        { lat: -13.231, lng: -76.685 },
        { lat: -13.283, lng: -76.673 },
        { lat: -13.418, lng: -76.533 },
        { lat: -13.483, lng: -76.513 },
        { lat: -13.514, lng: -76.477 },
        { lat: -13.588, lng: -76.48 },
        { lat: -13.617, lng: -76.553 },
        { lat: -13.67, lng: -76.599 },
        { lat: -14.07, lng: -76.599 },
        { lat: -14.145, lng: -76.528 },
        { lat: -14.203, lng: -76.513 },
        { lat: -14.234, lng: -76.483 },
        { lat: -14.363, lng: -76.473 },
        { lat: -14.378, lng: -76.415 },
        { lat: -14.431, lng: -76.365 },
        { lat: -14.563, lng: -76.353 },
        { lat: -14.576, lng: -76.299 },
        { lat: -14.621, lng: -76.247 },
        { lat: -14.671, lng: -76.205 },
        { lat: -14.883, lng: -76.113 },
        { lat: -14.9, lng: -76.052 },
        { lat: -15.003, lng: -75.953 },
    ],
    [
        { lat: 52.306, lng: -79.078 },
        { lat: 52.334, lng: -79.146 },
        { lat: 52.463, lng: -79.207 },
        { lat: 52.467, lng: -79.295 },
        { lat: 52.497, lng: -79.348 },
        { lat: 52.546, lng: -79.364 },
        { lat: 52.73, lng: -79.361 },
        { lat: 52.783, lng: -79.407 },
        { lat: 52.787, lng: -79.647 },
        { lat: 52.831, lng: -79.694 },
        { lat: 52.892, lng: -79.677 },
        { lat: 52.93, lng: -79.601 },
        { lat: 53.243, lng: -79.603 },
        { lat: 53.292, lng: -79.581 },
        { lat: 53.317, lng: -79.527 },
        { lat: 53.475, lng: -79.52 },
        { lat: 53.53, lng: -79.481 },
        { lat: 53.703, lng: -79.487 },
        { lat: 53.743, lng: -79.527 },
        { lat: 53.762, lng: -79.613 },
        { lat: 53.811, lng: -79.642 },
        { lat: 53.89, lng: -79.641 },
        { lat: 53.934, lng: -79.677 },
        { lat: 53.985, lng: -79.684 },
        { lat: 54.02, lng: -79.673 },
        { lat: 54.044, lng: -79.639 },
        { lat: 54.034, lng: -79.585 },
        { lat: 53.976, lng: -79.504 },
        { lat: 53.877, lng: -79.473 },
        { lat: 53.863, lng: -79.396 },
        { lat: 53.826, lng: -79.364 },
        { lat: 53.53, lng: -79.359 },
        { lat: 53.475, lng: -79.32 },
        { lat: 53.33, lng: -79.319 },
        { lat: 53.205, lng: -79.203 },
        { lat: 53.05, lng: -79.199 },
        { lat: 52.99, lng: -79.159 },
        { lat: 52.81, lng: -79.159 },
        { lat: 52.731, lng: -79.086 },
        { lat: 52.677, lng: -79.073 },
        { lat: 52.639, lng: -79.041 },
        { lat: 52.57, lng: -79.039 },
        { lat: 52.515, lng: -79.0 },
        { lat: 52.351, lng: -79.005 },
        { lat: 52.32, lng: -79.03 },
        { lat: 52.306, lng: -79.078 },
    ],
    [
        { lat: 46.069, lng: -61.861 },
        { lat: 46.084, lng: -61.918 },
        { lat: 46.143, lng: -61.933 },
        { lat: 46.187, lng: -61.896 },
        { lat: 46.193, lng: -61.818 },
        { lat: 46.157, lng: -61.784 },
        { lat: 46.122, lng: -61.785 },
        { lat: 46.078, lng: -61.815 },
        { lat: 46.069, lng: -61.861 },
    ],
    [
        { lat: 44.829, lng: -66.098 },
        { lat: 44.838, lng: -66.157 },
        { lat: 44.893, lng: -66.176 },
        { lat: 44.944, lng: -66.141 },
        { lat: 44.953, lng: -66.073 },
        { lat: 44.925, lng: -66.033 },
        { lat: 44.884, lng: -66.026 },
        { lat: 44.84, lng: -66.052 },
        { lat: 44.829, lng: -66.098 },
    ],
    [
        { lat: 44.539, lng: -66.483 },
        { lat: 44.543, lng: -66.535 },
        { lat: 44.587, lng: -66.563 },
        { lat: 44.645, lng: -66.557 },
        { lat: 44.69, lng: -66.521 },
        { lat: 44.797, lng: -66.524 },
        { lat: 44.848, lng: -66.504 },
        { lat: 44.874, lng: -66.442 },
        { lat: 44.873, lng: -66.237 },
        { lat: 44.856, lng: -66.202 },
        { lat: 44.813, lng: -66.185 },
        { lat: 44.761, lng: -66.206 },
        { lat: 44.716, lng: -66.259 },
        { lat: 44.703, lng: -66.313 },
        { lat: 44.539, lng: -66.483 },
    ],
    [
        { lat: 28.872, lng: -112.851 },
        { lat: 28.886, lng: -112.898 },
        { lat: 28.934, lng: -112.922 },
        { lat: 29.25, lng: -112.921 },
        { lat: 29.339, lng: -112.999 },
        { lat: 29.503, lng: -113.007 },
        { lat: 29.524, lng: -113.136 },
        { lat: 29.623, lng: -113.167 },
        { lat: 29.743, lng: -113.287 },
        { lat: 29.755, lng: -113.339 },
        { lat: 29.789, lng: -113.38 },
        { lat: 29.796, lng: -113.861 },
        { lat: 29.882, lng: -113.95 },
        { lat: 29.943, lng: -113.967 },
        { lat: 29.955, lng: -114.019 },
        { lat: 29.989, lng: -114.06 },
        { lat: 29.986, lng: -114.129 },
        { lat: 30.006, lng: -114.178 },
        { lat: 30.103, lng: -114.207 },
        { lat: 30.124, lng: -114.256 },
        { lat: 30.183, lng: -114.287 },
        { lat: 30.217, lng: -114.348 },
        { lat: 30.389, lng: -114.441 },
        { lat: 30.77, lng: -114.441 },
        { lat: 30.829, lng: -114.481 },
        { lat: 31.05, lng: -114.481 },
        { lat: 31.091, lng: -114.515 },
        { lat: 31.143, lng: -114.527 },
        { lat: 31.169, lng: -114.582 },
        { lat: 31.255, lng: -114.637 },
        { lat: 31.351, lng: -114.641 },
        { lat: 31.392, lng: -114.615 },
        { lat: 31.398, lng: -114.557 },
        { lat: 31.317, lng: -114.433 },
        { lat: 31.309, lng: -114.349 },
        { lat: 31.271, lng: -114.3 },
        { lat: 31.284, lng: -113.881 },
        { lat: 31.255, lng: -113.844 },
        { lat: 31.17, lng: -113.839 },
        { lat: 31.117, lng: -113.793 },
        { lat: 31.109, lng: -113.709 },
        { lat: 31.071, lng: -113.66 },
        { lat: 31.069, lng: -113.511 },
        { lat: 31.031, lng: -113.46 },
        { lat: 31.025, lng: -113.381 },
        { lat: 30.975, lng: -113.328 },
        { lat: 30.57, lng: -113.319 },
        { lat: 30.449, lng: -113.245 },
        { lat: 30.397, lng: -113.233 },
        { lat: 30.255, lng: -113.088 },
        { lat: 30.17, lng: -113.079 },
        { lat: 30.129, lng: -113.045 },
        { lat: 30.077, lng: -113.033 },
        { lat: 30.006, lng: -112.963 },
        { lat: 29.717, lng: -112.953 },
        { lat: 29.704, lng: -112.899 },
        { lat: 29.662, lng: -112.853 },
        { lat: 29.517, lng: -112.793 },
        { lat: 29.395, lng: -112.68 },
        { lat: 29.219, lng: -112.681 },
        { lat: 29.09, lng: -112.759 },
        { lat: 28.971, lng: -112.765 },
        { lat: 28.892, lng: -112.816 },
        { lat: 28.872, lng: -112.851 },
    ],
    [
        { lat: 9.379, lng: -71.443 },
        { lat: 9.38, lng: -71.489 },
        { lat: 9.414, lng: -71.521 },
        { lat: 9.583, lng: -71.527 },
        { lat: 9.638, lng: -71.665 },
        { lat: 9.741, lng: -71.759 },
        { lat: 9.886, lng: -71.763 },
        { lat: 9.984, lng: -71.668 },
        { lat: 10.016, lng: -71.613 },
        { lat: 9.987, lng: -71.504 },
        { lat: 9.942, lng: -71.453 },
        { lat: 9.837, lng: -71.433 },
        { lat: 9.738, lng: -71.33 },
        { lat: 9.639, lng: -71.281 },
        { lat: 9.525, lng: -71.288 },
        { lat: 9.478, lng: -71.335 },
        { lat: 9.463, lng: -71.393 },
        { lat: 9.405, lng: -71.408 },
        { lat: 9.379, lng: -71.443 },
    ],
    [
        { lat: 8.629, lng: -79.261 },
        { lat: 8.629, lng: -79.296 },
        { lat: 8.661, lng: -79.33 },
        { lat: 8.746, lng: -79.311 },
        { lat: 8.766, lng: -79.263 },
        { lat: 8.737, lng: -79.168 },
        { lat: 8.694, lng: -79.147 },
        { lat: 8.649, lng: -79.163 },
        { lat: 8.629, lng: -79.261 },
    ],
    [
        { lat: 11.834, lng: -69.032 },
        { lat: 11.831, lng: -68.58 },
        { lat: 11.858, lng: -68.521 },
        { lat: 11.831, lng: -68.46 },
        { lat: 11.837, lng: -68.047 },
        { lat: 11.889, lng: -68.035 },
        { lat: 11.93, lng: -68.001 },
        { lat: 12.423, lng: -68.007 },
        { lat: 12.435, lng: -68.059 },
        { lat: 12.509, lng: -68.18 },
        { lat: 12.509, lng: -68.58 },
        { lat: 12.402, lng: -68.627 },
        { lat: 12.389, lng: -68.76 },
        { lat: 12.438, lng: -68.827 },
        { lat: 12.503, lng: -68.847 },
        { lat: 12.589, lng: -68.94 },
        { lat: 12.583, lng: -69.353 },
        { lat: 12.077, lng: -69.353 },
        { lat: 11.917, lng: -69.193 },
        { lat: 11.865, lng: -69.061 },
        { lat: 11.834, lng: -69.032 },
    ],
    [
        { lat: 58.877, lng: -80.673 },
        { lat: 58.877, lng: -80.327 },
        { lat: 59.19, lng: -80.321 },
        { lat: 59.25, lng: -80.281 },
        { lat: 59.36, lng: -80.27 },
        { lat: 59.392, lng: -80.216 },
        { lat: 59.397, lng: -80.127 },
        { lat: 59.425, lng: -80.099 },
        { lat: 59.477, lng: -79.967 },
        { lat: 59.541, lng: -79.928 },
        { lat: 59.551, lng: -79.82 },
        { lat: 59.585, lng: -79.779 },
        { lat: 59.597, lng: -79.727 },
        { lat: 59.637, lng: -79.687 },
        { lat: 59.983, lng: -79.687 },
        { lat: 59.989, lng: -80.34 },
        { lat: 59.911, lng: -80.431 },
        { lat: 59.909, lng: -80.7 },
        { lat: 59.863, lng: -80.753 },
        { lat: 59.665, lng: -80.76 },
        { lat: 59.61, lng: -80.799 },
        { lat: 59.49, lng: -80.799 },
        { lat: 59.446, lng: -80.763 },
        { lat: 59.39, lng: -80.756 },
        { lat: 59.11, lng: -80.759 },
        { lat: 59.05, lng: -80.799 },
        { lat: 58.93, lng: -80.799 },
        { lat: 58.911, lng: -80.78 },
        { lat: 58.907, lng: -80.704 },
        { lat: 58.877, lng: -80.673 },
    ],
    [
        { lat: 59.557, lng: -166.393 },
        { lat: 59.551, lng: -165.9 },
        { lat: 59.619, lng: -165.869 },
        { lat: 59.669, lng: -165.809 },
        { lat: 59.671, lng: -165.62 },
        { lat: 59.711, lng: -165.56 },
        { lat: 59.711, lng: -165.38 },
        { lat: 59.73, lng: -165.361 },
        { lat: 59.97, lng: -165.361 },
        { lat: 59.989, lng: -165.38 },
        { lat: 59.989, lng: -165.42 },
        { lat: 59.989, lng: -167.58 },
        { lat: 59.97, lng: -167.599 },
        { lat: 59.931, lng: -167.538 },
        { lat: 59.877, lng: -167.513 },
        { lat: 59.865, lng: -167.461 },
        { lat: 59.791, lng: -167.34 },
        { lat: 59.789, lng: -167.191 },
        { lat: 59.751, lng: -167.14 },
        { lat: 59.75, lng: -167.075 },
        { lat: 59.711, lng: -167.02 },
        { lat: 59.707, lng: -166.945 },
        { lat: 59.671, lng: -166.9 },
        { lat: 59.669, lng: -166.789 },
        { lat: 59.631, lng: -166.74 },
        { lat: 59.63, lng: -166.515 },
        { lat: 59.557, lng: -166.393 },
    ],
    [
        { lat: 52.637, lng: -169.313 },
        { lat: 52.631, lng: -168.86 },
        { lat: 52.67, lng: -168.805 },
        { lat: 52.671, lng: -168.7 },
        { lat: 52.709, lng: -168.651 },
        { lat: 52.717, lng: -168.567 },
        { lat: 52.827, lng: -168.375 },
        { lat: 52.837, lng: -168.287 },
        { lat: 53.019, lng: -168.109 },
        { lat: 53.07, lng: -168.005 },
        { lat: 53.069, lng: -167.911 },
        { lat: 53.031, lng: -167.86 },
        { lat: 53.031, lng: -167.46 },
        { lat: 53.07, lng: -167.405 },
        { lat: 53.071, lng: -167.3 },
        { lat: 53.111, lng: -167.241 },
        { lat: 53.111, lng: -167.14 },
        { lat: 53.187, lng: -167.056 },
        { lat: 53.191, lng: -166.94 },
        { lat: 53.23, lng: -166.885 },
        { lat: 53.231, lng: -166.58 },
        { lat: 53.265, lng: -166.539 },
        { lat: 53.277, lng: -166.487 },
        { lat: 53.379, lng: -166.389 },
        { lat: 53.397, lng: -166.327 },
        { lat: 53.425, lng: -166.299 },
        { lat: 53.431, lng: -166.22 },
        { lat: 53.47, lng: -166.165 },
        { lat: 53.471, lng: -166.1 },
        { lat: 53.545, lng: -165.979 },
        { lat: 53.557, lng: -165.927 },
        { lat: 53.609, lng: -165.915 },
        { lat: 53.65, lng: -165.881 },
        { lat: 53.786, lng: -165.876 },
        { lat: 53.823, lng: -165.844 },
        { lat: 53.831, lng: -165.7 },
        { lat: 53.867, lng: -165.656 },
        { lat: 53.874, lng: -165.603 },
        { lat: 53.867, lng: -165.544 },
        { lat: 53.831, lng: -165.5 },
        { lat: 53.831, lng: -165.1 },
        { lat: 53.871, lng: -165.04 },
        { lat: 53.871, lng: -164.74 },
        { lat: 53.89, lng: -164.721 },
        { lat: 54.12, lng: -164.724 },
        { lat: 54.178, lng: -164.693 },
        { lat: 54.194, lng: -164.642 },
        { lat: 54.191, lng: -164.34 },
        { lat: 54.23, lng: -164.285 },
        { lat: 54.231, lng: -164.18 },
        { lat: 54.277, lng: -164.127 },
        { lat: 54.331, lng: -164.114 },
        { lat: 54.379, lng: -164.069 },
        { lat: 54.391, lng: -163.98 },
        { lat: 54.43, lng: -163.925 },
        { lat: 54.429, lng: -163.831 },
        { lat: 54.391, lng: -163.78 },
        { lat: 54.391, lng: -163.42 },
        { lat: 54.469, lng: -163.289 },
        { lat: 54.473, lng: -163.109 },
        { lat: 54.458, lng: -163.067 },
        { lat: 54.426, lng: -163.044 },
        { lat: 54.237, lng: -163.033 },
        { lat: 54.225, lng: -162.981 },
        { lat: 54.191, lng: -162.94 },
        { lat: 54.189, lng: -162.869 },
        { lat: 54.151, lng: -162.82 },
        { lat: 54.151, lng: -162.26 },
        { lat: 54.21, lng: -162.161 },
        { lat: 54.591, lng: -162.154 },
        { lat: 54.677, lng: -162.047 },
        { lat: 54.806, lng: -162.037 },
        { lat: 54.87, lng: -161.965 },
        { lat: 54.871, lng: -161.5 },
        { lat: 54.95, lng: -161.405 },
        { lat: 54.951, lng: -161.14 },
        { lat: 54.978, lng: -161.081 },
        { lat: 54.911, lng: -160.98 },
        { lat: 54.911, lng: -160.62 },
        { lat: 54.947, lng: -160.575 },
        { lat: 54.954, lng: -160.512 },
        { lat: 54.938, lng: -160.467 },
        { lat: 54.909, lng: -160.445 },
        { lat: 54.69, lng: -160.439 },
        { lat: 54.671, lng: -160.42 },
        { lat: 54.671, lng: -160.02 },
        { lat: 54.779, lng: -159.909 },
        { lat: 54.791, lng: -159.866 },
        { lat: 54.778, lng: -159.827 },
        { lat: 54.746, lng: -159.804 },
        { lat: 54.557, lng: -159.793 },
        { lat: 54.551, lng: -159.34 },
        { lat: 54.661, lng: -159.288 },
        { lat: 54.671, lng: -159.06 },
        { lat: 54.73, lng: -159.001 },
        { lat: 55.09, lng: -159.001 },
        { lat: 55.169, lng: -159.142 },
        { lat: 55.263, lng: -159.167 },
        { lat: 55.267, lng: -159.255 },
        { lat: 55.297, lng: -159.308 },
        { lat: 55.41, lng: -159.321 },
        { lat: 55.471, lng: -159.348 },
        { lat: 55.54, lng: -159.308 },
        { lat: 55.551, lng: -159.1 },
        { lat: 55.587, lng: -159.056 },
        { lat: 55.594, lng: -159.005 },
        { lat: 55.591, lng: -158.7 },
        { lat: 55.629, lng: -158.649 },
        { lat: 55.637, lng: -158.527 },
        { lat: 55.725, lng: -158.517 },
        { lat: 55.78, lng: -158.468 },
        { lat: 55.797, lng: -158.247 },
        { lat: 55.937, lng: -158.152 },
        { lat: 55.989, lng: -158.051 },
        { lat: 55.997, lng: -157.967 },
        { lat: 56.103, lng: -157.885 },
        { lat: 56.117, lng: -157.607 },
        { lat: 56.324, lng: -157.601 },
        { lat: 56.365, lng: -157.548 },
        { lat: 56.351, lng: -157.501 },
        { lat: 56.311, lng: -157.46 },
        { lat: 56.314, lng: -157.079 },
        { lat: 56.301, lng: -157.032 },
        { lat: 56.241, lng: -156.997 },
        { lat: 55.97, lng: -156.999 },
        { lat: 55.921, lng: -156.961 },
        { lat: 55.837, lng: -156.953 },
        { lat: 55.825, lng: -156.901 },
        { lat: 55.791, lng: -156.86 },
        { lat: 55.797, lng: -156.487 },
        { lat: 56.25, lng: -156.481 },
        { lat: 56.305, lng: -156.553 },
        { lat: 56.423, lng: -156.567 },
        { lat: 56.427, lng: -156.692 },
        { lat: 56.457, lng: -156.748 },
        { lat: 56.53, lng: -156.761 },
        { lat: 56.589, lng: -156.801 },
        { lat: 56.686, lng: -156.786 },
        { lat: 56.713, lng: -156.731 },
        { lat: 56.717, lng: -156.607 },
        { lat: 56.771, lng: -156.582 },
        { lat: 56.792, lng: -156.536 },
        { lat: 56.791, lng: -156.38 },
        { lat: 56.9, lng: -156.268 },
        { lat: 56.917, lng: -156.207 },
        { lat: 56.97, lng: -156.161 },
        { lat: 57.125, lng: -156.157 },
        { lat: 57.221, lng: -156.088 },
        { lat: 57.231, lng: -155.86 },
        { lat: 57.302, lng: -155.785 },
        { lat: 57.317, lng: -155.727 },
        { lat: 57.347, lng: -155.696 },
        { lat: 57.357, lng: -155.567 },
        { lat: 57.459, lng: -155.469 },
        { lat: 57.477, lng: -155.407 },
        { lat: 57.507, lng: -155.375 },
        { lat: 57.517, lng: -155.167 },
        { lat: 57.67, lng: -155.005 },
        { lat: 57.658, lng: -154.947 },
        { lat: 57.612, lng: -154.922 },
        { lat: 57.569, lng: -154.938 },
        { lat: 57.53, lng: -154.999 },
        { lat: 57.09, lng: -154.999 },
        { lat: 57.071, lng: -154.98 },
        { lat: 57.062, lng: -154.815 },
        { lat: 56.975, lng: -154.728 },
        { lat: 56.911, lng: -154.716 },
        { lat: 56.809, lng: -154.738 },
        { lat: 56.755, lng: -154.821 },
        { lat: 56.743, lng: -154.873 },
        { lat: 56.61, lng: -154.999 },
        { lat: 56.197, lng: -154.993 },
        { lat: 56.191, lng: -154.54 },
        { lat: 56.225, lng: -154.499 },
        { lat: 56.237, lng: -154.447 },
        { lat: 56.304, lng: -154.381 },
        { lat: 56.314, lng: -154.326 },
        { lat: 56.311, lng: -153.7 },
        { lat: 56.33, lng: -153.681 },
        { lat: 56.571, lng: -153.674 },
        { lat: 56.624, lng: -153.621 },
        { lat: 56.677, lng: -153.487 },
        { lat: 56.74, lng: -153.45 },
        { lat: 56.751, lng: -153.38 },
        { lat: 56.791, lng: -153.321 },
        { lat: 56.791, lng: -153.06 },
        { lat: 56.862, lng: -152.985 },
        { lat: 56.91, lng: -152.885 },
        { lat: 56.917, lng: -152.687 },
        { lat: 57.005, lng: -152.683 },
        { lat: 57.058, lng: -152.653 },
        { lat: 57.077, lng: -152.487 },
        { lat: 57.225, lng: -152.359 },
        { lat: 57.237, lng: -152.127 },
        { lat: 57.291, lng: -152.114 },
        { lat: 57.334, lng: -152.074 },
        { lat: 57.357, lng: -152.007 },
        { lat: 57.397, lng: -151.967 },
        { lat: 57.81, lng: -151.961 },
        { lat: 57.829, lng: -151.98 },
        { lat: 57.844, lng: -152.078 },
        { lat: 57.882, lng: -152.095 },
        { lat: 57.921, lng: -152.084 },
        { lat: 57.947, lng: -152.055 },
        { lat: 57.954, lng: -152.005 },
        { lat: 57.951, lng: -151.7 },
        { lat: 58.01, lng: -151.601 },
        { lat: 58.463, lng: -151.607 },
        { lat: 58.476, lng: -151.722 },
        { lat: 58.543, lng: -151.767 },
        { lat: 58.551, lng: -151.851 },
        { lat: 58.589, lng: -151.9 },
        { lat: 58.591, lng: -152.051 },
        { lat: 58.635, lng: -152.092 },
        { lat: 58.699, lng: -152.078 },
        { lat: 58.745, lng: -152.019 },
        { lat: 58.757, lng: -151.967 },
        { lat: 58.883, lng: -151.963 },
        { lat: 58.941, lng: -151.928 },
        { lat: 58.954, lng: -151.881 },
        { lat: 58.951, lng: -151.38 },
        { lat: 58.987, lng: -151.336 },
        { lat: 58.994, lng: -151.283 },
        { lat: 58.991, lng: -150.82 },
        { lat: 59.025, lng: -150.779 },
        { lat: 59.037, lng: -150.727 },
        { lat: 59.109, lng: -150.651 },
        { lat: 59.111, lng: -150.46 },
        { lat: 59.189, lng: -150.371 },
        { lat: 59.191, lng: -150.22 },
        { lat: 59.229, lng: -150.171 },
        { lat: 59.237, lng: -150.087 },
        { lat: 59.296, lng: -150.056 },
        { lat: 59.357, lng: -149.927 },
        { lat: 59.429, lng: -149.851 },
        { lat: 59.431, lng: -149.78 },
        { lat: 59.471, lng: -149.721 },
        { lat: 59.471, lng: -149.58 },
        { lat: 59.511, lng: -149.521 },
        { lat: 59.511, lng: -149.34 },
        { lat: 59.53, lng: -149.321 },
        { lat: 59.629, lng: -149.315 },
        { lat: 59.658, lng: -149.293 },
        { lat: 59.674, lng: -149.244 },
        { lat: 59.671, lng: -149.06 },
        { lat: 59.734, lng: -149.018 },
        { lat: 59.754, lng: -148.97 },
        { lat: 59.711, lng: -148.82 },
        { lat: 59.711, lng: -148.42 },
        { lat: 59.75, lng: -148.365 },
        { lat: 59.753, lng: -148.188 },
        { lat: 59.718, lng: -148.129 },
        { lat: 59.61, lng: -148.119 },
        { lat: 59.591, lng: -148.1 },
        { lat: 59.591, lng: -147.58 },
        { lat: 59.631, lng: -147.521 },
        { lat: 59.631, lng: -147.34 },
        { lat: 59.665, lng: -147.299 },
        { lat: 59.677, lng: -147.247 },
        { lat: 59.732, lng: -147.221 },
        { lat: 59.757, lng: -147.167 },
        { lat: 59.854, lng: -147.138 },
        { lat: 59.97, lng: -146.961 },
        { lat: 59.989, lng: -146.98 },
        { lat: 59.989, lng: -152.06 },
        { lat: 59.97, lng: -152.079 },
        { lat: 59.625, lng: -152.08 },
        { lat: 59.49, lng: -152.199 },
        { lat: 59.205, lng: -152.208 },
        { lat: 59.15, lng: -152.275 },
        { lat: 59.143, lng: -152.513 },
        { lat: 59.09, lng: -152.559 },
        { lat: 58.905, lng: -152.556 },
        { lat: 58.852, lng: -152.576 },
        { lat: 58.743, lng: -152.833 },
        { lat: 58.648, lng: -152.899 },
        { lat: 58.626, lng: -152.96 },
        { lat: 58.64, lng: -153.01 },
        { lat: 58.67, lng: -153.035 },
        { lat: 58.85, lng: -153.041 },
        { lat: 58.91, lng: -153.081 },
        { lat: 59.05, lng: -153.081 },
        { lat: 59.145, lng: -153.16 },
        { lat: 59.392, lng: -153.154 },
        { lat: 59.424, lng: -153.121 },
        { lat: 59.431, lng: -153.02 },
        { lat: 59.505, lng: -152.899 },
        { lat: 59.517, lng: -152.847 },
        { lat: 59.557, lng: -152.807 },
        { lat: 59.631, lng: -152.794 },
        { lat: 59.663, lng: -152.765 },
        { lat: 59.671, lng: -152.62 },
        { lat: 59.705, lng: -152.579 },
        { lat: 59.717, lng: -152.527 },
        { lat: 59.877, lng: -152.367 },
        { lat: 59.983, lng: -152.367 },
        { lat: 59.989, lng: -164.66 },
        { lat: 59.97, lng: -164.679 },
        { lat: 59.917, lng: -164.673 },
        { lat: 59.905, lng: -164.621 },
        { lat: 59.871, lng: -164.58 },
        { lat: 59.867, lng: -164.504 },
        { lat: 59.808, lng: -164.416 },
        { lat: 59.759, lng: -164.396 },
        { lat: 59.69, lng: -164.399 },
        { lat: 59.637, lng: -164.353 },
        { lat: 59.63, lng: -164.195 },
        { lat: 59.591, lng: -164.14 },
        { lat: 59.591, lng: -163.3 },
        { lat: 59.631, lng: -163.241 },
        { lat: 59.631, lng: -162.94 },
        { lat: 59.67, lng: -162.885 },
        { lat: 59.671, lng: -162.78 },
        { lat: 59.709, lng: -162.731 },
        { lat: 59.717, lng: -162.647 },
        { lat: 59.79, lng: -162.525 },
        { lat: 59.794, lng: -162.396 },
        { lat: 59.778, lng: -162.347 },
        { lat: 59.717, lng: -162.313 },
        { lat: 59.615, lng: -162.203 },
        { lat: 59.564, lng: -162.184 },
        { lat: 59.526, lng: -162.197 },
        { lat: 59.503, lng: -162.233 },
        { lat: 59.45, lng: -162.239 },
        { lat: 59.05, lng: -162.239 },
        { lat: 59.005, lng: -162.203 },
        { lat: 58.914, lng: -162.186 },
        { lat: 58.869, lng: -162.224 },
        { lat: 58.869, lng: -162.34 },
        { lat: 58.85, lng: -162.359 },
        { lat: 58.45, lng: -162.359 },
        { lat: 58.431, lng: -162.34 },
        { lat: 58.433, lng: -162.027 },
        { lat: 58.412, lng: -161.979 },
        { lat: 58.351, lng: -161.94 },
        { lat: 58.351, lng: -161.5 },
        { lat: 58.389, lng: -161.451 },
        { lat: 58.406, lng: -161.334 },
        { lat: 58.351, lng: -161.26 },
        { lat: 58.351, lng: -160.74 },
        { lat: 58.47, lng: -160.605 },
        { lat: 58.469, lng: -160.511 },
        { lat: 58.431, lng: -160.46 },
        { lat: 58.431, lng: -160.06 },
        { lat: 58.45, lng: -160.041 },
        { lat: 58.549, lng: -160.035 },
        { lat: 58.584, lng: -160.003 },
        { lat: 58.594, lng: -159.675 },
        { lat: 58.579, lng: -159.611 },
        { lat: 58.237, lng: -159.273 },
        { lat: 58.225, lng: -159.221 },
        { lat: 58.191, lng: -159.18 },
        { lat: 58.191, lng: -158.62 },
        { lat: 58.277, lng: -158.527 },
        { lat: 58.403, lng: -158.508 },
        { lat: 58.433, lng: -158.451 },
        { lat: 58.427, lng: -158.385 },
        { lat: 58.391, lng: -158.34 },
        { lat: 58.391, lng: -157.98 },
        { lat: 58.43, lng: -157.925 },
        { lat: 58.431, lng: -157.826 },
        { lat: 58.373, lng: -157.785 },
        { lat: 58.259, lng: -157.801 },
        { lat: 58.21, lng: -157.839 },
        { lat: 58.061, lng: -157.841 },
        { lat: 57.93, lng: -157.919 },
        { lat: 57.821, lng: -157.916 },
        { lat: 57.766, lng: -157.942 },
        { lat: 57.749, lng: -158.02 },
        { lat: 57.675, lng: -158.141 },
        { lat: 57.663, lng: -158.193 },
        { lat: 57.555, lng: -158.301 },
        { lat: 57.543, lng: -158.433 },
        { lat: 57.515, lng: -158.461 },
        { lat: 57.463, lng: -158.593 },
        { lat: 57.396, lng: -158.659 },
        { lat: 57.383, lng: -158.713 },
        { lat: 57.223, lng: -158.873 },
        { lat: 57.134, lng: -158.883 },
        { lat: 57.078, lng: -158.935 },
        { lat: 57.069, lng: -159.14 },
        { lat: 56.953, lng: -159.344 },
        { lat: 56.943, lng: -159.433 },
        { lat: 56.88, lng: -159.492 },
        { lat: 56.863, lng: -159.553 },
        { lat: 56.833, lng: -159.585 },
        { lat: 56.829, lng: -159.78 },
        { lat: 56.79, lng: -159.835 },
        { lat: 56.789, lng: -159.94 },
        { lat: 56.633, lng: -160.224 },
        { lat: 56.623, lng: -160.313 },
        { lat: 56.595, lng: -160.341 },
        { lat: 56.583, lng: -160.393 },
        { lat: 56.516, lng: -160.459 },
        { lat: 56.463, lng: -160.593 },
        { lat: 56.409, lng: -160.606 },
        { lat: 56.343, lng: -160.673 },
        { lat: 56.282, lng: -160.69 },
        { lat: 56.193, lng: -160.785 },
        { lat: 56.186, lng: -161.244 },
        { lat: 56.193, lng: -161.296 },
        { lat: 56.229, lng: -161.34 },
        { lat: 56.191, lng: -161.391 },
        { lat: 56.189, lng: -161.54 },
        { lat: 56.153, lng: -161.585 },
        { lat: 56.149, lng: -161.7 },
        { lat: 56.11, lng: -161.755 },
        { lat: 56.109, lng: -161.98 },
        { lat: 55.913, lng: -162.344 },
        { lat: 55.903, lng: -162.433 },
        { lat: 55.8, lng: -162.532 },
        { lat: 55.783, lng: -162.593 },
        { lat: 55.716, lng: -162.659 },
        { lat: 55.703, lng: -162.713 },
        { lat: 55.623, lng: -162.793 },
        { lat: 55.572, lng: -162.816 },
        { lat: 55.552, lng: -162.85 },
        { lat: 55.566, lng: -162.898 },
        { lat: 55.629, lng: -162.94 },
        { lat: 55.629, lng: -163.38 },
        { lat: 55.61, lng: -163.399 },
        { lat: 55.386, lng: -163.407 },
        { lat: 55.273, lng: -163.545 },
        { lat: 55.269, lng: -163.98 },
        { lat: 55.23, lng: -164.035 },
        { lat: 55.229, lng: -164.14 },
        { lat: 55.15, lng: -164.275 },
        { lat: 55.149, lng: -164.42 },
        { lat: 55.109, lng: -164.48 },
        { lat: 55.103, lng: -164.713 },
        { lat: 54.983, lng: -164.833 },
        { lat: 54.925, lng: -164.848 },
        { lat: 54.84, lng: -164.932 },
        { lat: 54.829, lng: -165.06 },
        { lat: 54.795, lng: -165.101 },
        { lat: 54.783, lng: -165.153 },
        { lat: 54.462, lng: -165.156 },
        { lat: 54.395, lng: -165.2 },
        { lat: 54.412, lng: -165.264 },
        { lat: 54.509, lng: -165.3 },
        { lat: 54.509, lng: -165.82 },
        { lat: 54.431, lng: -165.911 },
        { lat: 54.429, lng: -166.14 },
        { lat: 54.391, lng: -166.189 },
        { lat: 54.383, lng: -166.273 },
        { lat: 54.33, lng: -166.319 },
        { lat: 54.222, lng: -166.329 },
        { lat: 54.188, lng: -166.384 },
        { lat: 54.186, lng: -166.805 },
        { lat: 54.193, lng: -166.856 },
        { lat: 54.229, lng: -166.9 },
        { lat: 54.19, lng: -166.955 },
        { lat: 54.189, lng: -167.1 },
        { lat: 54.151, lng: -167.151 },
        { lat: 54.143, lng: -167.273 },
        { lat: 54.05, lng: -167.359 },
        { lat: 53.745, lng: -167.36 },
        { lat: 53.671, lng: -167.429 },
        { lat: 53.656, lng: -167.559 },
        { lat: 53.703, lng: -167.607 },
        { lat: 53.711, lng: -167.729 },
        { lat: 53.749, lng: -167.78 },
        { lat: 53.743, lng: -168.433 },
        { lat: 53.68, lng: -168.492 },
        { lat: 53.663, lng: -168.553 },
        { lat: 53.623, lng: -168.593 },
        { lat: 53.571, lng: -168.605 },
        { lat: 53.482, lng: -168.667 },
        { lat: 53.469, lng: -168.82 },
        { lat: 53.435, lng: -168.861 },
        { lat: 53.423, lng: -168.913 },
        { lat: 53.343, lng: -168.993 },
        { lat: 53.255, lng: -169.003 },
        { lat: 53.199, lng: -169.049 },
        { lat: 53.156, lng: -169.099 },
        { lat: 53.143, lng: -169.153 },
        { lat: 53.082, lng: -169.187 },
        { lat: 53.023, lng: -169.313 },
        { lat: 52.637, lng: -169.313 },
    ],
    [
        { lat: 58.173, lng: -153.803 },
        { lat: 58.249, lng: -153.795 },
        { lat: 58.317, lng: -153.727 },
        { lat: 58.376, lng: -153.696 },
        { lat: 58.396, lng: -153.598 },
        { lat: 58.375, lng: -153.553 },
        { lat: 58.339, lng: -153.542 },
        { lat: 58.29, lng: -153.56 },
        { lat: 58.154, lng: -153.694 },
        { lat: 58.135, lng: -153.76 },
        { lat: 58.173, lng: -153.803 },
    ],
    [
        { lat: 59.197, lng: -146.473 },
        { lat: 59.197, lng: -146.127 },
        { lat: 59.663, lng: -146.127 },
        { lat: 59.669, lng: -146.5 },
        { lat: 59.623, lng: -146.553 },
        { lat: 59.25, lng: -146.559 },
        { lat: 59.197, lng: -146.473 },
    ],
    [
        { lat: 55.597, lng: -79.993 },
        { lat: 55.591, lng: -79.54 },
        { lat: 55.662, lng: -79.465 },
        { lat: 55.674, lng: -79.4 },
        { lat: 55.654, lng: -79.342 },
        { lat: 55.591, lng: -79.3 },
        { lat: 55.591, lng: -78.94 },
        { lat: 55.677, lng: -78.847 },
        { lat: 55.735, lng: -78.832 },
        { lat: 55.797, lng: -78.767 },
        { lat: 55.851, lng: -78.754 },
        { lat: 55.896, lng: -78.713 },
        { lat: 55.949, lng: -78.609 },
        { lat: 55.957, lng: -78.487 },
        { lat: 56.009, lng: -78.475 },
        { lat: 56.05, lng: -78.441 },
        { lat: 56.53, lng: -78.441 },
        { lat: 56.655, lng: -78.517 },
        { lat: 56.73, lng: -78.521 },
        { lat: 56.779, lng: -78.559 },
        { lat: 56.866, lng: -78.568 },
        { lat: 56.869, lng: -79.02 },
        { lat: 56.83, lng: -79.075 },
        { lat: 56.828, lng: -79.218 },
        { lat: 56.854, lng: -79.266 },
        { lat: 56.943, lng: -79.287 },
        { lat: 57.023, lng: -79.367 },
        { lat: 57.038, lng: -79.505 },
        { lat: 57.085, lng: -79.552 },
        { lat: 57.143, lng: -79.567 },
        { lat: 57.171, lng: -79.606 },
        { lat: 57.246, lng: -79.603 },
        { lat: 57.33, lng: -79.521 },
        { lat: 57.475, lng: -79.52 },
        { lat: 57.53, lng: -79.481 },
        { lat: 57.73, lng: -79.481 },
        { lat: 57.829, lng: -79.54 },
        { lat: 57.829, lng: -79.94 },
        { lat: 57.73, lng: -80.039 },
        { lat: 57.25, lng: -80.039 },
        { lat: 57.177, lng: -80.014 },
        { lat: 57.122, lng: -80.045 },
        { lat: 57.01, lng: -80.159 },
        { lat: 56.58, lng: -80.156 },
        { lat: 56.529, lng: -80.178 },
        { lat: 56.503, lng: -80.233 },
        { lat: 56.45, lng: -80.279 },
        { lat: 56.23, lng: -80.279 },
        { lat: 56.17, lng: -80.319 },
        { lat: 56.01, lng: -80.319 },
        { lat: 55.953, lng: -80.246 },
        { lat: 55.85, lng: -80.239 },
        { lat: 55.801, lng: -80.201 },
        { lat: 55.717, lng: -80.193 },
        { lat: 55.637, lng: -80.113 },
        { lat: 55.629, lng: -80.029 },
        { lat: 55.597, lng: -79.993 },
    ],
    [
        { lat: 56.954, lng: -78.512 },
        { lat: 56.951, lng: -78.14 },
        { lat: 56.997, lng: -78.087 },
        { lat: 57.155, lng: -78.08 },
        { lat: 57.21, lng: -78.041 },
        { lat: 57.37, lng: -78.041 },
        { lat: 57.417, lng: -78.108 },
        { lat: 57.503, lng: -78.127 },
        { lat: 57.509, lng: -78.48 },
        { lat: 57.549, lng: -78.54 },
        { lat: 57.511, lng: -78.589 },
        { lat: 57.503, lng: -78.673 },
        { lat: 57.09, lng: -78.679 },
        { lat: 57.059, lng: -78.611 },
        { lat: 56.954, lng: -78.512 },
    ],
    [
        { lat: 56.957, lng: -169.993 },
        { lat: 56.957, lng: -169.967 },
        { lat: 57.01, lng: -169.921 },
        { lat: 57.41, lng: -169.921 },
        { lat: 57.429, lng: -169.94 },
        { lat: 57.429, lng: -169.98 },
        { lat: 57.41, lng: -169.999 },
        { lat: 56.957, lng: -169.993 },
    ],
    [
        { lat: 56.357, lng: -169.873 },
        { lat: 56.357, lng: -169.327 },
        { lat: 56.41, lng: -169.281 },
        { lat: 56.783, lng: -169.287 },
        { lat: 56.829, lng: -169.34 },
        { lat: 56.823, lng: -169.953 },
        { lat: 56.625, lng: -169.96 },
        { lat: 56.57, lng: -169.999 },
        { lat: 56.41, lng: -169.999 },
        { lat: 56.391, lng: -169.98 },
        { lat: 56.387, lng: -169.904 },
        { lat: 56.357, lng: -169.873 },
    ],
    [
        { lat: 56.077, lng: -78.073 },
        { lat: 56.071, lng: -77.74 },
        { lat: 56.105, lng: -77.699 },
        { lat: 56.117, lng: -77.647 },
        { lat: 56.171, lng: -77.622 },
        { lat: 56.192, lng: -77.577 },
        { lat: 56.197, lng: -77.287 },
        { lat: 56.623, lng: -77.287 },
        { lat: 56.629, lng: -77.7 },
        { lat: 56.561, lng: -77.731 },
        { lat: 56.511, lng: -77.791 },
        { lat: 56.503, lng: -78.153 },
        { lat: 56.13, lng: -78.159 },
        { lat: 56.077, lng: -78.073 },
    ],
    [
        { lat: 55.597, lng: -155.913 },
        { lat: 55.597, lng: -155.367 },
        { lat: 56.103, lng: -155.367 },
        { lat: 56.109, lng: -155.72 },
        { lat: 56.143, lng: -155.793 },
        { lat: 56.01, lng: -155.919 },
        { lat: 55.597, lng: -155.913 },
    ],
    [
        { lat: 54.797, lng: -133.553 },
        { lat: 54.797, lng: -133.527 },
        { lat: 54.823, lng: -133.527 },
        { lat: 54.823, lng: -133.553 },
        { lat: 54.797, lng: -133.553 },
    ],
    [
        { lat: 54.117, lng: -81.313 },
        { lat: 54.117, lng: -80.887 },
        { lat: 54.543, lng: -80.887 },
        { lat: 54.543, lng: -81.313 },
        { lat: 54.117, lng: -81.313 },
    ],
    [
        { lat: 52.517, lng: -169.993 },
        { lat: 52.511, lng: -169.94 },
        { lat: 52.547, lng: -169.896 },
        { lat: 52.554, lng: -169.845 },
        { lat: 52.551, lng: -169.58 },
        { lat: 52.585, lng: -169.539 },
        { lat: 52.597, lng: -169.487 },
        { lat: 53.223, lng: -169.487 },
        { lat: 53.223, lng: -169.953 },
        { lat: 53.139, lng: -169.961 },
        { lat: 53.09, lng: -169.999 },
        { lat: 52.517, lng: -169.993 },
    ],
    [
        { lat: 47.037, lng: -62.193 },
        { lat: 47.037, lng: -61.607 },
        { lat: 47.169, lng: -61.595 },
        { lat: 47.304, lng: -61.469 },
        { lat: 47.349, lng: -61.411 },
        { lat: 47.351, lng: -61.3 },
        { lat: 47.385, lng: -61.259 },
        { lat: 47.397, lng: -61.207 },
        { lat: 47.595, lng: -61.2 },
        { lat: 47.65, lng: -61.161 },
        { lat: 47.823, lng: -61.167 },
        { lat: 47.829, lng: -61.74 },
        { lat: 47.795, lng: -61.781 },
        { lat: 47.783, lng: -61.833 },
        { lat: 47.718, lng: -61.895 },
        { lat: 47.703, lng: -61.953 },
        { lat: 47.636, lng: -62.019 },
        { lat: 47.623, lng: -62.113 },
        { lat: 47.57, lng: -62.159 },
        { lat: 47.485, lng: -62.168 },
        { lat: 47.41, lng: -62.239 },
        { lat: 47.33, lng: -62.239 },
        { lat: 47.27, lng: -62.199 },
        { lat: 47.037, lng: -62.193 },
    ],
    [
        { lat: 43.717, lng: -60.313 },
        { lat: 43.717, lng: -59.607 },
        { lat: 43.77, lng: -59.561 },
        { lat: 44.183, lng: -59.567 },
        { lat: 44.189, lng: -59.94 },
        { lat: 44.149, lng: -60.0 },
        { lat: 44.143, lng: -60.313 },
        { lat: 43.717, lng: -60.313 },
    ],
    [
        { lat: 39.157, lng: -31.473 },
        { lat: 39.151, lng: -30.98 },
        { lat: 39.197, lng: -30.927 },
        { lat: 39.431, lng: -30.921 },
        { lat: 39.49, lng: -30.881 },
        { lat: 39.903, lng: -30.887 },
        { lat: 39.903, lng: -31.353 },
        { lat: 39.74, lng: -31.37 },
        { lat: 39.69, lng: -31.479 },
        { lat: 39.157, lng: -31.473 },
    ],
    [
        { lat: 38.197, lng: -28.633 },
        { lat: 38.191, lng: -27.86 },
        { lat: 38.21, lng: -27.841 },
        { lat: 38.284, lng: -27.843 },
        { lat: 38.338, lng: -27.813 },
        { lat: 38.354, lng: -27.764 },
        { lat: 38.351, lng: -27.58 },
        { lat: 38.42, lng: -27.553 },
        { lat: 38.444, lng: -27.519 },
        { lat: 38.431, lng: -26.86 },
        { lat: 38.45, lng: -26.841 },
        { lat: 38.635, lng: -26.84 },
        { lat: 38.69, lng: -26.801 },
        { lat: 38.85, lng: -26.801 },
        { lat: 38.891, lng: -26.835 },
        { lat: 38.943, lng: -26.847 },
        { lat: 38.989, lng: -26.9 },
        { lat: 38.989, lng: -27.58 },
        { lat: 38.97, lng: -27.599 },
        { lat: 38.825, lng: -27.607 },
        { lat: 38.796, lng: -27.637 },
        { lat: 38.795, lng: -27.679 },
        { lat: 38.858, lng: -27.723 },
        { lat: 39.01, lng: -27.721 },
        { lat: 39.07, lng: -27.761 },
        { lat: 39.25, lng: -27.761 },
        { lat: 39.303, lng: -27.807 },
        { lat: 39.309, lng: -28.26 },
        { lat: 39.29, lng: -28.279 },
        { lat: 39.021, lng: -28.276 },
        { lat: 38.962, lng: -28.307 },
        { lat: 38.943, lng: -28.513 },
        { lat: 38.86, lng: -28.53 },
        { lat: 38.828, lng: -28.583 },
        { lat: 38.829, lng: -28.94 },
        { lat: 38.77, lng: -29.039 },
        { lat: 38.397, lng: -29.033 },
        { lat: 38.317, lng: -28.953 },
        { lat: 38.307, lng: -28.785 },
        { lat: 38.237, lng: -28.713 },
        { lat: 38.225, lng: -28.661 },
        { lat: 38.197, lng: -28.633 },
    ],
    [
        { lat: 37.517, lng: -25.793 },
        { lat: 37.511, lng: -25.06 },
        { lat: 37.549, lng: -25.011 },
        { lat: 37.557, lng: -24.927 },
        { lat: 38.023, lng: -24.927 },
        { lat: 38.069, lng: -24.98 },
        { lat: 38.066, lng: -25.445 },
        { lat: 38.073, lng: -25.496 },
        { lat: 38.109, lng: -25.54 },
        { lat: 38.103, lng: -26.033 },
        { lat: 37.905, lng: -26.04 },
        { lat: 37.85, lng: -26.079 },
        { lat: 37.69, lng: -26.079 },
        { lat: 37.649, lng: -26.045 },
        { lat: 37.597, lng: -26.033 },
        { lat: 37.585, lng: -25.981 },
        { lat: 37.551, lng: -25.94 },
        { lat: 37.549, lng: -25.829 },
        { lat: 37.517, lng: -25.793 },
    ],
    [
        { lat: 36.717, lng: -25.153 },
        { lat: 36.711, lng: -24.86 },
        { lat: 36.757, lng: -24.807 },
        { lat: 37.17, lng: -24.801 },
        { lat: 37.223, lng: -24.847 },
        { lat: 37.229, lng: -25.34 },
        { lat: 37.183, lng: -25.393 },
        { lat: 36.757, lng: -25.393 },
        { lat: 36.751, lng: -25.199 },
        { lat: 36.717, lng: -25.153 },
    ],
    [
        { lat: 32.997, lng: -119.593 },
        { lat: 32.997, lng: -119.247 },
        { lat: 33.41, lng: -119.241 },
        { lat: 33.463, lng: -119.287 },
        { lat: 33.463, lng: -119.753 },
        { lat: 33.05, lng: -119.759 },
        { lat: 33.031, lng: -119.74 },
        { lat: 33.029, lng: -119.629 },
        { lat: 32.997, lng: -119.593 },
    ],
    [
        { lat: 32.077, lng: -65.033 },
        { lat: 32.077, lng: -64.567 },
        { lat: 32.17, lng: -64.481 },
        { lat: 32.583, lng: -64.487 },
        { lat: 32.589, lng: -64.86 },
        { lat: 32.518, lng: -64.935 },
        { lat: 32.503, lng: -64.993 },
        { lat: 32.45, lng: -65.039 },
        { lat: 32.077, lng: -65.033 },
    ],
    [
        { lat: 28.677, lng: -118.513 },
        { lat: 28.671, lng: -118.1 },
        { lat: 28.717, lng: -118.047 },
        { lat: 29.25, lng: -118.041 },
        { lat: 29.291, lng: -118.075 },
        { lat: 29.343, lng: -118.087 },
        { lat: 29.383, lng: -118.127 },
        { lat: 29.383, lng: -118.593 },
        { lat: 28.89, lng: -118.599 },
        { lat: 28.805, lng: -118.523 },
        { lat: 28.677, lng: -118.513 },
    ],
    [
        { lat: 20.717, lng: -73.873 },
        { lat: 20.711, lng: -73.46 },
        { lat: 20.751, lng: -73.4 },
        { lat: 20.757, lng: -72.967 },
        { lat: 20.837, lng: -72.887 },
        { lat: 20.889, lng: -72.875 },
        { lat: 21.01, lng: -72.801 },
        { lat: 21.206, lng: -72.802 },
        { lat: 21.252, lng: -72.781 },
        { lat: 21.277, lng: -72.727 },
        { lat: 21.362, lng: -72.723 },
        { lat: 21.412, lng: -72.701 },
        { lat: 21.434, lng: -72.643 },
        { lat: 21.431, lng: -72.26 },
        { lat: 21.523, lng: -72.228 },
        { lat: 21.554, lng: -72.169 },
        { lat: 21.551, lng: -72.02 },
        { lat: 21.578, lng: -71.959 },
        { lat: 21.528, lng: -71.856 },
        { lat: 21.473, lng: -71.836 },
        { lat: 21.13, lng: -71.839 },
        { lat: 21.071, lng: -71.74 },
        { lat: 21.071, lng: -71.46 },
        { lat: 21.107, lng: -71.416 },
        { lat: 21.114, lng: -71.364 },
        { lat: 21.111, lng: -71.02 },
        { lat: 21.203, lng: -70.988 },
        { lat: 21.25, lng: -70.921 },
        { lat: 21.69, lng: -70.921 },
        { lat: 21.709, lng: -70.94 },
        { lat: 21.719, lng: -71.208 },
        { lat: 21.777, lng: -71.243 },
        { lat: 21.903, lng: -71.247 },
        { lat: 21.915, lng: -71.299 },
        { lat: 21.949, lng: -71.34 },
        { lat: 21.96, lng: -71.41 },
        { lat: 22.029, lng: -71.46 },
        { lat: 22.035, lng: -71.619 },
        { lat: 22.088, lng: -71.701 },
        { lat: 22.149, lng: -71.74 },
        { lat: 22.149, lng: -72.22 },
        { lat: 22.088, lng: -72.259 },
        { lat: 22.067, lng: -72.306 },
        { lat: 22.066, lng: -72.484 },
        { lat: 22.082, lng: -72.533 },
        { lat: 22.145, lng: -72.564 },
        { lat: 22.543, lng: -72.567 },
        { lat: 22.589, lng: -72.62 },
        { lat: 22.589, lng: -72.721 },
        { lat: 22.629, lng: -72.78 },
        { lat: 22.63, lng: -72.885 },
        { lat: 22.669, lng: -72.94 },
        { lat: 22.675, lng: -73.199 },
        { lat: 22.765, lng: -73.272 },
        { lat: 22.823, lng: -73.287 },
        { lat: 22.844, lng: -73.456 },
        { lat: 22.904, lng: -73.484 },
        { lat: 23.29, lng: -73.481 },
        { lat: 23.309, lng: -73.5 },
        { lat: 23.303, lng: -73.993 },
        { lat: 23.094, lng: -73.998 },
        { lat: 23.048, lng: -74.019 },
        { lat: 23.028, lng: -74.064 },
        { lat: 23.029, lng: -74.54 },
        { lat: 23.015, lng: -74.583 },
        { lat: 23.03, lng: -74.624 },
        { lat: 23.067, lng: -74.643 },
        { lat: 23.17, lng: -74.641 },
        { lat: 23.211, lng: -74.675 },
        { lat: 23.263, lng: -74.687 },
        { lat: 23.32, lng: -74.828 },
        { lat: 23.389, lng: -74.854 },
        { lat: 23.431, lng: -74.816 },
        { lat: 23.431, lng: -74.66 },
        { lat: 23.477, lng: -74.607 },
        { lat: 23.599, lng: -74.599 },
        { lat: 23.637, lng: -74.567 },
        { lat: 23.738, lng: -74.533 },
        { lat: 23.754, lng: -74.484 },
        { lat: 23.751, lng: -74.3 },
        { lat: 23.797, lng: -74.247 },
        { lat: 24.343, lng: -74.247 },
        { lat: 24.343, lng: -74.673 },
        { lat: 24.303, lng: -74.713 },
        { lat: 24.219, lng: -74.721 },
        { lat: 24.17, lng: -74.759 },
        { lat: 23.945, lng: -74.767 },
        { lat: 23.907, lng: -74.825 },
        { lat: 23.917, lng: -74.885 },
        { lat: 23.949, lng: -74.914 },
        { lat: 24.023, lng: -74.927 },
        { lat: 24.028, lng: -75.016 },
        { lat: 24.06, lng: -75.07 },
        { lat: 24.383, lng: -75.087 },
        { lat: 24.482, lng: -75.19 },
        { lat: 24.543, lng: -75.207 },
        { lat: 24.556, lng: -75.261 },
        { lat: 24.602, lng: -75.31 },
        { lat: 24.663, lng: -75.327 },
        { lat: 24.731, lng: -75.395 },
        { lat: 24.863, lng: -75.447 },
        { lat: 24.875, lng: -75.499 },
        { lat: 24.909, lng: -75.54 },
        { lat: 24.906, lng: -75.845 },
        { lat: 24.937, lng: -75.908 },
        { lat: 24.988, lng: -75.924 },
        { lat: 25.33, lng: -75.921 },
        { lat: 25.383, lng: -75.967 },
        { lat: 25.401, lng: -76.029 },
        { lat: 25.543, lng: -76.167 },
        { lat: 25.551, lng: -76.249 },
        { lat: 25.583, lng: -76.287 },
        { lat: 25.598, lng: -76.345 },
        { lat: 25.663, lng: -76.407 },
        { lat: 25.688, lng: -76.461 },
        { lat: 25.749, lng: -76.5 },
        { lat: 25.749, lng: -76.9 },
        { lat: 25.735, lng: -76.943 },
        { lat: 25.75, lng: -76.984 },
        { lat: 25.795, lng: -77.004 },
        { lat: 25.969, lng: -76.995 },
        { lat: 26.056, lng: -76.936 },
        { lat: 26.077, lng: -76.847 },
        { lat: 26.13, lng: -76.801 },
        { lat: 26.53, lng: -76.801 },
        { lat: 26.581, lng: -76.839 },
        { lat: 26.703, lng: -76.847 },
        { lat: 26.783, lng: -76.927 },
        { lat: 26.804, lng: -77.016 },
        { lat: 26.903, lng: -77.087 },
        { lat: 26.921, lng: -77.149 },
        { lat: 27.063, lng: -77.287 },
        { lat: 27.075, lng: -77.339 },
        { lat: 27.109, lng: -77.38 },
        { lat: 27.106, lng: -77.924 },
        { lat: 27.113, lng: -77.976 },
        { lat: 27.149, lng: -78.02 },
        { lat: 27.111, lng: -78.069 },
        { lat: 27.103, lng: -78.153 },
        { lat: 26.951, lng: -78.165 },
        { lat: 26.907, lng: -78.227 },
        { lat: 26.916, lng: -78.301 },
        { lat: 26.989, lng: -78.38 },
        { lat: 26.989, lng: -78.78 },
        { lat: 26.91, lng: -78.875 },
        { lat: 26.903, lng: -79.193 },
        { lat: 26.49, lng: -79.199 },
        { lat: 26.437, lng: -79.153 },
        { lat: 26.417, lng: -79.088 },
        { lat: 26.317, lng: -78.993 },
        { lat: 26.309, lng: -78.909 },
        { lat: 26.271, lng: -78.86 },
        { lat: 26.271, lng: -78.54 },
        { lat: 26.307, lng: -78.495 },
        { lat: 26.311, lng: -78.42 },
        { lat: 26.35, lng: -78.365 },
        { lat: 26.351, lng: -78.3 },
        { lat: 26.389, lng: -78.251 },
        { lat: 26.405, lng: -78.126 },
        { lat: 26.351, lng: -78.06 },
        { lat: 26.341, lng: -77.832 },
        { lat: 26.31, lng: -77.805 },
        { lat: 26.237, lng: -77.793 },
        { lat: 26.205, lng: -77.763 },
        { lat: 26.042, lng: -77.745 },
        { lat: 25.997, lng: -77.77 },
        { lat: 25.983, lng: -78.073 },
        { lat: 25.55, lng: -78.079 },
        { lat: 25.49, lng: -78.119 },
        { lat: 25.425, lng: -78.127 },
        { lat: 25.386, lng: -78.19 },
        { lat: 25.383, lng: -78.393 },
        { lat: 25.094, lng: -78.403 },
        { lat: 24.894, lng: -78.534 },
        { lat: 24.863, lng: -78.593 },
        { lat: 24.81, lng: -78.639 },
        { lat: 24.397, lng: -78.633 },
        { lat: 24.317, lng: -78.553 },
        { lat: 24.305, lng: -78.501 },
        { lat: 24.271, lng: -78.46 },
        { lat: 24.262, lng: -78.375 },
        { lat: 24.179, lng: -78.291 },
        { lat: 24.117, lng: -78.273 },
        { lat: 24.049, lng: -78.205 },
        { lat: 23.917, lng: -78.153 },
        { lat: 23.838, lng: -78.049 },
        { lat: 23.73, lng: -78.039 },
        { lat: 23.609, lng: -77.965 },
        { lat: 23.557, lng: -77.953 },
        { lat: 23.545, lng: -77.901 },
        { lat: 23.511, lng: -77.86 },
        { lat: 23.511, lng: -77.82 },
        { lat: 23.511, lng: -77.42 },
        { lat: 23.557, lng: -77.367 },
        { lat: 23.609, lng: -77.355 },
        { lat: 23.65, lng: -77.321 },
        { lat: 24.21, lng: -77.321 },
        { lat: 24.255, lng: -77.357 },
        { lat: 24.33, lng: -77.361 },
        { lat: 24.371, lng: -77.395 },
        { lat: 24.423, lng: -77.407 },
        { lat: 24.482, lng: -77.47 },
        { lat: 24.543, lng: -77.487 },
        { lat: 24.575, lng: -77.517 },
        { lat: 24.746, lng: -77.516 },
        { lat: 24.793, lng: -77.453 },
        { lat: 24.791, lng: -77.18 },
        { lat: 24.825, lng: -77.139 },
        { lat: 24.837, lng: -77.087 },
        { lat: 25.22, lng: -77.073 },
        { lat: 25.245, lng: -77.028 },
        { lat: 25.229, lng: -76.831 },
        { lat: 25.151, lng: -76.7 },
        { lat: 25.145, lng: -76.621 },
        { lat: 25.094, lng: -76.562 },
        { lat: 25.031, lng: -76.532 },
        { lat: 24.97, lng: -76.559 },
        { lat: 24.89, lng: -76.559 },
        { lat: 24.812, lng: -76.519 },
        { lat: 24.768, lng: -76.539 },
        { lat: 24.746, lng: -76.591 },
        { lat: 24.743, lng: -76.993 },
        { lat: 24.317, lng: -76.993 },
        { lat: 24.314, lng: -76.831 },
        { lat: 24.288, lng: -76.776 },
        { lat: 24.197, lng: -76.753 },
        { lat: 24.155, lng: -76.72 },
        { lat: 24.037, lng: -76.713 },
        { lat: 23.963, lng: -76.612 },
        { lat: 23.797, lng: -76.553 },
        { lat: 23.785, lng: -76.501 },
        { lat: 23.751, lng: -76.46 },
        { lat: 23.754, lng: -76.312 },
        { lat: 23.726, lng: -76.254 },
        { lat: 23.674, lng: -76.236 },
        { lat: 23.397, lng: -76.233 },
        { lat: 23.385, lng: -76.141 },
        { lat: 23.317, lng: -76.073 },
        { lat: 23.305, lng: -76.021 },
        { lat: 23.231, lng: -75.9 },
        { lat: 23.231, lng: -75.76 },
        { lat: 23.191, lng: -75.7 },
        { lat: 23.18, lng: -75.47 },
        { lat: 23.121, lng: -75.436 },
        { lat: 22.957, lng: -75.433 },
        { lat: 22.945, lng: -75.381 },
        { lat: 22.871, lng: -75.26 },
        { lat: 22.859, lng: -75.171 },
        { lat: 22.811, lng: -75.126 },
        { lat: 22.677, lng: -75.073 },
        { lat: 22.671, lng: -74.66 },
        { lat: 22.685, lng: -74.617 },
        { lat: 22.67, lng: -74.576 },
        { lat: 22.625, lng: -74.556 },
        { lat: 22.574, lng: -74.563 },
        { lat: 22.53, lng: -74.599 },
        { lat: 22.37, lng: -74.599 },
        { lat: 22.341, lng: -74.512 },
        { lat: 22.309, lng: -74.485 },
        { lat: 21.997, lng: -74.473 },
        { lat: 21.985, lng: -74.421 },
        { lat: 21.951, lng: -74.38 },
        { lat: 21.957, lng: -74.087 },
        { lat: 21.985, lng: -74.059 },
        { lat: 21.997, lng: -74.007 },
        { lat: 22.104, lng: -73.901 },
        { lat: 22.117, lng: -73.807 },
        { lat: 22.237, lng: -73.687 },
        { lat: 22.368, lng: -73.624 },
        { lat: 22.394, lng: -73.562 },
        { lat: 22.394, lng: -73.432 },
        { lat: 22.371, lng: -73.378 },
        { lat: 22.316, lng: -73.356 },
        { lat: 22.17, lng: -73.359 },
        { lat: 22.151, lng: -73.34 },
        { lat: 22.151, lng: -73.08 },
        { lat: 22.071, lng: -72.94 },
        { lat: 22.074, lng: -72.596 },
        { lat: 22.048, lng: -72.536 },
        { lat: 21.969, lng: -72.526 },
        { lat: 21.92, lng: -72.572 },
        { lat: 21.903, lng: -72.633 },
        { lat: 21.863, lng: -72.673 },
        { lat: 21.776, lng: -72.679 },
        { lat: 21.743, lng: -72.705 },
        { lat: 21.749, lng: -73.22 },
        { lat: 21.73, lng: -73.239 },
        { lat: 21.619, lng: -73.241 },
        { lat: 21.57, lng: -73.279 },
        { lat: 21.495, lng: -73.283 },
        { lat: 21.438, lng: -73.335 },
        { lat: 21.429, lng: -73.58 },
        { lat: 21.39, lng: -73.635 },
        { lat: 21.389, lng: -73.74 },
        { lat: 21.322, lng: -73.787 },
        { lat: 21.303, lng: -73.873 },
        { lat: 21.251, lng: -73.885 },
        { lat: 21.21, lng: -73.919 },
        { lat: 21.17, lng: -73.919 },
        { lat: 20.81, lng: -73.919 },
        { lat: 20.769, lng: -73.885 },
        { lat: 20.717, lng: -73.873 },
    ],
    [
        { lat: 25.256, lng: -77.066 },
        { lat: 25.269, lng: -77.1 },
        { lat: 25.266, lng: -77.564 },
        { lat: 25.289, lng: -77.622 },
        { lat: 25.346, lng: -77.644 },
        { lat: 25.635, lng: -77.644 },
        { lat: 25.686, lng: -77.637 },
        { lat: 25.772, lng: -77.581 },
        { lat: 25.784, lng: -77.518 },
        { lat: 25.717, lng: -77.473 },
        { lat: 25.709, lng: -77.389 },
        { lat: 25.671, lng: -77.34 },
        { lat: 25.674, lng: -77.036 },
        { lat: 25.651, lng: -76.978 },
        { lat: 25.596, lng: -76.956 },
        { lat: 25.465, lng: -76.96 },
        { lat: 25.41, lng: -76.999 },
        { lat: 25.285, lng: -77.004 },
        { lat: 25.256, lng: -77.066 },
    ],
    [
        { lat: 23.776, lng: -75.601 },
        { lat: 23.787, lng: -75.655 },
        { lat: 23.826, lng: -75.688 },
        { lat: 23.833, lng: -75.736 },
        { lat: 23.869, lng: -75.78 },
        { lat: 23.866, lng: -76.044 },
        { lat: 23.889, lng: -76.102 },
        { lat: 23.946, lng: -76.124 },
        { lat: 24.21, lng: -76.121 },
        { lat: 24.251, lng: -76.155 },
        { lat: 24.303, lng: -76.167 },
        { lat: 24.339, lng: -76.244 },
        { lat: 24.378, lng: -76.255 },
        { lat: 24.416, lng: -76.238 },
        { lat: 24.433, lng: -76.203 },
        { lat: 24.442, lng: -75.975 },
        { lat: 24.425, lng: -75.901 },
        { lat: 24.384, lng: -75.856 },
        { lat: 24.317, lng: -75.833 },
        { lat: 24.28, lng: -75.77 },
        { lat: 24.21, lng: -75.759 },
        { lat: 24.161, lng: -75.721 },
        { lat: 23.954, lng: -75.712 },
        { lat: 23.911, lng: -75.584 },
        { lat: 23.879, lng: -75.55 },
        { lat: 23.81, lng: -75.559 },
        { lat: 23.776, lng: -75.601 },
    ],
    [
        { lat: 25.357, lng: -79.473 },
        { lat: 25.357, lng: -79.087 },
        { lat: 25.479, lng: -79.079 },
        { lat: 25.53, lng: -79.041 },
        { lat: 25.943, lng: -79.047 },
        { lat: 25.949, lng: -79.36 },
        { lat: 25.989, lng: -79.42 },
        { lat: 25.903, lng: -79.513 },
        { lat: 25.77, lng: -79.519 },
        { lat: 25.726, lng: -79.483 },
        { lat: 25.675, lng: -79.476 },
        { lat: 25.357, lng: -79.473 },
    ],
    [
        { lat: 23.357, lng: -164.913 },
        { lat: 23.357, lng: -164.487 },
        { lat: 23.783, lng: -164.487 },
        { lat: 23.783, lng: -164.913 },
        { lat: 23.357, lng: -164.913 },
    ],
    [
        { lat: 23.317, lng: -79.753 },
        { lat: 23.317, lng: -79.367 },
        { lat: 23.743, lng: -79.367 },
        { lat: 23.743, lng: -79.793 },
        { lat: 23.37, lng: -79.799 },
        { lat: 23.317, lng: -79.753 },
    ],
    [
        { lat: 19.637, lng: -77.913 },
        { lat: 19.631, lng: -77.3 },
        { lat: 19.667, lng: -77.256 },
        { lat: 19.674, lng: -77.205 },
        { lat: 19.671, lng: -76.82 },
        { lat: 19.709, lng: -76.769 },
        { lat: 19.711, lng: -76.62 },
        { lat: 19.747, lng: -76.576 },
        { lat: 19.754, lng: -76.521 },
        { lat: 19.751, lng: -75.92 },
        { lat: 19.671, lng: -75.78 },
        { lat: 19.671, lng: -74.94 },
        { lat: 19.709, lng: -74.889 },
        { lat: 19.717, lng: -74.767 },
        { lat: 19.778, lng: -74.733 },
        { lat: 19.791, lng: -74.66 },
        { lat: 19.83, lng: -74.605 },
        { lat: 19.831, lng: -74.38 },
        { lat: 19.871, lng: -74.321 },
        { lat: 19.877, lng: -74.047 },
        { lat: 19.931, lng: -74.022 },
        { lat: 19.957, lng: -73.967 },
        { lat: 20.01, lng: -73.921 },
        { lat: 20.41, lng: -73.921 },
        { lat: 20.509, lng: -74.02 },
        { lat: 20.509, lng: -74.201 },
        { lat: 20.556, lng: -74.301 },
        { lat: 20.642, lng: -74.39 },
        { lat: 20.703, lng: -74.407 },
        { lat: 20.72, lng: -74.468 },
        { lat: 20.823, lng: -74.567 },
        { lat: 20.833, lng: -74.655 },
        { lat: 20.909, lng: -74.78 },
        { lat: 20.909, lng: -75.12 },
        { lat: 20.949, lng: -75.18 },
        { lat: 20.956, lng: -75.301 },
        { lat: 21.019, lng: -75.359 },
        { lat: 21.09, lng: -75.361 },
        { lat: 21.145, lng: -75.4 },
        { lat: 21.263, lng: -75.407 },
        { lat: 21.275, lng: -75.459 },
        { lat: 21.303, lng: -75.487 },
        { lat: 21.311, lng: -75.849 },
        { lat: 21.469, lng: -76.14 },
        { lat: 21.471, lng: -76.329 },
        { lat: 21.543, lng: -76.447 },
        { lat: 21.553, lng: -76.535 },
        { lat: 21.623, lng: -76.647 },
        { lat: 21.636, lng: -76.701 },
        { lat: 21.783, lng: -76.847 },
        { lat: 21.796, lng: -76.901 },
        { lat: 21.863, lng: -76.967 },
        { lat: 21.878, lng: -77.025 },
        { lat: 21.943, lng: -77.087 },
        { lat: 21.998, lng: -77.225 },
        { lat: 22.103, lng: -77.327 },
        { lat: 22.128, lng: -77.381 },
        { lat: 22.211, lng: -77.435 },
        { lat: 22.263, lng: -77.447 },
        { lat: 22.297, lng: -77.548 },
        { lat: 22.383, lng: -77.567 },
        { lat: 22.503, lng: -77.647 },
        { lat: 22.517, lng: -77.844 },
        { lat: 22.623, lng: -77.927 },
        { lat: 22.635, lng: -78.059 },
        { lat: 22.743, lng: -78.167 },
        { lat: 22.746, lng: -78.688 },
        { lat: 22.777, lng: -78.748 },
        { lat: 22.869, lng: -78.78 },
        { lat: 22.871, lng: -79.169 },
        { lat: 22.92, lng: -79.268 },
        { lat: 22.983, lng: -79.327 },
        { lat: 22.998, lng: -79.425 },
        { lat: 23.103, lng: -79.527 },
        { lat: 23.115, lng: -79.579 },
        { lat: 23.143, lng: -79.607 },
        { lat: 23.159, lng: -79.808 },
        { lat: 23.263, lng: -79.887 },
        { lat: 23.275, lng: -79.939 },
        { lat: 23.309, lng: -79.98 },
        { lat: 23.311, lng: -80.051 },
        { lat: 23.349, lng: -80.1 },
        { lat: 23.35, lng: -80.245 },
        { lat: 23.389, lng: -80.3 },
        { lat: 23.39, lng: -80.525 },
        { lat: 23.429, lng: -80.58 },
        { lat: 23.433, lng: -80.656 },
        { lat: 23.469, lng: -80.7 },
        { lat: 23.469, lng: -81.1 },
        { lat: 23.391, lng: -81.191 },
        { lat: 23.389, lng: -81.42 },
        { lat: 23.353, lng: -81.464 },
        { lat: 23.346, lng: -81.517 },
        { lat: 23.349, lng: -81.761 },
        { lat: 23.389, lng: -81.82 },
        { lat: 23.389, lng: -82.46 },
        { lat: 23.351, lng: -82.511 },
        { lat: 23.343, lng: -82.633 },
        { lat: 23.278, lng: -82.695 },
        { lat: 23.23, lng: -82.795 },
        { lat: 23.229, lng: -83.26 },
        { lat: 23.19, lng: -83.315 },
        { lat: 23.189, lng: -83.46 },
        { lat: 23.115, lng: -83.541 },
        { lat: 23.109, lng: -83.62 },
        { lat: 23.07, lng: -83.675 },
        { lat: 23.069, lng: -83.74 },
        { lat: 22.993, lng: -83.865 },
        { lat: 22.989, lng: -83.94 },
        { lat: 22.953, lng: -83.984 },
        { lat: 22.949, lng: -84.06 },
        { lat: 22.91, lng: -84.115 },
        { lat: 22.903, lng: -84.233 },
        { lat: 22.8, lng: -84.332 },
        { lat: 22.783, lng: -84.393 },
        { lat: 22.729, lng: -84.406 },
        { lat: 22.683, lng: -84.448 },
        { lat: 22.663, lng: -84.513 },
        { lat: 22.605, lng: -84.528 },
        { lat: 22.543, lng: -84.593 },
        { lat: 22.459, lng: -84.601 },
        { lat: 22.41, lng: -84.639 },
        { lat: 22.301, lng: -84.636 },
        { lat: 22.246, lng: -84.662 },
        { lat: 22.223, lng: -84.753 },
        { lat: 22.191, lng: -84.791 },
        { lat: 22.189, lng: -84.86 },
        { lat: 22.151, lng: -84.909 },
        { lat: 22.149, lng: -85.02 },
        { lat: 22.113, lng: -85.064 },
        { lat: 22.109, lng: -85.14 },
        { lat: 22.09, lng: -85.159 },
        { lat: 21.637, lng: -85.153 },
        { lat: 21.633, lng: -84.788 },
        { lat: 21.612, lng: -84.739 },
        { lat: 21.551, lng: -84.7 },
        { lat: 21.551, lng: -84.34 },
        { lat: 21.589, lng: -84.291 },
        { lat: 21.597, lng: -84.207 },
        { lat: 21.662, lng: -84.145 },
        { lat: 21.71, lng: -84.045 },
        { lat: 21.711, lng: -83.82 },
        { lat: 21.747, lng: -83.776 },
        { lat: 21.754, lng: -83.725 },
        { lat: 21.753, lng: -83.467 },
        { lat: 21.732, lng: -83.419 },
        { lat: 21.686, lng: -83.398 },
        { lat: 21.41, lng: -83.399 },
        { lat: 21.369, lng: -83.365 },
        { lat: 21.317, lng: -83.353 },
        { lat: 21.305, lng: -83.301 },
        { lat: 21.231, lng: -83.18 },
        { lat: 21.231, lng: -82.62 },
        { lat: 21.307, lng: -82.495 },
        { lat: 21.317, lng: -82.407 },
        { lat: 21.378, lng: -82.321 },
        { lat: 21.351, lng: -82.26 },
        { lat: 21.351, lng: -81.94 },
        { lat: 21.387, lng: -81.896 },
        { lat: 21.394, lng: -81.844 },
        { lat: 21.391, lng: -81.34 },
        { lat: 21.425, lng: -81.299 },
        { lat: 21.437, lng: -81.247 },
        { lat: 21.517, lng: -81.167 },
        { lat: 21.758, lng: -81.164 },
        { lat: 21.82, lng: -81.13 },
        { lat: 21.834, lng: -81.081 },
        { lat: 21.829, lng: -80.629 },
        { lat: 21.677, lng: -80.473 },
        { lat: 21.665, lng: -80.421 },
        { lat: 21.631, lng: -80.38 },
        { lat: 21.619, lng: -80.291 },
        { lat: 21.571, lng: -80.246 },
        { lat: 21.517, lng: -80.233 },
        { lat: 21.509, lng: -80.111 },
        { lat: 21.471, lng: -80.06 },
        { lat: 21.47, lng: -79.835 },
        { lat: 21.431, lng: -79.78 },
        { lat: 21.429, lng: -79.711 },
        { lat: 21.397, lng: -79.673 },
        { lat: 21.383, lng: -79.61 },
        { lat: 21.338, lng: -79.585 },
        { lat: 21.13, lng: -79.599 },
        { lat: 21.075, lng: -79.56 },
        { lat: 20.877, lng: -79.553 },
        { lat: 20.862, lng: -79.455 },
        { lat: 20.677, lng: -79.273 },
        { lat: 20.662, lng: -79.215 },
        { lat: 20.597, lng: -79.153 },
        { lat: 20.584, lng: -79.059 },
        { lat: 20.517, lng: -78.993 },
        { lat: 20.501, lng: -78.872 },
        { lat: 20.437, lng: -78.833 },
        { lat: 20.427, lng: -78.664 },
        { lat: 20.311, lng: -78.54 },
        { lat: 20.311, lng: -78.14 },
        { lat: 20.33, lng: -78.121 },
        { lat: 20.469, lng: -78.115 },
        { lat: 20.506, lng: -78.076 },
        { lat: 20.511, lng: -77.92 },
        { lat: 20.471, lng: -77.86 },
        { lat: 20.463, lng: -77.65 },
        { lat: 20.418, lng: -77.625 },
        { lat: 20.368, lng: -77.641 },
        { lat: 20.278, lng: -77.735 },
        { lat: 20.263, lng: -77.793 },
        { lat: 20.205, lng: -77.808 },
        { lat: 20.103, lng: -77.913 },
        { lat: 20.051, lng: -77.925 },
        { lat: 20.01, lng: -77.959 },
        { lat: 19.89, lng: -77.959 },
        { lat: 19.83, lng: -77.919 },
        { lat: 19.637, lng: -77.913 },
    ],
    [
        { lat: 22.149, lng: -82.821 },
        { lat: 22.149, lng: -82.856 },
        { lat: 22.175, lng: -82.887 },
        { lat: 22.242, lng: -82.885 },
        { lat: 22.302, lng: -82.825 },
        { lat: 22.317, lng: -82.767 },
        { lat: 22.349, lng: -82.731 },
        { lat: 22.354, lng: -82.433 },
        { lat: 22.338, lng: -82.387 },
        { lat: 22.309, lng: -82.365 },
        { lat: 22.215, lng: -82.357 },
        { lat: 22.155, lng: -82.401 },
        { lat: 22.149, lng: -82.821 },
    ],
    [
        { lat: 21.775, lng: -82.31 },
        { lat: 21.794, lng: -82.351 },
        { lat: 21.87, lng: -82.401 },
        { lat: 22.113, lng: -82.403 },
        { lat: 22.164, lng: -82.359 },
        { lat: 22.153, lng: -82.304 },
        { lat: 22.111, lng: -82.26 },
        { lat: 22.109, lng: -82.191 },
        { lat: 22.077, lng: -82.153 },
        { lat: 22.065, lng: -82.101 },
        { lat: 21.991, lng: -82.02 },
        { lat: 21.993, lng: -81.867 },
        { lat: 21.949, lng: -81.805 },
        { lat: 21.905, lng: -81.807 },
        { lat: 21.875, lng: -81.841 },
        { lat: 21.869, lng: -82.06 },
        { lat: 21.83, lng: -82.115 },
        { lat: 21.829, lng: -82.22 },
        { lat: 21.789, lng: -82.261 },
        { lat: 21.775, lng: -82.31 },
    ],
    [
        { lat: 21.096, lng: -78.826 },
        { lat: 21.115, lng: -78.868 },
        { lat: 21.183, lng: -78.927 },
        { lat: 21.187, lng: -79.012 },
        { lat: 21.208, lng: -79.061 },
        { lat: 21.285, lng: -79.094 },
        { lat: 21.336, lng: -79.078 },
        { lat: 21.353, lng: -79.043 },
        { lat: 21.354, lng: -78.92 },
        { lat: 21.336, lng: -78.847 },
        { lat: 21.295, lng: -78.808 },
        { lat: 21.186, lng: -78.759 },
        { lat: 21.12, lng: -78.767 },
        { lat: 21.096, lng: -78.826 },
    ],
    [
        { lat: 22.357, lng: -89.673 },
        { lat: 22.357, lng: -89.647 },
        { lat: 22.409, lng: -89.635 },
        { lat: 22.45, lng: -89.601 },
        { lat: 22.491, lng: -89.635 },
        { lat: 22.543, lng: -89.647 },
        { lat: 22.555, lng: -89.699 },
        { lat: 22.589, lng: -89.74 },
        { lat: 22.557, lng: -89.753 },
        { lat: 22.468, lng: -89.67 },
        { lat: 22.357, lng: -89.673 },
    ],
    [
        { lat: 21.997, lng: -75.953 },
        { lat: 21.997, lng: -75.527 },
        { lat: 22.41, lng: -75.521 },
        { lat: 22.465, lng: -75.593 },
        { lat: 22.583, lng: -75.607 },
        { lat: 22.583, lng: -75.993 },
        { lat: 22.385, lng: -76.0 },
        { lat: 22.33, lng: -76.039 },
        { lat: 22.17, lng: -76.039 },
        { lat: 22.115, lng: -75.967 },
        { lat: 21.997, lng: -75.953 },
    ],
    [
        { lat: 21.597, lng: -160.433 },
        { lat: 21.591, lng: -160.02 },
        { lat: 21.705, lng: -159.907 },
        { lat: 21.723, lng: -159.864 },
        { lat: 21.71, lng: -159.715 },
        { lat: 21.671, lng: -159.66 },
        { lat: 21.677, lng: -159.207 },
        { lat: 21.757, lng: -159.127 },
        { lat: 21.879, lng: -159.119 },
        { lat: 21.93, lng: -159.081 },
        { lat: 22.343, lng: -159.087 },
        { lat: 22.429, lng: -159.18 },
        { lat: 22.429, lng: -159.78 },
        { lat: 22.395, lng: -159.821 },
        { lat: 22.383, lng: -159.873 },
        { lat: 22.233, lng: -160.024 },
        { lat: 22.223, lng: -160.273 },
        { lat: 22.158, lng: -160.335 },
        { lat: 22.143, lng: -160.393 },
        { lat: 22.09, lng: -160.439 },
        { lat: 21.597, lng: -160.433 },
    ],
    [
        { lat: 21.077, lng: -106.473 },
        { lat: 21.077, lng: -106.007 },
        { lat: 21.543, lng: -106.007 },
        { lat: 21.577, lng: -106.108 },
        { lat: 21.703, lng: -106.127 },
        { lat: 21.707, lng: -106.212 },
        { lat: 21.729, lng: -106.262 },
        { lat: 21.903, lng: -106.327 },
        { lat: 21.929, lng: -106.422 },
        { lat: 22.023, lng: -106.447 },
        { lat: 22.023, lng: -106.873 },
        { lat: 21.57, lng: -106.879 },
        { lat: 21.519, lng: -106.841 },
        { lat: 21.397, lng: -106.833 },
        { lat: 21.298, lng: -106.73 },
        { lat: 21.237, lng: -106.713 },
        { lat: 21.234, lng: -106.553 },
        { lat: 21.214, lng: -106.502 },
        { lat: 21.163, lng: -106.477 },
        { lat: 21.077, lng: -106.473 },
    ],
    [
        { lat: 18.717, lng: -155.793 },
        { lat: 18.717, lng: -155.447 },
        { lat: 18.797, lng: -155.367 },
        { lat: 18.891, lng: -155.342 },
        { lat: 18.917, lng: -155.287 },
        { lat: 18.982, lng: -155.225 },
        { lat: 18.997, lng: -155.167 },
        { lat: 19.062, lng: -155.105 },
        { lat: 19.071, lng: -154.94 },
        { lat: 19.109, lng: -154.891 },
        { lat: 19.117, lng: -154.807 },
        { lat: 19.219, lng: -154.709 },
        { lat: 19.237, lng: -154.647 },
        { lat: 19.277, lng: -154.607 },
        { lat: 19.73, lng: -154.601 },
        { lat: 19.783, lng: -154.647 },
        { lat: 19.804, lng: -154.713 },
        { lat: 19.849, lng: -154.754 },
        { lat: 19.903, lng: -154.767 },
        { lat: 19.974, lng: -154.866 },
        { lat: 20.103, lng: -154.887 },
        { lat: 20.183, lng: -154.967 },
        { lat: 20.195, lng: -155.019 },
        { lat: 20.269, lng: -155.14 },
        { lat: 20.273, lng: -155.216 },
        { lat: 20.349, lng: -155.34 },
        { lat: 20.353, lng: -155.416 },
        { lat: 20.383, lng: -155.447 },
        { lat: 20.4, lng: -155.508 },
        { lat: 20.463, lng: -155.567 },
        { lat: 20.466, lng: -155.727 },
        { lat: 20.486, lng: -155.778 },
        { lat: 20.547, lng: -155.804 },
        { lat: 20.97, lng: -155.801 },
        { lat: 21.023, lng: -155.847 },
        { lat: 21.078, lng: -155.985 },
        { lat: 21.143, lng: -156.047 },
        { lat: 21.153, lng: -156.296 },
        { lat: 21.223, lng: -156.367 },
        { lat: 21.241, lng: -156.469 },
        { lat: 21.289, lng: -156.514 },
        { lat: 21.343, lng: -156.527 },
        { lat: 21.389, lng: -156.58 },
        { lat: 21.386, lng: -156.884 },
        { lat: 21.393, lng: -156.936 },
        { lat: 21.429, lng: -156.98 },
        { lat: 21.436, lng: -157.402 },
        { lat: 21.569, lng: -157.514 },
        { lat: 21.663, lng: -157.527 },
        { lat: 21.688, lng: -157.621 },
        { lat: 21.771, lng: -157.675 },
        { lat: 21.823, lng: -157.687 },
        { lat: 21.909, lng: -157.78 },
        { lat: 21.909, lng: -158.18 },
        { lat: 21.838, lng: -158.255 },
        { lat: 21.823, lng: -158.313 },
        { lat: 21.793, lng: -158.344 },
        { lat: 21.783, lng: -158.473 },
        { lat: 21.37, lng: -158.479 },
        { lat: 21.329, lng: -158.445 },
        { lat: 21.277, lng: -158.433 },
        { lat: 21.179, lng: -158.331 },
        { lat: 21.117, lng: -158.313 },
        { lat: 21.077, lng: -158.273 },
        { lat: 21.074, lng: -157.599 },
        { lat: 21.061, lng: -157.552 },
        { lat: 20.966, lng: -157.483 },
        { lat: 20.877, lng: -157.473 },
        { lat: 20.873, lng: -157.348 },
        { lat: 20.843, lng: -157.292 },
        { lat: 20.761, lng: -157.241 },
        { lat: 20.69, lng: -157.239 },
        { lat: 20.641, lng: -157.201 },
        { lat: 20.557, lng: -157.193 },
        { lat: 20.554, lng: -156.993 },
        { lat: 20.531, lng: -156.938 },
        { lat: 20.311, lng: -156.86 },
        { lat: 20.311, lng: -156.38 },
        { lat: 20.374, lng: -156.338 },
        { lat: 20.394, lng: -156.285 },
        { lat: 20.394, lng: -156.151 },
        { lat: 20.368, lng: -156.096 },
        { lat: 20.27, lng: -156.079 },
        { lat: 20.21, lng: -156.119 },
        { lat: 20.099, lng: -156.121 },
        { lat: 19.97, lng: -156.239 },
        { lat: 19.49, lng: -156.239 },
        { lat: 19.411, lng: -156.166 },
        { lat: 19.33, lng: -156.159 },
        { lat: 19.275, lng: -156.12 },
        { lat: 18.877, lng: -156.113 },
        { lat: 18.837, lng: -156.073 },
        { lat: 18.827, lng: -155.984 },
        { lat: 18.757, lng: -155.913 },
        { lat: 18.749, lng: -155.829 },
        { lat: 18.717, lng: -155.793 },
    ],
    [
        { lat: 17.357, lng: -71.713 },
        { lat: 17.351, lng: -71.3 },
        { lat: 17.385, lng: -71.259 },
        { lat: 17.397, lng: -71.207 },
        { lat: 17.615, lng: -71.112 },
        { lat: 17.797, lng: -70.927 },
        { lat: 17.849, lng: -70.915 },
        { lat: 17.89, lng: -70.881 },
        { lat: 17.969, lng: -70.875 },
        { lat: 18.016, lng: -70.813 },
        { lat: 17.991, lng: -70.74 },
        { lat: 17.991, lng: -70.26 },
        { lat: 18.031, lng: -70.201 },
        { lat: 18.037, lng: -69.967 },
        { lat: 18.183, lng: -69.83 },
        { lat: 18.202, lng: -69.791 },
        { lat: 18.194, lng: -69.117 },
        { lat: 18.184, lng: -69.059 },
        { lat: 18.135, lng: -69.008 },
        { lat: 17.97, lng: -68.999 },
        { lat: 17.911, lng: -68.94 },
        { lat: 17.911, lng: -68.38 },
        { lat: 17.93, lng: -68.361 },
        { lat: 18.12, lng: -68.35 },
        { lat: 18.145, lng: -68.319 },
        { lat: 18.157, lng: -68.247 },
        { lat: 18.222, lng: -68.227 },
        { lat: 18.245, lng: -68.172 },
        { lat: 18.23, lng: -68.136 },
        { lat: 18.197, lng: -68.117 },
        { lat: 18.101, lng: -68.121 },
        { lat: 18.05, lng: -68.159 },
        { lat: 17.93, lng: -68.159 },
        { lat: 17.877, lng: -68.113 },
        { lat: 17.869, lng: -68.029 },
        { lat: 17.831, lng: -67.98 },
        { lat: 17.831, lng: -67.7 },
        { lat: 17.89, lng: -67.641 },
        { lat: 18.29, lng: -67.641 },
        { lat: 18.309, lng: -67.66 },
        { lat: 18.306, lng: -68.042 },
        { lat: 18.322, lng: -68.093 },
        { lat: 18.386, lng: -68.124 },
        { lat: 18.81, lng: -68.121 },
        { lat: 18.903, lng: -68.207 },
        { lat: 18.921, lng: -68.269 },
        { lat: 19.063, lng: -68.407 },
        { lat: 19.08, lng: -68.468 },
        { lat: 19.183, lng: -68.567 },
        { lat: 19.191, lng: -68.651 },
        { lat: 19.229, lng: -68.7 },
        { lat: 19.226, lng: -68.884 },
        { lat: 19.244, lng: -68.936 },
        { lat: 19.303, lng: -68.964 },
        { lat: 19.49, lng: -68.961 },
        { lat: 19.543, lng: -69.047 },
        { lat: 19.546, lng: -69.562 },
        { lat: 19.562, lng: -69.613 },
        { lat: 19.661, lng: -69.679 },
        { lat: 19.823, lng: -69.687 },
        { lat: 19.869, lng: -69.74 },
        { lat: 19.87, lng: -70.125 },
        { lat: 19.989, lng: -70.26 },
        { lat: 19.991, lng: -70.489 },
        { lat: 20.04, lng: -70.588 },
        { lat: 20.103, lng: -70.647 },
        { lat: 20.111, lng: -70.769 },
        { lat: 20.149, lng: -70.82 },
        { lat: 20.149, lng: -71.18 },
        { lat: 20.113, lng: -71.224 },
        { lat: 20.106, lng: -71.277 },
        { lat: 20.109, lng: -71.82 },
        { lat: 20.038, lng: -71.895 },
        { lat: 20.023, lng: -71.953 },
        { lat: 19.991, lng: -71.989 },
        { lat: 19.989, lng: -72.201 },
        { lat: 20.029, lng: -72.26 },
        { lat: 20.033, lng: -72.336 },
        { lat: 20.078, lng: -72.39 },
        { lat: 20.135, lng: -72.437 },
        { lat: 20.223, lng: -72.447 },
        { lat: 20.235, lng: -72.499 },
        { lat: 20.269, lng: -72.54 },
        { lat: 20.273, lng: -72.615 },
        { lat: 20.309, lng: -72.66 },
        { lat: 20.273, lng: -72.704 },
        { lat: 20.266, lng: -72.755 },
        { lat: 20.269, lng: -73.14 },
        { lat: 20.159, lng: -73.192 },
        { lat: 20.149, lng: -73.34 },
        { lat: 20.078, lng: -73.415 },
        { lat: 20.063, lng: -73.473 },
        { lat: 20.035, lng: -73.501 },
        { lat: 20.023, lng: -73.593 },
        { lat: 19.983, lng: -73.633 },
        { lat: 19.931, lng: -73.645 },
        { lat: 19.89, lng: -73.679 },
        { lat: 19.477, lng: -73.673 },
        { lat: 19.431, lng: -73.62 },
        { lat: 19.427, lng: -73.264 },
        { lat: 19.301, lng: -73.044 },
        { lat: 19.26, lng: -73.025 },
        { lat: 19.214, lng: -73.037 },
        { lat: 19.149, lng: -73.119 },
        { lat: 19.143, lng: -73.473 },
        { lat: 18.979, lng: -73.481 },
        { lat: 18.93, lng: -73.519 },
        { lat: 18.857, lng: -73.532 },
        { lat: 18.828, lng: -73.584 },
        { lat: 18.829, lng: -73.92 },
        { lat: 18.869, lng: -73.98 },
        { lat: 18.869, lng: -74.5 },
        { lat: 18.835, lng: -74.541 },
        { lat: 18.823, lng: -74.593 },
        { lat: 18.783, lng: -74.633 },
        { lat: 18.699, lng: -74.641 },
        { lat: 18.65, lng: -74.679 },
        { lat: 18.21, lng: -74.679 },
        { lat: 18.169, lng: -74.645 },
        { lat: 18.117, lng: -74.633 },
        { lat: 18.109, lng: -74.549 },
        { lat: 18.071, lng: -74.5 },
        { lat: 18.07, lng: -74.355 },
        { lat: 18.024, lng: -74.259 },
        { lat: 17.899, lng: -74.131 },
        { lat: 17.831, lng: -74.1 },
        { lat: 17.831, lng: -73.62 },
        { lat: 17.87, lng: -73.565 },
        { lat: 17.871, lng: -73.42 },
        { lat: 17.917, lng: -73.367 },
        { lat: 17.972, lng: -73.341 },
        { lat: 17.994, lng: -73.287 },
        { lat: 17.989, lng: -73.191 },
        { lat: 17.951, lng: -73.14 },
        { lat: 17.951, lng: -72.42 },
        { lat: 18.027, lng: -72.336 },
        { lat: 18.03, lng: -72.235 },
        { lat: 17.98, lng: -72.132 },
        { lat: 17.837, lng: -71.993 },
        { lat: 17.824, lng: -71.939 },
        { lat: 17.779, lng: -71.891 },
        { lat: 17.557, lng: -71.873 },
        { lat: 17.526, lng: -71.774 },
        { lat: 17.441, lng: -71.721 },
        { lat: 17.357, lng: -71.713 },
    ],
    [
        { lat: 19.477, lng: -80.313 },
        { lat: 19.471, lng: -79.7 },
        { lat: 19.509, lng: -79.651 },
        { lat: 19.517, lng: -79.567 },
        { lat: 19.57, lng: -79.521 },
        { lat: 19.943, lng: -79.527 },
        { lat: 19.949, lng: -80.02 },
        { lat: 19.91, lng: -80.075 },
        { lat: 19.903, lng: -80.273 },
        { lat: 19.85, lng: -80.319 },
        { lat: 19.477, lng: -80.313 },
    ],
    [
        { lat: 19.077, lng: -81.593 },
        { lat: 19.071, lng: -81.06 },
        { lat: 19.109, lng: -81.009 },
        { lat: 19.117, lng: -80.887 },
        { lat: 19.543, lng: -80.887 },
        { lat: 19.549, lng: -81.121 },
        { lat: 19.589, lng: -81.18 },
        { lat: 19.583, lng: -81.633 },
        { lat: 19.17, lng: -81.639 },
        { lat: 19.129, lng: -81.605 },
        { lat: 19.077, lng: -81.593 },
    ],
    [
        { lat: 18.517, lng: -111.193 },
        { lat: 18.517, lng: -110.727 },
        { lat: 19.004, lng: -110.723 },
        { lat: 19.058, lng: -110.693 },
        { lat: 19.09, lng: -110.601 },
        { lat: 19.543, lng: -110.607 },
        { lat: 19.549, lng: -110.98 },
        { lat: 19.503, lng: -111.033 },
        { lat: 19.106, lng: -111.047 },
        { lat: 19.068, lng: -111.102 },
        { lat: 19.063, lng: -111.193 },
        { lat: 18.97, lng: -111.279 },
        { lat: 18.57, lng: -111.279 },
        { lat: 18.517, lng: -111.193 },
    ],
    [
        { lat: 17.477, lng: -64.993 },
        { lat: 17.471, lng: -64.66 },
        { lat: 17.509, lng: -64.609 },
        { lat: 17.511, lng: -64.46 },
        { lat: 17.545, lng: -64.419 },
        { lat: 17.557, lng: -64.367 },
        { lat: 17.943, lng: -64.367 },
        { lat: 17.951, lng: -64.489 },
        { lat: 17.989, lng: -64.54 },
        { lat: 17.986, lng: -65.011 },
        { lat: 18.012, lng: -65.064 },
        { lat: 18.06, lng: -65.078 },
        { lat: 18.098, lng: -65.053 },
        { lat: 18.114, lng: -65.006 },
        { lat: 18.111, lng: -64.5 },
        { lat: 18.149, lng: -64.451 },
        { lat: 18.157, lng: -64.367 },
        { lat: 18.224, lng: -64.323 },
        { lat: 18.237, lng: -64.207 },
        { lat: 18.317, lng: -64.127 },
        { lat: 18.475, lng: -64.12 },
        { lat: 18.53, lng: -64.081 },
        { lat: 18.943, lng: -64.087 },
        { lat: 18.949, lng: -64.58 },
        { lat: 18.93, lng: -64.599 },
        { lat: 18.741, lng: -64.601 },
        { lat: 18.678, lng: -64.655 },
        { lat: 18.669, lng: -64.82 },
        { lat: 18.631, lng: -64.869 },
        { lat: 18.623, lng: -64.953 },
        { lat: 18.591, lng: -64.989 },
        { lat: 18.589, lng: -65.14 },
        { lat: 18.549, lng: -65.2 },
        { lat: 18.55, lng: -65.405 },
        { lat: 18.589, lng: -65.46 },
        { lat: 18.593, lng: -65.536 },
        { lat: 18.629, lng: -65.58 },
        { lat: 18.631, lng: -65.691 },
        { lat: 18.669, lng: -65.74 },
        { lat: 18.666, lng: -66.324 },
        { lat: 18.673, lng: -66.376 },
        { lat: 18.709, lng: -66.42 },
        { lat: 18.709, lng: -67.3 },
        { lat: 18.703, lng: -67.353 },
        { lat: 18.642, lng: -67.37 },
        { lat: 18.543, lng: -67.473 },
        { lat: 18.37, lng: -67.479 },
        { lat: 18.315, lng: -67.44 },
        { lat: 18.09, lng: -67.439 },
        { lat: 18.031, lng: -67.399 },
        { lat: 17.757, lng: -67.393 },
        { lat: 17.751, lng: -67.119 },
        { lat: 17.711, lng: -67.06 },
        { lat: 17.711, lng: -66.74 },
        { lat: 17.747, lng: -66.696 },
        { lat: 17.754, lng: -66.64 },
        { lat: 17.751, lng: -66.439 },
        { lat: 17.711, lng: -66.38 },
        { lat: 17.711, lng: -65.98 },
        { lat: 17.75, lng: -65.925 },
        { lat: 17.757, lng: -65.767 },
        { lat: 17.785, lng: -65.739 },
        { lat: 17.797, lng: -65.687 },
        { lat: 17.87, lng: -65.605 },
        { lat: 17.871, lng: -65.3 },
        { lat: 17.909, lng: -65.249 },
        { lat: 17.914, lng: -65.153 },
        { lat: 17.892, lng: -65.099 },
        { lat: 17.842, lng: -65.077 },
        { lat: 17.53, lng: -65.079 },
        { lat: 17.477, lng: -64.993 },
    ],
    [
        { lat: 17.517, lng: -77.433 },
        { lat: 17.511, lng: -76.94 },
        { lat: 17.62, lng: -76.89 },
        { lat: 17.631, lng: -76.74 },
        { lat: 17.671, lng: -76.68 },
        { lat: 17.677, lng: -76.047 },
        { lat: 17.73, lng: -76.001 },
        { lat: 18.143, lng: -76.007 },
        { lat: 18.205, lng: -76.072 },
        { lat: 18.263, lng: -76.087 },
        { lat: 18.383, lng: -76.207 },
        { lat: 18.39, lng: -76.325 },
        { lat: 18.429, lng: -76.38 },
        { lat: 18.433, lng: -76.456 },
        { lat: 18.469, lng: -76.5 },
        { lat: 18.473, lng: -76.576 },
        { lat: 18.589, lng: -76.7 },
        { lat: 18.59, lng: -76.805 },
        { lat: 18.629, lng: -76.86 },
        { lat: 18.63, lng: -77.045 },
        { lat: 18.669, lng: -77.1 },
        { lat: 18.669, lng: -77.44 },
        { lat: 18.709, lng: -77.5 },
        { lat: 18.709, lng: -78.1 },
        { lat: 18.63, lng: -78.235 },
        { lat: 18.629, lng: -78.42 },
        { lat: 18.595, lng: -78.461 },
        { lat: 18.583, lng: -78.513 },
        { lat: 18.543, lng: -78.553 },
        { lat: 18.05, lng: -78.559 },
        { lat: 17.997, lng: -78.513 },
        { lat: 17.98, lng: -78.27 },
        { lat: 17.837, lng: -78.153 },
        { lat: 17.811, lng: -78.058 },
        { lat: 17.729, lng: -78.005 },
        { lat: 17.677, lng: -77.993 },
        { lat: 17.665, lng: -77.941 },
        { lat: 17.637, lng: -77.913 },
        { lat: 17.63, lng: -77.555 },
        { lat: 17.517, lng: -77.433 },
    ],
    [
        { lat: 18.197, lng: -75.233 },
        { lat: 18.197, lng: -74.807 },
        { lat: 18.623, lng: -74.807 },
        { lat: 18.623, lng: -75.233 },
        { lat: 18.197, lng: -75.233 },
    ],
    [
        { lat: 18.117, lng: -114.993 },
        { lat: 18.117, lng: -114.527 },
        { lat: 18.543, lng: -114.527 },
        { lat: 18.543, lng: -114.993 },
        { lat: 18.117, lng: -114.993 },
    ],
    [
        { lat: 11.797, lng: -61.993 },
        { lat: 11.791, lng: -61.5 },
        { lat: 11.877, lng: -61.407 },
        { lat: 12.195, lng: -61.393 },
        { lat: 12.232, lng: -61.338 },
        { lat: 12.237, lng: -61.247 },
        { lat: 12.361, lng: -61.239 },
        { lat: 12.397, lng: -61.207 },
        { lat: 12.458, lng: -61.19 },
        { lat: 12.517, lng: -61.127 },
        { lat: 12.635, lng: -61.113 },
        { lat: 12.664, lng: -61.081 },
        { lat: 12.677, lng: -61.007 },
        { lat: 12.761, lng: -60.999 },
        { lat: 12.81, lng: -60.961 },
        { lat: 12.955, lng: -60.96 },
        { lat: 13.01, lng: -60.921 },
        { lat: 13.472, lng: -60.914 },
        { lat: 13.512, lng: -60.859 },
        { lat: 13.517, lng: -60.767 },
        { lat: 13.61, lng: -60.681 },
        { lat: 14.115, lng: -60.684 },
        { lat: 14.175, lng: -60.672 },
        { lat: 14.25, lng: -60.601 },
        { lat: 14.69, lng: -60.601 },
        { lat: 14.821, lng: -60.679 },
        { lat: 14.983, lng: -60.687 },
        { lat: 14.993, lng: -60.775 },
        { lat: 15.069, lng: -60.9 },
        { lat: 15.077, lng: -61.004 },
        { lat: 15.147, lng: -61.044 },
        { lat: 15.593, lng: -61.044 },
        { lat: 15.646, lng: -61.037 },
        { lat: 15.69, lng: -61.001 },
        { lat: 15.995, lng: -61.0 },
        { lat: 16.098, lng: -60.933 },
        { lat: 16.111, lng: -60.82 },
        { lat: 16.13, lng: -60.801 },
        { lat: 16.53, lng: -60.801 },
        { lat: 16.549, lng: -60.82 },
        { lat: 16.548, lng: -61.138 },
        { lat: 16.577, lng: -61.188 },
        { lat: 16.663, lng: -61.207 },
        { lat: 16.703, lng: -61.247 },
        { lat: 16.707, lng: -61.483 },
        { lat: 16.735, lng: -61.527 },
        { lat: 16.787, lng: -61.531 },
        { lat: 16.85, lng: -61.481 },
        { lat: 17.29, lng: -61.481 },
        { lat: 17.334, lng: -61.517 },
        { lat: 17.385, lng: -61.524 },
        { lat: 17.81, lng: -61.521 },
        { lat: 17.903, lng: -61.607 },
        { lat: 17.909, lng: -62.02 },
        { lat: 17.863, lng: -62.073 },
        { lat: 17.41, lng: -62.079 },
        { lat: 17.349, lng: -62.052 },
        { lat: 17.29, lng: -62.079 },
        { lat: 17.094, lng: -62.078 },
        { lat: 17.049, lng: -62.098 },
        { lat: 17.028, lng: -62.141 },
        { lat: 17.035, lng: -62.279 },
        { lat: 17.067, lng: -62.314 },
        { lat: 17.17, lng: -62.321 },
        { lat: 17.225, lng: -62.36 },
        { lat: 17.383, lng: -62.367 },
        { lat: 17.482, lng: -62.47 },
        { lat: 17.543, lng: -62.487 },
        { lat: 17.558, lng: -62.545 },
        { lat: 17.625, lng: -62.613 },
        { lat: 18.09, lng: -62.601 },
        { lat: 18.109, lng: -62.62 },
        { lat: 18.115, lng: -62.719 },
        { lat: 18.146, lng: -62.753 },
        { lat: 18.463, lng: -62.767 },
        { lat: 18.466, lng: -63.324 },
        { lat: 18.473, lng: -63.376 },
        { lat: 18.509, lng: -63.42 },
        { lat: 18.49, lng: -63.439 },
        { lat: 18.305, lng: -63.44 },
        { lat: 18.25, lng: -63.479 },
        { lat: 18.09, lng: -63.479 },
        { lat: 18.043, lng: -63.372 },
        { lat: 17.887, lng: -63.345 },
        { lat: 17.837, lng: -63.37 },
        { lat: 17.81, lng: -63.439 },
        { lat: 17.437, lng: -63.433 },
        { lat: 17.434, lng: -63.274 },
        { lat: 17.416, lng: -63.224 },
        { lat: 17.364, lng: -63.197 },
        { lat: 17.277, lng: -63.193 },
        { lat: 17.257, lng: -63.088 },
        { lat: 17.205, lng: -63.043 },
        { lat: 17.117, lng: -63.033 },
        { lat: 17.059, lng: -62.891 },
        { lat: 16.917, lng: -62.793 },
        { lat: 16.914, lng: -62.515 },
        { lat: 16.892, lng: -62.459 },
        { lat: 16.834, lng: -62.436 },
        { lat: 16.477, lng: -62.433 },
        { lat: 16.474, lng: -62.075 },
        { lat: 16.443, lng: -62.012 },
        { lat: 16.392, lng: -61.996 },
        { lat: 16.01, lng: -61.999 },
        { lat: 15.955, lng: -61.96 },
        { lat: 15.85, lng: -61.959 },
        { lat: 15.809, lng: -61.925 },
        { lat: 15.757, lng: -61.913 },
        { lat: 15.72, lng: -61.85 },
        { lat: 15.637, lng: -61.833 },
        { lat: 15.632, lng: -61.742 },
        { lat: 15.594, lng: -61.687 },
        { lat: 15.29, lng: -61.679 },
        { lat: 15.161, lng: -61.601 },
        { lat: 15.05, lng: -61.599 },
        { lat: 14.997, lng: -61.553 },
        { lat: 14.984, lng: -61.479 },
        { lat: 14.954, lng: -61.447 },
        { lat: 14.61, lng: -61.439 },
        { lat: 14.329, lng: -61.285 },
        { lat: 14.277, lng: -61.273 },
        { lat: 14.229, lng: -61.226 },
        { lat: 14.135, lng: -61.243 },
        { lat: 14.09, lng: -61.279 },
        { lat: 13.631, lng: -61.285 },
        { lat: 13.597, lng: -61.316 },
        { lat: 13.583, lng: -61.393 },
        { lat: 13.543, lng: -61.433 },
        { lat: 13.491, lng: -61.445 },
        { lat: 13.45, lng: -61.479 },
        { lat: 12.954, lng: -61.484 },
        { lat: 12.922, lng: -61.507 },
        { lat: 12.903, lng: -61.553 },
        { lat: 12.812, lng: -61.576 },
        { lat: 12.743, lng: -61.673 },
        { lat: 12.505, lng: -61.676 },
        { lat: 12.454, lng: -61.694 },
        { lat: 12.427, lng: -61.749 },
        { lat: 12.423, lng: -61.873 },
        { lat: 12.343, lng: -61.953 },
        { lat: 12.03, lng: -61.959 },
        { lat: 11.97, lng: -61.999 },
        { lat: 11.797, lng: -61.993 },
    ],
    [
        { lat: 16.546, lng: -61.838 },
        { lat: 16.585, lng: -61.913 },
        { lat: 16.65, lng: -61.921 },
        { lat: 16.705, lng: -61.96 },
        { lat: 16.76, lng: -61.95 },
        { lat: 16.794, lng: -61.887 },
        { lat: 16.786, lng: -61.695 },
        { lat: 16.725, lng: -61.666 },
        { lat: 16.663, lng: -61.713 },
        { lat: 16.58, lng: -61.73 },
        { lat: 16.554, lng: -61.764 },
        { lat: 16.546, lng: -61.838 },
    ],
    [
        { lat: 17.197, lng: -84.113 },
        { lat: 17.197, lng: -83.727 },
        { lat: 17.623, lng: -83.727 },
        { lat: 17.623, lng: -84.113 },
        { lat: 17.197, lng: -84.113 },
    ],
    [
        { lat: 16.277, lng: -24.513 },
        { lat: 16.271, lng: -24.14 },
        { lat: 16.332, lng: -24.101 },
        { lat: 16.352, lng: -24.058 },
        { lat: 16.357, lng: -23.847 },
        { lat: 16.783, lng: -23.847 },
        { lat: 16.829, lng: -23.9 },
        { lat: 16.83, lng: -24.045 },
        { lat: 16.869, lng: -24.1 },
        { lat: 16.866, lng: -24.601 },
        { lat: 16.88, lng: -24.65 },
        { lat: 16.937, lng: -24.683 },
        { lat: 17.063, lng: -24.687 },
        { lat: 17.141, lng: -24.759 },
        { lat: 17.29, lng: -24.761 },
        { lat: 17.389, lng: -24.86 },
        { lat: 17.389, lng: -25.34 },
        { lat: 17.315, lng: -25.461 },
        { lat: 17.303, lng: -25.513 },
        { lat: 17.263, lng: -25.553 },
        { lat: 16.85, lng: -25.559 },
        { lat: 16.801, lng: -25.521 },
        { lat: 16.717, lng: -25.513 },
        { lat: 16.703, lng: -25.315 },
        { lat: 16.675, lng: -25.287 },
        { lat: 16.61, lng: -25.279 },
        { lat: 16.591, lng: -25.26 },
        { lat: 16.584, lng: -24.678 },
        { lat: 16.529, lng: -24.638 },
        { lat: 16.437, lng: -24.633 },
        { lat: 16.378, lng: -24.57 },
        { lat: 16.317, lng: -24.553 },
        { lat: 16.277, lng: -24.513 },
    ],
    [
        { lat: 15.797, lng: -23.113 },
        { lat: 15.791, lng: -22.58 },
        { lat: 15.85, lng: -22.481 },
        { lat: 16.37, lng: -22.481 },
        { lat: 16.423, lng: -22.527 },
        { lat: 16.434, lng: -22.636 },
        { lat: 16.47, lng: -22.675 },
        { lat: 16.97, lng: -22.681 },
        { lat: 17.069, lng: -22.74 },
        { lat: 17.069, lng: -23.1 },
        { lat: 17.01, lng: -23.199 },
        { lat: 16.53, lng: -23.199 },
        { lat: 16.455, lng: -23.128 },
        { lat: 16.392, lng: -23.116 },
        { lat: 16.334, lng: -23.123 },
        { lat: 16.29, lng: -23.159 },
        { lat: 15.85, lng: -23.159 },
        { lat: 15.797, lng: -23.113 },
    ],
    [
        { lat: 16.517, lng: -169.753 },
        { lat: 16.517, lng: -169.327 },
        { lat: 16.943, lng: -169.327 },
        { lat: 16.943, lng: -169.753 },
        { lat: 16.517, lng: -169.753 },
    ],
    [
        { lat: 14.597, lng: -24.913 },
        { lat: 14.591, lng: -24.5 },
        { lat: 14.631, lng: -24.44 },
        { lat: 14.631, lng: -24.14 },
        { lat: 14.69, lng: -24.081 },
        { lat: 14.846, lng: -24.081 },
        { lat: 14.885, lng: -24.028 },
        { lat: 14.861, lng: -23.968 },
        { lat: 14.811, lng: -23.926 },
        { lat: 14.757, lng: -23.913 },
        { lat: 14.711, lng: -23.86 },
        { lat: 14.717, lng: -23.287 },
        { lat: 14.898, lng: -23.213 },
        { lat: 14.914, lng: -23.164 },
        { lat: 14.911, lng: -22.98 },
        { lat: 14.957, lng: -22.927 },
        { lat: 15.503, lng: -22.927 },
        { lat: 15.509, lng: -23.32 },
        { lat: 15.549, lng: -23.38 },
        { lat: 15.482, lng: -23.481 },
        { lat: 15.509, lng: -23.54 },
        { lat: 15.509, lng: -23.88 },
        { lat: 15.543, lng: -23.953 },
        { lat: 15.491, lng: -23.965 },
        { lat: 15.45, lng: -23.999 },
        { lat: 15.187, lng: -23.997 },
        { lat: 15.14, lng: -24.031 },
        { lat: 15.151, lng: -24.099 },
        { lat: 15.229, lng: -24.14 },
        { lat: 15.229, lng: -24.62 },
        { lat: 15.195, lng: -24.661 },
        { lat: 15.183, lng: -24.713 },
        { lat: 15.116, lng: -24.757 },
        { lat: 15.103, lng: -24.913 },
        { lat: 15.063, lng: -24.953 },
        { lat: 15.01, lng: -24.959 },
        { lat: 14.65, lng: -24.959 },
        { lat: 14.597, lng: -24.913 },
    ],
    [
        { lat: 13.117, lng: -81.593 },
        { lat: 13.117, lng: -81.167 },
        { lat: 13.543, lng: -81.167 },
        { lat: 13.549, lng: -81.48 },
        { lat: 13.583, lng: -81.553 },
        { lat: 13.349, lng: -81.559 },
        { lat: 13.29, lng: -81.599 },
        { lat: 13.117, lng: -81.593 },
    ],
    [
        { lat: 12.837, lng: -59.633 },
        { lat: 12.831, lng: -59.34 },
        { lat: 12.93, lng: -59.241 },
        { lat: 13.37, lng: -59.241 },
        { lat: 13.436, lng: -59.344 },
        { lat: 13.503, lng: -59.367 },
        { lat: 13.549, lng: -59.42 },
        { lat: 13.549, lng: -59.82 },
        { lat: 13.53, lng: -59.839 },
        { lat: 12.89, lng: -59.839 },
        { lat: 12.871, lng: -59.82 },
        { lat: 12.87, lng: -59.675 },
        { lat: 12.837, lng: -59.633 },
    ],
    [
        { lat: 12.317, lng: -81.913 },
        { lat: 12.317, lng: -81.487 },
        { lat: 12.783, lng: -81.487 },
        { lat: 12.783, lng: -81.913 },
        { lat: 12.317, lng: -81.913 },
    ],
    [
        { lat: 11.797, lng: -67.873 },
        { lat: 11.797, lng: -67.447 },
        { lat: 12.183, lng: -67.447 },
        { lat: 12.183, lng: -67.873 },
        { lat: 11.797, lng: -67.873 },
    ],
    [
        { lat: 11.637, lng: -64.833 },
        { lat: 11.637, lng: -64.367 },
        { lat: 12.103, lng: -64.367 },
        { lat: 12.103, lng: -64.793 },
        { lat: 12.05, lng: -64.839 },
        { lat: 11.637, lng: -64.833 },
    ],
    [
        { lat: 11.557, lng: -66.993 },
        { lat: 11.551, lng: -66.42 },
        { lat: 11.591, lng: -66.36 },
        { lat: 11.597, lng: -65.927 },
        { lat: 11.755, lng: -65.92 },
        { lat: 11.81, lng: -65.881 },
        { lat: 12.023, lng: -65.887 },
        { lat: 12.026, lng: -66.482 },
        { lat: 12.033, lng: -66.536 },
        { lat: 12.069, lng: -66.58 },
        { lat: 12.063, lng: -66.953 },
        { lat: 11.979, lng: -66.961 },
        { lat: 11.93, lng: -66.999 },
        { lat: 11.557, lng: -66.993 },
    ],
    [
        { lat: 10.677, lng: -65.433 },
        { lat: 10.677, lng: -65.007 },
        { lat: 11.143, lng: -65.007 },
        { lat: 11.151, lng: -65.089 },
        { lat: 11.183, lng: -65.127 },
        { lat: 11.189, lng: -65.18 },
        { lat: 11.189, lng: -65.54 },
        { lat: 11.143, lng: -65.593 },
        { lat: 10.73, lng: -65.599 },
        { lat: 10.711, lng: -65.58 },
        { lat: 10.709, lng: -65.469 },
        { lat: 10.677, lng: -65.433 },
    ],
    [
        { lat: 10.077, lng: -109.433 },
        { lat: 10.077, lng: -109.007 },
        { lat: 10.503, lng: -109.007 },
        { lat: 10.503, lng: -109.433 },
        { lat: 10.077, lng: -109.433 },
    ],
    [
        { lat: 5.677, lng: -162.273 },
        { lat: 5.677, lng: -161.847 },
        { lat: 6.063, lng: -161.847 },
        { lat: 6.063, lng: -162.273 },
        { lat: 5.677, lng: -162.273 },
    ],
    [
        { lat: 5.317, lng: -87.313 },
        { lat: 5.317, lng: -86.887 },
        { lat: 5.515, lng: -86.88 },
        { lat: 5.57, lng: -86.841 },
        { lat: 5.743, lng: -86.847 },
        { lat: 5.743, lng: -87.313 },
        { lat: 5.317, lng: -87.313 },
    ],
    [
        { lat: 4.517, lng: -160.593 },
        { lat: 4.517, lng: -160.207 },
        { lat: 4.715, lng: -160.2 },
        { lat: 4.77, lng: -160.161 },
        { lat: 4.903, lng: -160.167 },
        { lat: 4.903, lng: -160.593 },
        { lat: 4.517, lng: -160.593 },
    ],
    [
        { lat: 3.597, lng: -159.553 },
        { lat: 3.597, lng: -159.047 },
        { lat: 4.023, lng: -159.047 },
        { lat: 4.109, lng: -159.14 },
        { lat: 4.106, lng: -159.445 },
        { lat: 4.143, lng: -159.553 },
        { lat: 4.103, lng: -159.593 },
        { lat: 4.05, lng: -159.599 },
        { lat: 3.65, lng: -159.599 },
        { lat: 3.597, lng: -159.553 },
    ],
    [
        { lat: 1.517, lng: -157.473 },
        { lat: 1.517, lng: -156.967 },
        { lat: 1.93, lng: -156.961 },
        { lat: 1.983, lng: -157.007 },
        { lat: 1.998, lng: -157.065 },
        { lat: 2.045, lng: -157.112 },
        { lat: 2.183, lng: -157.127 },
        { lat: 2.191, lng: -157.211 },
        { lat: 2.229, lng: -157.26 },
        { lat: 2.223, lng: -157.713 },
        { lat: 2.139, lng: -157.721 },
        { lat: 2.09, lng: -157.759 },
        { lat: 1.905, lng: -157.76 },
        { lat: 1.85, lng: -157.799 },
        { lat: 1.69, lng: -157.799 },
        { lat: 1.66, lng: -157.732 },
        { lat: 1.597, lng: -157.673 },
        { lat: 1.585, lng: -157.621 },
        { lat: 1.551, lng: -157.58 },
        { lat: 1.547, lng: -157.505 },
        { lat: 1.517, lng: -157.473 },
    ],
    [
        { lat: 1.151, lng: -91.94 },
        { lat: 1.157, lng: -91.607 },
        { lat: 1.57, lng: -91.601 },
        { lat: 1.589, lng: -91.62 },
        { lat: 1.588, lng: -91.736 },
        { lat: 1.617, lng: -91.788 },
        { lat: 1.665, lng: -91.804 },
        { lat: 1.863, lng: -91.807 },
        { lat: 1.869, lng: -92.18 },
        { lat: 1.85, lng: -92.199 },
        { lat: 1.45, lng: -92.199 },
        { lat: 1.431, lng: -92.18 },
        { lat: 1.434, lng: -92.071 },
        { lat: 1.408, lng: -92.016 },
        { lat: 1.355, lng: -91.996 },
        { lat: 1.17, lng: -91.999 },
        { lat: 1.151, lng: -91.98 },
        { lat: 1.151, lng: -91.94 },
    ],
    [
        { lat: 0.631, lng: -29.54 },
        { lat: 0.637, lng: -29.167 },
        { lat: 1.063, lng: -29.167 },
        { lat: 1.063, lng: -29.593 },
        { lat: 0.65, lng: -29.599 },
        { lat: 0.631, lng: -29.58 },
        { lat: 0.631, lng: -29.54 },
    ],
    [
        { lat: -1.569, lng: -90.5 },
        { lat: -1.569, lng: -90.26 },
        { lat: -1.483, lng: -90.167 },
        { lat: -1.014, lng: -90.174 },
        { lat: -0.974, lng: -90.145 },
        { lat: -0.963, lng: -90.087 },
        { lat: -0.87, lng: -90.001 },
        { lat: -0.605, lng: -90.004 },
        { lat: -0.554, lng: -89.997 },
        { lat: -0.51, lng: -89.961 },
        { lat: -0.39, lng: -89.961 },
        { lat: -0.291, lng: -90.06 },
        { lat: -0.293, lng: -90.294 },
        { lat: -0.272, lng: -90.341 },
        { lat: -0.227, lng: -90.362 },
        { lat: -0.07, lng: -90.361 },
        { lat: -0.026, lng: -90.403 },
        { lat: 0.026, lng: -90.414 },
        { lat: 0.071, lng: -90.376 },
        { lat: 0.071, lng: -90.26 },
        { lat: 0.117, lng: -90.207 },
        { lat: 0.503, lng: -90.207 },
        { lat: 0.549, lng: -90.26 },
        { lat: 0.546, lng: -90.445 },
        { lat: 0.566, lng: -90.498 },
        { lat: 0.663, lng: -90.527 },
        { lat: 0.701, lng: -90.559 },
        { lat: 0.823, lng: -90.567 },
        { lat: 0.829, lng: -90.92 },
        { lat: 0.869, lng: -90.98 },
        { lat: 0.85, lng: -90.999 },
        { lat: 0.37, lng: -90.999 },
        { lat: 0.351, lng: -90.98 },
        { lat: 0.354, lng: -90.833 },
        { lat: 0.328, lng: -90.776 },
        { lat: 0.25, lng: -90.759 },
        { lat: 0.191, lng: -90.719 },
        { lat: 0.057, lng: -90.732 },
        { lat: 0.026, lng: -90.795 },
        { lat: 0.04, lng: -90.948 },
        { lat: 0.095, lng: -90.997 },
        { lat: 0.183, lng: -91.007 },
        { lat: 0.214, lng: -91.066 },
        { lat: 0.349, lng: -91.14 },
        { lat: 0.349, lng: -91.58 },
        { lat: 0.315, lng: -91.621 },
        { lat: 0.303, lng: -91.673 },
        { lat: 0.183, lng: -91.793 },
        { lat: -0.028, lng: -91.798 },
        { lat: -0.071, lng: -91.818 },
        { lat: -0.11, lng: -91.879 },
        { lat: -0.55, lng: -91.879 },
        { lat: -0.591, lng: -91.845 },
        { lat: -0.643, lng: -91.833 },
        { lat: -0.658, lng: -91.775 },
        { lat: -0.715, lng: -91.723 },
        { lat: -0.87, lng: -91.719 },
        { lat: -0.93, lng: -91.679 },
        { lat: -1.11, lng: -91.679 },
        { lat: -1.151, lng: -91.645 },
        { lat: -1.203, lng: -91.633 },
        { lat: -1.21, lng: -91.435 },
        { lat: -1.249, lng: -91.38 },
        { lat: -1.249, lng: -90.98 },
        { lat: -1.211, lng: -90.931 },
        { lat: -1.209, lng: -90.86 },
        { lat: -1.17, lng: -90.805 },
        { lat: -1.175, lng: -90.761 },
        { lat: -1.241, lng: -90.716 },
        { lat: -1.523, lng: -90.713 },
        { lat: -1.53, lng: -90.595 },
        { lat: -1.569, lng: -90.54 },
        { lat: -1.569, lng: -90.5 },
    ],
    [
        { lat: -0.603, lng: -160.153 },
        { lat: -0.603, lng: -159.807 },
        { lat: -0.177, lng: -159.807 },
        { lat: -0.177, lng: -160.233 },
        { lat: -0.55, lng: -160.239 },
        { lat: -0.603, lng: -160.153 },
    ],
    [
        { lat: -1.609, lng: -89.86 },
        { lat: -1.603, lng: -89.407 },
        { lat: -1.202, lng: -89.391 },
        { lat: -1.133, lng: -89.296 },
        { lat: -1.123, lng: -89.207 },
        { lat: -1.061, lng: -89.189 },
        { lat: -1.016, lng: -89.141 },
        { lat: -1.003, lng: -89.087 },
        { lat: -0.951, lng: -89.075 },
        { lat: -0.91, lng: -89.041 },
        { lat: -0.497, lng: -89.047 },
        { lat: -0.491, lng: -89.58 },
        { lat: -0.6, lng: -89.692 },
        { lat: -0.617, lng: -89.753 },
        { lat: -0.676, lng: -89.784 },
        { lat: -0.697, lng: -89.833 },
        { lat: -1.067, lng: -89.838 },
        { lat: -1.118, lng: -89.867 },
        { lat: -1.15, lng: -89.959 },
        { lat: -1.55, lng: -89.959 },
        { lat: -1.603, lng: -89.913 },
        { lat: -1.609, lng: -89.86 },
    ],
    [
        { lat: -4.083, lng: -32.673 },
        { lat: -4.083, lng: -32.207 },
        { lat: -3.657, lng: -32.207 },
        { lat: -3.657, lng: -32.633 },
        { lat: -3.855, lng: -32.64 },
        { lat: -3.91, lng: -32.679 },
        { lat: -4.083, lng: -32.673 },
    ],
    [
        { lat: -4.283, lng: -155.193 },
        { lat: -4.283, lng: -154.767 },
        { lat: -4.049, lng: -154.761 },
        { lat: -3.99, lng: -154.721 },
        { lat: -3.857, lng: -154.727 },
        { lat: -3.857, lng: -155.193 },
        { lat: -4.283, lng: -155.193 },
    ],
    [
        { lat: -5.643, lng: -155.913 },
        { lat: -5.643, lng: -155.887 },
        { lat: -5.617, lng: -155.887 },
        { lat: -5.617, lng: -155.913 },
        { lat: -5.643, lng: -155.913 },
    ],
    [
        { lat: -8.243, lng: -140.913 },
        { lat: -8.243, lng: -140.487 },
        { lat: -8.191, lng: -140.475 },
        { lat: -8.15, lng: -140.441 },
        { lat: -7.777, lng: -140.447 },
        { lat: -7.777, lng: -140.913 },
        { lat: -8.243, lng: -140.913 },
    ],
    [
        { lat: -9.643, lng: -140.313 },
        { lat: -9.643, lng: -139.887 },
        { lat: -9.59, lng: -139.841 },
        { lat: -9.205, lng: -139.844 },
        { lat: -9.137, lng: -139.805 },
        { lat: -9.123, lng: -139.287 },
        { lat: -8.95, lng: -139.281 },
        { lat: -8.895, lng: -139.32 },
        { lat: -8.697, lng: -139.327 },
        { lat: -8.651, lng: -139.38 },
        { lat: -8.65, lng: -139.805 },
        { lat: -8.611, lng: -139.86 },
        { lat: -8.607, lng: -139.936 },
        { lat: -8.571, lng: -139.98 },
        { lat: -8.577, lng: -140.433 },
        { lat: -8.775, lng: -140.44 },
        { lat: -8.83, lng: -140.479 },
        { lat: -8.99, lng: -140.479 },
        { lat: -9.039, lng: -140.441 },
        { lat: -9.123, lng: -140.433 },
        { lat: -9.205, lng: -140.36 },
        { lat: -9.59, lng: -140.359 },
        { lat: -9.643, lng: -140.313 },
    ],
    [
        { lat: -9.163, lng: -158.193 },
        { lat: -9.163, lng: -157.767 },
        { lat: -8.777, lng: -157.767 },
        { lat: -8.777, lng: -158.193 },
        { lat: -9.163, lng: -158.193 },
    ],
    [
        { lat: -10.763, lng: -138.793 },
        { lat: -10.769, lng: -138.46 },
        { lat: -10.71, lng: -138.401 },
        { lat: -10.51, lng: -138.401 },
        { lat: -10.455, lng: -138.44 },
        { lat: -10.257, lng: -138.447 },
        { lat: -10.25, lng: -138.805 },
        { lat: -10.183, lng: -138.866 },
        { lat: -10.029, lng: -138.822 },
        { lat: -10.007, lng: -138.771 },
        { lat: -10.009, lng: -138.7 },
        { lat: -9.963, lng: -138.647 },
        { lat: -9.805, lng: -138.64 },
        { lat: -9.75, lng: -138.601 },
        { lat: -9.55, lng: -138.601 },
        { lat: -9.531, lng: -138.62 },
        { lat: -9.529, lng: -138.769 },
        { lat: -9.491, lng: -138.82 },
        { lat: -9.491, lng: -139.22 },
        { lat: -9.525, lng: -139.261 },
        { lat: -9.537, lng: -139.313 },
        { lat: -9.59, lng: -139.359 },
        { lat: -10.11, lng: -139.359 },
        { lat: -10.209, lng: -139.3 },
        { lat: -10.206, lng: -138.956 },
        { lat: -10.232, lng: -138.896 },
        { lat: -10.288, lng: -138.876 },
        { lat: -10.71, lng: -138.879 },
        { lat: -10.763, lng: -138.793 },
    ],
    [
        { lat: -10.243, lng: -161.273 },
        { lat: -10.243, lng: -160.887 },
        { lat: -9.857, lng: -160.887 },
        { lat: -9.857, lng: -161.273 },
        { lat: -10.243, lng: -161.273 },
    ],
    [
        { lat: -10.403, lng: -160.993 },
        { lat: -10.403, lng: -160.967 },
        { lat: -10.377, lng: -160.967 },
        { lat: -10.377, lng: -160.993 },
        { lat: -10.403, lng: -160.993 },
    ],
    [
        { lat: -11.083, lng: -165.993 },
        { lat: -11.083, lng: -165.607 },
        { lat: -10.697, lng: -165.607 },
        { lat: -10.697, lng: -165.993 },
        { lat: -11.083, lng: -165.993 },
    ],
    [
        { lat: -11.643, lng: -151.993 },
        { lat: -11.643, lng: -151.607 },
        { lat: -11.217, lng: -151.607 },
        { lat: -11.211, lng: -151.92 },
        { lat: -11.177, lng: -151.993 },
        { lat: -11.371, lng: -151.999 },
        { lat: -11.43, lng: -152.039 },
        { lat: -11.47, lng: -152.039 },
        { lat: -11.59, lng: -152.039 },
        { lat: -11.643, lng: -151.993 },
    ],
    [
        { lat: -14.403, lng: -141.393 },
        { lat: -14.403, lng: -140.967 },
        { lat: -13.977, lng: -140.967 },
        { lat: -13.977, lng: -141.393 },
        { lat: -14.403, lng: -141.393 },
    ],
    [
        { lat: -14.483, lng: -169.633 },
        { lat: -14.489, lng: -169.3 },
        { lat: -14.443, lng: -169.247 },
        { lat: -14.017, lng: -169.247 },
        { lat: -14.01, lng: -169.405 },
        { lat: -13.971, lng: -169.46 },
        { lat: -13.977, lng: -169.873 },
        { lat: -14.363, lng: -169.873 },
        { lat: -14.403, lng: -169.833 },
        { lat: -14.413, lng: -169.745 },
        { lat: -14.483, lng: -169.633 },
    ],
    [
        { lat: -14.683, lng: -146.433 },
        { lat: -14.689, lng: -146.06 },
        { lat: -14.597, lng: -146.028 },
        { lat: -14.568, lng: -145.979 },
        { lat: -14.563, lng: -145.647 },
        { lat: -14.137, lng: -145.647 },
        { lat: -14.131, lng: -146.06 },
        { lat: -14.199, lng: -146.091 },
        { lat: -14.249, lng: -146.151 },
        { lat: -14.257, lng: -146.473 },
        { lat: -14.39, lng: -146.479 },
        { lat: -14.449, lng: -146.439 },
        { lat: -14.683, lng: -146.433 },
    ],
    [
        { lat: -14.883, lng: -145.473 },
        { lat: -14.889, lng: -145.02 },
        { lat: -14.87, lng: -145.001 },
        { lat: -14.796, lng: -145.003 },
        { lat: -14.742, lng: -144.973 },
        { lat: -14.729, lng: -144.82 },
        { lat: -14.658, lng: -144.745 },
        { lat: -14.643, lng: -144.687 },
        { lat: -14.217, lng: -144.687 },
        { lat: -14.217, lng: -145.153 },
        { lat: -14.27, lng: -145.199 },
        { lat: -14.386, lng: -145.203 },
        { lat: -14.437, lng: -145.248 },
        { lat: -14.457, lng: -145.433 },
        { lat: -14.655, lng: -145.44 },
        { lat: -14.71, lng: -145.479 },
        { lat: -14.883, lng: -145.473 },
    ],
    [
        { lat: -14.723, lng: -168.353 },
        { lat: -14.723, lng: -167.967 },
        { lat: -14.337, lng: -167.967 },
        { lat: -14.337, lng: -168.353 },
        { lat: -14.723, lng: -168.353 },
    ],
    [
        { lat: -15.003, lng: -148.873 },
        { lat: -15.009, lng: -148.46 },
        { lat: -14.982, lng: -148.399 },
        { lat: -15.009, lng: -148.34 },
        { lat: -15.009, lng: -147.86 },
        { lat: -14.99, lng: -147.841 },
        { lat: -14.79, lng: -147.841 },
        { lat: -14.731, lng: -147.9 },
        { lat: -14.744, lng: -148.399 },
        { lat: -14.72, lng: -148.433 },
        { lat: -14.651, lng: -148.46 },
        { lat: -14.651, lng: -148.86 },
        { lat: -14.67, lng: -148.879 },
        { lat: -15.003, lng: -148.873 },
    ],
    [
        { lat: -15.003, lng: -147.713 },
        { lat: -15.009, lng: -147.3 },
        { lat: -14.99, lng: -147.281 },
        { lat: -14.91, lng: -147.281 },
        { lat: -14.891, lng: -147.3 },
        { lat: -14.891, lng: -147.7 },
        { lat: -14.91, lng: -147.719 },
        { lat: -15.003, lng: -147.713 },
    ],
];
