import * as React from 'react';
import { connect } from 'react-redux';
import { withRoute } from 'react-router5';

import * as teamLimitsAndUsage from 'reports/models/team_usage';
import * as auth from 'reports/modules/auth';
import ProjectLimitProgressBar from './ProjectLimitProgressBar';
import { IWithRouteProps } from 'reports/utils/router';

interface IOwnProps {
    collapsed: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps & IWithRouteProps;

const ProjectLimitProgressBarContainer = ({ user, collapsed, route, teamUsageAndLimits }: IProps) => {
    const isUserOnTrial = user?.status === 'trial';
    const shouldShowProgressBar = isUserOnTrial || user.subscription?.subscription_type !== 'contract';

    const showUpsellAtCritical = !!user?.team.should_upsell_consumption;
    const teamLimits = isUserOnTrial ? teamUsageAndLimits?.trial_limits : teamUsageAndLimits?.subscription_limits;

    if (!shouldShowProgressBar || !teamLimits || !teamLimits?.project_limit || collapsed) {
        return <></>;
    }

    const { project_count, project_limit } = teamLimits;

    return (
        <ProjectLimitProgressBar
            userOnTrial={isUserOnTrial}
            projectCount={project_count}
            projectLimit={project_limit}
            showUpsellAtCritical={showUpsellAtCritical}
            routeName={route?.name}
            routeParams={route?.params}
            isTeamAdmin={user.team_admin}
            planType={user.subscription?.plan_type}
        />
    );
};

const mapStateToProps = (state) => {
    const user = auth.selectors.getUser(state)!;
    const teamUsageAndLimits = user?.team_id ? teamLimitsAndUsage.selectors.byId(state, user?.team_id) : undefined;

    return { teamUsageAndLimits, user };
};

export default connect(mapStateToProps)(withRoute(ProjectLimitProgressBarContainer));
