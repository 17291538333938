import React from 'react';
import { generatePath, useNavigate } from 'react-router';
import { FinancialTemplateLibrary } from 'reports/modules/financials/components/financial_template/FinancialTemplateLibrary';

const FinancialModels = () => {
    const navigate = useNavigate();
    const navigateToFinTemplate = (finTemplateId) =>
        navigate({
            pathname: generatePath('/financial-models/:finTemplateId/edit', {
                finTemplateId: finTemplateId.toString(),
            }),
        });
    const navigateToFinTempPreview = (finTemplateId) =>
        navigate({
            pathname: generatePath('/financial-models/:finTemplateId/preview', {
                finTemplateId: finTemplateId.toString(),
            }),
        });

    return (
        <FinancialTemplateLibrary
            hideUpsellBanner={true}
            inlineControls={true}
            navigateToFinTemplate={navigateToFinTemplate}
            navigateToFinTempPreview={navigateToFinTempPreview}
        />
    );
};

export { FinancialModels };
