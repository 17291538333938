import * as React from 'react';

import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useSettingsContext } from 'frontend/settings/use-settings-data';

import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';

import { SettingsPane as BetaSettingsPane } from 'reports/modules/settings/common';
import { AccountStatusBox as BetaAccountStatusBox } from 'reports/modules/settings/billing/components/AccountStatusBox';
import { TeamManagement as BetaTeamManagement } from 'reports/modules/settings/team/components/TeamManagement';

const TeamManagement = () => {
    const { subscription, team, user } = useSettingsContext();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const purchaseMessage = 'You need to purchase a license before you can invite new team members to a seat.';
    const navigateToLicensesDialog = () => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('dialog', 'licenses');
        newSearchParams.set('referrer', ReferrerTypes.manage_team_page);
        newSearchParams.set('message', purchaseMessage);
        navigate({ pathname: '/settings/team/billing', search: `?${newSearchParams.toString()}` });
    };
    return (
        <BetaSettingsPane
            content={
                <BetaTeamManagement
                    navigateToBilling={navigateToLicensesDialog}
                    team={team}
                    subscription={subscription}
                    user={user}
                />
            }
            card={<BetaAccountStatusBox subscription={subscription} user={user} />}
        />
    );
};

export { TeamManagement };
