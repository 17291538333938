import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterStateLock } from 'reports/utils/router';
import { isSubstr } from 'reports/utils/strings';

import * as usr from 'reports/models/user';
import { selectors as authSelectors } from 'reports/modules/auth';

import { Button, Intent, Tag } from '@blueprintjs/core';

import { SaveCancelButtons } from 'reports/components/core/controls';
import { Form, handleRequestException } from 'reports/components/forms';

import { addPromiseToasts } from 'reports/modules/Toaster';

import { FixedWidthTextInput, StyledCheckbox } from 'reports/modules/settings/common';
import Section2 from 'reports/components/core/containers/Section2';
import ChangePasswordDialog from './ChangePasswordDialog';
import { useAdminData } from '../../hooks/useAdminData';

interface Props {
    user: usr.User;
    changePassword?: boolean;
}

const HSAdminForm = () => (
    <Section2 title="HelioScope Admin Access" contextEl={<Tag intent={Intent.DANGER}>HelioScope Super Admin only</Tag>}>
        <StyledCheckbox inline fill bold checkboxLabel="Grant HelioScope Admin Access" path="is_admin" />
    </Section2>
);

const UserOverviewForm = ({ user, changePassword = false }: Props) => {
    const dispatch = useDispatch();
    const updateUser = (form: usr.IUserForm) => dispatch(usr.api.save({ ...form, email: user.email }));
    const authUser = useSelector((state) => authSelectors.getUser(state)!);
    const { authUserIsHSSuperAdmin } = useAdminData({ isAdmin: authUser.is_admin });

    const userHasAuroraEmail = isSubstr(user.email, '@aurorasolar.com') || isSubstr(user.email, '@folsomlabs.com');
    const disableEmailField = !authUser.is_admin;
    const passwordButton = (
        <Button
            text="Change Password"
            onClick={() => {
                setPasswordDialogVisible(true);
            }}
            fill={false}
            intent={Intent.PRIMARY}
        />
    );

    const onSubmit = async (formData: usr.IUserForm) => {
        await addPromiseToasts(updateUser(formData), {
            initial: 'Saving user settings...',
            onSuccess: 'Successfully saved user settings.',
            onCatch: 'Error saving user settings.',
        });
    };

    const [passwordDialogVisible, setPasswordDialogVisible] = useState(!!changePassword);
    const onClosePasswordDialog = () => setPasswordDialogVisible(false);

    return (
        <>
            <ChangePasswordDialog user={user} open={passwordDialogVisible} onClose={onClosePasswordDialog} />
            <Form
                baseValue={{ ...user, updated_email: user.email }}
                onSubmit={onSubmit}
                exceptionHandler={handleRequestException}
            >
                {({ dirty, submitForm, clearForm }) => (
                    <>
                        <RouterStateLock
                            title="Save User Settings?"
                            prompt="You have unsaved changes on user settings."
                            cancellable={true}
                            showExitPrompt={dirty}
                            onSave={submitForm}
                            onDontSave={clearForm}
                        />
                        <Section2 title="Account Information" contextEl={passwordButton}>
                            <FixedWidthTextInput
                                inline
                                bold
                                path="updated_email"
                                label="Email"
                                disabled={disableEmailField}
                            />
                            <FixedWidthTextInput inline bold path="first_name" label="First Name" />
                            <FixedWidthTextInput inline bold path="last_name" label="Last Name" />
                            <FixedWidthTextInput inline bold path="company" label="Company" />
                        </Section2>
                        {authUserIsHSSuperAdmin && userHasAuroraEmail && <HSAdminForm />}
                        <SaveCancelButtons
                            onSave={submitForm}
                            hasChanges={dirty}
                            onCancel={clearForm}
                            style={{ marginBottom: 10 }}
                        />
                    </>
                )}
            </Form>
        </>
    );
};

export { UserOverviewForm };
