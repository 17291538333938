import { Schema, ReduxEndpoint } from 'reports/utils/api';
import { GeoPoint } from 'helioscope/app/utilities/geometry';
import { IAppState } from 'reports/types';

ReduxEndpoint.addUrlFormatter(GeoPoint, ({ latitude, longitude }) => `${latitude},${longitude}`);
ReduxEndpoint.addUrlFormatter(Array, (arr) => arr.join(';'));
ReduxEndpoint.addUrlFormatter(Date, (date) => date.toISOString());

export const schema = new Schema<IAppState>();

export function createReducer(storeKey: string) {
    return schema.getReducer(storeKey);
}
