import * as React from 'react';
import { Link } from 'reports/components/core/controls';

import { ReferrerType } from 'reports/analytics/ReferrerTypes';
import { DetailLine } from 'reports/modules/settings/common';

export const PurchaseLink = ({ referrer }: { referrer: ReferrerType }) => (
    <DetailLine>
        <Link routeName="app.settings.team.billing" searchParams={{ referrer, dialog: 'initial' }}>
            Purchase plan
        </Link>
    </DetailLine>
);
