import * as React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';

import { Classes, Icon, MenuItem, Colors as BpColors, FormGroup, Switch, Alignment } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ContextMenu2 } from '@blueprintjs/popover2';
import classNames from 'classnames';

import { Flex } from 'reports/components/core/containers';
import Translations from 'reports/localization/strings';
import { hasOutput, FIN_STATUSES } from 'reports/modules/financials/state';
import Colors from 'reports/styles/colors';
import { DeepPartial } from 'reports/types';

import { getWidget, IReportContext, IWidget, IWidgetConfig, RICH_TEXT_WIDGET_NAME } from '../widgets';
import { IWidgetStyle, WIDGET_BORDER_RADIUS, WIDGET_STYLES, WidgetStyle } from '../widget_style';

import { ContextMenu, ContextMenuHeader, WidgetMenu, MenuButton, FormHeader, FormSection } from './helpers';
import { ILayout, LayoutComponent, FullDiv } from './layout';
import SidebarFormatForm from './SidebarFormatForm';
import WidgetContent from './WidgetContent';
import { IWidgetErrorDesc, WidgetErrorState } from './WidgetErrorBoundary';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const HEADER_HEIGHT = 38;

const WidgetHeaderContainer = styled(Flex.Container).attrs({
    className: 'center',
})`
    height: ${HEADER_HEIGHT}px;
    padding: 0 10px;
    background-color: ${Colors.BG_LIGHT_GRAY};
    font-size: 16px;

    border-bottom: 1px solid ${Colors.BORDER_TABLE};
    border-top-left-radius: ${WIDGET_BORDER_RADIUS}px;
    border-top-right-radius: ${WIDGET_BORDER_RADIUS}px;

    .${Classes.ICON} {
        padding-right: 6px;
    }
`;

const WidgetHeader = ({ title, icon }) => (
    <WidgetHeaderContainer>
        <Icon icon={icon} />
        <FormattedMessage {...title} />
    </WidgetHeaderContainer>
);

const StyledFullDiv = styled(FullDiv)<{ hover: boolean; selected: boolean; disabled: boolean }>`
    cursor: pointer;
    pointer-events: none;
    overflow: hidden;
    transition: transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9),
        -webkit-transform 0.2s cubic-bezier(0.4, 1, 0.75, 0.9), -webkit-box-shadow 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);

    ${({ hover }) =>
        hover &&
        styles.css`
            box-shadow:
                0 0 0 1px rgba(51, 80, 110, .1),
                0 2px 4px rgba(51, 80, 110, .2),
                0 8px 24px rgba(51, 80, 110, .2);`}

    ${({ selected }) =>
        selected &&
        styles.css`
            pointer-events: auto;
            overflow: visible;
            border: solid 1px ${BpColors.GRAY3};`}

    ${(props) =>
        props.disabled &&
        styles.css`
            pointer-events: auto;
            cursor: not-allowed;
            opacity: 0.4;

            && textarea {
                cursor: not-allowed;
            }`}
`;

interface IState {
    pendingConfig: IWidgetConfig;
    hover: boolean;
}

interface IOwnProps {
    widgetId: string;
    config: IWidgetConfig;

    documentMode: 'view' | 'edit' | 'configure'; // possible values should match DocumentEditor mode
    selected: boolean;

    context: IReportContext;

    widgetStyle: WidgetStyle;
    deleteWidget: () => void;
    patchConfig: (config: DeepPartial<IWidgetConfig> | undefined) => any;
    setZIndex: (layer: 'front' | 'back') => void;
    movePage: (direction: 'up' | 'down') => void;
    unselect: () => void;

    intl: IntlShape;
}

export class WidgetContainer extends React.Component<IOwnProps, IState> {
    static PROPS_FOR_UPDATE = ['widgetId', 'config', 'documentMode', 'selected', 'context'];

    widget: IWidget;

    constructor(props: IOwnProps, ctx?: any) {
        super(props, ctx);

        const { config } = props;
        this.widget = getWidget(config.name);

        this.state = {
            hover: false,
            pendingConfig: cloneDeep(config),
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState !== this.state) return true;

        for (const key of WidgetContainer.PROPS_FOR_UPDATE) {
            if (this.props[key] !== nextProps[key]) {
                if (key === 'context' && isEqual(this.props[key], nextProps[key])) {
                    return false;
                }
                return true;
            }
        }

        return false;
    }

    componentDidUpdate(prevProps) {
        const { config, selected } = this.props;
        const { pendingConfig } = this.state;

        if (config !== pendingConfig && !isEqual(config, pendingConfig)) {
            if (!selected && prevProps.selected) {
                // exited editing mode
                this.commitConfig();
            } else if (config !== prevProps.config) {
                this.rollbackConfig();
            }
        }
    }

    render() {
        const { context, selected, documentMode, widgetId, intl } = this.props;
        const { metadata } = this.widget;
        const { pendingConfig, hover } = this.state;
        const { configurable, name: widgetName } = pendingConfig;

        const error = this.getErrorForInjectable();
        const { showHeaders, style } = this.getStyleConfig();

        // Exclude header height from widget layout when resizing
        const layoutDelta: Partial<ILayout> | undefined =
            showHeaders && !metadata.ignoreStyle ? { h: -HEADER_HEIGHT } : undefined;

        const content = error ? (
            <WidgetErrorState title={error.title} message={error.message} />
        ) : (
            <WidgetContent
                widget={this.widget}
                setPendingConfig={this.setPendingConfig}
                commitConfig={this.commitConfig}
                rollbackConfig={this.rollbackConfig}
                selected={selected}
                renderProps={{ widgetId, context, config: pendingConfig }}
                className={classNames({ 'widget-header': showHeaders })}
            />
        );

        return (
            <>
                <ContextMenu2
                    content={
                        <ContextMenu>
                            <ContextMenuHeader>
                                Widget: {intl.formatMessage(this.widget.metadata.displayName)}
                            </ContextMenuHeader>
                            <MenuItem text="Bring to front" onClick={this.moveToFront} />
                            <MenuItem text="Send to back" onClick={this.moveToBack} />

                            <ContextMenuHeader>Page</ContextMenuHeader>
                            <MenuItem text="Move page up" onClick={this.movePageUp} />
                            <MenuItem text="Move page down" onClick={this.movePageDown} />
                        </ContextMenu>
                    }
                    disabled={documentMode !== 'edit'}
                    popoverProps={{
                        onClosing: this.props.unselect,
                    }}
                >
                    <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                        <LayoutComponent
                            layout={this.getContainerLayout()}
                            itemMeta={{
                                widgetId,
                                layoutDelta,
                                config: pendingConfig,
                            }}
                            resizable={selected && documentMode === 'edit'}
                            draggable={documentMode === 'edit'}
                            style={
                                // temporarily move to top when selected
                                selected ? { zIndex: 10000 } : undefined
                            }
                        >
                            {this.renderOverlay()}
                            <StyledFullDiv
                                disabled={documentMode === 'configure' && !configurable}
                                selected={selected}
                                hover={hover}
                            >
                                {metadata.ignoreStyle ? (
                                    content
                                ) : (
                                    <div
                                        style={
                                            error
                                                ? {
                                                      ...style,
                                                      height: '100%',
                                                      width: '100%',
                                                  }
                                                : style
                                        }
                                    >
                                        {showHeaders && (
                                            <WidgetHeader title={metadata.displayName} icon={metadata.icon} />
                                        )}
                                        {content}
                                    </div>
                                )}
                            </StyledFullDiv>
                        </LayoutComponent>
                    </div>
                </ContextMenu2>

                {documentMode === 'edit' && selected && widgetName === RICH_TEXT_WIDGET_NAME && (
                    <SidebarFormatForm>
                        <FormSection>
                            <FormHeader>
                                <FormattedMessage {...Translations.widgets.project_settings} />
                            </FormHeader>
                            <FormGroup
                                helperText={intl.formatMessage(Translations.widgets.enable_configuration_helper_text)}
                            >
                                <Switch
                                    checked={configurable}
                                    onChange={() =>
                                        this.setPendingConfig({
                                            ...pendingConfig,
                                            configurable: !configurable,
                                        })
                                    }
                                    label={intl.formatMessage(Translations.widgets.enable_configuration_label)}
                                    alignIndicator={Alignment.RIGHT}
                                    style={{ fontWeight: 'normal' }}
                                />
                            </FormGroup>
                        </FormSection>
                    </SidebarFormatForm>
                )}
            </>
        );
    }

    edit = () => {};

    getErrorForInjectable(): IWidgetErrorDesc | undefined {
        const { context, intl } = this.props;
        const widget = this.widget as IWidget;

        if (widget.dependencies) {
            const injectableWidget: IWidget<object, Partial<IReportContext>> = widget;

            if (injectableWidget.dependencies.includes('project')) {
                if (!context.project) {
                    return {
                        title: intl.formatMessage(Translations.errors.project_couldnt_load),
                    };
                }
            }

            if (injectableWidget.dependencies.includes('design')) {
                if (!context.design) {
                    return {
                        title: intl.formatMessage(Translations.errors.no_design_available),
                    };
                }

                if (!context.design.field_segments || context.design.field_segments.length === 0) {
                    return {
                        title: intl.formatMessage(Translations.errors.incomplete_design),
                    };
                }
            }

            // Since simulation is loaded async (see "loadProjectState"), there is no way to distinguish
            // a sim that is currently loading from one that attempted to load but doesn't exist
            if (
                injectableWidget.dependencies.includes('simulation') ||
                injectableWidget.dependencies.includes('financialTokens')
            ) {
                if (!context.simulation) {
                    return {
                        title: intl.formatMessage(Translations.errors.no_sim_available),
                    };
                }
                if (context.simulation.status !== 'completed' || !context.simulation.metadata.finalized) {
                    return {
                        title: intl.formatMessage(Translations.errors.sim_in_progress),
                    };
                }
            }

            if (injectableWidget.dependencies.includes('scenario')) {
                if (!context.scenario) {
                    return {
                        title: intl.formatMessage(Translations.errors.no_condition_set),
                    };
                }
            }

            if (injectableWidget.dependencies.includes('financialTokens')) {
                if (!context.financialTokens) {
                    return {
                        title: intl.formatMessage(Translations.errors.no_fin_results_available),
                    };
                }

                if (!hasOutput(context.financialTokens)) {
                    switch (context.financialTokens.status) {
                        case FIN_STATUSES.computing:
                        case FIN_STATUSES.refreshing:
                            return {
                                title: 'The financial results are still being calculated...',
                            };
                        case FIN_STATUSES.missingInputs:
                            return {
                                title: 'There are no financial inputs configured for this project.',
                            };
                        case FIN_STATUSES.permissionsError:
                            return {
                                title: 'Widget unavailable',
                                message: 'This widget requires access that is not currently included in your plan.',
                            };
                        case FIN_STATUSES.calculationError:
                        default:
                            return {
                                title: 'There was an error calculating the financial results.',
                            };
                    }
                }
            }
        }

        return undefined;
    }

    renderOverlay() {
        const { documentMode, selected } = this.props;
        const { hover } = this.state;

        if (!selected && !hover) {
            return;
        }

        const overlayStyle: React.CSSProperties = {
            pointerEvents: 'auto', // ensures hover buttons always work
        };

        return (
            <div>
                <WidgetMenu style={overlayStyle}>
                    <MenuButton className={Classes.MINIMAL} icon={IconNames.EDIT} onClick={this.edit} />
                    {documentMode === 'edit' && (
                        <>
                            <MenuButton className={Classes.MINIMAL} icon={IconNames.MOVE} />
                            <MenuButton
                                className={Classes.MINIMAL}
                                icon={IconNames.CROSS}
                                onClick={(e) => {
                                    this.props.deleteWidget();
                                    e.stopPropagation();
                                }}
                            />
                        </>
                    )}
                </WidgetMenu>
            </div>
        );
    }

    getContainerLayout(): ILayout {
        const { layout } = this.state.pendingConfig;

        // Account for header in container layout if necessary, otherwise use same layout as widget config
        return this.getStyleConfig().showHeaders && !this.widget.metadata.ignoreStyle
            ? { ...layout, h: layout.h + HEADER_HEIGHT }
            : layout;
    }

    getStyleConfig = (): IWidgetStyle => WIDGET_STYLES[this.props.widgetStyle];

    setPendingConfig = (pendingConfig: IWidgetConfig) => this.setState({ pendingConfig });
    commitConfig = (config?: IWidgetConfig) => this.props.patchConfig(cloneDeep(config || this.state.pendingConfig));
    rollbackConfig = () => this.setPendingConfig(cloneDeep(this.props.config));

    moveToBack = () => this.props.setZIndex('back');
    moveToFront = () => this.props.setZIndex('front');

    movePageUp = () => this.props.movePage('up');
    movePageDown = () => this.props.movePage('down');

    onMouseEnter = () => {
        const { documentMode, config } = this.props;
        if (documentMode === 'edit' || (documentMode === 'configure' && config.configurable)) {
            this.setState({ hover: true });
        }
    };
    onMouseLeave = () => this.setState({ hover: false });
}

export default injectIntl(WidgetContainer);
