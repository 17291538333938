import * as React from 'react';
import { ImageFile } from 'react-dropzone';
import { isEqual } from 'lodash';
import { Portal } from '@blueprintjs/core';
import InteractiveDesign3dView, {
    InteractiveDesign3dViewWrapper,
    IFullRenderSettings,
} from 'reports/modules/ogdesigner/components/InteractiveDesign3dView';

interface IOffscreenDesignSnapshotProps {
    designId: number;
    renderSettings: IFullRenderSettings;
    onCapture: (file: ImageFile) => any;
    width: number;
    height: number;
}

const OffscreenDesignSnapshotFC: React.FC<IOffscreenDesignSnapshotProps> = ({
    designId,
    renderSettings,
    width,
    height,
    onCapture,
}) => {
    const interactiveDesign3dRef = React.useRef<InteractiveDesign3dViewWrapper | null>(null);

    const captureScreenshot = React.useCallback(async () => {
        // only attempt capture if ref is valid
        if (interactiveDesign3dRef.current === null) {
            return;
        }

        try {
            const imgData = await interactiveDesign3dRef.current.getSnapshot();
            onCapture(imgData.file);
        } catch (e) {
            console.error('failed to capture design snapshot', e);
        }
    }, [onCapture]);

    // Capture screenshot after any updates
    React.useEffect(() => {
        captureScreenshot();
    });

    // clean up the reference on unmount
    React.useEffect(() => {
        return () => {
            interactiveDesign3dRef.current = null;
        };
    }, []);

    const handleRefUpdate = React.useCallback(
        (ref) => {
            interactiveDesign3dRef.current = ref;
            // run capture at least once when the designer is loaded into dom,
            // this can happen outside of a render cycle
            captureScreenshot();
        },
        [captureScreenshot],
    );

    return (
        // If the report editor is zoomed (via transform: scale), the scale factor
        // affects its whole DOM sub-tree, so we wrap this in a portal so that it
        // renders at the unscaled widget size
        <Portal>
            <div
                style={{
                    width,
                    height,
                    position: 'fixed',
                    left: '99999px',
                    top: '99999px',
                }}
            >
                <InteractiveDesign3dView designId={designId} renderSettings={renderSettings} ref={handleRefUpdate} />
            </div>
        </Portal>
    );
};

export const OffscreenDesignSnapshot = React.memo(OffscreenDesignSnapshotFC, (prevProps, nextProps) =>
    isEqual(prevProps, nextProps),
);
