import { AuthErrorCode } from 'reports/modules/financials/model/pipeline';

export interface ErrorInfo {
    name: string;
    message: string | JSX.Element;
}

export const ErrorMessages: { [key in AuthErrorCode]?: ErrorInfo } = {
    [AuthErrorCode.UNAUTHORIZED]: {
        name: 'Unauthorized',
        message: 'You are unauthorized to use this financial module.',
    },
    [AuthErrorCode.SCF_UNAUTHORIZED]: {
        name: 'SCF Integration Not Found',
        message: `Your team does not currently have the SCF integration enabled.
                  Contact your team admin or our support team to find out more.
                  If you already have an SCF account add your credentials in your team settings page`,
    },
};
