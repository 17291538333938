import { chain } from 'lodash';
import kdbush from 'kdbush';

import { IPoint, ILayout } from './types';

const SNAP_RADIUS = 15;

interface IIndexedPoint extends IPoint {
    layout: ILayout;
}

export interface IMatchResult<IResult = ILayout> {
    result: IResult;
    matchXLines: { x: number; layout: ILayout }[];
    matchYLines: { y: number; layout: ILayout }[];
}

const GET_X = (pt) => pt.x;
const GET_Y = (pt) => pt.y;

export class SnapIndex {
    index;
    points: IIndexedPoint[] = [];
    radius: number;

    constructor(targetLayouts: ILayout[], radius = SNAP_RADIUS) {
        for (const layout of targetLayouts) {
            this.points.push(
                { layout, x: layout.x, y: layout.y },
                {
                    layout,
                    x: layout.x + layout.w / 2,
                    y: layout.y + layout.h / 2,
                },
                { layout, x: layout.x + layout.w, y: layout.y + layout.h },
            );
        }

        this.index = kdbush(this.points, GET_X, GET_Y);
        this.radius = radius;
    }

    matchResize(basePoint: IPoint, offset: Partial<IPoint>, baseLayout?: ILayout): IMatchResult<IPoint> {
        const xSearch = offset.x ? [basePoint.x + offset.x] : [];
        const ySearch = offset.y ? [basePoint.y + offset.y] : [];

        return this.snapOffset({ x: offset.x || 0, y: offset.y || 0 }, xSearch, ySearch, baseLayout);
    }

    matchMove(offset: IPoint, baseLayout: ILayout): IMatchResult<ILayout> {
        const xSearch = [
            offset.x + baseLayout.x,
            offset.x + baseLayout.x + baseLayout.w / 2,
            offset.x + baseLayout.x + baseLayout.w,
        ];

        const ySearch = [
            offset.y + baseLayout.y,
            offset.y + baseLayout.y + baseLayout.h / 2,
            offset.y + baseLayout.y + baseLayout.h,
        ];

        const matchResult = this.snapOffset(offset, xSearch, ySearch, baseLayout);

        return {
            ...matchResult,
            result: {
                x: baseLayout.x + matchResult.result.x,
                y: baseLayout.y + matchResult.result.y,
                w: baseLayout.w,
                h: baseLayout.h,
            },
        };
    }

    /**
     * return an adjusted offset that is snapped based on references x and y search points (i.e. snap moving any of
     * the reference values by the offset).
     *
     * if multiple snaps within the same radius, returns the one that makes the smallest move.
     */
    snapOffset(offset: IPoint, xSearch: number[], ySearch: number[], ignore?: ILayout): IMatchResult<IPoint> {
        const radius = this.radius;
        const xMatches = chain(xSearch)
            .map((x) =>
                chain(this.index.range(x - radius, Number.NEGATIVE_INFINITY, x + radius, Number.POSITIVE_INFINITY))
                    .map((idx) => this.points[idx])
                    .filter((res) => res.layout !== ignore)
                    .map((result) => ({
                        matchedX: result.x,
                        xAdjust: result.x - x,
                        layout: result.layout,
                    }))
                    .value(),
            )
            .flatten()
            .value();

        let closestXAdj = radius;
        let finalXAdjust = 0;
        let matchXLines: any[] = [];

        for (const { matchedX, xAdjust, layout } of xMatches) {
            const adjustMagnitude = Math.abs(xAdjust);
            if (adjustMagnitude < closestXAdj) {
                finalXAdjust = xAdjust;
                closestXAdj = adjustMagnitude;
                matchXLines = [{ layout, x: matchedX }];
            } else if (xAdjust === finalXAdjust) {
                matchXLines.push({ layout, x: matchedX });
            }
        }

        const yMatches = chain(ySearch)
            .map((y) =>
                chain(this.index.range(Number.NEGATIVE_INFINITY, y - radius, Number.POSITIVE_INFINITY, y + radius))
                    .map((idx) => this.points[idx])
                    .filter((res) => res.layout !== ignore)
                    .map((result) => ({
                        matchedY: result.y,
                        yAdjust: result.y - y,
                        layout: result.layout,
                    }))
                    .value(),
            )
            .flatten()
            .value();

        let closestYAdj = radius;
        let finalYAdjust = 0;
        let matchYLines: any[] = [];

        for (const { matchedY, yAdjust, layout } of yMatches) {
            const adjustMagnitude = Math.abs(yAdjust);
            if (adjustMagnitude < closestYAdj) {
                finalYAdjust = yAdjust;
                closestYAdj = adjustMagnitude;
                matchYLines = [{ layout, y: matchedY }];
            } else if (yAdjust === finalYAdjust) {
                matchYLines.push({ layout, y: matchedY });
            }
        }

        return {
            matchXLines,
            matchYLines,
            result: { x: finalXAdjust + offset.x, y: finalYAdjust + offset.y },
        };
    }
}

export default SnapIndex;
