import ZendeskChat from './zendesk-chat';
import NoopChat from './noop-chat';
import Logger from 'js-logger';

const logger = Logger.get('chat');

export function getChat() {
    if (window['zE']) {
        return ZendeskChat;
    }
    logger.info('No chatbox configured on window.');
    return NoopChat;
}
