import * as React from 'react';

import moment from 'moment';

import type { Address } from '@stripe/stripe-js';

import { Divider as BpDivider, H5, Spinner } from '@blueprintjs/core';

import Flex from 'reports/components/core/containers/Flex';

import { useInvoicePreview } from '../Hooks';

import { HelperText } from 'reports/modules/settings/common';

import { User } from 'reports/models/user';
import { Subscription } from 'reports/models/subscription';

import { Price } from 'reports/models/stripe/price';

import { styled } from 'reports/styles/styled-components';

import { formatBillingCurrency } from '../';

interface Props {
    abridged?: boolean;
    price?: Price;
    quantity: number;
    address?: Address;
    subscription?: Subscription;
    user: User;
    setTaxIncluded?: (taxIncluded: boolean) => void;
    isDowngrade?: boolean;
}

const Container = styled(Flex.Container)`
    justify-content: flex-start;
    min-width: 400px;
    width: 400px;
    flex-direction: column;
`;

const Summary = styled(Flex.Container)`
    background-color: white;
    flex-direction: column;
    padding: 12px;
`;

const LicenseSummary = styled.div`
    padding-bottom: 12px;
`;

const CostSummary = styled.div`
    padding-top: 12px;
`;

const Divider = styled(BpDivider)`
    width: 100%;
    margin-left: -2px;
`;

const LineItem = styled(Flex.Container)`
    justify-content: space-between;
    padding-top: 4px;
`;

const LineItemElement = styled.div<{ bold?: boolean }>`
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const Footer = styled.div`
    padding-top: 12px;
`;

const PurchaseSummary = React.memo(
    ({ abridged, price, quantity, address, subscription, user, setTaxIncluded, isDowngrade }: Props) => {
        const isScheduledChange = (subscription && subscription.paid_seats > quantity) || isDowngrade;

        const { invoice } = useInvoicePreview({
            isScheduledChange,
            quantity,
            price,
            address,
            subscription,
            team: user.team,
        });

        if (!price || !invoice) {
            return (
                <Container>
                    <Spinner />
                </Container>
            );
        }

        if (setTaxIncluded) {
            setTaxIncluded(invoice.helioscope_tax_included);
        }

        const currentPeriodEnd = subscription
            ? subscription.current_period_end.format('LL')
            : moment().add(1, price?.recurring.interval).format('LL');

        const amountDue = formatBillingCurrency(invoice.amount_due);

        if (abridged && isScheduledChange) {
            return (
                <Container>
                    <Summary>
                        <H5>Summary</H5>
                        <Divider />
                        <CostSummary>
                            You will pay {formatBillingCurrency(invoice.amount_due)} starting {currentPeriodEnd}
                        </CostSummary>
                    </Summary>
                </Container>
            );
        }

        // The item.description.replace call will go away once we figure out our product description
        // standardization in Stripe. Invoice line item descriptions have the following shape:
        // Single line item with no proration: 4 × HelioScope Basic (at $159.00 / month) Monthly
        // Prorated line items:
        //   - Remaining time on 5 × HelioScope Basic after 04 Aug 2023
        //   - Unused time on 4 × HelioScope Basic after 04 Aug 2023
        // We need to convert each case to something like:
        // 4 × HelioScope Basic Monthly and Remaining time on 5 × HelioScope Basic Monthly.
        // Again, this is very temporary.
        const itemDescription = (description: string) =>
            description.replace(/^(.*?) \(.*/, '$1').replace(/ after .*/, '');

        // We want remaining time cost to show up as the first item instead of unused time cost.
        const invoiceLines = invoice.lines.data.sort((a, b) => b.amount - a.amount);

        // Stripe returns tax rates differently depending on whether it is an active subscription or a preview of
        // a non persisted invoice.
        const totalRate =
            invoice.default_tax_rates.length > 0
                ? invoice.default_tax_rates.reduce((total, taxRate) => total + taxRate.percentage, 0)
                : invoiceLines[0].tax_rates.reduce((total, taxRate) => total + taxRate.percentage, 0);

        return (
            <Container>
                <Summary>
                    <H5>Summary</H5>
                    <LicenseSummary>
                        {invoiceLines.map((item, index) => (
                            <LineItem key={index}>
                                <LineItemElement>
                                    <div>
                                        {itemDescription(item.description)}{' '}
                                        {item.price.recurring.interval === 'year' ? 'Yearly' : 'Monthly'}
                                    </div>
                                    <div>
                                        ({formatBillingCurrency(item.price.unit_amount, 0)} × {item.quantity}{' '}
                                        {item.quantity === 1 ? 'license' : 'licenses'})
                                    </div>
                                </LineItemElement>
                                <LineItemElement>{formatBillingCurrency(item.amount)}</LineItemElement>
                            </LineItem>
                        ))}
                    </LicenseSummary>
                    <Divider />
                    <CostSummary>
                        <LineItem>
                            <LineItemElement>Subtotal</LineItemElement>
                            <LineItemElement>{formatBillingCurrency(invoice.subtotal)}</LineItemElement>
                        </LineItem>
                        <LineItem>
                            <LineItemElement>Estimated Tax ({totalRate}%)</LineItemElement>
                            <LineItemElement>{formatBillingCurrency(invoice.tax)}</LineItemElement>
                        </LineItem>
                        <LineItem>
                            <LineItemElement bold>
                                {isScheduledChange ? `Amount due on ${currentPeriodEnd}` : 'Total Due'}
                            </LineItemElement>
                            <LineItemElement bold>{formatBillingCurrency(invoice.amount_due)}</LineItemElement>
                        </LineItem>
                        {(subscription && subscription.paid_seats === quantity) ||
                            (isScheduledChange && (
                                <LineItem>
                                    <LineItemElement>
                                        <HelperText>
                                            You will pay {amountDue} per {price.recurring.interval} starting{' '}
                                            {currentPeriodEnd}{' '}
                                        </HelperText>
                                    </LineItemElement>
                                </LineItem>
                            ))}
                    </CostSummary>
                </Summary>
                {!subscription && (
                    <Footer>
                        <HelperText bold>Your plan will automatically renew on {currentPeriodEnd}.</HelperText>
                        <HelperText>
                            You will be charged {price.recurring.interval === 'year' ? 'annually' : 'monthly'} for your
                            plan, unless you cancel prior to the end of the subscription term. Purchase subject to our{' '}
                            <a href="https://helioscope.aurorasolar.com/terms-of-use/" target="_blank">
                                Terms of Service
                            </a>
                            .
                        </HelperText>
                    </Footer>
                )}
            </Container>
        );
    },
);

export { PurchaseSummary };
