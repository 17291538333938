import { get } from 'lodash';

import * as analytics from 'reports/analytics/analytics';
import { currentRoute } from 'reports/analytics/analyticsTracker';
import * as events from 'reports/analytics/eventSchemas';

export function track(name: string, payload: events.IEventProps) {
    const view = get(currentRoute, 'name', '');
    const app = 'reports';

    analytics.track(name, { app, view, ...payload });
}

export function trackExport(payload: events.IExportEvent) {
    track('File Exported', payload);
}

export { default as analyticsMiddleware } from 'reports/analytics/middleware';
