import { Colors } from '@blueprintjs/core';

import Flex from './Flex';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const Card = styled(Flex.ContainerV)`
    margin: 8px 4px;
    padding: 8px 16px 16px 16px;
    background-color: #ffffff;
    border: 1px solid ${Colors.LIGHT_GRAY1};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.125);
    color: ${Colors.DARK_GRAY1};

    > div:only-child {
        flex: 1;
    }
`;

export default Card;
