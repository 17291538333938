import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box } from '@aurorasolar/ds';

const BaseProjects = () => {
    return (
        <Box h="95vh" applySystemPadding={true} overflowX="hidden">
            <Outlet />
        </Box>
    );
};

export { BaseProjects };
