/* tslint:disable:no-increment-decrement */
import { chain, isArray, isEqualWith, isObjectLike, isPlainObject, map, mapValues, pickBy, random } from 'lodash';
import { clone, setWith, curry } from 'lodash/fp';

const COPY_PATTERN = / \(copy\)| \(copy \d+\)/;
const NUMBER_PATTERN = /\d+/;

/** Return a unique description string based on the `baseDescription` appending appropriate 'copy (N)'
 * depending on existing names provided in `taken` array
 * E.g.
 *     createUniqueDescription('foo', []) => returns 'foo'
 *     createUniqueDescription('foo', ['foo', 'foo (copy)']) => returns 'foo (copy 1)'
 **/
export function createUniqueDescription(baseDescription: string, taken: string[], justNumber: boolean = false) {
    let count = 0;
    const copyMatch = COPY_PATTERN.exec(baseDescription);
    let base = baseDescription;
    if (copyMatch !== null && copyMatch.length === 1) {
        const numMatch = NUMBER_PATTERN.exec(copyMatch[0]);
        base = baseDescription.substring(0, baseDescription.length - copyMatch[0].length);
        if (numMatch !== null && numMatch.length === 1) {
            count = parseInt(numMatch[0], 10) + 1;
        } else {
            count = 1;
        }
    }

    // Return base description (stripped of any appended stuff) if nothing is taken
    if (!taken.length) return base;

    // If justNumber is true, we just want to append an incrementing number to the end of the description
    let str;
    if (!justNumber) {
        str = `${base} (copy)`;
        for (let i = 0; i < 20; i++) {
            if (count !== 0) {
                str = `${base} (copy ${count})`;
            }
            if (taken.indexOf(str) === -1) {
                return str;
            }
            count++;
        }
    } else {
        count = 1;
        for (let i = 0; i < 50; i++) {
            str = `${base} ${count}`;
            if (taken.indexOf(str) === -1) {
                return str;
            }
            count++;
        }
    }
    return str;
}

export function fallback(...values) {
    for (const value of values) {
        if (value != null) {
            return value;
        }
    }
}

export const rangesOverlap = (minR1: number, maxR1: number, minR2: number, maxR2: number): boolean =>
    !(minR1 > maxR2 || minR2 > maxR1);

export function uniqueId(prefix = '', length = 8) {
    let uid = prefix;
    if (prefix !== '') {
        uid += '_';
    }

    for (let i = 0; i < length; i += 1) {
        uid += random(0, 255).toString(16);
    }

    return uid;
}

export const mapValuesDeep = (obj, fn) =>
    mapValues(obj, (val, key) => (isPlainObject(val) ? mapValuesDeep(val, fn) : fn(val, key, obj)));

export const isEqualWithoutFuncs = (a, b) =>
    isEqualWith(a, b, (a, b) => (typeof a === 'function' || typeof b === 'function' ? true : undefined));

export const objWithoutFuncs = (obj) => pickBy(obj, (x) => typeof x !== 'function');

// set a path and value on an object immutably (cloning any necessary properties)
export const setIn = curry((path, value, obj) => setWith(clone, path, value, clone(obj)));

export const shallowCompare = (obj1, obj2) =>
    Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every((key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);

// A recursive version of lodash's omitBy function.
export const omitByRecursively = (value, predicate) => {
    const recurse = (v) => omitByRecursively(v, predicate);
    if (isArray(value)) {
        return map(value, recurse);
    }
    if (isObjectLike(value)) {
        return chain(value).omitBy(predicate).mapValues(recurse).value();
    }
    return value;
};
