import * as React from 'react';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { actions as routerActions } from 'redux-router5';
import { connect } from 'react-redux';

import * as proj from 'reports/models/project';

import { bindActions } from 'reports/utils/redux';
import { NewScenarioButton } from 'reports/components/BreadcrumbPopovers';
import { ContextBarControls } from 'reports/components/ContextBar';
import { Card } from 'reports/components/core/containers';
import { HSLightTheme } from 'reports/styles/colors';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const EmptyStateCard = styled(Card)`
    min-height: 600px;
    padding: 16px;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
`;

interface IConditionSetEmptyStateProps {
    project: proj.Project;
}

class ConditionSetEmptyState extends React.Component<IConditionSetEmptyStateProps> {
    render() {
        return (
            <>
                <ContextBarControls>
                    <NewScenarioButton project={this.props.project} />
                </ContextBarControls>
                <EmptyStateCard>
                    <p>
                        <Icon iconSize={64} icon={IconNames.CLOUD} color={HSLightTheme.emptyStateIcon} />
                    </p>
                    <p>There are no Condition Sets for this project.</p>
                    <p>
                        <NewScenarioButton project={this.props.project} />
                    </p>
                </EmptyStateCard>
            </>
        );
    }

    createScenario() {
        // TODO: implement
        window.alert('New Condition Set');
    }
}

const mapDispatchToProps = bindActions(() => ({
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
}));

export default connect(null, mapDispatchToProps)(ConditionSetEmptyState);
