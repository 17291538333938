import * as React from 'react';

import { Text, Form, PasswordInput, Button } from '@aurorasolar/ds';
import { Field, Formik, FormikHelpers } from 'formik';
import { NewPasswordField } from 'reports/modules/auth/components/NewPasswordField';
import { ChangePasswordFormData } from './ChangePasswordDialog';

interface Props {
    onSubmit: (formData: ChangePasswordFormData, formikHelpers: FormikHelpers<ChangePasswordFormData>) => any;
}

const PasswordForm = ({ onSubmit }: Props) => (
    <>
        <Text text="body14">
            For security purposes, use a unique password made up of numbers, letters, and special characters.
        </Text>
        <Formik
            initialValues={{ password: '', new_password: '', confirm_password: '' }}
            onSubmit={(values, formikHelpers: FormikHelpers<ChangePasswordFormData>) => {
                onSubmit(values, formikHelpers);
            }}
            validateOnChange={false}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Field
                        component={PasswordInput}
                        fontWeight="normal"
                        label="Current password"
                        name="password"
                        placeholder="Enter current password"
                    />
                    <NewPasswordField name="new_password" />
                    <Field
                        component={PasswordInput}
                        fontWeight="normal"
                        label="Confirm password"
                        name="confirm_password"
                        placeholder="Confirm new password"
                    />
                    <Button fullWidth size="md" type="submit" variant="primary" disabled={isSubmitting}>
                        Change password
                    </Button>
                </Form>
            )}
        </Formik>
    </>
);

export default PasswordForm;
