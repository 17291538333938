import * as React from 'react';

import { Alignment, Switch } from '@blueprintjs/core';

import { AuthErrorCode, Node } from 'reports/modules/financials/model/pipeline';

import FinancialStep from './FinancialStep';
import StepParameter from './StepParameter';
import StepResult from './StepResult';
import WarningTooltip from '../WarningTooltip';

interface ITemplateStepProps {
    node: Node;
    error?: AuthErrorCode;
}

const PreviewStep: React.SFC<ITemplateStepProps> = ({ node, error }) => {
    const parametersToShow = node.getPipelineParameters().filter((i) => !i.hidden);
    const resultsToShow = node.module.outputValues ? node.module.outputValues.filter((i) => !i.hidden) : [];

    const titleText = node.user_label ? node.user_label : node.module.description;
    const title = error ? (
        <>
            <WarningTooltip error={error} />
            {titleText}
        </>
    ) : (
        titleText
    );

    return (
        <FinancialStep.Container minimal>
            <FinancialStep.Header
                title={title}
                controls={
                    node.toggleable && (
                        <FinancialStep.Controls>
                            <Switch
                                alignIndicator={Alignment.RIGHT}
                                style={{
                                    marginRight: '8px',
                                    marginBottom: '0px',
                                }}
                                checked={true}
                                disabled={true}
                            />
                        </FinancialStep.Controls>
                    )
                }
            />
            {!error && parametersToShow.length > 0 && (
                <FinancialStep.Content>
                    {parametersToShow.map((parameter, idx) => (
                        <StepParameter key={idx} parameter={parameter} editable={false} />
                    ))}
                    {resultsToShow.map((result, idx) => (
                        <StepResult key={idx} result={result} />
                    ))}
                </FinancialStep.Content>
            )}
        </FinancialStep.Container>
    );
};

export default PreviewStep;
