/* tslint:disable:variable-name */
import { merge, set } from 'lodash';

import * as React from 'react';

import * as ur from 'reports/models/utility_rate';

import { IParameterDefinition, IParamProps, ParamValueType } from 'reports/modules/financials/params';
import ParamRatesList from './ParamRatesList';
import ParamTimeOfUseTables from './ParamTimeOfUseTables';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const RateElement = styled.div`
    border: 1px solid #c8c8c8;
    padding: 6px;
    margin: 6px;
`;

export const RateSection = styled.div`
    margin: 8px;
`;

const RateSectionHeader = styled(RateSection)`
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
`;

export function isEnergyRate(rates: ur.RateConfig): rates is ur.IEnergyConfig {
    return (rates as ur.IEnergyConfig).net_meter_options !== undefined;
}

const rateColors = (() => {
    const code3 = ['001', '010', '011', '100', '101', '110', '012', '021', '102', '120', '201', '210'];

    const hex = ['fc', 'ca', '98', '70', '48'];

    const convert3 = (hex1, hex2, hex3) => {
        const tuple = [hex1, hex2, hex3];
        return code3.map((i) => `#${tuple[i.charAt(0)]}${tuple[i.charAt(1)]}${tuple[i.charAt(2)]}`);
    };

    return convert3(hex[0], hex[1], hex[2])
        .concat(convert3(hex[0], hex[2], hex[3]))
        .concat(convert3(hex[0], hex[3], hex[4]))
        .concat(convert3(hex[1], hex[2], hex[3]))
        .concat(convert3(hex[1], hex[3], hex[4]))
        .concat(convert3(hex[2], hex[3], hex[4]));
})();

export const getRateColor = (idx) => rateColors[idx % rateColors.length];

export interface IRatesConfig extends IParameterDefinition<ur.RateConfig> {
    type: ParamValueType.RatesFull;
    default: ur.RateConfig;
    label_key: string;
    rate_import_path: string;
    net_meter_parameters?: { flat_rate?: boolean };
}

export class ParamRatesFull extends React.Component<IParamProps<IRatesConfig>> {
    render() {
        const { parameter, value, disabled } = this.props;
        return (
            <div>
                <RateSectionHeader>Rates</RateSectionHeader>
                <ParamRatesList
                    parameter={parameter}
                    value={value}
                    onUpdate={this.updateRateProp}
                    disabled={disabled}
                />
                <RateSectionHeader>Time of Use</RateSectionHeader>
                <ParamTimeOfUseTables
                    value={value.tables}
                    onUpdate={this.updateRateProp}
                    maxValue={value.rate_schedules.length}
                    disabled={disabled}
                />
            </div>
        );
    }

    updateRateProp = (propPath: string, value: any) => {
        const { updateFn } = this.props;
        if (!updateFn) return;

        const newValue = merge({}, this.props.value);
        set(newValue, propPath, value);
        updateFn(newValue);
    };
}

export default ParamRatesFull;
