import * as React from 'react';

import * as styles from 'reports/styles/styled-components';
import { Callout as bpCallout, CalloutProps, Colors, Classes, H4 } from '@blueprintjs/core';

const styled = styles.styled;

const StyledCallout = styled(bpCallout)`
    margin-bottom: ${({ bottomMargin }: Props) => (bottomMargin ? bottomMargin : BottomMargin.NONE)};

    &&& .${Classes.HEADING} {
        // Blueprint overrides
        font-size: 16px;
        color: ${Colors.BLACK};
    }
`;

const StyledCalloutWithRightElement = styled(StyledCallout)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const RightElementContainer = styled.div`
    margin: auto 0;
`;

export enum BottomMargin {
    NONE = '0',
    STANDARD = '16px',
}

interface IOwnProps {
    rightElement?: JSX.Element;
    bottomMargin?: BottomMargin;
}

type Props = CalloutProps & IOwnProps;

const Callout = (props: CalloutProps & IOwnProps) => {
    /**
     * This is a wrapper component that centralizes any overrides of the default Blueprint styles for Callout.
     * It also adds support for the common use case of rendering a vertically centered button or call-to-action
     * on the right side of the callout (e.g. rightElement).
     * @param {rightElement} is vertically centered button or any html element(assumably CTA) which will appear on the right end of the banner;
     * @param {bottomMargin} is to override the default margin by 16px;
     */
    if (props.rightElement) {
        const { children, rightElement, title, ...otherProps } = props;
        return (
            <StyledCalloutWithRightElement {...otherProps}>
                <div>
                    {title && <H4 className={Classes.HEADING}>{title}</H4>}
                    {children}
                </div>
                <RightElementContainer>{rightElement}</RightElementContainer>
            </StyledCalloutWithRightElement>
        );
    }

    return <StyledCallout {...props}></StyledCallout>;
};

export default Callout;
