import * as React from 'react';
import { useSelector } from 'react-redux';

import { Intent, Spinner, Tag } from '@blueprintjs/core';

import { Section2 } from 'reports/components/core/containers';
import { selectors as authSelectors } from 'reports/modules/auth';
import { TeamFeaturesForm } from 'reports/modules/settings/admin/components/TeamFeaturesForm';

import { Team } from 'reports/models/team';
import { User } from 'reports/models/user';
import { useAdminData } from '../../hooks/useAdminData';

interface Props {
    team: Team;
    user: User;
}

const TeamFeatures = ({ team, user }: Props) => {
    const authUser = useSelector((state) => authSelectors.getUser(state)!);
    const { adminDataLoaded, teamExperiments } = useAdminData({ isAdmin: authUser.is_admin });

    if (adminDataLoaded) {
        return (
            <Section2 title="Feature Flags" contextEl={<Tag intent={Intent.DANGER}>HelioScope admin only</Tag>}>
                <TeamFeaturesForm team={team} user={user} features={teamExperiments} />
            </Section2>
        );
    }

    return <Spinner />;
};

export { TeamFeatures };
