import { connect } from 'react-redux';

import { createSelector } from 'reselect';

import { injectIntl, IntlShape } from 'react-intl';

import { IAppState } from 'reports/types';

import Translations from 'reports/localization/strings';
import * as fmt from 'reports/utils/formatters';

import { Simulation } from 'reports/models/simulation';
import { ILayoutChartProps, LayoutChart } from 'reports/modules/report/components/charts';

function cleanData(data, intl) {
    if (!data) return [];

    const lossData = [] as any[];
    const constrainedPower =
        data.optimal_dc_power -
        data.inverter_overpower_loss -
        data.inverter_underpower_loss -
        data.inverter_overvoltage_loss -
        data.inverter_undervoltage_loss;

    lossData.push({
        name: intl.formatMessage(Translations.simulation.shading_loss_name),
        y: 1 - Math.max(data.shaded_irradiance / (data.poa_irradiance || 1), 0),
        description: intl.formatMessage(Translations.simulation.shading_loss_description),
    });
    lossData.push({
        name: intl.formatMessage(Translations.simulation.reflection_loss_name),
        y: 1 - Math.max(data.effective_irradiance / (data.shaded_irradiance || 1), 0),
        description: intl.formatMessage(Translations.simulation.reflection_loss_description),
    });
    lossData.push({
        name: intl.formatMessage(Translations.simulation.soiling_loss_name),
        y: 1 - Math.max(data.soiled_irradiance / (data.effective_irradiance || 1), 0),
        description: intl.formatMessage(Translations.simulation.soiling_loss_description),
    });
    lossData.push({
        name: intl.formatMessage(Translations.simulation.low_irradiation_loss_name),
        y: 1 - Math.max(data.module_irradiance_derated_power / (data.nameplate_power || 1), 0),
        description: intl.formatMessage(Translations.simulation.low_irradiation_loss_description),
    });
    lossData.push({
        name: intl.formatMessage(Translations.simulation.temperature_loss_name),
        y: 1 - Math.max(data.module_mpp_power / (data.module_irradiance_derated_power || 1), 0),
        description: intl.formatMessage(Translations.simulation.temperature_loss_description),
    });
    lossData.push({
        name: intl.formatMessage(Translations.simulation.mismatch_loss_name),
        y: 1 - Math.max(data.module_power / (data.module_mpp_power || 1), 0),
        description: intl.formatMessage(Translations.simulation.mismatch_loss_description),
    });

    if (data.optimizer_input_power > 0) {
        lossData.push({
            name: intl.formatMessage(Translations.simulation.optimizer_loss_name),
            y:
                1 -
                Math.max(
                    (data.optimizer_output_power + (data.module_power - data.optimizer_input_power)) /
                        (data.module_power || 1),
                    0,
                ),
            description: intl.formatMessage(Translations.simulation.optimizer_loss_description),
        });
    }
    if (data.optimal_dc_power > 0) {
        lossData.push({
            name: intl.formatMessage(Translations.simulation.wiring_loss_name),
            y:
                1 -
                Math.max(
                    data.optimal_dc_power /
                        (data.optimizer_output_power + (data.module_power - data.optimizer_input_power) || 1),
                    0,
                ),
            description: intl.formatMessage(Translations.simulation.wiring_loss_description),
        });
        lossData.push({
            name: intl.formatMessage(Translations.simulation.clipping_loss_name),
            y: 1 - Math.max(constrainedPower / (data.optimal_dc_power || 1), 0),
            description: intl.formatMessage(Translations.simulation.clipping_loss_description),
        });
    } else {
        lossData.push({
            name: intl.formatMessage(Translations.simulation.wiring_loss_name),
            y:
                1 -
                Math.max(
                    data.actual_dc_power /
                        (data.optimizer_output_power + (data.module_power - data.optimizer_input_power) || 1),
                    0,
                ),
            description: intl.formatMessage(Translations.simulation.wiring_loss_description),
        });
    }

    lossData.push({
        name: intl.formatMessage(Translations.simulation.inverter_loss_name),
        y: 1 - Math.max(data.ac_power / (constrainedPower || 1), 0),
        description: intl.formatMessage(Translations.simulation.inverter_loss_description),
    });
    lossData.push({
        name: intl.formatMessage(Translations.simulation.ac_system_loss_name),
        y: 1 - Math.max(data.grid_power / (data.ac_power || 1), 0),
        description: intl.formatMessage(Translations.simulation.ac_system_loss_description),
    });

    return lossData;
}

interface IOwnProps extends ILayoutChartProps {
    simulation: Simulation;
    intl: IntlShape;
}

const chartConfigSelector = createSelector(
    (_state, props: IOwnProps) => props.simulation,
    (_state, props: IOwnProps) => props.intl,
    (simulation, intl) => {
        const { locale } = intl;

        return {
            chart: {
                backgroundColor: 'transparent',
            },
            title: '',
            tooltip: {
                pointFormatter(this: any) {
                    return `${this.series.name}: <b>${fmt.percentage(this.y, {
                        locale,
                    })}</b><br>${this.description}`;
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    innerSize: '40%',
                    dataLabels: {
                        enabled: true,
                        color: '#000000',
                        connectorColor: '#000000',
                        formatter(this: any) {
                            return `<b>${this.point.name}</b>: ${fmt.percentage(this.y, { locale })}`;
                        },
                    },
                },
            },
            colors: ['#771100', '#CC6633', '#FF9900', '#656565', '#BBB', '#AA3300', '#CA883A', '#FABB0A', '#999'],

            series: [
                {
                    type: 'pie',
                    name: intl.formatMessage(Translations.widgets.system_loss_chart_header),
                    data: cleanData(simulation.metadata, intl),
                },
            ],
            credits: {
                enabled: false,
            },
        };
    },
);

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => ({
    config: chartConfigSelector(state, ownProps),
});

const ConnectedChart = connect(mapStateToProps)(LayoutChart as any);

export default injectIntl(ConnectedChart) as any as React.ComponentClass<Omit<IOwnProps, 'intl'>>;
