import * as React from 'react';
import { isRouteErrorResponse, Navigate, useRouteError } from 'react-router';

import { getChat } from 'reports/modules/chat/chat-wrapper';

import * as errorReports from 'reports/error-reports';

const ErrorBoundary = () => {
    const errorStatus = (error: unknown): number | null => {
        if (isRouteErrorResponse(error)) {
            return error.status;
        } else if (error != undefined && typeof error === 'object') {
            return Object.keys(error).includes('status') ? error['status'] : null;
        } else {
            return null;
        }
    };

    const error = useRouteError();
    const [status, setStatus] = React.useState<number | null>(errorStatus(error));

    React.useEffect(() => {
        setStatus(errorStatus(error));
        const errorId: any = errorReports.captureException(error, errorReports.LEVELS.error, 'app');
        getChat().updateVisitorStatus(`Last Error: ${errorReports.getEventLink(errorId)}`);
    }, [error]);

    const redirectPath = status === 403 ? '/403' : '/error';

    return <Navigate to={redirectPath} />;
};

export { ErrorBoundary };
