import * as React from 'react';

import { Dialog, IDialogProps } from '@blueprintjs/core';

const DialogOverlay: React.SFC<IDialogProps & { style?: React.CSSProperties }> = ({
    isOpen,
    children,
    style,
    ...dialogProps
}) => (
    <Dialog
        isOpen
        hasBackdrop
        transitionDuration={50}
        style={{ width: 'auto', padding: '0px', ...style }}
        {...dialogProps}
    >
        {children}
    </Dialog>
);

export default DialogOverlay;
