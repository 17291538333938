import * as React from 'react';
import moment from 'moment';
import { Intent } from '@blueprintjs/core';
import { DatePicker, TimePickerProps, TimePrecision } from '@blueprintjs/datetime';
import FormField from 'reports/components/forms/FormField';
import { FormGroup, IFormGroupProps } from 'reports/components/core/forms';

type IFormDatePickerProps = IFormGroupProps & {
    path: string;
    minDate?: Date;
    maxDate?: Date;
    timePrecision?: TimePrecision;
    timePickerProps?: TimePickerProps;
};

// TODO: migrate to using withForm higher-order component
const FormDatePicker: React.FC<IFormDatePickerProps> = (props) => {
    const { path, label, inline, labelWidth, fill, bold, timePrecision, timePickerProps, helperText, ...inputProps } =
        props;

    return (
        <FormField path={path}>
            {({ onChange, value, errors }) => (
                <FormGroup
                    inline={inline}
                    fill={fill}
                    bold={bold}
                    label={label}
                    labelWidth={labelWidth}
                    intent={errors.length ? Intent.DANGER : undefined}
                    helperText={errors.length > 0 ? errors[0] : helperText}
                >
                    <DatePicker
                        onChange={(x) => onChange(moment(x))}
                        value={value?.toDate()}
                        canClearSelection={false}
                        timePrecision={timePrecision}
                        timePickerProps={timePickerProps}
                        {...inputProps}
                    />
                </FormGroup>
            )}
        </FormField>
    );
};

export default FormDatePicker;
