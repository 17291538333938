/* tslint:disable:variable-name function-name */
import { Address } from '@stripe/stripe-js';

import { schema } from '../schema';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { StripeInvoiceSettings } from './stripe_customer_invoice_settings';

const PURCHASE_ORDER_KEY = 'Purchase Order #';

class StripeCustomer extends BaseClass {
    id: string;
    email: string;
    name: string;
    address: Address;
    invoice_settings: StripeInvoiceSettings;
    tax_ids;

    constructor(data) {
        super(StripeCustomer.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({});

    get purchaseOrder() {
        return this.invoice_settings.custom_fields
            ? this.invoice_settings.custom_fields.find((item) => item.name === PURCHASE_ORDER_KEY)
            : { value: '' };
    }

    get taxIDs() {
        return this.tax_ids.data ? this.tax_ids.data.map((taxID) => ({ type: taxID.type, value: taxID.value })) : [];
    }
}

const schemaObj = schema.addObject(StripeCustomer, 'customer', { idName: 'id' });

const endpoint = ReduxEndpoint.fromSchema('/api/v2/customers/', schemaObj);

interface UpdateStripeCustomerForm {
    email: string;
    name: string;
    address: Address;
    purchase_order?: string;
}

interface AddressValidationForm {
    address: Address;
}

const api = {
    create: endpoint.post<{ frozen_time?: string }>('', {
        ...ReduxEndpoint.PassThroughConfig(),
    }),
    getStripeCustomer: endpoint.get<{ hs_subscription_external_id: string }>('{hs_subscription_external_id}'),
    createStripePortalSession: endpoint.post<{ hs_subscription_external_id: string }>(
        '{hs_subscription_external_id}/stripe_portal_sessions',
        {
            ...ReduxEndpoint.PassThroughConfig(),
        },
    ),
    updateStripeCustomer: endpoint.put<UpdateStripeCustomerForm, { hs_subscription_external_id: string }>(
        '{hs_subscription_external_id}',
        {
            ...ReduxEndpoint.PassThroughConfig(),
        },
    ),
    getAddressValidation: endpoint.post<
        AddressValidationForm,
        { stripe_subscription_id: string },
        { tax_included: boolean }
    >('{stripe_subscription_id}/validate_address', {
        ...ReduxEndpoint.PassThroughConfig(),
    }),
};

export { StripeCustomer, api };
