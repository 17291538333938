import * as React from 'react';
import { Switch } from '@blueprintjs/core';

import { IParameterDefinition, IParamProps, ParamValueType } from '../params';

export interface IBooleanConfig extends IParameterDefinition<boolean> {
    type: ParamValueType.Boolean;
}

export const ParamBoolean: React.SFC<IParamProps<IBooleanConfig>> = ({ value, updateFn, disabled }) => (
    <Switch
        innerLabel="off"
        innerLabelChecked="on"
        checked={value}
        onChange={() => {
            if (updateFn) updateFn(!value);
        }}
        disabled={disabled}
    />
);

export default ParamBoolean;
