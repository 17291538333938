import _ from 'lodash';
import * as React from 'react';

import { stringifyNumber } from 'reports/utils/formatters';

import * as pd from 'reports/models/power_device';
import BasicTable from 'reports/components/core/tables/BasicTable';

interface IOwnProps {
    characterization: pd.PowerDeviceCharacterization;
}

const EfficiencyTable: React.SFC<IOwnProps> = ({ characterization }) => {
    const { power_device: device, efficiency_points: points } = characterization;

    const factor1 = _(points)
        .map('factor_1')
        .uniq()
        .value()
        .sort((a, b) => {
            return a - b;
        });
    const factor2 = _(points)
        .map('factor_2')
        .uniq()
        .value()
        .sort((a, b) => {
            return a - b;
        });
    const efficiencyPercent = (f1, f2) => {
        const point = points.find((pt) => pt.factor_1 === f1 && pt.factor_2 === f2);
        // not every combination of factor_1 and factor_2 exists
        if (point == null) {
            return '-';
        }
        // turn 0.911 into '91.1%'
        const eff = point.efficiency * 100;
        return `${eff.toFixed(1)}%`;
    };

    return (
        <BasicTable tableTheme="specs" width="100%">
            {/* TODO - figure out why table-layout: fixed was needed */}
            <thead>
                <tr>
                    <th rowSpan={2}>{device.efficiency_factor_2}</th>
                    <th colSpan={factor1.length} className="center">
                        {device.efficiency_factor_1}
                    </th>
                </tr>
                <tr>
                    {factor1.map((f1, i) => (
                        <th key={i} className="center">
                            {f1}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {factor2.map((f2, i) => (
                    <tr key={i}>
                        <th>{device.efficiency_factor_2 ? stringifyNumber(f2) : null}</th>
                        {factor1.map((f1, j) => (
                            <td key={j}>{efficiencyPercent(f1, f2)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </BasicTable>
    );
};

export default EfficiencyTable;
