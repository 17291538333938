import _ from 'lodash';
import moment from 'moment-timezone';

import { Unit } from 'reports/utils/units';

import { DurationTimeSeriesHour, TIME_INTERVALS, TimeSeries } from '../core';

import { ParamValueType } from 'reports/modules/financials/params';
import { OutputCategory } from 'reports/modules/financials/model/debug';
import { IPipelineModule } from 'reports/modules/financials/model/modules/types';

function bucketByMonth(series) {
    const datetime = series.startMoment;
    const buckets = _.range(12).map(() => 0.0);

    series.iterate((value, start, end, _idx) => {
        const i = datetime.month();
        datetime.add(end - start, 'ms');

        buckets[i] += value;
    });

    return buckets;
}

function convertKwhEnergySeriesToWh(series, interval = TIME_INTERVALS.YEAR): number[] {
    return series.reaggregateInterval(interval).toOutputSeries(0.0).multiplyScalar(1000).seriesRaw();
}

export const BasicInitializeTimeSeries: IPipelineModule = {
    required: true,
    description: 'System Lifetime',
    parameters: [
        {
            description: 'Operating Years',
            path: 'years',
            type: ParamValueType.Integer,
            max_value: 100,
            min_value: 1,
            default: 25,
        },
        {
            description: 'Install Time (Months from Present)',
            path: 'start_months_from_now',
            type: ParamValueType.Integer,
            max_value: 999,
            min_value: 1,
            default: 1,
        },
        // for testing
        {
            path: 'absolute_start',
            type: ParamValueType.Boolean,
            hidden: true,
            default: false,
        },
        {
            path: 'start_year',
            type: ParamValueType.Integer,
            hidden: true,
            max_value: 100,
            min_value: 0,
            default: 0,
        },
        {
            path: 'start_month',
            type: ParamValueType.Integer,
            hidden: true,
            max_value: 12,
            min_value: 0,
            default: 0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { years, absolute_start } = params;
            const { timeZoneId } = state;

            state.systemLifeYears = years;
            state.fineGranularity = TIME_INTERVALS.HOUR;

            if (absolute_start) {
                const { start_year, start_month } = params;
                state.systemStartYear = start_year;
                state.systemStartMonth = start_month - 1; // tests are 1-indexed
            } else {
                const { start_months_from_now } = params;
                const baseMoment = moment().tz(timeZoneId).add(start_months_from_now, 'months');

                state.systemStartYear = baseMoment.year();
                state.systemStartMonth = baseMoment.month();
            }

            // initialize accounting data -- assuming hourly granularity for now
            const hourly = new DurationTimeSeriesHour(0, null, _.range(0, 8760));
            const fixed = hourly.fixStartMonth(
                timeZoneId,
                timeZoneId,
                state.systemStartYear,
                state.systemStartMonth,
                years,
            );
            const zeroYearly = fixed.reaggregateInterval(TIME_INTERVALS.YEAR).map(() => 0.0);
            const zeroMonthly = fixed.reaggregateInterval(TIME_INTERVALS.MONTH).map(() => 0.0);

            state.systemInitial = 0.0;
            state.systemYearly = zeroYearly.clone();

            state.incentiveInitial = 0.0;
            state.incentiveYearly = zeroYearly.clone();

            state.financeInitial = 0.0;
            state.financeYearly = zeroYearly.clone();

            state.taxInitial = 0.0;
            state.taxYearly = zeroYearly.clone();

            state.utilityBeforeMonthly = zeroMonthly.clone();
            state.utilityAfterMonthly = zeroMonthly.clone();
        },
    },
};

export const BasicProductionSimulated: IPipelineModule = {
    required: true,
    description: 'Analyze Solar Production Data',
    parameters: [],
    module: {
        main: (state) => {
            const { fineGranularity, systemLifeYears, systemStartYear, systemStartMonth, timeZoneId, hourlyData } =
                state;

            // looks like production data is in local time not Etc/UTC
            const hourly = new DurationTimeSeriesHour(
                0,
                null,
                hourlyData.map((i) => i.grid_power * 0.001),
            );
            const fixedOneYear = hourly.fixStartMonth(timeZoneId, timeZoneId, systemStartYear, systemStartMonth, 1);
            const fixed = hourly.fixStartMonth(
                timeZoneId,
                timeZoneId,
                systemStartYear,
                systemStartMonth,
                systemLifeYears,
            );

            state.productionOneYear = fixedOneYear.reaggregateInterval(fineGranularity);
            state.production = fixed.reaggregateInterval(fineGranularity);
        },
        debugOutputs: [
            {
                table: OutputCategory.Production,
                getParamValue: (state) => convertKwhEnergySeriesToWh(state.production),
                getParamValueMonthly: (state) => convertKwhEnergySeriesToWh(state.production, TIME_INTERVALS.MONTH),
            },
        ],
    },
};

export const BasicConsumptionHourly: IPipelineModule = {
    required: true,
    description: 'Analyze Usage Data',
    parameters: [],
    module: {
        main: (state) => {
            const { fineGranularity, systemLifeYears, systemStartYear, systemStartMonth, timeZoneId, hourlyData } =
                state;

            const hourly = new DurationTimeSeriesHour(
                0,
                null,
                hourlyData.map((i) => i.usage * 0.001),
            );
            const fixedOneYear = hourly.fixStartMonth(timeZoneId, timeZoneId, systemStartYear, systemStartMonth, 1);
            const fixed = hourly.fixStartMonth(
                timeZoneId,
                timeZoneId,
                systemStartYear,
                systemStartMonth,
                systemLifeYears,
            );

            state.consumptionOneYear = fixedOneYear.reaggregateInterval(fineGranularity);
            state.consumption = fixed.reaggregateInterval(fineGranularity);
        },
        debugOutputs: [
            {
                table: OutputCategory.Consumption,
                getParamValue: (state) => convertKwhEnergySeriesToWh(state.consumption),
                getParamValueMonthly: (state) => convertKwhEnergySeriesToWh(state.consumption, TIME_INTERVALS.MONTH),
            },
        ],
    },
};

export const BasicDegradationSimple: IPipelineModule = {
    description: 'System Degradation',
    parameters: [
        {
            description: 'Annual Degradation Rate',
            path: 'value_yearly',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.005,
        },
    ],
    module: {
        main: function main(state, params) {
            const { value_yearly } = params;
            const { production } = state;

            // this is stupid but apparently it's what people do
            const yearlyCoeffs = production
                .reaggregateInterval(TIME_INTERVALS.YEAR)
                .map((_i, _j, _k, idx) => 1.0 - value_yearly * idx);

            production.multiplySeries(yearlyCoeffs.resampleInterval(production.intervalType));

            state.annualDegradation = value_yearly;
        },
        debugOutputs: [
            {
                table: OutputCategory.Production,
                getParamValue: (state) => convertKwhEnergySeriesToWh(state.production),
                getParamValueMonthly: (state) => convertKwhEnergySeriesToWh(state.production, TIME_INTERVALS.MONTH),
            },
        ],
    },
};

export const BasicDemandProfile: IPipelineModule = {
    required: true,
    description: 'Simulate Demand Profile',
    parameters: [],
    module: {
        main: (state) => {
            const zipped = TimeSeries.zipSeries({
                consumption: state.consumption,
                production: state.production,
            }).map(({ consumption, production }) => {
                const offset = Math.min(consumption, production);
                const surplus = production - offset;
                const after = consumption - offset;
                return { offset, surplus, after };
            });

            state.offset = zipped.map((i) => i.offset);
            state.surplus = zipped.map((i) => i.surplus);
            state.consumptionAfter = zipped.map((i) => i.after);

            state.surplusYearly = state.surplus.reaggregateInterval(TIME_INTERVALS.YEAR);
        },
        debugOutputs: [
            {
                table: OutputCategory.Consumption,
                getParamValue: (state) => convertKwhEnergySeriesToWh(state.consumptionAfter),
                getParamValueMonthly: (state) =>
                    convertKwhEnergySeriesToWh(state.consumptionAfter, TIME_INTERVALS.MONTH),
            },
        ],
    },
};

export const BasicEnvironmentalMetrics: IPipelineModule = {
    description: 'Environmental Offsets',
    parameters: [
        {
            description: 'Carbon Dioxide (kg / kWh)',
            path: 'co2_kg',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.707,
            precision: 8,
        },
        {
            description: 'Passenger Vehicles Driven for 1 Year (per kWh)',
            path: 'driven_vehicle',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.0002,
            precision: 8,
        },
        {
            description: 'Passenger Vehicle Distance Driven (km / kWh)',
            path: 'driven_km',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 2.73588,
            precision: 8,
        },
        {
            description: 'Waste Recycled Instead of Landfilled (Tons / kWh)',
            path: 'recycled_ton',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.00025,
            precision: 8,
        },
        {
            description: 'Waste Recycled Instead of Landfilled (Garbage Trucks / kWh)',
            path: 'recycled_truck',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.00004,
            precision: 8,
        },
        {
            description: 'Gasoline Consumed (Liter / kWh)',
            path: 'gasoline_liter',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.3028329,
            precision: 8,
        },
        {
            description: 'Gasoline Consumed (Tanker Truck / kWh)',
            path: 'gasoline_tanker',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.000009,
            precision: 8,
        },
        {
            description: 'Home Annual Energy Usage (Homes / kWh)',
            path: 'energy_annual_home',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.00008,
            precision: 8,
        },
        {
            description: 'Home Annual Electricity Usage (Homes /kWh)',
            path: 'electricity_annual_home',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.00012,
            precision: 8,
        },
        {
            description: 'Incandescent Lamps Switched to LEDs (Bulbs / kWh)',
            path: 'led_bulb',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.027,
            precision: 8,
        },
        {
            description: 'Barrels of Oil Consumed (Barrels / kWh)',
            path: 'oil_barrel',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.00164,
            precision: 8,
        },
        {
            description: 'Propane Used for Home Barbecues (Cylinders / kWh)',
            path: 'propane_can',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.029,
            precision: 8,
        },
        {
            description: 'Coal Burned (kg / kWh)',
            path: 'coal_kg',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.3506269,
            precision: 8,
        },
        {
            description: 'Coal Burned (Railcars / kWh)',
            path: 'coal_railcar',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.000004,
            precision: 8,
        },
        {
            description: 'Tree Seedlings Grown for 10 Years (Seedling / kWh)',
            path: 'seedling_ten_year',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.0117,
            precision: 8,
        },
        {
            description: 'Hectares of Forest Preserved for 1 Year (Hectares / kWh)',
            path: 'forest_hectare',
            type: ParamValueType.Float,
            min_value: 0.0,
            max_value: 99.0,
            default: 0.00832,
            precision: 8,
        },
    ],
    module: {
        main: function main(state, params) {
            const totalkwh = _.sum(state.production.seriesRaw());

            state.co2_kg = totalkwh * params.co2_kg;
            state.driven_vehicle = totalkwh * params.driven_vehicle;
            state.driven_km = totalkwh * params.driven_km;
            state.recycled_ton = totalkwh * params.recycled_ton;
            state.recycled_truck = totalkwh * params.recycled_truck;
            state.gasoline_liter = totalkwh * params.gasoline_liter;
            state.gasoline_tanker = totalkwh * params.gasoline_tanker;
            state.energy_annual_home = totalkwh * params.energy_annual_home;
            state.electricity_annual_home = totalkwh * params.electricity_annual_home;
            state.led_bulb = totalkwh * params.led_bulb;
            state.oil_barrel = totalkwh * params.oil_barrel;
            state.propane_can = totalkwh * params.propane_can;
            state.coal_kg = totalkwh * params.coal_kg;
            state.coal_railcar = totalkwh * params.coal_railcar;
            state.seedling_ten_year = totalkwh * params.seedling_ten_year;
            state.forest_hectare = totalkwh * params.forest_hectare;
        },
    },
};

export const BasicFinancialMetrics: IPipelineModule = {
    required: true,
    description: 'Financial Metrics',
    parameters: [
        {
            description: 'Discount Rate',
            path: 'discount',
            type: ParamValueType.Percentage,
            min_value: 0.0,
            max_value: 1.0,
            default: 0.1,
        },
    ],
    module: {
        main: function main(state, params) {
            const { discount } = params;

            const cashFlowEnergy = state.utilityBeforeMonthly
                .clone()
                .subSeries(state.utilityAfterMonthly)
                .reaggregateInterval(TIME_INTERVALS.YEAR);

            const cashFlowEnergyMonthly = state.utilityBeforeMonthly
                .clone()
                .subSeries(state.utilityAfterMonthly)
                .reaggregateInterval(TIME_INTERVALS.MONTH);

            // create raw metrics data for consumption by reports
            state.outSystemYearly = state.systemYearly.toOutputSeries(state.systemInitial);
            state.outIncentiveYearly = state.incentiveYearly.toOutputSeries(state.incentiveInitial);
            state.outFinanceYearly = state.financeYearly.toOutputSeries(state.financeInitial);
            state.outTaxYearly = state.taxYearly.toOutputSeries(state.taxInitial);

            state.outSystemMonthly = state.systemYearly
                .reaggregateInterval(TIME_INTERVALS.MONTH)
                .toOutputSeries(state.systemInitial);
            state.outIncentiveMonthly = state.incentiveYearly
                .reaggregateInterval(TIME_INTERVALS.MONTH)
                .toOutputSeries(state.incentiveInitial);
            state.outFinanceMonthly = state.financeYearly
                .reaggregateInterval(TIME_INTERVALS.MONTH)
                .toOutputSeries(state.financeInitial);
            state.outTaxMonthly = state.taxYearly
                .reaggregateInterval(TIME_INTERVALS.MONTH)
                .toOutputSeries(state.taxInitial);

            const cashFlowRaw = cashFlowEnergy
                .toOutputSeries(0.0)
                .addSeries(state.outSystemYearly)
                .addSeries(state.outIncentiveYearly)
                .addSeries(state.outFinanceYearly)
                .addSeries(state.outTaxYearly)
                .seriesRaw();

            const cashFlowRawMonthly = cashFlowEnergyMonthly
                .toOutputSeries(0.0)
                .addSeries(state.outSystemMonthly)
                .addSeries(state.outIncentiveMonthly)
                .addSeries(state.outFinanceMonthly)
                .addSeries(state.outTaxMonthly)
                .seriesRaw();

            const cumulativeCashFlow: number[] = [];
            let sum = 0.0;

            for (const i of cashFlowRaw) {
                sum += i;
                cumulativeCashFlow.push(sum);
            }

            sum = 0.0;
            const cumulativeCashFlowMonthly: number[] = [];
            for (const i of cashFlowRawMonthly) {
                sum += i;
                cumulativeCashFlowMonthly.push(sum);
            }

            const costFlowRaw = state.systemYearly
                .toOutputSeries(state.systemInitial)
                .multiplyScalar(-1.0)
                .subSeries(state.outIncentiveYearly)
                .subSeries(state.outFinanceYearly)
                .seriesRaw();

            const energyFlowYearly = state.production.reaggregateInterval(TIME_INTERVALS.YEAR);
            const energyFlowRaw = energyFlowYearly.toOutputSeries(0.0).seriesRaw();

            state.productionYearly = state.production.reaggregateInterval(TIME_INTERVALS.YEAR);
            state.consumptionYearly = state.consumption.reaggregateInterval(TIME_INTERVALS.YEAR);
            state.consumptionAfterYearly = state.consumptionAfter.reaggregateInterval(TIME_INTERVALS.YEAR);
            state.utilityBeforeYearly = state.utilityBeforeMonthly.reaggregateInterval(TIME_INTERVALS.YEAR);
            state.utilityAfterYearly = state.utilityAfterMonthly.reaggregateInterval(TIME_INTERVALS.YEAR);

            // Monthly Aggregates of First Year Data
            const extractFirstMonths = (series, months = 12) => {
                if (series.intervalType !== TIME_INTERVALS.MONTH) {
                    throw new Error('modifying a non-monthly series');
                }
                const cpy = series.clone();
                cpy.values.length = months;
                return cpy;
            };

            state.productionMonthSums = bucketByMonth(state.productionOneYear);
            state.consumptionMonthSums = bucketByMonth(state.consumptionOneYear);
            state.utilityBeforeMonthSums = bucketByMonth(extractFirstMonths(state.utilityBeforeMonthly));
            state.utilityAfterMonthSums = bucketByMonth(extractFirstMonths(state.utilityAfterMonthly));
            // end Monthly Summaries

            state.npvDiscount = discount;
            state.outCashFlow = cashFlowRaw;
            state.outCashFlowMonthly = cashFlowRawMonthly;
            state.outCumulativeCashFlow = cumulativeCashFlow;
            state.outCumulativeCashFlowMonthly = cumulativeCashFlowMonthly;
            state.outCostFlow = costFlowRaw;
            state.outEnergyFlow = energyFlowRaw;
        },
        debugOutputs: [
            {
                table: OutputCategory.CashFlow,
                unit: Unit.Currency,
                getParamValue: (state) => state.outCashFlow,
                getParamValueMonthly: (state) => state.outCashFlowMonthly,
            },
            {
                table: OutputCategory.CumulativeCashFlow,
                unit: Unit.Currency,
                getParamValue: (state) => state.outCumulativeCashFlow,
                getParamValueMonthly: (state) => state.outCumulativeCashFlowMonthly,
            },
        ],
    },
};
