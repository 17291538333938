/* tslint:disable:variable-name */

import * as React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { actions as routerActions } from 'redux-router5';

import { Icon } from '@blueprintjs/core';

import { ContextBarControls } from 'reports/components/ContextBar';
import { FinConfigAddDropdownContainer, FinConfigSelectDropdownContainer } from 'reports/components/CustomDropdowns';
import { RedirectHelperContainer } from 'reports/components/helpers/common';

import { Project } from 'reports/models/project';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';

import { selectors as userSelectors } from 'reports/modules/auth';
import { actions as finActions } from 'reports/modules/financials/state';
import { actions as projActions } from 'reports/modules/project';
import Toaster from 'reports/modules/Toaster';
import { RouteComponent } from 'reports/routing';
import { bindActions } from 'reports/utils/redux';

import FinancialConfigurationEditor from './FinancialConfiguration';

interface IFinancialConfigListProps {
    project: Project;
    financialTemplates?: any;
    financialConfigs?: any;

    createFinancialConfig: (template: finTemp.FinancialTemplate) => Promise<any>;
    setPrimaryConfig: (config: projFinTemp.ProjectFinancialTemplate) => Promise<any>;
    navigateTo: (name: string, params: object) => any;
}

class FinancialConfigurationList extends React.Component<IFinancialConfigListProps> {
    render() {
        const { project } = this.props;
        const hasprimary = !!project.primary_project_financial_template;

        return (
            <>
                <RouteComponent name="app.projects.project.financial-configurations">
                    {hasprimary ? (
                        <RedirectHelperContainer
                            name="app.projects.project.financial-configurations.financial-configuration"
                            params={{
                                projectId: project.project_id,
                                finConfigId: project.primary_project_financial_template_id,
                            }}
                        />
                    ) : (
                        this.renderContainerList()
                    )}
                </RouteComponent>
                <RouteComponent name="app.projects.project.financial-configurations.financial-configuration">
                    {this.renderContainerEdit()}
                </RouteComponent>
            </>
        );
    }

    renderContainerList() {
        const warning = (
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    margin: '24px 0px',
                }}
            >
                <div className="sub-content-box-1" style={{ display: 'inline-block', maxWidth: '420px' }}>
                    <div style={{ margin: '8px 0px' }}>
                        <Icon icon="warning-sign" />
                    </div>
                    <div style={{ margin: '8px 0px' }}>Project currently has no primary financial configuration.</div>
                    <div style={{ margin: '8px 0px' }}>
                        Please select an existing configuration or create a new configuration to proceed.
                    </div>
                </div>
            </div>
        );

        const buttons = (
            <div
                style={{
                    flexBasis: 'auto',
                    margin: '16px 0px',
                    textAlign: 'center',
                }}
            >
                <div style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                        <FinConfigAddDropdownContainer onItemSelect={(template) => this.createConfig(template, true)} />
                        <div style={{ width: '4px' }} />
                        <FinConfigSelectDropdownContainer
                            project={this.props.project}
                            onItemSelect={this.props.setPrimaryConfig}
                        />
                    </div>
                </div>
            </div>
        );

        return (
            <div className="sub-container">
                <div className="sub-sidebar" style={{ width: '100%' }}>
                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <div style={{ flexBasis: 'auto' }}>
                            {warning}
                            {buttons}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderContainerEdit() {
        const { project } = this.props;

        return (
            <div className="sub-container">
                <ContextBarControls>
                    <FinConfigAddDropdownContainer onItemSelect={(template) => this.createConfig(template, false)} />
                </ContextBarControls>
                <div className="sub-content-container">
                    <RouteComponent name="app.projects.project.financial-configurations.financial-configuration">
                        {({ config }) => {
                            if (!config) {
                                return (
                                    <RedirectHelperContainer
                                        name="app.projects.project.financial-configurations"
                                        params={{
                                            projectId: project.project_id,
                                        }}
                                        callback={() => {
                                            Toaster.show({
                                                icon: 'warning-sign',
                                                message: <div>Financial configuration does not exist</div>,
                                                timeout: 2500,
                                            });
                                        }}
                                    />
                                );
                            }

                            return <FinancialConfigurationEditor config={config} project={project} />;
                        }}
                    </RouteComponent>
                </div>
            </div>
        );
    }

    async createConfig(template, setPrimary) {
        const config = await this.props.createFinancialConfig(template);
        if (setPrimary) this.props.setPrimaryConfig(config);

        this.props.navigateTo('app.projects.project.financial-configurations.financial-configuration', {
            projectId: this.props.project.project_id,
            finConfigId: config.project_financial_template_id,
        });
    }
}

const financialModel = createSelector(
    (_state, { project }) => {
        return project;
    },
    (project) => {
        if (!project) return {};
        return project.data.financialModel || {};
    },
);

const mapStateToProps = (state, ownProps: { project: Project }) => {
    return {
        formatters: userSelectors.formatters(state),
        financialModel: financialModel(state, ownProps),
        financialTemplates: finTemp.selectors.all(state),
        financialConfigs: projFinTemp.selectors.all(state, {
            filter: (obj) => obj.project_id === ownProps.project.project_id,
        }),
    };
};

const mapDispatchToProps = bindActions(({ project }) => ({
    createFinancialConfig: (template) => finActions.createConfiguration(template, project),
    setPrimaryConfig: (config) => projActions.setPrimaryProjectFinancialTemplate(project, config),
    navigateTo: (name: string, params: object) => routerActions.navigateTo(name, params),
}));

export default connect(mapStateToProps, mapDispatchToProps)(FinancialConfigurationList);
