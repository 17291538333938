import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { SunSpinnerLoader } from '@aurorasolar/ds';

import { ProjectContext, useProject } from 'frontend/projects/use-project';

import { installProjectListeners, removeProjectListeners } from 'reports/modules/project/listeners';

const BaseProject = () => {
    const { project } = useProject();
    const dispatch = useDispatch();

    if (!project) {
        return <SunSpinnerLoader />;
    }

    useEffect(() => {
        installProjectListeners(project.project_id, dispatch);
        return () => removeProjectListeners(project.project_id);
    }, []);

    return <Outlet context={{ project } satisfies ProjectContext} />;
};

export { BaseProject };
