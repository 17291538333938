import { noop } from 'lodash';
import * as React from 'react';

type MapValuesDeep<Obj, ValType> = Partial<{
    [K in keyof Obj]: Obj[K] extends object ? MapValuesDeep<Obj, ValType> : ValType;
}>;

export type IFieldErrors<FormType> = MapValuesDeep<FormType, string[]>;
export type IDirtyFields<FormType> = MapValuesDeep<FormType, boolean>;

export interface IFormContext<FormType, Rtn = FormType> {
    formData: FormType;
    fieldErrors: IFieldErrors<FormType>;
    formErrors: string[];
    dirtyFields: IDirtyFields<FormType>;

    valid: boolean;
    dirty: boolean;
    submitting: boolean;

    submitForm: () => Rtn | Promise<Rtn>;
    clearForm: () => void;

    updateValue: (key: string, value: any, markDirty?: boolean | string | string[]) => void;
    updateValues: (partialObject: object, markDirty?: boolean) => void;
}

const DEFAULT_CONTEXT = {
    formData: {},
    fieldErrors: {},
    formErrors: [],
    valid: true,

    dirty: false,
    dirtyFields: {},

    submitting: false,
    submitForm: noop,
    clearForm: noop,
    updateValue: noop,
    updateValues: noop,
};

export const { Consumer: FormConsumer, Provider: FormProvider } =
    React.createContext<IFormContext<any>>(DEFAULT_CONTEXT);
