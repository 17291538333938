import React from 'react';
import { Classes } from '@blueprintjs/core';
import FormTextInput from 'reports/components/forms/inputs/experimental/FormTextInput';
import styled from 'styled-components';
import classNames from 'classnames';
import { FormField } from 'reports/components/forms';
import { AddressElement } from '@stripe/react-stripe-js';

const SectionContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 8px;
    }

    .${Classes.FORM_GROUP} {
        margin-bottom: 6px;
        justify-content: center;
    }

    .${Classes.INPUT} {
        height: 38px;
        border-radius: 4px;
    }
`;

const SectionTitle = styled.div`
    margin-bottom: 4px;
`;

const StyledTextInput = styled(FormTextInput).attrs({
    inline: true,
    fill: true,
})`
    .${Classes.INPUT} {
        height: 38px;
        border-radius: 4px;
    }
`;

const BillingDetailsFields = ({ user, customer, mapsAPIKey }) => (
    <>
        <SectionContainer>
            <SectionTitle>Billing Email</SectionTitle>
            <StyledTextInput path="email" placeholder="Email Address" type="email" />
        </SectionContainer>
        <SectionContainer>
            <div className={classNames(Classes.FORM_CONTENT)}>
                <FormField path="billingDetails">
                    {({ onChange, form: formContext }) => (
                        <AddressElement
                            options={{
                                autocomplete: {
                                    mode: 'google_maps_api',
                                    apiKey: mapsAPIKey,
                                },
                                defaultValues: {
                                    name: customer?.name || user.company,
                                    address: customer?.address && {
                                        line1: customer.address.line1,
                                        line2: customer.address.line2,
                                        city: customer.address.city,
                                        state: customer.address.state,
                                        postal_code: customer.address.postal_code,
                                        country: customer.address.country!,
                                    },
                                },
                                display: {
                                    name: 'organization',
                                },
                                mode: 'billing',
                                fields: { phone: 'never' },
                            }}
                            onChange={(event) => onChange(event.value)}
                            onFocus={() => onChange(formContext.formData['billingDetails'])}
                        />
                    )}
                </FormField>
            </div>
        </SectionContainer>
        <SectionContainer>
            <SectionTitle>Purchase Order #</SectionTitle>
            <StyledTextInput path="purchaseOrder" placeholder="Purchase Order (optional)" type="text" />
        </SectionContainer>
    </>
);

export { BillingDetailsFields };
