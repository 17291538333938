import { get } from 'lodash';

import { actions as routerActions } from 'redux-router5';

import * as cfg from 'reports/config';

import { actions as navActions, INavError } from 'reports/modules/navigation';
import * as auth from 'reports/modules/auth';

import { addDefaultHeader, HEADERS } from 'reports/modules/request';

const HTTP_CODE_PAYMENT_REQUIRED = 402;

let errorAsUser = null as any;
let errorRetry = null as any;

export function retryRouteWithAsUser(email) {
    if (errorAsUser) {
        errorAsUser(email);
    }
}

export function retryRouteWithOverride() {
    if (errorRetry) {
        addDefaultHeader(HEADERS.overridePermissions, true);
        errorRetry();
    }
}

export function navPlugin() {
    const pluginName = 'hsPlugin';

    const hsPlugin = (_router, dependencies) => {
        const dispatch = (action) => {
            const { store } = dependencies;
            store.dispatch(action);
        };

        return {
            onTransitionStart: (toState, fromState) => {
                dispatch(navActions.setError(null));

                const fromName = fromState?.name || '';
                const toName = toState.name;
                // if going from a library list to its preview (or preview -> list), don't show spinner
                // i.e. app.reports.report.preview -> app.reports
                const hideSpinner =
                    (toName.endsWith('.preview') && toName.includes(fromName)) ||
                    (fromName.endsWith('.preview') && fromName.includes(toName));

                if (!hideSpinner) {
                    dispatch(navActions.setBusy(true));
                }
            },
            onTransitionSuccess: (_toState, _fromState) => {
                dispatch(navActions.setBusy(null));
            },
            onTransitionCancel: (_toState, _fromState) => {
                dispatch(navActions.setBusy(null));
            },
            onTransitionError: (toState, _fromState, err) => {
                dispatch(navActions.setBusy(null));

                if (err.code === 'SAME_STATES') return;

                if (err.code === 'CANNOT_DEACTIVATE') return;

                errorAsUser = (email: string) => {
                    const { store } = dependencies;
                    const user = auth.selectors.getUser(store.getState());
                    if (user) {
                        user._masquerading_admin = true;
                        user._masquerading_admin_email = user.email;
                        user.email = email;
                        dispatch(
                            auth.api.login.done({
                                result: user,
                                params: {},
                            } as any),
                        );
                    }
                    dispatch(routerActions.navigateTo(toState.name, toState.params));
                };

                errorRetry = () => {
                    dispatch(routerActions.navigateTo(toState.name, toState.params));
                };

                const navError: INavError = { status: 500 };

                if (err.code === 'ROUTE_NOT_FOUND') {
                    navError.status = 404;
                }

                if (err.code === 'TRANSITION_ERR' && err.promiseError && err.promiseError.status) {
                    navError.status = err.promiseError.status;
                    navError.body = err.promiseError.response && err.promiseError.response.body;
                }

                if (err.promiseError instanceof auth.permissions.UnauthorizedError) {
                    navError.status = 403;
                }

                if (navError.status === HTTP_CODE_PAYMENT_REQUIRED) {
                    // We don't currently have billing/payments pages in the reports app.
                    // Redirect expired users to old angular app's expired page.

                    const { store } = dependencies;
                    const baseUrl = get(cfg.selectors.getConfig(store.getState()), 'primary_url_root', '');
                    window.location.href = `${baseUrl}/expired`;
                    return;
                }

                dispatch(navActions.setError(navError));
            },
        };
    };

    return Object.assign(hsPlugin, { pluginName });
}

export default navPlugin;
