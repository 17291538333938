/* tslint:disable:variable-name */
import { chain, get, last } from 'lodash';
import * as React from 'react';

import { MaybeElement } from '@blueprintjs/core';

import { IParameterDefinition, IParamProps, ParamConfigType, ParamValueType } from 'reports/modules/financials/params';

import { IBooleanConfig, ParamBoolean } from './ParamBoolean';
import { INumericConfig, ParamNumeric } from './ParamNumeric';
import { IRatesConfig, ParamRatesFull } from './ParamRatesFull';
import { ITableConfig, ParamTable } from './ParamTable';

import {
    authenticateModule,
    getEntryFromRegistry,
    MODULE_CATEGORIES,
    ModuleCategory,
} from 'reports/modules/financials/model/modules';
import {
    AuthErrorCode,
    FinancialPipelineTree,
    Node,
    NodeType,
    parentChildIndex,
    PipelineMetaParam,
} from 'reports/modules/financials/model/pipeline';
import { IIncentivesConfig, IncentiveTierTable } from 'reports/modules/financials/components/IncentiveTierTable';

export function complexEditor(param: ParamConfigType) {
    const { type } = param;

    if (
        type === ParamValueType.Table ||
        type === ParamValueType.RatesFull ||
        type === ParamValueType.IncentiveTierTable
    ) {
        return true;
    }
    return false;
}

type ParamInputProps<T extends IParameterDefinition> = Omit<IParamProps<T>, 'parameter'>;

export const Param: React.SFC<IParamProps<ParamConfigType>> = ({ parameter, ...props }) => {
    // We can just check the 'type' property to determine the appropriate component
    // because it's the discriminant property on all parameters, see IParameterDefinition.
    if (
        parameter.type === ParamValueType.Currency ||
        parameter.type === ParamValueType.Percentage ||
        parameter.type === ParamValueType.Integer ||
        parameter.type === ParamValueType.Float
    ) {
        const numericProps = props as ParamInputProps<INumericConfig>;
        return <ParamNumeric parameter={parameter} {...numericProps} />;
    }

    if (parameter.type === ParamValueType.Boolean) {
        const booleanProps = props as ParamInputProps<IBooleanConfig>;
        return <ParamBoolean parameter={parameter} {...booleanProps} />;
    }

    if (parameter.type === ParamValueType.Table) {
        const tableProps = props as ParamInputProps<ITableConfig>;
        return <ParamTable parameter={parameter} {...tableProps} />;
    }

    if (parameter.type === ParamValueType.RatesFull) {
        const ratesProps = props as ParamInputProps<IRatesConfig>;
        return <ParamRatesFull parameter={parameter} {...ratesProps} />;
    }

    if (parameter.type === ParamValueType.IncentiveTierTable) {
        const incentivesProps = props as ParamInputProps<IIncentivesConfig>;
        return <IncentiveTierTable parameter={parameter} {...incentivesProps} />;
    }

    return null;
};

interface IPipelineNode {
    metaParam: PipelineMetaParam;
    nindex: number[];
    tree: FinancialPipelineTree;
    updateTree: (updated: FinancialPipelineTree) => any;
}

export const PipelineNode: React.SFC<IPipelineNode> = ({ metaParam, tree, updateTree, nindex }) => {
    const { configurable, value, ...param } = metaParam;

    return (
        <Param
            parameter={param}
            value={value}
            updateFn={(value) => {
                const updated = tree.updateNode(nindex, `parameter_settings.${param.path}.value`, value);
                updateTree(updated);
            }}
            disabled={!configurable}
        />
    );
};

interface IPipelineEditor {
    tree: FinancialPipelineTree;
    renderLeaf: (node: Node, index: number[]) => MaybeElement;
    renderLeafError?: (authError: AuthErrorCode, node: Node, index: number[]) => MaybeElement;
    renderRoot: (node: Node, children: MaybeElement[]) => MaybeElement;
    renderCategory?: ((category: ModuleCategory, children: MaybeElement[], index: number[]) => MaybeElement) | null;
    renderError?: (error?: any) => MaybeElement;
}

export const PipelineEditor: React.SFC<IPipelineEditor> = ({
    tree,
    renderLeaf,
    renderLeafError,
    renderRoot,
    renderError,
    renderCategory,
}) => {
    const treeRenderData = {};
    let rootRender: MaybeElement = null;

    const indexChild = (index: number[]) => {
        const { parentIndex, childIndex } = parentChildIndex(index);
        const key = parentIndex.toString();
        if (!treeRenderData[key]) treeRenderData[key] = [];
        return { array: treeRenderData[key], idx: childIndex };
    };

    const getChildren = (index: number[]) => {
        const rootRenderData = treeRenderData[index.toString()];

        if (renderCategory) {
            const categorizedRenderData = chain(rootRenderData)
                .groupBy('category')
                .mapValues((categoryNodeData, _) => categoryNodeData.map((nodeData) => nodeData.rendered))
                .value();

            const categoryIndices = chain(rootRenderData)
                .groupBy('category')
                .mapValues((categoryNodeData, _) => last(categoryNodeData.map((nodeData) => nodeData.index)))
                .value();

            let lastIndex = [0]; // last node index preceding category
            return MODULE_CATEGORIES.map((category) => {
                const categoryData = get(categorizedRenderData, category, []);
                const categoryIndex = lastIndex;
                lastIndex = get(categoryIndices, category, lastIndex);

                return <div key={category}>{renderCategory(category, categoryData, categoryIndex)}</div>;
            });
        }

        return rootRenderData.map((nodeRenderData) => nodeRenderData.rendered);
    };

    const postFn = (node: Node, index: number[]) => {
        if (node.node_type === NodeType.Root) {
            const children = getChildren(index);
            rootRender = renderRoot(node, children);
            return;
        }

        if (node.node_type === NodeType.Basic) {
            const { array, idx } = indexChild(index);
            const authorization = authenticateModule(node.module);
            if (idx != null) {
                array[idx] = {
                    index,
                    category: get(getEntryFromRegistry(node.module_key), 'categoryName', ''),
                    rendered:
                        authorization === AuthErrorCode.AUTHORIZED ? (
                            renderLeaf(node, index)
                        ) : renderLeafError ? (
                            renderLeafError(authorization, node, index)
                        ) : (
                            <></>
                        ),
                };
            }
            return;
        }

        // add handlers for intermediate nodes when needed
        throw new Error('invalid pipeline node');
    };

    if (renderError) {
        try {
            tree.traverseNodes(null, postFn);
        } catch (e) {
            return renderError(e) || null;
        }
    } else {
        tree.traverseNodes(null, postFn);
    }

    return rootRender;
};
