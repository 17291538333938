import * as React from 'react';

import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';

import { PAPER_TYPES, PaperType, Document } from 'reports/models/report';

interface IOwnProps {
    document: Document;
    onUpdate: (newDoc: Document) => void;
}

class ReportPageSettingControls extends React.PureComponent<IOwnProps> {
    render() {
        const { document } = this.props;

        const { paperType, isLandscape } = document;
        const currPaperType = PAPER_TYPES[paperType];

        return (
            <Flex.Container alignV={Flex.AlignV.CENTER}>
                <Icon icon={IconNames.ROTATE_DOCUMENT} className="label-icon" />
                <StaticSelect<PaperType>
                    items={Object.keys(PAPER_TYPES) as PaperType[]}
                    itemRenderer={(id, { handleClick, modifiers }) => (
                        <DropdownMenuItem key={id} title={PAPER_TYPES[id].name} onClick={handleClick} {...modifiers} />
                    )}
                    onItemSelect={(id) => this.setPaperType(id)}
                    buttonText={currPaperType.name}
                />
                <StaticSelect<boolean>
                    items={[true, false]}
                    itemRenderer={(itemIsLandscape, { handleClick, modifiers }) => (
                        <DropdownMenuItem
                            key={itemIsLandscape.toString()}
                            title={itemIsLandscape ? 'Landscape' : 'Portrait'}
                            onClick={handleClick}
                            {...modifiers}
                            active={isLandscape === itemIsLandscape}
                        />
                    )}
                    onItemSelect={(itemIsLandscape) => this.setIsLandscape(itemIsLandscape)}
                    buttonText={isLandscape ? 'Landscape' : 'Portrait'}
                />
            </Flex.Container>
        );
    }

    setIsLandscape = (isLandscape: boolean) => this.props.onUpdate(this.props.document.patch({ isLandscape }));
    setPaperType = (paperType: PaperType) => this.props.onUpdate(this.props.document.patch({ paperType }));
}

export default ReportPageSettingControls;
