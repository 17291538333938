/* Structured dropdown menu item component with optional subtitle and item action button.
 *
 * Example usage,
 *    const renderSelectItem: ItemRenderer<T> = (item, { handleClick, modifiers }) => (
 *        <DropdownMenuItem
 *            title={item.title}
 *            subtitle={this.state.input}
 *            rightButton={<CopyButton/>}
 *            [disableActive]={false}
 *            {...modifiers}
 *        />
 *    );
 */
import * as React from 'react';
import classNames from 'classnames';

import { Classes, Colors, IMenuItemProps, MenuItem } from '@blueprintjs/core';
import { IItemModifiers } from '@blueprintjs/select';

import Flex from 'reports/components/core/containers/Flex';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface IOwnProps {
    title: string | React.ReactNode;
    rightButton?: React.ReactNode | null;
    subtitle?: string | React.ReactNode;

    // Ignore click handler if item is currently selected, workaround for when activeItem !== items[activeItemIdx]
    // Should be replaced with `itemsEqual` prop when we update BlueprintJS Core and Select libs to latest v3.X.X
    selected?: boolean;
}

type IDropdownMenuItem = IOwnProps & Omit<IMenuItemProps, 'text'> & IItemModifiers;

const MenuItemText = styled.div.attrs({
    className: Classes.TEXT_OVERFLOW_ELLIPSIS,
})``;

const MenuItemSubtitle = styled(MenuItemText)`
    font-size: 90%;
    color: ${Colors.GRAY1};
`;

const MenuItemContainer = styled(MenuItem)`
    max-width: 500px;
    padding: 7px;

    &.${Classes.ACTIVE} ${MenuItemSubtitle} {
        color: ${Colors.LIGHT_GRAY5};
    }
    .right-button {
        padding-left: 10px;
    }
`;

const DropdownMenuItem: React.SFC<IDropdownMenuItem> = ({
    title,
    subtitle,
    rightButton,
    onClick,
    matchesPredicate,
    selected,
    className,
    ...props
}) => {
    if (!matchesPredicate) {
        return null;
    }

    const textEl = (
        <Flex.Container className="center">
            <Flex.Main>
                <MenuItemText>{title}</MenuItemText>
                <MenuItemSubtitle>{subtitle}</MenuItemSubtitle>
            </Flex.Main>
            {rightButton ? <Flex.ContainerV className="center right-button">{rightButton}</Flex.ContainerV> : null}
        </Flex.Container>
    );

    return (
        <MenuItemContainer
            text={textEl}
            onClick={selected ? undefined : onClick}
            className={classNames({ [Classes.DARK]: props.active }, className)}
            {...props}
        />
    );
};

export default DropdownMenuItem;
