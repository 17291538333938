import * as React from 'react';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { PrimaryIntent } from 'reports/components/core/controls';
import { promptModalBoolean } from 'reports/components/dialog';

export const cancelSubscriptionStr =
    'This will cancel the subscription and expire all users on the account at the end of the billing period';

export const promptCancel = async ({ subscription, cancelAtPeriodEnd }) => {
    const periodEndStr = subscription.current_period_end.format('LL');
    const shouldSave = await promptModalBoolean({
        title: 'Cancel Subscription?',
        prompt: (
            <>
                {cancelSubscriptionStr} ({periodEndStr}). The user’s subscription will not auto-renew.
            </>
        ),
        cancellable: true,
        cancelLabel: 'Not now',
        yesLabel: 'Cancel subscription',
        yesIntent: PrimaryIntent.DELETE,
    });

    if (shouldSave) {
        await addPromiseToasts(cancelAtPeriodEnd(subscription), {
            initial: 'Scheduling cancellation...',
            onSuccess: 'Successfully scheduled cancellation.',
            onCatch: (error) => `Error scheduling cancellation: ${error.response?.body.error}.`,
        });
    }
};
