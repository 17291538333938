import * as React from 'react';

import { Classes, IconName, TabId, Tabs, Tab } from '@blueprintjs/core';

import { Flex } from 'reports/components/core/containers';
import { LibraryList as List } from 'reports/components/core/layout';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const TabsContainer = styled(Flex.ContainerV)`
    width: 100%;
    height: 100%;

    padding-bottom: 6px;

    .${Classes.TABS} {
        width: 100%;
        height: 100%;

        .${Classes.TAB} {
            width: 100%;
            height: 100%;
        }

        .${Classes.TAB_PANEL} {
            width: 100%;
            height: 100%;

            margin-top: 0;
            padding-top: 0;
        }
    }
`;

const SearchState = styled(Flex.Main)`
    margin: 24px 0;
    text-align: center;
    font-style: italic;
`;

const FullTab = styled.div`
    width: 100%;
    height: 100%;
`;

interface IViewTabsProps {
    views: { view: JSX.Element; icon: IconName; id: TabId }[];
    id: TabId;
    searching?: boolean;
}

interface IState {
    selectedTab: TabId | null;
}

export class ViewTabs extends React.Component<IViewTabsProps, IState> {
    state = {
        selectedTab: this.props.views.length > 0 ? this.props.views[0].id : null,
    };

    render() {
        const { views, id, searching } = this.props;
        const { selectedTab } = this.state;

        return (
            selectedTab != null && (
                <TabsContainer>
                    {views.length > 1 && (
                        <List.ViewModeButtons>
                            {views.map(({ icon, id }, idx) => (
                                <List.ViewModeButton
                                    key={idx}
                                    icon={icon}
                                    active={selectedTab === id}
                                    onClick={() => this.setState({ selectedTab: id })}
                                />
                            ))}
                        </List.ViewModeButtons>
                    )}
                    <Tabs id={id} selectedTabId={selectedTab}>
                        {views.map(({ view: View, id }, idx) => (
                            <Tab
                                key={idx}
                                id={id}
                                panel={searching ? <SearchState>searching...</SearchState> : <FullTab>{View}</FullTab>}
                            />
                        ))}
                    </Tabs>
                </TabsContainer>
            )
        );
    }
}

export default ViewTabs;
