import * as React from 'react';

import { IconNames } from '@blueprintjs/icons';

import { PrimaryIntent } from './PrimaryButton';
import IconButton, { IIconButton } from './IconButton';

const FavoriteStar: React.FC<IIconButton & { empty?: boolean }> = ({ empty, ...iconProps }) => (
    <IconButton
        intent={PrimaryIntent.FAVORITE}
        icon={empty ? IconNames.STAR_EMPTY : IconNames.STAR}
        // TODO: This is is hack to fix the `margin-left: 8px` that this shouldn't be inheriting from
        // core/controls/Button. This should be fixed properly in a full refactor of *Button components.
        style={{ marginLeft: 0 }}
        {...iconProps}
    />
);

export default FavoriteStar;
