import LinkButton from 'reports/utils/router/LinkButton';
// tslint:disable-next-line:no-duplicate-imports
import type { ILinkButtonProps, ILinkButtonTrackingOpts } from 'reports/utils/router/LinkButton';

import { BUTTON_MARGIN_LEFT } from './Button';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export { ILinkButtonProps, ILinkButtonTrackingOpts };

export default styled(LinkButton)`
    margin-left: ${BUTTON_MARGIN_LEFT};

    // Remove default link styles
    &:active,
    &:focus,
    &:hover,
    &:visited {
        text-decoration: none;
    }
` as React.ComponentClass<ILinkButtonProps>;
