import * as React from 'react';
import * as analytics from 'reports/analytics';
import * as events from 'reports/analytics/eventSchemas';

import { AnchorButton, IAnchorButtonProps } from '@blueprintjs/core';
import { useRoute } from 'react-router5';
import { URLSearchParamsInit, useLocation, useNavigate } from 'react-router-dom';

import { createRoutePath } from 'reports/routing/common';

type TargetValue = '_blank';
export type ILinkButtonTrackingOpts = {
    name: string;
    options: events.IEventProps;
};

export interface ILinkButtonProps extends IAnchorButtonProps {
    routeName: string;
    routeParams?: { [x: string]: any };
    // Currently we use routeParams for routeParams like :projectId and for searchParams like ?dialog=initial.
    // Eventually, we will use searchParams to properly represent search parameters. That conversion will happen
    // when we port components like UpsellDialog that should be using searchParams instead of routeParams.
    searchParams?: URLSearchParamsInit;
    style?: React.CSSProperties;
    target?: TargetValue;
    theme?: string;
    tracking?: ILinkButtonTrackingOpts;
}

const LinkButton = ({
    routeName,
    routeParams = {},
    searchParams = {},
    loading,
    theme,
    tracking,
    onClick,
    ...buttonProps
}: ILinkButtonProps) => {
    const [navigating, setNavigating] = React.useState(false);
    const onNavigate = (evt) => {
        onClick && onClick(evt);

        if (evt.metaKey || evt.ctrlKey || evt.shiftKey || buttonProps.target === '_blank') {
            // early exit to enable users to modifier-click and open new tabs
            return;
        }

        evt.preventDefault();

        if (tracking) {
            analytics.track(tracking.name, tracking.options);
        }
    };

    const style =
        theme === 'black'
            ? {
                  ...buttonProps.style,
                  backgroundColor: 'black',
                  backgroundImage: 'none',
                  color: 'white',
                  fontWeight: 'normal',
                  textDecoration: 'none',
              }
            : buttonProps.style;

    // Attempt to navigate using the new router. If it fails, fallback to the old router.
    try {
        const navigate = useNavigate();
        const location = useLocation();
        const route = createRoutePath(routeName, routeParams, location.search, searchParams);
        const onClick = (evt) => {
            onNavigate(evt);
            setNavigating(true);
            navigate(route);
            setNavigating(false);
        };
        return <AnchorButton {...buttonProps} onClick={onClick} loading={loading || navigating} style={style} />;
    } catch (error) {
        const { router } = useRoute();
        const href = router.buildUrl(routeName, routeParams);
        const onClick = (evt) => {
            onNavigate(evt);
            setNavigating(true);
            router.navigate(routeName, { ...routeParams, ...(searchParams as object) }, {}, () => setNavigating(false));
        };
        return (
            <AnchorButton
                {...buttonProps}
                onClick={onClick}
                href={href}
                loading={loading || navigating}
                style={style}
            />
        );
    }
};

export default LinkButton;
