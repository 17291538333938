import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Logger from 'js-logger';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import * as analytics from 'reports/analytics';
import { CreateButton, EditButton } from 'reports/components/core/controls';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';
import * as ws from 'reports/models/weather_source';
import BatchUploadDialog from 'reports/modules/files/batch_upload/BatchUploadDialog';
import { DetailsCell } from 'reports/modules/files/batch_upload/BatchUploadTable';
import AppToaster from 'reports/modules/Toaster';
import { bindActions } from 'reports/utils/redux';
import { BYTES_PER_MEGABYTE } from 'reports/utils/units';
import { IUploadItem } from 'reports/modules/files/batch_upload/common';
import WeatherSourceUploadEditDialog from './WeatherSourceUploadEditDialog';

const logger = Logger.get('weather_upload');

const MAX_SIZE = 10 * BYTES_PER_MEGABYTE;
const ACCEPTED_FILE_TYPES = {
    csv: 'TMY3 (.csv)',
    tm3: 'TMY3 (.tm3)',
    tm2: 'TMY2 (.tm2)',
    epw: 'EnergyPlus Worldwide (.epw)',
    'csv-prospector': 'Prospector (.csv)',
    gz: 'Compressed Prospector (.csv.gz)',
    zip: 'EPW, Embedded in Zip (.zip)',
};

const FileTypeSelectContainer = styled.div`
    width: 150px;
    margin: 0 auto;
`;

const Description: React.FC = () => (
    <div>
        <p>Upload any weather data you have to your account.</p>
    </div>
);

export interface IWeatherSourceUploadItem extends IUploadItem {
    fileType: string;
    uploadResult?: ws.WeatherSource;
}

interface IWeatherSourceUploadItemMap {
    [key: string]: IWeatherSourceUploadItem;
}

interface IFileCellProps {
    item: IWeatherSourceUploadItem;
    changeFileType: (item: IWeatherSourceUploadItem, fileType: string) => void;
}
const FileCell: React.FC<IFileCellProps> = ({ item, changeFileType }) => {
    const { fileType } = item;
    return (
        <td>
            <strong>File Format</strong>
            <br />
            <FileTypeSelectContainer>
                <BasicSelect<string>
                    dataSource={{ items: Object.keys(ACCEPTED_FILE_TYPES) }}
                    itemRenderer={(fileType: string) => ({
                        key: fileType,
                        text: ACCEPTED_FILE_TYPES[fileType],
                    })}
                    value={fileType || null}
                    onChange={(fileType) => changeFileType(item, fileType)}
                    fill
                />
            </FileTypeSelectContainer>
        </td>
    );
};

const WeatherSourceCustomCell: React.FC<{
    item: IWeatherSourceUploadItem;
    changeFileType: (item, fileType) => void;
}> = ({ item, changeFileType }) => {
    if (item.uploadResult) {
        return (
            <DetailsCell
                obj={item.uploadResult}
                detailsFields={[
                    {
                        name: 'Name',
                        path: 'name',
                    },
                    {
                        name: 'Location',
                        path: 'locationString',
                    },
                    {
                        name: 'Source',
                        path: 'source',
                    },
                ]}
            />
        );
    }
    return <FileCell item={item} changeFileType={changeFileType} />;
};

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
interface IOwnProps {
    addWeatherSources: (sources: ws.WeatherSource[]) => void;
}
type IProps = IDispatchProps & IOwnProps;

interface IState {
    items: IWeatherSourceUploadItemMap;
    showUploadDialog: boolean;
    editingItem?: IWeatherSourceUploadItem;
}

function initialState(): IState {
    return { items: {}, showUploadDialog: false };
}

class WeatherSourceUploadLauncher extends React.PureComponent<IProps, IState> {
    state: IState = initialState();

    render() {
        const { items } = this.state;
        return (
            <>
                <WeatherSourceUploadEditDialog
                    editingItem={this.state.editingItem}
                    onClose={() => this.setState({ editingItem: undefined })}
                    changeWeatherSrc={this.changeWeatherSrc}
                />
                <CreateButton
                    icon={IconNames.UPLOAD}
                    text="Upload Weather Source"
                    onClick={() => this.openUploadDialog()}
                    fill={true}
                />
                <BatchUploadDialog<IWeatherSourceUploadItem>
                    title="Upload Weather Dataset"
                    acceptTypes=".csv, .tm3, .tm2, .zip, .epw, .csv.gz, .CSV, .TM3, .TM2, .ZIP, .EPW, .CSV.GZ"
                    maxSize={MAX_SIZE}
                    description={<Description />}
                    isOpen={
                        this.state.showUploadDialog && (!this.state.editingItem || !this.state.editingItem.uploadResult)
                    }
                    onClose={() => this.closeDialog()}
                    items={Object.values(items)}
                    onSelectFiles={this.onSelectFiles}
                    uploadItem={this.uploadItem}
                    tableProps={{
                        helperText: "Use 'Select Files' or drag and drop here to add Weather files to your account",
                        errorMsg: 'Error parsing or uploading file',
                        removeItem: this.removeItem,
                        customTableColumns: [
                            {
                                title: 'Details',
                                cell: (item) => (
                                    <WeatherSourceCustomCell item={item} changeFileType={this.changeFileType} />
                                ),
                            },
                        ],
                        postUploadActions: (item) => (
                            <EditButton
                                text="Edit"
                                onClick={() =>
                                    this.setState({
                                        editingItem: item,
                                    })
                                }
                            />
                        ),
                    }}
                />
            </>
        );
    }

    closeDialog = () => {
        // Add newly uploaded sources to the library before closing the dialog
        const sourcesToAdd = Object.values(this.state.items)
            .filter((item) => !!item.uploadResult)
            .map((item) => item.uploadResult!);
        this.props.addWeatherSources(sourcesToAdd);
        this.setState({ showUploadDialog: false });
    };

    changeWeatherSrc = (item: IWeatherSourceUploadItem, weatherSrc: ws.WeatherSource) => {
        const newItems = { ...this.state.items };
        newItems[item.file.name].uploadResult = weatherSrc;
        this.setState({ items: newItems, editingItem: item });
    };

    changeFileType = (item: IWeatherSourceUploadItem, fileType: string) => {
        const newItems = { ...this.state.items };
        newItems[item.file.name].fileType = fileType;
        this.setState({ items: newItems });
    };

    openUploadDialog() {
        this.setState({ ...initialState(), showUploadDialog: true });
    }

    removeItem = (item: IWeatherSourceUploadItem) => {
        const { items } = this.state;
        const newModuleUploadItemsMap = { ...items };
        delete newModuleUploadItemsMap[item.file.name];
        this.setState({ items: newModuleUploadItemsMap });
    };

    onSelectFiles = async (files: File[]) => {
        let newItems = { ...this.state.items };
        for (const panFile of files) {
            newItems[panFile.name] = {
                fileType: panFile.name !== undefined ? panFile.name.split('.').pop() ?? 'csv' : 'csv',
                file: panFile,
                status: 'staged',
            };
        }
        newItems = { ...newItems };
        this.setState({ items: newItems });
    };

    uploadItem = async (item: IWeatherSourceUploadItem) => {
        const { items } = this.state;
        let newItemsMap = { ...items };
        newItemsMap[item.file.name].status = 'uploading';
        this.setState({ items: newItemsMap });

        const toast = AppToaster.show({
            message: `Uploading ${item.file.name}`,
        });

        let result;
        try {
            result = await this.props.uploadWeatherSource({
                file: item.file,
                filetype: item.fileType,
            });
        } catch (e) {
            newItemsMap = { ...items };
            newItemsMap[item.file.name].status = 'error';
            this.setState({ items: newItemsMap });

            const errorMsg = `Error saving ${item.file.name}`;
            AppToaster.show(
                {
                    message: errorMsg,
                    intent: Intent.DANGER,
                },
                toast,
            );

            logger.warn(errorMsg);
            logger.warn(e);

            return;
        }

        newItemsMap = { ...items };
        newItemsMap[item.file.name].uploadResult = result;
        newItemsMap[item.file.name].status = 'uploaded';
        this.setState({ items: newItemsMap });

        analytics.track('weather.upload', {
            weather_source_id: result.weather_source_id,
        });

        AppToaster.show(
            {
                message: `Successfully uploaded weather source: ${item.file.name}`,
                intent: Intent.SUCCESS,
            },
            toast,
        );
    };
}

const mapDispatchToProps = bindActions(() => ({
    uploadWeatherSource: ws.api.upload,
}));

export default connect(null, mapDispatchToProps)(WeatherSourceUploadLauncher);
