import { map, mapValues, identity } from 'lodash';
import * as React from 'react';

import { Icon, Colors } from '@blueprintjs/core';

import { DragSource, DragSourceSpec, DragSourceConnector, ClientOffset, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

import { disableDropzone, enableDropzone } from 'reports/modules/files/components/dropzone_helpers';

import { ILayout, ILayoutCallback, HandlePosition, IDragItem, ILayoutContext, ILayoutItemMeta, IPoint } from './types';
import { getLayoutDeltaFromMonitor } from './DragPreviewLayer';

const HANDLE_SIZE = 9;

interface Offset {
    x: number;
    y: number;
}

const POSITION_OFFSETS: { [k in HandlePosition]: Offset } = {
    TopLeft: { x: 0.0, y: 0.0 },
    TopMiddle: { x: 0.5, y: 0.0 },
    TopRight: { x: 1.0, y: 0.0 },
    MiddleLeft: { x: 0.0, y: 0.5 },
    MiddleRight: { x: 1.0, y: 0.5 },
    BottomLeft: { x: 0.0, y: 1.0 },
    BottomMiddle: { x: 0.5, y: 1.0 },
    BottomRight: { x: 1.0, y: 1.0 },
};

export const FullDiv = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const ResizeFullSpan = styled(FullDiv)`
    z-index: 99;
    border: solid 1px ${Colors.GRAY3};
    pointer-events: none;
    * {
        pointer-events: auto;
    }
`;

const GrabIcon = styled(Icon).attrs({
    iconSize: HANDLE_SIZE,
    icon: 'square',
})`
    position: absolute;
    background-color: white;
    color: ${Colors.GRAY3};
`;

const cssPercent = (val) => `${100 * val}%`;
const halfHandlePx = `${HANDLE_SIZE / 2}px`;

const HANDLE_ICONS = mapValues(
    POSITION_OFFSETS,
    (offset) =>
        styled(GrabIcon)`
            top: calc(${cssPercent(offset.y)} - ${halfHandlePx});
            left: calc(${cssPercent(offset.x)} - ${halfHandlePx});
        `,
);

const layoutToTuple = ({ x, y, w, h, z }: ILayout) => ({
    z,
    x1: x,
    y1: y,
    x2: x + w,
    y2: y + h,
});
const tupleToOrientedLayout = ({ x1, y1, x2, y2, z }): ILayout => ({
    z,
    x: Math.min(x1, x2),
    y: Math.min(y1, y2),
    w: Math.abs(x2 - x1),
    h: Math.abs(y2 - y1),
});

const addDelta = (tuple, delta) => {
    return {
        x1: tuple.x1 + (delta.x1 || 0),
        y1: tuple.y1 + (delta.y1 || 0),
        x2: tuple.x2 + (delta.x2 || 0),
        y2: tuple.y2 + (delta.y2 || 0),
        z: tuple.z,
    };
};

const DragOperations: { [k in HandlePosition]: any } = {
    TopLeft: (offset) => ({ x1: offset.x, y1: offset.y }),
    TopMiddle: (offset) => ({ y1: offset.y }),
    TopRight: (offset) => ({ x2: offset.x, y1: offset.y }),
    MiddleLeft: (offset) => ({ x1: offset.x }),
    MiddleRight: (offset) => ({ x2: offset.x }),
    BottomLeft: (offset) => ({ x1: offset.x, y2: offset.y }),
    BottomMiddle: (offset) => ({ y2: offset.y }),
    BottomRight: (offset) => ({ x2: offset.x, y2: offset.y }),
};

const ResizeOffsetTransform: {
    [k in HandlePosition]: (IPoint) => Partial<IPoint>;
} = {
    TopLeft: identity,
    TopMiddle: (offset) => ({ y: offset.y }),
    TopRight: identity,
    MiddleLeft: (offset) => ({ x: offset.x }),
    MiddleRight: (offset) => ({ x: offset.x }),
    BottomLeft: identity,
    BottomMiddle: (offset) => ({ y: offset.y }),
    BottomRight: identity,
};

const GetAnchorPoint = mapValues(POSITION_OFFSETS, (offset) => (layout: ILayout) => ({
    x: layout.x + offset.x * layout.w,
    y: layout.y + offset.y * layout.h,
}));

export function getResizedLayout(position: HandlePosition, item: IDragItem, offset: ClientOffset) {
    const { layout } = item;

    const matchResult = item.snapIndex.matchResize(
        GetAnchorPoint[position](layout),
        ResizeOffsetTransform[position](offset),
        layout,
    );

    const tuple = layoutToTuple(layout);
    const delta = DragOperations[position](matchResult.result);

    return {
        matchResult,
        layout: tupleToOrientedLayout(addDelta(tuple, delta)),
    };
}

const dragSpec: DragSourceSpec<IResizeHandleProps> = {
    beginDrag({ itemMeta, layout, scale, snapIndex }) {
        disableDropzone();
        return {
            itemMeta,
            layout,
            scale,
            snapIndex,

            previewHTML: '',
        };
    },

    endDrag({ onChange }, monitor) {
        enableDropzone();
        if (monitor == null) return;

        const dragItem: IDragItem = monitor.getItem() as any;

        onChange({
            ...dragItem,
            layout: getResizedLayout(
                monitor.getItemType() as HandlePosition,
                dragItem,
                getLayoutDeltaFromMonitor(monitor),
            ).layout,
        });
    },
};

const collect = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
});

interface IResizeProps extends ILayoutContext {
    itemMeta: ILayoutItemMeta;
    layout: ILayout;
    scale: number;
    onChange: ILayoutCallback;
}

interface IResizeHandleProps extends IResizeProps {
    setResizing(isResising: boolean): void;
    isResizing: boolean;
}

const DRAGGABLE_HANDLES = mapValues(HANDLE_ICONS, (HandleIcon: React.ComponentClass<any>, position) => {
    class DragHandle extends React.PureComponent<IResizeHandleProps & ReturnType<typeof collect>> {
        componentDidMount() {
            this.props.connectDragPreview(getEmptyImage(), {
                captureDraggingState: true,
            });
        }

        componentDidUpdate(lastProps) {
            if (lastProps.isDragging !== this.props.isDragging) {
                this.props.setResizing(this.props.isDragging);
            }
        }

        render() {
            const { scale, connectDragSource, isResizing } = this.props;
            return connectDragSource(
                <span style={isResizing ? { opacity: 0 } : undefined}>
                    <HandleIcon style={{ transform: `scale(${1 / scale})` }} />
                </span>,
            );
        }
    }

    return DragSource(position, dragSpec, collect)(DragHandle);
});

export class ResizeHandles extends React.PureComponent<IResizeProps> {
    state = { isResizing: false };
    setResizing = (isResizing: boolean) => this.setState({ isResizing });

    render() {
        const { isResizing } = this.state;
        const props = this.props;

        return (
            <ResizeFullSpan>
                {map(DRAGGABLE_HANDLES, (Handle, position) => (
                    // Typescript will complain that Handle cannot be used like this without a constructor.
                    // @ts-ignore
                    <Handle key={position} {...props} setResizing={this.setResizing} isResizing={isResizing} />
                ))}
            </ResizeFullSpan>
        );
    }
}

export default ResizeHandles;
