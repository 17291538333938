import * as React from 'react';
import { Button, Colors, H5, Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import styled from 'styled-components';

import { Flex } from 'reports/components/core/containers';
import * as us from 'reports/models/user';
import * as ws from 'reports/models/weather_source';
import { SatelliteSourceType } from './WeatherSourceMap';
import WeatherSourcesLearnMoreDialog from './WeatherSourcesLearnMoreDialog';

const FiltersContainer = styled(Flex.ContainerV)`
    min-width: 225px;
    width: 225px;
    margin-right: 20px;
`;

const FilterGroup = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 24px;
    }
`;

const FilterSubGroup = styled.div`
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
`;

const FilterHeader = styled(H5)`
    color: ${Colors.DARK_GRAY3};
`;

const FilterButton = styled(Button)`
    margin: 2px 0;
`;

const LearnMoreContainer = styled(Flex.Container)`
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    margin: 8px 0 4px;
    color: ${Colors.DARK_GRAY3};
`;

export const GROUND_WEATHER_SOURCE_TYPES = {
    tmy3: 'NREL TMY3',
    tmy2: 'NREL TMY2',
    epw: 'DOE EPW',
    swera: 'SWERA',
    custom: 'Custom',
};

export const FiltersPanel: React.FC<{
    groundFilters: string[];
    satelliteFilter?: string;
    ownTeamOnly: boolean;
    toggleSatelliteFilter: (srcType: string) => void;
    toggleGroundFilter: (srcType: string) => void;
    toggleOwnTeamOnly: () => void;
    user: us.User;
    sources: ws.WeatherSource[] | null;
}> = (props) => {
    const [srcCounts, setSrcCounts] = React.useState<{ [st: string]: number }>({});
    const [dialogIsOpen, setDialogIsOpen] = React.useState<boolean>(false);

    // Cache source counts on initial load
    React.useEffect(() => {
        if (!props.sources) {
            return;
        }
        const srcCounts = {};
        Object.keys(GROUND_WEATHER_SOURCE_TYPES).forEach((srcType) => {
            srcCounts[srcType] = props.sources!.filter((x) => x.source_type === srcType).length;
        });
        setSrcCounts(srcCounts);
    }, [props.sources]);

    return (
        <FiltersContainer>
            <FilterGroup>
                <FilterHeader>
                    <Icon icon={IconNames.FILTER_LIST} /> Filter by Source Type
                </FilterHeader>
                <FilterSubGroup>
                    <p>Ground-based Sources</p>
                    <Flex.ContainerV>
                        {Object.keys(GROUND_WEATHER_SOURCE_TYPES).map((srcType, index) => {
                            const isSelected = props.groundFilters.includes(srcType);
                            return (
                                <FilterButton
                                    text={`${GROUND_WEATHER_SOURCE_TYPES[srcType]} ${
                                        srcCounts[srcType] !== undefined ? `(${srcCounts[srcType]})` : ''
                                    }`}
                                    icon={IconNames.FLASH}
                                    intent={isSelected ? Intent.PRIMARY : undefined}
                                    onClick={() => props.toggleGroundFilter(srcType)}
                                    alignText={'left'}
                                    key={index}
                                    fill
                                />
                            );
                        })}
                    </Flex.ContainerV>
                </FilterSubGroup>
                <FilterSubGroup>
                    <p>Satellite Sources</p>
                    <Flex.ContainerV>
                        <FilterButton
                            text="Prospector"
                            icon={IconNames.POLYGON_FILTER}
                            intent={
                                props.satelliteFilter === SatelliteSourceType.Prospector ? Intent.PRIMARY : undefined
                            }
                            onClick={() => props.toggleSatelliteFilter(SatelliteSourceType.Prospector)}
                            alignText={'left'}
                            fill
                        />
                        <FilterButton
                            text="PSM v3"
                            icon={IconNames.POLYGON_FILTER}
                            intent={props.satelliteFilter === SatelliteSourceType.Psm ? Intent.PRIMARY : undefined}
                            onClick={() => props.toggleSatelliteFilter(SatelliteSourceType.Psm)}
                            alignText={'left'}
                            fill
                        />
                    </Flex.ContainerV>
                    <LearnMoreContainer onClick={() => setDialogIsOpen(true)}>
                        <Icon icon={'info-sign'} style={{ width: '16px', height: '16px' }} />
                        <span style={{ marginLeft: '4px' }}>Learn more</span>
                    </LearnMoreContainer>
                    <WeatherSourcesLearnMoreDialog isOpen={dialogIsOpen} onClose={() => setDialogIsOpen(false)} />
                </FilterSubGroup>
            </FilterGroup>
            <FilterGroup>
                <FilterHeader>
                    <Icon icon={IconNames.FILTER_LIST} /> Filter by Team
                </FilterHeader>
                <FilterButton
                    text={`Only Show My Team`}
                    icon={IconNames.PEOPLE}
                    intent={props.ownTeamOnly ? Intent.PRIMARY : undefined}
                    onClick={props.toggleOwnTeamOnly}
                    alignText={'left'}
                    fill
                />
            </FilterGroup>
        </FiltersContainer>
    );
};
