import React from 'react';

import { generatePath, useNavigate } from 'react-router-dom';

import { IncentiveLibrary } from 'reports/modules/incentive/components/IncentiveLibrary';

const Incentives = () => {
    const navigate = useNavigate();

    const navigateToIncentive = (incentiveId: number) =>
        navigate({
            pathname: generatePath('/incentives/:incentiveId', { incentiveId: incentiveId.toString() }),
        });
    return <IncentiveLibrary hideUpsellBanner={true} inlineControls={true} navigateToIncentive={navigateToIncentive} />;
};

export { Incentives };
