import { IconNames } from '@blueprintjs/icons';
import { IntlShape } from 'react-intl';

import Translations from 'reports/localization/strings';

import { IReportContext, IWidgetEditProps, IWidgetRenderProps, registerWidget } from 'reports/modules/report/widgets';

import { EditComponent, ViewComponent } from './components';

export interface ICustomTextContent {
    tokens: string; // raw text w/ autocomplete tokens
    style: Partial<React.CSSProperties>;
}

export type IContext = Pick<IReportContext, 'theme' | 'tokenMap'>;
export type CustomTextProps = IWidgetRenderProps<ICustomTextContent, IContext> & { intl: IntlShape };
export type EditCustomTextProps = IWidgetEditProps<ICustomTextContent, IContext> & { intl: IntlShape };

const CustomTextWidget = registerWidget('report_custom_text', {
    Component: ViewComponent,
    EditingComponent: EditComponent,
    metadata: {
        category: 'ignore',
        dimensions: { h: 200, w: 300 },
        displayName: Translations.widgets.custom_text_header,
        icon: IconNames.FONT,
        ignoreStyle: true,
    },
    dependencies: ['tokenMap'],
});

export default CustomTextWidget;
