import React from 'react';

import { useSelector } from 'react-redux';

import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';

import { UtilityRate as UtilityRateModel, selectors as utilityRateSelectors } from 'reports/models/utility_rate';

import { UtilityRateEditor } from 'reports/modules/utility_rate/components/UtilityRateEditor';
import { IAppState } from 'reports/store';

const UtilityRate = () => {
    const navigate = useNavigate();
    const navigateToUtilityRate = (utilityRateId: number) =>
        navigate({
            pathname: generatePath('/utility-rates/:utilityRateId', { utilityRateId: utilityRateId.toString() }),
        });
    const navigateToUtilityRates = () => navigate({ pathname: '/utility-rates' });

    const utilityRateToSelect = useLoaderData() as UtilityRateModel;
    const utilityRate = useSelector((state) =>
        utilityRateSelectors.byObject(
            state as IAppState,
            { utility_rate_id: utilityRateToSelect.utility_rate_id },
            { creator: true, last_modified_by_user: true },
        ),
    );

    if (!utilityRate) {
        navigateToUtilityRates();
        throw new Response('Not Found', { status: 404 });
    }

    return (
        <UtilityRateEditor
            inlineControls={true}
            navigateToUtilityRate={navigateToUtilityRate}
            navigateToUtilityRates={navigateToUtilityRates}
            utilityRate={utilityRate}
        />
    );
};

export { UtilityRate };
