/* tslint:disable:variable-name */
import * as React from 'react';

import { noop } from 'helioscope/app/utilities/helpers';

import { TableSortConfig } from 'reports/components/core/tables/DataTable';

export interface ILibraryContext<T> {
    items: Promise<T[]>;
    filters: { [key: string]: any };
    setFilter: (name: string, value: any) => void;
    setSearchValue: (q: string) => void;
    setTableSort: (tableSort: TableSortConfig<T>) => void;
    itemSelected: (item: T) => boolean;
    toggleFilter: (name: string, value?: T[keyof T]) => void;
    clearFilter: (name: string) => void;
    updateSearch: () => void;
    searchValue?: string;
    tableSort?: TableSortConfig<T>;
}

const DEFAULT_CONTEXT = {
    items: Promise.resolve([]),
    filters: {},
    setFilter: noop,
    setSearchValue: noop,
    setTableSort: noop,
    itemSelected: () => false,
    toggleFilter: noop,
    clearFilter: noop,
    updateSearch: noop,
    searchValue: '',
    tableSort: undefined,
};

export const { Consumer: LibraryConsumer, Provider: LibraryProvider } =
    React.createContext<ILibraryContext<any>>(DEFAULT_CONTEXT);
