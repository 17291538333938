import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { Text, Form, Field, CheckboxInput, PasswordInput, Button } from '@aurorasolar/ds';

import { TermsOfUseStatement } from './common';
import { SetPasswordFormData } from '..';
import { NewPasswordField } from './NewPasswordField';

export interface SetPasswordFormProps {
    loading: boolean;
    isReset: boolean;
    onSubmit: (formData: SetPasswordFormData, formikHelpers: FormikHelpers<SetPasswordFormData>) => any;
}

const SetPasswordFormFields = ({ isSubmitting, isReset, loading }) => (
    <Form>
        <NewPasswordField name="new_password" />
        <Field
            component={PasswordInput}
            fontWeight="normal"
            label="Confirm new password"
            name="confirm_password"
            placeholder="Re-enter new password"
        />
        {!isReset && (
            <Field
                component={CheckboxInput}
                label={<TermsOfUseStatement />}
                name="terms_of_use"
                disabled={isSubmitting}
            />
        )}
        <Button fullWidth size="md" type="submit" variant="primary" disabled={loading}>
            Create password
        </Button>
    </Form>
);

export const SetPasswordForm = ({ onSubmit, loading, isReset }: SetPasswordFormProps) => (
    <>
        <Text text="body14">
            For security purposes, use a unique password made up of numbers, letters, and special characters.
        </Text>
        <Formik
            initialValues={{ new_password: '', confirm_password: '', terms_of_use: false }}
            onSubmit={(values, formikHelpers: FormikHelpers<SetPasswordFormData>) => {
                onSubmit(values, formikHelpers);
            }}
            validateOnChange={false}
        >
            {({ isSubmitting }) => (
                <SetPasswordFormFields isSubmitting={isSubmitting} isReset={isReset} loading={loading} />
            )}
        </Formik>
    </>
);

export default SetPasswordForm;
