import * as React from 'react';

import { Intent } from '@blueprintjs/core';

import { User } from 'reports/models/user';
import Callout, { BottomMargin } from 'reports/components/core/controls/Callout';

export const ENFORCEMENT_DATES = {
    phase_iv_and_v: 'October 3rd 2023',
    one_year_grace_period: 'October 2024',
};

const ENFORCEMENT_DATE_TEXT = {
    phase_iv_and_v: {
        title: 'Your project limit enforcement is coming up',
        subtitle: `You can continue using projects as you have been until ${ENFORCEMENT_DATES.phase_iv_and_v}`,
    },
    one_year_grace_period: {
        title: 'Your project limit enforcement grace period is starting',
        subtitle: `You can continue using projects as you have been until ${ENFORCEMENT_DATES.one_year_grace_period}`,
    },
};

export type IProjectEnforcementDateCalloutProps = {
    user: User;
};

export const ProjectEnforcementDateCallout = ({ user }: IProjectEnforcementDateCalloutProps) => (
    <>
        {user.team.should_upsell_consumption &&
            !user.team.should_enforce_consumption &&
            user.team.consumption_enforcement_period === 'one_year_grace_period' && (
                <Callout
                    icon="info-sign"
                    intent={Intent.PRIMARY}
                    title={ENFORCEMENT_DATE_TEXT.one_year_grace_period.title}
                    bottomMargin={BottomMargin.STANDARD}
                >
                    {ENFORCEMENT_DATE_TEXT.one_year_grace_period.subtitle}
                </Callout>
            )}
        {user.team.should_upsell_consumption &&
            !user.team.should_enforce_consumption &&
            user.team.consumption_enforcement_period === 'phase_iv_and_v' && (
                <Callout
                    icon="info-sign"
                    intent={Intent.PRIMARY}
                    title={ENFORCEMENT_DATE_TEXT.phase_iv_and_v.title}
                    bottomMargin={BottomMargin.STANDARD}
                >
                    {ENFORCEMENT_DATE_TEXT.phase_iv_and_v.subtitle}
                </Callout>
            )}
    </>
);
