import * as React from 'react';

import { Button, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';

import * as inc from 'reports/models/incentive';

interface IIncentiveTypeSelectProps {
    onChange: (item: inc.IncentiveType) => void;
    value: inc.IncentiveType;
    disabled: boolean;
}

const SimpleSelect = Select.ofType<inc.IncentiveType>();

const IncentiveTypeSelect: React.SFC<IIncentiveTypeSelectProps> = ({ onChange, value, disabled }) => {
    return (
        <SimpleSelect
            items={Object.keys(inc.IncentiveTypes) as inc.IncentiveType[]}
            onItemSelect={onChange}
            itemRenderer={(item, { handleClick }) => (
                <MenuItem key={item} text={inc.IncentiveTypes[item]} onClick={handleClick} />
            )}
            filterable={false}
            disabled={disabled}
        >
            <Button text={inc.IncentiveTypes[value]} rightIcon={IconNames.DOUBLE_CARET_VERTICAL} disabled={disabled} />
        </SimpleSelect>
    );
};

export default IncentiveTypeSelect;
