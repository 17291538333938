import { IAppRoute, makeRoute } from '../common';

import * as sub from 'reports/models/subscription';
import * as tm from 'reports/models/team';
import * as usr from 'reports/models/user';

import * as auth from 'reports/modules/auth';
import { actions as subActions } from 'reports/modules/subscription';
import { canViewTeamResources, helioscopeAdmin, UnauthorizedError } from 'reports/modules/auth/permissions';

import { AppStore } from 'reports/store';

const makeRedirectHandler = (userCheck) => {
    return (_router, dependencies: { store: AppStore }) => (_toState, _fromState) => {
        const { store } = dependencies;
        const user = auth.selectors.getUser(store.getState());
        if (userCheck(user)) {
            return Promise.reject({
                redirect: { name: 'app.settings.team.billing' },
            });
        }

        // If the user can view settings, there's no reason to redirect.
        // Also if no user is logged in, they'll see the login overlay. No need to redirect.
        return Promise.resolve(true);
    };
};

export const redirectToBillingHandler = makeRedirectHandler(
    (user: usr.User) => user && !user.role.can_view_settings && !user.is_admin,
);

const getUserFromParamsOrState = async (dispatch, getState, toParams): Promise<usr.User | null | undefined> => {
    if (toParams.email != null) {
        const user = usr.selectors.byEmail(getState(), toParams.email);
        if (user) {
            return user;
        }
        return await dispatch(usr.api.get({ email: toParams.email as string }));
    }
    return auth.selectors.getUser(getState());
};

const hsAdminOnEnter = (_dispatch, getState) => {
    const user = auth.selectors.getUser(getState());
    if (user == null || !helioscopeAdmin(user)) {
        throw new UnauthorizedError();
    }
};

const manageOnEnter = (_dispatch, getState) => {
    const user = auth.selectors.getUser(getState());
    if (user == null || !canViewTeamResources(user, user.team)) {
        throw new UnauthorizedError();
    }
};

export const AccountSettingsConfig: IAppRoute = makeRoute(
    'settings',
    'settings?email',
    {
        onEnter: async (dispatch, getState, { toParams }) => {
            const user = (await getUserFromParamsOrState(dispatch, getState, toParams))!;
            const team = await dispatch(tm.api.get({ team_id: user.team_id }));
            const subToLoad = team.latest_subscription || user.latest_subscription;

            if (subToLoad) {
                await dispatch(subActions.loadFullSubscription(subToLoad));
            }
            return { team, user };
        },
        selectors: {
            user: (state, { data }) => {
                // data contains the return from onEnter
                return usr.selectors.byObject(state, data.user);
            },
            subscription: (state, { data }) => {
                const user = usr.selectors.byObject(state, data.user)!;
                const team = user.team;
                // Always try to use the team's subscription first. If that's not available, it may be due to the team
                // not matching 1:1 with the subscription. In that case, defer to the current user's subscription.
                const subscription = team.latest_subscription || user.latest_subscription;
                return subscription ? sub.selectors.byObject(state, subscription) : null;
            },
            team: (state, { data }) => {
                return tm.selectors.byObject(state, data.team);
            },
        },
    },
    [
        makeRoute('team', '/team', {}, [
            makeRoute('overview', '/', { canActivate: redirectToBillingHandler }),
            makeRoute('manage', '/manage', { onEnter: manageOnEnter }),
            makeRoute('integrations', '/integrations', { canActivate: redirectToBillingHandler }),
            makeRoute('themes', '/themes', { canActivate: redirectToBillingHandler }),
            makeRoute('billing', '/billing'),
            makeRoute('features', '/features', { onEnter: hsAdminOnEnter }),
        ]),
        makeRoute('user', '/user', { canActivate: redirectToBillingHandler }, [
            makeRoute('preferences', '/preferences'),
            makeRoute('features', '/features', { onEnter: hsAdminOnEnter }),
            makeRoute('changepassword', '/changepassword'),
        ]),
        makeRoute('hs-admin', '/hs-admin', { onEnter: hsAdminOnEnter }, [
            makeRoute('subscription', '/subscription'),
            makeRoute('merge-teams', '/merge-teams'),
            makeRoute('team-features', '/team-features'),
            makeRoute('user-features', '/user-features'),
        ]),
    ],
);

export default AccountSettingsConfig;
