import * as React from 'react';

import { TeamFeatures as BetaTeamFeatures } from 'reports/modules/settings/admin/views/TeamFeatures';

import { useSettingsContext } from 'frontend/settings/use-settings-data';

const TeamFeatures = () => {
    const { team, user } = useSettingsContext();

    return <BetaTeamFeatures team={team} user={user} />;
};

export { TeamFeatures };
