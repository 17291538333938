import * as React from 'react';

import UnitsInput, { IConversionProps } from 'reports/components/forms/inputs/experimental/UnitsInput';
import { withForm } from 'reports/components/forms/inputs/experimental/common';

type IOwnProps = {
    unitTagText: string;
    conversion?: IConversionProps;
    precision?: number;
    placeholder?: string;
    inputWidth?: number;

    value: number;
    onChange: (v: number) => any;
    disabled: boolean;
};

/**
 * A numeric input that shows units on the right side of the input.
 * The API for this is not fully baked, hence it's in the /experimental dir.
 */
const _FormUnitsInput = ({
    unitTagText,
    conversion,
    placeholder = '',
    precision = 5,
    inputWidth,
    value,
    onChange,
    disabled,
}) => (
    <UnitsInput
        value={value}
        conversion={conversion}
        unitTagText={unitTagText}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        precision={precision}
        width={inputWidth}
    />
);

const FormUnitsInput = withForm<IOwnProps>(_FormUnitsInput);

export default FormUnitsInput;
