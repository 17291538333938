/* globals angular:true */
/* eslint-disable import/no-mutable-exports */

export let $compile;
export let $controller;
export let $document;
export let $rootScope;
export let $window;
export let $cookies;

// Angular:
export let $anchorScroll;
export let $filter;
export let $http;
// This stub here is needed so tests pass. This file needs to be rewritten
// without shadowing and whatever other magic nonesense is going on.
export let $injector = ({ get: (name) => undefined });
export let $log;
export let $q;
export let $sce;
export let $timeout;
export let $modal;
export let $location;
export let $stateParams;
export let $templateCache;

// Libs:
export let $state;
export let FileUploader;
export let FileUploaderS3;
export let pusher;

export let Authenticator;
export let FullDiodeModule;
export let Messager;
export let OptimizerLibrary;
export let PVSystDiodeModule;
export let wireLibrary;

import * as _ngProxies from './ng';

export const ngRefs = {};

export function populateProxies($injector) { // eslint-disable-line no-shadow
    'ngInject';

    const newProxies = Object.assign({}, _ngProxies);

    for (const name of Object.keys(_ngProxies)) {
        if (_ngProxies[name] === undefined) {
            ngRefs[name] = $injector.get(name);
            switch (name) {
                case "$compile":
                    $compile = ngRefs[name];
                    break;
                case "$controller":
                    $controller = ngRefs[name];
                    break;
                case "$document":
                    $document = ngRefs[name];
                    break;
                case "$rootScope":
                    $rootScope = ngRefs[name];
                    break;
                case "$window":
                    $window = ngRefs[name];
                    break;
                case "$cookies":
                    $cookies = ngRefs[name];
                    break;
                case "$anchorScroll":
                    $anchorScroll = ngRefs[name];
                    break;
                case "$filter":
                    $filter = ngRefs[name];
                    break;
                case "$http":
                    $http = ngRefs[name];
                    break;
                case "$injector":
                    $injector = ngRefs[name];
                    break;
                case "$log":
                    $log = ngRefs[name];
                    break;
                case "$q":
                    $q = ngRefs[name];
                    break;
                case "$sce":
                    $sce = ngRefs[name];
                    break;
                case "$timeout":
                    $timeout = ngRefs[name];
                    break;
                case "$modal":
                    $modal = ngRefs[name];
                    break;
                case "$location":
                    $location = ngRefs[name];
                    break;
                case "$stateParams":
                    $stateParams = ngRefs[name];
                    break;
                case "$templateCache":
                    $templateCache = ngRefs[name];
                    break;
                case "$state":
                    $state = ngRefs[name];
                    break;
                case "FileUploader":
                    FileUploader = ngRefs[name];
                    break;
                case "FileUploaderS3":
                    FileUploaderS3 = ngRefs[name];
                    break;
                case "pusher":
                    pusher = ngRefs[name];
                    break;
                case "Authenticator":
                    Authenticator = ngRefs[name];
                    break;
                case "FullDiodeModule":
                    FullDiodeModule = ngRefs[name];
                    break;
                case "Messager":
                    Messager = ngRefs[name];
                    break;
                case "OptimizerLibrary":
                    OptimizerLibrary = ngRefs[name];
                    break;
                case "PVSystDiodeModule":
                    PVSystDiodeModule = ngRefs[name];
                    break;
                case "wireLibrary":
                    wireLibrary = ngRefs[name];
                    break;
                default:
                    break;
            }
        }
    }
}
