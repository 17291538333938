import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { HelperText } from 'reports/modules/settings/common';
import Section2 from 'reports/components/core/containers/Section2';
import { fallback } from 'reports/utils/helpers';
import DataTable, { ColWidth } from 'reports/components/core/tables/DataTable';
import ScrollContainer from 'reports/components/core/tables/ScrollContainer';

import * as inv from 'reports/models/stripe/invoice';
import * as tm from 'reports/models/team';
import * as usr from 'reports/models/user';
import * as sub from 'reports/models/subscription';

import { PusherChannel } from 'reports/modules/project/listeners';

import { Intent, Tag } from '@blueprintjs/core';

import { formatBillingCurrency } from '../';

type Props = {
    subscription?: sub.Subscription;
    team: tm.Team;
    user: usr.User;
    subChannel?: PusherChannel;
    manageBilling: boolean;
};

const statusIntent = (status) => {
    switch (status) {
        case 'draft':
            return Intent.NONE;
        case 'open':
            return Intent.PRIMARY;
        case 'paid':
            return Intent.SUCCESS;
        case 'canceled':
        case 'uncollectible':
            return Intent.DANGER;
        default:
            return Intent.NONE;
    }
};

const STRIPE_INVOICE_WINDOW_STYLING = 'width=485,height=730';

const TABLE_ROW_HEIGHT = 43;
const TABLE_MAX_HEIGHT = 20 * TABLE_ROW_HEIGHT - TABLE_ROW_HEIGHT / 2;

const OlderInvoicesText = styled.div`
    margin-top: -10px;
`;

const InvoiceList = React.memo(
    ({ manageBilling, subscription, subChannel, user }: Pick<Props, Exclude<keyof Props, 'team'>>) => {
        if (!manageBilling) {
            return null;
        }

        const dispatch = useDispatch();
        const [legacyInvoiceUrl, setLegacyInvoiceUrl] = React.useState<string | null>();
        const [invoicesPromise, setInvoicesPromise] = React.useState<Promise<inv.Invoice[]>>(Promise.resolve([]));
        const loadInvoices = (teamId, subscription) =>
            dispatch(inv.api.index({ team_id: teamId, subscription_external_id: subscription.external_id }));

        const getLegacyInvoiceUrl = async (teamId) => dispatch(tm.api.getLegacyInvoiceUrl({ team_id: teamId }));

        React.useEffect(() => {
            (() => subscription && setInvoicesPromise(loadInvoices(user.team_id, subscription)))();
            return () => {};
        }, [subscription?.status]);
        // Be careful not to change subscription?.status to subscription.
        // useEffect will loop endlessly.

        React.useEffect(() => {
            if (subChannel) {
                const setInvoicesCallback = () => {
                    setInvoicesPromise(loadInvoices(user.team_id, subscription));
                };
                subChannel.watch('invoice.paid', setInvoicesCallback);
                subChannel.watch('invoice.created', setInvoicesCallback);
            }
            return () => {};
        }, [subChannel]);

        React.useEffect(() => {
            (async () => {
                const downloadUrl = (await getLegacyInvoiceUrl(user.team_id)).download_url;
                setLegacyInvoiceUrl(downloadUrl);
            })();
            return () => {};
        }, []);

        return (
            <>
                {!subscription ? (
                    <Section2 title="Billing History">
                        <HelperText large>There are no invoices on your account right now</HelperText>
                    </Section2>
                ) : (
                    <Section2 title="Billing History">
                        <>
                            <ScrollContainer height={TABLE_MAX_HEIGHT} fillHeight={false}>
                                <DataTable
                                    items={invoicesPromise}
                                    width="100%"
                                    centered={true}
                                    hasScrollContainer={true}
                                    sticky={true}
                                    columns={[
                                        {
                                            headerText: 'Invoice Number',
                                            colWidth: ColWidth.MEDIUM,
                                            renderCell: (invoice) => <>{invoice.number}</>,
                                        },
                                        {
                                            headerText: 'Date Created',
                                            colWidth: ColWidth.MEDIUM,
                                            renderCell: (invoice) => <>{invoice.created.format('ll')}</>,
                                        },
                                        {
                                            headerText: 'Amount',
                                            colWidth: ColWidth.SMALL,
                                            renderCell: (invoice) => <>{formatBillingCurrency(invoice.total)}</>,
                                        },
                                        {
                                            headerText: 'Payment Method',
                                            colWidth: ColWidth.MEDIUM,
                                            renderCell: (invoice) => (
                                                <>
                                                    {fallback(invoice.charge?.payment_method_details?.card?.last4, '-')}
                                                </>
                                            ),
                                        },
                                        {
                                            headerText: 'Status',
                                            colWidth: ColWidth.MEDIUM,
                                            renderCell: (invoice) => (
                                                <Tag intent={statusIntent(invoice.status)} minimal={true}>
                                                    {inv.InvoiceStatuses[invoice.status]}
                                                </Tag>
                                            ),
                                        },
                                        {
                                            headerText: '',
                                            colWidth: ColWidth.MEDIUM,
                                            renderCell: (invoice) => {
                                                return invoice.hosted_invoice_url ? (
                                                    <a
                                                        onClick={async () => {
                                                            window.open(
                                                                invoice.hosted_invoice_url,
                                                                'popup',
                                                                STRIPE_INVOICE_WINDOW_STYLING,
                                                            );
                                                        }}
                                                    >
                                                        {invoice.status === 'open' ? 'Pay Invoice' : 'View Invoice'}
                                                    </a>
                                                ) : (
                                                    <>Invoice not available</>
                                                );
                                            },
                                        },
                                    ]}
                                />
                            </ScrollContainer>
                        </>
                    </Section2>
                )}

                {legacyInvoiceUrl && (
                    <OlderInvoicesText>
                        <a href={legacyInvoiceUrl}>Download older invoices</a>
                    </OlderInvoicesText>
                )}
            </>
        );
    },
);

export default InvoiceList;
