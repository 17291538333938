import { filter, map, mapValues } from 'lodash';
import { createSelector } from 'reselect';

import { Template } from 'reports/utils/Template';

import { ITokenMap } from 'reports/modules/report/tokens';

import { CustomTextProps } from './index';

export const selectors = {
    getWidgetText: ({ config }: CustomTextProps) => (config.content ? config.content.tokens || '' : ''),

    get getCompiledTemplate() {
        return createSelector(this.getWidgetText, (text) => new Template(text));
    },

    get compileText() {
        return createSelector(
            this.getCompiledTemplate,
            (props: CustomTextProps) => props.context.tokenMap,
            ({ intl }: CustomTextProps) => intl.locale,
            (compiledTemplate, map, locale) => {
                const formattedTokens = mapValues(map, (token) => token.format({ locale }));
                return compiledTemplate.compile(formattedTokens, true);
            },
        );
    },

    get getCompletions() {
        return createSelector(
            (props: CustomTextProps) => props.context.tokenMap,
            // hidden tokens shouldn't show up in auto-complete
            (tokenMap: ITokenMap) =>
                map(
                    filter(tokenMap, (token) => !token.hidden),
                    (token) => token.selector,
                ),
        );
    },
};

export default selectors;
