/*
 * Select with throttled query change handler. Likely can be combined with QuerySelect.
 */
import { debounce } from 'lodash';
import * as React from 'react';

import { IconName, MaybeElement, MenuItem } from '@blueprintjs/core';
import { ISelectProps, ItemListRenderer, Select } from '@blueprintjs/select';

import { BehavedButton } from 'reports/components/helpers/common';
import { ConstrainedMenu } from 'reports/components/core/forms';

interface ISearchSelect<T = any> extends ISelectProps<T> {
    applySearch: (val: string) => any;
    buttonText: string;
    fill?: boolean;
    icon?: IconName | MaybeElement;
    onTextChange?: (val?: string) => any;
    searching?: boolean;
    throttle?: number;
}

export class SearchSelect<SelectType = any> extends React.Component<ISearchSelect<SelectType>> {
    throttledSearch: (val: string) => any;

    constructor(props: ISearchSelect<SelectType>) {
        super(props);
        this.throttledSearch = debounce(props.applySearch, props.throttle || 1000, { trailing: true });
    }

    render() {
        const TypedSelect = Select.ofType<SelectType>();

        const {
            items,
            buttonText,
            onTextChange,
            searching,
            icon,
            fill,
            applySearch: _applySearch,
            throttle: _throttle,
            ...otherProps
        } = this.props;

        const empty = <MenuItem text="No results." disabled={true} />;
        const search = <MenuItem text="Searching..." disabled={true} />;

        return (
            <TypedSelect
                items={items}
                popoverProps={{
                    boundary: 'scrollParent',
                    modifiers: { preventOverflow: { enabled: true } },
                    targetProps: { style: { width: '100%' } },
                    minimal: true,
                }}
                noResults={searching ? search : empty}
                initialContent={searching ? search : empty}
                onQueryChange={(value) => {
                    if (onTextChange) onTextChange(value);
                    this.throttledSearch(value);
                }}
                itemListRenderer={items.length ? this.renderItemList : undefined}
                {...otherProps}
            >
                <BehavedButton fill={fill} text={buttonText} icon={icon || false} rightIcon="caret-down" />
            </TypedSelect>
        );
    }

    renderItemList: ItemListRenderer<SelectType> = ({ items, itemsParentRef, renderItem }) => {
        const renderedItems = items.map(renderItem).filter((item) => item != null);

        return <ConstrainedMenu ulRef={itemsParentRef}>{renderedItems}</ConstrainedMenu>;
    };
}

export default SearchSelect;
