/* tslint:disable:variable-name function-name */
import moment from 'moment';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import * as sub from './subscription';

import { schema } from './schema';

class BillingV2MigrationLog extends BaseClass {
    billing_v2_migration_log_id: number;
    migration_ended_at: moment.Moment;
    reminded_to_update_billing_at: moment.Moment;

    subscription_external_id: string;
    subscription: sub.Subscription;

    constructor(data) {
        super(BillingV2MigrationLog.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        migration_ended_at: (x) => moment(x),
        reminded_to_update_billing_at: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(BillingV2MigrationLog, 'billing_v2_migration_log', {
    relationships: {
        subscription: {
            schema: sub.schemaObj,
            idName: 'subscription_external_id',
        },
    },
});
sub.schemaObj.addRelationship('billing_v2_migration_log', schemaObj);

const endpoint = ReduxEndpoint.fromSchema('/api/billing_migration/', schemaObj);

const api = {
    updateRemindedToUpdateBillingAt: endpoint.put<{ team_id: number }>('{team_id}/reminded_to_update_billing_at'),
};

export { BillingV2MigrationLog, api };
