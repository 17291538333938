/**
 * Uploadable image component - uploads files to S3 using Dropzone and S3File API
 *
 * Example usage:
 *     <UploadableImage
 *         fileId={data.fileId}
 *         onUpdate={file => this.updateFile({ file_id: file.file_id })}
 *         tag='overlay'
 *     />
 */
import { map } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { Intent, Classes } from '@blueprintjs/core';

import Dropzone, { ImageFile } from 'react-dropzone';

import Translations from 'reports/localization/strings';

import { ProgressBarTop } from 'reports/components/helpers/common';

import { humanizeBytes } from 'reports/utils/formatters';
import { bindActions } from 'reports/utils/redux';
import { IConnectedProps } from 'reports/types';

import { Toaster } from 'reports/modules/Toaster';
import * as s3file from 'reports/models/s3file';

import Image from 'reports/components/helpers/Image';

import { deregisterDropzoneInstance, registerDropzoneInstance } from './dropzone_helpers';
import { DropzoneContainer } from './DropzoneContainer';
import * as uploads from '../uploads';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const Progress = styled(ProgressBarTop).attrs({
    className: classNames('absolute', Classes.PROGRESS_NO_STRIPES),
})`
    .${Classes.PROGRESS_METER} {
        height: 4px;
    }
`;

interface IDispatchProps {
    uploadFile: typeof uploads.actions.uploadFile.propSignature;
    deleteFile: (fileId: number) => Promise<null>;
}

interface IEditState {
    disabled: boolean;
    progress?: number;
    stagedFileId?: number;
}

interface IUploadableProps extends IConnectedProps {
    onUpdate: (file: s3file.S3File) => void;
    tag: IFileTag;
    autoOpen?: boolean;
    disableClick?: boolean;
    fileId?: number;
    intl: IntlShape;
    style?: React.CSSProperties;
}

export type IFileTag = 'logo' | 'overlay' | 'report_image';

class UploadableImage extends React.PureComponent<IUploadableProps & IDispatchProps, IEditState> {
    dropzoneRef = React.createRef<Dropzone>();
    state: IEditState = {
        stagedFileId: this.props.fileId || undefined,
        progress: undefined,
        disabled: false,
    };

    componentDidMount() {
        registerDropzoneInstance(
            this,
            () => this.setState({ disabled: false }),
            () => this.setState({ disabled: true }),
        );

        if (this.props.autoOpen) {
            this.dropzoneRef.current && this.dropzoneRef.current.open();
        }
    }

    componentDidUpdate(prevProps) {
        const { fileId } = this.props;
        const { fileId: oldFileId } = prevProps;

        if (oldFileId !== fileId || fileId !== this.state.stagedFileId) {
            this.setState({ stagedFileId: fileId });
        }
    }

    componentWillUnmount() {
        deregisterDropzoneInstance(this);
    }

    async onDrop(acceptedFiles: ImageFile[], rejectedFiles: ImageFile[], event) {
        const { intl } = this.props;
        const { locale } = intl;

        if (acceptedFiles.length) {
            event.stopPropagation();
        }

        if (rejectedFiles.length > 0) {
            Toaster.show({
                message: intl.formatMessage(Translations.widgets.uploadable_image_file_rejected, {
                    name: rejectedFiles[0].name,
                    size: humanizeBytes(rejectedFiles[0].size, { locale }),
                }),
                intent: Intent.DANGER,
            });
            return;
        }

        const { onUpdate, tag, uploadFile } = this.props;
        const file = acceptedFiles[0];
        const onProgress = (progressEvent) => this.setState({ progress: progressEvent.percent / 100 });

        if (file !== null) {
            try {
                const uploadedFile = await uploadFile({
                    file,
                    onProgress,
                    meta: { tags: [tag] },
                });
                this.setState({ stagedFileId: uploadedFile.file_id });
                onUpdate(uploadedFile);
            } catch (exc) {
                Toaster.show({
                    message: intl.formatMessage(Translations.widgets.uploadable_image_error_uploading, {
                        response_body: map(exc.response.body, (val) => val[0]),
                    }),
                    intent: Intent.DANGER,
                });
            } finally {
                this.setState({ progress: undefined });
            }
        }
    }

    render() {
        return (
            <DropzoneContainer
                ref={this.dropzoneRef}
                disabled={this.state.disabled}
                onDrop={this.onDrop.bind(this)}
                accept="image/jpeg, image/png"
                disableClick={this.props.disableClick || false}
            >
                {({ isDragActive, isDragReject }) => this.renderDropzoneContent(isDragActive, isDragReject)}
            </DropzoneContainer>
        );
    }

    renderDropzoneContent(isDragActive, isDragReject) {
        const { stagedFileId, progress } = this.state;
        const { intl, style } = this.props;

        const progressBar = progress != null ? <Progress intent={Intent.PRIMARY} value={progress} /> : null;
        const url = stagedFileId != null ? s3file.api.download.url({ file_id: stagedFileId }) : null;
        const msg = isDragReject ? (
            <FormattedMessage
                {...Translations.widgets.uploadable_image_invalid_file_type}
                values={{ linebreak: <br /> }}
            />
        ) : isDragActive ? (
            intl.formatMessage(Translations.widgets.uploadable_image_drop_file_here)
        ) : (
            `${intl.formatMessage(Translations.widgets.uploadable_image_drop_image_here)} (*.jpg, *.png)`
        );

        return (
            <span>
                {progressBar}
                <Image src={url} errorMsg={msg} style={style || { objectFit: 'contain', minHeight: 50 }} />
                {!(isDragActive || isDragReject || progress) ? this.props.children : null}
            </span>
        );
    }
}

const mapDispatchToProps = bindActions({
    uploadFile: uploads.actions.uploadFileWithProgress,
    deleteFile: (fileId) => s3file.api.delete({ file_id: fileId }),
});

export const UploadableImageContainer = connect(null, mapDispatchToProps)(injectIntl(UploadableImage));

export default UploadableImageContainer;
