import * as React from 'react';

import { UserOverviewForm } from 'reports/modules/settings/user/components/UserOverviewForm';

import { useSettingsContext } from 'frontend/settings/use-settings-data';

const UserOverview = ({ changePassword = false }: { changePassword?: boolean }) => {
    const { user } = useSettingsContext();

    return <UserOverviewForm changePassword={changePassword} user={user} />;
};

export { UserOverview };
