import * as React from 'react';
import { FormattedInput } from 'reports/components/helpers';
import * as fmt from 'reports/utils/formatters';

/**
 * An input for a numeric percentage field.
 * The API for this is not fully baked, hence it's in the /experimental dir.
 *
 * @param value expected to be 100 times the decimal representation of the percentage.
 * E.g. a raw value of 5 is rendered as 5%, not 500%.
 */
const PercentInput = ({ value, placeholder = '', precision = 2, onChange, disabled }) => (
    <FormattedInput
        placeholder={placeholder}
        value={value / 100.0}
        onConfirm={(val) => onChange(val * 100)}
        textToValue={(val) => fmt.numberStringToFloat(val) * 0.01}
        valueToDisplayText={(val) => fmt.stringifyNumberSimple(val * 100.0, precision) + '%'}
        valueToEditText={(val) => fmt.stringifyNumberSimple(val * 100.0, precision)}
        selectAllOnFocus={true}
        disabled={disabled}
        fill={false}
        numericInputOnly={true}
    />
);

export default PercentInput;
