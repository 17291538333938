/**
 * File browser dialog
 */

import classNames from 'classnames';
import { Classes, Colors, Icon } from '@blueprintjs/core';

import { Flex } from 'reports/components/core/containers';

import { CenteredContainer } from 'reports/components/helpers/Image';
import { DropzoneContainer } from './DropzoneContainer';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const DialogBody = styled(Flex.ContainerV).attrs({
    className: Classes.DIALOG_BODY,
})`
    overflow: hidden;
    margin: 0;

    // remove default bottom margin on the last element. e.g. <p>
    & > :last-child {
        margin-bottom: 0px;
    }

    & > div {
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const FileContainer = styled.div`
    width: 160px;
    height: 150px;
    margin: 10px 10px 0 10px;
`;

export const FileName = styled.div`
    font-size: 11px;
    text-overflow: ellipsis;
    padding-top: 10px;
    line-height: 1.2;
    max-height: 24px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    white-space: nowrap;
    text-align: center;

    &:hover {
        cursor: default;
    }
`;

export const FileSearch = styled.div`
    margin-top: 20px;

    .${Classes.POPOVER_WRAPPER}, .${Classes.POPOVER_TARGET} {
        width: 100%;
    }
    .${Classes.INPUT} {
        margin-bottom: 10px;
    }
    .${Classes.TAG_INPUT} .${Classes.BUTTON} {
        margin-top: 4px;
    }
`;

export const Filters = styled.div`
    display: flex;

    label {
        font-size: 12px;
        font-weight: 500;
    }
    .${Classes.LABEL} {
        margin: 0 10px 10px 2px;
    }
    .${Classes.CONTROL_INDICATOR} {
        margin-right: 6px;
    }
`;

export const Gallery = styled.div`
    max-height: 400px;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 12px;
    overflow-y: auto;
    background: ${Colors.LIGHT_GRAY2};
    border: 1px solid ${Colors.GRAY5};
    border-radius: 2px;

    &::after {
        content: '';
        flex: auto;
    }
`;

export const ImageContainer = styled.div.attrs<{ className?: string }>((props) => ({
    className: classNames(props.className, 'hover-select-state'),
}))`
    position: relative;
    height: 120px;
    width: 160px;
    border-radius: 6px;
    border: 1px solid #fff5;
    background: #fff4;

    &:hover .${Classes.ICON} {
        display: block;
    }
    &:last-child {
        margin-right: auto;
    }
    &.disabled {
        opacity: 0.5;

        ${CenteredContainer} {
            pointer-events: none;
        }
    }
    &.selected {
        border: 1px solid ${Colors.BLACK};
    }
    > div:not(${DropzoneContainer}) {
        padding: 2px;
    }
    ${DropzoneContainer} {
        font-size: 12px;

        div {
            border-radius: 6px;
        }
    }
`;

// Transparent, round button. Pass in size prop to change button size.
export const ButtonRndTransp = styled(Icon).attrs<{ size?: number }>((props) => ({
    iconSize: props.size || 18,
}))`
    display: none;
    color: ${Colors.GRAY1};
    position: absolute;
    top: -12px;
    right: -12px;
    padding: 6px;
    background: rgba(170, 170, 170, 0.4);
    border-radius: 100%;
    box-sizing: content-box;

    &:hover {
        cursor: pointer;
        color: ${Colors.RED1};
        background: rgba(255, 183, 165, 0.6);
    }
`;
