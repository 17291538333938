import { Dispatch } from 'redux';
import { SubscriptionVersion, v1Api as subV1Api, api as subV2Api } from 'reports/models/subscription';

export const actions = {
    loadFullSubscription:
        ({ external_id, version }: { external_id: string; version: SubscriptionVersion }) =>
        async (dispatch: Dispatch) => {
            const api = version === SubscriptionVersion.v1 ? subV1Api : subV2Api;
            return dispatch(api.get({ external_id }));
        },
};
