import * as React from 'react';
import moment from 'moment';

import { DateRange, DateRangePicker } from '@blueprintjs/datetime';
import { Callout } from '@blueprintjs/core';

import { FormFields } from 'reports/components/forms';

// A time of 14:00 UTC stored on the server needs to be converted to 14:00 browser local time
// so it's displayed to the user correctly. This is due to DateRangePicker's choice
// to always use browser local time.
function utcServerToLocalTime(utcServerDate: Date): Date {
    return moment(moment.utc(utcServerDate).toArray()).toDate();
}

// Converting from UTC shown in local time to an array representation that disregards tz offset.
function utcLocalToArr(utcLocalDate: Date): number[] {
    return moment(utcLocalDate).toArray();
}

function arrToUTCString(dateArr: number[]): string {
    return moment.utc(dateArr).toISOString();
}

export const FormDateTimeRangePicker: React.FC = () => (
    <FormFields paths={['row_spacing_start_time', 'row_spacing_end_time']}>
        {({ values: rowSpacingStartEnd, onChange: onChangeDate }) => {
            const { row_spacing_start_time, row_spacing_end_time } = rowSpacingStartEnd;

            const startingDate = moment(row_spacing_start_time).toDate();
            const endingDate = moment(row_spacing_end_time).toDate();
            const utcStartingDate = utcServerToLocalTime(startingDate);
            const utcEndingDate = utcServerToLocalTime(endingDate);

            // Need to keep track of the ending time, so that we can override the starting date's time
            // in the case in which selectedDates' end date is null.
            const endingHours = moment.utc(row_spacing_end_time).toArray()[3];
            const endingMinutes = moment.utc(row_spacing_end_time).toArray()[4];

            const dateRange: DateRange = [utcStartingDate, utcEndingDate];
            return (
                <>
                    <DateRangePicker
                        allowSingleDayRange={true}
                        singleMonthOnly={true}
                        shortcuts={false}
                        value={dateRange}
                        onChange={(selectedDates) => {
                            const startDate = selectedDates[0];
                            if (startDate === null) return;
                            const endDate = selectedDates[1];

                            // Need to convert back to the local time zone to call
                            // toISOString to save the UTC time string
                            const utcStartDateArr = utcLocalToArr(startDate);
                            const startDateString = arrToUTCString(utcStartDateArr);
                            let partialObject;
                            if (endDate === null) {
                                // If we're selecting a new date, it falls under this case.
                                // Set the endDate to the same date, but update the time to be the current end time.
                                const utcEndDateArr = [...utcStartDateArr];
                                utcEndDateArr[3] = endingHours;
                                utcEndDateArr[4] = endingMinutes;

                                partialObject = {
                                    row_spacing_start_time: startDateString,
                                    row_spacing_end_time: arrToUTCString(utcEndDateArr),
                                };
                            } else {
                                // Else, we're changing the start or end time. Convert the endDate to local time zone.
                                const utcEndDateArr = utcLocalToArr(endDate);
                                if (moment.utc(utcEndDateArr).toDate().getTime() === endingDate.getTime()) {
                                    partialObject = {
                                        row_spacing_start_time: startDateString,
                                    };
                                } else {
                                    partialObject = {
                                        row_spacing_end_time: arrToUTCString(utcEndDateArr),
                                    };
                                }
                            }

                            onChangeDate(partialObject);
                        }}
                        timePrecision="minute"
                    />
                    <Callout style={{ width: 230 }}>
                        <p>
                            <b>Start Time: </b>
                            {moment.utc(row_spacing_start_time).format('M/D/YYYY H:mm:ss')}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                            <b>End Time: </b>
                            {moment.utc(row_spacing_end_time).format('M/D/YYYY H:mm:ss')}
                        </p>
                    </Callout>
                </>
            );
        }}
    </FormFields>
);
