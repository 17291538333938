import * as React from 'react';

import { Classes, FormGroup, IFormGroupProps, IIntentProps, InputGroup } from '@blueprintjs/core';
import classNames from 'classnames';

import { ReactComponent } from 'reports/utils/types';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// Style inline FormGroup to allow input to fill parent element for stacked uniform widths given a label width
export const StyledFormGroup = styled(FormGroup)<{ labelWidth?: number }>`
    &.${Classes.INLINE} {
        .${Classes.FORM_CONTENT} {
            position: relative;
            flex: 1;
        }
        .${Classes.LABEL} {
            width: ${(props) => props.labelWidth || 80}px;
        }
    }
`;

export interface IFormField extends IFormGroupProps, IIntentProps {
    // props to pass down to provided input component (default InputGroup)
    value: any;
    onChange?: (val: any) => void;
    placeholder?: string;

    // FormGroup props
    labelWidth?: number;

    // optional props to render custom input component
    inputComponent?: ReactComponent; // TODO: enforce component supports value/onChange signature
    inputProps?: { [key: string]: any }; // TODO: enforce component gets the props it needs
    renderInput?: (props: any) => JSX.Element;
}

//

/**
 * Input wrapper around customizable input component that includes label + helper text, intent etc.
 *
 * @deprecated: note – this field is too generic, which makes for a leaky abstraction. e.g. some inputs take events
 * some take raw types, events that aren't handled need to overridden, to disable some kinds of fields means duplicating
 * props in multiple places or generally overriding the inner input fuekd a lot
 *
 * in usage – it is so customizable that every individual invcocation uses a large portion of the configuration options,
 * which really eliminates the utility of an abstraction: we effectively have a different API of form components, rather
 * than something that centralizes decisions for us and streamlines feature implementation
 */
export const FormField: React.SFC<IFormField> = ({
    inputComponent,
    inputProps = {},
    renderInput,
    onChange,
    placeholder,
    value,
    ...props
}) => {
    const InputEl = inputComponent || InputGroup;

    const { helperText, inline, intent, label, ...formGroupProps } = props;
    const { className, ...otherInputProps } = inputProps;

    const combinedInputProps = {
        value,
        onChange,
        placeholder,
        intent,
        ...inputProps,
    };

    return (
        <StyledFormGroup
            label={label}
            helperText={helperText}
            inline={inline === true || inline === undefined}
            intent={intent}
            {...formGroupProps}
        >
            {renderInput ? (
                renderInput(combinedInputProps)
            ) : (
                <InputEl
                    onChange={(e) => onChange && onChange(e.target != null ? e.target.value : e)}
                    value={value}
                    className={classNames(Classes.FILL, className)}
                    placeholder={placeholder}
                    intent={intent}
                    {...otherInputProps}
                />
            )}
        </StyledFormGroup>
    );
};

export default FormField;
