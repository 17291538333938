import classNames from 'classnames';
import * as React from 'react';

import { Classes, Intent as bpColorIntent } from '@blueprintjs/core';

import Colors from 'reports/styles/colors';

import Button, { IProps as IButtonProps } from './Button';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

/** Delineate the Blueprint Intents for our own purposes.
 *  We want to have our own primary button colors that override
 *  Blueprint's default Primary Intent style.
 */

export enum PrimaryIntent {
    SAVE = 'intent-save',
    CREATE = 'intent-create',
    DELETE = 'intent-delete',
    FAVORITE = 'intent-favorite',
}

const IntentMap = {
    [PrimaryIntent.SAVE]: bpColorIntent.PRIMARY,
    [PrimaryIntent.CREATE]: bpColorIntent.WARNING,
    [PrimaryIntent.FAVORITE]: bpColorIntent.PRIMARY,
    [PrimaryIntent.DELETE]: bpColorIntent.DANGER,
} as const;

/** The Blueprint Intent classes include more styles than just color:
 *  text color, shading, borders, etc. The helio-orange class lets us
 *  have an Intent button with a different background color.
 */
const PrimaryButton = styled(Button)`
    &&.${PrimaryIntent.CREATE}:not(.${Classes.MINIMAL}) {
        background-color: ${Colors.HELIO_ORANGE};

        &:hover {
            background-color: ${Colors.HELIO_ORANGE_MEDIUM};
        }
        &:active {
            background-color: ${Colors.HELIO_ORANGE_DARK};
        }
        &:disabled {
            background-color: ${Colors.HELIO_ORANGE_DISABLED};
        }
    }

    // Favorite button styling similar to BlueprintJS intents
    &&.${PrimaryIntent.FAVORITE} {
        &.${Classes.MINIMAL} {
            color: ${Colors.INTENT_FAVORITE};

            &&:hover,
            &&:active {
                background: ${Colors.INTENT_FAVORITE_HOVER_MINIMAL_BG};
            }
        }
        &:not(.${Classes.MINIMAL}) {
            background-color: ${Colors.INTENT_FAVORITE};

            &:hover {
                background: ${Colors.INTENT_FAVORITE_HOVER_BG};
            }
        }
    }
`;

export type IPrimaryButton = Omit<IButtonProps, 'intent'> & {
    intent?: PrimaryIntent;
};

const PrimaryButtonContainer: React.FC<IPrimaryButton> = (props) => {
    const { intent: primaryIntent = PrimaryIntent.SAVE, className, ...buttonProps } = props;

    return (
        <PrimaryButton
            className={classNames(className, primaryIntent)}
            intent={IntentMap[primaryIntent]}
            {...buttonProps}
        />
    );
};

export default PrimaryButtonContainer;
