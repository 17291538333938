import * as React from 'react';

import ExpiredAccountDialog from 'reports/modules/auth/components/ExpiredAccountDialog';

export const ExpiredBoundary = ({ user, route, children }) => {
    const [isOpen, setOpen] = React.useState(false);

    const dialog = <ExpiredAccountDialog user={user} isOpen={isOpen} onClose={() => setOpen(false)} />;

    React.useEffect(() => {
        if (user.isExpired()) {
            if (user.latest_subscription?.is_pay_by_invoice && user.latest_subscription?.cleaned_status === 'unpaid') {
                setOpen(false);
            } else if (
                route &&
                !route.name.startsWith('app.settings.team') &&
                !route.name.startsWith('app.settings.user.features') &&
                !route.name.startsWith('app.settings.hs-admin')
            ) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        } else {
            setOpen(false);
        }
    }, [route?.name, user.user_id]);

    return (
        <>
            {dialog}
            {children}
        </>
    );
};
export default ExpiredBoundary;
