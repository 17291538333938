import * as React from 'react';
import { connect } from 'react-redux';
import { bindActions } from 'reports/utils/redux';

import { Card } from 'reports/components/core/containers';

import { NestedFields } from 'reports/components/forms';
import { FormBasicSelect } from 'reports/components/forms/inputs/experimental';

import * as ft from 'reports/models/financial_template';

import { LABEL_WIDTH, Section2 } from 'reports/modules/profile/components/common';
import FinancialProfileIncentiveEditor from 'reports/modules/profile/components/FinancialProfileIncentiveEditor';

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

const FinancialProfileEditor: React.FC<IDispatchProps> = (props) => {
    const { getFinancialTemplates } = props;
    return (
        <NestedFields path="data">
            <Card>
                <Section2 title="Financial Template Selection">
                    <FormBasicSelect<ft.FinancialTemplate>
                        inline
                        bold
                        noneSelected="None"
                        label="Financial Model"
                        labelWidth={LABEL_WIDTH}
                        path={'financial_template_id'}
                        dataSource={{
                            async: true,
                            query: async (q) => getFinancialTemplates({ q, limit: 20 }),
                            keyLookup: (finTemp) => finTemp.financial_template_id,
                            itemLookup: (finTempId, state) => ft.selectors.byId(state, finTempId)!,
                        }}
                        itemRenderer={(ft: ft.FinancialTemplate) => ({
                            key: ft.financial_template_id,
                            text: ft.toString(),
                        })}
                    />
                </Section2>
            </Card>
            <Card>
                <FinancialProfileIncentiveEditor path={'incentive_ids'} />
            </Card>
        </NestedFields>
    );
};

const mapDispatchToProps = bindActions(() => ({
    getFinancialTemplates: ft.api.index,
}));

export default connect(null, mapDispatchToProps)(FinancialProfileEditor);
