import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLoaderData, useNavigate } from 'react-router';
import * as mod from 'reports/models/module';

import { ModulePreview } from 'reports/modules/module/components/ModulePreview';
import { Modules } from './modules';
import { IAppState } from 'reports/types';

type ModuleContext = {
    characterization: mod.ModuleCharacterization;
    module: mod.Module;
};

const Module = () => {
    const navigate = useNavigate();
    const navigateToModules = () => navigate('/modules');

    const navigateToCharacterization = (moduleId: number, characterizationId: number) => {
        const path = generatePath('/modules/:moduleId/characterizations/:characterizationId/preview', {
            moduleId: moduleId.toString(),
            characterizationId: characterizationId.toString(),
        });
        navigate(path);
    };

    const { characterization, module } = useLoaderData() as ModuleContext;
    const selectedModule = useSelector((state) =>
        mod.selectors.byObject(state as IAppState, { module_id: module.module_id }),
    );

    if (!selectedModule || !characterization) {
        throw new Response('Not Found', { status: 404 });
    }

    return (
        <Modules
            selectedModuleId={selectedModule.module_id}
            preview={
                <ModulePreview
                    module={selectedModule}
                    characterization={characterization}
                    navigateToModules={navigateToModules}
                    navigateToCharacterization={navigateToCharacterization}
                />
            }
        />
    );
};

export { Module };
