import * as React from 'react';
import { Card } from 'reports/components/core/containers';
import classNames from 'classnames';
import { Button } from 'reports/components/core/controls';
import { Intent, Classes, Tag } from '@blueprintjs/core';

import * as styles from 'reports/styles/styled-components';
import { IntegrationOption } from './ExternalIntegrationsList';
const styled = styles.styled;

const Logo = styled.img`
    height: 30px;
`;

const IntegrationCard = styled(Card)`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 275px;
`;

const IntegrationCardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const IntegrationCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

const IntegrationCardText = styled.p`
    margin: 5px 0px;
    // && gives this specific style higher specificity than the style on parent App.Body that sets p.font-size to 14px.
    && {
        font-size: 12px;
    }
    line-height: normal;
`;

interface IExternalIntegrationCallbacks {
    onCancel?: () => void;
    onDisconnect: () => void;
    onConnect: () => void;
}

interface IExternalIntegrationsOptions {
    connected?: boolean;
    options: IntegrationOption;
    helpCenterUrl?: string;
    team?: any;
    user?: any;
}

export type IExternalIntegrationCardProps = IExternalIntegrationCallbacks & IExternalIntegrationsOptions;

const IntegrationCardButton = styled(Button)`
    margin: 0;
`;

function ExternalIntegrationsCard(props: IExternalIntegrationCardProps) {
    const { connected, onConnect, onDisconnect, options } = props;

    const renderTag = () => {
        return connected ? (
            <Tag minimal active className={classNames(Classes.INTENT_SUCCESS)}>
                Connected
            </Tag>
        ) : (
            <Tag minimal>Disconnected</Tag>
        );
    };

    return (
        <>
            <IntegrationCard>
                <IntegrationCardContent>
                    <Logo src={options.imgUrl} />
                    <div>
                        <IntegrationCardHeader>
                            <b>{options.title}</b>
                            {options.service !== 'sso' && renderTag()}
                        </IntegrationCardHeader>
                        <IntegrationCardText>{options.shortDescription}</IntegrationCardText>
                    </div>
                </IntegrationCardContent>

                {!connected && <IntegrationCardButton intent={Intent.PRIMARY} text="Connect" onClick={onConnect} />}
                {connected && <IntegrationCardButton text="Disconnect" onClick={onDisconnect} />}
            </IntegrationCard>
        </>
    );
}

export default ExternalIntegrationsCard;
