import * as React from 'react';

import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { IAppState } from 'reports/types';

import BasicSelect, { BasicSelectProps, IAsyncDataSource, ISyncDataSource } from './BasicSelect';
import { withForm, withFormBare } from './common';

// BasicSelect expects its value to be of the same type as its item, but our convention
// with forms is that the "value" is a key such as an ID. The wrappers below slightly
// modify BasicSelect to accommodate this.
interface ISyncDataSourceByKey<I, K> extends Omit<ISyncDataSource<I>, 'items'> {
    items: I[];

    /**
     * A function that looks up the item's key (usually ID) given that item
     */
    keyLookup: (item: I) => K;
}

interface IAsyncDataSourceByKey<I, K> extends IAsyncDataSource<I> {
    /**
     * A function that looks up the item's key (usually ID) given that item
     */
    keyLookup: (item: I) => K;

    /**
     * A function that looks up the full item object via its key (usually ID)
     */
    itemLookup: (key: K, state: IAppState) => I;
}

type BasicSelectByKeyProps<I, K = any> = Omit<BasicSelectProps<K>, 'dataSource' | 'value'> & {
    dataSource: ISyncDataSourceByKey<I, K> | IAsyncDataSourceByKey<I, K>;
    value: I;
    onChange: (itemKey: K) => any;
};

type ConnectedBasicSelectProps<I, K> = Omit<BasicSelectProps<I>, 'value'> & {
    item: K;
};

// Exposing itemLookup and using mapStateToProps on the inner BasicSelect component provides an easy way
// for someone using async FormBasicSelect to do ID-based lookups in the Redux store. My previous attempt
// was putting a mapStateToProps in the parent component that returns a lookup function. The performance
// characteristics of that are very bad, since every state change results in a new lookup function and
// thus a new prop passed to FormBasicSelect and thus a re-render. In this solution, Redux's fast comparisons
// (see here https://react-redux.js.org/using-react-redux/connect-mapstate#mapstatetoprops-functions-should-be-fast)
// prevent unnecessary re-renders of the underlying BasicSelect.
const mapStateToProps = <I, K>(state: IAppState, ownProps: ConnectedBasicSelectProps<I, K>) => {
    const itemLookup = (ownProps.dataSource as IAsyncDataSourceByKey<I, K>).itemLookup;
    return {
        value: itemLookup(ownProps.item, state),
    };
};

const ConnectedBasicSelect = connect(mapStateToProps)(BasicSelect);

const BasicSelectByKey = <I,>(props: BasicSelectByKeyProps<I>) => {
    const { onChange, value, dataSource, ...otherProps } = props;

    if ((dataSource as ISyncDataSourceByKey<I, unknown>).items) {
        const { keyLookup, items, filterBy } = dataSource as ISyncDataSourceByKey<I, unknown>;

        return (
            <BasicSelect<I>
                value={items.find((item) => isEqual(keyLookup(item), value)) || null}
                onChange={(item) => onChange(keyLookup(item))}
                dataSource={{
                    filterBy,
                    items,
                    async: false,
                }}
                {...otherProps}
            />
        );
    }

    const { keyLookup, ...dataSourceProps } = dataSource as IAsyncDataSourceByKey<I, unknown>;

    return (
        <ConnectedBasicSelect<I>
            item={value}
            onChange={(item) => onChange(keyLookup(item))}
            dataSource={dataSourceProps}
            {...otherProps}
        />
    );
};

const FormBasicSelect = withForm(BasicSelectByKey);
export const FormBareBasicSelect = withFormBare(BasicSelectByKey);

export default FormBasicSelect;
