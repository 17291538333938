import * as React from 'react';

import * as usr from 'reports/models/user';
import * as sub from 'reports/models/subscription';

import { Button } from '@blueprintjs/core';
import { UserSelectDropdownContainer } from 'reports/components/CustomDropdowns';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const Container = styled.div`
    > *:not(:first-child) {
        margin-top: 10px;
    }
`;

const ActionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

interface IOwnProps {
    children: (props: {
        user: usr.User | null;
        newUser: sub.IPersonalDataForm | null;
        clearForm: () => void;
    }) => React.ReactNode;
    excludeTeamId?: number;
    canAddNewUser?: boolean;
}

interface IState {
    selectedUser: usr.User | null;
    selectedNewUser: sub.IPersonalDataForm | null;
}

class UserSelectContainer extends React.PureComponent<IOwnProps, IState> {
    state: IState = {
        selectedUser: null,
        selectedNewUser: null,
    };

    render() {
        const { children, excludeTeamId, canAddNewUser } = this.props;
        const { selectedUser, selectedNewUser } = this.state;
        return (
            <Container>
                <ActionButtonsContainer>
                    <UserSelectDropdownContainer
                        text={canAddNewUser ? 'Search Existing Users...' : 'Search users...'}
                        onItemSelect={async (user) => {
                            this.setState({
                                selectedNewUser: null,
                                selectedUser: user,
                            });
                        }}
                        excludeTeamId={excludeTeamId}
                    />
                    {canAddNewUser && (
                        <Button
                            style={{ marginLeft: '12px' }}
                            icon="new-person"
                            intent="success"
                            onClick={() => {
                                this.setState({
                                    selectedUser: null,
                                    selectedNewUser: {
                                        first_name: '',
                                        last_name: '',
                                        email: '',
                                    },
                                });
                            }}
                        >
                            Add New
                        </Button>
                    )}
                </ActionButtonsContainer>
                {(selectedUser != null || selectedNewUser != null) &&
                    children({
                        user: selectedUser,
                        newUser: selectedNewUser,
                        clearForm: () =>
                            this.setState({
                                selectedUser: null,
                                selectedNewUser: null,
                            }),
                    })}
            </Container>
        );
    }
}

export default UserSelectContainer;
