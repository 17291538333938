/* tslint:disable:variable-name */
import moment from 'moment';
import * as stripe from '@stripe/stripe-js';

import { ReduxEndpoint, BaseClass } from 'reports/utils/api';

import { schema } from '../schema';
import * as sub from '../subscription';
import { Price } from './price';
import IStripeList from './stripe_list';
import { TestClock } from './test_clock';

/**
 * Based off of the Stripe Invoice object: https://stripe.com/docs/api/invoices
 * Note: All currency values are in cents: https://stripe.com/docs/currencies#zero-decimal
 */
class Invoice extends BaseClass {
    id: string;
    number: string;
    customer_name: string;
    customer_email: string;
    created: moment.Moment;
    default_tax_rates: TaxRate[];
    payment_intent: stripe.PaymentIntent;
    hosted_invoice_url: string;
    subtotal: number;
    total: number;
    tax: number;
    amount_due: number;
    lines: IStripeList<LineItem>;
    status: InvoiceStatus;
    paid: boolean;
    charge: any;
    next_payment_attempt: moment.Moment;
    status_transitions: StatusTransitions;
    metadata: stripe.Metadata;
    subscription?: string; // id of the linked stripe subscription, if any
    subscription_proration_date?: moment.Moment;
    customer_address: stripe.ExpressCheckoutAddress;

    test_clock: TestClock;

    // HelioScope-specific fields
    helioscope_subscription: sub.Subscription;
    helioscope_tax_included: boolean;

    constructor(data) {
        super(Invoice.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        created: moment.unix,
        tax_rates: (rates) => rates.map((rate) => new TaxRate(rate)),
        next_payment_attempt: moment.unix,
        subscription_proration_date: moment.unix,
        status_transitions: (x) => new StatusTransitions(x),
        lines: (list) => {
            const newData = list.data.map((line) => new LineItem(line));
            return { ...list, data: newData };
        },
        test_clock: (x) => new TestClock(x),
    });

    canPay() {
        // Interestingly, stripe allows "uncollectible" invoices to transition to "paid".
        // https://stripe.com/docs/billing/invoices/overview
        return this.status === 'open' || this.status === 'uncollectible';
    }

    get isUpcoming() {
        return this.id == null;
    }

    get isInitialInvoice() {
        return this.subscription == null;
    }

    now = () => this.test_clock?.frozen_time || moment();
}

class LineItem extends BaseClass {
    description: string;
    quantity: number;
    amount: number;
    period: Period;
    // Note: this price isn't stored in redux because we don't have a good way to define relationships to
    // deeply nested objects.
    price: Price;
    tax_rates: TaxRate[];

    constructor(args) {
        super(LineItem.deserializer(args));
    }

    static deserializer = BaseClass.getDeserializer({
        period: (x) => new Period(x),
        price: (x) => new Price(x),
        tax_rates: (rates) => rates.map((rate) => new TaxRate(rate)),
    });
}

class Period extends BaseClass {
    start: moment.Moment;
    end: moment.Moment;

    constructor(args) {
        super(Period.deserializer(args));
    }

    static deserializer = BaseClass.getDeserializer({
        start: moment.unix,
        end: moment.unix,
    });
}

class TaxRate extends BaseClass {
    percentage: number;

    constructor(args) {
        super(TaxRate.deserializer(args));
    }

    static deserializer = BaseClass.getDeserializer({});
}

class StatusTransitions extends BaseClass {
    finalized_at: moment.Moment;
    marked_uncollectible_at: moment.Moment;
    paid_at: moment.Moment;
    voided_at: moment.Moment;

    constructor(data) {
        super(StatusTransitions.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        finalized_at: moment.unix,
        marked_uncollectible_at: moment.unix,
        paid_at: moment.unix,
        voided_at: moment.unix,
    });
}

enum InvoiceStatuses {
    draft = 'Draft',
    open = 'Open',
    paid = 'Paid',
    uncollectible = 'Payment Failed',
    void = 'Void',
    canceled = 'Canceled',
}
type InvoiceStatus = keyof typeof InvoiceStatuses;

const schemaObj = schema.addObject(Invoice, 'invoice', {
    relationships: {
        helioscope_subscription: { schema: sub.schemaObj },
    },
    idName: 'id',
});
sub.schemaObj.addRelationship('latest_invoice', schemaObj);

const endpoint = ReduxEndpoint.fromSchema('/api/v2/invoices/', schemaObj);

const invoicePreviewEndpoint = ReduxEndpoint.fromSchema('/api/v2/', schemaObj);

const invoicePreviewAPI = {
    getNewInvoicePreview: invoicePreviewEndpoint.post<{
        quantity: number;
        stripe_customer_id: string;
        stripe_price_id: string;
        address?: stripe.Address;
    }>('new_invoice_previews', {
        ...ReduxEndpoint.PassThroughConfig(),
        selector: (_state, respData) => new Invoice(respData),
    }),
    getChangeInvoicePreview: invoicePreviewEndpoint.post<{
        quantity: number;
        address?: stripe.Address;
        subscription: string;
        stripe_price_id: string;
    }>('change_invoice_previews', {
        ...ReduxEndpoint.PassThroughConfig(),
        selector: (_state, respData) => new Invoice(respData),
    }),
};

const api = {
    index: endpoint.index<{ team_id: string; subscription_external_id: string }>(),
    get: endpoint.get<{ stripe_invoice_id: string }>('{stripe_invoice_id}'),
    save: endpoint.put<{ metadata: stripe.Metadata }, { stripe_invoice_id: string }>('{stripe_invoice_id}'),
    upcoming: endpoint.get<{ subscription_external_id: string }>('upcoming', {
        // Don't try to save upcoming invoice in redux, since it won't have an "id"
        ...ReduxEndpoint.PassThroughConfig(),
        selector: (_state, respData) => new Invoice(respData),
    }),
    pay: endpoint.post<{}, { stripe_invoice_id: string }>('{stripe_invoice_id}/pay'),
    payOutOfBand: endpoint.post<{}, { stripe_invoice_id: string }>('{stripe_invoice_id}/pay_out_of_band'),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { Invoice, InvoiceStatus, InvoiceStatuses, schemaObj, selectors, api, invoicePreviewAPI };
