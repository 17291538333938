import { ParamValueType } from 'reports/modules/financials/params';

import { OutputCategory } from 'reports/modules/financials/model/debug';
import { IPipelineModule } from 'reports/modules/financials/model/modules/types';

export const BasicSystemCostSimple: IPipelineModule = {
    description: 'Fixed Price',
    parameters: [
        {
            description: 'Price',
            path: 'flat_cost',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999999.0,
            default: 0.0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { flat_cost } = params;

            state.systemInitial -= flat_cost;
        },
        debugOutputs: [{ table: OutputCategory.System }],
    },
};

export const BasicSystemCostNameplate: IPipelineModule = {
    description: 'Price Per Watt',
    parameters: [
        {
            description: '$ / Wdc',
            path: 'per_w_cost',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999.0,
            default: 2.5,
        },
    ],
    module: {
        main: function main(state, params) {
            const { per_w_cost } = params;
            const { nameplate } = state;

            state.systemInitial -= per_w_cost * nameplate;
        },
        debugOutputs: [{ table: OutputCategory.System }],
    },
};

export const BasicSystemCostPerModule: IPipelineModule = {
    description: 'Price Per Module',
    parameters: [
        {
            description: '$ / module',
            path: 'per_module_cost',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999.0,
            default: 120.0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { per_module_cost } = params;
            const { moduleCount } = state;

            state.systemInitial -= per_module_cost * moduleCount;
        },
        debugOutputs: [{ table: OutputCategory.System }],
    },
};

export const BasicSystemCostPerInverter: IPipelineModule = {
    description: 'Price Per Inverter',
    parameters: [
        {
            description: '$ / inverter',
            path: 'per_inverter_cost',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999.0,
            default: 650.0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { per_inverter_cost } = params;
            const { inverterCount } = state;

            state.systemInitial -= per_inverter_cost * inverterCount;
        },
        debugOutputs: [{ table: OutputCategory.System }],
    },
};

export const BasicSystemCostPerOptimizer: IPipelineModule = {
    description: 'Price Per Optimizer',
    parameters: [
        {
            description: '$ / optimizer',
            path: 'per_optimizer_cost',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999.0,
            default: 45.0,
        },
    ],
    module: {
        main: function main(state, params) {
            const { per_optimizer_cost } = params;
            const { optimizerCount } = state;

            state.systemInitial -= per_optimizer_cost * optimizerCount;
        },
        debugOutputs: [{ table: OutputCategory.System }],
    },
};

export const BasicReplacementCostSimple: IPipelineModule = {
    description: 'Equipment Replacement Cost',
    parameters: [
        {
            description: 'Replacement Cost',
            path: 'schedule',
            type: ParamValueType.Table,
            max_rows: 99,
            sort_path: 'year',
            default: [],
            columns: [
                {
                    description: 'Equipment Cost',
                    path: 'flat_cost',
                    type: ParamValueType.Currency,
                    min_value: 0.0,
                    max_value: 9999999999.0,
                    default: 0.0,
                },
                {
                    description: 'Year of Replacement',
                    path: 'year',
                    type: ParamValueType.Integer,
                    min_value: 0,
                    max_value: 99,
                    default: 10,
                },
            ],
        },
    ],
    module: {
        main: function main(state, params) {
            const { schedule } = params;
            const { systemYearly } = state;

            for (const i of schedule) {
                if (i.year === 0) {
                    state.systemInitial -= i.flat_cost;
                    continue;
                }

                systemYearly.setRawSafe(i.year - 1, systemYearly.getRawSafe(i.year - 1) - i.flat_cost);
            }
        },
        debugOutputs: [{ table: OutputCategory.System }],
    },
};

export const BasicMaintenanceCostSimple: IPipelineModule = {
    description: 'Annual Maintenance Cost',
    parameters: [
        {
            description: 'Annual Cost ($ / Wdc)',
            path: 'w_yearly',
            type: ParamValueType.Currency,
            min_value: 0.0,
            max_value: 9999999.0,
            default: 0.0,
            precision: 4,
        },
    ],
    module: {
        main: function main(state, params) {
            const { w_yearly } = params;
            const { nameplate } = state;

            state.systemYearly = state.systemYearly.subSeries(state.systemYearly.map(() => nameplate * w_yearly));

            state.annualMaintenanceCost = w_yearly;
        },
        debugOutputs: [{ table: OutputCategory.System }],
    },
};
