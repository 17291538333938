/* tslint:disable:variable-name function-name */

import moment from 'moment';

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { Month } from 'reports/utils/time';

import { schema } from 'reports/models/schema';
import type { Design } from 'reports/models/design';
import type { Scenario } from 'reports/models/scenario';

import request from 'reports/modules/request';

export interface ISimResultsSummary {
    poa_irradiance: number;
    shaded_irradiance: number;
    total_irradiance: number;
    nameplate_power: number;
    mpp_power: number;
    power: number;
}

export interface IModuleLevelResults {
    [fieldModuleId: number]: {
        [month: number]: ISimResultsSummary; // month is 1 .. 12
    };
}

class Simulation extends BaseClass {
    simulation_id: number;

    design_id: number;
    design: Design;

    scenario_id: number;
    scenario: Scenario;

    created_timestamp?: moment.Moment;
    last_modified?: moment.Moment;

    metadata: SimulationMetadata;
    status: 'pending' | 'initialized' | 'completed' | 'processing' | 'error';
    simulation_metadata: {
        shading_engine: string;
        version: string;
    };

    constructor(data) {
        super(Simulation.deserializer(data));
    }

    complete() {
        const metadata = this.metadata;
        if (!metadata || !metadata.total_slices) return false;
        return metadata.total_slices === metadata.complete_slices;
    }

    static deserializer = BaseClass.getDeserializer({
        last_modified: (x) => moment(x),
        created_timestamp: (x) => moment(x),
        metadata: (data) => new SimulationMetadata(data),
    });
}

class SimulationMetadata extends BaseClass {
    ac_power: number;
    actual_dc_power: number;
    actual_dc_voltage: number;
    adjusted_ghi: number;
    avg_ambient_temp: number;
    avg_cell_temp: number;
    complete_slices: number;
    effective_irradiance: number;
    error_slices: number;
    finalized: boolean;
    global_horizontal_irradiance: number;
    grid_power: number;
    inverter_overpower_loss: number;
    inverter_overvoltage_loss: number;
    inverter_underpower_loss: number;
    inverter_undervoltage_loss: number;
    module_irradiance_derated_power: number;
    module_level_data_id: number;
    module_mpp_power: number;
    module_power: number;
    monthly_data: {
        [k in Month]: {
            global_horizontal_irradiance: number;
            grid_power: number;
            nameplate_power: number;
            poa_irradiance: number;
            shaded_irradiance: number;
            total_irradiance: number;
        };
    };
    nameplate_power: number;
    optimal_dc_power: number;
    optimal_dc_voltage: number;
    optimization_end: moment.Moment;
    optimization_start: moment.Moment;
    optimizer_input_power: number;
    optimizer_output_power: number;
    pending_slices: number;
    poa_irradiance: number;
    s3_bucket: string;
    s3_key: string;
    shaded_irradiance: number;
    simulation_id: number;
    soiled_irradiance: number;
    total_irradiance: number;
    total_slices: number;
    version: string;

    constrainedDCOutput() {
        return (
            this.optimal_dc_power -
            this.inverter_overpower_loss -
            this.inverter_underpower_loss -
            this.inverter_overvoltage_loss -
            this.inverter_undervoltage_loss
        );
    }

    constructor(data) {
        super(SimulationMetadata.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        optimization_end: (x) => moment(x),
        optimization_start: (x) => moment(x),
    });
}

const schemaObj = schema.addObject(Simulation, 'simulation');

const endpoint = ReduxEndpoint.fromSchema('/api/simulations/', schemaObj);

const api = {
    index: endpoint.index<{
        scenario_id?: number;
        design_id?: number;
        project_id?: number;
        detail?: boolean;
    }>(),
    get: endpoint.get<{ simulation_id: string | number }>('{simulation_id}'),
    create: endpoint.post<{ scenario_id: number; design_id: number }>(),
    save: endpoint.put('{simulation_id}'),
    delete: endpoint.delete('{simulation_id}'),

    find_optimal_tilt_azimuth: endpoint.post<
        {
            project_id: number;
            weather_dataset_id: number;
            module_characterization_id: number;
        },
        undefined,
        { channel: number }
    >('find_optimal_tilt_azimuth', ReduxEndpoint.PassThroughConfig()),
    global_single_diode_hourly: endpoint.post<any, any, any>('global_single_diode_hourly'),

    hourly_results_zip: endpoint.get<{ simulation_id: string | number }, Blob>(
        '{simulation_id}/hourly',
        ReduxEndpoint.PassThroughConfig('blob'),
    ),

    module_level_results: async (simulationId: number | string) => {
        const response = await request.get(`/api/simulations/${simulationId}/module_level`);
        return response.body as IModuleLevelResults;
    },
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { schemaObj, Simulation, SimulationMetadata, selectors, api, endpoint };
