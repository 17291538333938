import { chain, get, mapValues, sumBy } from 'lodash';

import { IReportContext } from 'reports/modules/report/widgets';
import { createMultipleInstanceTokens, IGenericTokenConfig, ITokenMap } from './index';

export interface IDynamicTokenConfig extends Omit<IGenericTokenConfig, 'path'> {
    path: string;
    subpath: string; // object path to initial token property instance(s)
    uniqIdPath: string; // object path to unique indentifier prop used to group instances for summation
    countPath?: string; // object path to numeric property used in summation
}

// Adds the 'selector' field to match the key. Does type checking of the value type. Preserves the key union type by
// type inference.
// This is very similar to `makeTokenCfgMap` in declarativeTokens.ts, but can't be pulled out into a generic function
// because Typescript doesn't support partially inferring generics.
const makeTokenCfgMap = <Selectors extends string>(tokenMap: {
    [Selector in Selectors]: Omit<IDynamicTokenConfig, 'selector'>;
}) => mapValues(tokenMap, (config, key) => ({ ...config, selector: key }));

const DYNAMIC_TOKENS = makeTokenCfgMap({
    // Design
    inverter_manufacturer: {
        category: 'design',
        path: 'design.wiring_zones',
        subpath: 'inverter.manufacturer',
        countPath: 'field_component_metadata.inverter[0].count',
        uniqIdPath: 'inverter_id',
        formatter: 'text',
        description: 'Inverter manufacturer',
    },
    inverter_model: {
        category: 'design',
        path: 'design.wiring_zones',
        subpath: 'inverter.name',
        countPath: 'field_component_metadata.inverter[0].count',
        uniqIdPath: 'inverter_id',
        formatter: 'text',
        description: 'Inverter model name',
    },
    inverter_quantity: {
        category: 'design',
        path: 'design.wiring_zones',
        subpath: 'field_component_metadata.inverter[0].count',
        countPath: 'field_component_metadata.inverter[0].count',
        uniqIdPath: 'inverter_id',
        formatter: 'integer',
        description: 'Inverter count',
    },
    inverter_wattage: {
        category: 'design',
        path: 'design.wiring_zones',
        subpath: 'inverter.max_power',
        countPath: 'field_component_metadata.inverter[0].count',
        uniqIdPath: 'inverter_id',
        formatter: 'power',
        description: 'Inverter wattage',
    },
    module_manufacturer: {
        category: 'design',
        path: 'design.field_segments',
        subpath: 'module_characterization.module.manufacturer',
        countPath: 'data.modules',
        uniqIdPath: 'module_characterization.module_characterization_id',
        formatter: 'text',
        description: 'Module manufacturer',
    },
    module_model: {
        category: 'design',
        path: 'design.field_segments',
        subpath: 'module_characterization.module.name',
        countPath: 'data.modules',
        uniqIdPath: 'module_characterization.module_characterization_id',
        formatter: 'text',
        description: 'Module model name',
    },
    module_power: {
        category: 'design',
        path: 'design.field_segments',
        subpath: 'module_characterization.power',
        countPath: 'data.modules',
        uniqIdPath: 'module_characterization.module_characterization_id',
        formatter: 'power',
        description: 'Module power',
    },
    module_quantity: {
        category: 'design',
        path: 'design.field_segments',
        subpath: 'data.modules',
        countPath: 'data.modules',
        uniqIdPath: 'module_characterization.module_characterization_id',
        formatter: 'integer',
        description: 'Module quantity',
    },
});

export type DynTokenSelector = keyof typeof DYNAMIC_TOKENS;

export function createDynamicTokens(context: IReportContext, tokens: ITokenMap): ITokenMap {
    for (const tokenConfig of Object.values(DYNAMIC_TOKENS)) {
        const { countPath, path, selector, subpath, uniqIdPath } = tokenConfig;

        let valArr: object[] = get(context, path);
        if (countPath != null) {
            valArr = chain(valArr)
                .groupBy((each) => get(each, uniqIdPath || subpath))
                .map((group) => {
                    const count = sumBy(group, countPath);
                    return {
                        count,

                        // use total count as token value if quantity based, otherwise use first (assumingly common)
                        // subpath value
                        [subpath]: selector.includes('_quantity') ? count : get(group[0], subpath),
                    };
                })
                .orderBy('count', 'desc')
                .value();
        }

        createMultipleInstanceTokens(valArr, tokenConfig, tokens);
    }

    return tokens;
}
