import { get, mapValues } from 'lodash';

import * as fmt from 'reports/utils/formatters';
import { perfRatio } from 'reports/utils/metrics';

import { TOKEN_FUNCTIONS } from 'reports/modules/financials/model/tokens';
import { hasOutput, MaybeFinOutput } from 'reports/modules/financials/state';
import { IReportContext } from 'reports/modules/report/widgets';

import { createToken, getTokenVal, IGenericTokenConfig, ITokenMap } from './';

// Adds the 'selector' field to match the key. Does type checking of the value type. Preserves the key union type by
// type inference.
// This is very similar to `makeTokenCfgMap` in dynamicTokens.ts, but can't be pulled out into a generic function
// because Typescript doesn't support partially inferring generics.
const makeTokenCfgMap = <Selectors extends string>(tokenMap: {
    [Selector in Selectors]: Omit<IGenericTokenConfig, 'selector'>;
}) => mapValues(tokenMap, (config, key) => ({ ...config, selector: key }));

const markNoFinancials = (func) => {
    func.omitFinancials = true;
    return func;
};

const DECLARATIVE_TOKENS = makeTokenCfgMap({
    // internal use
    system_life_start: {
        hidden: true,
        category: 'project',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.system_life_start(financialTokens),
        formatter: (val) => (val ? val.format('MMMM YYYY') : 'N/A'),
        description: '',
    },

    // Consumption
    avg_utility_bill_monthly_presolar: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.avg_utility_bill_monthly_presolar(financialTokens),
        formatter: 'currency',
        description: 'Average monthly electricity bill (year 1)',
    },
    avg_utility_bill_monthly_postsolar: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.avg_utility_bill_monthly_postsolar(financialTokens),
        formatter: 'currency',
        description: 'Average monthly electricity bill',
    },
    total_utility_bill_yearly_presolar: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.total_utility_bill_yearly_presolar(financialTokens),
        formatter: 'currency',
        description: 'Annual electricity bill',
    },
    total_utility_bill_yearly_postsolar: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.total_utility_bill_yearly_postsolar(financialTokens),
        formatter: 'currency',
        description: 'Annual electricity bill with solar',
    },
    total_utility_bill_lifetime_presolar: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.total_utility_bill_lifetime_presolar(financialTokens),
        formatter: 'currency',
        description: 'Lifetime energy costs',
    },
    total_savings_yearly: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.total_savings_yearly(financialTokens),
        formatter: 'currency',
        description: 'Annual electricity bill with solar',
    },
    total_savings_monthly: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.total_savings_monthly(financialTokens),
        formatter: 'currency',
        description: 'Annual electricity bill with solar',
    },
    lifetime_savings: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.lifetime_savings(financialTokens),
        formatter: 'currency',
        description: 'Annual electricity bill with solar',
    },
    avg_cost_per_kwh: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.avg_cost_per_kwh(financialTokens),
        formatter: 'currency',
        description: 'Cost per kWh',
    },
    avg_cost_per_kwh_lifetime: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.avg_cost_per_kwh_lifetime(financialTokens),
        formatter: 'currency',
        description: 'Cost per kWh',
    },
    avg_consumption_monthly: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.avg_consumption_monthly(financialTokens),
        formatter: 'energy',
        description: 'Average monthly energy consumption',
    },
    total_consumption_yearly: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.total_consumption_yearly(financialTokens),
        formatter: 'energy',
        description: 'Annual energy consumption',
    },
    total_consumption_yearly_with_solar: {
        category: 'consumption',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.total_consumption_yearly_with_solar(financialTokens),
        formatter: 'energy',
        description: 'Annual energy consumption with solar',
    },

    // Design
    annual_production: {
        category: 'design',
        path: 'simulation.metadata.grid_power',
        formatter: 'energy',
        description: 'Annual energy production',
    },
    design_name: {
        category: 'design',
        path: 'design.description',
        formatter: 'text',
        description: 'Name of design',
    },
    design_nameplate_ac: {
        category: 'design',
        path: 'design.field_component_metadata.ac_nameplate',
        formatter: 'power',
        description: 'Total AC nameplate rating',
    },
    design_nameplate_dc: {
        category: 'design',
        path: 'design.field_component_metadata.nameplate',
        formatter: 'power',
        description: 'Total DC nameplate rating',
    },
    module_quantity_total: {
        category: 'design',
        path: 'design.moduleCount',
        formatter: 'integer',
        description: 'Total module quantity',
    },
    system_yield: {
        category: 'design',
        path: markNoFinancials(({ design, simulation }) =>
            get(simulation, 'metadata.grid_power') != null
                ? simulation.metadata.grid_power / design.field_component_metadata.nameplate
                : null,
        ),
        formatter: 'number',
        description: 'System yield (kWh/kWp)',
    },

    // Financials
    cost_per_watt_ac: {
        category: 'financials',
        path: ({ financialTokens, design, simulation }) => {
            return get(simulation, 'metadata.grid_power') != null
                ? -TOKEN_FUNCTIONS.system_total_cost(financialTokens) / design.field_component_metadata.ac_nameplate
                : null;
        },
        formatter: 'currency',
        description: 'Cost per watt AC',
    },
    cost_per_watt_dc: {
        category: 'financials',
        path: ({ financialTokens, design, simulation }) => {
            return get(simulation, 'metadata.grid_power') != null
                ? -TOKEN_FUNCTIONS.system_total_cost(financialTokens) / design.field_component_metadata.nameplate
                : null;
        },
        formatter: 'currency',
        description: 'Cost per watt DC',
    },
    irr: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.irr(financialTokens),
        formatter: 'percent',
        description: 'Internal rate of return (IRR)',
    },
    lcoe: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.lcoe(financialTokens),
        formatter: (val, options) => `${fmt.currency(val, { displayOnly: true, ...options })} / KWh`,
        description: 'Levelized cost of energy',
    },
    // incentives_federal: {
    //     category: 'financials',
    //     path: 'financials.annualResults[0].taxCredit',
    //     formatter: 'currency',
    //     description: 'Federal Incentives',
    // },
    // incentives_local: {
    //     category: 'financials',
    //     path: '',
    //     formatter: (_val: any) => `$0.00`,
    //     description: 'State/Local Incentives',
    // },
    incentives_total: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.incentives_total(financialTokens),
        formatter: 'currency',
        description: 'Total incentives',
    },
    // net_incentives: {
    //     category: 'financials',
    //     path: '',
    //     formatter: (val: any) => `${val}`,
    //     description: 'Net of incentives',
    //     disable: true,
    // },
    offset_bill: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.offset_bill(financialTokens),
        formatter: 'percent',
        description: 'Percent offset of electricity bill',
    },
    offset_energy: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.offset_energy(financialTokens),
        formatter: 'percent',
        description: 'Annual offset consumption',
    },
    payback_period: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.payback_period(financialTokens),
        formatter: 'duration_years',
        description: 'Payback period (in years)',
    },
    ppa_rate: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.ppa_rate(financialTokens),
        formatter: 'currency',
        description: 'PPA rate, if applicable',
    },
    ppa_term_years: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.ppa_term_years(financialTokens),
        formatter: 'duration_years',
        description: 'PPA term in years',
    },
    roi: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.roi(financialTokens),
        formatter: 'percent',
        description: 'Return on investment (ROI)',
    },
    system_net_costs: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.system_net_costs(financialTokens),
        formatter: (val, { precision = 0, ...opts }) => fmt.currency(val, { precision, ...opts }),
        description: 'Net system cost',
    },
    system_upfront_costs: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.system_upfront_costs(financialTokens),
        formatter: (val, { precision = 0, ...opts }) => fmt.currency(val, { precision, ...opts }),
        description: 'Net system cost',
    },
    system_total_cost: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.system_total_cost(financialTokens),
        formatter: (val, { precision = 0, ...opts }) => fmt.currency(val, { precision, ...opts }),
        description: 'Total system cost',
    },
    system_npv_cash: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.system_npv_cash(financialTokens),
        formatter: 'currency',
        description: 'Lifetime value (NPV)',
    },
    system_npv_energy: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.system_npv_energy(financialTokens),
        formatter: 'currency',
        description: 'Lifetime energy production (NPV), kWh',
    },
    system_performance_ratio: {
        category: 'financials',
        path: ({ design, simulation }) => perfRatio(design, simulation),
        formatter: 'percent',
        description: 'System performance ratio',
    },
    financing_costs_monthly: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.financing_costs_monthly(financialTokens),
        formatter: 'currency',
        description: 'Monthly loan payment',
    },
    avg_full_cost_monthly: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.avg_full_cost_monthly(financialTokens),
        formatter: 'currency',
        description: 'Average monthly cost (financing + utility bill in year 1)',
    },
    utility_rate_escalation: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.utility_rate_escalation(financialTokens),
        formatter: 'percent',
        description: 'Annual rate of utility rate escalation',
    },
    discount_rate: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.discount_rate(financialTokens),
        formatter: 'percent',
        description: 'Annual discount rate',
    },
    annual_degradation: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.annual_degradation(financialTokens),
        formatter: 'percent',
        description: 'Annual rate of degradation',
    },
    annual_maintenance_cost: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.annual_maintenance_cost(financialTokens),
        formatter: 'currency',
        description: 'Annual maintenance cost',
    },

    // Environmental
    co2_kg: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.co2_kg(financialTokens),
        formatter: 'number',
        description: 'Carbon Dioxide (kg)',
    },
    driven_vehicle: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.driven_vehicle(financialTokens),
        formatter: 'number',
        description: 'Passenger vehicles driven for one year',
    },
    driven_km: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.driven_km(financialTokens),
        formatter: 'number',
        description: 'Km driven by an average passenger vehicle',
    },
    recycled_ton: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.recycled_ton(financialTokens),
        formatter: 'number',
        description: 'Tons of waste recycled instead of landfilled',
    },
    recycled_truck: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.recycled_truck(financialTokens),
        formatter: 'number',
        description: 'Garbage trucks of waste recycled instead of landfilled',
    },
    gasoline_liter: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.gasoline_liter(financialTokens),
        formatter: 'number',
        description: 'Gasoline (liter) consumed',
    },
    gasoline_tanker: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.gasoline_tanker(financialTokens),
        formatter: 'number',
        description: 'Gasoline (tanker trucks) consumed',
    },
    energy_annual_home: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.energy_annual_home(financialTokens),
        formatter: 'number',
        description: 'Home energy use for one year',
    },
    electricity_annual_home: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.electricity_annual_home(financialTokens),
        formatter: 'number',
        description: 'Homes electricity use for one year',
    },
    led_bulb: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.led_bulb(financialTokens),
        formatter: 'number',
        description: 'Incandescent lamps switched to LEDs',
    },
    oil_barrel: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.oil_barrel(financialTokens),
        formatter: 'number',
        description: 'Barrels of oil consumed',
    },
    propane_can: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.propane_can(financialTokens),
        formatter: 'number',
        description: 'Propane cylinders used for home barbeques',
    },
    coal_kg: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.coal_kg(financialTokens),
        formatter: 'number',
        description: 'Coal burned (kg)',
    },
    coal_railcar: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.coal_railcar(financialTokens),
        formatter: 'number',
        description: 'Coal burned (railcars)',
    },
    seedling_ten_year: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.seedling_ten_year(financialTokens),
        formatter: 'number',
        description: 'Tree seedlings grown for 10 years',
    },
    forest_hectare: {
        category: 'financials',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.forest_hectare(financialTokens),
        formatter: 'number',
        description: 'Hectares of forests in one year',
    },

    // Project
    project_address: {
        category: 'project',
        path: 'project.address',
        formatter: 'text',
        description: 'Project address',
    },
    project_created: {
        category: 'project',
        path: 'project.created',
        formatter: 'date',
        description: 'Project creation date',
    },
    project_creator_company: {
        category: 'project',
        path: 'project.creator.company',
        formatter: 'text',
        description: 'Project creator company',
    },
    project_creator_email: {
        category: 'project',
        path: 'project.creator.email',
        formatter: 'text', // potentially, link
        description: 'Project creator email',
    },
    project_creator_firstname: {
        category: 'project',
        path: 'project.creator.first_name',
        formatter: 'text',
        description: 'Project creator first name',
    },
    project_creator_fullname: {
        category: 'project',
        path: markNoFinancials(({ project }) => project.creator.fullName()),
        formatter: 'text',
        description: "Project creator's full name",
    },
    project_creator_lastname: {
        category: 'project',
        path: 'project.creator.last_name',
        formatter: 'text',
        description: 'Project creator last name',
    },
    project_name: {
        category: 'project',
        path: 'project.name',
        formatter: 'text',
        description: 'Project name',
    },
    system_lifetime_years: {
        category: 'project',
        path: ({ financialTokens }) => TOKEN_FUNCTIONS.system_lifetime_years(financialTokens),
        formatter: 'duration_years',
        description: 'System lifetime in years',
    },
});

export type DeclTokenSelector = keyof typeof DECLARATIVE_TOKENS;

const DEPRECATED_SELECTORS: { [key: string]: DeclTokenSelector } = {
    avg_bill_monthly: 'avg_utility_bill_monthly_presolar',
    avg_bill_monthly_with_solar: 'avg_utility_bill_monthly_postsolar',
    total_bill_yearly: 'total_utility_bill_yearly_presolar',
    total_bill_yearly_with_solar: 'total_utility_bill_yearly_postsolar',
    total_bill_lifetime: 'total_utility_bill_lifetime_presolar',
};

const DEPRECATED_TOKENS = mapValues(DEPRECATED_SELECTORS, (newSelector, deprSelector) => ({
    ...DECLARATIVE_TOKENS[newSelector],
    selector: deprSelector, // 'selector' field has to match the key
    hidden: true, // don't show deprecated tokens in auto-complete
}));

const DECLARATIVE_TOKENS_WITH_DEPRECATED = {
    ...DEPRECATED_TOKENS,
    ...DECLARATIVE_TOKENS,
};

export function createDeclarativeTokens(
    context: IReportContext,
    tokens: ITokenMap,
    filter?: DeclTokenSelector[],
): ITokenMap {
    for (const tokenConfig of Object.values(DECLARATIVE_TOKENS_WITH_DEPRECATED)) {
        const { path, selector } = tokenConfig;
        if (filter && !filter.includes(selector as DeclTokenSelector)) {
            continue;
        }

        const tokenVal = getTokenVal(context, path);
        tokens[selector] = createToken(tokenConfig, tokenVal);
    }

    return tokens;
}

const UNKNOWN_TOKEN = '(unknown token)';

export function finTokenValue(financialTokens: MaybeFinOutput, id: DeclTokenSelector, locale) {
    const token = createToken(
        DECLARATIVE_TOKENS[id],
        hasOutput(financialTokens) ? TOKEN_FUNCTIONS[id](financialTokens) : financialTokens.status,
    );
    return token.format({ locale });
}

export function lookupTokenValue(tokens: ITokenMap | undefined, id: DeclTokenSelector, locale: string) {
    if (tokens == null) {
        return '(tokens loading...)';
    }
    if (!tokens[id]) {
        return UNKNOWN_TOKEN;
    }
    return tokens[id].format({ locale });
}
