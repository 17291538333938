import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import Translations from 'reports/localization/strings';
import * as des from 'reports/models/design';
import Image from 'reports/components/helpers/Image';
import { bindActions } from 'reports/utils/redux';

interface IOwnProps {
    design: des.Design;
    intl: IntlShape;
}

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IOwnProps & IDispatchProps;

class DesignRenderIntl extends React.PureComponent<IProps> {
    rerenderDesign() {
        // Re-render design as a JPG if its current render image is a PNG to optimize future
        // loads of this image on the Project Overview page
        const { design, triggerRender } = this.props;
        if (design.s3_key) {
            if (design.s3_key.slice(-3) === 'png') {
                triggerRender(design);
            }
        }
    }

    componentDidMount() {
        this.rerenderDesign();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.design.design_id !== this.props.design.design_id) {
            // if user selects a different design render to display
            this.rerenderDesign();
        }
    }

    render() {
        const { design, intl } = this.props;

        return (
            <Image
                src={design.render_url}
                errorMsg={intl.formatMessage(Translations.errors.design_not_found)}
                loading={design.currently_rendering}
            />
        );
    }
}

const mapDispatchToProps = bindActions({
    triggerRender: (design) => des.api.trigger_render({ design_id: design.design_id }),
});

const DesignRender = injectIntl(DesignRenderIntl);

export default connect(null, mapDispatchToProps)(DesignRender);
