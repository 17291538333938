import { noop } from 'lodash';
import Logger from 'js-logger';
import Q from 'q';
import Pusher from 'pusher-js';

import { fetchJSON } from 'helioscope/app/utilities/relational/resource';

const logger = Logger.get('pusher');

let _pusher;
let _appSync;

export function initApp(parameters, appSync = noop) {
    _pusher = new Pusher(parameters.pusher_key, {
        cluster: parameters.pusher_cluster,
        authEndpoint: `${parameters.url_root}/api/pusher/auth`,
        encrypted: true,
    });

    _appSync = appSync;
}


const outstandingChannels = {};
const channelCallbacks = new WeakMap();

function increment(object, key, inc) {
    const oldVal = object[key] || 0;
    const newVal = oldVal + inc;
    object[key] = newVal;
    return newVal;
}


/**
 * return a function that automatically subscribes to a pusher channel and lets you
 * add watchers that subscribe to pusher events
 */
export function makeChannel(channelName) {
    let channel = _pusher.channel(channelName);
    increment(outstandingChannels, channelName, 1);

    if (!channel) {
        logger.debug(`Subscribing to Pusher channel ${channelName}`);
        channel = _pusher.subscribe(channelName);
        channelCallbacks.set(channel, {});
    }

    const callbacks = channelCallbacks.get(channel);

    return {
        watch(eventName, callback) {
            const listener = (data) => {
                callback(data);
                _appSync();
            };

            channel.bind(eventName, listener);
            callbacks[eventName] = listener;

            return () => {
                channel.unbind(eventName, listener);
                delete callbacks[eventName];
            };
        },
        triggerLocal(eventName, data) {
            callbacks[eventName](data);
        },
        unsubscribe() {
            if (increment(outstandingChannels, channelName, -1) === 0) {
                logger.info(`Unsubscribing from Pusher channel ${channelName}`);
                _pusher.unsubscribe(channelName);
            }
        },
    };
}

export function promiseFromChannel(channelName, onProgress = evt => logger.info(`${channelName}.progress`, evt)) {
    const channel = makeChannel(channelName);
    const processed = new Set();

    const watch = (eventName, callback) => {
        channel.watch(eventName, async ({ id, message, needs_fetch: fetch }) => {
            if (processed.has(id)) {
                logger.info(`Ignoring already processed message ${channelName}.${eventName}.${id}`);
            }

            processed.add(id);

            if (fetch === false) {
                callback(message);
            } else {
                logger.info(`${channelName}.${eventName} message was too large, fetching ${message}`);
                const { data } = await fetchJSON(message);
                callback(data);
            }
        });
    };

    const close = channel.watch('pusher:subscription_succeeded', async () => {
        try {
            const { data } = await fetchJSON(`/api/pusher/history/${channelName}`);
            for (const { event, payload } of data) {
                logger.info(`${channelName}.${event} processessing event from history`);
                channel.triggerLocal(event, payload);
            }
        } catch (err) {
            logger.warn(err);
        } finally {
            close();
        }
    });

    const deferred = Q.defer();

    watch('success', message => deferred.resolve(message));
    watch('failure', message => deferred.reject(message));
    watch('progress', message => onProgress(message));

    deferred.promise.finally(() => channel.unsubscribe());

    return deferred.promise;
}
