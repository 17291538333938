import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddProjectsDialog, IntervalPrices } from './AddProjectsDialog';
import * as price from 'reports/models/stripe/price';
import * as cfg from 'reports/config';
import { IAppState } from 'reports/types';
import * as auth from 'reports/modules/auth';

interface AddProjectsDialog {
    onClose: () => void;
    onOpen?: () => void;
    routeName?: string;
    searchParams?: { [x: string]: string };
}

const AddProjectsDialogContainer = ({ onClose, onOpen, routeName, searchParams }: AddProjectsDialog) => {
    const [isOpen, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [subscriptionIntervalPrices, setSubscriptionIntervalPrices] = React.useState<IntervalPrices>({});

    const user = useSelector((state) => auth.selectors.getUser(state))!;
    const config = useSelector((state: IAppState) => cfg.selectors.getConfig(state));

    const dispatch = useDispatch();
    const getPrices = async ({ product_id }) => dispatch(price.api.index({ product_id }));

    const subscription = user.subscription;
    const product = subscription ? config?.product_metadata[subscription?.product_key] : undefined;

    const loadPrices = React.useCallback(async () => {
        const prices = await getPrices({
            product_id: product?.v2_stripe_id,
        });

        const intervalPrices = prices.reduce<IntervalPrices>((acc, price) => {
            acc[price.recurring.interval] = price.unit_amount;
            return acc;
        }, {});

        setSubscriptionIntervalPrices(intervalPrices);
        setIsLoading(false);
    }, [subscription]);

    React.useEffect(() => {
        if (searchParams?.dialog === 'add_projects') {
            setIsLoading(true);
            setOpen(true);
            onOpen && onOpen();
            loadPrices();
        } else {
            setOpen(false);
        }
    }, [searchParams?.dialog]);

    const handleClose = React.useCallback(() => {
        setOpen(false);
        onClose();
    }, [routeName, searchParams]);

    const licenseCount = subscription ? subscription.paid_seats : 0;
    const isAnnualSubscription = subscription?.plan_type === 'year';

    return (
        <AddProjectsDialog
            intervalPrices={subscriptionIntervalPrices}
            isOpen={isOpen}
            isAdmin={user.team_admin}
            isAnnualSubscription={isAnnualSubscription}
            isLoading={isLoading}
            licenseCount={licenseCount}
            product={product}
            onClose={handleClose}
        />
    );
};

export { AddProjectsDialogContainer };
