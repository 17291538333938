import * as React from 'react';
import { connect } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { ContextBarControls } from 'reports/components/ContextBar';
import SaveDropdown from 'reports/components/library/SaveDropdown';
import UndoRedoManager from 'reports/components/library/UndoRedoManager';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';
import * as repCfg from 'reports/models/report/ReportConfiguration';

import * as auth from 'reports/modules/auth';
import { addPromiseToasts } from 'reports/modules/Toaster';

import { IAppState } from 'reports/store';

import { RouterStateLock } from 'reports/utils/router';
import { bindActions } from 'reports/utils/redux';

import DocumentEditor from '../components/DocumentEditor';
import ReportUndoRedoButtons from '../components/ReportUndoRedoButtons';
import UpdateReportConfigControls from '../components/UpdateReportConfigControls';
import ViewReportTemplateButton from '../components/ViewReportTemplateButton';

interface IOwnProps {
    report: rep.Report;
    project: proj.Project;
    printableReady: Promise<any>;
}

type IProps = IOwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

interface IUndoRedoData {
    document: rep.Document;
}

const ReportConfigurationEditor: React.FC<IProps> = (props) => {
    const { reportConfig, project, printableReady, saveRepConfig, goToProjectReportView } = props;
    const { report, report_configuration_id } = reportConfig;
    const baseDocument = React.useMemo<rep.Document>(() => reportConfig.makeDocument(), [reportConfig]);

    return (
        <UndoRedoManager<IUndoRedoData>
            baseData={{ document: baseDocument }}
            hasChangesFrom={(oldData, newData) => newData.document.hasChangesFrom(oldData.document)}
            hotkeyGroup="Configure Report"
        >
            {({ data: { document }, setData, undo, redo, hasChanges, canUndo, canRedo, clearChanges }) => {
                const save = async () => {
                    const newRepCfg = await saveRepConfig({
                        report_configuration_id,
                        configuration_data: rep.ReportConfiguration.makeConfigData(document),
                    });
                    await clearChanges();
                    return newRepCfg;
                };

                const saveWithToast = () =>
                    addPromiseToasts(save(), {
                        initial: `Saving ${report.name} configuration`,
                        onCatch: `Error saving ${report.name} configuration`,
                        onSuccess: `Successfully saved ${report.name} configuration`,
                    });
                const setDocument = (newDoc: rep.Document) => setData({ document: newDoc });

                return (
                    <>
                        <RouterStateLock
                            title="Save configuration changes?"
                            prompt={
                                `You have unsaved changes on the configuration of ${report.name}` +
                                `. Would you like to save these changes before leaving?`
                            }
                            cancellable={true}
                            showExitPrompt={hasChanges}
                            onSave={() => saveWithToast()}
                            onDontSave={clearChanges}
                        />
                        <ContextBarControls>
                            <ViewReportTemplateButton project={project} report={report} />
                            <ReportUndoRedoButtons undo={undo} canUndo={canUndo()} redo={redo} canRedo={canRedo()} />
                            <SaveDropdown
                                saveEnabled={hasChanges()}
                                save={() => {
                                    // Don't use saveWithToast() here. SaveDropdown does its own toast handling.
                                    return save().then(() => goToProjectReportView());
                                }}
                                cancel={async () => {
                                    await clearChanges();
                                    goToProjectReportView();
                                }}
                            />
                        </ContextBarControls>
                        <DocumentEditor
                            mode="configure"
                            report={report}
                            document={document}
                            setDocument={setDocument}
                            project={project}
                            printableReady={printableReady}
                            controls={<UpdateReportConfigControls reportConfig={reportConfig} />}
                        />
                    </>
                );
            }}
        </UndoRedoManager>
    );
};

const mapStateToProps = (state: IAppState, { project, report }: IOwnProps) => ({
    user: auth.selectors.getUser(state)!,
    reportConfig: rep.repCfgSelectors.configForProject(state, report, project.project_id)!,
});

const mapDispatchToProps = bindActions(({ project, report }: IOwnProps) => ({
    saveRepConfig: repCfg.api.save,
    goToProjectReportView: () =>
        routerActions.navigateTo('app.projects.project.report.view', {
            reportId: report.report_id,
            slug: report.slug,
            projectId: project.project_id,
        }),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ReportConfigurationEditor);
