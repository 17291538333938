import { chain } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { Button, Classes, Intent, Menu, MenuItem, Popover, Position, MenuDivider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { actions as routerActions } from 'redux-router5';

import { humanizeWatts } from 'reports/utils/formatters';
import { bindActions } from 'reports/utils/redux';

import { selectors, Project } from 'reports/models/project';
import { createDesignDialog } from './NewDesignForm';

import * as des from 'reports/models/design';

interface IOwnProps {
    project: Project;
    onClickOverride?: (design: des.Design) => any;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

type IProps = IOwnProps & IStateProps & IDispatchProps;

const BaseDesignMenu: React.FC<IProps> = (props) => {
    const { project, designs, activeDesign, navigateToDesigner, loadDesigns } = props;
    const { onClickOverride, children } = props;

    React.useEffect(() => {
        loadDesigns(project);
    }, [project.project_id]);

    const onClick = onClickOverride != null ? onClickOverride : navigateToDesigner;

    const designRows =
        designs == null || designs.length === 0
            ? [<MenuItem key={'none'} disabled={true} text={'No designs loaded...'} />]
            : chain(designs)
                  .sortBy('description')
                  .filter((design) => !design.to_delete)
                  .map((design) => (
                      <MenuItem
                          key={design.design_id}
                          text={design.description}
                          label={humanizeWatts(design.nameplate, {
                              precision: 1,
                          })}
                          onClick={() => onClick(design)}
                          disabled={design === activeDesign}
                      />
                  ))
                  .value();

    designRows.push(
        <MenuDivider key="divider" />,
        <div key="new_button" style={{ padding: '4px' }}>
            <Button
                text="Add New Design"
                icon={IconNames.ADD}
                onClick={async () => {
                    try {
                        const design = await createDesignDialog(project);
                        navigateToDesigner(design);
                    } catch (exc) {
                        console.warn(exc);
                    }
                }}
                style={{ width: '100%' }}
                intent={Intent.WARNING}
                className={Classes.POPOVER_DISMISS_OVERRIDE}
            />
        </div>,
    );

    const content = <Menu>{designRows}</Menu>;
    return (
        <Popover position={Position.BOTTOM} content={content}>
            {children}
        </Popover>
    );
};

const mapStateToProps = (state, { project }) => ({
    designs: selectors.designs(state, project),
    activeDesign: selectors.primaryDesign(state, project),
});

const mapDispatchToProps = bindActions({
    navigateToDesigner: (design) =>
        routerActions.navigateTo('app.projects.project.designer', {
            projectId: design.project_id,
            designId: design.design_id,
            subpath: 'field_segments',
        }),
    loadDesigns: (project) => des.api.index({ project_id: project.project_id }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseDesignMenu);
