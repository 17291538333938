import _ from 'lodash';
import Logger from 'js-logger';
import Q from 'q';

import { $http, $templateCache, $document, $rootScope, $compile, $controller } from 'helioscope/app/utilities/ng';

const logger = Logger.get('helpers');

export const KEY = {
    ESC: 27,
    ALT: 18,
    SPACE: 32,
    UP: 38, // arrow up
    DOWN: 40, // arrow down
    BACKSPACE: 8,
    DELETE: 46,
    a: 65,
    e: 69,
    r: 82,
    s: 83,
    t: 84,
    y: 89,
    z: 90,
    c: 67,
    v: 86,
};

export class PerformanceTimer {
    constructor() {
        this.total = 0;
    }

    start() {
        this.t0 = performance.now();
    }

    stop() {
        this.t1 = performance.now();
        this.elapsed = this.t1 - this.t0;
        this.total += this.elapsed;
        return this.elapsed;
    }

    elapsedSeconds() {
        return this.elapsed * 0.001;
    }

    totalSeconds() {
        return this.total * 0.001;
    }
}

export function csvToArray(text) {
    const split = text
        .replace('\r\n', '\n')
        .replace('\r', '\n')
        .split('\n')
        .map((i) => i.trim());
    const filtered = _.filter(split, (i) => i.length);

    const rawrows = filtered.map((i) => i.split(','));
    const fields = rawrows[0];
    rawrows.shift();

    const textobjs = rawrows.map(() => ({}));

    for (let i = 0; i < fields.length; ++i) {
        const field = fields[i];

        for (let j = 0; j < rawrows.length; ++j) {
            textobjs[j][field] = rawrows[j][i];
        }
    }

    return textobjs;
}

export function scrollToChild(child) {
    if (child && child.offsetParent) {
        child.offsetParent.scrollTop = child.offsetTop;
    }
}

export function truthyZero(val) {
    if (val === 0) return true;
    return val;
}

export function plainXY(vec) {
    return { x: vec.x, y: vec.y };
}

export function plainXYZ(vec) {
    return { x: vec.x, y: vec.y, z: vec.z || 0 };
}

export function padZeroes(str, digits) {
    let out = str;
    while (out.length < digits) out = `0${out}`;
    return out;
}

// in chrome debug console type in debug.[key]
export function registerDebugProperty(key, value) {
    if (!window.debug) window.debug = {};
    window.debug[key] = value;
}

export function arrayUnorderedEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;

    const cpy1 = arr1.slice().sort();
    const cpy2 = arr2.slice().sort();
    for (let i = 0; i < cpy1.length; ++i) {
        if (cpy1[i] !== cpy2[i]) return false;
    }

    return true;
}

export function arrayItemWrap(array, index) {
    let idx = index % array.length;
    if (idx < 0) idx += array.length;
    return array[idx];
}

export function arraySwap(array, i, j) {
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
    return array;
}

export function arraySwapPop(array, idx) {
    if (idx < 0) return undefined;

    const j = array.length - 1;
    const temp = array[idx];
    array[idx] = array[j];
    array[j] = temp;
    return array.pop();
}

export function arraySwapPopItem(array, item) {
    const idx = array.indexOf(item);
    if (idx < 0) return undefined;

    const j = array.length - 1;
    const temp = array[idx];
    array[idx] = array[j];
    array[j] = temp;
    return array.pop();
}

export function arrayPermute(array, cnt, rng) {
    for (let i = 0; i < cnt; ++i) {
        const idx = Math.floor(rng() * array.length);
        arraySwap(array, idx, i);
    }

    return array;
}

export function getValueOrDefault(resource, key, defaults) {
    const val = _.get(resource, key, undefined);
    if (!_.isNil(val) && !_.isNaN(val)) return val;

    const def = _.get(defaults, key, undefined);
    return _.isFunction(def) ? def(resource) : def;
}

export class DeferredPromise {
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

export class FLError extends Error {
    constructor(messageOrData, data) {
        super();
        const hasMessageString = !(messageOrData instanceof Object);

        this.name = this.constructor.name;
        this.message = hasMessageString ? messageOrData : messageOrData.message;
        this.data = hasMessageString ? data : messageOrData;

        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(this.message).stack;
        }
    }
}

let _body;
export function getBody() {
    if (_body === undefined) {
        _body = $document.find('body');
    }

    return _body;
}

/**
 * compile and bind an element into angularjs (with scope and controller)
 */
export function compileBoundElement(element = angular.element('<div>'), options = {}) {
    const { locals, scopeVars, controller, controllerAs } = options;

    // scopeVars are put immediately on the scope
    const $scope = angular.extend($rootScope.$new(), scopeVars);

    $compile(element)($scope);

    if (controller) {
        // locals are injected into the controller
        const ctrl = $controller(controller, angular.extend({ $scope }, locals));

        if (controllerAs) {
            $scope[controllerAs] = ctrl;
        }

        element.data('ngControllerController', ctrl);
    }

    return { element, $scope };
}

export function undefinedOr(val, other) {
    return val === undefined ? other : val;
}

export function noop() {}

// shallow flatten array arr (starting from element at index startIdx)
// by appending flattened values to res
function flattenArrayShallow(arr, startIdx, resInOut) {
    let i = startIdx;
    const n = arr.length;
    while (i < n) {
        const el = arr[i++];
        if (Array.isArray(el)) {
            for (let j = 0; j < el.length; j++) {
                resInOut.push(el[j]);
            }
        } else {
            resInOut.push(el);
        }
    }
    // for convenience of callers, return it as well
    return resInOut;
}

// shallow flatten array i.e. [[1, [2]]] => [1, [2]]
// it differes from loadash.flatten() in that lodash always returns a copy of the
// array while this function can sometimes return original array (if already flat)
// a fast path for the case where there's no need to flatten in which case
// returns the original
export function flatten(arr) {
    for (let i = 0; i < arr.length; i++) {
        const el = arr[i];
        if (el instanceof Array) {
            if (arr.length === 1) {
                return el;
            }
            return flattenArrayShallow(arr, i, arr.slice(0, i));
        }
    }
    return arr;
}

export function objectFromKeys(keys, initializer) {
    const rtn = {};

    for (const k of keys) {
        rtn[k] = initializer();
    }

    return rtn;
}

function loadScript(url) {
    const script = document.createElement('script');
    const deferred = Q.defer();

    script.src = url;
    script.onload = function onload() {
        deferred.resolve(this);
    };
    script.onerror = (err) => deferred.reject(err);

    document.getElementsByTagName('head')[0].appendChild(script);

    return deferred.promise;
}

const SCRIPT_PROMISES = {};

export function asyncLoadScript(url, globalName = undefined) {
    let promise = SCRIPT_PROMISES[url];

    if (promise === undefined) {
        logger.info(`Asyncs: requesting ${url}`);
        promise = SCRIPT_PROMISES[url] = loadScript(url)
            .then(() => {
                // eslint-disable-line consistent-return
                logger.info(`Asyncs: loaded ${url}`);
                return window[globalName];
            })
            .catch(() => Q.reject(`Could not load ${url}`));
    }

    return promise;
}

/**
 * load and cache a template
 */
export function loadTemplate(templateUrl) {
    return $http.get(templateUrl, { cache: $templateCache }).then((resp) => resp.data);
}

const COPY_PATTERN = / \(copy\)| \(copy \d+\)/;
const NUMBER_PATTERN = /\d+/;

// baseDescription is sth. like "foo" or "foo (copy 3)" and taken is []
// of names that are already taken.
// we generate a unique string "foo (copy)" or "foo (copy N)" based on baseDescription
export function createUniqueDescription(baseDescription, taken) {
    let count = 0;
    const copyMatch = COPY_PATTERN.exec(baseDescription);
    let base = baseDescription;
    if (copyMatch !== null && copyMatch.length === 1) {
        const numMatch = NUMBER_PATTERN.exec(copyMatch[0]);
        base = baseDescription.substring(0, baseDescription.length - copyMatch[0].length);
        if (numMatch !== null && numMatch.length === 1) {
            count = parseInt(numMatch[0], 10) + 1;
        } else {
            count = 1;
        }
    }
    let str = `${base} (copy)`;
    for (let i = 0; i < 20; i++) {
        if (count !== 0) {
            str = `${base} (copy ${count})`;
        }
        if (taken.indexOf(str) === -1) {
            return str;
        }
        count++;
    }
    return str;
}

let _isFireFox;

export function isFireFox() {
    if (_isFireFox === undefined) {
        const nav = navigator.userAgent.toString().toLowerCase();
        _isFireFox = nav.indexOf('firefox') !== -1;
    }
    return _isFireFox;
}

export function getBrowserPlatform() {
    // Modern way of getting platform, not supported yet in all browsers/platforms
    if (typeof navigator.userAgentData !== 'undefined' && navigator.userAgentData != null) {
        return navigator.userAgentData.platform;
    }
    // Deprecated method, still works on most browsers/platforms
    if (typeof navigator.platform !== 'undefined') {
        return navigator.platform;
    }
    return 'unknown';
}

export function isMacLike() {
    const platform = getBrowserPlatform();
    return /(Mac|iPhone|iPod|iPad)/i.test(platform);
}

export function isKeyComboPressed({
    event,
    modifiers = { ctrl: false, shift: false, alt: false, meta: false },
    keyCode,
}) {
    const { ctrl, shift, alt, meta } = modifiers;
    return (
        event.ctrlKey === !!ctrl &&
        event.shiftKey === !!shift &&
        event.altKey === !!alt &&
        event.metaKey === !!meta &&
        event.keyCode === keyCode
    );
}

function noOp() {
    return false;
}

// see https://github.com/aurorasolar/helioscope/issues/481#issuecomment-242822643
// Compared to Chrome, FireFox generates a spurious 'click' event after right-click.
// We consider 'click' event to dismiss context-menu, so in FireFox we have
// to eat the first 'click' event. This functions returns a function which
// encapsulates this logic.
// Call returned functions with event. If it returns true, ignore that event.
export function genFireFoxClickFilter() {
    if (!isFireFox()) {
        return noOp;
    }
    let seenFirstClick = false;
    return (ev) => {
        // we've already filtered first click
        if (seenFirstClick) {
            return false;
        }
        // we only filter click event
        if (ev.type !== 'click') {
            return false;
        }
        // trying to be as specific in filtering as possible
        // this is based on looking at what FireFox generates using litle spy
        // tool https://jsbin.com/roluza/1/edit?html,js,console,output
        if (ev.button === 2 && ev.buttons === 0) {
            seenFirstClick = true;
            return true;
        }
        return false;
    };
}

// returns true if event originated in text area or input field
export function isEventFromTextInput(evt) {
    return (
        evt.target.type === 'textarea' ||
        evt.target.type === 'input' ||
        evt.target.type === 'text' ||
        evt.target.type === 'number'
    );
}

export function uniqueId(prefix = '', length = 8) {
    let uid = prefix;
    if (_.isEmpty(prefix) === false) {
        uid += '_';
    }

    for (let i = 0; i < length; i++) {
        uid += _.random(0, 255).toString(16);
    }

    return uid;
}

export function assert(cond, msg) {
    if (cond) {
        return;
    }
    logger.warn(msg);
    throw new Error(msg);
}

export function wait(milliseconds) {
    return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
    });
}

/* eslint no-await-in-loop: 0 */
export async function testWorker(wrk) {
    let resolved = false;
    wrk.onmessage = (evt) => {
        const { _test } = evt.data;
        if (_test) resolved = true;
    };

    wrk.postMessage({ _test: 'test' });

    for (let i = 1; i < 20; ++i) {
        if (resolved) break;
        await wait(50);
    }

    wrk.terminate();
    return resolved;
}

export const CLASSIC_TO_BETA_COPY_TEXT_ID = {
    CUSTOM_PLAN_FINANCIALS: 'custom_plan_financials',
    SUBSCRIPTION_FINANCIALS: 'subscription_financials',
    SUBSCRIPTION_NO_FINANCIALS: 'subscription_no_financials',
    TRIAL: 'trial',
};
function hasSubscriptionWithFinancials(user) {
    return user.subscription && user.hasFinancialsAccess();
}

function hasSubscriptionWithoutFinancials(user) {
    return user.subscription && !user.hasFinancialsAccess();
}

export function getClassicToBetaCopyTextID(user) {
    if (user.team.is_on_custom_plan) {
        return CLASSIC_TO_BETA_COPY_TEXT_ID.CUSTOM_PLAN_FINANCIALS;
    } else if (hasSubscriptionWithFinancials(user)) {
        return CLASSIC_TO_BETA_COPY_TEXT_ID.SUBSCRIPTION_FINANCIALS;
    } else if (hasSubscriptionWithoutFinancials(user)) {
        return CLASSIC_TO_BETA_COPY_TEXT_ID.SUBSCRIPTION_NO_FINANCIALS;
    } else {
        return CLASSIC_TO_BETA_COPY_TEXT_ID.TRIAL;
    }
}
