import * as React from 'react';

import { LibraryConsumer } from 'reports/components/library/main/context';
import DataTable, { IDataTableProps } from 'reports/components/core/tables/DataTable';
import ScrollContainer from 'reports/components/core/tables/ScrollContainer';

type IOwnProps<T> = {
    onRowClick: (item: T) => void;
};

type IProps<T> = Omit<IDataTableProps<T>, 'items' | 'onSort' | 'tableSort' | 'loading' | 'rowSelectConfig'> &
    IOwnProps<T>;

export default class ResourceTable<T> extends React.PureComponent<IProps<T>> {
    render() {
        return (
            <LibraryConsumer>
                {({ items, setTableSort, itemSelected, updateSearch }) => {
                    return (
                        <ScrollContainer height="100%">
                            <DataTable
                                items={items}
                                rowSelectConfig={{
                                    itemSelected,
                                    onRowClick: this.props.onRowClick,
                                }}
                                columns={this.props.columns}
                                onSortChange={(tableSort) => setTableSort(tableSort)}
                                onRefresh={() => updateSearch()}
                                sticky={true}
                                hasScrollContainer={true}
                                width="100%"
                                centered={true}
                            />
                        </ScrollContainer>
                    );
                }}
            </LibraryConsumer>
        );
    }
}
