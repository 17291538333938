import Logger from 'js-logger';
import { toRadians, toDegrees } from './math';
import { Vector } from './vector';

const logger = Logger.get('geography');

export const RADIUS_OF_EARTH = 6378137; // meters

export function defaultAzimuth(design) {
    return design.project.location.latitude > 0 ? 180 : 0;
}

export function bearingFromVector(vector) {
    return (toDegrees(Math.PI / 2 - Math.atan2(vector.y, vector.x)) + 360) % 360;
}

export class GeoPoint {
    constructor(latitude, longitude) {
        if (typeof latitude === 'number') {
            this.latitude = latitude;
            this.longitude = longitude;
        } else if (typeof latitude.latitude === 'number') { // from POJO
            this.latitude = latitude.latitude;
            this.longitude = latitude.longitude;
        } else { // from Google Maps
            this.latitude = latitude.lat();
            this.longitude = latitude.lng();
        }
    }

    /**
     * http://en.wikipedia.org/wiki/Haversine_formula
     */
    distance(other) {
        const deltaLat = toRadians(this.latitude - other.latitude);
        const deltaLng = toRadians(this.longitude - other.longitude);

        const x = (
            Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)
             + (Math.cos(toRadians(this.latitude))
                * Math.cos(toRadians(other.latitude))
                * Math.sin(deltaLng / 2)
                * Math.sin(deltaLng / 2))
        );

        const arc = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));

        return RADIUS_OF_EARTH * arc;
    }

    bearing(other) {
        const deltaLng = toRadians(other.longitude - this.longitude);

        const y = Math.sin(deltaLng) * Math.cos(toRadians(other.latitude));
        const x = (Math.cos(toRadians(this.latitude)) * Math.sin(toRadians(other.latitude))
                   - Math.sin(toRadians(this.latitude)) * Math.cos(toRadians(other.latitude)) * Math.cos(deltaLng));

        return toDegrees(Math.atan2(y, x));
    }

    offsetVector(vector, radius = RADIUS_OF_EARTH) {
        const bearing = bearingFromVector(vector);

        // explicitly use the 2d Distance because we only care about distance along the surface
        return this.offset(Math.sqrt(vector.x ** 2 + vector.y ** 2), bearing, radius);
    }

    /**
     * ported from google maps
     */
    offset(distance, heading, radius = RADIUS_OF_EARTH) {
        const normalizedDistance = distance / radius;
        const headingRad = toRadians(heading);
        const latRad = toRadians(this.latitude);
        const lngRad = toRadians(this.longitude);
        const cosDist = Math.cos(normalizedDistance);
        const sinDist = Math.sin(normalizedDistance);

        const sinLat = Math.sin(latRad);
        const cosLat = Math.cos(latRad);
        const x = cosDist * sinLat + sinDist * cosLat * Math.cos(headingRad);

        return new GeoPoint(
            toDegrees(Math.asin(x)),
            toDegrees(lngRad + Math.atan2(sinDist * cosLat * Math.sin(headingRad), cosDist - sinLat * x))
        );
    }


    /**
     * get a vector representing the distance in meters between this and a different point
     */
    gridOffsets(geopoint) {
        const distance = this.distance(geopoint);
        const bearing = toRadians(this.bearing(geopoint));

        return new Vector(Math.sin(bearing) * distance, Math.cos(bearing) * distance);
    }

    googleLatLng() {
        if (window.google !== undefined) {
            return new window.google.maps.LatLng(this.latitude, this.longitude);
        }

        logger.error('Warning, tried to access google maps, but not loaded yet');
        return null;
    }
}
