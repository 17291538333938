import * as React from 'react';

import { PrintContext } from './PrintProvider';
import { IPrintableFn, IPrintContext } from './types';

/**
 * decorator to wrap a react component and have it incorporated into the print system
 * @param printFunction this function will be called with the current props + the component instance, and should
 *  return pageHTML
 *
 * @makePrintable((props, component) => ({ html: [`page 1 html`, `page 2 html`], metadata: { stuff } }))
 * class MyComponent extend React.Component {}
 */
export function makePrintable<Comp extends React.Component<P> = any, P = any, Conf = any>(
    printFunction: IPrintableFn<Comp, P, Conf>,
) {
    type Constructor = new (props: P) => Comp;

    // return a decorator that wraps a componet constructor with the provided print function and print context
    return (Component: Constructor): Constructor => {
        const inner: React.SFC<P> = (props) => (
            <PrintContext.Consumer>
                {(printContext) => (
                    <WrappedPrintable
                        subProps={props}
                        Component={Component}
                        printFunction={printFunction}
                        {...printContext}
                    >
                        {props.children}
                    </WrappedPrintable>
                )}
            </PrintContext.Consumer>
        );

        return inner as any;
    };
}

export default makePrintable;

interface PrintableProps<P> {
    printFunction: IPrintableFn;
    Component: React.ComponentType<P>;
}

type WrappedPrintableProps<P> = React.PropsWithChildren<{ subProps: P } & PrintableProps<P> & IPrintContext>;

/**
 * wrapper that tracks the lifecycle of a printable component adding/removing it in the print context stack
 */
const WrappedPrintable = React.memo(
    <P,>({
        Component,
        subProps,
        registerPrintFn,
        deregisterPrintFn,
        printFunction,
        children,
    }: WrappedPrintableProps<P>) => {
        const componentRef = React.useRef<React.ComponentType<P>>(null);

        const print = React.useCallback(
            async (parameters?: any) => {
                const currentRef = componentRef.current;

                if (currentRef == null) {
                    throw new Error('no component ref to get print info');
                }

                return await printFunction(currentRef, subProps, parameters);
            },
            [subProps],
        );

        React.useEffect(() => {
            registerPrintFn(print);
            return () => {
                deregisterPrintFn(print);
            };
        }, [print, registerPrintFn, deregisterPrintFn]);

        return (
            <Component ref={componentRef} {...subProps}>
                {children}
            </Component>
        );
    },
);

// type Props = { text: string };

// @makePrintable<TestComponent, Props>((props, component) => {
//     console.log(props.text, component.instProp);
//     return { html: [] };
// })
// class TestComponent extends React.Component<Props> {
//     instProp: string = 'hi';

//     render() {
//         return <h1>{this.props.text}</h1>;
//     }
// }
// export const CallTest: React.SFC = () => <TestComponent text="Hi"/>;
