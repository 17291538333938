import * as React from 'react';

import DragPreviewLayer from './DragPreviewLayer';
import withDragDropContext from './withDragDropContext';

class LayoutContext extends React.PureComponent {
    render() {
        return (
            <>
                <DragPreviewLayer />
                {this.props.children}
            </>
        );
    }
}

export default withDragDropContext(LayoutContext);
