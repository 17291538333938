import { createSelector } from 'reselect';

import { actionCreatorFactory, isType, Action } from 'typescript-fsa';

import { IAppState } from 'reports/types';

const actionCreator = actionCreatorFactory('NAVIGATION');

export const actions = {
    setError: actionCreator<INavError | null>('SET_ERROR'),
    setBusy: actionCreator<any>('SET_BUSY'),
};

export const selectors = {
    get error() {
        return createSelector(
            (state: IAppState) => state.navigation,
            (navigation) => navigation.error,
        );
    },
    get busy() {
        return createSelector(
            (state: IAppState) => state.navigation,
            (navigation) => navigation.busy,
        );
    },
};

export interface INavError {
    status: any;
    body?: any;
}

export interface INavigationState {
    error: INavError | null;
    busy: any;
}

const INITIAL_STATE: INavigationState = {
    error: null,
    busy: null,
};

export const reducer = (state: INavigationState = INITIAL_STATE, action: Action<any>): INavigationState => {
    if (isType(action, actions.setError)) {
        const error = action.payload;
        return { ...state, error };
    }

    if (isType(action, actions.setBusy)) {
        const busy = action.payload;
        return { ...state, busy };
    }

    return state;
};
