import * as React from 'react';

import { TeamOverview as BetaTeamOverview } from 'reports/modules/settings/team/components/TeamOverview';

import { useSettingsContext } from 'frontend/settings/use-settings-data';

const TeamOverview = () => {
    const { team, user } = useSettingsContext();

    return <BetaTeamOverview user={user} team={team} />;
};

export { TeamOverview };
