/**
 * This library is not transpiled enough for IE11 in NPM (includes arrow functions)
 * rather than making our build process complicated, just include the source directly
 *
 * and adapt slightly for our purposes
 *
 * src: https://github.com/xdave/typescript-fsa-redux-thunk
 *
 *
 * should be replaced by https://redux.js.org/redux-toolkit/overview which
 * is an equivalent library maintained by the redux team
 *
 */

import * as React from 'react';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionCreatorFactory, AsyncActionCreators } from 'typescript-fsa';

import { IAppState } from 'reports/types'; // non-ideal circular import, but gives us the right default type for state

interface Dispatch<S> {
    <R, E>(asyncAction: ThunkAction<R, S, E, any> | Action): R;
}

export type AsyncThunk<Rtn, State> = ThunkAction<Promise<Rtn>, State, any, any>;

export type AsyncWorker<Rtn, Params, State, T = any> = (
    params: Params,
    dispatch: Dispatch<State>,
    getState: () => State,
    extra: T,
) => Promise<Rtn>;

export interface AsyncActionWorker<Params, Rtn, Err, State> extends AsyncActionCreators<Params, Rtn, Err> {
    (params: Params): AsyncThunk<Rtn, State>;
    propSignature: (params: Params) => Promise<Rtn>;
}

export function isPromise<R, S>(asyncItem: Promise<R> | AsyncThunk<R, S>): asyncItem is Promise<R> {
    return (<Promise<R>>asyncItem).then !== undefined;
}

export class Deferred<I> {
    promise: Promise<I>;
    resolve: (x: I) => void;
    reject: (x: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

const bindThunkAction = <Params, Rtn, Err, State = IAppState>(
    asyncAction: AsyncActionCreators<Params, Rtn, Err>,
    worker: AsyncWorker<Rtn, Params, State>,
): AsyncActionWorker<Params, Rtn, Err, State> =>
    Object.assign(
        // update to also attach the action to the function
        (params) => async (dispatch, getState, extra) => {
            dispatch(asyncAction.started(params));
            try {
                const result = await worker(params, dispatch, getState, extra);
                dispatch(asyncAction.done({ params, result }));
                return result;
            } catch (error) {
                dispatch(asyncAction.failed({ params, error }));
                throw error;
            }
        },
        asyncAction,
        {
            propSignature(_param: Params) {
                // provide a signature for the unrwapped function for when you have a
                // component that has props that are wrapped in a dispatch already
                throw new Error('unimplemented, signature only for typing');
            },
        },
    );

/**
 * create a dispatchable async worker (thunk) that subactions based on the results of the thunk
 */
export function createAsyncWorkflow<Params, Rtn, Err = any, State = IAppState>(
    actionName: string,
    actionCreatorFactory: ActionCreatorFactory,
    worker: AsyncWorker<Rtn, Params, State>,
) {
    return bindThunkAction<Params, Rtn, Err, State>(actionCreatorFactory.async<Params, Rtn, Err>(actionName), worker);
}

export function setStatePromise<P, S>(component: React.Component<P, S>, newState: Partial<S>): Promise<null> {
    const deferred = new Deferred<null>();

    component.setState(newState as any, () => deferred.resolve(null));

    return deferred.promise;
}
