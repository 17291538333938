/**
 * SolarEdge Stringing Guidelines - From Email Chain:
 * from: Evan Sarkisian <evan.sarkisian@folsomlabs.com>
 * to:   Paul Gibbs <paul.gibbs@folsomlabs.com>
 * cc:   Paul Grana <paul.grana@folsomlabs.com>, Teresa Zhang <teresa.zhang@folsomlabs.com>
 * date: Thu, Aug 18, 2016 at 7:27 AM
 * subject: Fwd: SolarEdge integration in HelioScope
 *
 * Summarized Below:
 *
 * ## Inputs/Definitions:
 *  - Module_Power: ideally use the minimum of 'peak module power' and Module STC Power
 *      Note: 'Peak Module Power' refers to peak expected power during array operation
 *            This is not a standard industry thing, just SolarEdge trying to optimize
 *            for slightly longer strings when moduels are shaded or the location isn't
 *            very sunny.
 *            STC means 'standard test conditions' and defines generic operting conditions
 *            of 1,000 Watts/meter^2 (irradiance) and 25ºC (temp)
 * - Inverter_Nominal_Input_Voltage: standard operating input voltage of inverters (input to
 *                                   inverters from modules/optimizers)
 *     Note: Unlike most inverters, SolarEdge inverters operate at fixed 'nominal' operating
 *           voltage.  Most scan between a predefined operational voltage range, so when
 *           this data doesn't exist, fall back to the average.
 * - Optimizer_Max_Output_Current: self explanatory, almost universally 15Amps in solar,
 *                                 due to code requirements
 *
 * Maximum String Length: max number of modules per string
 *  = floor(Inverter_Nominal_Input_Voltage * Optimizer_Max_Output_Current / Module_Power)
 * Exceptions:
 *  - For P600/P700 - the maximum number of optimizers per string is 30
 *  - For everything else, the maximum number of optimizers per string is 50
 *      Note: assume the limit of 30 optimizers applies to **all** optimizers that allow
 *            multiple modules per optimizer
 *
 * Min Optimizers per String:
 *     = ceiling(Inverter_Nominal_Input_Voltage / Power_Optimizer_Max_Operating_Output_Voltag) + 2
 * Exceptions:
 *      - 8 for inverters that output at 208V/Single phase
 *          (note: spec sheets refer to three buckets, 'single phase', '208v' and '480v', this means the first)
 *      - 13 for P600/P700/P730 Optimizers running on inverters outputing at 277/480V
 *
 * Broader Exceptions/Rules:
 *     - the max number of power optimizers for all solaredge systems is 50 (single opt), 30 (dual opt)
 *     - For single phase (AC Output) inverters, input voltage in the formulas should always be
 *       350V, even for the 208V models that work at 325V)
 *     - for SE27.6K with P600/P700/P730: Max 13.5kW per string (when 3 strings connected), max power difference 2kw
 *     - for SE33.3K with P600/P700/P730: Max 15.0kW per string (when 3 strings connected), max power difference 2kw
 *     - for SE14.4K with P600/P700/P730: Max 6.5kW per string (when 3 strings connected), max power difference 1kw
 *
 * Inverter Over-sizing:
 *     - String sizing rules must observe the inverter DC loading limit, with a maximimum DC
 *       oversizng of 135% based on 'achievable DC Power' (note, Yoni updated this to 130%, with
 *       specific exceptions)
 *
 * P600/P700 are two larger optimizers solaredge produces designed to optimize 2+ modules at a time
 */

import Logger from 'js-logger';
import { round } from 'lodash';

import { getBoundedBatchSizes, groupArray } from '../components/helpers';
import { DEFAULT_AC_CONFIG } from '../components/electrical';
import { StringingError } from './string_sizing';
import { GenericInverterConfig } from './inverter_scheduling';

const logger = Logger.get('solaredge');


class BoundsCalculator {
    constructor(modulePower, deprecatedModulesPerOptimizer = 1) {
        this.modulePower = modulePower;
        this.deprecatedModulesPerOptimizer = deprecatedModulesPerOptimizer;

        this.min = Number.NEGATIVE_INFINITY;
        this.max = Number.POSITIVE_INFINITY;
        this.minWarning = 'Strings too short according to SolarEdge guidelines';
        this.maxWarning = 'Strings too long according to SolarEdge guidelines';
    }

    updateMin(newMin, message) {
        if (newMin > this.min) {
            this.min = newMin;
            this.minWarning = message;
        }
    }

    updateMax(newMax, message) {
        if (newMax < this.max) {
            this.max = newMax;
            this.maxWarning = message;
        }
    }

    updateMaxModules(mods, message = `At most ${mods} modules per string`) {
        this.updateMax(mods, message);
    }

    updateMinModules(mods, message = `At least ${mods} modules per string`) {
        this.updateMin(mods, message);
    }

    updateMaxPower(maxPower, message = `At most ${round(maxPower / 1000, 1)}kW per string`) {
        const newMax = Math.floor(maxPower / this.modulePower);
        this.updateMaxModules(newMax, message);
    }

    updateMinOptimizers(cnt, message = `At least ${cnt} optimizers per string`) {
        // modules can partially fill an optimizer, so if you have optimizers that can handle
        // two modules, a min of 6 optimizers => 12 modules, but you could also do 6 optimizer to 6
        // modules, or even 11 of modules across 6 optimizer.
        //
        // Note: we used to fake dual optimizers' by creating 'half' optimizers with their output
        // spec's cut in half. So if it's min optimizers of 6 to hit the voltage
        // target, this needs to be 12 'half' optimizers to ensure equivalent performance.
        this.updateMinModules(cnt * this.deprecatedModulesPerOptimizer, message);
    }

    updateMaxOptimizers(cnt, message = `At most ${cnt} optimizers per string`) {
        this.updateMaxModules(cnt * this.modulesPerOptimizer, message);
    }

    sanitize() {
        if (this.max < this.min) {
            this.min = Math.max(0, this.max);
        }

        return this;
    }
}

function isSolaredge(component) {
    return _.get(component, 'manufacturer', '').toLowerCase().startsWith('solaredge');
}

export function isAvailable(inverter, optimizer) {
    return isSolaredge(inverter) || isSolaredge(optimizer);
}

function checkComponents(inverter, optimizer) {
    const solaredgeInverter = isSolaredge(inverter);
    const solaredgeOptimizer = isSolaredge(optimizer);

    if (!solaredgeInverter || !solaredgeOptimizer) {
        throw new StringingError('invalid components', {
            source: 'solaredge',
            solaredgeOptimizer,
            solaredgeInverter,
        });
    }
}


export function determineBounds(inverter, optimizer, moduleCharacterization, inputConfiguration) {
    checkComponents(inverter, optimizer);

    let acConfig = inverter.ac_config;

    if (acConfig === undefined) {
        logger.warn(`No AC Config found for ${inverter}, using default`);
        acConfig = DEFAULT_AC_CONFIG;
    }


    // if we don't have nominal voltage assume the midpoint of the MPPT range
    let inverterVoltage = inverter.nominal_input_voltage || (inverter.min_mpp_voltage + inverter.max_mpp_voltage) / 2;
    if (acConfig.phase === 1) {
        // from: "For 1ph inverters, the input voltage in the formulas will always be 350V
        //        (also for the 208V models that work at 325V)"
        inverterVoltage = 350;
    }

    // both these fields are deprecated, and have been replaced by an explicit input Configuration
    // that uses the votlage characteristics of the optimizers
    //   * modules_per_device:    for optimizers that have multiple modules in the data model, thi
    //                            was hardcoded to be the number of modules in series into an optimizer
    //   * modules_per_optimizer: hack for optimizers that have a single module in the data model,
    //                            but in reality should have several modules per optimizer.
    //                            these optimizers were entered in with their voltage characteristics
    //                            cut in half; this adjustment is not made in the data model, but
    //                            bill-of-material calculations are adjusted by it.
    const deprectedModulesPerOptimizer = optimizer.modules_per_optimizer || 1;
    const modulesPerOptimizer = inputConfiguration.maxModules;

    const optimizerMaxVoltage = optimizer.max_output_voltage;
    const optimizerMaxCurrent = optimizer.max_output_current; // Note: this is basically univerally 15Amps

    const bounds = new BoundsCalculator(moduleCharacterization.power, deprectedModulesPerOptimizer); // note, SEDGE prefers min(achieved power, STC Power)

    bounds.updateMaxPower(inverterVoltage * optimizerMaxCurrent);

    if (acConfig.voltage === 208 && acConfig.phase === 1) {
        bounds.updateMinOptimizers(8);
    } else if (acConfig.voltage === 480 && modulesPerOptimizer > 1) {
        bounds.updateMinOptimizers(13);
    } else {
        bounds.updateMinOptimizers(Math.ceil(inverterVoltage / optimizerMaxVoltage) + 2);
    }

    // Maximum Overrirides
    if (modulesPerOptimizer > 1) {
        bounds.updateMaxOptimizers(30);
        if (inverter.name.indexOf('14.4K') !== -1) bounds.updateMaxPower(6500);
        if (inverter.name.indexOf('27.6K') !== -1) bounds.updateMaxPower(13500);
        if (inverter.name.indexOf('33.6K') !== -1) bounds.updateMaxPower(15000);
    } else {
        bounds.updateMaxModules(50);
    }

    return bounds.sanitize();
}


/**
 * Generate an inverter schedule for solar edge optmizers.  Each string is
 * allowed to vary (even on the same input), but must have a length (module count)
 * between the min and max.
 */
export function createSchedule({ min, max }, moduleCount, inverterCount) {
    logger.debug('Building SolarEdge wiring schedule');

    const stringAllocations = getBoundedBatchSizes(moduleCount, min, max);

    return groupArray(stringAllocations, inverterCount).map(
        moduleGroup => new GenericInverterConfig([moduleGroup]),
    );
}
