import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as analytics from 'reports/analytics';

import { fromNow } from 'reports/utils/formatters';
import { PreviewDrawer } from 'reports/components/library/main/components';
import { LibraryPreview } from 'reports/components/core/layout';
import { EditButton, CopyButton, FavoriteStar } from 'reports/components/core/controls';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import * as auth from 'reports/modules/auth';
import * as pro from 'reports/models/profile';
import { Section2, ProfilesParameter } from './common';
import MechanicalPreviewContents from 'reports/modules/profile/components/MechanicalPreviewContents';
import ElectricalPreviewContents from 'reports/modules/profile/components/ElectricalPreviewContents';
import ConditionsPreviewContents from 'reports/modules/profile/components/ConditionsPreviewContents';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { createUniqueDescription } from 'reports/utils/helpers';
import ProjectProfileContents from 'reports/modules/profile/components/ProjectPreviewContents';
import FinancialPreviewContents from 'reports/modules/profile/components/FinancialPreviewContents';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const ContentContainer = styled.div`
    // Add 100px of bottom padding so that the Chat doesn't block content
    padding: 8px 12px 100px;
`;

interface Props {
    navigateToProfile: (profileId: number) => void;
    navigateToProfiles: () => void;
    profile: pro.Profile;
}

const ProfileOverviewContents = ({ profile }: { profile: pro.Profile }) => (
    <Section2 title="Overview">
        <ProfilesParameter label="Name">{profile.name}</ProfilesParameter>
        {profile.creator && <ProfilesParameter label="Owner">{profile.creator.full_name}</ProfilesParameter>}
        {profile.description && <ProfilesParameter label="Description">{profile.description}</ProfilesParameter>}
    </Section2>
);

const ProfilePreview = React.memo(({ navigateToProfile, navigateToProfiles, profile }: Props) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
    const dispatch = useDispatch();

    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const createProfile = (newProfile: pro.ProfileForm) => dispatch(pro.api.create(newProfile));
    const getProfiles = (args: pro.ProfileGetOpts) => dispatch(pro.api.index(args));
    const setBookmarked = (profile: pro.Profile, bookmarked: boolean) =>
        dispatch(
            pro.api.patchTeamSettings({
                bookmarked,
                profile_id: profile.profile_id,
            }),
        );

    const duplicateProfile = async () => {
        await addPromiseToasts(
            (async () => {
                const matchingProfiles = await getProfiles({ q: profile.name });
                const newProfile = await createProfile({
                    ...profile,
                    name: createUniqueDescription(
                        profile.name,
                        matchingProfiles.map((p: pro.Profile) => p.name),
                    ),
                    public: false,
                });

                navigateToProfile(newProfile.profile_id);
            })(),
            {
                initial: 'Duplicating profile...',
                onSuccess: 'Successfully duplicated profile.',
                onCatch: 'Error duplicating profile.',
            },
        );
    };

    const lastModified = profile.last_modified || profile.created;
    const editButton =
        user.team_id === profile.team_id ? (
            <EditButton
                onClick={() => {
                    analytics.track('profile.edit', {
                        profile_id: profile.profile_id,
                        profile_type: profile.type,
                    });
                    navigateToProfile(profile.profile_id);
                }}
            />
        ) : (
            <></>
        );

    return (
        <PreviewDrawer
            isOpen={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            onClosed={navigateToProfiles}
            title={
                <LibraryPreview.Header
                    title={profile.name}
                    subtitle={lastModified && `Last modified ${fromNow(lastModified)}`}
                    favorite={
                        <FavoriteStar
                            empty={!profile.bookmarked()}
                            onClick={() => {
                                addPromiseToastsToggle(
                                    'favorite',
                                    setBookmarked(profile, !profile.bookmarked()),
                                    'financial model',
                                    profile.name,
                                    profile.bookmarked(),
                                );
                            }}
                        />
                    }
                />
            }
            editButton={editButton}
            duplicateButton={<CopyButton text="Duplicate" onClick={() => duplicateProfile()} />}
        >
            <ContentContainer>
                <ProfileOverviewContents profile={profile} />
                {profile instanceof pro.MechanicalProfile && <MechanicalPreviewContents profile={profile} />}
                {profile instanceof pro.ElectricalProfile && <ElectricalPreviewContents profile={profile} />}
                {profile instanceof pro.ConditionsProfile && <ConditionsPreviewContents profile={profile} />}
                {profile instanceof pro.ProjectProfile && <ProjectProfileContents profile={profile} />}
                {profile instanceof pro.FinancialProfile && <FinancialPreviewContents profile={profile} />}
            </ContentContainer>
        </PreviewDrawer>
    );
});

export { ProfilePreview };
