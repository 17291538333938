import Colors from 'reports/styles/colors';

export interface IWidgetStyle {
    showHeaders?: boolean;
    style?: React.CSSProperties;
}

export const WIDGET_BORDER_RADIUS = 5;

export enum WidgetStyle {
    classic = 'classic',
    minimal = 'minimal',
}

export const WIDGET_STYLES: { [k in keyof typeof WidgetStyle]: IWidgetStyle } = {
    classic: {
        showHeaders: true,
        style: {
            border: `1px solid ${Colors.BORDER_TABLE}`,
            borderRadius: WIDGET_BORDER_RADIUS,
            overflow: 'hidden',
        },
    },
    minimal: {
        showHeaders: false,
    },
};
