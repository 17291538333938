/**
 * Consumption Editor dialog
 */
import * as React from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import {
    Button,
    Classes,
    Dialog,
    FormGroup,
    IDialogProps,
    MenuItem,
    NumericInput,
    ProgressBar,
    Spinner,
    H4,
    H5,
    Intent,
    Tabs,
    Tab,
    TabId,
} from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { IconNames } from '@blueprintjs/icons';
import { isMatch, omit } from 'lodash';

import { bindActions } from 'reports/utils/redux';

import { PrimaryButton, Button as SecondaryButton, Tooltip } from 'reports/components/core/controls';
import Flex from 'reports/components/core/containers/Flex';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';
import FormTextInput from 'reports/components/forms/inputs/experimental/FormTextInput';
import { Form } from 'reports/components/forms';

import * as proj from 'reports/models/project';
import * as s3file from 'reports/models/s3file';
import * as uc from 'reports/models/user_consumption';
import * as cpo from 'reports/models/consumption_parser_options';

import ConsumptionEnergyChart from 'reports/modules/consumption/components/ConsumptionEnergyChart';
import DailyProfileChart from 'reports/modules/consumption/components/DailyProfileChart';
import { DEFAULT_LAYOUT_CHART_HEIGHT } from 'reports/modules/report/components/charts';

import * as styles from 'reports/styles/styled-components';
import Callout from 'reports/components/core/controls/Callout';
const styled = styles.styled;

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
export type ConsumptionFileType = 'csv' | 'xml';

interface IConsumpUploadDialogProps extends IDialogProps, IDispatchProps {
    project: proj.Project;
    uploadingS3File: Promise<s3file.S3File>;
    filename: string;
    fileType: ConsumptionFileType;

    onClose: () => void;
    onSave: (parserOptions?: cpo.ConsumptionParserOptions) => void;
}

const DIALOG_WIDTH = 1080;
const SAVE_CONFIG_DIALOG_WIDTH = 540;
const DEFAULT_PARSER_OPTS = {
    datetime_format: '%Y-%m-%d %H:%M',
    is_kilo: true,
    is_power: false,
    row_start: 1,
    date_column: 0,
    energy_column: 1,
    interval_length: 60,
};
const DATETIME_FORMATS = {
    '%d/%m/%Y %H:%M': 'DD/MM/YYYY HH:mm',
    '%m/%d/%Y %H:%M': 'MM/DD/YYYY HH:mm',
    '%d/%m/%y %H:%M': 'DD/MM/YY HH:mm',
    '%m/%d/%y %H:%M': 'MM/DD/YY HH:mm',
    '%Y/%m/%d %H:%M': 'YYYY/MM/DD HH:mm',
    '%Y-%m-%d %H:%M': 'YYYY-MM-DD HH:mm',
};
const DATETIME_FORMAT_OPTIONS = Object.keys(DATETIME_FORMATS);
const UNIT_OPTIONS = ['Power', 'Energy'];
const SCALE_OPTIONS = [
    ['kWp', 'Wp'],
    ['kWh', 'Wh'],
];
const BOOLEAN_OPTIONS = [false, true];
const INTERVAL_LENGTH_OPTIONS = [60, 30, 15];

const StringSelect = Select.ofType<string>();
const NumberSelect = Select.ofType<number>();
const BooleanSelect = Select.ofType<boolean>();

const makeParserOptionsForm = (name, parserOptions) => {
    return {
        ...parserOptions,
        name,
        bookmarked: true,
    };
};

const InputsContainer = styled.div`
    display: flex;
    margin-top: 1rem;

    .${Classes.FORM_GROUP} {
        margin: 2px;
    }
`;

const ChartContainer = styled.div`
    margin-top: 1rem;
    // 50px extra height for tabs
    min-height: ${DEFAULT_LAYOUT_CHART_HEIGHT + 50}px;
`;

const ConfigColumn = styled.div`
    &:last-child {
        // same as dialog left padding
        margin-left: 20px;
    }
`;

const RowLabel = styled.span`
    width: 60px;
    font-weight: bold;
    align-self: flex-end;
    padding-bottom: 8px;
`;

const CenteredSpinner = styled(Spinner)`
    width: 100%;
    height: ${DEFAULT_LAYOUT_CHART_HEIGHT}px;
`;

const SaveConfigDialog = (props) => {
    const { onSave, isOpen, onCancel } = props;

    return (
        <Dialog
            title="Save Configuration Settings"
            isOpen={isOpen}
            isCloseButtonShown={true}
            onClose={onCancel}
            style={{ width: SAVE_CONFIG_DIALOG_WIDTH, overflow: 'hidden' }}
        >
            <Form baseValue={{ configName: '' }} onSubmit={onSave}>
                {({ submitForm, formData }) => (
                    <>
                        <div className={Classes.DIALOG_BODY}>
                            <p>
                                Do you want to save these configuration settings to use with future consumption files?
                            </p>
                            <FormTextInput
                                inline
                                fill
                                bold
                                autoFocus
                                path="configName"
                                label="Configuration Name"
                                type="text"
                            />
                        </div>
                        <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                            <SecondaryButton text="Don't Save" onClick={onCancel} />
                            <PrimaryButton
                                text="Save Configuration"
                                icon={IconNames.FLOPPY_DISK}
                                disabled={!formData.configName}
                                onClick={submitForm}
                            />
                        </div>
                    </>
                )}
            </Form>
        </Dialog>
    );
};

const ConsumptionUploadDialog: React.FC<IConsumpUploadDialogProps> = (props) => {
    const { uploadingS3File, isOpen, project, filename, fileType, getParserOptions } = props;
    const showCsvParserOpts = fileType === 'csv';

    const [parserOptions, setParserOptionsOrig] = React.useState<cpo.ConsumptionParserOptions>(DEFAULT_PARSER_OPTS);
    const [consumption, setConsumption] = React.useState<uc.UserConsumption>();
    const [errorMsg, setErrorMsg] = React.useState<string>();
    const [isTryParseLoading, setIsTryParseLoading] = React.useState<boolean>(false);
    const [s3File, setS3File] = React.useState<s3file.S3File>();
    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    const [selectedTabId, setSelectedTabId] = React.useState<TabId>('monthly');
    const [isUploadError, setIsUploadError] = React.useState<boolean>(false);
    const [isSaveConfigOpen, setIsSaveConfigOpen] = React.useState<boolean>(false);
    const [selectedParserOptions, setSelectedParserOptions] = React.useState<cpo.ConsumptionParserOptions>();
    const [savedParserOptions, setSavedParserOptions] = React.useState<Promise<cpo.ConsumptionParserOptions[]>>(
        Promise.resolve([]),
    );

    // use useRef because it doesn't need to trigger a re-render and because setting it needs to take immediate effect
    const tryParsePromise = React.useRef<Promise<uc.UserConsumption>>();

    const onParserOptionsChange = async (newParserOptions, s3FileToParse = s3File) => {
        setErrorMsg(undefined);
        setIsTryParseLoading(true);
        setConsumption(undefined);

        const curTryParsePromise = props.tryParseConsumption({
            consumption_parser_options: newParserOptions,
            project_id: project.project_id,
            name: s3FileToParse!.filename,
            file_id: s3FileToParse!.file_id,
        });
        tryParsePromise.current = curTryParsePromise;

        try {
            const rawConsumption = await curTryParsePromise;

            // Prevents race condition when promises return out of order
            if (curTryParsePromise !== tryParsePromise.current) {
                return;
            }

            const uploadedConsumption = new uc.UserConsumption({
                ...rawConsumption,
                project,
            });
            setConsumption(uploadedConsumption);
            setIsTryParseLoading(false);
        } catch (err) {
            // Prevents race condition when promises return out of order
            if (curTryParsePromise !== tryParsePromise.current) {
                return;
            }

            const msgParts: string[] = [];
            const errObj = err?.response?.body?.csv;

            msgParts.push("We couldn't parse your file. Use different format settings and try again.");
            if (errObj && 'row' in errObj && 'col' in errObj && 'val' in errObj) {
                if (newParserOptions.date_column === newParserOptions.energy_column) {
                    msgParts.push("Date and energy can't have the same column index.");
                } else if (newParserOptions.date_column === errObj.col) {
                    msgParts.push(
                        `There was a problem reading the date in row ${errObj.row + 1}. A date formatted like \
                        "${DATETIME_FORMATS[newParserOptions.datetime_format]}" was expected, but the value in \
                        the file was "${errObj.val}".`,
                    );
                } else if (newParserOptions.energy_column === errObj.col) {
                    msgParts.push(
                        `There was a problem reading the energy value in row ${errObj.row + 1}. An energy value \
                        formatted like a number was expected, but the value in the file was "${errObj.val}".`,
                    );
                }
            } else if (errObj && 'err' in errObj) {
                msgParts.push(errObj.err);
            }

            setErrorMsg(msgParts.join(' '));
            setIsTryParseLoading(false);
        }
    };

    const setParserOptions = (parserOptions) => {
        setParserOptionsOrig(parserOptions);
        onParserOptionsChange(parserOptions);
    };

    const onClose = () => {
        props.onClose();
        setParserOptions(DEFAULT_PARSER_OPTS);
        setConsumption(undefined);
        setIsSaveConfigOpen(false);
        setSelectedParserOptions(undefined);
    };

    const onSave = async () => {
        if (fileType !== 'csv') {
            props.onSave();
            onClose();
        } else if (selectedParserOptions && isMatch(parserOptions, selectedParserOptions)) {
            // We don't want to show the SaveConfigDialog if there's a previously saved, unmodified config selected
            props.onSave(parserOptions);
            onClose();
        } else {
            // If the parser options have been modified from the original saved version, we want to treat it as new
            props.onSave(omit(parserOptions, 'consumption_parser_options_id'));
            setIsSaveConfigOpen(true);
        }
    };

    const scaleOptions = SCALE_OPTIONS[parserOptions.is_power ? 0 : 1];

    const handleFileChange = async () => {
        setIsUploading(true);
        setIsUploadError(false);
        setSavedParserOptions(getParserOptions());

        let s3File;

        try {
            s3File = await uploadingS3File;
        } catch (err) {
            setIsUploadError(true);
            return;
        } finally {
            setIsUploading(false);
        }

        setS3File(s3File);
        onParserOptionsChange(parserOptions, s3File);
    };

    const onSaveConfigDialogSave = async ({ configName }) => {
        await props.createParserOptions(makeParserOptionsForm(configName, parserOptions));
        onClose();
    };

    // When s3File changes, try to parse it immediately
    React.useEffect(() => {
        handleFileChange();
    }, [uploadingS3File]);

    const renderItem =
        (textFn = (x) => x) =>
        (item, { handleClick, modifiers }) =>
            <MenuItem key={item} text={textFn(item)} onClick={handleClick} {...modifiers} />;

    return (
        <>
            <SaveConfigDialog isOpen={isSaveConfigOpen} onSave={onSaveConfigDialogSave} onCancel={onClose} />
            <Dialog
                title="Configure Consumption Upload"
                isOpen={isOpen && !isSaveConfigOpen}
                isCloseButtonShown={true}
                onClose={onClose}
                style={{ width: DIALOG_WIDTH, overflow: 'hidden' }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <H4>{filename}</H4>
                    {isUploadError && (
                        <Callout icon={IconNames.WARNING_SIGN} intent={Intent.DANGER}>
                            An error occurred while the file was uploading.
                        </Callout>
                    )}
                    {isUploading && (
                        <InputsContainer>
                            <ProgressBar />
                        </InputsContainer>
                    )}
                    {!isUploading && !isUploadError && (
                        <>
                            {showCsvParserOpts && (
                                <Flex.Container>
                                    <ConfigColumn>
                                        <H5>Configure data</H5>
                                        <p>
                                            Select the expected date and energy formats, and specify any file header
                                            rows to ignore.
                                        </p>
                                        <InputsContainer>
                                            <RowLabel>Date</RowLabel>
                                            <FormGroup label="Date Format">
                                                <StringSelect
                                                    filterable={false}
                                                    items={DATETIME_FORMAT_OPTIONS}
                                                    activeItem={parserOptions.datetime_format}
                                                    itemRenderer={
                                                        renderItem((x) => DATETIME_FORMATS[x]) as ItemRenderer<string>
                                                    }
                                                    onItemSelect={(item) =>
                                                        setParserOptions({
                                                            ...parserOptions,
                                                            datetime_format: item,
                                                        })
                                                    }
                                                >
                                                    <Button
                                                        text={DATETIME_FORMATS[parserOptions.datetime_format]}
                                                        rightIcon={IconNames.CARET_DOWN}
                                                        style={{
                                                            width: '200px',
                                                        }}
                                                        alignText="left"
                                                    />
                                                </StringSelect>
                                            </FormGroup>
                                            <FormGroup label="Interval Length">
                                                <NumberSelect
                                                    filterable={false}
                                                    items={INTERVAL_LENGTH_OPTIONS}
                                                    activeItem={parserOptions.interval_length}
                                                    itemRenderer={
                                                        renderItem((x) => `${x} minutes`) as ItemRenderer<number>
                                                    }
                                                    onItemSelect={(item) =>
                                                        setParserOptions({
                                                            ...parserOptions,
                                                            interval_length: item,
                                                        })
                                                    }
                                                >
                                                    <Button
                                                        text={`${parserOptions.interval_length} minutes`}
                                                        rightIcon={IconNames.CARET_DOWN}
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                        alignText="left"
                                                    />
                                                </NumberSelect>
                                            </FormGroup>
                                            <FormGroup label="Column">
                                                <NumericInput
                                                    value={parserOptions.date_column + 1}
                                                    onValueChange={(val) =>
                                                        !isNaN(val) &&
                                                        setParserOptions({
                                                            ...parserOptions,
                                                            date_column: val - 1,
                                                        })
                                                    }
                                                    min={1}
                                                />
                                            </FormGroup>
                                        </InputsContainer>
                                        <InputsContainer>
                                            <RowLabel>Energy</RowLabel>
                                            <FormGroup label="Units">
                                                <BooleanSelect
                                                    filterable={false}
                                                    items={BOOLEAN_OPTIONS}
                                                    activeItem={parserOptions.is_power}
                                                    itemRenderer={
                                                        renderItem(
                                                            (x) => UNIT_OPTIONS[x ? 0 : 1],
                                                        ) as ItemRenderer<boolean>
                                                    }
                                                    onItemSelect={(item) =>
                                                        setParserOptions({
                                                            ...parserOptions,
                                                            is_power: item,
                                                        })
                                                    }
                                                >
                                                    <Button
                                                        text={UNIT_OPTIONS[parserOptions.is_power ? 0 : 1]}
                                                        rightIcon={IconNames.CARET_DOWN}
                                                        style={{
                                                            width: '200px',
                                                        }}
                                                        alignText="left"
                                                    />
                                                </BooleanSelect>
                                            </FormGroup>
                                            <FormGroup label="Scale">
                                                <BooleanSelect
                                                    filterable={false}
                                                    items={BOOLEAN_OPTIONS}
                                                    activeItem={parserOptions.is_kilo}
                                                    itemRenderer={
                                                        renderItem(
                                                            (x) => scaleOptions[x ? 0 : 1],
                                                        ) as ItemRenderer<boolean>
                                                    }
                                                    onItemSelect={(item) =>
                                                        setParserOptions({
                                                            ...parserOptions,
                                                            is_kilo: item,
                                                        })
                                                    }
                                                >
                                                    <Button
                                                        text={scaleOptions[parserOptions.is_kilo ? 0 : 1]}
                                                        rightIcon={IconNames.CARET_DOWN}
                                                        style={{
                                                            width: '150px',
                                                        }}
                                                        alignText="left"
                                                    />
                                                </BooleanSelect>
                                            </FormGroup>
                                            <FormGroup label="Column">
                                                <NumericInput
                                                    value={parserOptions.energy_column + 1}
                                                    onValueChange={(val) =>
                                                        !isNaN(val) &&
                                                        setParserOptions({
                                                            ...parserOptions,
                                                            energy_column: val - 1,
                                                        })
                                                    }
                                                    min={1}
                                                />
                                            </FormGroup>
                                        </InputsContainer>
                                        <InputsContainer>
                                            <RowLabel>Other</RowLabel>
                                            <FormGroup
                                                label={
                                                    <Tooltip
                                                        underlined
                                                        content={
                                                            `The specified number of rows will be ignored when parsing ` +
                                                            `this file. Data should start on row ${
                                                                parserOptions.row_start + 1
                                                            }.`
                                                        }
                                                    >
                                                        Number of Header Rows
                                                    </Tooltip>
                                                }
                                            >
                                                <NumericInput
                                                    value={parserOptions.row_start}
                                                    onValueChange={(val) =>
                                                        !isNaN(val) &&
                                                        setParserOptions({
                                                            ...parserOptions,
                                                            row_start: val,
                                                        })
                                                    }
                                                    min={0}
                                                />
                                            </FormGroup>
                                        </InputsContainer>
                                    </ConfigColumn>
                                    <ConfigColumn>
                                        <FormGroup
                                            inline={true}
                                            label={
                                                <Tooltip
                                                    underlined
                                                    content={`Use existing consumption configurations you've saved.`}
                                                >
                                                    Quick Select
                                                </Tooltip>
                                            }
                                        >
                                            <BasicSelect<cpo.ConsumptionParserOptions>
                                                dataSource={{
                                                    items: savedParserOptions,
                                                }}
                                                itemRenderer={(opts: cpo.ConsumptionParserOptions) => ({
                                                    key: opts.consumption_parser_options_id!,
                                                    text: opts.name!,
                                                })}
                                                value={selectedParserOptions || null}
                                                onChange={(opts) => {
                                                    setSelectedParserOptions(opts);
                                                    setParserOptions({
                                                        ...opts,
                                                    });
                                                }}
                                                noneSelected="- None Selected -"
                                            />
                                        </FormGroup>
                                    </ConfigColumn>
                                </Flex.Container>
                            )}
                            <ChartContainer>
                                {consumption && !errorMsg && (
                                    <Tabs
                                        selectedTabId={selectedTabId}
                                        onChange={(newTabId) => setSelectedTabId(newTabId)}
                                    >
                                        <Tab
                                            title="Monthly Aggregate"
                                            id="monthly"
                                            panel={
                                                <ConsumptionEnergyChart
                                                    production={false}
                                                    project={project}
                                                    consumptionData={consumption}
                                                />
                                            }
                                        />
                                        <Tab
                                            title="Daily Profile"
                                            id="daily"
                                            panel={<DailyProfileChart consumption={consumption} />}
                                        />
                                    </Tabs>
                                )}
                                {errorMsg && (
                                    <Callout icon={IconNames.WARNING_SIGN} intent={Intent.WARNING}>
                                        {errorMsg}
                                    </Callout>
                                )}
                                {isTryParseLoading && <CenteredSpinner />}
                            </ChartContainer>
                        </>
                    )}
                </div>
                <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                    <SecondaryButton text="Cancel" onClick={onClose} />
                    <PrimaryButton
                        text="Save"
                        icon={IconNames.FLOPPY_DISK}
                        onClick={onSave}
                        disabled={Boolean(errorMsg) || isTryParseLoading || isUploading || isUploadError}
                    />
                </div>
            </Dialog>
        </>
    );
};

const mapDispatchToProps = bindActions(({ project }) => ({
    tryParseConsumption: (form: uc.UserConsumptionForm) => uc.api.tryParse(form),
    getParserOptions: () => cpo.api.index({ team_id: project.team_id, bookmarked: true }),
    createParserOptions: (parserOptions: cpo.IParserOptionsForm) => cpo.api.create(parserOptions),
}));

export default connect(null, mapDispatchToProps)(ConsumptionUploadDialog);
