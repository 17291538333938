/**
 * Report Proposals Custom Text Format Form
 */
import * as React from 'react';

import { Classes, Colors, NumericInput } from '@blueprintjs/core';

import { Theme } from 'reports/models/theme';
import { AVAILABLE_FONTS, isColor, IColorCustomKey, rgbToString, IStyleMap } from 'reports/modules/report/styles';
import { ColorPicker, IColor } from 'reports/modules/report/components/forms/ColorPicker';

import { FormSection, FormatButton, ButtonBar } from 'reports/modules/report/components/helpers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const FontDetails = styled.div`
    display: flex;
`;

const FontSelect = styled.div`
    &.${Classes.FILL} {
        &::after {
            top: 2px;
        }

        select {
            height: 100%;
        }
    }
`;

const FontSizeInput: NumericInput = styled(NumericInput as any).attrs({
    buttonPosition: 'none',
    className: Classes.MINIMAL,
})`
    align-items: center;
    margin-left: 6px;

    .${Classes.INPUT_GROUP} {
        width: 50px;
        border-radius: 1px 0 0 1px;
        border: 1px solid ${Colors.LIGHT_GRAY4};
    }

    input {
        padding: 0;
        text-align: center;
        height: 34px;

        &:focus {
            box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2);
        }
    }
` as any;

interface IProps {
    style: React.CSSProperties;
    updateStyle(style: React.CSSProperties): void;

    theme: Theme;
    styleMap: IStyleMap;
}

const ACTIVE = ' ' + Classes.ACTIVE;

class CustomTextForm extends React.PureComponent<IProps> {
    render() {
        const { style } = this.props;

        return (
            <FormSection onClick={(e) => e.stopPropagation()}>
                {this.renderButtonBar(style)}
                {this.renderFontDetails(style)}
                <ColorPicker
                    color={this.props.style.color}
                    maxWidth={275}
                    setColorProp={this.setColorProp}
                    theme={this.props.theme}
                    styleMap={this.props.styleMap}
                />
            </FormSection>
        );
    }

    renderButtonBar(style: React.CSSProperties) {
        const { fontStyle, fontWeight, textAlign, textDecoration } = style;
        const { updateStyle } = this.props;
        return (
            <ButtonBar>
                <FormatButton
                    icon="bold"
                    onClick={() =>
                        updateStyle({
                            fontWeight: fontWeight === 400 ? 600 : 400,
                        })
                    }
                    className={Classes.MINIMAL + (fontWeight === 600 ? ACTIVE : '')}
                />
                <FormatButton
                    icon="italic"
                    onClick={() =>
                        updateStyle({
                            fontStyle: fontStyle === 'normal' ? 'italic' : 'normal',
                        })
                    }
                    className={Classes.MINIMAL + (fontStyle === 'italic' ? ACTIVE : '')}
                />
                <FormatButton
                    icon="underline"
                    onClick={() =>
                        updateStyle({
                            textDecoration: textDecoration === 'none' ? 'underline' : 'none',
                        })
                    }
                    className={Classes.MINIMAL + (textDecoration === 'underline' ? ACTIVE : '')}
                />
                <FormatButton
                    icon="align-left"
                    onClick={() => updateStyle({ textAlign: 'left' })}
                    className={Classes.MINIMAL + (textAlign === 'left' ? ACTIVE : '')}
                />
                <FormatButton
                    icon="align-center"
                    onClick={() => updateStyle({ textAlign: 'center' })}
                    className={Classes.MINIMAL + (textAlign === 'center' ? ACTIVE : '')}
                />
                <FormatButton
                    icon="align-right"
                    onClick={() => updateStyle({ textAlign: 'right' })}
                    className={Classes.MINIMAL + (textAlign === 'right' ? ACTIVE : '')}
                />
                <FormatButton
                    icon="align-justify"
                    onClick={() => updateStyle({ textAlign: 'justify' })}
                    className={Classes.MINIMAL + (textAlign === 'justify' ? ACTIVE : '')}
                />
            </ButtonBar>
        );
    }

    renderFontDetails(style: React.CSSProperties) {
        const { fontFamily, fontSize } = style;
        const renderedOptions = AVAILABLE_FONTS.map(({ label }, i) => {
            return (
                <option key={i} value={label}>
                    {label}
                </option>
            );
        });

        // TODO: typesystem jank can probably be removed
        const FontSizeInputAny: any = FontSizeInput;

        return (
            <FontDetails>
                <FontSelect className={`${Classes.SELECT} ${Classes.FILL}`}>
                    <select
                        value={fontFamily || 'Arial'}
                        onChange={(e) =>
                            this.props.updateStyle({
                                fontFamily: e.target.value,
                            })
                        }
                    >
                        {renderedOptions}
                    </select>
                </FontSelect>
                <FontSizeInputAny
                    value={fontSize}
                    onValueChange={(fontSize) => this.props.updateStyle({ fontSize })}
                    min={5}
                    max={500}
                    onBlur={() => this.props.updateStyle({})}
                />
            </FontDetails>
        );
    }

    setColorProp = (c: IColor | IColorCustomKey) => {
        const color = isColor(c) ? rgbToString(c.rgb) : c;
        this.props.updateStyle({ color });
    };
}

export default CustomTextForm;
