import * as React from 'react';

import { IParamProps, ITier, ParamValueType } from 'reports/modules/financials/params';
import { adjustAbsCaps, ParamTiers } from 'reports/modules/financials/components/ParamTiers';
import { ITableConfig } from 'reports/modules/financials/components/ParamTable';

export interface IIncentivesConfig extends Omit<ITableConfig, 'type'> {
    type: ParamValueType.IncentiveTierTable;
    tier_value_path?: 'value_ratio' | 'per_w' | 'per_kwh';
    tier_cap_path?: 'value_cap' | 'kwh_cap';
    abs_cap_path?: 'w_cap';
}

export class IncentiveTierTable extends React.Component<IParamProps<IIncentivesConfig>> {
    incentiveTableConfig = {
        ...this.props.parameter,
        type: ParamValueType.Table,
    } as ITableConfig;

    generateParamTiersData = () => {
        const { parameter, value } = this.props;

        let tiers;
        if (parameter.tier_value_path) {
            // Financial model incentive
            const tierValuePath: string = parameter.tier_value_path;
            tiers = value.map((tier) => {
                const tierValue = tier[tierValuePath];
                if (parameter.abs_cap_path) {
                    // Nameplate incentive: w_cap <--> abs_cap
                    return {
                        tier_value: tierValue,
                        abs_cap: tier[parameter.abs_cap_path],
                    };
                }
                // System % cost or production incentive: value_cap, kwh_cap <--> tier_cap
                if (parameter.tier_cap_path === undefined) {
                    throw new Error('Tier cap path for financial model incentive unspecified.');
                }
                return {
                    tier_value: tierValue,
                    tier_cap: tier[parameter.tier_cap_path],
                };
            });
            if (parameter.abs_cap_path) {
                adjustTierCapsOffAbsCaps(tiers);
            } else {
                adjustAbsCaps(tiers, 'tier_cap');
            }
        } else {
            // Project incentive
            tiers = value.slice();
            if (tiers.length && tiers[0].abs_cap === undefined) {
                adjustAbsCaps(tiers, 'tier_cap');
            }
        }
        return tiers;
    };

    render() {
        const { disabled } = this.props;
        const tiers = this.generateParamTiersData();

        return (
            <ParamTiers
                parameter={this.incentiveTableConfig}
                value={tiers}
                updateFn={(val) => this.updateIncentive(val)}
                disabled={disabled}
                showLastTierCapToggle={true}
            />
        );
    }

    generalToSpecificTiers = (generalTiers: ITier[]) => {
        const { parameter } = this.props;
        return generalTiers.map((tier) => {
            const specificTier = {};
            if (parameter.tier_value_path) {
                specificTier[parameter.tier_value_path] = tier.tier_value;
            } else {
                throw Error('tierValuePath must exist for conversion');
            }
            if (parameter.tier_cap_path) {
                specificTier[parameter.tier_cap_path] = tier.tier_cap;
            }
            if (parameter.abs_cap_path) {
                specificTier[parameter.abs_cap_path] = tier.abs_cap;
            }
            return specificTier;
        });
    };

    updateIncentive = (value: ITier[]) => {
        const { parameter, updateFn } = this.props;
        if (!updateFn) return;
        if (parameter.tier_value_path) {
            // Financial model incentive
            updateFn(this.generalToSpecificTiers(value));
        } else {
            // Project incentive
            updateFn(value);
        }
    };
}

function adjustTierCapsOffAbsCaps(tiers: ITier[]) {
    if (tiers.length === 0) return;

    if (tiers[0].abs_cap == null) {
        tiers[0].tier_cap = null;
    }
    tiers[0].tier_cap = tiers[0].abs_cap;
    for (let i = 1; i < tiers.length; i += 1) {
        const prevTier = tiers[i - 1];
        const tier = tiers[i];
        if (prevTier && prevTier.abs_cap != null && tier && tier.abs_cap != null) {
            tiers[i].tier_cap = tier.abs_cap - prevTier.abs_cap;
        }
    }
}
