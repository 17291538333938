import { isEmpty } from 'lodash';

import * as React from 'react';
import { ChromePicker, CirclePicker } from 'react-color';
import { Colors, Icon, Popover, Position } from '@blueprintjs/core';

import { DEFAULT_PALETTE, TRANSPARENT_BG } from 'reports/styles/global';

import { Theme } from 'reports/models/theme';
import { getReadableColor, IColorCustomKey, IRgba, IStyleMap } from 'reports/modules/report/styles';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export interface IColor {
    hex: string;
    hsl: { h: number; s: number; l: number; a: number };
    hsv: { h: number; s: number; v: number; a: number };
    oldHue: number;
    rgb: IRgba;
    source: string;
}

type ThemeColorPicker = Pick<Theme, 'primary_color' | 'secondary_color' | 'palette'> & Partial<Theme>;

interface IColorPicker extends React.InputHTMLAttributes<HTMLInputElement> {
    theme: ThemeColorPicker;
    setColorProp: (color: IColor | IColorCustomKey, e: React.SyntheticEvent<HTMLDivElement>) => void;
    color?: string;
    maxWidth?: number;
    style?: React.CSSProperties;
    styleMap?: IStyleMap;
}

const ColorPickerContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2px 0;

    .circle-picker {
        flex: 1;
        justify-content: flex-start;
        padding: 4px 0;
    }
`;

const ColorSwatch = styled.div<{ size: number; color?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    margin-right: 5px;
    border-radius: 100%;
    font-size: 16px;
    font-weight: 600;
    transform: scale(1);
    transition: 100ms transform ease;
    transition: 100ms box-shadow ease;
    box-shadow: ${({ color, size }) => `inset 0 0 0 ${size / 2}px ${color}`};

    &:hover {
        transform: scale(1.2);
    }
    &.active {
        box-shadow: ${({ color }) => `inset 0 0 0 3px ${color}`};
        background: #fff5;

        .label {
            color: ${Colors.BLACK};
            user-select: none;
        }
    }
    .label {
        color: ${({ color }) => (color !== undefined ? getReadableColor(color) : Colors.BLACK)};
        user-select: none;
    }
`;

const ColorSwatchButton = styled(ColorSwatch)`
    border: 3px solid;
    font-size: 14px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
    }
`;

const TRANSPARENT_RGB = 'rgba(0,0,0,0)';

export const ColorPicker: React.SFC<IColorPicker> = ({ setColorProp, color, theme, maxWidth, style, styleMap }) => {
    const buttonWidth = 36;
    const defaultMarginRight = 5;
    const maxSwatchWidth = 28;

    const dimensions = {
        height: buttonWidth,
        width: buttonWidth,
        marginRight: defaultMarginRight,
    };

    // Colors to display in palette
    const { palette } = theme;
    const paletteColors = palette && !isEmpty(palette.colors) ? palette.colors : DEFAULT_PALETTE;

    // Calculate swatch and palette widths, including theme primary and secondary colors if they exist
    const totalColors = paletteColors.length + (theme.primary_color ? 1 : 0) + (theme.secondary_color ? 1 : 0);
    const swatchWidth = maxWidth
        ? (maxWidth - buttonWidth - dimensions.marginRight) / totalColors - defaultMarginRight - 1
        : 24;
    const swatchSize = Math.min(swatchWidth, maxSwatchWidth);
    const paletteWidth = (swatchSize + defaultMarginRight) * paletteColors.length;

    // Apply appropriate swatch select button styles and fetch color value if prop.color is a valid styleMap key
    let swatchButtonStyle;
    let trueColor = color;
    if (color === TRANSPARENT_RGB) {
        swatchButtonStyle = {
            color: Colors.DARK_GRAY1,
            borderColor: Colors.DARK_GRAY1,
            ...dimensions,
        };
    } else {
        const styleMapColor = color && styleMap && styleMap[color] && styleMap[color].color;
        const themeColor =
            color === 'color_primary'
                ? theme.primary_color
                : color === 'color_secondary'
                ? theme.secondary_color
                : undefined;
        trueColor = styleMapColor ?? themeColor ?? color;
        swatchButtonStyle = {
            color: trueColor,
            borderColor: trueColor,
            ...dimensions,
        };
    }

    const content = <ChromePicker color={trueColor} onChange={setColorProp} onChangeComplete={setColorProp} />;
    return (
        <ColorPickerContainer style={{ ...style, ...(maxWidth ? { width: maxWidth } : {}) }}>
            <div style={swatchButtonStyle}>
                <Popover content={content} position={Position.BOTTOM_LEFT}>
                    <ColorSwatchButton
                        size={34}
                        style={
                            color === TRANSPARENT_RGB
                                ? {
                                      background: TRANSPARENT_BG,
                                      color: '#a3a8ad',
                                  }
                                : {}
                        }
                    >
                        <Icon icon="tint" />
                    </ColorSwatchButton>
                </Popover>
            </div>
            {theme.primary_color ? (
                <ColorSwatch
                    color={theme.primary_color}
                    size={swatchSize}
                    className={color === 'color_primary' ? 'active' : ''}
                    onClick={(e) => setColorProp('color_primary', e)}
                >
                    <span className="label">P</span>
                </ColorSwatch>
            ) : null}
            {theme.secondary_color ? (
                <ColorSwatch
                    color={theme.secondary_color}
                    size={swatchSize}
                    className={color === 'color_secondary' ? 'active' : ''}
                    onClick={(e) => setColorProp('color_secondary', e)}
                >
                    <span className="label">S</span>
                </ColorSwatch>
            ) : null}
            <CirclePicker
                color={color}
                width={paletteWidth || 220}
                colors={paletteColors}
                circleSize={swatchSize}
                circleSpacing={defaultMarginRight}
                onChangeComplete={setColorProp}
            />
        </ColorPickerContainer>
    );
};

export default ColorPicker;
