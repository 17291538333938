import Flex from 'reports/components/core/containers/Flex';

import { styled } from 'reports/styles/styled-components';

const Panels = styled(Flex.Container)`
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    padding: 16px 16px 24px 16px;
`;

const LeftPanel = styled.div`
    padding-right: 24px;
`;
const RightPanel = styled.div``;

export { LeftPanel, Panels, RightPanel };
