import * as React from 'react';

import { MaybeElement } from '@blueprintjs/core';

interface ILineItem {
    label: string;
    line: React.ReactNode;
    after?: MaybeElement;
}

export const LineItem: React.SFC<ILineItem> = ({ label, line, after = null }) => (
    <div className="finance-parameter-entry">
        <div className="finance-parameter-line">
            <div className="line-edit">{line}</div>
            <div className="line-text">{label}</div>
        </div>
        {after}
    </div>
);
