import { merge } from 'lodash';
import invariant from 'invariant';
import * as React from 'react';
import { connect } from 'react-redux';

import { createRouteNodeSelector } from 'redux-router5';
import { State as RouterState } from 'router5';
import { IParams, IRoute, IRouteConfig } from './types';
import { isParentOfRoute, indexRoutes } from './utils';
import { createSelector } from 'reselect';

interface IRouteComponent {
    name: string;
    exact?: boolean; // if false, will show when children routes are active
    nameNot?: string | RegExp;

    // If children is a function, it'll get called w/ the route params, route data, and selected redux objects
    // Note: the type should really be "ReactNode" instead of "Element | Element[]", but the former gives some
    // painful type errors that I don't have time to dig into right now.
    children: JSX.Element | JSX.Element[] | ((params?: any) => React.ReactNode);
}

interface IWrappedRouteComponent<State = any> extends IRouteComponent {
    state: State;
    selectors?: { k: (state: State, props: IParams) => any };
    route: IRoute;
}

const RouteComponentSFC: React.FC<IWrappedRouteComponent> = ({
    name,
    nameNot,
    route,
    children,
    state,
    selectors = undefined,
    exact = true,
}) => {
    if (route == null) {
        return null as any;
    }

    if (name === route.name || (!exact && isParentOfRoute(name, route.name))) {
        if (nameNot) {
            if (typeof nameNot === 'string') {
                if (nameNot === route.name || isParentOfRoute(nameNot, route.name)) {
                    return null as any;
                }
            } else if (route.name.match(nameNot)) {
                return null as any;
            }
        }

        if (typeof children === 'function') {
            const routeData = route.data != null || selectors != null ? { ...route.params } : route.params;

            if (route.data != null) {
                routeData.data = route.data;
            }

            if (selectors != null) {
                // dependency middleware will createa a data object that we should also expose to the selectors
                const selectorProps = { ...route.params, data: route.data };

                for (const key of Object.keys(selectors)) {
                    routeData[key] = selectors[key](state, selectorProps);
                }
            }

            return children(routeData);
        }

        return children;
    }

    return null as any;
};

/**
 * get all the preceding substrings separated by periods
 * 'a.b.c' = ['a', 'a.b', 'a.b.c']
 * @param routeName
 */
function getAncestorRoutes(routeName: string) {
    const routeSegments = routeName.split('.');

    const rtn: string[] = [];
    for (let i = 0; i < routeSegments.length; i += 1) {
        rtn.push(routeSegments.slice(0, i + 1).join('.'));
    }

    return rtn;
}

const _getRouteName = (ownProps: IRouteComponent) => ownProps.name;

export function makeRouteComponent<State extends { router: RouterState }>(
    routes: IRouteConfig<IParams, State>[],
): React.FC<IRouteComponent> {
    const indexedRoutes = indexRoutes(routes);

    return connect<any, any, any, State>(() => {
        const routeDependencies = createSelector(_getRouteName, (name) => {
            invariant(indexedRoutes[name] !== undefined, `Route "${name}" does not exist`);

            const selectors = merge({}, ...getAncestorRoutes(name).map((rtName) => indexedRoutes[rtName].selectors));
            return selectors;
        });

        return (state: State, ownProps: IRouteComponent) => {
            const selectors = routeDependencies(ownProps);
            const routeSelector = createRouteNodeSelector(ownProps.name);

            return {
                selectors,
                state,
                route: routeSelector(state).route,
            };
        };
    })(RouteComponentSFC);
}
