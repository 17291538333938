import * as React from 'react';

import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';

import { registerWidget, IWidgetRenderProps, IReportContext } from 'reports/modules/report/widgets';
import DesignBOMTableContainer from 'reports/modules/project/components/DesignBOMTable';
import DesignRender from 'reports/modules/project/components/DesignRender';
import DesignWiringZoneTable from 'reports/modules/project/components/DesignWiringZoneTable';
import { ProjectDetailWidgetTable } from 'reports/modules/project/components/ProjectDetailTable';
import ProjectLocationMap from 'reports/modules/project/components/ProjectLocationMap';
import { ScenarioSummaryWidgetTable } from 'reports/modules/project/components/ScenarioSummary';
import SystemLossChart from 'reports/modules/project/components/SystemLossChart';

type IProjectDetailProps = IWidgetRenderProps<object, Pick<IReportContext, 'project'>>;
export const ProjectDetailWidget = registerWidget('project_detail_table', {
    Component: ({ context, className }: IProjectDetailProps) => (
        <ProjectDetailWidgetTable className={className} {...context} />
    ),
    metadata: {
        category: 'project',
        dimensions: { h: 300, w: 350 },
        displayName: Translations.widgets.project_detail_header,
        icon: IconNames.PANEL_TABLE,
    },
    dependencies: ['project'],
});

type IProjectLocationProps = IWidgetRenderProps<object, Pick<IReportContext, 'project'>>;
export const ProjectLocationWidget = registerWidget('project_location_map', {
    // TODO: add zoom level ['building', 'neightborhood', 'city', 'state'] (see issue #704)
    Component: ({ context }: IProjectLocationProps) => <ProjectLocationMap project={context.project} />,
    metadata: {
        category: 'project',
        dimensions: { h: 300, w: 300 },
        displayName: Translations.widgets.project_location_header,
        icon: IconNames.MAP_MARKER,
    },
    dependencies: ['project'],
});

type IScenarioSummaryProps = IWidgetRenderProps<object, Pick<IReportContext, 'scenario'>>;
export const ScenarioSummaryWidget = registerWidget('scenario_summary', {
    Component: ({ context, className }: IScenarioSummaryProps) => (
        <ScenarioSummaryWidgetTable className={className} {...context} />
    ),
    metadata: {
        category: 'project',
        dimensions: { h: 200, w: 300 },
        displayName: Translations.widgets.scenario_summary_header,
        icon: IconNames.PANEL_TABLE,
    },
    dependencies: ['scenario'],
});

type IDesignRenderProps = IWidgetRenderProps<object, Pick<IReportContext, 'design'>>;
export const DesignRenderWidget = registerWidget('design_render', {
    Component: ({ context }: IDesignRenderProps) => <DesignRender design={context.design} />,
    metadata: {
        category: 'project',
        dimensions: { h: 400, w: 400 },
        displayName: Translations.widgets.design_render_header,
        icon: IconNames.LAYERS,
    },
    dependencies: ['design'],
});

type IDesignBomTableProps = IWidgetRenderProps<object, Pick<IReportContext, 'design'>>;
export const DesignBomTableWidget = registerWidget('design_bom_table', {
    Component: ({ context, className }: IDesignBomTableProps) => (
        <DesignBOMTableContainer className={className} design={context.design} />
    ),
    metadata: {
        category: 'project',
        dimensions: { h: 500, w: 350 },
        displayName: Translations.widgets.design_bom_table_header,
        icon: IconNames.PANEL_TABLE,
    },
    dependencies: ['design'],
});

type IDesignWiringZoneProps = IWidgetRenderProps<object, Pick<IReportContext, 'design'>>;
export const DesignWiringZoneWidget = registerWidget('design_wz_table', {
    Component: ({ context, className }: IDesignWiringZoneProps) => (
        <DesignWiringZoneTable className={className} design={context.design} />
    ),
    metadata: {
        category: 'project',
        dimensions: { h: 500, w: 350 },
        displayName: Translations.widgets.design_wiring_zone_header,
        icon: IconNames.PANEL_TABLE,
    },
    dependencies: ['design'],
});

type ISystemLossProps = IWidgetRenderProps<object, Pick<IReportContext, 'simulation'>>;
export const SystemLossChartWidget = registerWidget('system_loss_chart', {
    Component: ({ context, config }: ISystemLossProps) => <SystemLossChart {...context} layout={config.layout} />,
    EditingComponent: ({ context, config }: ISystemLossProps) => (
        <SystemLossChart mouseEvents={false} {...context} layout={config.layout} />
    ),
    metadata: {
        category: 'project',
        dimensions: { h: 500, w: 350 },
        displayName: Translations.widgets.system_loss_chart_header,
        icon: IconNames.PIE_CHART,
    },
    dependencies: ['simulation'],
});
