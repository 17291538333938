import * as React from 'react';
import { round } from 'lodash';

import { DEFAULT_THEME } from 'reports/modules/themes';
import { SHORT_MONTHS } from 'reports/utils/time';

import { ILayoutChartProps, LayoutChart } from 'reports/modules/report/components/charts';

interface IOwnProps extends ILayoutChartProps {
    energy: number[];
    showXLabels?: boolean;
    title?: string;
}

function makeConfig(data: number[] = [], title: string = '', showXLabels: boolean = true): Highcharts.Options {
    return {
        series: [
            {
                data,
                name: 'Energy Consumption',
                color: DEFAULT_THEME.secondary_color,
            },
        ],
        chart: {
            type: 'area',
            backgroundColor: 'transparent',
        },
        title: { text: title },
        xAxis: {
            categories: SHORT_MONTHS,
            labels: {
                enabled: showXLabels,
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: 'kWh',
            },
            visible: true,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter(this: any) {
                return `${this.x}: <b>${round(this.y, 1)} kWh</b>`;
            },
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            series: {
                animation: false,
            },
        },
    };
}

export const MonthlyChart: React.SFC<IOwnProps> = ({ energy, title, showXLabels, ...layoutProps }) => (
    <LayoutChart config={makeConfig(energy, title, showXLabels)} {...layoutProps} />
);

export default MonthlyChart;
