import * as React from 'react';

import { IButtonProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Button from './Button';

export const CopyButton: React.FC<Omit<IButtonProps, 'icon'>> = (props) => (
    <Button icon={IconNames.DUPLICATE} text="Copy" {...props} />
);

export default CopyButton;
