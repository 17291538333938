import * as React from 'react';

import { Form, handleRequestException } from 'reports/components/forms';
import { SaveCancelButtons } from 'reports/components/core/controls';
import { RouterStateLock } from 'reports/utils/router';

import { FormBareNumericInput } from 'reports/components/forms/inputs/experimental';
import ConsumptionEnforcementPeriodSelect from 'reports/modules/settings/admin/components/ConsumptionEnforcementPeriodSelect';
import PayByInvoiceEnableControl from 'reports/modules/settings/admin/components/PayByInvoiceEnableControl';
import { FormData, SubscriptionExtensionFormData } from 'reports/modules/settings/admin/views/SelfServeView';
import SelfServeCancellation from 'reports/modules/settings/admin/components/SelfServeCancellation';
import SelfServeExtension from 'reports/modules/settings/admin/components/SelfServeExtension';

import { SubscriptionInput } from 'reports/modules/settings/admin/components/SubscriptionInput';

import { Subscription } from 'reports/models/subscription';
import { SelfServeSettings } from 'reports/models/team';
import { User } from 'reports/models/user';

type SelfServeFormType = SelfServeSettings & SubscriptionExtensionFormData;

interface Props {
    baseValue: SelfServeFormType;
    isDirty: (formData: FormData) => boolean;
    minDate: Date;
    onSubmit: (formData: SelfServeFormType) => void;
    showConsumptionAllocation: boolean;
    subscription: Subscription;
    user: User;
}

const SelfServeForm = ({
    baseValue,
    isDirty,
    minDate,
    onSubmit,
    showConsumptionAllocation,
    subscription,
    user,
}: Props) => (
    <Form<SelfServeFormType, void>
        baseValue={baseValue}
        onSubmit={onSubmit}
        exceptionHandler={(exc) => handleRequestException(exc)}
    >
        {({ formData, formErrors, submitForm, clearForm }) => (
            <>
                <RouterStateLock
                    cancellable={true}
                    showExitPrompt={isDirty(formData)}
                    onSave={submitForm}
                    onDontSave={clearForm}
                />
                <ConsumptionEnforcementPeriodSelect disabled={!showConsumptionAllocation} />
                <SubscriptionInput
                    disabled={!showConsumptionAllocation}
                    helperText="Allocate additional projects for current billing period. Leave field blank (-) sets 0 additional"
                    title="Additional Project Allocation"
                >
                    <FormBareNumericInput
                        path="admin_allowance_project_count"
                        placeholder="Additional Projects"
                        min={0}
                        max={1000}
                        disabled={!showConsumptionAllocation}
                        inline
                        bold
                        integerOnly
                    />
                </SubscriptionInput>
                <SelfServeCancellation subscription={subscription} />
                <PayByInvoiceEnableControl user={user} />
                <SelfServeExtension
                    disabled={!subscription.isV2 || subscription.is_pay_by_invoice}
                    formErrors={formErrors}
                    minDate={minDate}
                    subscriptionExtension={subscription.subscription_extension}
                    subscriptionExtensionEnabled={formData.subscription_extension_enabled}
                />
                <SaveCancelButtons
                    onSave={submitForm}
                    hasChanges={isDirty(formData)}
                    onCancel={clearForm}
                    style={{ marginBottom: 10 }}
                />
            </>
        )}
    </Form>
);

export default SelfServeForm;
