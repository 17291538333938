import { get } from 'lodash';

import * as React from 'react';

import { Checkbox } from '@blueprintjs/core';

import FormInput, { IFormInputProps } from './FormInput';
import { FormConsumer } from '../types';

type IProps = Omit<IFormInputProps, 'inputComponent' | 'inputProps' | 'placeholder' | 'labelWidth' | 'renderInput'>;

const CheckboxInput: React.FC<IProps> = ({ path, label, defaultValue, disabled, ...inputProps }) => (
    <FormConsumer>
        {({ formData, updateValue }) => (
            <FormInput
                path={path}
                defaultValue={defaultValue}
                inputComponent={Checkbox}
                inputProps={{
                    label,
                    checked: get(formData, path, defaultValue),
                    onChange: () => updateValue(path, !get(formData, path, defaultValue)),
                }}
                {...inputProps}
                disabled={disabled}
            />
        )}
    </FormConsumer>
);

export default CheckboxInput;
