import * as React from 'react';

import { Project } from 'reports/models/project';
import { Report, ReportVersion, ReportConfiguration } from 'reports/models/report';

import { ContextBarControls } from 'reports/components/ContextBar';
import { EditButton } from 'reports/components/core/controls';
import { PrintButton } from 'reports/modules/pdf';

import EditReportTemplateButton from '../components/EditReportTemplateButton';
import ViewReportTemplateButton from '../components/ViewReportTemplateButton';

export interface IProjectReportViewerControlsProps {
    report: Report;
    project: Project;
    reportConfig: ReportConfiguration | null;
    reportVersion: ReportVersion | null;
    canViewFinancials: boolean;
    configureBtnOnClick: () => Promise<any>;
}

const ProjectReportViewerControls = (props: IProjectReportViewerControlsProps) => {
    const { report, project, reportConfig, reportVersion, canViewFinancials } = props;
    const { configureBtnOnClick } = props;

    return (
        <ContextBarControls>
            {canViewFinancials ? (
                <>
                    {reportConfig || reportVersion?.document.configurable ? (
                        <>
                            <ViewReportTemplateButton report={report} project={project} />
                            <EditButton text="Configure" onClick={configureBtnOnClick} />
                        </>
                    ) : (
                        <EditReportTemplateButton report={report} project={project} />
                    )}
                </>
            ) : (
                <EditReportTemplateButton report={report} project={project} upsellDisabled={false} />
            )}
            <PrintButton />
        </ContextBarControls>
    );
};

export default ProjectReportViewerControls;
