import { round } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';

import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';

import { IAppState } from 'reports/types';

import * as rep from 'reports/modules/report';

import { NumericInput } from 'reports/components/helpers/formHelpers';

interface IOwnProps {
    pageCount: number;
}

type IDispatchProps = typeof mapDispatchToProps;
type IStateProps = ReturnType<typeof mapStateToProps>;

const pageNumFromIdx = (pageNum) => pageNum + 1;
const pageIdxFromNum = (pageIdx) => pageIdx - 1;

class ReportViewControls extends React.PureComponent<IOwnProps & IStateProps & IDispatchProps> {
    componentWillUnmount() {
        this.props.setCurrentPage(0);
    }

    render() {
        const {
            currentPage,
            setCurrentPage,

            zoomScale,
            setZoomScale,

            pageCount,
        } = this.props;

        return (
            <Flex.Container alignV={Flex.AlignV.CENTER}>
                <Icon icon={IconNames.ZOOM_IN} className="label-icon" />
                <NumericInput
                    style={{ width: 80 }}
                    leftIcon={IconNames.PERCENTAGE}
                    value={round(zoomScale * 100, 5)}
                    min={25}
                    max={200}
                    onValueChange={(val) => setZoomScale(val / 100)}
                />
                <Icon icon={IconNames.DOCUMENT} className="label-icon" />
                <NumericInput
                    style={{ width: 60 }}
                    value={pageNumFromIdx(currentPage)}
                    disabled={pageCount <= 1}
                    min={1}
                    max={pageCount}
                    onValueChange={(newPageNum) => setCurrentPage(pageIdxFromNum(Math.round(newPageNum)))}
                />
            </Flex.Container>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    currentPage: rep.selectors.currentPage(state),
    zoomScale: rep.selectors.zoomScale(state),
});

const mapDispatchToProps = {
    setCurrentPage: rep.actions.setCurrentPage,
    setZoomScale: rep.actions.setZoomScale,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportViewControls);
