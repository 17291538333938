/* tslint:disable:max-line-length */
// prettier is buggy and sometimes formats lines to 121 chars
import * as React from 'react';
import { connect } from 'react-redux';

import { Dialog, Classes, Intent } from '@blueprintjs/core';

import { Toaster } from 'reports/modules/Toaster';

import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';
import DialogActions from 'reports/components/dialog/components/DialogActions';
import { PrimaryButton } from 'reports/components/core/controls';
import * as auth from 'reports/modules/auth';
import * as usr from 'reports/models/user';

export type IStateProps = ReturnType<typeof mapStateToProps>;
export type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IProps = IStateProps & IDispatchProps;

type GDPRDialogueProps = {
    user: usr.User;
    agreeGDPR: (user: usr.User) => Promise<usr.User>;
};

const GDPRDialogue: React.FC<GDPRDialogueProps> = (props) => {
    const { agreeGDPR, user } = props;
    const [isOpen, setIsOpen] = React.useState(true);

    const handleAccept = async () => {
        try {
            await agreeGDPR(user);
            setIsOpen(false);
        } catch (error) {
            Toaster.show({ intent: Intent.DANGER, message: 'Error agreeing to GDPR' });
        }
    };
    return (
        <Dialog title="Updated Terms of Agreement" isOpen={isOpen} isCloseButtonShown={false} canEscapeKeyClose={false}>
            <div className={Classes.DIALOG_BODY}>
                <p>We've updated our policies and processes to comply with the new EU GDPR regulations.</p>
                <p>
                    As always, we take privacy seriously. In addition to our commitment to handling your data
                    professionally and with the highest integrity, we have added procedures to correct or delete data
                    upon your request, and to improve transparency on how the data is processed. You can find further
                    detail in our{' '}
                    <a href="https://aurorasolar.com/privacy-policy/" target="_blank">
                        Data Processing Agreement
                    </a>
                    .
                </p>
                <p>
                    Please contact us with any questions:{' '}
                    <a href="mailto:support@helioscope.com">support@helioscope.com</a>
                </p>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <DialogActions>
                    <PrimaryButton text="I Agree" onClick={handleAccept} loadingWhileAwaiting />
                </DialogActions>
            </div>
        </Dialog>
    );
};

const GDPRBoundary: React.FC<IProps> = (props) => {
    const { children, user, agreeGDPR } = props;
    const showGDPR = user.gdpr_authorization_ip === null;

    return <>{showGDPR ? <GDPRDialogue user={user} agreeGDPR={agreeGDPR} /> : children}</>;
};

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = (dispatch: any, ownProps: any) =>
    bindActions(() => {
        return {
            agreeGDPR: (user) => usr.api.save({ ...user, is_gdpr_authorized: true }),
        };
    })(dispatch, ownProps);

export default connect(mapStateToProps, mapDispatchToProps)(GDPRBoundary);
