import Logger from 'js-logger';
import { chain, cloneDeep, reduce } from 'lodash';

import { Hour } from 'reports/utils/time';

import * as ur from 'reports/models/utility_rate';

const logger = Logger.get('rates');

export function getTOUfromSeason(season: ur.ISeasonConfig): ur.ITOUPeriod[] {
    const tou: ur.ITOUPeriod[] = [];
    for (const schedule of season.rate_schedules) {
        if (schedule.tou) {
            tou.push(schedule.tou);
        }
    }
    return tou;
}

export function aggregateSeasonalTOU(seasonalInput: ur.ISeasonalInput | null): ur.ITOUPeriod[] {
    return reduce(
        seasonalInput ? seasonalInput.seasons : [],
        (results: ur.ITOUPeriod[], season: ur.ISeasonConfig) => results.concat(getTOUfromSeason(season)),
        [],
    );
}

const _mergeOverlapping = (sortedIntervals: ur.IInterval[]) =>
    reduce(
        sortedIntervals,
        (merged: ur.IInterval[], interval: ur.IInterval) => {
            if (!merged.length) {
                return merged.concat([interval]);
            }

            const prevInterval = merged.pop()!;
            const [prevStart, prevEnd] = prevInterval;
            const [start, end] = interval;

            if (start <= prevEnd) {
                const newEnd = end === 0 ? end : (Math.max(prevEnd, end) as Hour);
                return merged.concat([[prevStart, newEnd]]);
            }

            return merged.concat([prevInterval, interval]);
        },
        [],
    );

const sortTOUIntervals = (periods: ur.ITOUPeriod[], mergeOverlapping: boolean = false) => {
    const sorted = chain(periods)
        .reduce((intervals: ur.IInterval[], period: ur.ITOUPeriod) => intervals.concat(period.intervals), [])
        .sortBy('[0]')
        .value();

    return mergeOverlapping ? _mergeOverlapping(sorted) : sorted;
};

export function getOffPeakIntervals(tou: ur.ITOUPeriod[]): ur.IInterval[] {
    const offPeak: ur.IInterval[] = [];
    const userDefinedTOU = cloneDeep(tou);

    // No other periods are defined - only off peak
    if (userDefinedTOU.length === 1) {
        return [[0, 0]];
    }

    userDefinedTOU.pop(); // Remove current off peak intervals

    const sorted = sortTOUIntervals(userDefinedTOU, true);

    let prevEnd;
    for (let i = 0; i < sorted.length; i += 1) {
        const [start, end]: ur.IInterval = sorted[i];

        if (prevEnd === undefined) {
            prevEnd = end;

            if (start !== 0) {
                const startInterval: ur.IInterval = [0, start];
                offPeak.push(startInterval);
            }
        } else {
            if (start !== prevEnd) {
                const missingInterval: ur.IInterval = [prevEnd, start];
                offPeak.push(missingInterval);
            }
            prevEnd = end;
        }

        // Add last interval
        if (i === sorted.length - 1 && end !== 0) {
            const endInterval: ur.IInterval = [end, 0];
            offPeak.push(endInterval);
        }
    }
    return offPeak;
}

/**
 * Given seasonal input, return boolean value for each season indicating valid TOU schedules
 */
function validateTOUsettings(seasonalInput: ur.ISeasonalInput): boolean[] {
    const { seasons } = seasonalInput;

    const validSeasons: boolean[] = [];

    for (const sIdx in seasons) {
        const periods: ur.ITOUPeriod[] = getTOUfromSeason(seasons[sIdx]);
        const sortedIntervals = sortTOUIntervals(periods);

        let validIntervalChain = true; // check that all intervals touch
        let full24 = false; // check that all 24 hours are included

        let prevEnd;
        for (let i = 0; i < sortedIntervals.length; i += 1) {
            const [start, end] = sortedIntervals[i];

            if (prevEnd === undefined) {
                prevEnd = end;

                if (start === 0) {
                    full24 = true;
                }
            } else {
                if (start !== prevEnd) {
                    const [prevStart, prevEnd] = sortedIntervals[i - 1];
                    logger.info(
                        `Invalid TOU schedule - overlapping intervals: [${start}, ${end}], [${prevStart}, ${prevEnd}]`,
                    );
                    validIntervalChain = false;
                }
                prevEnd = end;
            }

            // Check that last interval has to match up with first or end at 0 (midnight)
            if (i === sortedIntervals.length - 1) {
                if ((full24 && end !== 0) || (!full24 && end !== sortedIntervals[0][0])) {
                    full24 = false;
                }
            }
        }

        validSeasons.push(validIntervalChain && full24);
    }

    return validSeasons;
}

export function validateSeasonalInput(rate: ur.UtilityRate) {
    const ret: { error?: string } = {};

    const { seasonal_input: seasonalInput } = rate.data;

    // Currently only verifying tou settings for now
    if (seasonalInput) {
        if (aggregateSeasonalTOU(seasonalInput).length > 0) {
            const validSeasons = validateTOUsettings(seasonalInput);

            for (const sIdx in validSeasons) {
                if (!validSeasons[sIdx]) {
                    const error = `Error: invalid seasonal TOU settings for ${seasonalInput.seasons[sIdx].name}.`;
                    ret['error'] = error;
                    return ret;
                }
            }
        }
    }

    return ret;
}
