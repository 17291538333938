import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { LinkButton } from 'reports/components/core/controls';

import * as rep from 'reports/models/report';
import * as proj from 'reports/models/project';

export const ViewReportTemplateButton: React.FC<{
    report: rep.Report;
    project: proj.Project;
}> = ({ report, project }) => (
    <LinkButton
        text="View Report Template"
        icon={IconNames.DOCUMENT_OPEN}
        routeName="app.reports.report.preview"
        routeParams={{
            projectId: project.project_id,
            slug: report.slug,
            reportId: report.report_id,
        }}
        target="_blank"
    />
);
export default ViewReportTemplateButton;
