import * as React from 'react';

import { Form, handleRequestException } from 'reports/components/forms';
import { SaveCancelButtons } from 'reports/components/core/controls';
import { RouterStateLock } from 'reports/utils/router';

import { SubscriptionInput } from 'reports/modules/settings/admin/components/SubscriptionInput';
import { FormBareNumericInput } from 'reports/components/forms/inputs/experimental';

import { Team } from 'reports/models/team';
import { User } from 'reports/models/user';

interface Props {
    onSubmit: any;
    team: Team;
    trialExtensionUrl: string;
    user: User;
}

interface FormData {
    trial_project_limit: number | null;
}

const TrialForm = ({ onSubmit, team, trialExtensionUrl, user }: Props) => (
    <Form<FormData, void>
        baseValue={{ trial_project_limit: team.trial_project_limit }}
        onSubmit={onSubmit}
        exceptionHandler={(exc) => handleRequestException(exc)}
    >
        {({ dirty, submitForm, clearForm }) => (
            <>
                <RouterStateLock cancellable={true} showExitPrompt={dirty} onSave={submitForm} onDontSave={clearForm} />
                <SubscriptionInput disabled={user.isExpired()} title="Trial Project Limit">
                    <FormBareNumericInput
                        path="trial_project_limit"
                        placeholder="Total number of projects"
                        min={3}
                        max={1000}
                        disabled={false}
                        inline
                        bold
                        integerOnly
                    />
                </SubscriptionInput>
                <div>
                    To extend a Trial, use the <a href={trialExtensionUrl}>Trial extension tool</a> in HelioScope
                    classic
                </div>
                <SaveCancelButtons
                    disabled={user.isExpired()}
                    onSave={submitForm}
                    hasChanges={dirty}
                    onCancel={clearForm}
                    style={{ marginBottom: 10 }}
                />
            </>
        )}
    </Form>
);

export { FormData, TrialForm };
