/* tslint:disable:variable-name function-name */

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from './schema';

class SSOProvider extends BaseClass {
    sso_provider_id?: number;
    provider_uuid: string;
    client_id: string;
    client_secret?: string;
    discovery_endpoint: string;
}

const schemaObj = schema.addObject(SSOProvider, 'sso_provider');

const endpoint = ReduxEndpoint.fromSchema('/api/sso_provider', schemaObj);
const api = {
    get: endpoint.get<{ teamId: number }>('?team_id={teamId}'),
    create: endpoint.post<SSOProvider>(),
    save: endpoint.patch<SSOProvider>('/{sso_provider_id}'),
    delete: endpoint.delete('/{sso_provider_id}'),
};

export { api, schemaObj, SSOProvider };
