import * as React from 'react';
import { Classes, FormGroup as BpFormGroup } from '@blueprintjs/core';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

/**
 * This is still half-baked and experimental.
 * Mostly used in structuring the hierarchy in forms:
 * providing labels for components and nesting FormGroups
 * to form sections and subsections.
 *
 * Ex: UserPreferencesForm.tsx
 **/

export interface IFormGroupProps {
    label?: string;
    labelWidth?: number;
    inline?: boolean;
    fill?: boolean;
    bold?: boolean;
    helperText?: React.ReactNode;
    className?: string;
}

const FormGroup = styled(BpFormGroup)<{
    labelWidth?: number;
    bold?: boolean;
    fill?: boolean;
}>`
    &.${Classes.INLINE} {
        .${Classes.FORM_CONTENT} {
            position: relative;
            ${(props) => props.fill && 'flex: 1;'}
        }
        label.${Classes.LABEL} {
            width: ${(props) => props.labelWidth || 160}px;
            line-height: 20px;
            margin-top: 5px;
        }
    }

    label.${Classes.LABEL} {
        font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
    }
`;

export default FormGroup;
