import Q from 'q';
import { last } from 'lodash';

function _readAs(blob, method) {
    const deferred = Q.defer();
    const reader = new FileReader();

    reader.onloadend = () => deferred.resolve(reader.result);
    reader.onerror = () => deferred.reject(reader.error);
    reader[method](blob);

    return deferred.promise;
}

export function readAsText(blob) {
    return _readAs(blob, 'readAsText');
}

export function readAsArrayBuffer(blob) {
    return _readAs(blob, 'readAsArrayBuffer');
}

export function readAsBinaryString(blob) {
    return _readAs(blob, 'readAsBinaryString');
}

export function readAsDataURL(blob) {
    return _readAs(blob, 'readAsDataURL');
}

export function loadImage(url) {
    const deferred = Q.defer();
    const img = new Image();

    // Safari forbids this on data urls
    if (url.startsWith('data:') === false && url.startsWith('blob:') === false) {
        img.crossOrigin = 'Anonymous';
    }

    img.onload = () => deferred.resolve(img);
    img.onerror = (err) => deferred.reject(err);
    img.src = url;

    return deferred.promise;
}

export function downloadBlob(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const objectUrl = URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        const body = window.document.body;
        body.appendChild(tempLink);

        tempLink.href = objectUrl;
        tempLink.setAttribute('download', filename);
        tempLink.click();

        // sadly there isn't a good way to know when everything has been completed.
        // this is also how Google et al currently handle this.
        setTimeout(() => {
            body.removeChild(tempLink);
            URL.revokeObjectURL(objectUrl);
        }, 1000);
    }
}

export function getExtension(path) {
    return last(path.split('.')).toLowerCase();
}


export async function getBlobFromCanvas(canvas) {
    const deferred = Q.defer();
    if (canvas.toBlob) {
        canvas.toBlob(blob => deferred.resolve(blob));
    } else {
        deferred.resolve(dataURItoBlob(canvas.toDataURL()));
    }

    return deferred.promise;
}

function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    // data URIs are typically a string in the format:
    // data:image/png;base64,<base64 encoded raw data>
    const [header, data] = dataURI.split(',', 2);

    let byteString;
    if (header.indexOf('base64') >= 0) {
        byteString = atob(data);
    } else {
        byteString = unescape(data);
    }
    // separate out the mime component
    // looks like data:image/png;base64
    const mimeString = header.split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}


export function downloadURL(url) {
    const hiddenIFrameID = 'hiddenDownloader';
    let iframe = document.getElementById(hiddenIFrameID);

    if (iframe === null) {
        iframe = document.createElement('iframe');
        iframe.id = hiddenIFrameID;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
    }

    iframe.src = url;
}
