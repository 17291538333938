import * as React from 'react';
import { assign } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonGroup, Callout, Intent, Switch } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { RouterStateLock } from 'reports/utils/router';

import { Card, Flex, Section2 } from 'reports/components/core/containers';
import { ContextBarControls } from 'reports/components/ContextBar';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import SaveDropdown from 'reports/components/library/SaveDropdown';

import { FinancialTemplate, saver } from 'reports/models/financial_template';

import * as auth from 'reports/modules/auth';

import FinancialTemplatePreview from './EditorPreview';
import { FinancialTemplateEditor } from './Editor';

import { verifyAdvancedPipeline, getLastIncentiveIndex } from 'reports/modules/financials/model/pipeline/utils';
import { FinancialPipelineTree } from 'reports/modules/financials/model/pipeline';
import { actions as templateActions } from 'reports/modules/financials';

type Props = {
    inlineControls?: boolean;
    navigateToFinTemplatePreview: (finTemplateId: number) => void;
    template: FinancialTemplate;
};

const FinancialTemplateEditContainer = ({ inlineControls, navigateToFinTemplatePreview, template }: Props) => {
    const dispatch = useDispatch();
    const [advancedMode, setAdvancedMode] = React.useState<boolean>(
        verifyAdvancedPipeline(FinancialPipelineTree.fromRawData(template.data.root)),
    );
    const user = useSelector((state) => auth.selectors.getUser(state)!);

    const clearChanges = React.useCallback(() => dispatch(saver.get(template).clear()), []);
    const hasChanges = React.useCallback(() => dispatch(saver.get(template).hasChanges()), []);
    const shouldBlock = React.useCallback((_toState, _fromState) => hasChanges(), []);
    const saveFinancialTemplate = React.useCallback(() => dispatch(saver.get(template).save()), []);
    const saveAsNew = React.useCallback(() => dispatch(templateActions.saveAsNew(template)), []);
    const updateFinancialTemplate = React.useCallback((patch) => dispatch(saver.get(template).patch(patch)), []);

    const advancedEditorAccess = auth.permissions.canAccessAdvFinancialFeatures(user);
    const pipelineTree = FinancialPipelineTree.fromRawData(template.data.root);
    const advancedTemplate = verifyAdvancedPipeline(pipelineTree);
    const depreciationInFinancing = advancedTemplate && !verifyAdvancedPipeline(pipelineTree, true);

    const togglePublic = () =>
        updateFinancialTemplate({
            public: !template.public,
        });

    const updateDescription = (val) => {
        updateFinancialTemplate({ description: val });
    };

    const saveAndClose = async () => {
        await saveFinancialTemplate();
        navigateToFinTemplatePreview(template.financial_template_id);
    };

    const saveAsNewTemplate = async () => {
        const template = await saveAsNew();
        await clearChanges();
        navigateToFinTemplatePreview(template.financial_template_id);
    };

    const cancel = async () => {
        await clearChanges();
        navigateToFinTemplatePreview(template.financial_template_id);
    };

    const updateTree = (tree: FinancialPipelineTree) => {
        const root = tree.toRawData();
        const { data } = template;
        updateFinancialTemplate({ data: assign({}, data, { root }) });
    };

    const moveDepreciation = (pipelineTree: FinancialPipelineTree) => {
        const depreciationIndex = pipelineTree.findNode('BasicTaxDepreciationSimple');
        const incentiveIndex = getLastIncentiveIndex(pipelineTree);
        if (depreciationIndex && incentiveIndex) {
            updateTree(pipelineTree.moveNodeAfter(depreciationIndex, incentiveIndex));
        }
        setAdvancedMode(false);
    };

    // TODO: refactor using ButtonGroupSelect
    const AdvancedToggle = (
        <ButtonGroup>
            <Button
                text="Default"
                intent={advancedMode ? undefined : Intent.SUCCESS}
                active={!advancedMode}
                onClick={() => setAdvancedMode(false)}
                disabled={advancedTemplate}
            />
            <Button
                text="Advanced"
                intent={advancedMode ? Intent.SUCCESS : undefined}
                active={advancedMode}
                onClick={() => setAdvancedMode(true)}
                disabled={advancedTemplate}
            />
        </ButtonGroup>
    );

    const SaveCancelControls = (
        <SaveDropdown
            save={saveAndClose}
            saveAsNewOptions={{
                saveAsNew: saveAsNewTemplate,
                text: 'Save as New Financial Model',
            }}
            saveEnabled={hasChanges() && auth.permissions.canModifySharedTeamResource(user, template)}
            cancel={cancel}
            toastDescr={template.description}
        />
    );

    const AdvancedNotification = depreciationInFinancing ? (
        <Callout icon={IconNames.WARNING_SIGN} intent={Intent.WARNING}>
            <Flex.Container>
                <Flex.Main>
                    Depreciation has been moved from the Financing to the Incentives category. Update your model to use
                    the updated categorization and continue using the default mode for editing.
                </Flex.Main>
                <div>
                    <Button text="Update" intent={Intent.WARNING} onClick={() => moveDepreciation(pipelineTree)} />
                </div>
            </Flex.Container>
        </Callout>
    ) : (
        <Callout icon={IconNames.INFO_SIGN} intent={Intent.PRIMARY}>
            This model is in an advanced state. You cannot use default mode for editing.
        </Callout>
    );

    return (
        <>
            <RouterStateLock
                title="Save financial model changes?"
                prompt={
                    `You have unsaved changes on ` +
                    `${template.description || 'this financial model'}` +
                    `, would you like to save these changes before leaving?`
                }
                cancellable={true}
                showExitPrompt={shouldBlock}
                onSave={saveFinancialTemplate}
                onDontSave={clearChanges}
            />
            <div className="content-header">
                <EditableTitleSubHeader
                    value={template.description}
                    updateFn={(val) => updateDescription(val)}
                    right={
                        <>
                            {advancedEditorAccess && AdvancedToggle}
                            {inlineControls && SaveCancelControls}
                        </>
                    }
                />
            </div>
            <div className="sub-content-inner" style={{ padding: '8px' }}>
                {advancedEditorAccess && advancedTemplate && AdvancedNotification}
                <Flex.Container>
                    <Card style={{ flex: 1 }}>
                        <Section2
                            title="Financial Model"
                            contextEl={
                                auth.permissions.canMakeResourcePublic(user, template) && (
                                    <Switch
                                        label="Public"
                                        checked={template.public}
                                        onChange={togglePublic}
                                        large={true}
                                        style={{ marginBottom: 0 }}
                                    />
                                )
                            }
                        >
                            <FinancialTemplateEditor
                                template={template}
                                update={updateFinancialTemplate}
                                advanced={advancedMode}
                            />
                        </Section2>
                    </Card>
                    <Card style={{ justifyContent: 'flex-start', flex: 1 }}>
                        <Section2 title="Preview">
                            <Callout icon={IconNames.EYE_OPEN} intent={Intent.PRIMARY}>
                                View Only: Financial configuration inputs are disabled for model preview.
                            </Callout>
                            <FinancialTemplatePreview template={template} advanced={advancedMode} />
                        </Section2>
                    </Card>
                </Flex.Container>
            </div>
            <div className="sub-content-footer" />
            {!inlineControls && <ContextBarControls>{SaveCancelControls}</ContextBarControls>}
        </>
    );
};

export { FinancialTemplateEditContainer };
