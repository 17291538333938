import { Flex } from 'reports/components/core/containers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const Body = styled(Flex.ContainerV)`
    position: relative;
    height: 100%;
    width: 100%;

    // Now that we've wrapped the Beta application with a StyleProvider, xstyled styles are set for these common
    // components. The xstyled styles are overriden here to maintain the existing beta look.
    a {
        font-weight: normal;
    }
    p {
        font-size: 14px;
        margin-bottom: 10px;
    }
`;

const Main = styled(Flex.Main)`
    overflow: hidden;
`;

export default {
    Body,
    Main,
};
