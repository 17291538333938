import * as React from 'react';

import { IButtonProps, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Button from './Button';

const CreateButton = (buttonProps: IButtonProps) => (
    <Button icon={IconNames.ADD} intent={Intent.WARNING} {...buttonProps} />
);

export default CreateButton;
