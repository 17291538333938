import { FormGroup } from '@blueprintjs/core';
import * as React from 'react';

import { ReferrerType } from 'reports/analytics/ReferrerTypes';
import { IntentContainer } from 'reports/components/helpers/errors';
import { getUserStatusIntent, User, UserStatuses } from 'reports/models/user';
import { DetailLine } from 'reports/modules/settings/common';

import { PlanInfoWrapper } from './PlanInfo';
import { PurchaseLink } from './PurchaseLink';

interface Props {
    user: User;
    referrer: ReferrerType;
    manageBilling: boolean;
}

const LegacySubscriptionInfo = ({ user, referrer, manageBilling }: Props) => {
    const showPurchasePlan = manageBilling && user.team.can_purchase_subscription;

    return (
        <PlanInfoWrapper>
            <FormGroup label="Account Status">
                <IntentContainer intent={getUserStatusIntent(user.status)}>{UserStatuses[user.status]}</IntentContainer>
            </FormGroup>
            <FormGroup label="Renewal Date">
                <DetailLine>{user.latest_legacy_subscription_end_date!.format('ll')}</DetailLine>
                {showPurchasePlan && <PurchaseLink referrer={referrer} />}
            </FormGroup>
        </PlanInfoWrapper>
    );
};

export { LegacySubscriptionInfo };
