import * as React from 'react';

import { PopoverPosition } from '@blueprintjs/core';
import { ItemRenderer } from '@blueprintjs/select';

import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';
import { WeatherSourceType, WEATHER_SOURCE_TYPES } from 'reports/models/weather_source';

export interface Props {
    onItemSelect: (weatherSource: WeatherSourceType) => void;
    selectedType?: WeatherSourceType | null;
    disabled?: boolean;
    noFill?: boolean;
}

const WeatherSourceTypeSelect = ({ onItemSelect, selectedType, disabled, noFill }: Props) => {
    const weatherSourceTypesArray = React.useMemo(() => {
        const allWeatherSourceTypes: (WeatherSourceType | null)[] = [
            null,
            ...(Object.keys(WEATHER_SOURCE_TYPES) as WeatherSourceType[]),
        ];

        return allWeatherSourceTypes;
    }, []);

    const renderWeatherItem: ItemRenderer<WeatherSourceType> = React.useCallback(
        (weatherSourceType, { handleClick, modifiers }) => (
            <DropdownMenuItem
                key={weatherSourceType}
                title={weatherSourceType ? WEATHER_SOURCE_TYPES[weatherSourceType] : 'Always take the nearest source'}
                onClick={handleClick}
                selected={selectedType != null && weatherSourceType === selectedType}
                {...modifiers}
            />
        ),
        [selectedType],
    );

    return (
        <StaticSelect<WeatherSourceType | null>
            activeItem={selectedType || null}
            items={weatherSourceTypesArray}
            onItemSelect={onItemSelect}
            itemRenderer={renderWeatherItem}
            buttonText={selectedType ? WEATHER_SOURCE_TYPES[selectedType] : 'Always take the nearest source'}
            buttonProps={{ disabled, fill: !noFill }}
            popoverProps={{ position: PopoverPosition.BOTTOM_RIGHT }}
            disabled={disabled}
        />
    );
};

export default WeatherSourceTypeSelect;
