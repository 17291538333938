import * as React from 'react';

import { connect } from 'react-redux';
import { IAppState } from 'reports/types';
import { bindActions } from 'reports/utils/redux';
import * as auth from 'reports/modules/auth';

import { RouteComponent } from 'reports/routing';
import * as proj from 'reports/models/project';
import * as scen from 'reports/models/scenario';

import { ContextBarControls } from 'reports/components/ContextBar';
import { NewScenarioButton } from 'reports/components/BreadcrumbPopovers';
import { EditLinkButton } from 'reports/components/core/controls';

import ParameterDisplay from '../components/condition_sets/ParameterDisplay';
import ConditionSetEdit from '../components/condition_sets/ConditionSetEdit';
import ConditionSetsEmptyState from '../components/condition_sets/ConditionSetsEmptyState';

import ProjectAssetPage from './ProjectAssetPage';

interface IPageOwnProps {
    project: proj.Project;
    scenario: scen.Scenario;
}
type IPageProps = IPageOwnProps & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: IAppState) => ({
    user: auth.selectors.getUser(state)!,
});

const mapDispatchToProps = bindActions(({ scenario }: IPageOwnProps) => ({
    updateDescription: (description) => scen.scenarioSaver.get(scenario).patch({ description }),
}));

const _ConditionSetPage: React.FC<IPageProps> = ({ user, project, scenario, updateDescription }) => {
    return (
        <ProjectAssetPage title={scenario.description} updateTitle={updateDescription}>
            <RouteComponent name="app.projects.project.conditions.condition" exact={true}>
                <ContextBarControls>
                    <NewScenarioButton project={project} />
                    <EditLinkButton
                        routeName="app.projects.project.conditions.condition.edit"
                        routeParams={{
                            scenarioId: scenario.scenario_id,
                            projectId: scenario.project_id,
                        }}
                    />
                </ContextBarControls>

                <ParameterDisplay scenario={scenario} user={user} />
            </RouteComponent>

            <RouteComponent name="app.projects.project.conditions.condition.edit">
                <ConditionSetEdit project={scenario.project} scenario={scenario} />
            </RouteComponent>
        </ProjectAssetPage>
    );
};

const ConditionSetPage = connect(mapStateToProps, mapDispatchToProps)(_ConditionSetPage);

interface IOwnProps {
    project: proj.Project;
}

const ConditionSetView: React.FunctionComponent<IOwnProps> = ({ project }) => (
    <>
        <RouteComponent name="app.projects.project.conditions" exact={true}>
            <ConditionSetsEmptyState project={project} />
        </RouteComponent>
        <RouteComponent name="app.projects.project.conditions.condition" exact={false}>
            {({ scenario }) => <ConditionSetPage project={project} scenario={scenario} />}
        </RouteComponent>
    </>
);

export default ConditionSetView;
