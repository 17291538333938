import * as React from 'react';

import {
    Classes,
    Colors,
    Label,
    INumericInputProps,
    NumericInput as BlueprintNumericInput,
    FormGroup,
} from '@blueprintjs/core';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// Form inputs
// TODO: Replace with 'FormField' component above in NewDesign.tsx
export const FormErrorMsg = styled.div.attrs({
    className: Classes.FORM_HELPER_TEXT,
})`
    position: absolute;
    left: 0;
    right: 0;
    margin-left: 5px;
    padding: 5px 0;
    color: ${Colors.RED1};
    font-size: 12px;
    line-height: 1.2;
`;

export const InlineLabel = styled(Label).attrs({
    className: Classes.INLINE,
})`
    &.${Classes.LABEL} {
        position: relative;
        display: flex;
        justify-content: space-between;

        & > span {
            position: relative;
        }
    }
`;

export const InlineFormGroup = styled(FormGroup).attrs({ inline: true })`
    margin: 0;

    .bp3-label {
        font-weight: normal;
    }
`;

export const Input = styled.input.attrs({
    className: Classes.INPUT,
    type: 'text',
})`
    width: 300px;
    height: 34px;
    line-height: 34px;
`;

export const InputContainer = styled.div`
    position: relative;
    padding-bottom: 26px;
`;

interface InputProps {
    value: any;
    onChange: (val) => void;
    className?: string;
    placeholder?: string;
    style?: React.CSSProperties;

    errorMsg?: string | JSX.Element;
    invalid?: boolean;
    renderInputEl?: (props) => JSX.Element;
}

// TODO: this stuff is only used w/ New Design Dialog
// and should be removed once it can be replaced by
// properly mapped Input fields in `reports/components/forms/inputs`
export const InputWithError = (props: InputProps) => {
    const { errorMsg, invalid, onChange, renderInputEl, ...inputProps } = props;
    const error = typeof errorMsg === 'string' ? <FormErrorMsg>{errorMsg}</FormErrorMsg> : errorMsg;
    const inputEl = renderInputEl ? (
        renderInputEl(inputProps)
    ) : (
        <Input onChange={(e) => onChange(e.target.value)} {...inputProps} />
    );

    return (
        <InputContainer>
            {inputEl}
            {errorMsg && invalid ? error : null}
        </InputContainer>
    );
};

// TODO END
/**
 * @deprecated use NumericInput in `reports/components/forms/inputs`
 */
export const NumericInput = (props: INumericInputProps & { style?: React.CSSProperties }) => (
    <BlueprintNumericInput
        allowNumericCharactersOnly={true}
        clampValueOnBlur={true}
        selectAllOnFocus={true}
        selectAllOnIncrement={true}
        {...props}
    />
);

// Autocomplete suggestions
export const Suggestion = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 4px 8px;
    color: #000;
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
    }
    &.generic:hover, // only apply to generic text widget hover
    &.selected {
        background: ${Colors.LIGHT_GRAY4};
    }
    &.disabled,
    &.${Classes.DISABLED} {
        background: ${Colors.LIGHT_GRAY3};
        cursor: default;
        opacity: 0.5;
    }
`;

export const Suggestions = styled.div`
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid ${Colors.LIGHT_GRAY2};
    border-radius: 2px;
    color: ${Colors.DARK_GRAY3};
    z-index: 10; // Override Blueprint input button z-index of 9
`;
