/**
 * Save prompt dialog
 */
import * as React from 'react';
import { Classes, Dialog, IDialogProps, Intent } from '@blueprintjs/core';

import { PrimaryButton, Button as SecondaryButton } from 'reports/components/core/controls';
import DialogActions from 'reports/components/dialog/components/DialogActions';

interface ISaveDialogProps extends IDialogProps {
    cancel: () => void;
    saveChanges: () => void;
    content: JSX.Element | string;
    title: string;
    discardChanges?: () => void;
    primaryButtonText?: string;
    secondaryButtonText?: string;
}

export const SaveDialog: React.SFC<ISaveDialogProps> = ({
    cancel,
    content,
    discardChanges,
    primaryButtonText,
    saveChanges,
    secondaryButtonText,
    style,
    ...dialogProps
}) => (
    <Dialog
        style={{ width: 400, ...style }}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        {...dialogProps}
    >
        <div className={Classes.DIALOG_BODY}>{content}</div>
        <div className={Classes.DIALOG_FOOTER}>
            <DialogActions>
                {discardChanges ? (
                    <SecondaryButton
                        text="Don't Save"
                        className="hs-btn-left-align"
                        intent={Intent.DANGER}
                        onClick={discardChanges}
                    />
                ) : null}
                <SecondaryButton text={secondaryButtonText || 'Cancel'} onClick={cancel} />
                <PrimaryButton text={primaryButtonText || 'Save'} onClick={saveChanges} />
            </DialogActions>
        </div>
    </Dialog>
);

export default SaveDialog;
