import * as React from 'react';

import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { hasDefaultHeader, HEADERS } from 'reports/modules/request';

import { UserToaster } from 'reports/modules/Toaster';

const ADMIN_USER_TOAST_KEY = 'admin-user-toast';

function showAdminUserToast(userEmail?: string, administering?: boolean) {
    UserToaster.show(
        {
            message: userEmail ? (
                <span>
                    {administering ? (
                        <>
                            Administering <b>{userEmail}’s</b> account.
                        </>
                    ) : (
                        <>
                            Impersonating as <b>{userEmail}</b>.
                        </>
                    )}
                </span>
            ) : (
                <span>You are currently overriding permissions.</span>
            ),
            action: {
                onClick: () =>
                    self.document && administering
                        ? self.document.location.replace(self.document.location.pathname)
                        : self.document.location.reload(),
                text: userEmail ? 'Exit' : 'Stop',
            },
            icon: userEmail ? IconNames.PERSON : IconNames.UNLOCK,
            intent: administering ? Intent.WARNING : Intent.DANGER,
            timeout: 0, // disable to keep permanent (unless dismissed)
            className: 'admin-user-toast',
        },
        ADMIN_USER_TOAST_KEY,
    );
}

function hideAdminUserToast() {
    UserToaster.dismiss(ADMIN_USER_TOAST_KEY);
}

/**
 * Router plugin to show appropriate admin user toast when overriding permissions
 * or impersonating another user
 */
export function adminUserPlugin() {
    const pluginName = 'adminUserPlugin';

    const _plugin = (_router, dependencies) => {
        const getState = () => {
            const { store } = dependencies;
            return store.getState();
        };

        return {
            onTransitionSuccess: (_toState, _fromState) => {
                const state = getState();
                const impersonateUser = state.auth.impersonateUser;
                const transitionRoute = state.router.transitionRoute;
                const administeringUser = transitionRoute?.params?.email;

                const hasActiveUserToast = () =>
                    UserToaster.getToasts().findIndex((t) => t.key === ADMIN_USER_TOAST_KEY) > -1;

                if (!hasActiveUserToast()) {
                    if (impersonateUser) {
                        showAdminUserToast(impersonateUser);
                    } else if (hasDefaultHeader(HEADERS.overridePermissions)) {
                        showAdminUserToast();
                    } else if (transitionRoute?.name.startsWith('app.settings') && administeringUser) {
                        showAdminUserToast(administeringUser, true);
                    }
                } else if (
                    !impersonateUser &&
                    !hasDefaultHeader(HEADERS.overridePermissions) &&
                    (!administeringUser || !transitionRoute?.name.startsWith('app.settings'))
                ) {
                    hideAdminUserToast();
                }
            },
        };
    };

    return Object.assign(_plugin, { pluginName });
}
