import * as React from 'react';
import { Link } from 'reports/components/core/controls';
import { FormGroup } from '@blueprintjs/core';
import * as analytics from 'reports/analytics';
import { DetailLine, pluralizeText } from 'reports/modules/settings/common';
import { Subscription } from 'reports/models/subscription';
import * as team from 'reports/models/team';
import { RecurringInterval } from 'reports/models/stripe/price';

export interface Props {
    currentUsageAndLimits: team.ITeamLimitsAndUsage | null | undefined;
    isTeamAdmin: boolean;
    planType: RecurringInterval | undefined;
    showUpsell: boolean;
    subscription: Subscription;
}

export const CurrentUsage = ({ currentUsageAndLimits, isTeamAdmin, planType, showUpsell, subscription }: Props) => {
    const projectCount = currentUsageAndLimits?.subscription_limits?.project_count || 0;
    const projectLimit = currentUsageAndLimits?.subscription_limits?.project_limit || 0;

    if (subscription.is_self_serve && !currentUsageAndLimits?.subscription_limits) {
        return <></>;
    }

    const tracking = {
        name: 'paywall.consumption_enforcement.open',
        options: {
            projectCount,
            projectLimit,
            referrer: 'add project',
            model_type: {
                user_type: isTeamAdmin ? 'admin' : 'non-admin',
                subscription_type: planType ? (planType === 'year' ? 'annual' : 'monthly') : null,
            },
        },
    };

    return (
        <FormGroup label="Projects">
            {subscription.is_contract && (
                <DetailLine>
                    {subscription.project_limit
                        ? `${projectCount} / ${subscription.project_limit} projects used`
                        : 'Not Set'}
                </DetailLine>
            )}
            {subscription.is_self_serve && (
                <DetailLine>{pluralizeText('project', projectCount)} consumed this billing period</DetailLine>
            )}
            {subscription.is_self_serve && showUpsell && (
                <DetailLine>
                    <Link
                        routeName="app.settings.team.billing"
                        searchParams={{ dialog: 'add_projects' }}
                        onClick={() => analytics.track(tracking.name, tracking.options)}
                    >
                        Add Projects
                    </Link>
                </DetailLine>
            )}
        </FormGroup>
    );
};
