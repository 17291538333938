import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { H3 } from '@blueprintjs/core';

import Translations from 'reports/localization/strings';

import { IWidget } from 'reports/modules/report/widgets';

export function widgetNotFound(name: string): IWidget {
    const Component: React.FC<any> = () => (
        <H3>
            <FormattedMessage {...Translations.widgets.not_found} values={{ name }} />
        </H3>
    );

    return {
        Component,
        metadata: {
            category: 'ignore', // do not include in widget controls
            displayName: Translations.widgets.not_found_header,
            dimensions: { w: 0, h: 0 },
        },
        dependencies: [],
    };
}
