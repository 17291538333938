import { noop } from 'lodash';

import * as React from 'react';
import { FormConsumer, FormProvider, IFormContext } from './types';

export interface IFormFieldProps {
    path: string;

    // optional callback for making changes after the user has made a change
    onUpdate?: (val: any, form?: IFormContext<any>) => void;
}

const NestedFields: React.FC<IFormFieldProps> = ({ path, onUpdate = noop, children }) => (
    <FormConsumer>
        {(formContext) => {
            const { formData, fieldErrors, dirtyFields, updateValue, updateValues, ...rest } = formContext;

            return (
                <FormProvider
                    value={{
                        formData: formData[path],
                        fieldErrors: (fieldErrors[path] || {}) as any,
                        dirtyFields: (dirtyFields[path] || {}) as any,
                        updateValue: (propertyPath: string, val: any, makeDirty: boolean = true) => {
                            const fullVal = {
                                ...formData[path],
                                [propertyPath]: val,
                            };
                            // TODO: Handle string and string[] makeDirty
                            updateValue(path, fullVal, makeDirty ? `${path}.${propertyPath}` : false);
                            onUpdate(fullVal, formContext);
                        },
                        updateValues: (partialObject: object, makeDirty: boolean = true) => {
                            const fullVal = {
                                ...formData[path],
                                ...partialObject,
                            };
                            const dirtyPropPaths = Object.keys(partialObject).map(
                                (propertyPath) => `${path}.${propertyPath}`,
                            );
                            updateValue(path, fullVal, makeDirty ? dirtyPropPaths : false);
                            onUpdate(fullVal, formContext);
                        },
                        ...rest,
                    }}
                >
                    {children}
                </FormProvider>
            );
        }}
    </FormConsumer>
);

export default NestedFields;
