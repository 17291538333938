import * as DS from '@aurorasolar/ds';
import React from 'react';

import classNames from 'classnames';

import { Button, Classes, Dialog, Intent, Callout, Card, Spinner } from '@blueprintjs/core';

import { handleRequestException, IFormAndFieldErrors } from 'reports/components/forms/errors';

import { Form } from 'reports/components/forms';
import { FormErrorCallout } from 'reports/components/helpers/errors';
import { SSOProvider } from 'reports/models/sso_provider';

import { styled } from 'reports/styles/styled-components';
import { IntegrationOption } from './ExternalIntegrationsList';
import FormTextInput from '../../../../components/forms/inputs/experimental/FormTextInput';

interface SSOCredentialsDialogProps {
    handleClose: () => void;
    isOpen: boolean;
    options: IntegrationOption;
    onSubmit: (formData: SSOProvider) => void;
    ssoProvider: SSOProvider;
    loadingSSOProvider: boolean;
    onDeleteButtonClicked: () => void;
}

const DialogContent = styled.div`
    margin: 0 0 24px 0;
    & p {
        margin: 0;
    }
`;

const DIALOG_WIDTH = 500;

export const clientSecretPlaceholder = '__SOME_PLACEHOLDER_FOR_CLIENT_SECRET__';

interface CopyCardProps {
    title: string;
    titleIcon: React.ReactNode | null;
    text: string;
    style?: React.CSSProperties;
}

const CopyCard = ({ title, titleIcon = null, text, style = {} }: CopyCardProps) => {
    const onCopyText = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Card style={{ padding: '10px', marginBottom: '15px', ...style }}>
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                {titleIcon}
                <DS.Text fontSize="body14">{title}</DS.Text>
            </div>
            {text && (
                <Callout
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        gap: '8px',
                    }}
                >
                    <DS.Text
                        fontSize="body14"
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text}
                    </DS.Text>
                    <Button minimal={true} style={{ minHeight: '16px', minWidth: '16px', padding: '0' }}>
                        <DS.IconCopy onClick={() => onCopyText(text)} size="16px" />
                    </Button>
                </Callout>
            )}
        </Card>
    );
};

const SSOCredentialsDialog = ({
    handleClose,
    isOpen,
    options,
    onSubmit,
    ssoProvider,
    loadingSSOProvider,
    onDeleteButtonClicked: onDeleteButtonCLicked,
}: SSOCredentialsDialogProps) => {
    const [redirectURL, setRedirectURL] = React.useState<string | null>(null);
    const isValidHttpUrl = (url: string) => {
        let urlObject: URL;
        try {
            urlObject = new URL(url);
        } catch {
            return false;
        }
        return urlObject.protocol === 'http:' || urlObject.protocol === 'https:';
    };

    React.useEffect(() => {
        if (ssoProvider.provider_uuid) {
            setRedirectURL(`${window.location.origin}/api/sso/authorize/${ssoProvider.provider_uuid}`);
        }
    }, [ssoProvider]);

    const onValidate = async (formData: SSOProvider): Promise<IFormAndFieldErrors<SSOProvider> | undefined> => {
        const fieldErrors = {};
        if (!isValidHttpUrl(formData.discovery_endpoint)) {
            fieldErrors['discovery_endpoint'] = ['Invalid URL'];
        }
        if (!formData.client_id) {
            fieldErrors['client_id'] = ['Client ID is required'];
        }
        if (!formData.client_secret) {
            fieldErrors['client_secret'] = ['Client secret is required'];
        }
        return Object.keys(fieldErrors).length ? { fieldErrors, formErrors: [] } : undefined;
    };

    const renderFormFields = (formData, formErrors, updateValue) => {
        return (
            <div className={Classes.DIALOG_BODY}>
                {formErrors.length > 0 && <FormErrorCallout errorMsg={formErrors[0]} style={{ marginBottom: 10 }} />}
                <DialogContent>{options.dialogInstructions}</DialogContent>
                <div style={{ alignSelf: 'stretch' }}>
                    <FormTextInput
                        dataTestId="discovery_endpoint"
                        path="discovery_endpoint"
                        label="SSO Provider Discovery URL"
                        bold
                    />
                </div>
                <div style={{ alignSelf: 'stretch' }}>
                    <FormTextInput dataTestId="client_id" path="client_id" label="Client ID" bold />
                </div>
                <div style={{ alignSelf: 'stretch' }}>
                    <FormTextInput
                        dataTestId="client_secret"
                        onFocus={() => {
                            if (formData.client_secret === clientSecretPlaceholder) updateValue('client_secret', '');
                        }}
                        onBlur={() => {
                            if (ssoProvider.sso_provider_id && !formData.client_secret) {
                                updateValue('client_secret', clientSecretPlaceholder);
                            }
                        }}
                        path="client_secret"
                        label="Client secret"
                        type="password"
                        bold
                    />
                </div>
                {redirectURL && (
                    <CopyCard
                        title="Redirect URL to fill out on your SSO provider."
                        titleIcon={<DS.IconFormattingLink size="16px" />}
                        text={redirectURL}
                    />
                )}
            </div>
        );
    };

    return (
        <Form
            baseValue={{
                sso_provider_id: ssoProvider.sso_provider_id,
                client_id: ssoProvider.client_id || '',
                client_secret: ssoProvider.sso_provider_id ? ssoProvider.client_secret || clientSecretPlaceholder : '',
                discovery_endpoint: ssoProvider.discovery_endpoint || '',
            }}
            onSubmit={onSubmit}
            onValidate={onValidate}
            exceptionHandler={handleRequestException}
        >
            {({ clearForm, dirty, formData, formErrors, submitting, submitForm, updateValue }) => {
                const onClose = () => {
                    clearForm();
                    handleClose();
                };

                return (
                    <Dialog
                        isOpen={isOpen}
                        onClose={onClose}
                        style={{ width: DIALOG_WIDTH }}
                        title={options.dialogTitle}
                    >
                        {loadingSSOProvider ? (
                            <div className={Classes.DIALOG_BODY}>
                                <Spinner />
                            </div>
                        ) : (
                            renderFormFields(formData, formErrors, updateValue)
                        )}

                        <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                            <Button
                                text="Remove SSO"
                                onClick={() => onDeleteButtonCLicked()}
                                data-testid="remove-sso-button"
                                disabled={!ssoProvider.sso_provider_id}
                            />
                            <Button
                                intent={Intent.NONE}
                                onClick={onClose}
                                text="Close"
                                data-testid="bottom-close-button"
                            />
                            <Button
                                intent={Intent.PRIMARY}
                                onClick={() => submitForm()}
                                text="Save"
                                loading={submitting}
                                disabled={
                                    submitting ||
                                    !dirty ||
                                    !formData.discovery_endpoint ||
                                    !formData.client_id ||
                                    !formData.client_secret
                                }
                                data-testid="bottom-save-button"
                            />
                        </div>
                    </Dialog>
                );
            }}
        </Form>
    );
};

export default SSOCredentialsDialog;
