import * as React from 'react';
import { connect } from 'react-redux';
import { selectors as userSelectors } from 'reports/modules/auth';

import { Card, Section2 } from 'reports/components/core/containers';
import { NestedFields } from 'reports/components/forms';

import FormProfileSelect from 'reports/modules/profile/components/FormProfileSelect';

type IStateProps = ReturnType<typeof mapStateToProps>;

const ProjectProfileEditor: React.FC<IStateProps> = ({ user }) => {
    const canViewFinancials = user.hasFinancialsAccess();
    return (
        <NestedFields path="data">
            <Card>
                <Section2 title="Profile Selection">
                    <FormProfileSelect
                        label={'Mechanical Profile'}
                        path={'mechanical_profile_id'}
                        type={'mechanical'}
                        helperText="Mechanical profiles define the module layout and racking assumptions used when creating new Field Segments for a given project."
                        isNullable={true}
                    />
                    <FormProfileSelect
                        label={'Electrical Profile'}
                        path={'electrical_profile_id'}
                        type={'electrical'}
                        helperText="Electrical profiles define the inverter, wiring, combiner boxes and stringing used when creating new Wiring Zones."
                        isNullable={true}
                    />
                    <FormProfileSelect
                        label={'Conditions Profile'}
                        path={'scenario_profile_id'}
                        type={'scenario'}
                        helperText="Condition profiles define the energy calculations to be used by default when creating a Condition Set to run energy simulations."
                        isNullable={true}
                    />
                    {canViewFinancials && (
                        <FormProfileSelect
                            label={'Financial Profile'}
                            path={'financial_profile_id'}
                            type={'financial'}
                            helperText="Financial profiles define the financial model and set of incentives to be used by default when calculating a project's financial metrics."
                            isNullable={true}
                        />
                    )}
                </Section2>
            </Card>
        </NestedFields>
    );
};

const mapStateToProps = (state) => ({
    user: userSelectors.getUser(state)!,
});

export default connect(mapStateToProps, null)(ProjectProfileEditor);
