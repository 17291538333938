import * as React from 'react';
import { connect } from 'react-redux';
import { IAppState } from 'reports/store';

import moment from 'moment';

import { AlignmentIcons, ProfilesParameter, Section2 } from 'reports/modules/profile/components/common';
import { moduleOrientation, rackingType } from 'reports/utils/formatters';

import * as pro from 'reports/models/profile';
import * as auth from 'reports/modules/auth';

interface IOwnProps {
    profile: pro.MechanicalProfile;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IOwnProps & IStateProps;

interface IStrategyParamProps {
    strategyName: string;
    paramLabel?: string;
    paramValue?: string | number;
    displayInfo?: React.ReactElement;
}

const StrategyParameter: React.FC<IStrategyParamProps> = ({ strategyName, paramLabel, paramValue, displayInfo }) => {
    return (
        <>
            <ProfilesParameter label="Strategy">{strategyName}</ProfilesParameter>
            {paramLabel && paramValue && <ProfilesParameter label={paramLabel}>{paramValue}</ProfilesParameter>}
            {displayInfo}
        </>
    );
};

const TiltSelectionContents: React.FC<IOwnProps> = ({ profile }) => {
    switch (profile.data.tilt_strategy) {
        case 'fixed': {
            return (
                <StrategyParameter
                    strategyName={pro.TiltStrategyTypes['fixed']}
                    paramLabel="Tilt"
                    paramValue={`${profile.data.tilt}°`}
                />
            );
        }
        case 'latitude': {
            return (
                <StrategyParameter
                    strategyName={pro.TiltStrategyTypes['latitude']}
                    displayInfo={
                        <div style={{ marginTop: 5 }}>
                            <b>Tilt will be set to Project Latitude</b>
                        </div>
                    }
                />
            );
        }
        case 'simple_optimization': {
            const displayInfo = (
                <div style={{ marginTop: 5 }}>
                    <b>Tilt will be Optimized for Project Latitude</b>
                    <ul>
                        <li>Latitude is below 25&deg;: lat &times; 0.87</li>
                        <li>Latitude is between 25&deg; and 50&deg;: lat &times; 0.76 + 3.1&deg;</li>
                        <li>Latitude is above 50&deg;: 45&deg;</li>
                    </ul>
                </div>
            );
            return (
                <StrategyParameter
                    strategyName={pro.TiltStrategyTypes['simple_optimization']}
                    displayInfo={displayInfo}
                />
            );
        }
    }
    throw Error('Unknown tilt strategy.');
};

const RowSpacingContents: React.FC<IOwnProps & IStateProps> = ({ profile, formatters }) => {
    switch (profile.data.row_spacing_strategy) {
        case 'fixed': {
            return (
                <StrategyParameter
                    strategyName={pro.RowSpacingTypes['fixed']}
                    paramLabel="Spacing"
                    paramValue={formatters.distance(profile.data.row_spacing, {
                        precision: 3,
                    })}
                />
            );
        }
        case 'span_to_rise': {
            return (
                <StrategyParameter
                    strategyName={pro.RowSpacingTypes['span_to_rise']}
                    paramLabel="Span to rise"
                    paramValue={profile.data.span_to_rise}
                />
            );
        }
        case 'gcr': {
            return (
                <StrategyParameter
                    strategyName={pro.RowSpacingTypes['gcr']}
                    paramLabel="GCR"
                    paramValue={profile.data.gcr}
                />
            );
        }
        case 'shading': {
            const date = moment.utc(profile.data.row_spacing_start_time).format('M/D/YYYY');
            const startTime = moment.utc(profile.data.row_spacing_start_time).format('H:mm:ss');
            const endTime = moment.utc(profile.data.row_spacing_end_time).format('H:mm:ss');
            const paramValue = `${date}: ${startTime} - ${endTime} (UTC)`;
            return (
                <StrategyParameter
                    strategyName={pro.RowSpacingTypes['shading']}
                    paramLabel="Date"
                    paramValue={paramValue}
                />
            );
        }
    }
    throw Error('Unknown row spacing strategy.');
};

class MechanicalPreviewContents extends React.PureComponent<IProps> {
    render() {
        const { profile, formatters } = this.props;
        const { module } = profile;
        return (
            <>
                <Section2 title="Module Selection">
                    <ProfilesParameter label="Module">
                        {module ? `${module.manufacturer}, ${module.name}` : 'No module selected'}
                    </ProfilesParameter>
                </Section2>
                <Section2 title="Racking Selection" subtitle="The core racking type and layout">
                    <ProfilesParameter label="Racking">{rackingType(profile.data.rack_type)}</ProfilesParameter>
                    <ProfilesParameter label="Orientation">
                        {moduleOrientation(profile.data.orientation)}
                    </ProfilesParameter>
                    <ProfilesParameter label="Frame Size">
                        {`${profile.data.bank_depth} up `}&times;
                        {` ${profile.data.bank_width} wide`}
                    </ProfilesParameter>
                    <ProfilesParameter label="Alignment">
                        <>
                            {AlignmentIcons[profile.data.alignment]}
                            <span style={{ marginLeft: 5 }}>{pro.AlignmentTypes[profile.data.alignment]}</span>
                        </>
                    </ProfilesParameter>
                </Section2>
                <Section2 title="Tilt Selection" subtitle="How your starting tilt will be selected">
                    <TiltSelectionContents profile={profile} />
                </Section2>
                <Section2 title="Row Spacing" subtitle="How your starting row spacing will be selected">
                    <RowSpacingContents profile={profile} formatters={formatters} />
                </Section2>
                <Section2 title="Other Layout Rules" subtitle="How your field segments will be laid out initially">
                    <ProfilesParameter label="Setback">
                        {formatters.distance(profile.data.inner_setback, {
                            precision: 3,
                        })}
                    </ProfilesParameter>
                    <ProfilesParameter label="Module Spacing">
                        {formatters.distance(profile.data.module_spacing, {
                            precision: 3,
                        })}
                    </ProfilesParameter>
                    <ProfilesParameter label="Frame Spacing">
                        {formatters.distance(profile.data.frame_spacing, {
                            precision: 3,
                        })}
                    </ProfilesParameter>
                </Section2>
            </>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    formatters: auth.selectors.formatters(state),
});

export default connect(mapStateToProps)(MechanicalPreviewContents);
