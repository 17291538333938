/* tslint:disable:variable-name function-name */

import { BaseClass } from 'reports/utils/api';
import { schema } from 'reports/models/schema';
import * as pd from 'reports/models/power_device';
import type { Scenario } from './Scenario';

class ScenarioPowerDeviceCharacterization extends BaseClass {
    scenario_id: number;
    scenario: Scenario;

    // the power_device used in the design
    power_device_id: number;
    power_device: pd.PowerDevice;

    // the characterization used to model it
    power_device_characterization_id: number;
    power_device_characterization: pd.PowerDeviceCharacterization;
}

const schemaObj = schema.addObject(ScenarioPowerDeviceCharacterization, 'scenario_power_device_characterization', {
    compositeKeyFn: (obj) => obj.scenario_id + ',' + obj.power_device_id,

    relationships: {
        power_device: { schema: pd.schemaObj },
        power_device_characterization: { schema: pd.charSchemaObj },
    },
    defaultDeep: { power_device: '*', power_device_characterization: '*' },
});

export default ScenarioPowerDeviceCharacterization;

export { ScenarioPowerDeviceCharacterization, schemaObj };
