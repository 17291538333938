import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { fromNow } from 'reports/utils/formatters';

import { Flex } from 'reports/components/core/containers';
import Section from 'reports/components/core/containers/Section2';
import { FavoriteStar, PublishButton } from 'reports/components/core/controls';
import { LibraryPreview } from 'reports/components/core/layout';
import { PreviewDrawer } from 'reports/components/library/main/components';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import * as auth from 'reports/modules/auth';
import * as mod from 'reports/models/module';
import { canMakeResourcePublic } from 'reports/modules/auth/permissions';

import { CharacterizationSpecs } from './CharacterizationSpecs';
import { ModuleCharViewControls as CharacterizationViewControls } from './CharacterizationViewControls';
import ModeledPerformance from './ModeledPerformance';
import { ModuleSpecs } from './ModuleSpecs';
import { RawParameters } from './RawParameters';

const RightPanel = styled(Flex.ContainerV)`
    flex: 1;
    margin-left: 20px;
`;

type Props = {
    module: mod.Module;
    characterization: mod.ModuleCharacterization;
    navigateToCharacterization: (moduleId: number, characterizationId: number) => void;
    navigateToModules: () => void;
};

const ModulePreviewContents = ({ module, characterization }) => (
    <Flex.Container>
        <Flex.ContainerV>
            <Section title="Module Model">
                <ModuleSpecs module={module} />
            </Section>
            <Section title="Module Characterization">
                <CharacterizationSpecs characterization={characterization} />
            </Section>
        </Flex.ContainerV>
        <RightPanel>
            <Section title="Modeled Performance">
                <ModeledPerformance characterization={characterization} />
            </Section>
            <Section title="Raw Parameters">
                <RawParameters characterization={characterization} />
            </Section>
        </RightPanel>
    </Flex.Container>
);

const ModulePreview = React.memo(
    ({ module, characterization, navigateToCharacterization, navigateToModules }: Props) => {
        const user = useSelector((state) => auth.selectors.getUser(state)!);
        const dispatch = useDispatch();
        const { module_id, favorite, last_update, public: isPublic } = module;
        const toggleFavorite = () => {
            return dispatch(mod.api.save({ module_id, favorite: !favorite }));
        };
        const togglePublic = () => {
            return dispatch(mod.api.save({ module_id, public: !isPublic }));
        };
        const [drawerOpen, setDrawerOpen] = React.useState<boolean>(true);

        return (
            <PreviewDrawer
                isOpen={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onClosed={navigateToModules}
                title={
                    <LibraryPreview.Header
                        title={module.name}
                        subtitle={last_update && `Last modified ${fromNow(last_update)}`}
                        favorite={
                            <FavoriteStar
                                empty={!favorite}
                                onClick={() => {
                                    addPromiseToastsToggle(
                                        'favorite',
                                        toggleFavorite(),
                                        'module',
                                        module.name,
                                        favorite,
                                    );
                                }}
                            />
                        }
                    />
                }
                publishButton={
                    <PublishButton
                        isPublic={module.public}
                        resourceName={module.name}
                        resourceType={'module'}
                        privatePrompt={
                            <p>
                                Making this module private will also revoke access to this resource for any HelioScope
                                users that are not on the Folsom Labs team and are currently using this module in their
                                designs.
                            </p>
                        }
                        publicPrompt={
                            <p>
                                Making this module public will give all HelioScope users access to this module. Everyone
                                will be able to find and use this module in their designs, but not make any changes to
                                the resource itself.
                            </p>
                        }
                        togglePublic={togglePublic}
                        disabled={!canMakeResourcePublic(user, module)}
                    />
                }
                widthInPercent={80}
                viewControls={
                    <CharacterizationViewControls
                        module={module}
                        selectedChar={characterization}
                        navigateToCharacterization={navigateToCharacterization}
                    />
                }
            >
                <div style={{ padding: 10 }}>
                    <ModulePreviewContents module={module} characterization={characterization} />
                </div>
            </PreviewDrawer>
        );
    },
);

export { ModulePreview, ModulePreviewContents };
