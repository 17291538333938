import { fill, includes, range, remove } from 'lodash';

import * as React from 'react';

import { Button, Checkbox } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { DayOfWeek, MONTHS, SHORT_DAYS } from 'reports/utils/time';
import { padZeroes } from 'helioscope/app/utilities/helpers';

import { DeleteButton } from 'reports/components/core/controls';
import { Flex } from 'reports/components/core/containers';

import * as ur from 'reports/models/utility_rate';

import { IParameterDefinition, IParamProps, ParamValueType } from 'reports/modules/financials/params';
import { IGridConfig, ParamGrid } from './ParamGrid';
import { getRateColor, RateElement, RateSection } from './ParamRatesFull';

const hourLabels = range(24).map((hour) => `${padZeroes(hour.toString(), 2)}`);

const getDayTableParamConfig = (config): IGridConfig => ({
    type: ParamValueType.Grid,
    cell_type: 'integer',
    cell_width: 40,
    cell_height: 24,
    min_value: 1,
    columns: hourLabels,
    rows: MONTHS,
    ...config,
});

interface ITimeOfUseConfig extends IParameterDefinition<ur.IScheduleAssignment[]> {
    default: ur.IScheduleAssignment[];
}

// IParamProps parameter (IGridConfig) is self contained here instead of passed in as a prop
interface IParamTimeOfUseTables extends Omit<IParamProps<ITimeOfUseConfig>, 'parameter'> {
    maxValue: number;
    onUpdate: (propPath: string, value: any) => void;
}

interface IState {
    selectedTableIdx: number;
}

class ParamTimeOfUseTables extends React.PureComponent<IParamTimeOfUseTables, IState> {
    state: IState = { selectedTableIdx: 0 };

    render() {
        const { value, disabled } = this.props;
        const { selectedTableIdx } = this.state;

        const maxTablesReached = value.length >= 8;

        const currTable = this.getCurrTable();
        const noDel = currTable.days === null;
        const daySelector = currTable.days === null ? <div>Default schedule</div> : this.renderDaySelector();

        return (
            <div>
                <RateSection>
                    {value.map((table, idx) => (
                        <Button
                            key={idx}
                            text={this.getTabText(table.days)}
                            disabled={disabled}
                            style={{ margin: 2, width: 175 }}
                            active={idx === selectedTableIdx}
                            onClick={() => this.setState({ selectedTableIdx: idx })}
                        />
                    ))}
                    <Button
                        icon={IconNames.ADD}
                        disabled={maxTablesReached || disabled}
                        style={{ margin: 2 }}
                        onClick={this.addTable}
                    />
                </RateSection>
                <RateElement>
                    <Flex.Container className="center" style={{ paddingBottom: 6 }}>
                        <div>
                            <DeleteButton disabled={noDel || disabled} onClick={this.deleteTable} />
                        </div>
                        <div style={{ padding: '0px 4px' }}>{daySelector}</div>
                    </Flex.Container>
                    <ParamGrid
                        parameter={getDayTableParamConfig({
                            max_value: this.props.maxValue,
                        })}
                        value={currTable.table}
                        updateFn={this.updateTable}
                        cellStyler={(_1, _2, v) => ({
                            backgroundColor: getRateColor(v - 1),
                        })}
                        disabled={disabled}
                    />
                </RateElement>
            </div>
        );
    }

    renderDaySelector() {
        const days = this.getCurrTable().days;
        const blocked = fill(range(7), false);

        for (const table of this.props.value) {
            if (table.days === days) continue;
            for (const day of table.days || []) {
                blocked[day] = true;
            }
        }

        const elements = SHORT_DAYS.map((day, idx) => {
            const checked = includes(days, idx);
            return (
                <div key={idx} style={{ padding: '0px 6px' }}>
                    <Checkbox
                        label={day}
                        disabled={blocked[idx] || this.props.disabled}
                        checked={checked}
                        onChange={() => this.toggleDay(idx as DayOfWeek)}
                        style={{ marginBottom: 0 }}
                    />
                </div>
            );
        });

        return (
            <Flex.Container className="center">
                <div style={{ paddingLeft: 6 }}>Days</div>
                <Flex.Container style={{ padding: 4 }}>{elements}</Flex.Container>
            </Flex.Container>
        );
    }

    addTable = () => {
        const tables = this.props.value.slice();

        tables.push({
            days: [],
            table: fill(range(12 * 24), 1),
        });

        this.setState({ selectedTableIdx: tables.length - 1 });
        this.props.onUpdate('tables', tables);
    };

    deleteTable = () => {
        const { selectedTableIdx } = this.state;
        const tables = this.props.value.slice();

        tables.splice(selectedTableIdx, 1);
        this.setState({ selectedTableIdx: Math.max(selectedTableIdx - 1, 0) });

        this.props.onUpdate('tables', tables);
    };

    getCurrTable = () => {
        const { value } = this.props;
        const { selectedTableIdx } = this.state;
        if (!value[selectedTableIdx]) {
            // this can happen if this user clicks Save->Cancel which reverses all changes
            this.setState({ selectedTableIdx: 0 });
            return value[0];
        }
        return value[selectedTableIdx];
    };

    getTabText = (days: DayOfWeek[] | null) => {
        if (!days) return 'Default';
        if (!days.length) return 'No days selected';
        if (days.length === 7) return 'All';
        return days
            .sort()
            .map((i) => SHORT_DAYS[i])
            .join(',');
    };

    toggleDay = (day: DayOfWeek) => {
        const table = this.getCurrTable();

        if (!table.days) return;

        const days = table.days.slice();
        if (includes(days, day)) {
            remove(days, (d) => d === day);
        } else {
            days.push(day);
        }

        this.props.onUpdate(`tables[${this.state.selectedTableIdx}].days`, days);
    };

    updateTable = (table: number[]) => this.props.onUpdate(`tables[${this.state.selectedTableIdx}].table`, table);
}

export default ParamTimeOfUseTables;
