import * as React from 'react';

import classNames from 'classnames';
import { Callout, Classes, Dialog, Code, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button, PrimaryButton } from 'reports/components/core/controls';
import { getClassicUrl } from 'reports/utils/url';

const ProjectShareButton = ({ hash }: { hash: string }) => {
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const handleOpen = () => setDialogOpen(true);
    const handleClose = () => setDialogOpen(false);
    const shareLink = `${getClassicUrl('')}projects/share/${hash}`;

    return (
        <>
            <PrimaryButton icon={IconNames.SHARE} onClick={handleOpen} style={{ marginLeft: '5px' }}>
                Share Link
            </PrimaryButton>
            <Dialog
                title="Share Your Project"
                icon={IconNames.SHARE}
                isOpen={dialogOpen}
                isCloseButtonShown={true}
                onClose={handleClose}
                style={{ width: '600px' }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <p>
                        To share your project simply copy and paste this link and send it to any other user of
                        HelioScope:
                    </p>
                    <div style={{ textAlign: 'center' }}>
                        <Code>
                            <a href={shareLink} onClick={(evt) => evt.preventDefault()}>
                                {shareLink}
                            </a>
                        </Code>
                    </div>
                    <br />
                    <Callout intent={Intent.WARNING} title="Warning!">
                        Anyone with this URL (and a HelioScope account) will be able to see all resources associated
                        with this project. Only the project administrator can add or remove users.
                    </Callout>
                </div>
                <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                    <Button text="Close" onClick={handleClose} />
                </div>
            </Dialog>
        </>
    );
};

export default ProjectShareButton;
