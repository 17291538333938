import { RadioGroup as BpRadioGroup } from '@blueprintjs/core';
import * as React from 'react';
import styled from 'styled-components';

const StyledBpGroup = styled(BpRadioGroup)`
    white-space: nowrap;

    .bp3-radio {
        font-weight: normal;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const RadioGroup = ({ onChange, ...props }) => {
    return <StyledBpGroup onChange={(e) => onChange(e.currentTarget.value)} {...props} />;
};
export default RadioGroup;
