import moment from 'moment-timezone';

const MONTHS_PER_YEAR = 12;

class GenericSeries {
    verifySeries(series) {
        if (typeof this !== typeof series || this.length !== series.length) {
            throw new Error('series mismatch');
        }
    }

    addSeries(series) {
        this.verifySeries(series);

        for (let i = 0; i < this.length; ++i) {
            this.values[i] += series.getRaw(i);
        }

        return this;
    }

    subSeries(series) {
        this.verifySeries(series);

        for (let i = 0; i < this.length; ++i) {
            this.values[i] -= series.getRaw(i);
        }

        return this;
    }

    maxSeries(series) {
        this.verifySeries(series);

        for (let i = 0; i < this.length; ++i) {
            this.values[i] = Math.max(this.values[i], series.getRaw(i));
        }

        return this;
    }

    multiplySeries(series) {
        this.verifySeries(series);

        for (let i = 0; i < this.length; ++i) {
            this.values[i] *= series.getRaw(i);
        }

        return this;
    }

    multiplyScalar(scalar) {
        for (let i = 0; i < this.length; ++i) {
            this.values[i] *= scalar;
        }

        return this;
    }

    get length() {
        return this.values.length;
    }

    push(value) {
        this.values.push(value);
    }

    setValues(values) {
        this.values = values;
    }

    getRaw(idx) {
        return this.values[idx];
    }

    getRawSafe(idx) {
        if (idx < 0 || idx >= this.values.length) throw new Error();
        return this.values[idx];
    }

    setRaw(idx, value) {
        this.values[idx] = value;
    }

    setRawSafe(idx, value) {
        if (idx >= 0 && idx < this.values.length) this.values[idx] = value;
    }

    seriesRaw() {
        return this.values;
    }
}

export class OutputSeries extends GenericSeries {
    constructor(values) {
        super();

        this.values = values;
    }
}

export class TimeSeries {
    static zipSeries(spec) {
        if (!_.isObject(spec)) throw new Error('expected object');

        const zipped = _.first(Object.values(spec)).map(() => ({}));

        for (const k of Object.keys(spec)) {
            const series = spec[k];
            zipped.verifySeries(series);

            for (let i = 0; i < series.length; ++i) {
                zipped.getRaw(i)[k] = series.getRaw(i);
            }
        }

        return zipped;
    }
}

export const TIME_INTERVALS = {
    QUARTER_HOUR: Symbol('QUARTER_HOUR'),
    HOUR: Symbol('HOUR'),
    DAY: Symbol('DAY'),
    MONTH: Symbol('MONTH'),
    YEAR: Symbol('YEAR'),
};

class TimeSeriesBase extends GenericSeries {
    get intervalType() {
        throw new Error('not implemented');
    }
}

export class AbsoluteTimeSeriesBase extends TimeSeriesBase {
    static getConstructor(intervalType) {
        if (intervalType === TIME_INTERVALS.HOUR) {
            return AbsoluteTimeSeriesHour;
        } else if (intervalType === TIME_INTERVALS.MONTH) {
            return AbsoluteTimeSeriesMonth;
        } else if (intervalType === TIME_INTERVALS.YEAR) {
            return AbsoluteTimeSeriesYear;
        }

        throw new Error();
    }

    constructor(startTime, timeZoneId, count = 0, value = null, raw = null) {
        super();

        if (!startTime || !timeZoneId || !(typeof timeZoneId === 'string')) {
            throw new Error('must have valid start time');
        }

        this.startTime = startTime;
        this.timeZoneId = timeZoneId;
        this._startMoment = moment.tz(this.startTime, this.timeZoneId);

        if (raw) {
            this.values = raw.slice();
        } else {
            this.values = _.range(0, count).map(() => value);
        }
    }

    get startMoment() {
        return this._startMoment.clone();
    }

    clone() {
        const Ctor = this.constructor;
        return new Ctor(this.startTime, this.timeZoneId, null, null, this.values);
    }

    subSeriesByTime(startTime, endTime) {
        // returns a series of entries indexed by startTime up to endTime (not inclusive)
        const startIdx = this.indexByTime(startTime);
        const endIdx = this.indexByTime(endTime);

        const slice = this.values.slice(startIdx, endIdx);

        const Ctor = this.constructor;
        return new Ctor(startTime, this.timeZoneId, null, null, slice);
    }

    map(fn) {
        const cpy = this.clone();
        cpy.transform(fn);
        return cpy;
    }

    transform(fn) {
        const values = [];

        this.iterate((value, start, end, idx) => {
            values.push(fn(value, start, end, idx));
        });

        this.values = values;
    }

    verifySeries(series) {
        if (
            typeof this !== typeof series ||
            this.length !== series.length ||
            this.intervalType !== series.intervalType
        ) {
            throw new Error('series mismatch');
        }
    }

    changeInterval(intervalType, upFn, downFn) {
        if (this.intervalType === intervalType) return this.clone();

        const Ctor = AbsoluteTimeSeriesBase.getConstructor(intervalType);
        const aggregate = new Ctor(this.startTime, this.timeZoneId);

        if (this.intervalIdeal < aggregate.intervalIdeal) {
            // up to longer inteval
            aggregate.resetEndTime(this.endTime);
            aggregate.transform((value, start, end) => {
                const sub = this.subSeriesByTime(start, end);
                return upFn(sub);
            });
        } else {
            // down to shorter interval
            aggregate.resetEndTime(this.endTime);
            this.iterate((value, start, end) => {
                const startIdx = aggregate.indexByTime(start);
                const endIdx = aggregate.indexByTime(end);
                const count = endIdx - startIdx;
                const dnvalue = downFn(value, count);
                for (let i = startIdx; i < endIdx; ++i) {
                    aggregate.setRaw(i, dnvalue);
                }
            });
        }

        return aggregate;
    }

    reaggregateInterval(intervalType) {
        const upFn = (subseries) => {
            let sum = 0;
            subseries.iterate((i) => {
                sum += i;
            });

            return sum;
        };

        const dnFn = (value, count) => value / count;

        return this.changeInterval(intervalType, upFn, dnFn);
    }

    resampleInterval(intervalType) {
        const upFn = (subseries) => {
            let sum = 0;
            let weight = 0.0;
            subseries.iterate((i) => {
                sum += i;
                weight += 1.0;
            });

            return sum / weight;
        };

        const dnFn = (value, _count) => value;

        return this.changeInterval(intervalType, upFn, dnFn);
    }
}

class AbsoluteTimeSeriesRegularBase extends AbsoluteTimeSeriesBase {
    get endTime() {
        // end time (not inclusive)
        return this.startTime + this.values.length * this.intervalIdeal;
    }

    indexByTime(time) {
        const idx = Math.floor((time - this.startTime) / this.intervalIdeal);
        if (idx < 0) return null;
        return idx;
    }

    resetEndTime(time, value = null) {
        // set the end time (not inclusive)
        const count = Math.floor((time - this.startTime) / this.intervalIdeal);

        this.values = [];
        for (let i = 0; i < count; ++i) {
            this.values.push(value);
        }
    }

    iterate(fn) {
        let curr = this.startTime;
        const step = this.intervalIdeal;
        const length = this.values.length;

        for (let idx = 0; idx < length; ++idx) {
            fn(this.values[idx], curr, curr + step, idx);
            curr += step;
        }
    }
}

class AbsoluteTimeSeriesIrregularBase extends AbsoluteTimeSeriesBase {
    get endTime() {
        // end time (not inclusive)
        return this.timeByIndex(this.values.length);
    }

    resetEndTime(time, value = null) {
        // set the end time (not inclusive)
        this.values = [];
        while (this.endTime < time) {
            this.values.push(value);
        }
    }

    iterate(fn) {
        const interval = this._momentInterval;

        const curr = this._startMoment.clone();
        let prevt = curr.valueOf();
        let nextt = 0;

        let idx = 0;
        for (const i of this.values) {
            curr.add(1, interval);
            nextt = curr.valueOf();
            fn(i, prevt, nextt, idx);
            prevt = nextt;
            idx += 1;
        }
    }

    timeByIndex(idx) {
        const interval = this._momentInterval;
        const curr = this._startMoment.clone();
        return curr.add(idx, interval).valueOf();
    }
}

export class AbsoluteTimeSeriesHour extends AbsoluteTimeSeriesRegularBase {
    get intervalIdeal() {
        // valid only for comparison
        return 60 * 60 * 1000;
    }

    get intervalType() {
        return TIME_INTERVALS.HOUR;
    }
}

export class AbsoluteTimeSeriesMonth extends AbsoluteTimeSeriesIrregularBase {
    get _momentInterval() {
        return 'months';
    }

    get intervalIdeal() {
        // valid only for comparison
        return 30 * 24 * 60 * 60 * 1000;
    }

    get intervalType() {
        return TIME_INTERVALS.MONTH;
    }

    indexByTime(time) {
        const start = this._startMoment;
        const curr = moment.tz(time, this.timeZoneId);

        const diffYear = curr.year() - start.year();
        const diffMonth = curr.month() - start.month();
        return diffYear * 12 + diffMonth;
    }

    toOutputSeries(initial = 0.0) {
        const values = [initial].concat(this.values);
        return new OutputSeries(values);
    }
}

export class AbsoluteTimeSeriesYear extends AbsoluteTimeSeriesIrregularBase {
    get _momentInterval() {
        return 'years';
    }

    get intervalIdeal() {
        // valid only for comparison
        return 365 * 24 * 60 * 60 * 1000;
    }

    get intervalType() {
        return TIME_INTERVALS.YEAR;
    }

    indexByTime(time) {
        const start = this._startMoment;
        const curr = moment.tz(time, this._startMoment);

        const diffYear = curr.year() - start.year();
        return diffYear;
    }

    toOutputSeries(initial = 0.0) {
        const values = [initial].concat(this.values);
        return new OutputSeries(values);
    }
}

class DurationTimeSeries extends TimeSeriesBase {
    constructor(count = 0, value = null, raw = []) {
        super();

        if (raw) {
            this.values = raw.slice();
        } else {
            this.values = _.range(0, count).map(() => value);
        }
    }

    clone() {
        const Ctor = this.constructor;
        return new Ctor(null, null, this.values);
    }
}

export class DurationTimeSeriesHour extends DurationTimeSeries {
    get intervalIdeal() {
        return 60 * 60 * 1000;
    }

    get intervalType() {
        return TIME_INTERVALS.HOUR;
    }

    fixStartMonth(sourceTimeZoneId, targetTimeZoneId, year, month, repeat = 1) {
        if (this.values.length * this.intervalIdeal !== 365 * 24 * 60 * 60 * 1000) {
            throw new Error('expected 365 day time series');
        }

        const baseTimeSrc = moment.tz({ year: 2020, month: 0, date: 1 }, sourceTimeZoneId);
        const baseTimeTgt = moment.tz({ year: 2020, month: 0, date: 1 }, targetTimeZoneId);
        const diff = (baseTimeTgt.valueOf() - baseTimeSrc.valueOf()) / this.intervalIdeal;

        const baseYear = this.values.slice();

        for (let i = 0; i < this.values.length; ++i) {
            baseYear[(i + diff) % this.values.length] = this.values[i];
        }

        const leapTimeTgt = moment.tz({ year: 2020, month: 1, date: 29 }, targetTimeZoneId);
        const leapDayIndex = (leapTimeTgt.valueOf() - baseTimeTgt.valueOf()) / this.intervalIdeal;
        const leapDayIntervals = (24 * 60 * 60 * 1000) / this.intervalIdeal;

        const initRaw = moment.tz({ year, month: 0, date: 1 }, targetTimeZoneId);
        const initSeries = moment.tz({ year, month, date: 1 }, targetTimeZoneId);
        const endSeries = moment.tz({ year: year + repeat, month, date: 1 }, targetTimeZoneId);

        const raw = [];

        for (let i = 0; i < repeat + 1; ++i) {
            const curr = initRaw.clone().add(i, 'years');
            const leap = curr.isLeapYear();

            if (leap) {
                raw.push(
                    // days before Feb 29
                    ...baseYear.slice(0, leapDayIndex),

                    // duplicate Feb 29,
                    ...baseYear.slice(leapDayIndex - leapDayIntervals, leapDayIndex),

                    // add rest of year
                    ...baseYear.slice(leapDayIndex, baseYear.length),
                );
            } else {
                raw.push(...baseYear);
            }
        }

        const i0 = (initSeries.valueOf() - initRaw.valueOf()) / this.intervalIdeal;
        const i1 = (endSeries.valueOf() - initRaw.valueOf()) / this.intervalIdeal;

        const series = new AbsoluteTimeSeriesHour(initSeries.valueOf(), targetTimeZoneId);
        series.setValues(raw.slice(i0, i1));

        return series;
    }
}

export class DurationTimeSeriesMonth extends DurationTimeSeries {
    get intervalIdeal() {
        return 30 * 24 * 60 * 60 * 1000;
    }

    get intervalType() {
        return TIME_INTERVALS.MONTH;
    }

    fixStartMonth() {
        throw new Error('not implemented');
    }
}
/**
 * Calls `mapFn` with each monthly value and a flag delimiting the last month of the annual
 * true-up period. Monthly data is not assumed to start at the beginning of the calendar year,
 * which means that the true-up period may be offset from the calendar year (e.g. May 2020 to Apr 2021)
 */
export function mapAnnualTrueUp(monthlyData, mapFn) {
    return monthlyData.map((value, _start, _end, idx) => {
        const isLastMonthOfTrueUp = idx % MONTHS_PER_YEAR === MONTHS_PER_YEAR - 1;
        return mapFn(value, isLastMonthOfTrueUp);
    });
}
