/* tslint:disable:variable-name */

import moment from 'moment';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { schema } from 'reports/models/schema';

import { FullDiodeModule, PVSystDiodeModule } from './DiodeModule';
import type { Module } from './Module';
import * as tm from '../team';

class ModuleCharacterization extends BaseClass {
    module_characterization_id: number;

    module_model_name: ModuleModelType;

    module_id: number;
    module: Module;

    team_id: number;
    team: tm.Team;

    name: string;
    description: string;
    source?: string;
    methodology?: string;
    last_update?: moment.Moment;

    power: number;
    v_oc: number;
    v_mp: number;
    i_sc: number;
    i_mp: number;
    length: number;
    width: number;

    temp_power: number;
    temp_voltage: number;
    temp_current: number;

    g_ref: number;
    t_ref: number;

    // ---- fields for full-diode characterizations (module_model_name === 'full_diode') -----
    a: number;
    i0: number;
    series_resistance: number;
    parallel_resistance: number;
    temp_i0: number;

    // ---- fields for PAN file characterizations (module_model_name === 'pvsyst') -----
    has_file: boolean;
    pvsyst_filename: string;

    mu_isc: number;
    mu_voc: number;
    mu_pmp: number;

    rshunt_0: number;
    rshunt_exp: number;
    rshunt_gref: number;

    rseries: number;
    di2_mu_tau: number;

    diode_voltage: number;
    brev: number;

    i0_ref: number;
    iph_ref: number;
    gamma_ref: number;
    mu_gamma: number;

    constructor(data: any) {
        super(ModuleCharacterization.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        last_update: (x) => moment(x),
    });

    get vector() {
        return { x: this.length, y: this.width, z: 0.0 };
    }

    implementation(irradiance = 1000, temp = 25) {
        if (this.module_model_name === 'pvsyst') {
            return new PVSystDiodeModule(this, irradiance, temp);
        }

        return new FullDiodeModule(this, irradiance, temp);
    }
}

interface MatchedModule extends Module {
    _match_quality: number;
}

enum ModuleModelTypes {
    full_diode = 'Full-Diode',
    pvsyst = 'PAN',
}
type ModuleModelType = keyof typeof ModuleModelTypes;

const schemaObj = schema.addObject(ModuleCharacterization, 'module_characterization', {
    relationships: {
        team: { schema: tm.schemaObj },
    },
});

const endpoint = ReduxEndpoint.fromSchema('/api/module_characterizations/', schemaObj);

interface IModCharForm {
    file: File;
    characterization?: {
        module_id: number;
        name: string;
        description: string;
    };
}

const api = {
    index: endpoint.index<{
        module_id?: number | string;
        project_id?: number | string;
    }>(),
    get: endpoint.get<{ module_characterization_id: number | string }>('{module_characterization_id}'),
    panFile: endpoint.get<{ module_characterization_id: number | string }>('{module_characterization_id}/pan'),
    panFileToCharOrSaveCharToModule: endpoint.post<
        IModCharForm,
        undefined,
        MatchedCharacterization | ModuleCharacterization
    >('pan', ReduxEndpoint.PassThroughConfig()),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

// PAN endpoint returns this. Contains Module, ModuleCharacterization, Metadata.
class MatchedCharacterization extends ModuleCharacterization {
    metadata: { modules: MatchedModule[] };
}

export default ModuleCharacterization;

export { ModuleCharacterization, ModuleModelTypes, MatchedCharacterization, MatchedModule, schemaObj, api, selectors };
