/* tslint:disable:variable-name function-name */
import moment from 'moment';

import { BaseClass } from 'reports/utils/api/BaseClass';

export class TestClock extends BaseClass {
    id: string;
    created: moment.Moment;
    frozen_time: moment.Moment;
    livemode: boolean;
    name?: string;
    status: string;

    constructor(data) {
        super(TestClock.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        created: moment.unix,
        frozen_time: moment.unix,
    });
}
